import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import Icon from 'components-v2/atoms/Icon'
import Select from 'components-v2/atoms/Select'
import MultiSelectFilter from 'components-v2/molecules/MultiSelectFilter'
import Popover from 'components-v2/molecules/Popover'
import DateRangeFilter from '../DateRangeFilter'
import { getFilterSelectedCount } from '../utils'
import { filterTypes } from '../consts'
import {
  Wrapper,
  FilterRow,
  FilterCol,
  FilterTitle,
  FilterBox,
  FilterBoxBody,
  FilterBoxFooter,
  SelectedFilterCount,
  ResetFilterLink,
  RemoveFilterButton,
  AddFilterPopoverButton,
  AddFilterPopoverContent,
  Footer,
  ApplyButton,
} from './styles'

const getFilterComponent = (type) => {
  switch (type) {
    case filterTypes.DATE_RANGE:
      return DateRangeFilter
    default:
      return MultiSelectFilter
  }
}

const PanelContent = ({
  maxWidth,
  configList,
  visibleFilters,
  filtersEditable,
  value: valueProp,
  onChange,
  onClose,
  onChangeVisibleFilters,
}) => {
  const [value, setValue] = React.useState(valueProp)

  const handleChange = React.useCallback((name, val) => {
    setValue((currentValue) => ({
      ...currentValue,
      [name]: val,
    }))
  }, [])

  const handleResetFilter = React.useCallback((name) => {
    setValue((currentValue) => ({
      ...currentValue,
      [name]: undefined,
    }))
  }, [])

  const handleRemoveFilter = React.useCallback((name) => {
    onChangeVisibleFilters((currentVisibleFilters) =>
      currentVisibleFilters.filter((e) => e !== name),
    )
    handleResetFilter(name)
  }, [])

  const handleResetAll = React.useCallback(() => {
    setValue({})
  }, [])

  const handleApply = React.useCallback(() => {
    onChange(value)
    onClose()
  }, [value, onChange, onClose])

  const visibleFilterConfigList = React.useMemo(() => {
    if (!visibleFilters) {
      return configList
    }
    return configList.filter((o) => visibleFilters.indexOf(o.name) !== -1)
  }, [visibleFilters, configList])

  const invisibleFilterConfigList = React.useMemo(
    () => configList.filter((o) => visibleFilterConfigList.indexOf(o) === -1),
    [visibleFilterConfigList, configList],
  )

  const addFilterButtonVisible =
    filtersEditable && invisibleFilterConfigList.length > 0

  return (
    <Wrapper>
      <FilterRow
        $maxWidth={maxWidth - 35}
        $cols={visibleFilterConfigList.length}
        addFilterButtonVisible={addFilterButtonVisible}
      >
        {map(visibleFilterConfigList, (o) => {
          const { name, title, type, component, ...restFilterProps } = o
          const selectedCount = getFilterSelectedCount(configList, name, value)
          const Component = component || getFilterComponent(type)
          return (
            <FilterCol key={name}>
              <FilterTitle>{title}</FilterTitle>
              <FilterBox>
                <FilterBoxBody>
                  <Component
                    {...restFilterProps}
                    value={value?.[name]}
                    onChange={(val) => handleChange(name, val)}
                  />
                </FilterBoxBody>
                <FilterBoxFooter>
                  <SelectedFilterCount value={selectedCount}>
                    {selectedCount} selected
                  </SelectedFilterCount>
                  <ResetFilterLink onClick={() => handleResetFilter(name)}>
                    Reset
                  </ResetFilterLink>
                  {filtersEditable && (
                    <RemoveFilterButton
                      onClick={() => handleRemoveFilter(name)}
                    />
                  )}
                </FilterBoxFooter>
              </FilterBox>
            </FilterCol>
          )
        })}
        {addFilterButtonVisible && (
          <Popover offset={-30}>
            <Popover.Button as={AddFilterPopoverButton}>
              <Icon icon="fa fa-plus" />
              Add
            </Popover.Button>
            <Popover.Panel title="Add Filter" arrow>
              {({ close }) => (
                <AddFilterPopoverContent>
                  <Select
                    value=""
                    onChange={(e) => {
                      onChangeVisibleFilters((currentVisibleFilters) => [
                        ...currentVisibleFilters,
                        e.currentTarget.value,
                      ])
                      close()
                    }}
                  >
                    <option key="default" value="" disabled>
                      Select
                    </option>
                    {invisibleFilterConfigList.map((e) => (
                      <option key={e.name} value={e.name}>
                        {e.title}
                      </option>
                    ))}
                  </Select>
                </AddFilterPopoverContent>
              )}
            </Popover.Panel>
          </Popover>
        )}
      </FilterRow>
      <Footer>
        <ResetFilterLink onClick={handleResetAll}>Reset All</ResetFilterLink>
        <ApplyButton onClick={handleApply}>Apply</ApplyButton>
      </Footer>
    </Wrapper>
  )
}

PanelContent.propTypes = {
  maxWidth: PropTypes.number.isRequired,
  configList: PropTypes.array.isRequired,
  visibleFilters: PropTypes.array,
  filtersEditable: PropTypes.bool,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onChangeVisibleFilters: PropTypes.func.isRequired,
}

export default PanelContent
