import React from 'react'
import PropTypes from 'prop-types'
import orderBy from 'lodash/orderBy'
import FormField from 'components-v2/molecules/FormField'
import { toLocalTimeString } from 'utils/date'
import config from 'config'
import { SortOrderTypes } from 'constants/index'

const SelectAssessmentFormField = ({ assessments, ...rest }) => {
  const options = React.useMemo(
    () =>
      orderBy(
        assessments,
        ['name', 'sent_at'],
        [SortOrderTypes.ASC, SortOrderTypes.DESC],
      ).map((assessment) => ({
        value: assessment.id,
        label: `${assessment.name} (Sent ${toLocalTimeString(
          assessment.sent_at,
          config.tableDateFormat,
        )})`,
      })),
    [assessments],
  )
  return (
    <FormField
      name="assessment"
      label="Assessment"
      type={FormField.types.SELECT}
      options={options}
      horizontal
      {...rest}
    />
  )
}

SelectAssessmentFormField.propTypes = {
  assessments: PropTypes.array.isRequired,
}

export default SelectAssessmentFormField
