import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Title } from './styles'

const Meta = ({ title, content }) => (
  <Wrapper>
    <Title>{title}</Title>
    <div>{content}</div>
  </Wrapper>
)

Meta.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
}

export default Meta
