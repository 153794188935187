import React from 'react'
import PropTypes from 'prop-types'
import isNumber from 'lodash/isNumber'
import get from 'lodash/get'
import { HeatBubbleChart } from 'components-v2/molecules/Charts'
import { getSectionsFromElements } from 'lib/assessment'

const getRiskRatingsArray = (
  sections,
  sectionRiskRatings,
  riskRating,
  ratingLabel,
) => {
  const result = []
  sections.forEach((section) => {
    if (isNumber(sectionRiskRatings[section.id])) {
      result.push([
        section.label.replace(/\./gi, ''),
        sectionRiskRatings[section.id],
      ])
    }
  })
  if (isNumber(riskRating)) {
    result.push([`Overall ${ratingLabel}`, riskRating])
  }
  return result
}

const Heatmap = ({ assessment, ...rest }) => {
  const ratingLabel = get(
    assessment,
    'assessment_template.rating_label',
    'Risk Rating',
  )
  const {
    elements,
    risk_rating: riskRating,
    section_risk_ratings: sectionRiskRatings,
  } = assessment
  const sections = getSectionsFromElements(elements)
  const data = React.useMemo(
    () =>
      getRiskRatingsArray(
        sections,
        sectionRiskRatings,
        riskRating,
        ratingLabel,
      ),
    [sections, sectionRiskRatings, riskRating, ratingLabel],
  )
  return (
    <HeatBubbleChart
      {...rest}
      height={568}
      data={data}
      hLabel="Section"
      vLabel={ratingLabel}
      options={{
        title: `Section ${ratingLabel}`,
        chartArea: { left: 80, top: 45, width: '90%', height: '50%' },
        sizeAxis: { minValue: 0, maxSize: 14 },
        bubble: {
          textStyle: { fontSize: 11, color: 'white', auraColor: 'none' },
        },
      }}
    />
  )
}

Heatmap.propTypes = {
  assessment: PropTypes.object.isRequired,
}

export default React.memo(Heatmap)
