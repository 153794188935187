import styled from 'styled-components'

export const Metric = styled.div`
  font-size: 50px;
  line-height: 37px;
  ${(p) => p.color && `color: ${p.color};`}
`

export const Bottom = styled.div`
  margin-top: 0.8em;
  font-size: 14px;
  line-height: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Dot = styled.span`
  display: inline-block;
  width: 0.85em;
  height: 0.85em;
  border-radius: 100%;
  background: ${(p) => p.color};

  &:not(:last-child) {
    margin-right: 4px;
  }
`

export const Label = styled.span`
  text-transform: capitalize;
  font-weight: bold;
`
