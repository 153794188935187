import React from 'react'
import * as yup from 'yup'
import Well from 'components-v2/atoms/Well'
import FormDialog from 'components-v2/organisms/FormDialog'
import { StyledFormField as FormField } from './styles'

const formConfig = {
  validationSchema: yup.object().shape({
    days: yup
      .number()
      .label('This')
      .typeError('This must be a number')
      .required('This is required')
      .positive()
      .integer()
      .min(1),
  }),
  initialValues: {
    days: '',
  },
}

const RequestAssessmentExtensionModal = (props) => (
  <FormDialog
    {...props}
    title="Assessment Extension"
    submitButtonLabel="Submit"
    formConfig={formConfig}
  >
    <Well>How many days do you need to complete the assessment?</Well>
    <FormField name="days" label="Days" horizontal />
  </FormDialog>
)

export default RequestAssessmentExtensionModal
