import styled from 'styled-components'
import A from 'components-v2/atoms/A'
import { colors } from 'styles'

export const SocialLink = styled(A).attrs({ underline: true })`
  line-height: 26px;

  i {
    color: ${colors.BODY_TEXT};
    margin-right: 7px;
  }
`
