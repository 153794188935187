import isNumber from 'lodash/isNumber'

function commarize() {
  // Alter numbers larger than 1k
  if (this >= 1e3) {
    const units = ['k', 'million', 'billion', 'trillion']

    // Divide to get SI Unit engineering style numbers (1e3,1e6,1e9, etc)
    const unit = Math.floor((this.toFixed(0).length - 1) / 3) * 3
    // Calculate the remainder
    const num = (this / `1e${unit}`).toFixed(2)
    const unitname = units[Math.floor(unit / 3) - 1]

    // output number remainder + unitname
    return `${num} ${unitname}`
  }

  // return formatted original number
  return this.toLocaleString()
}

// Add method to prototype. this allows you to use this function on numbers directly
// eslint-disable-next-line no-extend-native
Number.prototype.commarize = commarize

export const toLocaleString = (num) =>
  isNumber(num) ? num.toLocaleString() : num
