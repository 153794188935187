import { useState, useEffect } from 'react'
import { useCurrent } from 'hooks'
import NotificationManager from 'lib/notifications'
import { getVendorAssessmentElementResponse } from 'apis'

export default function usePusher(
  channelId,
  assessmentId,
  sectionId,
  setAssessment,
  updateStatesFromElementResponse,
  activateHighlight,
) {
  const [socketId, setSocketId] = useState()
  const sectionIdRef = useCurrent(sectionId)

  // Listen state changes via pusher
  useEffect(() => {
    const update = (data) => {
      if (data.status) {
        setAssessment((current) => ({
          ...current,
          status: data.status,
        }))
      }

      if (data.response) {
        const elementId = data.response.element_id
        const selectedSectionId = sectionIdRef.current

        getVendorAssessmentElementResponse(assessmentId, elementId, {
          params: {
            section_scope: selectedSectionId,
          },
        })
          .then((res) => {
            updateStatesFromElementResponse(res.data, selectedSectionId, true)

            // Show yellowFade animation
            activateHighlight(elementId)
          })
          .catch(() =>
            NotificationManager.error(
              'There was an error fetching new updates. Please check your connection and try again later.',
            ),
          )
      }
    }
    const channel = window.pusher.subscribe(channelId)
    channel.bind('update', update)
    return () => {
      channel.unbind('update', update)
    }
  }, [channelId, assessmentId, setAssessment, activateHighlight])

  useEffect(() => {
    window.pusher.connection.bind('connected', () => {
      setSocketId(window.pusher.connection.socket_id)
    })
  }, [])

  return socketId
}
