import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import Segment, { padding } from './Segment'
import ViewMoreSegment from './ViewMoreSegment'

const Card = styled.div`
  background: #fff;
  border-radius: 4px;
  ${(props) =>
    props.bordered
      ? `border: 1px solid #D7D7D7;`
      : `box-shadow: 0px 5px 15px 0px rgba(27,30,35,0.05);`}

  ${(props) =>
    props.padding &&
    css`
      ${padding}

      ${Segment} {
        padding-left: 0;
        padding-right: 0;

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          padding-bottom: 0;
        }
      }
    `}
`

Card.propTypes = {
  bordered: PropTypes.bool,
  padding: PropTypes.bool,
}

Card.defaultProps = {
  bordered: false,
  padding: false,
}

Card.Segment = Segment
Card.ViewMoreSegment = ViewMoreSegment

export default Card
