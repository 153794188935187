import { useCallback } from 'react'
import { useUpdateClientAssessmentTemplate } from 'apis'

export default function useUpdateCustomKeysAbility(templateId) {
  const { mutateAsync: updateClientAssessmentTemplate } =
    useUpdateClientAssessmentTemplate()
  return useCallback(
    (enabled) => {
      const data = {
        assessment_template: {
          use_custom_keys: enabled,
        },
      }
      return updateClientAssessmentTemplate({ id: templateId, data })
    },
    [templateId, updateClientAssessmentTemplate],
  )
}
