import React from 'react'
import PropTypes from 'prop-types'
import concat from 'lodash/concat'
import map from 'lodash/map'
import get from 'lodash/get'
import Dialog from 'components-v2/molecules/Dialog'
import Icon from 'components-v2/atoms/Icon'
import { RiskReconIssuePriorityTypes } from 'components/client/lib/globorg_integration_metrics'
import { compose, hasLoader, hasError } from 'hocs'
import config from 'config'
import {
  StyledTabs as Tabs,
  StyledTable as Table,
  IssueWrapper,
  IssueContentLeft,
  Title,
  Severity,
  Description,
  CreateButton,
} from './styles'

const Content = compose(
  hasLoader,
  hasError(),
)(({ data, filter, onCreateIssue }) => {
  const [issueFilter, setIssueFilter] = React.useState(filter)

  const issues = React.useMemo(() => {
    const arr =
      issueFilter === RiskReconIssuePriorityTypes.PRIORITY1
        ? get(data, 'p1_issues')
        : issueFilter === RiskReconIssuePriorityTypes.PRIORITY2
        ? get(data, 'p2_issues')
        : concat(get(data, 'p1_issues', []), get(data, 'p2_issues', []))
    // Add unique key field to every issue
    return map(arr, (e, index) => ({ ...e, id: index }))
  }, [data, issueFilter])

  const tableColumns = React.useMemo(
    () => [
      {
        id: 'df1',
        header: '',
        cell: (info) => (
          <IssueWrapper hoverable={!!onCreateIssue}>
            <IssueContentLeft>
              <Title>{info.row.original.finding}</Title>
              <Severity danger={info.row.original.severity === 'critical'}>
                {info.row.original.severity}
              </Severity>
              <Description>
                {info.row.original.host_name
                  ? `${info.row.original.host_name}: `
                  : ''}
                {info.row.original.finding_context}
              </Description>
            </IssueContentLeft>
            {onCreateIssue && (
              <CreateButton
                color="primary"
                onClick={() => onCreateIssue(info.row.original)}
              >
                <Icon icon="fa fa-plus" />
                Create Issue
              </CreateButton>
            )}
          </IssueWrapper>
        ),
      },
    ],
    [onCreateIssue],
  )

  return (
    <>
      <Tabs activeKey={issueFilter} onChange={setIssueFilter}>
        <Tabs.Tab eventKey="all" title="View all" />
        <Tabs.Tab
          eventKey={RiskReconIssuePriorityTypes.PRIORITY1}
          title="Priority 1"
        />
        <Tabs.Tab
          eventKey={RiskReconIssuePriorityTypes.PRIORITY2}
          title="Priority 2"
        />
      </Tabs>
      <Table
        key={issueFilter}
        data={issues}
        columns={tableColumns}
        defaultPagination={{
          page: 1,
          sizePerPage: config.collectionQueryPageLimit.riskReconIssuesModal,
        }}
        enableSorting={false}
        noDataText="No issues found."
        type={Table.types.HEADLESS}
        paginationTotalRenderer={(from, to, size) => `${from}-${to} of ${size}`}
      />
    </>
  )
})

const RiskReconIssuesModal = ({
  data,
  loading,
  error,
  filter,
  onCreateIssue,
  onClose,
  ...rest
}) => (
  <Dialog {...rest} onClose={onClose}>
    <Dialog.Header onClose={onClose}>RiskRecon Issues</Dialog.Header>
    <Dialog.Content>
      <Content
        data={data}
        loading={loading}
        hasError={error}
        filter={filter}
        onCreateIssue={onCreateIssue}
      />
    </Dialog.Content>
  </Dialog>
)

RiskReconIssuesModal.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  filter: PropTypes.string,
  onCreateIssue: PropTypes.func,
  onClose: PropTypes.func,
}

export default RiskReconIssuesModal
