import React from 'react'
import PropTypes from 'prop-types'
import FilesInput from 'components-v2/molecules/FilesInput'
import BaseField from './BaseField'

const FilesInputField = ({
  name,
  value,
  disabled,
  controlProps = {},
  onChange,
  ...rest
}) => (
  <BaseField {...rest}>
    <FilesInput
      {...controlProps}
      name={name}
      value={value}
      disabled={disabled}
      onChange={onChange}
    />
  </BaseField>
)

FilesInputField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.array,
  disabled: PropTypes.bool,
  controlProps: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

export default React.memo(FilesInputField)
