import { useCallback } from 'react'
import Privva from 'Privva'
import PromotionalModal from 'components/shared/PromotionalModal'
import NotificationManager from 'lib/notifications'
import * as apis from 'apis'
import { useModal } from 'hooks'

export default (clientId, vendorId) => {
  const [openModal] = useModal(PromotionalModal)

  return useCallback(
    (metricType, metricCode) => {
      // call api to get url
      apis
        .getClientVendorIntegrationLink(vendorId, {
          metric_type: metricType,
          metric_code: metricCode,
        })
        .then((response) => {
          if (response.data?.error) {
            openModal({
              targetOrgId: clientId,
              parentOrgId: clientId,
              placement: Privva.Utils.promotionPlacements.RISK_RECON_DETAILS,
              promotionType: Privva.Utils.promotionTypes.BASIC_MESSAGE,
            })
          } else if (response.data?.warning) {
            NotificationManager.error(response.data.warning)
          } else {
            window.open(response.data, '_new')
          }
        })
        .catch((error) => {
          NotificationManager.error()
          console.error(error)
        })
    },
    [clientId, vendorId, openModal],
  )
}
