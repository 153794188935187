import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import { BaseField } from 'components-v2/molecules/FormField'
import { StyledSelect } from './styles'

const EXPIRATION_REMINDER_OPTIONS = [1, 2, 5, 7, 15, 30, 60, 90]

const ExpirationReminderFormField = ({
  name,
  value,
  disabled,
  onChange,
  ...rest
}) => (
  <BaseField {...rest}>
    <div>
      <StyledSelect
        name={name}
        value={value !== undefined && value !== null ? value : ''}
        disabled={disabled}
        onChange={(e) => onChange(e.currentTarget.value)}
      >
        <option key="default" value="" disabled>
          Select
        </option>
        {map(EXPIRATION_REMINDER_OPTIONS, (num) => (
          <option key={num} value={num}>
            {num} {num === 1 ? 'day' : 'days'}
          </option>
        ))}
      </StyledSelect>
      before expiration.
    </div>
  </BaseField>
)

ExpirationReminderFormField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

export default ExpirationReminderFormField
