import React from 'react'
import Privva from 'Privva'
import PropTypes from 'prop-types'
import keyBy from 'lodash/keyBy'
import filter from 'lodash/filter'
import { PieChart } from 'components-v2/molecules/Charts'
import { getAssessmentGradeColor } from '../utils'
import {
  StyledContentCard,
  Top,
  Title,
  ChartWrapper,
  Bottom,
  BottomRight,
  Score,
  ScoreLabel,
} from './styles'

const ORDERED_GRADE_VALUES = [1, -1, 0]
const gradeOptions = keyBy(Privva.Utils.assessment_grade_options, 'value')
const getChartData = (grades) => {
  const data = ORDERED_GRADE_VALUES.map((value) => ({
    name: gradeOptions[value].label,
    value: grades[value],
    color: getAssessmentGradeColor(value, gradeOptions[value].color),
  }))
  return filter(data, (sector) => sector.value)
}

const GradeChartWidget = ({
  title,
  grades,
  averageScore,
  viewAllUrl,
  ...rest
}) => (
  <StyledContentCard {...rest}>
    <Top>
      <Title>{title}</Title>
      <ChartWrapper>
        <PieChart
          data={getChartData(grades)}
          width="100%"
          height={260}
          innerRadius={38}
          outerRadius={77}
          startAngle={90}
          endAngle={450}
          label
          labelOnHover
        />
      </ChartWrapper>
    </Top>
    <Bottom>
      <Score>
        {averageScore ? Number(averageScore) : '--'}
        <sup>%</sup>
      </Score>
      <BottomRight>
        <ScoreLabel>Average score</ScoreLabel>
        <a href={viewAllUrl}>View all</a>
      </BottomRight>
    </Bottom>
  </StyledContentCard>
)

GradeChartWidget.propTypes = {
  title: PropTypes.string.isRequired,
  grades: PropTypes.object.isRequired,
  averageScore: PropTypes.string,
  viewAllUrl: PropTypes.string,
}

export default React.memo(GradeChartWidget)
