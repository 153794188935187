import React from 'react'
import PropTypes from 'prop-types'
import { PlusSquare } from '@styled-icons/fa-solid/PlusSquare'
import Icon from 'components-v2/atoms/Icon'
import MultiCheckbox from 'components-v2/molecules/MultiCheckbox'
import { Heading, Wrapper, CreateButton } from './styles'

const SaveToWatchlist = ({
  activeWatchlistIds,
  watchlists,
  onChange,
  onAddToNew,
  ...rest
}) => (
  <Wrapper {...rest}>
    <Heading>Save to Watchlist</Heading>
    <MultiCheckbox
      value={activeWatchlistIds}
      options={watchlists}
      onChange={onChange}
      getOptionValue={(e) => e.id}
      getOptionLabel={(e) => e.name}
    />
    <CreateButton onClick={onAddToNew}>
      <Icon icon={PlusSquare} />
      Add to New Watchlist
    </CreateButton>
  </Wrapper>
)

SaveToWatchlist.propTypes = {
  watchlists: PropTypes.array.isRequired,
  activeWatchlistIds: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onAddToNew: PropTypes.func.isRequired,
}

SaveToWatchlist.defaultProps = {
  activeWatchlistIds: [],
}

export default SaveToWatchlist
