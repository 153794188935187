import { getMultiSelectFilterSelectedCount } from 'lib/multiSelectFilter'
import { getPageUrl, getEndpointUrl } from 'utils/url'
import { localTimeZoneOffsetInHours, toMonthParam } from 'utils/date'

export const getMonthlyMetricsQueryParams = ({
  assessmentTemplate,
  status,
  riskTier,
  assignee,
  vendorTag,
  dateRange,
  archivedValues,
  ...rest
}) => ({
  ...rest,
  with_templates: assessmentTemplate?.selected,
  status: status?.selected,
  risk_tier_list: riskTier?.selected,
  client_assignee: assignee?.selected,
  unassigned: assignee?.other,
  with_vendor_tags: vendorTag?.selected,
  any: vendorTag?.anyMatch,
  start_date: dateRange?.startDate ? toMonthParam(dateRange.startDate) : '',
  end_date: dateRange?.endDate ? toMonthParam(dateRange.endDate, true) : '',
  tz: localTimeZoneOffsetInHours,
  exclude_archived_vendors: archivedValues?.selected?.indexOf('vendors') === -1,
  exclude_archived: archivedValues?.selected?.indexOf('projects') === -1,
})

export const getMonthlyMetricsExportUrl = (filters, format) =>
  getEndpointUrl(
    'clientAssessmentMonthlyMetrics',
    undefined,
    getMonthlyMetricsQueryParams(filters),
    format,
  )

export const getAssessmentsForMetricPageUrl = (filters, customFilters) =>
  getPageUrl('clientVendors', undefined, {
    tab: 'assessments',
    vendorTag: filters.vendorTag?.selected,
    vendorTagsAnyMatch: filters.vendorTag?.anyMatch,
    riskTier: filters.riskTier?.selected,
    status: customFilters.status || filters.status?.selected,
    assignee: filters.assignee?.selected,
    unassigned: filters.assignee?.other,
    // The following params are not unsupported in client assessment list view
    assessmentTemplate: filters.assessmentTemplate?.selected,
    date_type: customFilters.date_type,
    start_date:
      customFilters.startDate || filters.dateRange?.startDate
        ? new Date(customFilters.startDate || filters.dateRange?.startDate)
            .toISOString()
            .substring(0, 10)
        : undefined,
    end_date:
      customFilters.endDate || filters.dateRange?.endDate
        ? new Date(customFilters.endDate || filters.dateRange?.endDate)
            .toISOString()
            .substring(0, 10)
        : undefined,
    include_archived: filters.archivedValues?.selected,
  })

export const getMultiSelectFilterLabelWithSelectedCount = (label, filter) => {
  const count = getMultiSelectFilterSelectedCount(filter)
  return `${label} ${count ? `(${count})` : ''}`
}
