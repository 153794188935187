import React, { useRef, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import IconButton from 'components-v2/atoms/IconButton'
import { FormFieldTypes } from 'components-v2/molecules/FormField'
import Table from 'components-v2/molecules/Table'
import Toolbar, { SearchInput } from 'components-v2/molecules/Table/Toolbar'
import FormikAutoSave from 'components-v2/utils/FormikAutoSave'
import { pluralize } from 'utils/string'
import { SortOrderTypes } from 'constants/index'
import config from 'config'
import { StyledFormField as FormField } from '../styles'

const getTableColumns = (templateCount, vendorCount, onEdit, onRemove) => {
  const columns = [
    {
      id: 'name',
      header: '',
      cell: (info) => {
        const { templates, vendors } = info.row.original
        const vendorString = `${
          vendors.length
            ? `${vendors.length}/${pluralize(vendorCount, 'vendor')}`
            : ''
        }`
        const templateString = `${
          templates.length
            ? `${templates.length}/${pluralize(templateCount, 'template')}${
                vendorString.length > 0 ? ' for ' : ''
              }`
            : ''
        }`
        return `${info.getValue()} 
          ${
            templateString.length || vendorString.length
              ? `(${templateString}${vendorString})`
              : ''
          }
        `
      },
      width: '100%',
    },
  ]
  if (templateCount > 1 || vendorCount > 1) {
    columns.push({
      id: 'edit',
      header: '',
      cell: (info) => (
        <IconButton
          icon="fa fa-pencil"
          onClick={() => onEdit(info.row.original.id)}
        />
      ),
    })
  }
  columns.push({
    id: 'remove',
    header: '',
    cell: (info) => (
      <IconButton
        icon="fa fa-trash-o"
        onClick={() => onRemove(info.row.original.id)}
      />
    ),
  })
  return columns
}

const NotificationsForm = ({
  project,
  eventSubscribers,
  isFetchingEventSubscribers,
  onSubmit,
  onEditEventSubscriber,
  onRemoveEventSubscriber,
}) => {
  const initialValues = useRef({
    subscriptions_enabled: project.subscriptions_enabled,
  })
  const templateCount = project.assessment_templates.length
  const vendorCount = project.project_vendors.length
  const tableColumns = useMemo(
    () =>
      getTableColumns(
        templateCount,
        vendorCount,
        onEditEventSubscriber,
        onRemoveEventSubscriber,
      ),
    [
      templateCount,
      vendorCount,
      onEditEventSubscriber,
      onRemoveEventSubscriber,
    ],
  )
  const [searchText, setSearchText] = useState('')

  return (
    <>
      <Toolbar>
        <SearchInput value={searchText} onChange={setSearchText} />
      </Toolbar>
      <Formik initialValues={initialValues.current} onSubmit={onSubmit}>
        <>
          <FormikAutoSave />
          <FormField
            type={FormFieldTypes.TOGGLE}
            name="subscriptions_enabled"
            label="Project related notifications"
            helpMessage="Enable this to send notifications for this project's assessments and associated issues to only the users specified below"
          />
        </>
      </Formik>
      <Table
        data={eventSubscribers}
        columns={tableColumns}
        loading={isFetchingEventSubscribers}
        defaultPagination={{
          page: 1,
          sizePerPage:
            config.collectionQueryPageLimit.projectNotificationsTable,
        }}
        defaultSorting={{
          sortField: 'name',
          sortOrder: SortOrderTypes.ASC,
        }}
        globalFilter={searchText}
        onGlobalFilterChange={setSearchText}
        noDataText="No users found."
        type={Table.types.HEADLESS}
        paginationTotalRenderer={(from, to, size) =>
          `Viewing ${from}-${to} of ${pluralize(size, 'user')}`
        }
      />
    </>
  )
}

NotificationsForm.propTypes = {
  project: PropTypes.object.isRequired,
  eventSubscribers: PropTypes.array,
  isFetchingEventSubscribers: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onEditEventSubscriber: PropTypes.func.isRequired,
  onRemoveEventSubscriber: PropTypes.func.isRequired,
}

export default NotificationsForm
