import { useCallback } from 'react'
import pick from 'lodash/pick'
import useRequestAssessmentExtension from 'components/vendor/shared/useRequestAssessmentExtension'

const safePropertiesToUpdate = [
  'status',
  'humanized_status',
  'extension_request_allowed',
  'extension_requested_at',
  'extension_minutes_requested',
  'due_at',
]

export default function useRequestExtension(assessment, setAssessment) {
  const requestExtension = useRequestAssessmentExtension()

  return useCallback(() => {
    const onSuccess = (res) => {
      setAssessment((current) => ({
        ...current,
        ...pick(
          res.assessments.find((ass) => ass.id === assessment.id),
          safePropertiesToUpdate,
        ),
      }))
    }
    requestExtension(assessment, onSuccess)
  }, [assessment, setAssessment, requestExtension])
}
