import React from 'react'
import PropTypes from 'prop-types'
import {
  AssessmentElementTypes,
  AssessmentElementSubtypes,
} from 'constants/index'
import MultiSelect from './MultiSelect'
import SingleSelect from './SingleSelect'
import Text from './Text'
import TextWithValidation from './TextWithValidation'
import Date from './Date'

const AssessmentElementAnswerForm = ({
  element,
  showAutoScores,
  autoScoreOptions,
  onAutoScoreChange,
  ...rest
}) => {
  const { type, subtype, choices } = element
  switch (type) {
    case AssessmentElementTypes.MULTI_SELECT_QUESTION:
      return <MultiSelect choices={choices} {...rest} />
    case AssessmentElementTypes.SINGLE_SELECT_QUESTION:
      return (
        <SingleSelect
          choices={choices}
          showAutoScores={showAutoScores}
          autoScoreOptions={autoScoreOptions}
          onAutoScoreChange={onAutoScoreChange}
          {...rest}
        />
      )
    default:
  }
  switch (subtype) {
    case AssessmentElementSubtypes.FLOAT:
    case AssessmentElementSubtypes.MONEY:
    case AssessmentElementSubtypes.YEAR:
    case AssessmentElementSubtypes.PHONE:
    case AssessmentElementSubtypes.STATE:
    case AssessmentElementSubtypes.ZIPCODE:
    case AssessmentElementSubtypes.EMAIL:
    case AssessmentElementSubtypes.URI:
      return <TextWithValidation type={subtype} {...rest} />
    case AssessmentElementSubtypes.DATE:
    case AssessmentElementSubtypes.DATETIME:
      return <Date type={subtype} {...rest} />
    default:
      return <Text {...rest} />
  }
}

AssessmentElementAnswerForm.propTypes = {
  element: PropTypes.object.isRequired,
  value: PropTypes.array,
  disabled: PropTypes.bool,
  onChange: PropTypes.func, // Will be fired on blur and if valid
  showAutoScores: PropTypes.bool,
  autoScoreOptions: PropTypes.array,
  onAutoScoreChange: PropTypes.func,
  translatable: PropTypes.bool,
}

export default React.memo(AssessmentElementAnswerForm)
