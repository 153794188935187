import React from 'react'
import NotificationManager from 'lib/notifications'
import { useModal, useGlobalLoader } from 'hooks'
import objectToFormData from 'utils/object_to_formdata'
import { useBulkImportClientVendors } from 'apis'
import ImportVendorsModal from './ImportVendorsModal'

export default function useImportVendors() {
  const [openModal, closeModal] = useModal(ImportVendorsModal)
  const [showLoader, hideLoader] = useGlobalLoader()
  const { mutateAsync: bulkImportVendors } = useBulkImportClientVendors()

  return React.useCallback(
    (orgId) => {
      const onSubmit = ({ file }, { setErrors }) => {
        const loaderId = showLoader()
        return bulkImportVendors({
          data: objectToFormData({ import_file: file, client_id: orgId }),
        })
          .then(() => {
            NotificationManager.success(
              'Vendors have been successfully imported.',
            )
            closeModal()
          })
          .catch((error) => {
            console.error(error)
            if (error.response && error.response.status === 422) {
              setErrors({ file: error.response.data })
            } else {
              NotificationManager.error()
            }
          })
          .then(() => hideLoader(loaderId))
      }
      openModal({ onSubmit })
    },
    [bulkImportVendors, openModal, closeModal, showLoader, hideLoader],
  )
}
