import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import NotificationManager from 'lib/notifications'
import Editable from 'components-v2/molecules/Editable'

const AttributeEditLink = ({
  value: valueProp,
  method,
  url,
  model,
  attribute,
  successCallback,
  ...rest
}) => {
  const [value, setValue] = React.useState(valueProp)

  React.useEffect(() => {
    setValue(valueProp)
  }, [valueProp])

  const handleSubmit = (newValue) => {
    const params = {
      method,
      url,
      data: {
        [model]: {
          [attribute]: newValue,
        },
      },
    }

    // Let's update UI first and call api
    setValue(newValue)

    axios(params)
      .then((response) => {
        if (successCallback) {
          successCallback(response.data)
        }
      })
      .catch((error) => {
        console.error(error)
        if (error.response && error.response.status === 422) {
          const msg = error.response.data.error || 'Invalid'
          NotificationManager.error(
            `There was an error saving your response. ${msg}`,
          )
        } else {
          NotificationManager.error(
            'There was an error saving your response. Please check your connection and try again later.',
          )
        }
      })
  }

  return <Editable {...rest} value={value} onSubmit={handleSubmit} />
}

AttributeEditLink.propTypes = {
  value: PropTypes.string,
  url: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  attribute: PropTypes.string.isRequired,
  dataType: PropTypes.string,
  mode: PropTypes.string,
  title: PropTypes.string,
  method: PropTypes.string,
  disabled: PropTypes.bool,
  emptyValueText: PropTypes.string,
  includeIcon: PropTypes.bool,
  successCallback: PropTypes.func,
}

AttributeEditLink.defaultProps = {
  dataType: 'text',
  mode: 'inline',
  title: 'Edit Title',
  method: 'PATCH',
  disabled: false,
  emptyValueText: 'empty',
  includeIcon: false,
}

export default AttributeEditLink
