import has from 'lodash/has'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'
import pickBy from 'lodash/pickBy'
import upperFirst from 'lodash/upperFirst'
import toLower from 'lodash/toLower'
import keyBy from 'lodash/keyBy'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import * as yup from 'yup'
import FormField from 'components-v2/molecules/FormField'
import VendorFieldGroups, {
  VendorFields,
  VendorFieldGroupIdTypes,
  VendorFieldNameTypes,
} from 'components/client/vendor_info_view/constants'
import { buildCustomFieldsCard } from 'components/client/vendor_info_view/utils'
import RiskTierQuestionsFieldGroup from './RiskTierQuestionsFieldGroup'

export const FieldNameTypes = {
  REQUEST_COMMENT: 'request_comment',
  REQUEST_FILES: 'request_files',
}

const RISK_TIER_QUESTIONS_PER_PAGE = 5

export const standardFieldGroups = [
  VendorFieldGroupIdTypes.ORGANIZATION_DETAILS,
  VendorFieldGroupIdTypes.DATA_SOURCES,
  VendorFieldGroupIdTypes.ASSESSMENT_CATEGORIES,
  VendorFieldGroupIdTypes.OTHER,
]

export const getStepsData = ({
  vendorRequestConfig,
  riskTierQuestions,
  customFieldDefinitions,
}) => {
  const { introNote, riskCalculatorEnabled, uploadEnabled, fieldGroupsConfig } =
    vendorRequestConfig
  const addFieldGroups = Object.keys(fieldGroupsConfig).length > 0
  const additionalInfoSteps = addFieldGroups
    ? mapValues(
        keyBy(
          filter(standardFieldGroups, (e) => has(fieldGroupsConfig, e)),
          (e) => e,
        ),
        (_, key) => ({
          title: upperFirst(toLower(VendorFieldGroups[key]?.label)),
          fields: filterAndParseFieldsFromExternalSource(
            VendorFieldGroups[key].fields,
            fieldGroupsConfig[key],
          ),
          horizontal: true,
          canSkip: true,
        }),
      )
    : undefined

  const customFieldSteps = addFieldGroups
    ? mapValues(
        keyBy(
          buildCustomFieldsCard(
            customFieldDefinitions.field_definitions,
            customFieldDefinitions.groups.Organization,
          ).filter((e) => has(fieldGroupsConfig, e.key) && !isEmpty(e.fields)),
          (e) => e.key,
        ),
        (value) => ({
          title: value.label,
          fields: filterAndParseFieldsFromExternalSource(
            value.fields,
            fieldGroupsConfig[value.key],
          ),
          horizontal: true,
          canSkip: true,
        }),
      )
    : undefined

  const additionalInfoNodes = {
    ...customFieldSteps,
    ...additionalInfoSteps,
  }

  return rejectUndefinedProperties({
    vendor_basics: {
      title: 'Vendor Basics',
      horizontal: true,
      // introNote is *not* currently formatted, to be clear, but this turns
      // double line breaks into P tags which looks much better.
      // (In theory the user could manually enter html tags, but they would
      // be limited to whatever we support in formatted text anyway)
      note: isEmpty(introNote) ? null : { body: introNote, formatted: true },
      fields: [
        VendorFields[VendorFieldNameTypes.NAME],
        VendorFields[VendorFieldNameTypes.VENDOR_CONTACTS],
      ],
    },
    additional_information: !isEmpty(additionalInfoNodes)
      ? {
          title: 'Additional Information',
          nodes: additionalInfoNodes,
        }
      : undefined,
    risk_tier_calculator:
      riskCalculatorEnabled && riskTierQuestions.length > 0
        ? {
            title: 'Risk Tier Calculator',
            hideSubMenu: true,
            nodes: mapValues(
              keyBy(
                [
                  ...Array(
                    Math.ceil(
                      riskTierQuestions.length / RISK_TIER_QUESTIONS_PER_PAGE,
                    ),
                  ).keys(),
                ],
                (val) => val + 1,
              ),
              (_, key) => ({
                title: 'Risk Tier Calculator',
                fields: [
                  {
                    name: VendorFieldNameTypes.VENDOR_RISK_TIER_ANSWERS,
                    component: RiskTierQuestionsFieldGroup,
                    controlProps: {
                      questions: riskTierQuestions,
                      pageSize: RISK_TIER_QUESTIONS_PER_PAGE,
                      page: key,
                    },
                    validation: yup.array().of(
                      yup.object().shape({
                        value: yup
                          .string()
                          .test(
                            'risk-tier-answer-required',
                            'Please select.',
                            function test(value) {
                              const index = parseInt(
                                this.path.split('[')[1].split(']')[0],
                                10,
                              )
                              const from =
                                (key - 1) * RISK_TIER_QUESTIONS_PER_PAGE
                              const to = Math.min(
                                from + RISK_TIER_QUESTIONS_PER_PAGE - 1,
                                riskTierQuestions.length - 1,
                              )
                              return !(index >= from && index <= to) || !!value
                            },
                          ),
                      }),
                    ),
                  },
                ],
              }),
            ),
          }
        : undefined,
    request_notes: {
      title: 'Request Notes',
      fields: rejectUndefinedElements([
        {
          name: FieldNameTypes.REQUEST_COMMENT,
          label: 'Add internal notes about this request. (Optional)',
          type: FormField.types.TEXTAREA,
          controlProps: {
            rows: 5,
          },
        },
        uploadEnabled
          ? {
              name: FieldNameTypes.REQUEST_FILES,
              type: FormField.types.FILES_INPUT,
              controlProps: {
                maxFiles: 3,
              },
            }
          : undefined,
      ]),
    },
  })
}

function filterAndParseFieldsFromExternalSource(fields, config) {
  // Remove field properties specific to VendorInfoView
  const omitProperties = ({
    valueRenderComponent,
    formFieldValueFormatter,
    visibilityGetter,
    ...rest
  }) => rest
  // only include specified fields
  const fieldList = filter(
    fields,
    (field) => config === true || config.includes(field.name),
  )
  return map(fieldList, (field) => {
    const result = omitProperties(field)
    if (result.other) {
      result.other = omitProperties(result.other)
    }
    return result
  })
}

function rejectUndefinedElements(arr) {
  return arr.filter((e) => e)
}

function rejectUndefinedProperties(obj) {
  return pickBy(obj, (e) => e)
}
