import React from 'react'
import NotificationManager from 'lib/notifications'
import { useGlobalLoader, useConfirm } from 'hooks'
import { LabelTypes, VendorStatusTransitions } from 'constants/index'
import { useUpdateClientVendor } from 'apis'

export default function useArchive(vendorId) {
  const { mutateAsync: updateClientVendor } = useUpdateClientVendor()
  const openConfirm = useConfirm()
  const [showLoader, hideLoader] = useGlobalLoader()

  const updateVendorStatus = React.useCallback(
    async (status, successMessage) => {
      const loaderId = showLoader()
      try {
        await updateClientVendor({
          id: vendorId,
          data: {
            vendor: { _state: status },
          },
        })
        NotificationManager.success(successMessage)
      } catch (error) {
        NotificationManager.error()
      }
      hideLoader(loaderId)
    },
    [vendorId, showLoader, hideLoader],
  )

  const onArchive = React.useCallback(() => {
    openConfirm({
      title: `Archive this ${LabelTypes.VENDOR.toLowerCase()}?`,
      body: 'This will remove it from most views, lists, and reports.', // need copy check
      confirmText: 'Yes, archive it',
      cancelText: 'Nevermind',
      onConfirm: () => {
        updateVendorStatus(
          VendorStatusTransitions.ARCHIVE,
          'This vendor has been successfully archived.',
        )
      },
    })
  }, [openConfirm, updateVendorStatus])

  const onUnarchive = React.useCallback(() => {
    openConfirm({
      title: `Re-activate this ${LabelTypes.VENDOR.toLowerCase()}?`,
      body: 'This will re-enable its access and visibility across your account.', // need copy check
      confirmText: 'Yes, activate it',
      cancelText: 'Nevermind',
      onConfirm: () => {
        updateVendorStatus(
          VendorStatusTransitions.UNARCHIVE,
          'This vendor has been successfully re-activated.',
        )
      },
    })
  }, [openConfirm])

  return [onArchive, onUnarchive]
}
