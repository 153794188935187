import { css } from 'styled-components'
import sizes from './sizes'

const breakpoints = {
  tablet: sizes.TABLET_MAX_WIDTH,
  mobile: sizes.MOBILE_MAX_WIDTH,
}

const breakPointMedias = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${breakpoints[label]}px) {
      ${css(...args)};
    }
  `
  acc[`n${label}`] = (...args) => css`
    @media (min-width: ${breakpoints[label] + 1}px) {
      ${css(...args)};
    }
  `
  return acc
}, {})

export default {
  breakpoints,
  desktop: breakPointMedias.ntablet,
  ...breakPointMedias,
}
