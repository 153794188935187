import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import addYears from 'date-fns/addYears'
import Well from 'components-v2/atoms/Well'
import FormField from 'components-v2/molecules/FormField'
import FormDialog from 'components-v2/organisms/FormDialog'

const formConfig = {
  validationSchema: yup.object().shape({
    date: yup
      .date()
      .nullable()
      .required('This is required')
      .min(new Date(), 'This must be in the future'),
  }),
  initialValues: {
    date: addYears(new Date(), 1),
  },
}

const dateControlProps = {
  fullWidth: true,
  minDateTime: new Date(),
}

const NextAssessmentReminderModal = (props) => (
  <FormDialog
    {...props}
    title="Next Assessment Reminder"
    submitButtonLabel="Save"
    formConfig={formConfig}
  >
    <Well size="small">
      Please set a date for the next assessment of {props.vendorName}
    </Well>
    <FormField
      name="date"
      label="Next Assessment Date"
      type={FormField.types.DATE}
      controlProps={dateControlProps}
      placeholder="Select a date"
      horizontal
    />
  </FormDialog>
)

NextAssessmentReminderModal.propTypes = {
  vendorName: PropTypes.string.isRequired,
}

export default NextAssessmentReminderModal
