import React from 'react'
import PropTypes from 'prop-types'
import { AssessmentTemplateStatusTypes } from 'constants/index'
import { StyledLabel as Label } from './styles'

const StatusLabel = ({ status, ...rest }) => {
  const title = status.titleize()
  switch (status) {
    case AssessmentTemplateStatusTypes.IN_PROCESS:
      return (
        <Label {...rest} color="warning">
          <i className="fa fa-lock"></i> {title}
        </Label>
      )
    case AssessmentTemplateStatusTypes.ARCHIVED:
      return (
        <Label {...rest} color="danger">
          <i className="fa fa-lock"></i> {title}
        </Label>
      )
    case AssessmentTemplateStatusTypes.READY:
      return (
        <Label {...rest} color="success">
          <i className="fa fa-lock"></i> {title}
        </Label>
      )
    default:
      return (
        <Label {...rest} color="default">
          {title}
        </Label>
      )
  }
}

StatusLabel.propTypes = {
  status: PropTypes.string.isRequired,
}

export default StatusLabel
