import styled from 'styled-components'
import IconButton from 'components-v2/atoms/IconButton'
import { colors } from 'styles'

export const TableWrapper = styled.div`
  margin-top: 28px;
`

export const Warning = styled.span`
  color: ${colors.DANGER};
  white-space: nowrap;
`

export const ReminderSpot = styled.i`
  color: ${colors.MED_GREY};
`

export const WarningSpot = styled.i`
  color: ${colors.DANGER};
`

export const WarningGroup = styled.div`
  color: ${colors.DANGER};
`

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  white-space: nowrap;
`

export const EllipsisIconButton = styled(IconButton).attrs({
  icon: 'fa fa-ellipsis-h',
})``
