import { useCallback } from 'react'
import isEqual from 'lodash/isEqual'
import pickBy from 'lodash/pickBy'
import { useModal, useGlobalLoader, useConfirm } from 'hooks'
import NotificationManager from 'lib/notifications'
import {
  useUpdateUser,
  useSetUserAPIEnabled,
  useUpdateUserOrgRole,
  useRemoveUserFromOrganization,
} from 'apis'
import { UserOrgRoles } from 'utils/roles'
import { VendorStatusTypes } from 'constants/index'
import EditUserModal from './EditUserModal'
import { parseUserVendorRoles } from './helpers'

export default (vendors, currentUserId, permittedOrgRoles, isClient) => {
  const [openModal, closeModal] = useModal(EditUserModal)
  const [showLoader, hideLoader] = useGlobalLoader()
  const confirm = useConfirm()
  const { mutateAsync: updateUser } = useUpdateUser()
  const { mutateAsync: setUserAPIEnabled } = useSetUserAPIEnabled()
  const { mutateAsync: updateUserOrgRole } = useUpdateUserOrgRole()
  const { mutateAsync: removeUserFromOrganization } =
    useRemoveUserFromOrganization()

  return useCallback(
    (user) => {
      const initialValues = {
        ...user,
        groups: user.groups.map(({ name }) => name),
        vendor_roles: user.vendor_roles || { all: true },
      }
      const onSubmit = async (formData) => {
        const loaderId = showLoader()
        try {
          // update groups
          if (!isEqual(initialValues.groups, formData.groups)) {
            await updateUser({
              id: user.id,
              data: {
                user: {
                  groups: formData.groups,
                  org_setting: true,
                },
              },
            })
          }
          // update email_notifications
          if (
            initialValues.email_notifications !== formData.email_notifications
          ) {
            await updateUser({
              id: user.id,
              data: {
                user: {
                  email_notifications: formData.email_notifications,
                  org_setting: true,
                },
              },
            })
          }
          // udpate api_enabled
          if (initialValues.api_enabled !== formData.api_enabled) {
            await setUserAPIEnabled({
              id: user.id,
              data: {
                user: {
                  api_enabled: formData.api_enabled,
                },
              },
            })
          }
          // update org_role & vendor_roles
          if (
            initialValues.org_role !== formData.org_role ||
            !isEqual(initialValues.vendor_roles, formData.vendor_roles)
          ) {
            // may only happen in dev/test, but if a user has a vendorId configured that
            // doesn't actually exist anymore, clean that up
            const vendorIds = vendors?.map((e) => e.id)
            const filteredVendorRoles = formData.vendor_roles.all
              ? formData.vendor_roles
              : pickBy(formData.vendor_roles, (_, vendorId) =>
                  vendorIds?.includes(vendorId),
                )
            const userData = {
              org_role: formData.org_role,
              vendor_roles: parseUserVendorRoles(
                UserOrgRoles[formData.org_role].allows_vendors
                  ? filteredVendorRoles
                  : undefined,
                formData.org_role,
              ),
            }
            await updateUserOrgRole({
              id: user.id,
              data: { user: userData },
            })
          }
          NotificationManager.success('The user has been successfully updated.')
          closeModal()
        } catch (error) {
          NotificationManager.error()
        }
        hideLoader(loaderId)
      }
      const onRemove = async () => {
        const confirmed = await confirm(
          {
            title: 'Removing User',
            body: 'Are you sure you want to remove the user from this organization?',
          },
          true,
        )
        if (!confirmed) {
          return
        }
        const loaderId = showLoader()
        try {
          await removeUserFromOrganization({ id: user.id })
          NotificationManager.success('The user has been successfully deleted.')
          closeModal()
        } catch (error) {
          NotificationManager.error()
        }
        hideLoader(loaderId)
      }
      const userVendors = Object.keys(user.vendor_roles)
      openModal({
        initialValues,
        user,
        vendors: vendors?.filter(
          (v) =>
            v.status === VendorStatusTypes.ACTIVE || userVendors.includes(v.id),
        ),
        currentUserId,
        permittedOrgRoles,
        isClient,
        onSubmit,
        onRemove,
      })
    },
    [
      vendors,
      openModal,
      closeModal,
      currentUserId,
      permittedOrgRoles,
      isClient,
      updateUser,
      setUserAPIEnabled,
      updateUserOrgRole,
      removeUserFromOrganization,
      showLoader,
      hideLoader,
      confirm,
    ],
  )
}
