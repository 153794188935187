import { useCallback } from 'react'
import { useModal, useGlobalLoader } from 'hooks'
import NotificationManager from 'lib/notifications'
import { useUpdateCurrentOrganization } from 'apis'
import ReplyToEmailFormModal from './ReplyToEmailFormModal'

export default function useUpdateBccEmail(organization) {
  const [openModal, closeModal] = useModal(ReplyToEmailFormModal)
  const [showLoader, hideLoader] = useGlobalLoader()
  const { mutateAsync: updateCurrentOrganization } =
    useUpdateCurrentOrganization()
  const {
    default_reply_name: replyName,
    default_reply_email: replyEmail,
    id: organizationId,
  } = organization

  return useCallback(() => {
    const onSubmit = async (formData) => {
      const loaderId = showLoader()
      try {
        await updateCurrentOrganization({
          data: {
            id: organizationId,
            organization: formData,
          },
        })
        closeModal()
      } catch (error) {
        if (error.response.status === 422) {
          // Example response
          // { message: ["Bcc email only allows valid emails"] }
          NotificationManager.error(error.response?.data?.message)
        } else {
          NotificationManager.error()
        }
      }
      hideLoader(loaderId)
    }
    openModal({
      default_reply_name: replyName,
      default_reply_email: replyEmail,
      onSubmit,
    })
  }, [
    openModal,
    organizationId,
    replyEmail,
    replyName,
    updateCurrentOrganization,
    showLoader,
    hideLoader,
  ])
}
