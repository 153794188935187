import ColumnMap from 'lib/column_map'

// NOTE: Do NOT change the order below; it's unrelated
// to display ordering, but user preference indexing
// relies on it staying frozen. Any new columns can
// simply be added to the end.
export const COL_NAMES = new ColumnMap({
  GUID: 'guid',
  NAME: 'name',
  INDUSTRY: 'industry',
  RATING_DATE: 'rating_date',
  RATING: 'rating',
  RATING_DETAILS_DATA_BREACHES_GRADE: 'rating_details.data_breaches.grade',
  RATING_DETAILS_BOTNET_INFECTIONS_GRADE:
    'rating_details.botnet_infections.grade',
  RATING_DETAILS_INSECURE_SYSTEMS_GRADE:
    'rating_details.insecure_systems.grade',
  RATING_DETAILS_SPAM_PROPAGATION_GRADE:
    'rating_details.spam_propagation.grade',
  RATING_DETAILS_DESKTOP_SOFTWARE_GRADE:
    'rating_details.desktop_software.grade',
  RATING_DETAILS_SSL_CONFIGURATIONS_GRADE:
    'rating_details.ssl_configurations.grade',
  RATING_DETAILS_MOBILE_SOFTWARE_GRADE: 'rating_details.mobile_software.grade',
  RATING_DETAILS_DNSSEC_GRADE: 'rating_details.dnssec.grade',
  RATING_DETAILS_MOBILE_APPLICATION_SECURITY_GRADE:
    'rating_details.mobile_application_security.grade',
  RATING_DETAILS_SSL_CERTIFICATES_GRADE:
    'rating_details.ssl_certificates.grade',
  RATING_DETAILS_SERVER_SOFTWARE_GRADE: 'rating_details.server_software.grade',
  RATING_DETAILS_PATCHING_CADENCE_GRADE:
    'rating_details.patching_cadence.grade',
  RATING_DETAILS_POTENTIALLY_EXPLOITED_GRADE:
    'rating_details.potentially_exploited.grade',
  RATING_DETAILS_OPEN_PORTS_GRADE: 'rating_details.open_ports.grade',
  RATING_DETAILS_APPLICATION_SECURITY_GRADE:
    'rating_details.application_security.grade',
  RATING_DETAILS_MALWARE_SERVERS_GRADE: 'rating_details.malware_servers.grade',
  RATING_DETAILS_FILE_SHARING_GRADE: 'rating_details.file_sharing.grade',
  RATING_DETAILS_UNSOLICITED_COMM_GRADE:
    'rating_details.unsolicited_comm.grade',
  RATING_DETAILS_DKIM_GRADE: 'rating_details.dkim.grade',
  RATING_DETAILS_SPF_GRADE: 'rating_details.spf.grade',
  PRIVVA_ISSUES: 'privva_issues',
})
