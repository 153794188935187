import React from 'react'
import PropTypes from 'prop-types'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import merge from 'lodash/merge'
import pick from 'lodash/pick'
import { useModal } from 'hooks'
import { debug } from 'utils/debug'
import { useClientClientSourceContractFetcher } from 'apis'
import View from './View'
import AutoReportFormModal from './AutoReportFormModal'
import { formatText } from '../utils'

export const ATTRIBUTES_LIST = ['name', 'body']

const AutoReportSection = ({
  currentAutomation,
  currentSource,
  mode,
  // container,
  extras,
  setCurrentSource,
  onOpenSourceForm,
  onLargeTextViewClick,
  onResetSource,
  onClearSource,
}) => {
  const [openFormModal, closeFormModal] = useModal(AutoReportFormModal)

  // STATE HANDLING
  const [clause, setClause] = React.useState(currentSource)
  React.useEffect(() => {
    setClause(currentSource)
  }, [currentSource])

  const [contractId, setContractId] = React.useState(
    clause.client_source_contract_id,
  )
  React.useEffect(() => {
    if (clause.client_source_contract_id)
      setContractId(clause.client_source_contract_id)
  }, [clause])

  const contractList = extras.clientSourceContracts ?? []
  React.useEffect(() => {
    // FIXME: why are the state's clientSourceContracts not being passed in directly as a prop?
    const list = extras.clientSourceContracts
    if (list.length === 1) {
      // if there's only one source contract, might as
      // go ahead and pre-load its clause list
      setContractId(list[0].id)
    }
  }, [extras.clientSourceContracts])

  const [sourceMode, setSourceMode] = React.useState()
  React.useEffect(() => {
    setSourceMode(mode === 'display' ? 'display' : 'select')
  }, [mode])

  // misc functions

  const getSourceContractOptions = () =>
    map(contractList, (csc) => ({ label: csc.name, value: csc.id }))

  const getClauseListOptions = () =>
    map(clauseList, (item) => ({ label: item.name, value: item.id }))

  const { data: sourceContract } = useClientClientSourceContractFetcher(
    { id: contractId },
    { enabled: !!contractId },
  )
  const clauseList = React.useMemo(
    () => keyBy(sourceContract?.client_contract_clauses, 'id'),
    [sourceContract],
  )

  const handleContractSelect = (newContractId) => {
    if (newContractId !== contractId) {
      setContractId(newContractId)
      setClause({})
    }
  }

  const applyClause = (clauseId) => {
    const tmp = clauseList[clauseId] || {}
    setCurrentSource(tmp)
  }

  const handleFormModalUpdate = (formData, srcMode) => {
    debug('current sourceMode:', sourceMode)
    debug('formData to apply:', formData)
    debug('incoming srcMode:', srcMode)
    let newClause // = {name: '', body: ''};
    if (['add', 'copy'].includes(srcMode)) {
      newClause = formData
      if (srcMode === 'copy') {
        // if we're copying an existing clause, probably want to keep
        // it in the same (current) source contract group
        // eslint-disable-next-line camelcase
        newClause.client_source_contract_id = contractId
      }
    } else if (srcMode === 'edit') {
      newClause = merge(clause, formData)
    }
    debug('current clause:', clause)
    debug('newClause:', newClause)
    setCurrentSource(newClause)
    setSourceMode(srcMode)
  }

  const triggerSourceForm = (source, srcMode) => {
    onOpenSourceForm({
      openFormModal,
      closeFormModal,
      label: 'AutoReport Section',
      updateCallback: handleFormModalUpdate,
      submitSourceMode: srcMode,
      source,
    })
  }

  const handleSourceCreate = () => {
    triggerSourceForm({}, 'add')
  }

  const handleSourceEdit = () => {
    triggerSourceForm(clause, 'edit')
  }

  const handleSourceCopy = () => {
    const source = pick(clause, ATTRIBUTES_LIST)
    source.name += ' (copy)'
    triggerSourceForm(source, 'copy')
  }

  const openBodyDisplayModal = () => {
    onLargeTextViewClick(
      clause.name,
      clause.body.length < 150 ? clause.body : formatText(clause.body),
    )
  }

  const handleSourceReset = () => {
    onResetSource()
    setSourceMode('select')
  }

  // render

  return (
    <View
      mode={mode}
      sourceMode={sourceMode}
      currentAutomation={currentAutomation}
      currentSource={currentSource}
      contractId={contractId}
      clause={clause}
      contractList={contractList}
      clauseList={clauseList}
      handleSourceCreate={handleSourceCreate}
      getSourceContractOptions={getSourceContractOptions}
      handleContractSelect={handleContractSelect}
      getClauseListOptions={getClauseListOptions}
      applyClause={applyClause}
      handleSourceEdit={handleSourceEdit}
      handleSourceCopy={handleSourceCopy}
      handleSourceReset={handleSourceReset}
      handleSourceClear={onClearSource}
      openBodyDisplayModal={openBodyDisplayModal}
    />
  )
}

AutoReportSection.propTypes = {
  currentAutomation: PropTypes.object.isRequired,
  currentSource: PropTypes.object,
  mode: PropTypes.string.isRequired,
  // container: PropTypes.object,
  extras: PropTypes.object,
  setCurrentSource: PropTypes.func.isRequired,
  onOpenSourceForm: PropTypes.func.isRequired,
  onLargeTextViewClick: PropTypes.func.isRequired,
  onResetSource: PropTypes.func.isRequired,
  onClearSource: PropTypes.func.isRequired,
}

export default AutoReportSection
