import React from 'react'
import PropTypes from 'prop-types'
import keyBy from 'lodash/keyBy'
import get from 'lodash/get'
import Popover from 'components-v2/molecules/Popover'
import { RatingLabelSetOptions } from 'constants/index'
import { RatingLabel, PopoverButton, PanelContent } from './styles'
import EditRatingLabelForm from './EditRatingLabelForm'

const RatingLabelSetsObj = keyBy(RatingLabelSetOptions, 'value')

const RatingLabelSetLink = ({ disabled, value, onSave }) => {
  const name = get(RatingLabelSetsObj, [value, 'label'])
  if (disabled) {
    return <RatingLabel $isEmpty={!value}>{name || 'empty'}</RatingLabel>
  }
  return (
    <Popover>
      <Popover.Button
        as={PopoverButton}
        $isEmpty={!value}
        data-test-id="rating-label-set-button"
      >
        {name || 'empty'}
      </Popover.Button>
      <Popover.Panel title="Choose Rating Type" arrow>
        {({ close }) => (
          <PanelContent>
            <EditRatingLabelForm
              value={value}
              onSave={onSave}
              onClose={close}
            />
          </PanelContent>
        )}
      </Popover.Panel>
    </Popover>
  )
}

RatingLabelSetLink.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onSave: PropTypes.func,
}

RatingLabelSetLink.defaultProps = {
  value: undefined,
  disabled: false,
}

export default RatingLabelSetLink
