import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components-v2/atoms/Button'
import Textarea from 'components-v2/atoms/Textarea'
import { Footer } from '../styles'

const EditSectionPopoverForm = ({ element, onSave, onClose, ...rest }) => {
  const [label, setLabel] = React.useState(element.label)

  const handleLabelChange = React.useCallback((event) => {
    setLabel(event.target.value)
  }, [])

  const handleSave = React.useCallback(async () => {
    try {
      await onSave(element.id, { label })
      onClose()
      // eslint-disable-next-line no-empty
    } catch {}
  }, [label, onSave, onClose, element])

  return (
    <div {...rest}>
      <Textarea name="label" value={label} onChange={handleLabelChange} />
      <Footer>
        <Button onClick={handleSave}>Update</Button>
      </Footer>
    </div>
  )
}

EditSectionPopoverForm.propTypes = {
  element: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default EditSectionPopoverForm
