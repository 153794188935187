import styled from 'styled-components'
import Popover from 'components-v2/molecules/Popover'

export const Footer = styled.div`
  margin-top: 10px;
  text-align: right;
`

export const PanelContent = styled.div`
  padding: 20px;
  width: 200px;
`

export const PopoverPanel = styled(Popover.Panel)`
  left: 50% !important;
  transform: translateX(-50%) !important;
`
