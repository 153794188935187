import React from 'react'
import PropTypes from 'prop-types'
import RDU from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import config from 'config'
import Layout from './Layout'
import Input from './Input'

const DropzoneUploaderRef = React.forwardRef((props, ref) => (
  <RDU ref={ref} LayoutComponent={Layout} InputComponent={Input} {...props} />
))

DropzoneUploaderRef.propTypes = {
  accept: PropTypes.string,
  maxFiles: PropTypes.number,
  maxSizeBytes: PropTypes.number,
  disabled: PropTypes.bool,
}

DropzoneUploaderRef.defaultProps = config.uploadLimits

export default DropzoneUploaderRef
