import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import FormField from 'components-v2/molecules/FormField'
import FormDialog from 'components-v2/organisms/FormDialog'

const validationSchema = yup.object().shape({
  default_reply_name: yup
    .string()
    .label('This')
    .nullable()
    .max(64, '64 character maximum length')
    .matches(/^[^<>]+$/, 'valid characters only'),
  default_reply_email: yup.string().label('This').nullable().email().required(),
})

const ReplyToEmailFormModal = ({
  default_reply_name: name,
  default_reply_email: email,
  ...rest
}) => {
  const formConfig = React.useMemo(
    () => ({
      validationSchema,
      initialValues: {
        default_reply_name: name,
        default_reply_email: email,
      },
    }),
    [name, email],
  )
  return (
    <FormDialog {...rest} title="Reply-To Email" formConfig={formConfig}>
      <FormField name="default_reply_name" label="From Name" />
      <FormField name="default_reply_email" label="From Email Address" />
    </FormDialog>
  )
}

ReplyToEmailFormModal.propTypes = {
  default_reply_name: PropTypes.string,
  default_reply_email: PropTypes.string,
}

export default ReplyToEmailFormModal
