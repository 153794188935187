import styled from 'styled-components'
import Icon from 'components-v2/atoms/Icon'
import { colors } from 'styles'

export const NoDataText = styled.p`
  font-style: italic;
  color: ${colors.MED_GREY};
`

export const ListItemWrapper = styled.div`
  width: 100%;
  padding: 16px 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  border-bottom: 1px solid #e5e5e5;
  @media (max-width: 400px) {
    flex-direction: column;
    align-items: start;
  }
`
export const ListItemIcon = styled(Icon).attrs({
  icon: 'fa fa-file',
})`
  align-self: baseline;
  margin-top: 4px;
  @media (max-width: 400px) {
    display: none;
  }
`

export const ListItemMain = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

export const ListItemTitle = styled.div`
  font-weight: 420;
  color: #000;
`

export const ListItemDescription = styled.p`
  margin: 0;
  font-size: 12px;
  color: #000;
`

export const ListItemDate = styled.span`
  font-size: 12px;
  color: ${colors.MED_GREY};
`
