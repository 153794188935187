import styled, { css } from 'styled-components'
import Icon from 'components-v2/atoms/Icon'
import { EyeSlash } from '@styled-icons/fa-solid/EyeSlash'
import { Comment } from '@styled-icons/fa-solid/Comment'
import { mixins } from 'styles'

export const Row = styled.div`
  display: flex;
  page-break-inside: avoid;
  ${(props) =>
    props.skipped &&
    css`
      opacity: 0.75;

      body:not(.include-skipped-questions-on-print) & {
        ${mixins.hidePrint}
      }
    `}

  > * {
    padding: 8px;
  }
`

export const KeyColumn = styled.div`
  flex: none;
  width: ${(p) => (p.customKey ? 110 : 52)}px;
`

export const QuestonColumn = styled.div`
  flex: 1;
  min-width: 0;
  overflow-wrap: break-word;
`

export const QuestionLabel = styled.div`
  font-weight: bold;
`

export const CommentWrapper = styled.div`
  white-space: pre-wrap;
  padding-left: 22px;
  position: relative;

  > svg {
    position: absolute;
    left: 0;
    top: 3px;
  }
`

export const ReviewScoreColumn = styled.div`
  flex: none;
  width: 120px;
`

export const ActionColumn = styled.div`
  flex: none;
  width: 240px;

  body.hide-comments-on-print & {
    ${mixins.hidePrint}
  }
`

export const ActionWrapper = styled.div`
  padding-bottom: 8px;
  padding-left: 6px;
  overflow-wrap: break-word;
`

export const CommentIcon = styled(Icon).attrs({ icon: Comment })`
  color: ${(props) => (props.isReviewed ? '#888' : '#333')};
`
export const EyeSlashIcon = styled(Icon).attrs({ icon: EyeSlash })``
