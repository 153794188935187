import styled from 'styled-components'
import Icon from 'components-v2/atoms/Icon'
import AssessmentScoreSheetHeader from 'components/shared/AssessmentScoreSheetHeader'
import { mixins } from 'styles'

export const theme = {
  hGutter: 25,
  vGutter: 24,
}

export const StatusSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > *:not(:last-child) {
    margin-bottom: 8px;
  }
`

export const StatusLabel = styled.div`
  margin-top: -4px;
  display: inline-flex;
  align-items: center;
  height: 51px;
  border-radius: 41px;
  background: ${({ color }) => color};
  padding: 0 30px 0 9px;
  color: #fff;
  font-size: 18px;
`

export const StatusIcon = styled(Icon)`
  margin-right: 17px;
  font-size: 32.5px;
`

export const SubmittedByClient = styled.div`
  width: 160px;
  text-align: center;
`

export const Comment = styled.div`
  font-size: 18px;
  color: #8a96a0;
`

export const MonitoringDataCard = styled(AssessmentScoreSheetHeader.Card)`
  body:not(.include-monitoring-data-on-print) & {
    ${mixins.hidePrint}
  }
`
