import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import FormField from 'components-v2/molecules/FormField'
import FormDialog from 'components-v2/organisms/FormDialog'
import { exportClientAssessmentsToCSV } from 'lib/export'
import { LabelTypes, AssessmentStatusTypes } from 'constants/index'

const columnOptions = [
  { value: 'vendor.name', label: LabelTypes.VENDOR },
  { value: 'assessment_template.name', label: 'Template' },
  { value: 'sent_at', label: 'Sent' },
  { value: 'submit_at', label: 'Submitted' },
  { value: 'reviewed_at', label: 'Reviewed' },
  { value: 'expired_at', label: 'Expired' },
  { value: 'due_at', label: 'Due' },
  { value: 'vendor.next_reassessment_at', label: 'Next Assessment' },
  { value: 'status', label: 'status' },
  { value: 'risk_rating', label: 'Rating' },
  { value: 'grade', label: 'Grade' },
]

const statusFilterVals = [
  AssessmentStatusTypes.INVITED,
  AssessmentStatusTypes.IN_PROCESS,
  AssessmentStatusTypes.SUBMITTED,
  AssessmentStatusTypes.REVIEWED,
  AssessmentStatusTypes.EXPIRED,
]
const statusFilterOptions = statusFilterVals.map((value) => ({
  value,
  label: value.titleize(),
}))

const formConfig = {
  validationSchema: yup.object().shape({
    columns: yup.array().required('Please choose at least 1 column'),
    statuses: yup.array().required('Please choose at least 1 status'),
  }),
  initialValues: {
    columns: [],
    statuses: [],
  },
}

const ExportVendorAssessmentsModal = (props) => {
  const handleSubmit = React.useCallback(
    ({ columns, statuses }) => {
      exportClientAssessmentsToCSV({
        column_filter: columns,
        status: statuses.join(','),
      })
      props.onClose()
    },
    [props],
  )
  return (
    <FormDialog
      {...props}
      title="Export the following assessment details"
      formConfig={formConfig}
      onSubmit={handleSubmit}
    >
      <FormField
        name="columns"
        label="Columns"
        type={FormField.types.MULTI_CHECKBOX}
        options={columnOptions}
        selectAllVisible
        horizontal
      />
      <FormField
        name="statuses"
        label="Statuses"
        type={FormField.types.MULTI_CHECKBOX}
        options={statusFilterOptions}
        selectAllVisible
        horizontal
      />
    </FormDialog>
  )
}

ExportVendorAssessmentsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default ExportVendorAssessmentsModal
