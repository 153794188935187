import React from 'react'
import PropTypes from 'prop-types'
import isSameDay from 'date-fns/isSameDay'
import set from 'date-fns/set'
import getHours from 'date-fns/getHours'
import getMinutes from 'date-fns/getMinutes'
import getSeconds from 'date-fns/getSeconds'
import getMilliseconds from 'date-fns/getMilliseconds'
import { toDate } from 'utils/date'

// Let's support minDateTime prop
export default (WrappedComponent) => {
  const WrapperComponent = React.forwardRef(({ minDateTime, ...rest }, ref) => {
    let pickerProps = rest
    if (minDateTime) {
      pickerProps = {
        minDate: minDateTime,
        ...pickerProps,
      }
      if (
        isSameDay(
          toDate(pickerProps.value || pickerProps.openToDate) || new Date(),
          minDateTime,
        )
      ) {
        pickerProps = {
          minTime: set(new Date(), {
            hours: getHours(minDateTime),
            minutes: getMinutes(minDateTime),
            seconds: getSeconds(minDateTime),
            milliseconds: getMilliseconds(minDateTime),
          }),
          maxTime: set(new Date(), {
            hours: 23,
            minutes: 59,
            seconds: 59,
            milliseconds: 999,
          }),
          ...pickerProps,
        }
      }
    }
    return <WrappedComponent {...pickerProps} ref={ref} />
  })

  WrapperComponent.propTypes = {
    minDateTime: PropTypes.instanceOf(Date),
  }

  return WrapperComponent
}
