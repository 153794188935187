import React from 'react'
import PropTypes from 'prop-types'
import FormField from 'components-v2/molecules/FormField'
import { Col } from 'components-v2/organisms/Layout'

import {
  QuestionRow,
  QuestionContainer,
  GripLinesIcon,
  QuestionNumber,
  QuestionTextControls,
  QuestionLabel,
  QuestionIcons,
  Icon,
} from './styles'

const Question = ({
  question,
  previewMode,
  editMode,
  onEditClick,
  onDeleteClick,
}) => (
  <QuestionRow>
    <QuestionContainer
      xs={11}
      sm={10}
      smOffset={1}
      md={10}
      mdOffset={1}
      lg={9}
      lgOffset={2}
    >
      <QuestionNumber xs={1}>
        {question.order}.<br />
        {editMode && <GripLinesIcon />}
      </QuestionNumber>
      <QuestionTextControls xs={editMode ? 10 : 11}>
        <QuestionLabel> {question.question} </QuestionLabel>
        <FormField
          name={`q_${question.id}`}
          type={FormField.types.RADIO_GROUP}
          options={[
            { value: 'yes', label: 'Yes' },
            { value: 'no', label: 'No' },
          ]}
          disabled={previewMode || editMode}
          preserveHelpTextSpace={false}
        />
      </QuestionTextControls>
      {editMode && (
        <Col xs={1}>
          <QuestionIcons>
            <Icon
              className="fa fa-pencil"
              onClick={() => onEditClick(question.id)}
            />
            <Icon
              className="fa fa-trash"
              onClick={() => onDeleteClick(question.id)}
            />
          </QuestionIcons>
        </Col>
      )}
    </QuestionContainer>
  </QuestionRow>
)

Question.propTypes = {
  question: PropTypes.object.isRequired,
  previewMode: PropTypes.bool,
  editMode: PropTypes.bool,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
}

export default Question
