import React from 'react'
import PropTypes from 'prop-types'
import reject from 'lodash/reject'
import some from 'lodash/some'
import includes from 'lodash/includes'
import difference from 'lodash/difference'
import concat from 'lodash/concat'
import uniq from 'lodash/uniq'
import map from 'lodash/map'
import intersection from 'lodash/intersection'
import IconButton from 'components-v2/atoms/IconButton'
import CreatableSelectField from 'components-v2/molecules/FormField/CreatableSelectField'
import SearchableSelectField from 'components-v2/molecules/FormField/SearchableSelectField'
import { BaseField } from 'components-v2/molecules/FormField'
import Toolbar, { SearchInput } from 'components-v2/molecules/Table/Toolbar'
import useCreateVendor from 'components/client/shared/useCreateVendor'
import { usePrevious, useUpdateEffect } from 'hooks'
import { LabelTypes } from 'constants/index'
import {
  SelectedVendorsContainer,
  SelectedVendorsLabel,
  StyledTable,
} from './styles'

const getColumns = (labels, handleRemoveSelectedVendor) => [
  {
    id: 'name',
    header: LabelTypes.VENDOR,
    width: 236,
  },
  {
    id: 'vendor_tag_list',
    header: 'Label',
    cell: (info) =>
      info.getValue() ? intersection(info.getValue(), labels).join(', ') : null,
  },
  {
    id: 'df1',
    header: '',
    cell: (info) => (
      <IconButton
        icon="fa fa-trash-o"
        color="primary"
        onClick={() => handleRemoveSelectedVendor(info.row.original.id)}
      />
    ),
    width: 30,
  },
]

const VendorsSelectFormField = ({
  vendorNames,
  vendorNamesLoading,
  vendorNamesByTag,
  clientTags,
  clientTagsLoading,
  projectVendors,
  showGloborg,
  fetchVendorNamesByTag,
  onVendorCreated,
  value,
  onChange,
  ...rest
}) => {
  const [labels, setLabels] = React.useState([])
  const [fetchingVendorsByLabel, setFetchingVendorsByLabel] =
    React.useState(false)
  const [searchText, setSearchText] = React.useState('')
  const createVendor = useCreateVendor()

  const vendorNamesWithTags = React.useMemo(
    () =>
      map(vendorNames, (vendorName) => {
        const vendorTagList = Object.keys(vendorNamesByTag).filter((tag) =>
          some(vendorNamesByTag[tag], (e) => e.id === vendorName.id),
        )
        return {
          ...vendorName,
          vendor_tag_list: vendorTagList,
        }
      }),
    [vendorNames, vendorNamesByTag],
  )

  const selectedVendors = React.useMemo(
    () => vendorNamesWithTags.filter((e) => includes(value, e.id)),
    [vendorNamesWithTags, value],
  )

  const vendorSelectOptions = React.useMemo(
    () =>
      reject(vendorNamesWithTags, (e) => some(value, (v) => v === e.id)).map(
        (e) => ({ label: e.name, value: e.id }),
      ),
    [value, vendorNamesWithTags],
  )

  const handleVendorSelect = React.useCallback(
    (vendor) => {
      onChange(value ? [...value, vendor] : [vendor])
    },
    [value, onChange],
  )

  const handleVendorNewOptionClick = React.useCallback(
    (inputValue) => {
      createVendor({
        name: inputValue,
        showGloborg,
        onCreated: (newVendor) => {
          onChange(value ? [...value, newVendor.id] : [newVendor.id])
          onVendorCreated(newVendor)
        },
      })
    },
    [value, onChange, onVendorCreated, showGloborg, createVendor],
  )

  const vendorSelectControlProps = React.useMemo(
    () => ({
      placeholder: `Select or create a ${LabelTypes.VENDOR.toLowerCase()}...`,
      formatCreateLabel: (i) =>
        `Create new ${LabelTypes.VENDOR.toLowerCase()} ${i ? `"${i}"` : ''}`,
      createOptionPosition: 'first',
      isValidNewOption: (inputValue) => {
        if (!inputValue) {
          return true
        }
        return !vendorSelectOptions.some(
          (opt) => opt.label.toLowerCase() === inputValue.toLowerCase(),
        )
      },
      isLoading: vendorNamesLoading,
      onCreateOption: handleVendorNewOptionClick,
    }),
    [vendorSelectOptions, vendorNamesLoading, handleVendorNewOptionClick],
  )

  const handleRemoveSelectedVendor = React.useCallback(
    (vendorId) => {
      onChange(reject(value, (e) => e === vendorId))
    },
    [value, onChange],
  )

  const columns = React.useMemo(
    () => getColumns(labels, handleRemoveSelectedVendor),
    [labels, handleRemoveSelectedVendor],
  )

  const labelMultiSelectControlProps = React.useMemo(
    () => ({
      getOptionLabel: (e) => e.name,
      getOptionValue: (e) => e.id,
      isLoading: clientTagsLoading || fetchingVendorsByLabel,
      isMulti: true,
    }),
    [clientTagsLoading, fetchingVendorsByLabel],
  )

  const previousLabels = usePrevious(labels)
  useUpdateEffect(() => {
    if (previousLabels === labels) {
      return
    }

    const labelsToAdd = difference(labels, previousLabels)
    const labelsToRemove = difference(previousLabels, labels)

    const addPromise = Promise.all(labelsToAdd.map(fetchVendorNamesByTag)).then(
      (responses) => concat(...responses),
    )
    const removePromise = Promise.all(
      labelsToRemove.map(fetchVendorNamesByTag),
    ).then((responses) => concat(...responses))

    setFetchingVendorsByLabel(true)
    Promise.all([addPromise, removePromise])
      .then(([vendorsToAdd, vendorsToRemove]) => {
        const vendorIdsToAdd = vendorsToAdd
          .map((e) => e.id)
          .filter((id) => vendorNamesWithTags.find((e) => e.id === id))
        const vendorIdsToRemove = vendorsToRemove
          .map((e) => e.id)
          .filter((id) => {
            const vendor = vendorNamesWithTags.find((e) => e.id === id)
            if (!vendor) {
              return true
            }
            return intersection(vendor.vendor_tag_list, labels).length === 0
          })
        const newValue = uniq(
          difference(concat(value, vendorIdsToAdd), vendorIdsToRemove),
        )
        onChange(newValue)
        setFetchingVendorsByLabel(false)
      })
      .catch(() => {
        setFetchingVendorsByLabel(false)
      })
  }, [
    previousLabels,
    labels,
    value,
    onChange,
    fetchVendorNamesByTag,
    vendorNamesWithTags,
  ])

  return (
    <BaseField {...rest}>
      <CreatableSelectField
        label={`Add ${LabelTypes.VENDOR.toLowerCase()}`}
        controlProps={vendorSelectControlProps}
        options={vendorSelectOptions}
        onChange={handleVendorSelect}
        horizontal
      />
      <SearchableSelectField
        label="Bulk add by label"
        controlProps={labelMultiSelectControlProps}
        options={clientTags}
        value={labels}
        onChange={setLabels}
        horizontal
      />
      {selectedVendors.length > 0 && (
        <SelectedVendorsContainer>
          <div>
            <Toolbar>
              <SelectedVendorsLabel>
                Selected {LabelTypes.VENDORS.toLowerCase()}
              </SelectedVendorsLabel>
              <SearchInput
                value={searchText}
                onChange={setSearchText}
                placeholder="Search for a selected vendor"
              />
            </Toolbar>
            <StyledTable
              data={selectedVendors}
              columns={columns}
              enablePagination={false}
              enableSorting={false}
              globalFilter={searchText}
              onGlobalFilterChange={setSearchText}
            />
          </div>
        </SelectedVendorsContainer>
      )}
    </BaseField>
  )
}

VendorsSelectFormField.propTypes = {
  vendorNames: PropTypes.array,
  vendorNamesLoading: PropTypes.bool,
  vendorNamesByTag: PropTypes.object,
  clientTags: PropTypes.array,
  clientTagsLoading: PropTypes.bool,
  projectVendors: PropTypes.array,
  showGloborg: PropTypes.bool,
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onVendorCreated: PropTypes.func,
  fetchVendorNamesByTag: PropTypes.func.isRequired,
}

VendorsSelectFormField.defaultProps = {
  vendorNames: [],
  vendorNamesByTag: {},
  clientTags: [],
  value: [],
  onVendorCreated: () => {},
}

export default VendorsSelectFormField
