import { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useGlobalLoader, useModal } from 'hooks'
import NotificationManager from 'lib/notifications'
import {
  useUpdateClientIssue,
  useClientAssessmentSectionFetcher,
  useClientAssessmentElementsInfiniteFetcher,
} from 'apis'
import { IssueTransitionTypes } from 'constants/index'
import useCreateUpdateIssue from 'components/client/shared/useCreateUpdateIssue'
import { useSetElementResponse } from './useAssessmentElementsFetcher'
import IssueListModal from './IssueListModal'

export default function useCRUDIssue(assessment) {
  const queryClient = useQueryClient()
  const [showLoader, hideLoader] = useGlobalLoader()
  const [openIssueListModal, closeIssueListModal] = useModal(IssueListModal)
  const { createResponseIssue } = useCreateUpdateIssue()
  const setElementResponse = useSetElementResponse()
  const { mutateAsync: updateClientIssue } = useUpdateClientIssue()

  const createIssue = useCallback(
    (responseId) => {
      createResponseIssue({
        vendorId: assessment.vendor_id,
        responseId,
        onIssueCreated: (issue) => {
          setElementResponse(responseId, (current) => ({
            ...current,
            issues: [issue, ...current.issues],
          }))
        },
      })
    },
    [assessment, createResponseIssue, setElementResponse],
  )

  const sendIssue = useCallback(
    (responseId, issueId) => {
      const loaderId = showLoader()
      updateClientIssue({
        id: issueId,
        data: {
          issue: { transition: IssueTransitionTypes.OPEN },
        },
      })
        .then((data) => {
          setElementResponse(responseId, (current) => ({
            ...current,
            issues: current.issues.map((e) => (e.id === issueId ? data : e)),
          }))
          NotificationManager.success('An issue has been successfully sent.')
        })
        .catch(() => NotificationManager.error())
        .then(() => hideLoader(loaderId))
    },
    [showLoader, hideLoader, updateClientIssue, setElementResponse],
  )

  const viewIssues = useCallback(() => {
    let isReloadElementsRequired = false
    openIssueListModal({
      assessment,
      onIssuesTransitioned: () => {
        isReloadElementsRequired = true
      },
      onClose: () => {
        closeIssueListModal()
        if (isReloadElementsRequired) {
          queryClient.invalidateQueries(
            useClientAssessmentSectionFetcher.queryKey(),
          )
          queryClient.invalidateQueries(
            useClientAssessmentElementsInfiniteFetcher.queryKey(),
          )
        }
      },
    })
  }, [queryClient, assessment, openIssueListModal, closeIssueListModal])

  return {
    createIssue,
    sendIssue,
    viewIssues,
  }
}
