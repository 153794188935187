import React from 'react'
import Table from 'components-v2/molecules/Table'
import { dateFormatterFactory } from 'lib/table'
import {
  issueDueDateFormatterFactory,
  issuePriorityFormatterFactory,
  getIssueResolutionLabel,
  getIssueStatusLabel,
} from 'lib/issue'
import { IssueStatusTypes } from 'constants/index'

const dateFormatter = dateFormatterFactory()
const dueDateFormatter = issueDueDateFormatterFactory()
const priorityFormatter = issuePriorityFormatterFactory()

const issueTableColumns = [
  {
    id: 'summary',
    header: 'Issue',
    cell: (info) => <a href={info.row.original.path}>{info.getValue()}</a>,
    sortingFn: 'text',
    width: 350,
  },
  {
    id: 'created_at',
    header: 'Created',
    cell: dateFormatter,
    width: 120,
  },
  {
    id: 'due_at',
    header: 'Due',
    cell: dueDateFormatter,
    width: 100,
  },
  {
    id: 'priority',
    header: 'Priority',
    cell: priorityFormatter,
    width: 120,
  },
  {
    id: 'status',
    header: 'Status',
    cell: (info) => {
      const status = getIssueStatusLabel(info.getValue(), false)
      if (
        [IssueStatusTypes.CLOSED, IssueStatusTypes.ARCHIVED].includes(
          info.getValue(),
        ) &&
        info.row.original.resolution
      ) {
        return (
          <div>
            <div>{status}</div>
            <div className="small">
              {getIssueResolutionLabel(info.row.original.resolution)}
            </div>
          </div>
        )
      }
      return status
    },
    enableSorting: false,
    width: 120,
  },
]

export default (props) => (
  <Table
    {...props}
    columns={issueTableColumns}
    type={Table.types.ALT}
    enablePagination={false}
  />
)
