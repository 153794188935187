import styled from 'styled-components'
import { ResponsiveContainer } from 'recharts'
import { mixins } from 'styles'

export const Container = styled(ResponsiveContainer)`
  .recharts-cartesian-axis-tick-value {
    font-size: 12px;
    fill: currentColor;
  }

  .recharts-label {
    ${mixins.smallHeader}
    font-size: 11px;
    fill: currentColor;
  }
`
