import React, { useState, useRef, useCallback, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { useCurrent } from 'hooks'

// Disabled by default
export default function withSticky(WrappedComponent) {
  const StyledWrappedComponent = styled(WrappedComponent)`
    position: relative;

    thead {
      display: block;
      overflow: hidden;
    }

    thead tr,
    tbody {
      width: 100%;
      display: table;
      table-layout: fixed;
    }

    ${(props) =>
      props.$isSticky &&
      css`
        &:before {
          position: fixed;
          display: block;
          content: '';
          top: ${props.$topOffset - props.$aboveStickyWhiteSpace}px;
          height: ${props.$aboveStickyWhiteSpace + 7}px;
          width: ${props.$width}px;
          background: ${props.$aboveStickyWhiteSpaceColor};
          z-index: 100;
        }

        .table-responsive {
          padding-top: ${props.$headerHeight}px;
        }

        thead {
          position: fixed;
          margin-left: -1px;
          top: ${props.$topOffset}px;
          width: ${props.$width}px;
          border: 1px solid #d7d7d7;
          border-bottom: none;
          border-top-left-radius: 7px;
          border-top-right-radius: 7px;
          z-index: 100;
        }

        // Fix for IE11 and 10 (Edge works well):
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          &:before {
            height: ${props.$aboveStickyWhiteSpace}px;
          }
          .table-responsive {
            transform: translate3d(
              0,
              0,
              0
            ); // https://stackoverflow.com/questions/12463658/parent-child-with-position-fixed-parent-overflowhidden-bug#answer-38268846
          }
          thead {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
        }
      `}
  `

  const WrapperComponent = ({
    enableSticky,
    topOffset,
    aboveStickyWhiteSpace,
    aboveStickyWhiteSpaceColor,
    ...rest
  }) => {
    const [isSticky, setIsSticky] = useState(false)
    const [headerHeight, setHeaderHeight] = useState(0)
    const [width, setWidth] = useState(0)
    const ref = useRef()
    const topOffsetRef = useCurrent(topOffset)
    const headerHeightRef = useCurrent(headerHeight)

    const syncHorizontalScroll = useCallback(() => {
      if (ref.current) {
        ref.current.getElementsByTagName('thead')[0].scrollLeft =
          ref.current.getElementsByClassName('table-responsive')[0].scrollLeft
      }
    }, [])

    useLayoutEffect(() => {
      if (!enableSticky) {
        return undefined
      }

      const checkSticky = () => {
        if (ref.current) {
          const boundingClientRect = ref.current
            .getElementsByClassName('table-responsive')[0]
            .getBoundingClientRect()
          const clientTopOffset = boundingClientRect.top
          const clientBottomOffset = boundingClientRect.bottom
          const newIsSticky =
            clientTopOffset < topOffsetRef.current &&
            topOffsetRef.current + headerHeightRef.current < clientBottomOffset
          setIsSticky(newIsSticky)
        }
      }

      const checkSizes = () => {
        if (ref.current) {
          setHeaderHeight(
            ref.current.getElementsByTagName('thead')[0].offsetHeight,
          )
          setWidth(
            ref.current
              .getElementsByClassName('table-responsive')[0]
              .getBoundingClientRect().width,
          )
        }
      }

      checkSticky()
      checkSizes()
      window.addEventListener('scroll', checkSticky)
      window.addEventListener('scroll', syncHorizontalScroll, true)
      window.addEventListener('resize', checkSizes)

      return () => {
        window.removeEventListener('scroll', checkSticky)
        window.removeEventListener('scroll', syncHorizontalScroll)
        window.removeEventListener('resize', checkSizes)
      }
    }, [enableSticky, syncHorizontalScroll])

    useLayoutEffect(() => {
      if (enableSticky) {
        syncHorizontalScroll()
      }
    }, [enableSticky, isSticky, syncHorizontalScroll])

    if (!enableSticky) {
      return <WrappedComponent {...rest} />
    }

    return (
      <StyledWrappedComponent
        {...rest}
        wrapperRef={ref}
        $isSticky={isSticky}
        $headerHeight={headerHeight}
        $width={width}
        $topOffset={topOffset}
        $aboveStickyWhiteSpace={aboveStickyWhiteSpace}
        $aboveStickyWhiteSpaceColor={aboveStickyWhiteSpaceColor}
      />
    )
  }

  WrapperComponent.propTypes = {
    enableSticky: PropTypes.bool,
    topOffset: PropTypes.number,
    aboveStickyWhiteSpace: PropTypes.number,
    aboveStickyWhiteSpaceColor: PropTypes.string,
  }

  WrapperComponent.defaultProps = {
    enableSticky: false,
    topOffset: 0,
    aboveStickyWhiteSpace: 0,
    aboveStickyWhiteSpaceColor: '#fff',
  }

  return WrapperComponent
}
