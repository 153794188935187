import React from 'react'
import { compose, withAuthorize, withReactQuery } from 'hocs'
import { useQueryParams } from 'hooks'
import { useVendorAssessmentsFetcher } from 'apis'
import View from './View'
import useRequestExtension from './useRequestExtension'

const Container = () => {
  const [{ tab }, setQueryParams] = useQueryParams()
  const handleTabSelect = React.useCallback(
    (e) => setQueryParams({ tab: e }, true),
    [],
  )
  const { data: assessments, isFetching } = useVendorAssessmentsFetcher()
  const requestExtension = useRequestExtension()

  return (
    <View
      assessments={assessments}
      isFetching={isFetching}
      activeTab={tab}
      onTabSelect={handleTabSelect}
      onRequestExtension={requestExtension}
    />
  )
}

export default compose(withAuthorize(), withReactQuery())(Container)
