import PropTypes from 'prop-types'
import React from 'react'
import Table from 'components-v2/molecules/Table'
import { TableWrapper } from './styles'

const getCellValue = (url, value) => (
  <span>{value > 0 ? <a href={url}>{value}</a> : value}</span>
)

const getColumns = (getAssessmentsPageUrl) => [
  {
    id: 'name',
    header: 'Month',
  },
  {
    id: 'sent',
    header: 'Total Sent',
    cell: (props) =>
      getCellValue(
        getAssessmentsPageUrl({
          date_type: 'sent',
          startDate: props.row.original.date,
          endDate: props.row.original.date,
        }),
        props.getValue(),
      ),
  },
  {
    id: 'submitted',
    header: 'Total Submitted',
    cell: (props) =>
      getCellValue(
        getAssessmentsPageUrl({
          date_type: 'submitted',
          startDate: props.row.original.date,
          endDate: props.row.original.date,
        }),
        props.getValue(),
      ),
  },
  {
    id: 'reviewed',
    header: 'Total Reviewed',
    cell: (props) =>
      getCellValue(
        getAssessmentsPageUrl({
          date_type: 'reviewed',
          startDate: props.row.original.date,
          endDate: props.row.original.date,
        }),
        props.getValue(),
      ),
  },
  {
    id: 'Avg submitted rating',
    header: 'Avg Submitted Rating',
    cell: (props) =>
      getCellValue(
        getAssessmentsPageUrl({
          status: 'submitted',
          date_type: 'submitted',
          startDate: props.row.original.date,
          endDate: props.row.original.date,
        }),
        props.getValue() || '-',
      ),
  },
  {
    id: 'Avg reviewed rating',
    header: 'Avg Reviewed Rating',
    cell: (props) =>
      getCellValue(
        getAssessmentsPageUrl({
          status: 'reviewed',
          date_type: 'reviewed',
          startDate: props.row.original.date,
          endDate: props.row.original.date,
        }),
        props.getValue() || '-',
      ),
  },
]

const ChartTableWidget = ({ data, totals, getAssessmentsPageUrl }) => {
  const columns = getColumns(getAssessmentsPageUrl)
  return (
    <TableWrapper>
      <Table
        data={data ?? []}
        columns={columns}
        enableSorting={false}
        enablePagination={false}
        tfootContent={
          totals && (
            <tr>
              <th>{totals.name}</th>
              <th>{totals.sent || '0'}</th>
              <th>{totals.submitted || '0'}</th>
              <th>{totals.reviewed || '0'}</th>
              <th>{totals['Avg submitted rating'] || '-'}</th>
              <th>{totals['Avg reviewed rating'] || '-'}</th>
            </tr>
          )
        }
      />
    </TableWrapper>
  )
}

ChartTableWidget.propTypes = {
  data: PropTypes.array,
  totals: PropTypes.object,
  getAssessmentsPageUrl: PropTypes.func.isRequired,
}

export default ChartTableWidget
