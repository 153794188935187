import format from 'date-fns/format'
import isValid from 'date-fns/isValid'
import formatRelative from 'date-fns/formatRelative'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import { toDate as toDate2 } from 'date-fns-tz'
import { getTimeZones } from '@vvo/tzdb'

const localTimeZoneAbbreviation = new Date()
  .toLocaleTimeString('en-us', { timeZoneName: 'short' })
  .split(' ')[2]

export const timeZones = getTimeZones()
export const localTimeZoneOffsetInMinutes = -new Date().getTimezoneOffset() // -480 in case of GTM-8
export const localTimeZoneOffsetInHours = localTimeZoneOffsetInMinutes / 60 // -8 in case of GTM-8
export const localTimeZone = timeZones.find(
  (e) =>
    e.currentTimeOffsetInMinutes === localTimeZoneOffsetInMinutes &&
    e.abbreviation === localTimeZoneAbbreviation,
)

export const toDate = (value, timeZoneName = 'UTC') => {
  if (!value) {
    return undefined
  }
  const timeZone = timeZoneName === 'local' ? undefined : timeZoneName
  const date = toDate2(value, { timeZone })
  return isValid(date) ? date : undefined
}

export const toLocalTimeString = (
  dateTime,
  f = 'MMMM d, yyyy h:mmaaa',
  invalidText = '',
  timeZoneName = undefined,
) => {
  if (!dateTime) {
    return ''
  }
  const date = toDate(dateTime, timeZoneName)
  return date ? format(date, f) : invalidText
}

export const toCalendarString = (dateTime, invalidText = '') => {
  const date = toDate(dateTime)
  return date ? formatRelative(date, new Date()) : invalidText
}

export const toFromNowString = (dateTime, options, invalidText = '') => {
  const date = toDate(dateTime)
  return date ? formatDistanceToNow(date, options) : invalidText
}

export const toMonthParam = (date, pad = false) => {
  let dt = date
  if (pad) {
    const y = dt.getFullYear()
    const m = dt.getMonth()
    // get last day of any month
    // by getting the zero-th day of the next one
    dt = new Date(y, m + 1, 0)
  }
  return dt.toISOString().substring(0, 10)
}
