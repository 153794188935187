import { useCallback } from 'react'
import { useRequestVendorProjectExtension } from 'apis'
import { useGlobalLoader, useModal } from 'hooks'
import NotificationManager from 'lib/notifications'
import RequestAssessmentExtensionModal from './RequestAssessmentExtensionModal'

export default function useRequestAssessmentExtension() {
  const [openModal, closeModal] = useModal(RequestAssessmentExtensionModal)
  const [showLoader, hideLoader] = useGlobalLoader()
  const { mutateAsync: requestProjectExtension } =
    useRequestVendorProjectExtension()

  return useCallback(
    (assessment, onSuccess) => {
      const request = (days) => {
        const loaderId = showLoader()
        const data = {
          days_requested: days,
        }
        return requestProjectExtension({
          id: assessment.project.id,
          data,
        })
          .then((res) => {
            closeModal()
            NotificationManager.success(
              'Your request has been successfully completed.',
            )
            if (onSuccess) {
              onSuccess(res)
            }
          })
          .catch(() => NotificationManager.error())
          .then(() => hideLoader(loaderId))
      }

      if (assessment.allow_requested_days) {
        openModal({
          onSubmit: ({ days }) => request(days),
        })
        return
      }

      request()
    },
    [requestProjectExtension, openModal, closeModal, showLoader, hideLoader],
  )
}
