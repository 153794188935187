import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { Row, Col } from 'components-v2/organisms/Layout'
import GradeLabel from 'components/shared/GradeLabel'
import { toLocalTimeString } from 'utils/date'
import Meta from './Meta'
import { Wrapper, Title, Content, GradeWrapper } from './styles'

const theme = {
  hGutter: 10,
  vGutter: 4,
}

const FacetableItem = ({ data }) => (
  <Wrapper>
    <Title scored={!!data.grade}>{data.facet_name}</Title>
    <Content>
      <GradeWrapper>
        <GradeLabel grade={data.grade} noGradeText="Pending" />
      </GradeWrapper>
      <ThemeProvider theme={theme}>
        <Row>
          {data.facet_source_type && (
            <Col xs={data.value ? 6 : 12}>
              <Meta title="Source" content={data.facet_source_type} />
            </Col>
          )}
          {data.value && (
            <Col xs={6}>
              <Meta
                title={data.facet_field_name.titleize()}
                content={data.value}
              />
            </Col>
          )}
          <Col xs={12}>
            {data.created_at && data.facet_source_type && (
              <Meta
                title="Date"
                content={toLocalTimeString(data.created_at, 'MM/dd/yy')}
              />
            )}
          </Col>
        </Row>
      </ThemeProvider>
    </Content>
  </Wrapper>
)

FacetableItem.propTypes = {
  data: PropTypes.object.isRequired,
}

export default FacetableItem
