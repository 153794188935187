import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import Icon from 'components-v2/atoms/Icon'
import A from 'components-v2/atoms/A'
import IconButton from 'components-v2/atoms/IconButton'
import Select from 'components-v2/atoms/Select'
import { colors, fonts, mixins, media } from 'styles'

export const ProfileImg = styled.img`
  padding-right: 10px;
`

export const StyledIcon = styled(Icon)`
  color: ${({ $active }) => ($active ? colors.PRIMARY : colors.GREY)};
  && {
    font-size: 20px;
  }
`

export const StyledIconButton = styled(IconButton)`
  font-size: 20px;
`

export const SaveToWatchlistTrigger = styled(A)`
  display: inline-flex;
  align-items: center;
  ${({ $empty }) =>
    !$empty &&
    `
    @media print {
      display: inline-flex !important;
    }
  `}
  color: ${colors.BODY_TEXT};

  > svg {
    margin-left: 8px;
  }
`

export const Body = styled.div`
  display: flex;
  color: ${colors.BODY_TEXT};
`

export const Sidebar = styled.div`
  width: 230px;
  flex: none;
  padding-right: 37px;
  padding-top: 70px;

  ${media.tablet`
    display: none;
  `}
`

export const Watchlists = styled.div`
  margin-left: -50px;
`

export const WatchlistSize = styled.span`
  position: absolute;
  right: 17px;
  top: 4px;
  font-weight: 500;
  display: none;
`

const activeWatchList = css`
  color: ${colors.PRIMARY};
  background: ${rgba(colors.PRIMARY, 0.05)};

  ${WatchlistSize} {
    display: block;
  }
`

export const Watchlist = styled.div`
  position: relative;
  height: 30px;
  padding: 4px 57px 3px 50px;
  border-top-right-radius: 17px;
  border-bottom-right-radius: 17px;
  cursor: pointer;
  ${mixins.truncate}

  &:not(:last-child) {
    margin-bottom: 9px;
  }

  ${({ $active }) =>
    !$active &&
    css`
      &:hover {
        ${activeWatchList}
      }
    `}

  ${({ $active }) =>
    $active &&
    css`
      ${activeWatchList}
      background: ${rgba(colors.PRIMARY, 0.1)};
      cursor: initial;
    `}
`

export const AllCompanies = styled(Watchlist)`
  font-weight: 500;
`

export const Content = styled.div`
  flex: 1;
  min-width: 0;
`

export const WatchlistSelectMobile = styled(Select)`
  margin-bottom: 20px;

  ${media.ntablet`
    display: none;
  `}
`

export const TitleBar = styled.div`
  margin-bottom: 30px;
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 10px;
  }
`

export const Title = styled.h2`
  margin: 0;
  font-family: ${fonts.PRIMARY};
  font-size: 36px;
  font-weight: normal;
  color: #000;
`
