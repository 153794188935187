import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Accordion from 'components-v2/molecules/Accordion'
import Card from 'components-v2/organisms/Card'
import {
  Body,
  Sidebar,
  Content,
  HideFiltersButton,
  ShowFiltersButton,
  AccordionItem,
} from './styles'

function ContentWithFilterSidebar({ filters, children }) {
  const [filtersVisible, setFiltersVisible] = useState(true)
  return (
    <Body>
      {filtersVisible && (
        <Sidebar>
          <Card>
            <HideFiltersButton onClick={() => setFiltersVisible(false)}>
              Hide Filters
              <i className="fa fa-filter" />
            </HideFiltersButton>
            <Accordion allowMultipleExpanded allowZeroExpanded>
              {filters}
            </Accordion>
          </Card>
        </Sidebar>
      )}
      <Content>
        {!filtersVisible && (
          <ShowFiltersButton onClick={() => setFiltersVisible(true)}>
            Show Filters
            <i className="fa fa-filter" />
          </ShowFiltersButton>
        )}
        {children}
      </Content>
    </Body>
  )
}

ContentWithFilterSidebar.AccordionItem = AccordionItem

ContentWithFilterSidebar.propTypes = {
  filters: PropTypes.node,
  children: PropTypes.node,
}
export default ContentWithFilterSidebar
