import React from 'react'
import PropTypes from 'prop-types'
import {
  BarChart as BChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Bar,
  Cell,
  Tooltip,
} from 'recharts'
import { Container } from './styles'

const BarChart = ({
  width,
  height,
  data,
  xAxisDataKey,
  xAxisLabel,
  xAxisTickFormatter,
  yAxisLabel,
  yAxisTickFormatter,
  barColorGetter,
  tooltipProps,
  ...rest
}) => (
  <Container width={width} height={height}>
    <BChart data={data} margin={{ top: 15, right: 0, left: 0, bottom: 0 }}>
      <XAxis
        height={45}
        dataKey={xAxisDataKey}
        tickLine={false}
        tickFormatter={xAxisTickFormatter}
        label={{ value: xAxisLabel, position: 'insideBottom' }}
      />
      <YAxis
        width={50}
        axisLine={false}
        tickLine={false}
        tickFormatter={yAxisTickFormatter}
        label={{
          value: yAxisLabel,
          angle: -90,
          position: 'insideLeft',
          style: { textAnchor: 'middle' },
        }}
      />
      <CartesianGrid vertical={false} stroke="#E9ECEF" />
      <Tooltip
        formatter={(value) => [yAxisTickFormatter(value), yAxisLabel]}
        labelFormatter={xAxisTickFormatter}
        {...tooltipProps}
      />
      <Bar {...rest}>
        {barColorGetter &&
          data.map((entry, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Cell key={index} fill={barColorGetter(entry, index)} />
          ))}
      </Bar>
    </BChart>
  </Container>
)

BarChart.propTypes = {
  ...Bar.propTypes,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  xAxisDataKey: PropTypes.string,
  xAxisLabel: PropTypes.string,
  xAxisTickFormatter: PropTypes.func,
  yAxisLabel: PropTypes.string,
  yAxisTickFormatter: PropTypes.func,
  barColorGetter: PropTypes.func,
  tooltipProps: PropTypes.object,
}

BarChart.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  dataKey: 'value',
  // eslint-disable-next-line react/default-props-match-prop-types
  maxBarSize: 30,
  xAxisDataKey: 'label',
  tooltipProps: {},
  yAxisTickFormatter: (value) => value,
}

export default BarChart
