import React from 'react'
import PropTypes from 'prop-types'
import { useQueryClient } from '@tanstack/react-query'
import every from 'lodash/every'
import get from 'lodash/get'
import mapValues from 'lodash/mapValues'
import some from 'lodash/some'
import NotificationManager from 'lib/notifications'
import {
  AssessmentStatusTypes,
  ProjectStatusTypes,
  SortOrderTypes,
} from 'constants/index'
import config from 'config'
import {
  useClientProjectProjectVendorsFetcher,
  useClientAssessmentsFetcher,
  useReinviteClientProjectVendor,
  useUpdateMultiProjectVendorsForClientProject,
  useClientProjectTotalsFetcher,
} from 'apis'
import View from './View'
import { getTableColumns } from './cols'
import useExtendVendor from './useExtendVendor'
import useApproveExtensionRequest from './useApproveExtensionRequest'
import { FilterTypes, FilterOptions, ActionMenuItemTypes } from './consts'

const DoNotInviteStatusTypes = [
  AssessmentStatusTypes.SUBMITTED,
  AssessmentStatusTypes.REVIEWED,
  AssessmentStatusTypes.EXPIRED,
  AssessmentStatusTypes.CANCELED,
]

const NonDraftView = ({
  project,
  projectTotals: projectTotalsProp,
  initialSearchText,
  authManager,
}) => {
  const queryClient = useQueryClient()
  const [filter, setFilter] = React.useState(FilterTypes.ALL)
  const [selected, setSelected] = React.useState({})
  const [pagination, setPagination] = React.useState({
    page: 1,
    sizePerPage: config.collectionQueryPageLimit.default,
  })
  const [sorting, setSorting] = React.useState({
    sortField: 'vendor.name',
    sortOrder: SortOrderTypes.ASC,
  })
  const [searchText, setSearchText] = React.useState(initialSearchText || '')
  const { canBulkExtend, bulkExtend } = useExtendVendor(project.id)

  const { data: projectTotals } = useClientProjectTotalsFetcher(
    { id: project.id },
    {
      initialData: projectTotalsProp,
      refetchOnMount: false,
    },
  )

  const approveExtensionRequest = useApproveExtensionRequest()
  const {
    data: projectVendors,
    totalSize,
    isFetching: isFetchingProjectVendors,
  } = useClientProjectProjectVendorsFetcher({
    projectId: project.id,
    params: {
      per_page: pagination.sizePerPage,
      page: pagination.page,
      ordered_by: sorting.sortField,
      direction: sorting.sortOrder,
      search_query: searchText,
      status: filter,
    },
  })
  const { data: assessments, isFetching: isFetchingAssessments } =
    useClientAssessmentsFetcher(
      {
        params: {
          for_project: project.id,
          for_vendors: projectVendors?.map((pv) => pv.vendor?.id),
          per_page: config.collectionQueryTotalLimit.assessments,
        },
      },
      { enabled: !!projectVendors && projectVendors.length > 0 },
    )
  const { mutateAsync: reinviteProjectVendor } =
    useReinviteClientProjectVendor()
  const { mutateAsync: updateMultiProjectVendors } =
    useUpdateMultiProjectVendorsForClientProject()

  const selectedRows = React.useMemo(() => {
    const selectedIds = Object.keys(selected)
    return projectVendors?.filter((e) => selectedIds.includes(e.id)) || []
  }, [selected, projectVendors])

  const inviteOrRemindable = (pv) =>
    get(pv, 'date_range.started_at', false) &&
    some(
      assessments,
      (asmt) =>
        asmt.vendor_id === pv.vendor.id &&
        !DoNotInviteStatusTypes.includes(asmt.status),
    )

  const resendEnabled = React.useCallback(
    (pvArr) => {
      const pvSet = pvArr || selectedRows
      return (
        project.status === ProjectStatusTypes.LAUNCHED &&
        pvSet.length > 0 &&
        every(pvSet, (pv) => inviteOrRemindable(pv))
      )
    },
    [selectedRows],
  )

  const disableRemindersEnabled = React.useCallback(
    (pvArr) => {
      const pvSet = pvArr || selectedRows
      return (
        project.status === ProjectStatusTypes.LAUNCHED &&
        pvSet.length > 0 &&
        every(
          pvSet,
          (pv) => !pv.reminders_disabled_at && inviteOrRemindable(pv),
        )
      )
    },
    [selectedRows],
  )

  const reinviteVendorsToProject = React.useCallback(
    (pvArr) => {
      Promise.all(
        pvArr.map((row) => reinviteProjectVendor({ projectVendorId: row.id })),
      )
        .then(() => {
          NotificationManager.success(
            'Your request has successfully been submitted',
          )
          setSelected({})
        })
        .catch(() =>
          NotificationManager.error(
            'There was an error processing your request. Please check your connection and try again later.',
          ),
        )
    },
    [project, reinviteProjectVendor],
  )

  const disableProjectVendorReminders = React.useCallback(
    (pvArr) => {
      const now = new Date()
      const data = {
        project_vendors: pvArr.map((row) => ({
          id: row.id,
          reminders_disabled_at: now,
        })),
      }
      updateMultiProjectVendors({ projectId: project.id, data })
        .then(() => {
          queryClient.invalidateQueries(useClientAssessmentsFetcher.queryKey())
          NotificationManager.success(
            'Your request has successfully been submitted',
          )
          setSelected({})
        })
        .catch(() =>
          NotificationManager.error(
            'There was an error processing your request. Please check your connection and try again later.',
          ),
        )
    },
    [project, updateMultiProjectVendors, queryClient],
  )

  const columns = React.useMemo(() => {
    const templateColumnsVisible = project.assessments_count > 0
    const reminderColumnVisible = project.invitation_reminder
    const quickActionColumnVisible =
      project.status === ProjectStatusTypes.LAUNCHED
    const quickActions = [
      {
        label: 'Resend Invite',
        iconClass: 'fa-repeat',
        onClick: reinviteVendorsToProject,
        enabled: (rows) => resendEnabled(rows),
      },
    ]
    if (reminderColumnVisible) {
      quickActions.push({
        label: 'Disable Reminders',
        iconClass: 'fa-bell-slash-o',
        onClick: disableProjectVendorReminders,
        enabled: (rows) => disableRemindersEnabled(rows),
      })
    }

    return getTableColumns(
      authManager,
      assessments,
      project.assessment_templates,
      templateColumnsVisible,
      reminderColumnVisible,
      quickActionColumnVisible,
      quickActions,
      approveExtensionRequest,
    )
  }, [project, assessments, approveExtensionRequest])

  // mapValues(FilterOptions, (_, type) => project.project_totals[type] || 0),
  const filterCountMap = React.useMemo(
    () => mapValues(FilterOptions, (_, type) => projectTotals[type] || 0),
    [projectTotals],
  )

  const handleSearchChange = React.useCallback((newSearchText) => {
    // Let's reset page to 1
    setPagination((state) => ({ ...state, page: 1 }))
    setSearchText(newSearchText)
    setSelected({})
  }, [])

  const handleFilterChange = React.useCallback((newValue) => {
    setFilter(newValue)
    setSelected({})
  }, [])

  const handleMenuItemSelect = (key) => {
    switch (key) {
      case ActionMenuItemTypes.RESEND_INVITE:
        reinviteVendorsToProject(selectedRows)
        break
      case ActionMenuItemTypes.DISABLE_REMINDERS:
        disableProjectVendorReminders(selectedRows)
        break
      case ActionMenuItemTypes.EXTEND:
        bulkExtend(selectedRows, () => setSelected({}))
        break
      default:
    }
  }

  const remindersEnabled = project.invitation_reminder
  return (
    <View
      vendors={projectVendors || []}
      selected={selected}
      columns={columns}
      filter={filter}
      filterCountMap={filterCountMap}
      remindersEnabled={remindersEnabled}
      enableResend={resendEnabled()}
      enableDisableReminders={disableRemindersEnabled()}
      bulkExtendActionEnabled={canBulkExtend(project, selectedRows)}
      pagination={pagination}
      sorting={sorting}
      searchText={searchText}
      totalSize={totalSize}
      loading={isFetchingProjectVendors || isFetchingAssessments}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      onSearchChange={handleSearchChange}
      onFilterChange={handleFilterChange}
      onRowSelectionChange={setSelected}
      onMenuItemSelect={handleMenuItemSelect}
    />
  )
}

NonDraftView.propTypes = {
  project: PropTypes.object.isRequired,
  projectTotals: PropTypes.object.isRequired,
  initialSearchText: PropTypes.string,
  authManager: PropTypes.object.isRequired,
}

export default NonDraftView
