import styled from 'styled-components'
import { colors } from 'styles'
import LargeCard from '../LargeCard'
import SpyCloudMetric from '../SpyCloudMetric'

export const Wrapper = styled(LargeCard)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 164px;
`

export const Records = styled(SpyCloudMetric)`
  font-size: 50px;
  margin-top: 7px;
`

export const UpdatedAt = styled.div`
  flex: none;
  font-size: 13px;
  line-height: 16px;
  height: 14px;
  color: ${colors.DARK_GREY};
`
