import styled from 'styled-components'
import { colors } from 'styles'

export const Wrapper = styled.div`
  font-size: 11px;
  line-height: 1.4;
  word-break: break-all;
`

export const Title = styled.div`
  color: ${colors.HEADING};
  font-weight: 600;
`
