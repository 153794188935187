import React from 'react'
import PropTypes from 'prop-types'
import { InputLabel, Input } from './styles'

const DropzoneInput = ({
  getFilesFromEvent,
  accept,
  multiple,
  disabled,
  onFiles,
}) => (
  <InputLabel>
    browse files
    <Input
      type="file"
      accept={accept}
      multiple={multiple}
      disabled={disabled}
      onChange={async (e) => {
        const chosenFiles = await getFilesFromEvent(e)
        onFiles(chosenFiles)
        e.target.value = null
      }}
    />
  </InputLabel>
)

DropzoneInput.propTypes = {
  getFilesFromEvent: PropTypes.func.isRequired,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  onFiles: PropTypes.func.isRequired,
}

export default DropzoneInput
