import React from 'react'
import map from 'lodash/map'
import isEqual from 'lodash/isEqual'
import { useModal, useConfirm, useCurrent } from 'hooks'
import { useUpdateClientElement, useDeleteClientChoice } from 'apis'
import objectToFormData from 'utils/object_to_formdata'
import QuestionFormModal from './QuestionFormModal'
import useDefaultAsyncActionHandler from './useDefaultAsyncActionHandler'
import {
  getElementById,
  isSection,
  hasRootQuestions,
  sortElements,
  checkNewElementDataForTrouble,
  screenChoiceTempIds,
} from './utils'
import { ProblemList } from './styles'

// Callback when click "Edit" button of an element
export default function useEditElement({
  initialElements,
  assessmentTemplate,
  questionClassesForSelect,
}) {
  const { mutateAsync: updateClientElement } = useUpdateClientElement()
  const { mutateAsync: deleteClientChoice } = useDeleteClientChoice()
  const assessmentTemplateRef = useCurrent(assessmentTemplate)
  const [openQuestionFormModal, closeQuestionFormModal] =
    useModal(QuestionFormModal)
  const confirm = useConfirm()
  const handleAsyncAction = useDefaultAsyncActionHandler()

  return React.useCallback(
    (elementId, newData) => {
      const element = getElementById(
        assessmentTemplateRef.current.elements,
        elementId,
      )
      if (isSection(element)) {
        // If this is a section heading, show an edit section popover
        const promise = updateClientElement({
          id: elementId,
          data: { element: newData },
        })
        handleAsyncAction(promise, 'The section has been successfully updated.')
      } else {
        // Otherwise, show "Edit Question" modal

        openQuestionFormModal({
          elementId,
          sectionRequired: !hasRootQuestions(initialElements),
          elements: sortElements(assessmentTemplateRef.current.elements),
          questionClassesForSelect,
          onSubmit: async (formData, { setSubmitting }) => {
            // We disable user interaction using loader, so we can just set submitting to false immediately
            setSubmitting(false)

            // eslint-disable-next-line no-shadow
            const element = getElementById(
              assessmentTemplateRef.current.elements,
              elementId,
            )
            const problems = checkNewElementDataForTrouble(formData, element)

            if (problems.length) {
              const confirmed = await confirm(
                {
                  title: 'Are you sure?',
                  body: (
                    <>
                      You&apos;ve made changes that may result in invalid
                      question automations, which will be removed if you
                      proceed:
                      <br />
                      <ProblemList>
                        {map(problems, (p, index) => (
                          <li key={index}>{p}</li>
                        ))}
                      </ProblemList>
                    </>
                  ),
                },
                true,
              )
              if (!confirmed) {
                return
              }
            }

            const data = { ...formData }
            let deletedChoices = []

            // check attachments
            if (formData.attachments) {
              const deadThings = element.attachments.filter(
                (obj) => !formData.attachments.some((e) => e.id === obj.id),
              )
              const newThings = formData.attachments.filter((obj) => !obj.id)
              data.attachments_attributes = [
                ...deadThings.map((obj) => ({
                  id: obj.id,
                  _destroy: 1,
                })),
                ...newThings.map((obj) => ({ file: obj })),
              ]
              delete data.attachments
            }

            // check choices
            if (formData.choices_attributes) {
              deletedChoices = element.choices.filter(
                (obj) =>
                  !formData.choices_attributes.some((e) => e.id === obj.id),
              )

              if (!isEqual(element.choices, formData.choices_attributes)) {
                data.choices_attributes = screenChoiceTempIds(
                  formData.choices_attributes,
                )
              } else {
                delete data.choices_attributes
              }
            }

            const promises = [
              updateClientElement({
                id: elementId,
                data: objectToFormData({ element: data }),
              }),
              ...deletedChoices.map((e) => deleteClientChoice({ id: e.id })),
            ]
            const p = Promise.all(promises).then(() => closeQuestionFormModal())
            await handleAsyncAction(p)
          },
        })
      }
    },
    [
      initialElements,
      questionClassesForSelect,
      openQuestionFormModal,
      closeQuestionFormModal,
      confirm,
      handleAsyncAction,
    ],
  )
}
