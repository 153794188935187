import styled from 'styled-components'
import IconButton from 'components-v2/atoms/IconButton'
import { mixins } from 'styles'

export const theme = {
  hGutter: 20,
  vGutter: 20,
}

export const Heading1 = styled.div`
  ${mixins.smallHeader}
  font-size: 14px;
  letter-spacing: 0;
  color: #9b9b9b;
  margin-bottom: 16px;
`

export const Heading2 = styled.div`
  margin-top: 36px;
  font-weight: 500;
  margin-bottom: 20px;
`

export const ToggleIconButton = styled(IconButton)`
  color: inherit;
  margin-left: 7px;
`
