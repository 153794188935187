import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import map from 'lodash/map'
import filter from 'lodash/filter'
import FormField from 'components-v2/molecules/FormField'
import FormDrawer from 'components-v2/organisms/FormDrawer'
import { UserOrgRoles } from 'utils/roles'
import { LabelTypes } from 'constants/index'
import VendorPermissionsField from '../VendorPermissionsField'

const validationSchema = yup.object().shape({
  name: yup.string().label('Name').required(),
  email: yup.string().label('Email').required().email(),
  org_role: yup.string().label('Access Role').required(),
})

const orgRolesForVendorPermissions = filter(
  UserOrgRoles,
  (r) => r.allows_vendors,
).map((r) => r.value)

const InviteClientUserModal = ({
  vendors,
  permittedRoles,
  defaultInviteRole,
  ...rest
}) => {
  const orgRoleOptions = React.useMemo(
    () => map(permittedRoles, ({ value, label }) => ({ value, label })),
    [permittedRoles],
  )

  const formConfig = React.useMemo(
    () => ({
      validationSchema,
      initialValues: {
        name: undefined,
        email: undefined,
        org_role: defaultInviteRole,
        vendor_roles: { all: true },
      },
    }),
    [defaultInviteRole],
  )

  return (
    <FormDrawer {...rest} title="Send Invitation" formConfig={formConfig}>
      {({ values }) => (
        <>
          <FormField name="name" label="Name" placeholder="Jane Doe" />
          <FormField name="email" label="Email" placeholder="jdoe@email.com" />
          <FormField
            name="org_role"
            label="Access Role"
            type={FormField.types.SELECT}
            options={orgRoleOptions}
          />
          {orgRolesForVendorPermissions.includes(values.org_role) && (
            <FormField
              name="vendor_roles"
              label={`${LabelTypes.VENDOR} Permissions`}
              component={VendorPermissionsField}
              vendors={vendors}
            />
          )}
        </>
      )}
    </FormDrawer>
  )
}

InviteClientUserModal.propTypes = {
  vendors: PropTypes.array.isRequired,
  permittedRoles: PropTypes.array,
  defaultInviteRole: PropTypes.string.isRequired,
}

export default InviteClientUserModal
