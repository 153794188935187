import { useCallback } from 'react'
import { useApproveClientProjectVendorExtension } from 'apis'
import { useGlobalLoader, useModal } from 'hooks'
import NotificationManager from 'lib/notifications'
import ApproveExtensionRequestModal from './ApproveExtensionRequestModal'

export default function useApproveProjectVendorExtensionRequest() {
  const [openModal, closeModal] = useModal(ApproveExtensionRequestModal)
  const [showLoader, hideLoader] = useGlobalLoader()
  const { mutateAsync: approveProjectVendorExtension } =
    useApproveClientProjectVendorExtension()

  return useCallback(
    ({ endAt, requestedAt, minutesRequested, projectVendorId, onSuccess }) => {
      const onSubmit = ({ dueDate }) => {
        const loaderId = showLoader()
        const data = {
          project_vendor: {
            date_range_attributes: {
              end_at: dueDate,
            },
          },
        }
        return approveProjectVendorExtension({ id: projectVendorId, data })
          .then((res) => {
            closeModal()
            NotificationManager.success(
              'The extension request has been accepted successfully.',
            )
            if (onSuccess) {
              onSuccess(res)
            }
          })
          .catch(() => NotificationManager.error())
          .then(() => hideLoader(loaderId))
      }
      openModal({
        endAt,
        requestedAt,
        minutesRequested,
        onSubmit,
      })
    },
    [
      approveProjectVendorExtension,
      openModal,
      closeModal,
      showLoader,
      hideLoader,
    ],
  )
}
