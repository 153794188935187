import styled from 'styled-components'
import ListGroup from 'components-v2/molecules/ListGroup'
import { colors, mixins } from 'styles'

export const Section = styled.div`
  margin-top: 30px;
`

export const StyledListGroupItem = styled(ListGroup.Item)`
  display: flex;
  align-items: center;
`

export const ListGroupItemContent = styled.div`
  flex: 1;
  margin: 0 20px;
  min-width: 0;
  ${mixins.truncate}
`

export const Footer = styled(Section)`
  border-top: 1px solid ${colors.ALT_TITLE_GREY};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
`
