import React from 'react'
import PropTypes from 'prop-types'
import A from 'components-v2/atoms/A'
import { MarkdownEditor } from 'components-v2/molecules/Markdown'
import { FormattingToggleWrapper, StyledTextarea } from './styles'

const FormattableEditor = ({
  name,
  value,
  disableFormatting,
  autoFocus,
  placeholder,
  disabled,
  toolbarCondensed,
  rows,
  onChange,
  ...rest
}) => {
  const { body, formatted } = value || {}

  const toggleFormatting = React.useCallback(() => {
    onChange({ body, formatted: !formatted })
  }, [body, formatted, onChange])

  const handleChange = React.useCallback(
    (newContent) => {
      onChange({ body: newContent, formatted })
    },
    [formatted, onChange],
  )

  const handleInputRef = React.useCallback(
    (input) => {
      if (!autoFocus) {
        return
      }
      // IE11 bugfix: Add some delay until modal opening animation is completed if any
      setTimeout(() => {
        if (!input) {
          return
        }
        input.focus()
        input.setSelectionRange(input.value.length, input.value.length)
      }, 500)
    },
    [autoFocus],
  )

  return (
    <div {...rest}>
      {!disableFormatting && (
        <FormattingToggleWrapper>
          <A onClick={toggleFormatting} className="small">
            {formatted ? 'Stop' : 'Add'} Formatting
          </A>
        </FormattingToggleWrapper>
      )}
      {!disableFormatting && formatted ? (
        <MarkdownEditor
          autoFocus={autoFocus}
          value={body || ''}
          placeholder={placeholder}
          disabled={disabled}
          toolbarCondensed={toolbarCondensed}
          onChange={handleChange}
        />
      ) : (
        <StyledTextarea
          name={name}
          value={body || ''}
          placeholder={placeholder}
          disabled={disabled}
          rows={rows}
          onChange={(e) => handleChange(e.currentTarget.value)}
          ref={handleInputRef}
        />
      )}
    </div>
  )
}

FormattableEditor.propTypes = {
  name: PropTypes.string,
  value: PropTypes.shape({
    body: PropTypes.string,
    formatted: PropTypes.bool,
  }),
  disableFormatting: PropTypes.bool,
  autoFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  toolbarCondensed: PropTypes.bool,
  rows: PropTypes.number,
  onChange: PropTypes.func.isRequired,
}

FormattableEditor.defaultProps = {
  value: {},
  toolbarCondensed: false,
}

export default FormattableEditor
