import styled from 'styled-components'

export const TableWrapper = styled.div`
  border-top: 2px solid #ddd;

  tbody > tr:nth-of-type(odd) {
    background-color: #f9f9f9;
  }

  td,
  th {
    text-align: right;
  }
  td:first-child,
  th:first-child {
    text-align: left;
  }
`
