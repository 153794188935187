import React from 'react'
import map from 'lodash/map'
import assignIn from 'lodash/assignIn'
import unset from 'lodash/unset'
import { useModal, useConfirm, useCurrent } from 'hooks'
import { AssessmentElementTypes } from 'constants/index'
import objectToFormData from 'utils/object_to_formdata'
import { useCreateClientAssessmentTemplateElement } from 'apis'
import QuestionFormModal from './QuestionFormModal'
import { sortElements, hasRootQuestions, screenChoiceTempIds } from './utils'
import { QuestionPlacementTypes } from './constants'
import useDefaultAsyncActionHandler from './useDefaultAsyncActionHandler'
import useUpdateCustomKeysAbility from './useUpdateCustomKeysAbility'

// Callback when click "Add Question" button
export default function useCreateQuestion({
  initialElements,
  assessmentTemplate,
  questionClassesForSelect,
}) {
  const { mutateAsync: createClientAssessmentTemplateElement } =
    useCreateClientAssessmentTemplateElement()
  const [openModal, closeModal] = useModal(QuestionFormModal)
  const confirm = useConfirm()
  const handleAsyncAction = useDefaultAsyncActionHandler()
  const updateCustomKeysAbility = useUpdateCustomKeysAbility(
    assessmentTemplate.id,
  )
  const assessmentTemplateRef = useCurrent(assessmentTemplate)

  return React.useCallback(() => {
    const handleSubmit = (formData) => {
      const addQuestion = () => {
        const {
          placement,
          relative_question_id: relativeQuestionId,
          ...elementData
        } = formData

        // Add some default values
        const element = assignIn(
          {
            type: AssessmentElementTypes.TEXT_QUESTION,
            weight: 5,
            allow_comments: true,
            allow_attachments: true,
          },
          elementData,
        )

        // Let's remove any temp id properties in choices_attributes
        element.choices_attributes = screenChoiceTempIds(
          element.choices_attributes,
        )

        element.attachments_attributes = map(element.attachments, (file) => ({
          file,
        }))
        delete element.attachments

        const payload = { element }

        if (placement === QuestionPlacementTypes.BEFORE_QUESTION) {
          payload.insert_before = relativeQuestionId
          unset(payload.element, 'parent_id')
        } else if (placement === QuestionPlacementTypes.AFTER_QUESTION) {
          payload.insert_after = relativeQuestionId
          unset(payload.element, 'parent_id')
        }

        return createClientAssessmentTemplateElement({
          id: assessmentTemplateRef.current.id,
          data: objectToFormData(payload),
        }).then(() => closeModal())
      }

      if (assessmentTemplateRef.current.use_custom_keys) {
        return confirm(
          {
            title: 'Adding New Question',
            body: 'This action will disable custom keys. Are you sure you wish to add new question?',
          },
          true,
        ).then((confirmed) => {
          if (confirmed) {
            handleAsyncAction(
              updateCustomKeysAbility(false).then(addQuestion),
              'New question has been successfully added.',
            )
          }
        })
      }

      return handleAsyncAction(
        addQuestion(),
        'New question has been successfully added.',
      )
    }

    const start = (initialValues) => {
      openModal({
        sectionRequired: !hasRootQuestions(initialElements),
        elements: sortElements(assessmentTemplateRef.current.elements),
        questionClassesForSelect,
        initialValues,
        submitAndNewButtonVisible: true,
        onSubmit: (formData) =>
          handleSubmit(formData).catch((error) => console.error(error)),
        onSubmitAndNew: (formData) =>
          handleSubmit(formData)
            .then(() =>
              setTimeout(
                () =>
                  start({
                    type: formData.type,
                    parent_id: formData.parent_id,
                  }),
                1000,
              ),
            )
            .catch((error) => console.error(error)),
      })
    }

    start()
  }, [
    initialElements,
    questionClassesForSelect,
    updateCustomKeysAbility,
    openModal,
    closeModal,
    confirm,
    handleAsyncAction,
  ])
}
