import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import SimpleDialog from 'components-v2/molecules/SimpleDialog'
import {
  useLibraryManageListingFetcher,
  useUpdateLibraryManageListing,
  useUpdateLibraryManageShare,
} from 'apis'
import NotificationManager from 'lib/notifications'
import { useGlobalLoader } from 'hooks'
import { useQueryClient } from '@tanstack/react-query'
import { SubTitle } from './styles'
import AssessmentVisibility from './AssessmentVisibility'
import PermittedAccess from './PermittedAccess'

const SharingModal = ({
  profileId,
  listing: listingWithoutShares,
  ...rest
}) => {
  const queryClient = useQueryClient()
  const { data: listing } = useLibraryManageListingFetcher(
    { id: listingWithoutShares.id },
    { initialData: listingWithoutShares },
  )
  const [showLoader, hideLoader] = useGlobalLoader()
  const { mutateAsync: updateLibraryManageListing } =
    useUpdateLibraryManageListing()
  const { mutateAsync: updateLibraryManageShare } =
    useUpdateLibraryManageShare()

  const handleChangeVisibility = useCallback(
    async (data) => {
      const loaderId = showLoader()
      try {
        await updateLibraryManageListing({
          id: listing.id,
          data,
        })
        queryClient.invalidateQueries(
          useLibraryManageListingFetcher.queryKey(),
          {
            predicate: (query) => query.queryKey[1].id === listing.id,
          },
        )
      } catch (error) {
        NotificationManager.error()
      }
      hideLoader(loaderId)
    },
    [queryClient, updateLibraryManageListing, showLoader, hideLoader, listing],
  )

  const onTransitRequest = useCallback(
    async (item, transition) => {
      const loaderId = showLoader()
      try {
        await updateLibraryManageShare({
          id: item.id,
          data: {
            transition,
            share: {
              expires_at: item.expires_at,
            },
          },
        })
        queryClient.invalidateQueries(
          useLibraryManageListingFetcher.queryKey(),
          {
            predicate: (query) => query.queryKey[1].id === listing.id,
          },
        )
      } catch (error) {
        NotificationManager.error()
      }
      hideLoader(loaderId)
    },
    [queryClient, updateLibraryManageShare, showLoader, hideLoader],
  )

  return (
    <SimpleDialog {...rest} title={`${listing.name} Sharing`}>
      <SubTitle>Manage file sharing on your vendor profile</SubTitle>
      <AssessmentVisibility
        visible={listing.visible}
        onChange={() => handleChangeVisibility({ private: listing.visible })}
        approvalRequired={listing.approval_required}
        onSelect={() =>
          handleChangeVisibility({
            approval_required: !listing.approval_required,
          })
        }
      />
      <PermittedAccess
        shares={
          listing.shares
            ? listing.shares.filter((item) => item.status !== 'denied')
            : []
        }
        onAcceptRequest={(item) => onTransitRequest(item, 'approve')}
        onDeclineRequest={(item) => onTransitRequest(item, 'deny')}
      />
    </SimpleDialog>
  )
}

SharingModal.propTypes = {
  profileId: PropTypes.string.isRequired,
  listing: PropTypes.object.isRequired,
}

export default SharingModal
