import { useCallback } from 'react'
import { useConfirm, useCurrent } from 'hooks'
import { useMultiDeleteClientAssessmentTemplateElements } from 'apis'
import useDeleteElement from './useDeleteElement'
import useDefaultAsyncActionHandler from './useDefaultAsyncActionHandler'

// Callback when click "Bulk Delete" button
export default function useDeleteElements({
  assessmentTemplate,
  isSectionSelectionMode,
  selectedSectionId,
  selectedQuestionIds,
  setSelectedQuestionIds,
  setSelectedSectionId,
}) {
  const { mutateAsync: multiDeleteClientAssessmentTemplateElements } =
    useMultiDeleteClientAssessmentTemplateElements()
  const assessmentTemplateRef = useCurrent(assessmentTemplate)
  const openConfirm = useConfirm()
  const deleteElement = useDeleteElement(assessmentTemplate)
  const handleAsyncAction = useDefaultAsyncActionHandler()

  return useCallback(() => {
    if (isSectionSelectionMode) {
      deleteElement(selectedSectionId)
    } else {
      openConfirm({
        title: 'Deleting Questions',
        body: 'Are you sure you wish to remove the selected questions?',
        onConfirm: () => {
          const promise = multiDeleteClientAssessmentTemplateElements({
            id: assessmentTemplateRef.current.id,
            data: {
              elements: selectedQuestionIds.reduce(
                (acc, cur) => ({
                  ...acc,
                  [cur]: { id: cur },
                }),
                {},
              ),
            },
          }).then(() => {
            setSelectedQuestionIds([])
            setSelectedSectionId(null)
          })
          return handleAsyncAction(
            promise,
            'The selected questions have been successfully deleted',
          )
        },
      })
    }
  }, [
    isSectionSelectionMode,
    selectedSectionId,
    selectedQuestionIds,
    setSelectedQuestionIds,
    setSelectedSectionId,
    openConfirm,
    handleAsyncAction,
    deleteElement,
  ])
}
