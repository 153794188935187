import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components-v2/atoms/Button'
import PageHeader from 'components-v2/organisms/PageHeader'
import { getPageUrl } from 'utils/url'
import ToolStatusDot from '../../ToolStatusDot'
import { Title, DotWrapper } from './styles'

const getDashboardUrl = (isVendorContext) =>
  isVendorContext
    ? getPageUrl('cmeDashboard')
    : getPageUrl('cmeDashboardVendors')
const getBreadcrumbs = (title, isVendorContext) => [
  {
    href: getDashboardUrl(isVendorContext),
    title: 'Dashboard',
  },
  {
    active: true,
    title,
  },
]

const ObjectivePageHeader = ({ title, status, isVendorContext, ...rest }) => {
  const titleContent = (
    <Title>
      {title}
      <DotWrapper>
        <ToolStatusDot status={status} />
      </DotWrapper>
    </Title>
  )
  return (
    <PageHeader
      {...rest}
      title={titleContent}
      breadcrumbs={getBreadcrumbs(title, isVendorContext)}
    >
      <Button color="primary" href={getDashboardUrl(isVendorContext)}>
        &lt; Back To Dashboard
      </Button>
    </PageHeader>
  )
}

ObjectivePageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  status: PropTypes.string,
  isVendorContext: PropTypes.bool,
}

export default ObjectivePageHeader
