import { useEffect } from 'react'
import { useQueryParams } from 'hooks'
import { AssessmentStatusTypes } from 'constants/index'
import useCRUDAssessment from './useCRUDAssessment'

// Handle 'extend' query param
// The query param can be used in email sent to client on assessment expiration
export default function useExtendQueryParamOnMount(assessment) {
  const { unlockAssessment } = useCRUDAssessment(assessment)
  const [{ extend }] = useQueryParams({ extend: { type: Boolean } })

  useEffect(() => {
    if (
      extend &&
      [AssessmentStatusTypes.SUBMITTED, AssessmentStatusTypes.EXPIRED].includes(
        assessment.status,
      )
    ) {
      unlockAssessment()
    }
  }, []) // On mount only
}
