import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import Privva from 'Privva'
import Checkbox from 'components-v2/atoms/Checkbox'
import Button from 'components-v2/atoms/Button'
import IconButton from 'components-v2/atoms/IconButton'
import ListGroup from 'components-v2/molecules/ListGroup'
import PageHeader from 'components-v2/organisms/PageHeader'
import { Row, Col } from 'components-v2/organisms/Layout'
import { withReactQuery } from 'hocs'
import { useAssistAnswerSetsFetcher, useAssistQuestionSetFetcher } from 'apis'
import { getPageUrl } from 'utils/url'
import { pluralize } from 'utils/string'
import { toLocalTimeString } from 'utils/date'
import useDeleteAnswerSet from '../shared/useDeleteAnswerSet'
import useEditQuestionSetSettings from '../shared/useEditQuestionSetSettings'
import useUploadAnswerSet from '../shared/useUploadAnswerSet'
import AnswerSetFormatTooltip from '../shared/AnswerSetFormatTooltip'
import CardWithTitle from '../shared/CardWithTitle'
import useToggleAnswerSet from './useToggleAnswerSet'
import {
  Section,
  StyledListGroupItem as ListGroupItem,
  ListGroupItemContent,
  Footer,
} from './styles'

function AnswerSetsView({
  questionSet: questionSetProp,
  answerSets: answerSetsProp,
  assistOrgName,
}) {
  const { data: questionSet } = useAssistQuestionSetFetcher(
    { id: questionSetProp.id },
    {
      initialData: questionSetProp,
      refetchOnMount: false,
    },
  )
  const { data: answerSets } = useAssistAnswerSetsFetcher(undefined, {
    initialData: answerSetsProp,
    refetchOnMount: false,
  })
  const deleteAnswerSet = useDeleteAnswerSet()
  const editQuestionSetSettings = useEditQuestionSetSettings(questionSet.id)
  const toggleAnswerSet = useToggleAnswerSet(questionSet.id)
  const uploadAnswerSet = useUploadAnswerSet(
    useCallback(
      (data) => {
        toggleAnswerSet(data.id, true)
      },
      [toggleAnswerSet],
    ),
  )
  return (
    <>
      <PageHeader title={`${Privva.Utils.brand.programName} Assist`} />
      <h3>
        {assistOrgName}&nbsp;
        <IconButton
          href={getPageUrl('assistOrganizations')}
          icon="fa fa-pencil"
          color="primary"
          className="h5"
        />
      </h3>
      <h4>
        {questionSet.name}&nbsp;
        <IconButton
          title="Question Set Settings"
          icon="fa fa-gear"
          color="primary"
          className="h5"
          onClick={editQuestionSetSettings}
        />
      </h4>
      <Section>
        <Row>
          <Col md={8}>
            <h4>2. Upload Answer Sets</h4>
            <p>
              To get started, choose one or more Answer Sets and/or upload more.
              An Answer Set is a spreadsheet of previously answered questions
              and answers.
            </p>
          </Col>
        </Row>
      </Section>
      <Section>
        <Row>
          <Col md={5}>
            <CardWithTitle title="Answer Sets">
              <ListGroup>
                {answerSets.map((as) => (
                  <ListGroupItem key={as.id}>
                    <Checkbox
                      checked={questionSet.answer_sets.includes(as.id)}
                      onChange={(e) => toggleAnswerSet(as.id, e.target.checked)}
                    />
                    <ListGroupItemContent>
                      <b>{as.name}</b>
                      <div className="small">
                        {pluralize(as.answers_count, 'answer')}{' '}
                        {toLocalTimeString(
                          as.completed_at || as.created_at,
                          'MMMM yyyy',
                        )}
                      </div>
                    </ListGroupItemContent>
                    <IconButton
                      icon="fa fa-times-circle"
                      color="primary"
                      onClick={() => deleteAnswerSet(as.id)}
                    />
                  </ListGroupItem>
                ))}
              </ListGroup>
            </CardWithTitle>
          </Col>
          <Col md={1}></Col>
          <Col md={6}>
            <Button color="primary" onClick={uploadAnswerSet}>
              <i className="fa fa-table" />
              Upload Spreadsheet
            </Button>
            <br />
            <AnswerSetFormatTooltip />
          </Col>
        </Row>
      </Section>
      <Footer>
        <Button
          href={getPageUrl('assistQuestionSets', undefined, {
            selected: questionSet.id,
          })}
        >
          {'<< Previous'}
        </Button>
        <Button
          color="primary"
          href={getPageUrl('assistQuestionSetMatch', { id: questionSet.id })}
        >
          Run Report
        </Button>
      </Footer>
    </>
  )
}

AnswerSetsView.propTypes = {
  questionSet: PropTypes.object.isRequired,
  answerSets: PropTypes.arrayOf(PropTypes.object).isRequired,
  assistOrgName: PropTypes.string.isRequired,
}

export default withReactQuery()(AnswerSetsView)
