import React from 'react'
import filter from 'lodash/filter'
import reject from 'lodash/reject'
import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import ProgressBar from 'components-v2/molecules/ProgressBar'
import Tooltip from 'components-v2/molecules/Tooltip'
import {
  ProjectStatusTypes,
  ProjectStatusOptions,
  AssessmentStatusTypes,
} from 'constants/index'

const ProjectStatusOptionsObj = keyBy(ProjectStatusOptions, 'value')

// this and getCloseDate() below should match
// the value used in sorting
export const getLaunchDate = (project) => project.earliest_launch_at
// project.status === ProjectStatusTypes.DRAFT
//   ? project.earliest_launch_at
//   : project.launched_at

export const getCloseDate = (project) => project.latest_due_at
// project.closed_at || project.latest_due_at

export const progressFormatterFactory = () => (info) => {
  const { status, assessments } = info.row.original

  const renderProgressBar = (count, total, label) => {
    const progress = (count / total) * 100
    const tooltip = `${count} of ${total} Assessments ${label}`
    const parent = (
      <ProgressBar
        color={get(ProjectStatusOptionsObj, [status, 'color'])}
        now={progress}
      />
    )
    return <Tooltip parent={parent}>{tooltip}</Tooltip>
  }

  switch (status) {
    case ProjectStatusTypes.LAUNCHED: {
      const count = filter(
        assessments,
        (e) =>
          e.status === AssessmentStatusTypes.SUBMITTED ||
          e.status === AssessmentStatusTypes.REVIEWED,
      ).length
      const total = reject(assessments, {
        status: AssessmentStatusTypes.CANCELED,
      }).length
      return renderProgressBar(count, total, 'Submitted')
    }
    case ProjectStatusTypes.CLOSED: {
      const count = filter(assessments, {
        status: AssessmentStatusTypes.REVIEWED,
      }).length
      const total = reject(assessments, {
        status: AssessmentStatusTypes.CANCELED,
      }).length
      return renderProgressBar(count, total, 'Reviewed')
    }
    default:
      return null
  }
}
