import { IssuePriorityTypes } from 'constants/index'

export function convertRiskReconSeverityToIssuePriority(severity) {
  const mapping = {
    critical: IssuePriorityTypes.CRITICAL,
    high: IssuePriorityTypes.HIGH,
    medium: IssuePriorityTypes.MEDIUM,
    low: IssuePriorityTypes.LOW,
  }
  return mapping[severity]
}
