import React, { useCallback } from 'react'
import {
  useClientUserGroupsFetcher,
  useCreateClientUserGroup,
  useUpdateClientUserGroup,
  useDeleteClientUserGroup,
} from 'apis'
import EditableItemListTable from '../EditableItemListTable'

export default function UserGroupTable() {
  const { data, isLoading } = useClientUserGroupsFetcher()
  const { mutateAsync: createUserGroup } = useCreateClientUserGroup()
  const { mutateAsync: updateUserGroup } = useUpdateClientUserGroup()
  const { mutateAsync: deleteUserGroup } = useDeleteClientUserGroup()
  const handleCreate = useCallback(
    (name) => createUserGroup({ data: { name } }),
    [createUserGroup],
  )
  const handleUpdate = useCallback(
    (id, name) => updateUserGroup({ id, data: { user_group: { id, name } } }),
    [updateUserGroup],
  )
  const handleDelete = useCallback(
    (id) => deleteUserGroup({ id }),
    [deleteUserGroup],
  )
  return (
    <EditableItemListTable
      data={data}
      isLoading={isLoading}
      updateConfirmText="Changing this group will update it across users."
      deleteConfirmText="Deleting this group will remove it across users."
      onCreate={handleCreate}
      onUpdate={handleUpdate}
      onDelete={handleDelete}
    />
  )
}
