import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withReactQuery } from 'hocs'
import { useOrganizationsFetcher } from 'apis'
import View from './View'

const PerPage = 30

const Container = (props) => {
  const [page, setPage] = useState(1)
  const [query, setQuery] = useState('')
  const {
    data: organizations,
    totalSize,
    isLoading,
  } = useOrganizationsFetcher({
    params: {
      page,
      per_page: PerPage,
      search_query: query,
      ordered_by: 'is_vendor',
    },
  })
  const handleQueryChange = useCallback((e) => {
    setPage(1)
    setQuery(e.target.value)
  }, [])
  const handleQueryClear = useCallback(() => {
    setPage(1)
    setQuery('')
  }, [])
  const handlePrevClick = useCallback(() => {
    setPage((current) => current - 1)
  }, [])
  const handleNextClick = useCallback(() => {
    setPage((current) => current + 1)
  }, [])
  return (
    <View
      {...props}
      organizations={organizations}
      total={totalSize}
      loading={isLoading}
      query={query}
      page={page}
      perPage={PerPage}
      onQueryChange={handleQueryChange}
      onQueryClear={handleQueryClear}
      onPrevClick={handlePrevClick}
      onNextClick={handleNextClick}
    />
  )
}

Container.propTypes = {
  redirect: PropTypes.string,
  destOrg: PropTypes.object,
  priority: PropTypes.string,
  noOrgs: PropTypes.bool,
}

export default withReactQuery()(Container)
