import React from 'react'
import PropTypes from 'prop-types'
import Uploader from 'components-v2/molecules/Uploader'
import AssessmentScoreSheetHeader from 'components/shared/AssessmentScoreSheetHeader'
import { LabelTypes } from 'constants/index'
import { getRatingLabelSetOption } from 'lib/assessment'
import { Comment } from './styles'

const ScoreSheetHeader = ({ assessment, ...rest }) => (
  <AssessmentScoreSheetHeader
    assessment={assessment}
    ratingLabel={
      getRatingLabelSetOption(assessment.rating_label_set).rating_label
    }
    {...rest}
  >
    <AssessmentScoreSheetHeader.Card title="Details">
      <AssessmentScoreSheetHeader.SmallHeading>
        {LabelTypes.VENDOR} Attachments
      </AssessmentScoreSheetHeader.SmallHeading>
      {assessment.attachments.length > 0 ? (
        <Uploader
          files={assessment.attachments}
          displayLabel={false}
          disabled
        />
      ) : (
        <div>No attachments</div>
      )}
      <AssessmentScoreSheetHeader.Card.Separator />
      <AssessmentScoreSheetHeader.SmallHeading>
        {LabelTypes.VENDOR} Comments
      </AssessmentScoreSheetHeader.SmallHeading>
      {assessment.comment ? (
        <Comment>&ldquo;{assessment.comment}&rdquo;</Comment>
      ) : (
        <div>No comment</div>
      )}
    </AssessmentScoreSheetHeader.Card>
  </AssessmentScoreSheetHeader>
)

ScoreSheetHeader.propTypes = {
  assessment: PropTypes.object.isRequired,
}

export default ScoreSheetHeader
