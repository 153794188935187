import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import keyBy from 'lodash/keyBy'
import Icon from 'components-v2/atoms/Icon'
import A from 'components-v2/atoms/A'
import Button from 'components-v2/atoms/Button'
import Uploader from 'components-v2/molecules/Uploader'
import { Row, Col } from 'components-v2/organisms/Layout'
import AssessmentScoreSheetHeader from 'components/shared/AssessmentScoreSheetHeader'
import GloborgIntegrationOverviewCard from 'components/client/shared/GloborgIntegrationOverviewCard'
import Assignee from 'components/client/shared/Assignee'
import { useModal, useAuthorizations } from 'hooks'
import {
  AssessmentStatusTypes,
  AssessmentStatusOptions,
  LabelTypes,
  GloborgIntegrationOptions,
} from 'constants/index'
import MoreInfoModal from './MoreInfoModal'
import RiskRatingSummaryModal from './RiskRatingSummaryModal'
import {
  theme,
  StatusSection,
  StatusLabel,
  StatusIcon,
  SubmittedByClient,
  Comment,
  MonitoringDataCard,
} from './styles'

const AssessmentStatusOptionsObj = keyBy(AssessmentStatusOptions, 'value')

const ScoreSheetHeader = ({
  assessment,
  apiPath,
  newContributorButtonVisible,
  addNewContributorUrl,
  onAssign,
  currentOrganization,
  integrationMetrics,
  onTranslateResponsesChange,
  onAttachmentsChange,
  onInternalAttachmentsChange,
  onApproveExtensionRequest,
  onSubmitExpiredAssessment,
  ...rest
}) => {
  const [userCanManage] = useAuthorizations('manage')
  const [openRiskRatingSummaryModal] = useModal(RiskRatingSummaryModal)
  const [openMoreInfoModal] = useModal(MoreInfoModal)
  const [translateResponses, setTranslateResponses] = React.useState(false)
  const hasAttachments =
    assessment.attachments && assessment.attachments.length > 0
  const allowAttachments =
    get(assessment, 'assessment_template.allow_attachments') &&
    assessment.client_submitted &&
    assessment.status === AssessmentStatusTypes.SUBMITTED
  const contributors = React.useMemo(() => {
    const partials = map(
      assessment.vendor.users,
      (c) => `${c.name || c.email}${c.status ? ` (${c.status})` : ''}`,
    )
    return partials.join(', ')
  }, [assessment.vendor.users])

  const handleViewSummaryClick = React.useCallback(
    () =>
      openRiskRatingSummaryModal({
        currentOrganization,
        assessment,
      }),
    [openRiskRatingSummaryModal, currentOrganization, assessment],
  )

  const handleClickMore = React.useCallback(() => {
    openMoreInfoModal({
      assessment,
      translated: translateResponses,
      onTranslateClick: () => {
        onTranslateResponsesChange({ translateResponses: !translateResponses })
        setTranslateResponses(!translateResponses)
      },
    })
  }, [
    assessment,
    translateResponses,
    onTranslateResponsesChange,
    openMoreInfoModal,
  ])

  return (
    <ThemeProvider theme={theme}>
      <AssessmentScoreSheetHeader
        assessment={assessment}
        ratingLabel={assessment.assessment_template?.rating_label}
        onViewSummaryClick={handleViewSummaryClick}
        {...rest}
      >
        <AssessmentScoreSheetHeader.Card
          title="Details"
          onClickMore={handleClickMore}
        >
          <Row>
            <Col lg={4}>
              <AssessmentScoreSheetHeader.SmallHeading>
                Status
              </AssessmentScoreSheetHeader.SmallHeading>
              <StatusSection>
                <StatusLabel
                  color={AssessmentStatusOptionsObj?.[assessment.status]?.color}
                >
                  <StatusIcon
                    icon={AssessmentStatusOptionsObj?.[assessment.status]?.icon}
                  />
                  {assessment.status.titleize()}
                </StatusLabel>
                {assessment.reviewable && assessment.client_submitted && (
                  <SubmittedByClient>Submitted by Client</SubmittedByClient>
                )}
                {userCanManage &&
                  assessment.status === AssessmentStatusTypes.EXPIRED && (
                    <A onClick={onSubmitExpiredAssessment} size="sm" underline>
                      Submit Asessment
                    </A>
                  )}
                {assessment.status === AssessmentStatusTypes.EXPIRED &&
                  assessment.extension_requested_at && (
                    <Button
                      variant="outlined"
                      color="danger"
                      size="xsmall"
                      onClick={onApproveExtensionRequest}
                      disabled={!userCanManage}
                    >
                      Extension Requested
                      <Icon icon="fa fa-arrows-h" />
                    </Button>
                  )}
              </StatusSection>
            </Col>
            <Col lg={4}>
              <AssessmentScoreSheetHeader.SmallHeading>
                Assigned to
              </AssessmentScoreSheetHeader.SmallHeading>
              <Assignee
                assigneeId={assessment.client_assignee?.id}
                assigneeName={assessment.client_assignee?.name}
                note={assessment.client_assignee_note}
                editable={userCanManage}
                onSave={onAssign}
              />
            </Col>
            <Col lg={4}>
              <AssessmentScoreSheetHeader.SmallHeading>
                Contributors
              </AssessmentScoreSheetHeader.SmallHeading>
              <div>
                {contributors}&nbsp;
                {newContributorButtonVisible && (
                  <a href={addNewContributorUrl} className="hidden-print">
                    + Add
                  </a>
                )}
              </div>
            </Col>
          </Row>
          <AssessmentScoreSheetHeader.Card.Separator />
          <AssessmentScoreSheetHeader.SmallHeading>
            Internal Attachments
          </AssessmentScoreSheetHeader.SmallHeading>
          <Uploader
            model="assessment"
            files={assessment.internal_attachments}
            formParam="internal_attachments"
            description="Attach any additional internal files related to this assessment below."
            url={apiPath}
            displayLabel={false}
            onChange={onInternalAttachmentsChange}
          />
        </AssessmentScoreSheetHeader.Card>
        {(allowAttachments ||
          hasAttachments ||
          (assessment.assessment_template?.allow_comments &&
            assessment.comment)) && (
          <AssessmentScoreSheetHeader.Card>
            {(allowAttachments || hasAttachments) && (
              <>
                <AssessmentScoreSheetHeader.SmallHeading>
                  {LabelTypes.VENDOR} Attachments
                </AssessmentScoreSheetHeader.SmallHeading>
                <Uploader
                  model="assessment"
                  files={assessment.attachments}
                  description={`Attach any additional ${LabelTypes.VENDOR.toLowerCase()} files related to this assessment below.`}
                  url={apiPath}
                  disabled={!allowAttachments}
                  displayLabel={false}
                  onChange={onAttachmentsChange}
                />
                <AssessmentScoreSheetHeader.Card.Separator />
              </>
            )}
            {assessment.assessment_template?.allow_comments &&
              assessment.comment && (
                <>
                  <AssessmentScoreSheetHeader.SmallHeading>
                    {LabelTypes.VENDOR} Comments
                  </AssessmentScoreSheetHeader.SmallHeading>
                  <Comment>&ldquo;{assessment.comment}&rdquo;</Comment>
                </>
              )}
          </AssessmentScoreSheetHeader.Card>
        )}
        {!isEmpty(integrationMetrics) && (
          <MonitoringDataCard title="Monitoring Data">
            <Row $equalHeight break="mobile">
              {GloborgIntegrationOptions.map(({ value }) => {
                const data = integrationMetrics?.[value]
                if (!data) {
                  return null
                }
                return (
                  <Col key={value} sm={6} lg={4}>
                    <GloborgIntegrationOverviewCard
                      type={value}
                      data={data}
                      detailsUrl={`${assessment.vendor.url}?tab=riskProfile#section_${value}`}
                    />
                  </Col>
                )
              })}
            </Row>
          </MonitoringDataCard>
        )}
      </AssessmentScoreSheetHeader>
    </ThemeProvider>
  )
}

ScoreSheetHeader.propTypes = {
  assessment: PropTypes.object.isRequired,
  apiPath: PropTypes.string.isRequired,
  newContributorButtonVisible: PropTypes.bool,
  addNewContributorUrl: PropTypes.string.isRequired,
  onAssign: PropTypes.func.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  integrationMetrics: PropTypes.object,
  onTranslateResponsesChange: PropTypes.func.isRequired,
  onAttachmentsChange: PropTypes.func.isRequired,
  onInternalAttachmentsChange: PropTypes.func.isRequired,
  onApproveExtensionRequest: PropTypes.func.isRequired,
  onSubmitExpiredAssessment: PropTypes.func.isRequired,
}

export default ScoreSheetHeader
