import styled from 'styled-components'
import { ChevronDown } from '@styled-icons/fa-solid/ChevronDown'
import { ChevronUp } from '@styled-icons/fa-solid/ChevronUp'
import { Copy } from '@styled-icons/fa-solid/Copy'
import { Edit } from '@styled-icons/fa-solid/Edit'
import { ExternalLinkAlt } from '@styled-icons/fa-solid/ExternalLinkAlt'
import { PlusCircle } from '@styled-icons/fa-solid/PlusCircle'
import { TimesCircle } from '@styled-icons/fa-regular/TimesCircle'
import { TrashAlt } from '@styled-icons/fa-solid/TrashAlt'
import { Undo } from '@styled-icons/fa-solid/Undo'
import Icon from 'components-v2/atoms/Icon'
import Well from 'components-v2/atoms/Well'
import ResponseScoreSelect from 'components/shared/ResponseScoreSelect'
import { colors, mixins } from 'styles'

export const ScoreLimitWrapper = styled.div`
  display: flex;

  > *:first-child {
    flex: none;
  }
`

const RenderMarkers = styled(ResponseScoreSelect.Markers)`
  flex: none;
  width: 104px;
  margin-right: 15px;
`

const RenderLabel = styled(ResponseScoreSelect.Label)`
  text-align: left;
  font-size: 0.95em;
  padding-top: 0;
  ${mixins.truncate}
`

export const StyledResponseScoreSelect = styled(ResponseScoreSelect).attrs({
  RenderMarkers,
  RenderLabel,
})`
  display: inline-flex;
  align-items: center;
  margin-left: 25px;
  min-width: 0;
`

export const AutomationListRow = styled.div`
  border: 1px solid #ddd;
  padding-left: 10px;
  padding-top: 5px;
  display: flex;
  justify-content: space-between;
  ${(props) => props.selected && `background: ${colors.LIGHT_GREY};`}

  &:hover {
    background: ${colors.LIGHT_GREY};

    svg {
      visibility: visible;
    }
  }
`

export const AutomationListLabel = styled.span``

export const AutomationListItem = styled.div``

export const AutomationListBlock = styled.div`
  margin-top: 5px;
  flex: auto;
`

export const IconBlock = styled.div`
  margin: 0px 15px 0px 10px;
  flex: auto;
  text-align: right;
`
export const HiddenIconBlock = styled(IconBlock)`
  margin-top: 5px;
  visibility: hidden;
`

export const ButtonRow = styled.div`
  display: block;
  float: right;
`

export const AutomationTitleRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`

export const AutomationTitle = styled.span`
  flex: auto;
`

export const DetailDisplayRow = styled.div`
  font-weight: bold;
  display: flex;
  margin: 5px;
`
export const DetailDisplayLabel = styled.div`
  width: 70px;
  display: inline-table;
`
export const DetailDisplayBody = styled.div`
  font-weight: normal;
`

export const BlockWell = styled(Well)`
  margin-bottom: 0px;
  padding-bottom: 0px;
  padding-top: 15px;
`

export const DetailBlock = styled.blockquote`
  display: inline-grid;
  font-size: 0.9em;
  padding-top: 5px;
`
export const DetailTitleRow = styled.div`
  display: flex;
  margin-bottom: 5px;
`
export const DetailItemLabel = styled.span`
  width: 400px;
  font-weight: bold;
  margin-bottom: 5px;
`
export const DetailItemBody = styled.div`
  p {
    text-indent: 25px;
  }
`

export const SourceIconBar = styled.span`
  text-align: right;
  width: 100px;
  flex: auto;
`

const SourceBlockIcon = styled(Icon)`
  color: ${colors.MED_GREY};
  margin-left: 4px;

  &:hover,
  &:focus {
    color: ${colors.DARK_GREY};
  }
`
const BarIcon = styled(SourceBlockIcon)`
  width: 0.9em;
  height: 0.9em;
  margin-left: 10px;
`
export const EditIcon = styled(BarIcon).attrs({ icon: Edit })``
export const CopyIcon = styled(BarIcon).attrs({ icon: Copy })``
export const OpenExternalIcon = styled(BarIcon).attrs({
  icon: ExternalLinkAlt,
})``
export const TrashIcon = styled(BarIcon).attrs({ icon: TrashAlt })``
export const UndoIcon = styled(BarIcon).attrs({ icon: Undo })``
export const NewFileIcon = styled(BarIcon).attrs({ icon: PlusCircle })`
  margin-left: 5px;
  margin-right: 5px;
  color: ${colors.PRIMARY};
`
export const DeleteFileIcon = styled(BarIcon).attrs({ icon: TimesCircle })``
// styled-icons doesn't have this one
export const FileIcon = styled.i.attrs({ className: 'fa fa-file-text-o' })`
  width: 0.9em;
  height: 0.9em;
  margin-left: 5px;
  margin-right: 5px;
`

export const ExpandDownIcon = styled(SourceBlockIcon).attrs({
  icon: ChevronDown,
})``
export const CompressIcon = styled(SourceBlockIcon).attrs({ icon: ChevronUp })``

export const SelectionLabelRow = styled.div`
  display: flex;
`

export const DirtySource = styled.span`
  font-style: italic;
  color: ${colors.PRIMARY};
`

export const StubDetailRow = styled.div`
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
`
export const StubDetailLabel = styled.span`
  font-weight: bold;
  width: 150px;
`
export const StubLabelBody = styled.span`
  width: 250px;
`

export const StubDetailBody = styled.div`
  width: 325px;

  p {
    text-indent: 25px;
  }
`

export const InfoLabel = styled.div`
  font-size: 0.8em;
  font-style: italic;
`
export const FileList = styled.div`
  margin-top: 7px;
  margin-bottom: 7px;
`
export const FileRow = styled.div`
  display: flex;

  // not yet
  &:hover {
    svg {
      visibility: visible;
    }
  }
`
export const FileItem = styled.a`
  ${(props) => props.deleted && `text-decoration: line-through;`}
`
export const HiddenDelete = styled(IconBlock)`
  visibility: hidden;
  text-align: left;
  margin-left: 0px;
`

export const DetailPanelDivider = styled.hr`
  margin-top: 15px;
  margin-bottom: 0px;
`
export const SourceDivider = styled.hr`
  margin: 10px;
`
