import React from 'react'
import PropTypes from 'prop-types'
import addDays from 'date-fns/addDays'
import startOfDay from 'date-fns/startOfDay'
import isSameDay from 'date-fns/isSameDay'
import format from 'date-fns/format'
import isBefore from 'date-fns/isBefore'
import get from 'lodash/get'
import map from 'lodash/map'
import filter from 'lodash/filter'
import Tooltip from 'components-v2/molecules/Tooltip'
import ToolStatusDot from 'components/cme/ToolStatusDot'
import { toDate, toFromNowString } from 'utils/date'
import { SecurityObjectiveStatusTypes } from 'constants/index'
import {
  Wrapper,
  Header,
  Title,
  Body,
  Timeline,
  TItem,
  Day,
  Bar,
  Logo,
  ConnectButton,
  ObjectiveStatus,
} from './styles'

const TODAY = 'Today'

const ObjectiveCard = ({
  objective: { name, tool, status },
  onConnect,
  ...rest
}) => {
  const rawMetrics = React.useMemo(() => {
    const metrics = get(tool, 'metrics')
    const now = new Date()
    const start = startOfDay(addDays(new Date(), -6))

    // Generate map between day and metric value
    const metricMap = {}
    const parsed = map(metrics, (metric) => ({
      ...metric,
      time: toDate(metric.time),
    }))
    const filteredMetrics = filter(
      parsed,
      (metric) => isBefore(metric.time, now) && isBefore(start, metric.time),
    )
    filteredMetrics.forEach((metric) => {
      metricMap[format(metric.time, 'EEE')] = metric.value
    })

    // Generate metric array for one week
    return Array(7)
      .fill()
      .map((_, i) => {
        const date = addDays(start, i)
        const day = format(date, 'EEE')
        return {
          id: i,
          day,
          value: metricMap[day],
          today: isSameDay(date, now),
        }
      })
  }, [tool])
  return (
    <Wrapper {...rest} disabled={!tool}>
      <Header>
        <Title title={name}>{name}</Title>
        {tool && tool.lastActiveTime ? (
          <Tooltip parent={<ToolStatusDot status={tool.status} />}>
            Active as of{' '}
            {toFromNowString(tool.lastActiveTime, { addSuffix: true })}
          </Tooltip>
        ) : (
          <ToolStatusDot status={get(tool, 'status')} />
        )}
      </Header>
      <Body>
        <Timeline>
          {rawMetrics.map((metric, index) => (
            <TItem key={metric.id} index={index}>
              <Day today={metric.today}>
                {metric.today ? TODAY : metric.day}
              </Day>
              <Bar value={metric.value} />
            </TItem>
          ))}
        </Timeline>
        {tool ? (
          <Logo src={get(tool, 'logo')} />
        ) : status === SecurityObjectiveStatusTypes.NOT_CONNECTED ? (
          <ConnectButton onClick={onConnect}>Connect</ConnectButton>
        ) : (
          <ObjectiveStatus>{status.titleize()}</ObjectiveStatus>
        )}
      </Body>
    </Wrapper>
  )
}

ObjectiveCard.propTypes = {
  objective: PropTypes.object.isRequired,
  onConnect: PropTypes.func,
}

export default React.memo(ObjectiveCard)
