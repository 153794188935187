import React from 'react'
import styled from 'styled-components'

export const Table = styled.div`
  display: table;
  margin: 0 -15px;
`

export const Tr = styled(({ isHeading, ...rest }) => <div {...rest} />)`
  display: table-row;
  height: 47px;
  ${(props) => props.isHeading && 'background: #EEE;'}
`

export const THead = styled(Tr)`
  font-weight: bold;
  height: auto;
`

export const Td = styled.div`
  display: table-cell;
  padding: 4px 15px;
  border-bottom: 1px solid #ddd;
`

export const LabelTd = styled(Td)`
  width: 100%;
  padding-top: 12px;
  word-break: break-word;
`

export const NaturalKeyTd = styled(Td)`
  padding-top: 12px;
`

export const CustomKeyTd = styled(Td)`
  min-width: 180px;
`
