import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import some from 'lodash/some'
import sortBy from 'lodash/sortBy'
import { Formik } from 'formik'
import * as yup from 'yup'
import Button from 'components-v2/atoms/Button'
import FormField from 'components-v2/molecules/FormField'
import Tooltip from 'components-v2/molecules/Tooltip'
import Editable from 'components-v2/molecules/Editable'
import { IconLink } from './styles'

const View = ({
  items,
  defaultConfig,
  loaded,
  onAdd,
  onSetActive,
  onEditClick,
  onCopyClick,
  onDeleteClick,
  onViewDefaultClick,
  onNameChange,
  // ...rest
}) => {
  const itemList = sortBy(items, 'name')
  const validationSchema = yup.object().shape({
    newItem: yup.string().max(255, 'Maximum of 255 characters'),
  })
  const initialValues = {
    newItem: '',
  }
  const handleSubmit = (vals, { resetForm }) => {
    onAdd(vals)
    resetForm()
  }
  return (
    <div>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {(formProps) => (
          <form onSubmit={formProps.handleSubmit}>
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Active</th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {!loaded && (
                  <tr>
                    <td colSpan="2">
                      <i className="fa fa-spinner fa-spin" /> Loading...
                    </td>
                  </tr>
                )}
                {loaded &&
                  map(itemList, (item) => (
                    <tr key={item.id}>
                      <td>
                        <Editable
                          dataType="text"
                          mode="inline"
                          value={item.name}
                          onSubmit={(value) => onNameChange(item.id, value)}
                          emptyValueText=""
                        />
                      </td>
                      <td>
                        <input
                          type="radio"
                          id="calculator-enable"
                          name="active"
                          checked={item.enabled}
                          onChange={() => onSetActive(item.id)}
                        />
                      </td>
                      <td>
                        <Tooltip
                          parent={
                            <IconLink onClick={() => onEditClick(item.id)}>
                              <i className="fa fa-pencil" />
                            </IconLink>
                          }
                        >
                          Edit
                        </Tooltip>
                        <Tooltip
                          parent={
                            <IconLink onClick={() => onCopyClick(item.id)}>
                              <i className="fa fa-copy" />
                            </IconLink>
                          }
                        >
                          Make a copy
                        </Tooltip>
                        <Tooltip
                          parent={
                            <IconLink onClick={() => onDeleteClick(item.id)}>
                              <i className="fa fa-trash" />
                            </IconLink>
                          }
                        >
                          Delete
                        </Tooltip>
                      </td>
                    </tr>
                  ))}
                <tr key="default">
                  <td>
                    {defaultConfig.name} <i>(default)</i>
                  </td>
                  <td>
                    <input
                      type="radio"
                      id="calculator-enable"
                      name="active"
                      checked={!some(itemList, 'enabled')}
                      onChange={() => onSetActive('default')}
                    />
                  </td>
                  <td>
                    <Tooltip
                      parent={
                        <IconLink onClick={onViewDefaultClick}>
                          <i className="fa fa-search" />
                        </IconLink>
                      }
                    >
                      View
                    </Tooltip>
                    <Tooltip
                      parent={
                        <IconLink onClick={() => onCopyClick('default')}>
                          <i className="fa fa-copy" />
                        </IconLink>
                      }
                    >
                      Make a copy to customize
                    </Tooltip>
                  </td>
                </tr>

                <tr>
                  <td>
                    <FormField className="top10" name="newItem" />
                  </td>
                  <td>
                    <Button type="submit" className="top10">
                      <i className="fa fa-plus" />
                      Add
                    </Button>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </form>
        )}
      </Formik>
    </div>
  )
}

View.propTypes = {
  items: PropTypes.array,
  defaultConfig: PropTypes.object.isRequired,
  loaded: PropTypes.bool,
  onAdd: PropTypes.func,
  onSetActive: PropTypes.func,
  onEditClick: PropTypes.func,
  onCopyClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onViewDefaultClick: PropTypes.func,
  onNameChange: PropTypes.func,
}

export default View
