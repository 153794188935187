import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import filter from 'lodash/filter'
import has from 'lodash/has'
import clone from 'lodash/clone'
import keyBy from 'lodash/keyBy'
import Button from 'components-v2/atoms/Button'
import Select from 'components-v2/atoms/Select'
import { BaseField } from 'components-v2/molecules/FormField'
import Tooltip from 'components-v2/molecules/Tooltip'
import { Row, Col } from 'components-v2/organisms/Layout'
import { UserOrgRoles } from 'utils/roles'
import {
  useModal,
  useCurrent,
  useUpdateEffect,
  useComparePrevious,
} from 'hooks'
import { LabelTypes, VendorStatusTypes } from 'constants/index'
import SelectVendorsModal from '../SelectVendorsModal'
import {
  DetailsWrapper,
  SelectedVendorsTable,
  SelectedVendorsHead,
  SelectedVendorsTh,
  SelectedVendorTr,
  SelectedVendorTd,
  SelectedArchivedVendorTd,
  DeleteIconButton,
  RoleSelect,
  ClearButton,
} from './styles'

const SelectAllTypes = {
  ALL: 'all',
  SELECT_ONLY: 'select_only',
}

const VendorPermissionsField = ({
  name,
  componentClass,
  value,
  onChange,
  vendors,
  hasIndividualRoles,
  disabled,
  ...rest
}) => {
  const valueRef = useCurrent(value)
  const [openSelectModal, closeSelectModal] = useModal(SelectVendorsModal)

  const handleChangeSelectAll = (e) => {
    onChange(e.target.value === SelectAllTypes.ALL ? { all: true } : {})
  }

  const handleClearVendors = React.useCallback(() => {
    onChange({})
  }, [onChange])

  const handleDeleteVendor = (vendorId) => {
    if (has(value, vendorId)) {
      const newValue = clone(value)
      delete newValue[vendorId]
      onChange(newValue)
    }
  }

  const handleChangeRole = (vendorId, e) => {
    const newValue = value ? clone(value) : {}
    newValue[vendorId] = e.target.value
    onChange(newValue)
  }

  const allSelected = value.all
  const vendorsObj = keyBy(vendors, 'id')
  const filteredVendors = filter(vendors, (v) => !has(value, v.id))
  const vendorRoleOptions = filter(UserOrgRoles, (r) => r.vendor_option)

  const handleAdd = React.useCallback(() => {
    openSelectModal({
      vendors: filteredVendors,
      onSelect: (selected) => {
        const newValue = valueRef.current ? clone(valueRef.current) : {}
        selected.forEach((vendorId) => {
          newValue[vendorId] = true
        })
        onChange(newValue)
        closeSelectModal()
      },
    })
  }, [filteredVendors, openSelectModal, closeSelectModal, onChange])

  const allSelectedChanged = useComparePrevious(allSelected)
  useUpdateEffect(() => {
    if (
      allSelectedChanged &&
      !allSelected &&
      Object.keys(valueRef.current).length === 0
    ) {
      handleAdd()
    }
  }, [allSelectedChanged, allSelected, handleAdd])

  return (
    <BaseField {...rest}>
      <Row>
        <Col sm={6}>
          <Select
            value={
              allSelected ? SelectAllTypes.ALL : SelectAllTypes.SELECT_ONLY
            }
            disabled={disabled}
            onChange={handleChangeSelectAll}
          >
            <option value={SelectAllTypes.ALL}>All {LabelTypes.VENDORS}</option>
            <option value={SelectAllTypes.SELECT_ONLY}>
              Select {LabelTypes.VENDORS} Only
            </option>
          </Select>
        </Col>
      </Row>
      {!allSelected && (
        <DetailsWrapper>
          {!disabled && <Button onClick={handleAdd}>Add Vendors</Button>}
          {Object.keys(value).length > 0 && (
            <SelectedVendorsTable>
              {!disabled && (
                <ClearButton onClick={handleClearVendors}>Clear</ClearButton>
              )}
              {hasIndividualRoles && (
                <SelectedVendorsHead>
                  <SelectedVendorsTh>{LabelTypes.VENDORS}</SelectedVendorsTh>
                  <SelectedVendorsTh>Role</SelectedVendorsTh>
                </SelectedVendorsHead>
              )}
              {map(value, (role, vendorId) => {
                if (!vendorsObj[vendorId]) {
                  return null
                }

                return (
                  <SelectedVendorTr key={vendorId}>
                    {vendorsObj[vendorId].status ===
                      VendorStatusTypes.ACTIVE && (
                      <SelectedVendorTd>
                        {vendorsObj[vendorId].name}
                      </SelectedVendorTd>
                    )}
                    {vendorsObj[vendorId].status ===
                      VendorStatusTypes.ARCHIVED && (
                      <SelectedArchivedVendorTd>
                        <Tooltip
                          parent={<span>{vendorsObj[vendorId].name}</span>}
                          position="left"
                        >
                          This {LabelTypes.VENDOR.toLowerCase()} has been
                          archived. Deleting this user&apos;s access to it
                          can&apos;t be undone without re-activating the{' '}
                          {LabelTypes.VENDOR.toLowerCase()}.
                        </Tooltip>
                      </SelectedArchivedVendorTd>
                    )}
                    {hasIndividualRoles && !disabled && (
                      <SelectedVendorTd>
                        <RoleSelect
                          // value={role === true ? UserVendorRoleTypes.MANAGER: role}
                          value={
                            role === true ? UserOrgRoles.manager.value : role
                          }
                          onChange={(e) => handleChangeRole(vendorId, e)}
                        >
                          {map(vendorRoleOptions, (o) => (
                            <option key={o.value} value={o.value}>
                              {o.label}
                            </option>
                          ))}
                        </RoleSelect>
                      </SelectedVendorTd>
                    )}
                    {!disabled && (
                      <DeleteIconButton
                        onClick={() => handleDeleteVendor(vendorId)}
                      />
                    )}
                  </SelectedVendorTr>
                )
              })}
            </SelectedVendorsTable>
          )}
        </DetailsWrapper>
      )}
    </BaseField>
  )
}

VendorPermissionsField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.any,
  componentClass: PropTypes.string,
  value: PropTypes.object,
  helpMessage: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  showError: PropTypes.bool,
  errorMessage: PropTypes.string,
  vendors: PropTypes.array,
  hasIndividualRoles: PropTypes.bool,
}

VendorPermissionsField.defaultProps = {
  value: {
    all: true,
  },
  vendors: [],
  hasIndividualRoles: false,
}

export default VendorPermissionsField
