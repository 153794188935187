import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import Button from 'components-v2/atoms/Button'
import FormField from 'components-v2/molecules/FormField'
import { useUsersFetcher } from 'apis'
import { ButtonsWrapper } from './styles'

const AssignForm = ({
  assigneeId = '',
  note = '',
  close,
  onSubmit,
  ...rest
}) => {
  const { data: users } = useUsersFetcher({ params: { assignable: true } })

  const initialValues = useMemo(
    () => ({
      assigneeId,
      note,
    }),
    [assigneeId, note],
  )

  const handleSubmit = useCallback(
    async (e) => {
      try {
        await onSubmit(e)
        // Automatically close on success
        close()
        // eslint-disable-next-line no-empty
      } catch {}
    },
    [onSubmit, close],
  )

  return (
    <Formik {...rest} initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values, setFieldValue }) => (
        <Form>
          <FormField
            name="assigneeId"
            type={FormField.types.SELECT}
            options={users?.map((user) => ({
              label: user.name,
              value: user.id,
            }))}
            placeholder="unassigned"
            controlProps={{
              placeholderSelectable: true,
            }}
            onChange={() => setFieldValue('note', '', false)}
          />
          <FormField
            name="note"
            label="Note"
            placeholder="optional note"
            disabled={!values.assigneeId}
            controlProps={{ maxLength: 255 }}
          />
          <ButtonsWrapper>
            <Button type="submit" color="primary" size="small">
              Save
            </Button>
            <Button size="small" onClick={close}>
              Cancel
            </Button>
          </ButtonsWrapper>
        </Form>
      )}
    </Formik>
  )
}

AssignForm.propTypes = {
  assigneeId: PropTypes.number,
  note: PropTypes.string,
  close: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default AssignForm
