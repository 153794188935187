import styled from 'styled-components'
import Dialog from 'components-v2/molecules/Dialog'

export const Footer = styled(Dialog.Footer)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
`

export const FooterSpacer = styled.div`
  flex-grow: 1;
  min-width: 5px;
`
