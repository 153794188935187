import React from 'react'
import DateRangeFilter from 'components-v2/molecules/DateRangeFilter'

const DateRangeFilterEx = ({ datePickerProps, ...rest }) => (
  <DateRangeFilter
    {...rest}
    datePickerProps={{ portalId: 'datepicker-portal', ...datePickerProps }}
  />
)

DateRangeFilterEx.propTypes = DateRangeFilter.propTypes

export default DateRangeFilterEx
