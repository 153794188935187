import { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import useRequestAssessmentExtension from 'components/vendor/shared/useRequestAssessmentExtension'
import { useVendorAssessmentsFetcher } from 'apis'

export default () => {
  const requestExtension = useRequestAssessmentExtension()
  const queryClient = useQueryClient()

  return useCallback(
    (assessment) => {
      const onSuccess = () =>
        queryClient.invalidateQueries(useVendorAssessmentsFetcher.queryKey())
      requestExtension(assessment, onSuccess)
    },
    [requestExtension, queryClient],
  )
}
