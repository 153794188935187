import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'components-v2/atoms/Icon'
import { Wrapper, Title, IconsWrapper, IconWrapper } from './styles'

const Widget = ({
  title,
  description,
  href,
  categoryIcon,
  fileIcon,
  ...rest
}) => (
  <Wrapper
    {...rest}
    href={href}
    as={href ? 'a' : undefined}
    role="button"
    tabIndex={href ? undefined : 0}
  >
    <Title>{title}</Title>
    <p>{description}</p>
    <IconsWrapper>
      <IconWrapper>
        <Icon icon={categoryIcon} />
      </IconWrapper>
      <IconWrapper>
        <Icon icon={fileIcon} />
      </IconWrapper>
    </IconsWrapper>
  </Wrapper>
)

Widget.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  href: PropTypes.string,
  color: PropTypes.string,
  categoryIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  fileIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
}

export default React.memo(Widget)
