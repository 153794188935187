import styled from 'styled-components'
import { PlusCircle } from '@styled-icons/fa-solid/PlusCircle'
import Icon from 'components-v2/atoms/Icon'
import A from 'components-v2/atoms/A'
import { Col } from 'components-v2/organisms/Layout'
import { colors, sizes } from 'styles'

export const Wrapper = styled.div`
  color: ${colors.BODY_TEXT};
`

export const ManageLink = styled(A).attrs({ underline: true })`
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 900;
`

export const PlusCircleIcon = styled(Icon).attrs({ icon: PlusCircle })``

export const StyledCol = styled(Col)`
  > * {
    margin-bottom: ${sizes.GRID_HGUTTER}px;
  }
`

export const ColTitle = styled.h6`
  font-size: 14px;
  letter-spacing: 0;
  &&& {
    flex: none;
    margin: 0 0 10px 0;
  }
`
