import { useEffect, useState, useMemo, useCallback } from 'react'
import mapValues from 'lodash/mapValues'
import mapKeys from 'lodash/mapKeys'
import defaults from 'lodash/defaults'
import findKey from 'lodash/findKey'
import queryString from 'query-string'
import { toDate, toLocalTimeString } from 'utils/date'
import useCurrent from './useCurrent'

const SUPPORTED_PARAMS_TYPES = [Number, String, Boolean, Date]

/*
  config = {
    activeTab: {
      defaultValue: 'list',
      type: String,
      queryKey: 'tab',
      isArray: false,
    }
  }
*/
export default function useQueryParams(config = {}) {
  if (config) validateTypes(config)

  /**
   * The main idea of this hook is to make things response to change of `window.location.search`,
   * so no need for introducing new state (in the mean time).
   * Whenever `window.location.search` is changed but  not cause re-render, call `forceUpdate()`.
   * Whenever the component - user of this hook - re-render, this hook should return
   * the query object that corresponse to the current `window.location.search`
   */
  const [, forceUpdate] = useState()
  const configRef = useCurrent(config)

  const locationSearch = window.location.search
  const params = useMemo(
    () => parseRawQueryString(locationSearch, config),
    [locationSearch, config],
  )

  // if 'replace' is set, it will remove all existing query parameters not specified in 'params'
  const setParams = useCallback((newParams, replace) => {
    let newRawQueryParams = generateRawQueryParams(newParams, configRef.current)

    if (!replace) {
      const parsedSearch = parseQueryString(window.location.search)
      newRawQueryParams = {
        ...parsedSearch,
        ...newRawQueryParams,
      }
    }
    const newQueryString = queryString.stringify(newRawQueryParams, {
      skipNull: true,
      skipEmptyString: true,
    })
    const url = newQueryString
      ? `${window.location.pathname}?${newQueryString}`
      : window.location.pathname

    if (window.location.href !== url) {
      // eslint-disable-next-line no-restricted-globals
      history.replaceState({}, null, url)
      forceUpdate({})
    }
  }, [])

  useEffect(() => {
    const onPopState = () => {
      forceUpdate({})
    }
    window.addEventListener('popstate', onPopState)
    return () => {
      window.removeEventListener('popstate', onPopState)
    }
  }, [])

  return [params, setParams]
}

function isNoneEmptyPrimitiveArray(input) {
  return (
    Array.isArray(input) &&
    input.length > 0 &&
    input.every(
      (item) =>
        typeof item === 'number' ||
        typeof item === 'string' ||
        typeof item === 'boolean',
    )
  )
}

function validateTypes(config = {}) {
  const isValidTypes = Object.values(config).every(
    ({ type = String }) =>
      SUPPORTED_PARAMS_TYPES.includes(type) ||
      isNoneEmptyPrimitiveArray(type) ||
      typeof type === 'function',
  )

  if (!isValidTypes) {
    throw new Error(
      `Unsupported param types. Must be one of [${SUPPORTED_PARAMS_TYPES.map(
        (item) => item.name,
      ).join(', ')}]`,
    )
  }
}

const booleanValues = {
  true: true,
  false: false,
}

function parseRawQueryParam(value, type) {
  if (value === undefined || !type) return value

  if (type === Number) {
    const parsed = Number(value)
    return Number.isNaN(parsed) ? undefined : parsed
  }
  if (type === Boolean) {
    return booleanValues[value]
  }
  if (type === Date) {
    return toDate(value, 'local')
  }
  if (Array.isArray(type)) {
    // eslint-disable-next-line eqeqeq
    return type.find((item) => item == value)
  }
  if (typeof type === 'function') {
    return type(value)
  }

  return value
}

function parseRawQueryString(locationSearch, config) {
  const rawParams = parseQueryString(locationSearch)

  // Correctify keys
  const rawParamsWithCorrectKey = mapKeys(
    rawParams,
    (_, key) => findKey(config, (e) => e.queryKey === key) || key,
  )

  // Parse values with type config
  const results = mapValues(rawParamsWithCorrectKey, (value, key) => {
    const { type, isArray } = config[key] || {}
    if (!isArray) {
      const firstValue = Array.isArray(value) ? value[0] : value
      return parseRawQueryParam(firstValue, type)
    }
    const values = Array.isArray(value) ? value : [value]
    const parsed = values
      .map((e) => parseRawQueryParam(e, type))
      .filter((e) => e !== undefined)
    return parsed.length > 0 ? parsed : undefined
  })

  // With default values
  return defaults(
    results,
    mapValues(config, (e) => e.defaultValue),
  )
}

function formatQueryParam(value, paramConfig) {
  const { type, dateFormat } = paramConfig
  if (value === undefined || !type) return value

  if (type === Date) {
    return toLocalTimeString(value, dateFormat, undefined, 'local')
  }

  return value
}

function generateRawQueryParams(params, config) {
  // Format values
  const results = mapValues(params, (value, key) => {
    const paramConfig = config[key] || {}
    if (!paramConfig.isArray) {
      return formatQueryParam(value, paramConfig)
    }
    return value?.map((e) => formatQueryParam(e, paramConfig))
  })
  // Correctify keys
  return mapKeys(results, (_, key) => config[key]?.queryKey || key)
}

function parseQueryString(str) {
  const rawParams = queryString.parse(str)

  // Remove bracket in case of bracket array format (i.e. foo[]=1&foo[]=2&foo[]=3)
  return mapKeys(rawParams, (_, key) => key.replace(/\[\]$/g, ''))
}
