import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import truncate from 'lodash/truncate'
import Tooltip from 'components-v2/molecules/Tooltip'
import { getResponseScoreLabelByValue } from 'lib/assessment'
import { SortOrderTypes } from 'constants/index'
import { useAuthorizations } from 'hooks'

import {
  AutomationListRow,
  AutomationListItem,
  AutomationListBlock,
  HiddenIconBlock,
  TrashIcon,
} from './styles'

const ExistingAutomationsTable = ({
  element,
  ratingLabelSet,
  automationIndex,
  currentAutomationGroupID,
  setCurrentAutomationGroupID,
  onDeleteAutomationGroup,
}) => {
  const [userCanEdit] = useAuthorizations('update')
  const choiceMap = keyBy(element.choices, 'id')

  const renderScoreTrigger = (oper, score) => (
    <li>
      Score &nbsp;{oper} &nbsp;
      {getResponseScoreLabelByValue(score, ratingLabelSet)}
    </li>
  )

  const renderChoiceTrigger = (choices) => (
    <>
      {map(choices, (c) => (
        <li key={c}>{choiceMap[c].text}</li>
      ))}
    </>
  )

  const renderTriggerList = (qa) => (
    <ul>
      {qa.trigger_type === 'score'
        ? renderScoreTrigger(qa.score_oper, qa.score)
        : renderChoiceTrigger(qa.choices)}
    </ul>
  )

  const renderClause = (qa) => {
    const clauseName = get(qa, 'automation_source.name', '')
    return (
      <AutomationListItem>
        <i>AutoReport Section:</i> &nbsp;
        {truncate(clauseName, { length: 60, separator: ' ' })}
      </AutomationListItem>
    )
  }

  const renderStub = (qa) => {
    const stubLabel = get(qa, 'automation_source.label', '')
    return (
      <AutomationListItem>
        <i>Issue:</i> &nbsp;
        {truncate(stubLabel, { length: 75, separator: ' ' })}
      </AutomationListItem>
    )
  }

  const sortIndex = (autoIndex) =>
    // make sure the pairs are listed before the singles
    orderBy(
      autoIndex,
      ['count', 'primary_type'],
      [SortOrderTypes.DESC, SortOrderTypes.DESC],
    )

  return (
    <>
      <h6>Current Automations</h6>
      {map(sortIndex(automationIndex), (autoGroup) => {
        const groupKey = autoGroup.primary.group_key
        const thisLabel =
          autoGroup.count > 1 ? 'automation group' : 'automation'
        return (
          <AutomationListRow
            key={groupKey}
            onClick={() => setCurrentAutomationGroupID(groupKey)}
            selected={groupKey === currentAutomationGroupID}
          >
            <AutomationListBlock>
              {autoGroup.issue_stub && renderStub(autoGroup.issue_stub)}
              {autoGroup.clause && renderClause(autoGroup.clause)}
              {renderTriggerList(autoGroup.primary)}
            </AutomationListBlock>
            <HiddenIconBlock>
              {userCanEdit && (
                <Tooltip
                  parent={
                    <TrashIcon
                      tabIndex={0}
                      onClick={(e) => {
                        e.stopPropagation()
                        onDeleteAutomationGroup(
                          autoGroup.primary.id,
                          autoGroup.count > 1,
                        )
                      }}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13 || e.keyCode === 32) {
                          onDeleteAutomationGroup(
                            autoGroup.primary.id,
                            autoGroup.count > 1,
                          )
                        }
                      }}
                    />
                  }
                >
                  Delete this {thisLabel} from this template and all related
                  assessments
                </Tooltip>
              )}
            </HiddenIconBlock>
          </AutomationListRow>
        )
      })}
    </>
  )
}

ExistingAutomationsTable.propTypes = {
  element: PropTypes.object.isRequired,
  ratingLabelSet: PropTypes.string,
  automationIndex: PropTypes.object.isRequired,
  currentAutomationGroupID: PropTypes.string,
  setCurrentAutomationGroupID: PropTypes.func.isRequired,
  onDeleteAutomationGroup: PropTypes.func.isRequired,
}

export default ExistingAutomationsTable
