import styled, { css } from 'styled-components'
import Label from 'components-v2/atoms/Label'
import { mixins } from 'styles'

export const RiskRatingLabel = styled(Label)`
  width: 40px;
  font-size: 0.7em;
`

export const DomainScoreLabel = styled.div`
  font-weight: bold;
  font-size: 0.9em;
  ${(props) =>
    css`
      color: ${props.color};
    `}
`

export const VendorName = styled.span`
  white-space: nowrap;
  font-weight: bold;
  font-size 14px;
`

export const AssessmentName = styled.span`
  white-space: nowrap;
  font-size: 14px;
  ${mixins.truncate}
`
