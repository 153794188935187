import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import Dialog from 'components-v2/molecules/Dialog'
import keyBy from 'lodash/keyBy'
import CircleProgressBar from 'components-v2/molecules/CircleProgressBar'
import { Row, Col } from 'components-v2/organisms/Layout'
import { GloborgIntegrationOptions } from 'constants/index'
import { MetricCard, Label, Metric, MetricIcon } from './styles'

const theme = {
  hGutter: 25,
  vGutter: 24,
}

const IntegrationMetricsModal = ({
  metricType,
  metricKeys,
  formattedIntegrationMetrics,
  onClose,
  ...rest
}) => {
  const GloborgIntegrationOptionsObj = keyBy(GloborgIntegrationOptions, 'value')
  const metricName = GloborgIntegrationOptionsObj[metricType].name
  return (
    <Dialog onClose={onClose} {...rest}>
      <Dialog.Header onClose={onClose}>{metricName} Scores</Dialog.Header>
      <Dialog.Content>
        <ThemeProvider theme={theme}>
          <Row $equalHeight break="mobile">
            {metricKeys.map((code) => {
              const metric = formattedIntegrationMetrics[metricType].find(
                (m) => m.code === code,
              )

              if (!metric) {
                return ''
              }

              return (
                <Col lg={4} sm={4} xs={6} key={code}>
                  <MetricCard>
                    <Label>{metric.category}</Label>
                    <CircleProgressBar
                      progress={metric.value}
                      steps={metric.total}
                      strokeColor={metric.color}
                      radius={60}
                      strokeWidth={4}
                      trackStrokeWidth={4}
                      pointerRadius={5}
                      pointerStrokeWidth={4}
                      initialAnimation
                      initialAnimationDelay={500}
                    >
                      <Metric color={metric.color}>{metric.value}</Metric>
                      {metric.icon && (
                        <MetricIcon icon={metric.icon} color={metric.color} />
                      )}
                    </CircleProgressBar>
                  </MetricCard>
                </Col>
              )
            })}
          </Row>
        </ThemeProvider>
      </Dialog.Content>
    </Dialog>
  )
}

IntegrationMetricsModal.propTypes = {
  metricType: PropTypes.string.isRequired,
  metricKeys: PropTypes.array.isRequired,
  formattedIntegrationMetrics: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default IntegrationMetricsModal
