import styled from 'styled-components'
import { mixins } from 'styles'

export const HeaderWrapper = styled.div`
  padding: 0 12px 0 12px;
`

export const Title = styled.h3`
  text-align: center;
  @media print {
    text-align: left;
  }
`

export const Subtitle = styled.h4`
  margin-bottom: 1.5em;
  text-align: center;

  @media print {
    text-align: left;
  }
`

export const ExtLogo = styled.img`
  ${mixins.showPrint};
  float: right;
  margin-left: 15px;
  max-height: 100px;
  max-width: 250px;
`
