import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components-v2/atoms/Button'
import { getPageUrl } from 'utils/url'
import useDisableTwoFactorAuth from '../../shared/useDisableTwoFactorAuth'

const twoFactorAuthPageUrl = getPageUrl('usersTwoFactorAuths')

const Security = ({ currentUser }) => {
  const disableTwoFactorAuthDialog = useDisableTwoFactorAuth()

  return (
    <div>
      <h3>Two-Factor Authentication</h3>
      <p>
        Increase your account&apos;s security by enabling Two-Factor
        Authentication (2FA).
      </p>
      <p>
        Status:{' '}
        <span className="strong">
          {currentUser.otp_required_for_login ? 'Enabled' : 'Disabled'}
        </span>
      </p>
      <p>
        {currentUser.otp_required_for_login ? (
          <Button color="danger" onClick={disableTwoFactorAuthDialog}>
            Disable two-factor authentication
          </Button>
        ) : (
          <Button href={twoFactorAuthPageUrl} color="primary">
            Enable two-factor authentication
          </Button>
        )}
      </p>
    </div>
  )
}

Security.propTypes = {
  currentUser: PropTypes.object.isRequired,
}

export default Security
