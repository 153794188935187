import React from 'react'
import ModalManager from 'lib/modal'

/**
 * Custom hook to manage a modal component's lifecycle using `ModalManager`.
 * It returns functions to open and close the modal, and ensures the modal is properly closed
 * when the parent component unmounts or when the `modalComponent` changes.
 *
 * @param {React.ComponentType} modalComponent - The modal component that will be managed.
 * @returns {Array} An array of modal functions: the first function opens the modal, and the second function closes it.
 *
 * @example
 * const [openModal, closeModal] = useModal(MyModalComponent);
 *
 * // Open the modal
 * openModal({ someProp: value });
 *
 * // Close the modal
 * closeModal();
 */

export default function useModal(modalComponent) {
  const functions = React.useMemo(
    () => ModalManager.getModalFunctions(modalComponent),
    [modalComponent],
  )

  // Make sure to close the modal when parent component is destroyed or "modalComponent" is changed
  React.useEffect(
    () => () => {
      functions[1]()
    },
    [functions],
  )

  return functions
}
