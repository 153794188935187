import styled from 'styled-components'
import Checkbox from 'components-v2/atoms/Checkbox'

export const FilterWrapper = styled.div`
  text-align: right;
  margin-bottom: 20px;
`

export const Placeholder = styled.div`
  padding-top: 30px;
  text-align: center;
`

export const OptionCheckbox = styled(Checkbox)`
  margin-right: 10px;
`
