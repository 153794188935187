import React from 'react'
import mapValues from 'lodash/mapValues'
import { useModal, useGlobalLoader } from 'hooks'
import NotificationManager from 'lib/notifications'
import { useDuplicateClientAssessmentTemplate } from 'apis'
import DuplicateAssessmentTemplateModal from './DuplicateAssessmentTemplateModal/DuplicateAssessmentTemplateModal'
import DuplicateAssessmentTemplateConfirmModal from './DuplicateAssessmentTemplateModal/DuplicateAssessmentTemplateConfirmModal'

export default function useDuplicateAssessmentTemplate() {
  const { mutateAsync: duplicateClientAssessmentTemplate } =
    useDuplicateClientAssessmentTemplate()
  const [openModal, closeModal] = useModal(DuplicateAssessmentTemplateModal)
  const [openConfirmModal] = useModal(DuplicateAssessmentTemplateConfirmModal)
  const [showLoader, hideLoader] = useGlobalLoader()

  return React.useCallback(
    ({
      assessmentTemplate,
      hasAutomations,
      title = 'Duplicating Template',
      api = duplicateClientAssessmentTemplate,
    }) => {
      const onConfirmSubmit = async (formData) => {
        const directives = mapValues(
          formData.automation_source_directives,
          (val) => (val ? 'transfer' : 'clone'),
        )
        const loaderId = showLoader({ text: 'Duplicating Template...' })
        try {
          const response = await api({
            id: assessmentTemplate.id,
            data: {
              include_automations: true,
              automation_source_directives: directives,
            },
          })

          window.location.href = response.output.url
        } catch (err) {
          NotificationManager.error()
        }
        hideLoader(loaderId)
      }

      const onSubmit = async (formData) => {
        const loaderId = showLoader({ text: 'Duplicating Template...' })
        try {
          const response = await api({
            id: assessmentTemplate.id,
            data: {
              include_automations: formData.includeAutomations,
            },
          })

          if (response.result === 'duplicated') {
            window.location.href = response.output.url
            return
          }

          if (response.result !== 'analyzed') {
            throw response
          }

          closeModal()
          const analysis = response.output
          openConfirmModal({
            title,
            assessmentTemplate,
            analysis,
            onSubmit: onConfirmSubmit,
          })
        } catch (err) {
          NotificationManager.error()
        }
        hideLoader(loaderId)
      }

      openModal({
        title,
        assessmentTemplate,
        hasAutomations,
        onSubmit,
      })
    },
    [openModal, closeModal, openConfirmModal, showLoader, hideLoader],
  )
}
