import styled, { css } from 'styled-components'

export const Indicator = styled.i`
  position: absolute;
  left: 0;
  top: 50%;
  color: #aaa;
  font-size: 16px;
  width: 1em;
  height: 1em;
  display: block;
  vertical-align: top;
  margin-top: -1px;
  transform: translateY(-50%);
  border-radius: 2px;
  border: 1px solid currentColor;
`

export const Label = styled.label`
  display: ${(props) => (props.inline ? 'inline-block' : 'block')};
  position: relative;
  margin: 0;
  font-weight: inherit;
  padding-left: ${(props) => (props.hasLabel ? '1.4em' : '1em')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  ${(props) => props.disabled && `opacity: .5;`}

  ${(props) =>
    !props.hasLabel &&
    css`
      &:after {
        display: inline-block;
        content: ' ';
      }
    `}

  input {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    z-index: -1;
    margin: 0;

    &:checked ~ ${Indicator} {
      ${(props) => props.color && `color: ${props.color};`}
      background-color: currentColor;
      box-shadow: inset 0px 0px 0px 1px #fff;

      // Background color printing is disabled in Firefox and IE browsers by default
      // Let's add a workaround to fix it using border css
      @media print {
        border-width: 0.5em;
      }
    }

    &:focus ~ ${Indicator}:after {
      position: absolute;
      content: ' ';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: -1px;
      border-radius: inherit;
      box-shadow: 0 0 0 3px ${(props) => props.color};
      opacity: 0.2;
    }
  }
`

export const Input = styled.input``
