import styled from 'styled-components'
import { colors } from 'styles'

export const UserCell = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: 8px;
  }
`

export const TopSection = styled.div`
  margin-bottom: 30px;
`

export const DisabledText = styled.span`
  color: ${colors.GREY};
`
