import React from 'react'
import PropTypes from 'prop-types'
import sum from 'lodash/sum'
import ProgressBar from 'components-v2/molecules/ProgressBar'

export default function AsssessmentReviewScoresProgressBar({
  reviewScores,
  ...rest
}) {
  const assessmentReviewTotalCount = sum(reviewScores?.map((e) => e.count))
  if (!reviewScores?.length) {
    return null
  }
  return (
    <ProgressBar {...rest}>
      {reviewScores.map((element, index) => {
        const roundedProgress =
          Math.round((element.count / assessmentReviewTotalCount) * 1000) / 10
        return (
          <ProgressBar
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            color={element.color}
            max={assessmentReviewTotalCount}
            now={element.count}
            tooltip={`${element.label}: ${element.count} (${roundedProgress}%)`}
          />
        )
      })}
    </ProgressBar>
  )
}

AsssessmentReviewScoresProgressBar.propTypes = {
  reviewScores: PropTypes.array,
}
