import { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useClientGlobalProfilesSummaryFetcher } from 'apis'
import useCreateVendor from 'components/client/shared/useCreateVendor'

export default function useCreateVendorFromGloborg(globalOrgsRef) {
  const queryClient = useQueryClient()
  const createVendor = useCreateVendor()

  return useCallback(
    (globorgId) => {
      const globorg = globalOrgsRef.current.find((e) => e.id === globorgId)
      if (!globorg) {
        return
      }
      createVendor({
        name: globorg.name,
        domain: globorg.domain,
        showGloborg: true,
        domainReadOnly: true,
        onCreated: () => {
          queryClient.invalidateQueries(
            useClientGlobalProfilesSummaryFetcher.queryKey(),
          )
        },
      })
    },
    [queryClient, createVendor],
  )
}
