import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components-v2/atoms/Button'
import { toLocalTimeString } from 'utils/date'
import { pluralize } from 'utils/string'
import { Wrapper, Heading, Name, RequestedAt } from './styles'

const PendingRequestsCard = ({ clientName, requestedAt, count, onReview }) => (
  <Wrapper bordered>
    <Heading>Pending Requests</Heading>
    <Name>{clientName}</Name>
    <RequestedAt>
      Request sent on {toLocalTimeString(requestedAt, 'MMM d, yy')}
    </RequestedAt>
    <Button fullWidth color="primary" onClick={onReview}>
      {`Review ${pluralize(count, 'Request')}`}
    </Button>
  </Wrapper>
)

PendingRequestsCard.propTypes = {
  clientName: PropTypes.string.isRequired,
  requestedAt: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  onReview: PropTypes.func.isRequired,
}

export default PendingRequestsCard
