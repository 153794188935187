import { useCallback } from 'react'
import NotificationManager from 'lib/notifications'
import { useModal, useGlobalLoader } from 'hooks'
import { useCreateClientProject } from 'apis'
import { getPageUrl } from 'utils/url'
import CreateProjectModal from './CreateProjectModal'

export default function useCreateProject() {
  const { mutateAsync: createClientProject } = useCreateClientProject()
  const [openModal, closeModal] = useModal(CreateProjectModal)
  const [showLoader, hideLoader] = useGlobalLoader()

  return useCallback(() => {
    const onSubmit = async ({ name }) => {
      const loaderId = showLoader()
      try {
        const response = await createClientProject({
          data: { project: { name } },
        })
        window.location = getPageUrl('clientProjectEdit', {
          id: response.id,
        })
      } catch (error) {
        NotificationManager.error()
      }
      hideLoader(loaderId)
    }
    openModal({ onSubmit })
  }, [openModal, closeModal, showLoader, hideLoader])
}
