import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components-v2/atoms/Button'
import { BaseField } from 'components-v2/molecules/FormField'
import SearchableSelect from 'components-v2/molecules/SearchableSelect'
import RiskTierCalculatorModal from 'components/elements/risk_tier_calculator'
import { RiskTierOptions } from 'constants/index'
import { useModal } from 'hooks'
import { Row, Option, SingleValue } from './styles'

const VendorRiskTierFormField = ({
  name,
  value = {},
  onChange,
  calculateButton: CalculateButton = Button,
  calculatorRequired,
  size,
  showError,
  ...rest
}) => {
  const [openModal, closeModal] = useModal(RiskTierCalculatorModal)
  const handleRiskTierChange = (riskTier) => {
    onChange({ ...value, riskTier })
  }

  const handleRiskTierCalculateClick = () => {
    openModal({
      answers: value.riskTierAnswers,
      onClose: closeModal,
      onSave: ({ calculatorId, riskTier, riskTierAnswers }) => {
        onChange({ calculatorId, riskTier, riskTierAnswers })
        closeModal()
      },
    })
  }

  return (
    <BaseField {...rest} size={size} showError={showError}>
      <Row>
        <SearchableSelect
          name={name}
          value={value.riskTier}
          options={RiskTierOptions}
          disabled={calculatorRequired}
          size={size}
          onChange={handleRiskTierChange}
          components={{
            Option,
            SingleValue,
          }}
          isClearable
          error={showError}
        />
        <CalculateButton size={size} onClick={handleRiskTierCalculateClick}>
          <i className="fa fa-calculator" />
          Calculate
        </CalculateButton>
      </Row>
    </BaseField>
  )
}

VendorRiskTierFormField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.shape({
    riskTier: PropTypes.number,
    riskTierAnswers: PropTypes.array,
  }),
  size: PropTypes.string,
  showError: PropTypes.bool,
  calculatorRequired: PropTypes.bool,
  calculateButton: PropTypes.elementType,
  onChange: PropTypes.func.isRequired,
}

export default VendorRiskTierFormField
