import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import keyBy from 'lodash/keyBy'
import Slider from 'rc-slider'
import SimpleDialog from 'components-v2/molecules/SimpleDialog'
import { RiskTierTypes, RiskTierOptions } from 'constants/index'
import {
  Row,
  NoteRow,
  TierRow,
  TierHeadRow,
  LeftCol,
  LeftColHead,
  MidCol,
  RightCol,
} from './styles'

const RiskTierOptionsObj = keyBy(RiskTierOptions, 'value')
const rangeProps = {
  range: true,
  // rc-slider doesn't apply track colors to the first or last segments, so we have to
  // set the main rail color to our first segment color, and trick it by adding a
  // zero-width last segment so that the last *visible* segment is the alternate color
  // we actually want.
  count: 3,
  pushable: true,
  // first segment shows bare rail color
  railStyle: {
    backgroundColor: RiskTierOptionsObj[RiskTierTypes.TIER4].color,
  },
  // next three segments
  trackStyle: [
    { backgroundColor: RiskTierOptionsObj[RiskTierTypes.TIER3].color },
    { backgroundColor: RiskTierOptionsObj[RiskTierTypes.TIER2].color },
    { backgroundColor: RiskTierOptionsObj[RiskTierTypes.TIER1].color },
  ],
  handleStyle: [
    { width: '14px', height: '14px', backgroundColor: 'black' },
    { width: '14px', height: '14px', backgroundColor: 'black' },
    { width: '14px', height: '14px', backgroundColor: 'black' },
    // this is our placeholder for the zero-width final segment,
    // so you're not supposed to see it or be able to grab it.
    { width: '0px', height: '0px', backgroundColor: 'white' },
  ],
}

const getDefaultTiers = (max) => [
  Math.round(max * 0.1),
  Math.round(max * 0.25),
  Math.round(max * 0.5),
]

const EditTireThresholdsModal = ({ tiers, max, onSave, onClose, ...rest }) => {
  const defaultValue = React.useMemo(() => {
    const temp = isEmpty(tiers) ? getDefaultTiers(max) : tiers
    return [...temp, max]
  }, [tiers, max])
  const [value, setValue] = React.useState(defaultValue)
  const displaySet = React.useMemo(
    () => [
      { left: 'Low', tire: RiskTierTypes.TIER4, low: 0, high: value[0] },
      {
        left: '',
        tire: RiskTierTypes.TIER3,
        low: value[0] + 1,
        high: value[1],
      },
      {
        left: '',
        tire: RiskTierTypes.TIER2,
        low: value[1] + 1,
        high: value[2],
      },
      {
        left: 'High',
        tire: RiskTierTypes.TIER1,
        low: value[2] + 1,
        high: max,
      },
    ],
    [value],
  )

  const handleSave = React.useCallback(() => {
    onSave(value.slice(0, 3))
  }, [value])

  const buttons = React.useMemo(
    () => [
      { children: 'Cancel', onClick: onClose },
      { children: 'Ok', color: 'primary', onClick: handleSave },
    ],
    [onClose, handleSave],
  )

  return (
    <SimpleDialog
      {...rest}
      title="Edit Risk Tier Thresholds"
      buttons={buttons}
      onClose={onClose}
    >
      <NoteRow>Possible Points: {max}</NoteRow>
      <Row>
        <Slider {...rangeProps} value={value} max={max} onChange={setValue} />
      </Row>
      <Row>
        <TierHeadRow>
          <LeftColHead>Tier</LeftColHead>
          <MidCol />
          <RightCol>Range</RightCol>
        </TierHeadRow>
        {displaySet.map((row) => (
          <TierRow key={row.tire} color={RiskTierOptionsObj[row.tire].color}>
            <LeftCol>{row.left}</LeftCol>
            <MidCol>{RiskTierOptionsObj[row.tire].label}</MidCol>
            <RightCol>
              {row.low === row.high ? row.low : `${row.low} and ${row.high}`}
            </RightCol>
          </TierRow>
        ))}
      </Row>
    </SimpleDialog>
  )
}

EditTireThresholdsModal.propTypes = {
  tiers: PropTypes.array.isRequired,
  max: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default EditTireThresholdsModal
