import React from 'react'
import PropTypes from 'prop-types'
import Table from 'components-v2/molecules/Table'
import Tooltip from 'components-v2/molecules/Tooltip'
import Toolbar, {
  SearchInput,
  ColumnToggler,
} from 'components-v2/molecules/Table/Toolbar'
import MultiSelectFilterDropdown from 'components-v2/molecules/MultiSelectFilterDropdown'
import FilterDropdownTray from 'components-v2/molecules/FilterDropdownTray'
import MenuDropdown from 'components-v2/molecules/MenuDropdown'
import { useAuthManager, useAuthorizations } from 'hooks'
import { applyColumnToggles } from 'lib/table'
import { LabelTypes } from 'constants/index'
import { getAssessmentTableColumns } from './cols'

// Constants
import {
  ActionMenuItemTypes,
  archiveOptions,
  initialVisibleFilters,
} from './constants'

const View = ({
  assessments,
  totalSize,
  page,
  sizePerPage,
  sortField,
  sortOrder,
  searchText,
  selected,
  filter,
  filterConfigList,
  archiveValues,
  loading,
  columnToggles,
  disabledIntegrations,
  orgRole,
  context,
  onSearchChange,
  onMenuItemSelect,
  onArchiveChange,
  onFilterChange,
  onColumnTogglesChange,
  onRowSelectionChange,
  onPaginationChange,
  onSortingChange,
}) => {
  const [canReport, canExport, canViewArchived] = useAuthorizations(
    ['report', 'Assessment'],
    ['export', 'Assessment'],
    ['view_archived', 'Assessment'],
  )
  const authManager = useAuthManager()
  const columns = React.useMemo(
    () => getAssessmentTableColumns(authManager, orgRole, context),
    [orgRole, context, authManager],
  )

  const finalColumns = React.useMemo(
    () => applyColumnToggles(columns, columnToggles),
    [columns, columnToggles],
  )

  const archiveTitle = React.useMemo(() => {
    const count = archiveValues?.length
    let title = 'Show Archived'
    if (count) title += ` (${count})`
    return title
  }, [archiveValues])

  const noRowSelection = Object.keys(selected).length === 0

  return (
    <div>
      <Toolbar>
        <Toolbar.Group>
          <SearchInput value={searchText} onChange={onSearchChange} />
          <Tooltip>
            This search will allow you to filter for terms specifically in the
            vendor name, project or template by prefixing the term with name:,
            template: or project:
            <br />
            <br />
            You can search for exact phrases by including them in &quot;double
            quotes&quot;
            <br />
            <br />A specific term can be negated by adding - before it ( i.e.
            “sig -core” )
          </Tooltip>
          {canViewArchived && (
            <MultiSelectFilterDropdown
              title={archiveTitle}
              value={{ selected: archiveValues }}
              options={archiveOptions}
              onChange={onArchiveChange}
              selectAllVisible
            />
          )}
        </Toolbar.Group>
        <Toolbar.Group>
          <ColumnToggler
            columns={columns}
            selected={columnToggles}
            onToggle={onColumnTogglesChange}
            maskedColumns={disabledIntegrations}
          />
          <FilterDropdownTray
            value={filter}
            filtersEditable
            configList={filterConfigList}
            initialVisibleFilters={initialVisibleFilters}
            onChange={onFilterChange}
          />
          <MenuDropdown title="Actions" onSelect={onMenuItemSelect}>
            {canExport && (
              <MenuDropdown.Item
                eventKey={ActionMenuItemTypes.EXPORT_CURRENT_VIEW}
              >
                <i className="fa fa-table" />
                Export Current View
              </MenuDropdown.Item>
            )}
            {canReport && (
              <MenuDropdown.Item eventKey={ActionMenuItemTypes.STATUS_REPORT}>
                <i className="fa fa-bar-chart" />
                Status Report
              </MenuDropdown.Item>
            )}
            {canReport && (
              <MenuDropdown.Item
                eventKey={ActionMenuItemTypes.CYCLE_TIME_REPORT}
              >
                <i className="fa fa-bar-chart" />
                Cycle Time Report
              </MenuDropdown.Item>
            )}
            {canExport && (
              <MenuDropdown.Item
                eventKey={ActionMenuItemTypes.DOWNLOAD_SCORES}
                disabled={noRowSelection}
              >
                <i className="fa fa-download" />
                Download Scores
              </MenuDropdown.Item>
            )}
            {canReport && (
              <MenuDropdown.Item
                eventKey={ActionMenuItemTypes.RESULTS_REPORT}
                disabled={noRowSelection}
              >
                <i className="fa fa-bar-chart" />
                Results Report
              </MenuDropdown.Item>
            )}
            {canReport && (
              <MenuDropdown.Item
                eventKey={ActionMenuItemTypes.VENDOR_ANALYSIS_REPORT}
                disabled={noRowSelection}
              >
                <i className="fa fa-bar-chart" />
                {`${LabelTypes.VENDOR} Analysis Report`}
              </MenuDropdown.Item>
            )}
            {canReport && (
              <MenuDropdown.Item
                eventKey={ActionMenuItemTypes.VENDOR_ANSWER_COMPARISON_REPORT}
                disabled={noRowSelection}
              >
                <i className="fa fa-bar-chart" />
                {`${LabelTypes.VENDOR} Answer Comparison Report`}
              </MenuDropdown.Item>
            )}
          </MenuDropdown>
        </Toolbar.Group>
      </Toolbar>
      <Table
        loading={loading}
        data={assessments || []}
        columns={finalColumns}
        rowSelection={selected}
        totalSize={totalSize}
        pagination={{ page, sizePerPage }}
        sorting={{ sortField, sortOrder }}
        onRowSelectionChange={onRowSelectionChange}
        onPaginationChange={onPaginationChange}
        onSortingChange={onSortingChange}
        manualPagination
        manualSorting
        enableRowSelection={canReport || canExport}
        type={Table.types.ALT}
      />
    </div>
  )
}

View.propTypes = {
  assessments: PropTypes.array,
  totalSize: PropTypes.number,
  page: PropTypes.number,
  sizePerPage: PropTypes.number,
  sortField: PropTypes.string,
  sortOrder: PropTypes.string,
  searchText: PropTypes.string,
  selected: PropTypes.object.isRequired,
  filter: PropTypes.object,
  filterConfigList: PropTypes.array.isRequired,
  archiveValues: PropTypes.array,
  loading: PropTypes.bool,
  context: PropTypes.string,
  orgRole: PropTypes.string,
  columnToggles: PropTypes.array,
  disabledIntegrations: PropTypes.array.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onMenuItemSelect: PropTypes.func.isRequired,
  onArchiveChange: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onColumnTogglesChange: PropTypes.func.isRequired,
  onRowSelectionChange: PropTypes.func.isRequired,
  onPaginationChange: PropTypes.func.isRequired,
  onSortingChange: PropTypes.func.isRequired,
}

export default View
