import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as yup from 'yup'
import { FormFieldTypes } from 'components-v2/molecules/FormField'
import ProjectCustomEmailSettingFormField from 'components/client/shared/ProjectCustomEmailSettingFormField'
import FormikAutoSave from 'components-v2/utils/FormikAutoSave'
import FormikIsValidDetector from 'components-v2/utils/FormikIsValidDetector'
import { StyledFormField as FormField } from '../styles'

const validationSchema = yup.object().shape({
  custom_email_subject: yup
    .string()
    .label('Custom subject')
    .nullable()
    .defined(),
  custom_email_text: yup.string().label('Custom text').nullable().defined(),
})

const EmailInvitationsForm = ({ project, onValidStateChange, ...rest }) => {
  const initialValues = React.useRef({
    custom_email_subject: project.custom_email_subject,
    custom_email_text: project.custom_email_text,
    invitation_reminder: project.invitation_reminder,
  })

  return (
    <Formik
      {...rest}
      initialValues={initialValues.current}
      validationSchema={validationSchema}
    >
      <>
        <FormikAutoSave />
        <FormikIsValidDetector onValidStateChange={onValidStateChange} />
        <FormField
          component={ProjectCustomEmailSettingFormField}
          name="custom_email_subject"
          label="Custom subject"
          controlProps={{
            defaultText: project.default_custom_email_subject,
            maxLength: 200,
          }}
        />
        <FormField
          component={ProjectCustomEmailSettingFormField}
          name="custom_email_text"
          label="Custom text"
          controlProps={{
            componentClass: 'textarea',
            defaultText: project.default_custom_email_text,
            maxLength: 2000,
            rows: 4,
          }}
        />
        <FormField
          type={FormFieldTypes.TOGGLE}
          name="invitation_reminder"
          label="Send reminder emails"
          helpMessage="If enabled, automatic email reminders will be sent 2, 5, and 7 business days after the launch date if the invitation has not been accepted. If not submitted, reminders will also be sent 3 days and 1 day prior to the completion date."
        />
      </>
    </Formik>
  )
}

EmailInvitationsForm.propTypes = {
  project: PropTypes.object.isRequired,
  onValidStateChange: PropTypes.func.isRequired,
}

export default EmailInvitationsForm
