import React from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'
import some from 'lodash/some'
import Button from 'components-v2/atoms/Button'
import DList from 'components-v2/molecules/DList'
import {
  getCalcConfig,
  calculateRiskTier,
} from 'components/elements/risk_tier_calculator/util'
import useCommentThread from 'components/shared/CommentThread/useCommentThread'
import { openDraftClientVendor } from 'apis'
import { useAuthorizations } from 'hooks'
import { toCalendarString } from 'utils/date'
import { LabelTypes, VendorStatusTypes } from 'constants/index'
import { Wrapper, Top, Title, ButtonBox } from './styles'

const ReviewRequestCard = ({
  vendorRequest,
  organizationDetail,
  onApproveRequest,
  onDeclineRequest,
}) => {
  const [canUpdate] = useAuthorizations('update')
  const calcConfig = getCalcConfig('default')
  const riskTier = organizationDetail.vendor_risk_tier_answers
    ? calculateRiskTier(organizationDetail.vendor_risk_tier_answers, calcConfig)
    : null
  const [commentsData] = useCommentThread(
    // 'OrganizationDetail',
    // organizationDetailId,
    'VendorRequest',
    vendorRequest.id,
    vendorRequest.id,
    false,
  )
  const [haveAttachments, setHaveAttachments] = React.useState(false)

  React.useEffect(() => {
    if (vendorRequest.status === VendorStatusTypes.REQUESTED && canUpdate) {
      openDraftClientVendor(vendorRequest.id)
    }
  }, [vendorRequest, canUpdate])

  React.useEffect(() => {
    if (commentsData) {
      const attCheck = some(commentsData, (c) => c.attachments.length > 0)
      setHaveAttachments(attCheck)
    }
  }, [commentsData])

  return (
    <Wrapper bordered padding>
      <Top>
        <Title>Review {LabelTypes.VENDOR} Request</Title>
        {canUpdate && (
          <ButtonBox>
            <Button color="primary" size="xs" onClick={onApproveRequest}>
              Approve
            </Button>
            &nbsp;
            <Button
              size="xs"
              onClick={onDeclineRequest}
              disabled={vendorRequest.status === VendorStatusTypes.DECLINED}
            >
              Decline
            </Button>
          </ButtonBox>
        )}
      </Top>
      <DList>
        <dt />
        <dd>
          <DList horizontal>
            <dt>Requested by</dt>
            <dd>{vendorRequest.requester && vendorRequest.requester.name}</dd>
            <dt>When</dt>
            <dd>
              {capitalize(
                toCalendarString(vendorRequest.organization_detail.created_at),
              )}
            </dd>
            <dt>Risk Tier</dt>
            <dd>{riskTier}</dd>
          </DList>
        </dd>
        <dt>Comments / Attachments</dt>
        <dd>
          <table className="table top15">
            <thead>
              <tr>
                <th>Date</th>
                <th>From</th>
                {haveAttachments && <th>Attachment</th>}
              </tr>
            </thead>
            <tbody>
              {commentsData?.map((comment) => (
                <React.Fragment key={comment.id}>
                  <tr>
                    <td width="25%">
                      {capitalize(toCalendarString(comment.created_at))}
                    </td>
                    <td width="50%">{comment?.created_by?.name}</td>
                    {haveAttachments && (
                      <td rowSpan={comment.body ? 2 : 1}>
                        <ul className="list-unstyled fa-ul">
                          {comment.attachments.map((attachment) => (
                            <li key={attachment.id} className="nobr">
                              <a target="_blank" href={attachment.url}>
                                <i className="fa fa-li fa-file-text-o" />
                                {attachment.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </td>
                    )}
                  </tr>
                  {comment.body && (
                    <tr>
                      <td colSpan="2">{comment.body}</td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
              {commentsData?.length === 0 && !commentsData?.loading && (
                <tr key="no-comments">
                  <td colSpan="3">
                    <p>
                      <i>
                        There are no comments yet on this {LabelTypes.VENDOR}{' '}
                        Request
                      </i>
                    </p>
                  </td>
                </tr>
              )}
              {commentsData?.loading && (
                <tr key="loading-comments">
                  <td colSpan="3">
                    <p>
                      <i className="fa fa-large fa-spinner fa-spin" />
                      <i> Loading comments</i>
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </dd>
      </DList>
    </Wrapper>
  )
}

ReviewRequestCard.propTypes = {
  organizationDetail: PropTypes.object.isRequired,
  vendorRequest: PropTypes.object.isRequired,
  onApproveRequest: PropTypes.func.isRequired,
  onDeclineRequest: PropTypes.func.isRequired,
}

export default ReviewRequestCard
