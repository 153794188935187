import React from 'react'
import PropTypes from 'prop-types'
import { getRiskRatingColor } from 'lib/assessment'
import reduce from 'lodash/reduce'
import slice from 'lodash/slice'
import sortBy from 'lodash/sortBy'
import cloneDeep from 'lodash/cloneDeep'
import each from 'lodash/each'
import random from 'lodash/random'
import get from 'lodash/get'
import map from 'lodash/map'

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Text,
  Rectangle,
} from 'recharts'

// const colors = ['#8884d8', '#82ca9d', '#46a5db']

// get fill color based on value
const CustomBar = (props) => {
  const { value } = props
  // console.log('bar props', props);
  const fill = getRiskRatingColor(value)
  return <Rectangle {...props} fill={fill} className="recharts-bar-rectangle" />
}
CustomBar.propTypes = {
  value: PropTypes.number.isRequired,
}

const CustomizedAxisTick = ({ x, y, payload }) => (
  <Text
    x={x}
    y={y}
    width={75}
    angle={-45}
    textAnchor="end"
    verticalAnchor="start"
  >
    {payload.value}
  </Text>
)
CustomizedAxisTick.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  payload: PropTypes.object.isRequired,
}

/* prototype per-vendor risk domain bar chart */
const VendorRiskDomainChart = ({ assessments, riskDomains }) => {
  const asmtHash = reduce(
    slice(assessments, 0, 3),
    (res, val) => {
      res[val.id] = val
      return res
    },
    {},
  )
  const keys = Object.keys(asmtHash)
  const data = sortBy(cloneDeep(riskDomains), 'name')

  each(keys, (aid) => {
    each(data, (domain) => {
      const val = get(asmtHash[aid].risk_domain_ratings, domain.id, random(100))
      // eslint-disable-next-line no-param-reassign
      domain[aid] = val
    })
  })

  const nameForLegend = (val) => asmtHash[val].name

  const nameForTooltip = (val, name) => [val, asmtHash[name].name]

  // const barLabelNumber = ({ key }) => {
  //   // console.log('bar label props', props);
  //   const idx = keys.findIndex((el) => el === key)
  //   return idx
  // }

  return (
    <BarChart
      width={900}
      height={250}
      data={data}
      barCategoryGap="25%"
      barGap="3"
      barSize="15"
      margin={{ top: 20, right: 10, left: 10, bottom: 50 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" interval={0} tick={CustomizedAxisTick} />
      <YAxis />
      <Tooltip formatter={nameForTooltip} />
      <Legend
        verticalAlign="top"
        formatter={nameForLegend}
        iconType="diamond"
      />
      {/*
      map(keys, (key, idx) => {
        return (<Bar dataKey={key} fill={colors[idx]} />);
      })
      */}
      {/*
      <Bar dataKey={keys[0]} shape={CustomBar}>
        <LabelList dataKey='name' position='insideBottomLeft' angle={-90} offset={10} />
      </Bar>
      */}
      {map(slice(keys, 0, 3), (key) => (
        <Bar key={key} dataKey={key} shape={CustomBar}>
          {/*
          <LabelList dataKey={key} position='top' shape='diamond' /> content={(props) => barLabelNumber({key, ...props})} />
          */}
        </Bar>
      ))}
    </BarChart>
  )
}

VendorRiskDomainChart.propTypes = {
  riskDomains: PropTypes.array.isRequired,
  assessments: PropTypes.array.isRequired,
}

export default VendorRiskDomainChart
