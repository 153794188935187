import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import Dialog from 'components-v2/molecules/Dialog'
import Privva from 'Privva'
import A from 'components-v2/atoms/A'
import Button from 'components-v2/atoms/Button'
import Select from 'components-v2/molecules/SearchableSelect'
import Tooltip from 'components-v2/molecules/Tooltip'
import { Row, Col } from 'components-v2/organisms/Layout'
import { createClientOrganizationPartnerMapping, getClientVendors } from 'apis'
import { useAuthorizations } from 'hooks'
import { VendorLink } from './styles'
import { getVendorPageUrl } from '../utils'

const defaultVendorLinkClick = () => {
  window.location.assign(getVendorPageUrl(this.state.vendor_id))
}

const VendorPartnerLinkModal = (props) => {
  const [showModal, setShowModal] = React.useState(false)
  const [vendors, setVendors] = React.useState([])
  const [selectedVendor, setSelectedVendor] = React.useState('')
  const [vendorId, setVendorId] = React.useState(props.vendorId)
  const [canLinkVendor] = useAuthorizations('manage')

  const open = () => {
    setShowModal(true)
    getClientVendors()
      .then((response) => {
        // console.info(response);
        const data = map(response.data, (v) => ({
          value: v.id,
          label: v.name,
        }))
        setVendors(data)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const close = () => {
    setShowModal(false)
    setSelectedVendor('')
  }

  const handleVendorSelect = (newSelectedVendor) => {
    if (newSelectedVendor) {
      setSelectedVendor(newSelectedVendor.value)
    }
  }

  const handleLinkVendor = () => {
    const data = {
      organization_partner_mapping: {
        vendor_id: selectedVendor,
        partner_vendor_id: props.partnerVendorId,
      },
    }
    createClientOrganizationPartnerMapping(props.partnerId, data).then(() => {
      // just show connected with link to issues
      setVendorId(selectedVendor)
      close()
    })
  }

  return (
    <>
      <Dialog open={showModal} onClose={close}>
        <Dialog.Header onClose={close}>
          Connect {props.partnerVendorName}
        </Dialog.Header>
        <Dialog.Content>
          <p>
            Connecting this company&apos;s {props.partnerName} data to an
            existing {Privva.Utils.brand.programName} vendor allows you to
            create and manage issues based on {props.partnerName} reporting
            data.
          </p>
          <p>
            <b>Connect {props.partnerVendorName} to </b>
            {/* todo: create vendor */}
          </p>
          <Row>
            <Col sm={8}>
              <Select
                name="vendor-list"
                placeholder="Select a vendor..."
                value={selectedVendor}
                onChange={handleVendorSelect}
                options={vendors}
              />
            </Col>
          </Row>
        </Dialog.Content>
        <Dialog.Footer>
          <Button onClick={close}>Nevermind</Button>
          <Button
            color="primary"
            disabled={!selectedVendor}
            onClick={handleLinkVendor}
          >
            <i className="fa fa-link" />
            Connect to {Privva.Utils.brand.programName} Vendor
          </Button>
        </Dialog.Footer>
      </Dialog>
      {vendorId && (
        <VendorLink onClick={() => props.vendorLinkOnClick(vendorId)} underline>
          {props.vendorLinkText}
        </VendorLink>
      )}
      {!vendorId && canLinkVendor && (
        <A onClick={open} underline>
          {props.connectLinkText}
          <Tooltip position="left">
            Connect this {props.partnerName} company to an existing{' '}
            {Privva.Utils.brand.programName} vendor.
          </Tooltip>
        </A>
      )}
    </>
  )
}

VendorPartnerLinkModal.propTypes = {
  partnerVendorName: PropTypes.string.isRequired,
  partnerName: PropTypes.string.isRequired,
  partnerVendorId: PropTypes.string.isRequired,
  partnerId: PropTypes.string.isRequired,
  connectLinkText: PropTypes.string,
  vendorId: PropTypes.string,
  vendorLinkText: PropTypes.string,
  vendorLinkOnClick: PropTypes.func,
}

VendorPartnerLinkModal.defaultProps = {
  connectLinkText: 'Connect',
  vendorLinkText: 'View',
  vendorLinkOnClick: defaultVendorLinkClick,
}

export default VendorPartnerLinkModal
