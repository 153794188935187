import { colors } from 'styles'

export const ResponseScoreOptions = [
  { value: -3, color: '#901700' },
  { value: -2, color: colors.RED },
  { value: -1, color: colors.ORANGE },
  { value: 0, color: '#FED260' },
  { value: 1, color: colors.ATLANTIS },
  { value: 2, color: colors.GREEN },
]
