import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import merge from 'lodash/merge'
import Icon from 'components-v2/atoms/Icon'
import { toUncontrollable } from 'hocs'
import { useAuthorizations } from 'hooks'
import objectToFormData from 'utils/object_to_formdata'
import DropzoneUploader from '../../DropzoneUploader'
import Layout from './Layout'

const SingleUploader = ({
  file,
  disabled,
  canRemove,
  canReplace,
  url,
  model,
  formParam,
  buttonComponent,
  buttonContent,
  buttonColor,
  description,
  accept,
  maxSizeBytes,
  additionalPayload,
  confirmReplace,
  confirmDelete,
  onChange,
  renderLayout: RenderLayout,
  ...rest
}) => {
  // TODO: Consider to get rid of this business logic
  const [canManage] = useAuthorizations('manage')
  const isDisabled = disabled || !canManage

  // canReplace will allow updating an existing rails Attribute object with
  // a *new* file attribute (e.g. to use /attributes/:id url and avoid the change
  // creating broken links).
  const getUploadParams = React.useCallback(
    ({ file: fileParam }) => {
      let attrs = { file: fileParam }
      if (file && canReplace) attrs.id = file.id
      if (formParam.endsWith('s')) attrs = [attrs] // Rails plural convention

      const body = objectToFormData(
        merge(
          {
            [model]: {
              [`${formParam}_attributes`]: attrs,
            },
          },
          additionalPayload,
        ),
      )
      return { url, body, method: 'PATCH' }
    },
    [url, model, formParam, canReplace, additionalPayload],
  )

  const handleChangeStatus = React.useCallback(
    ({ xhr, remove }, status) => {
      if (status === 'done') {
        const response = JSON.parse(xhr.response)
        onChange(response[formParam], response)
        remove()
      }
    },
    [formParam, onChange],
  )

  // if we have an existing file and we've been
  // given a confirmation routine, run that
  // before proceeding; else simply proceed
  const handleUpload = React.useCallback(() => {
    if (file && confirmReplace) {
      return confirmReplace(file)
    }
    return Promise.resolve(true)
  }, [file, confirmReplace])

  // if we've been given a confirmation fucntion,
  // run it before proceeding; else simply proceed
  const handleRemove = React.useCallback(() => {
    if (confirmDelete) {
      confirmDelete(file).then((confirmed) => {
        if (confirmed) handleFileRemove(file.id)
      })
    } else handleFileRemove(file.id)
  }, [file, confirmDelete])

  const handleFileRemove = React.useCallback(
    (id) => {
      const data = merge(
        {
          [model]: {
            [`${formParam}_attributes`]: {
              id,
              _destroy: 1,
            },
          },
        },
        additionalPayload,
      )
      axios.patch(url, data).then((response) => {
        onChange(response.data[formParam], response.data)
      })
    },
    [url, model, formParam, additionalPayload, onChange],
  )

  const dropzoneUploader = (
    <DropzoneUploader
      disabled={isDisabled}
      getUploadParams={getUploadParams}
      buttonComponent={buttonComponent}
      buttonContent={buttonContent}
      buttonColor={buttonColor}
      description={description}
      accept={accept}
      maxFiles={1}
      maxSizeBytes={maxSizeBytes}
      confirmUpload={handleUpload}
      onChangeStatus={handleChangeStatus}
    />
  )
  return (
    <RenderLayout
      {...rest}
      dropzoneUploader={dropzoneUploader}
      file={file}
      disabled={isDisabled}
      canRemove={canRemove}
      onRemove={handleRemove}
    />
  )
}

SingleUploader.propTypes = {
  url: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  file: PropTypes.object,
  disabled: PropTypes.bool,
  canRemove: PropTypes.bool,
  canReplace: PropTypes.bool,
  displayLabel: PropTypes.bool,
  formParam: PropTypes.string,
  buttonComponent: PropTypes.any,
  buttonContent: PropTypes.node,
  buttonColor: PropTypes.string,
  description: PropTypes.string,
  accept: PropTypes.string,
  maxSizeBytes: PropTypes.number,
  additionalPayload: PropTypes.object,
  confirmReplace: PropTypes.func,
  confirmDelete: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  renderLayout: PropTypes.any,
}

SingleUploader.defaultProps = {
  disabled: false,
  canRemove: true,
  canReplace: false,
  displayLabel: true,
  formParam: 'attachment',
  buttonContent: [<Icon key="icon" icon="fa fa-cloud-upload" />, 'Attach file'],
  additionalPayload: {},
  renderLayout: Layout,
}

export default toUncontrollable({ file: 'onChange' })(SingleUploader)
