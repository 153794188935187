import React from 'react'
import PropTypes from 'prop-types'
import keyBy from 'lodash/keyBy'
import Button from 'components-v2/atoms/Button'
import Alert from 'components-v2/molecules/Alert'
import ToolbarGroup from 'components-v2/molecules/ToolbarGroup'
import MenuDropdown from 'components-v2/molecules/MenuDropdown'
import PageHeader from 'components-v2/organisms/PageHeader'
import { compose, withAuthorize, withReactQuery } from 'hocs'
import { useAuthorizations } from 'hooks'
import { getPageUrl } from 'utils/url'
import {
  ProjectStatusTypes,
  ProjectStatusOptions,
  ExportFormatTypes,
} from 'constants/index'
import DraftView from './DraftView'
import NonDraftView from './NonDraftView'
import useProjectMutations from './useProjectMutations'
import { Description, StatusBreadcrumb, StatusBreadcrumbItem } from './styles'

const ProjectStatusOptionsObj = keyBy(ProjectStatusOptions, 'value')

const ProjectViewContainer = ({
  project,
  projectTotals,
  initialSearchText,
  showGloborg,
  assessmentTemplates,
  assessmentTemplatesEditable,
  aasmStates,
  shouldPromptForCloseEarly,
  authManager,
}) => {
  const [
    canUpdateProject,
    canManageProject,
    canDeleteProject,
    canReportProject,
    canExportProject,
  ] = useAuthorizations('update', 'manage', 'delete', 'report', 'export')
  const {
    deleteProject,
    launchProjectEarly,
    closeProject,
    relaunchProject,
    archiveProject,
    unarchiveProject,
    reviewProject,
    handleDismissError,
    error,
  } = useProjectMutations({ project, shouldPromptForCloseEarly })
  const breadcrumbs = [
    {
      href: getPageUrl('clientProjects'),
      title: 'Projects',
    },
    {
      active: true,
      title: project.friendly_name,
    },
  ]
  return (
    <div>
      <PageHeader title={project.friendly_name} breadcrumbs={breadcrumbs}>
        <ToolbarGroup>
          <MenuDropdown title="Actions">
            {project.status === ProjectStatusTypes.DRAFT &&
              canManageProject && (
                <MenuDropdown.Item onClick={launchProjectEarly}>
                  <i className="fa fa-rocket" />
                  Launch Project Early
                </MenuDropdown.Item>
              )}
            {project.status === ProjectStatusTypes.DRAFT &&
              canDeleteProject && (
                <MenuDropdown.Item onClick={deleteProject}>
                  <i className="fa fa-trash" />
                  Delete
                </MenuDropdown.Item>
              )}
            {canManageProject && project.may_close && (
              <MenuDropdown.Item onClick={closeProject}>
                <i className="fa fa-ban" />
                Close
              </MenuDropdown.Item>
            )}
            {canManageProject && project.may_re_launch && (
              <MenuDropdown.Item onClick={relaunchProject}>
                <i className="fa fa-rocket" />
                Re-Open
              </MenuDropdown.Item>
            )}
            {canManageProject && project.may_archive && (
              <MenuDropdown.Item onClick={archiveProject}>
                <i className="fa fa-archive" />
                Archive
              </MenuDropdown.Item>
            )}
            {canManageProject && project.may_unarchive && (
              <MenuDropdown.Item onClick={unarchiveProject}>
                <i className="fa fa-unlock" />
                Unarchive
              </MenuDropdown.Item>
            )}
            {canManageProject && project.may_review && (
              <MenuDropdown.Item onClick={reviewProject}>
                <i className="fa fa-gavel" />
                Mark Reviewed
              </MenuDropdown.Item>
            )}
            {project.status !== ProjectStatusTypes.DRAFT && (
              <MenuDropdown.Divider />
            )}
            {project.status !== ProjectStatusTypes.DRAFT &&
              canReportProject && (
                <MenuDropdown.Item
                  href={getPageUrl('clientProjectResultsReport', {
                    id: project.id,
                  })}
                >
                  <i className="fa fa-th-list" />
                  Results Report
                </MenuDropdown.Item>
              )}
            {project.status !== ProjectStatusTypes.DRAFT &&
              project.reportable &&
              canExportProject && (
                <MenuDropdown.Item
                  href={getPageUrl(
                    'clientProjectResultsExport',
                    {
                      id: project.id,
                    },
                    undefined,
                    ExportFormatTypes.XLSX,
                  )}
                >
                  <i className="fa fa-table" />
                  Results Export (XLSX)
                </MenuDropdown.Item>
              )}
            {project.status !== ProjectStatusTypes.DRAFT &&
              project.reportable &&
              canExportProject && (
                <MenuDropdown.Item
                  href={getPageUrl(
                    'clientProjectResponseComparison',
                    {
                      id: project.id,
                    },
                    undefined,
                    ExportFormatTypes.XLSX,
                  )}
                >
                  <i className="fa fa-table" />
                  Response Comparison (XLSX)
                </MenuDropdown.Item>
              )}
            {project.status !== ProjectStatusTypes.DRAFT &&
              canExportProject && (
                <MenuDropdown.Item
                  href={getPageUrl(
                    'clientProjectGrade',
                    {
                      id: project.id,
                    },
                    undefined,
                    ExportFormatTypes.CSV,
                  )}
                >
                  <i className="fa fa-download" />
                  Download Scores
                </MenuDropdown.Item>
              )}
          </MenuDropdown>
          {canUpdateProject && (
            <Button
              color="primary"
              href={getPageUrl('clientProjectEdit', { id: project.id })}
            >
              Edit Project
            </Button>
          )}
        </ToolbarGroup>
      </PageHeader>
      {error && (
        <Alert color="danger" onDismiss={handleDismissError}>
          {error}
        </Alert>
      )}
      <Description>{project.description}</Description>
      <StatusBreadcrumb>
        {aasmStates
          .filter((e) => e !== ProjectStatusTypes.ARCHIVED)
          .map((status) => (
            <StatusBreadcrumbItem
              key={status}
              backgroundColor={
                status === project.status
                  ? ProjectStatusOptionsObj[status]?.color
                  : ''
              }
            >
              {ProjectStatusOptionsObj[status]?.label}
            </StatusBreadcrumbItem>
          ))}
      </StatusBreadcrumb>
      {project.status === ProjectStatusTypes.DRAFT ? (
        <DraftView
          project={project}
          showGloborg={showGloborg}
          assessmentTemplates={assessmentTemplates}
          assessmentTemplatesEditable={assessmentTemplatesEditable}
        />
      ) : (
        <NonDraftView
          project={project}
          projectTotals={projectTotals}
          initialSearchText={initialSearchText}
          authManager={authManager}
        />
      )}
    </div>
  )
}

ProjectViewContainer.propTypes = {
  project: PropTypes.object.isRequired,
  projectTotals: PropTypes.object.isRequired,
  initialSearchText: PropTypes.string,
  showGloborg: PropTypes.bool,
  assessmentTemplates: PropTypes.array.isRequired,
  assessmentTemplatesEditable: PropTypes.bool,
  aasmStates: PropTypes.arrayOf(PropTypes.string).isRequired,
  shouldPromptForCloseEarly: PropTypes.bool,
  authManager: PropTypes.object.isRequired,
}

export default compose(withAuthorize(), withReactQuery())(ProjectViewContainer)
