import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import Button from 'components-v2/atoms/Button'
import ButtonGroup from 'components-v2/molecules/ButtonGroup'
import FilterOptionTypes from 'constants/filter_option_types'
import Single from './Single'
import ColorfulCheckboxSet from './ColorfulCheckboxSet'

const ALL_FILTER_BUTTON_LABEL = 'All'

const getFilterOptionComponent = (type) => {
  switch (type) {
    case FilterOptionTypes.COLORFUL_CHECKBOX_SET:
      return ColorfulCheckboxSet
    default:
      return Single
  }
}

export const FilterButtonGroup = ({
  selected,
  filterOptions,
  selectAllVisible,
  selectedButtonColor,
  selectedButtonVariant,
  defaultButtonColor,
  defaultButtonVariant,
  buttonSize,
  onChange,
  RenderButton,
  ...rest
}) => (
  <ButtonGroup {...rest}>
    {selectAllVisible && (
      <RenderButton
        active={isEmpty(selected)}
        color={isEmpty(selected) ? selectedButtonColor : defaultButtonColor}
        variant={
          isEmpty(selected) ? selectedButtonVariant : defaultButtonVariant
        }
        size={buttonSize}
        hidePrint={false}
        onClick={() => onChange({})}
      >
        {ALL_FILTER_BUTTON_LABEL}
      </RenderButton>
    )}
    {map(filterOptions, ({ key, type, ...optionsRest }) => {
      const Component = getFilterOptionComponent(type)
      return (
        <Component
          key={key}
          {...optionsRest}
          selected={selected[key]}
          selectedButtonColor={selectedButtonColor}
          selectedButtonVariant={selectedButtonVariant}
          defaultButtonColor={defaultButtonColor}
          defaultButtonVariant={defaultButtonVariant}
          buttonSize={buttonSize}
          RenderButton={RenderButton}
          onSelect={(value) => onChange({ [key]: value })}
        />
      )
    })}
  </ButtonGroup>
)

FilterButtonGroup.propTypes = {
  selected: PropTypes.object,
  filterOptions: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]).isRequired,
  selectAllVisible: PropTypes.bool,
  selectedButtonColor: PropTypes.string,
  selectedButtonVariant: PropTypes.string,
  defaultButtonColor: PropTypes.string,
  defaultButtonVariant: PropTypes.string,
  buttonSize: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  RenderButton: PropTypes.elementType,
}

FilterButtonGroup.defaultProps = {
  selected: {},
  selectAllVisible: true,
  selectedButtonColor: 'default',
  defaultButtonColor: 'default',
  buttonSize: undefined,
  RenderButton: Button,
}

export default React.memo(FilterButtonGroup)
