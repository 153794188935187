import React from 'react'
import styled from 'styled-components'
import get from 'lodash/get'
import includes from 'lodash/includes'
import keyBy from 'lodash/keyBy'
import Label from 'components-v2/atoms/Label'
import { ProjectStatusTypes, ProjectStatusOptions } from 'constants/index'

const ProjectStatusOptionsObj = keyBy(ProjectStatusOptions, 'value')

export const EditableProjectStatusTypes = [
  ProjectStatusTypes.DRAFT,
  ProjectStatusTypes.LAUNCHED,
]

export function isProjectEditable(projectStatus) {
  return includes(EditableProjectStatusTypes, projectStatus)
}

const StatusLabel = styled(Label)`
  width: 80px;
`

export const projectStatusFormatterFactory = () => (info) =>
  info.getValue() ? (
    <StatusLabel
      color={get(ProjectStatusOptionsObj, [info.getValue(), 'color'])}
    >
      {get(ProjectStatusOptionsObj, [info.getValue(), 'label'])}
    </StatusLabel>
  ) : (
    ''
  )
