import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import includes from 'lodash/includes'
import union from 'lodash/union'
import without from 'lodash/without'
import styled from 'styled-components'
import Checkbox from 'components-v2/atoms/Checkbox'
import { hidePrint } from 'styles/mixins'

const StyledCheckbox = styled(Checkbox)`
  margin: 8px 0;
`

const ClearButton = styled.a`
  cursor: pointer;
  ${hidePrint}
`

const Wrapper = styled.div``

const MultiSelect = ({
  choices,
  value,
  onChange,
  disabled,
  translatable,
  ...rest
}) => {
  const [selected, setSelected] = useState(value)

  useEffect(() => {
    setSelected(value)
  }, [value])

  const toggleChoice = (e) => {
    const newSelected = e.target.checked
      ? union(selected, [e.target.value])
      : without(selected, e.target.value)
    if (onChange) {
      onChange(newSelected)
    }
  }
  const handleClear = () => {
    if (onChange) {
      onChange([])
    }
  }
  const choiceText = (choice) =>
    disabled && choice.require_artifacts ? `${choice.text} *` : choice.text
  return (
    <Wrapper {...rest}>
      {!disabled && <ClearButton onClick={handleClear}>Clear</ClearButton>}
      {map(choices, (choice) => (
        <StyledCheckbox
          className={!translatable && 'no-lang'}
          key={choice.id}
          value={choice.id}
          label={choiceText(choice, disabled)}
          checked={includes(selected, choice.id)}
          disabled={disabled}
          onChange={toggleChoice}
        />
      ))}
    </Wrapper>
  )
}

MultiSelect.propTypes = {
  choices: PropTypes.array,
  value: PropTypes.array,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  translatable: PropTypes.bool,
}

MultiSelect.defaultProps = {
  choices: [],
  value: [],
  onChange: undefined,
  translatable: true,
}

export default MultiSelect
