import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components-v2/atoms/Button'
import ToolbarGroup from 'components-v2/molecules/ToolbarGroup'
import ErrorView from 'components-v2/templates/ErrorView'
import hazardImg from 'images/error/hazard2.png'

const View = ({ fileName, downloadUrl }) => (
  <ErrorView imageUrl={hazardImg} heading="Infected File">
    <p>&ldquo;{fileName}&rdquo; may be infected with a virus.</p>
    <p>
      This file might harm your computer, so only download this file if you
      understand the risks.
    </p>
    <br />
    <ToolbarGroup>
      <Button href="/dashboard">Cancel</Button>
      <Button href={downloadUrl} color="primary">
        Download infected file anyway
      </Button>
    </ToolbarGroup>
  </ErrorView>
)

View.propTypes = {
  fileName: PropTypes.string.isRequired,
  downloadUrl: PropTypes.string.isRequired,
}

export default View
