import React, { useRef, useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as yup from 'yup'
import zip from 'zippo'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import states from 'states-us'
import styled from 'styled-components'
import Input from 'components-v2/atoms/Input'
import FormField from 'components-v2/molecules/FormField'
import { useCurrent } from 'hooks'
import {
  AssessmentElementSubtypes,
  AssessmentElementSubtypeOptions,
} from 'constants/index'

const Wrapper = styled.div`
  max-width: 300px;
`

const validationSchemas = {
  [AssessmentElementSubtypes.FLOAT]: yup
    .number()
    .typeError('Must be a valid number (decimal)'),
  [AssessmentElementSubtypes.MONEY]: yup
    .number()
    .typeError('Must be a valid numeric value (whole or decimal)'),
  [AssessmentElementSubtypes.YEAR]: yup
    .number()
    .typeError('Must be a valid year')
    .min(1900, 'Must be between 1900 and 2100')
    .max(2100, 'Must be between 1900 and 2100'),
  [AssessmentElementSubtypes.PHONE]: yup
    .string()
    .test(
      'is-phone',
      'Must be a valid phone number',
      (value) => !value || isPossiblePhoneNumber(value),
    ),
  [AssessmentElementSubtypes.STATE]: yup.string().oneOf(
    states.map((s) => s.abbreviation),
    'Must be a valid state',
  ),
  [AssessmentElementSubtypes.ZIPCODE]: yup
    .string()
    .test(
      'is-zipcode',
      'Must be a valid zipcode',
      (value) => !value || zip.validate(value),
    ),
  [AssessmentElementSubtypes.EMAIL]: yup
    .string()
    .email('Must be a valid email'),
  [AssessmentElementSubtypes.URI]: yup.string().url('Must be a valid url'),
}

const formFieldProps = {
  [AssessmentElementSubtypes.PHONE]: {
    type: FormField.types.PHONE_NUMBER,
  },
  [AssessmentElementSubtypes.STATE]: {
    type: FormField.types.SELECT,
    options: states.map((s) => ({ label: s.name, value: s.abbreviation })),
    placeholder: 'Select State',
    controlProps: { placeholderSelectable: true },
  },
}

const TextWithValidation = ({
  type,
  value,
  disabled,
  placeholder,
  onChange,
}) => {
  const formikRef = useRef()
  const inputValue = (value && value[0]) ?? ''
  const inputValueRef = useCurrent(inputValue)
  const validationSchema = useMemo(
    () => yup.object().shape({ input: validationSchemas[type] }),
    [type],
  )
  const placeholderEx =
    placeholder ??
    AssessmentElementSubtypeOptions.find((e) => e.value === type).label

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue('input', inputValue)
    }
  }, [inputValue])

  const handleSubmit = useCallback(
    ({ input }) => {
      if (inputValueRef.current !== input) {
        onChange([input ?? ''])
      }
    },
    [onChange],
  )

  if (disabled) {
    return (
      <Wrapper>
        <Input placeholder={placeholderEx} readOnly value={inputValue} />
      </Wrapper>
    )
  }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{ input: inputValue }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ submitForm }) => (
        <form
          onSubmit={(e) => {
            e.preventDefault()
          }}
        >
          <Wrapper>
            <FormField
              name="input"
              placeholder={placeholderEx}
              {...formFieldProps[type]}
              controlProps={{
                ...formFieldProps[type]?.controlProps,
                onBlur: submitForm,
              }}
              preserveHelpTextSpace={false}
            />
          </Wrapper>
        </form>
      )}
    </Formik>
  )
}

TextWithValidation.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.array,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}

export default TextWithValidation
