import React, { useState, useCallback, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { HexColorPicker } from 'react-colorful'
import tinycolor from 'tinycolor2'
import Popover from 'components-v2/molecules/Popover'
import {
  ColorIndicator,
  Wrapper,
  StyledInput,
  StyledPopoverPanel,
  PopoverButton,
} from './styles'

const ColorInput = ({ value: valueProp, placeholder, onChange, ...rest }) => {
  const [value, setValue] = useState(valueProp)
  const [indicatorColor, setIndicatorColor] = useState(valueProp)
  const inputRef = useRef()

  const handleUpdate = useCallback(
    (val) => {
      let color = tinycolor(val)
      if (color.isValid()) {
        setValue(val)
      } else {
        const defaultColor = '#ffffff' // default color after color input is opened
        color = tinycolor(defaultColor)
        setValue(defaultColor)
      }
      const validValue = color.toHexString()
      setIndicatorColor(validValue)
      onChange(validValue)
    },
    [onChange],
  )

  const handleInputChange = useCallback(
    (e) => {
      handleUpdate(e.currentTarget.value)
    },
    [handleUpdate],
  )

  const handleInputBlur = useCallback(() => {
    setValue(valueProp)
    setIndicatorColor(valueProp)
  }, [valueProp])

  useEffect(() => {
    if (inputRef.current !== document.activeElement) {
      setValue(valueProp)
      setIndicatorColor(valueProp)
    }
  }, [valueProp])

  return (
    <Wrapper {...rest}>
      <StyledInput
        ref={inputRef}
        value={value}
        placeholder={placeholder}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
      />
      <Popover placement="bottom-end" offset={0}>
        <Popover.Button as={PopoverButton}>
          <ColorIndicator color={indicatorColor} />
        </Popover.Button>
        <StyledPopoverPanel>
          <HexColorPicker color={value} onChange={handleUpdate} />
        </StyledPopoverPanel>
      </Popover>
    </Wrapper>
  )
}

ColorInput.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

ColorInput.defaultProps = {
  value: '',
  placeholder: '',
}

export default ColorInput
