import React from 'react'
import PropTypes from 'prop-types'
import addDays from 'date-fns/addDays'
import A from 'components-v2/atoms/A'
import Button from 'components-v2/atoms/Button'
import Card from 'components-v2/organisms/Card'
import DList from 'components-v2/molecules/DList'
import Tooltip from 'components-v2/molecules/Tooltip'
import { SecurityToolStatusTypes } from 'constants/index'
import ToolStatusDot from '../ToolStatusDot'
import RiskRatingLabel from '../RiskRatingLabel'
import SecuredDevices from './SecuredDevices'
import Incidents from './Incidents'
import PageHeader from './PageHeader'
import {
  Wrapper,
  Left,
  Right,
  FrameworkLabel,
  Title,
  SubTitle,
  StatusDetailsSegment,
  StatusDotWrapper,
  ToolLogo,
} from './styles'

const toolStatus = SecurityToolStatusTypes.ACTIVE
const devices = {
  insecure: 4,
  unknown: 0,
  secure: 80,
}
const incidentsData = {
  count: 5,
  trend: -3,
  history: [
    {
      date: addDays(new Date(), -1).toISOString(),
      count: 5,
    },
    {
      date: addDays(new Date(), -2).toISOString(),
      count: 8,
    },
    {
      date: addDays(new Date(), -3).toISOString(),
      count: 10,
    },
    {
      date: addDays(new Date(), -4).toISOString(),
      count: 9,
    },
    {
      date: addDays(new Date(), -5).toISOString(),
      count: 12,
    },
    {
      date: addDays(new Date(), -6).toISOString(),
      count: 8,
    },
    {
      date: addDays(new Date(), -7).toISOString(),
      count: 2,
    },
  ],
}

const View = ({ data, isVendorContext }) => (
  <Wrapper>
    <PageHeader
      title={data.name}
      status={toolStatus}
      isVendorContext={isVendorContext}
    />
    <Left>
      <Card bordered padding>
        <Card.Segment>
          <Title>Objective Details</Title>
          <SubTitle>General</SubTitle>
          <DList horizontal>
            <dt>Objective</dt>
            <dd>{data.name}</dd>
            <dt>Category</dt>
            <dd>
              {data.category.name}&nbsp;
              <RiskRatingLabel value={data.category.average_score} />
            </dd>
            <dt>Definition</dt>
            <dd>{data.definition}</dd>
          </DList>
        </Card.Segment>
        <Card.Segment>
          <SubTitle>Security Framework Mapping</SubTitle>
          <DList horizontal>
            {data.frameworkMappings.map((collection, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={i}>
                <dt>{collection.framework}</dt>
                <dd>
                  <FrameworkLabel>{collection.identifier}</FrameworkLabel>
                  {collection.parentIdentifier && (
                    <FrameworkLabel>
                      {collection.parentIdentifier}
                    </FrameworkLabel>
                  )}
                  {collection.description}
                </dd>
              </React.Fragment>
            ))}
          </DList>
        </Card.Segment>
      </Card>
    </Left>
    <Right>
      <Card padding bordered>
        <StatusDetailsSegment>
          <Title>Status Details</Title>
          <StatusDotWrapper>
            <Tooltip parent={<ToolStatusDot status={toolStatus} />}>
              Active as of 9 mins ago
            </Tooltip>
          </StatusDotWrapper>
          <DList>
            <dt>Tool(s) Aligned</dt>
            <dd>
              {data.sources.map((collection, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={i}>
                  <ToolLogo src={collection.logo_url} title={collection.name} />
                  <br />
                  {isVendorContext && (
                    <>
                      <Button color="primary" size="small">
                        Manage access
                      </Button>
                      &nbsp;
                      <A size="sm">Disconnect</A>
                    </>
                  )}
                </div>
              ))}
            </dd>
          </DList>
        </StatusDetailsSegment>
        {isVendorContext && (
          <Card.Segment>
            <SubTitle>Data Monitoring</SubTitle>
            <DList>
              <dt>Insecure Devices</dt>
              <dd>
                <SecuredDevices devices={devices} />
              </dd>
              <dt>Critical Events</dt>
              <dd>
                <Incidents data={incidentsData} />
              </dd>
            </DList>
          </Card.Segment>
        )}
      </Card>
    </Right>
  </Wrapper>
)

View.propTypes = {
  data: PropTypes.object.isRequired,
  isVendorContext: PropTypes.bool.isRequired,
}

export default View
