export const ModeTypes = {
  MAP: 'map',
  ANSWER: 'answer',
}

export const ModeOptions = [
  {
    value: ModeTypes.MAP,
    label: 'Map',
  },
  {
    value: ModeTypes.ANSWER,
    label: 'Answer',
  },
]
