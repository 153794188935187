import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { mixins, colors, media } from 'styles'

const VerticalDList = styled.dl`
  margin: 0;

  dt {
    ${mixins.smallHeader}
    margin-bottom: 8px;
  }

  dd {
    overflow-wrap: break-word;
    &:not(:last-child) {
      padding-bottom: 15px;
      border-bottom: 1px solid ${colors.LIGHT_GREY};
      margin-bottom: 15px;
    }
  }
`

const HorizontalDList = styled.dl.attrs({ className: 'dl-horizontal' })`
  margin: 0;

  dt {
    margin-bottom: 8px;

    ${media.nmobile`
      white-space: normal;
      width: 170px;
      text-align: left;
      margin-bottom: 0;
    `}
  }

  dd {
    font-size: 15px;
    overflow-wrap: break-word;

    ${media.nmobile`
      padding-left: 190px;
      margin-left: 0;
    `}

    &:not(:last-child) {
      padding-bottom: 15px;
      border-bottom: 1px solid ${colors.LIGHT_GREY};
      margin-bottom: 15px;
    }
  }
`

const DList = ({ horizontal, ...rest }) => {
  const Component = horizontal ? HorizontalDList : VerticalDList
  return <Component {...rest} />
}

DList.propTypes = {
  horizontal: PropTypes.bool,
}

DList.defaultProps = {
  horizontal: false,
}

export default DList
