import React from 'react'
import PropTypes from 'prop-types'
import some from 'lodash/some'
import Checkbox from 'components-v2/atoms/Checkbox'
import Tooltip from 'components-v2/molecules/Tooltip'
import Weight from 'components-v2/molecules/Weight'
import AssessmentElementNoteTooltip from 'components/shared/AssessmentElementNoteTooltip'
import AssessmentElementAnswerForm from 'components/shared/AssessmentElementAnswerForm'
import { useAuthorizations } from 'hooks'
import {
  Row,
  DragSourceColumn,
  Key,
  QuestionColumn,
  WeightColumn,
  CheckboxColumn,
  IconColumn,
  IconButton,
} from '../styles'
import {
  EyeIcon,
  PencilIcon,
  TrashIcon,
  CogsIcon,
  FileDownloadIcon,
} from '../../styles'

const QuestionRow = ({
  element,
  selected,
  selectDisabled,
  editable,
  skipped,
  useCustomKeys,
  autoScoreOptions,
  dragSourceRef,
  onFieldChange,
  onEdit,
  onDelete,
  onEditSkipConditions,
  onElementQuestionAutomationEdit,
  onViewQuestionAttachmentsClick,
  onToggleSelection,
  onAutoScoreChange,
  ...rest
}) => {
  const [userCanEdit] = useAuthorizations('update')
  const handleFieldChange = React.useCallback(
    (field, value) => onFieldChange(element.id, field, value),
    [element.id],
  )
  const automationsPresent = element.question_automations.length > 0
  const automationsAllowed = !(
    element.type === 'TextQuestion' && element.weight === '0.0'
  )

  const artifactsRequired = some(element.choices, 'require_artifacts')
  const commentsRequired = !element.allow_attachments && artifactsRequired
  const attachmentsRequired = !element.allow_comments && artifactsRequired

  return (
    <Row {...rest}>
      <DragSourceColumn
        ref={dragSourceRef}
        customKey={useCustomKeys}
        draggable={!!dragSourceRef}
      >
        {editable && userCanEdit && (
          <Checkbox
            checked={selected}
            disabled={selectDisabled}
            onChange={(e) => onToggleSelection(element.id, e.target.checked)}
          />
        )}
        <Key>
          {element.key}
          {element.required && ' *'}
        </Key>
      </DragSourceColumn>
      <QuestionColumn>
        <p>
          {element.label}&nbsp;
          <AssessmentElementNoteTooltip element={element} />
        </p>
        <AssessmentElementAnswerForm
          element={element}
          disabled
          showAutoScores={element.weight > 0}
          autoScoreOptions={autoScoreOptions}
          onAutoScoreChange={(choiceId, score) =>
            onAutoScoreChange(element.id, choiceId, score)
          }
        />
      </QuestionColumn>
      <WeightColumn>
        <Weight
          value={element.weight}
          disabled={!editable}
          onChange={(weight) => handleFieldChange('weight', weight)}
          data-test-id={`question-weight-${element.id}`}
        />
      </WeightColumn>
      <IconColumn>
        <FileDownloadIcon
          tabIndex={0}
          hasAttachments={element.attachments?.length > 0}
          onClick={() => {
            onViewQuestionAttachmentsClick(element)
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 13 || e.keyCode === 32) {
              onViewQuestionAttachmentsClick(element)
            }
          }}
          data-test-id={`question-attachment-${element.id}`}
        />
      </IconColumn>
      <CheckboxColumn>
        <Checkbox
          checked={element.allow_comments}
          disabled={!editable || commentsRequired}
          onChange={(e) =>
            handleFieldChange('allow_comments', e.target.checked)
          }
          onKeyUp={(e) => {
            if (e.keyCode === 13 || e.keyCode === 32) {
              handleFieldChange('allow_comments', !e.target.checked)
            }
          }}
          data-test-id={`comment-checkbox-${element.id}`}
        />
      </CheckboxColumn>
      <CheckboxColumn>
        <Checkbox
          checked={element.allow_attachments}
          disabled={!editable || attachmentsRequired}
          onChange={(e) =>
            handleFieldChange('allow_attachments', e.target.checked)
          }
          onKeyUp={(e) => {
            if (e.keyCode === 13 || e.keyCode === 32) {
              handleFieldChange('allow_attachments', !e.target.checked)
            }
          }}
          data-test-id={`attachments-checkbox-${element.id}`}
        />
      </CheckboxColumn>
      <IconColumn>
        {automationsAllowed ? (
          <CogsIcon
            tabIndex={0}
            hasAutomations={automationsPresent}
            inactive={!automationsAllowed || !userCanEdit}
            onClick={() => {
              if (automationsAllowed && userCanEdit)
                onElementQuestionAutomationEdit(element)
            }}
            onKeyDown={(e) => {
              if (
                (e.keyCode === 13 || e.keyCode === 32) &&
                automationsAllowed &&
                userCanEdit
              ) {
                onElementQuestionAutomationEdit(element)
              }
            }}
            data-test-id={`automations-icon-${element.id}`}
          />
        ) : (
          <Tooltip parent={<CogsIcon inactive />}>
            Question automations not supported for text questions with zero
            weight
          </Tooltip>
        )}
      </IconColumn>
      <IconColumn>
        <IconButton
          onClick={() => onEditSkipConditions(element.id)}
          data-test-id={`visibility-icon-${element.id}`}
        >
          <EyeIcon inactive={!editable} slashed={skipped} />
        </IconButton>
      </IconColumn>
      {editable && (
        <>
          <IconColumn>
            <PencilIcon
              tabIndex={0}
              onClick={() => onEdit(element.id)}
              onKeyDown={(e) => {
                if (e.keyCode === 13 || e.keyCode === 32) {
                  onEdit(element.id)
                }
              }}
              data-test-id={`edit-question-icon-${element.id}`}
            />
          </IconColumn>
          <IconColumn>
            <TrashIcon
              tabIndex={0}
              onClick={() => onDelete(element.id)}
              onKeyDown={(e) => {
                if (e.keyCode === 13 || e.keyCode === 32) {
                  onDelete(element.id)
                }
              }}
              data-test-id={`delete-question-icon-${element.id}`}
            />
          </IconColumn>
        </>
      )}
    </Row>
  )
}

QuestionRow.propTypes = {
  element: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  selectDisabled: PropTypes.bool,
  editable: PropTypes.bool,
  skipped: PropTypes.bool,
  useCustomKeys: PropTypes.bool,
  autoScoreOptions: PropTypes.array.isRequired,
  dragSourceRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  onFieldChange: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEditSkipConditions: PropTypes.func.isRequired,
  onElementQuestionAutomationEdit: PropTypes.func.isRequired,
  onViewQuestionAttachmentsClick: PropTypes.func.isRequired,
  onToggleSelection: PropTypes.func.isRequired,
  onAutoScoreChange: PropTypes.func.isRequired,
}

export default React.memo(QuestionRow)
