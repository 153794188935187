/* eslint-disable no-underscore-dangle */
/*
 * Somewhat like a hash that keeps track of insertion
 * order for indexing/reference purposes.
 * Usage:
 *  cm = new ColumnMap({
 *    KEY1: 'val1',
 *    KEY2: 'val2',
 *    KEY3: 'val3',
 *    KEY4: 'val4',
 *    KEY5: 'val5'
 *  })
 *
 *  // to persist index elsewhere:
 *  cm.stringsToNums(['val3', 'val5', 'val2']);
 *  // => [1, 2, 4]
 *
 *  // to dereference indices:
 *  cm.numsToStrings([1, 2, 4]);
 *  // => ['val2', 'val3', 'val5']
 */
export default class ColumnMap {
  constructor(obj) {
    Object.keys(obj).forEach((key) => {
      const len = this.#__colnames__.length
      const val = obj[key]
      this.#__colnames__[len] = val
      this.#__idxmap__[val] = len
      this[key] = val
    })
  }

  numsToStrings(idxArr) {
    return idxArr.map((i) => this.#__colnames__[i])
  }

  stringsToNums(strArr) {
    return strArr.map((s) => this.#__idxmap__[s])
  }

  #__colnames__ = []

  #__idxmap__ = {}
}
