import React from 'react'
import PropTypes from 'prop-types'
import { colors } from 'styles'
import { Label, Indicator } from './styles'

const Radio = ({
  label,
  color,
  disabled,
  inline,
  className,
  style,
  ...rest
}) => (
  <Label
    color={color}
    disabled={disabled}
    inline={inline}
    className={className}
    style={style}
    hasLabel={!!label}
  >
    <input {...rest} type="radio" disabled={disabled} />
    <Indicator />
    {label}
  </Label>
)

Radio.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
}

Radio.defaultProps = {
  color: colors.PRIMARY,
}

export default Radio
