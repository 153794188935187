import React, { useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'
import { IssueStatusTypes } from 'constants/index'
import { useTableColumnsPref, useQueryParams, useAuthorizations } from 'hooks'
import {
  useUsersFetcher,
  useClientTagsFetcher,
  useClientIssuesFetcher,
  useClientIssueCategoriesFetcher,
} from 'apis'

import View from './View'
import { getFilterConfigList, getIssueQueryParams, exportToCSV } from './utils'
import {
  ActionMenuItemTypes,
  ColumnDataFieldTypes,
  queryParamsConfig,
} from './constants'
import useCRUDIssue from './useCRUDIssue'

const Container = ({
  vendorId,
  assessmentId,
  newButtonVisible,
  modalFormat,
  columnOrder,
  columnTogglerVisible,
  orgId,
  userPrefs,
  onIssuesTransitioned,
}) => {
  const [columnToggles, setColumnToggles] = useTableColumnsPref(
    userPrefs,
    'table_columns.issues',
    ColumnDataFieldTypes,
    orgId,
  )
  const [selected, setSelected] = useState({})
  const { data: categories = [] } = useClientIssueCategoriesFetcher()
  const { data: users = [] } = useUsersFetcher()
  const { data: vendorTags = [] } = useClientTagsFetcher(undefined, {
    enabled: !vendorId,
  })
  const [queryParams, setQueryParams] = useQueryParams(queryParamsConfig)
  const { page, sizePerPage, sortField, sortOrder, searchText } = queryParams

  const issueQueryParams = getIssueQueryParams(
    queryParams,
    vendorId,
    assessmentId,
  )
  const {
    data: issues = [],
    totalSize,
    isFetching,
  } = useClientIssuesFetcher({
    params: issueQueryParams,
  })
  const statusIdx = useMemo(
    () => mapValues(keyBy(issues, 'id'), 'status'),
    [issues],
  )

  const [canUpdate, canClose] = useAuthorizations(
    ['update', 'Issue'],
    ['close', 'Issue'],
  )
  const selectedIds = Object.keys(selected)
  const archiveMenuItemDisabled =
    !canUpdate ||
    selectedIds.length === 0 ||
    !selectedIds.every((x) => IssueStatusTypes.ARCHIVED !== statusIdx[x])
  const archiveMenuItemHidden = !canClose
  const sendMenuItemDisabled =
    !canUpdate ||
    selectedIds.length === 0 ||
    !selectedIds.every((x) => IssueStatusTypes.CREATED === statusIdx[x])
  const resendMenuItemDisabled =
    !canUpdate ||
    selectedIds.length === 0 ||
    !selectedIds.every((x) => IssueStatusTypes.OPENED === statusIdx[x])
  const deleteMenuItemDisabled = !canUpdate || selectedIds.length === 0
  const filterConfigList = useMemo(
    () => getFilterConfigList(users, categories, vendorTags, !!vendorId),
    [users, categories, vendorTags, vendorId],
  )
  const filter = {
    statuses: { selected: queryParams.statuses },
    categories: { selected: queryParams.categories },
    vendorTags: {
      selected: queryParams.vendorTags,
      anyMatch: queryParams.vendorTagsAnyMatch,
    },
    clientAssignees: {
      other: queryParams.unassigned,
      selected: queryParams.clientAssignees,
    },
    resolutions: { selected: queryParams.resolutions },
    priorities: { selected: queryParams.priorities },
    riskTiers: { selected: queryParams.riskTiers },
  }

  const handleSearchChange = useCallback(
    (newValue) => {
      setQueryParams({
        page: 1,
        searchText: newValue,
      })
      setSelected({})
    },
    [setQueryParams],
  )

  const handleFilterChange = useCallback(
    (newValue) => {
      setQueryParams({
        page: 1,
        statuses: newValue.statuses?.selected,
        categories: newValue.categories?.selected,
        vendorTags: newValue.vendorTags?.selected,
        vendorTagsAnyMatch: newValue.vendorTags?.anyMatch,
        clientAssignees: newValue.clientAssignees?.selected,
        unassigned: newValue.clientAssignees?.other,
        resolutions: newValue.resolutions?.selected,
        priorities: newValue.priorities?.selected,
        riskTiers: newValue.riskTiers?.selected,
      })
      setSelected({})
    },
    [setQueryParams],
  )

  const {
    createIssue,
    sendIssue,
    archiveIssues,
    sendIssues,
    resendIssues,
    deleteIssues,
  } = useCRUDIssue(vendorId, issues, onIssuesTransitioned)

  const handleMenuItemSelect = useCallback(
    (key) => {
      switch (key) {
        case ActionMenuItemTypes.EXPORT_CURRENT_VIEW:
          exportToCSV(queryParams, vendorId, assessmentId)
          break
        case ActionMenuItemTypes.ARCHIVE_SELECTED:
          archiveIssues(selectedIds, () => setSelected({}))
          break
        case ActionMenuItemTypes.SEND_SELECTED:
          sendIssues(selectedIds, () => setSelected({}))
          break
        case ActionMenuItemTypes.RESEND_SELECTED:
          resendIssues(selectedIds, () => setSelected({}))
          break
        case ActionMenuItemTypes.DELETE_SELECTED:
          deleteIssues(selectedIds, () => setSelected({}))
          break
        default:
      }
    },
    [
      queryParams,
      vendorId,
      assessmentId,
      archiveIssues,
      sendIssues,
      resendIssues,
      deleteIssues,
      selectedIds,
    ],
  )

  return (
    <View
      issues={issues}
      selected={selected}
      loading={isFetching}
      totalSize={totalSize}
      page={page}
      sizePerPage={sizePerPage}
      sortField={sortField}
      sortOrder={sortOrder}
      searchText={searchText}
      filter={filter}
      columnToggles={columnToggles}
      vendorColumnHidden={!!vendorId}
      newButtonVisible={newButtonVisible}
      columnTogglerVisible={columnTogglerVisible}
      archiveMenuItemDisabled={archiveMenuItemDisabled}
      archiveMenuItemHidden={archiveMenuItemHidden}
      sendMenuItemDisabled={sendMenuItemDisabled}
      resendMenuItemDisabled={resendMenuItemDisabled}
      deleteMenuItemDisabled={deleteMenuItemDisabled}
      filterConfigList={filterConfigList}
      modalFormat={modalFormat}
      columnOrder={columnOrder}
      onPaginationChange={setQueryParams}
      onSortingChange={setQueryParams}
      onSearchChange={handleSearchChange}
      onColumnTogglesChange={setColumnToggles}
      onFilterChange={handleFilterChange}
      onMenuItemSelect={handleMenuItemSelect}
      onRowSelectionChange={setSelected}
      onSendIssue={sendIssue}
      onCreateIssue={createIssue}
    />
  )
}

Container.propTypes = {
  vendorId: PropTypes.string,
  assessmentId: PropTypes.string,
  newButtonVisible: PropTypes.bool,
  modalFormat: PropTypes.bool,
  columnOrder: PropTypes.array,
  columnTogglerVisible: PropTypes.bool,
  orgId: PropTypes.string,
  userPrefs: PropTypes.object,
  onIssuesTransitioned: PropTypes.func,
}

export default Container
