import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { dateFormatterFactory } from 'lib/table'
import { getEndpointUrl } from 'utils/url'
import SimpleDialog from 'components-v2/molecules/SimpleDialog'
import Table from 'components-v2/molecules/Table'
import Tooltip from 'components-v2/molecules/Tooltip'
import Uploader, { SingleUploader } from 'components-v2/molecules/Uploader'
import CustomUploaderLayout from './CustomUploaderLayout'
import { EditIcon, LinkButton } from './styles'

const dateFormatter = dateFormatterFactory()
const tableColumns = [
  {
    id: 'name',
    header: 'File Name',
    cell: (props) => {
      const display = (
        <>
          <a href={props.row.original.url} target="_blank">
            {props.row.original.name}
          </a>
          {props.row.original.kind && (
            <small> ({props.row.original.kind})</small>
          )}
        </>
      )
      if (props.row.original.full_name !== props.row.original.name)
        return (
          <Tooltip parent={display}>{props.row.original.full_name}</Tooltip>
        )
      return <span>{display}</span>
    },
  },
  {
    id: 'created_at',
    header: 'Uploaded',
    cell: dateFormatter,
    width: '15%',
  },
]

const updateButton = (
  <Tooltip
    parent={
      <>
        <EditIcon /> Update
      </>
    }
  >
    Upload an updated version of this file
  </Tooltip>
)

const QuestionAttachmentsModal = ({
  questionId,
  loading,
  attachments,
  onAttachmentsChange,
  canAdd,
  canReplace,
  canDelete,
  confirmReplace,
  confirmDelete,
  onClose,
  ...rest
}) => {
  const triggerUploader = React.useRef(() => {})

  const uploadEndpoint = getEndpointUrl('clientElement', { id: questionId })

  const buttons = useMemo(() => {
    const arr = [
      {
        onClick: onClose,
        children: 'Close',
      },
    ]
    if (canAdd) {
      arr.push({
        color: 'primary',
        onClick: triggerUploader.current,
        children: 'Add Attachment',
      })
    }
    return arr
  }, [canAdd, onClose])

  const cols = useMemo(() => {
    if (canReplace || canDelete) {
      return tableColumns.concat({
        id: 'dfAction',
        header: '',
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (props) => (
          <SingleUploader
            // eslint-disable-next-line react/prop-types
            file={props.row.original}
            model="element"
            formParam="attachments"
            additionalPayload={{ id: questionId }}
            canReplace
            canRemove={canDelete}
            url={uploadEndpoint}
            description="Upload an updated version of this file"
            displayLabel={false}
            confirmReplace={confirmReplace}
            confirmDelete={confirmDelete}
            onChange={onAttachmentsChange}
            buttonComponent={LinkButton}
            buttonContent={updateButton}
            renderLayout={CustomUploaderLayout}
          />
        ),
        width: '15%',
      })
    }
    return tableColumns
  }, [
    questionId,
    canReplace,
    canDelete,
    confirmReplace,
    confirmDelete,
    onAttachmentsChange,
  ])

  return (
    <SimpleDialog
      {...rest}
      title="Question Attachments"
      buttons={buttons}
      onClose={onClose}
    >
      <>
        <Table
          loading={loading}
          data={attachments || []}
          columns={cols}
          noDataText="No attachments found."
          enableSorting={false}
          enablePagination={false}
          type={Table.types.ALT}
        />
        {canAdd && (
          // don't need to see this, just need it to be there
          // would be great if the per-file upload progress could
          // just add rows to the table, but that's a bit out of
          // scope at the moment
          <div style={{ display: 'none' }}>
            <Uploader
              model="element"
              url={uploadEndpoint}
              description="Upload additional files"
              onChange={onAttachmentsChange}
              setTrigger={(func) => {
                triggerUploader.current = func
              }}
            />
          </div>
        )}
      </>
    </SimpleDialog>
  )
}

QuestionAttachmentsModal.propTypes = {
  questionId: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  attachments: PropTypes.array,
  onAttachmentsChange: PropTypes.func,
  canAdd: PropTypes.bool,
  canReplace: PropTypes.bool,
  canDelete: PropTypes.bool,
  confirmReplace: PropTypes.func,
  confirmDelete: PropTypes.func,
  onClose: PropTypes.func.isRequired,
}

QuestionAttachmentsModal.defaultProps = {
  onAttachmentsChange: () => {},
  canAdd: false,
  canReplace: false,
  canDelete: true,
}

export default QuestionAttachmentsModal
