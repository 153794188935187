import React from 'react'
import PropTypes from 'prop-types'
import { useAuthorizations } from 'hooks'
import Checkbox from 'components-v2/atoms/Checkbox'
import {
  Wrapper,
  StyledKey,
  LabelColumn,
  StyledDragSourceColumn,
} from './styles'
import { IconColumn } from '../styles'
import { TrashIcon } from '../../styles'
import EditSectionPopover from './EditSectionPopover'

const SectionHeadingRow = ({
  element,
  selected,
  selectDisabled,
  skipped,
  editable,
  useCustomKeys,
  dragSourceRef,
  onEdit,
  onDelete,
  onEditSkipConditions,
  onToggleSelection,
  ...rest
}) => {
  const [userCanEdit] = useAuthorizations('update')
  return (
    <Wrapper {...rest} id={element.id}>
      <StyledDragSourceColumn
        ref={dragSourceRef}
        customKey={useCustomKeys}
        draggable={!!dragSourceRef}
      >
        {editable && userCanEdit && (
          <Checkbox
            checked={selected}
            disabled={selectDisabled}
            onChange={(e) => onToggleSelection(element.id, e.target.checked)}
          />
        )}
        <StyledKey>{element.key}</StyledKey>
      </StyledDragSourceColumn>
      <LabelColumn className="label-column">{element.label}</LabelColumn>
      {editable && (
        <>
          <IconColumn>
            <EditSectionPopover element={element} onSave={onEdit} />
          </IconColumn>
          <IconColumn>
            <TrashIcon onClick={() => onDelete(element.id)} />
          </IconColumn>
        </>
      )}
    </Wrapper>
  )
}

SectionHeadingRow.propTypes = {
  element: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  selectDisabled: PropTypes.bool,
  skipped: PropTypes.bool,
  editable: PropTypes.bool,
  useCustomKeys: PropTypes.bool,
  dragSourceRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEditSkipConditions: PropTypes.func.isRequired,
  onToggleSelection: PropTypes.func.isRequired,
}

export default React.memo(SectionHeadingRow)
