import React from 'react'
// import PropTypes from 'prop-types'
import FormField from 'components-v2/molecules/FormField'

const BooleanFieldDetails = () => {
  // placeholder
  const primary = <React.Fragment key="primary_fields" />

  const advanced = (
    <React.Fragment key="advanced_fields">
      <FormField
        name="render_opts.use_toggle"
        label="Option Display"
        controlProps={{ style: { width: 100 } }}
        type={FormField.types.RADIO_GROUP}
        options={[
          { label: 'Checkbox', value: false },
          { label: 'Toggle', value: true },
        ]}
        horizontal
        inline
      />
    </React.Fragment>
  )

  return [primary, advanced]
}
// react/no-unused-prop-type
// BooleanFieldDetails.propTypes = {
//   fieldDef: PropTypes.object,
//   typeConfig: PropTypes.object,
//   formProps: PropTypes.object,
// }

export default BooleanFieldDetails
