import React from 'react'
import styled from 'styled-components'
import { media } from 'styles'

const Button = styled.button`
  background-color: transparent;
  background-image: none;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-left: auto;
  margin-right: 15px;
  padding: 9px 10px;
  position: relative;

  &:hover,
  &:focus {
    background-color: #ddd;
  }

  ${media.nmobile`
    display: none !important;
  `}
`

const IconBar = styled.span`
  border-radius: 1px;
  display: block;
  height: 2px;
  width: 22px;
  background-color: #888;

  & + & {
    margin-top: 4px;
  }
`

export default function Toggle(props) {
  return (
    <Button type="button" {...props}>
      <span className="sr-only">Toggle navigation</span>
      <IconBar />
      <IconBar />
      <IconBar />
    </Button>
  )
}
