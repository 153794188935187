import React from 'react'
import countBy from 'lodash/countBy'
import Table from 'components-v2/molecules/Table'
import { useLibraryManageSharesByClientFetcher } from 'apis'
import useListShares from './useListShares'
import { NoDataText } from './styles'

const columns = [
  {
    id: 'name',
    header: 'Client',
    width: '20%',
  },
  {
    id: 'shares',
    header: 'Items Shared',
    accessorFn: (e) => countBy(e.shares, (x) => x.status).active || 0,
    width: '10%',
  },
  {
    id: 'requests',
    header: 'Items Requested',
    accessorFn: (e) => countBy(e.shares, (x) => x.status).requested || 0,
    width: '10%',
  },
]

export default () => {
  const { data: clients, isFetching } = useLibraryManageSharesByClientFetcher()
  const listShares = useListShares()

  if (!isFetching && clients.length === 0) {
    return (
      <NoDataText>No clients have interacted with your content yet</NoDataText>
    )
  }

  return (
    <Table
      loading={isFetching}
      data={clients || []}
      columns={columns}
      enablePagination={false}
      onRowClick={(row) => listShares(row.original)}
      type={Table.types.ALT}
    />
  )
}
