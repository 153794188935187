import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import RatingSelect from 'components-v2/molecules/RatingSelect'
import Tooltip from 'components-v2/molecules/Tooltip'
import { getResponseScoreOptionsWithLabel } from 'lib/assessment'
import useAuthorizations from 'hooks/useAuthorizations'

const Zero = styled.div`
  background: #eee
    url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 20 30'> <path d='M20 0 L0 30' fill='none' stroke='LightGrey' stroke-width='1' /></svg>");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
`

const ResponseScoreSelect = ({
  ratingLabelSet,
  weight,
  RenderMarkers,
  RenderMarker,
  disabled,
  ...rest
}) => {
  const [userCanUpdate] = useAuthorizations('update')
  const isDisabled = !userCanUpdate || disabled
  const markers = React.useMemo(
    () => getResponseScoreOptionsWithLabel(ratingLabelSet),
    [ratingLabelSet],
  )

  const ZeroMarkers = React.useMemo(
    // eslint-disable-next-line react/no-unstable-nested-components
    () => (props) =>
      (
        <Tooltip parent={<RenderMarkers {...props} />}>
          Questions with zero weight are not scored.
        </Tooltip>
      ),
    [RenderMarkers],
  )

  const ZeroMarker = React.useMemo(
    // eslint-disable-next-line react/no-unstable-nested-components
    () => (props) => <Zero {...props} as={RenderMarker} />,
    [RenderMarker],
  )

  if (weight === '0.0') {
    return (
      <RatingSelect
        {...rest}
        markers={markers}
        RenderMarkers={ZeroMarkers}
        RenderMarker={ZeroMarker}
        labelVisible={false}
        tooltipVisible={false}
        readOnly
      />
    )
  }

  return (
    <RatingSelect
      {...rest}
      disabled={isDisabled}
      markers={markers}
      RenderMarkers={RenderMarkers}
      RenderMarker={RenderMarker}
    />
  )
}

ResponseScoreSelect.propTypes = {
  ratingLabelSet: PropTypes.string,
  weight: PropTypes.any,
  disabled: PropTypes.bool,
  RenderMarkers: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  RenderMarker: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
}

ResponseScoreSelect.defaultProps = RatingSelect.defaultProps

ResponseScoreSelect.Markers = RatingSelect.Markers
ResponseScoreSelect.Marker = RatingSelect.Marker
ResponseScoreSelect.Label = RatingSelect.Label

export default ResponseScoreSelect
