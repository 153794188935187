import { useCallback } from 'react'
import { LabelTypes } from 'constants/index'
import useCreateVendor, {
  CreateVendorModeTypes,
} from 'components/client/shared/useCreateVendor'
import { useConfirm } from 'hooks'
import { useDeclineClientVendorRequest } from 'apis'
import { getPageUrl } from 'utils/url'
import {
  getCalcConfig,
  calculateRiskTier,
} from 'components/elements/risk_tier_calculator/util'

export default function useVendorRequestMutations(
  vendorRequest,
  globorgEnabled,
) {
  const { mutateAsync: declineClientVendorRequest } =
    useDeclineClientVendorRequest()
  const openConfirm = useConfirm()
  const createVendor = useCreateVendor()

  const approveVendorRequest = useCallback(() => {
    const calcConfig = getCalcConfig('default')
    createVendor({
      id: vendorRequest.id,
      name: vendorRequest.name,
      suggestedContribs: vendorRequest.organization_detail.vendor_contacts,
      riskTier: calculateRiskTier(
        vendorRequest.organization_detail.vendor_risk_tier_answers,
        calcConfig,
      ),
      riskTierAnswers:
        vendorRequest.organization_detail.vendor_risk_tier_answers,
      showGloborg: globorgEnabled,
      mode: CreateVendorModeTypes.ACTIVATE,
      onCreated: (vendor) => {
        // this works to update the page but not the location bar,
        // so a subsequent page refresh breaks
        // apis.getClientVendor(vendor.result_vendor_id).then((result) => {
        //   this.props.onVendorChange(result.data)
        // })
        window.location.href = getPageUrl('clientVendor', {
          id: vendor.id,
        })
      },
    })
  }, [createVendor, vendorRequest, globorgEnabled])

  const declineVendorRequest = useCallback(() => {
    openConfirm({
      title: `Decline the following ${LabelTypes.VENDOR.toLowerCase()} request?`,
      body: vendorRequest.name,
      confirmText: `Yes, decline this ${LabelTypes.VENDOR.toLowerCase()} request`,
      cancelText: 'Nevermind',
      onConfirm: () => {
        declineClientVendorRequest({ id: vendorRequest.id }).then(() => {
          window.location.href = getPageUrl(
            'clientVendors',
            {},
            { tab: 'requests' },
          )
        })
      },
    })
  }, [openConfirm, vendorRequest, declineClientVendorRequest])

  return {
    approveVendorRequest,
    declineVendorRequest,
  }
}
