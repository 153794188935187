import styled from 'styled-components'
import A from 'components-v2/atoms/A'
import Radio from 'components-v2/atoms/Radio'
import Button from 'components-v2/atoms/Button'
import Loader from 'components-v2/molecules/Loader'
import { colors, mixins } from 'styles'

export const NameInputWrapper = styled.div`
  position: relative;
`

export const ResultsLoader = styled(Loader).attrs({ size: 16 })`
  position: absolute;
  top: 10px;
  right: 10px;
`

export const GlobOrgResultsWrapper = styled.div`
  position: relative;
  background: ${colors.LIGHT_GREY};
  border-radius: 4px;
  padding: 15px 18px;
  margin-top: 6px;
`

export const GlobOrgResultsTitle = styled.div`
  ${mixins.smallHeader}
  letter-spacing: 0;
`

export const GlobOrgResults = styled.div`
  margin: 5px 0;
`

export const GlobOrg = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6px;
`

export const GlobOrgRadioWrapper = styled.div`
  flex: none;
  width: 140px;
  margin-right: 14px;
`

export const GlobOrgRadio = styled(Radio)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: top;
`

export const GlobOrgDomain = styled(A)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const GlobOrgResultsClose = styled.button.attrs({ type: 'button' })`
  ${mixins.clearButtonStyle}
  position: absolute;
  top: 10px;
  right: 7px;
  font-size: 14px;
  color: ${colors.GREY};

  &:hover,
  &:active,
  &:focus {
    color: ${colors.MED_GREY};
  }
`

export const GlobOrgResultsShowAll = styled(Button).attrs({
  variant: 'text',
  color: 'primary',
})`
  ${mixins.smallHeader}
  letter-spacing: 0;
  font-size: 11px;
`
