import React from 'react'
import PropTypes from 'prop-types'
import IssueStub from './IssueStub'
import ClientContractClause from './AutoReport'

const sourceTypeMap = {
  // eslint-disable-next-line camelcase
  issue_stub: IssueStub,
  clause: ClientContractClause,
}

const AutomationSourceDisplay = ({ sourceType, extras, ...rest }) => {
  const Component = sourceTypeMap[sourceType]
  return <Component extras={extras[sourceType]} {...rest} />
}

AutomationSourceDisplay.propTypes = {
  sourceType: PropTypes.string.isRequired,
  currentAutomation: PropTypes.object.isRequired,
  currentSource: PropTypes.object,
  mode: PropTypes.string.isRequired,
  extras: PropTypes.object,
  setCurrentSource: PropTypes.func.isRequired,
  onOpenSourceForm: PropTypes.func.isRequired,
  onLargeTextViewClick: PropTypes.func.isRequired,
  onResetSource: PropTypes.func.isRequired,
  onClearSource: PropTypes.func.isRequired,
}

export default AutomationSourceDisplay
