import styled from 'styled-components'
import isNumber from 'lodash/isNumber'
import { mixins } from 'styles'

export const ChartWrapper = styled.div`
  ${mixins.avoidPageBreak}
  display: flex;
  align-items: center;
  height: ${(props) =>
    isNumber(props.height) ? `${props.height}px` : props.height};
`

export const Loader = styled.div`
  flex: 1;
  text-align: center;
`
