import styled, { css } from 'styled-components'
import Label from 'components-v2/atoms/Label'
import { AssessmentStatusTypes } from 'constants/index'
import { DocumentAttach } from '@styled-icons/ionicons-solid/DocumentAttach'
import Icon from 'components-v2/atoms/Icon'
import { colors } from 'styles'
import Capsule from 'components-v2/atoms/Capsule'

export const StatusLabel = styled.span`
  white-space: nowrap;
  ${(props) =>
    props.status === AssessmentStatusTypes.EXPIRED &&
    css`
      color: ${colors.RED};
    `}
`
export const RiskRatingLabel = styled(Label)`
  width: 50px;
`

export const SubmittedFile = styled(Icon).attrs({ icon: DocumentAttach })`
  vertical-align: baseline;
  margin-left: 4px;
`

export const RisReconScoreCapsule = styled(Capsule)`
  & > *:first-child {
    font-weight: 500;
    text-transform: uppercase;
    width: 34px;
  }

  & > *:last-child {
    font-weight: 500;
    width: 40px;
  }
`

export const ArgosRiskScoreCapsule = styled(Capsule)`
  & > *:first-child {
    font-weight: 500;
    text-transform: uppercase;
    width: 25px;
  }

  & > *:last-child {
    font-weight: 500;
    width: 35px;
  }
`

export const OsanoScoreCapsule = styled(Capsule)`
  & > *:first-child {
    font-size: 11px;
    font-weight: 500;
    text-transform: uppercase;
    width: 85px;
  }

  & > *:last-child {
    font-weight: 500;
    width: 40px;
  }
`
