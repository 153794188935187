import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import isEqual from 'lodash/isEqual'
import FormField from 'components-v2/molecules/FormField'
import FormDialog from 'components-v2/organisms/FormDialog'
import { exportClientIssuesToCSV } from 'lib/export'
import { useClientIssueCategoriesFetcher } from 'apis'
import { IssueStatusTypes } from 'constants/index'

const statusFilterOptions = [
  { value: IssueStatusTypes.CREATED, label: 'Created' },
  { value: IssueStatusTypes.OPENED, label: 'Open' },
  { value: IssueStatusTypes.RESPONDED, label: 'Needs Review' },
  { value: IssueStatusTypes.CLOSED, label: 'Closed' },
  { value: IssueStatusTypes.ARCHIVED, label: 'Archived' },
]

const formConfig = {
  validationSchema: yup.object().shape({
    statuses: yup.array().required('Please choose at least 1 status'),
  }),
  initialValues: {
    categories: [],
    statuses: [],
  },
}

const ExportIssuesModal = (props) => {
  const { data: categories = [] } = useClientIssueCategoriesFetcher()

  const handleSubmit = React.useCallback(
    (formData) => {
      // There can be issues not belonged into any categories.
      // If all selected, do not send categories param
      const allCategoryIds = categories.map(({ id }) => id)
      if (
        formData.categories &&
        formData.categories.length > 0 &&
        !isEqual(formData.categories.sort(), allCategoryIds.sort())
      ) {
        exportClientIssuesToCSV(formData)
      } else {
        exportClientIssuesToCSV({ statuses: formData.statuses })
      }
      props.onClose()
    },
    [categories, props],
  )

  const categoriesOptions = React.useMemo(
    () =>
      categories.map((category) => ({
        value: category.id,
        label: category.name,
      })),
    [categories],
  )

  return (
    <FormDialog
      {...props}
      title="Export the following issue details"
      formConfig={formConfig}
      onSubmit={handleSubmit}
    >
      {categories.length > 0 && (
        <FormField
          name="categories"
          label="Categories"
          type={FormField.types.MULTI_CHECKBOX}
          options={categoriesOptions}
          selectAllVisible
          horizontal
        />
      )}
      <FormField
        name="statuses"
        label="Statuses"
        type={FormField.types.MULTI_CHECKBOX}
        options={statusFilterOptions}
        selectAllVisible
        horizontal
      />
    </FormDialog>
  )
}

ExportIssuesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default ExportIssuesModal
