import styled from 'styled-components'
import ListGroup from 'components-v2/molecules/ListGroup'
import { mixins } from 'styles'

export const Section = styled.div`
  margin-top: 50px;
`

export const StyledListGroupItem = styled(ListGroup.Item)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ListGroupItemContent = styled.div`
  flex: 1;
  margin-right: 20px;
  min-width: 0;
  ${mixins.truncate}
`
