import React from 'react'
// import PropTypes from 'prop-types'
import * as yup from 'yup'
import FormField, { ControlLabel } from 'components-v2/molecules/FormField'
import { OptionGroup, OptionLabel } from './styles'

export const integerRules = () =>
  yup.object({
    value: yup.object({
      min: yup.number().label('Minimum').integer(),
      max: yup.number().label('Maximum').integer(),
    }),
  })

export const floatRules = () =>
  yup.object({
    value: yup.object({
      min: yup.number().label('Minimum'),
      max: yup.number().label('Maximum'),
    }),
  })

const NumberFieldDetails = () => {
  const primary = <React.Fragment key="primary_fields" />

  const advanced = (
    <React.Fragment key="advanced_fields">
      <ControlLabel>Limit allowed values</ControlLabel>
      <OptionGroup>
        <FormField
          name="render_opts.validations.value.min"
          label="Minimum"
          controlProps={{ style: { width: 100 } }}
          renderControlLabel={OptionLabel}
          placeholder={0}
          horizontal
          inline
        />
        <FormField
          name="render_opts.validations.value.max"
          label="Maximum"
          controlProps={{ style: { width: 100 } }}
          renderControlLabel={OptionLabel}
          placeholder="No limit"
          horizontal
          inline
        />
      </OptionGroup>
    </React.Fragment>
  )

  return [primary, advanced]
}
// react/no-unused-prop-type
// NumberFieldDetails.propTypes = {
//   fieldDef: PropTypes.object.isRequired,
//   typeConfig: PropTypes.object,
//   formProps: PropTypes.object.isRequired,
// }

export default NumberFieldDetails
