import React from 'react'
import find from 'lodash/find'
import Icon from 'components-v2/atoms/Icon'
import Menu from 'components-v2/molecules/Menu'
import { dateFormatterFactory } from 'lib/table'
import * as dueDateFormatter from './formatters/DueDateFormatter'
import * as reminderFormatter from './formatters/ReminderFormatter'
import * as templateFormatter from './formatters/TemplateFormatter'
import { EllipsisIconButton } from './styles'

const NOT_LAUNCHED = 'Not Launched'
const dateFormatter = dateFormatterFactory()

export const getTableColumns = (
  authManager,
  assessments,
  assessmentTemplates,
  templateColumnsVisible,
  reminderColumnVisible,
  quickActionColumnVisible,
  quickActions,
  onApproveExtensionRequest,
) => {
  const canManageProject = authManager.userCan('manage', 'Project')
  const canLinkTemplate = authManager.userCan('show', 'AssessmentTemplate')
  const columns = [
    {
      id: 'vendor.name',
      header: 'Name',
      cell: (info) => {
        const canLink = authManager.userCan('show', info.row.original)
        return canLink ? (
          <a href={info.row.original.vendor.path}>{info.getValue()}</a>
        ) : (
          info.getValue()
        )
      },
      width: '20%',
    },
    {
      id: 'vendor.name',
      header: '',
      hidden: true,
    },
    {
      id: 'date_range.started_at',
      header: 'Sent At',
      cell: dateFormatter,
      minWidth: 110,
    },
    {
      id: 'date_range.end_at',
      header: 'Due At',
      cell: dueDateFormatter.formatCell({ assessments, assessmentTemplates }),
      minWidth: 104,
    },
    {
      id: 'completed_count',
      header: 'Assessments Submitted',
      cell: (info) => `${info.getValue()} of ${assessmentTemplates.length}`,
    },
  ]

  if (templateColumnsVisible) {
    assessmentTemplates.forEach((template) => {
      columns.push({
        id: `df.${template.id}`,
        header: () => templateFormatter.formatHeader(template, canLinkTemplate),
        cell: templateFormatter.formatCell({
          authManager,
          template,
          assessments,
          unlaunchedString: NOT_LAUNCHED,
          onApproveExtensionRequest,
        }),
        enableSorting: false,
      })

      // Dummy column to support html searching
      columns.push({
        id: `df.${template.id}.search`,
        header: '',
        cell: (info) => {
          const assessment = find(assessments, {
            assessment_template_id: template.id,
            vendor_id: info.row.original.vendor.id,
          })
          return assessment ? assessment.status.titleize() : NOT_LAUNCHED
        },
        enableSorting: false,
        hidden: true,
      })
    })
  }

  if (reminderColumnVisible) {
    columns.push({
      id: 'df.reminder',
      header: reminderFormatter.formatHeader,
      cell: reminderFormatter.formatCell({ assessments }),
      enableSorting: false,
    })
  }

  if (quickActionColumnVisible && canManageProject) {
    columns.push({
      id: 'dfAction',
      header: '',
      cell: (info) => {
        const actions = quickActions
          .filter((action) => action.enabled([info.row.original]))
          .map((action) => (
            <Menu.Item
              key={action.label}
              onClick={() => action.onClick([info.row.original])}
            >
              <Icon icon={`fa ${action.iconClass}`} />
              {action.label}
            </Menu.Item>
          ))
        return actions.length === 0 ? null : (
          <Menu placement="right" offset={16} portal>
            <Menu.Button as={EllipsisIconButton} />
            <Menu.Items size="small" arrow>
              {actions}
            </Menu.Items>
          </Menu>
        )
      },
      enableSorting: false,
    })
  }

  return columns
}
