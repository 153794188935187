import { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useClientAssessmentTemplateFetcher } from 'apis'

export default function useSetAssessmentTemplate(id) {
  const queryClient = useQueryClient()

  return useCallback(
    (value) => {
      queryClient.setQueriesData(
        {
          predicate: (query) =>
            query.queryKey[0] ===
              useClientAssessmentTemplateFetcher.queryKey()[0] &&
            query.queryKey[1].id === id,
        },
        value,
      )
    },
    [queryClient, id],
  )
}
