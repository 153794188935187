import styled from 'styled-components'
import Metric from 'components-v2/molecules/Metric'
import { colors } from 'styles'

export const Content = styled.div`
  display: flex;
`

export const StyledMetric = styled(Metric)`
  flex: none;
  width: 75px;
  color: ${colors.RED};
`

export const ChartWrapper = styled.div`
  flex: 1;
  margin-top: -15px;
  margin-bottom: -10px;

  > * {
    font-size: 15px;
  }
`
