import React from 'react'
import PropTypes from 'prop-types'
import BaseField from './BaseField'
import Select from '../Select'

const SelectField = ({
  name,
  controlId,
  value,
  placeholder,
  options,
  disabled,
  controlProps,
  onChange,
  ...rest
}) => (
  <BaseField controlId={controlId} {...rest}>
    <Select
      {...controlProps}
      id={controlId}
      name={name}
      value={value}
      placeholder={placeholder}
      options={options}
      disabled={disabled}
      onChange={onChange}
    />
  </BaseField>
)

SelectField.propTypes = {
  name: PropTypes.string,
  controlId: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  controlProps: PropTypes.object,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}

SelectField.defaultProps = {
  placeholder: 'Select',
  controlProps: {},
}

export default SelectField
