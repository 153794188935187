import styled, { css } from 'styled-components'
import Card from 'components-v2/organisms/Card'
import { colors } from 'styles'

export const Heading = styled.h4`
  margin: 0;
`

export const Top = styled(Card.Segment)`
  padding-bottom: 0;
`

export const Bottom = Card.Segment

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const UpdatedAt = styled.span`
  font-size: 14px;
  color: ${colors.GREY};
`

export const ObjectiveList = styled.div`
  margin-top: 5px;
`

export const ObjectiveItem = styled.div`
  padding: 12px 0 15px;
  border-bottom: 1px solid ${colors.LIGHT_GREY};

  &:last-child {
    border-bottom: none;
  }
`

export const ObjectiveName = styled.a`
  font-size: 15px;
  margin-bottom: 5px;
  color: ${colors.BODY_TEXT};
  cursor: pointer;

  ${(props) =>
    props.disabled &&
    css`
      color: ${colors.GREY};
    `}
`
