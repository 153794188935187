import React from 'react'
import PropTypes from 'prop-types'
import PrintButton from 'components-v2/molecules/PrintButton'
import ToolbarGroup from 'components-v2/molecules/ToolbarGroup'
import MultiSelectFilter from 'components-v2/molecules/MultiSelectFilter'
import DateRangeFilter from 'components-v2/molecules/DateRangeFilter'
import MenuDropdown from 'components-v2/molecules/MenuDropdown'
import Card from 'components-v2/organisms/Card'
import PageHeader from 'components-v2/organisms/PageHeader'
import InfiniteScroll from 'components-v2/organisms/InfiniteScroll'
import ContentWithFilterSidebar from 'components-v2/organisms/ContentWithFilterSidebar'
import { useAuthorizations } from 'hooks'
import {
  getMultiSelectFilterSelectedCount,
  formatMultiSelectFilterValue,
} from 'lib/multiSelectFilter'
import { toLocalTimeString } from 'utils/date'
import {
  ExportFormatTypes,
  LabelTypes,
  RiskTierOptions,
  // AssessmentStatusOptions,
} from 'constants/index'
import ComposedChartWidget from './ComposedChartWidget'
import ChartTableWidget from './ChartTableWidget'
import { getMultiSelectFilterLabelWithSelectedCount } from './utils'
import { TableTitle, PrintViewInfo } from './styles'

const archiveOptions = [
  { value: 'projects', label: 'Projects' },
  { value: 'vendors', label: 'Vendors' },
]

const datePickerProps = {
  dateFormat: 'MM/yyyy',
  showMonthYearPicker: true,
  portalId: 'datepicker-portal',
}

const View = ({
  // status,
  riskTier,
  archivedValues,
  assessmentTemplate,
  assignee,
  vendorTag,
  dateRange,
  clientTagsFetcherResult,
  assessmentTemplatesFetcherResult,
  usersFetcherResult,
  chartData,
  // onStatusChange,
  onArchivedValuesChange,
  onAssessmentTemplateChange,
  onRiskTierChange,
  onAssigneeChange,
  onVendorTagChange,
  onDateRangeChange,
  onExport,
  getAssessmentsPageUrl,
}) => {
  const [canExport] = useAuthorizations('export')
  const multiSelectFilters = [
    {
      uuid: 'archived',
      label: 'Archived',
      value: archivedValues,
      options: archiveOptions,
      onChange: onArchivedValuesChange,
      selectAllVisible: true,
    },
    /* TODO: Uncomment this when status query param is supported */
    // {
    //   uuid: 'status',
    //   label: 'Status',
    //   value: status,
    //   options: AssessmentStatusOptions,
    //   onChange: onStatusChange,
    //   selectAllVisible: true,
    // },
    {
      uuid: 'vendorLabels',
      label: `${LabelTypes.VENDORS} Labels`,
      value: vendorTag,
      options: clientTagsFetcherResult.data || [],
      getOptionValue: (e) => e.id,
      getOptionLabel: (e) => e.name,
      onChange: onVendorTagChange,
      selectAllVisible: true,
      matchVisible: true,
    },
    {
      uuid: 'assignee',
      label: 'Assignee(s)',
      value: assignee,
      options: usersFetcherResult.data || [],
      getOptionValue: (e) => e.id,
      getOptionLabel: (e) => e.name,
      onChange: onAssigneeChange,
      otherVisible: true,
      otherLabel: 'Unassigned',
      collectionQueryResult: usersFetcherResult,
    },
    {
      uuid: 'vendorRiskTiers',
      label: `${LabelTypes.VENDORS} Risk Tiers`,
      value: riskTier,
      options: [...RiskTierOptions, { value: 'none', label: 'None' }],
      onChange: onRiskTierChange,
      selectAllVisible: true,
    },
    {
      uuid: 'assessmentTemplates',
      label: 'Assessment Templates',
      value: assessmentTemplate,
      options: assessmentTemplatesFetcherResult.data || [],
      getOptionValue: (e) => e.id,
      getOptionLabel: (e) => e.name,
      onChange: onAssessmentTemplateChange,
      collectionQueryResult: assessmentTemplatesFetcherResult,
    },
  ]
  return (
    <>
      <PageHeader title="Assessments by Month">
        <ToolbarGroup>
          <PrintButton color={canExport ? 'default' : 'primary'} />
          {canExport && (
            <MenuDropdown
              title="Export"
              icon="fa fa-table"
              buttonProps={{ color: 'primary' }}
              onSelect={onExport}
            >
              <MenuDropdown.Item eventKey={ExportFormatTypes.CSV}>
                <i className="fa fa-table" />
                Export (CSV)
              </MenuDropdown.Item>
              <MenuDropdown.Item eventKey={ExportFormatTypes.XLSX}>
                <i className="fa fa-table" />
                Export (XLS)
              </MenuDropdown.Item>
            </MenuDropdown>
          )}
        </ToolbarGroup>
      </PageHeader>
      <ContentWithFilterSidebar
        filters={
          <>
            {multiSelectFilters.map(
              ({ uuid, label, value, collectionQueryResult, ...rest }) => (
                <ContentWithFilterSidebar.AccordionItem
                  key={uuid}
                  uuid={uuid}
                  title={getMultiSelectFilterLabelWithSelectedCount(
                    label,
                    value,
                  )}
                >
                  {collectionQueryResult ? (
                    <InfiniteScroll
                      loadMore={collectionQueryResult.fetchNextPage}
                      hasMore={collectionQueryResult.hasNextPage}
                      isLoading={collectionQueryResult.isFetchingNextPage}
                      threshold={30}
                      useWindow={false}
                    >
                      <MultiSelectFilter {...rest} value={value} />
                    </InfiniteScroll>
                  ) : (
                    <MultiSelectFilter {...rest} value={value} />
                  )}
                </ContentWithFilterSidebar.AccordionItem>
              ),
            )}
            <ContentWithFilterSidebar.AccordionItem
              key="dateRange"
              uuid="dateRange"
              title="Date Range"
            >
              <DateRangeFilter
                value={dateRange}
                onChange={onDateRangeChange}
                minDate={new Date('2018-01-01')}
                maxDate={new Date()}
                startLabel="Start Month"
                endLabel="End Month"
                datePickerProps={datePickerProps}
              />
            </ContentWithFilterSidebar.AccordionItem>
          </>
        }
      >
        <Card padding>
          <PrintViewInfo>
            <b>Date Created:</b> {toLocalTimeString(new Date(), 'MM/dd/yyyy')}
            <br />
            {/* <b>Total Vendors:</b> 11
            <br />
            <b>Average Risk Rating:</b> 52
            <br /> */}
            {multiSelectFilters.map(({ uuid, value, label, ...rest }) => {
              if (!getMultiSelectFilterSelectedCount(value)) {
                return null
              }
              return (
                <React.Fragment key={uuid}>
                  <b>{label}:</b>&nbsp;
                  {formatMultiSelectFilterValue({ value, ...rest })}
                  <br />
                </React.Fragment>
              )
            })}
            {(dateRange?.startDate || dateRange?.endDate) && (
              <>
                <b>Date Range:</b>&nbsp;
                {dateRange?.startDate &&
                  toLocalTimeString(dateRange.startDate, 'MM/yyyy')}{' '}
                ~
                {dateRange?.endDate &&
                  toLocalTimeString(dateRange.endDate, 'MM/yyyy')}
              </>
            )}
          </PrintViewInfo>
          <ComposedChartWidget chartData={chartData?.chart} />
          <TableTitle>Vendor&apos;s Data</TableTitle>
          <ChartTableWidget
            data={chartData?.chart}
            totals={chartData?.totals}
            getAssessmentsPageUrl={getAssessmentsPageUrl}
          />
        </Card>
      </ContentWithFilterSidebar>
    </>
  )
}

View.propTypes = {
  // status: PropTypes.object,
  riskTier: PropTypes.object,
  archivedValues: PropTypes.object,
  assessmentTemplate: PropTypes.object,
  assignee: PropTypes.object,
  vendorTag: PropTypes.object,
  dateRange: PropTypes.object,
  clientTagsFetcherResult: PropTypes.object.isRequired,
  assessmentTemplatesFetcherResult: PropTypes.object.isRequired,
  usersFetcherResult: PropTypes.object.isRequired,
  chartData: PropTypes.object,
  // onStatusChange: PropTypes.func.isRequired,
  onArchivedValuesChange: PropTypes.func.isRequired,
  onAssessmentTemplateChange: PropTypes.func.isRequired,
  onRiskTierChange: PropTypes.func.isRequired,
  onAssigneeChange: PropTypes.func.isRequired,
  onVendorTagChange: PropTypes.func.isRequired,
  onDateRangeChange: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  getAssessmentsPageUrl: PropTypes.func.isRequired,
}

export default View
