import React, { useMemo, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import MultiSelectFilterDropdown from 'components-v2/molecules/MultiSelectFilterDropdown'
import difference from 'lodash/difference'

const ColumnToggler = ({
  columns,
  selected,
  onToggle,
  maskedColumns,
  ...rest
}) => {
  const [filteredColumns, setFilteredColumns] = React.useState([])

  useEffect(() => {
    let cols = columns
    let sels = selected
    if (maskedColumns) {
      // masked columns relate to disabled integrations at the moment
      cols = columns.filter((c) => maskedColumns.indexOf(c.id) === -1)
      if (selected) {
        sels = selected.filter((s) => maskedColumns.indexOf(s) === -1)
      }
    }
    setFilteredColumns(cols)
    // Remove columns in their settings if they are no longer allowed to see
    if (difference(selected, sels).length > 0) handleChange({ selected: sels })
  }, [columns, selected, maskedColumns])

  const togglableColumns = useMemo(
    () => filteredColumns.filter((e) => e.togglable !== false),
    [filteredColumns],
  )
  const options = useMemo(
    () =>
      togglableColumns.map(({ id: value, header: label }) => ({
        value,
        label,
      })),
    [togglableColumns],
  )
  const defaultValue = useMemo(
    () => togglableColumns.filter((e) => !e.hidden).map((e) => e.id),
    [togglableColumns],
  )
  const handleChange = useCallback(
    (value) => onToggle(value.selected),
    [onToggle],
  )

  return (
    <MultiSelectFilterDropdown
      selectAllVisible
      title="Show Columns"
      {...rest}
      value={{
        selected: selected || defaultValue,
      }}
      options={options}
      onChange={handleChange}
    />
  )
}

ColumnToggler.propTypes = {
  columns: PropTypes.array.isRequired,
  selected: PropTypes.array,
  onToggle: PropTypes.func.isRequired,
  maskedColumns: PropTypes.array,
}

export default ColumnToggler
