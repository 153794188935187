import PropTypes from 'prop-types'
import React from 'react'
import A from 'components-v2/atoms/A'
import DList from 'components-v2/molecules/DList'
import InfoCard from '../InfoCard'
import TagList from '../TagList'
import { SocialLink } from './styles'

const WebCard = ({ globalOrg }) => (
  <InfoCard title="Web presence">
    <DList>
      <dt>Primary domain</dt>
      <dd>
        {globalOrg.domain && (
          <A href={`https://${globalOrg.domain}`} target="_blank" underline>
            https://{globalOrg.domain}
          </A>
        )}
      </dd>
      <dt>Other domain(s)</dt>
      <dd>
        <TagList list={globalOrg.domain_aliases} plural="domains" />
      </dd>
      <dt>Alexa rank (U.S.)</dt>
      <dd>{globalOrg.alexa_us_rank}</dd>
      <dt>Social media</dt>
      <dd>
        {globalOrg.twitter_handle && (
          <div>
            <SocialLink
              href={`https://twitter.com/${globalOrg.twitter_handle}`}
              target="_blank"
            >
              <i className="fa fa-twitter-square" aria-hidden="true"></i>
              twitter.com/{globalOrg.twitter_handle}
            </SocialLink>
          </div>
        )}
        {globalOrg.facebook_handle && (
          <div>
            <SocialLink
              href={`https://www.facebook.com/${globalOrg.facebook_handle}`}
              target="_blank"
            >
              <i className="fa fa-facebook-square" aria-hidden="true"></i>
              facebook.com/{globalOrg.facebook_handle}
            </SocialLink>
          </div>
        )}
        {globalOrg.linked_in_handle && (
          <div>
            <SocialLink
              href={`https://www.linkedin.com/${globalOrg.linked_in_handle}`}
              target="_blank"
            >
              <i className="fa fa-linkedin-square" aria-hidden="true"></i>
              linkedin.com/{globalOrg.linked_in_handle}
            </SocialLink>
          </div>
        )}
      </dd>
    </DList>
  </InfoCard>
)

WebCard.propTypes = {
  globalOrg: PropTypes.object.isRequired,
}

export default WebCard
