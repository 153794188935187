import PropTypes from 'prop-types'
import styled from 'styled-components'

// Let's add dark theme and set as default
export default (WrappedComponent) => {
  const WrapperComponent = styled(WrappedComponent).attrs((p) => ({
    bgColor: p.bgColor || (p.dark ? '#000' : undefined),
    borderColor: p.borderColor || (p.dark ? '#000' : undefined),
  }))`
    ${(p) =>
      p.dark &&
      `
      color: #FFF;
      opacity: .9;
    `}
  `

  WrapperComponent.propTypes = {
    dark: PropTypes.bool,
  }

  WrapperComponent.defaultProps = {
    dark: true,
  }

  return WrapperComponent
}
