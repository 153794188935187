import React from 'react'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'
import map from 'lodash/map'
import omit from 'lodash/omit'
import NotificationManager from 'lib/notifications'
import defaultConfig from 'components/elements/risk_tier_calculator/questions.json'
import RiskTierCalculatorModal from 'components/elements/risk_tier_calculator'
import { useModal, useConfirm, useGlobalLoader } from 'hooks'
import { LabelTypes } from 'constants/index'
import {
  useClientRiskTierCalculatorsFetcher,
  useCreateClientRiskTierCalculator,
  useUpdateClientRiskTierCalculator,
  useSetActiveClientRiskTierCalculator,
  useDeleteClientRiskTierCalculator,
} from 'apis'
import EditCalculatorModal from './EditCalculatorModal'

import View from './View'

const RiskTierCalculatorTable = ({ organization }) => {
  const { mutateAsync: createClientRiskTierCalculator } =
    useCreateClientRiskTierCalculator()
  const { mutateAsync: updateClientRiskTierCalculator } =
    useUpdateClientRiskTierCalculator()
  const { mutateAsync: setActiveClientRiskTierCalculator } =
    useSetActiveClientRiskTierCalculator()
  const { mutateAsync: deleteClientRiskTierCalculator } =
    useDeleteClientRiskTierCalculator()
  const openConfirm = useConfirm()
  const [showLoader, hideLoader] = useGlobalLoader()
  const [openEditCalculatorModal, closeEditCalculatorModal] =
    useModal(EditCalculatorModal)
  const [openRiskTierCalculatorModal, closeRiskTierCalculatorModal] = useModal(
    RiskTierCalculatorModal,
  )

  const { data: riskTierCalculators, isLoading } =
    useClientRiskTierCalculatorsFetcher()

  const handleNewItem = (formData) => {
    const { newItem } = formData
    // yum's "required" is a little too aggressive here;
    // just quietly don't do anything unless there's something to do
    if (newItem.length === 0) return

    const newConfig = {
      name: newItem,
      client_id: organization.id,
      questions: [],
      tier_divisions: [],
    }
    createClientRiskTierCalculator({
      data: { risk_tier_calculator: newConfig },
    })
      .then(() => NotificationManager.success('Custom risk calculator added'))
      .catch(() => NotificationManager.error())
  }

  const handleSetActive = (id) => {
    setActiveClientRiskTierCalculator({ id })
      .then(() => NotificationManager.success('Set active risk calculator'))
      .catch(() => NotificationManager.error())
  }

  const handleEditClick = (id) => {
    openEditCalculatorModal({
      calculatorConfig: riskTierCalculators.find((e) => e.id === id),
      onSubmit: (data) => {
        const loaderId = showLoader()
        return updateClientRiskTierCalculator({
          id,
          data: {
            risk_tier_calculator: omit(data, 'class'),
          },
        })
          .then(() => {
            closeEditCalculatorModal()
            NotificationManager.success('Changes saved')
          })
          .catch(() => {
            NotificationManager.error()
          })
          .then(() => hideLoader(loaderId))
      },
    })
  }

  const handleViewDefaultClick = () => {
    openRiskTierCalculatorModal({
      calculatorId: 'default',
      previewMode: true,
      onClose: closeRiskTierCalculatorModal,
      onSave: closeRiskTierCalculatorModal,
    })
  }

  const handleCopyClick = (id) => {
    let oldConfig
    if (id === 'default') {
      oldConfig = defaultConfig
    } else {
      oldConfig = riskTierCalculators.find((e) => e.id === id)
    }
    const nameCheck = oldConfig.name.toLowerCase()

    const clones = riskTierCalculators.filter((res) =>
      res.name.toLowerCase().startsWith(`${nameCheck} (copy `),
    )

    const newConfig = cloneDeep(oldConfig)
    newConfig.id = null
    newConfig.name = `${oldConfig.name} (Copy ${clones.length + 1})`
    newConfig.enabled = false
    newConfig.questions = map(newConfig.questions, (question) => {
      const q = question
      q.id = Math.random().toString(36).substring(2, 15)
      return q
    })
    if (id === 'default') {
      newConfig.client_id = organization.id
    }
    createClientRiskTierCalculator({
      data: { risk_tier_calculator: newConfig },
    })
      .then(() => NotificationManager.success('Default risk calculator copied'))
      .catch(() => NotificationManager.error())
  }

  const handleNameChange = (id, newName) => {
    updateClientRiskTierCalculator({
      id,
      data: {
        risk_tier_calculator: omit(
          {
            ...riskTierCalculators.find((e) => e.id === id),
            name: newName,
          },
          'class',
        ),
      },
    })
      .then(() => NotificationManager.success('Changes saved'))
      .catch(() => NotificationManager.error())
  }

  const handleDelete = (id) => {
    openConfirm({
      title: 'Delete Calculator?',
      body: `Are you sure you want to delete this risk tier calculator configuration? If you've already used this calculator for any of your
             ${LabelTypes.VENDORS.toLowerCase()}, you'll no longer be able to view the selected answers.`,
      onConfirm: () => {
        deleteClientRiskTierCalculator({ id })
          .then(() => NotificationManager.success('Calculator removed'))
          .catch(() => NotificationManager.error())
      },
    })
  }

  return (
    <View
      items={riskTierCalculators}
      defaultConfig={defaultConfig}
      loaded={!isLoading}
      onAdd={handleNewItem}
      onSetActive={handleSetActive}
      onEditClick={handleEditClick}
      onDeleteClick={handleDelete}
      onViewDefaultClick={handleViewDefaultClick}
      onCopyClick={handleCopyClick}
      onNameChange={handleNameChange}
    />
  )
}

RiskTierCalculatorTable.propTypes = {
  organization: PropTypes.object.isRequired,
}

export default RiskTierCalculatorTable
