import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import Icon from 'components-v2/atoms/Icon'
import Uploader from 'components-v2/molecules/Uploader'
import AssessmentElementAnswerForm from 'components/shared/AssessmentElementAnswerForm'
import { AssessmentElementTypes, AssessmentStatusTypes } from 'constants/index'
import { useAuthorizations } from 'hooks'
import { StyledResponseScoreSelect } from './styles'

const ResponseSource = ({
  response,
  isVendorContext,
  extras,
  onScoreChange,
}) => {
  const [canAccessAssessment] = useAuthorizations(['show', 'Assessment'])
  const sectionId = get(response, 'element.parent.id')
  const sectionUrl = `${get(
    response,
    'assessment.path',
  )}?section_id=${sectionId}#${sectionId}`
  const questionId = get(response, 'element.id')
  const questionUrl = `${get(
    response,
    'assessment.path',
  )}?section_id=${sectionId}#${questionId}`
  const elementSkipped = !!(
    extras &&
    extras.skipped_element_ids &&
    questionId &&
    extras.skipped_element_ids.includes(questionId)
  )
  // TODO: Get ratingLabelSet from response object
  const ratingLabelSet = get(
    response,
    'assessment.assessment_template.rating_label_set',
  )

  return (
    <>
      <dt>Project</dt>
      <dd>
        <a href={get(response, 'assessment.project.path')}>
          {get(response, 'assessment.project.friendly_name')}
        </a>
      </dd>
      <dt>Assessment</dt>
      <dd>
        <a href={canAccessAssessment && get(response, 'assessment.path')}>
          {get(response, 'assessment.name')}
        </a>
      </dd>
      {get(response, 'element.parent.type') ===
        AssessmentElementTypes.SECTION_HEADING && (
        <>
          <dt>Section</dt>
          <dd>
            <a href={canAccessAssessment && sectionUrl}>
              {get(response, 'element.parent.label')}
            </a>
          </dd>
        </>
      )}
      <dt>Question</dt>
      <dd>
        <a href={canAccessAssessment && questionUrl}>
          {get(response, 'element.label')}
        </a>
      </dd>
      <dt>Answer</dt>
      <dd>
        {elementSkipped ? (
          <span>
            <Icon icon="fa fa-eye-slash" className="primary-color" /> Disabled
          </span>
        ) : (
          <AssessmentElementAnswerForm
            element={response.element}
            value={response.answer}
            disabled
            placeholder="(No answer provided)"
          />
        )}
      </dd>
      {!isVendorContext && !elementSkipped && response.responded && (
        <>
          <dt>Score</dt>
          <dd>
            <StyledResponseScoreSelect
              value={response.review_score}
              weight={response.element.weight}
              ratingLabelSet={ratingLabelSet}
              disabled={
                get(extras, 'assessment_status') ===
                AssessmentStatusTypes.REVIEWED
              }
              resetable
              onChange={onScoreChange}
            />
          </dd>
        </>
      )}
      <dt>Comment</dt>
      <dd>{response.comment}</dd>
      <dt>Attachments</dt>
      <dd>
        <Uploader
          defaultFiles={get(response, 'attachments', [])}
          displayLabel={false}
          url=""
          model=""
          disabled
        />
      </dd>
    </>
  )
}

ResponseSource.propTypes = {
  response: PropTypes.object.isRequired,
  isVendorContext: PropTypes.bool,
  extras: PropTypes.object,
  onScoreChange: PropTypes.func,
}

export default ResponseSource
