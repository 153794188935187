import styled from 'styled-components'
import Metric from 'components-v2/molecules/Metric'
import { colors } from 'styles'

export const strokeColor = colors.GREEN

export const Content = styled.div`
  display: flex;
`

export const StyledMetric = styled(Metric).attrs({
  trendColor: colors.DARK_GREY,
})`
  flex: none;
  width: 110px;
  color: ${colors.GREEN};
`
