import React from 'react'
import PropTypes from 'prop-types'
import { useQueryClient } from '@tanstack/react-query'
import NotificationManager from 'lib/notifications'
import { useGlobalLoader, useUpdateEffect } from 'hooks'
import { compose, withAuthorize, withReactQuery } from 'hocs'
import { useVendorIssueFetcher, useTouchVendorIssue } from 'apis'
import { IssueStatusTypes } from 'constants/index'
import View from './View'

const cannotCommentStatusTypes = [
  IssueStatusTypes.CLOSED,
  IssueStatusTypes.ARCHIVED,
]

const Container = ({ issue: issueProp }) => {
  const queryClient = useQueryClient()
  const { mutateAsync: touchVendorIssue } = useTouchVendorIssue()
  const [showLoader, hideLoader] = useGlobalLoader()
  const { data: issue } = useVendorIssueFetcher(
    {
      id: issueProp.id,
    },
    { initialData: issueProp, refetchOnMount: false },
  )

  React.useEffect(() => {
    touchVendorIssue({ id: issueProp.id })
  }, [issueProp.id])

  useUpdateEffect(() => {
    NotificationManager.success(
      `An issue has been successfully ${issue.status}.`,
    )
  }, [issue.status])

  const handleCommentCreated = React.useCallback(async () => {
    if (issue.status !== IssueStatusTypes.OPENED) {
      return
    }

    const loaderId = showLoader()
    await queryClient.invalidateQueries(useVendorIssueFetcher.queryKey(), {
      predicate: (query) =>
        query.queryKey[0] === useVendorIssueFetcher.queryKey()[0] &&
        query.queryKey[1].id === issue.id,
    })
    hideLoader(loaderId)
  }, [issue.id, showLoader, hideLoader, issue.status])

  return (
    <View
      issue={issue}
      canComment={!cannotCommentStatusTypes.includes(issue.status)}
      onCommentCreated={handleCommentCreated}
    />
  )
}

Container.propTypes = {
  issue: PropTypes.object.isRequired,
}

export default compose(withAuthorize(), withReactQuery())(Container)
