import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Textarea from 'components-v2/atoms/Textarea'
import { Wrapper, Title, Row, StyledButton } from './styles'

export default function WriteInAnswer({
  answer,
  onBlurInput,
  onToggleSelectedAnswer,
}) {
  const [writeInAnswer, setWriteInAnswer] = useState(
    answer?.write_in_answer ?? '',
  )

  useEffect(() => {
    setWriteInAnswer(answer?.write_in_answer ?? '')
  }, [answer?.id])

  return (
    <Wrapper>
      <Title>Write-In Answer</Title>
      <Row>
        <Textarea
          rows="3"
          value={writeInAnswer}
          onChange={(e) => setWriteInAnswer(e.target.value)}
          maxLength="4000"
          onBlur={(e) => onBlurInput(e.target.value)}
        />
        <StyledButton
          size="sm"
          color={answer?.is_selected_answer ? 'success' : 'default'}
          onClick={() => onToggleSelectedAnswer(writeInAnswer)}
        >
          {answer?.is_selected_answer ? 'Selected' : 'Select Answer'}
        </StyledButton>
      </Row>
    </Wrapper>
  )
}

WriteInAnswer.propTypes = {
  answer: PropTypes.object,
  onBlurInput: PropTypes.func.isRequired,
  onToggleSelectedAnswer: PropTypes.func.isRequired,
}
