import styled from 'styled-components'
import { Row, DragSourceColumn, QuestionColumn } from '../styles'

export const Wrapper = styled(Row)`
  border-bottom: 2px solid #ddd;
`

export const StyledDragSourceColumn = styled(DragSourceColumn)`
  font-weight: bold;
  padding-left: 42px;
`

export const LabelColumn = styled(QuestionColumn)`
  font-weight: bold;
`
