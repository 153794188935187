export default {
  // Google charts default colors
  DEFAULT: [
    '#2176DB',
    '#DD2400',
    '#E4B93F',
    '#01BC31',
    '#990099',
    '#3B3EAC',
    '#0099C6',
    '#DD4477',
    '#66AA00',
    '#B82E2E',
    '#316395',
    '#994499',
    '#22AA99',
    '#AAAA11',
    '#6633CC',
    '#E67300',
    '#8B0707',
    '#329262',
    '#5574A6',
    '#3B3EAC',
  ],
}
