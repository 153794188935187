import React from 'react'
import PropTypes from 'prop-types'
import Table from 'components-v2/molecules/Table'
import Toolbar, { SearchInput } from 'components-v2/molecules/Table/Toolbar'
import MultiSelectFilterDropdown from 'components-v2/molecules/MultiSelectFilterDropdown'
import FilterDropdownTray from 'components-v2/molecules/FilterDropdownTray'
import MenuDropdown from 'components-v2/molecules/MenuDropdown'
import { useAuthorizations } from 'hooks'
import { domainColumns } from './columns'
import { ActionMenuItemTypes, archiveOptions } from './constants'

const View = ({
  assessments,
  totalSize,
  page,
  riskDomains,
  sizePerPage,
  sortField,
  sortOrder,
  searchText,
  archiveValues,
  filter,
  filterConfigList,
  loading,
  onPaginationChange,
  onSortingChange,
  onSearchChange,
  onMenuItemSelect,
  onArchiveChange,
  onFilterChange,
}) => {
  const [canExport, canViewArchived, canLinkTemplate] = useAuthorizations(
    ['export', 'Assessment'],
    ['view_archived', 'Assessment'],
    ['show', 'AssessmentTemplate'],
  )
  const tableColumns = React.useMemo(
    () => domainColumns(riskDomains, canLinkTemplate),
    [riskDomains, canLinkTemplate],
  )
  const archiveTitle = React.useMemo(() => {
    const count = archiveValues?.length
    let title = 'Show Archived'
    if (count) title += ` (${count})`
    return title
  }, [archiveValues])
  return (
    <div>
      <Toolbar>
        <Toolbar.Group>
          <SearchInput value={searchText} onChange={onSearchChange} />
          {canViewArchived && (
            <MultiSelectFilterDropdown
              title={archiveTitle}
              value={{ selected: archiveValues }}
              options={archiveOptions}
              onChange={onArchiveChange}
              selectAllVisible
            />
          )}
        </Toolbar.Group>
        <Toolbar.Group>
          <FilterDropdownTray
            value={filter}
            configList={filterConfigList}
            onChange={onFilterChange}
          />
          <MenuDropdown title="Actions" onSelect={onMenuItemSelect}>
            {canExport && (
              <MenuDropdown.Item
                eventKey={ActionMenuItemTypes.EXPORT_CURRENT_VIEW_CSV}
              >
                <i className="fa fa-table" />
                Export Current View (CSV)
              </MenuDropdown.Item>
            )}
            {canExport && (
              <MenuDropdown.Item eventKey={ActionMenuItemTypes.EXPORT_ALL_CSV}>
                <i className="fa fa-table" />
                Export All (CSV)
              </MenuDropdown.Item>
            )}
          </MenuDropdown>
        </Toolbar.Group>
      </Toolbar>
      <Table
        loading={loading}
        data={assessments || []}
        columns={tableColumns}
        totalSize={totalSize}
        pagination={{ page, sizePerPage }}
        sorting={{ sortField, sortOrder }}
        onPaginationChange={onPaginationChange}
        onSortingChange={onSortingChange}
        manualPagination
        manualSorting
        noDataText="Risk Domain Scores will appear here once a vendor has submitted an assessment with Risk Domains."
        type={Table.types.ALT}
      />
    </div>
  )
}

View.propTypes = {
  assessments: PropTypes.array,
  totalSize: PropTypes.number,
  loading: PropTypes.bool,
  riskDomains: PropTypes.array.isRequired,
  page: PropTypes.number,
  sizePerPage: PropTypes.number,
  sortField: PropTypes.string,
  sortOrder: PropTypes.string,
  searchText: PropTypes.string,
  archiveValues: PropTypes.array,
  filter: PropTypes.object,
  filterConfigList: PropTypes.array.isRequired,
  onPaginationChange: PropTypes.func.isRequired,
  onSortingChange: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onMenuItemSelect: PropTypes.func.isRequired,
  onArchiveChange: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
}

export default View
