/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.'5ea404ba2231bb7ffbc6'
// console.log('Hello World from Webpacker')
// Support component names relative to this directory:
// var componentRequireContext = require.context("components", true)
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Pusher from 'pusher-js'
import cssVars from 'css-vars-ponyfill'
import ClientTimeZone from 'utils/client-timezone'
import ahoy from 'ahoy.js'
import utils from '../utils/privva_utils.js.erb'
import axiosInterceptors from '../lib/axios-interceptors'
import '../utils/string'
import '../utils/number'
import 'font-awesome/css/font-awesome.css'
import '../stylesheets/application.sass'
import '../images/import_images'
import '../lib/local-time'
import '../lib/rails-ujs'
import '../lib/jquery-interceptors'
import '../lib/bootstrap.alert'

window.Privva ||= {}
window.Privva.Utils = utils

axiosInterceptors()

ahoy.configure({
  visitsUrl: '/bhoy/visits',
  eventsUrl: '/bhoy/events',
})
// set pusher global window object
if (window.pusher == null) {
  window.pusher = new Pusher('5ea404ba2231bb7ffbc6', {
    cluster: 'mt1',
    encrypted: true,
    disableStats: true,
  })
}

document.addEventListener('DOMContentLoaded', ClientTimeZone.apply())
window.addEventListener('load', () => {
  if (window.performance && window.performance.timing) {
    const perf = window.performance.timing
    const path =
      window.location && window.location.pathname
        ? window.location.pathname
        : '<unknown>'
    ahoy.track('Browser Performance', { performance: perf, _path: path })
  }
})

const componentRequireContext = require.context('components', true)
window.componentRequireContext = componentRequireContext
const ReactRailsUJS = require('react_ujs')
// eslint-disable-next-line react-hooks/rules-of-hooks
ReactRailsUJS.useContext(componentRequireContext)

// init css-vars-ponyfill
cssVars()
