import React from 'react'
import PropTypes from 'prop-types'

// Let's add time delay when hide tooltip
export default (WrappedComponent) => {
  // eslint-disable-next-line react/prop-types
  const WrapperComponent = ({ active: activeProp, tooltipDelay, ...rest }) => {
    const [active, setActive] = React.useState(activeProp)
    const timeout = React.useRef(null)

    React.useEffect(() => {
      if (!tooltipDelay) {
        return
      }

      if (timeout.current) {
        clearTimeout(timeout.current)
      }

      /*
      if (!activeProp) {
        timeout.current = setTimeout(() => setActive(false), tooltipDelay)
      } else {
        // setActive(true)
        timeout.current = setTimeout(() => setActive(true), tooltipDelay)
      }
      */
      timeout.current = setTimeout(() => setActive(!!activeProp), tooltipDelay)
    }, [activeProp])

    return (
      <WrappedComponent {...rest} active={tooltipDelay ? active : activeProp} />
    )
  }

  WrapperComponent.propTypes = {
    tooltipDelay: PropTypes.number,
  }

  WrapperComponent.defaultProps = {
    tooltipDelay: 0,
  }

  return WrapperComponent
}
