import styled from 'styled-components'
import Chart from 'react-google-charts'
import { Row } from 'components-v2/organisms/Layout'
import { colors, mixins } from 'styles'

export const AnalysisHeader = styled.div`
  text-align: center;
  background: #2b2b2b;
  color: white;
  padding: 200px 0;
  margin-bottom: 50px;
  page-break-after: always;
  ${mixins.hidePrint}
`

export const AnalysisSubHeader = styled.div`
  text-align: center;
  margin: 40px 0;
  padding: 20px 0;
`

export const TableWrapper = styled.div`
  tbody > tr:nth-of-type(odd) {
    background-color: #f9f9f9;
  }

  thead {
    background: ${colors.PRIMARY};
    color: white;
  }
`

export const SummaryReportHeaderRow = styled(Row)`
  margin-bottom: 40px;
  page-break-after: always;
`

export const VendorColumnSectionHeader = styled.h1`
  ${mixins.hidePrint}
`

export const VendorColumnSectionHeaderPrint = styled.h1`
  ${mixins.showPrint}
`

export const StyledChart = styled(Chart)`
  min-height: 300px;
  text-align: center;
  page-break-after: always;

  @media screen {
    margin-top: 50px;
  }
`

export const PrintFooter = styled.div`
  ${mixins.showPrint}
  position: fixed;
  bottom: 0;
  right: 0;
`

export const LoadingContent = styled.h3`
  text-align: center;
`
