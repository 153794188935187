import PropTypes from 'prop-types'
import React from 'react'
import round from 'lodash/round'
import sumBy from 'lodash/sumBy'
import toPairs from 'lodash/toPairs'
import Table from 'components-v2/molecules/Table'
import { TableWrapper } from './styles'

const getColumns = (total) => [
  {
    id: 'status',
    header: 'Status',
    cell: (props) => props.getValue().titleize(),
  },
  {
    id: 'count',
    header: 'Count',
  },
  {
    id: 'df',
    header: 'Percent',
    cell: (props) =>
      `${round((props.row.original.count / total) * 100, 1) || 0}%`,
  },
]

const StatusTableWidget = ({ data }) => {
  const total = React.useMemo(() => sumBy(toPairs(data), (o) => o[1]), [data])
  const rows = React.useMemo(
    () =>
      Object.entries(data).map(([k, v]) => ({
        status: k,
        count: v,
      })),
    [data],
  )
  const columns = getColumns(total)
  return (
    <TableWrapper>
      <Table
        data={rows ?? []}
        columns={columns}
        enableSorting={false}
        enablePagination={false}
        tfootContent={
          <tr>
            <td>
              <b>Total</b>
            </td>
            <td>{total}</td>
            <td>100%</td>
          </tr>
        }
      />
    </TableWrapper>
  )
}

StatusTableWidget.propTypes = {
  data: PropTypes.object,
}

export default StatusTableWidget
