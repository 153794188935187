import React, { useState, useCallback, useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'
import Checkbox from 'components-v2/atoms/Checkbox'
import Loader from 'components-v2/molecules/Loader'
import MultiSelectFilterDropdown from 'components-v2/molecules/MultiSelectFilterDropdown'
import ToolbarGroup from 'components-v2/molecules/ToolbarGroup'
import PrintButton from 'components-v2/molecules/PrintButton'
import MenuDropdown from 'components-v2/molecules/MenuDropdown'
import PageHeader from 'components-v2/organisms/PageHeader'
import { Row, Col } from 'components-v2/organisms/Layout'
import { getEndpointUrl } from 'utils/url'
import { localTimeZoneOffsetInHours, toLocalTimeString } from 'utils/date'
import { ExportFormatTypes, LabelTypes } from 'constants/index'
import { compose, withAuthorize, withReactQuery } from 'hocs'
import { useAuthorizations } from 'hooks'
import { useClientTagsFetcher, useClientAssessmentSummaryFetcher } from 'apis'
import StatusChartWidget from './StatusChartWidget'
import StatusTableWidget from './StatusTableWidget'

const Container = () => {
  const [canExport] = useAuthorizations('export')
  const [showArchived, setShowArchived] = useState(false)
  const [vendorTagsFilter, setVendorTagsFilter] = useState({
    anyMatch: true,
    selected: [],
  })
  const { data: allVendorTags = [] } = useClientTagsFetcher()
  const vendorTagOptions = useMemo(
    () =>
      allVendorTags.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [allVendorTags],
  )
  const params = useMemo(
    () => ({
      metric: 'status',
      exclude_archived: !showArchived,
      with_vendor_tags: vendorTagsFilter.selected.join(','),
      any: vendorTagsFilter.anyMatch,
    }),
    [showArchived, vendorTagsFilter],
  )
  const { data: summaryData, isFetched } = useClientAssessmentSummaryFetcher({
    params,
  })
  const data = useMemo(
    () => (isEmpty(summaryData) ? { none: 0 } : summaryData),
    [summaryData],
  )

  const handleArchived = useCallback((event) => {
    setShowArchived(event.target.checked)
  }, [])

  const handleMenuAction = useCallback(
    (key) => {
      const paramsEx = {
        ...params,
        tz: localTimeZoneOffsetInHours,
      }
      window.location.assign(
        getEndpointUrl('clientAssessmentSummary', undefined, paramsEx, key),
      )
    },
    [params],
  )

  return (
    <div>
      <PageHeader title="Assessment Status" sub="Report">
        <ToolbarGroup>
          <PrintButton />
          {canExport && (
            <MenuDropdown
              title="Export"
              icon="fa fa-table"
              buttonProps={{ color: 'primary' }}
              onSelect={handleMenuAction}
            >
              <MenuDropdown.Item eventKey={ExportFormatTypes.CSV}>
                <i className="fa fa-table" />
                Export (CSV)
              </MenuDropdown.Item>
              <MenuDropdown.Item eventKey={ExportFormatTypes.XLSX}>
                <i className="fa fa-table" />
                Export (XLS)
              </MenuDropdown.Item>
            </MenuDropdown>
          )}
        </ToolbarGroup>
      </PageHeader>
      <Row>
        <Col sm={12} className="hidden-print">
          <ToolbarGroup>
            {vendorTagOptions.length > 0 && (
              <MultiSelectFilterDropdown
                title={
                  vendorTagsFilter.selected?.length > 0
                    ? `Filter ${LabelTypes.VENDOR} Labels: (${vendorTagsFilter.selected.length})`
                    : `Filter ${LabelTypes.VENDOR} Labels`
                }
                value={vendorTagsFilter}
                options={vendorTagOptions}
                onChange={setVendorTagsFilter}
                selectAllVisible
                matchVisible
              />
            )}
            <Checkbox
              checked={showArchived}
              inline
              onChange={handleArchived}
              label="Include Archived"
            />
          </ToolbarGroup>
        </Col>
      </Row>
      <Row>
        <Col sm={12} className="text-center">
          <h2>Assessment Status Summary</h2>
          <p>Date: {toLocalTimeString(new Date(), 'MM/dd/yyyy')}</p>
        </Col>
      </Row>
      <Row>
        <Col sm={8} smOffset={2}>
          {!isFetched && <Loader />}
          {isFetched && (
            <>
              <StatusChartWidget chartData={data} />
              <StatusTableWidget data={data} />
            </>
          )}
        </Col>
      </Row>
    </div>
  )
}

export default compose(withAuthorize(), withReactQuery())(Container)
