import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import Toggle from 'components-v2/atoms/Toggle'

export default function UserAttributeToggle({
  value: valueProp,
  model,
  attribute,
  orgSetting,
  url,
  disabled,
}) {
  const [value, setValue] = React.useState(valueProp)

  const inputChanged = (newValue) => {
    setValue(newValue)

    const data = {
      [model]: {
        [attribute]: newValue,
      },
    }
    if (orgSetting) data[model].org_setting = true

    axios
      .patch(url, data)
      // eslint-disable-next-line no-console
      .catch((err) => console.log('UserAttributeToggle', err))
  }

  return <Toggle checked={value} disabled={disabled} onChange={inputChanged} />
}

UserAttributeToggle.propTypes = {
  url: PropTypes.string,
  model: PropTypes.string,
  attribute: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  orgSetting: PropTypes.bool,
}

UserAttributeToggle.defaultProps = {
  disabled: false,
  model: 'user',
  url: '/user.json',
  orgSetting: false,
}
