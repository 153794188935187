import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import filter from 'lodash/filter'
import get from 'lodash/get'
import without from 'lodash/without'
import map from 'lodash/map'
import { withReactQuery } from 'hocs'
import { useModal } from 'hooks'
import { SecurityObjectiveStatusTypes } from 'constants/index'
import SecuritySenseDashboard from '../SecuritySenseDashboard'
import ConnectVendorModal from './ConnectVendorModal'
import { SecurityObjectiveCategories } from '../constants'

/*
const categories = [
  {
    name: 'Application Security',
    objectives: [
      'Code Analysis',
      'Software Development Life Cycle',
      'Change Management',
    ],
  },
  {
    name: 'Network Security',
    objectives: [
      'Identity & Access Management',
      'Infrastructure Security',
      'Intrusion Detection',
      'Vulnerability Scan',
      'IT Operations Management',
    ],
  },
  {
    name: 'Organization Security',
    objectives: [
      'Background',
      'Endpoint Protection',
      'Mobile Management',
      'Vendor Risk Management',
    ],
  },
]
*/

function getAllObjectivesByCategory(sources) {
  const connectedObjectives = sources.map((source) => {
    const category = get(source, 'metrics[0].category.name')
    const objective = get(source, 'metrics[0].objective.name')
    return {
      id: source.id,
      name: objective,
      category,
      status: SecurityObjectiveStatusTypes.CONNECTED,
      source,
    }
  })
  const result = {}
  SecurityObjectiveCategories.forEach(
    ({ name, objectives: objectiveNames }) => {
      const objectives = filter(connectedObjectives, { category: name })
      const unconnectedObjectives = without(
        objectiveNames,
        ...map(objectives, 'name'),
      )
      unconnectedObjectives.forEach((objective) => {
        objectives.push({
          id: objective,
          name: objective,
          category: name,
          status: SecurityObjectiveStatusTypes.NOT_CONNECTED,
        })
      })
      result[name] = objectives
    },
  )
  return result
}

const CmeDashboard = ({ partner }) => {
  const [openConnectVendorModal] = useModal(ConnectVendorModal)
  const {
    data: feedData,
    isFetching,
    isError,
  } = useQuery(['cmeDashboardFeed', partner.feed_api_path], () =>
    axios.get(partner.feed_api_path).then((res) => res.data),
  )
  const objectivesByCategory = useMemo(
    () =>
      feedData?.sources ? getAllObjectivesByCategory(feedData.sources) : {},
    [feedData],
  )

  const handleConnect = useCallback(
    () => openConnectVendorModal(),
    [openConnectVendorModal],
  )
  return (
    <SecuritySenseDashboard
      title="Security Sense Dashboard"
      objectivesByCategory={objectivesByCategory}
      loading={isFetching}
      error={isError || (feedData && !objectivesByCategory)}
      onConnect={handleConnect}
    />
  )
}

CmeDashboard.propTypes = {
  partner: PropTypes.object.isRequired,
}

export default withReactQuery()(CmeDashboard)
