import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  align-items: flex-start;

  > * {
    flex: 1;
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
`
