import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'components-v2/atoms/Icon'
import A from 'components-v2/atoms/A'
import Button from 'components-v2/atoms/Button'
import useShowRiskReconIssues from 'components/client/shared/useShowRiskReconIssues'
// TODO: Think a better way to share code between two views
import VendorRiskProfileView from 'components/client/vendor_risk_profile_view/View'
import { useGlobalOrgIntegrationMetricsFetcher } from 'apis'
import { OrgHeader, OrgLogo, OrgName } from './styles'

const GloborgOrgRiskProfile = ({ clientId, org, backButtonLabel, onBack }) => {
  const showRiskReconIssues = useShowRiskReconIssues(org.id, clientId)
  const {
    data: metrics,
    isLoading,
    isError,
  } = useGlobalOrgIntegrationMetricsFetcher({
    globorgOrgId: org.id,
    params: { client_id: clientId },
  })
  const domain = `https://${org.domain}`
  return (
    <div>
      <Button variant="text" onClick={onBack}>
        <Icon icon="fa fa-chevron-left" />
        {backButtonLabel}
      </Button>
      <OrgHeader>
        {org.logo_url_small && (
          <OrgLogo src={org.logo_url_small} alt={org.name} />
        )}
        <div>
          <OrgName>{org.name}</OrgName>
          <A href={domain} target="_blank" underline>
            {domain}
          </A>
        </div>
      </OrgHeader>
      <VendorRiskProfileView
        loading={isLoading}
        hasError={isError}
        integrationMetrics={metrics}
        onRiskReconIssuesCountClick={showRiskReconIssues}
      />
    </div>
  )
}

GloborgOrgRiskProfile.propTypes = {
  clientId: PropTypes.string.isRequired,
  org: PropTypes.object.isRequired,
  backButtonLabel: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
}

export default GloborgOrgRiskProfile
