import { useEffect } from 'react'
import { scrollTo, scrollToTable } from './utils'

export default function useHashScrollOnInitialLoad(
  assessmentId,
  initialSectionId,
) {
  // Scroll to hashed element on initial load
  useEffect(() => {
    if (window.location.hash) {
      const domId = window.location.hash.replace('#', '')
      setTimeout(() => {
        if (domId === initialSectionId) {
          scrollToTable(assessmentId)
        } else {
          scrollTo(domId)
        }
      }, 2000)
    }
  }, [initialSectionId, assessmentId])
}
