import React from 'react'
import PropTypes from 'prop-types'
import { getFilteredRowModel } from '@tanstack/react-table'
import reactElementToJSXString from 'react-element-to-jsx-string'

const globalFilterFn = (row, columnId, filterValue) => {
  const cell = row.getAllCells().find((e) => e.column.id === columnId)
  const content = cell.column.columnDef.cell(cell)
  return (
    content &&
    reactElementToJSXString(content)
      .toLowerCase()
      .includes(filterValue.toLowerCase())
  )
}

// Enabled by default
export default function withGlobalFilter(WrappedComponent) {
  const WrapperComponent = ({
    state = {},
    globalFilter,
    enableGlobalFilter,
    onGlobalFilterChange,
    ...rest
  }) => {
    const stateEx =
      enableGlobalFilter && globalFilter
        ? {
            ...state,
            globalFilter,
          }
        : state
    const onGlobalFilterChangeEx =
      enableGlobalFilter && onGlobalFilterChange
        ? (updater) => {
            onGlobalFilterChange(updater(stateEx.globalFilter))
          }
        : undefined

    return (
      <WrappedComponent
        {...rest}
        state={stateEx}
        getFilteredRowModel={getFilteredRowModel()}
        globalFilterFn={globalFilterFn}
        enableGlobalFilter={enableGlobalFilter}
        onGlobalFilterChange={onGlobalFilterChangeEx}
      />
    )
  }

  WrapperComponent.propTypes = {
    state: PropTypes.object,
    globalFilter: PropTypes.string,
    enableGlobalFilter: PropTypes.bool,
    onGlobalFilterChange: PropTypes.func,
  }

  WrapperComponent.defaultProps = {
    enableGlobalFilter: true,
  }

  return WrapperComponent
}
