import differenceInHours from 'date-fns/differenceInHours'
import map from 'lodash/map'
import filter from 'lodash/filter'
import get from 'lodash/get'
import max from 'lodash/max'
import { toDate } from 'utils/date'
import {
  SecurityToolStatusTypes,
  SecurityObjectiveStatusTypes,
} from 'constants/index'

export const getLastActiveTime = (metrics) => {
  if (metrics && metrics.length > 0) {
    const activeMetrics = filter(metrics, { value: 1 })
    const lastActiveTime = max(map(activeMetrics, 'time'))
    return toDate(lastActiveTime)
  }
  return null
}

export const getToolStatus = (source) => {
  const lastActiveTime = getLastActiveTime(source.metrics)
  if (!lastActiveTime) {
    return SecurityToolStatusTypes.NOT_REPORTING
  }
  const diff = differenceInHours(new Date(), lastActiveTime)
  if (diff >= 2) {
    return SecurityToolStatusTypes.NOT_REPORTING
  }
  if (diff === 1) {
    return SecurityToolStatusTypes.WARNING
  }
  return SecurityToolStatusTypes.ACTIVE
}

export const getCategoryRiskRating = (objectivesInCategory) => {
  if (objectivesInCategory.find((objective) => objective.tool)) {
    const active = filter(
      objectivesInCategory,
      (objective) =>
        get(objective, 'tool.status') === SecurityToolStatusTypes.ACTIVE,
    )
    const total = filter(
      objectivesInCategory,
      (objective) =>
        objective.status !== SecurityObjectiveStatusTypes.NOT_APPLICABLE &&
        objective.status !== SecurityObjectiveStatusTypes.REQUESTED,
    )
    return ((active.length || 0) / total.length) * 100
  }
  return null
}

export const getCategoryUpdatedAt = (objectivesInCategory) => {
  const lastTimes = objectivesInCategory.map((objective) => {
    if (get(objective, 'tool.metrics') && objective.tool.metrics.length > 0) {
      return max(map(objective.tool.metrics, 'time'))
    }
    return null
  })
  const lastTime = max(lastTimes)
  return toDate(lastTime)
}
