import React from 'react'
import * as yup from 'yup'
import FormDialog from 'components-v2/organisms/FormDialog'
import {
  useClientAssessmentTemplatesFetcher,
  useClientAssessmentsFetcher,
} from 'apis'
import { submitForm } from 'utils/submit_form'
import { getPageUrl } from 'utils/url'
import { AssessmentStatusTypes, LabelTypes } from 'constants/index'
import config from 'config'
import CompareVendorsFormField from '../fields/CompareVendorsFormField'
import SelectAssessmentTemplateFormField from '../fields/SelectAssessmentTemplateFormField'

const formConfig = {
  validationSchema: yup.object().shape({
    assessmentTemplate: yup.string().label('Assessment').required(),
    assessments: yup
      .array()
      .min(1, `Please choose at least 1 ${LabelTypes.VENDOR.toLowerCase()}`)
      .max(
        5,
        `Please choose 5 ${LabelTypes.VENDORS.toLowerCase()} at the maximum`,
      ),
  }),
  initialValues: {
    assessmentTemplate: undefined,
    assessments: [],
  },
}

const VendorAnswerComparisonModal = (props) => {
  const [templateId, setTemplateId] = React.useState()

  // Fetch assessment templates that have been completed by more than one vendor
  const { data: assessmentTemplates = [] } =
    useClientAssessmentTemplatesFetcher({
      params: {
        page: 1,
        per_page: config.collectionQueryTotalLimit.assessmentTemplates,
        has_assessments_status: AssessmentStatusTypes.REVIEWED,
      },
    })

  // Fetch reviewed assessments using templateId
  const { data: assessments = [] } = useClientAssessmentsFetcher(
    {
      params: {
        page: 1,
        per_page: config.collectionQueryTotalLimit.assessments,
        assessment_template_id: templateId,
        status: AssessmentStatusTypes.REVIEWED,
      },
    },
    { enabled: !!templateId },
  )

  const handleAssessmentTemplateSelect = React.useCallback(
    (newTemplateId, setFieldValue) => {
      setTemplateId(newTemplateId)
      setFieldValue('assessments', [], false)
    },
    [],
  )

  const handleSubmit = React.useCallback((formData) => {
    submitForm(getPageUrl('clientAssessmentsVendorAnswerComparisonReport'), {
      method: 'POST',
      body: { ids: formData.assessments.join() },
    })
  }, [])

  return (
    <FormDialog
      {...props}
      title={`${LabelTypes.VENDOR} Answer Comparison Report`}
      submitButtonLabel="View Report"
      formConfig={formConfig}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue }) => (
        <>
          <SelectAssessmentTemplateFormField
            assessmentTemplates={assessmentTemplates}
            onChange={(value) =>
              handleAssessmentTemplateSelect(value, setFieldValue)
            }
          />
          <CompareVendorsFormField assessments={assessments} />
        </>
      )}
    </FormDialog>
  )
}

export default VendorAnswerComparisonModal
