import styled from 'styled-components'
import { Combobox } from '@headlessui/react'
import { media } from 'styles'

export const Wrapper = styled.div`
  position: relative;
  margin-right: 8px;
  display: none;

  ${media.desktop`
    display: block;
  `}
`

export const SearchInput = styled(Combobox.Input)`
  width: 145px;
  display: block;
  height: 38px;
  padding: 7px 12px;
  font-size: 16px;
  line-height: 1.428571429;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
  }
`

export const ResultsContainer = styled.div`
  position: absolute;
  top: 36px;
  left: 0px;
  width: 300px;
  padding: 10px;
  max-height: calc(100vh - 80px);
  z-index: 1;

  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 6px 2px rgba(0, 0, 0, 0.1);

  overflow-y: auto;
  overflow-x: hidden;
`

export const Result = styled.div`
  margin-top: 20px;
  &:first-child {
    margin-top: 0;
  }
`

export const ResultHeading = styled.h5`
  text-transform: capitalize;
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
`

export const ResultItem = styled.a`
  ${({ active }) =>
    active &&
    `
      text-decoration: underline;
    `}
  line-height: 1.5;
`
