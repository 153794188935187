import { useQuery } from '@tanstack/react-query'
import * as apis from 'apis'
import config from 'config'

export const useAssessmentsElementsFetcher = (params, options) =>
  useQuery(
    ['clientAssessmentsElements', params],
    () => {
      const { assessmentIds, sectionId } = params
      return Promise.all(
        assessmentIds.map((assessmentId) => {
          if (sectionId) {
            return apis
              .getClientAssessmentSection(assessmentId, sectionId)
              .then((response) => response.data.elements)
          }
          const payload = {
            params: {
              per_page: config.collectionQueryTotalLimit.assessmentElements,
            },
          }
          return apis
            .getClientAssessmentElements(assessmentId, payload)
            .then((response) => response.data)
        }),
      )
    },
    options,
  )
