import React from 'react'
import PropTypes from 'prop-types'
import Error from 'components-v2/molecules/Error'

export default (ErrorComponent = Error) =>
  (WrappedComponent) => {
    const WrapperComponent = ({ hasError, ...rest }) =>
      hasError ? <ErrorComponent /> : <WrappedComponent {...rest} />

    WrapperComponent.propTypes = {
      hasError: PropTypes.bool,
    }

    return WrapperComponent
  }
