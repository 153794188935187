import React from 'react'
import PropTypes from 'prop-types'
import { useLibraryManageListingsFetcher } from 'apis'
import useManageSharing from './useManageSharing'
import useRequestNewVendorProject from './useRequestNewVendorProject'
import View from './View'

const ProfileArtifactsContainer = ({ profileId, requestableProjects }) => {
  const { data: listings } = useLibraryManageListingsFetcher()
  const assessmentListings = listings // for now
  const attachmentListings = []
  const manageSharing = useManageSharing(profileId)
  const requestNewVendorProject =
    useRequestNewVendorProject(requestableProjects)

  return (
    <View
      assessmentListings={assessmentListings}
      attachmentListings={attachmentListings}
      onAddNew={requestNewVendorProject}
      onManageSharing={manageSharing}
    />
  )
}

ProfileArtifactsContainer.propTypes = {
  profileId: PropTypes.string.isRequired,
  requestableProjects: PropTypes.array,
}

export default ProfileArtifactsContainer
