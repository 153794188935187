import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import keyBy from 'lodash/keyBy'
import isNumber from 'lodash/isNumber'
import { withReactQuery } from 'hocs'
import {
  isQuestionScorable,
  getRiskRatingLabel,
  getRiskRatingColor,
} from 'lib/assessment'
import { AssessmentElementTypes, ResponseScoreOptions } from 'constants/index'
import View from './View'
import { useAssessmentsElementsFetcher } from './queries'

const ResponseScoreOptionsObj = keyBy(ResponseScoreOptions, 'value')

const Container = ({ template, assessments, needPagination, sections }) => {
  const [page, setPage] = useState(1)
  const assessmentIds = useMemo(
    () => assessments.map((e) => e.id),
    [assessments],
  )
  const { data, isFetching } = useAssessmentsElementsFetcher({
    assessmentIds,
    sectionId: needPagination && page ? sections[page - 1]?.id : null,
  })

  const handlePaginationChange = useCallback((newState) => {
    setPage(newState.page)
  }, [])

  const tableData = useMemo(() => {
    if (!data) {
      return []
    }
    let elementsArr = data
    if (needPagination && page) {
      // Add section heading to the beginning of element array
      const section = {
        ...sections[page - 1],
        type: AssessmentElementTypes.SECTION_HEADING,
      }
      elementsArr = data.map((result) => [section, ...result])
    }

    const elementsArr2 = elementsArr.map((elements) => keyBy(elements, 'id'))
    const firstElements = elementsArr[0]
    return map(firstElements, (element) => {
      if (element.type === AssessmentElementTypes.SECTION_HEADING) {
        const cells = assessments.map((assessment) => {
          const riskRating = assessment.section_risk_ratings[element.id]
          const label = getRiskRatingLabel(riskRating)
          const color = getRiskRatingColor(riskRating)
          return { label, color }
        })
        return { ...element, cells }
      }

      const cells = elementsArr2.map((elements) => {
        const value =
          elements[element.id] &&
          isQuestionScorable(elements[element.id]) &&
          elements[element.id].response?.review_score
        const note = elements[element.id]?.response?.review_note
        // Translate review score range into positive number range
        const label = isNumber(value) ? value + 3 : 'No score'
        const color = isNumber(value)
          ? ResponseScoreOptionsObj[value]?.color
          : undefined
        return { label, color, note }
      })
      return { ...element, cells }
    })
  }, [data, sections, needPagination, page, assessments])

  return (
    <View
      page={page}
      loading={isFetching}
      template={template}
      assessments={assessments}
      needPagination={needPagination}
      pageCount={sections.length}
      tableData={tableData}
      onPaginationChange={handlePaginationChange}
    />
  )
}

Container.propTypes = {
  template: PropTypes.object.isRequired,
  assessments: PropTypes.array.isRequired,
  needPagination: PropTypes.bool,
  sections: PropTypes.array.isRequired,
}

export default withReactQuery()(Container)
