import pick from 'lodash/pick'
import { getPageUrl } from 'utils/url'
import { localTimeZoneOffsetInHours } from 'utils/date'
import { FilterTypes } from 'components-v2/molecules/FilterDropdownTray'
import { LabelTypes, RiskTierOptions, ExportFormatTypes } from 'constants/index'
import { RequestStatuses } from './constants'

export const getFilterConfigList = (requests, filterData) =>
  requests ? getRequestFilters(filterData) : getVendorFilters(filterData)

const getVendorFilters = (vendorTags = []) => {
  const filterConfigList = []
  if (vendorTags && vendorTags.length > 0) {
    filterConfigList.push({
      name: 'vendorTags',
      title: `${LabelTypes.VENDOR} Label`,
      options: vendorTags.map((tag) => ({
        value: tag.id,
        label: tag.name,
      })),
      selectAllVisible: true,
      matchVisible: true,
    })
  }
  return filterConfigList.concat([
    {
      name: 'riskTiers',
      title: `${LabelTypes.VENDOR} Risk Tier`,
      options: [...RiskTierOptions, { value: 'none', label: 'None' }],
      selectAllVisible: true,
    },
    {
      name: 'createdAt',
      title: 'Created',
      type: FilterTypes.DATE_RANGE,
      minDate: new Date('2018-01-01'),
      maxDate: new Date(),
      datePickerProps: {
        dateFormat: 'MM/dd/yyyy',
      },
    },
    {
      name: 'nextReassessmentAt',
      title: 'Next Assessment',
      type: FilterTypes.DATE_RANGE,
      minDate: new Date('2018-01-01'),
      maxDate: new Date(),
      datePickerProps: {
        dateFormat: 'MM/dd/yyyy',
      },
    },
  ])
}

const getRequestFilters = (requesters = []) => {
  const filterConfigList = [
    {
      name: 'requestedBy',
      title: 'Requested By',
      options: requesters.map((user) => ({
        value: user.id,
        label: user.name,
      })),
    },
    {
      name: 'status',
      title: 'Status',
      options: Object.entries(RequestStatuses).map(([k, v]) => ({
        value: k,
        label: v,
      })),
    },
    {
      name: 'createdAt',
      title: 'Created',
      type: FilterTypes.DATE_RANGE,
      minDate: new Date('2018-01-01'),
      maxDate: new Date(),
      datePickerProps: {
        dateFormat: 'MM/dd/yyyy',
      },
    },
  ]
  return filterConfigList
}

export const getVendorsQueryParams = (
  {
    page,
    sizePerPage,
    sortField,
    sortOrder,
    searchText,
    includeArchived,
    filter,
  },
  requests,
) => {
  const status = filter.status?.selected ?? []
  const params = {
    page,
    per_page: sizePerPage,
    ordered_by: sortField,
    direction: sortOrder,
    search_query: searchText,
    requests,
    with_vendor_tags: filter.vendorTags?.selected?.length
      ? filter.vendorTags.selected.join(',')
      : null,
    any: filter.vendorTags?.anyMatch ?? true,
    risk_tier_list: filter.riskTiers?.selected ?? [],
    exclude_archived:
      status.length > 0 ? !status.includes('declined') : !includeArchived,
    requested_by: filter.requestedBy?.selected ?? [],
    status_list: status,
    create_start_date: filter.createdAt?.startDate,
    create_end_date: filter.createdAt?.endDate,
    next_start_date: filter.nextReassessmentAt?.startDate,
    next_end_date: filter.nextReassessmentAt?.endDate,
    tz: localTimeZoneOffsetInHours,
  }
  return params
}

export const getExportUrl = (format, queryParams) =>
  getPageUrl(
    queryParams.requests ? 'clientVendorRequestsExport' : 'clientVendorsExport',
    undefined,
    pick(queryParams, [
      'requests',
      'exclude_archived',
      'search_query',
      'with_vendor_tags',
      'any',
      'risk_tier_list',
      'requested_by',
      'status_list',
      'create_start_date',
      'create_end_date',
      'next_start_date',
      'next_end_date',
      'tz',
    ]),
    format,
  )

export const getCSVContactExportUrl = (queryParams) =>
  getPageUrl(
    'clientVendorsContactsExport',
    undefined,
    pick(queryParams, [
      'exclude_archived',
      'search_query',
      'with_vendor_tags',
      'any',
      'risk_tier_list',
      'create_start_date',
      'create_end_date',
      'next_start_date',
      'next_end_date',
      'tz',
    ]),
    ExportFormatTypes.CSV,
  )
