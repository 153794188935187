import React from 'react'
import PropTypes from 'prop-types'
import DList from 'components-v2/molecules/DList'
import InfoCard from 'components/shared/GlobalProfile/InfoCard'

import { Wrapper, Left, Right } from './styles'

// eslint-disable-next-line no-unused-vars
const General = ({ libraryProfile }) => (
  <Wrapper>
    <Left>
      <InfoCard title="Details">
        <DList horizontal>
          <dt>Name</dt>
          <dd>{libraryProfile.name}</dd>
          <dt>Website</dt>
          <dd>{libraryProfile.website_url}</dd>
        </DList>
      </InfoCard>
    </Left>
    <Right></Right>
  </Wrapper>
)

General.propTypes = {
  libraryProfile: PropTypes.object.isRequired,
}

export default General
