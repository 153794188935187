import React from 'react'
import ErrorView from 'components-v2/templates/ErrorView'
import img from 'images/error/exclaim.png'

export default () => (
  <ErrorView
    imageUrl={img}
    heading={<span className="text-uppercase">Suspended Account</span>}
  >
    <p>
      This account is currently suspended and may not be accessed. Please
      contact support for more information.
    </p>
  </ErrorView>
)
