import styled from 'styled-components'
import Card from 'components-v2/organisms/Card'

export const Wrapper = styled(Card).attrs({ bordered: true })`
  display: flex;
  flex-direction: column;
  text-align: center;
  min-height: 200px;
`

export const Top = styled(Card.Segment)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 25px 0 0;
`

export const Title = styled.h6`
  flex: none;
  margin: 0;
  line-height: 14px;
`

export const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Bottom = styled(Card.ViewMoreSegment)`
  padding-left: 0;
  padding-right: 0;
`
