import React from 'react'
import PropTypes from 'prop-types'
import SimpleDialog from 'components-v2/molecules/SimpleDialog'
import { toLocalTimeString } from 'utils/date'
import {
  ListItemWrapper,
  ListItemIcon,
  ListItemMain,
  ListItemTitle,
  ListItemDate,
  NoDataText,
} from './styles'

const SharedFilesModal = ({ client, ...rest }) => (
  <SimpleDialog {...rest} title={`${client.name}’s  Shared Files`}>
    {client.shares.length ? (
      client.shares.map((item) => (
        <ListItemWrapper key={item.id}>
          <ListItemIcon />
          <ListItemMain>
            <ListItemTitle>{item.listing?.name}</ListItemTitle>
          </ListItemMain>
          <ListItemDate>
            Expiration:{' '}
            {item.expires_at
              ? toLocalTimeString(item.expires_at, 'MM/d/yy')
              : 'None'}
          </ListItemDate>
        </ListItemWrapper>
      ))
    ) : (
      <NoDataText>No shared files yet</NoDataText>
    )}
  </SimpleDialog>
)

SharedFilesModal.propTypes = {
  client: PropTypes.object.isRequired,
}

export default SharedFilesModal
