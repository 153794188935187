import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Library } from '@styled-icons/fluentui-system-filled/Library'
import { ShieldStar } from '@styled-icons/remix-line/ShieldStar'
import styled from 'styled-components'
import Icon from 'components-v2/atoms/Icon'
import Label from 'components-v2/atoms/Label'
import Button from 'components-v2/atoms/Button'
import ListGroup from 'components-v2/molecules/ListGroup'
import { Row, Col } from 'components-v2/organisms/Layout'

const NameWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 6px;
  font-size: 22px;
`

const REQUIRED = 'required'

const OrganizationItem = ({ org, destOrg, redirect, priority }) => {
  const path = useMemo(() => {
    if (
      redirect &&
      redirect.length > 0 &&
      (destOrg === null || destOrg.id === org.id)
    ) {
      return `${org.switch_path}?redirect=${encodeURIComponent(redirect)}`
    }
    return org.switch_path
  }, [org, destOrg, redirect])

  return (
    <ListGroup.Item href={path}>
      <Row>
        <Col sm={12}>
          {priority === REQUIRED && destOrg?.id === org.id && (
            <Button color="primary" className="pull-right">
              Continue
            </Button>
          )}
          {priority === REQUIRED && destOrg?.id !== org.id && (
            <Button className="pull-right">Choose</Button>
          )}
          {priority !== REQUIRED && (
            <Button color="primary" className="pull-right">
              Choose
            </Button>
          )}
          <NameWrapper>
            {org.is_system_org ? (
              <>
                {org.name}
                <Icon icon={org.is_vendor ? Library : ShieldStar} />
              </>
            ) : (
              org.name_with_client
            )}
          </NameWrapper>
          <div className="top5">
            {org.is_vendor && (
              <span>
                {org.invited_or_in_progress_assessments_count > 0 && (
                  <Label style={{ marginRight: '5px' }}>
                    open assessments (
                    {org.invited_or_in_progress_assessments_count})
                  </Label>
                )}
                {org.open_issues_count > 0 && (
                  <Label color="primary">
                    open issues ({org.open_issues_count})
                  </Label>
                )}
              </span>
            )}
          </div>
        </Col>
      </Row>
    </ListGroup.Item>
  )
}

OrganizationItem.propTypes = {
  org: PropTypes.object.isRequired,
  destOrg: PropTypes.object,
  priority: PropTypes.string,
  redirect: PropTypes.string,
}

export default OrganizationItem
