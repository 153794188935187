import React from 'react'
import PropTypes from 'prop-types'
import { useAuthorizations } from 'hooks'
import { Wrapper, Title, EditLink } from './styles'

const InfoCard = ({ title, editing, children, updatable, onEdit, ...rest }) => {
  const [canUpdate] = useAuthorizations('update')
  return (
    <Wrapper {...rest}>
      <Title>{title}</Title>
      {onEdit && !editing && canUpdate && updatable && (
        <EditLink onClick={onEdit} />
      )}
      {children}
    </Wrapper>
  )
}

InfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  editing: PropTypes.bool,
  onEdit: PropTypes.func,
  children: PropTypes.node,
  updatable: PropTypes.bool,
}

export default React.memo(InfoCard)
