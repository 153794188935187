import React from 'react'
import ConfirmDialog from 'components-v2/organisms/ConfirmDialog'
import useModal from './useModal'

/**
 * Custom hook that provides a modal confirmation dialog, with an option to return a promise.
 * It utilizes the `useModal` hook to manage the modal's state.
 *
 * @returns {Function} A function to trigger the confirmation dialog.
 * The function accepts `modalProps` to customize the dialog and a `returnPromise` flag.
 *
 * @param {Object} [modalProps={}] - Properties to customize the confirmation dialog, including callbacks like `onConfirm` and `onCancel`.
 * @param {boolean} [returnPromise=false] - If `true`, the function returns a promise that resolves with `true` for confirmation and `false` for cancellation.
 *
 * @example
 * const confirm = useConfirm();
 * confirm({ title: "Are you sure?" }, true).then((confirmed) => {
 *   if (confirmed) {
 *     // Perform an action on confirmation
 *   }
 * });
 */

export default function useConfirm() {
  const functions = useModal(ConfirmDialog)
  return React.useCallback(
    (modalProps = {}, returnPromise = false) => {
      if (returnPromise) {
        return new Promise((resolve) => {
          functions[0]({
            ...modalProps,
            onConfirm: () => {
              functions[1]()
              resolve(true)
            },
            onCancel: () => {
              functions[1]()
              resolve(false)
            },
          })
        })
      }

      return functions[0]({
        ...modalProps,
        onConfirm: () => {
          // TODO: Check if there is real use case that we need to delay closing until an async action is completed
          Promise.resolve(modalProps.onConfirm && modalProps.onConfirm()).then(
            () => {
              // If the confirming promise is resolved, close the modal
              functions[1]()
            },
          )
        },
        onCancel: () => {
          functions[1]()
          if (modalProps.onCancel) {
            modalProps.onCancel()
          }
        },
      })
    },
    [functions],
  )
}
