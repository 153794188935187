import { useCallback } from 'react'
import { useModal } from 'hooks'
import { useImportVendorAssessmentResponse } from 'apis'
import ImportAssessmentResponsesModal from 'components/shared/ImportAssessmentResponsesModal'

export default function useImportResponses(assessment) {
  const [openModal] = useModal(ImportAssessmentResponsesModal)
  const { mutateAsync: importVendorAssessmentResponse } =
    useImportVendorAssessmentResponse()
  const { status: assessmentStatus, id: assessmentId } = assessment

  return useCallback(() => {
    openModal({
      assessmentStatus,
      instruction: `To import responses for this assessment, upload the Excel file containing the responses in the correct format.`,
      note: `Note: This will replace any answers already entered.`,
      onSubmit: (data) =>
        importVendorAssessmentResponse({
          assessmentId,
          data,
        }),
    })
  }, [
    openModal,
    importVendorAssessmentResponse,
    assessmentStatus,
    assessmentId,
  ])
}
