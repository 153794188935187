import React from 'react'
import { useModal } from 'hooks'
import SharedFilesModal from './SharedFilesModal'

export default () => {
  const [openModal] = useModal(SharedFilesModal)

  return React.useCallback(
    (client) => {
      openModal({
        client,
      })
    },
    [openModal],
  )
}
