import React from 'react'
import PropTypes from 'prop-types'
import Loader from 'components-v2/molecules/Loader'
import PrintButton from 'components-v2/molecules/PrintButton'
import PageHeader from 'components-v2/organisms/PageHeader'
import VendorResultsSummary from 'components/client/shared/VendorResultsSummary'
import { LabelTypes } from 'constants/index'
import { useAuthorizations } from 'hooks'
import { getPageUrl } from 'utils/url'
import { FilterWrapper, Placeholder, OptionCheckbox } from './styles'

const View = ({
  vendor,
  loading,
  projects,
  archivedVisible,
  includeChildren,
  hasChildren,
  onToggleArchived,
  onToggleChildren,
  ...rest
}) => {
  const [canViewArchived] = useAuthorizations(['view_archived', 'Assessment'])
  const breadcrumbs = [
    {
      href: getPageUrl('clientVendors'),
      title: LabelTypes.VENDORS,
    },
    {
      href: getPageUrl('clientVendor', { id: vendor.id }),
      title: vendor.name,
    },
    {
      active: true,
      title: 'Results Summary',
    },
  ]
  return (
    <div {...rest}>
      <PageHeader
        title={`${vendor.name} Results Summary`}
        breadcrumbs={breadcrumbs}
      >
        <PrintButton color="primary" />
      </PageHeader>
      <FilterWrapper>
        {hasChildren && (
          <OptionCheckbox
            inline
            label="Include children"
            checked={includeChildren}
            onChange={onToggleChildren}
          />
        )}
        {canViewArchived && (
          <OptionCheckbox
            inline
            label="Show archived"
            checked={archivedVisible}
            onChange={onToggleArchived}
          />
        )}
      </FilterWrapper>
      {loading ? (
        <Placeholder>
          <Loader message="Loading..." />
        </Placeholder>
      ) : projects?.length > 0 ? (
        <VendorResultsSummary
          projects={projects}
          includeChildren={includeChildren}
        />
      ) : (
        <Placeholder>
          No projects found for {LabelTypes.VENDOR.toLowerCase()}
        </Placeholder>
      )}
    </div>
  )
}

View.propTypes = {
  vendor: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  projects: PropTypes.array,
  archivedVisible: PropTypes.bool,
  includeChildren: PropTypes.bool,
  hasChildren: PropTypes.bool,
  onToggleArchived: PropTypes.func.isRequired,
  onToggleChildren: PropTypes.func.isRequired,
}

export default View
