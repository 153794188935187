import React, { useState } from 'react'
import PropTypes from 'prop-types'
import A from 'components-v2/atoms/A'
import Button from 'components-v2/atoms/Button'
import SearchInput from 'components-v2/molecules/SearchInput'
import { ModeTypes } from '../constants'
import { Title, List, Item, StyledButtonGroup } from './styles'

function QuestionList({ questions, selectedQuestion, onQuestionSelect }) {
  const [questionKeyword, setQuestionKeyword] = useState('')
  let section

  return (
    <div>
      <Title>Questions</Title>
      <SearchInput
        value={questionKeyword}
        placeholder="Find questions..."
        onChange={setQuestionKeyword}
      />
      <List>
        {questions.map((question, index) => {
          let sectionComponent = null
          if (question.section !== section) {
            sectionComponent = (
              <Item>
                <b>{question.section}</b>
              </Item>
            )
          }
          section = question.section
          return (
            <React.Fragment key={question.id}>
              {sectionComponent}
              <Item $active={selectedQuestion?.id === question.id}>
                <A onClick={() => onQuestionSelect(question.id)}>
                  {`${index + 1}. ${question.question}`}
                </A>
                <StyledButtonGroup>
                  <Button
                    size="sm"
                    color={
                      question.selected_matches_count > 0 ? 'info' : 'default'
                    }
                    onClick={() => onQuestionSelect(question.id, ModeTypes.MAP)}
                  >
                    {question.selected_matches_count > 0 ? 'Mapped' : 'Map'}
                  </Button>
                  <Button
                    size="sm"
                    color={
                      question.selected_answers_count > 0
                        ? 'success'
                        : 'default'
                    }
                    onClick={() =>
                      onQuestionSelect(question.id, ModeTypes.ANSWER)
                    }
                  >
                    {question.selected_answers_count > 0
                      ? 'Answered'
                      : 'Answer'}
                  </Button>
                </StyledButtonGroup>
              </Item>
            </React.Fragment>
          )
        })}
      </List>
    </div>
  )
}

QuestionList.propTypes = {
  questions: PropTypes.array.isRequired,
  selectedQuestion: PropTypes.object,
  onQuestionSelect: PropTypes.func.isRequired,
}

export default QuestionList
