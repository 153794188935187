import { css } from 'styled-components'
import fonts from './fonts'

export const hidePrint = css`
  @media print {
    display: none !important;
  }
`

export const showPrint = css`
  @media not print {
    display: none !important;
  }
`

export const avoidPageBreak = css`
  @media print {
    page-break-inside: avoid;
  }
`

export const noPrintRowGroup = css`
  @media print {
    display: table-row-group;
  }
`

export const smallHeader = css`
  font-family: ${fonts.SECONDARY};
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-transform: uppercase;
`

export const clearfix = css`
  &:after {
    content: '';
    display: table;
    clear: both;
  }
`

export const truncate = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const clearButtonStyle = css`
  border: none;
  background: none;
  padding: 0;
`

export default {
  hidePrint,
  showPrint,
  avoidPageBreak,
  smallHeader,
  clearfix,
  truncate,
  noPrintRowGroup,
  clearButtonStyle,
}
