import styled from 'styled-components'
import get from 'lodash/get'
import { sizes, media } from 'styles'

export const Col = styled.div`
  position: relative;
  min-height: 1px;
  float: left;
  padding: 0 ${(props) => (props.theme.hGutter || sizes.GRID_HGUTTER) / 2}px;

  ${({ theme }) =>
    theme.vGutter &&
    `
    margin-bottom: ${theme.vGutter}px;
  `}

  ${({ xs = 12 }) =>
    `
    width: ${(100 / 12) * xs}%;
  `}

  ${({ sm }) =>
    sm &&
    media.nmobile`
    width: ${(100 / 12) * sm}%;
  `}

  ${({ md }) =>
    md &&
    media.ntablet`
    width: ${(100 / 12) * md}%;
  `}

  ${({ lg }) =>
    lg &&
    `
    @media (min-width: 1200px) {
      width: ${(100 / 12) * lg}%;
    }
  `}

  ${({ xsOffset }) =>
    xsOffset &&
    `
    margin-left: ${(100 / 12) * xsOffset}%;
  `}

  ${({ smOffset }) =>
    smOffset &&
    media.nmobile`
    margin-left: ${(100 / 12) * smOffset}%;
  `}

  ${({ mdOffset }) =>
    mdOffset &&
    media.ntablet`
    margin-left: ${(100 / 12) * mdOffset}%;
  `}

  ${({ lgOffset }) =>
    lgOffset &&
    `
    @media (min-width: 1200px) {
      margin-left: ${(100 / 12) * lgOffset}%;
    }
  `}
`

export const Row = styled.div`
  margin-left: -${(props) => (props.theme.hGutter || sizes.GRID_HGUTTER) / 2}px;
  margin-right: -${(props) => (props.theme.hGutter || sizes.GRID_HGUTTER) / 2}px;

  &:before,
  &:after {
    content: ' ';
    display: table;
  }

  &:after {
    clear: both;
  }

  ${(props) =>
    props.$equalHeight &&
    `
    @media (min-width: ${get(media.breakpoints, props.break, 991) + 1}px) {
      display: flex;
      flex-wrap: wrap;

      ${Col} {
        display: flex;
        flex-direction: column;

        > * {
          flex: auto;
        }
      }
    }
  `}

  ${({ theme }) =>
    theme.vGutter &&
    `
    margin-bottom: -${theme.vGutter}px;
  `}
`
