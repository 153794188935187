import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import Accordion from 'components-v2/molecules/Accordion'
import config from 'config'
import {
  useClientAssessmentRiskScoreRatingFetcher,
  useClientIssuesFetcher,
  useClientIssueSummaryFetcher,
} from 'apis'
import Note from '../Note'
import BySections from './BySections'
import Heatmap from './Heatmap'
import IssueSummary from './IssueSummary'
import IssueList from './IssueList'

// Constants
import { AccordionItemTypes } from './constants'

const RiskRatingSummary = ({ assessmentId, ...rest }) => {
  const [issueSummaryEntered, setIssueSummaryEntered] = useState(false)
  const { data: assessment, isSuccess: assessmentLoaded } =
    useClientAssessmentRiskScoreRatingFetcher({ assessmentId })
  const { data: issues, isSuccess: issuesLoaded } = useClientIssuesFetcher({
    params: {
      page: 1,
      per_page: config.collectionQueryTotalLimit.issues,
      assessment_id: assessmentId,
    },
  })
  const { data: issueSummaryData, isSuccess: issueSummaryLoaded } =
    useClientIssueSummaryFetcher(
      {
        params: {
          page: 1,
          per_page: config.collectionQueryTotalLimit.issueSummary,
          dimension: 'all',
          assessment_id: assessmentId,
        },
      },
      { enabled: issueSummaryEntered },
    )
  const issueSummary = useMemo(
    () =>
      issueSummaryData
        ? issueSummaryData[Object.keys(issueSummaryData)[0]]
        : undefined,
    [issueSummaryData],
  )

  const handleChange = useCallback((val) => {
    if (val.includes(AccordionItemTypes.ISSUE_SUMMARY)) {
      setIssueSummaryEntered(true)
    }
  }, [])

  if (!assessmentLoaded) {
    return (
      <div style={{ textAlign: 'center' }}>
        <h4>
          <i className="fa fa-spinner fa-spin spin-big" /> &nbsp; Loading...
        </h4>
      </div>
    )
  }

  return (
    <Accordion
      {...rest}
      allowMultipleExpanded
      allowZeroExpanded
      preExpanded={[
        AccordionItemTypes.NOTES,
        AccordionItemTypes.BY_SECTION,
        AccordionItemTypes.ISSUES,
      ]}
      onChange={handleChange}
    >
      <Accordion.Item uuid={AccordionItemTypes.NOTES} title="Assessment Notes">
        {assessment.notes?.[0] && <Note note={assessment.notes[0]} />}
      </Accordion.Item>
      <Accordion.Item
        uuid={AccordionItemTypes.BY_SECTION}
        title={`${get(
          assessment,
          'assessment_template.rating_label',
        )} by Section`}
        avoidPageBreak
      >
        <BySections assessment={assessment} />
      </Accordion.Item>
      <Accordion.Item
        uuid={AccordionItemTypes.HEATMAP}
        title={`${get(
          assessment,
          'assessment_template.rating_label',
        )} Heat Map`}
        mountOnEnter
        avoidPageBreak
      >
        <Heatmap assessment={assessment} />
      </Accordion.Item>
      <Accordion.Item
        uuid={AccordionItemTypes.ISSUE_SUMMARY}
        title="Issue Summary"
        mountOnEnter
        avoidPageBreak
      >
        <IssueSummary data={issueSummary} loading={!issueSummaryLoaded} />
      </Accordion.Item>
      <Accordion.Item
        uuid={AccordionItemTypes.ISSUES}
        title="Issue List"
        mountOnEnter
        avoidPageBreak
      >
        <IssueList data={issues || []} loading={!issuesLoaded} />
      </Accordion.Item>
    </Accordion>
  )
}

RiskRatingSummary.propTypes = {
  assessmentId: PropTypes.string.isRequired,
}

export default React.memo(RiskRatingSummary)
