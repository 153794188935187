import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import debounce from 'lodash/debounce'
import differenceBy from 'lodash/differenceBy'
import FormDialog from 'components-v2/organisms/FormDialog'
import FormField from 'components-v2/molecules/FormField'
import * as apis from 'apis'
import config from 'config'
import TemplateFilterFormFields, {
  validationSchema as TemplatesValidationSchema,
} from '../TemplateFilterFormFields'
import VendorFilterFormFields, {
  validationSchema as VendorsValidationSchema,
} from '../VendorFilterFormFields'

const formConfig = {
  initialValues: {
    users: [],
    templatesFilter: false,
    vendorsFilter: false,
    templates: [],
    vendors: [],
  },
  validationSchema: yup.object().shape({
    users: yup.array().min(1, 'Please add at least 1 user'),
    templates: TemplatesValidationSchema,
    vendors: VendorsValidationSchema,
  }),
}

const getUsersControlProps = (eventSubscribers, userGroups) => ({
  isAsync: true,
  isSimpleValue: false,
  isMulti: true,
  cacheOptions: true,
  placeholder: 'Search users or groups...',
  noOptionsMessage: () => `No users or groups found`,
  defaultOptions: true,
  loadOptions: debounce(
    (search_query) =>
      apis
        .getUsers({
          params: {
            search_query,
            notifiable: true,
            page: 1,
            per_page: config.collectionQueryTotalLimit.addUsersToNotifyModal,
          },
        })
        .then((response) => {
          const existing = Object.values(eventSubscribers)
          const users = differenceBy(response.data, existing, 'id')
          const userOptions = users.map((e) => ({
            value: e.id,
            label: e.name,
            type: 'user',
          }))
          const groupOptions = (
            search_query
              ? userGroups?.filter((e) =>
                  e.name.toLowerCase().includes(search_query.toLowerCase()),
                )
              : userGroups
          )?.map((e) => ({
            value: e.id,
            label: `Group: ${e.name}`,
            type: 'group',
          }))
          return userOptions.concat(groupOptions)
        }),
    1000,
    { leading: true },
  ),
})

const AddUsersToNotifyModal = ({
  assessmentTemplates,
  projectVendorsProps,
  eventSubscribers,
  userGroups,
  ...rest
}) => {
  const usersControlProps = useMemo(
    () => getUsersControlProps(eventSubscribers, userGroups),
    [eventSubscribers, userGroups],
  )
  return (
    <FormDialog
      {...rest}
      title="Add Users to Notify"
      submitButtonLabel="Save"
      formConfig={formConfig}
    >
      {({ values, setFieldValue }) => (
        <>
          <FormField
            name="users"
            label="Add users"
            type={FormField.types.SEARCHABLE_SELECT}
            controlProps={usersControlProps}
            horizontal
            onChange={() => {
              // Whenever users selection is changed, let's reset vendor filter fields since vendor list depends on selected users
              setFieldValue('vendorsFilter', false, false)
              setFieldValue('vendors', [], false)
            }}
          />
          <TemplateFilterFormFields assessmentTemplates={assessmentTemplates} />
          <VendorFilterFormFields
            projectVendors={projectVendorsProps.projectVendors}
            users={values.users}
          />
        </>
      )}
    </FormDialog>
  )
}

AddUsersToNotifyModal.propTypes = {
  assessmentTemplates: PropTypes.array,
  // projectVendors: PropTypes.array,
  projectVendorsProps: PropTypes.object,
  eventSubscribers: PropTypes.object,
  userGroups: PropTypes.array,
}

export default AddUsersToNotifyModal
