import styled from 'styled-components'
import { mixins } from 'styles'

export const OrText = styled.h6`
  margin-left: 30%;
`

export const Progress = styled.div`
  margin-top: 20px;
  margin-left: 24px;
`

export const Wrapper = styled.div`
  margin-top: 20px;
  text-align: center;
`

export const Text = styled.div`
  font-weight: bold;
  ${mixins.truncate}
`
