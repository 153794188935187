import styled from 'styled-components'
import Label from 'components-v2/atoms/Label'
import { Lock } from '@styled-icons/fa-solid/Lock'
import { Eye } from '@styled-icons/fa-regular/Eye'
import { EyeSlash } from '@styled-icons/fa-regular/EyeSlash'
import { PencilAlt } from '@styled-icons/fa-solid/PencilAlt'
import { TrashAlt } from '@styled-icons/fa-solid/TrashAlt'
import { BalanceScale } from '@styled-icons/fa-solid/BalanceScale'
import { Outdent } from '@styled-icons/fa-solid/Outdent'
import { QuestionCircle } from '@styled-icons/fa-solid/QuestionCircle'
import { Question } from '@styled-icons/fa-solid/Question'
import { CommentDots } from '@styled-icons/fa-solid/CommentDots'
import { Paperclip } from '@styled-icons/fa-solid/Paperclip'
import { Warning } from '@styled-icons/material/Warning'
import { Copy } from '@styled-icons/fa-solid/Copy'
import { Key } from '@styled-icons/fa-solid/Key'
import { Cogs } from '@styled-icons/fa-solid/Cogs'
import { FileDownload } from '@styled-icons/fa-solid/FileDownload'
import Icon from 'components-v2/atoms/Icon'
import { colors, sizes, mixins } from 'styles'

export const Wrapper = styled.div`
  position: relative;
`

export const TopInfoSection = styled.dl`
  margin-bottom: 12px;

  dd:not(:last-child) {
    margin-bottom: 22px;
  }
`

export const GlobalLabel = styled(Label).attrs({
  color: colors.MED_DARK_GREY,
})`
  text-transform: capitalize;
`

export const TemplateAttributeList = styled.div`
  > * {
    margin: 6px 0;
  }
`

export const RatingLabelSetEditLink = styled.a.withConfig({
  shouldForwardProp: (prop) => !['isEmpty'].includes(prop),
})`
  border-bottom: none;

  ${(props) =>
    props.isEmpty &&
    `
      font-style: italic;
      ${mixins.hidePrint}
    `}

  ${(props) =>
    props.disabled &&
    `
      pointer-events: none;
      font-style: normal;
      color: gray;
    `}

  &,
  &:hover,
  &:active {
    ${(props) =>
      props.isEmpty &&
      `
        color: ${colors.DANGER};
      `}
    cursor: pointer;
    text-decoration: none;
  }

  &:hover,
  &:active {
    border-bottom: dashed 1px;
    background: lightyellow;
  }
`

export const StickyContent = styled.div`
  padding-top: 8px;
  background: #fff;
  ${(props) =>
    props.isSticky &&
    `
    top: ${sizes.HEADER_HEIGHT}px !important;
    z-index: 1;
  `}
`

export const Title = styled.h3`
  margin: 0;
`

export const TableInfo = styled.div`
  padding-top: 14px;
  white-space: nowrap;
`

export const ProblemList = styled.blockquote`
  font-size: 0.9em;
  font-style: italic;
  margin-top: 10px;
`
export const LockIcon = styled(Icon).attrs({ icon: Lock })``
export const PencilIcon = styled(Icon).attrs({ icon: PencilAlt })``
export const TrashIcon = styled(Icon).attrs({ icon: TrashAlt })``
export const BalanceScaleIcon = styled(Icon).attrs({ icon: BalanceScale })``
export const OutdentIcon = styled(Icon).attrs({ icon: Outdent })``
export const QuestionCircleIcon = styled(Icon).attrs({ icon: QuestionCircle })``
export const QuestionIcon = styled(Icon).attrs({ icon: Question })``
export const CommentDotsIcon = styled(Icon).attrs({ icon: CommentDots })``
export const PaperclipIcon = styled(Icon).attrs({ icon: Paperclip })``
export const WarningIcon = styled(Icon).attrs({ icon: Warning })``
export const CopyIcon = styled(Icon).attrs({ icon: Copy })``
export const KeyIcon = styled(Icon)
  .attrs({ icon: Key })
  .withConfig({
    shouldForwardProp: (prop) => prop !== 'active',
  })`
  ${(props) => props.active && `color: ${colors.PRIMARY};`}
`
export const EyeIcon = styled(Icon)
  .attrs((props) => ({
    icon: props.slashed ? EyeSlash : Eye,
  }))
  .withConfig({
    shouldForwardProp: (prop) => !['slashed', 'inactive'].includes(prop),
  })`
  ${(props) => props.slashed && `color: ${colors.PRIMARY};`}
  ${(props) => props.inactive && `opacity: .8;`}
`
export const CogsIcon = styled(Icon)
  .attrs({ icon: Cogs })
  .withConfig({
    shouldForwardProp: (prop) => !['hasAutomations', 'inactive'].includes(prop),
  })`
  ${(props) => props.hasAutomations && `color: ${colors.PRIMARY};`}
  ${(props) =>
    props.inactive &&
    `
    opacity: .8;
    cursor: not-allowed;
  `}
`
export const FileDownloadIcon = styled(Icon)
  .attrs({ icon: FileDownload })
  .withConfig({
    shouldForwardProp: (prop) => prop !== 'hasAttachments',
  })`
  ${(props) => props.hasAttachments && `color: ${colors.PRIMARY};`}
`
