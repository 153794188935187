// for form architecture using Formik + Yup
import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { FieldArray } from 'formik'
import { BaseField } from 'components-v2/molecules/FormField'
import { useFormField } from 'hooks'
import {
  Row,
  StyledFormField,
  DeleteButton,
  AddButton,
  subFormFieldTheme,
} from './styles'

const ContributorsFieldGroup = ({ name, addNewLabel, ...rest }) => {
  const field = useFormField(name)

  return (
    <BaseField {...rest}>
      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <div>
            <ThemeProvider theme={subFormFieldTheme}>
              {field.value?.map((c, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Row key={index}>
                  <StyledFormField
                    name={`${name}.${index}.name`}
                    placeholder="Name"
                    disabled={!!c.id}
                    controlProps={{ 'data-test-id': `new-user-name-${index}` }}
                    horizontal={false}
                  />
                  <StyledFormField
                    name={`${name}.${index}.email`}
                    placeholder="Email address"
                    disabled={!!c.id}
                    controlProps={{ 'data-test-id': `new-user-email-${index}` }}
                  />
                  <DeleteButton onClick={() => arrayHelpers.remove(index)} />
                </Row>
              ))}
            </ThemeProvider>
            <AddButton
              onClick={() =>
                arrayHelpers.push({ name: undefined, email: undefined })
              }
            >
              <i className="fa fa-plus-circle" />
              {addNewLabel}
            </AddButton>
          </div>
        )}
      />
    </BaseField>
  )
}

ContributorsFieldGroup.propTypes = {
  name: PropTypes.string.isRequired,
  addNewLabel: PropTypes.string,
}

ContributorsFieldGroup.defaultProps = {
  addNewLabel: 'Add another contributor',
}

export default ContributorsFieldGroup
