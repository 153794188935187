import styled from 'styled-components'
import { colors, mixins, media } from 'styles'

const GUTTER = 23

export const Group = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -${GUTTER / 2}px -15px -${GUTTER / 2}px;

  > * {
    flex: 1;
    margin: 0 ${GUTTER / 2}px 15px ${GUTTER / 2}px;

    ${media.tablet`
      width: 144px;
      flex: none;
    `}
  }
`

export const Card = styled.div`
  position: relative;
  padding: 14px 12px;
  border-radius: 4px;
  border: 1px solid ${(p) => (p.checked ? p.color : '#CFD1D4')};
  background: ${(p) => (p.checked ? p.color : colors.WHITE)};
  ${(p) => !p.checked && `cursor: pointer;`}
  ${(p) =>
    p.active &&
    `
    outline: 2px solid ${colors.PRIMARY};
  `}
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: ${(p) => p.color};
  }
`

export const Label = styled.div`
  ${mixins.smallHeader}
  margin-bottom: 12px;
  color: ${(p) => (p.checked ? colors.WHITE : colors.BODY_TEXT)};
  letter-spacing: 0.06em;
`

export const Count = styled.div`
  font-size: 36px;
  line-height: 24px;
  color: ${(p) => {
    if (p.checked) {
      return colors.WHITE
    }
    if (p.zero) {
      return colors.GREY
    }
    return colors.BLACK
  }};
`
