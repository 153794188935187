function isObject(value) {
  return value === Object(value)
}

function isArray(value) {
  return Array.isArray(value)
}

function isFile(value) {
  return value instanceof File
}

function isDate(value) {
  return value instanceof Date
}

function makeArrayKey(key) {
  if (key.length > 2 && key.lastIndexOf('[]') === key.length - 2) {
    return key
  }
  return `${key}[]`
}

export default function objectToFormData(obj, fdOrg, pre) {
  const fd = fdOrg || new FormData()

  const appendToFormData = (key, value) => {
    // Ignore null or undefined values
    if (value === null || value === undefined) {
      return
    }
    fd.append(key, isDate(value) ? value.toISOString() : value)
  }

  Object.keys(obj).forEach((prop) => {
    const key = pre ? `${pre}[${prop}]` : prop

    if (
      isObject(obj[prop]) &&
      !isArray(obj[prop]) &&
      !isFile(obj[prop]) &&
      !isDate(obj[prop])
    ) {
      objectToFormData(obj[prop], fd, key)
    } else if (isArray(obj[prop])) {
      obj[prop].forEach((value) => {
        const arrayKey = makeArrayKey(key)

        if (isObject(value) && !isFile(value) && !isDate(value)) {
          objectToFormData(value, fd, arrayKey)
        } else {
          appendToFormData(arrayKey, value)
        }
      })
    } else {
      appendToFormData(key, obj[prop])
    }
  })

  return fd
}
