import styled from 'styled-components'
import { colors, mixins } from 'styles'

export const Project = styled.div`
  &:not(:last-child) {
    padding-bottom: 15px;
    border-bottom: 1px solid ${colors.LIGHT_GREY};
    margin-bottom: 18px;
  }
`

export const ProjectName = styled.span`
  ${mixins.smallHeader}
  font-size: .8em;
  margin: 0 0 0.7em;
  color: ${(props) => (props.muted ? colors.GREY : colors.BODY_TEXT)};
`

export const ProjectNameLink = styled.a`
  ${mixins.smallHeader}
  font-size: .8em;
  margin: 0 0 0.7em;
  color: ${(props) => (props.muted ? colors.GREY : colors.BODY_TEXT)};
`

export const ProjectNameMeta = styled.span`
  font-weight: normal;
  margin-left: 0.8em;
`

export const VendorBlock = styled.div`
  display: block;
`

export const VendorName = styled(ProjectName)`
  font-weight: normal;
  font-size: 11px;
  display: inline-flex;
  margin-top: 15px;
  margin-left: 15px;
`

export const VendorNameLink = styled(ProjectNameLink)`
  font-weight: normal;
  font-size: 11px;
  display: inline-flex;
  margin-top: 15px;
  margin-left: 15px;
`
