import styled from 'styled-components'

export const FormControlStatic = styled.p`
  min-height: 38px;
  margin: 0;
  padding: 8px 0;
`

export const List = styled.ul`
  margin: 0;
  list-style: circle;
`
