import { getPageUrl } from 'utils/url'
import {
  AssessmentStatusTypes,
  LabelTypes,
  ExportFormatTypes,
} from 'constants/index'

export const exportViewToCSV = ({
  page,
  sizePerPage,
  sortField,
  sortOrder,
  searchText,
  statuses,
  vendorTags,
  vendorTagsAnyMatch,
  assignees,
  archiveValues,
}) => {
  const params = {
    page,
    per_page: sizePerPage,
    search_query: searchText,
    ordered_by: sortField,
    direction: sortOrder,
    status: statuses?.join(','),
    with_vendor_tags: vendorTags?.join(','),
    exclude_archived_vendors: !archiveValues?.includes('vendors'),
    exclude_archived: !archiveValues?.includes('projects'),
    any: vendorTagsAnyMatch,
    client_assignee: assignees?.join(','),
    with_additional_attributes: true,
    with_risk_domain_data: true,
  }
  const url = getPageUrl(
    'clientAssessmentRiskDomainExport',
    undefined,
    params,
    ExportFormatTypes.CSV,
  )
  window.location.assign(url)
}

const statusFilterVals = [
  AssessmentStatusTypes.INVITED,
  AssessmentStatusTypes.IN_PROCESS,
  AssessmentStatusTypes.SUBMITTED,
  AssessmentStatusTypes.REVIEWED,
  AssessmentStatusTypes.EXPIRED,
]
const statusFilterOptions = statusFilterVals.map((value) => ({
  value,
  label: value.titleize(),
}))

export const getFilterConfigList = (vendorTags, users) => {
  const filterConfigList = [
    {
      name: 'statuses',
      title: 'Status',
      options: statusFilterOptions,
      selectAllVisible: true,
    },
    {
      name: 'assignees',
      title: 'Assignee(s)',
      options: users.map((s) => ({ value: s.id, label: s.name })),
      selectAllVisible: true,
    },
  ]
  if (vendorTags && vendorTags.length > 0) {
    filterConfigList.splice(1, 0, {
      name: 'vendorTags',
      title: `${LabelTypes.VENDOR} Label`,
      options: vendorTags.map((s) => ({ value: s.id, label: s.name })),
      selectAllVisible: true,
      matchVisible: true,
    })
  }
  return filterConfigList
}
