import React from 'react'
import PropTypes from 'prop-types'
import Input from 'components-v2/atoms/Input'
import Textarea from 'components-v2/atoms/Textarea'
import BaseField from './BaseField'

const InputField = ({
  name,
  type,
  controlId,
  value,
  placeholder,
  disabled,
  controlProps = {},
  componentClass,
  onChange,
  ...rest
}) => {
  const Component = componentClass === 'textarea' ? Textarea : Input
  return (
    <BaseField controlId={controlId} {...rest}>
      <Component
        {...controlProps}
        id={controlId}
        name={name}
        type={type}
        value={value !== undefined && value !== null ? value : ''}
        placeholder={placeholder}
        disabled={disabled}
        onChange={(e) => onChange(e.currentTarget.value)}
      />
    </BaseField>
  )
}

InputField.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  controlId: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  controlProps: PropTypes.object,
  componentClass: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default React.memo(InputField)
