import React from 'react'
import get from 'lodash/get'
import set from 'lodash/set'
import every from 'lodash/every'
import isNumber from 'lodash/isNumber'
import { useUpdateUserPreferences } from 'apis'

export default function useTableColumnsPref(
  initialUserPrefs,
  path,
  columnMap,
  orgId,
) {
  const { mutateAsync: updateUserPreferences } = useUpdateUserPreferences()
  const pref = get(initialUserPrefs, path)
  const initialToggles =
    pref && every(pref, isNumber) // quick sanity check
      ? columnMap.numsToStrings(pref)
      : undefined
  const [toggles, setToggles] = React.useState(initialToggles)

  const handleTogglesChange = React.useCallback(
    (value) => {
      // Update UI first
      setToggles(value)

      if (!orgId) {
        return
      }
      // Update user preferences
      const preferences = { organization_id: orgId }
      set(preferences, path, columnMap.stringsToNums(value))
      updateUserPreferences({ data: { preferences } }).catch((error) =>
        console.error(error),
      )
    },
    [orgId, columnMap, path],
  )

  return [toggles, handleTogglesChange]
}
