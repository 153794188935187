import styled from 'styled-components'

export const NoWrapCell = styled.span`
  white-space: nowrap;
`
export const StyledTable = styled.table.attrs({ className: 'table' })`
  margin-bottom: 0;
  border-top: 2px solid #ddd;

  td,
  th {
    text-align: right;
  }
  td:first-child,
  th:first-child {
    text-align: left;
  }
`
