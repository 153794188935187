import config from 'config'

export const queryParamsConfig = {
  page: {
    type: Number,
    defaultValue: 1,
  },
  sizePerPage: {
    queryKey: 'per_page',
    type: Number,
    defaultValue: config.collectionQueryPageLimit.default,
  },
  sortField: {
    queryKey: 'ordered_by',
  },
  sortOrder: {
    queryKey: 'direction',
  },
  searchText: {
    queryKey: 'q',
    defaultValue: '',
  },
  status: {
    defaultValue: '',
  },
}
