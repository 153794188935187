import React from 'react'
import PropTypes from 'prop-types'
import { Menu } from '@headlessui/react'
import { Float } from '@headlessui-float/react'
import Button from 'components-v2/atoms/Button'

const MenuButtonBase = React.forwardRef(
  ({ forwardedAs: Component = Button, ...props }, ref) => (
    <Component
      ref={ref}
      {...props}
      active={props['data-headlessui-state'] === 'open' || props.active}
    />
  ),
)

MenuButtonBase.propTypes = {
  forwardedAs: PropTypes.elementType,
  active: PropTypes.bool,
  'data-headlessui-state': PropTypes.string,
}

const MenuButton = React.forwardRef((props, ref) => (
  <Float.Reference>
    <Menu.Button
      {...props}
      as={MenuButtonBase}
      forwardedAs={props.as}
      ref={ref}
    />
  </Float.Reference>
))

MenuButton.propTypes = {
  as: PropTypes.elementType,
}

export default MenuButton
