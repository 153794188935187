import { useCallback } from 'react'
import { useModal } from 'hooks'
import IntegrationMetricsModal from './IntegrationMetricsModal'

export default function useViewIntegrationMetrics(formattedIntegrationMetrics) {
  const [openModal] = useModal(IntegrationMetricsModal)
  return useCallback(
    (metricType, metricKeys) => {
      openModal({
        metricType,
        metricKeys,
        formattedIntegrationMetrics,
      })
    },
    [formattedIntegrationMetrics, openModal],
  )
}
