import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
`

export const Counter = styled.span`
  position: absolute;
  bottom: -12px;
  right: 0px;
  font-size: 11px;
  line-height: 1;
  color: #c0c0c0;

  ${({ visibleOnFocusOnly }) =>
    visibleOnFocusOnly &&
    `
    opacity: 0;
    transition: opacity 0.1s ease-in-out;

    textarea:focus + &,
    input:focus + & {
      opacity: 1;
    }
  `}
`
