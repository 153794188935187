import { useState, useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import {
  useAutoAnswerVendorAssessment,
  useVendorAssessmentSectionFetcher,
  useVendorAssessmentElementsInfiniteFetcher,
} from 'apis'

export default function useAutoAnswer(assessmentId, setAssessment) {
  const queryClient = useQueryClient()
  const [dismissed, setDimissed] = useState(false)
  const {
    data,
    isError,
    mutateAsync: autoAnswerVendorAssessment,
  } = useAutoAnswerVendorAssessment()

  const confirm = useCallback(
    (vendorIds) => {
      autoAnswerVendorAssessment({
        id: assessmentId,
        data: { vendor_ids: vendorIds },
      })
        .then(({ progress }) => {
          setAssessment((current) => ({ ...current, progress }))
          queryClient.invalidateQueries(
            useVendorAssessmentSectionFetcher.queryKey(),
            {
              predicate: (query) =>
                query.queryKey[1].assessmentId === assessmentId,
            },
          )
          queryClient.invalidateQueries(
            useVendorAssessmentElementsInfiniteFetcher.queryKey(),
            {
              predicate: (query) =>
                query.queryKey[1].assessmentId === assessmentId,
            },
          )
        })
        .catch((err) => {
          console.error(err)
        })
    },
    [assessmentId, autoAnswerVendorAssessment, queryClient],
  )

  const close = useCallback(() => {
    setDimissed(true)
  }, [])

  return {
    dismissed,
    data,
    isError,
    confirm,
    close,
  }
}
