import styled from 'styled-components'
import GradeLabel from 'components/shared/GradeLabel'

export const Grade = styled(GradeLabel)`
  display: block;
  font-size: 14px;
  font-weight: bold;
  margin-top: 0.8em;
  line-height: 1em;
`

export const Status = styled.span`
  font-size: 36px;
  opacity: 0.75;
`
