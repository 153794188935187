import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import Dialog from 'components-v2/molecules/Dialog'
import Button from 'components-v2/atoms/Button'
import Icon from 'components-v2/atoms/Icon'
import FormField from 'components-v2/molecules/FormField'
import {
  buildCustomFieldConfig,
  getFormFieldValue,
  getFormFieldValidation,
  getFormFieldProps,
} from 'components/client/vendor_info_view/utils'

const FieldPreviewModal = ({ fieldDef, onClose, ...rest }) => {
  const fieldConfig = useMemo(
    () => buildCustomFieldConfig(fieldDef),
    [fieldDef],
  )
  const initialValues = useMemo(
    () => ({
      [fieldConfig.name]: getFormFieldValue(fieldConfig, {}),
    }),
    [fieldConfig],
  )
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        [fieldConfig.name]: getFormFieldValidation(fieldConfig),
      }),
    [fieldConfig],
  )
  return (
    <Dialog {...rest} onClose={onClose}>
      <Dialog.Header onClose={onClose}>Preview</Dialog.Header>
      <Dialog.Content>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={() => {}}
        >
          <Form>
            <FormField {...getFormFieldProps(fieldConfig)} horizontal />
          </Form>
        </Formik>
      </Dialog.Content>
      <Dialog.Footer>
        <Button color="primary" onClick={onClose}>
          <Icon icon="fa fa-pencil" />
          Edit
        </Button>
      </Dialog.Footer>
    </Dialog>
  )
}

FieldPreviewModal.propTypes = {
  fieldDef: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default FieldPreviewModal
