import React from 'react'
import { useGlobalLoader } from 'hooks'
import NotificationManager from 'lib/notifications'
import { unflatten } from 'utils/object'
import { useCreateTwoFactorAuth } from 'apis'

/**
 * Custom hook that provides functionality to handle two-factor authentication (2FA) operations,
 * enabling, and managing backup codes.
 *
 * @returns {Object} An object containing the following properties and functions:
 * - `codes {Array}`: Backup codes generated during 2FA registration.
 * - `registered {boolean}`: Indicates whether two-factor authentication is registered.
 * - `registerTwoFactorAuth {Function}`: Handles the registration process for 2FA.
 *
 * @example
 * const { registered, registerTwoFactorAuth, codes } = useTwoFactorAuthMutations();
 *
 * // Register 2FA
 * registerTwoFactorAuth(formData, { setErrors });
 */

export default function useTwoFactorAuthMutations() {
  const [showLoader, hideLoader] = useGlobalLoader()
  const { mutateAsync: create } = useCreateTwoFactorAuth()
  const [registered, setRegistered] = React.useState(false)
  const [codes, setCodes] = React.useState([])

  const registerTwoFactorAuth = React.useCallback(
    async (formData, { setErrors }) => {
      const loaderId = showLoader()
      try {
        const res = await create({
          data: formData,
        })
        setRegistered(true)
        setCodes(res.codes)
      } catch (error) {
        if (error.response.status === 422) {
          setErrors(unflatten(error.response.data.errors))
        } else {
          NotificationManager.error()
        }
      }
      hideLoader(loaderId)
    },
    [create, showLoader, hideLoader],
  )

  return {
    codes,
    registered,
    registerTwoFactorAuth,
  }
}
