import { useCallback } from 'react'
import NotificationManager from 'lib/notifications'
import { useModal, useGlobalLoader, useConfirm } from 'hooks'
import { unflatten } from 'utils/object'
import {
  useCreateClientWatchlist,
  useUpdateClientWatchlist,
  useDeleteClientWatchlist,
} from 'apis'
import WatchlistModal from './WatchlistModal'

export default function useCRUDWatchlist() {
  const [openModal, closeModal] = useModal(WatchlistModal)
  const [showLoader, hideLoader] = useGlobalLoader()
  const confirm = useConfirm()
  const { mutateAsync: createClientWatchlist } = useCreateClientWatchlist()
  const { mutateAsync: updateClientWatchlist } = useUpdateClientWatchlist()
  const { mutateAsync: deleteClientWatchlist } = useDeleteClientWatchlist()

  const createWatchlist = useCallback(
    (onCreated) => {
      const onSubmit = async ({ name }, { setErrors }) => {
        const loaderId = showLoader()
        try {
          const data = await createClientWatchlist({ data: { name } })
          if (onCreated) {
            onCreated(data)
          }
          NotificationManager.success(
            'A new watchlist has successfully been created.',
          )
          closeModal()
        } catch (error) {
          if (error.response.status === 422) {
            if (error.response.data.errors) {
              setErrors(unflatten(error.response.data.errors))
            } else {
              NotificationManager.error(error.response.data.error)
            }
          } else {
            NotificationManager.error()
          }
        }
        hideLoader(loaderId)
      }
      openModal({ title: 'Create a Watchlist', onSubmit })
    },
    [createClientWatchlist, openModal, closeModal, showLoader, hideLoader],
  )

  const updateWatchlist = useCallback(
    (watchlists, watchlistId) => {
      const watchlist = watchlists?.find((e) => e.id === watchlistId)
      if (!watchlist) {
        return
      }

      const onSubmit = async ({ name }, { setErrors }) => {
        const loaderId = showLoader()
        try {
          await updateClientWatchlist({
            id: watchlistId,
            data: { name },
          })
          NotificationManager.success(
            'The watchlist has been successfully updated.',
          )
          closeModal()
        } catch (error) {
          if (error.response.status === 422) {
            if (error.response.data.errors) {
              setErrors(unflatten(error.response.data.errors))
            } else {
              NotificationManager.error(error.response.data.error)
            }
          } else {
            NotificationManager.error()
          }
        }
        hideLoader(loaderId)
      }
      openModal({ title: 'Edit Watchlist', name: watchlist.name, onSubmit })
    },
    [updateClientWatchlist, openModal, closeModal, showLoader, hideLoader],
  )

  const deleteWatchlist = useCallback(
    async (watchlistId) => {
      const confirmed = await confirm(
        {
          title: 'Delete this watchlist?',
        },
        true,
      )
      if (!confirmed) {
        return
      }
      const loaderId = showLoader()
      try {
        await deleteClientWatchlist({ id: watchlistId })
        NotificationManager.success(
          'The watchlist has been successfully deleted.',
        )
      } catch (error) {
        NotificationManager.error()
      }
      hideLoader(loaderId)
    },
    [deleteClientWatchlist, showLoader, hideLoader],
  )

  return { createWatchlist, updateWatchlist, deleteWatchlist }
}
