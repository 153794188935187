import React from 'react'
import img from 'images/error/wrench-gear.png'
import ErrorView from '../shared/ErrorView'

export default () => (
  <ErrorView
    imageUrl={img}
    errorCode={406}
    errorType="Not Acceptable"
    errorMessage="An appropriate representation of the requested resource could not be found on this server."
  />
)
