import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import A from 'components-v2/atoms/A'
import Button from 'components-v2/atoms/Button'
import Table from 'components-v2/molecules/Table'
import Toolbar, {
  SearchInput,
  ColumnToggler,
} from 'components-v2/molecules/Table/Toolbar'
import { applyColumnToggles } from 'lib/table'
import { SortOrderTypes } from 'constants/index'
import { useAuthorizations } from 'hooks'
import { toLocalTimeString } from 'utils/date'
import VendorPartnerLinkModal from './VendorPartnerLinkModal'
import { getBitSightRatingColor, getVendorPageUrl } from './utils'
import { COL_NAMES } from './constants'
import {
  CircleRate,
  CogIcon,
  CompanyLink,
  EnvelopeIcon,
  ErrorButtonsWrapper,
  ErrorContainer,
  ErrorDescription,
  ErrorTitle,
  ExternalLinkIcon,
  RoundedRate,
} from './styles'

const vendorLinkClick = (vendorId) => {
  window.location.assign(getVendorPageUrl(vendorId, 'issues'))
}

const circleRateCell = (colorKey) => (info) =>
  (
    <CircleRate
      small={info.getValue() === 'N/A'}
      color={get(info.row.original, colorKey)}
    >
      {info.getValue()}
    </CircleRate>
  )

const getTableColumns = (partnerInfo) => [
  {
    id: COL_NAMES.NAME,
    header: 'Company',
    cell: (info) => (
      <CompanyLink
        href={info.row.original.display_url}
        title={info.getValue()}
        target="_blank"
      >
        <span>{info.getValue()}</span>
      </CompanyLink>
    ),
    width: '18%',
    minWidth: 60,
  },
  {
    id: COL_NAMES.INDUSTRY,
    header: 'Industry',
    width: '18%',
    minWidth: 160,
  },
  {
    id: COL_NAMES.RATING_DATE,
    header: 'Last Updated',
    cell: (info) => (
      <span>{toLocalTimeString(info.getValue(), 'MM/dd/yyyy')}</span>
    ),
    width: '14%',
    minWidth: 80,
  },
  {
    id: COL_NAMES.RATING,
    header: 'BitSight Rating',
    cell: (info) => (
      <RoundedRate color={getBitSightRatingColor(info.getValue())}>
        {info.getValue()}
      </RoundedRate>
    ),
    width: '10%',
    minWidth: 80,
  },
  {
    id: COL_NAMES.RATING_DETAILS_DATA_BREACHES_GRADE,
    header: 'Data Breaches',
    cell: circleRateCell('rating_details.data_breaches.grade_color'),
    width: '10%',
    minWidth: 80,
  },
  {
    id: COL_NAMES.RATING_DETAILS_BOTNET_INFECTIONS_GRADE,
    header: 'Botnet Infections',
    cell: circleRateCell('rating_details.botnet_infections.grade_color'),
    width: '10%',
    minWidth: 80,
  },
  {
    id: COL_NAMES.RATING_DETAILS_INSECURE_SYSTEMS_GRADE,
    header: 'Insecure Systems',
    cell: circleRateCell('rating_details.insecure_systems.grade_color'),
    width: '10%',
    minWidth: 80,
  },
  {
    id: COL_NAMES.RATING_DETAILS_SPAM_PROPAGATION_GRADE,
    header: 'Spam Propagation',
    cell: circleRateCell('rating_details.spam_propagation.grade_color'),
    width: '10%',
    minWidth: 120,
    hidden: true,
  },
  {
    id: COL_NAMES.RATING_DETAILS_DESKTOP_SOFTWARE_GRADE,
    header: 'Desktop Software',
    cell: circleRateCell('rating_details.desktop_software.grade_color'),
    width: '10%',
    hidden: true,
  },
  {
    id: COL_NAMES.RATING_DETAILS_SSL_CONFIGURATIONS_GRADE,
    header: 'SSL Configurations',
    cell: circleRateCell('rating_details.ssl_configurations.grade_color'),
    width: 120,
    hidden: true,
  },
  {
    id: COL_NAMES.RATING_DETAILS_MOBILE_SOFTWARE_GRADE,
    header: 'Mobile Software',
    cell: circleRateCell('rating_details.mobile_software.grade_color'),
    width: 120,
    hidden: true,
  },
  {
    id: COL_NAMES.RATING_DETAILS_DNSSEC_GRADE,
    header: 'DNSSEC',
    cell: circleRateCell('rating_details.dnssec.grade_color'),
    width: 120,
    hidden: true,
  },
  {
    id: COL_NAMES.RATING_DETAILS_MOBILE_APPLICATION_SECURITY_GRADE,
    header: 'Mobile Application Security',
    cell: circleRateCell(
      'rating_details.mobile_application_security.grade_color',
    ),
    width: 120,
    hidden: true,
  },
  {
    id: COL_NAMES.RATING_DETAILS_SSL_CERTIFICATES_GRADE,
    header: 'SSL Certificates',
    cell: circleRateCell('rating_details.ssl_certificates.grade_color'),
    width: 120,
    hidden: true,
  },
  {
    id: COL_NAMES.RATING_DETAILS_SERVER_SOFTWARE_GRADE,
    header: 'Server Software',
    cell: circleRateCell('rating_details.server_software.grade_color'),
    width: 120,
    hidden: true,
  },
  {
    id: COL_NAMES.RATING_DETAILS_PATCHING_CADENCE_GRADE,
    header: 'Patching Cadence',
    cell: circleRateCell('rating_details.patching_cadence.grade_color'),
    width: 120,
    hidden: true,
  },
  {
    id: COL_NAMES.RATING_DETAILS_POTENTIALLY_EXPLOITED_GRADE,
    header: 'Potentially Exploited',
    cell: circleRateCell('rating_details.potentially_exploited.grade_color'),
    width: 120,
    hidden: true,
  },
  {
    id: COL_NAMES.RATING_DETAILS_OPEN_PORTS_GRADE,
    header: 'Open Ports',
    cell: circleRateCell('rating_details.open_ports.grade_color'),
    width: 120,
    hidden: true,
  },
  {
    id: COL_NAMES.RATING_DETAILS_APPLICATION_SECURITY_GRADE,
    header: 'Application Security',
    cell: circleRateCell('rating_details.application_security.grade_color'),
    width: 120,
    hidden: true,
  },
  {
    id: COL_NAMES.RATING_DETAILS_MALWARE_SERVERS_GRADE,
    header: 'Malware Servers',
    cell: circleRateCell('rating_details.malware_servers.grade_color'),
    width: 120,
    hidden: true,
  },
  {
    id: COL_NAMES.RATING_DETAILS_FILE_SHARING_GRADE,
    header: 'File Sharing',
    cell: circleRateCell('rating_details.file_sharing.grade_color'),
    width: 120,
    hidden: true,
  },
  {
    id: COL_NAMES.RATING_DETAILS_UNSOLICITED_COMM_GRADE,
    header: 'Unsolicited Communications',
    cell: circleRateCell('rating_details.unsolicited_comm.grade_color'),
    width: 120,
    hidden: true,
  },
  {
    id: COL_NAMES.RATING_DETAILS_DKIM_GRADE,
    header: 'DKIM',
    cell: circleRateCell('rating_details.dkim.grade_color'),
    width: 120,
    hidden: true,
  },
  {
    id: COL_NAMES.RATING_DETAILS_SPF_GRADE,
    header: 'SPF',
    cell: circleRateCell('rating_details.spf.grade_color'),
    width: 120,
    hidden: true,
  },
  {
    id: COL_NAMES.PRIVVA_ISSUES,
    header: 'Issues',
    cell: (info) => (
      <VendorPartnerLinkModal
        vendorLinkOnClick={vendorLinkClick}
        vendorId={get(info.row.original, 'privva_issues.vendor_id')}
        vendorLinkText={get(
          info.row.original,
          'privva_issues.count',
          'View',
        ).toString()}
        partnerId={partnerInfo.id}
        partnerName={partnerInfo.type}
        partnerVendorId={info.row.original.guid}
        partnerVendorName={info.row.original.name}
      />
    ),
    width: '10%',
    minWidth: 75,
  },
]

const View = ({
  orgId,
  feed,
  loading,
  error,
  searchText,
  integrationManagementPath,
  supportPath,
  partnerInfo,
  columnToggles,
  onColumnTogglesChange,
  onSearch,
}) => {
  const currOrg = { class: 'Organization', id: orgId }
  const [canManageClient] = useAuthorizations(['manage', currOrg])
  const columns = React.useMemo(
    () => getTableColumns(partnerInfo),
    [partnerInfo],
  )

  const finalColumns = React.useMemo(
    () => applyColumnToggles(columns, columnToggles),
    [columns, columnToggles],
  )

  return (
    <div>
      <Toolbar>
        <SearchInput
          // eslint-disable-next-line react/prop-types
          value={searchText}
          // eslint-disable-next-line react/prop-types
          onChange={onSearch}
          placeholder="Search for a company..."
        />
        <Toolbar.Group>
          {canManageClient && (
            <A href={integrationManagementPath} underline>
              Manage BitSight integration
            </A>
          )}
          <ColumnToggler
            columns={columns}
            selected={columnToggles}
            onToggle={onColumnTogglesChange}
          />
          {canManageClient && (
            <Button href={partnerInfo.login_url} target="_blank">
              <ExternalLinkIcon /> Login to BitSight
            </Button>
          )}
        </Toolbar.Group>
      </Toolbar>
      {error ? (
        <ErrorContainer>
          <ErrorTitle>Something&apos;s not working.</ErrorTitle>
          <ErrorDescription>
            Looks like something went wrong with our integration.
          </ErrorDescription>
          <ErrorButtonsWrapper>
            <Button color="primary" href={integrationManagementPath}>
              <CogIcon /> Manage BitSight integration
            </Button>
            <Button
              variant="outlined"
              color="primary"
              href={supportPath}
              target="_blank"
            >
              <EnvelopeIcon /> Contact the Support Team
            </Button>
          </ErrorButtonsWrapper>
        </ErrorContainer>
      ) : (
        <Table
          loading={loading}
          data={feed ? feed.companies : []}
          columns={finalColumns}
          defaultSorting={{
            sortField: 'name',
            sortOrder: SortOrderTypes.ASC,
          }}
          enablePagination={false}
          globalFilter={searchText}
          onGlobalFilterChange={onSearch}
          getRowId={(row) => row.guid}
          noDataText="Looks like you're not tracking any companies yet."
          type={Table.types.ALT}
        />
      )}
    </div>
  )
}

View.propTypes = {
  orgId: PropTypes.string,
  feed: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  searchText: PropTypes.string,
  integrationManagementPath: PropTypes.string.isRequired,
  supportPath: PropTypes.string.isRequired,
  partnerInfo: PropTypes.object.isRequired,
  columnToggles: PropTypes.array,
  onColumnTogglesChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
}

export default View
