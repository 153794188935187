import React from 'react'
import { useVendorIssuesFetcher } from 'apis'
import { compose, withAuthorize, withReactQuery } from 'hocs'
import { useQueryParams } from 'hooks'
import { queryParamsConfig } from './constants'
import View from './View'

const VendorIssues = () => {
  const [queryParams, setQueryParams] = useQueryParams(queryParamsConfig)
  const { page, sizePerPage, sortField, sortOrder, searchText, status } =
    queryParams
  const {
    data: issues = [],
    totalSize,
    isFetching,
  } = useVendorIssuesFetcher({
    params: {
      page,
      per_page: sizePerPage,
      search_query: searchText,
      ordered_by: sortField,
      direction: sortOrder,
      status,
    },
  })

  const handleSearchChange = React.useCallback((newSearchText) => {
    // Let's reset page to 1
    setQueryParams({
      page: 1,
      searchText: newSearchText,
    })
  }, [])

  const handleStatusFilterChange = React.useCallback((event) => {
    // Let's reset page to 1
    setQueryParams({
      page: 1,
      status: event.target.value,
    })
  }, [])

  return (
    <View
      issues={issues}
      loading={isFetching}
      totalSize={totalSize}
      page={page}
      sizePerPage={sizePerPage}
      sortField={sortField}
      sortOrder={sortOrder}
      searchText={searchText}
      statusFilter={status}
      onPaginationChange={setQueryParams}
      onSortingChange={setQueryParams}
      onSearchChange={handleSearchChange}
      onStatusFilterChange={handleStatusFilterChange}
    />
  )
}

export default compose(withAuthorize(), withReactQuery())(VendorIssues)
