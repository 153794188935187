import styled from 'styled-components'
import Well from 'components-v2/atoms/Well'

export const KeyCode = styled.span`
  font-family: monospace;

  span:nth-child(4n) {
    padding-right: 0.5em;
  }
`

export const FieldWrapper = styled.div`
  max-width: 300px;
`

export const Codes = styled(Well)`
  font-family: monospace;
`
