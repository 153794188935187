import styled from 'styled-components'
import Card from 'components-v2/organisms/Card'
import { colors } from 'styles'

export const Wrapper = styled(Card).attrs({ padding: true, bordered: true })`
  position: relative;
`

export const Title = styled.h3`
  color: ${colors.HEADING};
  margin: 0 0 20px;
`
