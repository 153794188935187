import { useCallback, useEffect } from 'react'
import { useGlobalLoader, useModal } from 'hooks'
import NotificationManager from 'lib/notifications'
import {
  useAssistQuestionSetFetcher,
  useUpdateAssistQuestionSet,
  useAssistQuestionSetScorerTypesFetcher,
  useAssistQuestionSetScorerModelsFetcher,
} from 'apis'
import QuestionSetSettingsModal from './QuestionSetSettingsModal'

export default function useEditQuestionSetSettings(questionSetId) {
  const [showLoader, hideLoader] = useGlobalLoader()
  const [openModal, closeModal, updateModal] = useModal(
    QuestionSetSettingsModal,
  )
  const { data: questionSet } = useAssistQuestionSetFetcher({
    id: questionSetId,
  })
  const { data: scorerTypes } = useAssistQuestionSetScorerTypesFetcher()
  const { data: scorerModels } = useAssistQuestionSetScorerModelsFetcher()
  const { mutateAsync: updateAssistQuestionSet } = useUpdateAssistQuestionSet()

  useEffect(() => {
    updateModal({ scorerTypes, scorerModels })
  }, [updateModal, scorerTypes, scorerModels])

  return useCallback(() => {
    if (!questionSet) {
      return
    }
    const onSubmit = (formData) => {
      const loaderId = showLoader()
      updateAssistQuestionSet({
        id: questionSet.id,
        data: {
          question_set: {
            scorer_type: formData.scorerType,
            scorer_model: formData.scorerModel,
          },
        },
      })
        .then(() => closeModal())
        .catch(() => NotificationManager.error())
        .then(() => hideLoader(loaderId))
    }
    openModal({
      questionSet,
      scorerTypes,
      scorerModels,
      onSubmit,
    })
  }, [
    openModal,
    closeModal,
    questionSet,
    scorerTypes,
    scorerModels,
    updateAssistQuestionSet,
    showLoader,
    hideLoader,
  ])
}
