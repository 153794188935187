import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import config from 'config'
import { useClientVendorsFetcher, useUsersFetcher } from 'apis'
import useEditUser from './useEditUser'
import useInviteUser from './useInviteUser'
import useShowAccessRoleInformation from './useShowAccessRoleInformation'
import { parseRawUserData, getPermittedOrgRoles } from './helpers'
import View from './View'

const Container = ({ isClient, currentOrgRole, currentUserId, ...props }) => {
  const { data: vendors } = useClientVendorsFetcher(
    {
      params: { exclude_archived: false },
    },
    { enabled: isClient },
  )
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: config.collectionQueryPageLimit.default,
  })
  const [sorting, setSorting] = useState()
  const [searchText, setSearchText] = useState('')
  const {
    data: users,
    totalSize,
    isFetching,
  } = useUsersFetcher({
    params: {
      extended: true,
      per_page: pagination.sizePerPage,
      page: pagination.page,
      ordered_by: sorting?.sortField,
      direction: sorting?.sortOrder,
      search_query: searchText,
    },
  })
  const permittedOrgRoles = useMemo(
    () => getPermittedOrgRoles(currentOrgRole),
    [currentOrgRole],
  )
  const editUser = useEditUser(
    vendors,
    currentUserId,
    permittedOrgRoles,
    isClient,
  )
  const inviteUser = useInviteUser(isClient, vendors, permittedOrgRoles)
  const showAccessRoleInformation = useShowAccessRoleInformation()
  const parsedUsers = useMemo(() => users?.map(parseRawUserData), [users])

  const handleSearchChange = useCallback((value) => {
    setSearchText(value)
    setPagination((current) => ({ ...current, page: 1 }))
  }, [])

  return (
    <View
      {...props}
      users={parsedUsers}
      searchText={searchText}
      loading={isFetching}
      totalSize={totalSize}
      pagination={pagination}
      sorting={sorting}
      isClient={isClient}
      permittedOrgRoles={permittedOrgRoles}
      onClickInviteBtn={inviteUser}
      onClickAccessRoleExplainer={showAccessRoleInformation}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      onSearchChange={handleSearchChange}
      onEditUser={editUser}
    />
  )
}

Container.propTypes = {
  isClient: PropTypes.bool,
  ssoActive: PropTypes.bool,
  currentUserId: PropTypes.string.isRequired,
  currentOrgRole: PropTypes.string.isRequired,
}

export default Container
