import React from 'react'
import PropTypes from 'prop-types'
import Privva from 'Privva'
import AutoLink from 'components-v2/atoms/AutoLink'
import Tooltip from 'components-v2/molecules/Tooltip'
import queryString from 'query-string'
import {
  Wrapper,
  Title,
  PoweredBy,
  SourceImg,
  ImageLink,
  TooltipWrapper,
} from './styles'

const SectionTitle = ({
  title,
  sourceLogo,
  sourceUrl,
  sourceLogoWidth,
  information,
}) => {
  const logoImg = sourceLogo ? (
    <SourceImg src={sourceLogo} width={sourceLogoWidth} />
  ) : null

  const urlOnly = sourceUrl ? queryString.parseUrl(sourceUrl).url : sourceUrl

  return (
    <Wrapper>
      <Title>{title}</Title>
      {sourceLogo && (
        <>
          <PoweredBy>Powered by</PoweredBy>
          {!sourceUrl ? (
            logoImg
          ) : (
            <ImageLink href={sourceUrl} target="_blank">
              {logoImg}
            </ImageLink>
          )}
        </>
      )}
      {information && (
        <TooltipWrapper>
          <Tooltip>
            <AutoLink text={information} />
          </Tooltip>
        </TooltipWrapper>
      )}
      {!information && sourceUrl && (
        <TooltipWrapper>
          <Tooltip>
            For more information, talk to your {Privva.Utils.brand.programName}{' '}
            representative or visit{' '}
            <a href={sourceUrl} target="_blank">
              {urlOnly}
            </a>
          </Tooltip>
        </TooltipWrapper>
      )}
    </Wrapper>
  )
}

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  sourceLogo: PropTypes.string,
  sourceUrl: PropTypes.string,
  sourceLogoWidth: PropTypes.number,
  information: PropTypes.string,
}

export default SectionTitle
