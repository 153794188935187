import styled from 'styled-components'
import { Row, Key, QuestionColumn, DragSourceColumn } from '../styles'

export const Wrapper = styled(Row)`
  background: #eee;
`

export const StyledKey = styled(Key)`
  color: #333;
  font-size: 120%;
  font-weight: bold;
`

export const LabelColumn = styled(QuestionColumn)`
  color: #333;
  font-size: 120%;
  font-weight: bold;
`

export const StyledDragSourceColumn = styled(DragSourceColumn)`
  align-items: center;
`
