import React from 'react'
import PropTypes from 'prop-types'
import Avatar from 'react-avatar-edit'
import {
  Button,
  ButtonsWrapper,
  EditWrapper,
  ExistingButtonsWrapper,
  Preview,
  PreviewImage,
  PreviewWrap,
} from './styles'

const AvatarField = ({ avatarUrl, onSave, onDelete }) => {
  const [preview, setPreview] = React.useState()
  const [editing, setEditing] = React.useState(false)
  const editorRef = React.useRef()
  const editingEx = editing || !avatarUrl

  const handleClose = React.useCallback(() => {
    setPreview()
    setEditing(false)
  }, [])

  const handleEditClick = React.useCallback(() => {
    setEditing(true)
  }, [])

  const handleCancel = React.useCallback(() => {
    editorRef.current?.onCloseClick()
  }, [])

  const handleSave = () => {
    onSave(preview)
    handleCancel()
  }

  if (editingEx) {
    return (
      <div>
        <EditWrapper>
          <Avatar
            ref={editorRef}
            label="Upload Picture"
            width={200}
            height={200}
            onCrop={setPreview}
            onClose={handleClose}
            imageWidth={128}
          />
        </EditWrapper>
        {preview && (
          <PreviewWrap>
            <Preview>
              <PreviewImage src={preview} />
            </Preview>
          </PreviewWrap>
        )}
        <ButtonsWrapper>
          <Button
            color="primary"
            size="small"
            disabled={!preview}
            onClick={handleSave}
          >
            <i className="fa fa-check" />
          </Button>
          <Button size="small" onClick={handleCancel}>
            <i className="fa fa-times" />
          </Button>
        </ButtonsWrapper>
      </div>
    )
  }
  return (
    <div>
      <Preview>
        <PreviewImage src={avatarUrl} />
      </Preview>
      <ExistingButtonsWrapper>
        <Button size="small" onClick={handleEditClick}>
          <i className="fa fa-pencil" />
        </Button>
        <Button size="small" onClick={onDelete}>
          <i className="fa fa-trash" />
        </Button>
      </ExistingButtonsWrapper>
    </div>
  )
}

AvatarField.propTypes = {
  avatarUrl: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default AvatarField
