import styled from 'styled-components'
import { colors, mixins } from 'styles'

export const RatingLabel = styled.span`
  ${(props) =>
    props.$isEmpty &&
    `
    font-style: italic;
  `}

  color: ${(props) => (props.$isEmpty ? colors.DANGER : colors.BLUE)};
`

export const PopoverButton = styled.button`
  ${mixins.clearButtonStyle}
  color: ${colors.BLUE};
  border-bottom: 1px dashed transparent;

  ${(props) =>
    props.$isEmpty &&
    `
    font-style: italic;
    color: ${colors.DANGER};
  `}

  &:hover,
  &:active {
    border-bottom-color: currentColor;
    background: lightyellow;
  }
`

export const PanelContent = styled.div`
  padding: 9px 14px;
  width: 535px;
  max-width: 90vw;
`
