import React from 'react'
import PropTypes from 'prop-types'
import { GaugeChart } from 'components-v2/molecules/Charts'
import { colors } from 'styles'
import { toFromNowString } from 'utils/date'
import { RISK_HEALTH_SCORE_MAX } from '../constants'
import { getRiskHealthScoreColor } from '../../utils'
import {
  Wrapper,
  Title,
  ScoreLabel,
  ChartWrapper,
  Score,
  UpdatedAt,
} from './styles'

const colorArr = [colors.RED, colors.YELLOW, colors.GREEN]

const RiskHealthCard = ({ data: { label, score }, updatedAt, ...rest }) => (
  <Wrapper {...rest} padding>
    <Title>Average Risk Health</Title>
    <ScoreLabel>{label}</ScoreLabel>
    <ChartWrapper>
      <GaugeChart
        value={score}
        max={RISK_HEALTH_SCORE_MAX}
        stepCount={25}
        colors={colorArr}
        width="100%"
        height={155}
        outerRadius={110}
        innerRadius={80}
        cy={110}
      />
      <Score color={getRiskHealthScoreColor(score)}>{score}</Score>
    </ChartWrapper>
    <UpdatedAt>
      Updated {toFromNowString(updatedAt, { addSuffix: true })}
    </UpdatedAt>
  </Wrapper>
)

RiskHealthCard.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string.isRequired,
    score: PropTypes.number.isRequired,
  }),
  updatedAt: PropTypes.any.isRequired,
}

export default React.memo(RiskHealthCard)
