import styled from 'styled-components'
import { colors } from 'styles'
import withTransition from './withTransition'

const getTimeout = (timeout, state) => {
  if (typeof timeout !== 'object') {
    return timeout
  }
  if (state === 'appearing' || state === 'appeared') {
    return timeout.appear
  }
  if (state === 'entering' || state === 'entered') {
    return timeout.enter
  }
  if (state === 'exiting' || state === 'exited') {
    return timeout.exit
  }
  return 0
}

export const FadeTransition = withTransition(styled.div`
  transition: opacity ${(p) => getTimeout(p.timeout, p.state)}ms ease-in-out;
  opacity: ${(p) => (p.state === 'entering' || p.state === 'entered' ? 1 : 0)};
`)

export const HighlightTransition = withTransition(styled.div`
  transition: background-color ${(p) => getTimeout(p.timeout, p.state)}ms
    ease-in-out;
  background-color: ${(p) =>
    p.state === 'entering' || p.state === 'entered'
      ? colors.LEMON
      : 'transparent'};
`)

export default {
  Fade: FadeTransition,
  Highlight: HighlightTransition,
}
