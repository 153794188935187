import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import map from 'lodash/map'
import without from 'lodash/without'
import includes from 'lodash/includes'
import concat from 'lodash/concat'
import { IssuePriorityOptions } from 'constants/index'
import Well from 'components-v2/atoms/Well'
import FormField, {
  BaseField,
  ControlLabel,
} from 'components-v2/molecules/FormField'
import AutoSizeTextarea from 'components-v2/atoms/AutoSizeTextarea'
import FormDialog from 'components-v2/organisms/FormDialog'

import {
  FileList,
  FileRow,
  FileItem,
  FileIcon,
  DeleteFileIcon,
  HiddenDelete,
} from '../../styles'

// eslint-disable-next-line react/prop-types
const AutoSizeTextAreaField = ({ name, value, onChange, ...rest }) => (
  <BaseField {...rest}>
    <AutoSizeTextarea
      name={name}
      value={value || ''}
      onChange={(e) => onChange(e.currentTarget.value)}
    />
  </BaseField>
)

AutoSizeTextAreaField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
}

const DeletableFilesField = ({ name, value, files, onChange, ...rest }) => {
  const handleFileDelete = React.useCallback(
    (fileIdx) => {
      onChange(
        includes(value, fileIdx)
          ? without(value, fileIdx)
          : concat(value, fileIdx),
      )
    },
    [onChange, value],
  )

  if (!files || files.length === 0) {
    return null
  }

  return (
    <BaseField {...rest}>
      <FileList>
        {map(files, (file) => (
          <FileRow key={file.id}>
            <FileItem
              href={file.url}
              title={file.full_name}
              deleted={includes(value, file.id)}
            >
              <FileIcon />
              {file.name}
            </FileItem>
            <HiddenDelete>
              <DeleteFileIcon onClick={() => handleFileDelete(file.id)} />
            </HiddenDelete>
          </FileRow>
        ))}
      </FileList>
    </BaseField>
  )
}

DeletableFilesField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  files: PropTypes.array,
  onChange: PropTypes.func,
}

const validationSchema = yup.object().shape({
  label: yup.string().label('Issue template name').required(),
  summary: yup.string().label('Summary').required(),
  description: yup.string().label('Description').required(),
  priority: yup.number().label('Priority').required(),
  // eslint-disable-next-line camelcase
  default_delay: yup.string().label('Days to respond').required(),
  // eslint-disable-next-line camelcase
  issue_category_id: yup.string().label('Category').nullable(),
})

const issueCategoryControlProps = {
  placeholder: 'Select or Create New...',
  noOptionsMessage: () => 'No categories found',
  formatCreateLabel: (i) => `Create new category "${i}"`,
  isClearable: true,
}

export const IssueStubFormModal = ({
  source,
  issueCategories,
  onSave,
  ...rest
}) => {
  const formConfig = React.useMemo(
    () => ({
      validationSchema,
      initialValues: {
        label: source.label,
        summary: source.summary,
        description: source.description,
        priority: source.priority,
        // eslint-disable-next-line camelcase
        default_delay: source.default_delay,
        // eslint-disable-next-line camelcase
        issue_category_id: source.issue_category_id,
        fileUploads: source.fileUploads || [],
        fileDeletes: source.fileDeletes || [],
      },
    }),
    [source],
  )

  const issueCategoryOptions = React.useMemo(
    () => map(issueCategories, (e) => ({ label: e.name, value: e.id })),
    [issueCategories],
  )

  const handleSubmit = React.useCallback(
    (formData, options) => {
      const payload = {
        ...formData,
        priority: formData.priority
          ? Number.parseInt(formData.priority, 10)
          : undefined,
      }
      return onSave(payload, options)
    },
    [onSave],
  )

  return (
    <FormDialog
      {...rest}
      title="New Issue Template"
      onSubmit={handleSubmit}
      submitButtonLabel="Apply"
      formConfig={formConfig}
    >
      <ControlLabel>Issue details to apply:</ControlLabel>
      <Well>
        <FormField name="label" label="Issue Template Name" horizontal />
        <FormField name="summary" label="Summary" horizontal />
        <FormField
          name="description"
          label="Description"
          component={AutoSizeTextAreaField}
          horizontal
        />

        <BaseField label="Attachments" horizontal>
          <FormField
            name="fileDeletes"
            files={source.attachments}
            component={DeletableFilesField}
          />
          <FormField
            name="fileUploads"
            type={FormField.types.FILES_INPUT}
            controlProps={{ maxFiles: 3 }}
          />
        </BaseField>

        <FormField
          name="priority"
          label="Priority"
          type={FormField.types.SELECT}
          options={IssuePriorityOptions}
          controlProps={{ style: { width: 196 } }}
          horizontal
        />
        <FormField
          name="default_delay"
          label="Days to respond"
          controlProps={{ style: { width: '50px', textAlign: 'right' } }}
          horizontal
        />
        <FormField
          name="issue_category_id"
          label="Category"
          type={FormField.types.CREATABLE_SELECT}
          options={issueCategoryOptions}
          controlProps={issueCategoryControlProps}
          horizontal
        />
      </Well>
    </FormDialog>
  )
}

IssueStubFormModal.propTypes = {
  source: PropTypes.object,
  issueCategories: PropTypes.array,
  onSave: PropTypes.func,
}

export default IssueStubFormModal
