import React from 'react'
import find from 'lodash/find'
import some from 'lodash/some'
import isBefore from 'date-fns/isBefore'
import addDays from 'date-fns/addDays'
import Icon from 'components-v2/atoms/Icon'
import config from 'config'
import { AssessmentStatusTypes } from 'constants/index'
import { toLocalTimeString, toDate } from 'utils/date'
import { Warning } from '../styles'

export const formatCell =
  ({ assessmentTemplates, assessments }) =>
  (info) => {
    if (!info.getValue()) {
      return ''
    }
    const str = toLocalTimeString(info.getValue(), config.tableDateFormat)
    if (!str) {
      return ''
    }
    const date = toDate(info.getValue())
    const warning =
      isBefore(date, addDays(new Date(), 3)) &&
      some(assessmentTemplates, (template) => {
        const assessment = find(assessments, {
          assessment_template_id: template.id,
          vendor_id: info.row.original.vendor.id,
        })
        return (
          assessment &&
          ![
            AssessmentStatusTypes.REVIEWED,
            AssessmentStatusTypes.SUBMITTED,
          ].includes(assessment.status)
        )
      })
    const overdue = warning && isBefore(date, new Date())
    return warning ? (
      <Warning>
        {str}
        {overdue && (
          <>
            &nbsp;&nbsp;
            <Icon icon="fa fa-exclamation-triangle" />
          </>
        )}
      </Warning>
    ) : (
      str
    )
  }
