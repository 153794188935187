import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import CircleMarker from 'components-v2/atoms/CircleMarker'
import { SecurityToolStatusOptions } from '../constants'

const ToolStatusDot = ({ status, ...rest }) => (
  <CircleMarker
    {...rest}
    color={get(SecurityToolStatusOptions, [status, 'color'])}
  />
)

ToolStatusDot.propTypes = {
  status: PropTypes.string,
}

export default ToolStatusDot
