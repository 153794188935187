import React from 'react'
import PropTypes from 'prop-types'
import Toggle from 'components-v2/atoms/Toggle'
import Input from 'components-v2/atoms/Input'
import Textarea from 'components-v2/atoms/Textarea'
import { BaseField } from 'components-v2/molecules/FormField'
import { InputWrapper } from './styles'

const ProjectCustomEmailSettingFormField = ({
  name,
  value,
  disabled,
  controlProps = {},
  onChange,
  ...rest
}) => {
  const checked = value !== undefined && value !== null
  const { defaultText = '', componentClass, ...restControlProps } = controlProps
  const ControlComponent = componentClass === 'textarea' ? Textarea : Input

  const handleToggle = React.useCallback(
    (enabled) => {
      onChange(enabled ? defaultText : null)
    },
    [defaultText, onChange],
  )

  const handleInputChange = React.useCallback(
    (e) => {
      onChange(e.currentTarget.value)
    },
    [onChange],
  )

  return (
    <BaseField {...rest}>
      <div>
        <Toggle checked={checked} disabled={disabled} onChange={handleToggle} />
      </div>
      {checked && (
        <InputWrapper>
          <ControlComponent
            {...restControlProps}
            value={value || ''}
            disabled={disabled}
            onChange={handleInputChange}
          />
        </InputWrapper>
      )}
    </BaseField>
  )
}

ProjectCustomEmailSettingFormField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  controlProps: PropTypes.shape({
    defaultText: PropTypes.string,
    componentClass: PropTypes.oneOf(['textarea', 'text']),
  }),
  onChange: PropTypes.func.isRequired,
}

export default ProjectCustomEmailSettingFormField
