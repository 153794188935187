import React from 'react'
import * as yup from 'yup'
import FormField from 'components-v2/molecules/FormField'
import FormDialog from 'components-v2/organisms/FormDialog'

const formConfig = {
  initialValues: {
    file: undefined,
  },
  validationSchema: yup.object().shape({
    file: yup.mixed().nullable().required('Please attach a file'),
  }),
}

export default function CompletedUploadModal(props) {
  return (
    <FormDialog
      {...props}
      title="Upload Completed Assessment"
      submitButtonLabel="Save"
      formConfig={formConfig}
    >
      <FormField
        name="file"
        label="Assessment File"
        type={FormField.types.FILE_INPUT}
        controlProps={{
          accept: '.pdf,.doc,.docx,.xls,.xlsx,.csv,.xlsm,.zip',
        }}
        horizontal
      />
    </FormDialog>
  )
}
