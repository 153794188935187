import Cookies from 'js-cookie'
import { localTimeZoneOffsetInHours } from './date'

export default class ClientTimeZone {
  static apply() {
    const tz =
      Intl.DateTimeFormat().resolvedOptions().timeZone ||
      localTimeZoneOffsetInHours
    Cookies.set('timezone', tz, { expires: 1, path: '/' })
  }
}
