import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import mapValues from 'lodash/mapValues'
import clone from 'lodash/clone'
import filter from 'lodash/filter'
import { UserOrgRoles } from 'utils/roles'

export const parseRawUserData = (user) => {
  const rawVendorRoles = user.org_roles.vendors
  const cloned = clone(rawVendorRoles)
  const vendorRoles = isEmpty(cloned) ? { all: true } : cloned
  const emailPrefs = get(user, 'org_preferences.email_settings.all', true)
  return {
    ...user,
    org_role: user.org_roles.org,
    org_role_scope: user.org_roles.scope,
    vendor_roles: vendorRoles,
    email_notifications: emailPrefs,
  }
}

export const parseUserVendorRoles = (vendorRoles, orgRole) => {
  if (typeof vendorRoles === 'undefined' || vendorRoles.all) {
    return {}
  }
  return mapValues(vendorRoles, () => orgRole)

  // } else if (orgRole !== UserOrgRoleTypes.CUSTOM) {
  //   return mapValues(vendorRoles, _ => orgRole);
  // } else {
  //   return mapValues(vendorRoles, o => o === true ? UserVendorRoleTypes.MANAGER : o);
  // }
}

export const getPermittedOrgRoles = (role) =>
  filter(UserOrgRoles, (r) => r.sort <= UserOrgRoles[role].sort && r.org_option)
