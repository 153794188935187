import styled from 'styled-components'
import Card from 'components-v2/organisms/Card'

export const Wrapper = styled(Card)`
  font-size: 15px;
`

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`

export const Title = styled.h3`
  color: #212121;
  margin: 0;
`

export const ButtonBox = styled.div`
  display: flex;
  align-items: center;
`
