import { useCallback } from 'react'
import useSetAssessmentTemplate from './useSetAssessmentTemplate'
import useEditQuestionAutoScore from '../shared/useEditQuestionAutoScore'

// Callback when edit an element auto score
export default function useChangeElementAutoScore({
  assessmentTemplate,
  confirmAutoScore,
}) {
  const setAssessmentTemplate = useSetAssessmentTemplate(assessmentTemplate.id)
  const editQuestionAutoScore = useEditQuestionAutoScore(
    assessmentTemplate.elements,
    confirmAutoScore,
  )

  return useCallback(
    (elementId, choiceId, scoreValue) => {
      editQuestionAutoScore(elementId, choiceId, scoreValue, (data) => {
        // Update state using the api response
        setAssessmentTemplate((current) => ({
          ...current,
          elements: current.elements?.map((e) => {
            if (e.id === elementId) {
              return {
                ...e,
                choices: e.choices?.map((c) => (c.id === choiceId ? data : c)),
              }
            }
            return e
          }),
        }))
      })
    },
    [editQuestionAutoScore, setAssessmentTemplate],
  )
}
