import styled from 'styled-components'
import { Filter } from '@styled-icons/fa-solid/Filter'
import Popover from 'components-v2/molecules/Popover'

export const PopoverPanel = styled(Popover.Panel).withConfig({
  shouldForwardProp: (prop) => prop !== 'position',
})`
  // Populate ideal position of popover panel
  ${({ position }) =>
    position.right < position.left
      ? `
    left: auto !important;
    right: ${-position.right}px !important;
  `
      : `
    left: ${-position.left}px !important;
  `}
`

export const FilterIcon = styled(Filter)`
  opacity: 0.7;
  margin-right: 4px;
  width: 1em;
  height: 1em;
  font-size: 14px;
`

export const Inner = styled.div`
  position: relative;
`
