import styled from 'styled-components'

export const Wrapper = styled.div``

export const CheckboxWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 4px;
  }

  &:not(:last-child) {
    margin-bottom: 4px;
  }
`
