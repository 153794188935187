import styled from 'styled-components'
import { colors } from 'styles'
import Button from 'components-v2/atoms/Button'

export const Title = styled.h3`
  color: ${colors.HEADING};
  margin: 0 0 20px;
`

export const MoreButton = styled(Button).attrs({ color: 'primary' })`
  margin-left: 0;
  margin-right: 0;
  border-radius: 0;
  width: 100%;
  white-space: normal;
`
