import styled from 'styled-components'
import { fonts } from 'styles'

export const Wrapper = styled.div`
  position: relative;
`

export const HoleContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -20px;
  width: 40px;
  text-align: center;
  font-size: 9px;
  line-height: 11px;
  font-weight: bold;
  font-family: ${fonts.SECONDARY};
  text-transform: uppercase;
`

export const RiskRating = styled.div`
  font-size: 25px;
  line-height: 30px;
  color: ${(props) => props.color};
`
