import React from 'react'
import reject from 'lodash/reject'
import GlobalLoaderManager from 'lib/globalLoader'

export default function useGlobalLoader() {
  const idsRef = React.useRef([])

  const show = React.useCallback((props = {}) => {
    const loaderId = GlobalLoaderManager.showLoader(props)
    idsRef.current = [...idsRef.current, loaderId]
    return loaderId
  }, [])

  const hide = React.useCallback((loaderId) => {
    GlobalLoaderManager.hideLoader(loaderId)
    idsRef.current = reject(idsRef.current, (id) => id === loaderId)
  }, [])

  // Make sure to hide all loaders created by this component at destroying phase
  React.useEffect(
    () => () => {
      idsRef.current.forEach((id) => GlobalLoaderManager.hideLoader(id))
    },
    [],
  )

  return [show, hide]
}
