import styled from 'styled-components'
import { Col } from 'components-v2/organisms/Layout'
import { colors, media } from 'styles'

export const Wrapper = styled.div`
  color: ${colors.BODY_TEXT};
`

export const StyledCol = styled(Col)`
  margin-bottom: 20px;
`

export const ChartImg = styled.img`
  width: 100%;
  height: auto;

  ${media.tablet`
    height: 250px;
  `}
`

export const TableImg = styled.img`
  width: 100%;
  height: 450px;

  ${media.tablet`
    height: 350px;
  `}
`
