import React from 'react'
import PropTypes from 'prop-types'
import has from 'lodash/has'
import size from 'lodash/size'
import map from 'lodash/map'
import A from 'components-v2/atoms/A'
import Toggle from 'components-v2/atoms/Toggle'
import IconButton from 'components-v2/atoms/IconButton'
import Editable from 'components-v2/molecules/Editable'
import { LabelTypes } from 'constants/index'

import {
  Table,
  PreviewWrapper,
  GroupList,
  GroupLine,
  CheckboxWrapper,
  CheckboxCell,
  EditIconWrapper,
} from './styles'

const View = ({
  introNote,
  enableRequests,
  openPreviewModal,
  customFieldGroupOptions,
  customFieldsTree,
  standardGroupOptions,
  fieldGroupsConfig,
  enableCalc,
  enableUploads,
  onEnableRequestsChecked,
  onNoteChange,
  onGroupChange,
  onEditGroup,
  onEnableCalculator,
  onEnableUpload,
}) => (
  <Table>
    <tbody>
      <tr>
        <td>Allow status-only users to request assessments</td>
        <td>
          <Toggle checked={enableRequests} onChange={onEnableRequestsChecked} />
          {enableRequests && (
            <PreviewWrapper>
              <A onClick={(e) => openPreviewModal(e)}>Preview</A>
            </PreviewWrapper>
          )}
        </td>
      </tr>
      <tr>
        <td>Include custom note / instructions</td>
        <td>
          {/* maybe make this properly support formatting at some point */}
          <Editable value={introNote} mode="inline" onSubmit={onNoteChange} />
        </td>
      </tr>
      <tr>
        <td>Include additional info field groups</td>
        <td>
          <GroupList>
            <dt>Custom {LabelTypes.VENDOR.toLowerCase()} Field Groups</dt>
            <dd>
              {map(customFieldGroupOptions, (opt) => {
                const hasFields = size(customFieldsTree[opt.value]) > 0
                return (
                  <GroupLine key={opt.value}>
                    <CheckboxWrapper>
                      <CheckboxCell
                        horizontal
                        label={opt.label}
                        name={opt.value}
                        onChange={(checked) =>
                          onGroupChange(opt.value, checked)
                        }
                        value={has(fieldGroupsConfig, opt.value) && hasFields}
                        disabled={!hasFields}
                      />
                    </CheckboxWrapper>
                    {hasFields && (
                      <EditIconWrapper>
                        <IconButton
                          icon="fa fa-pencil"
                          onClick={() => onEditGroup(opt)}
                        />
                      </EditIconWrapper>
                    )}
                  </GroupLine>
                )
              })}
            </dd>
            <dt>Standard {LabelTypes.VENDOR.toLowerCase()} Field Groups</dt>
            <dd>
              {map(standardGroupOptions, (opt) => (
                <GroupLine key={opt.value}>
                  <CheckboxWrapper>
                    <CheckboxCell
                      horizontal
                      label={opt.label}
                      name={opt.value}
                      onChange={(checked) => onGroupChange(opt.value, checked)}
                      value={has(fieldGroupsConfig, opt.value)}
                    />
                  </CheckboxWrapper>
                  <EditIconWrapper>
                    <IconButton
                      icon="fa fa-pencil"
                      onClick={() => onEditGroup(opt)}
                    />
                  </EditIconWrapper>
                </GroupLine>
              ))}
            </dd>
          </GroupList>
        </td>
      </tr>
      <tr>
        <td>Require Risk Tier Calculator</td>
        <td>
          <Toggle
            disabled={!enableRequests}
            checked={enableCalc}
            onChange={onEnableCalculator}
          />
        </td>
      </tr>
      <tr>
        <td>Allow file uploads</td>
        <td>
          <Toggle
            disabled={!enableRequests}
            checked={enableUploads}
            onChange={onEnableUpload}
          />
        </td>
      </tr>
    </tbody>
  </Table>
)

View.propTypes = {
  introNote: PropTypes.string,
  enableRequests: PropTypes.bool.isRequired,
  openPreviewModal: PropTypes.func.isRequired,
  customFieldGroupOptions: PropTypes.array.isRequired,
  customFieldsTree: PropTypes.object.isRequired,
  standardGroupOptions: PropTypes.array.isRequired,
  fieldGroupsConfig: PropTypes.object,
  enableCalc: PropTypes.bool.isRequired,
  enableUploads: PropTypes.bool.isRequired,
  onEnableRequestsChecked: PropTypes.func.isRequired,
  onNoteChange: PropTypes.func.isRequired,
  onGroupChange: PropTypes.func.isRequired,
  onEditGroup: PropTypes.func.isRequired,
  onEnableCalculator: PropTypes.func.isRequired,
  onEnableUpload: PropTypes.func.isRequired,
}

export default View
