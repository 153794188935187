import { useCallback } from 'react'
import { useGlobalLoader, useModal } from 'hooks'
import NotificationManager from 'lib/notifications'
import objectToFormData from 'utils/object_to_formdata'
import { useCreateAssistQuestionSet } from 'apis'
import AttachFilesModal from 'components-v2/molecules/AttachFilesModal'

export default function useUploadQuestionSet() {
  const [showLoader, hideLoader] = useGlobalLoader()
  const [openModal, closeModal] = useModal(AttachFilesModal)
  const { mutateAsync: createAssistQuestionSet } = useCreateAssistQuestionSet()

  return useCallback(() => {
    const onSubmit = (files) => {
      const loaderId = showLoader()
      createAssistQuestionSet({ data: objectToFormData({ sheet: files[0] }) })
        .then(() => closeModal())
        .catch((error) =>
          NotificationManager.error(error.response.data?.message),
        )
        .then(() => hideLoader(loaderId))
    }
    openModal({
      accept: '.csv',
      maxFiles: 1,
      onSubmit,
    })
  }, [openModal, closeModal, createAssistQuestionSet, showLoader, hideLoader])
}
