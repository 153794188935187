import styled from 'styled-components'
import Input from 'components-v2/atoms/Input'
import Select from 'components-v2/atoms/Select'
import Toolbar from 'components-v2/molecules/Table/Toolbar'
import Progress from './Progress'

export const ImportButtonContainer = styled.div`
  text-align: right;
  margin-bottom: 15px;
`

export const Description = styled.p`
  white-space: pre-wrap;
`

export const AutosavedNote = styled.div`
  font-style: italic;
  font-size: 12px;
  text-transform: none;
  color: #333333;
  font-weight: normal;
  min-width: 55px;
`

export const FilterFormInput = styled(Input)`
  width: 120px;
`

export const StyledToolbar = styled(Toolbar)`
  margin-top: 15px;
  margin-bottom: 0;
`

export const ToolbarSubmitButtonWrapper = styled.div`
  width: 160px;
  button {
    width: 100%;
  }
`

export const StyledProgress = styled(Progress.type)`
  width: 210px;
`

export const SectionSelectionWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #eee;
  color: #333;
  padding: 8px;
  margin-top: 8px;
`

export const SectionSelect = styled(Select)`
  width: 240px;
`

export const SectionProgressWrapper = styled.div`
  margin-left: auto;
`

export const ResponseTable = styled.div`
  & > * {
    border-top: 1px solid #ddd;
  }

  ${(props) => props.submitted && 'opacity: 0.75'}
`
