import React from 'react'
import styled from 'styled-components'
import A from 'components-v2/atoms/A'
import Tooltip from 'components-v2/molecules/Tooltip'

const StyledTooltip = styled(Tooltip).attrs({ dark: false })`
  text-align: left;
  max-width: 400px;
`

export default function QuestionSetFormatTooltip() {
  return (
    <StyledTooltip
      parent={
        <A>
          <i className="fa fa-question-circle" /> Question Set Format
        </A>
      }
      trigger="click"
    >
      <h6>Question Set Format</h6>
      <table
        className="table table-bordered table-condensed"
        style={{ marginBottom: 0 }}
      >
        <thead>
          <tr>
            <th>key</th>
            <th>section</th>
            <th className="required">question*</th>
          </tr>
        </thead>
        <tbody>
          <tr className="warning">
            <td>unique identifier</td>
            <td>section or area this question relates to</td>
            <td>the question</td>
          </tr>
          <tr>
            <td>1.1</td>
            <td>General Security</td>
            <td>Do you have password requirements?</td>
          </tr>
        </tbody>
      </table>
      <div className="text-right strong">*required</div>
    </StyledTooltip>
  )
}
