import styled from 'styled-components'
import Textarea from 'components-v2/atoms/Textarea'

export const FormattingToggleWrapper = styled.div`
  text-align: right;
  margin-bottom: 8px;
`

export const StyledTextarea = styled(Textarea)`
  resize: vertical;
`
