import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useQueryClient } from '@tanstack/react-query'
import NotificationManager from 'lib/notifications'
import { useGlobalLoader, useUpdateEffect } from 'hooks'
import { compose, withAuthorize, withReactQuery } from 'hocs'
import { useClientIssueFetcher } from 'apis'
import { IssueStatusTypes } from 'constants/index'
import useEditNote from './useEditNote'
import useAssignUser from './useAssignUser'
import useCRUDIssue from './useCRUDIssue'
import View from './View'

const canCloseStatusTypes = [
  IssueStatusTypes.CREATED,
  IssueStatusTypes.OPENED,
  IssueStatusTypes.RESPONDED,
]
const canReopenStatusTypes = [
  IssueStatusTypes.CLOSED,
  IssueStatusTypes.ARCHIVED,
]
const cannotCommentStatusTypes = [
  IssueStatusTypes.CLOSED,
  IssueStatusTypes.ARCHIVED,
]

const Container = ({ issue: issueProp, extras }) => {
  const { data: issue } = useClientIssueFetcher(
    {
      id: issueProp.id,
    },
    { initialData: issueProp, refetchOnMount: false },
  )
  const queryClient = useQueryClient()
  const [showLoader, hideLoader] = useGlobalLoader()
  const editNote = useEditNote(issue)
  const assignUser = useAssignUser(issue)
  const {
    editIssue,
    deleteIssue,
    closeIssue,
    sendIssue,
    archiveIssue,
    reopenIssue,
  } = useCRUDIssue(issue)

  useUpdateEffect(() => {
    NotificationManager.success(
      `An issue has been successfully ${issue.status}.`,
    )
  }, [issue.status])

  const handleCommentCreated = React.useCallback(async () => {
    if (issue.status !== IssueStatusTypes.RESPONDED) {
      return
    }

    const loaderId = showLoader()
    await queryClient.invalidateQueries(useClientIssueFetcher.queryKey(), {
      predicate: (query) =>
        query.queryKey[0] === useClientIssueFetcher.queryKey()[0] &&
        query.queryKey[1].id === issue.id,
    })
    hideLoader(loaderId)
  }, [issue.id, showLoader, hideLoader, issue.status])

  const setIssue = useCallback(
    (value) => {
      queryClient.setQueriesData(
        {
          predicate: (query) =>
            query.queryKey[0] === useClientIssueFetcher.queryKey()[0] &&
            query.queryKey[1].id === issueProp.id,
        },
        value,
      )
    },
    [queryClient, issueProp.id],
  )

  return (
    <View
      issue={issue}
      extras={extras}
      canClose={canCloseStatusTypes.includes(issue.status)}
      canSend={issue.status === IssueStatusTypes.CREATED}
      canArchive={issue.status === IssueStatusTypes.CLOSED}
      canReopen={canReopenStatusTypes.includes(issue.status)}
      canComment={!cannotCommentStatusTypes.includes(issue.status)}
      setIssue={setIssue}
      onEditIssue={editIssue}
      onDeleteIssue={deleteIssue}
      onCloseIssue={closeIssue}
      onSendIssue={sendIssue}
      onArchiveIssue={archiveIssue}
      onReopenIssue={reopenIssue}
      onAssign={assignUser}
      onCommentCreated={handleCommentCreated}
      onEditNote={editNote}
    />
  )
}

Container.propTypes = {
  issue: PropTypes.object.isRequired,
  extras: PropTypes.object,
}

export default compose(withAuthorize(), withReactQuery())(Container)
