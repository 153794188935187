import styled from 'styled-components'
import A from 'components-v2/atoms/A'

export const SectionRow = styled.div`
  padding: 10px 20px;
  border-bottom: 1px solid #ededed;
  display: flex;
`

export const Label = styled.label`
  flex: 1;
  padding-right: 20px;
`

export const VendorLink = styled(A)`
  position: relative;
`
