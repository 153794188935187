import styled from 'styled-components'
import Button from 'components-v2/atoms/Button'
import { mixins } from 'styles'

export const ToggleButton = styled(Button).attrs({
  variant: 'text',
  color: 'primary',
})`
  ${mixins.smallHeader};
  letter-spacing: 0;
  margin: 10px 0;
`
