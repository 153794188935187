import React, {
  useMemo,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react'
import PropTypes from 'prop-types'
import { useTableColumnsPref, useCurrent, useQueryParams } from 'hooks'
import { ExportFormatTypes } from 'constants/index'
import config from 'config'
import { useClientTagsFetcher, useClientVendorRequestersFetcher } from 'apis'
import View from './View'
import useImportVendors from './useImportVendors'
import useImportCustomFields from './useImportCustomFields'
import useVendorsFetcher from './useVendorsFetcher'
import {
  getFilterConfigList,
  getVendorsQueryParams,
  getExportUrl,
  getCSVContactExportUrl,
} from './utils'
import { ActionMenuItemTypes, RequestOpts, COL_NAMES } from './constants'

const Container = forwardRef(
  ({ orgId, canAdmin, requests, disabledIntegrations, userPrefs }, ref) => {
    const [columnToggles, setColumnToggles] = useTableColumnsPref(
      userPrefs,
      `table_columns.${RequestOpts[requests].prefsPath}`,
      COL_NAMES,
      orgId,
    )
    const importVendors = useImportVendors()
    const importCustomFields = useImportCustomFields()
    const { data: vendorTags } = useClientTagsFetcher()
    const { data: requesters } = useClientVendorRequestersFetcher()
    const filterConfigList = useMemo(() => {
      const filterData = requests ? requesters : vendorTags
      return getFilterConfigList(requests, filterData)
    }, [requests, vendorTags, requesters])

    const [
      { page, sizePerPage, sortField, sortOrder, searchText, includeArchived },
      setQueryParams,
    ] = useQueryParams({
      page: {
        type: Number,
        defaultValue: 1,
      },
      sizePerPage: {
        queryKey: 'per_page',
        type: Number,
        defaultValue: config.collectionQueryPageLimit.default,
      },
      sortField: {
        queryKey: 'ordered_by',
      },
      sortOrder: {
        queryKey: 'direction',
      },
      searchText: {
        queryKey: 'q',
        defaultValue: '',
      },
      includeArchived: {
        queryKey: 'include_archived',
        type: Boolean,
        defaultValue: false,
      },
    })
    const [filter, setFilter] = useState({
      vendorTags: { selected: [], anyMatch: true },
      riskTiers: { selected: [] },
      requestedBy: { selected: [] },
      status: { selected: [] },
      createdAt: {
        startDate: undefined,
        endDate: undefined,
      },
      nextReassessmentAt: {
        startDate: undefined,
        endDate: undefined,
      },
    })

    const tableState = {
      page,
      sizePerPage,
      sortField,
      sortOrder,
      searchText,
      includeArchived,
      filter,
    }

    const vendorsQueryParams = getVendorsQueryParams(tableState, requests)
    const vendorsQueryParamsRef = useCurrent(vendorsQueryParams)
    const {
      data: vendors,
      totalSize,
      isFetching: isFetchingVendors,
    } = useVendorsFetcher(vendorsQueryParams)

    const handleSearchChange = useCallback(
      (newValue) => {
        setQueryParams({
          page: 1,
          searchText: newValue,
        })
      },
      [setQueryParams],
    )

    const handleToggleArchived = useCallback(
      (event) => {
        setQueryParams({
          page: 1,
          includeArchived: event.target.checked,
        })
      },
      [setQueryParams],
    )

    const handleFilterChange = useCallback(
      (newValue) => {
        setQueryParams({ page: 1 })
        setFilter(newValue)
      },
      [setQueryParams],
    )

    const handleMenuItemSelect = useCallback(
      (key) => {
        switch (key) {
          case ActionMenuItemTypes.IMPORT_VENDORS:
            importVendors(orgId)
            return
          case ActionMenuItemTypes.IMPORT_CUSTOM_FIELDS:
            importCustomFields(orgId)
            return
          case ActionMenuItemTypes.EXPORT_CSV:
            window.location.assign(
              getExportUrl(
                ExportFormatTypes.CSV,
                vendorsQueryParamsRef.current,
              ),
            )
            return
          case ActionMenuItemTypes.EXPORT_XLS:
            window.location.assign(
              getExportUrl(
                ExportFormatTypes.XLSX,
                vendorsQueryParamsRef.current,
              ),
            )
            return
          case ActionMenuItemTypes.EXPORT_CONTACTS_CSV:
            window.location.assign(
              getCSVContactExportUrl(vendorsQueryParamsRef.current),
            )
            break
          default:
        }
      },
      [orgId, importVendors, importCustomFields, vendorsQueryParamsRef],
    )

    useImperativeHandle(ref, () => ({
      updateSearch: handleSearchChange,
    }))

    return (
      <View
        {...tableState}
        vendors={vendors}
        totalSize={totalSize}
        loading={isFetchingVendors}
        columnToggles={columnToggles}
        requests={requests}
        disabledIntegrations={disabledIntegrations}
        filterConfigList={filterConfigList}
        canBulkImport={canAdmin}
        onPaginationChange={setQueryParams}
        onSortingChange={setQueryParams}
        onSearchChange={handleSearchChange}
        onMenuItemSelect={handleMenuItemSelect}
        onToggleArchived={handleToggleArchived}
        onFilterChange={handleFilterChange}
        onColumnTogglesChange={setColumnToggles}
      />
    )
  },
)

Container.propTypes = {
  orgId: PropTypes.string.isRequired,
  canAdmin: PropTypes.bool,
  requests: PropTypes.bool,
  disabledIntegrations: PropTypes.array,
  userPrefs: PropTypes.object,
}

export default Container
