// Simple hook that keeps state inside a ref
import { useRef, useEffect } from 'react'

export default (value) => {
  const ref = useRef(value)

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref
}
