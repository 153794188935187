import React from 'react'
import PropTypes from 'prop-types'
import { useFormField } from 'hooks'
import CheckboxField from './CheckboxField'
import MultiCheckboxField from './MultiCheckboxField'
import RadioGroupField from './RadioGroupField'
import InputField from './InputField'
import SelectField from './SelectField'
import SearchableSelectField from './SearchableSelectField'
import CreatableSelectField from './CreatableSelectField'
import DateField from './DateField'
import ToggleField from './ToggleField'
import FilesInputField from './FilesInputField'
import FileInputField from './FileInputField'
import PhoneNumberField from './PhoneNumberField'
import FormattableEditorField from './FormattableEditorField'

const types = {
  CHECKBOX: 'checkbox',
  MULTI_CHECKBOX: 'multi_checkbox',
  RADIO_GROUP: 'radio_group',
  SELECT: 'select',
  INPUT: 'input',
  TEXTAREA: 'textarea',
  SEARCHABLE_SELECT: 'searchable_select',
  CREATABLE_SELECT: 'creatable_select',
  DATE: 'date',
  TOGGLE: 'toggle',
  FILES_INPUT: 'files_input',
  FILE_INPUT: 'file_input',
  PHONE_NUMBER: 'phone_number',
  FORMATTABLE_EDITOR: 'formattable_editor',
}

export const getComponent = (type) => {
  switch (type) {
    case types.CHECKBOX:
      return CheckboxField
    case types.MULTI_CHECKBOX:
      return MultiCheckboxField
    case types.RADIO_GROUP:
      return RadioGroupField
    case types.SELECT:
      return SelectField
    case types.SEARCHABLE_SELECT:
      return SearchableSelectField
    case types.CREATABLE_SELECT:
      return CreatableSelectField
    case types.DATE:
      return DateField
    case types.TOGGLE:
      return ToggleField
    case types.FILES_INPUT:
      return FilesInputField
    case types.FILE_INPUT:
      return FileInputField
    case types.PHONE_NUMBER:
      return PhoneNumberField
    case types.FORMATTABLE_EDITOR:
      return FormattableEditorField
    default:
      return InputField
  }
}

const FormField = ({ name, type, component, onChange, ...rest }) => {
  const props = useFormField(name)
  const Component = component || getComponent(type)
  const handleChange = React.useCallback(
    (value) => {
      props.onChange(value)
      if (onChange) {
        onChange(value)
      }
    },
    [props.onChange, onChange],
  )
  return (
    <Component
      {...rest}
      {...props}
      componentClass={type}
      onChange={handleChange}
    />
  )
}

FormField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(types)),
  component: PropTypes.any,
  onChange: PropTypes.func,
}

FormField.types = types

export default FormField

export const FormFieldTypes = types
export { default as ControlLabel } from './ControlLabel'
export { default as ErrorMessage } from './ErrorMessage'
export { default as BaseField } from './BaseField'
