import {
  SecurityToolStatusTypes,
  SecurityObjectiveStatusTypes,
} from 'constants/index'
import { colors } from 'styles'

export const SecurityToolStatusOptions = {
  [SecurityToolStatusTypes.ACTIVE]: {
    label: 'Active',
    color: colors.GREEN,
  },
  [SecurityToolStatusTypes.WARNING]: {
    label: 'Warning',
    color: colors.YELLOW,
  },
  [SecurityToolStatusTypes.NOT_REPORTING]: {
    label: 'Not reporting',
    color: colors.RED,
  },
}

export const SecurityObjectiveStatusOptions = {
  [SecurityObjectiveStatusTypes.NO_COVERAGE]: {
    color: colors.RED,
  },
}

export const SecurityObjectiveCategories = [
  {
    name: 'Application Security',
    objectives: [
      'Code Analysis',
      'Software Development Life Cycle',
      'Change Management',
      'Data Encryption',
    ],
  },
  {
    name: 'Network Security',
    objectives: [
      'Identity & Access Management',
      'Infrastructure Security',
      'Intrusion Detection',
      'Vulnerability Scan',
      'IT Operations Management',
    ],
  },
  {
    name: 'Organization Security',
    objectives: [
      'Background',
      'Endpoint Protection',
      'Email Scanning and Phishing',
      'Mobile Management',
      'Vendor Risk Management',
    ],
  },
]

export const RiskHealthScoreColors = [
  colors.RED, // 0-50
  '#EE635F', // 51-100
  colors.ORANGE, // 101-150
  colors.YELLOW, // 151-200
  colors.ATLANTIS, // 201-250
  colors.GREEN, // 251-300
]
