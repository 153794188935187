import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components-v2/atoms/Button'
import Checkbox from 'components-v2/atoms/Checkbox'
import { getEndpointUrl } from 'utils/url'
import { useClientAssessmentAttachmentsFetcher } from 'apis'
import AttachmentsModal from '../AttachmentsModal'

const AssessmentFilesModal = ({ assessmentId, ...rest }) => {
  const [includeIssueAttachments, setIncludeIssueAttachments] =
    React.useState(false)
  const params = {
    include_issue_attachments: includeIssueAttachments,
  }
  const { data: attachments, isLoading } =
    useClientAssessmentAttachmentsFetcher({ assessmentId, params })
  const downloadUrl = getEndpointUrl(
    'clientAssessmentAttachmentsZip',
    {
      id: assessmentId,
    },
    params,
  )
  const additionalFooterContent = (
    <>
      <Checkbox
        label="Include Issue Attachments"
        inline
        checked={includeIssueAttachments}
        onChange={(e) => setIncludeIssueAttachments(e.currentTarget.checked)}
      />
      &nbsp;&nbsp;&nbsp;
      <Button
        color="primary"
        target="_blank"
        href={downloadUrl}
        disabled={!attachments?.length}
      >
        Download ZIP
      </Button>
    </>
  )
  return (
    <AttachmentsModal
      {...rest}
      title="Assessment Files"
      attachments={attachments}
      loading={isLoading}
      additionalFooterContent={additionalFooterContent}
    />
  )
}

AssessmentFilesModal.propTypes = {
  assessmentId: PropTypes.string.isRequired,
}

export default AssessmentFilesModal
