import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from '../../atoms/Checkbox'
import { Wrapper, CheckboxWrapper } from './styles'

const MultiCheckbox = ({
  value: selected,
  options,
  disabled,
  onChange,
  selectAllVisible,
  RenderCheckbox,
  RenderCheckboxWrapper,
  getOptionValue,
  getOptionLabel,
  getOptionColor,
  ...rest
}) => {
  const isCheckboxChecked = React.useCallback(
    (opt) => !!selected && selected.indexOf(getOptionValue(opt)) !== -1,
    [getOptionValue, selected],
  )

  const isAllChecked = React.useMemo(
    () => options.length > 0 && !options.some((e) => !isCheckboxChecked(e)),
    [options, isCheckboxChecked],
  )

  const handleSelectAllChange = React.useCallback(
    (e) => {
      if (e.target.checked) {
        onChange(options.map(getOptionValue))
      } else {
        onChange([])
      }
    },
    [options, getOptionValue, onChange],
  )

  const handleCheckboxChange = React.useCallback(
    (e, opt) => {
      const { checked } = e.target
      const newSelected = selected ? [...selected] : []
      const index = newSelected.indexOf(getOptionValue(opt))
      if (!checked && index !== -1) {
        newSelected.splice(index, 1)
      } else if (checked && index === -1) {
        newSelected.push(getOptionValue(opt))
      }
      onChange(newSelected)
    },
    [selected, getOptionValue, onChange],
  )

  return (
    <Wrapper {...rest}>
      {selectAllVisible && (
        <RenderCheckboxWrapper key="all">
          <RenderCheckbox
            label="Select All"
            checked={isAllChecked}
            disabled={disabled}
            onChange={handleSelectAllChange}
          />
        </RenderCheckboxWrapper>
      )}
      {options.map((opt) => (
        <RenderCheckboxWrapper key={getOptionValue(opt)}>
          <RenderCheckbox
            label={getOptionLabel(opt)}
            color={getOptionColor(opt)}
            checked={isCheckboxChecked(opt)}
            disabled={disabled}
            onChange={(e) => handleCheckboxChange(e, opt)}
          />
        </RenderCheckboxWrapper>
      ))}
    </Wrapper>
  )
}

MultiCheckbox.propTypes = {
  value: PropTypes.array,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  selectAllVisible: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  // RenderCheckbox: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType]),
  // RenderCheckboxWrapper: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  RenderCheckbox: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  RenderCheckboxWrapper: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionColor: PropTypes.func,
}

MultiCheckbox.defaultProps = {
  RenderCheckbox: Checkbox,
  RenderCheckboxWrapper: CheckboxWrapper,
  disabled: false,
  selectAllVisible: false,
  getOptionValue: (e) => e.value,
  getOptionLabel: (e) => e.label,
  getOptionColor: (e) => e.color,
}

export default React.memo(MultiCheckbox)
