import React, { useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { PreviewsWrapper, Dropzone, UploadIcon, ContentWrapper } from './styles'

const Layout = ({
  input,
  previews,
  dropzoneProps,
  files,
  extra: { maxFiles, active, reject },
}) => {
  const labelRef = useRef()

  const handleKeyDown = useCallback((e) => {
    if (e.keyCode === 32 || e.keyCode === 13) {
      labelRef.current.click()
    }
  }, [])

  return (
    <div>
      <PreviewsWrapper>{previews}</PreviewsWrapper>
      <Dropzone {...dropzoneProps}>
        <ContentWrapper
          tabIndex={0}
          disabled={files.length >= maxFiles}
          active={active}
          reject={reject}
          ref={labelRef}
          onKeyDown={handleKeyDown}
        >
          <UploadIcon />
          <div>Drag and drop your file(s), or {input}</div>
        </ContentWrapper>
      </Dropzone>
    </div>
  )
}

Layout.propTypes = {
  input: PropTypes.element.isRequired,
  previews: PropTypes.element.isRequired,
  dropzoneProps: PropTypes.object,
  files: PropTypes.array.isRequired,
  extra: PropTypes.object.isRequired,
}

export default Layout
