import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import includes from 'lodash/includes'
import { ShieldAlt as ShieldAltIcon } from '@styled-icons/fa-solid/ShieldAlt'
import { BookOpen as BookOpenIcon } from '@styled-icons/fa-solid/BookOpen'
import { Cog as CogIcon } from '@styled-icons/fa-solid/Cog'
import { Tasks as TasksIcon } from '@styled-icons/fa-solid/Tasks'
import { Table as TableIcon } from '@styled-icons/fa-solid/Table'
import { File as FileIcon } from '@styled-icons/fa-solid/File'
import { ChartPie as ChartPieIcon } from '@styled-icons/fa-solid/ChartPie'
import { Microscope as MicroscopeIcon } from '@styled-icons/fa-solid/Microscope'
import { PersonFill as PersonFillIcon } from '@styled-icons/bootstrap/PersonFill'
import Icon from 'components-v2/atoms/Icon'
import Button from 'components-v2/atoms/Button'
import Tabs, { Tab } from 'components-v2/molecules/Tabs'
import PrintButton from 'components-v2/molecules/PrintButton'
import ToolbarGroup from 'components-v2/molecules/ToolbarGroup'
import PageHeader from 'components-v2/organisms/PageHeader'
import {
  LabelTypes,
  VendorStatusGroups,
  VendorStatusTypes,
} from 'constants/index'
import { getPageUrl } from 'utils/url'
import { useAuthorizations } from 'hooks'
import IssueListView from '../issue_list_view'
import AttachmentListForVendorsView from '../attachment_list_for_vendors_view'
import ProjectListForVendorView from '../project_list_for_vendor_view'
import VendorInfoView from '../vendor_info_view'
import VendorUsersView from '../vendor_users_view'
import VendorRiskProfileView from '../vendor_risk_profile_view'
import VendorRiskMeasuresView from '../vendor_risk_measures_view'
import BitSightVendorView from '../bitsight_vendor_view'
import LibraryProfileListingsView from '../../library/profile_listings_view'
import LinkedGloborgAlertCard from './LinkedGloborgAlertCard'
import { Wrapper } from './styles'

const VendorView = ({
  usersViewRef,
  clientId,
  bitSight,
  activeTab,
  vendor,
  globorgEnabled,
  hasGloborg,
  globalOrg,
  globalOrgIntegrationMetrics,
  libraryProfileId,
  clientVendorId,
  facetableSets,
  onTabSelect,
  onEditContributors,
  onSendAssessment,
  onRiskReconIssuesCountClick,
  onConnectGloborg,
  onConfirmGloborg,
  onDisconnectGloborg,
  onArchive,
  onUnarchive,
}) => {
  const [
    canManageVendor,
    canViewPartner,
    canViewRiskProfile,
    canViewProjects,
    canViewAttachments,
    canViewIssues,
  ] = useAuthorizations(
    'manage',
    'view_partner_data',
    ['index', 'RiskDomain'],
    ['index', 'Project'],
    ['index', 'ClientVendorAttachment'],
    ['index', 'Issue'],
  )
  const statusIsOperational = includes(
    VendorStatusGroups.operational,
    vendor.status,
  )
  const title = statusIsOperational ? vendor.name : `${vendor.name} (Proposed)`
  const breadcrumbs = [
    {
      href: getPageUrl('clientVendors'),
      title: LabelTypes.VENDORS,
    },
    {
      active: true,
      title: vendor.name,
    },
  ]
  return (
    <Wrapper>
      <PageHeader title={title} breadcrumbs={breadcrumbs}>
        <ToolbarGroup>
          {activeTab === 'riskProfile' && <PrintButton />}
          {canManageVendor && vendor.status === VendorStatusTypes.ACTIVE && (
            <Button onClick={onArchive}>
              <i className="fa fa-trash" />
              Archive
            </Button>
          )}
          {canManageVendor && vendor.status === VendorStatusTypes.ARCHIVED && (
            <Button onClick={onUnarchive}>
              <i className="fa fa-unlock" />
              Unarchive
            </Button>
          )}
          {statusIsOperational && (
            <Button
              href={getPageUrl('clientVendorResultsSummary', { id: vendor.id })}
              hidePrint
            >
              <i className="fa fa-th-list" />
              Results Summary
            </Button>
          )}
          {statusIsOperational &&
            canManageVendor && [
              <Button key="editUsers" onClick={onEditContributors}>
                <i className="fa fa-edit" />
                Edit Users
              </Button>,
              <Button
                key="sendAssessment"
                color="primary"
                onClick={onSendAssessment}
              >
                <i className="fa fa-plus-circle" />
                Send Assessment
              </Button>,
            ]}
        </ToolbarGroup>
      </PageHeader>
      <Tabs activeKey={activeTab} onChange={onTabSelect}>
        <Tab
          eventKey="info"
          title={
            <span>
              <Icon icon={CogIcon} /> Info
            </span>
          }
        >
          <VendorInfoView
            vendor={vendor}
            globorgEnabled={globorgEnabled}
            globalOrg={globalOrg}
            facetableSets={facetableSets}
            onConnectGloborg={onConnectGloborg}
            onDisconnectGloborg={onDisconnectGloborg}
            onClickGlobOrgName={() => onTabSelect('riskProfile')}
          />
        </Tab>
        {statusIsOperational && canManageVendor && (
          <Tab
            eventKey="users"
            title={
              <span>
                <Icon icon={PersonFillIcon} /> Users
              </span>
            }
            mountOnEnter={false}
            unmountOnExit={false}
          >
            <VendorUsersView ref={usersViewRef} vendor={vendor} />
          </Tab>
        )}
        {hasGloborg && canViewRiskProfile && (
          <Tab
            eventKey="riskProfile"
            title={
              <span>
                <Icon icon={ChartPieIcon} /> Risk Profile
              </span>
            }
          >
            {get(vendor, 'globorg_mapping.type') === 'implicit' &&
              globalOrg && (
                <LinkedGloborgAlertCard
                  onConfirm={onConfirmGloborg}
                  onDisconnect={onDisconnectGloborg}
                  globalOrg={globalOrg}
                />
              )}
            <VendorRiskProfileView
              clientId={clientId}
              vendorId={vendor.id}
              globalOrgIntegrationMetrics={globalOrgIntegrationMetrics}
              onRiskReconIssuesCountClick={onRiskReconIssuesCountClick}
            />
          </Tab>
        )}
        {statusIsOperational && canViewProjects && (
          <Tab
            eventKey="projects"
            title={
              <span>
                <Icon icon={TableIcon} /> Projects
              </span>
            }
          >
            <ProjectListForVendorView vendorId={vendor.id} />
          </Tab>
        )}
        {statusIsOperational && canViewAttachments && (
          <Tab
            eventKey="files"
            title={
              <span>
                <Icon icon={FileIcon} /> Files
              </span>
            }
          >
            <AttachmentListForVendorsView
              vendorId={vendor.id}
              clientVendorId={clientVendorId}
            />
          </Tab>
        )}
        {statusIsOperational && canViewIssues && (
          <Tab
            eventKey="issues"
            title={
              <span>
                <Icon icon={TasksIcon} /> Issues
              </span>
            }
          >
            <IssueListView vendorId={vendor.id} newButtonVisible />
          </Tab>
        )}
        {facetableSets?.length > 0 && (
          <Tab
            eventKey="riskMeasures"
            title={
              <span>
                <Icon icon={MicroscopeIcon} /> Risk Measures
              </span>
            }
          >
            <VendorRiskMeasuresView data={facetableSets} />
          </Tab>
        )}
        {bitSight && canViewPartner && (
          <Tab
            eventKey="bitSight"
            title={
              <span>
                <Icon icon={ShieldAltIcon} /> BitSight
              </span>
            }
          >
            <BitSightVendorView partnerInfo={bitSight} vendorId={vendor.id} />
          </Tab>
        )}
        {libraryProfileId && (
          <Tab
            eventKey="library"
            title={
              <span>
                <Icon icon={BookOpenIcon} /> Library Content
              </span>
            }
          >
            <LibraryProfileListingsView profileId={libraryProfileId} />
          </Tab>
        )}
      </Tabs>
    </Wrapper>
  )
}

VendorView.propTypes = {
  usersViewRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
  clientId: PropTypes.string.isRequired,
  vendor: PropTypes.object.isRequired,
  bitSight: PropTypes.object,
  facetableSets: PropTypes.array,
  activeTab: PropTypes.string,
  globorgEnabled: PropTypes.bool,
  hasGloborg: PropTypes.bool,
  globalOrg: PropTypes.object,
  globalOrgIntegrationMetrics: PropTypes.object,
  libraryProfileId: PropTypes.string,
  onTabSelect: PropTypes.func.isRequired,
  onEditContributors: PropTypes.func.isRequired,
  onSendAssessment: PropTypes.func.isRequired,
  onRiskReconIssuesCountClick: PropTypes.func.isRequired,
  onConnectGloborg: PropTypes.func.isRequired,
  onConfirmGloborg: PropTypes.func.isRequired,
  onDisconnectGloborg: PropTypes.func.isRequired,
  onArchive: PropTypes.func.isRequired,
  onUnarchive: PropTypes.func.isRequired,
  clientVendorId: PropTypes.string,
}

export default VendorView
