import React from 'react'
import * as yup from 'yup'
import FormField from 'components-v2/molecules/FormField'
import FormDialog from 'components-v2/organisms/FormDialog'

const formConfig = {
  validationSchema: yup.object().shape({
    name: yup
      .string()
      .required('This is required')
      .min(2, 'Must not be fewer than 2 characters')
      .max(255, 'Must not be more than 255 characters'),
  }),
  initialValues: {
    name: '',
  },
}

const CreateProjectModal = (props) => (
  <FormDialog
    {...props}
    title="New Project"
    submitButtonLabel="Create Project"
    formConfig={formConfig}
  >
    <FormField
      name="name"
      label="Project Name"
      horizontal
      controlProps={{ autoFocus: true }}
    />
  </FormDialog>
)

export default CreateProjectModal
