import styled, { css } from 'styled-components'
import { CaretUp } from '@styled-icons/fa-solid/CaretUp'
import { CaretDown } from '@styled-icons/fa-solid/CaretDown'
import Icon from 'components-v2/atoms/Icon'

export const Wrapper = styled.span`
  font-size: 60px;
  font-weight: 300;
  line-height: 1em;

  sup {
    font-size: 60%;
  }
`

export const Trend = styled.span`
  font-size: 18px;
  line-height: 1em;
  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}
`

export const CaretIcon = styled(Icon).attrs((p) => ({
  icon: p.positive ? CaretUp : CaretDown,
}))`
  font-size: 16px !important;
`
