import React from 'react'
import PropTypes from 'prop-types'
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts'
import Loader from 'components-v2/molecules/Loader'
import { chartColors } from 'styles'

const COLORS = chartColors.DEFAULT

const ComposedChartWidget = ({ chartData }) => (
  <ResponsiveContainer height={340} width="100%">
    <ComposedChart
      data={chartData}
      loader={<Loader>Loading Chart...</Loader>}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" scale="band" dy={10} dx={40} />
      <YAxis
        yAxisId="left"
        label={{
          value: 'Total Assessments',
          angle: -90,
          position: 'insideCenter',
          dx: -15,
        }}
      />
      <YAxis
        yAxisId="right"
        orientation="right"
        domain={[0, 100]}
        label={{
          value: 'Average Risk Rating',
          angle: -90,
          position: 'insideCenter',
          dx: 15,
        }}
      />
      <Tooltip />
      <Legend />
      <Bar
        yAxisId="left"
        dataKey="sent"
        barSize={20}
        fill={COLORS[5]}
        stroke={COLORS[5]}
        fillOpacity={0.6}
      />
      <Bar
        yAxisId="left"
        dataKey="submitted"
        barSize={20}
        fill={COLORS[6]}
        stroke={COLORS[6]}
        fillOpacity={0.6}
      />
      <Bar
        yAxisId="left"
        dataKey="reviewed"
        barSize={20}
        fill={COLORS[0]}
        stroke={COLORS[0]}
        fillOpacity={0.6}
      />
      <Line
        type="linear"
        yAxisId="right"
        dataKey="Avg submitted rating"
        stroke={COLORS[8]}
      />
      <Line
        type="linear"
        yAxisId="right"
        dataKey="Avg reviewed rating"
        stroke={COLORS[1]}
      />
    </ComposedChart>
  </ResponsiveContainer>
)

ComposedChartWidget.propTypes = {
  chartData: PropTypes.array,
}

export default ComposedChartWidget
