import React from 'react'
import PropTypes from 'prop-types'
import { Bars } from '@styled-icons/fa-solid/Bars'
import { isIE } from 'utils/browser'
import Tooltip from 'components-v2/molecules/Tooltip'
import Editable from 'components-v2/molecules/Editable'

import { IconLink, DragIcon, DragHandle } from '../styles'

const FieldRow = ({
  fieldDef,
  dataType,
  dragProps,
  enableTooltips,
  onDragChange,
  onNameChange,
  onEditClick,
  onDeleteClick,
}) => {
  React.useEffect(() => {
    onDragChange(fieldDef.key, dragProps.isDragging)
  }, [dragProps.isDragging])

  return (
    <tr
      key={fieldDef.key}
      ref={dragProps.ref}
      style={{ opacity: dragProps.opacity }}
    >
      <td>
        <Editable
          dataType="text"
          mode="inline"
          value={fieldDef.name}
          onSubmit={(value) => onNameChange(fieldDef.key, value)}
          emptyValueText=""
        />
      </td>
      <td>{dataType}</td>
      <td align="right">
        {!isIE && (
          <Tooltip
            enabled={enableTooltips}
            closeOnParentClick
            parent={
              <DragHandle
                ref={dragProps.dragSourceRef}
                draggable={!!dragProps.dragSourceRef}
              >
                <IconLink>
                  <DragIcon icon={Bars} />
                </IconLink>
              </DragHandle>
            }
          >
            Reorder
          </Tooltip>
        )}
        <Tooltip
          parent={
            <IconLink onClick={() => onEditClick(fieldDef.key)}>
              <i className="fa fa-pencil" />
            </IconLink>
          }
        >
          Edit
        </Tooltip>
        <Tooltip
          parent={
            <IconLink onClick={() => onDeleteClick(fieldDef.key)}>
              <i className="fa fa-trash" />
            </IconLink>
          }
        >
          Delete Field
        </Tooltip>
      </td>
    </tr>
  )
}

FieldRow.propTypes = {
  fieldDef: PropTypes.object.isRequired,
  dataType: PropTypes.string.isRequired,
  dragProps: PropTypes.object.isRequired,
  enableTooltips: PropTypes.bool.isRequired,
  onDragChange: PropTypes.func.isRequired,
  onNameChange: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
}

export default FieldRow
