import React from 'react'
import { EyeFill } from '@styled-icons/bootstrap/EyeFill'
import { EyeSlashFill } from '@styled-icons/bootstrap/EyeSlashFill'
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill'
// import { DashCircleFill } from '@styled-icons/bootstrap/DashCircleFill'
import { PlusCircleFill } from '@styled-icons/bootstrap/PlusCircleFill'
import A from 'components-v2/atoms/A'
import Popover from 'components-v2/molecules/Popover'
import {
  riskReconScoreFormatterFactory,
  osanoScoreFormatterFactory,
} from 'components/client/lib/globorg_integration_table'
import SaveToWatchlist from './SaveToWatchlist'
import {
  ProfileImg,
  SaveToWatchlistTrigger,
  StyledIcon,
  StyledIconButton,
} from './styles'

const riskReconScoreFormatter = riskReconScoreFormatterFactory()
const osanoScoreFormatter = osanoScoreFormatterFactory()

export const orderedByMap = {
  watchlists: 'watchlists.name',
}

export const getTableColumns = (
  watchlists,
  watchlistSelected,
  onAddToNewWatchlist,
  onChangeGloborgWatchlists,
  onCreateVendor,
  onRemoveFromWatchlist,
  onClickGlobalOrg,
  canManage,
) => [
  {
    id: 'name',
    header: 'Company',
    cell: (info) => (
      <>
        {info.row.original.logo_url_small && (
          <ProfileImg
            src={info.row.original.logo_url_small}
            alt={info.getValue()}
            width="40"
          />
        )}
        <A onClick={() => onClickGlobalOrg(info.row.original)}>
          {info.getValue()}
        </A>
      </>
    ),
    width: '25%',
  },
  {
    id: 'metrics.risk_recon',
    header: 'RiskRecon Score',
    cell: riskReconScoreFormatter,
    enableSorting: false,
    width: '10%',
  },
  {
    id: 'metrics.osano',
    header: 'Osano Score',
    cell: osanoScoreFormatter,
    enableSorting: false,
    width: '10%',
  },
  /*
  {
    id: 'assessments',
    header: `${Privva.Utils.brand.programName} Assessment`,
    cell: (info) =>
      info.getValue() ? (
        <StyledIcon icon={CheckCircleFill} $active />
      ) : (
        <StyledIcon icon={DashCircleFill} />
      ),
    enableSorting: false,
    width: '5%',
    headerStyle: {
      textAlign: 'center,
    },
    style: {
      textAlign: 'center',
    },
  },
  */
  {
    id: 'watchlists',
    header: 'Watchlist',
    cell: (info) => {
      if (!canManage) {
        return info
          .getValue()
          ?.map((id) => watchlists.find((e) => e.id === id)?.name)
          .filter((e) => e)
          .join(', ')
      }
      if (watchlistSelected) {
        return (
          <SaveToWatchlistTrigger
            $empty
            onClick={() => onRemoveFromWatchlist(info.row.original.id)}
          >
            Remove
            <StyledIcon icon={EyeSlashFill} $active />
          </SaveToWatchlistTrigger>
        )
      }
      let popoverButton = (
        <Popover.Button as={SaveToWatchlistTrigger} $empty>
          Watch Vendor
          <StyledIcon icon={EyeFill} $active />
        </Popover.Button>
      )
      if (info.getValue()?.length > 0) {
        const str = info
          .getValue()
          ?.map((id) => watchlists.find((e) => e.id === id)?.name)
          .filter((e) => e)
          .join(', ')
        popoverButton = (
          <Popover.Button as={SaveToWatchlistTrigger}>{str}</Popover.Button>
        )
      }
      return (
        <Popover>
          {popoverButton}
          <Popover.Panel arrow>
            {({ close }) => (
              <SaveToWatchlist
                activeWatchlistIds={info.getValue()}
                watchlists={watchlists}
                onChange={(value) =>
                  onChangeGloborgWatchlists(info.row.original.id, value)
                }
                onAddToNew={() => {
                  onAddToNewWatchlist(info.row.original.id)
                  close()
                }}
              />
            )}
          </Popover.Panel>
        </Popover>
      )
    },
    enableSorting: false,
    width: '10%',
  },
  {
    id: 'vendors',
    header: 'My Vendors',
    cell: (info) => {
      if (info.getValue()?.length > 0) {
        return <StyledIcon icon={CheckCircleFill} $active />
      }
      if (canManage) {
        return (
          <StyledIconButton
            icon={PlusCircleFill}
            color="primary"
            onClick={() => onCreateVendor(info.row.original.id)}
          />
        )
      }
      return null
    },
    enableSorting: false,
    width: '5%',
    headerStyle: {
      textAlign: 'center',
    },
    style: {
      textAlign: 'center',
    },
  },
]
