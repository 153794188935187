import React from 'react'
import img from 'images/error/wrench-gear.png'
import ErrorView from '../shared/ErrorView'

export default () => (
  <ErrorView
    imageUrl={img}
    errorCode={400}
    errorType="Bad Request"
    errorMessage="Looks like your browser sent a request that this server could not understand. Chalk it up to one of the mysteries of the universe and move on!"
  />
)
