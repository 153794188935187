import React from 'react'
import PropTypes from 'prop-types'
import orderBy from 'lodash/orderBy'
import FormField from 'components-v2/molecules/FormField'
import { LabelTypes, SortOrderTypes } from 'constants/index'

const SelectVendorFormField = ({ vendors, ...rest }) => {
  const options = React.useMemo(
    () =>
      orderBy(vendors, [(v) => v.name.toLowerCase()], [SortOrderTypes.ASC]).map(
        (vendor) => ({ value: vendor.id, label: vendor.name }),
      ),
    [vendors],
  )
  return (
    <FormField
      name="vendor"
      label={LabelTypes.VENDOR}
      type={FormField.types.SELECT}
      options={options}
      horizontal
      {...rest}
    />
  )
}

SelectVendorFormField.propTypes = {
  vendors: PropTypes.array.isRequired,
}

export default SelectVendorFormField
