import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from 'components-v2/molecules/Tooltip'
import Metric from '../Metric'
import { Wrapper, Top, Label, Content, UpdatedAt, Bottom } from './styles'

const MetricCard = ({
  label,
  value,
  color,
  description,
  updatedAt,
  onGetMoreInformation,
  ...rest
}) => (
  <Wrapper {...rest}>
    <Top>
      <Label>
        {description ? (
          <Tooltip parent={<span>{label}</span>}>{description}</Tooltip>
        ) : (
          label
        )}
      </Label>
      <Content>
        <Metric color={color}>{value}</Metric>
      </Content>
      {updatedAt && <UpdatedAt>Updated {updatedAt}</UpdatedAt>}
    </Top>
    {onGetMoreInformation && (
      <Bottom onClick={onGetMoreInformation}>View Details</Bottom>
    )}
  </Wrapper>
)

MetricCard.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  color: PropTypes.string,
  description: PropTypes.string,
  updatedAt: PropTypes.string,
  onGetMoreInformation: PropTypes.func,
}

export default MetricCard
