import styled from 'styled-components'
import Table from 'components-v2/molecules/Table'

export const SelectedVendorsContainer = styled.div`
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
  padding: 15px 0;
`

export const SelectedVendorsLabel = styled.div`
  font-weight: bold;
`

export const StyledTable = styled(Table).attrs({ type: Table.types.MINI2 })`
  font-weight: 500;

  .table {
    display: block;
  }

  tbody {
    display: block;
    max-height: 184px;
    overflow-y: auto;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
`
