import Privva from 'Privva'
import set from 'lodash/set'
import each from 'lodash/each'

const DEBUG_ENABLED = Privva.Utils.development

// if we're not in development, just no-op all of it for now
// eslint-disable-next-line no-console
const writeLog = DEBUG_ENABLED ? console.log : () => {}

// might play around w/ chalk at some point...
export const debug = (...args) => writeLog('[Debug]\t', ...args)
export const info = (...args) => writeLog('[Info]\t', ...args)
export const warn = (...args) => writeLog('[Warn]\t', ...args)
export const error = (...args) => writeLog('[Error]\t', ...args)

export default {
  debug,
  info,
  warn,
  error,
}

/*
 * log(propsHash)
 *
 * Simple key-value console logger, expects a hash
 * e.g.:
 *  const t0 = performance.now()
 *  const foo = 13;
 *  const blah = 'some random string';
 *  const xyz = objectInstanceVar;
 *  const t1 = performance.now()
 *
 *  log({ foo, blah, xyz, timing: tdiff(t0, t1) })
 *
 *  ...or, if you "import { log as Log }"
 *  you can drop tags in rendering output like so,
 *  though don't expect the output to be sequential:
 *    <Log foo={foo} blah={blah} />
 *  or
 *    <Log props={{ foo, blah }} />
 *
 *  Tip: use 'lf' as a key to insert a linefeed
 */
export const log = (props) => doWrite(props, false)

/*
 * setLog(propsHash)
 *
 * Call this instead of log to set the hash keys as properties on the
 * window object that can be monitored in the console as live watch
 * expressions (eye icon).
 * Useful for testing/ad-hoc tinkering, but obviously use with care and
 * don't leave lying around
 */
export const setLog = (props) => doWrite(props, true)

/*
 * tdiff(startTime, stopTime)
 *
 * takes two timestamps, returns a descriptive string showing the difference
 */
export const tdiff = (x, y) => {
  const ms = (y - x).toFixed(3)
  const s = (ms / 1000.0).toFixed(3)
  return `${s}sec (${ms}ms )`
}

// NOT exported, just used by log and setLog above
const doWrite = (props, doSet = false, prefix = '') => {
  if (!DEBUG_ENABLED) return null
  const b = 'font-weight: bolder;'

  each(props, (v, k) => {
    if (k === 'lf') writeLog(' ')
    else if (typeof v === 'object' && v !== null && prefix === '') {
      if (doSet) set(window, k, v)
      // if value is itself a hash/object, loop through that as well
      // but only one level deep
      writeLog(`%c${prefix}${k}`, b, ':')
      doWrite(v, false, '\t')
    } else {
      if (doSet) set(window, k, v)
      writeLog(`%c${prefix}${k}`, b, ':', typeof v === 'function' ? 'f ()' : v)
    }
  })
  return null
}

if (DEBUG_ENABLED) {
  // Import lodash module dynamically to reduce bundle size
  import('lodash').then((module) => {
    // if we're doing any ad-hoc debugging anyway, just pull lodash in
    // for browser console convenience
    // (no effect if not in development mode; this won't be available for
    // ad hoc browser-based debugging in production or integration or whatever)
    setLog({ _: module.default })
  })
}
