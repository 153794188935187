import React from 'react'
import PropTypes from 'prop-types'
import Toggle from 'components-v2/atoms/Toggle'
import Editable from 'components-v2/molecules/Editable'
import Tooltip from 'components-v2/molecules/Tooltip'
import { Row, Col } from 'components-v2/organisms/Layout'
import AvatarField from './AvatarField'
import useUpdateUser from '../useUpdateUser'

const GeneralSettings = ({ currentUser }) => {
  const onUpdate = useUpdateUser()
  return (
    <div>
      <h3>General Settings</h3>
      <Row>
        <Col sm={6}>
          <table className="table">
            <tbody>
              <tr>
                <td>Profile Image</td>
                <td>
                  <AvatarField
                    avatarUrl={currentUser.avatar_url}
                    onSave={(preview) =>
                      onUpdate(
                        { avatar_image_base64: preview },
                        { avatar_url: preview },
                      )
                    }
                    onDelete={() =>
                      onUpdate(
                        {
                          avatar_image_attributes: [{ _destroy: true }],
                        },
                        { avatar_url: null },
                      )
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Name</td>
                <td>
                  <Editable
                    value={currentUser.name}
                    required
                    onSubmit={(val) => onUpdate({ name: val })}
                  />
                </td>
              </tr>
              <tr>
                <td>Email</td>
                <td>
                  <Editable
                    value={currentUser.email}
                    required
                    onSubmit={(val) => onUpdate({ email: val })}
                  />
                </td>
              </tr>
              <tr>
                <td>Phone</td>
                <td>
                  <Editable
                    value={currentUser.phone}
                    onSubmit={(val) => onUpdate({ phone: val })}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Email Notifications{' '}
                  <Tooltip>
                    Global notification setting. This will disable notifications
                    across all organizations.
                  </Tooltip>
                </td>
                <td>
                  <Toggle
                    checked={currentUser.email_notifications}
                    onChange={(val) => onUpdate({ email_notifications: val })}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
    </div>
  )
}

GeneralSettings.propTypes = {
  currentUser: PropTypes.object.isRequired,
}

export default GeneralSettings
