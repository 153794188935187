import styled from 'styled-components'
import { Check } from '@styled-icons/fa-solid/Check'
import { Close } from '@styled-icons/material/Close'
import Icon from 'components-v2/atoms/Icon'
import ProgressBar from 'components-v2/molecules/ProgressBar'
import Card from 'components-v2/organisms/Card'
import { colors, media } from 'styles'

export const Wrapper = styled(Card).attrs({ padding: true, bordered: true })`
  position: relative;
  padding: 25px;
  margin-bottom: 20px;
  color: ${colors.PRIMARY};
  border-color: currentColor;
  background: transparent;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: inherit;
    background: currentColor;
    opacity: 0.1;
    z-index: -1;
  }

  ${(props) =>
    props.error &&
    `
    color: #DC1B20;
  `}
`

export const Inner = styled.div`
  display: flex;
  min-height: 80px;

  ${media.mobile`
    display: block;
  `}
`

export const Heading = styled.h3`
  color: ${colors.PRIMARY};
  margin-top: 5px;
  margin-bottom: 10px;
`

export const Content = styled.div`
  color: ${colors.HEADING};
  flex: 1;
  min-width: 0;
`

export const IconWrapper = styled.div`
  flex: none;
  margin-right: 25px;
  width: 2em;
  height: 2em;
  border: 1px solid currentColor;
  border-radius: 100%;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.mobile`
    margin-right: 0;
    margin-bottom: 10px;
  `}
`

export const CheckIcon = styled(Icon).attrs({ icon: Check })``
export const CloseIcon = styled(Icon).attrs({ icon: Close })``

export const CheckWrapper = styled.div`
  margin: 10px 30px;
`

export const StyledProgressBar = styled(ProgressBar).attrs({
  active: true,
  height: 22,
})`
  margin-top: 20px;
`
