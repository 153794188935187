import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import DList from 'components-v2/molecules/DList'
import { Contact } from './styles'

const ContactsDisplay = ({ value }) => (
  <DList>
    <dd>
      {map(value, (contact) => (
        <Contact key={contact.email}>
          {contact.name}
          <br />
          <b>Email:</b> {contact.email} &nbsp;
          {contact.phone && (
            <>
              <b>Phone:</b> {contact.phone}
            </>
          )}
        </Contact>
      ))}
    </dd>
  </DList>
)

ContactsDisplay.propTypes = {
  value: PropTypes.array,
}

export default ContactsDisplay
