import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components-v2/atoms/Button'
import { LabelTypes } from 'constants/index'
import A from 'components-v2/atoms/A'
import {
  ButtonsWrapper,
  Company,
  CompanyLabel,
  Content,
  Description,
  Heading,
  Inner,
  Wrap,
} from './styles'

const LinkedGloborgAlertCard = ({
  onConfirm,
  onDisconnect,
  globalOrg,
  ...rest
}) => (
  <Wrap {...rest}>
    <Inner>
      <Content>
        <Heading>Does this look right?</Heading>
        <Description>
          We’ve automatically connected this company’s public profile for you
          based on a close match to the name of the{' '}
          {LabelTypes.VENDOR.toLowerCase()}.
        </Description>
        <Company>
          {/* {globalOrg.logo_url_small && <Logo src={globalOrg.logo_url_small} />} */}
          <CompanyLabel>{globalOrg.name}</CompanyLabel>
          {globalOrg.domain && (
            <A href={`https://${globalOrg.domain}`} target="_blank" underline>
              https://{globalOrg.domain}
            </A>
          )}
        </Company>
      </Content>
      <ButtonsWrapper>
        <Button color="primary" variant="outlined" onClick={onDisconnect}>
          Disconnect
        </Button>
        <Button color="primary" onClick={onConfirm}>
          Looks good!
        </Button>
      </ButtonsWrapper>
    </Inner>
  </Wrap>
)

LinkedGloborgAlertCard.propTypes = {
  onDisconnect: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  globalOrg: PropTypes.object.isRequired,
}

export default LinkedGloborgAlertCard
