import React from 'react'
import PropTypes from 'prop-types'
import { BarChart } from 'components-v2/molecules/Charts'
import Card from 'components-v2/organisms/Card'
import VendorResultsSummary from 'components/client/shared/VendorResultsSummary'
import { LabelTypes } from 'constants/index'
import config from 'config'
import { toLocalTimeString } from 'utils/date'
import { getPageUrl } from 'utils/url'
import { useAuthorizations } from 'hooks'
import {
  Wrapper,
  Top,
  Title,
  Toolbox,
  // PrintLink,
  // PrintIcon,
  OptionCheckbox,
  IconButton,
  Placeholder,
  ChartWrapper,
  CHART_HEIGHT,
  TooltipWrapper,
} from './styles'

const yAxisTickFormatter = (value) => `${value}%`
const barColorGetter = (entry) => entry.risk_rating_color
const barTooltipProps = {
  content: (() => {
    const Content = ({ active, payload, coordinate }) => {
      if (active && payload[0]) {
        return (
          <TooltipWrapper coordinate={coordinate}>
            <a
              href={getPageUrl('clientAssessment', {
                id: payload[0].payload.id,
              })}
            >
              {payload[0].payload.name}
            </a>
            <br />
            <span>Score: {yAxisTickFormatter(payload[0].value)}</span>
          </TooltipWrapper>
        )
      }
      return null
    }
    Content.propTypes = {
      active: PropTypes.bool,
      payload: PropTypes.arrayOf(
        PropTypes.shape({
          payload: PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
          }).isRequired,
          value: PropTypes.number.isRequired,
        }),
      ),
      coordinate: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
      }),
    }
    return Content
  })(),
}

const ResultsSummaryCard = ({
  projects,
  reviewedAssessments,
  hasMore,
  loading,
  archivedVisible,
  includeChildren,
  vendor,
  onToggleArchived,
  onToggleChildren,
  ...rest
}) => {
  const [listView, setListView] = React.useState(true)
  const [canViewArchived] = useAuthorizations(['view_archived', 'Assessment'])

  return (
    <Wrapper bordered {...rest}>
      <Card.Segment>
        <Top>
          <Title>Results Summary</Title>
          <Toolbox>
            {vendor.child_orgs.length > 0 && (
              <OptionCheckbox
                label="Include children"
                checked={includeChildren}
                onChange={(e) => onToggleChildren(e.target.checked)}
              />
            )}
            {canViewArchived && (
              <OptionCheckbox
                label="Show archived"
                checked={archivedVisible}
                onChange={(e) => onToggleArchived(e.target.checked)}
              />
            )}
            {/* <PrintLink href="#"><PrintIcon />Print</PrintLink> */}
            <IconButton active={!listView} onClick={() => setListView(false)}>
              <i className="fa fa-bar-chart" />
            </IconButton>
            <IconButton active={listView} onClick={() => setListView(true)}>
              <i className="fa fa-list-alt" />
            </IconButton>
          </Toolbox>
        </Top>
        {loading ? (
          <Placeholder>Loading...</Placeholder>
        ) : listView ? (
          projects.length > 0 ? (
            <VendorResultsSummary
              projects={projects}
              includeChildren={includeChildren}
            />
          ) : (
            <Placeholder>
              No projects found for {LabelTypes.VENDOR.toLowerCase()}.
            </Placeholder>
          )
        ) : reviewedAssessments.length > 0 ? (
          <ChartWrapper>
            <BarChart
              width="100%"
              height={CHART_HEIGHT}
              data={reviewedAssessments}
              dataKey="risk_rating"
              xAxisDataKey="reviewed_at"
              xAxisLabel="Date of results"
              xAxisTickFormatter={(val) =>
                toLocalTimeString(val, config.tableDateFormat)
              }
              yAxisLabel="Score"
              yAxisTickFormatter={yAxisTickFormatter}
              barColorGetter={barColorGetter}
              tooltipProps={barTooltipProps}
            />
          </ChartWrapper>
        ) : (
          <Placeholder>
            No reviewed assessments found for {LabelTypes.VENDOR.toLowerCase()}.
          </Placeholder>
        )}
      </Card.Segment>
      {!loading && listView && hasMore && (
        <Card.ViewMoreSegment
          href={getPageUrl(
            'clientVendorResultsSummary',
            { id: vendor.id },
            { includeChildren },
          )}
        >
          View All
        </Card.ViewMoreSegment>
      )}
    </Wrapper>
  )
}

ResultsSummaryCard.propTypes = {
  projects: PropTypes.array,
  reviewedAssessments: PropTypes.array,
  hasMore: PropTypes.bool,
  loading: PropTypes.bool,
  archivedVisible: PropTypes.bool,
  includeChildren: PropTypes.bool,
  vendor: PropTypes.object.isRequired,
  onToggleArchived: PropTypes.func.isRequired,
  onToggleChildren: PropTypes.func.isRequired,
}

ResultsSummaryCard.defaultProps = {
  projects: [],
  reviewedAssessments: [],
}

export default React.memo(ResultsSummaryCard)
