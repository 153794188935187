import { useCallback } from 'react'
import { useGlobalLoader, useCurrent } from 'hooks'
import NotificationManager from 'lib/notifications'
import { useUpdateClientAssessmentTemplate } from 'apis'
import useSetAssessmentTemplate from './useSetAssessmentTemplate'

export default function useChangeTemplateAttribute(assessmentTemplate) {
  const { mutateAsync: updateClientAssessmentTemplate } =
    useUpdateClientAssessmentTemplate()
  const assessmentTemplateRef = useCurrent(assessmentTemplate)
  const [showLoader, hideLoader] = useGlobalLoader()
  const setAssessmentTemplate = useSetAssessmentTemplate(assessmentTemplate.id)

  return useCallback(
    (attribute, value) => {
      const oldValue = assessmentTemplateRef.current[attribute]
      // Update UI first
      setAssessmentTemplate((current) => ({
        ...current,
        [attribute]: value,
      }))
      const loaderId = showLoader({ withDelay: true })
      const data = {
        assessment_template: {
          [attribute]: value,
        },
      }
      return updateClientAssessmentTemplate({
        id: assessmentTemplateRef.current.id,
        data,
      })
        .catch(() => {
          NotificationManager.error()
          // Revert the changes
          setAssessmentTemplate((current) => ({
            ...current,
            [attribute]: oldValue,
          }))
        })
        .then(() => hideLoader(loaderId))
    },
    [showLoader, hideLoader, setAssessmentTemplate],
  )
}
