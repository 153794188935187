import React from 'react'
import PropTypes from 'prop-types'
import FormField from 'components-v2/molecules/FormField'
import FormDialog from 'components-v2/organisms/FormDialog'
import {
  AssessmentClientSubmitReasonTypes,
  AssessmentClientSubmitReasonOptions,
} from 'constants/index'
import { toLocalTimeString } from 'utils/date'
import { Warning } from './styles'

const formConfig = {
  initialValues: {
    client_submit_reason: AssessmentClientSubmitReasonTypes.DID_NOT_RESPOND,
  },
}

const ExpiredSubmitModal = ({ assessment, ...rest }) => (
  <FormDialog
    {...rest}
    title={`Submit ${assessment.assessment_template.name}`}
    formConfig={formConfig}
  >
    <p>
      {assessment.assessment_template.name} expired on{' '}
      <b>{toLocalTimeString(assessment.expired_at, 'M/d/yy')}</b>.
      <Warning>
        Submitting the assessment will <b>clear any answers</b> provided by the
        vendor.
      </Warning>
    </p>
    <FormField
      name="client_submit_reason"
      label="Reason for Submission"
      type={FormField.types.SELECT}
      options={AssessmentClientSubmitReasonOptions}
      preserveHelpTextSpace={false}
      horizontal
    />
  </FormDialog>
)

ExpiredSubmitModal.propTypes = {
  assessment: PropTypes.object.isRequired,
}

export default ExpiredSubmitModal
