import React from 'react'
import PropTypes from 'prop-types'
import FormField from 'components-v2/molecules/FormField'
import Default from './Default'

const toFormValues = (partner) => ({
  ...Default.toFormValues(partner),
  custom_login_url: partner.is_custom_login_url ? partner.login_url : '',
})

const fromFormValues = (formValues) => ({
  ...Default.fromFormValues(formValues),
  custom_login_url: formValues.custom_login_url,
})

const BitSightFields = ({ data }) => (
  <>
    <Default data={data} />
    <FormField name="custom_login_url" label="Custom Login URL" />
  </>
)

BitSightFields.propTypes = {
  data: PropTypes.object.isRequired,
}

BitSightFields.validationSchema = Default.validationSchema
BitSightFields.toFormValues = toFormValues
BitSightFields.fromFormValues = fromFormValues

export default BitSightFields
