import React from 'react'
import PropTypes from 'prop-types'
import Table from 'components-v2/molecules/Table'
import TableToolbar, {
  SearchInput,
} from 'components-v2/molecules/Table/Toolbar'
import { dateFormatterFactory } from 'lib/table'
import { SortOrderTypes } from 'constants/index'

const dateFormatter = dateFormatterFactory()
const tableColumns = [
  {
    id: 'friendly_name',
    header: 'Project',
    cell: (info) => (
      <a href={info.row.original.url} title={info.getValue()}>
        {info.getValue()}
      </a>
    ),
    width: '40%',
  },
  {
    id: 'assessment_submissions_count',
    header: 'Assessments Status',
    cell: (info) =>
      info.getValue() || info.getValue() === 0
        ? `${info.getValue()}/${info.row.original.assessment_templates_count}`
        : '',
    sortingFn: (rowA, rowB) => {
      const completenessA = rowA.original.assessment_submissions_count
        ? parseInt(rowA.original.assessment_submissions_count, 10) /
          parseInt(rowA.original.assessment_templates_count, 10)
        : 0
      const completenessB = rowB.original.assessment_submissions_count
        ? parseInt(rowB.original.assessment_submissions_count, 10) /
          parseInt(rowB.original.assessment_templates_count, 10)
        : 0
      return completenessA < completenessB
        ? -1
        : completenessB < completenessA
        ? 1
        : 0
    },
    width: '20%',
  },
  {
    id: 'status',
    header: 'Project Status',
    width: '20%',
  },
  {
    id: 'launched_at',
    header: 'Launched',
    cell: dateFormatter,
    width: '10%',
  },
  {
    id: 'due_at',
    header: 'Due',
    cell: dateFormatter,
    width: '10%',
  },
  {
    id: 'dfName',
    header: '',
    cell: (info) => info.row.original.name,
    hidden: true,
  },
]

const View = ({ projects, loading, searchText, onSearch }) => (
  <div>
    <TableToolbar>
      <SearchInput value={searchText} onChange={onSearch} />
    </TableToolbar>
    <Table
      loading={loading}
      data={projects || []}
      columns={tableColumns}
      defaultSorting={{
        sortField: 'friendly_name',
        sortOrder: SortOrderTypes.ASC,
      }}
      enablePagination={false}
      globalFilter={searchText}
      onGlobalFilterChange={onSearch}
      noDataText="No projects found."
      type={Table.types.ALT}
    />
  </div>
)

View.propTypes = {
  projects: PropTypes.array,
  loading: PropTypes.bool,
  searchText: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
}

export default View
