import React from 'react'
import PropTypes from 'prop-types'
import { Tab as HeadlessTab } from '@headlessui/react'
import styled from 'styled-components'
import { colors, mixins } from 'styles'
import { toUncontrollable } from 'hocs'
import { useCurrent } from 'hooks'

const TabList = styled.div`
  ${mixins.hidePrint}
`

const TabItem = styled.button`
  ${mixins.clearButtonStyle}
  font-weight: 500;
  color: ${colors.BODY_TEXT};
  border-top: 4px solid transparent;
  margin-right: 24px;
  padding: 20px 0 10px;

  &:hover,
  &:focus {
    color: ${colors.PRIMARY};
  }

  ${({ selected }) =>
    selected &&
    `
    color: ${colors.PRIMARY};
    border-top-color: ${colors.PRIMARY};
  `}
`

const TabItemBase = React.forwardRef(
  ({ forwardedAs: Component, ...props }, ref) => (
    <Component
      ref={ref}
      {...props}
      selected={props['data-headlessui-state'] === 'selected'}
      onMouseDown={() => {}} // Hacky solution to prevent setting focus-visible on click by ignoring HeadlessTab's internal onMouseDown handler
    />
  ),
)

TabItemBase.propTypes = {
  forwardedAs: PropTypes.elementType,
  'data-headlessui-state': PropTypes.string,
}

const PanelBase = React.forwardRef(
  ({ forwardedAs: Component, ...props }, ref) => (
    <Component
      ref={ref}
      {...props}
      selected={props['data-headlessui-state'] === 'selected'}
    />
  ),
)

PanelBase.propTypes = {
  forwardedAs: PropTypes.elementType,
  'data-headlessui-state': PropTypes.string,
}

const Panels = styled.div`
  margin-top: 35px;
`

// eslint-disable-next-line no-unused-vars
export function Tab({ eventKey, title, disabled, unmount, children }) {
  return null
}
Tab.propTypes = {
  eventKey: PropTypes.string,
  title: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  unmount: PropTypes.bool,
  children: PropTypes.node,
}

const BaseTabs = ({
  activeKey,
  onChange,
  RenderTabList = TabList,
  RenderTabItem = TabItem,
  RenderPanels = Panels,
  RenderPanel = 'div',
  children,
  ...rest
}) => {
  const validChildren = React.Children.toArray(children)
    .filter((child) => React.isValidElement(child))
    .map((child) => child)
  const validChildrenRef = useCurrent(validChildren)
  const selectedIndex =
    typeof activeKey === 'string'
      ? Math.max(
          validChildren.findIndex(
            (child) => child.props.eventKey === activeKey,
          ),
          0,
        )
      : activeKey
  const handleChange = React.useCallback(
    (index) =>
      onChange(validChildrenRef.current[index].props.eventKey ?? index),
    [onChange],
  )
  return (
    <HeadlessTab.Group
      as="div"
      {...rest}
      selectedIndex={selectedIndex}
      onChange={handleChange}
    >
      <HeadlessTab.List as={RenderTabList}>
        {validChildren.map((child) => (
          <HeadlessTab
            as={TabItemBase}
            key={child.props.eventKey}
            disabled={child.props.disabled}
            forwardedAs={RenderTabItem}
          >
            {child.props.title}
          </HeadlessTab>
        ))}
      </HeadlessTab.List>
      <HeadlessTab.Panels as={RenderPanels}>
        {validChildren.map((child) => (
          <HeadlessTab.Panel
            as={PanelBase}
            key={child.props.eventKey}
            unmount={child.props.unmount}
            forwardedAs={RenderPanel}
          >
            {child.props.children}
          </HeadlessTab.Panel>
        ))}
      </HeadlessTab.Panels>
    </HeadlessTab.Group>
  )
}

BaseTabs.propTypes = {
  activeKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  manual: PropTypes.bool,
  RenderTabList: PropTypes.elementType,
  RenderTabItem: PropTypes.elementType,
  RenderPanels: PropTypes.elementType,
  RenderPanel: PropTypes.elementType,
  children: PropTypes.node.isRequired,
}

const Tabs = toUncontrollable({ activeKey: 'onChange' })(BaseTabs)

Tabs.Tab = Tab

export default Tabs
