import styled from 'styled-components'
import { transparentize } from 'polished'
import { ExternalLinkAlt } from '@styled-icons/fa-solid/ExternalLinkAlt'
import { Cog } from '@styled-icons/fa-solid/Cog'
import { Envelope } from '@styled-icons/fa-solid/Envelope'
import A from 'components-v2/atoms/A'
import Icon from 'components-v2/atoms/Icon'

export const CompanyLink = styled(A)`
  position: relative;

  span {
    text-decoration: underline;
  }

  &:after {
    content: '\f08e';
    margin-left: 5px;
    margin-top: 5px;
    font: normal normal normal 12px/1 FontAwesome;
    color: #ccc;
  }
`

export const CircleRate = styled.span.attrs((p) => ({
  color: p.color || '#000',
}))`
  width: 22px;
  display: inline-block;
  height: 22px;
  line-height: 20px;
  font-size: ${(props) => (props.small ? '9px;' : '13px;')}
  font-weight: 600;
  text-align: center;
  border: 1px solid ${(props) => props.color};
  border-radius: 100%;
  background: ${(props) => transparentize(0.8, props.color)};
  color: ${(props) => props.color};
`

export const RoundedRate = styled.span.attrs((p) => ({
  color: p.color || '#000',
}))`
  display: inline-block;
  padding: 0 7px;
  height: 22px;
  line-height: 20px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.07em;
  text-align: center;
  border: 1px solid ${(props) => props.color};
  border-radius: 5px;
  background: ${(props) => transparentize(0.8, props.color)};
  color: ${(props) => props.color};
`

export const ErrorContainer = styled.div`
  background: #f1f3f7;
  border-radius: 5px;
  padding: 60px 15px;
  text-align: center;
`

export const ErrorTitle = styled.h3`
  color: #000;
`

export const ErrorDescription = styled.p`
  color: #4f4f4f;
`

export const ErrorButtonsWrapper = styled.div`
  margin-top: 40px;
  > * {
    margin: 0 5px;
  }
`

export const ExternalLinkIcon = styled(Icon).attrs({ icon: ExternalLinkAlt })``
export const CogIcon = styled(Icon).attrs({ icon: Cog })``
export const EnvelopeIcon = styled(Icon).attrs({ icon: Envelope })``
