import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Wrapper = styled.div`
  text-align: center;
`

const Error = ({ message, ...rest }) => <Wrapper {...rest}>{message}</Wrapper>

Error.propTypes = {
  message: PropTypes.string,
}

Error.defaultProps = {
  message: 'An error occurred. Please try again.',
}

export default Error
