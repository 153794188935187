import React from 'react'
import PropTypes from 'prop-types'
import BaseField from './BaseField'
import { CreatableSelect } from '../SearchableSelect'

const CreatableSelectField = ({
  name,
  value,
  options,
  disabled,
  controlProps = {},
  onChange,
  size,
  showError,
  ...rest
}) => (
  <BaseField {...rest} size={size} showError={showError}>
    <CreatableSelect
      {...controlProps}
      name={name}
      value={value}
      options={options}
      isDisabled={disabled}
      size={size}
      error={showError}
      onChange={onChange}
    />
  </BaseField>
)

CreatableSelectField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  controlProps: PropTypes.object,
  size: PropTypes.string,
  showError: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

export default CreatableSelectField
