import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import head from 'lodash/head'
import get from 'lodash/get'
import map from 'lodash/map'
import keyBy from 'lodash/keyBy'
import isNumber from 'lodash/isNumber'
import Privva from 'Privva'
import Tooltip from 'components-v2/molecules/Tooltip'
import { Row, Col } from 'components-v2/organisms/Layout'
import { compose, hasLoader, hasError } from 'hocs'
import { useAuthorizations } from 'hooks'
import { getRiskRatingColor } from 'lib/assessment'
import { toLocalTimeString } from 'utils/date'
import {
  GloborgIntegrationTypes,
  GloborgIntegrationOptions,
} from 'constants/index'
import PrivvaLogo from 'assets/images/privva-logo-blue.png'
import SmarshLogo from 'assets/images/smarsh-logo-blue.png'
import GloborgIntegrationOverviewCard, {
  BaseOverviewCard,
} from '../shared/GloborgIntegrationOverviewCard'
import PrivvaAssessmentMetric from './PrivvaAssessmentMetric'
import SpyCloudMetric from './SpyCloudMetric'
import SectionTitle from './SectionTitle'
import MetricCard from './MetricCard'
import AssessmentTable from './AssessmentTable'
import AssessmentRiskRatingCard from './AssessmentRiskRatingCard'
import IntegrationScoreCard from './IntegrationScoreCard'
import RiskReconOverallScoreCard from './RiskReconOverallScoreCard'
import SpyCloudRecordsCard from './SpyCloudRecordsCard'
import OsanoScoreCard from './OsanoScoreCard'
import OFACInfoCard from './OFACInfoCard'
import OFACSanctionStatusCard from './OFACSanctionStatusCard'
import {
  parseArgosRiskData,
  parseRiskReconData,
  parseSpyCloudData,
  parseOsanoData,
} from '../lib/globorg_integration_metrics'

import {
  theme,
  Section,
  SectionHeader,
  ShowArchivedCheckbox,
  TwentyCol,
  OsanoContributingFactor,
  OsanoPrivacyImpact,
} from './styles'

const GloborgIntegrationOptionsObj = keyBy(GloborgIntegrationOptions, 'value')
const dateFormatter = (str) => toLocalTimeString(str, 'M/d/yy')
const ofacTooltip = `Information provided here is on a best effort to match vendors by names, addresses, websites etc. Due to a relatively broad data set and generic aliases, false hits may be generated. ${Privva.Utils.brand.programName} recommends all results should be confirmed with The Office of Foreign Assets Control ("OFAC") - https://home.treasury.gov/policy-issues/financial-sanctions/sanctions-list-search-tool`
const BrandLogo = Privva.Utils.brand.brandSmarsh ? SmarshLogo : PrivvaLogo

const View = ({
  privvaRiskAssessmentVisible,
  riskReconMoreInformationAvailable,
  vendorId,
  assessmentsData,
  assessmentForRiskDomainScores,
  riskDomains,
  includeArchivedAssessments,
  integrationMetrics,
  onToggleArchivedAssessments,
  onRiskReconIssuesCountClick,
  onGetMoreInformation,
}) => {
  const [canViewArchived] = useAuthorizations(['view_archived', 'Assessment'])
  const latestAssessment = head(assessmentsData.data)
  const argosRisk = get(integrationMetrics, GloborgIntegrationTypes.ARGOS_RISK)
  const riskRecon = get(integrationMetrics, GloborgIntegrationTypes.RISK_RECON)
  const spyCloud = get(integrationMetrics, GloborgIntegrationTypes.SPY_CLOUD)
  const osano = get(integrationMetrics, GloborgIntegrationTypes.OSANO)
  const ofac = get(integrationMetrics, GloborgIntegrationTypes.OFAC)
  const argosRiskData = React.useMemo(() => {
    if (!argosRisk) {
      return undefined
    }
    const [primary, ...metrics] = parseArgosRiskData(argosRisk)
    const updatedAt = dateFormatter(argosRisk.date)
    return { primary, metrics, updatedAt }
  }, [argosRisk])
  const riskReconData = React.useMemo(() => {
    if (!riskRecon) {
      return undefined
    }
    const [primary, ...metrics] = parseRiskReconData(riskRecon)
    const filteredMetrics = metrics.filter((e) => e.type !== 'issue_count')
    const updatedAt = dateFormatter(riskRecon.date)
    return { primary, metrics: filteredMetrics, updatedAt }
  }, [riskRecon])
  const spyCloudData = React.useMemo(() => {
    if (!spyCloud) {
      return undefined
    }
    const [primary, ...metrics] = parseSpyCloudData(spyCloud)
    const updatedAt = dateFormatter(spyCloud.date)
    return { primary, metrics, updatedAt }
  }, [spyCloud])
  const osanoData = React.useMemo(() => {
    if (!osano) {
      return undefined
    }
    const data = parseOsanoData(osano)
    return {
      ...data,
      updatedAt: dateFormatter(data.updated_at),
    }
  }, [osano])
  const riskDomainScoresUpdatedAt = React.useMemo(
    () =>
      assessmentForRiskDomainScores
        ? dateFormatter(assessmentForRiskDomainScores.updated_at)
        : undefined,
    [assessmentForRiskDomainScores],
  )
  return (
    <ThemeProvider theme={theme}>
      <Section>
        <SectionHeader>
          <SectionTitle title="Risk Profile Overview" />
        </SectionHeader>
        <Row $equalHeight break="mobile">
          {privvaRiskAssessmentVisible && (
            <Col key="privva" sm={3} lg={2}>
              <BaseOverviewCard
                title={
                  <span>
                    Recent {Privva.Utils.brand.programName}
                    <br />
                    assessment
                  </span>
                }
                description={
                  latestAssessment
                    ? `Sent ${dateFormatter(latestAssessment.sent_at)}`
                    : ''
                }
                loading={assessmentsData.isLoading}
                error={assessmentsData.isError}
                noData={!latestAssessment}
                detailsUrl="#section_privva"
              >
                {latestAssessment && (
                  <PrivvaAssessmentMetric assessment={latestAssessment} />
                )}
              </BaseOverviewCard>
            </Col>
          )}
          {GloborgIntegrationOptions.map(({ value }) => {
            const data = integrationMetrics?.[value]
            if (!data) {
              return null
            }
            return (
              <Col key={value} sm={3} lg={2}>
                <GloborgIntegrationOverviewCard
                  type={value}
                  data={data}
                  detailsUrl={`#section_${value}`}
                />
              </Col>
            )
          })}
        </Row>
      </Section>
      {privvaRiskAssessmentVisible && (
        <Section id="section_privva">
          <SectionHeader>
            <SectionTitle
              title="Detailed Risk Assessment"
              sourceLogo={BrandLogo}
              sourceLogoWidth={96}
            />
            {canViewArchived && (
              <ShowArchivedCheckbox
                label="Show archived"
                checked={includeArchivedAssessments}
                onChange={onToggleArchivedAssessments}
              />
            )}
          </SectionHeader>
          <Row $equalHeight break="mobile">
            {assessmentForRiskDomainScores && riskDomains && (
              <>
                <Col lg={4} sm={12}>
                  <AssessmentRiskRatingCard
                    assessment={assessmentForRiskDomainScores}
                  />
                </Col>
                <Col lg={8} sm={12}>
                  <Row>
                    {map(riskDomains, (e) => {
                      const riskRating = get(
                        assessmentForRiskDomainScores,
                        `risk_domain_ratings.${e.id}`,
                      )
                      return (
                        <TwentyCol key={e.id}>
                          <MetricCard
                            label={e.name}
                            value={isNumber(riskRating) ? riskRating : 'N/A'}
                            color={getRiskRatingColor(riskRating)}
                            updatedAt={riskDomainScoresUpdatedAt}
                          />
                        </TwentyCol>
                      )
                    })}
                  </Row>
                </Col>
              </>
            )}
            <Col sm={12}>
              <AssessmentTable
                assessmentsData={assessmentsData}
                vendorId={vendorId}
              />
            </Col>
          </Row>
        </Section>
      )}
      {riskRecon && (
        <Section id={`section_${GloborgIntegrationTypes.RISK_RECON}`}>
          <SectionHeader>
            <SectionTitle
              title="Continuous Risk Assessment"
              {...GloborgIntegrationOptionsObj[
                GloborgIntegrationTypes.RISK_RECON
              ]}
              sourceLogoWidth={80}
            />
          </SectionHeader>
          <Row $equalHeight break="mobile">
            <Col lg={4} sm={12}>
              <RiskReconOverallScoreCard
                data={riskRecon}
                onIssuesCountClick={onRiskReconIssuesCountClick}
              />
            </Col>
            <Col lg={8} sm={12}>
              <Row>
                {riskReconData.metrics.map((data) => {
                  if (data.baseMetric) {
                    return (
                      <TwentyCol key={data.category}>
                        <MetricCard
                          label={data.category}
                          value={data.value}
                          color={data.color}
                          description={data.description}
                          updatedAt={riskReconData.updatedAt}
                          onGetMoreInformation={
                            riskReconMoreInformationAvailable
                              ? () =>
                                  onGetMoreInformation(
                                    GloborgIntegrationTypes.RISK_RECON,
                                    data.code,
                                  )
                              : undefined
                          }
                        />
                      </TwentyCol>
                    )
                  }
                  return ''
                })}
              </Row>
            </Col>
          </Row>
        </Section>
      )}
      {osano && (
        <Section id={`section_${GloborgIntegrationTypes.OSANO}`}>
          <SectionHeader>
            <SectionTitle
              title="Privacy Monitoring"
              {...GloborgIntegrationOptionsObj[GloborgIntegrationTypes.OSANO]}
              sourceLogoWidth={84}
            />
          </SectionHeader>
          <Row>
            <Col lg={4}>
              <OsanoScoreCard
                score={osanoData.value}
                rangeLabel={osanoData.rangeLabel}
                color={osanoData.color}
              />
            </Col>
            <Col lg={8}>
              <Row $equalHeight break="mobile">
                <Col sm={6}>
                  <MetricCard
                    label="Privacy impact"
                    value={
                      <OsanoPrivacyImpact>
                        {osanoData.rangeDescription}
                      </OsanoPrivacyImpact>
                    }
                  />
                </Col>
                <Col sm={4}>
                  <MetricCard
                    label="Contributing factors"
                    value={
                      <>
                        {osanoData.contributingFactors.map((data) => (
                          <OsanoContributingFactor key={data.name}>
                            <Tooltip parent={<span>{data.name}</span>}>
                              {data.description}
                            </Tooltip>
                          </OsanoContributingFactor>
                        ))}
                      </>
                    }
                    updatedAt={osanoData.updatedAt}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Section>
      )}
      {spyCloud && (
        <Section id={`section_${GloborgIntegrationTypes.SPY_CLOUD}`}>
          <SectionHeader>
            <SectionTitle
              title="Dark Web Monitoring"
              {...GloborgIntegrationOptionsObj[
                GloborgIntegrationTypes.SPY_CLOUD
              ]}
              sourceLogoWidth={84}
            />
          </SectionHeader>
          <Row>
            <Col lg={4}>
              <SpyCloudRecordsCard
                records={spyCloudData.primary.value}
                description={spyCloudData.primary.description}
                updatedAt={spyCloudData.updatedAt}
              />
            </Col>
            <Col lg={8}>
              <Row>
                {spyCloudData.metrics.map((data) => (
                  <Col key={data.category} sm={3}>
                    <MetricCard
                      label={data.category}
                      value={<SpyCloudMetric records={data.value} />}
                      description={data.description}
                      updatedAt={spyCloudData.updatedAt}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Section>
      )}
      {argosRisk && (
        <Section id={`section_${GloborgIntegrationTypes.ARGOS_RISK}`}>
          <SectionHeader>
            <SectionTitle
              title="Business Health"
              {...GloborgIntegrationOptionsObj[
                GloborgIntegrationTypes.ARGOS_RISK
              ]}
              sourceLogoWidth={90}
            />
          </SectionHeader>
          <Row $equalHeight break="mobile">
            <Col lg={4} sm={12}>
              <IntegrationScoreCard
                title="Argos Risk Score"
                {...argosRiskData.primary}
                updatedAt={argosRiskData.updatedAt}
                companyName={argosRisk.integration_name}
                companyAddress={argosRisk.integration_address}
                companyHosts={argosRisk.integration_hosts}
              />
            </Col>
            <Col lg={8} sm={12}>
              <Row>
                {argosRiskData.metrics.map((data) => (
                  <Col key={data.category} sm={3}>
                    <MetricCard
                      label={data.category}
                      value={data.value}
                      color={data.color}
                      description={data.description}
                      updatedAt={argosRiskData.updatedAt}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Section>
      )}
      {ofac && (
        <Section id={`section_${GloborgIntegrationTypes.OFAC}`}>
          <SectionHeader>
            <SectionTitle
              title="OFAC Check"
              sourceLogo={BrandLogo}
              sourceLogoWidth={96}
              information={ofacTooltip}
            />
          </SectionHeader>
          <Row $equalHeight break="mobile">
            <Col lg={4} sm={12}>
              <OFACSanctionStatusCard data={ofac} />
            </Col>
            <Col lg={8} sm={12}>
              <OFACInfoCard />
            </Col>
          </Row>
        </Section>
      )}
    </ThemeProvider>
  )
}

View.propTypes = {
  privvaRiskAssessmentVisible: PropTypes.bool,
  riskReconMoreInformationAvailable: PropTypes.bool,
  vendorId: PropTypes.string,
  assessmentsData: PropTypes.object,
  assessmentForRiskDomainScores: PropTypes.object,
  riskDomains: PropTypes.array,
  includeArchivedAssessments: PropTypes.bool,
  integrationMetrics: PropTypes.object.isRequired,
  onToggleArchivedAssessments: PropTypes.func,
  onRiskReconIssuesCountClick: PropTypes.func.isRequired,
  onGetMoreInformation: PropTypes.func,
}

View.defaultProps = {
  assessmentsData: {},
}

export default compose(hasLoader, hasError())(View)
