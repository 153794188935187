import React, { useState, useRef, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import reject from 'lodash/reject'
import includes from 'lodash/includes'
import filter from 'lodash/filter'
import useAddProjectVendors from 'components/client/shared/useAddProjectVendors'
import {
  useClientProjectsWithCountsFetcher,
  useUpdateUserPreferences,
} from 'apis'
import { compose, withAuthorize, withReactQuery } from 'hocs'
import { useUpdateEffect, useQueryParams } from 'hooks'
import {
  ProjectStatusTypes,
  ProjectStatusOptions,
  SortOrderTypes,
} from 'constants/index'
import config from 'config'
import useCreateProject from './useCreateProject'
import View from './View'
import { queryParamsConfig } from './constants'

const ALL = 'all'
const statusFilters = [
  { value: ALL, label: 'All projects', color: '#404144' },
  ...filter(ProjectStatusOptions, (o) =>
    includes(
      [
        ProjectStatusTypes.DRAFT,
        ProjectStatusTypes.LAUNCHED,
        ProjectStatusTypes.CLOSED,
        ProjectStatusTypes.REVIEWED,
      ],
      o.value,
    ),
  ),
]

const getFinalStatusFilter = (status, userPrefs, counts) =>
  status ||
  userPrefs?.status ||
  (counts?.[ProjectStatusTypes.LAUNCHED] > 0
    ? ProjectStatusTypes.LAUNCHED
    : ALL)

const Container = ({ showGloborg, orgId, userPrefs, canCreate }) => {
  const { mutateAsync: updateUserPreferences } = useUpdateUserPreferences()
  const addProjectVendors = useAddProjectVendors()
  const createProject = useCreateProject()
  const [queryParams, setQueryParams] = useQueryParams(queryParamsConfig)
  const { sortField, sortOrder, status } = queryParams
  const [page, setPage] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(
    config.collectionQueryPageLimit.default,
  )
  const [searchText, setSearchText] = useState('')
  const userPrefsRef = useRef(userPrefs)
  const finalSortField =
    sortField || userPrefs?.ordered_by || 'earliest_launch_at'
  const finalSortOrder =
    sortOrder || userPrefs?.direction || SortOrderTypes.DESC
  const {
    data: { projects = [], counts } = {},
    totalSize,
    isFetching,
    refetch,
  } = useClientProjectsWithCountsFetcher(
    {
      params: {
        ordered_by: finalSortField,
        direction: finalSortOrder,
        page,
        per_page: sizePerPage,
        search_query: searchText,
        status:
          getFinalStatusFilter(status, userPrefs, counts) !== ALL
            ? getFinalStatusFilter(status, userPrefs, counts)
            : undefined,
        only_archived:
          getFinalStatusFilter(status, userPrefs, counts) ===
          ProjectStatusTypes.ARCHIVED
            ? true
            : undefined,
      },
    },
    {
      keepPreviousData: true,
    },
  )
  const finalStatusFilter = getFinalStatusFilter(status, userPrefs, counts)

  const updateUserPrefs = useCallback(
    (newProperties) => {
      const preferences = {
        organization_id: orgId,
        table_settings: {
          projects: {
            ...(userPrefsRef.current || {}),
            ...newProperties,
          },
        },
      }
      updateUserPreferences({ data: { preferences } }).then((data) => {
        // Don't want to rerender or change view state by new userPrefs from server
        userPrefsRef.current = data?.table_settings?.projects
      })
    },
    [orgId, updateUserPreferences],
  )

  useUpdateEffect(() => {
    updateUserPrefs({ ordered_by: sortField, direction: sortOrder })
  }, [sortField, sortOrder])

  useUpdateEffect(() => {
    updateUserPrefs({ status })
  }, [status])

  const handleStatusFilterChange = useCallback((statusFilter) => {
    setPage(1)
    setQueryParams({
      status: statusFilter,
    })
  }, [])

  const handleToggleArchived = useCallback((e) => {
    setPage(1)
    setQueryParams({
      status: e.target.checked ? ProjectStatusTypes.ARCHIVED : ALL,
    })
  }, [])

  const handlePaginationChange = useCallback((newState) => {
    setPage(newState.page)
    setSizePerPage(newState.sizePerPage)
  }, [])

  const handleSearchChange = useCallback((newSearchText) => {
    // Let's reset page to 1
    setPage(1)
    setSearchText(newSearchText)
  }, [])

  const handleAddProjectVendor = useCallback(
    (projectId) => {
      addProjectVendors({
        projectId,
        showGloborg,
        onProjectVendorsAdded: () => refetch(),
      })
    },
    [showGloborg, addProjectVendors, refetch],
  )

  const statusFiltersWithCount = useMemo(() => {
    if (!counts) {
      return []
    }
    const filtersWithCount = statusFilters.map((e) => ({
      ...e,
      count: counts[e.value === ALL ? 'total' : e.value] ?? 0,
    }))
    return reject(
      filtersWithCount,
      (e) => e.count === 0 && e.value !== finalStatusFilter,
    )
  }, [counts, finalStatusFilter])

  return (
    <View
      projects={projects}
      loading={isFetching}
      canCreate={canCreate}
      totalSize={totalSize}
      page={page}
      sizePerPage={sizePerPage}
      sortField={finalSortField}
      sortOrder={finalSortOrder}
      searchText={searchText}
      statusFilter={finalStatusFilter}
      statusFiltersWithCount={statusFiltersWithCount}
      archivedSelected={finalStatusFilter === ProjectStatusTypes.ARCHIVED}
      onStatusFilterChange={handleStatusFilterChange}
      onToggleArchived={handleToggleArchived}
      onAddProjectVendor={handleAddProjectVendor}
      onPaginationChange={handlePaginationChange}
      onSortingChange={setQueryParams}
      onSearchChange={handleSearchChange}
      onCreateProjectClick={createProject}
    />
  )
}

Container.propTypes = {
  orgId: PropTypes.string.isRequired,
  showGloborg: PropTypes.bool,
  userPrefs: PropTypes.object,
  canCreate: PropTypes.bool,
}

export default compose(withAuthorize(), withReactQuery())(Container)
