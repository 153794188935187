import React from 'react'
import PropTypes from 'prop-types'

import Icon from 'components-v2/atoms/Icon'
import Editable from 'components-v2/molecules/Editable'
import Menu from 'components-v2/molecules/Menu'
import Tooltip from 'components-v2/molecules/Tooltip'
import { LabelTypes } from 'constants/index'

import {
  GroupTitleRow,
  GroupTitle,
  TitleBarSpinner,
  GroupIconSet,
  ArrowUpIcon,
  ArrowDownIcon,
  EllipsisIconButton,
  QuestionCircleIcon,
} from '../styles'

const GroupTitleBar = ({
  group,
  groupIdx,
  groupCount,
  loaded,
  onRename,
  onSort,
  onHide,
  onUnhide,
  onDelete,
}) => (
  <GroupTitleRow>
    <GroupTitle>
      <Editable
        dataType="text"
        mode="inline"
        value={group.name}
        onSubmit={(value) => onRename(group.key, value)}
      />
    </GroupTitle>

    {!loaded && (
      <TitleBarSpinner>
        <i className="fa fa-spinner fa-spin" /> Loading...
      </TitleBarSpinner>
    )}

    <GroupIconSet>
      {groupCount > 1 && (
        <Menu placement="top-start" offset={8} portal>
          <Menu.Button as={EllipsisIconButton} />
          <Menu.Items size="small" arrow>
            {groupIdx > 0 && (
              <Menu.Item onClick={() => onSort(groupIdx, groupIdx - 1)}>
                <ArrowUpIcon />
                Move Up
              </Menu.Item>
            )}

            {groupIdx < groupCount - 1 && (
              <Menu.Item onClick={() => onSort(groupIdx, groupIdx + 1)}>
                <ArrowDownIcon />
                Move Down
              </Menu.Item>
            )}

            {group.hidden ? (
              <Menu.Item onClick={() => onUnhide(group.key)}>
                <Icon icon="fa fa-eye" />
                <span>
                  Unhide Group
                  <Tooltip parent={QuestionCircleIcon}>
                    Make this group and its fields visible on the{' '}
                    {LabelTypes.VENDOR} info page
                  </Tooltip>
                </span>
              </Menu.Item>
            ) : (
              <Menu.Item onClick={() => onHide(group.key)}>
                <Icon icon="fa fa-eye-slash" />
                <span>
                  Hide Group
                  <Tooltip parent={QuestionCircleIcon}>
                    Hide this group and its fields from being displayed on the{' '}
                    {LabelTypes.VENDOR} info page
                  </Tooltip>
                </span>
              </Menu.Item>
            )}

            <Menu.Item onClick={() => onDelete(group.key)}>
              <Icon icon="fa fa-trash" />
              Remove Group
            </Menu.Item>
          </Menu.Items>
        </Menu>
      )}
    </GroupIconSet>
  </GroupTitleRow>
)

GroupTitleBar.propTypes = {
  group: PropTypes.object.isRequired,
  groupIdx: PropTypes.number.isRequired,
  groupCount: PropTypes.number.isRequired,
  loaded: PropTypes.bool.isRequired,
  onRename: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  onUnhide: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default GroupTitleBar
