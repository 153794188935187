import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'components-v2/atoms/Icon'
import Button from 'components-v2/atoms/Button'
import Table from 'components-v2/molecules/Table'
import Toolbar, { SearchInput } from 'components-v2/molecules/Table/Toolbar'
import FilterDropdownTray from 'components-v2/molecules/FilterDropdownTray'
import MenuDropdown from 'components-v2/molecules/MenuDropdown'
import { useAuthorizations } from 'hooks'
import { dateFormatterFactory } from 'lib/table'
import { LabelTypes } from 'constants/index'
import {
  nameFormatterFactory,
  actionsFormatterFactory,
  sourceFormatterFactory,
  vendorSourceFormatterFactory,
  commentFormatterFactory,
} from '../lib/attachment_table'
import { ActionMenuItemTypes } from './constants'

const commentFormatter = commentFormatterFactory()
const dateFormatter = dateFormatterFactory()
const nameFormatter = nameFormatterFactory()
const sourceFormatter = sourceFormatterFactory()
const vendorSourceFormatter = vendorSourceFormatterFactory()
const getVendorAttachmentTableColumns = (
  vendorColumnVisible,
  canEdit,
  canDelete,
  onEdit,
  onDelete,
) =>
  [
    vendorColumnVisible
      ? {
          id: 'cva.vendor.name',
          header: LabelTypes.VENDOR,
          cell: vendorSourceFormatter,
          width: '20%',
        }
      : undefined,
    {
      id: 'attachment.name',
      header: 'File',
      cell: nameFormatter,
      width: '40%',
    },
    {
      id: 'created_at',
      header: 'Date Uploaded',
      cell: dateFormatter,
      width: '10%',
    },
    {
      id: 'cva.expire_at',
      header: 'Expires',
      cell: dateFormatter,
      width: '10%',
    },
    {
      id: 'cva.comment',
      header: 'Notes',
      cell: commentFormatter,
      width: '25%',
    },
    {
      id: 'source',
      header: 'Source',
      cell: sourceFormatter,
    },
    {
      id: 'df1',
      header: '',
      cell: actionsFormatterFactory(canEdit, canDelete, onEdit, onDelete),
      enableSorting: false,
      width: '10%',
    },
  ].filter((e) => e)

const View = ({
  attachments,
  totalSize,
  page,
  sizePerPage,
  sortField,
  sortOrder,
  searchText,
  filter,
  filterConfigList,
  loading,
  vendorColumnVisible,
  newFileButtonVisible,
  onPaginationChange,
  onSortingChange,
  onSearchChange,
  onMenuItemSelect,
  onFilterChange,
  onBulkCreate,
  onEdit,
  onDelete,
}) => {
  const [containerDom, setContainerDom] = React.useState()
  const [canManage, canEdit, canDelete] = useAuthorizations(
    'manage',
    ['update', 'ClientVendorAttachment'],
    ['delete', 'ClientVendorAttachment'],
  )
  const columns = React.useMemo(
    () =>
      getVendorAttachmentTableColumns(
        vendorColumnVisible,
        canEdit,
        canDelete,
        onEdit,
        onDelete,
      ),
    [vendorColumnVisible, canEdit, canDelete, onEdit, onDelete],
  )
  return (
    <div ref={(dom) => setContainerDom(dom)}>
      <Toolbar>
        <SearchInput value={searchText} onChange={onSearchChange} />
        <Toolbar.Group>
          {newFileButtonVisible && canManage && (
            <Button color="primary" onClick={onBulkCreate}>
              <Icon icon="fa fa-plus" />
              New Files
            </Button>
          )}
          <FilterDropdownTray
            value={filter}
            configList={filterConfigList}
            onChange={onFilterChange}
            filtersEditable={false}
            boundingTarget={containerDom}
          />
          <MenuDropdown title="Actions" onSelect={onMenuItemSelect}>
            <MenuDropdown.Item eventKey={ActionMenuItemTypes.EXPORT_CSV}>
              <i className="fa fa-table" />
              Export File List (CSV)
            </MenuDropdown.Item>
            <MenuDropdown.Item eventKey={ActionMenuItemTypes.EXPORT_XLS}>
              <i className="fa fa-table" />
              Export File List (XLS)
            </MenuDropdown.Item>
          </MenuDropdown>
        </Toolbar.Group>
      </Toolbar>
      <Table
        loading={loading}
        data={attachments || []}
        columns={columns}
        totalSize={totalSize}
        pagination={{ page, sizePerPage }}
        sorting={{ sortField, sortOrder }}
        onPaginationChange={onPaginationChange}
        onSortingChange={onSortingChange}
        manualPagination
        manualSorting
        noDataText="No attachments found."
        type={Table.types.ALT}
      />
    </div>
  )
}

View.propTypes = {
  attachments: PropTypes.array,
  totalSize: PropTypes.number,
  page: PropTypes.number,
  sizePerPage: PropTypes.number,
  sortField: PropTypes.string,
  sortOrder: PropTypes.string,
  searchText: PropTypes.string,
  filter: PropTypes.object,
  filterConfigList: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  vendorColumnVisible: PropTypes.bool,
  newFileButtonVisible: PropTypes.bool,
  onPaginationChange: PropTypes.func.isRequired,
  onSortingChange: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onMenuItemSelect: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onBulkCreate: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default View
