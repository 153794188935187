import React, { useState, useCallback } from 'react'
import { compose, withAuthorize, withReactQuery } from 'hocs'
import {
  useClientTagsFetcher,
  useUsersInfiniteFetcher,
  useClientAssessmentTemplatesInfiniteFetcher,
  useClientVendorAssessmentsStatusFetcher,
} from 'apis'
import {
  getVendorAssessmentsStatusQueryParams,
  getVendorAssessmentsStatusExportUrl,
} from './utils'
import View from './View'

const Container = () => {
  const [status, setStatus] = useState()
  const [riskTier, setRiskTier] = useState()
  const [archivedValues, setArchivedValues] = useState()
  const [assessmentTemplate, setAssessmentTemplate] = useState()
  const [assignee, setAssignee] = useState()
  const [vendorTag, setVendorTag] = useState()
  const [assessRange, setAssessRange] = useState()
  const [createRange, setCreateRange] = useState()

  const clientTagsFetcherResult = useClientTagsFetcher()
  const assessmentTemplatesFetcherResult =
    useClientAssessmentTemplatesInfiniteFetcher({
      params: {
        per_page: 10,
      },
    })
  const usersFetcherResult = useUsersInfiniteFetcher({
    params: {
      per_page: 10,
    },
  })
  const filters = {
    assessmentTemplate,
    status,
    riskTier,
    assignee,
    vendorTag,
    assessRange,
    createRange,
    archivedValues,
  }
  const { data: vendorAssessmentsStatusData } =
    useClientVendorAssessmentsStatusFetcher({
      params: getVendorAssessmentsStatusQueryParams(filters),
    })

  const handleExport = useCallback(
    (format) => {
      window.location.assign(
        getVendorAssessmentsStatusExportUrl(filters, format),
      )
    },
    [filters],
  )

  return (
    <View
      status={status}
      riskTier={riskTier}
      archivedValues={archivedValues}
      assessmentTemplate={assessmentTemplate}
      assignee={assignee}
      vendorTag={vendorTag}
      assessRange={assessRange}
      createRange={createRange}
      clientTagsFetcherResult={clientTagsFetcherResult}
      assessmentTemplatesFetcherResult={assessmentTemplatesFetcherResult}
      usersFetcherResult={usersFetcherResult}
      detail={vendorAssessmentsStatusData?.detail}
      summary={vendorAssessmentsStatusData?.summary}
      onStatusChange={setStatus}
      onArchivedValuesChange={setArchivedValues}
      onAssessmentTemplateChange={setAssessmentTemplate}
      onRiskTierChange={setRiskTier}
      onAssigneeChange={setAssignee}
      onVendorTagChange={setVendorTag}
      onAssessRangeChange={setAssessRange}
      onCreateRangeChange={setCreateRange}
      onExport={handleExport}
    />
  )
}

export default compose(withAuthorize(), withReactQuery())(Container)
