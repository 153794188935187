import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components-v2/atoms/Button'
import Tabs from 'components-v2/molecules/Tabs'
import PageHeader from 'components-v2/organisms/PageHeader'
import { LabelTypes } from 'constants/index'
import { getPageUrl } from 'utils/url'
import { useAuthorizations } from 'hooks'
import ThemeView from './ThemeView'
import OptionsView from './OptionsView'
import PartnerListView from './PartnerListView'
import UserListView from './UserListView'
import VendorRequestSettings from './VendorRequestSettings'
import IssueCategoryTable from './IssueCategoryTable'
import UserGroupTable from './UserGroupTable'
import ClientTagTable from './ClientTagTable'
import CustomFieldDefinitionsTable from './CustomFieldDefinitionsTable'
import RiskTierCalculatorTable from './RiskTierCalculatorTable'
import { TabPaneTitle, SmallWidthContent } from './styles'

const View = ({
  organization,
  isClient,
  ssoEnabled,
  ssoActive,
  allowVendorRequests,
  showMfaEnforcement,
  showMultilang,
  currentUserId,
  currentOrgRole,
  activeTab,
  onTabSelect,
}) => {
  const [canManage, canAdmin] = useAuthorizations('manage', 'admin')
  return (
    <>
      <PageHeader title={organization.name} />
      <Tabs activeKey={activeTab} onChange={onTabSelect}>
        <Tabs.Tab eventKey="users" title="Users">
          <UserListView
            isClient={isClient}
            ssoActive={ssoActive}
            currentUserId={currentUserId}
            currentOrgRole={currentOrgRole}
          />
        </Tabs.Tab>
        {isClient && canManage && (
          <Tabs.Tab eventKey="groups" title="Groups">
            <TabPaneTitle>Groups</TabPaneTitle>
            <SmallWidthContent>
              <UserGroupTable />
            </SmallWidthContent>
          </Tabs.Tab>
        )}
        {isClient && canManage && (
          <Tabs.Tab eventKey="theme" title="Theme">
            <TabPaneTitle>Theme</TabPaneTitle>
            <SmallWidthContent>
              <ThemeView organization={organization} />
            </SmallWidthContent>
          </Tabs.Tab>
        )}
        {isClient && canManage && (
          <Tabs.Tab eventKey="labels" title="Labels">
            <TabPaneTitle>
              {LabelTypes.VENDOR} Labels <small> (Limit 100) </small>
            </TabPaneTitle>
            <SmallWidthContent>
              <ClientTagTable />
            </SmallWidthContent>
          </Tabs.Tab>
        )}
        {isClient && canManage && (
          <Tabs.Tab eventKey="categories" title="Categories">
            <TabPaneTitle>Issue Categories</TabPaneTitle>
            <SmallWidthContent>
              <IssueCategoryTable />
            </SmallWidthContent>
          </Tabs.Tab>
        )}
        {ssoEnabled && canAdmin && (
          <Tabs.Tab eventKey="sso" title="SSO">
            <TabPaneTitle>Single Sign On</TabPaneTitle>
            <Button color="primary" href={getPageUrl('organizationSSO')}>
              Configure SAML
            </Button>
          </Tabs.Tab>
        )}
        {isClient && canManage && (
          <Tabs.Tab eventKey="partners" title="Partners">
            <TabPaneTitle>Partners</TabPaneTitle>
            <PartnerListView organization={organization} />
          </Tabs.Tab>
        )}
        {isClient && canAdmin && (
          <Tabs.Tab eventKey="customFields" title="Custom Fields">
            <TabPaneTitle>Custom {LabelTypes.VENDOR} Fields</TabPaneTitle>
            <SmallWidthContent>
              <CustomFieldDefinitionsTable
                organization={organization}
                targetType="Organization"
              />
            </SmallWidthContent>
          </Tabs.Tab>
        )}
        {false && isClient && canManage && (
          <Tabs.Tab eventKey="calculator" title="Calculators">
            <TabPaneTitle>Risk Tier Calculators</TabPaneTitle>
            <SmallWidthContent>
              <RiskTierCalculatorTable organization={organization} />
            </SmallWidthContent>
          </Tabs.Tab>
        )}
        {isClient && allowVendorRequests && canManage && (
          <Tabs.Tab eventKey="requests" title="Requests">
            <TabPaneTitle>Requests</TabPaneTitle>
            <SmallWidthContent>
              <VendorRequestSettings organization={organization} />
            </SmallWidthContent>
          </Tabs.Tab>
        )}
        {isClient &&
          (canAdmin ||
            (canManage && (showMfaEnforcement || showMultilang))) && (
            <Tabs.Tab eventKey="options" title="Options">
              <SmallWidthContent>
                <OptionsView
                  organization={organization}
                  showMfaEnforcement={showMfaEnforcement}
                  showMultilang={showMultilang}
                />
              </SmallWidthContent>
            </Tabs.Tab>
          )}
      </Tabs>
    </>
  )
}

View.propTypes = {
  organization: PropTypes.object,
  isClient: PropTypes.bool,
  ssoEnabled: PropTypes.bool,
  ssoActive: PropTypes.bool,
  allowVendorRequests: PropTypes.bool,
  showMfaEnforcement: PropTypes.bool,
  showMultilang: PropTypes.bool,
  currentUserId: PropTypes.string,
  currentOrgRole: PropTypes.string,
  activeTab: PropTypes.string,
  onTabSelect: PropTypes.func.isRequired,
}

export default View
