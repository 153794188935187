import React, { useState, useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import toFinite from 'lodash/toFinite'
import orderBy from 'lodash/orderBy'
import omit from 'lodash/omit'
import tsquery from 'pg-tsquery'
import Button from 'components-v2/atoms/Button'
import Checkbox from 'components-v2/atoms/Checkbox'
import Alert from 'components-v2/molecules/Alert'
import ButtonGroup from 'components-v2/molecules/ButtonGroup'
import SearchInput from 'components-v2/molecules/SearchInput'
import Table from 'components-v2/molecules/Table'
import Transition from 'components-v2/molecules/Transition'
import { SortOrderTypes } from 'constants/index'
import { useAutoToggleState } from 'hooks'
import {
  useAssistQuestionFetcher,
  useAssistQuestionAdvancedFetcher,
  useCreateAssistQuestionAnswer,
  useUpdateAssistQuestionAnswer,
} from 'apis'
import { ModeTypes, ModeOptions } from '../constants'
import WriteInAnswer from './WriteInAnswer'
import { getTableColumns } from './cols'
import {
  Title,
  Toolbar,
  SearchInputWrapper,
  AdvancedSearchTooltip,
} from './styles'

const advancedSearchTooltipContent = (
  <div>
    <h6>Advanced Search</h6>
    <small>
      Searches question, answer and comment and ranks combined results
    </small>
    <ul>
      <li>AND: & + and</li>
      <li>OR: , | or</li>
      <li>NOT: ! -</li>
      <li>SUBSTRING: *</li>
      <li>PARENTHESES: ()[]</li>
      <li>FOLLOWED BY: {'<> <-> <d+>'}</li>
    </ul>
    Examples:
    <br />
    <ul>
      <li>
        <code>(virus or fraudulent or malware) and mail </code>{' '}
        <i>
          any of the words virus, fraudulent, malware but always the word mail
        </i>
      </li>
      <li>
        <code>employees and paid -contractors</code>{' '}
        <i>employees and paid but not contractors</i>
      </li>
      <li>
        <code>anti* and servers</code>{' '}
        <i>starts with anti and contains servers</i>
      </li>
      <li>
        <code>remote {'<>'} network</code> <i>remote followed by network</i>
      </li>
      <li>
        <code>remote {'<2>'} authenticated</code>{' '}
        <i>remote followed by authenticated 2 words away</i>
      </li>
    </ul>
  </div>
)

const highlightTimeout = {
  enter: 100,
  exit: 2500,
}

export default function AnswerList({ question, mode, onModeChange }) {
  const { mutateAsync: createAssistQuestionAnswer } =
    useCreateAssistQuestionAnswer()
  const { mutateAsync: updateAssistQuestionAnswer } =
    useUpdateAssistQuestionAnswer()
  const [exactSearch, setExactSearch] = useState(false)
  const [advancedSearch, setAdvancedSearch] = useState(false)
  const [filter, setFilter] = useState('')
  const [highlightWriteInAnswer, activateHighlightWriteInAnswer] =
    useAutoToggleState(highlightTimeout.enter)
  const questionId = question.id
  const assistQuestionQuery = useAssistQuestionFetcher(
    { id: questionId },
    { enabled: !filter },
  )
  const assistQuestionAdvancedQuery = useAssistQuestionAdvancedFetcher(
    {
      id: questionId,
      params: {
        q: advancedSearch ? tsquery(filter) : filter,
        question_id: questionId,
        exact: exactSearch,
        advanced: advancedSearch,
      },
    },
    { enabled: !!filter },
  )
  const answers = !filter
    ? assistQuestionQuery.data?.question_answers
    : assistQuestionAdvancedQuery.data?.question_answers
  const isError = !filter
    ? assistQuestionQuery.isError
    : assistQuestionAdvancedQuery.isError
  const writeInAnswer = answers?.find((e) => e.assist_answer_id === null)

  useEffect(() => {
    setFilter('')
  }, [questionId, mode])

  const handleExactCheckboxChange = useCallback((event) => {
    setExactSearch(event.target.checked)
    setAdvancedSearch(false)
  }, [])

  const handleAdvancedCheckboxChange = useCallback((event) => {
    setExactSearch(false)
    setAdvancedSearch(event.target.checked)
  }, [])

  const handleChangeIsMatch = useCallback(
    async (answerId, newIsMatch) => {
      try {
        const answer = answers.find((e) => e.id === answerId)
        if (answer.is_new) {
          await createAssistQuestionAnswer({
            data: {
              question_answer: {
                ...omit(answer, 'score'),
                is_match: newIsMatch,
              },
            },
          })
        } else {
          await updateAssistQuestionAnswer({
            id: answerId,
            data: {
              question_answer: {
                is_match: newIsMatch,
              },
            },
          })
        }
      } catch (error) {
        console.error(error)
      }
    },
    [answers],
  )

  const handleToggleSelectedAnswer = useCallback(
    async (answerId) => {
      try {
        const answer = answers.find((e) => e.id === answerId)
        if (answer.is_new) {
          await createAssistQuestionAnswer({
            data: {
              question_answer: {
                ...omit(answer, 'score'),
                is_selected_answer: !answer.is_selected_answer,
              },
            },
          })
        } else {
          await updateAssistQuestionAnswer({
            id: answerId,
            data: {
              question_answer: {
                is_selected_answer: !answer.is_selected_answer,
              },
            },
          })
        }
        setFilter('')
      } catch (error) {
        console.error(error)
      }
    },
    [answers],
  )

  const handleBlurWriteInAnswer = useCallback(
    async (answerText) => {
      if (!writeInAnswer) {
        return
      }
      try {
        await updateAssistQuestionAnswer({
          id: writeInAnswer.id,
          data: {
            question_answer: {
              write_in_answer: answerText,
            },
          },
        })
        activateHighlightWriteInAnswer()
      } catch (error) {
        console.error(error)
      }
    },
    [writeInAnswer, activateHighlightWriteInAnswer],
  )

  const handleToggleSelectWriteInAnswer = useCallback(
    async (answerText) => {
      try {
        if (writeInAnswer) {
          await updateAssistQuestionAnswer({
            id: writeInAnswer.id,
            data: {
              question_answer: {
                write_in_answer: answerText,
                is_selected_answer: !writeInAnswer.is_selected_answer,
              },
            },
          })
        } else {
          await createAssistQuestionAnswer({
            data: {
              question_answer: {
                assist_question_id: questionId,
                is_match: null,
                is_selected_answer: true,
                write_in_answer: answerText,
              },
            },
          })
        }
        setFilter('')
      } catch (error) {
        console.error(error)
      }
    },
    [questionId, writeInAnswer],
  )

  const answersSorted = useMemo(
    () =>
      orderBy(
        answers?.filter((qa) => qa.assist_answer_id),
        [
          (o) => toFinite(o.score),
          'is_match',
          'created_at',
          'assist_answer_id',
        ],
        [
          SortOrderTypes.DESC,
          SortOrderTypes.DESC,
          SortOrderTypes.ASC,
          SortOrderTypes.ASC,
        ],
      ),
    [answers],
  )

  return (
    <div>
      <Title>
        Suggested {mode === ModeTypes.MAP ? 'questions' : 'answers'}
      </Title>
      <Toolbar>
        <SearchInputWrapper>
          <SearchInput
            value={filter}
            placeholder="Find questions..."
            onChange={setFilter}
          />
        </SearchInputWrapper>
        <ButtonGroup>
          {ModeOptions.map((e) => (
            <Button
              key={e.value}
              color={mode === e.value ? 'primary' : 'default'}
              active={mode === e}
              onClick={() => onModeChange(e.value)}
            >
              {e.label}
            </Button>
          ))}
        </ButtonGroup>
      </Toolbar>
      <Toolbar>
        <Checkbox
          inline
          onChange={handleExactCheckboxChange}
          checked={exactSearch}
          label="Exact Phrase"
        />
        &nbsp;&nbsp;
        <Checkbox
          inline
          onChange={handleAdvancedCheckboxChange}
          checked={advancedSearch}
          label="Advanced Search"
        />
        &nbsp;
        <AdvancedSearchTooltip>
          {advancedSearchTooltipContent}
        </AdvancedSearchTooltip>
      </Toolbar>
      {isError && <Alert color="warning">Invalid Query Syntax</Alert>}
      <Table
        data={answersSorted}
        columns={getTableColumns(
          mode,
          handleChangeIsMatch,
          handleToggleSelectedAnswer,
        )}
        enableSorting={false}
        enablePagination={false}
      />
      {mode === ModeTypes.ANSWER && (
        <Transition.Highlight
          in={highlightWriteInAnswer}
          timeout={highlightTimeout}
        >
          <WriteInAnswer
            answer={writeInAnswer}
            onBlurInput={handleBlurWriteInAnswer}
            onToggleSelectedAnswer={handleToggleSelectWriteInAnswer}
          />
        </Transition.Highlight>
      )}
    </div>
  )
}

AnswerList.propTypes = {
  question: PropTypes.object.isRequired,
  mode: PropTypes.string,
  onModeChange: PropTypes.func.isRequired,
}
