import React, { useState } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import Button from 'components-v2/atoms/Button'
// import Textarea from 'components-v2/atoms/Textarea'
import ButtonGroup from 'components-v2/molecules/ButtonGroup'
import SimpleDialog from 'components-v2/molecules/SimpleDialog'
import {
  SmallText,
  ListItemWrapper,
  ListItemIcon,
  ListItemMain,
  ListItemTitle,
} from './styles'

const ReviewPendingRequestsModal = ({
  clientName,
  requests,
  onSubmit,
  ...rest
}) => {
  const [choices, setChoices] = useState({})
  // const [note, setNote] = useState('')

  const buttons = [
    {
      color: 'primary',
      children: 'Submit',
      disabled: isEmpty(choices),
      // onClick: () => onSubmit(choices, note),
      onClick: () => onSubmit(choices),
    },
  ]

  return (
    <SimpleDialog
      {...rest}
      title={`${clientName}'s Pending Requests`}
      buttons={buttons}
    >
      <SmallText>
        Accept or decline client&apos;s ability to view certain files:
      </SmallText>
      {requests.map((item) => (
        <ListItemWrapper key={item.id}>
          <ListItemIcon />
          <ListItemMain>
            <ListItemTitle>{item.listing.name}</ListItemTitle>
          </ListItemMain>
          <ButtonGroup>
            <Button
              color={choices[item.id] === true ? 'success' : 'default'}
              active={choices[item.id] === true}
              onClick={() =>
                setChoices((current) => ({ ...current, [item.id]: true }))
              }
            >
              Accept
            </Button>
            <Button
              color={choices[item.id] === false ? 'danger' : 'default'}
              active={choices[item.id] === false}
              onClick={() =>
                setChoices((current) => ({ ...current, [item.id]: false }))
              }
            >
              Decline
            </Button>
          </ButtonGroup>
        </ListItemWrapper>
      ))}
      {/* {!isEmpty(choices) && (
        <Textarea
          placeholder="Add a note to the client..."
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
      )} */}
    </SimpleDialog>
  )
}

ReviewPendingRequestsModal.propTypes = {
  clientName: PropTypes.string.isRequired,
  requests: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default ReviewPendingRequestsModal
