import React from 'react'
// import PropTypes from 'prop-types'
import FormField from 'components-v2/molecules/FormField'
import ChoicesEditorField from 'components/client/assessment_template_view/QuestionFormModal/ChoicesEditorField/index'

const SelectFieldDetails = () => {
  const primaryFields = (
    <React.Fragment key="primary_fields">
      <FormField
        name="render_opts.choices"
        label="Choices"
        component={ChoicesEditorField}
        presets={[
          ['Yes', 'No'],
          ['Yes', 'No', 'N/A'],
        ]}
      />
      <FormField
        name="render_opts.multi"
        label="How many options can the user pick?"
        type={FormField.types.RADIO_GROUP}
        options={[
          { label: 'Single', value: false },
          { label: 'Multiple', value: true },
        ]}
        inline
      />
    </React.Fragment>
  )

  // placeholder
  const advancedFields = <React.Fragment key="advanced_fields" />

  return [primaryFields, advancedFields]
}
// react/no-unused-prop-type
// SelectFieldDetails.propTypes = {
//   fieldDef: PropTypes.object,
//   typeConfig: PropTypes.object,
//   formProps: PropTypes.object,
// }

export default SelectFieldDetails
