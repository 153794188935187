import React from 'react'
import styled from 'styled-components'
import Capsule from 'components-v2/atoms/Capsule'
import {
  parseOsanoData,
  parseOfacData,
  getRiskReconRatingColor,
} from 'components/client/lib/globorg_integration_metrics'
import Icon from '../../../components-v2/atoms/Icon'

const RiskReconScoreCapsule = styled(Capsule)`
  & > *:first-child {
    width: 34px;
  }

  & > *:last-child {
    width: 40px;
  }
`

export const riskReconScoreFormatterFactory = () => (info) => {
  const riskRecon = info.getValue()
  if (!riskRecon) {
    return null
  }
  return (
    <RiskReconScoreCapsule
      color={getRiskReconRatingColor(riskRecon.rating_numeric)}
      left={riskRecon.rating_v2}
      right={riskRecon.rating_numeric}
    />
  )
}

const OsanoScoreCapsule = styled(Capsule)`
  & > *:first-child {
    font-size: 11px;
    width: 85px;
  }

  & > *:last-child {
    width: 40px;
  }
`

export const osanoScoreFormatterFactory = () => (info) => {
  const osano = info.getValue()
  if (!osano) {
    return null
  }
  const parsed = parseOsanoData(osano)
  return (
    <OsanoScoreCapsule
      color={parsed.color}
      left={parsed.rangeLabel}
      right={parsed.value}
    />
  )
}

const OfacWrapper = styled.span`
  display: inline-flex;
  align-items: center;
`

const OfacIcon = styled(Icon)`
  margin-right: 6px;
`

export const ofacFormatterFactory = () => (info) => {
  const ofac = info.getValue()
  const parsed = parseOfacData(ofac)
  return (
    <OfacWrapper>
      <OfacIcon icon={parsed.icon} />
      {parsed.label}
    </OfacWrapper>
  )
}
