import React from 'react'
import PropTypes from 'prop-types'
import Popover from 'components-v2/molecules/Popover'
import AssignForm from './AssignForm'
import { AssigneeLabel, AssigneeButton, PanelContent } from './styles'

const Assignee = ({ assigneeId, assigneeName, note, editable, onSave }) => {
  if (!editable) {
    return (
      <AssigneeLabel $isEmpty={!assigneeId}>
        {assigneeId ? assigneeName : 'unassigned'}
      </AssigneeLabel>
    )
  }
  return (
    <Popover>
      <Popover.Button as={AssigneeButton} $isEmpty={!assigneeId}>
        {assigneeId ? assigneeName : 'unassigned'}
      </Popover.Button>
      <Popover.Panel title="Assign To" arrow>
        {({ close }) => (
          <PanelContent>
            <AssignForm
              assigneeId={assigneeId}
              note={note}
              close={close}
              onSubmit={onSave}
            />
          </PanelContent>
        )}
      </Popover.Panel>
    </Popover>
  )
}

Assignee.propTypes = {
  assigneeId: PropTypes.number,
  assigneeName: PropTypes.string,
  note: PropTypes.string,
  editable: PropTypes.bool,
  onSave: PropTypes.func,
}

Assignee.defaultProps = {
  assigneeId: undefined,
  assigneeName: undefined,
  note: undefined,
  editable: true,
}

export default Assignee
