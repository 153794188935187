import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
// import Checkbox from 'components-v2/atoms/Checkbox'
import Button from 'components-v2/atoms/Button'
import Input from 'components-v2/atoms/Input'
import Label from 'components-v2/atoms/Label'
import Textarea from 'components-v2/atoms/Textarea'
import { BaseField, ErrorMessage } from 'components-v2/molecules/FormField'
import Tooltip from 'components-v2/molecules/Tooltip'
import Tabs, { Tab } from 'components-v2/molecules/Tabs'
import ToolbarGroup from 'components-v2/molecules/ToolbarGroup'
import PageHeader from 'components-v2/organisms/PageHeader'
import { Row, Col } from 'components-v2/organisms/Layout'
import { updateOrganizationSSO } from 'apis'
import { useGlobalLoader } from 'hooks'
import NotificationManager from 'lib/notifications'
import EmailDomainsCard from './EmailDomainsCard'
import { Box, Header, Info, InfoGroup, InfoLabel } from './styles'

const SSODash = ({ orgSSODData, emailDomains, orgName }) => {
  const [showLoader, hideLoader] = useGlobalLoader()
  const [activeTab, setActiveTab] = useState(
    orgSSODData.idp?.target_url ? 'configuration' : 'import',
  )
  const [xmlConfiguration, setXMLConfiguration] = useState('')
  const [ssoData, setSSOData] = useState(orgSSODData)
  const [showErrors, setShowErrors] = useState(false)
  const [errors, setErrors] = useState([])

  const changeIdp = useCallback((key, event) => {
    setSSOData((current) => ({
      ...current,
      idp: {
        ...current.idp,
        [key]: event.target.value,
      },
    }))
  }, [])

  const updateSSOData = useCallback(
    (data) => {
      const loaderId = showLoader()

      updateOrganizationSSO(orgSSODData.id, data)
        .then((response) => {
          setXMLConfiguration('')
          setActiveTab('configuration')
          setShowErrors(false)
          setSSOData(response.data)
          NotificationManager.success('Settings updated!')
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            const err = error.response?.data?.errors ?? {}
            setShowErrors(true)
            setErrors([
              `Error Updating SAML Config. ${Object.values(err).join(', ')}`,
            ])
          } else {
            setShowErrors(true)
            setErrors(['Error Updating SAML Config.'])
          }
        })
        .then(() => hideLoader(loaderId))
    },
    [orgSSODData.id, showLoader, hideLoader],
  )

  const postForm = useCallback(
    (enableConfiguration = false) => {
      const data = {
        organization: {
          sso_redirect_enabled: ssoData.sso_redirect_enabled,
          idp_attributes: {
            target_url: ssoData.idp.target_url,
            cert: ssoData.idp.cert,
            id: ssoData.idp.id,
            entity_id: ssoData.idp.entity_id,
          },
        },
      }
      if (xmlConfiguration && xmlConfiguration.length > 0) {
        data.organization.idp_attributes.xml_config = xmlConfiguration
      }
      if (enableConfiguration) {
        data.organization.sso_configured = true
      }
      updateSSOData(data)
    },
    [ssoData, xmlConfiguration, updateSSOData],
  )

  const disableConfiguration = useCallback(() => {
    updateSSOData({
      organization: { sso_configured: false },
    })
  }, [updateSSOData])

  const validationSummary = (
    <ul>
      {errors?.map((value, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={index}>{value}</li>
      ))}
    </ul>
  )

  const canEnableConfiguration =
    ssoData.idp &&
    ssoData.idp.target_url &&
    ssoData.idp.cert &&
    ssoData.idp.entity_id

  return (
    <div id="sso-organization-component">
      <PageHeader title={orgName} />
      <Row>
        <Col sm={8}>
          <Box padding bordered>
            <Header>
              SSO Login URL <Tooltip>Your SP-initiated Login URL</Tooltip>
            </Header>
            <InfoGroup>
              <a href={ssoData.sso_url}>{ssoData.sso_url}</a>
            </InfoGroup>
          </Box>
          <Box padding bordered>
            <Header>SAML Setup Information</Header>
            <InfoGroup>
              <InfoLabel>Single sign on URL (ACS):</InfoLabel>
              <Info>{ssoData.idp.acs_url}</Info>
              <InfoLabel>Audience URI (SP Entity ID):</InfoLabel>
              <Info>{ssoData.idp.metadata_url}</Info>
            </InfoGroup>
          </Box>
        </Col>
        <Col sm={4}>
          <EmailDomainsCard data={emailDomains} />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <h3>
            {' '}
            SAML Settings{' '}
            <Label color={ssoData.sso_configured ? 'success' : 'default'}>
              {ssoData.sso_configured ? 'Enabled' : 'Disabled'}
            </Label>
          </h3>
        </Col>
      </Row>

      <Tabs activeKey={activeTab} onChange={setActiveTab}>
        <Tab eventKey="configuration" title="Configuration">
          {showErrors && <ErrorMessage>{validationSummary}</ErrorMessage>}
          <BaseField
            horizontal
            label="Target URL"
            helpMessage="The Identity Provider Single Sign-On URL"
          >
            <Input
              value={ssoData.idp.target_url || ''}
              onChange={(e) => changeIdp('target_url', e)}
            />
          </BaseField>
          <BaseField
            horizontal
            label="Certificate"
            helpMessage="X.509 Certificate"
          >
            <Textarea
              rows={5}
              value={ssoData.idp.cert || ''}
              onChange={(e) => changeIdp('cert', e)}
            />
          </BaseField>
          {/* <BaseField horizontal label="Fingerprint">
            <Textarea
              value={this.state.fingerprint}
              onChange={(e) => this.change('fingerprint', e)}
            />
          </BaseField> */}
          <BaseField
            horizontal
            label="Entity ID/Provider URL"
            helpMessage="Identity Provider Issuer"
          >
            <Input
              value={ssoData.idp.entity_id || ''}
              onChange={(e) => changeIdp('entity_id', e)}
            />
          </BaseField>

          {/* <h5>Additional Settings</h5>

          <BaseField horizontal label="Allow Login Page to SSO Redirect">
            <CheckboxToggle
              checked={ssoData.sso_redirect_enabled}
              onChange={(e) => setSSOData((current) => ({ ...current, sso_redirect_enabled: e }))}
            />
          </BaseField> */}
        </Tab>
        <Tab eventKey="import" title="Import Configuration">
          {showErrors && <ErrorMessage>{validationSummary}</ErrorMessage>}
          <BaseField
            controlId="metadata"
            showError={showErrors}
            label="SAML 2.0 IDP metadata in XML format"
            helpMessage="Paste in the SAML XML metadata provided by your Identity Provider, or optionally configure the parameters manually by selecting the Configuration Tab above"
          >
            <Textarea
              rows={10}
              placeholder="Paste configuration here"
              value={xmlConfiguration}
              onChange={(e) => setXMLConfiguration(e.target.value)}
            />
          </BaseField>
        </Tab>
      </Tabs>
      <ToolbarGroup>
        <Button color="primary" onClick={() => postForm(false)}>
          Save
        </Button>
        {ssoData.sso_configured && (
          <Button onClick={disableConfiguration}>Disable</Button>
        )}
        {!ssoData.sso_configured && (
          <Button
            disabled={!canEnableConfiguration}
            onClick={() => postForm(true)}
          >
            Enable
          </Button>
        )}
      </ToolbarGroup>
    </div>
  )
}

SSODash.propTypes = {
  orgSSODData: PropTypes.object.isRequired,
  emailDomains: PropTypes.array.isRequired,
  orgName: PropTypes.string.isRequired,
}

export default SSODash
