import styled from 'styled-components'
import Button from 'components-v2/atoms/Button'
import Label from 'components-v2/atoms/Label'
import Tabs from 'components-v2/molecules/Tabs'
import GradeLabel from 'components/shared/GradeLabel'
import { colors, fonts, media, mixins } from 'styles'

export const Wrapper = styled.div`
  background: #d4d4d4;
  border-radius: 5px;
  overflow-wrap: break-word; // TODO: Consider to set this globally
`

export const Top = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  min-height: 56px;
  cursor: pointer;
`

export const Title = styled.h3`
  margin: 0 10px 0 0;
`

export const SmallNoScore = styled.div`
  font-size: 24px;
  font-weight: 800;
  color: ${colors.BLUE};
  letter-spacing: 0.07em;
  margin-right: 10px;
`

export const RiskScoreSmallChartWrapper = styled.div`
  position: relative;
  width: 74px;
  margin-right: 10px;
`

export const SmallRiskScore = styled.div`
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  color: ${colors.BLUE};
  font-size: 16px;
  font-weight: 700;
  line-height: 1em;
  letter-spacing: 0.05em;
`

export const HeaderGradeLabel = styled(GradeLabel)`
  margin-top: 2px;
  font-family: ${fonts.SECONDARY};
  font-size: 14px;
  font-weight: bold;
`

export const Toggler = styled.span`
  ${mixins.smallHeader};
  color: ${colors.DARK_GREY};
  margin-left: auto;
  ${mixins.hidePrint}

  i {
    font-size: 14px;
    color: ${colors.PRIMARY};
  }
`

export const Content = styled.div`
  padding: 0 16px 16px;
  display: flex;
  align-items: stretch;

  ${media.mobile`
    display: block;
  `}
`

export const Left = styled.div`
  flex: none;
  width: 460px;
  margin-right: 10px;

  ${media.tablet`
    width: 280px;
  `}

  ${media.mobile`
    width: auto;
    margin-right: 0;
  `}

  > * {
    height: 100%;
  }
`

export const Right = styled.div`
  flex: auto;
  min-width: 0;

  > *:not(:first-child) {
    margin-top: 10px;
  }

  ${media.nmobile`
    display: flex;
    flex-direction: column;

    > *:not(:last-child) {
      flex: none;
    }

    > *:last-child {
      flex: 1;
    }
  `}

  ${media.mobile`
    margin-top: 10px;
  `}
`

export const ScoreAndGradeWrapper = styled.div`
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

export const RiskScoreChartWrapper = styled.div`
  flex: none;
  position: relative;
  width: 260px;
  font-size: 11px;
  margin-right: 15px;
`

export const RiskScore = styled.div`
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  color: ${colors.BLUE};
  font-size: 50px;
  font-weight: 800;
  line-height: 1em;
  letter-spacing: 0.05em;
`

export const NoScoreWrapper = styled.div`
  flex: none;
  width: 260px;
  margin-right: 15px;
  text-align: center;
  max-width: 100%;
`

export const NoScore = styled.div`
  color: ${colors.BLUE};
  font-size: 80px;
  font-weight: 800;
  letter-spacing: 0.07em;
  line-height: 1.1em;
`

export const NoScoreReason = styled.div`
  color: ${colors.MED_DARK_GREY};
  font-weight: bold;
`

export const ViewSummary = styled(Button).attrs({ bsStyle: 'link' })`
  font-size: 15px;
  color: #777;

  &:hover,
  &:active,
  &:focus {
    color: ${colors.DARK_GREY};
  }
`

export const Legend = styled.div`
  margin-top: 10px;
  text-align: center;
`

export const LegendItem = styled.div`
  display: inline-block;
  font-size: 15px;
  margin: 0 5px;

  &:before {
    content: url('data:image/svg+xml;base64,${(props) =>
      window.btoa(
        `<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='${props.color}' /></svg>`,
      )}');
    margin-right: 10px;
    display: inline-block;
  }
`

export const DomainLegend = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px -5px;
`

export const DomainLegendItem = styled.div`
  flex: 50%;
  padding: 0 12px;
  font-size: 14px;
  height: 18px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
`

export const DomainLegendItemLabel = styled(Label)`
  width: 26px;
  padding: 0;
  line-height: 18px;
`

export const ContentGradeLabel = styled(GradeLabel)`
  font-family: ${fonts.SECONDARY};
  font-size: 20px;
  font-weight: bold;
  line-height: 1em;
  margin-top: 18px;
  display: inline-block;
`

export const SmallHeading = styled.h6`
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.55px;
  margin-bottom: 16px;
`

export const StyledTabs = styled(Tabs).attrs({
  RenderTabItem: styled.button`
    ${mixins.clearButtonStyle}
    ${mixins.smallHeader}
    color: ${colors.GREY};
    border-top: 4px solid transparent;
    margin-right: 24px;
    padding: 10px 0;

    &:hover,
    &:focus {
      color: ${colors.BODY_TEXT};
    }

    ${({ selected }) =>
      selected &&
      `
      color: ${colors.BODY_TEXT};
      border-top-color: ${colors.BODY_TEXT};
    `}
  `,
  RenderPanels: 'div',
})`
  margin-top: 15px;
`
