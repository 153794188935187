import { compose, toUncontrollable } from 'hocs'
import ControlledTooltip from './ControlledTooltip'
import withDarkTheme from './withDarkTheme'
import withDelay from './withDelay'
import withEnhancedParent from './withEnhancedParent'

// Add additional functionalities
export default compose(
  toUncontrollable({ active: 'onToggle' }),
  withDarkTheme,
  withDelay,
  withEnhancedParent,
)(ControlledTooltip)
