const maxAttachmentMBytes = 100

const uploadLimits = {
  maxSizeBytes: maxAttachmentMBytes * 1048576,
  maxFiles: 25,
  accept:
    '.pdf,.doc,.docx,.xls,.xlsx,.csv,.rtf,.zip,.mp3,.wma,.mpg,.flv,.avi,.jpg,.jpeg,.png,.gif,.ppt,.pptx',
}

// Related to paginated collection query
const collectionQueryPageLimit = {
  default: 25,
  selectGlobalAssessmentTemplateModal: 5,
  projectVendorsTable: 10,
  projectNotificationsTable: 10,
  riskReconIssuesModal: 5,
  vendorsToggleAttributeModal: 12,
  emailDomainsCard: 6,
  libraryReceivedOrgsTable: 10,
  issueSummary: 48,
}

// Related to non-paginated collection query
const collectionQueryTotalLimit = {
  issues: 65536,
  assessments: 65536,
  assessmentTemplates: 65536,
  assessmentElements: 65536,
  assessmentRiskDomains: 65536,
  vendors: 65536,
  answerFiles: 65536,
  vendorResultsSummary: 65536,
  issueSummary: 65536,
  assessmentTemplatesInClientSendAssessmentModal: 65536,
  addUsersToNotifyModal: 65536,
}

export default {
  collectionQueryPageLimit,
  collectionQueryTotalLimit,
  tableDateFormat: 'MM/dd/yy',
  uploadLimits,
}
