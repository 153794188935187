import React from 'react'
import PropTypes from 'prop-types'
import IconButton from 'components-v2/atoms/IconButton'
import Table from 'components-v2/molecules/Table'
import { SortOrderTypes } from 'constants/index'
import { useAuthorizations } from 'hooks'
import { Name } from './styles'

const getAssessmentTemplatesTableColumns = (
  editable,
  canLinkTemplate,
  onDelete,
) => {
  const columns = [
    {
      id: 'name',
      header: '',
      cell: (info) =>
        canLinkTemplate ? (
          <Name as="a" href={info.row.original.url}>
            {info.getValue()}
          </Name>
        ) : (
          <Name>{info.getValue()}</Name>
        ),
      width: '100%',
    },
  ]
  if (editable) {
    columns.push({
      id: 'id',
      header: '',
      cell: (info) => (
        <IconButton
          icon="fa fa-trash-o"
          onClick={() => onDelete(info.getValue())}
        />
      ),
    })
  }
  return columns
}

const AssessmentTemplates = ({ assessmentTemplates, editable, onDelete }) => {
  const [canLinkTemplate] = useAuthorizations(['show', 'AssessmentTemplate'])
  const assessmentTemplatesTableColumns = React.useMemo(
    () =>
      getAssessmentTemplatesTableColumns(editable, canLinkTemplate, onDelete),
    [editable, onDelete],
  )

  return (
    <Table
      data={assessmentTemplates || []}
      columns={assessmentTemplatesTableColumns}
      defaultSorting={{
        sortField: 'name',
        sortOrder: SortOrderTypes.ASC,
      }}
      enablePagination={false}
      noDataText="No templates found."
      type={Table.types.HEADLESS}
    />
  )
}

AssessmentTemplates.propTypes = {
  assessmentTemplates: PropTypes.array,
  editable: PropTypes.bool,
  onDelete: PropTypes.func,
}

AssessmentTemplates.defaultProps = {
  assessmentTemplates: [],
  onDelete: () => {},
}

export default AssessmentTemplates
