import React from 'react'
import PropTypes from 'prop-types'
import { parseSpyCloudData } from 'components/client/lib/globorg_integration_metrics'
import { toLocaleString } from 'utils/number'
import BaseOverviewCard from '../BaseOverviewCard'
import { Metric } from './styles'

const SpyCloudOverviewCard = ({ data, ...rest }) => {
  const parsed = React.useMemo(() => parseSpyCloudData(data)[0], [data])
  return (
    <BaseOverviewCard
      {...rest}
      title={
        <span>
          SpyCloud
          <br />
          exposed records
        </span>
      }
      description={parsed.description}
      noData={!parsed.value}
    >
      {parsed.value && <Metric>{toLocaleString(parsed.value)}</Metric>}
    </BaseOverviewCard>
  )
}

SpyCloudOverviewCard.propTypes = {
  data: PropTypes.object.isRequired,
}

export default SpyCloudOverviewCard
