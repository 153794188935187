import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { colors } from 'styles'

const CircleMarker = styled.div`
  position: relative;
  width 13px;
  height: 13px;
  border-radius: 100%;
  ${(props) =>
    props.color &&
    css`
      background: ${props.color};
    `}

  &:before {
    position: absolute;
    display: block;
    content: '';
    top: -3px;
    left: -3px;
    width: 19px;
    height: 19px;
    border: 3px solid ${(props) => props.color || colors.GREY};
    border-radius: 100%;
    opacity: .3;
  }
`

CircleMarker.propTypes = {
  color: PropTypes.string,
}

export default CircleMarker
