import { scrollTo as scrollToDom } from 'utils/dom'
import { ContentOffsetTopDesktop, ContentOffsetTopTablet } from './styles'

export function getContentOffsetTop() {
  const smallScreen = window.innerWidth < 992
  return smallScreen ? ContentOffsetTopTablet : ContentOffsetTopDesktop
}

export function scrollTo(id) {
  scrollToDom(id, getContentOffsetTop() - 10) // Add a minor offset to sync with sidebar correctly in all browsers
}
