import React from 'react'
import PropTypes from 'prop-types'
import Loader from 'components-v2/molecules/Loader'

export default (WrappedComponent) => {
  const WrapperComponent = ({ loading, loadingMessage, ...rest }) =>
    loading ? (
      <Loader message={loadingMessage} />
    ) : (
      <WrappedComponent {...rest} />
    )

  WrapperComponent.propTypes = {
    loading: PropTypes.bool,
    loadingMessage: PropTypes.string,
  }

  return WrapperComponent
}
