import styled from 'styled-components'
import { mixins } from 'styles'

export const Inner = styled.div`
  padding: 30px 0 20px 0;
`

export const Label = styled.div`
  ${mixins.smallHeader}
  font-size: 11px;
`
