import styled from 'styled-components'
import IconButton from 'components-v2/atoms/IconButton'

export const Inner = styled.div`
  > .ease-out {
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  > .duration-300 {
    transition-duration: 300ms;
  }

  > .translate-x-full {
    transform: translateX(100%);
  }

  > .translate-x-0 {
    transform: translateX(0);
  }

  > .opacity-0 {
    opacity: 0;
  }

  > .opacity-100 {
    opacity: 1;
  }
`

export const Backdrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
`

export const Panel = styled.div`
  position: fixed;
  top: 0;
  height: 100vh;
  right: 0;
  width: 600px;
  max-width: 80%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
`

export const DrawerTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 24px;
`

export const DrawerHeaderText = styled.h4`
  margin: 0 auto 0 0;
  font-size: 20px;
  line-height: 1.2;
`

export const DrawerCloseButton = styled(IconButton).attrs({
  icon: 'fa fa-times',
})`
  font-size: 17px;
  margin-left: 10px;
`

export const DrawerContent = styled.div.attrs({
  'data-autofocus-inside': true, // This is very important to set initial focus inside dialog content, not to the X close icon button
})`
  padding: 15px 24px;
`
