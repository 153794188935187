import React from 'react'
import Chart from 'react-google-charts'
import { colors } from 'styles'
import PropTypes from 'prop-types'
import sumBy from 'lodash/sumBy'
import toPairs from 'lodash/toPairs'
import Loader from 'components-v2/molecules/Loader'
import { AssessmentStatusTypes } from 'constants/index'
import { ChartWrapper } from './styles'

const columnChartOptions = {
  chartArea: {
    left: 45,
    top: 10,
    width: '90%',
    height: '85%',
  },
  bar: { groupWidth: '94%' },
  legend: { position: 'none' },
  hAxis: { textStyle: { fontSize: 14 } },
  tooltip: { textStyle: { fontSize: 13, bold: true } },
  vAxis: {
    minValue: 0,
    maxValue: 1,
    format: 'percent',
  },
  animation: {
    duration: 500,
    easing: 'out',
    startup: true,
  },
}

const statusColors = {
  [AssessmentStatusTypes.INVITED]: '#777777',
  [AssessmentStatusTypes.IN_PROCESS]: colors.YELLOW,
  [AssessmentStatusTypes.SUBMITTED]: colors.BLUE,
  [AssessmentStatusTypes.EXPIRED]: colors.RED,
  [AssessmentStatusTypes.REVIEWED]: colors.GREEN,
}

const getChartData = (chartData) => {
  const total = sumBy(toPairs(chartData), (o) => o[1])
  const data = Object.entries(chartData).map(([k, v]) => [
    k.titleize(),
    v / total,
    statusColors[k.toLowerCase()],
    `${k.titleize()}: ${v}`,
  ])
  // const data = toPairs(this.state.data)
  data.unshift([
    'Status',
    'Percent',
    { role: 'style' },
    { type: 'string', role: 'tooltip' },
  ])
  return data
}

const StatusChartWidget = ({ chartData }) => {
  const computedChartData = getChartData(chartData)
  return (
    <ChartWrapper>
      <Chart
        width="100%"
        height="340px"
        chartType="ColumnChart"
        loader={<Loader>Loading Chart...</Loader>}
        data={computedChartData}
        options={columnChartOptions}
      />
    </ChartWrapper>
  )
}

StatusChartWidget.propTypes = {
  chartData: PropTypes.object.isRequired,
}

export default StatusChartWidget
