import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import Icon from 'components-v2/atoms/Icon'
import UnstyledList from 'components-v2/atoms/UnstyledList'
import DList from 'components-v2/molecules/DList'
import UserEnteredTextRenderer from 'components-v2/molecules/UserEnteredTextRenderer'
import PageHeader from 'components-v2/organisms/PageHeader'
import CommentThread from 'components/shared/CommentThread'
import IssueIssuable from 'components/shared/IssueIssuable'
import config from 'config'
import { toLocalTimeString } from 'utils/date'
import {
  issueDueDateFormatterFactory,
  getIssuePriorityLabel,
  getIssueStatusLabel,
} from 'lib/issue'
import InfoCard from './InfoCard'
import { Content, Left, Right, NarrowDList } from './styles'

const dueAtFormatter = issueDueDateFormatterFactory()

const View = ({ issue, canComment, onCommentCreated }) => (
  <div>
    <PageHeader title="Issue" />
    <Content>
      <Left>
        <InfoCard title="Issue Details">
          <DList horizontal>
            <dt>Summary</dt>
            <dd>{issue.summary}</dd>
            <dt>Details</dt>
            <dd>
              <UserEnteredTextRenderer
                text={issue.description}
                formatted={issue.formatted}
              />
            </dd>
            <dt>Created</dt>
            <dd>
              {toLocalTimeString(issue.created_at, config.tableDateFormat)}
            </dd>
            <dt>Due</dt>
            <dd>
              {dueAtFormatter({
                getValue: () => issue.due_at,
                row: { original: issue },
              })}
            </dd>
            <dt>Priority</dt>
            <dd>{getIssuePriorityLabel(issue.priority)}</dd>
            <dt>Status</dt>
            <dd>{getIssueStatusLabel(issue.status, true)}</dd>
            {issue.closed_at && (
              <>
                <dt>Close date</dt>
                <dd>
                  {toLocalTimeString(issue.closed_at, config.tableDateFormat)}
                </dd>
              </>
            )}
            {issue.attachments.length > 0 && (
              <>
                <dt>Attachments</dt>
                <dd>
                  <UnstyledList className="fa-ul">
                    {issue.attachments.map((attachment) => (
                      <li key={attachment.id} className="nobr">
                        <a target="_blank" href={attachment.url}>
                          <Icon icon="fa fa-li fa-file-text-o" />
                          {attachment.name}
                        </a>
                      </li>
                    ))}
                  </UnstyledList>
                </dd>
              </>
            )}
          </DList>
        </InfoCard>
        <InfoCard title="Comments">
          <CommentThread
            commentableType="Issue"
            commentableId={issue.id}
            vendorId={issue.vendor.id}
            canComment={canComment}
            onCommentCreated={onCommentCreated}
            isVendorContext
          />
        </InfoCard>
      </Left>
      <Right>
        <InfoCard title="Issue Source">
          <NarrowDList>
            <dt>Client</dt>
            <dd>{get(issue, 'client.name')}</dd>
            <IssueIssuable issue={issue} isVendorContext />
          </NarrowDList>
        </InfoCard>
      </Right>
    </Content>
  </div>
)

View.propTypes = {
  issue: PropTypes.object.isRequired,
  canComment: PropTypes.bool,
  onCommentCreated: PropTypes.func.isRequired,
}

export default View
