import React from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import Button from 'components-v2/atoms/Button'
import FormField from 'components-v2/molecules/FormField'
import { Heading, Footer } from './styles'

const initialValues = {
  body: undefined,
  attachments: [],
}

const validationSchema = yup.object().shape({
  body: yup.string().required('Please write a comment'),
})

export default (props) => (
  <Formik
    {...props}
    initialValues={initialValues}
    validationSchema={validationSchema}
  >
    {({ handleSubmit, isSubmitting, resetForm, setTouched, touched }) => (
      <form onSubmit={handleSubmit}>
        <Heading>Add a new comment</Heading>
        <FormField
          type={FormField.types.TEXTAREA}
          name="body"
          placeholder="Write a comment..."
          controlProps={{ rows: 2 }}
          preserveHelpTextSpace={false}
          onFocus={() => setTouched({ body: true })}
        />
        <Footer>
          <FormField
            type={FormField.types.FILES_INPUT}
            name="attachments"
            controlProps={{ maxFiles: 3 }}
            preserveHelpTextSpace={false}
            onChange={() => setTouched({ body: true })}
          />
          {touched.body && (
            <div>
              <Button
                type="button"
                disabled={isSubmitting}
                onClick={() => resetForm()}
              >
                Cancel
              </Button>
              &nbsp;&nbsp;
              <Button type="submit" color="primary" disabled={isSubmitting}>
                {isSubmitting ? 'Saving...' : 'Save'}
              </Button>
            </div>
          )}
        </Footer>
      </form>
    )}
  </Formik>
)
