import React from 'react'
import PropTypes from 'prop-types'
import merge from 'lodash/merge'
import get from 'lodash/get'
import { StyledChart } from './styles'

const defaultOptions = {
  title: '',
  hAxis: {
    title: '',
    ticks: [],
    textStyle: {
      fontSize: 14,
    },
  },
  vAxis: {
    title: '',
    minValue: 0,
    maxValue: 110,
    ticks: [0, 25, 50, 75, 100],
  },
  sizeAxis: {
    minValue: 0,
    maxSize: 20,
  },
  bubble: {
    textStyle: {
      fontSize: 14,
      color: 'white',
      auraColor: 'none',
    },
  },
  colorAxis: {
    colors: ['#202020', '#202020'],
    legend: { position: 'none' },
  },
  chartArea: { left: 45, top: 45, width: '85%', height: '70%' },
  legend: 'none',
  tooltip: { trigger: 'none' },
  series: {},
}

const createSVG = (n, a, b = {}) => {
  const xmlns = 'http://www.w3.org/2000/svg'
  const e = document.createElementNS(xmlns, n)

  Object.keys(a).forEach((k) => {
    e.setAttributeNS(null, k, a[k])
  })
  Object.keys(b).forEach((k) => {
    e.setAttribute(k, b[k])
  })
  return e
}

const chartEvents = [
  {
    eventName: 'ready',
    callback: ({ chartWrapper }) => {
      const options = chartWrapper.getOptions()
      const max = get(options, 'vAxis.maxValue', 100)
      const shift = (max - 100) / 4
      const step = 100 / 4

      const gradient = createSVG(
        'linearGradient',
        { x1: 1, y1: 0, x2: 1, y2: 1 },
        { id: `${options.chartId}hbcfx` },
      )
      // shift linear gradient by offset over 100%
      gradient.appendChild(createSVG('stop', { offset: '0%' }))
      gradient.appendChild(createSVG('stop', { offset: `${shift}%` }))
      gradient.appendChild(
        createSVG('stop', { offset: `${step * 1 + shift}%` }),
      )
      gradient.appendChild(
        createSVG('stop', { offset: `${step * 2 + shift}%` }),
      )
      gradient.appendChild(
        createSVG('stop', { offset: `${step * 3 + shift}%` }),
      )
      gradient.appendChild(createSVG('stop', { offset: '100%' }))

      const chartContainer = document.getElementById(
        chartWrapper.getContainerId(),
      )
      chartContainer.querySelector('svg>defs').appendChild(gradient)
    },
  },
]

const HeatBubbleChart = (props) => {
  const data =
    !props.data || props.data.length === 0 ? [['(none)', 0]] : props.data
  const options = merge({}, defaultOptions, props.options)

  options.chartId = React.useId()
  options.vAxis.title = props.vLabel
  options.hAxis.title = props.hLabel

  // move labels to fixed hAxis intervals
  const ticks = data.map((row, i) => ({ v: i + 1, f: row[0] }))
  // add empty first and last hAxis labels
  ticks.splice(0, 0, { v: 0, f: '' })
  ticks.push({ v: ticks.length, f: '' })
  options.hAxis.ticks = ticks

  // force series bubbles to the same specific x labels
  let series = {}
  series = data.reduce((acc, row) => {
    acc[row[0]] = { color: '#202020' }
    return acc
  }, series)
  options.series = series

  if (ticks.length > 12) {
    options.hAxis.textStyle.fontSize = 12
  } else if (ticks.length > 16) {
    options.hAxis.textStyle.fontSize = 11
  } else if (ticks.length > 18) {
    options.hAxis.textStyle.fontSize = 10
  }
  // from [['label', 50]]
  // to [ ['ID', 'X', 'Y', 'section', 'sectionSize'],
  //     ['34%', 1, 34, 'Security Policy', 10]]
  const table = data.map((row, i) => [`${row[1]}%`, i + 1, row[1], row[0]])
  table.splice(0, 0, ['%', 'Row', props.vLabel, props.hLabel])

  return (
    <StyledChart
      {...props}
      chartType="BubbleChart"
      data={table}
      options={options}
      chartEvents={chartEvents}
    />
  )
}

HeatBubbleChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ).isRequired,
  options: PropTypes.object,
  hLabel: PropTypes.string.isRequired,
  vLabel: PropTypes.string.isRequired,
}

export default HeatBubbleChart
