import styled from 'styled-components'
import { colors, mixins } from 'styles'

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: flex-end;
  margin-right: 10px;
`

export const Title = styled.h3`
  margin: 0;
  color: ${colors.HEADING};
  line-height: 20px;
`

export const PoweredBy = styled.div`
  margin: 0 9px 1px 11px;
  ${mixins.smallHeader};
  font-size: 10px;
  color: ${colors.GREY};
  line-height: 8px;
`

export const SourceImg = styled.img`
  width: ${(p) => p.width}px;
  height: auto;
  filter: grayscale(100%);
  opacity: 0.4;
`

export const ImageLink = styled.a`
  line-height: 0;
`

export const TooltipWrapper = styled.span`
  line-height: 0;
  margin-left: 8px;
`
