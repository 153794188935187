import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import Input from 'components-v2/atoms/Input'
import { BaseField, ErrorMessage } from 'components-v2/molecules/FormField'
import Tooltip from 'components-v2/molecules/Tooltip'
import { autoCompleteGlobalOrg } from 'apis'
import { useDebounce, useComparePrevious, useFormField } from 'hooks'
import {
  NameInputWrapper,
  ResultsLoader,
  GlobOrgResultsWrapper,
  GlobOrgResultsTitle,
  GlobOrgResults,
  GlobOrg,
  GlobOrgRadioWrapper,
  GlobOrgRadio,
  GlobOrgDomain,
  GlobOrgResultsClose,
  GlobOrgResultsShowAll,
} from './styles'

const GLOGORG_RESULTS_LIMIT = 3

const VendorNameDomainFieldGroup = ({ onSearching, ...rest }) => {
  const nameField = useFormField('name')
  const domainField = useFormField('domain')
  const [globOrgResults, setGlobOrgResults] = React.useState([])
  const [globOrgSearching, setGlobOrgSearching] = React.useState(false)
  const [resultsExpanded, setResultsExpanded] = React.useState(false)
  const name = nameField.value
  const domain = domainField.value
  const [debouncedName] = useDebounce(name, 500)
  const debouncedNameChanged = useComparePrevious(debouncedName)

  const handleGlobOrgSelect = React.useCallback(
    (globOrg) => {
      nameField.onChange(globOrg.name)
      domainField.onChange(globOrg.domain)
    },
    [nameField.onChange, domainField.onChange],
  )

  const handleResultsClose = React.useCallback(() => {
    domainField.onChange(undefined)
    setGlobOrgResults([])
  }, [domainField.onChange])

  const handleShowAllClick = React.useCallback(() => {
    setResultsExpanded(true)
  }, [])

  // Search globOrgs
  React.useEffect(() => {
    if (domain || !debouncedNameChanged) {
      return
    }

    if (debouncedName) {
      setGlobOrgSearching(true)
      onSearching(true)
      autoCompleteGlobalOrg({ params: { query: debouncedName } }).then(
        (response) => {
          setGlobOrgResults(response.data)
          setGlobOrgSearching(false)
          onSearching(false)
        },
      )
    } else {
      setGlobOrgResults([])
    }
  })

  const showAllResults =
    resultsExpanded || globOrgResults.length <= GLOGORG_RESULTS_LIMIT
  const filteredGlobOrgResults = showAllResults
    ? globOrgResults
    : globOrgResults.slice(0, GLOGORG_RESULTS_LIMIT)

  return (
    <BaseField {...rest} showError={nameField.showError} errorMessage="">
      <NameInputWrapper>
        <Input
          value={name || ''}
          onChange={(e) => nameField.onChange(e.currentTarget.value)}
        />
        {globOrgSearching && <ResultsLoader />}
      </NameInputWrapper>
      <ErrorMessage error={nameField.errorMessage} />
      {globOrgResults.length > 0 && (
        <GlobOrgResultsWrapper>
          <GlobOrgResultsTitle>
            We&apos;ve found {globOrgResults.length} matching public profiles.
          </GlobOrgResultsTitle>
          <GlobOrgResultsClose onClick={handleResultsClose}>
            Dismiss
          </GlobOrgResultsClose>
          <GlobOrgResults>
            {map(filteredGlobOrgResults, (globOrg) => (
              <GlobOrg key={globOrg.domain}>
                <GlobOrgRadioWrapper>
                  <Tooltip
                    parent={
                      <GlobOrgRadio
                        checked={domain === globOrg.domain}
                        onChange={() => handleGlobOrgSelect(globOrg)}
                        label={globOrg.name}
                        inline
                      />
                    }
                  >
                    {globOrg.name}
                  </Tooltip>
                </GlobOrgRadioWrapper>
                <GlobOrgDomain
                  href={`https://${globOrg.domain}`}
                  target="_blank"
                  underline
                >
                  {globOrg.domain}
                </GlobOrgDomain>
              </GlobOrg>
            ))}
          </GlobOrgResults>
          {!showAllResults && (
            <GlobOrgResultsShowAll onClick={handleShowAllClick}>
              View all
            </GlobOrgResultsShowAll>
          )}
        </GlobOrgResultsWrapper>
      )}
    </BaseField>
  )
}

VendorNameDomainFieldGroup.propTypes = {
  label: PropTypes.any,
  onSearching: PropTypes.func.isRequired,
}

export default VendorNameDomainFieldGroup
