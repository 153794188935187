import styled from 'styled-components'
import { colors, mixins } from 'styles'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${colors.GREY};
  border-radius: 7px;
  overflow: hidden;
`

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${colors.DARK_GREY};
  background: ${colors.ALT_TITLE_GREY};
  border-bottom: 1px solid ${colors.GREY};
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  padding: 6px 12px;
`

export const Title = styled.div`
  ${mixins.truncate}
  margin-right: 10px;
`

export const Content = styled.div`
  padding: 10px 12px;
  font-size: 15px;
  flex: 1;
`

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`

export const ItemName = styled.div`
  ${mixins.truncate}
  font-weight: bold;
  margin-right: 10px;
`

export const Bottom = styled.div`
  padding: 5px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
`
