import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { ExclamationCircle } from '@styled-icons/bootstrap/ExclamationCircle'
import Button from 'components-v2/atoms/Button'
import Icon from 'components-v2/atoms/Icon'
import IconButton from 'components-v2/atoms/IconButton'
import A from 'components-v2/atoms/A'
import Alert from 'components-v2/molecules/Alert'
import ButtonGroup from 'components-v2/molecules/ButtonGroup'
import FilterButtonGroup from 'components-v2/molecules/FilterButtonGroup'
import ToolbarGroup from 'components-v2/molecules/ToolbarGroup'
import MenuDropdown from 'components-v2/molecules/MenuDropdown'
import PageHeader from 'components-v2/organisms/PageHeader'
import Note from 'components/shared/Note'
import AssessmentElementsViewWithSectionPicker from 'components/shared/AssessmentElementsViewWithSectionPicker'
import { useAuthorizations } from 'hooks'
import { getPageUrl } from 'utils/url'
import { ExportFormatTypes, AssessmentStatusTypes } from 'constants/index'
import ResponseRow from './ResponseRow'
import AutomationReviewHeader from './AutomationReviewHeader'
import ScoreSheetHeader from './ScoreSheetHeader'
import { getElementFilterOptions } from './utils'
import {
  OrgNameLink,
  Toolbar,
  CircleIcon,
  AdditionalLinkList,
  AdditionalLink,
  NoteWrapper,
  NoteLabel,
  NoteBody,
  HeaderSplit,
} from './styles'

// Constants
import { ASSESSMENT_TABLE_ID_PREFIX } from './constants'

const AssessmentView = ({
  assessmentEndpointUrl,
  currentOrganization,
  assessment,
  elements,
  integrationMetrics,
  formattedIntegrationMetrics,
  signatureIntegrationMetrics,
  elementFilter,
  selectedSectionId,
  completed,
  hasClauses,
  libraryProfile,
  canImportResponses,
  canExportResponses,
  displayAutomationReview,
  questionAutomations,

  // callbacks
  onElementFilterChange,
  onReviewScoreChange,
  onReviewNoteChange,
  onSectionChange,
  onMarkReviewed,
  onAutoScoreEditButtonClick,
  onCreateIssueClick,
  onSendIssue,
  onAssign,
  onUnlock,
  onContinueReview,
  onComplete,
  onAddOrEditNotes,
  onCloseAutomationReview,
  onAutomationTransitionClick,
  onViewContractClausesClick,
  onViewAttachmentsClick,
  onViewQuestionAttachmentsClick,
  onTemplateExcelExportButtonClick,
  onViewIssuesClick,
  onViewIntegrationMetricsClick,
  onTranslateResponsesChange,
  onPrintClick,
  onAttachmentsChange,
  onInternalAttachmentsChange,
  onElementAttachmentsChange,
  onApproveExtensionRequest,
  onSubmitExpiredAssessment,
  onImportResponsesButtonClick,
}) => {
  const [canUpdate, canManage, canCompleteReview, canExportTemplate] =
    useAuthorizations('update', 'manage', 'complete_review', [
      'export',
      'AssessmentTemplate',
    ])
  const ratingLabelSet = assessment.assessment_template.rating_label_set
  const title = (
    <>
      <div className="subhead">{assessment.assessment_template.name}</div>
      <div className="strong">
        <OrgNameLink href={assessment.vendor.url}>
          {assessment.vendor.name}
        </OrgNameLink>
      </div>
    </>
  )
  const breadcrumbs = React.useMemo(() => {
    if (!assessment.project) {
      return null
    }
    return [
      { title: 'Projects', href: getPageUrl('clientProjects') },
      {
        title: assessment.project.friendly_name,
        href: assessment.project.path,
      },
      { title: assessment.name_for_client, active: true },
    ]
  }, [assessment.project, assessment.name_for_client])
  const elementFilterOptions = React.useMemo(
    () => getElementFilterOptions(ratingLabelSet),
    [ratingLabelSet],
  )
  return (
    <>
      <PageHeader title={title} breadcrumbs={breadcrumbs}>
        <ToolbarGroup>
          {canImportResponses && (
            <MenuDropdown title="Import">
              <MenuDropdown.Item onClick={onImportResponsesButtonClick}>
                <i className="fa fa-table" /> Import Responses
              </MenuDropdown.Item>
            </MenuDropdown>
          )}
          <Button color="primary" onClick={onPrintClick}>
            <Icon icon="fa fa-print" />
            Print
          </Button>
          {(canExportResponses || canExportTemplate) && (
            <MenuDropdown title="Export" icon="fa fa-table">
              {canExportResponses && (
                <MenuDropdown.Item
                  href={getPageUrl('clientAssessmentResponseExport', {
                    id: assessment.id,
                    format: ExportFormatTypes.CSV,
                  })}
                >
                  <Icon icon="fa fa-table" />
                  Responses (CSV)
                </MenuDropdown.Item>
              )}
              {canExportResponses && (
                <MenuDropdown.Item
                  href={getPageUrl('clientAssessmentResponseExport', {
                    id: assessment.id,
                    format: ExportFormatTypes.XLSX,
                  })}
                >
                  <Icon icon="fa fa-table" />
                  Responses (XLSX)
                </MenuDropdown.Item>
              )}
              {canExportTemplate && (
                <MenuDropdown.Item onClick={onTemplateExcelExportButtonClick}>
                  <Icon icon="fa fa-table" />
                  Assessment Template (XLSX)
                </MenuDropdown.Item>
              )}
            </MenuDropdown>
          )}
        </ToolbarGroup>
      </PageHeader>
      {libraryProfile && (
        <Alert color="info">
          Vendor Library Profile:{' '}
          <Button
            size="xs"
            href={getPageUrl('clientAssessmentAllowOnProfile', {
              id: assessment.id,
            })}
            rel="nofollow"
            data-method="post"
          >
            <i className="fa fa-fw fa-plus" />
            Allow on Vendor Library Profile
          </Button>
        </Alert>
      )}
      {displayAutomationReview && (
        <>
          <AutomationReviewHeader
            questionAutomations={questionAutomations}
            onAutomationTransitionClick={onAutomationTransitionClick}
            onCancelClick={onCloseAutomationReview}
          />
          <HeaderSplit />
        </>
      )}
      <ScoreSheetHeader
        assessment={assessment}
        apiPath={assessmentEndpointUrl}
        newContributorButtonVisible={
          currentOrganization.id === assessment.vendor_id
        }
        addNewContributorUrl={getPageUrl('organization')}
        onAssign={onAssign}
        currentOrganization={currentOrganization}
        integrationMetrics={integrationMetrics}
        onTranslateResponsesChange={onTranslateResponsesChange}
        onAttachmentsChange={onAttachmentsChange}
        onInternalAttachmentsChange={onInternalAttachmentsChange}
        onApproveExtensionRequest={onApproveExtensionRequest}
        onSubmitExpiredAssessment={onSubmitExpiredAssessment}
      />
      <Toolbar>
        <NoteWrapper>
          <CircleIcon icon="fa fa-sticky-note" />
          <NoteLabel>Notes</NoteLabel>
          {assessment.notes?.[0] && (
            <NoteBody>
              <Note note={assessment.notes[0]} />
            </NoteBody>
          )}
          {canUpdate && (
            <IconButton icon="fa fa-pencil" onClick={onAddOrEditNotes} />
          )}
        </NoteWrapper>
        <AdditionalLinkList>
          <AdditionalLink>
            <CircleIcon
              icon="fa fa-paperclip"
              background="linear-gradient(331.46deg, #2176DB 0%, #3385E7 0%, #4191F1 100%)"
            />
            <A onClick={onViewAttachmentsClick}>Attachments</A>
          </AdditionalLink>
          <AdditionalLink>
            <CircleIcon
              icon={ExclamationCircle}
              background="linear-gradient(163.57deg, #FF58A2 0%, #FF6F58 29.64%, #EB5808 100%)"
            />
            <A onClick={onViewIssuesClick}>Issues</A>
          </AdditionalLink>
          {hasClauses && (
            <AdditionalLink>
              <CircleIcon
                icon="fa fa-list-alt"
                background="linear-gradient(167.77deg, #1FCF77 0%, #2CC579 100%)"
              />
              <A onClick={onViewContractClausesClick}>AutoReport Sections</A>
            </AdditionalLink>
          )}
        </AdditionalLinkList>
      </Toolbar>
      <Toolbar>
        <FilterButtonGroup
          selected={elementFilter}
          filterOptions={elementFilterOptions}
          buttonSize="sm"
          onChange={onElementFilterChange}
        />
        {!assessment.invited && (
          <ButtonGroup className="hidden-print">
            {[
              AssessmentStatusTypes.IN_PROCESS,
              AssessmentStatusTypes.SUBMITTED,
              AssessmentStatusTypes.EXPIRED,
            ].includes(assessment.status) && (
              <Button
                onClick={onUnlock}
                active={assessment.status === AssessmentStatusTypes.IN_PROCESS}
                color={
                  assessment.status === AssessmentStatusTypes.IN_PROCESS
                    ? 'primary'
                    : 'default'
                }
                size="small"
                disabled={
                  assessment.status === AssessmentStatusTypes.IN_PROCESS ||
                  !canManage
                }
              >
                {assessment.status === AssessmentStatusTypes.IN_PROCESS
                  ? 'Unlocked'
                  : 'Unlock'}
              </Button>
            )}
            {assessment.status !== AssessmentStatusTypes.IN_PROCESS && (
              <Button
                size="small"
                onClick={onContinueReview}
                disabled={
                  assessment.status !== AssessmentStatusTypes.REVIEWED ||
                  !canManage
                }
                active={[
                  AssessmentStatusTypes.SUBMITTED,
                  AssessmentStatusTypes.EXPIRED,
                ].includes(assessment.status)}
                color={
                  [
                    AssessmentStatusTypes.SUBMITTED,
                    AssessmentStatusTypes.EXPIRED,
                  ].includes(assessment.status)
                    ? 'primary'
                    : 'default'
                }
              >
                In Review
              </Button>
            )}
            {assessment.status !== AssessmentStatusTypes.IN_PROCESS && (
              <MenuDropdown
                title="Complete"
                buttonProps={{
                  size: 'small',
                  active: assessment.status === AssessmentStatusTypes.REVIEWED,
                  color:
                    assessment.status === AssessmentStatusTypes.REVIEWED
                      ? 'primary'
                      : 'default',
                  disabled: completed || !canCompleteReview,
                }}
                onSelect={onComplete}
              >
                <MenuDropdown.Item eventKey="1">
                  <i className="fa fa-check-circle fa-lg text-very-good" />-
                  Pass
                </MenuDropdown.Item>
                <MenuDropdown.Item eventKey="-1">
                  <i className="fa fa-times-circle fa-lg text-very-poor" />-
                  Fail
                </MenuDropdown.Item>
                <MenuDropdown.Item eventKey="0">
                  <i className="fa fa-minus-circle fa-lg text-nothing" /> - No
                  Grade
                </MenuDropdown.Item>
              </MenuDropdown>
            )}
          </ButtonGroup>
        )}
      </Toolbar>
      <AssessmentElementsViewWithSectionPicker
        elements={elements}
        sections={assessment.sections}
        selectedSectionId={selectedSectionId}
        useCustomKeys={get(assessment, 'assessment_template.use_custom_keys')}
        tableId={ASSESSMENT_TABLE_ID_PREFIX + assessment.id}
        renderQuestionRow={(props) => (
          <ResponseRow
            {...props}
            formattedIntegrationMetrics={formattedIntegrationMetrics}
            signatureIntegrationMetrics={signatureIntegrationMetrics}
            vendorId={assessment.vendor_id}
            reviewable={assessment.reviewable}
            isReviewed={assessment.reviewed}
            isClientSubmitted={assessment.client_submitted}
            scorable={assessment.scorable}
            completed={completed}
            ratingLabelSet={ratingLabelSet}
            onReviewScoreChange={onReviewScoreChange}
            onReviewNoteChange={onReviewNoteChange}
            onMarkReviewed={onMarkReviewed}
            onAutoScoreEditButtonClick={onAutoScoreEditButtonClick}
            onCreateIssueClick={onCreateIssueClick}
            onSendIssue={onSendIssue}
            onAttachmentsChange={onElementAttachmentsChange}
            onViewIntegrationMetricsClick={onViewIntegrationMetricsClick}
            onViewAttachmentsClick={onViewQuestionAttachmentsClick}
          />
        )}
        onSectionChange={onSectionChange}
      />
    </>
  )
}

AssessmentView.propTypes = {
  assessmentEndpointUrl: PropTypes.string.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  assessment: PropTypes.object.isRequired,
  elements: PropTypes.array.isRequired,
  integrationMetrics: PropTypes.object,
  formattedIntegrationMetrics: PropTypes.object,
  signatureIntegrationMetrics: PropTypes.array,
  elementFilter: PropTypes.object.isRequired,
  selectedSectionId: PropTypes.string,
  completed: PropTypes.bool,
  hasClauses: PropTypes.bool,
  libraryProfile: PropTypes.bool,
  canImportResponses: PropTypes.bool,
  canExportResponses: PropTypes.bool,
  displayAutomationReview: PropTypes.bool,
  questionAutomations: PropTypes.array,
  onElementFilterChange: PropTypes.func.isRequired,
  onReviewScoreChange: PropTypes.func.isRequired,
  onReviewNoteChange: PropTypes.func.isRequired,
  onSectionChange: PropTypes.func.isRequired,
  onMarkReviewed: PropTypes.func.isRequired,
  onAutoScoreEditButtonClick: PropTypes.func.isRequired,
  onCreateIssueClick: PropTypes.func.isRequired,
  onAssign: PropTypes.func.isRequired,
  onUnlock: PropTypes.func.isRequired,
  onContinueReview: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onAddOrEditNotes: PropTypes.func.isRequired,
  onCloseAutomationReview: PropTypes.func.isRequired,
  onAutomationTransitionClick: PropTypes.func.isRequired,
  onViewContractClausesClick: PropTypes.func.isRequired,
  onViewAttachmentsClick: PropTypes.func.isRequired,
  onViewQuestionAttachmentsClick: PropTypes.func.isRequired,
  onTemplateExcelExportButtonClick: PropTypes.func.isRequired,
  onViewIssuesClick: PropTypes.func.isRequired,
  onViewIntegrationMetricsClick: PropTypes.func.isRequired,
  onTranslateResponsesChange: PropTypes.func.isRequired,
  onPrintClick: PropTypes.func.isRequired,
  onSendIssue: PropTypes.func.isRequired,
  onAttachmentsChange: PropTypes.func.isRequired,
  onInternalAttachmentsChange: PropTypes.func.isRequired,
  onElementAttachmentsChange: PropTypes.func.isRequired,
  onApproveExtensionRequest: PropTypes.func.isRequired,
  onSubmitExpiredAssessment: PropTypes.func.isRequired,
  onImportResponsesButtonClick: PropTypes.func.isRequired,
}

export default AssessmentView
