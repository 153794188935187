import React from 'react'
import map from 'lodash/map'
import { useAuthManager } from 'lib/authorizationContext'

export default function useAuthorizations(...actions) {
  const authManager = useAuthManager()
  return React.useMemo(
    () =>
      map(actions, (action) => {
        if (!authManager) {
          return false
        }
        if (Array.isArray(action)) {
          return authManager.userCan(...action)
        }
        return authManager.userCan(action)
      }),
    [...actions],
  )
}
