import styled from 'styled-components'
import SearchableSelect from 'components-v2/molecules/SearchableSelect'

export const Content = styled.div`
  max-width: 850px;

  > * {
    margin-bottom: 35px;
  }
`

export const TemplateSelect = styled(SearchableSelect)`
  width: 270px;
  flex: none;
`
