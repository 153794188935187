import styled from 'styled-components'
import Menu from 'components-v2/molecules/Menu'
import UserAvatar from 'components-v2/molecules/UserAvatar'
import { colors, media } from 'styles'

export const StyledMenu = styled(Menu)`
  vertical-align: top;
  ${media.mobile`
    width: 100%;
  `}
`

export const MenuItems = styled(Menu.Items)`
  > div {
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    > div {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  ${media.mobile`
    position: static !important;

    > div {
      border: 0;
      box-shadow: none;

      > div {
        border: 0;
        background-color: transparent;
      }
    }
  `}
`

export const MenuItem = styled(Menu.Item)`
  font-weight: normal;
  padding-left: 20px;
  padding-right: 20px;

  i {
    opacity: 0.7;
  }

  ${({ active }) =>
    active &&
    media.nmobile`
    color: ${colors.SMARSH_BLUE};
  `}

  ${media.mobile`
    padding: 10px 15px 10px 30px;
    --color: white;
    --color-hover: white;
    --bg-hover: rgba(255, 255, 255, 0.3);
    color: var(--color);

    &[data-headlessui-state='active'] {
      color: var(--color-hover);
      background-color: var(--bg-hover);
    }

    [data-theme='dark'] & {
      --color: #777;
      --color-hover: #333;
      --bg-hover: rgba(0, 0, 0, 0.1);
    }
  `}
`

export const StyledUserAvatar = styled(UserAvatar)`
  margin-right: 8px;

  ${({ noSrc }) =>
    noSrc &&
    `
    color: inherit !important;
    border: 1px solid currentColor;
    border-radius: 100%;
  `}
`

export const Caret = styled.span`
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: middle;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  margin-left: 8px;

  ${media.mobile`
    margin-left: auto;
  `}
`
