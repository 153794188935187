import styled from 'styled-components'
import Card from 'components-v2/organisms/Card'

export const Wrapper = styled(Card).attrs({ bordered: true, padding: true })`
  text-align: center;
`

export const Title = styled.h6`
  margin: 0 0 20px;
`
