import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Key, Label } from './styles'

const SectionHeadingRow = ({
  element,
  translatable,
  useCustomKeys,
  ...rest
}) => (
  <Wrapper
    {...rest}
    data-test-id={`section-heading-${element.key}`}
    id={element.id}
  >
    <Key customKey={useCustomKeys}>{element.key}</Key>
    <Label className={!translatable && 'no-lang'}>{element.label}</Label>
  </Wrapper>
)

SectionHeadingRow.propTypes = {
  element: PropTypes.object.isRequired,
  translatable: PropTypes.bool,
  useCustomKeys: PropTypes.bool,
}

export default SectionHeadingRow
