import React from 'react'
import Privva from 'Privva'
import get from 'lodash/get'
import keys from 'lodash/keys'
import findLast from 'lodash/findLast'
import { Check } from '@styled-icons/boxicons-regular/Check'
import Icon from 'components-v2/atoms/Icon'
import {
  OsanoScoreRangeConfig,
  OsanoContributingFactors,
} from 'constants/index'
import { colors } from 'styles'

const findScoreRange = (score, rangeConfig) => {
  const ckeys = keys(rangeConfig).sort((a, b) => a - b)
  const ckey = findLast(ckeys, (key) => key <= score)
  return ckey ? rangeConfig[ckey] : undefined
}

const ArgosRiskMetricIconOptionsObj = {
  green: {
    icon: 'fa fa-check-circle',
    color: colors.GREEN,
  },
  yellow: {
    icon: 'fa fa-minus-circle',
    color: colors.YELLOW,
  },
  red: {
    icon: 'fa fa-times-circle',
    color: colors.RED,
  },
}

export const parseArgosRiskData = (raw) => {
  if (!raw) return null

  const { date, ar_score_trend_sort: trendSort } = raw
  let trendSortIcon
  if (trendSort === 1) {
    trendSortIcon = 'fa fa-arrow-up'
  } else if (trendSort === 2) {
    trendSortIcon = 'fa fa-arrow-right'
  } else if (trendSort === 3) {
    trendSortIcon = 'fa fa-arrow-down'
  }
  return [
    {
      category: 'Risk Score',
      code: 'argosrisk_score',
      total: 100,
      iconType: raw.argosrisk_score_icon,
      description:
        'The Argos Risk Score is a proprietary metric that assesses the overall financial, payment, and on-going risk associated with a specific business. It combines multiple risk factors into one simple score. The higher the score, the lower the risk of doing business with that company. This score takes into account over 10,000 data points on anything from the trade payments, credit history, industry trends, geographic concentration risks, consumer complaints, and public filings. This score is on a 0 to 100 scale and is color coded for easy interpretation with red = high risk, yellow = moderate risk and green = low risk.',
    },
    {
      category: '30-Day Trend',
      code: 'ar_score_trend',
      value: trendSortIcon ? <Icon icon={trendSortIcon} /> : undefined,
      iconType: raw.ar_score_trend,
      description: 'Direction the risk score has moved in the last 30 days',
    },
    {
      category: 'Business Health Index',
      code: 'business_health_index',
      total: 100,
      iconType: raw.business_health_index_icon,
      description:
        'Score that predicts the Business Health of a company out of 100. Factors that positively impact the Business Health Index score: timely vendor payments, increased high credit offered by vendors, higher number of trade payment records, low number of past due trade experiences, low number of public record filings. Factors that negatively impact the Business Health Index score: higher number of public record filings, poor overall industry trends, challenging geographic location, declining business scores',
    },
    {
      category: 'Likelihood of Consistent Payment',
      code: 'likelihood_consistent_payment',
      total: 100,
      iconType: raw.likelihood_consistent_payment_icon,
      description:
        'Score that predicts the likelihood of a business to pay its vendors on a timely basis out of 100. ',
    },
    {
      category: 'Days Beyond Terms',
      code: 'days_beyond_terms',
      iconType: raw.days_beyond_terms_icon,
      description:
        'Average number of days past the due date the vendor pays invoices',
    },
  ].map((e) => ({
    updated_at: date,
    color: get(ArgosRiskMetricIconOptionsObj, [e.iconType, 'color']),
    icon: get(ArgosRiskMetricIconOptionsObj, [e.iconType, 'icon']),
    value: raw[e.code],
    ...e,
  }))
}

const RiskReconRatingRangeConfig = {
  8.5: {
    icon: 'fa fa-check-circle',
    color: colors.GREEN,
  },
  7.0: {
    icon: 'fa fa-check-circle',
    color: colors.BLUE,
  },
  5.5: {
    icon: 'fa fa-minus-circle',
    color: colors.YELLOW,
  },
  4.0: {
    icon: 'fa fa-exclamation-circle',
    color: colors.ORANGE,
  },
  0: {
    icon: 'fa fa-times-circle',
    color: colors.RED,
  },
}

export const RiskReconIssuePriorityTypes = {
  PRIORITY1: 'p1',
  PRIORITY2: 'p2',
}

export const parseRiskReconData = (raw) => {
  if (!raw) return null
  return [
    {
      category: 'Overall Grade',
      code: 'rating_numeric',
      grade: raw.rating_v2?.toUpperCase(),
      recommendation: raw.rating,
      description:
        'Summary grade of risk based on the component scores. The higher the grade, the lower the risk',
    },
    {
      category: 'Risk Priority 1 Issues',
      code: 'p1_issue_count',
      description:
        'Count of critical severity issues affecting high value assets',
      color: colors.RED,
      type: 'issue_count',
      priority: RiskReconIssuePriorityTypes.PRIORITY1,
    },
    {
      category: 'Risk Priority 2 Issues',
      code: 'p2_issue_count',
      description:
        'Count of critical severity issues affecting high value assets',
      color: '#ee832c',
      type: 'issue_count',
      priority: RiskReconIssuePriorityTypes.PRIORITY2,
    },
    {
      category: 'Application Security',
      code: 'web_app_security_rating',
      baseMetric: true,
      description:
        'Assesses the degree to which web applications employ basic security mechanisms to help defend against unauthorized application access',
    },
    {
      category: 'Breach Events',
      code: 'data_loss_rating',
      baseMetric: true,
      description:
        'The Breach Event domain summarizes the breach events the organization has experienced',
    },
    {
      category: 'Defensibility',
      code: 'defensibility_rating',
      description:
        'Measures the complexity of defending the public-facing attack surface area',
    },
    {
      category: 'DNS Security',
      code: 'dns_security_rating',
      baseMetric: true,
      description:
        'Measures susceptibility to unauthorized modification of domain records which could result in domain hijacking',
    },
    {
      category: 'Email Security',
      code: 'email_security_rating',
      baseMetric: true,
      description:
        'Assesses authentication and encryption control utilization for the purposes of preventing email spoofing and interception of communications',
    },
    {
      category: 'Email Encryption',
      code: 'email_encryption_rating',
      subMetricOf: 'email_security_rating',
      description:
        'Measures how many email servers do not implement STARTTLS encryption relative to the total number in use. Encryption via STARTTLS helps prevent interception of email communications.',
    },
    {
      category: 'Governance',
      code: 'governance_rating',
      description: 'Measures compliance with security governance standards',
    },
    {
      category: 'IOT Devices',
      code: 'iot_devices_rating',
      subMetricOf: 'network_filtering_rating',
      description:
        'Measures the number of internet accessible Internet of Things (IoT) devices on the organizations networks and systems. IoT devices are typically not resistant to compromise, and should not be exposed to the Internet.',
    },
    {
      category: 'Network Filtering',
      code: 'network_filtering_rating',
      baseMetric: true,
      description:
        'Analyzes company networks and systems to detect network services and IoT devices, which are not safe to expose on the Internet. Only the systems and services that are safe and necessary should be exposed to the public Internet.',
    },
    {
      category: 'Software Patching',
      code: 'software_patching_rating',
      baseMetric: true,
      description:
        'Estimates risk of insecure systems based on the number of systems running end of life and vulnerable software',
    },
    {
      category: 'System Hosting',
      code: 'system_hosting_rating',
      baseMetric: true,
      description:
        'Analyzes hosting practices based on hosting providers and the countries in which they reside',
    },
    {
      category: 'System Reputation',
      code: 'threat_intell_rating',
      baseMetric: true,
      description:
        'Measures systems that are known to be compromised or exhibiting malicious behavior',
    },
    {
      category: 'Web CMS Security',
      code: 'web_cms_authentication_rating',
      subMetricOf: 'web_app_security_rating',
      description:
        'Analyzed the percentage of content management systems that have a publicly accessible administration interface with only one factor authentication.',
    },
    {
      category: 'Web Encryption',
      code: 'web_encryption_rating',
      baseMetric: true,
      description: 'Analyzes the effectiveness of encryption implementations',
    },
  ].map((e) => {
    const rating = raw[e.code]
    const range = findScoreRange(rating, RiskReconRatingRangeConfig)
    return {
      updated_at: raw.date,
      value: rating,
      color: range?.color,
      icon: range?.icon,
      total: 10,
      ...e,
    }
  })
}

export const getRiskReconRatingColor = (rating) => {
  const range = findScoreRange(rating, RiskReconRatingRangeConfig)
  return range?.color
}

export const parseSpyCloudData = (raw) => {
  if (!raw) return null
  return [
    {
      category: 'Exposed records',
      code: 'records',
      description:
        'Total number of employees with a corporate email address found within a data breach record. Each record contains info that a criminal can use to take over the account or commit fraud (like passwords & PII).',
    },
    {
      category: 'C-Suite',
      code: 'C-Level',
      description:
        'Breached corporate credentials that are tied to high-ranking titles, putting them at increased risk of account takeover and business email compromise fraud.',
    },
  ].map((e) => ({
    updated_at: raw.date,
    value: raw[e.code],
    ...e,
  }))
}

export const parseOsanoData = (raw) => {
  if (!raw) return null

  const { score } = raw
  const range = findScoreRange(score, OsanoScoreRangeConfig)
  return {
    value: score,
    description:
      'Ranking of privacy practices based on review of published privacy documents.',
    rangeLabel: range?.label,
    rangeDescription: range?.description,
    color: range?.color,
    code: null,
    updated_at: raw.date,
    contributingFactors: OsanoContributingFactors.filter((e) =>
      raw.contributing_factors?.includes(e.name),
    ),
  }
}

export const OFAC_MATCH_LABEL = Privva.Utils.OfacMatchLabel
export const OFAC_NO_MATCH_LABEL = Privva.Utils.OfacNoMatchLabel
export const OFAC_NO_INFO_LABEL = Privva.Utils.OfacNoInfoLabel

export const parseOfacData = (raw) => {
  const status = raw?.status
  if (status === 'Confirmed Match' || status === 'Strong Match') {
    return {
      value: 'match',
      label: OFAC_MATCH_LABEL,
      description: `This person, company, or organization has been matched against the OFAC Sanction list. This means economic and/or legal restrictions have been imposed upon the organization by the The Office of Foreign Assets Control ("OFAC") of the US Department of the Treasury.`,
      color: colors.RED,
      icon: 'fa fa-ban',
    }
  }
  if (
    status === 'No Match Found' ||
    status === 'No Match' ||
    status === 'Weak Match'
  ) {
    return {
      value: 'no_match',
      label: OFAC_NO_MATCH_LABEL,
      description: `This person, company, or organization has not been matched against the OFAC Sanction list. This means economic and/or legal restrictions have not been imposed upon the organization by the The Office of Foreign Assets Control ("OFAC") of the US Department of the Treasury.`,
      color: colors.GREEN,
      icon: Check,
    }
  }
  return {
    value: 'not_enough_info',
    label: OFAC_NO_INFO_LABEL,
    description: `There is not enough data to confirm a match for this person, company, or organization against the OFAC Sanction list.`,
    color: colors.MED_GREY,
    icon: 'fa fa-question-circle-o',
  }
}
