import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import Dialog from 'components-v2/molecules/Dialog'
import Button from 'components-v2/atoms/Button'
import Radio from 'components-v2/atoms/Radio'
import Table from 'components-v2/molecules/Table'
import { autoCompleteGlobalOrg } from 'apis'
import { LabelTypes } from 'constants/index'
import {
  Body,
  Footer,
  SearchWrapper,
  StyledSearchInput as SearchInput,
  SearchButton,
  LoadingIcon,
  ResultsTitle,
  // GreenCheckIcon,
  // RedCloseIcon,
  Muted,
  CreateNewButton,
  FooterSpacer,
} from './styles'

const getKey = (row) => (row ? `${row.name}(${row.domain})` : null)

const getTableColumns = (setSelected) => [
  {
    id: 'df1',
    header: LabelTypes.VENDOR,
    cell: (info) => (
      <div>
        <Radio
          inline
          label={info.row.original.name}
          checked={info.row.original.selected}
          onChange={() => setSelected(info.row.original)}
        />
      </div>
    ),
  },
  // {
  //   id: 'industry',
  //   header: 'Industry',
  //   width: 120,
  // },
  {
    id: 'domain',
    header: 'Domain',
    width: 200,
  },
  // {
  //   id: 'sig',
  //   header: 'Sig',
  //   cell: (info) => info.getValue() ? <GreenCheckIcon /> : <RedCloseIcon />,
  //   width: 38,
  // },
]

const getTableData = (data, selected) =>
  map(data, (row) => ({
    ...row,
    id: getKey(row),
    selected: getKey(selected) === getKey(row),
  }))

const SearchGlobOrgModal = ({
  title,
  submitButtonLabel,
  createButtonVisible,
  onClose,
  onSubmit,
  onCreate,
  ...rest
}) => {
  const [term, setTerm] = React.useState('')
  const [searchedTerm, setSearchedTerm] = React.useState('')
  const [selected, setSelected] = React.useState(null)
  const [results, setResults] = React.useState([])
  const [searching, setSearching] = React.useState(false)

  const handleSearch = () => {
    if (!term) {
      return
    }
    setSearching(true)
    autoCompleteGlobalOrg({ params: { query: term } }).then((response) => {
      setSearchedTerm(term)
      setResults(response.data)
      setSelected(null)
      setSearching(false)
    })
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  const tableColumns = React.useMemo(() => getTableColumns(setSelected), [])
  const data = React.useMemo(
    () => getTableData(results, selected),
    [results, selected],
  )

  return (
    <Dialog {...rest} onClose={onClose}>
      <Dialog.Header onClose={onClose}>{title}</Dialog.Header>
      <Body>
        <SearchWrapper>
          <SearchInput
            value={term}
            onChange={setTerm}
            debounceWait={0}
            onKeyPress={handleKeyPress}
            autoFocus
          />
          <SearchButton onClick={handleSearch}>Search</SearchButton>
          {searching && <LoadingIcon />}
        </SearchWrapper>
        <ResultsTitle>
          {results.length > 0
            ? `Search results (${results.length})`
            : 'Search results'}
        </ResultsTitle>
        {searchedTerm ? (
          results.length > 0 ? (
            <Table
              data={data}
              columns={tableColumns}
              enablePagination={false}
              enableSorting={false}
              type={Table.types.MINI2}
            />
          ) : (
            <Muted>No vendors found.</Muted>
          )
        ) : (
          <Muted>
            Start by searching for a {LabelTypes.VENDOR.toLowerCase()}.
          </Muted>
        )}
      </Body>
      <Footer>
        {createButtonVisible && searchedTerm && (
          <CreateNewButton onClick={() => onCreate(searchedTerm)}>
            <i className="fa fa-plus-circle" />
            {`Create ${LabelTypes.VENDOR.toLowerCase()} "${searchedTerm}"`}
          </CreateNewButton>
        )}
        <FooterSpacer />
        <Button onClick={onClose}>Cancel</Button>
        <Button
          color="primary"
          onClick={() => onSubmit(selected)}
          disabled={!selected}
        >
          {submitButtonLabel}
        </Button>
      </Footer>
    </Dialog>
  )
}

SearchGlobOrgModal.propTypes = {
  title: PropTypes.string.isRequired,
  submitButtonLabel: PropTypes.any,
  createButtonVisible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCreate: PropTypes.func,
}

export default SearchGlobOrgModal
