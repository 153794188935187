import styled from 'styled-components'
import { GripLines } from '@styled-icons/fa-solid/GripLines'
import { Row, Col } from 'components-v2/organisms/Layout'

export const QuestionRow = styled(Row)`
  margin: 20px 0;
`

export const QuestionContainer = styled(Col)`
  border-bottom: solid 1px #ebebeb;
  padding-bottom: 10px;
  display: inline-flex;
`

export const QuestionNumber = styled(Col)`
  font-size: 14px;
  font-weight: 400;
  height: 25px;
`

export const GripLinesIcon = styled(GripLines)`
  width: 10px;
  cursor: move;
`

export const QuestionTextControls = styled(Col)`
  display: inline-block;
`

export const QuestionLabel = styled.div`
  padding-right: 30px;
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
`

export const QuestionIcons = styled.div`
  display: inline-block;
`

export const Icon = styled.i`
  margin-left: 10px;
  cursor: pointer;
`
