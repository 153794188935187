import React from 'react'
import PropTypes from 'prop-types'
import AutoLink from 'components-v2/atoms/AutoLink'
import Uploader from 'components-v2/molecules/Uploader'
import AssessmentElementNoteTooltip from 'components/shared/AssessmentElementNoteTooltip'
import AssessmentElementAnswerForm from 'components/shared/AssessmentElementAnswerForm'
import ResponseScoreSelect from 'components/shared/ResponseScoreSelect'
import {
  Row,
  KeyColumn,
  QuestonColumn,
  QuestionLabel,
  CommentWrapper,
  ReviewScoreColumn,
  ActionColumn,
  ActionWrapper,
  EyeSlashIcon,
  CommentIcon,
} from './styles'

const QuestionRow = ({
  element,
  isReviewed,
  useCustomKeys,
  ratingLabelSet,
  ...rest
}) => {
  const { response } = element
  return (
    <Row {...rest} skipped={element.skipped}>
      <KeyColumn customKey={useCustomKeys}>
        {element.key}
        {element.required && ' *'}
      </KeyColumn>
      <QuestonColumn>
        <QuestionLabel>
          <AutoLink text={element.label} />
          &nbsp;
          <AssessmentElementNoteTooltip element={element} />
        </QuestionLabel>
        {!element.skipped && (
          <>
            <AssessmentElementAnswerForm
              element={element}
              value={
                response && (response.translated_answer || response.answer)
              }
              disabled
              placeholder="(No answer provided)"
            />
            {response && response.comment && (
              <CommentWrapper>
                <CommentIcon isReviewed={isReviewed} />
                {response.translated_comment || response.comment}
              </CommentWrapper>
            )}
            {response?.attachments?.length > 0 && (
              <Uploader
                files={response && response.attachments}
                disabled
                displayLabel={false}
              />
            )}
          </>
        )}
      </QuestonColumn>
      <ReviewScoreColumn>
        {!element.skipped &&
          response &&
          Number.isFinite(response.review_score) && (
            <ResponseScoreSelect
              value={response.review_score}
              weight={element.weight}
              ratingLabelSet={ratingLabelSet}
              disabled
            />
          )}
      </ReviewScoreColumn>
      <ActionColumn>
        {!element.skipped && isReviewed && response && response.review_note && (
          <ActionWrapper>
            <CommentWrapper>
              <CommentIcon isReviewed={isReviewed} />
              <AutoLink text={response.review_note} />
            </CommentWrapper>
          </ActionWrapper>
        )}
        {element.skipped && (
          <ActionWrapper>
            <EyeSlashIcon />
            &nbsp;
            <i>Disabled</i>
          </ActionWrapper>
        )}
      </ActionColumn>
    </Row>
  )
}

QuestionRow.propTypes = {
  element: PropTypes.object.isRequired,
  isReviewed: PropTypes.bool,
  useCustomKeys: PropTypes.bool,
  ratingLabelSet: PropTypes.string,
}

export default QuestionRow
