import React from 'react'
import map from 'lodash/map'
import split from 'lodash/split'

export const LONG_TEXT_LENGTH = 750

export const formatText = (text, tail) => {
  const parsed = split(text, /(?:\r\n|\r|\n)+/)
  const combined = map(parsed, (p, idx) => {
    if (tail && idx === parsed.length - 1) {
      return (
        <p key={`lgTxt-${idx}`}>
          {p} {tail}
        </p>
      )
    }
    return <p key={`lgTxt-${idx}`}>{p}</p>
  })
  return combined
}
