import { useCallback } from 'react'
import useCreateUpdateIssue from 'components/client/shared/useCreateUpdateIssue'
import { useConfirm, useGlobalLoader } from 'hooks'
import NotificationManager from 'lib/notifications'
import { getPageUrl } from 'utils/url'
import { useUpdateClientIssue, useDeleteClientIssue } from 'apis'
import { IssueTransitionTypes } from 'constants/index'

export default function useCRUDIssue(issue) {
  const confirm = useConfirm()
  const [showLoader, hideLoader] = useGlobalLoader()
  const { updateIssue } = useCreateUpdateIssue()
  const { mutateAsync: updateClientIssue } = useUpdateClientIssue()
  const { mutateAsync: deleteClientIssue } = useDeleteClientIssue()

  const editIssue = useCallback(() => {
    updateIssue({ issue })
  }, [issue, updateIssue])

  const deleteIssue = useCallback(() => {
    confirm({
      title: 'Please Confirm',
      body: 'Are you sure you want to delete this issue?',
      confirmText: 'Yes, delete this issue',
      cancelText: 'Cancel',
      onConfirm: () => {
        deleteClientIssue({ id: issue.id }).then(() =>
          window.location.replace(getPageUrl('clientIssues')),
        )
      },
    })
  }, [issue.id, confirm])

  const transitionIssue = useCallback(
    async (transition, additionalData = {}) => {
      const loaderId = showLoader()
      try {
        await updateClientIssue({
          id: issue.id,
          data: {
            issue: { transition, ...additionalData },
          },
        })
      } catch (error) {
        console.error(error)
        NotificationManager.error()
      }
      hideLoader(loaderId)
    },
    [issue.id, showLoader, hideLoader],
  )

  const closeIssue = useCallback(
    (resolution) => {
      transitionIssue(IssueTransitionTypes.CLOSE, { resolution })
    },
    [transitionIssue],
  )

  const sendIssue = useCallback(() => {
    transitionIssue(IssueTransitionTypes.OPEN)
  }, [transitionIssue])

  const archiveIssue = useCallback(() => {
    transitionIssue(IssueTransitionTypes.ARCHIVE)
  }, [transitionIssue])

  const reopenIssue = useCallback(() => {
    transitionIssue(IssueTransitionTypes.REOPEN)
  }, [transitionIssue])

  return {
    editIssue,
    deleteIssue,
    closeIssue,
    sendIssue,
    archiveIssue,
    reopenIssue,
  }
}
