import React from 'react'
import map from 'lodash/map'
import { Transition, TransitionGroup } from 'react-transition-group'
import ModalManager from 'lib/modal'

const duration = 300 // Should be same as bootstrap modal's transition duration

export default () => {
  const [modals, setModals] = React.useState(ModalManager.modals)

  React.useEffect(() => {
    ModalManager.addChangeListener(setModals)
    return () => {
      ModalManager.removeChangeListener(setModals)
    }
  }, [])

  return (
    <TransitionGroup>
      {map(modals, ({ component: Component, props = {} }, key) => (
        <Transition key={key} timeout={duration}>
          {(state) => (
            <Component
              onClose={() => ModalManager.closeModal(key)}
              {...props}
              open={state !== 'exiting' && state !== 'exited'}
            />
          )}
        </Transition>
      ))}
    </TransitionGroup>
  )
}
