import { localTimeZoneOffsetInHours } from 'utils/date'
import {
  AssessmentStatusTypes,
  LabelTypes,
  RiskTierOptions,
} from 'constants/index'
import { exportClientAssessmentsToCSV } from 'lib/export'
import DateRangeFilter, {
  getDateRangerFilterSelectedCount,
} from './DateRangeFilter'

export function exportToCSV(
  {
    searchText,
    sortField,
    sortOrder,
    vendorTags,
    vendorTagsAnyMatch,
    statuses,
    assignees,
    unassigned,
    riskTiers,
    assessmentTemplates,
    archiveValues,
    dateType,
    startDate,
    endDate,
  },
  columnToggles,
) {
  exportClientAssessmentsToCSV({
    search_query: searchText,
    ordered_by: sortField,
    direction: sortOrder,
    with_vendor_tags: vendorTags.join(','),
    any: vendorTagsAnyMatch,
    status: statuses.join(','),
    client_assignee: assignees.join(','),
    unassigned,
    risk_tier_list: riskTiers,
    with_templates: assessmentTemplates,
    date_type: dateType,
    start_date: startDate,
    end_date: endDate,
    tz: localTimeZoneOffsetInHours,
    exclude_archived_vendors: !archiveValues?.includes('vendors'),
    exclude_archived: !archiveValues?.includes('projects'),
    column_filter: columnToggles,
  })
}

export function validateAnalysisSelection(assessments, selectedAssessmentIds) {
  if (selectedAssessmentIds.length > 5) {
    return {
      error: true,
      message: 'You cannot create a report comparing more than 5 assessments.',
    }
  }

  const selectedAssessments = assessments.filter(
    (e) => selectedAssessmentIds.indexOf(e.id) !== -1,
  )
  const isNotReviewed = selectedAssessments.some(
    (assessment) => assessment.status !== AssessmentStatusTypes.REVIEWED,
  )
  if (isNotReviewed) {
    return {
      error: true,
      message:
        'You can only create a report with assessments with a reviewed status',
    }
  }

  let templateId = ''
  const hasMultipleTemplates = selectedAssessments.some((assessment) => {
    if (templateId === '') {
      templateId = assessment.assessment_template_id
    }
    return templateId !== assessment.assessment_template_id
  })

  if (hasMultipleTemplates) {
    return {
      error: true,
      message:
        'You cannot create a report comparing assessments using different templates.',
    }
  }

  return { error: false }
}

const statusFilterVals = [
  AssessmentStatusTypes.INVITED,
  AssessmentStatusTypes.IN_PROCESS,
  AssessmentStatusTypes.SUBMITTED,
  AssessmentStatusTypes.REVIEWED,
  AssessmentStatusTypes.EXPIRED,
]
const statusFilterOptions = statusFilterVals.map((value) => ({
  value,
  label: value.titleize(),
}))

export const getFilterConfigList = (users, templates, vendorTags) => {
  const filterConfigList = [
    {
      name: 'statuses',
      title: 'Status',
      options: statusFilterOptions,
      selectAllVisible: true,
    },
    {
      name: 'assignees',
      title: 'Assignee(s)',
      options: users.map((s) => ({ value: s.id, label: s.name })),
      selectAllVisible: true,
      otherVisible: true,
      otherLabel: 'Unassigned',
    },
    {
      name: 'riskTiers',
      title: `${LabelTypes.VENDOR} Risk Tier`,
      options: [...RiskTierOptions, { value: 'none', label: 'None' }],
      selectAllVisible: true,
    },
    {
      name: 'assessmentTemplates',
      title: 'Templates',
      options: templates.map((s) => ({ value: s.id, label: s.name })),
      selectAllVisible: true,
    },
    {
      name: 'dateRange',
      title: 'Date Range',
      component: DateRangeFilter,
      getSelectedCount: getDateRangerFilterSelectedCount,
      minDate: new Date('2018-01-01'),
      maxDate: new Date(),
      datePickerProps: {
        dateFormat: 'MM/yyyy',
        showMonthYearPicker: true,
      },
    },
  ]
  if (vendorTags && vendorTags.length > 0) {
    filterConfigList.splice(1, 0, {
      name: 'vendorTags',
      title: `${LabelTypes.VENDOR} Label`,
      options: vendorTags.map((s) => ({ value: s.id, label: s.name })),
      selectAllVisible: true,
      matchVisible: true,
    })
  }
  return filterConfigList
}
