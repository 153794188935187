import React from 'react'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'

const FormikIsValidDetector = ({ onValidStateChange }) => {
  const formik = useFormikContext()

  React.useEffect(() => {
    onValidStateChange(formik.isValid)
  }, [onValidStateChange, formik.isValid])

  return null
}

FormikIsValidDetector.propTypes = {
  onValidStateChange: PropTypes.func.isRequired,
}

export default FormikIsValidDetector
