import { useCallback } from 'react'
import find from 'lodash/find'
import filter from 'lodash/filter'
import NotificationManager from 'lib/notifications'
import { useModal, useGlobalLoader } from 'hooks'
import { unflatten } from 'utils/object'
import { VendorStatusTypes } from 'constants/index'
import { useInviteUser } from 'apis'
import InviteClientUserModal from './InviteClientUserModal'
import InviteVendorUserModal from './InviteVendorUserModal'
import { parseUserVendorRoles } from './helpers'

export default (isClient, vendors, permittedOrgRoles) => {
  const [showLoader, hideLoader] = useGlobalLoader()
  const [openInviteClientUserModal, closeInviteClientUserModal] = useModal(
    InviteClientUserModal,
  )
  const [openInviteVendorUserModal, closeInviteVendorUserModal] = useModal(
    InviteVendorUserModal,
  )
  const { mutateAsync: inviteUser } = useInviteUser()

  return useCallback(() => {
    const onSubmit = async (formData, { setErrors }) => {
      const loaderId = showLoader()
      try {
        await inviteUser({
          data: {
            user: isClient
              ? {
                  ...formData,
                  vendor_roles: parseUserVendorRoles(
                    formData.vendor_roles,
                    formData.org_role,
                  ),
                }
              : formData,
          },
        })
        NotificationManager.success('New user has been successfully invited.')
        if (isClient) {
          closeInviteClientUserModal()
        } else {
          closeInviteVendorUserModal()
        }
      } catch (error) {
        if (error.response.status === 422) {
          setErrors(unflatten(error.response.data.errors))
        } else {
          NotificationManager.error()
        }
      }
      hideLoader(loaderId)
    }

    if (isClient) {
      // if my permitted roles list includes the default invite role, use that;
      // otherwise just use the highest one available to me
      // (prevents admin sending invites that default to admin without intention)
      const x = find(permittedOrgRoles, 'default_invite_option')
      const defaultInviteRole = x ? x.value : permittedOrgRoles[0].value
      openInviteClientUserModal({
        vendors: filter(vendors, (v) => v.status === VendorStatusTypes.ACTIVE),
        permittedRoles: permittedOrgRoles,
        defaultInviteRole,
        onSubmit,
      })
    } else {
      openInviteVendorUserModal({
        onSubmit,
      })
    }
  }, [
    isClient,
    inviteUser,
    vendors,
    permittedOrgRoles,
    openInviteClientUserModal,
    closeInviteClientUserModal,
    openInviteVendorUserModal,
    closeInviteVendorUserModal,
  ])
}
