import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, TrashIcon, LinkButton } from './styles'

const CustomUploaderLayout = ({
  file,
  dropzoneUploader,
  canRemove,
  onRemove,
  ...layoutRest
}) => (
  <Wrapper {...layoutRest}>
    {dropzoneUploader}
    {!!file && canRemove && (
      <LinkButton onClick={onRemove}>
        <span>
          <TrashIcon /> Delete
        </span>
      </LinkButton>
    )}
  </Wrapper>
)

CustomUploaderLayout.propTypes = {
  file: PropTypes.instanceOf(File),
  dropzoneUploader: PropTypes.element,
  canRemove: PropTypes.bool,
  onRemove: PropTypes.func.isRequired,
}

export default CustomUploaderLayout
