import styled from 'styled-components'
import BaseButton from 'components-v2/atoms/Button'

export const EditWrapper = styled.div`
  float: left;
  clear: both;
`

export const PreviewWrap = styled.div`
  display: flex;
  padding-left: 15px;
`

export const Preview = styled.div`
  width: 128px;
  height: 128px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  object-fit: contain;
`

export const PreviewImage = styled.img`
  display: inline;
  margin: 0 auto;
  height: 100%;
  width: auto;
`

export const ButtonsWrapper = styled.div`
  margin-top: 10px;
  float: left;
  clear: both;
`

export const Button = styled(BaseButton)`
  min-width: 38px;
  &:not(:first-child) {
    margin-left: 6px;
  }
`

export const ExistingButtonsWrapper = styled.div`
  margin-top: 5px;
  width: 128px;
  text-align: center;
`

export const Break = styled.hr`
  flex-basis: 100%;
  height: 0;
  margin: 0;
  border: 0;
`
