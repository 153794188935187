import first from 'lodash/first'
import keys from 'lodash/keys'
import set from 'lodash/set'
import reduce from 'lodash/reduce'

export function unflatten(data) {
  const isArray = /^\[\d]$/.test(first(keys(data)))
  const seed = isArray ? [] : {}
  return reduce(data, (result, prop, key) => set(result, key, prop), seed)
}
