import { useState, useCallback } from 'react'
import { useDebouncedCallback } from 'hooks'
import { useUpdateCurrentOrganization } from 'apis'
import NotificationManager from 'lib/notifications'

export default (key, organization) => {
  const { mutateAsync: updateCurrentOrganization } =
    useUpdateCurrentOrganization()
  const [value, setValue] = useState(organization[key])

  const debouncedUpdate = useDebouncedCallback((val) => {
    updateCurrentOrganization({
      data: { organization: { [key]: val || '' } },
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error)
      NotificationManager.error()
    })
  }, 500)

  // Update state and then server using debouncing
  const handleChange = useCallback(
    (newValue) => {
      setValue(newValue)
      debouncedUpdate(newValue)
    },
    [debouncedUpdate],
  )

  return [value, handleChange]
}
