import styled from 'styled-components'

export const CriteriaTable = styled.div`
  display: flex;
  margin-bottom: 20px;
  text-align: center;

  > * {
    flex: 1;
  }
`

export const ScoreTable = styled.table.attrs({ className: 'table' })`
  margin-bottom: 0;
`
