import React from 'react'
import PropTypes from 'prop-types'
import { PreviewsWrapper, Dropzone, StyledButton as Button } from './styles'

const Layout = ({
  buttonComponent: ButtonComponent,
  buttonContent,
  buttonColor,
  previews,
  dropzoneProps,
  files,
  buttonDisabled,
  onButtonClick,
  extra: { maxFiles, active, reject },
}) => (
  <div>
    <PreviewsWrapper>{previews}</PreviewsWrapper>
    <Dropzone {...dropzoneProps}>
      <ButtonComponent
        color={buttonColor}
        disabled={files.length >= maxFiles || buttonDisabled}
        $active={active}
        $reject={reject}
        onClick={onButtonClick}
      >
        {buttonContent}
      </ButtonComponent>
    </Dropzone>
  </div>
)

Layout.propTypes = {
  buttonComponent: PropTypes.any,
  buttonContent: PropTypes.node,
  buttonColor: PropTypes.string,
  previews: PropTypes.any,
  dropzoneProps: PropTypes.any,
  files: PropTypes.array,
  buttonDisabled: PropTypes.bool,
  onButtonClick: PropTypes.func,
  extra: PropTypes.object,
}

Layout.defaultProps = {
  buttonComponent: Button,
}

export default Layout
