import styled from 'styled-components'
import { colors, media } from 'styles'

export const Top = styled.div`
  display: flex;
  align-items: center;
  min-height: 39px;
  justify-content: space-between;
  margin-bottom: 17px;
`

export const Title = styled.h3`
  margin: 0;
  color: ${colors.HEADING};

  ${media.tablet`
    font-size: 18px;
  `}
`
