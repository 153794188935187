import React from 'react'
import PropTypes from 'prop-types'
import { AssessmentElementTypes } from 'constants/index'
import { useAuthorizations } from 'hooks'
import { getEndpointUrl } from 'utils/url'
import A from 'components-v2/atoms/A'
import AutoLink from 'components-v2/atoms/AutoLink'
import Editable from 'components-v2/molecules/Editable'
import Tooltip from 'components-v2/molecules/Tooltip'
import Uploader from 'components-v2/molecules/Uploader'
import ResponseScoreSelect from 'components/shared/ResponseScoreSelect'
import AssessmentElementNoteTooltip from 'components/shared/AssessmentElementNoteTooltip'
import IssueLink from 'components/shared/IssueLink'
import AssessmentElementAnswerForm from 'components/shared/AssessmentElementAnswerForm'
import { isQuestionScorable } from 'lib/assessment'
import IntegrationMetrics from './IntegrationMetrics'

import {
  Row,
  KeyColumn,
  QuestonColumn,
  AnswerFormWrapper,
  CommentWrapper,
  ReviewScoreColumn,
  ActionColumn,
  QuestionLabel,
  ActionWrapper,
  EyeSlashIcon,
  ReviewScoreWrapper,
  Autoscored,
  NeedsReviewLabel,
  MarkReviewedButton,
  CogIcon,
  CommentIcon,
  PlusIcon,
  FileDownloadIcon,
  CreateIssueWrapper,
} from './styles'

const ResponseRow = ({
  element,
  formattedIntegrationMetrics,
  signatureIntegrationMetrics,
  vendorId,
  reviewable,
  isReviewed,
  isClientSubmitted,
  scorable,
  assessmentId,
  completed,
  useCustomKeys,
  ratingLabelSet,
  onReviewScoreChange,
  onReviewNoteChange,
  onMarkReviewed,
  onAutoScoreEditButtonClick,
  onCreateIssueClick,
  onSendIssue,
  onAttachmentsChange,
  onViewIntegrationMetricsClick,
  onViewAttachmentsClick,
  ...rest
}) => {
  const { response } = element
  const signatureIntegrationMetric = signatureIntegrationMetrics.find(
    (s) => s.signature === element.signature,
  )
  const [canUpdate, canCreateIssue] = useAuthorizations('update', [
    'create',
    'Issue',
  ])
  const hasResponseAttachments =
    response && response.attachments && response.attachments.length > 0
  const canUploadResponseAttachments =
    response &&
    isClientSubmitted &&
    scorable &&
    !completed &&
    element.allow_attachments &&
    canUpdate
  const attachmentUploadUrl = getEndpointUrl('clientResponse', {
    assessmentId,
    id: element?.response?.id,
  })
  const handleCreateIssue = React.useCallback(() => {
    if (response) {
      onCreateIssueClick(response.id)
    }
  }, [response, onCreateIssueClick])

  const handleSendIssue = React.useCallback(
    (issueId) => {
      if (response) {
        onSendIssue(response.id, issueId)
      }
    },
    [response, onSendIssue],
  )

  const handleAttachmentsChange = React.useCallback(
    (attachments, resp) => {
      onAttachmentsChange(resp.id, attachments, resp)
    },
    [onAttachmentsChange],
  )
  return (
    <Row
      {...rest}
      data-skipped={element.skipped}
      data-test-id={`element-${element.id}`}
      id={element.id}
    >
      <KeyColumn customKey={useCustomKeys}>
        {element.key}
        {element.required && ' *'}
      </KeyColumn>
      <QuestonColumn>
        <QuestionLabel>
          <AutoLink text={element.label} />
          &nbsp;
          <AssessmentElementNoteTooltip element={element} />
          {element.attachments?.length > 0 && (
            <>
              &nbsp;
              <span>
                <FileDownloadIcon
                  hasAttachments={element.attachments?.length > 0}
                  onClick={() => {
                    onViewAttachmentsClick(element)
                  }}
                />
              </span>
            </>
          )}
        </QuestionLabel>
        {!element.skipped && (
          <>
            <AnswerFormWrapper>
              <AssessmentElementAnswerForm
                element={element}
                value={
                  response && (response.translated_answer || response.answer)
                }
                disabled
                placeholder="(No answer provided)"
              />
            </AnswerFormWrapper>
            {response && response.comment && (
              <CommentWrapper>
                <CommentIcon isReviewed={isReviewed} />
                {response.translated_comment || response.comment}
              </CommentWrapper>
            )}
            {(hasResponseAttachments || canUploadResponseAttachments) && (
              <Uploader
                model="response"
                files={response && response.attachments}
                url={attachmentUploadUrl}
                disabled={!canUploadResponseAttachments || completed}
                description="Attach any additional files related to this response below."
                displayLabel={false}
                onChange={handleAttachmentsChange}
              />
            )}
          </>
        )}
      </QuestonColumn>
      <ReviewScoreColumn>
        {scorable &&
          !element.skipped &&
          reviewable &&
          response &&
          response.responded && (
            <ResponseScoreSelect
              value={response.review_score}
              weight={element.weight}
              ratingLabelSet={ratingLabelSet}
              disabled={!canUpdate || isReviewed}
              resetable
              onChange={(value) => onReviewScoreChange(response.id, value)}
            />
          )}
      </ReviewScoreColumn>
      <ActionColumn>
        {!element.skipped && reviewable && response && (
          <>
            {isQuestionScorable(element) && response.review_score !== null && (
              <ActionWrapper>
                <ReviewScoreWrapper>
                  {element.type ===
                    AssessmentElementTypes.SINGLE_SELECT_QUESTION &&
                    (canUpdate ? (
                      <Tooltip
                        parent={
                          <CogIcon
                            isReviewed={isReviewed}
                            onClick={() =>
                              onAutoScoreEditButtonClick(element.id)
                            }
                          />
                        }
                      >
                        Autoscore Settings
                      </Tooltip>
                    ) : (
                      <CogIcon isReviewed={isReviewed} />
                    ))}
                  {response.reviewer ? (
                    <span>Reviewed by {response.reviewer.name}</span>
                  ) : (
                    <>
                      <Autoscored>Autoscored</Autoscored>
                      {response.needs_review &&
                        (canUpdate ? (
                          <MarkReviewedButton
                            onClick={() => onMarkReviewed(response.id)}
                          />
                        ) : (
                          <NeedsReviewLabel />
                        ))}
                    </>
                  )}
                </ReviewScoreWrapper>
              </ActionWrapper>
            )}
            {(isReviewed || !canUpdate) && response.review_note && (
              <ActionWrapper>
                <CommentIcon isReviewed={isReviewed} />{' '}
                <AutoLink text={response.review_note} />
              </ActionWrapper>
            )}
            {!isReviewed && canUpdate && (
              <ActionWrapper>
                <Editable
                  mode="popup"
                  emptyValueText="Add Internal Comment"
                  value={response.review_note}
                  title={
                    response.review_note
                      ? 'Edit Internal Comment'
                      : 'Add Internal Comment'
                  }
                  includeIcon
                  disabled={!canUpdate}
                  onSubmit={(value) => onReviewNoteChange(response.id, value)}
                />
              </ActionWrapper>
            )}
            {signatureIntegrationMetric &&
              signatureIntegrationMetric.integration_metrics && (
                <ActionWrapper>
                  <IntegrationMetrics
                    signatureIntegrationMetric={signatureIntegrationMetric}
                    formattedIntegrationMetrics={formattedIntegrationMetrics}
                    onViewIntegrationMetricsClick={
                      onViewIntegrationMetricsClick
                    }
                  />
                </ActionWrapper>
              )}
            <ActionWrapper>
              <IssueLink
                issues={response.issues}
                createLinkVisible={!completed && canCreateIssue}
                onCreateIssue={handleCreateIssue}
                onSendIssue={handleSendIssue}
              />
              {!completed && canCreateIssue && response.issues.length === 0 && (
                <CreateIssueWrapper>
                  <PlusIcon />
                  &nbsp;
                  <A onClick={handleCreateIssue} underline>
                    Create Issue
                  </A>
                </CreateIssueWrapper>
              )}
            </ActionWrapper>
          </>
        )}
        {element.skipped && (
          <ActionWrapper>
            <EyeSlashIcon />
            &nbsp;
            <i>Disabled</i>
          </ActionWrapper>
        )}
      </ActionColumn>
    </Row>
  )
}

ResponseRow.propTypes = {
  element: PropTypes.object.isRequired,
  formattedIntegrationMetrics: PropTypes.object,
  signatureIntegrationMetrics: PropTypes.array,
  vendorId: PropTypes.string.isRequired,
  reviewable: PropTypes.bool,
  isReviewed: PropTypes.bool,
  isClientSubmitted: PropTypes.bool,
  scorable: PropTypes.bool,
  assessmentId: PropTypes.string,
  completed: PropTypes.bool,
  useCustomKeys: PropTypes.bool,
  ratingLabelSet: PropTypes.string,
  onReviewScoreChange: PropTypes.func.isRequired,
  onReviewNoteChange: PropTypes.func.isRequired,
  onMarkReviewed: PropTypes.func.isRequired,
  onAutoScoreEditButtonClick: PropTypes.func.isRequired,
  onCreateIssueClick: PropTypes.func.isRequired,
  onSendIssue: PropTypes.func.isRequired,
  onAttachmentsChange: PropTypes.func.isRequired,
  onViewIntegrationMetricsClick: PropTypes.func.isRequired,
  onViewAttachmentsClick: PropTypes.func.isRequired,
}

export default ResponseRow
