import styled from 'styled-components'
import Button from 'components-v2/atoms/Button'
import FormField, { ControlLabel } from 'components-v2/molecules/FormField'
import { colors } from 'styles'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const Scrollable = styled.div`
  flex: 1;
  overflow-y: auto;
  padding-left: 34px;
`

export const Title = styled.h5`
  font-size: 18px;
  line-height: 15px;
  color: ${colors.BLACK};
  margin: 0 0 25px;
`

export const NoteBody = styled.div`
  margin-bottom: 25px;
`

export const Footer = styled.div`
  flex: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
  padding-left: 34px;
`

export const BackButton = styled(Button)`
  margin-right: auto;
  color: #404144;
  border-color: #404144;
`

export const SkipButton = styled(Button).attrs({ variant: 'text' })`
  margin-right: 15px;
  color: ${colors.BODY_TEXT};
  opacity: 0.5;

  &:hover,
  &:active,
  &:focus {
    color: ${colors.BODY_TEXT};
    opacity: 1;
  }
`

const StyledControlLabel = styled(ControlLabel)`
  &&& {
    font-size: 18px;
  }
`

export const StyledFormField = styled(FormField).attrs({
  renderControlLabel: StyledControlLabel,
})``

export const OtherFormField = styled(StyledFormField)`
  margin-top: 5px;
  margin-left: 30%;
`
