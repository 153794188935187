import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import isArray from 'lodash/isArray'
import { ErrorMessage } from 'components-v2/molecules/FormField'
import Card from 'components-v2/organisms/Card'
import { Wrapper, Details, ToggleButton } from './styles'

const CustomErrorMessage = ({ error, ...rest }) => {
  const [errorDetailsOpen, setErrorDetailsOpen] = React.useState(false)

  React.useEffect(() => {
    setErrorDetailsOpen(false)
  }, [error])

  // In case of server error, format: { errors: {}, error_details: {} }
  if (error && error.errors) {
    const mainError = map(
      error.errors,
      (err, key) =>
        `${key.replace(/_/g, ' ')}: ${isArray(err) ? err.join(',') : err}`,
    )
    const errorDetails = map(
      error.error_details,
      (err, key) =>
        `${key.replace(/_/g, ' ')}: ${isArray(err) ? err.join(',') : err}`,
    )
    return (
      <Wrapper {...rest}>
        <p className="text-danger strong">
          Validation errors were detected with the file:
        </p>
        {map(mainError, (e, index) => (
          <div key={index} className="text-danger">
            - {e}
          </div>
        ))}
        {errorDetails.length > 0 && (
          <Details>
            {!errorDetailsOpen ? (
              <ToggleButton onClick={() => setErrorDetailsOpen(true)}>
                See more details
              </ToggleButton>
            ) : (
              <Card padding bordered>
                {map(errorDetails, (e, index) => (
                  <div key={index} className="text-danger">
                    - {e}
                  </div>
                ))}
              </Card>
            )}
          </Details>
        )}
      </Wrapper>
    )
  }

  return <ErrorMessage {...rest} error={error} />
}

CustomErrorMessage.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.shape({
      errors: PropTypes.object,
      error_details: PropTypes.object,
    }),
  ]),
}

export default CustomErrorMessage
