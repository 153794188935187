import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { colors, mixins } from 'styles'
import Icon from '../Icon'
import Clickable from '../Clickable'

// TODO: Support color variants
const Button = styled(Clickable)`
  ${mixins.hidePrint}
  color: ${colors.GREY};
  line-height: 0;

  &:hover,
  &:active,
  &:focus {
    color: ${colors.BODY_TEXT};
  }

  ${(p) => p.active && `color: ${colors.BODY_TEXT};`}

  ${(p) =>
    p.color === 'primary' &&
    css`
      color: var(--primary-color);

      &:hover,
      &:active,
      &:focus {
        color: var(--primary-color-d10);
      }

      ${p.active && `color: var(--primary-color-d10);`}
    `}
`

const IconButton = React.forwardRef(({ icon, ...rest }, ref) => (
  <Button {...rest} ref={ref}>
    <Icon icon={icon} />
  </Button>
))

IconButton.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  active: PropTypes.bool,
  color: PropTypes.oneOf(['primary']),
}

export default IconButton
