export const getMultiSelectFilterSelectedCount = (value) =>
  (value?.selected?.length || 0) + (value?.other ? 1 : 0)

export const formatMultiSelectFilterValue = ({
  value,
  options,
  otherLabel,
  matchVisible,
  getOptionValue = (e) => e.value,
  getOptionLabel = (e) => e.label,
}) => {
  if (!getMultiSelectFilterSelectedCount(value)) {
    return ''
  }
  let str = [
    ...(value?.selected?.map((e) => {
      const opt = options?.find((o) => getOptionValue(o) === e)
      return (opt && getOptionLabel(opt)) || e
    }) || []),
    value?.other ? otherLabel : undefined,
  ]
    .filter((e) => e)
    .join(', ')
  if (matchVisible) {
    str = `${str} (${value.anyMatch ? 'any' : 'all'})`
  }
  return str
}
