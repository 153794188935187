import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import Widget from '../Widget'
import { Inner, Graphics, Title, ViewAllLink } from './styles'

const StatsWidget = ({ details, ...rest }) => (
  <Widget {...rest}>
    {map(details, (detail, index) => (
      <Widget.Segment key={index}>
        <Inner>
          <Graphics />
          <Title>{detail.title}</Title>
          <ViewAllLink href={detail.link}>View all</ViewAllLink>
        </Inner>
      </Widget.Segment>
    ))}
  </Widget>
)

StatsWidget.propTypes = {
  details: PropTypes.array.isRequired,
}

export default React.memo(StatsWidget)
