import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import Table from 'components-v2/molecules/Table'
import Toolbar, { SearchInput } from 'components-v2/molecules/Table/Toolbar'
import CardRadioGroup from 'components-v2/molecules/CardRadioGroup'
import MenuDropdown from 'components-v2/molecules/MenuDropdown'
import { useAuthorizations } from 'hooks'
import { LabelTypes } from 'constants/index'
import { FilterOptions, ActionMenuItemTypes } from './consts'
import { TableWrapper } from './styles'

const View = ({
  vendors,
  loading,
  selected,
  columns,
  filter,
  filterCountMap,
  remindersEnabled,
  enableResend,
  enableDisableReminders,
  bulkExtendActionEnabled,
  totalSize,
  pagination,
  sorting,
  searchText,
  onPaginationChange,
  onSortingChange,
  onSearchChange,
  onFilterChange,
  onRowSelectionChange,
  onMenuItemSelect,
}) => {
  const [canManageProject] = useAuthorizations('manage')
  const options = map(FilterOptions, (option, key) => ({
    ...option,
    value: key,
    count: filterCountMap[key],
  }))
  return (
    <div>
      <CardRadioGroup
        label={`${LabelTypes.VENDOR} status`}
        value={filter}
        onChange={onFilterChange}
        options={options}
      />
      <TableWrapper>
        <Toolbar>
          <Toolbar.Group>
            <SearchInput
              // eslint-disable-next-line react/prop-types
              value={searchText}
              onChange={onSearchChange}
              placeholder={`Search for a ${LabelTypes.VENDOR.toLowerCase()}...`}
            />
          </Toolbar.Group>
          <Toolbar.Group>
            {canManageProject && (
              <MenuDropdown title="Actions" onSelect={onMenuItemSelect}>
                <MenuDropdown.Item
                  eventKey={ActionMenuItemTypes.RESEND_INVITE}
                  disabled={!enableResend}
                >
                  <i className="fa fa-repeat" />
                  Resend Invite
                </MenuDropdown.Item>
                {remindersEnabled && (
                  <MenuDropdown.Item
                    eventKey={ActionMenuItemTypes.DISABLE_REMINDERS}
                    disabled={!enableDisableReminders}
                  >
                    <i className="fa fa-bell-slash-o" />
                    Disable Reminders
                  </MenuDropdown.Item>
                )}
                <MenuDropdown.Item
                  eventKey={ActionMenuItemTypes.EXTEND}
                  disabled={!bulkExtendActionEnabled}
                >
                  <i className="fa fa-arrows-h" />
                  Extend
                </MenuDropdown.Item>
              </MenuDropdown>
            )}
          </Toolbar.Group>
        </Toolbar>
        <Table
          loading={loading}
          data={vendors || []}
          columns={columns}
          rowSelection={selected}
          totalSize={totalSize}
          pagination={pagination}
          sorting={sorting}
          onRowSelectionChange={onRowSelectionChange}
          onPaginationChange={onPaginationChange}
          onSortingChange={onSortingChange}
          manualPagination
          manualSorting
          enableRowSelection={canManageProject}
          noDataText="No vendors found."
          type={Table.types.ALT}
        />
      </TableWrapper>
    </div>
  )
}

View.propTypes = {
  vendors: PropTypes.array.isRequired,
  selected: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  filter: PropTypes.string,
  filterCountMap: PropTypes.object.isRequired,
  remindersEnabled: PropTypes.bool,
  enableResend: PropTypes.bool.isRequired,
  enableDisableReminders: PropTypes.bool.isRequired,
  bulkExtendActionEnabled: PropTypes.bool.isRequired,
  totalSize: PropTypes.number,
  pagination: PropTypes.object.isRequired,
  sorting: PropTypes.object.isRequired,
  searchText: PropTypes.string,
  loading: PropTypes.bool,
  onPaginationChange: PropTypes.func.isRequired,
  onSortingChange: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onRowSelectionChange: PropTypes.func.isRequired,
  onMenuItemSelect: PropTypes.func.isRequired,
}

export default View
