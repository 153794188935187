import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { colors, mixins } from 'styles'
import BaseTable from './BaseTable'
import { formatCell } from './styles'

const tableBorderColor = '#D7D7D7'
const trBorderColor = '#F5F5F5'
const Table = styled(BaseTable)`
  table {
    ${formatCell(`
      border-top: 1px solid ${trBorderColor};
      padding: 5px 14px;
      height: 50px;
      vertical-align: middle;
    `)}

    > thead > tr > th {
      ${mixins.smallHeader}
      line-height: 1.25em;
      color: ${colors.BODY_TEXT};
      border-bottom: 1px solid ${tableBorderColor};

      input[type='checkbox'] {
        font-size: 16px;
      }

      .caret {
        display: inline-block;
        width: 12px;
        height: 16px;
        vertical-align: top;
        border: none;
        position: absolute;
        margin-top: -8px;
        margin-left: 8px;
        transform: translateY(50%);

        &:before {
          font: normal normal normal 12px/1 FontAwesome;
          content: '\f063';
        }
      }

      .dropup.caret:before {
        content: '\f062';
      }

      &.sortable {
        position: relative;
        padding-right: 32px;
        cursor: pointer;
      }
    }
  }

  .pagination {
    font-weight: 500;
  }

  ${(props) =>
    props.$bordered &&
    css`
      .table-responsive {
        background: ${colors.WHITE};
        border: 1px solid ${tableBorderColor};
        border-radius: 7px;
      }

      table > thead {
        background: ${trBorderColor};

        body.grey-bg & {
          background: #d4d4d4;
        }
      }
    `}

  ${(props) =>
    props.$hover &&
    css`
      table > tbody > tr:hover {
        background: #fafafa;
      }
    `}

  ${(props) =>
    props.onRowClick &&
    css`
      table > tbody > tr {
        cursor: pointer;
      }
    `}

   ${(props) =>
    props.$noPrintRowGroup &&
    css`
      table > thead {
        ${mixins.noPrintRowGroup}
      }
    `}
`

const Alt = ({ bordered, hover, noPrintRowGroup, ...rest }) => (
  <Table
    {...rest}
    $bordered={bordered}
    $noPrintRowGroup={noPrintRowGroup}
    $hover={hover}
  />
)

Alt.propTypes = {
  bordered: PropTypes.bool,
  hover: PropTypes.bool,
  noPrintRowGroup: PropTypes.bool,
}

Alt.defaultProps = {
  bordered: true,
  hover: true,
  noPrintRowGroup: false,
}

export default Alt
