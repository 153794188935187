import styled from 'styled-components'

export const TableWrapper = styled.div`
  table td,
  th {
    text-align: right;
  }
  table td:first-child,
  th:first-child {
    text-align: left;
  }
`
