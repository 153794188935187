import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import Icon from 'components-v2/atoms/Icon'
import Button from 'components-v2/atoms/Button'
import Table from 'components-v2/molecules/Table'
import Toolbar, { SearchInput } from 'components-v2/molecules/Table/Toolbar'
import { LabelTypes } from 'constants/index'
import { useAuthManager } from 'hooks'
import config from 'config'
import ProjectVendorActions from './ProjectVendorActions'
import { AddButton, CanceledVendorsHeading, Name } from './styles'

const getVendorsTableColumn = (
  editable,
  launchDisabled,
  authManager,
  onDelete,
  onCancel,
  onDatesChange,
  onLaunch,
) => [
  {
    id: 'vendor.name',
    header: '',
    cell: (info) =>
      authManager.userCan('view', info.row.original) ? (
        <Name as="a" href={get(info.row.original, 'vendor.path')}>
          {info.getValue()}
        </Name>
      ) : (
        info.getValue()
      ),
    width: '100%',
  },
  {
    id: 'actions',
    header: '',
    width: 0,
    cell: (info) => (
      <ProjectVendorActions
        projectVendor={info.row.original}
        editable={editable}
        launchDisabled={launchDisabled}
        onDelete={onDelete}
        onCancel={onCancel}
        onDatesChange={onDatesChange}
        onLaunch={onLaunch}
      />
    ),
  },
]

const getCanceledVendorsTableColumn = (onRelaunch) => [
  {
    id: 'vendor.name',
    header: '',
    cell: (info) => (
      <Name as="a" href={get(info.row.original, 'vendor.path')}>
        {info.getValue()}
      </Name>
    ),
    width: '100%',
  },
  {
    id: 'id',
    header: '',
    cell: (info) => (
      <Button size="sm" onClick={() => onRelaunch(info.getValue())}>
        <Icon icon="fa fa-undo" />
        Relaunch
      </Button>
    ),
    width: 0,
  },
]

const paginationTotalRenderer = (from, to, size) =>
  `Viewing ${from}-${to} of ${size} ${LabelTypes.VENDORS.toLowerCase()}`

const ProjectVendors = ({
  editable,
  launchDisabled,
  projectVendors,
  totalSize,
  page,
  sizePerPage,
  searchText,
  loading,
  canceledExpanded,
  canceledProjectVendors,
  canceledTotalSize,
  canceledPage,
  canceledLoading,
  onPaginationChange,
  onCanceledPaginationChange,
  onSearchChange,
  onDelete,
  onCancel,
  onDatesChange,
  onLaunch,
  onRelaunch,
  onCanceledExpand,
}) => {
  const authManager = useAuthManager()
  const vendorsTableColumns = React.useMemo(
    () =>
      getVendorsTableColumn(
        editable,
        launchDisabled,
        authManager,
        onDelete,
        onCancel,
        onDatesChange,
        onLaunch,
      ),
    [
      editable,
      launchDisabled,
      authManager,
      onDelete,
      onCancel,
      onDatesChange,
      onLaunch,
    ],
  )

  const canceledVendorsTableColumns = React.useMemo(
    () => getCanceledVendorsTableColumn(onRelaunch),
    [onRelaunch],
  )

  return (
    <div>
      <Toolbar>
        <SearchInput value={searchText} onChange={onSearchChange} />
      </Toolbar>
      <Table
        data={projectVendors}
        columns={vendorsTableColumns}
        loading={loading}
        totalSize={totalSize}
        pagination={{ page, sizePerPage }}
        manualPagination
        onPaginationChange={onPaginationChange}
        enableSorting={false}
        noDataText={`No ${LabelTypes.VENDORS.toLowerCase()} found.`}
        type={Table.types.HEADLESS}
        paginationTotalRenderer={paginationTotalRenderer}
      />
      {canceledExpanded && (
        <>
          <CanceledVendorsHeading>Canceled</CanceledVendorsHeading>
          <Table
            data={canceledProjectVendors}
            columns={canceledVendorsTableColumns}
            loading={canceledLoading}
            totalSize={canceledTotalSize}
            pagination={{ page: canceledPage, sizePerPage }}
            manualPagination
            onPaginationChange={onCanceledPaginationChange}
            enableSorting={false}
            noDataText={`No ${LabelTypes.VENDORS.toLowerCase()} found.`}
            type={Table.types.HEADLESS}
            paginationTotalRenderer={paginationTotalRenderer}
          />
        </>
      )}
      {!canceledExpanded && (
        <AddButton onClick={onCanceledExpand}>
          <Icon icon="fa fa-plus-circle" />
          Canceled
        </AddButton>
      )}
    </div>
  )
}

ProjectVendors.propTypes = {
  projectVendors: PropTypes.array,
  editable: PropTypes.bool,
  launchDisabled: PropTypes.bool,
  totalSize: PropTypes.number,
  page: PropTypes.number,
  sizePerPage: PropTypes.number,
  searchText: PropTypes.string,
  loading: PropTypes.bool,
  canceledExpanded: PropTypes.bool,
  canceledProjectVendors: PropTypes.array,
  canceledTotalSize: PropTypes.number,
  canceledPage: PropTypes.number,
  canceledLoading: PropTypes.bool,
  onPaginationChange: PropTypes.func.isRequired,
  onCanceledPaginationChange: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
  onDatesChange: PropTypes.func,
  onLaunch: PropTypes.func,
  onRelaunch: PropTypes.func,
  onCanceledExpand: PropTypes.func,
}

const noop = () => {}

ProjectVendors.defaultProps = {
  projectVendors: [],
  canceledProjectVendors: [],
  canceledExpanded: false,
  editable: false,
  launchDisabled: false,
  totalSize: 0,
  page: 1,
  sizePerPage: config.collectionQueryPageLimit.projectVendorsTable,
  searchText: '',
  loading: false,
  onDelete: noop,
  onCancel: noop,
  onDatesChange: noop,
  onLaunch: noop,
  onRelaunch: noop,
  onCanceledExpand: noop,
}

export default ProjectVendors
