import React from 'react'
import PropTypes from 'prop-types'
import Card from 'components-v2/organisms/Card'
import { Top, Title } from './styles'

const Section = ({ title, topRight, children, ...rest }) => (
  <Card padding {...rest}>
    <Top>
      <Title>{title}</Title>
      {topRight}
    </Top>
    {children}
  </Card>
)

Section.propTypes = {
  title: PropTypes.string.isRequired,
  topRight: PropTypes.any,
  children: PropTypes.element,
}

export default Section
