import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import Well from 'components-v2/atoms/Well'
import FormField, {
  BaseField,
  ControlLabel,
} from 'components-v2/molecules/FormField'
import FormDialog from 'components-v2/organisms/FormDialog'
import ChoiceSelectField from './ChoiceSelectField'
import { QuestionHead } from './styles'

const validationSchema = yup.object().shape({
  question: yup.string().required('This is a required field'),
  choices: yup.object().shape({
    yes: yup.number().required().integer().min(0).max(3),
    no: yup.number().required().integer().min(0).max(3),
  }),
})

const QuestionModal = ({ question, ...rest }) => {
  const formConfig = React.useMemo(() => {
    const initialValues = {
      question: question?.question || '',
      choices: question?.choices || {
        yes: 0,
        no: 0,
      },
    }
    return {
      initialValues,
      validationSchema,
    }
  }, [question])
  return (
    <FormDialog {...rest} formConfig={formConfig} submitButtonLabel="Ok">
      <FormField
        type={FormField.types.TEXTAREA}
        label="Question"
        name="question"
        rows="2"
      />
      <BaseField>
        <ControlLabel>Choices</ControlLabel>
        <Well>
          <QuestionHead>Points</QuestionHead>
          <FormField
            label="Yes:"
            name="choices.yes"
            component={ChoiceSelectField}
            horizontal
          />
          <FormField
            label="No:"
            name="choices.no"
            component={ChoiceSelectField}
            horizontal
          />
        </Well>
      </BaseField>
    </FormDialog>
  )
}

QuestionModal.propTypes = {
  question: PropTypes.object,
}

export default QuestionModal
