import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components-v2/atoms/Button'
import ToolbarGroup from 'components-v2/molecules/ToolbarGroup'
import PageHeader from 'components-v2/organisms/PageHeader'
import { Row, Col } from 'components-v2/organisms/Layout'
import { getPageUrl } from 'utils/url'
import { pluralize } from 'utils/string'
import { toLocalTimeString } from 'utils/date'
import QuestionList from './QuestionList'
import AnswerList from './AnswerList'

function View({
  questionSet,
  answersCount,
  mode,
  selectedQuestion,
  onModeChange,
  onQuestionSelect,
  onSettingsButtonClick,
}) {
  const breadcrumbs = React.useMemo(
    () => [
      { title: 'Assist', href: getPageUrl('assist') },
      { title: questionSet.name, active: true },
    ],
    [questionSet.name],
  )

  return (
    <>
      <PageHeader title={questionSet.name} breadcrumbs={breadcrumbs}>
        <ToolbarGroup>
          <Button onClick={onSettingsButtonClick}>
            <i className="fa fa-gear" />
            Settings
          </Button>
          <Button
            href={getPageUrl(
              'assistQuestionSet',
              { id: questionSet.id },
              undefined,
              'csv',
            )}
          >
            <i className="fa fa-download" />
            Export
          </Button>
        </ToolbarGroup>
      </PageHeader>
      {pluralize(questionSet.questions.length, 'question')}
      <br />
      {pluralize(answersCount, 'answer set')} being sourced
      <br />
      {toLocalTimeString(questionSet.created_at, 'MMMM yyyy')}
      <br />
      <br />
      <Row>
        <Col md={6}>
          <QuestionList
            questions={questionSet.questions}
            selectedQuestion={selectedQuestion}
            onQuestionSelect={onQuestionSelect}
          />
        </Col>
        {selectedQuestion && (
          <Col md={6}>
            <AnswerList
              question={selectedQuestion}
              mode={mode}
              onModeChange={onModeChange}
            />
          </Col>
        )}
      </Row>
    </>
  )
}

View.propTypes = {
  questionSet: PropTypes.object.isRequired,
  answersCount: PropTypes.number.isRequired,
  mode: PropTypes.string.isRequired,
  selectedQuestion: PropTypes.object,
  onModeChange: PropTypes.func.isRequired,
  onQuestionSelect: PropTypes.func.isRequired,
  onSettingsButtonClick: PropTypes.func.isRequired,
}

export default View
