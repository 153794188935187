import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import get from 'lodash/get'
import countBy from 'lodash/countBy'
import identity from 'lodash/identity'
import Tooltip from 'components-v2/molecules/Tooltip'
import Card from 'components-v2/organisms/Card'
import { SecurityObjectiveStatusTypes } from 'constants/index'
import { toFromNowString } from 'utils/date'
import ToolStatusDot from '../../ToolStatusDot'
import ObjectiveStatusDot from '../ObjectiveStatusDot'
import StatusPieChart from '../../StatusPieChart'
import {
  Bottom,
  ConnectLink,
  Header,
  Heading,
  Logo,
  ObjectiveLabel,
  ObjectiveStatus,
  SourceColHeader,
  SourceItem,
  SourceListHeader,
  Top,
  UpdatedAt,
} from './styles'

const NOT_CONNECTED = 'Not connected'
const getCountByStatus = (objectives) => {
  const statuses = map(objectives, (objective) =>
    get(objective, 'tool.status', objective.status),
  )
  return countBy(statuses, identity)
}

const getObjectiveUrl = () => {
  // temp work around
  let url = '/cme/objectives/id'
  if (window.location.href.toLocaleLowerCase().indexOf('/cme/vendors/') > -1) {
    url = '/cme/vendors/id/objectives/id'
  }
  return url
}

const SummaryCard = ({
  collection: { objectives, updatedAt, riskRating },
  onConnect,
  ...rest
}) => (
  <Card {...rest}>
    <Top>
      <Header>
        <Heading>Current status</Heading>
        {updatedAt && (
          <UpdatedAt>
            Updated {toFromNowString(updatedAt, { addSuffix: true })}
          </UpdatedAt>
        )}
      </Header>
      <StatusPieChart
        countByStatus={getCountByStatus(objectives)}
        riskRating={riskRating}
      />
    </Top>
    <Bottom>
      <SourceListHeader>
        <Heading>Objective</Heading>
        <SourceColHeader>Source</SourceColHeader>
      </SourceListHeader>
      <div>
        {objectives.map((objective) => (
          <SourceItem key={objective.id} disabled={!objective.tool}>
            <ObjectiveLabel href={getObjectiveUrl()} title={objective.name}>
              {objective.name}
            </ObjectiveLabel>
            <div>
              {objective.tool ? (
                <Logo src={objective.tool.logo} />
              ) : objective.status ===
                SecurityObjectiveStatusTypes.NOT_CONNECTED ? (
                <ConnectLink onClick={onConnect}>Connect</ConnectLink>
              ) : (
                <ObjectiveStatus status={objective.status}>
                  {objective.status.titleize()}
                </ObjectiveStatus>
              )}
            </div>
            <div>
              {objective.tool && objective.tool.lastActiveTime ? (
                <Tooltip
                  parent={<ToolStatusDot status={objective.tool.status} />}
                >
                  Active as of{' '}
                  {toFromNowString(objective.tool.lastActiveTime, {
                    addSuffix: true,
                  })}
                </Tooltip>
              ) : objective.tool ? (
                <ToolStatusDot status={objective.tool.status} />
              ) : (
                <Tooltip
                  parent={<ObjectiveStatusDot status={objective.status} />}
                >
                  {NOT_CONNECTED}
                </Tooltip>
              )}
            </div>
          </SourceItem>
        ))}
      </div>
    </Bottom>
  </Card>
)

SummaryCard.propTypes = {
  collection: PropTypes.object.isRequired,
  onConnect: PropTypes.func,
}

export default React.memo(SummaryCard)
