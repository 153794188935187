import React from 'react'
import PropTypes from 'prop-types'
import find from 'lodash/find'
import get from 'lodash/get'
import useCreateUpdateIssue from 'components/client/shared/useCreateUpdateIssue'
import useShowRiskReconIssues from 'components/client/shared/useShowRiskReconIssues'
import { OrganizationPartnerTypes } from 'constants/index'
import { compose, withAuthorize, withReactQuery } from 'hocs'
import { useQueryParams } from 'hooks'
import {
  useClientVendorFetcher,
  useGlobalOrgFetcher,
  useGlobalOrgIntegrationMetricsFetcher,
  useClientVendorFacetableSetsFetcher,
} from 'apis'
import View from './View'
import useSendAssessment from './useSendAssessment'
import useArchive from './useArchive'
import useCRUDGloborgMapping from './useCRUDGloborgMapping'
import { convertRiskReconSeverityToIssuePriority } from './utils'

const VendorViewContainer = ({
  vendor: vendorProp,
  partners,
  clientVendorId,
  globorgEnabled,
  clientId,
  libraryProfileId,
}) => {
  const usersViewRef = React.useRef()
  const { createIssue } = useCreateUpdateIssue()
  const [{ tab }, setQueryParams] = useQueryParams()
  const handleTabSelect = React.useCallback(
    (e) => setQueryParams({ tab: e }, true),
    [],
  )
  const isRequest = vendorProp.class === 'VendorRequest'

  const { data: vendor } = useClientVendorFetcher(
    {
      id: vendorProp.id,
    },
    { initialData: vendorProp, refetchOnMount: false },
  )

  const vendorId = vendor.id
  const globalOrgId = get(vendor, 'globorg_mapping.globorg_org_id')
  const hasGloborg = !!(globorgEnabled && globalOrgId)
  const { data: globalOrg } = useGlobalOrgFetcher(
    { id: globalOrgId },
    { enabled: hasGloborg },
  )
  const globalOrgIntegrationMetrics = useGlobalOrgIntegrationMetricsFetcher(
    {
      globorgOrgId: globalOrgId,
      params: { client_id: clientId },
    },
    { enabled: hasGloborg },
  )
  const { data: facetableSets } = useClientVendorFacetableSetsFetcher(
    { vendorId },
    { enabled: !isRequest },
  )

  const showRiskReconIssues = useShowRiskReconIssues(globalOrgId, clientId)
  const sendAssessment = useSendAssessment(vendor)
  const [archive, unarchive] = useArchive(vendor.id)
  const { connectGloborg, confirmGloborg, disconnectGloborg } =
    useCRUDGloborgMapping(vendor)

  // Reset tab after disconnecting globorg
  React.useEffect(() => {
    if (!hasGloborg && (tab === 'profile' || tab === 'riskProfile')) {
      handleTabSelect('info')
    }
  }, [hasGloborg, tab, handleTabSelect])

  const handleRiskReconIssuesCountClick = React.useCallback(
    (filter) => {
      const onCreateIssue = (riskReconIssue) => {
        const initialFormData = {
          summary: `${riskReconIssue.finding} (RiskRecon)`,
          description: {
            body: riskReconIssue.finding_context,
            formatted: false,
          },
          priority: convertRiskReconSeverityToIssuePriority(
            riskReconIssue.severity,
          ),
        }
        createIssue({
          initialValues: initialFormData,
          vendorId: vendor.id,
          disabledFields: ['priority'],
        })
      }

      showRiskReconIssues(filter, onCreateIssue)
    },
    [vendor.id, showRiskReconIssues, createIssue],
  )

  const handleEditContributors = React.useCallback(() => {
    handleTabSelect('users')
    usersViewRef.current?.editContributors()
  }, [handleTabSelect])

  const bitSight = React.useMemo(
    () =>
      find(partners, {
        type: OrganizationPartnerTypes.BITSIGHT,
        enabled: true,
      }),
    [partners],
  )

  return (
    <View
      usersViewRef={usersViewRef}
      clientId={clientId}
      activeTab={tab}
      vendor={vendor}
      globorgEnabled={globorgEnabled}
      hasGloborg={hasGloborg}
      globalOrg={globalOrg}
      globalOrgIntegrationMetrics={globalOrgIntegrationMetrics}
      clientVendorId={clientVendorId}
      facetableSets={facetableSets}
      bitSight={bitSight}
      libraryProfileId={libraryProfileId}
      onTabSelect={handleTabSelect}
      onEditContributors={handleEditContributors}
      onSendAssessment={sendAssessment}
      onRiskReconIssuesCountClick={handleRiskReconIssuesCountClick}
      onConnectGloborg={connectGloborg}
      onConfirmGloborg={confirmGloborg}
      onDisconnectGloborg={disconnectGloborg}
      onArchive={archive}
      onUnarchive={unarchive}
    />
  )
}

VendorViewContainer.propTypes = {
  vendor: PropTypes.object.isRequired,
  clientVendorId: PropTypes.string.isRequired,
  partners: PropTypes.array.isRequired,
  globorgEnabled: PropTypes.bool,
  clientId: PropTypes.string.isRequired,
  libraryProfileId: PropTypes.string,
}

export default compose(withAuthorize(), withReactQuery())(VendorViewContainer)
