import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Transition } from '@headlessui/react'
import Modal from '../Modal'

import {
  Inner,
  Backdrop,
  Panel,
  DialogTitle,
  DialogContent,
  DialogFooter,
  DialogHeaderText,
  DialogCloseButton,
} from './styles'

const DialogHeader = ({ children, onClose, ...rest }) => (
  <DialogTitle {...rest}>
    <DialogHeaderText>{children}</DialogHeaderText>
    {onClose && <DialogCloseButton onClick={onClose} />}
  </DialogTitle>
)

DialogHeader.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.node,
}

const Dialog = ({ size, children, ...rest }) => (
  <Modal {...rest}>
    <Inner>
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-out duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Backdrop />
      </Transition.Child>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 translate-y-full"
        enterTo="opacity-100 translate-y-0"
        leave="ease-out duration-300"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-full"
      >
        <Panel size={size}>{children}</Panel>
      </Transition.Child>
    </Inner>
  </Modal>
)

Dialog.propTypes = {
  open: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'small', 'md', 'medium', 'lg', 'large']),
  onClose: PropTypes.func,
  children: PropTypes.node,
}

Dialog.defaultProps = {
  size: 'md',
}

Dialog.Header = DialogHeader

Dialog.Content = DialogContent

Dialog.Footer = DialogFooter

export default Dialog
