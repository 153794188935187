import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Title, Content, Separator, MoreIconButton } from './styles'

const Card = ({ title, children, onClickMore, ...rest }) => (
  <Wrapper {...rest}>
    {title && <Title>{title}</Title>}
    {onClickMore && <MoreIconButton onClick={onClickMore} />}
    <Content>{children}</Content>
  </Wrapper>
)

Card.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  onClickMore: PropTypes.func,
}

Card.Separator = Separator

export default Card
