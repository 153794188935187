import findKey from 'lodash/findKey'
import fromPairs from 'lodash/fromPairs'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import has from 'lodash/has'
import isArray from 'lodash/isArray'
import map from 'lodash/map'
import merge from 'lodash/merge'
import partition from 'lodash/partition'
import sortBy from 'lodash/sortBy'
import values from 'lodash/values'

//  function sortGroups(groups)
//
//    given: hash of { key: { name, sort }, key: {...} } groups,
//    return: sorted array: [{ key, name, sort }, {...}]
export const sortGroups = (groups) =>
  sortBy(
    map(groups, (g, k) => ({
      ...g,
      key: k || 'default',
    })),
    'sort',
  )

//  function defaultGropuKey(groups)
//
//    given: hash of { key: { name, sort }, key: {...} } groups,
//      look for either 'default' as key or, failing that, the key
//      of the first sorted group
//    return: the default key string
export const defaultGroupKey = (groups) =>
  has(groups, 'default') ? 'default' : findKey(groups, ['sort', 0])

//  function organizeFieldDefs(fieldDefArray, defaultGroupKey, groupHash)
//
//    given:
//      * a collection of field definition objects: { id, name, description, data_type, ... }
//      * string for the current default group's key
//      * hash of { key: { name, sort }, key: {...} } groups,
//
//    returns:
//      a hash of sorted groups as keys, each pointing to a sorted array of field defs:
//      {
//        group1key: [
//          { name: 'field 1', sort: 0, ...etc },
//          { name: 'field 2', sort: 1, ...etc },
//        ],
//        group2key: [
//          { name: 'field 3', sort: 0, ...etc },
//          { name: 'field 4', sort: 1, ...etc },
//        ],
//      }
export const organizeFieldDefs = (defs, defaultGroup, groups) => {
  const defsArr = isArray(defs) ? defs : values(defs)
  if (defsArr.length === 0) return {}

  // first get a useful lineup
  const sortedDefs = sortBy(defsArr, ['render_opts.group', 'render_opts.sort'])

  // see what group keys we have configured
  const gKeys = map(groups, (g) => g.key)
  // initialize sorted group structure
  const sortedGroupsObj = fromPairs(map(gKeys, (key) => [key, []]))

  // split the list into group-assigned vs orphaned fields
  const [groupedDefs, orphanDefs] = partition(sortedDefs, (v) =>
    gKeys.includes(get(v, 'render_opts.group')),
  )

  // first handle the group-assigned fields, grouping them
  // together by shared group key
  const defsList = merge(
    sortedGroupsObj,
    groupBy(groupedDefs, (v) => {
      const gKey = get(v, 'render_opts.group', defaultGroup)
      return gKeys.includes(gKey) ? gKey : defaultGroup
    }),
  )

  // now take care of any orphan fields, sticking them
  // in default and updating their group and sort attributes
  const defGroup = defsList[defaultGroup] || []
  defsList[defaultGroup] = defGroup.concat(
    map(orphanDefs, (o, i) =>
      merge(o, {
        // eslint-disable-next-line camelcase
        render_opts: { group: defaultGroup, sort: i + defGroup.length },
      }),
    ),
  )

  return defsList
}

export const buildGroupsTreeOrdered = (fieldDefs, fieldGroups) => {
  // returns sorted group list
  const groupList = sortGroups(fieldGroups)
  // returns default group key
  const defaultKey = defaultGroupKey(fieldGroups)
  // returns sorted field list per group
  const tree = organizeFieldDefs(fieldDefs, defaultKey, groupList)
  return tree
}
