import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion'
import { colors, fonts, mixins } from 'styles'

const ChevIcon = styled.i.attrs({ className: 'fa' })`
  margin-right: 5px;
  color: ${colors.PRIMARY};
  &:before {
    content: '\f13a';
  }
`

const Wrapper = styled(AccordionItem)`
  ${({ $avoidPageBreak }) => $avoidPageBreak && mixins.avoidPageBreak}

  &:not(:first-child) {
    .accordion__button {
      border-top: 1px solid ${colors.LIGHT_GREY};
    }
  }

  .accordion__button {
    padding: 12px;
    font-family: ${fonts.SECONDARY};
    font-size: 15px;
    font-weight: bold;

    &[aria-expanded='true'] > ${ChevIcon}:before {
      content: '\f139';
    }

    &[aria-expanded='false'] {
      ${mixins.hidePrint}
    }

    &:hover {
      background-color: #e6e6e6;
    }
  }

  .accordion__panel {
    padding: 10px 12px 15px;
  }
`

const Item = ({ title, mountOnEnter, avoidPageBreak, children, ...rest }) => {
  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Enter' || e.keyCode === 32) {
      e.target.click()
    }
  }, [])

  return (
    <Wrapper {...rest} $avoidPageBreak={avoidPageBreak}>
      <AccordionItemHeading onKeyDown={handleKeyDown}>
        <AccordionItemButton>
          <ChevIcon />
          {title}
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        {mountOnEnter ? (
          <AccordionItemState>
            {({ expanded }) => expanded && children}
          </AccordionItemState>
        ) : (
          children
        )}
      </AccordionItemPanel>
    </Wrapper>
  )
}

Item.propTypes = {
  title: PropTypes.string.isRequired,
  mountOnEnter: PropTypes.bool,
  avoidPageBreak: PropTypes.bool,
  children: PropTypes.element,
}

export default Item
