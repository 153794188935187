import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components-v2/atoms/Button'
import Table from 'components-v2/molecules/Table'
import SimpleDialog from 'components-v2/molecules/SimpleDialog'
import { NameLabel, SubLabel } from './styles'

const getTableColumns = (onRequest) => [
  {
    id: 'id',
    header: 'id',
    hidden: true,
  },
  {
    id: 'name',
    header: 'Available Assessments',
    cell: (info) => (
      <div>
        <NameLabel>{info.getValue()}</NameLabel>
        <SubLabel>{info.row.original.description}</SubLabel>
      </div>
    ),
    width: '84%',
  },
  {
    id: 'df1',
    header: '',
    cell: (info) => (
      <Button
        color="primary"
        size="small"
        onClick={() => onRequest(info.row.original.id)}
      >
        <i className="fa fa-share fa-fw" />
        Share
      </Button>
    ),
    classes: 'hidden-print',
    headerClasses: 'hidden-print',
    width: '16%',
  },
]

const RequestableProjectsModal = ({
  requestableProjects,
  onRequest,
  onClose,
  ...rest
}) => {
  const tableColumns = React.useMemo(
    () => getTableColumns(onRequest),
    [onRequest],
  )

  const buttons = React.useMemo(
    () => [
      {
        color: 'primary',
        onClick: onClose,
        children: 'Close',
      },
    ],
    [onClose],
  )

  return (
    <SimpleDialog {...rest} onClose={onClose} buttons={buttons}>
      <p>
        Please select the assessment you would like to fill out and submit to
        share on your profile
      </p>
      <Table
        data={requestableProjects || []}
        columns={tableColumns}
        enablePagination={false}
        enableSorting={false}
      />
    </SimpleDialog>
  )
}

RequestableProjectsModal.propTypes = {
  requestableProjects: PropTypes.array,
  onRequest: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default RequestableProjectsModal
