import React from 'react'
import PropTypes from 'prop-types'
import { branch } from 'hocs'

// When calendar is open along with month selector instead of date selector, there is no way to focus on the selected month.
export default (WrappedComponent) => {
  const WrapperComponent = React.forwardRef(
    ({ onCalendarOpen, onCalendarClose, onKeyDown, ...rest }, ref) => {
      const calendarContainerRef = React.useRef()
      const [open, setOpen] = React.useState(false)

      const CalendarContainer = React.useMemo(
        () =>
          // eslint-disable-next-line react/no-unstable-nested-components, react/prop-types
          ({ className, children }) =>
            (
              <div className={className} ref={calendarContainerRef}>
                {children}
              </div>
            ),
        [],
      )

      const handleCalendarOpen = React.useCallback(() => {
        setOpen(true)
        if (onCalendarOpen) {
          return onCalendarOpen()
        }
        return undefined
      }, [onCalendarOpen])

      const handleCalendarClose = React.useCallback(() => {
        setOpen(false)
        if (onCalendarClose) {
          return onCalendarClose()
        }
        return undefined
      }, [onCalendarClose])

      const handleKeyDown = React.useCallback(
        (event) => {
          if (onKeyDown) {
            onKeyDown(event)
          }
          if (open) {
            if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
              const selectedMonth = calendarContainerRef.current?.querySelector(
                '.react-datepicker__month-text[tabindex="0"]',
              )
              if (selectedMonth) {
                selectedMonth.focus({ preventScroll: true })
              }
            }
          }
        },
        [open, onKeyDown],
      )

      return (
        <WrappedComponent
          {...rest}
          ref={ref}
          calendarContainer={CalendarContainer}
          onKeyDown={handleKeyDown}
          onCalendarClose={handleCalendarClose}
          onCalendarOpen={handleCalendarOpen}
        />
      )
    },
  )

  WrapperComponent.propTypes = {
    onCalendarOpen: PropTypes.func,
    onCalendarClose: PropTypes.func,
    onKeyDown: PropTypes.func,
  }

  return branch(
    (props) => props.showMonthYearPicker,
    WrapperComponent,
    WrappedComponent,
  )
}
