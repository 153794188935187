import LabelTypes from './label_types'

const AssessmentClientSubmitReasonTypes = {
  DID_NOT_RESPOND: 'did_not_respond',
  EXTERNAL_DOCUMENT: 'external_document',
  OTHER: 'other',
}

export const AssessmentClientSubmitReasonOptions = [
  {
    value: AssessmentClientSubmitReasonTypes.DID_NOT_RESPOND,
    label: `No response from ${LabelTypes.VENDOR.toLowerCase()}`,
  },
  {
    value: AssessmentClientSubmitReasonTypes.EXTERNAL_DOCUMENT,
    label: `${LabelTypes.VENDOR} provided documentation externally`,
  },
  {
    value: AssessmentClientSubmitReasonTypes.OTHER,
    label: 'Moving to submitted for some other reason',
  },
]

export default AssessmentClientSubmitReasonTypes
