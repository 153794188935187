/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'
import { toLocalTimeString, toDate } from 'utils/date'
import config from 'config'
import { colors } from 'styles'

const WarningText = styled.span`
  color: ${colors.DANGER};
`

export const dateFormatterFactory = (options = {}) => {
  const {
    format = config.tableDateFormat,
    showWarning,
    emptyText = '',
  } = options
  return (props) => {
    if (!props.getValue()) {
      return emptyText
    }
    const str = toLocalTimeString(props.getValue(), format)
    const date = toDate(props.getValue())
    return str &&
      showWarning &&
      showWarning(props.getValue(), date, props.row.original) ? (
      <WarningText>{str}</WarningText>
    ) : (
      str
    )
  }
}

export const applyColumnToggles = (columns, toggles) => {
  if (!toggles) {
    return columns
  }
  return columns.map((e) => ({
    ...e,
    hidden: e.togglable === false ? e.hidden : !toggles.includes(e.id),
  }))
}
