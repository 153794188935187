import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import FormField, { BaseField } from 'components-v2/molecules/FormField'
import CharacterCounter from 'components-v2/molecules/CharacterCounter'
import FormDialog from 'components-v2/organisms/FormDialog'
import { toLocalTimeString } from 'utils/date'
import { StyledFormattableEditor } from './styles'

const InputField = ({
  name,
  value,
  maxLength,
  supportFormatting,
  onChange,
  ...rest
}) => (
  <BaseField {...rest}>
    <CharacterCounter
      maxLength={maxLength}
      length={value?.body?.length}
      visibleOnFocusOnly={false}
    >
      <StyledFormattableEditor
        name={name}
        value={value}
        onChange={onChange}
        disableFormatting={!supportFormatting}
        autoFocus
      />
    </CharacterCounter>
  </BaseField>
)

InputField.propTypes = {
  value: PropTypes.shape({
    body: PropTypes.string,
    formatted: PropTypes.bool,
  }),
  name: PropTypes.string,
  maxLength: PropTypes.number,
  supportFormatting: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

const NoteModal = ({
  note,
  maxLength,
  supportFormatting,
  onSubmit,
  ...rest
}) => {
  const footerLeftContent = (
    <div className="small">
      <div>
        <b>Last Updated:</b>{' '}
        {note?.updated_at
          ? toLocalTimeString(note.updated_at, 'yyyy/MM/dd h:mm a z')
          : 'never'}
      </div>
      <div>
        <b>Updated By:</b> {note?.user?.name || 'none'}
      </div>
    </div>
  )

  const formConfig = React.useMemo(() => {
    const initialValues = { note }
    const validationSchema = yup.object().shape({
      note: yup
        .object()
        .test(
          'note-maximum-length',
          `Maximum of ${maxLength} characters`,
          (value) => !value?.body || value?.body?.length <= maxLength,
        ),
    })
    return { initialValues, validationSchema }
  }, [note, maxLength])

  const handleSubmit = React.useCallback(
    (formData, actions) => {
      onSubmit(formData.note || {}, actions)
    },
    [onSubmit],
  )

  return (
    <FormDialog
      {...rest}
      footerLeftContent={footerLeftContent}
      size="lg"
      formConfig={formConfig}
      onSubmit={handleSubmit}
    >
      <FormField
        name="note"
        component={InputField}
        maxLength={maxLength}
        supportFormatting={supportFormatting}
      />
    </FormDialog>
  )
}

NoteModal.propTypes = {
  title: PropTypes.string,
  note: PropTypes.object,
  maxLength: PropTypes.number,
  submitButtonLabel: PropTypes.string,
  supportFormatting: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
}

NoteModal.defaultProps = {
  title: 'Note',
  submitButtonLabel: 'Save',
  maxLength: 100000,
  supportFormatting: false,
}

export default NoteModal
