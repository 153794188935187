import React from 'react'
import styled from 'styled-components'
import { ChevronRight } from '@styled-icons/fa-solid/ChevronRight'
import { CaretUp } from '@styled-icons/fa-solid/CaretUp'
import { CaretDown } from '@styled-icons/fa-solid/CaretDown'
import Icon from 'components-v2/atoms/Icon'
import A from 'components-v2/atoms/A'
import Label from 'components-v2/atoms/Label'
import { colors } from 'styles'
import { getRiskHealthScoreColor } from '../utils'

const ChevRightIcon = styled(Icon).attrs({ icon: ChevronRight })``

const RightArrowLink = styled.a`
  color: ${colors.GREY};
  &:hover {
    color: ${colors.DARK_GREY};
  }
`

const Trend = styled.span`
  font-size: 14px;
  line-height: 1em;
`

const CaretIcon = styled(Icon).attrs((p) => ({
  icon: p.positive ? CaretUp : CaretDown,
}))`
  font-size: 12px !important;
  margin-left: 4px;
`

const ToolLogo = styled.img`
  max-width: 100%;
  height: auto;
  max-height: 30px;
`

export const recentChangesTableColumns = [
  {
    id: 'name',
    header: 'Vendor',
    classes: 'text-ellipsis',
  },
  {
    id: 'score',
    header: 'Risk Health',
    cell: (info) => (
      <div>
        <Label color={getRiskHealthScoreColor(info.getValue())}>
          {info.getValue()}
        </Label>
        <CaretIcon positive={info.row.original.trend >= 0} />
        <Trend>{Math.abs(info.row.original.trend)}</Trend>
      </div>
    ),
    width: 95,
  },
  {
    id: 'df',
    header: '',
    cell: () => (
      <RightArrowLink href="/cme/vendors/1">
        <ChevRightIcon />
      </RightArrowLink>
    ),
    width: 20,
  },
]

export const securityToolsTableColumns = [
  {
    id: 'name',
    header: 'Tool',
    cell: (info) => (
      <ToolLogo
        src={info.row.original.logo_url}
        title={info.row.original.name}
      />
    ),
  },
  {
    id: 'count',
    header: 'Used by',
    cell: (info) => (
      <A href="#" underline>
        {info.getValue()} vendors
      </A>
    ),
    width: 80,
  },
  {
    id: 'df',
    header: '',
    cell: () => (
      <RightArrowLink href="#">
        <ChevRightIcon />
      </RightArrowLink>
    ),
    width: 20,
  },
]
