import { getMultiSelectFilterSelectedCount } from 'lib/multiSelectFilter'
import { filterTypes } from './consts'

export const getFilterSelectedCount = (configList, name, value) => {
  const filterConfig = configList?.find((e) => e.name === name)
  if (!filterConfig) {
    return undefined
  }

  const { type, getSelectedCount } = filterConfig
  const filterValue = value?.[name]

  if (getSelectedCount) {
    return getSelectedCount(filterValue)
  }

  switch (type) {
    case filterTypes.DATE_RANGE:
      return (filterValue?.startDate ? 1 : 0) + (filterValue?.endDate ? 1 : 0)
    default:
      return getMultiSelectFilterSelectedCount(filterValue)
  }
}
