import styled from 'styled-components'
import Dialog from 'components-v2/molecules/Dialog'
import { Panel } from 'components-v2/molecules/Dialog/styles'

export const StyledDialog = styled(Dialog)`
  @media (min-width: 680px) {
    ${Panel} {
      width: 660px;
    }
  }
`

export const DialogContent = styled(Dialog.Content)`
  padding: 0;
`

export const Iframe = styled.iframe`
  border: 0;
  width: 100%;
  height: 500px;
`
