import PropTypes from 'prop-types'
import React from 'react'
import sumBy from 'lodash/sumBy'
import Table from 'components-v2/molecules/Table'
import GradeLabel from 'components/shared/GradeLabel'
import { dateFormatterFactory } from 'lib/table'
import { TableWrapper, NoWrapCell } from './styles'

const valueFor = (data, key) => sumBy(data, `${key}_count`)
const dateFormatter = dateFormatterFactory()
const columns = [
  {
    id: 'name',
    header: 'Name',
    cell: (props) => (
      <NoWrapCell>
        <a href={props.row.original.path}>{props.getValue()}</a>
      </NoWrapCell>
    ),
  },
  {
    id: 'assessment_status',
    header: 'Status',
    cell: (props) => props.getValue().titleize(),
  },
  {
    id: 'next_reassessment_at',
    header: 'Scheduled',
    cell: dateFormatter,
  },
  {
    id: 'invited_count',
    header: 'Invited',
  },
  {
    id: 'in_process_count',
    header: 'In Process',
  },
  {
    id: 'submitted_count',
    header: 'Submitted',
  },
  {
    id: 'expired_count',
    header: 'Expired',
  },
  {
    id: 'failed_count',
    header: 'Failed',
  },
  {
    id: 'none_count',
    header: 'No Grade',
  },
  {
    id: 'passed_count',
    header: 'Passed',
  },
  {
    id: 'score',
    header: 'Score',
  },
  {
    id: 'grade',
    header: 'Grade',
    cell: (props) =>
      props.row.original.reviewed_count > 0 ||
      props.row.original.passed_count > 0 ||
      props.row.original.failed_count > 0 ? (
        <GradeLabel grade={props.getValue()} />
      ) : (
        ''
      ),
  },
]

const ChartTableWidget = ({ data, totals }) => (
  <TableWrapper>
    <Table
      data={data ?? []}
      columns={columns}
      enableSorting={false}
      enablePagination={false}
      tfootContent={
        totals && (
          <tr>
            <th>Total</th>
            <th>{data.length}</th>
            <th> </th>
            <th>{valueFor(data, 'invited')}</th>
            <th>{valueFor(data, 'in_process')}</th>
            <th>{valueFor(data, 'submitted')}</th>
            <th>{valueFor(data, 'expired')}</th>
            <th>{valueFor(data, 'failed')}</th>
            <th>{valueFor(data, 'none')}</th>
            <th>{valueFor(data, 'passed')}</th>
            <th> </th>
            <th> </th>
          </tr>
        )
      }
    />
  </TableWrapper>
)

ChartTableWidget.propTypes = {
  data: PropTypes.array,
  totals: PropTypes.array,
}

export default ChartTableWidget
