import styled from 'styled-components'
import { colors, mixins } from 'styles'

export const Wrapper = styled.div`
  background: #eee;
  border-radius: 5px;
`

export const Top = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  min-height: 56px;
  cursor: pointer;
`

export const Title = styled.h3`
  margin: 0 10px 0 0;
`

export const Toggler = styled.span`
  margin-left: auto;
  ${mixins.hidePrint}

  i {
    font-size: 14px;
    color: ${colors.PRIMARY};
  }
`

export const Content = styled.div`
  padding: 0 16px 16px;
`

export const Card = styled.div`
  background: #fff;
  border-radius: 5px;
  padding: 25px 20px;
`
