import React from 'react'
import PropTypes from 'prop-types'
import { getSortedRowModel } from '@tanstack/react-table'
import { toUncontrollable } from 'hocs'

// Enabled by default
export default function withSorting(WrappedComponent) {
  const WrapperComponent = ({
    state = {},
    sorting,
    manualSorting,
    enableSorting,
    onSortingChange,
    ...rest
  }) => {
    const sortingEx = React.useMemo(() => {
      if (sorting?.sortField && sorting?.sortOrder) {
        return [{ id: sorting.sortField, desc: sorting.sortOrder === 'desc' }]
      }
      return undefined
    }, [sorting])
    const stateEx =
      enableSorting && sortingEx
        ? {
            ...state,
            sorting: sortingEx,
          }
        : state
    const onSortingChangeEx =
      enableSorting && onSortingChange
        ? (updater) => {
            const newSorting = updater(sortingEx)
            onSortingChange({
              sortField: newSorting[0]?.id,
              sortOrder: newSorting[0]
                ? newSorting[0].desc
                  ? 'desc'
                  : 'asc'
                : undefined,
            })
          }
        : undefined

    return (
      <WrappedComponent
        {...rest}
        state={stateEx}
        getSortedRowModel={
          enableSorting && !manualSorting ? getSortedRowModel() : undefined
        }
        manualSorting={manualSorting}
        enableSorting={enableSorting}
        // Temporary fix of weird sorting state behavior
        // https://github.com/TanStack/table/issues/4289
        sortDescFirst
        onSortingChange={onSortingChangeEx}
      />
    )
  }

  WrapperComponent.propTypes = {
    state: PropTypes.object,
    sorting: PropTypes.shape({
      sortField: PropTypes.string,
      sortOrder: PropTypes.string,
    }),
    manualSorting: PropTypes.bool,
    enableSorting: PropTypes.bool,
    onSortingChange: PropTypes.func,
  }

  WrapperComponent.defaultProps = {
    enableSorting: true,
  }

  return toUncontrollable({ sorting: 'onSortingChange' })(WrapperComponent)
}
