import React from 'react'
import PropTypes from 'prop-types'
import { ChildVendorNameList, ChildVendorName } from './styles'

const ChildOrgDisplay = ({ value }) => (
  <ChildVendorNameList>
    {value.map((child) => (
      <ChildVendorName key={child.id}>
        <a href={child.path}>{child.name}</a>
      </ChildVendorName>
    ))}
  </ChildVendorNameList>
)

ChildOrgDisplay.propTypes = {
  value: PropTypes.array.isRequired,
}

export default ChildOrgDisplay
