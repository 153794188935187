import React from 'react'
import { useDrag, useDrop } from 'react-dnd'

export default ({
  id,
  index,
  itemType = 'Row',
  hasDragSource,
  onRowMove,
  onRowTemporaryMove,
  onRowCancelMove,
  canDrop,
  children,
}) => {
  const ref = React.useRef(null)
  const dragSourceRef = React.useRef(null)
  const [, dropConnector] = useDrop({
    accept: itemType,
    canDrop,
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Can drop?
      if (canDrop && !canDrop(item, monitor)) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to perform the temporary action
      if (onRowTemporaryMove) onRowTemporaryMove(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, dragSourceConnector, dragPreviewConnector] = useDrag({
    type: itemType,
    item: { id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end(item, monitor) {
      // If the drop was not handled by a compatible drop target, cancel the move action
      if (!monitor.didDrop()) {
        if (onRowCancelMove) onRowCancelMove(item.id, item.index)
        return
      }

      // When dropped on a compatible target, perform the actual action
      if (onRowMove) onRowMove(item.id, item.index)
    },
  })
  const opacity = isDragging ? 0 : 1
  dragSourceConnector(hasDragSource ? dragSourceRef : ref)
  dragPreviewConnector(dropConnector(ref))
  return children({
    opacity,
    isDragging,
    ref,
    dragSourceRef: hasDragSource ? dragSourceRef : undefined,
  })
}
