import React from 'react'
import { useGlobalLoader } from 'hooks'
import NotificationManager from 'lib/notifications'
import { useCreateClientAssessmentTemplate } from 'apis'

export default function useCreateAssessmentTemplate() {
  const { mutateAsync: createClientAssessmentTemplate } =
    useCreateClientAssessmentTemplate()
  const [showLoader, hideLoader] = useGlobalLoader()

  return React.useCallback(async () => {
    const loaderId = showLoader()
    try {
      const { url } = await createClientAssessmentTemplate()
      window.location.href = url
    } catch {
      NotificationManager.error()
      hideLoader(loaderId)
    }
  }, [showLoader, hideLoader])
}
