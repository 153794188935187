import React from 'react'
import PropTypes from 'prop-types'
import find from 'lodash/find'
import get from 'lodash/get'
import { getPageUrl } from 'utils/url'
import {
  RiskTierOptions,
  IssuePriorityOptions,
  IssuePriorityTypes,
  IssueStatusTypes,
} from 'constants/index'
import {
  Wrapper,
  Row,
  YLabel,
  XLabel,
  Cell,
  CellValue,
  CellLabel,
  XTitle,
  YTitle,
} from './styles'

const NO_TIER = 'none'

const priorityValueLabelMap = {
  [IssuePriorityTypes.INFORMATIONAL]: 'Info',
  [IssuePriorityTypes.LOW]: 'Low',
  [IssuePriorityTypes.MEDIUM]: 'Medium',
  [IssuePriorityTypes.HIGH]: 'High',
  [IssuePriorityTypes.CRITICAL]: 'Critical',
}

const xValues = IssuePriorityOptions.map((e) => e.value)
const xLabels = xValues.map((e) => priorityValueLabelMap[e])
const yValues = [...RiskTierOptions.map((e) => e.value), NO_TIER]
const yLabels = yValues.map((v) =>
  v === NO_TIER ? 'No Tier' : RiskTierOptions.find((e) => e.value === v).label,
)

const IssueRankingMatrix = ({ data, canLink, ...rest }) => {
  const mapData = React.useMemo(
    () =>
      yValues.map((yValue) =>
        xValues.map((xValue) =>
          get(
            find(data, { priority: `${xValue}`, risk_tier: `${yValue}` }),
            'count',
            0,
          ),
        ),
      ),
    [data],
  )
  const handleCellClick = React.useCallback((xIndex, yIndex) => {
    const priority = xValues[xIndex]
    const riskTier = yValues[yIndex]
    window.location.href = getPageUrl('clientIssues', undefined, {
      priority,
      risk_tier: riskTier,
      status: [
        IssueStatusTypes.CREATED,
        IssueStatusTypes.OPENED,
        IssueStatusTypes.RESPONDED,
      ],
    })
  }, [])
  return (
    <Wrapper {...rest}>
      {mapData.map((row, yIndex) => (
        <Row key={yValues[yIndex]}>
          <YLabel>{yLabels[yIndex]}</YLabel>
          {row.map((cell, xIndex) => {
            const clickProp = canLink
              ? {
                  onClick: () => handleCellClick(xIndex, yIndex),
                  onKeyPress: (e) => {
                    if (e.key === 'Enter') {
                      handleCellClick(xIndex, yIndex)
                    }
                  },
                }
              : {}
            return (
              <Cell
                key={`${yValues[yIndex]}-${xValues[xIndex]}`}
                muted={!cell}
                x={xIndex}
                y={yIndex}
                tabIndex={0}
                {...clickProp}
              >
                <CellValue>{cell}</CellValue>
                <CellLabel>{cell === 1 ? 'issue' : 'issues'}</CellLabel>
              </Cell>
            )
          })}
        </Row>
      ))}
      <Row>
        <YLabel />
        {xLabels.map((xLabel, index) => (
          <XLabel key={xValues[index]}>{xLabel}</XLabel>
        ))}
      </Row>
      <XTitle>Issue Severity</XTitle>
      <YTitle>Risk Tier</YTitle>
    </Wrapper>
  )
}

IssueRankingMatrix.propTypes = {
  data: PropTypes.array.isRequired,
  canLink: PropTypes.bool,
}

export default IssueRankingMatrix
