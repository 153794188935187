import styled from 'styled-components'
import DList from 'components-v2/molecules/DList'
import { media } from 'styles'

export const Content = styled.div`
  ${media.desktop`
    display: flex;
  `}
`

export const Left = styled.div`
  flex: auto;
  overflow: hidden;

  > * {
    margin-bottom: 25px;
  }
`

export const Right = styled.div`
  ${media.desktop`
    flex: none;
    width: 36%;
    margin-left: 25px;
  `}

  > * {
    margin-bottom: 25px;
  }
`

export const NarrowDList = styled(DList).attrs({ horizontal: true })`
  ${media.desktop`
    dt {
      width: 110px;
    }
    dd {
      padding-left: 130px;
    }
  `}
`
