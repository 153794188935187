import { useCallback } from 'react'
import { useModal } from 'hooks'
import AssessmentStatusModal from './AssessmentStatusModal'
import { getProgressDetails } from './utils'

export default function useViewAssessmentStatus(assessment) {
  const [openModal] = useModal(AssessmentStatusModal)

  return useCallback(() => {
    openModal({
      assessmentName: assessment.name,
      progressDetails: getProgressDetails(
        assessment.progress,
        assessment.sections,
      ),
    })
  }, [assessment, openModal])
}
