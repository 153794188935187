// define these to mimic the structure of how
// custom field groups come through in org settings
export const additionalInfoGroups = {
  organization_details: {
    name: 'Organization Details',
    sort: 0,
  },
  data_sources: {
    name: 'Data Sources',
    sort: 1,
  },
  assessment_categories: {
    name: 'Assessment Categories',
    sort: 2,
  },
  other: {
    name: 'Other',
    sort: 3,
  },
}
