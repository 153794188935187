import styled from 'styled-components'

export default styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;

  > * {
    position: relative;
    float: left;

    &:first-child:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:last-child):not(:first-child) {
      border-radius: 0;
    }

    & + * {
      margin-left: -1px;
    }
  }
`
