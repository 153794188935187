import React from 'react'
import PropTypes from 'prop-types'
import filter from 'lodash/filter'
import groupBy from 'lodash/groupBy'
import reduce from 'lodash/reduce'
import includes from 'lodash/includes'
import Icon from 'components-v2/atoms/Icon'
import IconButton from 'components-v2/atoms/IconButton'
import A from 'components-v2/atoms/A'
import Tooltip from 'components-v2/molecules/Tooltip'
import Table from 'components-v2/molecules/Table'
import Popover from 'components-v2/molecules/Popover'
import {
  getIssueStatusLabel,
  getIssueResolutionLabel,
  sortIssues,
  issuePriorityFormatterFactory,
} from 'lib/issue'
import { IssueStatusTypes, EntityTypes } from 'constants/index'
import { useAuthorizations } from 'hooks'
import {
  PanelContent,
  TooltipTitleRow,
  TooltipTitle,
  WarningIcon,
  CheckIcon,
} from './styles'

const priorityFormatter = issuePriorityFormatterFactory()

const getSummaryTableColumns = (isVendor, canManageIssues, onSendIssue) => [
  {
    id: 'summary',
    header: 'Summary',
    cell: (info) => <a href={info.row.original.path}>{info.getValue()}</a>,
    width: 300,
  },
  {
    id: 'status',
    header: 'Status',
    cell: (info) => {
      const status = getIssueStatusLabel(info.getValue(), isVendor)
      if (
        !isVendor &&
        [IssueStatusTypes.CLOSED, IssueStatusTypes.ARCHIVED].includes(
          info.getValue(),
        ) &&
        info.row.original.resolution
      ) {
        return (
          <div>
            <div>{status}</div>
            <div className="small">
              {getIssueResolutionLabel(info.row.original.resolution)}
            </div>
          </div>
        )
      }
      if (!isVendor && info.getValue() === IssueStatusTypes.CREATED) {
        return (
          <div>
            <span>{status}</span>&nbsp;
            {canManageIssues && (
              <Tooltip
                parent={
                  <IconButton
                    icon="fa fa-envelope"
                    onClick={() => onSendIssue(info.row.original.id)}
                  />
                }
              >
                Send Issue
              </Tooltip>
            )}
          </div>
        )
      }
      return status
    },
    width: 110,
  },
  {
    id: 'priority',
    header: 'Priority',
    cell: priorityFormatter,
    width: 120,
  },
]

const pluralize = (str, num) => (num > 1 ? `${str}s` : str)
const unpluralize = (str, num) => (num > 1 ? str.replace(/s$/, '') : str)

const actionString = (statusGroup, isVendor, num = 1) => {
  const unresolved = isVendor
    ? 'Open'
    : `${unpluralize('Requires', num)} Action`
  return statusGroup === 'done' ? 'Completed' : unresolved
}

const IssueLink = ({
  entity_type: entityType,
  issues: issuesProp,
  createLinkVisible,
  onCreateIssue,
  onSendIssue,
}) => {
  /*
    Basic idea: generate a link in one of the following four forms
      1. All active but off my plate:          <total_num> Issues
          e.g. "3 Issues"
      2. All might need something from me:     <total_num> Issues <action_string>
          e.g. "3 Issues Require Action"
      3. All are closed or archived:           <total_num> Issues Completed
          e.g. "3 Issues Completed"
      4. Mix of states :                       <total_num> Issues (<need_attn_num> <action_string>)
          e.g. "3 Issues (2 Open)"

    <action_string>:
      clients:  "Requires Action"
      vendors:  "Open"
    <need_attn_num>:
      clients:  anything in 'created', 'opened', 'responded'
      vendors:  only 'opened'
  */

  const isVendor = entityType !== EntityTypes.CLIENT
  const issues = isVendor
    ? filter(issuesProp, (issue) =>
        [
          IssueStatusTypes.OPENED,
          IssueStatusTypes.RESPONDED,
          IssueStatusTypes.CLOSED,
        ].includes(issue.status),
      )
    : issuesProp
  const issuesSorted = sortIssues(isVendor, issues)
  const grouped = groupBy(issues, 'status')
  const [canManageIssues] = useAuthorizations(['manage', 'Issue'])

  /* first figure out what we have */
  const res = reduce(
    [
      IssueStatusTypes.CREATED,
      IssueStatusTypes.OPENED,
      IssueStatusTypes.RESPONDED,
      IssueStatusTypes.CLOSED,
      IssueStatusTypes.ARCHIVED,
    ],
    (acc, val) => {
      if (grouped[val] && grouped[val].length) {
        acc.total += grouped[val].length
        const unresolvedSet = isVendor
          ? [IssueStatusTypes.OPENED]
          : [
              IssueStatusTypes.CREATED,
              IssueStatusTypes.OPENED,
              IssueStatusTypes.RESPONDED,
            ]
        const doneSet = [IssueStatusTypes.CLOSED, IssueStatusTypes.ARCHIVED]
        acc.unresolved += includes(unresolvedSet, val) ? grouped[val].length : 0
        acc.done += includes(doneSet, val) ? grouped[val].length : 0
      }
      return acc
    },
    { total: 0, unresolved: 0, done: 0 },
  )

  const columns = React.useMemo(
    () => getSummaryTableColumns(isVendor, canManageIssues, onSendIssue),
    [isVendor, onSendIssue],
  )

  if (res.total === 0) {
    return null
  }

  let iconType
  let anchorText
  if (res.total === res.done || res.total === res.unresolved) {
    iconType = res.unresolved > 0 ? 'unresolved' : 'done'
    anchorText = `${res.total} ${pluralize('Issue', res.total)} ${actionString(
      res.done > 0 ? 'done' : 'unresolved',
      isVendor,
      res.total,
    )}`
  } else {
    iconType = 'unresolved'
    anchorText = `${res.total} ${pluralize('Issue', res.total)}`
    anchorText +=
      res.unresolved > 0
        ? ` (${res.unresolved} ${actionString(
            'unresolved',
            isVendor,
            res.unresolved,
          )})`
        : ''
  }

  return (
    <Popover>
      <span>
        {iconType === 'unresolved' ? <WarningIcon /> : <CheckIcon />}&nbsp;
        <Popover.Button as={A}>{anchorText}</Popover.Button>
      </span>
      <Popover.Panel arrow>
        {({ close }) => (
          <PanelContent>
            <TooltipTitleRow>
              <TooltipTitle>Issues</TooltipTitle>
              {createLinkVisible && (
                <A
                  onClick={() => {
                    close()
                    setTimeout(() => {
                      onCreateIssue()
                    }, 100)
                  }}
                >
                  <Icon icon="fa fa-plus" /> Create Issue
                </A>
              )}
            </TooltipTitleRow>
            <Table
              data={issuesSorted}
              columns={columns}
              enablePagination={false}
              enableSorting={false}
              type={Table.types.MINI}
            />
          </PanelContent>
        )}
      </Popover.Panel>
    </Popover>
  )
}

IssueLink.propTypes = {
  issues: PropTypes.array,
  entity_type: PropTypes.string,
  createLinkVisible: PropTypes.bool,
  onCreateIssue: PropTypes.func,
  onSendIssue: PropTypes.func,
}

IssueLink.defaultProps = {
  issues: [],
  entity_type: EntityTypes.CLIENT,
  onCreateIssue: () => {},
  onSendIssue: () => {},
}

export default IssueLink
