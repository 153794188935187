import React, { useState, useLayoutEffect, cloneElement } from 'react'
import PropTypes from 'prop-types'
import Tooltip from '../Tooltip'
import { Wrapper, Single } from './styles'

const ROUND_PRECISION = 1000

function getPercentage(now, min, max) {
  const percentage = ((now - min) / (max - min)) * 100
  return Math.round(percentage * ROUND_PRECISION) / ROUND_PRECISION
}

const SingleProgressBar = ({
  min,
  now,
  max,
  label,
  srOnly,
  striped,
  active,
  color,
  tooltip,
  ...props
}) => {
  const [loaded, setLoaded] = useState(false)
  const [parent, setParent] = useState()

  useLayoutEffect(() => {
    setTimeout(() => {
      setLoaded(true)
    })
  }, [])

  return (
    <Single
      {...props}
      role="progressbar"
      $percentage={loaded ? getPercentage(now, min, max) : 0}
      $striped={active || striped}
      $active={active}
      $color={color}
      data-label={!srOnly ? label : ''}
      aria-valuenow={now}
      aria-valuemin={min}
      aria-valuemax={max}
      ref={setParent}
    >
      {srOnly ? <span className="sr-only">{label}</span> : label}
      {tooltip && parent && <Tooltip parent={parent}>{tooltip}</Tooltip>}
    </Single>
  )
}

SingleProgressBar.propTypes = {
  min: PropTypes.number,
  now: PropTypes.number,
  max: PropTypes.number,
  label: PropTypes.node,
  srOnly: PropTypes.bool,
  striped: PropTypes.bool,
  active: PropTypes.bool,
  color: PropTypes.string,
  tooltip: PropTypes.any,
}

const ProgressBar = ({ isChild, ...props }) => {
  if (isChild) {
    return <SingleProgressBar {...props} />
  }

  const {
    min,
    now,
    max,
    label,
    srOnly,
    striped,
    active,
    height,
    color,
    tooltip,
    children,
    ...wrapperProps
  } = props

  return (
    <Wrapper {...wrapperProps} $height={height}>
      {children ? (
        React.Children.map(children, (child) =>
          cloneElement(child, { isChild: true }),
        )
      ) : (
        <SingleProgressBar
          {...{
            min,
            now,
            max,
            label,
            srOnly,
            striped,
            active,
            color,
            tooltip,
          }}
        />
      )}
    </Wrapper>
  )
}

/**
 * Validate that children, if any, are instances of `<ProgressBar>`.
 */
function onlyProgressBar(props, propName, componentName) {
  const children = props[propName]
  if (!children) {
    return null
  }

  let error = null

  React.Children.forEach(children, (child) => {
    if (error) {
      return
    }

    /**
     * Compare types in a way that works with libraries that patch and proxy
     * components like react-hot-loader.
     *
     * see https://github.com/gaearon/react-hot-loader#checking-element-types
     */
    const element = <ProgressBar />
    if (child.type === element.type) return

    const childIdentifier = React.isValidElement(child)
      ? child.type.displayName || child.type.name || child.type
      : child
    error = new Error(
      `Children of ${componentName} can contain only ProgressBar ` +
        `components. Found ${childIdentifier}.`,
    )
  })

  return error
}

ProgressBar.propTypes = {
  min: PropTypes.number,
  now: PropTypes.number,
  max: PropTypes.number,
  label: PropTypes.node,
  srOnly: PropTypes.bool,
  striped: PropTypes.bool,
  active: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
  tooltip: PropTypes.any,
  children: onlyProgressBar,

  /**
   * @private
   */
  isChild: PropTypes.bool,
}

ProgressBar.defaultProps = {
  min: 0,
  max: 100,
  height: 9,
  active: false,
  isChild: false,
  srOnly: false,
  striped: false,
  color: undefined,
  tooltip: undefined,
}

export default ProgressBar
