import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 230px;
  max-width: 90vw;
  padding: 16px;
`

export const Footer = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
`
