import React from 'react'
import PropTypes from 'prop-types'
import { Paperclip } from '@styled-icons/fa-solid/Paperclip'
import Icon from 'components-v2/atoms/Icon'
import FileList from '../FileList'

const Layout = ({
  displayLabel,
  file,
  dropzoneUploader,
  disabled,
  canRemove,
  onRemove,
  ...rest
}) => (
  <div {...rest}>
    {!disabled && dropzoneUploader}
    {disabled && file && displayLabel && (
      <span>
        <Icon icon={Paperclip} /> Attachment
      </span>
    )}
    {file && (
      <FileList
        files={file ? [file] : undefined}
        onRemove={onRemove}
        disabled={disabled || !canRemove}
      />
    )}
  </div>
)

Layout.propTypes = {
  displayLabel: PropTypes.bool,
  file: PropTypes.instanceOf(File),
  dropzoneUploader: PropTypes.element,
  disabled: PropTypes.bool,
  canRemove: PropTypes.bool,
  onRemove: PropTypes.func.isRequired,
}

export default Layout
