import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import FormDialog from 'components-v2/organisms/FormDialog'
import { getPageUrl } from 'utils/url'
import SelectFormatFormField from '../fields/SelectFormatFormField'

const formConfig = {
  validationSchema: yup.object().shape({
    format: yup.string().label('Format').required(),
  }),
  initialValues: {
    format: undefined,
  },
}

const ExportVendorDetailsModal = (props) => {
  const handleSubmit = React.useCallback(
    (formData) => {
      window.location.assign(
        getPageUrl(
          'clientVendorsExport',
          undefined,
          undefined,
          formData.format,
        ),
      )
      props.onClose()
    },
    [props],
  )
  return (
    <FormDialog
      {...props}
      title="Export Vendor Details"
      formConfig={formConfig}
      onSubmit={handleSubmit}
    >
      <SelectFormatFormField />
    </FormDialog>
  )
}

ExportVendorDetailsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default ExportVendorDetailsModal
