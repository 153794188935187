import styled from 'styled-components'
import Dialog from 'components-v2/molecules/Dialog'
import Button from 'components-v2/atoms/Button'
import SearchInput from 'components-v2/molecules/SearchInput'
import Loader from 'components-v2/molecules/Loader'
import { mixins, colors } from 'styles'

export const Body = styled(Dialog.Content)`
  color: ${colors.BODY_TEXT};
  font-weight: 500;
  height: 320px;
  overflow-y: auto;
`

export const Footer = styled(Dialog.Footer)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const FooterSpacer = styled.div`
  flex-grow: 1;
`

export const SearchWrapper = styled.div`
  display: flex;
  margin-bottom: 27px;
  align-items: center;
`

export const StyledSearchInput = styled(SearchInput)`
  width: 378px;
`

export const SearchButton = styled(Button).attrs({ color: 'primary' })`
  background-color: #212121;
  border-color: #212121;
  margin-left: 8px;
  flex: none;

  &:hover,
  &:active,
  &:focus {
    background-color: #000 !important;
    border-color: #000 !important;
  }
`

export const LoadingIcon = styled(Loader).attrs({ size: '20px' })`
  line-height: 0;
  flex: none;
  margin-left: 20px;
`

export const ResultsTitle = styled.div`
  ${mixins.smallHeader}
  letter-spacing: 0;
  margin-bottom: 9px;
`

export const GreenCheckIcon = styled.i.attrs({
  className: 'fa fa-check-circle',
})`
  color: ${colors.GREEN};
`

export const RedCloseIcon = styled.i.attrs({ className: 'fa fa-times-circle' })`
  color: ${colors.RED};
`

export const Muted = styled.div`
  color: #b5b5b5;
`

export const CreateNewButton = styled(Button).attrs({
  color: 'primary',
  variant: 'text',
})`
  ${mixins.smallHeader}
  letter-spacing: 0;
`
