import { useCallback, useEffect } from 'react'
import { useModal, useCurrent } from 'hooks'
import AutoScoreSettingsModal from './AutoScoreSettingsModal'
import { getAutoScoreOptions } from './utils'
import { useSetElement } from './useAssessmentElementsFetcher'
import useEditQuestionAutoScoreOrg from '../shared/useEditQuestionAutoScore'

export default function useEditQuestionAutoScore(
  elements,
  confirmAutoScore,
  ratingLabelSet,
) {
  // eslint-disable-next-line no-unused-vars
  const [openModal, _, updateModal] = useModal(AutoScoreSettingsModal)
  const elementsRef = useCurrent(elements)
  const setElement = useSetElement()
  const editQuestionAutoScore = useEditQuestionAutoScoreOrg(
    elements,
    confirmAutoScore,
  )

  useEffect(() => {
    updateModal({ elements })
  }, [elements])

  return useCallback(
    (elementId) => {
      const element = elementsRef.current?.find((e) => e.id === elementId)
      if (!element) {
        return
      }

      const autoScoreOptions = getAutoScoreOptions(ratingLabelSet)
      const onAutoScoreChange = (choiceId, score) => {
        editQuestionAutoScore(elementId, choiceId, score, (response) => {
          // Update state using the api response
          setElement(elementId, (current) => ({
            ...current,
            choices: current.choices?.map((e) =>
              e.id === choiceId ? response : e,
            ),
          }))
        })
      }
      openModal({
        elements: elementsRef.current,
        elementId,
        autoScoreOptions,
        onAutoScoreChange,
      })
    },
    [openModal, ratingLabelSet, editQuestionAutoScore, setElement],
  )
}
