import React from 'react'
import PropTypes from 'prop-types'
import { Paperclip } from '@styled-icons/fa-solid/Paperclip'
import Icon from 'components-v2/atoms/Icon'
import FileList from '../FileList'

const Layout = ({
  displayLabel,
  files,
  dropzoneUploader,
  disabled,
  canRemove,
  onRemove,
  ...rest
}) => (
  <div {...rest}>
    {!disabled && dropzoneUploader}
    {disabled && files && files.length > 0 && displayLabel && (
      <span>
        <Icon icon={Paperclip} /> Attachment
      </span>
    )}
    {files && (
      <FileList
        files={files}
        onRemove={onRemove}
        disabled={disabled || !canRemove}
      />
    )}
  </div>
)

Layout.propTypes = {
  displayLabel: PropTypes.bool,
  files: PropTypes.array,
  dropzoneUploader: PropTypes.any,
  disabled: PropTypes.bool,
  canRemove: PropTypes.bool,
  onRemove: PropTypes.func,
}

export default Layout
