import React, { useCallback } from 'react'
import { LabelTypes } from 'constants/index'
import { useConfirm } from 'hooks'

export default () => {
  const confirm = useConfirm()

  return useCallback(() => {
    const tldr = (
      <ul>
        <li>
          <b>Admin:</b> This user has the ability to manage advanced
          authentication requirements for your organization, such as single
          sign-on and two factor authentication
        </li>
        <li>
          <b>Manager:</b> This is the default access role
        </li>
        <li>
          <b>User:</b> This user can review and score{' '}
          {LabelTypes.VENDOR.toLowerCase()} assessments, but cannot mark them as
          completed and can create issues but not close them.
        </li>
        <li>
          <b>Read Only:</b> This user can view all vendor data and reports, but
          cannot make any changes.
        </li>
        {/* <li><b>Summary View:</b> This user can view status lists and reports, but cannot see assessment response details</li> */}
        <li>
          <b>Status Only:</b> This user is limited to a single page overview
          listing the current status of all {LabelTypes.VENDOR.toLowerCase()}{' '}
          assessments, and can be further limited to a specific subset of{' '}
          {LabelTypes.VENDORS.toLowerCase()}
        </li>
      </ul>
    )
    confirm({
      title: 'Access Role Options',
      body: tldr,
      showCancelButton: false,
      confirmText: 'Got it!',
    })
  }, [confirm])
}
