import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
`

export const Muted = styled.span`
  font-style: italic;
  opacity: 0.5;
`

export const ConnectButtonWrapper = styled.div`
  position: absolute;
  bottom: 4px;
  right: 0;
`
