import React from 'react'
import PropTypes from 'prop-types'
import Scrollspy from 'react-scrollspy'
import { Sticky, StickyContainer } from 'react-sticky'
import Icon from 'components-v2/atoms/Icon'
import Button from 'components-v2/atoms/Button'
import { sizes } from 'styles'
import { LabelTypes } from 'constants/index'
import SectionCard from './SectionCard'
import DetailsForm from './DetailsForm'
import EmailInvitationsForm from './EmailInvitationsForm'
import ProjectVendors from './ProjectVendors'
import AssessmentTemplates from './AssessmentTemplates'
import NotificationsForm from './NotificationsForm'
import { scrollTo } from './utils'
import { SectionIdTypes } from './consts'
import {
  ScrollSpyOffset,
  StickyContent,
  StickyContentInner,
  StyledPageHeader as PageHeader,
  Wrapper,
  SectionSelect,
  Sidebar,
  SectionNav,
  SectionNavItem,
  SectionNavItemIcon,
  Content,
  TemplateSelect,
  FormWrapper,
} from './styles'

const View = ({
  project,
  editable,
  saveEnabled,
  addableAssessmentTemplates,
  assessmentTemplatesEditable,
  autoSavedVisibleCollection,
  projectVendorsProps,
  eventSubscribers,
  isFetchingEventSubscribers,
  onSaveClose,
  onEmailInvitationPreview,
  onDetailsFormSubmit,
  onDetailsFormValidStateChange,
  onEmailInvitationsFormSubmit,
  onEmailInvitationsFormValidStateChange,
  onAssessmentTemplateAdd,
  onAssessmentTemplateDelete,
  onAddUsersToNotify,
  onEditEventSubscriber,
  onRemoveEventSubscriber,
  onNotificationsFormSubmit,
}) => {
  const sections = {
    [SectionIdTypes.PROJECT_DETAILS]: {
      title: 'Project Details',
      icon: 'fa fa-list-ul',
      children: (
        <FormWrapper>
          <DetailsForm
            project={project}
            editable={editable}
            onSubmit={onDetailsFormSubmit}
            onValidStateChange={onDetailsFormValidStateChange}
          />
        </FormWrapper>
      ),
    },
    [SectionIdTypes.TEMPLATES]: {
      title: 'Templates',
      icon: 'fa fa-file-text-o',
      topRight: assessmentTemplatesEditable && (
        <TemplateSelect
          placeholder="Select a template..."
          options={addableAssessmentTemplates}
          getOptionLabel={(e) => e.name}
          getOptionValue={(e) => e.id}
          onChange={onAssessmentTemplateAdd}
        />
      ),
      children: (
        <AssessmentTemplates
          assessmentTemplates={project.assessment_templates}
          editable={assessmentTemplatesEditable}
          onDelete={onAssessmentTemplateDelete}
        />
      ),
    },
    [SectionIdTypes.VENDORS]: {
      title: LabelTypes.VENDORS,
      icon: 'fa fa-users',
      topRight: editable && (
        <Button color="primary" onClick={projectVendorsProps.onAdd}>
          <Icon icon="fa fa-plus-circle" />
          {`Add ${LabelTypes.VENDORS.toLowerCase()} to project`}
        </Button>
      ),
      children: <ProjectVendors {...projectVendorsProps} editable={editable} />,
    },
    [SectionIdTypes.EMAIL_INVITATIONS]: {
      title: 'Email Invitations',
      icon: 'fa fa-envelope-o',
      topRight: (
        <Button color="primary" onClick={onEmailInvitationPreview}>
          <Icon icon="fa fa-search" />
          Preview
        </Button>
      ),
      children: (
        <FormWrapper>
          <EmailInvitationsForm
            project={project}
            onSubmit={onEmailInvitationsFormSubmit}
            onValidStateChange={onEmailInvitationsFormValidStateChange}
          />
        </FormWrapper>
      ),
    },
    [SectionIdTypes.NOTIFICATIONS]: {
      title: 'Notifications',
      icon: 'fa fa-bell-o',
      topRight: (
        <Button color="primary" onClick={onAddUsersToNotify}>
          <Icon icon="fa fa-plus-circle" />
          Add users to notify
        </Button>
      ),
      children: (
        <NotificationsForm
          project={project}
          eventSubscribers={Object.values(eventSubscribers)}
          isFetchingEventSubscribers={isFetchingEventSubscribers}
          onEditEventSubscriber={onEditEventSubscriber}
          onRemoveEventSubscriber={onRemoveEventSubscriber}
          onSubmit={onNotificationsFormSubmit}
        />
      ),
    },
  }
  const sectionIds = Object.keys(sections)

  const handleKeyDown = React.useCallback((e, id) => {
    if (e.keyCode === 13) {
      scrollTo(id)
    }
  }, [])

  return (
    <StickyContainer>
      <Sticky topOffset={-sizes.HEADER_HEIGHT} disableHardwareAcceleration>
        {({ isSticky, style }) => (
          <StickyContent isSticky={isSticky} style={style}>
            <StickyContentInner>
              <PageHeader
                portal={false}
                fixedWidth={false}
                title="Edit Project"
              >
                <Button
                  color="primary"
                  disabled={!saveEnabled}
                  onClick={onSaveClose}
                >
                  Done
                </Button>
              </PageHeader>
              <SectionSelect
                value=""
                onChange={(e) => scrollTo(e.target.value)}
                onScroll={(e) => e.stopPropagation()}
              >
                <option key="default" value="" disabled>
                  Jump to...
                </option>
                {sectionIds.map((id) => (
                  <option key={id} value={id}>
                    {sections[id].title}
                  </option>
                ))}
              </SectionSelect>
            </StickyContentInner>
          </StickyContent>
        )}
      </Sticky>
      <Wrapper>
        <Sticky
          topOffset={-sizes.HEADER_HEIGHT}
          disableHardwareAcceleration
          disableCompensation
        >
          {({ isSticky, style }) => (
            <Sidebar isSticky={isSticky} style={style}>
              <Scrollspy
                items={sectionIds}
                currentClassName="is-current"
                offset={ScrollSpyOffset}
                componentTag={SectionNav}
              >
                {sectionIds.map((id) => (
                  <SectionNavItem
                    key={id}
                    onClick={() => scrollTo(id)}
                    onKeyDown={(e) => handleKeyDown(e, id)}
                    tabIndex={0}
                  >
                    <SectionNavItemIcon icon={sections[id].icon} />
                    {sections[id].title}
                  </SectionNavItem>
                ))}
              </Scrollspy>
            </Sidebar>
          )}
        </Sticky>
        <Content>
          {sectionIds.map((id) => (
            <SectionCard
              key={id}
              id={id}
              {...sections[id]}
              autoSavedVisible={autoSavedVisibleCollection[id]}
            />
          ))}
        </Content>
      </Wrapper>
    </StickyContainer>
  )
}

View.propTypes = {
  project: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  saveEnabled: PropTypes.bool,
  addableAssessmentTemplates: PropTypes.array.isRequired,
  assessmentTemplatesEditable: PropTypes.bool,
  autoSavedVisibleCollection: PropTypes.object.isRequired,
  projectVendorsProps: PropTypes.object.isRequired,
  eventSubscribers: PropTypes.object,
  isFetchingEventSubscribers: PropTypes.bool,
  onSaveClose: PropTypes.func.isRequired,
  onEmailInvitationPreview: PropTypes.func.isRequired,
  onDetailsFormSubmit: PropTypes.func.isRequired,
  onDetailsFormValidStateChange: PropTypes.func.isRequired,
  onEmailInvitationsFormSubmit: PropTypes.func.isRequired,
  onEmailInvitationsFormValidStateChange: PropTypes.func.isRequired,
  onAssessmentTemplateAdd: PropTypes.func.isRequired,
  onAssessmentTemplateDelete: PropTypes.func.isRequired,
  onAddUsersToNotify: PropTypes.func.isRequired,
  onEditEventSubscriber: PropTypes.func.isRequired,
  onRemoveEventSubscriber: PropTypes.func.isRequired,
  onNotificationsFormSubmit: PropTypes.func.isRequired,
}

export default View
