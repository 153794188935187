import React from 'react'
import PropTypes from 'prop-types'
import PrintButton from 'components-v2/molecules/PrintButton'
import PageHeader from 'components-v2/organisms/PageHeader'
import GradeLabel from 'components/shared/GradeLabel'
import { AssessmentElementTypes, AssessmentStatusTypes } from 'constants/index'
import ScoreCell from './ScoreCell'
import { TemplateLabel, TemplateName, StyledTable, classes } from './styles'

const breadcrumbs = [
  {
    active: true,
    title: 'Reports',
  },
]

const getTableColumns = (assessments) => {
  const columns = [
    {
      id: 'label',
      header: 'Question',
      classes: classes.QUESTION_CELL,
      headerClasses: classes.QUESTION_COLUMN_HEADER,
    },
  ]
  assessments.forEach((assessment, index) => {
    const rating =
      assessment.status === AssessmentStatusTypes.REVIEWED &&
      assessment.risk_rating != null
        ? `${assessment.risk_rating}%`
        : 'N/A'
    columns.push({
      id: assessment.id,
      header: () => (
        <span>
          {assessment.vendor.name} ({rating}){' '}
          <GradeLabel grade={assessment.grade} iconOnly />
        </span>
      ),
      cell: (info) => <ScoreCell {...info.row.original.cells[index]} />,
      classes: classes.SCORE_CELL,
      headerClasses: classes.SCORE_COLUMN_HEADER,
    })
  })
  return columns
}

const rowClasses = (row) =>
  row.original.type === AssessmentElementTypes.SECTION_HEADING
    ? classes.SECTION_HEADING_ROW
    : classes.QUESTION_ROW

const View = ({
  assessments,
  template,
  needPagination,
  tableData,
  loading,
  page,
  pageCount,
  onPaginationChange,
}) => {
  const tableColumns = React.useMemo(
    () => getTableColumns(assessments),
    [assessments],
  )
  return (
    <div>
      <PageHeader title="Answer Comparison Report" breadcrumbs={breadcrumbs}>
        <PrintButton color="primary" />
      </PageHeader>
      <TemplateLabel>Template</TemplateLabel>
      <TemplateName>{template.name}</TemplateName>
      <StyledTable
        loading={loading}
        data={tableData}
        columns={tableColumns}
        totalSize={pageCount}
        pagination={{ page, sizePerPage: 1 }}
        hidePageSizes
        enablePagination={needPagination}
        onPaginationChange={onPaginationChange}
        manualPagination
        enableSorting={false}
        noDataText="No data found."
        hover={false}
        noPrintRowGroup
        scoreColumnsLength={assessments.length}
        rowClasses={rowClasses}
      />
    </div>
  )
}

View.propTypes = {
  assessments: PropTypes.array.isRequired,
  template: PropTypes.object.isRequired,
  needPagination: PropTypes.bool,
  tableData: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  pageCount: PropTypes.number,
  page: PropTypes.number,
  onPaginationChange: PropTypes.func.isRequired,
}

export default View
