import styled from 'styled-components'
import { sizes } from 'styles'

export const StickyContent = styled.div`
  padding-top: 1px;
  background: #fff;
  ${(props) =>
    props.isSticky &&
    `
    top: ${sizes.HEADER_HEIGHT}px !important;
    z-index: 1;
    border-bottom: 1px solid #ddd;
  `}
`

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`

export const Table = styled.div`
  border: 1px solid #eee;
  background: #ffffff;

  body.grey-bg & {
    border-color: #d4d4d4;
  }
`

export const Section = styled.div`
  &:not(:first-child) {
    page-break-before: always;
  }

  &[data-empty-section='true'] + &[data-empty-section='true'] {
    page-break-before: initial !important;
  }

  body:not(.include-skipped-questions-on-print)
    &:is([data-empty-section='true'], [data-skipped-section='true'])
    + &:is([data-empty-section='true'], [data-skipped-section='true']) {
    page-break-before: initial !important;
  }

  & > * {
    border-top: 1px solid #ddd;
  }
`

export const SectionSkippedNote = styled.div`
  text-align: center;
  padding: 8px;
  opacity: 0.5;
  display: none;

  body:not(.include-skipped-questions-on-print) & {
    @media print {
      display: block;
    }
  }
`
