import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import uniqueId from 'lodash/uniqueId'
import { FieldArray } from 'formik'
import Icon from 'components-v2/atoms/Icon'
import { BaseField } from 'components-v2/molecules/FormField'
import { useFormField } from 'hooks'
import ContributorFieldGroup from './ContributorFieldGroup'
import { AddButton } from './styles'

const createNewContributor = () => ({
  id: uniqueId('contributor_'),
  name: undefined,
  title: undefined,
  phone: undefined,
  email: undefined,
  isNew: true,
})

function ContributorsFieldGroup({
  name,
  size,
  errorMessage,
  showError,
  onChange,
  ...rest
}) {
  const field = useFormField(name)
  const count = field.value?.length
  const mountedRef = useRef(false)

  // Make sure to have at least one contributor initally
  useEffect(() => {
    if (!mountedRef.current && !count) {
      const newContributor = createNewContributor()
      field.onChange([newContributor])
    }
    mountedRef.current = true
  }, [count, field.onChange])

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <BaseField {...rest} size={size}>
          {field.value?.map((user, index) => (
            <ContributorFieldGroup
              key={user.id}
              name={`${name}.${index}`}
              size={size}
              onDelete={() => arrayHelpers.remove(index)}
            />
          ))}
          <AddButton onClick={() => arrayHelpers.push(createNewContributor())}>
            <Icon icon="fa fa-plus-circle" />
            Add another contributor
          </AddButton>
        </BaseField>
      )}
    />
  )
}

ContributorsFieldGroup.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
  errorMessage: PropTypes.any,
  showError: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

export default ContributorsFieldGroup
