import React from 'react'
import PropTypes from 'prop-types'
import Privva from 'Privva'
import ProgressBar from 'components-v2/molecules/ProgressBar'
import PageHeader from 'components-v2/organisms/PageHeader'
import { withReactQuery } from 'hocs'
import { useAssistQuestionSetStatusFetcher } from 'apis'
import { getPageUrl } from 'utils/url'

function QuestionSetStatusView({ questionSetId, assistOrgName }) {
  useAssistQuestionSetStatusFetcher(
    { id: questionSetId },
    {
      onSuccess: (data) => {
        if (!['new', 'processing'].includes(data.status)) {
          window.location.replace(
            getPageUrl('assistQuestionSet', { id: questionSetId }),
          )
        }
      },
      refetchInterval: 3000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
    },
  )
  const [progress, setProgress] = React.useState(0)

  React.useEffect(() => {
    setInterval(() => {
      setProgress((current) => (current >= 100 ? 0 : current + 1))
    }, 500)
  }, [])

  return (
    <>
      <PageHeader title={`${Privva.Utils.brand.programName} Assist`} />
      <h3>{assistOrgName}</h3>
      <p>Processing Question Set...</p>
      <ProgressBar now={progress} striped active />
    </>
  )
}

QuestionSetStatusView.propTypes = {
  questionSetId: PropTypes.string.isRequired,
  assistOrgName: PropTypes.string.isRequired,
}

export default withReactQuery()(QuestionSetStatusView)
