import styled from 'styled-components'

export const theme = {
  hGutter: 8,
  vGutter: 10,
}

export const Wrapper = styled.div`
  position: relative;
  padding-right: 36px;
  margin-bottom: 24px;
`

export const DeleteButtonWrapper = styled.div`
  position: absolute;
  top: 3px;
  right: 0;
`
