import * as yup from 'yup'
import every from 'lodash/every'
import fromPairs from 'lodash/fromPairs'
import get from 'lodash/get'
import has from 'lodash/has'
import isArray from 'lodash/isArray'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'
import omitBy from 'lodash/omitBy'
import omit from 'lodash/omit'
import FormField from 'components-v2/molecules/FormField'
import { convertStringArrayToOptions } from 'utils/array'
import { formatCurrency } from 'utils/currency'
import {
  defaultGroupKey,
  organizeFieldDefs,
  sortGroups,
} from 'lib/custom_fields'
import { toLocalTimeString } from 'utils/date'
import { format, parseISO } from 'date-fns'
import vendorFieldGroups, {
  VendorOwnFieldNames,
  VendorFieldNameTypes,
} from './constants'
import MultiLineTextDisplay from './FieldRenderers/MultiLineTextDisplay'
import UrlDisplay from './FieldRenderers/UrlDisplay'

export function getVendorFieldValue(vendor, name) {
  const organizationDetail = vendor.organization_detail
  const customFieldData = vendor.custom_field_data
  if (VendorOwnFieldNames.indexOf(name) !== -1) return vendor[name]
  if (has(organizationDetail, name)) return organizationDetail[name]
  if (has(customFieldData, name)) return customFieldData[name]
  return null
}

// similar to _.pick, for when you have several
// fields you want to collect as a group
export function pickVendorFields(vendor, fieldArr) {
  return fromPairs(
    fieldArr.map((val) => [val, getVendorFieldValue(vendor, val)]),
  )
}

export function parseFormValue(fieldName, value) {
  if (isArray(value) && every(value, (val) => has(val, 'value')))
    return map(value, 'value')
  return value
}

export function getVendorFieldGroups({
  vendorUsers = [],
  otherVendors = {},
  clientTags = [],
  globorgEnabled,
  globOrgName,
  onConnectGlobOrg,
  onDisconnectGlobOrg,
  onClickGlobOrgName,
}) {
  return mapValues(vendorFieldGroups, (value) => ({
    ...value,
    fields: map(value.fields, (field) => {
      switch (field.name) {
        case VendorFieldNameTypes.PARENT_ORG:
          return {
            ...field,
            options: map(otherVendors, (org) => ({
              value: org.id,
              label: org.name,
            })),
          }
        case VendorFieldNameTypes.MAIN_CONTACT:
          return {
            ...field,
            options: vendorUsers.map((user) => ({
              value: user.id,
              label: user.name,
            })),
          }
        case VendorFieldNameTypes.VENDOR_TAG_LIST:
          return {
            ...field,
            options: convertStringArrayToOptions(clientTags),
          }
        case VendorFieldNameTypes.GLOBORG_MAPPING:
          if (!globorgEnabled) {
            return undefined
          }
          return {
            ...field,
            globOrgName,
            onConnect: onConnectGlobOrg,
            onDisconnect: onDisconnectGlobOrg,
            onClickName: onClickGlobOrgName,
          }
        default:
          return field
      }
    }).filter((e) => e),
  }))
}

export const getFormFieldValue = (field, vendor) => {
  const value = getVendorFieldValue(vendor, field.name)
  if (field.formFieldValueFormatter) {
    return field.formFieldValueFormatter({ value, field, vendor })
  }
  if (field.type === FormField.types.DATE) {
    return value ? parseISO(value) : value
  }
  return value
}

export const getFormFieldValidation = (field) =>
  field.validation ? field.validation.label(field.label) : undefined

export const getFormFieldProps = (field) =>
  omit(field, [
    'initialValue',
    'validation',
    'valueRenderComponent',
    'formFieldValueFormatter',
    'visibilityGetter',
    'other',
  ])

export const buildCustomFieldConfig = (fieldDef) => {
  // determine what field renderer and control props we need
  const renderType = fieldDef.meta_type || fieldDef.data_type
  const opts = fieldDef.render_opts
  const renderProps = {
    name: fieldDef.key,
    label: fieldDef.name,
    helpMessage: fieldDef.description,
    type: FormField.types.INPUT,
    controlProps: {},
    options: [],
  }
  let max = get(opts, 'validations.value.max')
  let min = get(opts, 'validations.value.min')
  switch (renderType) {
    case 'boolean':
      renderProps.type = opts.use_toggle
        ? FormField.types.TOGGLE
        : FormField.types.CHECKBOX
      renderProps.valueRenderComponent = ({ value }) => {
        if ([null, undefined].includes(value)) {
          return null
        }
        return value ? 'Yes' : 'No'
      }
      break
    case 'string':
      min ||= 0
      max ||= 255
      if (opts.multi) {
        renderProps.type = FormField.types.TEXTAREA
        max ||= 5000
        renderProps.controlProps = { rows: 4 }
        renderProps.valueRenderComponent = MultiLineTextDisplay
      }
      renderProps.validation = yup
        .string()
        .nullable()
        .min(min, `Must not be fewer than ${min} characters`)
        .max(max, `Must not be more than ${max} characters`)
      break
    case 'integer':
    case 'float':
    case 'money':
      renderProps.validation = yup
        .number()
        .nullable()
        .typeError('Must be a number')
      if (renderType === 'integer') {
        renderProps.validation =
          renderProps.validation.integer('Must be an integer')
      }
      if (min) {
        renderProps.validation = renderProps.validation.min(
          min,
          `Must be no less than ${min}`,
        )
      }
      if (max) {
        renderProps.validation = renderProps.validation.max(
          max,
          `Must be no more than ${max}`,
        )
      }
      if (renderType === 'money') {
        renderProps.valueRenderComponent = ({ value }) => formatCurrency(value)
      }
      break
    case 'email':
      renderProps.validation = yup
        .string()
        .nullable()
        .email('Must be a valid email address')
      break
    case 'url':
      renderProps.validation = yup
        .string()
        .nullable()
        .url('Must be a valid URL')
      renderProps.valueRenderComponent = UrlDisplay
      break
    case 'select':
      renderProps.type = FormField.types.SEARCHABLE_SELECT
      if (opts.multi) {
        renderProps.controlProps = { isMulti: true }
      } else {
        renderProps.formFieldValueFormatter = ({ value }) =>
          isArray(value) ? value[0] : value
        renderProps.controlProps = { isClearable: true }
      }
      if (opts.choices) {
        renderProps.options = convertStringArrayToOptions(opts.choices)
      } else {
        renderProps.options = []
        renderProps.disabled = true
      }
      break
    case 'date':
      renderProps.type = FormField.types.DATE
      renderProps.valueRenderComponent = ({ value }) => {
        const convertDate = (dateString) => {
          const date = parseISO(dateString)
          return format(date, 'MMMM d, yyyy')
        }
        return value && convertDate(value)
      }
      renderProps.controlProps = {
        dateFormat: 'MM/dd/yyyy',
        showTimeSelect: false,
        todayButton: 'Today',
        // minDate: opts.allow_past ? null : new Date(),
        style: { width: 240 },
        fullWidth: true,
      }
      if (!opts.allow_past) renderProps.controlProps.minDate = new Date()
      break
    case 'datetime':
      renderProps.type = FormField.types.DATE
      renderProps.valueRenderComponent = ({ value }) => toLocalTimeString(value)
      renderProps.controlProps = {
        dateFormat: 'MM/dd/yyyy h:mm aa',
        showTimeSelect: true,
        todayButton: 'Today',
        timeIntervals: 15,
        // minDateTime: opts.allow_past ? null : new Date(),
        style: { width: 240 },
        fullWidth: true,
      }
      if (!opts.allow_past) renderProps.controlProps.minDateTime = new Date()
      break
    default:
  }
  return renderProps
}

export function buildCustomFieldsCard(fieldDefs, fieldGroups) {
  // returns sorted group list
  const groupList = sortGroups(fieldGroups)
  // returns default group key
  const defaultKey = defaultGroupKey(fieldGroups)
  // returns sorted field list per group
  const defsList = organizeFieldDefs(fieldDefs, defaultKey, groupList)
  const visibleGroups = omitBy(groupList, (g) => g.hidden)

  return map(visibleGroups, (group) => ({
    key: group.key,
    label: group.name,
    fields: map(get(defsList, group.key), buildCustomFieldConfig),
  }))
}
