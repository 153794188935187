import React, { useCallback } from 'react'
import forEach from 'lodash/forEach'
import map from 'lodash/map'
import { useModal, useConfirm, useCurrent } from 'hooks'
import { useMultiUpdateClientAssessmentTemplateElements } from 'apis'
import BulkEditQuestionFormModal from './BulkEditQuestionFormModal'
import useDefaultAsyncActionHandler from './useDefaultAsyncActionHandler'
import {
  getElementById,
  sortElements,
  checkNewElementDataForTrouble,
} from './utils'
import { ProblemList } from './styles'

// Callback when click "Bulk Edit" button
export default function useEditQuestions({
  assessmentTemplate,
  selectedQuestionIds,
  setSelectedQuestionIds,
  setSelectedSectionId,
}) {
  const { mutateAsync: multiUpdateClientAssessmentTemplateElements } =
    useMultiUpdateClientAssessmentTemplateElements()
  const assessmentTemplateRef = useCurrent(assessmentTemplate)
  const selectedQuestionIdsRef = useCurrent(selectedQuestionIds)
  const [openBulkEditQuestionFormModal, closeBulkEditQuestionFormModal] =
    useModal(BulkEditQuestionFormModal)
  const openConfirm = useConfirm()
  const handleAsyncAction = useDefaultAsyncActionHandler()

  return useCallback(() => {
    const elementIds = selectedQuestionIdsRef.current
    openBulkEditQuestionFormModal({
      elementIds,
      elements: sortElements(assessmentTemplateRef.current.elements),
      onSubmit: (formData, { setSubmitting }) => {
        // We disable user interaction using loader, so we can just set submitting to false immediately
        setSubmitting(false)

        const elementsObj = {}
        let problemsFound = []
        forEach(elementIds, (elementId) => {
          elementsObj[elementId] = formData

          const element = getElementById(
            assessmentTemplateRef.current.elements,
            elementId,
          )
          problemsFound = problemsFound.concat(
            checkNewElementDataForTrouble(formData, element),
          )
        })

        const callUpdateElements = (elementsObject) => {
          const promise = multiUpdateClientAssessmentTemplateElements({
            id: assessmentTemplateRef.current.id,
            data: { elements: elementsObject },
          }).then(() => {
            setSelectedQuestionIds([])
            setSelectedSectionId(null)
            closeBulkEditQuestionFormModal()
          })
          handleAsyncAction(
            promise,
            'The questions have been successfully updated.',
          )
        }

        if (problemsFound.length > 0) {
          const introText =
            "You've made changes that may result in invalid question automations, which will be removed if you proceed:"
          openConfirm({
            title: 'Are you sure?',
            body: (
              <>
                {introText}
                <br />
                <ProblemList>
                  {map(problemsFound, (p, idx) => (
                    <li key={idx}>{p}</li>
                  ))}
                </ProblemList>
              </>
            ),
            onConfirm: () => callUpdateElements(elementsObj),
          })
        } else {
          callUpdateElements(elementsObj)
        }
      },
    })
  }, [
    setSelectedQuestionIds,
    setSelectedSectionId,
    handleAsyncAction,
    openBulkEditQuestionFormModal,
    closeBulkEditQuestionFormModal,
    openConfirm,
  ])
}
