import styled from 'styled-components'
import Button from 'components-v2/atoms/Button'
import { colors } from 'styles'

export const PreviewsWrapper = styled.div`
  .dzu-previewContainer {
    z-index: initial;
    &:last-child {
      border-bottom: none;
    }
  }
`

export const Dropzone = styled.div`
  display: inline-block;
  &.dzu-dropzoneDisabled {
    opacity: 1;
    cursor: not-allowed;
  }
`

export const StyledButton = styled(Button)`
  ${({ $active }) =>
    $active &&
    `
    background-color: #DEEBFF;
    border-color: #2484FF;
  `}

  ${({ $reject }) =>
    $reject &&
    `
    background-color: #DAA;
    border-color: ${colors.DANGER};
  `}
`
