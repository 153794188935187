import styled from 'styled-components'
import { fonts } from 'styles'

export const OrgHeader = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0 50px;
`

export const OrgLogo = styled.img`
  width: 70px;
  height: 70px;
  border: 1px solid #000;
  border-radius: 100%;
  object-fit: contain;
  margin-right: 20px;
`

export const OrgName = styled.h3`
  font-family: ${fonts.PRIMARY};
  font-weight: normal;
  font-size: 28px;
  color: #000;
  margin: 0 0 4px;
`
