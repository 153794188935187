import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Privva from 'Privva'
import Radio from 'components-v2/atoms/Radio'
import Button from 'components-v2/atoms/Button'
import IconButton from 'components-v2/atoms/IconButton'
import ListGroup from 'components-v2/molecules/ListGroup'
import PageHeader from 'components-v2/organisms/PageHeader'
import { Row, Col } from 'components-v2/organisms/Layout'
import { withReactQuery } from 'hocs'
import { useAssistQuestionSetsFetcher } from 'apis'
import { getPageUrl } from 'utils/url'
import { pluralize } from 'utils/string'
import { toLocalTimeString } from 'utils/date'
import useUploadQuestionSet from '../shared/useUploadQuestionSet'
import useDeleteQuestionSet from '../shared/useDeleteQuestionSet'
import QuestionSetFormatTooltip from '../shared/QuestionSetFormatTooltip'
import CardWithTitle from '../shared/CardWithTitle'
import {
  Section,
  StyledListGroupItem as ListGroupItem,
  ListGroupItemContent,
  Footer,
} from './styles'

function QuestionSetsView({ questionSets: questionSetsProp, assistOrgName }) {
  const { data: questionSets } = useAssistQuestionSetsFetcher(undefined, {
    initialData: questionSetsProp,
    refetchOnMount: false,
  })
  const uploadQuestionSet = useUploadQuestionSet()
  const deleteQuestionSet = useDeleteQuestionSet()
  const [selected, setSelected] = useState()
  return (
    <>
      <PageHeader title={`${Privva.Utils.brand.programName} Assist`} />
      <h3>
        {assistOrgName}&nbsp;
        <IconButton
          href={getPageUrl('assistOrganizations')}
          icon="fa fa-pencil"
          color="primary"
          className="h5"
        />
      </h3>
      <Section>
        <Row>
          <Col md={8}>
            <h4>1. Upload a Question Set</h4>
            {questionSets.length > 0 ? (
              <p>
                <p>
                  To get started, choose an existing Question Set or upload a
                  new one. A Question Set is a spreadsheet of new questions that
                  you&apos;d like me to help you answer.
                </p>
              </p>
            ) : (
              <p>
                To get started, upload a Question Set. A Question Set is a
                spreadsheet of new questions that you&apos;d like me to help you
                answer.
              </p>
            )}
          </Col>
        </Row>
      </Section>
      <Section>
        <Row>
          <Col md={5}>
            <CardWithTitle title="Question Sets">
              <ListGroup>
                {questionSets.map((qs) => (
                  <ListGroupItem key={qs.id}>
                    <Radio
                      checked={selected === qs.id}
                      onChange={() => setSelected(qs.id)}
                    />
                    <ListGroupItemContent>
                      <b>{qs.name}</b>
                      <div className="small">
                        {pluralize(qs.questions_count, 'question')}{' '}
                        {toLocalTimeString(qs.created_at, 'MMMM yyyy')}
                      </div>
                    </ListGroupItemContent>
                    <IconButton
                      icon="fa fa-times-circle"
                      color="primary"
                      onClick={() => deleteQuestionSet(qs.id)}
                    />
                  </ListGroupItem>
                ))}
              </ListGroup>
            </CardWithTitle>
          </Col>
          <Col md={1}></Col>
          <Col md={6}>
            <Button color="primary" onClick={uploadQuestionSet}>
              <i className="fa fa-table" />
              Upload Spreadsheet
            </Button>
            <br />
            <QuestionSetFormatTooltip />
          </Col>
        </Row>
      </Section>
      <Footer>
        <Button href={getPageUrl('assist')}>{'<< Previous'}</Button>
        <Button
          color="primary"
          href={
            selected
              ? getPageUrl('assistQuestionSetAnswerSets', { id: selected })
              : undefined
          }
          disabled={!selected}
        >
          {'Next >>'}
        </Button>
      </Footer>
    </>
  )
}

QuestionSetsView.propTypes = {
  questionSets: PropTypes.arrayOf(PropTypes.object).isRequired,
  assistOrgName: PropTypes.string.isRequired,
}

export default withReactQuery()(QuestionSetsView)
