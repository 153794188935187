import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Top, Title, AutoSavedMessage } from './styles'

const FADE_TIMEOUT = 500

const SectionCard = ({
  title,
  topRight,
  autoSavedVisible,
  children,
  ...rest
}) => (
  <Wrapper {...rest}>
    <Top>
      <Title>{title}</Title>
      {topRight}
    </Top>
    {children}
    <AutoSavedMessage in={autoSavedVisible} timeout={FADE_TIMEOUT}>
      This form has auto-saved
    </AutoSavedMessage>
  </Wrapper>
)

SectionCard.propTypes = {
  title: PropTypes.string.isRequired,
  topRight: PropTypes.any,
  autoSavedVisible: PropTypes.bool,
  children: PropTypes.element,
}

export default SectionCard
