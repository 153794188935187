import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components-v2/atoms/Button'
import ButtonGroup from 'components-v2/molecules/ButtonGroup'
import { toLocalTimeString } from 'utils/date'
import {
  ListItemWrapper,
  ListItemUserAvatar,
  ListItemMain,
  ListItemTitle,
  ListItemPendingLabel,
  ListItemEmail,
  ListItemDate,
} from './styles'

const PermittedAccessItem = ({ item, onAcceptRequest, onDeclineRequest }) => (
  <ListItemWrapper>
    <ListItemUserAvatar name={item.recipient_name} />
    <ListItemMain>
      <ListItemTitle>
        {item.recipient_name}
        {item.status === 'requested' && (
          <ListItemPendingLabel>Pending</ListItemPendingLabel>
        )}
      </ListItemTitle>
      <ListItemEmail>{item.recipient_type}</ListItemEmail>
    </ListItemMain>
    <div>
      {item.status === 'active' ? (
        <ListItemDate>
          Date Approved: {toLocalTimeString(item.status_change_at, 'MM/d/yy')}
        </ListItemDate>
      ) : (
        <ButtonGroup>
          <Button color="default" onClick={() => onAcceptRequest(item)}>
            Accept
          </Button>
          <Button color="default" onClick={() => onDeclineRequest(item)}>
            Decline
          </Button>
        </ButtonGroup>
      )}
    </div>
  </ListItemWrapper>
)

PermittedAccessItem.propTypes = {
  item: PropTypes.object.isRequired,
  onAcceptRequest: PropTypes.func.isRequired,
  onDeclineRequest: PropTypes.func.isRequired,
}

export default PermittedAccessItem
