import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import last from 'lodash/last'
import FormField from 'components-v2/molecules/FormField'
import CredentialsField from '../CredentialsField'

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .label('This')
    .nullable()
    .max(64, '64 character maximum length')
    .matches(/^[^<>]+$/, 'valid characters only'),
  email: yup.string().label('This').nullable().email().required(),
  aws_key: yup.string().label('This').nullable().required(),
  credentials: yup
    .string()
    .nullable()
    .test('is-valid', 'This is a required field', function callback(value) {
      return value || (this.parent.credentials_ending_in && value === null)
    }),
  aws_region: yup.string().label('This').nullable().required(),
})

const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi

const splitEmailAndName = (str) => {
  if (!str) {
    return {}
  }
  const emails = str.match(emailRegex)
  const email = last(emails)
  if (!email) {
    return { name: str }
  }
  const name = str.replace(email, '').replace('<', '').replace('>', '').trim()
  return { name, email }
}

const toFormValues = (partner) => ({
  ...splitEmailAndName(partner.aws_from_email),
  aws_key: partner.aws_key,
  aws_region: partner.aws_region,
  credentials: null,
  credentials_ending_in: partner.credentials_ending_in,
  enabled: partner.enabled,
})

const mergeEmailAndName = (name, email) => (name ? `${name} <${email}>` : email)

const fromFormValues = (formValues) => {
  const obj = {
    aws_from_email: mergeEmailAndName(formValues.name, formValues.email),
    aws_key: formValues.aws_key,
    aws_region: formValues.aws_region,
    enabled: formValues.enabled,
  }
  // Only if changed, send to server
  if (formValues.credentials !== null) {
    obj.credentials = formValues.credentials
  }
  return obj
}

const regionOptions = ['us-east-1', 'us-east-2', 'us-west-1', 'us-west-2'].map(
  (e) => ({ value: e, label: e }),
)

const AwsSesFields = ({ data }) => (
  <>
    <FormField name="name" label="From Name" />
    <FormField name="email" label="From Address" />
    <FormField name="aws_key" label="Access Key Id" />
    <FormField
      component={CredentialsField}
      name="credentials"
      label="Secret"
      ending={data.credentials_ending_in}
    />
    <FormField
      name="aws_region"
      label="Region"
      type={FormField.types.SELECT}
      options={regionOptions}
    />
  </>
)

AwsSesFields.propTypes = {
  data: PropTypes.object.isRequired,
}

AwsSesFields.validationSchema = validationSchema
AwsSesFields.toFormValues = toFormValues
AwsSesFields.fromFormValues = fromFormValues

export default AwsSesFields
