import React from 'react'
import PropTypes from 'prop-types'
import defaults from 'lodash/defaults'
import MultiCheckbox from '../MultiCheckbox'
import Checkbox from '../../atoms/Checkbox'
import {
  Wrapper,
  MatchWrapper,
  StyledRadio as Radio,
  OtherWrapper,
} from './styles'

const MultiSelectFilter = ({
  value: valueProp,
  defaultValue,
  options,
  onChange,
  selectAllVisible,
  matchVisible,
  otherVisible,
  otherLabel,
  RenderCheckbox,
  getOptionValue,
  getOptionLabel,
  getOptionColor,
  ...rest
}) => {
  const value = defaults({}, valueProp, defaultValue)
  const handleChange = (key, val) =>
    onChange({
      ...value,
      [key]: val,
    })
  return (
    <Wrapper {...rest}>
      {matchVisible && (
        <MatchWrapper>
          Match:
          <Radio
            inline
            checked={value.anyMatch}
            onChange={() => handleChange('anyMatch', true)}
            label="Any"
          />
          <Radio
            inline
            checked={!value.anyMatch}
            onChange={() => handleChange('anyMatch', false)}
            label="All"
          />
        </MatchWrapper>
      )}
      {otherVisible && (
        <OtherWrapper>
          <RenderCheckbox
            label={otherLabel}
            checked={value.other}
            onChange={(e) => handleChange('other', e.target.checked)}
          />
        </OtherWrapper>
      )}
      <MultiCheckbox
        value={value.selected}
        options={options}
        onChange={(val) => handleChange('selected', val)}
        selectAllVisible={selectAllVisible}
        RenderCheckbox={RenderCheckbox}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        getOptionColor={getOptionColor}
      />
    </Wrapper>
  )
}

MultiSelectFilter.propTypes = {
  value: PropTypes.shape({
    selected: PropTypes.array,
    other: PropTypes.bool,
    anyMatch: PropTypes.bool,
  }),
  defaultValue: PropTypes.shape({
    selected: PropTypes.array,
    other: PropTypes.bool,
    anyMatch: PropTypes.bool,
  }),
  options: PropTypes.array.isRequired,
  selectAllVisible: PropTypes.bool,
  matchVisible: PropTypes.bool,
  otherVisible: PropTypes.bool,
  otherLabel: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  RenderCheckbox: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionColor: PropTypes.func,
}

MultiSelectFilter.defaultProps = {
  value: {},
  defaultValue: {
    selected: [],
    other: false,
    anyMatch: true,
  },
  selectAllVisible: false,
  matchVisible: false,
  otherVisible: false,
  otherLabel: 'Other',
  RenderCheckbox: Checkbox,
}

export default MultiSelectFilter
