import styled from 'styled-components'

export const RiskRating = styled.span`
  font-weight: bold;
  color: ${(props) => props.color};
`

export const StatusWrapper = styled.div`
  white-space: nowrap;
`
