import { sizes } from 'styles'
import * as dom from 'utils/dom'
import { getResponseScoreOptionsWithLabel } from 'lib/assessment'
import { AssessmentStatusTypes } from 'constants/index'
import {
  ASSESSMENT_TABLE_ID_PREFIX,
  STICKY_HEADER_HEIGHT,
  ElementFilterOptions,
} from './constants'

export const scrollTo = (domId) =>
  dom.scrollTo(domId, sizes.HEADER_HEIGHT + STICKY_HEADER_HEIGHT)

export const scrollToTable = (assessmentId) => {
  scrollTo(ASSESSMENT_TABLE_ID_PREFIX + assessmentId)
}

export const isCompleted = (status) =>
  [
    AssessmentStatusTypes.REVIEWED,
    AssessmentStatusTypes.EXPIRED,
    AssessmentStatusTypes.CANCELED,
  ].includes(status)

export const getElementFilterOptions = (ratingLabelSet) => ({
  ...ElementFilterOptions,
  SCORED: {
    ...ElementFilterOptions.SCORED,
    options: getResponseScoreOptionsWithLabel(ratingLabelSet),
  },
})

export const getAutoScoreOptions = (ratingLabelSet) =>
  getResponseScoreOptionsWithLabel(
    ratingLabelSet,
    (label) => `Auto Score: ${label}`,
  )
