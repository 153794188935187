import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import * as yup from 'yup'
import FormField from 'components-v2/molecules/FormField'

const TemplateFilterFormFields = ({ assessmentTemplates }) => {
  const formik = useFormikContext()
  const templatesOptions = useMemo(
    () =>
      assessmentTemplates?.map((e) => ({
        value: e.id,
        label: e.name,
      })),
    [assessmentTemplates],
  )
  return (
    assessmentTemplates?.length > 1 && (
      <>
        <FormField
          type={FormField.types.TOGGLE}
          name="templatesFilter"
          label="Specify Templates"
          horizontal
          preserveHelpTextSpace={!formik.values.templatesFilter}
        />
        {formik.values.templatesFilter && (
          <FormField
            name="templates"
            type={FormField.types.MULTI_CHECKBOX}
            options={templatesOptions}
            horizontal
          />
        )}
      </>
    )
  )
}

TemplateFilterFormFields.propTypes = {
  assessmentTemplates: PropTypes.array,
}

export default TemplateFilterFormFields

export const validationSchema = yup.array().when('templatesFilter', {
  is: true,
  then: yup.array().min(1, 'Please select at least 1 template'),
})
