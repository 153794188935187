import { colors } from 'styles'

export const CategoryTypes = {
  SUMMARY: 'summary',
  ORGANIZATION_COMPARISONS: 'organizationComparisons',
  REMEDIATION_MANAGEMENT: 'remediationManagement',
  DATA_EXPORTS: 'dataExports',
  PROGRESS_TRACKING: 'progressTracking',
}

export const Categories = {
  [CategoryTypes.SUMMARY]: {
    label: 'Summary Reports',
    color: colors.BLUE,
    icon: 'fa fa-list-alt',
  },
  [CategoryTypes.ORGANIZATION_COMPARISONS]: {
    label: 'Organization Comparisons',
    color: colors.GREEN,
    icon: 'fa fa-sitemap',
  },
  [CategoryTypes.REMEDIATION_MANAGEMENT]: {
    label: 'Remediation Management',
    color: colors.YELLOW,
    icon: 'fa fa-wrench',
  },
  [CategoryTypes.DATA_EXPORTS]: {
    label: 'Data Exports',
    color: colors.MED_GREY,
    icon: 'fa fa-sign-out',
  },
  [CategoryTypes.PROGRESS_TRACKING]: {
    label: 'Progress and Tracking',
    color: colors.RED,
    icon: 'fa fa-bar-chart',
  },
}
