import ahoy from 'ahoy.js'
import * as StackTrace from 'stacktrace-js'

export const trackError = (err, extra = {}) => {
  const path =
    window.location && window.location.pathname
      ? window.location.pathname
      : '<unknown>'
  StackTrace.fromError(err)
    .then((stackArr) => {
      ahoy.track('Browser Error', {
        _path: path,
        message: err.message || err,
        callstack: stackArr,
        ...extra,
      })
    })
    .catch((e) => {
      console.error('Error getting StackTrace', e)
      try {
        ahoy.track('Browser Error', {
          _path: path,
          message: err.message || err,
          callstack: err.stack,
          ...extra,
        })
      } catch (error) {
        console.error('Error logging error', error)
      }
    })
}
