import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import values from 'lodash/values'
import Popover from 'components-v2/molecules/Popover'
import { useUpdateEffect } from 'hooks'
import Form, { dataTypes } from './Form'
import {
  Wrapper,
  ToggleButton,
  CommentIcon,
  PopoverPanelContent,
} from './styles'

const modeTypes = {
  INLINE: 'inline',
  POPUP: 'popup',
}

const Editable = ({
  dataType,
  mode,
  value,
  placeholder,
  disabled,
  emptyValueText,
  title,
  includeIcon,
  selectTextOnClick,
  required,
  icon: Icon,
  inputProps,
  onSubmit,
  ...rest
}) => {
  const [editable, setEditable] = useState(false)
  const toggleButtonRef = useRef()

  // After closing the form, restore focus to the toggle button
  useUpdateEffect(() => {
    if (!editable) {
      toggleButtonRef.current?.focus()
    }
  }, [editable])

  const renderEditForm = (close) => (
    <Form
      dataType={dataType}
      value={value}
      placeholder={placeholder}
      selectTextOnClick={selectTextOnClick}
      required={required}
      inputProps={inputProps}
      onSubmit={onSubmit}
      close={close}
    />
  )

  return (
    <Wrapper {...rest} isEmpty={!value}>
      {includeIcon && (
        <>
          <Icon />
          &nbsp;
        </>
      )}
      {mode === modeTypes.INLINE ? (
        <>
          {!editable && (
            <ToggleButton
              className={value ? 'no-lang' : ''}
              isEmpty={!value}
              multiLine={dataType === dataTypes.TEXTAREA}
              disabled={disabled}
              onClick={() => setEditable(true)}
              ref={toggleButtonRef}
            >
              {value || emptyValueText}
            </ToggleButton>
          )}
          {editable && renderEditForm(() => setEditable(false))}
        </>
      ) : (
        <Popover>
          <Popover.Button
            as={ToggleButton}
            isEmpty={!value}
            multiLine={dataType === dataTypes.TEXTAREA}
            disabled={disabled}
            className={value ? 'no-lang' : ''}
          >
            {value || emptyValueText}
          </Popover.Button>
          <Popover.Panel title={title} arrow className="dyn-lang">
            {({ close }) => (
              <PopoverPanelContent>{renderEditForm(close)}</PopoverPanelContent>
            )}
          </Popover.Panel>
        </Popover>
      )}
    </Wrapper>
  )
}

Editable.propTypes = {
  dataType: PropTypes.oneOf(values(dataTypes)),
  mode: PropTypes.oneOf(values(modeTypes)),
  value: PropTypes.string,
  placeholder: PropTypes.string,
  emptyValueText: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  selectTextOnClick: PropTypes.bool,
  includeIcon: PropTypes.bool,
  icon: PropTypes.any,
  required: PropTypes.bool,
  inputProps: PropTypes.object,
  onSubmit: PropTypes.func,
}

Editable.defaultProps = {
  dataType: dataTypes.TEXTAREA,
  mode: modeTypes.POPUP,
  value: undefined,
  placeholder: 'Enter text',
  emptyValueText: 'empty',
  disabled: false,
  title: undefined,
  selectTextOnClick: false,
  includeIcon: false,
  icon: CommentIcon,
  required: false,
  inputProps: {},
  onSubmit: () => {},
}

Editable.dataTypes = dataTypes
Editable.modeTypes = modeTypes

export default Editable
