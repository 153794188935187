import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import Button from 'components-v2/atoms/Button'
import Input from 'components-v2/atoms/Input'
import IconButton from 'components-v2/atoms/IconButton'
import Alert from 'components-v2/molecules/Alert'
import Editable from 'components-v2/molecules/Editable'
import Table from 'components-v2/molecules/Table'
import { useGlobalLoader, useConfirm } from 'hooks'
import NotificationManager from 'lib/notifications'
import { Footer } from './styles'

const getColumns = (onEdit, onDelete) => [
  {
    id: 'name',
    header: 'Name',
    cell: (props) => (
      <Editable
        dataType="text"
        mode="inline"
        value={props.getValue()}
        onSubmit={(value) => onEdit(props.row.original.id, value)}
        emptyValueText=""
      />
    ),
  },
  {
    id: 'df',
    header: '',
    cell: (props) => (
      <IconButton
        icon="fa fa-times-circle"
        className="pull-right"
        onClick={() => onDelete(props.row.original)}
      />
    ),
  },
]

const EditableItemListTable = ({
  data,
  isLoading,
  deleteConfirmText,
  updateConfirmText,
  onCreate,
  onUpdate,
  onDelete,
}) => {
  const openConfirm = useConfirm()
  const [showLoader, hideLoader] = useGlobalLoader()
  const [newItemText, setNewItemText] = useState('')
  const [error, setError] = useState('')

  const handleNewItemChange = useCallback((e) => {
    setNewItemText(e.target.value)
  }, [])

  const handleAdd = useCallback(async () => {
    const name = newItemText.trim()
    if (name.length <= 0) {
      setNewItemText(name)
      return
    }

    const loaderId = showLoader()
    setError('')
    try {
      await onCreate(name)
      setNewItemText('')
    } catch {
      NotificationManager.error()
    }
    hideLoader(loaderId)
  }, [newItemText, onCreate, showLoader, hideLoader])

  const handleEdit = useCallback(
    async (id, name) => {
      const confirmed = await openConfirm(
        {
          title: 'Updating List Item',
          body: `Are you sure you wish to update this item to '${name}'? ${updateConfirmText}`,
        },
        true,
      )
      if (confirmed) {
        const loaderId = showLoader()
        setError('')
        try {
          await onUpdate(id, name)
        } catch (err) {
          console.error(err)
          if (err.response.status === 422) {
            setError(err.response.data.error || 'Invalid Item')
          } else {
            NotificationManager.error(
              'There was an error updating the item. Please check your connection and try again later.',
            )
          }
        }
        hideLoader(loaderId)
      }
    },
    [openConfirm, updateConfirmText, onUpdate, showLoader, hideLoader],
  )

  const handleDelete = useCallback(
    async (item) => {
      const confirmed = await openConfirm(
        {
          title: 'Deleting List Item',
          body: `Are you sure you wish to delete the item '${item.name}'? ${deleteConfirmText}`,
        },
        true,
      )
      if (confirmed) {
        const loaderId = showLoader()
        setError('')
        try {
          await onDelete(item.id)
        } catch {
          NotificationManager.error()
        }
        hideLoader(loaderId)
      }
    },
    [openConfirm, deleteConfirmText, onDelete, showLoader, hideLoader],
  )

  const handleDismissError = useCallback(() => {
    setError('')
  }, [])

  const columns = useMemo(
    () => getColumns(handleEdit, handleDelete),
    [handleEdit, handleDelete],
  )

  return (
    <div>
      {error && (
        <Alert color="danger" onDismiss={handleDismissError}>
          {error}
        </Alert>
      )}
      <Table
        loading={isLoading}
        data={data ?? []}
        columns={columns}
        enableSorting={false}
        enablePagination={false}
      />
      <Footer>
        <Input
          type="text"
          value={newItemText}
          placeholder="Item Name"
          maxLength={255}
          onChange={handleNewItemChange}
        />
        <Button onClick={handleAdd} disabled={newItemText.length <= 0}>
          <i className="fa fa-plus" />
          Add
        </Button>
      </Footer>
    </div>
  )
}

EditableItemListTable.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  deleteConfirmText: PropTypes.string,
  updateConfirmText: PropTypes.string,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

EditableItemListTable.defaultProps = {
  data: [],
  isLoading: false,
  deleteConfirmText: '',
  updateConfirmText: '',
}

export default EditableItemListTable
