import styled from 'styled-components'
import Icon from 'components-v2/atoms/Icon'
import { RadioGroup } from '@headlessui/react'
import { colors } from 'styles'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const Label = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 420;
  color: #000;
  line-height: 1.5;
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 32px;
`

export const Description = styled.p`
  margin: 0;
  font-size: 12px;
  color: #000;
  line-height: 1.5;
`

export const Radio = styled(RadioGroup)`
  margin-top: 8px;
  display: flex;
  gap: 16px;
  @media (max-width: 550px) {
    flex-direction: column;
  }
`

export const RadioOption = styled(RadioGroup.Option)`
  flex: 1 1 0%;
  padding: 12px;
  cursor: pointer;
  background-color: #fafafa;
  border-radius: 4px;
  border: 1px solid transparent;

  &:has(> .checked) {
    border-color: ${colors.PRIMARY};
  }
`

export const ApprovalOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const ApprovalOptionMain = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const ApprovalOptionTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${colors.DARK_GREY};

  > span {
    font-size: 16px;
    line-height; 16px;
    font-weight: 420;
    color: #000;
  }

  > i {
    width: 16px;
    height: 16px;
    line-height: 16px;
  }
`

export const ApprovalOptionContent = styled.p`
  margin: 0;
  font-size: 12px;
  line-height: 1.5;
  color: #000;
`

export const ApprovalOptionMarker = styled(Icon).attrs((props) => ({
  icon: props.checked ? 'fa fa-check-circle' : 'fa fa-circle-o',
}))`
  font-size: 20px;

  ${(props) => props.checked && `color: ${colors.PRIMARY};`}
`
