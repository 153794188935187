import styled from 'styled-components'
import Icon from 'components-v2/atoms/Icon'
import { colors } from 'styles'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed ${colors.GREY};
  border-radius: 4px;
  color: ${colors.GREY};
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  min-height: 200px;
`

export const PlusIcon = styled(Icon).attrs({ icon: 'fa fa-plus-circle' })`
  font-size: 32px;
  margin-bottom: 10px;
`
