import React from 'react'
import PropTypes from 'prop-types'
import { GloborgIntegrationTypes } from 'constants/index'
import RiskReconOverviewCard from './RiskReconOverviewCard'
import OsanoOverviewCard from './OsanoOverviewCard'
import SpyCloudOverviewCard from './SpyCloudOverviewCard'
import ArgosRiskOverviewCard from './ArgosRiskOverviewCard'

const getComponent = (type) => {
  switch (type) {
    case GloborgIntegrationTypes.ARGOS_RISK:
      return ArgosRiskOverviewCard
    case GloborgIntegrationTypes.RISK_RECON:
      return RiskReconOverviewCard
    case GloborgIntegrationTypes.SPY_CLOUD:
      return SpyCloudOverviewCard
    case GloborgIntegrationTypes.OSANO:
      return OsanoOverviewCard
    default:
      return null
  }
}

const GloborgIntegrationOverviewCard = ({ type, ...rest }) => {
  const Component = getComponent(type)
  return Component ? <Component {...rest} /> : null
}

GloborgIntegrationOverviewCard.propTypes = {
  type: PropTypes.string.isRequired,
}

export default GloborgIntegrationOverviewCard
export { default as BaseOverviewCard } from './BaseOverviewCard'
