import React from 'react'
import { useModal, useCurrent } from 'hooks'
import {
  useCreateClientQuestionAutomation,
  useUpdateClientQuestionAutomation,
  useDeleteClientQuestionAutomation,
} from 'apis'
import QuestionAutomationModal from './QuestionAutomationModal'
import useDefaultAsyncActionHandler from './useDefaultAsyncActionHandler'

export default function useEditQuestionAutomations(assessmentTemplate) {
  const { mutateAsync: createClientQuestionAutomation } =
    useCreateClientQuestionAutomation()
  const { mutateAsync: updateClientQuestionAutomation } =
    useUpdateClientQuestionAutomation()
  const { mutateAsync: deleteClientQuestionAutomation } =
    useDeleteClientQuestionAutomation()
  const assessmentTemplateRef = useCurrent(assessmentTemplate)
  const [openModal] = useModal(QuestionAutomationModal)
  const asyncActionHandler = useDefaultAsyncActionHandler()

  return React.useCallback(
    (element) => {
      openModal({
        element,
        templateId: assessmentTemplateRef.current.id,
        templateStatus: assessmentTemplateRef.current.status,
        ratingLabelSet: assessmentTemplateRef.current.rating_label_set,
        handleAsyncAction: asyncActionHandler,
        createClientQuestionAutomation,
        updateClientQuestionAutomation,
        deleteClientQuestionAutomation,
      })
    },
    [openModal, asyncActionHandler],
  )
}
