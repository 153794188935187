import PropTypes from 'prop-types'
import React from 'react'
import A from 'components-v2/atoms/A'
import DList from 'components-v2/molecules/DList'
import InfoCard from '../InfoCard'
import { Link } from './styles'

const OfficialProfileCard = ({ globalOrg }) => (
  <InfoCard title="Official Profile">
    <DList horizontal>
      <dt>Company logo</dt>
      <dd>
        {globalOrg.logo_url_small && (
          <img src={globalOrg.logo_url_small} alt="" />
        )}
      </dd>
      <dt>Company name</dt>
      <dd>{globalOrg.name}</dd>
      <dt>Website</dt>
      <dd>
        {globalOrg.domain && (
          <A href={`https://${globalOrg.domain}`} target="_blank" underline>
            https://{globalOrg.domain}
          </A>
        )}
      </dd>
      <dt>Industry</dt>
      <dd>{globalOrg.category_industry}</dd>
      {globalOrg.privacy_policy_url && (
        <>
          <dt>Privacy Policy</dt>
          <dd>
            <Link
              href={globalOrg.privacy_policy_url}
              title={globalOrg.privacy_policy_url}
              target="_blank"
            >
              {globalOrg.privacy_policy_url}
            </Link>
          </dd>
        </>
      )}
      {globalOrg.terms_url && (
        <>
          <dt>Terms Of Use</dt>
          <dd>
            <Link
              href={globalOrg.terms_url}
              title={globalOrg.terms_url}
              target="_blank"
            >
              {globalOrg.terms_url}
            </Link>
          </dd>
        </>
      )}
      <dt>Description</dt>
      <dd>{globalOrg.description}</dd>
      {globalOrg.parent_domain && (
        <>
          <dt>Parent company</dt>
          <dd>
            <a href={`https://${globalOrg.parent_domain}`} target="_blank">
              https://{globalOrg.parent_domain}
            </a>
          </dd>
        </>
      )}
    </DList>
  </InfoCard>
)

OfficialProfileCard.propTypes = {
  globalOrg: PropTypes.object.isRequired,
}

export default OfficialProfileCard
