import React, { useCallback } from 'react'
import {
  useClientTagsFetcher,
  useCreateClientTag,
  useUpdateClientTag,
  useDeleteClientTag,
} from 'apis'
import { LabelTypes } from 'constants/index'
import EditableItemListTable from '../EditableItemListTable'

export default function CientTagTable() {
  const { data, isLoading } = useClientTagsFetcher()
  const { mutateAsync: createTag } = useCreateClientTag()
  const { mutateAsync: updateTag } = useUpdateClientTag()
  const { mutateAsync: deleteTag } = useDeleteClientTag()
  const handleCreate = useCallback(
    (name) => createTag({ data: { name } }),
    [createTag],
  )
  const handleUpdate = useCallback(
    (id, name) => updateTag({ id, data: { client_tag: { id, name } } }),
    [updateTag],
  )
  const handleDelete = useCallback((id) => deleteTag({ id }), [deleteTag])
  return (
    <EditableItemListTable
      data={data}
      isLoading={isLoading}
      updateConfirmText={`Changing this label will change it across ${LabelTypes.VENDORS.toLowerCase()}.`}
      deleteConfirmText={`Deleting this label will remove it across ${LabelTypes.VENDORS.toLowerCase()}.`}
      onCreate={handleCreate}
      onUpdate={handleUpdate}
      onDelete={handleDelete}
    />
  )
}
