import { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useClientAssessmentFetcher } from 'apis'

export default function useSetAssessment(assessmentId) {
  const queryClient = useQueryClient()
  return useCallback(
    (updater) => {
      queryClient.setQueriesData(
        {
          predicate: (query) =>
            query.queryKey[0] === useClientAssessmentFetcher.queryKey()[0] &&
            query.queryKey[1].id === assessmentId,
        },
        updater,
      )
    },
    [queryClient, assessmentId],
  )
}
