import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import filter from 'lodash/filter'
import uniqueId from 'lodash/uniqueId'
import { Times } from '@styled-icons/fa-solid/Times'
import { Plus } from '@styled-icons/fa-solid/Plus'
import { Bars } from '@styled-icons/fa-solid/Bars'
import Icon from 'components-v2/atoms/Icon'
import Button from 'components-v2/atoms/Button'
import Checkbox from 'components-v2/atoms/Checkbox'
import DraggableRow from 'components-v2/molecules/DraggableRow'
import { BaseField } from 'components-v2/molecules/FormField'
import withDragDropContext from 'hocs/withDragDropContext'
import { moveArrayElementTo } from 'utils/array'
import {
  RowWrapper,
  ActionWrapper,
  RoundedButton,
  ContentWrapper,
  StyledTextarea,
  HeadRow,
  CheckboxCol,
} from './styles'

const ChoicesEditorField = ({
  name,
  value: valueProp,
  componentClass,
  onChange,
  presets,
  requiredColumnHeader,
  ...rest
}) => {
  const [value, setValue] = React.useState(valueProp)

  React.useEffect(() => {
    setValue(valueProp)
  }, [valueProp])

  const handleInputChange = (index, event) => {
    const newValue = value?.map((e, i) => {
      if (i === index) {
        return {
          ...e,
          text: event.currentTarget.value,
        }
      }
      return e
    })
    onChange(newValue)
  }

  const handleRequiredClick = (index) => {
    const newValue = value?.map((e, i) => {
      if (i === index) {
        return {
          ...e,
          required: !e.required,
        }
      }
      return e
    })
    onChange(newValue)
  }

  const handleDelete = (index) => {
    const newValue = filter(value, (_, i) => i !== index)
    onChange(newValue)
  }

  const handleAdd = () => {
    const newChoice = { id: uniqueId('temp_') }
    const newValue = value ? [...value, newChoice] : [newChoice]
    onChange(newValue)
  }

  const handleSelectPreset = (preset) => {
    const additionalValue = preset.map((el) => ({
      id: uniqueId('temp_'),
      text: el,
    }))
    const newValue = value ? [...value, ...additionalValue] : additionalValue
    onChange(newValue)
  }

  const handleRowMove = () => {
    onChange(value)
  }

  const handlerRowTemporaryMove = React.useCallback((dragIndex, hoverIndex) => {
    setValue((current) => moveArrayElementTo(current, dragIndex, hoverIndex))
  }, [])

  const handleRowCancelMove = () => {
    setValue(valueProp)
  }

  return (
    <BaseField {...rest}>
      <ContentWrapper>
        {requiredColumnHeader && (
          <HeadRow>
            <CheckboxCol>{requiredColumnHeader}</CheckboxCol>
          </HeadRow>
        )}
        {map(value, (choice, index) => (
          <DraggableRow
            key={choice.id}
            id={choice.id}
            index={index}
            hasDragSource
            onRowMove={handleRowMove}
            onRowTemporaryMove={handlerRowTemporaryMove}
            onRowCancelMove={handleRowCancelMove}
          >
            {({ opacity, ref, dragSourceRef }) => (
              <RowWrapper ref={ref} style={{ opacity }}>
                <StyledTextarea
                  data-test-id={`choices-editor-field-input-${choice.id}`}
                  value={choice.text}
                  rows="1"
                  onChange={(e) => handleInputChange(index, e)}
                />
                <Button onClick={() => handleDelete(index)}>
                  <Icon icon={Times} />
                </Button>
                <div ref={dragSourceRef}>
                  <Button>
                    <Icon icon={Bars} />
                  </Button>
                </div>
                {requiredColumnHeader && (
                  <CheckboxCol>
                    <Checkbox
                      checked={choice.required}
                      onChange={() => handleRequiredClick(index)}
                    />
                  </CheckboxCol>
                )}
              </RowWrapper>
            )}
          </DraggableRow>
        ))}
        <ActionWrapper>
          <Button size="xsmall" onClick={handleAdd}>
            <Icon icon={Plus} /> Choice
          </Button>
          {(!value || value.length === 0) && presets && presets.length > 0 && (
            <>
              <span>OR</span>
              {presets &&
                presets.map((preset) => (
                  <RoundedButton
                    key={preset}
                    size="xsmall"
                    onClick={() => handleSelectPreset(preset)}
                  >
                    {preset.join(' | ')}
                  </RoundedButton>
                ))}
            </>
          )}
        </ActionWrapper>
      </ContentWrapper>
    </BaseField>
  )
}

ChoicesEditorField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.any,
  value: PropTypes.array,
  helpMessage: PropTypes.string,
  componentClass: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  showError: PropTypes.bool,
  errorMessage: PropTypes.string,
  presets: PropTypes.array,
  requiredColumnHeader: PropTypes.object,
}

export default withDragDropContext(ChoicesEditorField)
