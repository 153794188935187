import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import Table from 'components-v2/molecules/Table'
import { SortOrderTypes, AssessmentStatusTypes } from 'constants/index'
import { dateFormatterFactory } from 'lib/table'
import {
  assessmentStatusFormatterFactory,
  getProgressSummary,
} from 'lib/assessment'
import { getPageUrl } from 'utils/url'
import { statusesForProgressBar } from '../consts'
import {
  Container,
  StatusFilters,
  StatusFilter,
  TableWrapper,
  StatusSelectMobile,
  StyledSearchInput,
} from './styles'

const dateFormatter = dateFormatterFactory()
const statusFormatter = assessmentStatusFormatterFactory()
const tableColumns = [
  {
    id: 'assessment_template.name',
    header: 'Name',
    cell: (info) => (
      <div>
        <a
          href={`${getPageUrl('vendorProject', {
            id: info.row.original.project.id,
          })}#tab_${info.row.original.id}`}
          title={info.getValue()}
          className="strong"
        >
          {info.getValue()}
        </a>
        <div>from {info.row.original.assessment_template.client.name}</div>
      </div>
    ),
    width: '50%',
  },
  {
    id: 'progress',
    header: 'Progress',
    cell: (info) => {
      if (!statusesForProgressBar.includes(info.row.original.status)) {
        return null
      }
      const { answeredQuestions, totalQuestions } = getProgressSummary(
        info.getValue(),
      )
      return answeredQuestions === totalQuestions
        ? 'Complete'
        : `${answeredQuestions}/${totalQuestions} complete`
    },
    sortingFn: (rowA, rowB) => {
      const getWeight = (row) => {
        if (!statusesForProgressBar.includes(row.original.status)) {
          return -1
        }
        const summary = getProgressSummary(row.original.progress)
        return summary.totalQuestions
          ? summary.answeredQuestions / summary.totalQuestions
          : 0
      }
      const completenessA = getWeight(rowA)
      const completenessB = getWeight(rowB)
      return completenessA < completenessB
        ? -1
        : completenessB < completenessA
        ? 1
        : 0
    },
    width: '20%',
  },
  {
    id: 'status',
    header: 'Status',
    cell: statusFormatter,
    width: '15%',
  },
  {
    id: 'due_at',
    header: 'Due',
    cell: dateFormatter,
    width: '15%',
  },
  {
    idd: 'templateName',
    header: '',
    cell: (info) => info.row.original.assessment_template.name,
    enableSorting: false,
    hidden: true,
  },
  {
    id: 'clientName',
    header: '',
    cell: (info) => `from ${info.row.original.assessment_template.client.name}`,
    enableSorting: false,
    hidden: true,
  },
]

const ALL = 'all'

const statusFilters = [
  ALL,
  AssessmentStatusTypes.INVITED,
  AssessmentStatusTypes.IN_PROCESS,
  AssessmentStatusTypes.SUBMITTED,
  AssessmentStatusTypes.REVIEWED,
  AssessmentStatusTypes.EXPIRED,
]

const filterAssessments = (assessments, status) =>
  status === ALL ? assessments : assessments.filter((e) => e.status === status)

const ListView = ({ assessments, isFetching }) => {
  const [statusFilter, setStatusFilter] = useState(ALL)
  const [searchText, setSearchText] = useState('')
  const filteredAssessments = useMemo(
    () => filterAssessments(assessments, statusFilter),
    [assessments, statusFilter],
  )
  return (
    <Container className="dyn-lang">
      <StatusFilters>
        {statusFilters.map((status) => (
          <div key={status}>
            <StatusFilter
              onClick={() => setStatusFilter(status)}
              active={statusFilter === status}
            >
              {status.titleize()} (
              {filterAssessments(assessments, status).length})
            </StatusFilter>
          </div>
        ))}
      </StatusFilters>
      <TableWrapper>
        <StatusSelectMobile
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
        >
          {statusFilters.map((status) => (
            <option key={status} value={status}>
              {status.titleize()} (
              {filterAssessments(assessments, status).length})
            </option>
          ))}
        </StatusSelectMobile>
        <StyledSearchInput value={searchText} onChange={setSearchText} />
        <Table
          loading={isFetching}
          data={filteredAssessments}
          columns={tableColumns}
          defaultSorted={{
            sortField: 'due_at',
            sortOrder: SortOrderTypes.DESC,
          }}
          enablePagination={false}
          globalFilter={searchText}
          onGlobalFilterChange={setSearchText}
          noDataText="No assessments found."
          type={Table.types.ALT}
        />
      </TableWrapper>
    </Container>
  )
}

ListView.propTypes = {
  assessments: PropTypes.array,
  isFetching: PropTypes.bool,
}

ListView.defaultProps = {
  assessments: [],
}

export default ListView
