import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import FormField from 'components-v2/molecules/FormField'
import FormDialog from 'components-v2/organisms/FormDialog'

const validationSchema = yup.object().shape({
  bcc_email: yup.string().label('This').nullable().email().required(),
})

const BCCEmailFormModal = ({ bcc_email: bccEmail, ...rest }) => {
  const formConfig = React.useMemo(
    () => ({
      validationSchema,
      initialValues: {
        bcc_email: bccEmail,
      },
    }),
    [bccEmail],
  )
  return (
    <FormDialog {...rest} title="BCC Email" formConfig={formConfig}>
      <FormField name="bcc_email" label="Email Address" />
    </FormDialog>
  )
}

BCCEmailFormModal.propTypes = {
  bcc_email: PropTypes.string,
}

export default BCCEmailFormModal
