import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
`

export const LoaderWrapper = styled.div`
  position: ${(props) => (props.isGlobal ? 'fixed' : 'absolute')};
  display: ${(props) => (props.active ? 'flex' : 'none')};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  background-color: ${(props) => props.backgroundColor};
  z-index: ${(props) => (props.isGlobal ? '99999' : '99')};
`
