import styled from 'styled-components'
import { fonts, mixins } from 'styles'

export const Wrapper = styled.div`
  padding: 44px 0 22px;
`

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`

export const Breadcrumb = styled.ol.attrs({
  role: 'navigation',
  'aria-label': 'breadcrumbs',
})`
  padding: 0;
  margin-bottom: 15px;
  list-style: none;
  ${mixins.smallHeader}
  ${mixins.hidePrint}
`

export const BreadcrumbItem = styled.li`
  display: inline-block;

  & + &:before {
    padding: 0 5px;
    color: #ccc;
    content: '/ ';
  }

  ${({ active }) =>
    active &&
    `
    color: #777777;
  `}
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`

export const Title = styled.h1`
  margin: 0;
  font-family: ${fonts.PRIMARY};
  font-size: 36px;
  font-weight: normal;
  color: #000;
`

export const Sub = styled.div`
  margin-top: 5px;
  font-size: 15px;
  font-weight: 500;
  color: #7a7a7a;
`

export const Right = styled.div`
  margin-left: auto;
`
