import styled from 'styled-components'
import A from 'components-v2/atoms/A'
import Card from 'components-v2/organisms/Card'

export const Wrapper = styled(Card).attrs({ padding: true })`
  padding-bottom: 0;
`

export const Header = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 15px;
`

export const Title = styled.h4`
  margin: 0;
`

export const Link = styled(A).attrs({ underline: true })`
  font-size: 11px;
  font-weight: 900;
  text-transform: uppercase;
  margin-left: 10px;
`
