import styled from 'styled-components'
import { CheckCircle } from '@styled-icons/fa-regular/CheckCircle'
import Icon from 'components-v2/atoms/Icon'
import { colors } from 'styles'

export const Wrapper = styled.div`
  position: relative;
  padding-left: 30px;

  &:before {
    display: block;
    content: '';
    position: absolute;
    left: 5px;
    top: 5px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: #d9d9d9;
  }
`

export const Status = styled.div`
  display: flex;
  align-items: center;
`

export const Bottom = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`

export const CheckCircleIcon = styled(Icon).attrs({
  icon: CheckCircle,
})`
  color: ${colors.GREEN};
`

export const ResendInviteWrapper = styled.div`
  margin-left: 40px;
`
