import React from 'react'
import { useModal } from 'hooks'
import AttachmentsModal from './AttachmentsModal'

export default function useViewQuestionAttachments() {
  const [openModal] = useModal(AttachmentsModal)

  return React.useCallback(
    ({ attachments }) => {
      openModal({
        title: 'Question Attachments',
        attachments,
      })
    },
    [openModal],
  )
}
