import styled from 'styled-components'
import Input from 'components-v2/atoms/Input'
import { colors } from 'styles'

export const Wrapper = styled.div`
  display: flex;
  position: relative;
`

export const SearchIcon = styled.i.attrs({ className: 'fa fa-search' })`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
  color: ${colors.BODY_TEXT};
  font-size: 0.9em;
`

export const StyledInput = styled(Input)`
  flex: 1;
  padding-left: 34px;
  padding-right: 34px;
`

export const ClearIcon = styled.i.attrs({ className: 'fa fa-close' })`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  color: ${colors.GREY};
  cursor: pointer;

  &:hover {
    color: ${colors.MED_GREY};
  }

  ${(props) => props.disabled && `pointer-events: none;`}
`
