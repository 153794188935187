import React from 'react'
import PropTypes from 'prop-types'
import find from 'lodash/find'
import useCreateVendor from 'components/client/shared/useCreateVendor'
import useRequestVendor from 'components/client/shared/useRequestVendor'
import { compose, withAuthorize, withReactQuery } from 'hocs'
import { useQueryParams } from 'hooks'

// Constants
import { OrganizationPartnerTypes } from 'constants/index'
import View from './View'

const Container = ({
  showGloborg,
  showWatchlists,
  disabledIntegrations,
  vendorRequestConfig,
  partners,
  vendorLibraryEnabled,
  ...rest
}) => {
  const allowIntakeFeature = vendorRequestConfig.enabled
  const createVendor = useCreateVendor()
  const requestVendor = useRequestVendor()
  const [{ activeTab }, setQueryParams] = useQueryParams({
    activeTab: {
      queryKey: 'tab',
    },
  })
  const vendorListViewRef = React.useRef()

  const handleTabSelect = React.useCallback(
    (e) => setQueryParams({ activeTab: e }, true),
    [],
  )

  const handleAddVendorClick = React.useCallback(() => {
    createVendor({
      showGloborg,
      onCreated: (response) => {
        if (vendorListViewRef.current) {
          vendorListViewRef.current.updateSearch(response.name)
        }
      },
    })
  }, [showGloborg, createVendor])

  const handleRequestVendorClick = React.useCallback(() => {
    requestVendor({ vendorRequestConfig })
  }, [vendorRequestConfig, requestVendor])

  const bitSight = React.useMemo(
    () =>
      find(partners, {
        type: OrganizationPartnerTypes.BITSIGHT,
        enabled: true,
      }),
    [partners],
  )

  return (
    <View
      {...rest}
      activeTab={activeTab}
      bitSight={bitSight}
      vendorListViewRef={vendorListViewRef}
      allowIntakeFeature={allowIntakeFeature}
      onTabSelect={handleTabSelect}
      onAddVendorClick={handleAddVendorClick}
      onRequestVendorClick={handleRequestVendorClick}
      showWatchlists={showWatchlists}
      disabledIntegrations={disabledIntegrations}
      vendorLibraryEnabled={vendorLibraryEnabled}
    />
  )
}

Container.propTypes = {
  orgId: PropTypes.string,
  partners: PropTypes.array.isRequired,
  showGloborg: PropTypes.bool,
  vendorRequestConfig: PropTypes.object,
  userPrefs: PropTypes.object,
  orgRole: PropTypes.string,
  watchlists: PropTypes.array.isRequired,
  vendorRoles: PropTypes.object,
  enableRiskDomains: PropTypes.bool,
  showWatchlists: PropTypes.bool,
  canAdmin: PropTypes.bool,
  disabledIntegrations: PropTypes.array.isRequired,
  vendorLibraryEnabled: PropTypes.bool,
}

export default compose(withAuthorize(), withReactQuery())(Container)
