import React, { useState } from 'react'
import { useClientVendorOfacStatus } from 'apis'
import config from 'config'
import { withReactQuery } from 'hocs'
import { useDebounce, useQueryParams } from 'hooks'
import OfacStatusView from './View'

const queryParamsConfig = {
  page: {
    type: Number,
    defaultValue: 1,
  },
  sizePerPage: {
    queryKey: 'per_page',
    type: Number,
    defaultValue: config.collectionQueryPageLimit.default,
  },
}

function dateToParam(date) {
  return date.toISOString().substring(0, 10)
}

function OfacStatus() {
  const [queryParams, setQueryParams] = useQueryParams(queryParamsConfig)
  const { page, sizePerPage } = queryParams
  const [vendorNameFilter, setVendorNameFilter] = useState('')
  const [ofacStatusFilterValues, setOfacStatusFilterValues] = useState({})
  const [includeArchived, setIncludeArchived] = useState(false)
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null })
  const [riskTiers, setRiskTiers] = useState({})
  const [debouncedVendorNameFilter] = useDebounce(vendorNameFilter, 500)

  const handlePaginationChange = React.useCallback(
    (pagination) => setQueryParams(pagination),
    [setQueryParams],
  )

  const handleOfacStatusFilterChange = React.useCallback(
    (value) => {
      setQueryParams({ page: 1 })
      setOfacStatusFilterValues(value)
    },
    [setQueryParams],
  )

  const handleVendorNameFilterChange = React.useCallback(
    (value) => {
      setQueryParams({ page: 1 })
      setVendorNameFilter(value)
    },
    [setQueryParams],
  )

  const handleIncludeArchivedChange = React.useCallback(
    (value) => {
      setQueryParams({ page: 1 })
      setIncludeArchived(value)
    },
    [setQueryParams],
  )

  const handleRiskTierChange = React.useCallback(
    (value) => {
      setQueryParams({ page: 1 })
      setRiskTiers(value)
    },
    [setQueryParams],
  )

  const handleDateRangeChange = React.useCallback(
    (value) => {
      setQueryParams({ page: 1 })
      setDateRange(value)
    },
    [setQueryParams],
  )

  const params = { page, per_page: sizePerPage }
  if (ofacStatusFilterValues.selected?.length)
    params.ofac_statuses = ofacStatusFilterValues.selected
  if (debouncedVendorNameFilter) params.search_query = debouncedVendorNameFilter
  if (includeArchived) params.exclude_archived = false
  if (riskTiers.selected?.length) params.risk_tier_list = riskTiers.selected
  if (dateRange.startDate)
    params.create_start_date = dateToParam(dateRange.startDate)
  if (dateRange.endDate) params.create_end_date = dateToParam(dateRange.endDate)
  const results = useClientVendorOfacStatus({ params })

  return (
    <div>
      <OfacStatusView
        ofacSummary={results}
        page={page}
        sizePerPage={sizePerPage}
        vendorNameFilter={vendorNameFilter}
        ofacStatusFilterValues={ofacStatusFilterValues}
        includeArchived={includeArchived}
        riskTiers={riskTiers}
        dateRange={dateRange}
        onPaginationChange={handlePaginationChange}
        onVendorNameFilterChange={handleVendorNameFilterChange}
        onOfacStatusFilterValueChange={handleOfacStatusFilterChange}
        onIncludeArchivedChange={handleIncludeArchivedChange}
        onRiskTierChange={handleRiskTierChange}
        onDateRangeChange={handleDateRangeChange}
      />
    </div>
  )
}

export default withReactQuery()(OfacStatus)
