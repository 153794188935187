import styled from 'styled-components'
import { styles } from '../Input'

export default styled.select`
  ${styles}

  ${({ size }) =>
    ['sm', 'small'].includes(size) &&
    `
    line-height: 33px;
  `}
`
