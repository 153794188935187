import config from 'config'
import ColumnMap from 'lib/column_map'

export const ActionMenuItemTypes = {
  EXPORT_CURRENT_VIEW: 'export_current_view',
  ARCHIVE_SELECTED: 'archive_selected',
  SEND_SELECTED: 'send_selected',
  RESEND_SELECTED: 'resend_selected',
  DELETE_SELECTED: 'delete_selected',
}

export const ColumnDataFieldTypes = new ColumnMap({
  VENDOR_NAME: 'vendor.name',
  SUMMARY: 'summary',
  PRIORITY: 'priority',
  STATUS: 'status',
  CREATED_AT: 'created_at',
  DUE_AT: 'due_at',
  VENDOR_RISK_TIER: 'vendor.risk_tier',
})

export const queryParamsConfig = {
  page: {
    type: Number,
    defaultValue: 1,
  },
  sizePerPage: {
    queryKey: 'per_page',
    type: Number,
    defaultValue: config.collectionQueryPageLimit.default,
  },
  sortField: {
    queryKey: 'ordered_by',
  },
  sortOrder: {
    queryKey: 'direction',
  },
  searchText: {
    queryKey: 'q',
    defaultValue: '',
  },
  statuses: {
    queryKey: 'status',
    defaultValue: [],
    isArray: true,
  },
  categories: {
    queryKey: 'category',
    defaultValue: [],
    isArray: true,
  },
  resolutions: {
    queryKey: 'resolution',
    defaultValue: [],
    isArray: true,
  },
  vendorTags: {
    queryKey: 'vendor_tags',
    defaultValue: [],
    isArray: true,
  },
  vendorTagsAnyMatch: {
    queryKey: 'vendor_tags_any',
    type: Boolean,
    defaultValue: true,
  },
  clientAssignees: {
    queryKey: 'client_assignee',
    defaultValue: [],
    isArray: true,
  },
  unassigned: {
    type: Boolean,
    defaultValue: false,
  },
  priorities: {
    queryKey: 'priority',
    type: Number,
    defaultValue: [],
    isArray: true,
  },
  riskTiers: {
    queryKey: 'risk_tier',
    type: (e) => (Number.isNaN(parseInt(e, 10)) ? e : parseInt(e, 10)),
    defaultValue: [],
    isArray: true,
  },
}
