import styled from 'styled-components'
import Capsule from 'components-v2/atoms/Capsule'

export const ChartWrapper = styled.div`
  position: relative;
  font-size: 12px;
`

export const MetricWrapper = styled.div`
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
`

export const Metric = styled(Capsule)`
  & > *:first-child {
    font-size: 18px;
    letter-spacing: -1px;
  }

  & > *:last-child {
    font-size: 28px;
    padding: 0 12px 0 7px;
  }
`
