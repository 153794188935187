import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import map from 'lodash/map'
import debounce from 'lodash/debounce'
import Icon from 'components-v2/atoms/Icon'
import FormField from 'components-v2/molecules/FormField'
import FormDialog from 'components-v2/organisms/FormDialog'
import ProjectCustomEmailSettingFormField from 'components/client/shared/ProjectCustomEmailSettingFormField'
import * as apis from 'apis'
import config from 'config'
import { ToggleButton } from './styles'

export const NEW_OPTION_VALUE = 'new'

const dateControlProps = {
  dateFormat: 'yyyy/MM/dd h:mm aa',
  showTimeSelect: true,
  todayButton: 'Today',
  minDateTime: new Date(),
}
const dueDateControlProps = {
  ...dateControlProps,
  showDurationButtons: true,
}

const assessmentTemplateControlProps = {
  isAsync: true,
  cacheOptions: true,
  placeholder: 'Search a template...',
  noOptionsMessage: () => `No template found`,
  loadOptions: debounce(
    (search_query) =>
      apis
        .getClientAssessmentTemplates({
          params: {
            search_query,
            page: 1,
            per_page:
              config.collectionQueryTotalLimit
                .assessmentTemplatesInClientSendAssessmentModal,
            assignable: true,
          },
        })
        .then((response) => {
          const options = map(response.data, (e) => ({
            value: e.id,
            label: e.name,
          }))
          return options
        }),
    1000,
    { leading: true },
  ),
}

const validationSchema = yup.object().shape({
  projectId: yup.string().label('Project').required(),
  projectName: yup.string().label('Project name').when('projectId', {
    is: NEW_OPTION_VALUE,
    then: yup.string().required(),
  }),
  assessmentTemplateId: yup.string().label('Template').when('projectId', {
    is: NEW_OPTION_VALUE,
    then: yup.string().required(),
  }),
  customEmailSubject: yup
    .string()
    .label('Custom subject')
    .nullable()
    .when(['projectId', 'customEmailFieldsVisible'], {
      is: (projectId, customEmailFieldsVisible) =>
        projectId === NEW_OPTION_VALUE && customEmailFieldsVisible,
      then: yup.string().defined(),
    }),
  customEmailText: yup
    .string()
    .label('Custom text')
    .nullable()
    .when(['projectId', 'customEmailFieldsVisible'], {
      is: (projectId, customEmailFieldsVisible) =>
        projectId === NEW_OPTION_VALUE && customEmailFieldsVisible,
      then: yup.string().defined(),
    }),
  dueDate: yup
    .date()
    .nullable()
    .label('Due date')
    .min(yup.ref('launchDate'), 'Due date must be after launch date')
    .required(),
  launchDate: yup.date().nullable().label('Launch date').required(),
})

const SendAssessmentModal = ({
  vendorName,
  projects,
  projectsLoading,
  defaultCustomEmailSubject,
  defaultCustomEmailText,
  ...rest
}) => {
  const projectIdSelectOptions = React.useMemo(
    () => [
      { label: 'Create New', value: NEW_OPTION_VALUE },
      ...map(projects, (e) => ({ label: e.friendly_name, value: e.id })),
    ],
    [projects],
  )

  const formConfig = React.useMemo(() => {
    const launchAt = new Date()
    launchAt.setMinutes(launchAt.getMinutes() + 30)
    const initialValues = {
      projectId: undefined,
      projectName: `${vendorName} Assessment`,
      assessmentTemplateId: undefined,
      launchDate: launchAt,
      dueDate: undefined,
      customEmailFieldsVisible: false,
      customEmailSubject: null,
      customEmailText: null,
      invitationReminder: false,
    }
    return { initialValues, validationSchema }
  }, [vendorName])

  return (
    <FormDialog
      {...rest}
      title="Send Assessment"
      submitButtonLabel="Send Assessment"
      formConfig={formConfig}
    >
      {({ values, setFieldValue }) => {
        const isNew = values.projectId === NEW_OPTION_VALUE
        const { customEmailFieldsVisible } = values
        return (
          <>
            <FormField
              name="projectId"
              label="Project"
              type={FormField.types.SEARCHABLE_SELECT}
              controlProps={{
                isLoading: projectsLoading,
                autoFocus: true,
              }}
              options={projectIdSelectOptions}
              horizontal
            />
            {isNew && (
              <FormField name="projectName" label="Project Name" horizontal />
            )}
            {isNew && (
              <FormField
                name="assessmentTemplateId"
                label="Template"
                type={FormField.types.SEARCHABLE_SELECT}
                controlProps={assessmentTemplateControlProps}
                horizontal
              />
            )}
            <FormField
              name="launchDate"
              label="Launch Date"
              type={FormField.types.DATE}
              controlProps={dateControlProps}
              horizontal
            />
            <FormField
              name="dueDate"
              label="Due Date"
              type={FormField.types.DATE}
              controlProps={dueDateControlProps}
              horizontal
            />
            {isNew && !customEmailFieldsVisible && (
              <ToggleButton
                onClick={() =>
                  setFieldValue('customEmailFieldsVisible', true, false)
                }
              >
                <Icon icon="fa fa-plus-circle" />
                Custom Welcome Email
              </ToggleButton>
            )}
            {isNew && customEmailFieldsVisible && (
              <>
                <FormField
                  component={ProjectCustomEmailSettingFormField}
                  name="customEmailSubject"
                  label="Custom subject"
                  controlProps={{
                    defaultText: defaultCustomEmailSubject,
                    maxLength: 200,
                  }}
                />
                <FormField
                  component={ProjectCustomEmailSettingFormField}
                  name="customEmailText"
                  label="Custom text"
                  controlProps={{
                    componentClass: 'textarea',
                    defaultText: defaultCustomEmailText,
                    maxLength: 2000,
                    rows: 4,
                  }}
                />
                <FormField
                  type={FormField.types.TOGGLE}
                  name="invitationReminder"
                  label="Send reminder emails"
                  helpMessage="If enabled, automatic email reminders will be sent 2, 5, and 7 business days after the launch date if the invitation has not been accepted. If not submitted, reminders will also be sent 3 days and 1 day prior to the completion date."
                />
              </>
            )}
          </>
        )
      }}
    </FormDialog>
  )
}

SendAssessmentModal.propTypes = {
  vendorName: PropTypes.string.isRequired,
  projects: PropTypes.array,
  projectsLoading: PropTypes.bool,
  defaultCustomEmailSubject: PropTypes.string,
  defaultCustomEmailText: PropTypes.string,
}

export default SendAssessmentModal
