import React from 'react'
import PropTypes from 'prop-types'
import Privva from 'Privva'
import A from 'components-v2/atoms/A'
import Button from 'components-v2/atoms/Button'
import IconButton from 'components-v2/atoms/IconButton'
import ListGroup from 'components-v2/molecules/ListGroup'
import PageHeader from 'components-v2/organisms/PageHeader'
import { Row, Col } from 'components-v2/organisms/Layout'
import { withReactQuery } from 'hocs'
import { useAssistAnswerSetsFetcher, useAssistQuestionSetsFetcher } from 'apis'
import { getPageUrl } from 'utils/url'
import { pluralize } from 'utils/string'
import { toLocalTimeString } from 'utils/date'
import useDeleteAnswerSet from '../shared/useDeleteAnswerSet'
import useDeleteQuestionSet from '../shared/useDeleteQuestionSet'
import useUploadAnswerSet from '../shared/useUploadAnswerSet'
import useUploadQuestionSet from '../shared/useUploadQuestionSet'
import QuestionSetFormatTooltip from '../shared/QuestionSetFormatTooltip'
import AnswerSetFormatTooltip from '../shared/AnswerSetFormatTooltip'
import CardWithTitle from '../shared/CardWithTitle'
import {
  Section,
  StyledListGroupItem as ListGroupItem,
  ListGroupItemContent,
} from './styles'

function IndexView({
  answerSets: answerSetsProp,
  questionSets: questionSetsProp,
  assistOrgName,
}) {
  const { data: answerSets } = useAssistAnswerSetsFetcher(undefined, {
    initialData: answerSetsProp,
    refetchOnMount: false,
  })
  const { data: questionSets } = useAssistQuestionSetsFetcher(undefined, {
    initialData: questionSetsProp,
    refetchOnMount: false,
  })
  const uploadAnswerSet = useUploadAnswerSet()
  const uploadQuestionSet = useUploadQuestionSet()
  const deleteAnswerSet = useDeleteAnswerSet()
  const deleteQuestionSet = useDeleteQuestionSet()
  return (
    <>
      <PageHeader title={`${Privva.Utils.brand.programName} Assist`} />
      <h3>
        {assistOrgName}&nbsp;
        <IconButton
          href={getPageUrl('assistOrganizations')}
          icon="fa fa-pencil"
          color="primary"
          className="h5"
        />
      </h3>
      <Row>
        <Col md={7}>
          <p>
            I am the {Privva.Utils.brand.programName} Answer Assistant. I am an
            AI that helps you respond to questions using knowledge about your
            organization.
          </p>
          <Section>
            <h4>1. Upload an Answer Set</h4>
            <p>
              To get started, upload one or more Answer Sets. An Answer Set is a
              spreadsheet of previously answered questions and answers.
            </p>
            <Button color="primary" onClick={uploadAnswerSet}>
              <i className="fa fa-table" />
              Upload Spreadsheet
            </Button>
            &nbsp;&nbsp;
            <AnswerSetFormatTooltip />
          </Section>
          <Section>
            <h4>2. Upload a Question Set</h4>
            <p>
              Next, upload a Question Set. A Question Set is a spreadsheet of
              new questions that you&apos;d like me to help you answer.
            </p>
            <Button color="primary" onClick={uploadQuestionSet}>
              <i className="fa fa-table" />
              Upload Spreadsheet
            </Button>
            &nbsp;&nbsp;
            <QuestionSetFormatTooltip />
          </Section>
          <Section>
            <h4>3. Open a Question Set</h4>
            <p>
              Finally, click on any Question Set to explore my suggested answers
              and copy them into your response.
            </p>
          </Section>
        </Col>
        <Col md={5}>
          <CardWithTitle title="Answer Sets">
            <ListGroup>
              {answerSets.map((as) => (
                <ListGroupItem key={as.id}>
                  <ListGroupItemContent>
                    <b>{as.name}</b>
                    <div className="small">
                      {pluralize(as.answers_count, 'answer')}{' '}
                      {toLocalTimeString(as.created_at, 'MMMM yyyy')}
                    </div>
                  </ListGroupItemContent>
                  <IconButton
                    icon="fa fa-times-circle"
                    color="primary"
                    onClick={() => deleteAnswerSet(as.id)}
                  />
                </ListGroupItem>
              ))}
            </ListGroup>
          </CardWithTitle>
          <CardWithTitle title="Question Sets">
            <ListGroup>
              {questionSets.map((qs) => (
                <ListGroupItem key={qs.id}>
                  <ListGroupItemContent>
                    <A
                      href={getPageUrl('assistQuestionSetAnswerSets', {
                        id: qs.id,
                      })}
                    >
                      <b>{qs.name}</b>
                    </A>
                    <div className="small">
                      {pluralize(qs.questions_count, 'question')}{' '}
                      {toLocalTimeString(qs.created_at, 'MMMM yyyy')}
                    </div>
                  </ListGroupItemContent>
                  <IconButton
                    icon="fa fa-times-circle"
                    color="primary"
                    onClick={() => deleteQuestionSet(qs.id)}
                  />
                </ListGroupItem>
              ))}
            </ListGroup>
          </CardWithTitle>
        </Col>
      </Row>
    </>
  )
}

IndexView.propTypes = {
  answerSets: PropTypes.arrayOf(PropTypes.object).isRequired,
  questionSets: PropTypes.arrayOf(PropTypes.object).isRequired,
  assistOrgName: PropTypes.string.isRequired,
}

export default withReactQuery()(IndexView)
