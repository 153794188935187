import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'components-v2/atoms/Icon'
import FilesInput from '../FilesInput'

const FileInput = ({ value, onChange, ...rest }) => {
  const handleChange = React.useCallback(
    (files) => {
      onChange(files && files.length > 0 ? files[0] : null)
    },
    [onChange],
  )

  return (
    <FilesInput
      {...rest}
      maxFiles={1}
      value={value ? [value] : null}
      onChange={handleChange}
    />
  )
}

FileInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(File),
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ]),
  buttonContent: PropTypes.node,
  onChange: PropTypes.func.isRequired,
}

FileInput.defaultProps = {
  buttonContent: [<Icon key="icon" icon="fa fa-cloud-upload" />, 'Attach file'],
}

export default FileInput
