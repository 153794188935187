import React from 'react'
import PropTypes from 'prop-types'
import View from './View'
import demo from './demo'

const Controller = (props) => <View {...props} data={demo.data} />

Controller.propTypes = {
  isVendorContext: PropTypes.bool,
}

Controller.defaultProps = {
  isVendorContext: false,
}

export default Controller
