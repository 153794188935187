/* eslint-disable no-nested-ternary */
import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from 'components-v2/molecules/Tooltip'
import { Wrapper, Top, Title, Content, Bottom } from './styles'

const BaseOverviewCard = ({
  title,
  description,
  loading,
  error,
  noData,
  detailsUrl,
  tooltipMaxWidth,
  children,
  ...rest
}) => (
  <Wrapper {...rest}>
    <Top>
      <Title>
        {description ? (
          <Tooltip
            parent={<span>{title}</span>}
            style={{ maxWidth: tooltipMaxWidth }}
          >
            {description}
          </Tooltip>
        ) : (
          title
        )}
      </Title>
      <Content>
        {loading
          ? 'Loading...'
          : error
          ? 'Error loading data'
          : noData
          ? 'No data'
          : children}
      </Content>
    </Top>
    <Bottom href={detailsUrl}>View Details</Bottom>
  </Wrapper>
)

BaseOverviewCard.propTypes = {
  title: PropTypes.any.isRequired,
  description: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  noData: PropTypes.bool,
  children: PropTypes.element,
  detailsUrl: PropTypes.string,
  tooltipMaxWidth: PropTypes.number,
}

export default BaseOverviewCard
