import React from 'react'
import PropTypes from 'prop-types'

const Single = ({
  selected,
  label,
  selectedButtonColor,
  selectedButtonVariant,
  defaultButtonColor,
  defaultButtonVariant,
  buttonSize,
  RenderButton,
  onSelect,
  ...rest
}) => (
  <RenderButton
    {...rest}
    active={selected}
    size={buttonSize}
    color={selected ? selectedButtonColor : defaultButtonColor}
    variant={selected ? selectedButtonVariant : defaultButtonVariant}
    hidePrint={false}
    onClick={() => onSelect(true)}
  >
    {label}
  </RenderButton>
)

Single.propTypes = {
  selected: PropTypes.bool,
  label: PropTypes.any.isRequired,
  selectedButtonColor: PropTypes.string,
  selectedButtonVariant: PropTypes.string,
  defaultButtonColor: PropTypes.string,
  defaultButtonVariant: PropTypes.string,
  buttonSize: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  RenderButton: PropTypes.elementType.isRequired,
}

export default Single
