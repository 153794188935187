import React from 'react'
import PropTypes from 'prop-types'
import A from 'components-v2/atoms/A'
import Button from 'components-v2/atoms/Button'
import { useAuthorizations } from 'hooks'
import { Wrapper, Muted, ConnectButtonWrapper } from './styles'

const PublicProfileDisplay = ({ value, field = {} }) => {
  const { onConnect, onDisconnect, onClickName, globOrgName } = field
  const [canManage, canViewRiskProfile] = useAuthorizations('manage', [
    'index',
    'RiskDomain',
  ])
  if (!value) {
    return (
      <Wrapper>
        <Muted>Not connected.</Muted>
        {canManage && (
          <ConnectButtonWrapper key="connect">
            <Button color="primary" onClick={onConnect}>
              <i className="fa fa-link" aria-hidden="true" />
              Connect
            </Button>
          </ConnectButtonWrapper>
        )}
      </Wrapper>
    )
  }
  const displayOutput = canViewRiskProfile ? (
    <A onClick={onClickName} underline>
      {globOrgName}
    </A>
  ) : (
    globOrgName
  )
  return (
    <Wrapper>
      {globOrgName ? displayOutput : <Muted>Loading...</Muted>}
      {canManage && (
        <ConnectButtonWrapper key="disconnect">
          <Button onClick={onDisconnect}>
            <i className="fa fa-chain-broken" aria-hidden="true" />
            Disconnect
          </Button>
        </ConnectButtonWrapper>
      )}
    </Wrapper>
  )
}

PublicProfileDisplay.propTypes = {
  value: PropTypes.any,
  field: PropTypes.shape({
    globOrgName: PropTypes.string,
    onClickName: PropTypes.func.isRequired,
    onConnect: PropTypes.func.isRequired,
    onDisconnect: PropTypes.func.isRequired,
  }).isRequired,
}

export default PublicProfileDisplay
