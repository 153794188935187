import styled from 'styled-components'
import UserAvatar from 'components-v2/molecules/UserAvatar'
import { colors, mixins } from 'styles'

export const Wrapper = styled.div`
  :not(:first-child) {
    margin-top: 0.6em;
  }
`

export const SummaryRow = styled.div`
  display: flex;

  > *:not(:first-child) {
    margin-left: 10px;
  }
`

export const Name = styled.div`
  flex: auto;
  ${mixins.truncate}
`

export const Date = styled.div`
  flex: none;
  width: 5em;
`

export const Status = styled.div`
  flex: none;
  width: 5em;
`

export const Score = styled.div`
  flex: none;
  width: 16em;
`

export const ScoreBarWrapper = styled.div`
  display: inline-block;
  width: 12.6em;
  margin-right: 0.5em;
  margin-top: 3px;
  vertical-align: top;
`

export const Grade = styled.div`
  flex: none;
  width: 5em;
`

export const Notes = styled.div`
  margin-top: 0.66em;
  border: 1px solid ${colors.LIGHT_GREY};
  border-radius: 3px;
  background: rgba(241, 243, 247, 0.5);
  padding: 0.8em;
`

export const NoteWrapper = styled.div`
  :not(:first-child) {
    margin-top: 1.3em;
  }
`

export const NoteUser = styled.div`
  display: flex;
  align-items: center;
  ${mixins.smallHeader}
  font-size: .66em;
  letter-spacing: 0;
  margin-bottom: 1em;
`

export const NoteUserName = styled.span`
  color: ${colors.PRIMARY};
`

export const NoteUserAvatar = styled(UserAvatar).attrs({ size: 16 })`
  font-size: inherit;
  margin-right: 0.6em;
`

export const ReadMoreLink = styled.a`
  cursor: pointer;
  color: ${colors.PRIMARY};
  font-weight: bold;
  font-size: 0.73em;
  text-transform: uppercase;
`

export const TextWrap = styled.div`
  word-wrap: break-word;
`
