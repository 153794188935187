import React from 'react'
import PropTypes from 'prop-types'
import mergeRefs from 'react-merge-refs'
import styled from 'styled-components'
import add from 'date-fns/add'
import set from 'date-fns/set'
import getHours from 'date-fns/getHours'
import getMinutes from 'date-fns/getMinutes'
import getSeconds from 'date-fns/getSeconds'
import getMilliseconds from 'date-fns/getMilliseconds'
import { toDate } from 'utils/date'

const DurationList = styled.div`
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  clear: both;
`

const Duration = styled.div`
  margin: 2px;
  padding: 2px;
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
  width: 3.33rem;
  font-size: 0.9em;
  font-weight: bold;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #1d5d90;
  }
`

// Let's support durations prop
export default (WrappedComponent) => {
  const WrapperComponent = React.forwardRef(
    (
      { value, showDurationButtons, durations, onChange, children, ...rest },
      ref,
    ) => {
      const localRef = React.useRef()
      const handleClick = React.useCallback(
        (d) => {
          const { label, ...restParams } = d
          let newDate = add(new Date(), restParams)
          const currentDate = toDate(value)
          if (currentDate) {
            newDate = set(newDate, {
              hours: getHours(currentDate),
              minutes: getMinutes(currentDate),
              seconds: getSeconds(currentDate),
              milliseconds: getMilliseconds(currentDate),
            })
          }
          onChange(newDate)
          if (localRef.current) {
            localRef.current.setPreSelection(newDate)
          }
        },
        [value, onChange],
      )
      return (
        <WrappedComponent
          {...rest}
          value={value}
          onChange={onChange}
          ref={mergeRefs([localRef, ref])}
        >
          {children}
          {showDurationButtons && durations && durations.length > 0 && (
            <DurationList>
              {durations.map((d, index) => (
                <Duration
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  onClick={() => handleClick(d)}
                  data-test-label={d.label}
                >
                  {d.label}
                </Duration>
              ))}
            </DurationList>
          )}
        </WrappedComponent>
      )
    },
  )

  WrapperComponent.propTypes = {
    value: PropTypes.instanceOf(Date),
    showDurationButtons: PropTypes.bool,
    durations: PropTypes.array,
    onChange: PropTypes.func,
    children: PropTypes.element,
  }

  WrapperComponent.defaultProps = {
    durations: [
      { label: '1 Week', days: 7 },
      { label: '2 Weeks', days: 14 },
      { label: '15 Days', days: 15 },
      { label: '1 Month', months: 1 },
    ],
  }

  return WrapperComponent
}
