import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Checkbox from 'components-v2/atoms/Checkbox'
import IconButton from 'components-v2/atoms/IconButton'
import Tooltip from 'components-v2/molecules/Tooltip'
import { colors } from 'styles'
import BaseField from './BaseField'

const StyledCheckbox = styled(Checkbox)`
  ${(props) =>
    props.error &&
    css`
      color: ${colors.DANGER};
    `}

  &:not(:last-child) {
    margin-right: 7px;
  }
`

const QuestionIconButton = styled(IconButton).attrs({
  icon: 'fa fa-question-circle',
})`
  font-size: 12px;
`

const CheckboxField = ({
  name,
  value,
  label,
  color,
  disabled,
  helpMessage,
  onChange,
  showError,
  errorMessage,
  ...rest
}) => (
  <BaseField {...rest} showError={showError}>
    <StyledCheckbox
      name={name}
      label={label}
      color={color}
      checked={value}
      disabled={disabled}
      error={showError}
      inline
      onChange={(e) => onChange(e.currentTarget.checked)}
    />
    {helpMessage && (
      <Tooltip position="top" parent={QuestionIconButton}>
        {helpMessage}
      </Tooltip>
    )}
  </BaseField>
)

CheckboxField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.any,
  value: PropTypes.bool,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  helpMessage: PropTypes.string,
  componentClass: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  showError: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}

export default CheckboxField
