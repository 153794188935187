import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import size from 'lodash/size'
import { ProjectStatusTypes } from 'constants/index'
import { useAuthManager } from 'hooks'
import Assessment from './Assessment'
import {
  Project,
  ProjectName,
  ProjectNameLink,
  ProjectNameMeta,
  VendorBlock,
  VendorName,
  VendorNameLink,
} from './styles'

const VendorResultsSummary = ({ projects, includeChildren, ...rest }) => {
  const authManager = useAuthManager()
  return (
    <div {...rest}>
      {map(projects, (project) => {
        const ProjectDisplay = authManager.userCan('show', project)
          ? ProjectNameLink
          : ProjectName
        return (
          size(project.assessments) > 0 && (
            <Project key={project.id}>
              <ProjectDisplay
                muted={project.status === ProjectStatusTypes.ARCHIVED}
                href={project.url}
              >
                {project.friendly_name}
                {project.status === ProjectStatusTypes.ARCHIVED && (
                  <ProjectNameMeta>(archived)</ProjectNameMeta>
                )}
              </ProjectDisplay>
              {map(project.vendors, (vendor) => {
                const VendorDisplay = authManager.userCan('access', vendor)
                  ? VendorNameLink
                  : VendorName
                return (
                  project.assessments[vendor.id] && (
                    <VendorBlock key={vendor.id}>
                      {includeChildren && (
                        <VendorDisplay href={vendor.path}>
                          {vendor.name}
                        </VendorDisplay>
                      )}
                      <div>
                        {map(project.assessments[vendor.id], (assessment) => (
                          <Assessment
                            key={assessment.id}
                            assessment={assessment}
                          />
                        ))}
                      </div>
                    </VendorBlock>
                  )
                )
              })}
            </Project>
          )
        )
      })}
    </div>
  )
}

VendorResultsSummary.propTypes = {
  projects: PropTypes.array,
  includeChildren: PropTypes.bool,
}

export default VendorResultsSummary
