import { useCallback } from 'react'
import { useModal, useAuthorizations, useConfirm, useCurrent } from 'hooks'
import { useUpdateClientAssessmentTemplate } from 'apis'
import AlterAttributeRule from 'lib/assessment/alter_attribute_rule'
import {
  AssessmentTemplateRuleTypes,
  AssessmentTemplateRuleAttributeTypes,
} from 'constants/index'
import AlterAttributeRuleModal from './AlterAttributeRuleModal'
import useDefaultAsyncActionHandler from './useDefaultAsyncActionHandler'
import useSetAssessmentTemplate from './useSetAssessmentTemplate'
import { sortElements } from './utils'

export default function useCRUDSkipConditions({
  assessmentTemplate,
  selectedQuestionIds,
  setSelectedQuestionIds,
  setSelectedSectionId,
}) {
  const { mutateAsync: updateClientAssessmentTemplate } =
    useUpdateClientAssessmentTemplate()
  const [openModal, closeModal] = useModal(AlterAttributeRuleModal)
  const [userCanEdit] = useAuthorizations('update')
  const assessmentTemplateRef = useCurrent(assessmentTemplate)
  const openConfirm = useConfirm()
  const handleAsyncAction = useDefaultAsyncActionHandler()
  const setAssessmentTemplate = useSetAssessmentTemplate(assessmentTemplate.id)

  const bulkAddSkipConditions = useCallback(() => {
    openModal({
      rules: assessmentTemplateRef.current.rules,
      elements: sortElements(assessmentTemplateRef.current.elements),
      editable: !assessmentTemplateRef.current.readonly && userCanEdit,
      attribute: AssessmentTemplateRuleAttributeTypes.SKIP,
      selected_element_ids: selectedQuestionIds,
      mode: AlterAttributeRuleModal.modes.CREATE,
      onSubmit: (rules) => {
        const promise = updateClientAssessmentTemplate({
          id: assessmentTemplateRef.current.id,
          data: {
            assessment_template: {
              rules,
            },
          },
        }).then(() => {
          setSelectedQuestionIds([])
          setSelectedSectionId(null)
          closeModal()
        })
        handleAsyncAction(
          promise,
          'Skip Conditions have been successfully added to the selected questions.',
        )
      },
    })
  }, [
    openModal,
    closeModal,
    setAssessmentTemplate,
    setSelectedQuestionIds,
    setSelectedSectionId,
    userCanEdit,
    selectedQuestionIds,
  ])

  const editSkipConditions = useCallback(
    (elementId) => {
      openModal({
        rules: assessmentTemplateRef.current.rules,
        elements: sortElements(assessmentTemplateRef.current.elements),
        editable: !assessmentTemplateRef.current.readonly && userCanEdit,
        attribute: AssessmentTemplateRuleAttributeTypes.SKIP,
        selected_element_ids: [elementId],
        mode: AlterAttributeRuleModal.modes.EDIT,
        onSubmit: (rules) => {
          const promise = updateClientAssessmentTemplate({
            id: assessmentTemplateRef.current.id,
            data: {
              assessment_template: {
                rules,
              },
            },
          }).then(() => {
            closeModal()
          })
          handleAsyncAction(promise)
        },
      })
    },
    [
      openModal,
      closeModal,
      setAssessmentTemplate,
      setSelectedQuestionIds,
      setSelectedSectionId,
      userCanEdit,
    ],
  )

  const bulkDeleteSkipConditions = useCallback(() => {
    openConfirm({
      title: 'Deleting Conditions',
      body: 'Are you sure you want to remove conditions from the selected questions?',
      onConfirm: () => {
        const newRules = AlterAttributeRule.removeFromRules(
          assessmentTemplateRef.current.rules,
          AssessmentTemplateRuleTypes.ALTER_ATTRIBUTE,
          AssessmentTemplateRuleAttributeTypes.SKIP,
          selectedQuestionIds,
        )
        const promise = updateClientAssessmentTemplate({
          id: assessmentTemplateRef.current.id,
          data: {
            assessment_template: {
              rules: newRules,
            },
          },
        }).then(() => {
          setSelectedQuestionIds([])
          setSelectedSectionId(null)
        })
        return handleAsyncAction(
          promise,
          'Skip Conditions have been successfully removed from the selected questions.',
        )
      },
    })
  }, [
    openConfirm,
    selectedQuestionIds,
    setSelectedQuestionIds,
    setSelectedSectionId,
    handleAsyncAction,
  ])

  return {
    bulkAddSkipConditions,
    editSkipConditions,
    bulkDeleteSkipConditions,
  }
}
