import styled from 'styled-components'
import Card from 'components-v2/organisms/Card'
import { colors } from 'styles'

export const Wrapper = styled(Card).attrs({ bordered: true })`
  position: relative;
  display: block;
  padding: 25px 20px 20px;
  color: inherit;
  font-size: 14px;
  height: 178px;
  margin-bottom: 25px;
  overflow: hidden;
  ${(props) => props.onClick && `cursor: pointer;`}

  &:hover,
  &:focus {
    color: inherit;
    text-decoration: none;
  }

  &:focus-visible {
    outline: 2px solid ${colors.PRIMARY};
    outline-offset: 0;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: ${(props) => props.color};
  }
`

export const Title = styled.h4`
  margin: 0 0 13px 0;
  line-height: 22px;
  color: ${colors.HEADING};
  text-transform: capitalize;
`

export const IconsWrapper = styled.div`
  position: absolute;
  bottom: 12px;
  right: 15px;
`

export const IconWrapper = styled.div`
  width: 25px;
  height: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${colors.LIGHT_GREY};
  border-radius: 100%;
  vertical-align: top;
  margin-left: 3px;

  > * {
    opacity: 0.5;
  }
`
