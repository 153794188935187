import React from 'react'
import * as yup from 'yup'
import FormField from 'components-v2/molecules/FormField'
import FormDialog from 'components-v2/organisms/FormDialog'

const formConfig = {
  validationSchema: yup.object().shape({
    dueDate: yup
      .date()
      .nullable()
      .required('Due date is required')
      .min(new Date(), 'Due date must be in the future'),
  }),
  initialValues: {
    dueDate: undefined,
  },
}

const dueDateControlProps = {
  dateFormat: 'yyyy/MM/dd h:mm aa',
  fullWidth: true,
  showTimeSelect: true,
  todayButton: 'Today',
  minDateTime: new Date(),
  showDurationButtons: true,
}

const DueDateFormDialog = (props) => (
  <FormDialog {...props} title="Enter new due date" formConfig={formConfig}>
    <FormField
      name="dueDate"
      label="Due Date"
      type={FormField.types.DATE}
      controlProps={dueDateControlProps}
      placeholder="Select a date"
      horizontal
    />
  </FormDialog>
)

export default DueDateFormDialog
