import React from 'react'
import PropTypes from 'prop-types'
import sortBy from 'lodash/sortBy'
import { ResponsiveContainer, LineChart, Line, Tooltip } from 'recharts'
import { toLocalTimeString } from 'utils/date'
import ContentCardWithTimeMeta from '../ContentCardWithTimeMeta'
import { Content, StyledMetric, strokeColor } from './styles'

const OpenIssuesCard = ({ data: { count, history, trend }, ...rest }) => {
  const chartData = React.useMemo(() => {
    const sorted = sortBy(history, 'date')
    return sorted.map((item) => ({
      ...item,
      name: toLocalTimeString(item.date, 'MM/dd/yy'),
    }))
  }, [history])
  return (
    <ContentCardWithTimeMeta {...rest} title="Open Issues">
      <Content>
        <StyledMetric trend={trend}>{count}</StyledMetric>
        <ResponsiveContainer width="100%" height={80}>
          <LineChart data={chartData}>
            <Line
              type="monotone"
              dataKey="count"
              stroke={strokeColor}
              strokeWidth={2}
            />
            <Tooltip labelFormatter={() => ''} />
          </LineChart>
        </ResponsiveContainer>
      </Content>
    </ContentCardWithTimeMeta>
  )
}

OpenIssuesCard.propTypes = {
  data: PropTypes.object.isRequired,
}

export default React.memo(OpenIssuesCard)
