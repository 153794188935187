import React from 'react'
import img from 'images/error/wrench-gear.png'
import ErrorView from '../shared/ErrorView'

export default () => (
  <ErrorView
    imageUrl={img}
    errorCode="501/505"
    errorType="Not Implemented"
    errorMessage="A feature is not implemented and that is why you are seeing this screen."
  />
)
