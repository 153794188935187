import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'components-v2/atoms/Icon'
import Button from 'components-v2/atoms/Button'
import { LabelTypes } from 'constants/index'
import useAuthorizations from 'hooks/useAuthorizations'
import ProjectVendors from 'components/client/project_edit_view/ProjectVendors'
import AssessmentTemplates from 'components/client/project_edit_view/AssessmentTemplates'
import Section from './Section'
import { Content, TemplateSelect } from './styles'

const View = ({
  project,
  assessmentTemplatesEditable,
  addableAssessmentTemplates,
  projectVendorsProps,
  onAssessmentTemplateAdd,
  onAssessmentTemplateDelete,
  ...rest
}) => {
  const [userCanManage] = useAuthorizations('manage')
  return (
    <Content {...rest}>
      <Section
        title="Templates"
        topRight={
          userCanManage &&
          assessmentTemplatesEditable && (
            <TemplateSelect
              placeholder="Select a template..."
              options={addableAssessmentTemplates}
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e.id}
              onChange={onAssessmentTemplateAdd}
            />
          )
        }
      >
        <AssessmentTemplates
          assessmentTemplates={project.assessment_templates}
          editable={userCanManage && assessmentTemplatesEditable}
          onDelete={onAssessmentTemplateDelete}
        />
      </Section>
      <Section
        title={LabelTypes.VENDORS}
        topRight={
          userCanManage && (
            <Button color="primary" onClick={projectVendorsProps.onAdd}>
              <Icon icon="fa fa-plus-circle" />
              {`Add ${LabelTypes.VENDORS.toLowerCase()} to project`}
            </Button>
          )
        }
      >
        <ProjectVendors {...projectVendorsProps} editable={userCanManage} />
      </Section>
    </Content>
  )
}

View.propTypes = {
  project: PropTypes.object.isRequired,
  assessmentTemplatesEditable: PropTypes.bool,
  addableAssessmentTemplates: PropTypes.array.isRequired,
  projectVendorsProps: PropTypes.object.isRequired,
  onAssessmentTemplateAdd: PropTypes.func.isRequired,
  onAssessmentTemplateDelete: PropTypes.func.isRequired,
}

export default View
