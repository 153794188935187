import PropTypes from 'prop-types'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import styled from 'styled-components'
import Input from 'components-v2/atoms/Input'

const PhoneNumberInput = styled(PhoneInput).attrs({
  inputComponent: Input,
})`
  position: relative;

  > .PhoneInputCountry {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10px;
  }

  > .PhoneInputInput {
    padding-left: 55px;
  }
`

// Please refer https://www.npmjs.com/package/react-phone-number-input for more information about props and default values
PhoneNumberInput.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default PhoneNumberInput
