import { getMultiSelectFilterSelectedCount } from 'lib/multiSelectFilter'
import { getEndpointUrl } from 'utils/url'
import { localTimeZoneOffsetInHours, toMonthParam } from 'utils/date'

export const getVendorFacetSetsQueryParams = ({
  assessmentTemplate,
  status,
  riskTier,
  assignee,
  vendorTag,
  assessRange,
  createRange,
  archivedValues,
  ...rest
}) => ({
  ...rest,
  with_templates: assessmentTemplate?.selected,
  status: status?.selected,
  risk_tier_list: riskTier?.selected,
  client_assignee: assignee?.selected,
  unassigned: assignee?.other,
  with_vendor_tags: vendorTag?.selected,
  any: vendorTag?.anyMatch,
  assess_start_date: assessRange?.startDate
    ? toMonthParam(assessRange.startDate)
    : '',
  assess_end_date: assessRange?.endDate
    ? toMonthParam(assessRange.endDate, true)
    : '',
  create_start_date: createRange?.startDate
    ? toMonthParam(createRange.startDate)
    : '',
  create_end_date: createRange?.endDate
    ? toMonthParam(createRange.endDate, true)
    : '',
  tz: localTimeZoneOffsetInHours,
  include_archived: archivedValues?.selected?.indexOf('vendors') === 0,
})

export const getVendorFacetSetsExportUrl = (filters, format) =>
  getEndpointUrl(
    'clientVendorFacetSets',
    undefined,
    getVendorFacetSetsQueryParams(filters),
    format,
  )

export const getMultiSelectFilterLabelWithSelectedCount = (label, filter) => {
  const count = getMultiSelectFilterSelectedCount(filter)
  return `${label} ${count ? `(${count})` : ''}`
}
