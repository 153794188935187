import { useEffect, useRef, useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import isFunction from 'lodash/isFunction'
import {
  useClientAssessmentSectionFetcher,
  useClientAssessmentElementsInfiniteFetcher,
} from 'apis'
import { useAllPagesOfInfiniteQuery, useGlobalLoader, useCurrent } from 'hooks'
import NotificationManager from 'lib/notifications'
import config from 'config'
import { ElementFilterOptions } from './constants'
import { scrollToTable } from './utils'

const getFilterParams = (filter) => {
  const params = {}

  /*
   * For reference here, available response/elmeent filters are defined
   * in the FilterEngine interactor and are as follows:
   *
   * Boolean Filters:
   *  section_heading text_question single_select_question question required
   *  scored unscored needs_review open_issues attachments answered unanswered
   *
   * Value-based Filters
   *  review_score:   array of numeric values, -2..2
   *  search:         string, searches against element label/choices
   *                    and response answer/commment/review note
   */

  if (filter) {
    if (filter[ElementFilterOptions.NEEDS_REVIEW.key]) {
      params.needs_review = true
      params.open_issues = true
      params.conjunction = 'or'
    } else if (filter[ElementFilterOptions.UNANSWERED.key]) {
      params.unanswered = true
    } else if (filter[ElementFilterOptions.UNSCORED.key]) {
      params.unscored = true
    } else if (filter[ElementFilterOptions.SCORED.key]) {
      params.review_score = filter[ElementFilterOptions.SCORED.key].selected
    } else if (filter[ElementFilterOptions.ATTACHMENTS.key]) {
      params.attachments = filter[ElementFilterOptions.ATTACHMENTS.key]
    } else if (filter[ElementFilterOptions.REQUIRED.key]) {
      params.required = true
    }
  }

  return params
}

const getTranslateResponsesParams = (translateResponsesOptions) => {
  const params = {}
  if (translateResponsesOptions.translateResponses) {
    params.translate_responses = true
  }
  if (translateResponsesOptions.translateResponsesTo) {
    params.translate_responses_to =
      translateResponsesOptions.translateResponsesTo
  }
  return params
}

export default function useAssessmentElementsFetcher(
  { assessmentId, sectionId, filter, translateResponsesOptions },
  initialData,
) {
  const [showLoader, hideLoader] = useGlobalLoader()
  const previousSectionIdRef = useRef()
  const sectionIdRef = useCurrent(sectionId)
  const params = {
    ...getTranslateResponsesParams(translateResponsesOptions),
    ...getFilterParams(filter),
  }
  const options = {
    keepPreviousData: true,
    refetchOnMount: false,
    onSuccess: () => {
      if (previousSectionIdRef.current !== sectionIdRef.current) {
        setTimeout(() => {
          scrollToTable(assessmentId)
        }, 500)
      }
      previousSectionIdRef.current = sectionIdRef.current
    },
    onError: () => {
      NotificationManager.error()
      previousSectionIdRef.current = sectionIdRef.current
    },
  }
  const sectionData = useClientAssessmentSectionFetcher(
    {
      assessmentId,
      sectionId,
      params,
    },
    {
      ...options,
      initialData: {
        elements: initialData,
      },
      enabled: !!sectionId,
    },
  )
  const elementsData = useAllPagesOfInfiniteQuery(
    useClientAssessmentElementsInfiniteFetcher(
      {
        assessmentId,
        params: {
          per_page: config.collectionQueryTotalLimit.assessmentElements,
          ...params,
        },
      },
      {
        ...options,
        initialData: {
          pages: [
            {
              results: initialData,
            },
          ],
        },
        enabled: !sectionId,
      },
    ),
  )
  const res = sectionId
    ? {
        ...sectionData,
        data: sectionData.data?.elements,
      }
    : elementsData
  const { isFetching } = res

  useEffect(() => {
    if (!isFetching) {
      return undefined
    }
    const loaderId = showLoader()
    return () => {
      hideLoader(loaderId)
    }
  }, [isFetching])

  return res
}

function useSetElements() {
  const queryClient = useQueryClient()
  return useCallback(
    (updater) => {
      queryClient.setQueriesData(
        useClientAssessmentSectionFetcher.queryKey(),
        (current) =>
          current
            ? {
                ...current,
                elements: updater(current.elements),
              }
            : undefined,
      )
      queryClient.setQueriesData(
        useClientAssessmentElementsInfiniteFetcher.queryKey(),
        (current) =>
          current
            ? {
                ...current,
                pages: current.pages.map((page) => ({
                  ...page,
                  results: updater(page.results),
                })),
              }
            : undefined,
      )
    },
    [queryClient],
  )
}

export function useSetElementResponse() {
  const setElements = useSetElements()
  return useCallback(
    (responseId, updater) => {
      setElements((elements) =>
        elements.map((element) => {
          if (element.response?.id === responseId) {
            return {
              ...element,
              response: isFunction(updater)
                ? updater(element.response)
                : updater,
            }
          }
          return element
        }),
      )
    },
    [setElements],
  )
}

export function useSetElement() {
  const setElements = useSetElements()
  return useCallback(
    (elementId, updater) => {
      setElements((elements) =>
        elements.map((element) => {
          if (element.id === elementId) {
            return isFunction(updater) ? updater(element) : updater
          }
          return element
        }),
      )
    },
    [setElements],
  )
}
