import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { useFormField } from 'hooks'
import { Heading, Item, Index, RadioGroupFormField } from './styles'

const options = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
]

function RiskTierQuestionsFieldGroup({
  name,
  controlProps: { questions = [], pageSize = 5, page = 1 },
}) {
  const field = useFormField(name)
  const from = pageSize * (page - 1)
  const to = Math.min(from + pageSize - 1, questions.length - 1)
  const count = to - from + 1

  useEffect(() => {
    if (!field.value) {
      // TODO: helpers.setValue of Formik causes infinite loop when called inside useEffect in React 18
      // ReactDOM.flushSync is a workaround for now
      // Revisit this when Formik comes with a proper fix.
      // https://github.com/jaredpalmer/formik/issues/3602#issuecomment-1318901271
      ReactDOM.flushSync(() => {
        field.onChange(questions.map((e) => ({ id: e.id, value: undefined })))
      })
    }
  }, [questions, field.value, field.onChange])

  return (
    <div>
      <Heading>
        {count} of {questions.length} questions
      </Heading>
      {questions.map((question, index) => {
        if (index < from || index > to) {
          return null
        }
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Item key={index}>
            <Index>{index + 1}.</Index>
            <RadioGroupFormField
              name={`${name}.${index}.value`}
              label={question.question}
              options={options}
              inline
            />
          </Item>
        )
      })}
    </div>
  )
}

RiskTierQuestionsFieldGroup.propTypes = {
  name: PropTypes.string.isRequired,
  controlProps: PropTypes.object.isRequired,
}

export default RiskTierQuestionsFieldGroup
