import React from 'react'
import * as yup from 'yup'
import FormDialog from 'components-v2/organisms/FormDialog'
import { useClientVendorsFetcher, useClientAssessmentsFetcher } from 'apis'
import { getPageUrl } from 'utils/url'
import { AssessmentStatusTypes, LabelTypes } from 'constants/index'
import config from 'config'
import SelectVendorFormField from '../fields/SelectVendorFormField'
import SelectAssessmentFormField from '../fields/SelectAssessmentFormField'

const formConfig = {
  validationSchema: yup.object().shape({
    vendor: yup.string().label(LabelTypes.VENDOR).required(),
    assessment: yup.string().label('Assessment').required(),
  }),
  initialValues: {
    vendor: undefined,
    assessment: undefined,
  },
}

const VendorAssessmentModal = (props) => {
  const [vendorId, setVendorId] = React.useState()

  // Fetch vendors that have submitted assessments.
  const { data: vendors = [] } = useClientVendorsFetcher({
    params: {
      page: 1,
      per_page: config.collectionQueryTotalLimit.vendors,
      has_assessments_status: [
        AssessmentStatusTypes.SUBMITTED,
        AssessmentStatusTypes.REVIEWED,
      ],
    },
  })

  // Fetch submitted or reviewed assessments using vendorId
  const { data: assessments = [] } = useClientAssessmentsFetcher(
    {
      params: {
        page: 1,
        per_page: config.collectionQueryTotalLimit.assessments,
        vendor_id: vendorId,
        status: [
          AssessmentStatusTypes.SUBMITTED,
          AssessmentStatusTypes.REVIEWED,
        ],
      },
    },
    { enabled: !!vendorId },
  )

  const handleVendorSelect = React.useCallback((newVendorId, setFieldValue) => {
    setVendorId(newVendorId)
    setFieldValue('assessment', undefined, false)
  }, [])

  const handleSubmit = React.useCallback((formData) => {
    window.location.assign(
      getPageUrl('clientAssessmentRiskRatingSummary', {
        id: formData.assessment,
      }),
    )
  }, [])

  return (
    <FormDialog
      {...props}
      title={`${LabelTypes.VENDOR} Assessment Report`}
      submitButtonLabel="View Report"
      formConfig={formConfig}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue }) => (
        <>
          <SelectVendorFormField
            vendors={vendors}
            onChange={(value) => handleVendorSelect(value, setFieldValue)}
          />
          <SelectAssessmentFormField assessments={assessments} />
        </>
      )}
    </FormDialog>
  )
}

export default VendorAssessmentModal
