import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from 'components-v2/atoms/Button'
import PrintButton from 'components-v2/molecules/PrintButton'
import ToolbarGroup from 'components-v2/molecules/ToolbarGroup'
import PageHeader from 'components-v2/organisms/PageHeader'
import AssessmentListView from 'components/client/assessment_list_view'
import Privva from 'Privva'
import { getEndpointUrl } from 'utils/url'
import useAuthorizations from 'hooks/useAuthorizations'

// for visual site consistency
const Wrapper = styled.div`
  margin-top: 20px;
`

const View = ({
  orgId,
  orgRole,
  vendorRoles,
  userPrefs,
  requestEnabled,
  onRequestVendorClick,
}) => {
  const [canPrint] = useAuthorizations('show')
  return (
    <>
      <PageHeader
        title={
          <>
            Assessments{' '}
            {Privva.Utils.development && (
              <a
                className="small"
                href={getEndpointUrl('clientVendors')}
                target="_blank"
              >
                JSON
              </a>
            )}
          </>
        }
      >
        <ToolbarGroup>
          {canPrint && (
            // if there's no vendor request button, print should be primary
            <PrintButton color={requestEnabled ? 'default' : 'primary'} />
          )}
          {requestEnabled && (
            <Button color="primary" onClick={onRequestVendorClick}>
              <i className="fa fa-plus-circle" />
              Request Assessment
            </Button>
          )}
        </ToolbarGroup>
      </PageHeader>
      <Wrapper>
        <AssessmentListView
          orgId={orgId}
          userPrefs={userPrefs}
          orgRole={orgRole}
          vendorRoles={vendorRoles}
        />
      </Wrapper>
    </>
  )
}

View.propTypes = {
  orgId: PropTypes.string.isRequired,
  orgRole: PropTypes.string.isRequired,
  vendorRoles: PropTypes.object.isRequired,
  userPrefs: PropTypes.object.isRequired,
  requestEnabled: PropTypes.bool.isRequired,
  onRequestVendorClick: PropTypes.func.isRequired,
}

export default View
