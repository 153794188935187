import Privva from 'Privva'
import { darken } from 'polished'

const colors = {
  BLACK: '#000',
  WHITE: '#FFF',
  GREY: '#BCC6CE',
  LIGHT_GREY: '#F1F3F7',
  ALT_TITLE_GREY: '#D4D4D4',
  MED_GREY: '#888', // .grey50
  MED_DARK_GREY: '#777777',
  DARK_GREY: '#505050',
  MINE_SHAFT: '#212121',
  GREEN: '#01BC31',
  RED: '#DD2400',
  BLUE: '#2176DB',
  YELLOW: '#E4B93F',
  ORANGE: '#EB5809',
  ATLANTIS: '#8CC93D',
  LEMON: '#FFFF66',
  SMARSH_BLUE: '#5394ff',
}
const PRIMARY = Privva.Utils.theme?.primaryColor || colors.SMARSH_BLUE

export default {
  ...colors,

  // INTENTIONS
  PRIMARY,
  PRIMARY_D05: darken(0.05, PRIMARY),
  BODY_TEXT: colors.DARK_GREY, // Partially used in new designs
  HEADING: colors.MINE_SHAFT, // Partially used in new designs
  DANGER: colors.RED,
}
