import React from 'react'
import PropTypes from 'prop-types'
import { Popover } from '@headlessui/react'
import { Float } from '@headlessui-float/react'
import Button from 'components-v2/atoms/Button'

const PopoverButtonBase = React.forwardRef(
  ({ forwardedAs: Component = Button, ...props }, ref) => (
    <Component
      ref={ref}
      {...props}
      active={props['data-headlessui-state'] === 'open' || props.active}
    />
  ),
)

PopoverButtonBase.propTypes = {
  forwardedAs: PropTypes.elementType,
  active: PropTypes.bool,
  'data-headlessui-state': PropTypes.string,
}

const PopoverButton = React.forwardRef((props, ref) => (
  <Float.Reference>
    <Popover.Button
      {...props}
      as={PopoverButtonBase}
      forwardedAs={props.as}
      ref={ref}
    />
  </Float.Reference>
))

PopoverButton.propTypes = {
  as: PropTypes.elementType,
}

export default PopoverButton
