import { useCallback } from 'react'
import NotificationManager from 'lib/notifications'
import { useGlobalLoader } from 'hooks'
import { useUpdateClientGloborgOrg } from 'apis'
import useCRUDWatchlist from './useCRUDWatchlist'

export default function useGloborgWatchlistsMutations(
  globalOrgsRef,
  watchlistId,
) {
  const [showLoader, hideLoader] = useGlobalLoader()
  const { mutateAsync: updateClientGloborgOrg } = useUpdateClientGloborgOrg()
  const { createWatchlist } = useCRUDWatchlist()

  const updateGloborgWatchlists = useCallback(
    async (globorgId, watchlistIds) => {
      const loaderId = showLoader()
      try {
        await updateClientGloborgOrg({
          id: globorgId,
          data: {
            globorg_org: { watchlist_ids: watchlistIds },
          },
        })
      } catch (error) {
        NotificationManager.error()
      }
      hideLoader(loaderId)
    },
    [updateClientGloborgOrg, showLoader, hideLoader],
  )

  const addGloborgToNewWatchlist = useCallback(
    (globorgId) => {
      const onCreated = (watchlist) => {
        const globorg = globalOrgsRef.current.find((e) => e.id === globorgId)
        if (!globorg) {
          return
        }
        updateGloborgWatchlists(
          globorgId,
          globorg.watchlists
            ? [...globorg.watchlists, watchlist.id]
            : [watchlist.id],
        )
      }
      createWatchlist(onCreated)
    },
    [createWatchlist, updateGloborgWatchlists],
  )

  const removeGloborgFromWatchlist = useCallback(
    (globorgId) => {
      const globorg = globalOrgsRef.current.find((e) => e.id === globorgId)
      if (!globorg) {
        return
      }
      updateGloborgWatchlists(
        globorgId,
        globorg.watchlists?.filter((e) => e !== watchlistId) || [],
      )
    },
    [watchlistId, updateGloborgWatchlists],
  )

  return {
    updateGloborgWatchlists,
    addGloborgToNewWatchlist,
    removeGloborgFromWatchlist,
  }
}
