import styled from 'styled-components'
import IconButton from 'components-v2/atoms/IconButton'

export const Row = styled.div`
  display: flex;
  align-items: flex-start;

  > * {
    flex: 1;
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
`

export const DeleteButton = styled(IconButton)`
  flex: none;
  margin-top: 7px;
`
