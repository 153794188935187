import React from 'react'
import PropTypes from 'prop-types'
import UserEnteredTextRenderer from 'components-v2/molecules/UserEnteredTextRenderer'

const Note = ({ note }) => (
  <UserEnteredTextRenderer text={note.body} formatted={note.formatted} />
)

Note.propTypes = {
  note: PropTypes.shape({
    body: PropTypes.string,
    formatted: PropTypes.bool,
  }).isRequired,
}

export default Note
