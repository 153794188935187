import React, { useState } from 'react'
import styled from 'styled-components'
import A from 'components-v2/atoms/A'
import Button from 'components-v2/atoms/Button'
import Checkbox from 'components-v2/atoms/Checkbox'
import AttributeEditLink from 'components/elements/attribute_edit_link'
import { ModeTypes } from '../constants'

const AnswerColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const Answer = styled.div`
  font-weight: bold;
`

const SectionMeta = styled.div`
  font-size: 90%;
  font-weight: bold;
  color: #666;
`

const CommentText = styled.div`
  font-style: italic;
`

const SourceMeta = styled.div`
  font-size: 75%;
  font-style: italic;
  word-wrap: break-word;
`

const AnswerButton = styled(Button)`
  width: 100px;
  margin-bottom: 5px;
`

const CommentIcon = () => <i className="fa fa-comment" />

export const getTableColumns = (
  mode,
  onChangeIsMatch,
  onToggleSelectedAnswer,
) =>
  [
    {
      id: 'score',
      header: 'Score',
      cell: (info) =>
        Number(info.getValue()).toLocaleString(undefined, {
          style: 'percent',
          minimumFractionDigits: 0,
        }),
      width: 40,
    },
    mode === ModeTypes.MAP && {
      id: 'is_match',
      header: '',
      cell: (info) => (
        <Checkbox
          checked={info.getValue() ?? false}
          onChange={(e) =>
            onChangeIsMatch(info.row.original.id, e.target.checked)
          }
        />
      ),
      width: 40,
    },
    {
      id: 'answer',
      header: '',
      cell: (info) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [commentVisible, setCommentVisible] = useState(false)
        const answer = info.row.original
        const hasComment = answer.answer.comment || answer.write_in_comment
        return (
          <AnswerColumnContent>
            <div>{answer.answer.question}</div>
            <Answer>
              <AttributeEditLink
                dataType="textarea"
                model="question_answer"
                attribute="write_in_answer"
                emptyValueText="Add Answer"
                placeholder="Answer here."
                value={answer.write_in_answer || answer.answer.answer}
                url={answer.api_path}
                disabled={answer.is_new}
                icon={CommentIcon}
                includeIcon
              />
            </Answer>
            <div>
              {hasComment && (
                <A onClick={() => setCommentVisible((current) => !current)}>
                  {commentVisible ? 'Hide Comment' : 'View Comment'}
                </A>
              )}
              {(commentVisible || !hasComment) && (
                <CommentText>
                  <AttributeEditLink
                    dataType="textarea"
                    model="question_answer"
                    attribute="write_in_comment"
                    emptyValueText="Add Comment"
                    placeholder="Comment here."
                    value={answer.write_in_comment || answer.answer.comment}
                    url={answer.api_path}
                    disabled={answer.is_new}
                  />
                </CommentText>
              )}
            </div>
            <SectionMeta>Section: {answer.answer.section}</SectionMeta>
          </AnswerColumnContent>
        )
      },
    },
    {
      id: 'source',
      header: 'Source',
      width: '20%',
      cell: (info) => {
        const answer = info.row.original
        return (
          <div>
            {mode === ModeTypes.ANSWER && (
              <AnswerButton
                size="sm"
                color={answer.is_selected_answer ? 'success' : 'default'}
                onClick={() => onToggleSelectedAnswer(answer.id)}
              >
                {answer.is_selected_answer ? 'Selected' : 'Select Answer'}
              </AnswerButton>
            )}
            <SourceMeta>{answer.answer.answer_set.name}</SourceMeta>
          </div>
        )
      },
    },
  ].filter((e) => e)
