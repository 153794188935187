import React from 'react'
import PropTypes from 'prop-types'
import { Float } from '@headlessui-float/react'
import styled from 'styled-components'
import { Menu } from '@headlessui/react'

const Panel = styled(Menu.Items)`
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

  &:focus {
    outline: none;
  }
`

export const Card = styled.div`
  position: relative;
  text-align: left;
  background-color: #fff;
  border-radius: 4px;
  padding: 5px 0;

  ${(props) =>
    props.size === 'small'
      ? `
    min-width: 80px;
    font-size: 13px;
  `
      : `
    min-width: 160px;
    font-size: 16px;
  `}
`

const Arrow = styled(Float.Arrow)`
  position: absolute;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
`

const MenuItems = ({ arrow, size, children, ...rest }) => (
  <Float.Content {...rest}>
    <Panel>
      {(renderProps) => (
        <>
          {arrow && <Arrow />}
          <Card size={size}>
            {typeof children === 'function' ? children(renderProps) : children}
          </Card>
        </>
      )}
    </Panel>
  </Float.Content>
)

MenuItems.propTypes = {
  arrow: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'default']),
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
}

MenuItems.defaultProps = {
  arrow: false,
  size: 'default',
}

export default MenuItems
