import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { parseOsanoData } from 'components/client/lib/globorg_integration_metrics'
import BaseOverviewCard from '../BaseOverviewCard'
import OsanoScoreChart from '../../OsanoScoreChart'

const SmallOsanoScoreChart = styled(OsanoScoreChart)`
  transform: scale(0.6);
  margin: -50px;
`

const OsanoOverviewCard = ({ data, ...rest }) => {
  const parsed = React.useMemo(() => parseOsanoData(data), [data])
  return (
    <BaseOverviewCard
      {...rest}
      title={
        <span>
          Osano privacy
          <br />
          score
        </span>
      }
      description={parsed.description}
      noData={!parsed.value}
    >
      {parsed.value && (
        <SmallOsanoScoreChart
          score={parsed.value}
          rangeLabel={parsed.rangeLabel}
          color={parsed.color}
        />
      )}
    </BaseOverviewCard>
  )
}

OsanoOverviewCard.propTypes = {
  data: PropTypes.object.isRequired,
}

export default OsanoOverviewCard
