import React from 'react'
import PropTypes from 'prop-types'
import FormField from 'components-v2/molecules/FormField'
import FormDialog from 'components-v2/organisms/FormDialog'

const formConfig = {
  initialValues: {
    options: [],
  },
}

const PrintOptionsModal = ({ printOptions, ...rest }) => (
  <FormDialog
    {...rest}
    title="Print Options"
    submitButtonLabel="Print"
    formConfig={formConfig}
  >
    <FormField
      name="options"
      type={FormField.types.MULTI_CHECKBOX}
      options={printOptions}
      preserveHelpTextSpace={false}
    />
  </FormDialog>
)

PrintOptionsModal.propTypes = {
  printOptions: PropTypes.array.isRequired,
}

export default PrintOptionsModal
