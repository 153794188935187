import React from 'react'
import get from 'lodash/get'
import NoteModal from 'components/shared/NoteModal'
import NotificationManager from 'lib/notifications'
import { useModal, useGlobalLoader } from 'hooks'
import { useUpdateClientIssue } from 'apis'

const NOTE_MAX_LENGTH = 5000

export default function useEditNote(issue) {
  const { mutateAsync: updateClientIssue } = useUpdateClientIssue()
  const [openModal, closeModal] = useModal(NoteModal)
  const [showLoader, hideLoader] = useGlobalLoader()

  return React.useCallback(() => {
    const note = get(issue, 'notes[0]')
    openModal({
      title: `Notes`,
      maxLength: NOTE_MAX_LENGTH,
      note,
      supportFormatting: true,
      onSubmit: ({ body, formatted }) => {
        const loaderId = showLoader()
        const assessmentUpdate = {
          // eslint-disable-next-line camelcase
          notes_attributes: [
            {
              id: get(note, 'id', null),
              body,
              formatted,
            },
          ],
        }
        return updateClientIssue({
          id: issue.id,
          data: {
            issue: assessmentUpdate,
          },
        })
          .then(() => closeModal())
          .catch(() => NotificationManager.error())
          .then(() => hideLoader(loaderId))
      },
    })
  }, [issue, openModal, closeModal, showLoader, hideLoader])
}
