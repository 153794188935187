import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import FormDialog from 'components-v2/organisms/FormDialog'
import TemplateFilterFormFields, {
  validationSchema as TemplatesValidationSchema,
} from '../TemplateFilterFormFields'
import VendorFilterFormFields, {
  validationSchema as VendorsValidationSchema,
} from '../VendorFilterFormFields'

const validationSchema = yup.object().shape({
  templates: TemplatesValidationSchema,
  vendors: VendorsValidationSchema,
})

const EditNotificationsModal = ({
  eventSubscriber,
  assessmentTemplates,
  projectVendorsProps,
  ...rest
}) => {
  const formConfig = useMemo(
    () => ({
      initialValues: {
        templatesFilter: !!eventSubscriber.templates.length,
        templates: eventSubscriber.templates || [],
        vendorsFilter: !!eventSubscriber.vendors.length,
        vendors: eventSubscriber.vendors || [],
      },
      validationSchema,
    }),
    [eventSubscriber],
  )
  return (
    <FormDialog
      {...rest}
      title="Edit Notifications"
      submitButtonLabel="Save"
      formConfig={formConfig}
    >
      <TemplateFilterFormFields assessmentTemplates={assessmentTemplates} />
      <VendorFilterFormFields
        projectVendors={projectVendorsProps.projectVendors}
        users={[{ type: 'user', value: eventSubscriber.id }]}
      />
    </FormDialog>
  )
}

EditNotificationsModal.propTypes = {
  eventSubscriber: PropTypes.object.isRequired,
  assessmentTemplates: PropTypes.array,
  projectVendorsProps: PropTypes.object,
}

export default EditNotificationsModal
