import styled from 'styled-components'
import { colors } from 'styles'

export const Menu = styled.div`
  font-size: 18px;
  line-height: 14px;
`

export const MenuItemLabel = styled.div`
  font-weight: ${({ active }) => (active ? 'bold' : 500)};
  color: ${({ active }) => (active ? colors.PRIMARY : colors.GREY)};
`

export const SubMenu = styled.div`
  font-size: 15px;
  line-height: 16px;
  margin-top: 21px;
  margin-left: 11px;
`

export const MenuItem = styled.div`
  &:not(:last-child) {
    margin-bottom: 36px;
  }

  ${SubMenu} > & {
    &:not(:last-child) {
      margin-bottom: 14px;
    }
  }
`
