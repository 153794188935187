/* eslint-disable */
import reject from 'lodash/reject'
import isEmpty from 'lodash/isEmpty'
import forEach from 'lodash/forEach'
import isEqual from 'lodash/isEqual'
import union from 'lodash/union'
import pull from 'lodash/pull'
import cloneDeep from 'lodash/cloneDeep'
import intersection from 'lodash/intersection'

export default class AlterAttributeRule {
  /** merges rule, enforcing only one alterAttribute rule per attribute per target.
   * deletes alterAttribute rules with empty targets */
  static mergeRule(newRule, oldRules) {
    const existingRules = cloneDeep(oldRules)
    let foundMatch = false
    // remove any alterAttribute choices/targets with same attribute modifier or add new targets if same
    forEach(existingRules, (rule) => {
      if (rule.type === newRule.type && rule.attribute === newRule.attribute) {
        if (
          isEqual(newRule.choices.sort(), rule.choices.sort()) &&
          rule.conjunction === newRule.conjunction &&
          rule.value === newRule.value
        ) {
          // matching rule, add targets
          rule.targets = union(rule.targets, newRule.targets)
          foundMatch = true
        } else {
          newRule.targets.forEach((t) => pull(rule.targets, t))
        }
      }
    })
    if (!foundMatch) {
      existingRules.push(newRule)
    }
    return AlterAttributeRule.removeEmptyRules(
      existingRules,
      newRule.type,
      newRule.attribute,
    )
  }

  /** removes any targets or choices from alterAttribute rules that modify specified attribute.
   * deletes alterAttribute rules with empty targets or choices */
  static removeFromRules(rules, type, attribute, targets = [], choices = []) {
    const rulesCopy = cloneDeep(rules)
    // remove any alterAttribute choices/targets with same attribute modifier
    forEach(rulesCopy, (rule) => {
      if (rule.type === type && rule.attribute === attribute) {
        targets.forEach((t) => pull(rule.targets, t))
        choices.forEach((c) => pull(rule.choices, c))
      }
    })
    return AlterAttributeRule.removeEmptyRules(rulesCopy, type, attribute)
  }

  /** replace any choices from alterAttribute rules that modify specified attribute.
   * deletes alterAttribute rules with empty targets or choices */
  static replaceChoices(rules, type, attribute, choiceIdMap = {}) {
    const rulesCopy = cloneDeep(rules)
    forEach(rulesCopy, (rule) => {
      if (rule.type === type && rule.attribute === attribute) {
        forEach(choiceIdMap, (value, key) => {
          const oldId = key
          const newId = value
          if (rule.choices.indexOf(oldId) !== -1) {
            pull(rule.choices, oldId)
            if (newId) {
              rule.choices.push(newId)
            }
          }
        })
      }
    })
    return AlterAttributeRule.removeEmptyRules(rulesCopy, type, attribute)
  }

  static filterValidRules(rules, type, attribute, targets, choices) {
    const rulesCopy = cloneDeep(rules)
    forEach(rulesCopy, (rule) => {
      if (rule.type === type && rule.attribute === attribute) {
        rule.targets = intersection(rule.targets, targets)
        rule.choices = intersection(rule.choices, choices)
      }
    })
    return AlterAttributeRule.removeEmptyRules(rulesCopy, type, attribute)
  }

  // Remove rules with no choices or targets
  static removeEmptyRules(rules, type, attribute) {
    return reject(
      rules,
      (r) =>
        r.type === type &&
        r.attribute === attribute &&
        (isEmpty(r.choices) || isEmpty(r.targets)),
    )
  }
}
