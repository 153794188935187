import React from 'react'
import PropTypes from 'prop-types'
import filter from 'lodash/filter'
import { getSectionsFromElements } from 'lib/assessment'
import View from './View'

const AssessmentListingContainer = ({
  libraryProfile,
  data: assessmentListing,
}) => {
  const assessment = React.useMemo(
    () => assessmentListing?.listing_item || {},
    [assessmentListing],
  )
  const elements = React.useMemo(
    () => assessmentListing?.listing_item?.elements || [],
    [assessmentListing],
  )
  const sections = React.useMemo(
    () => getSectionsFromElements(elements),
    [elements],
  )
  const [selectedSectionId, setSelectedSectionId] = React.useState(
    sections[0]?.id,
  )
  const filteredElements = React.useMemo(() => {
    if (selectedSectionId) {
      return filter(elements, { parent_id: selectedSectionId })
    }
    return elements
  }, [elements, selectedSectionId])

  return (
    <View
      libraryProfile={libraryProfile}
      assessment={assessment}
      elements={filteredElements}
      sections={sections}
      selectedSectionId={selectedSectionId}
      onSectionChange={setSelectedSectionId}
    />
  )
}

AssessmentListingContainer.propTypes = {
  libraryProfile: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export default AssessmentListingContainer
