import React from 'react'
import NotificationManager from 'lib/notifications'
import { useModal, useGlobalLoader } from 'hooks'
import { EditableProjectStatusTypes } from 'lib/project'
import {
  useClientProjectNamesFetcher,
  useClientNewProjectFetcher,
  useCreateClientProject,
  useAddMultiProjectVendorsToClientProject,
} from 'apis'
import { getPageUrl } from 'utils/url'
import SendAssessmentModal, { NEW_OPTION_VALUE } from './SendAssessmentModal'

export default function useSendAssessment(vendor) {
  const { mutateAsync: createClientProject } = useCreateClientProject()
  const { mutateAsync: addMultiProjectVendorsToClientProject } =
    useAddMultiProjectVendorsToClientProject()
  /* eslint-disable-next-line no-unused-vars */
  const [openSendAssessmentModal, _, updateSendAssessmentModal] =
    useModal(SendAssessmentModal)
  const [showLoader, hideLoader] = useGlobalLoader()
  const [needData, setNeedData] = React.useState(false)
  const { id: vendorId, name: vendorName } = vendor
  const { data: projects, isLoading } = useClientProjectNamesFetcher(
    {
      params: {
        not_having_vendor: vendorId,
        status_list: EditableProjectStatusTypes,
      },
    },
    { enabled: needData },
  )
  const { data: newProject } = useClientNewProjectFetcher(
    {},
    { enabled: needData },
  )
  const defaultCustomEmailSubject = newProject?.default_custom_email_subject
  const defaultCustomEmailText = newProject?.default_custom_email_text

  React.useEffect(() => {
    updateSendAssessmentModal({
      projects,
      projectsLoading: isLoading,
      defaultCustomEmailSubject,
      defaultCustomEmailText,
    })
  }, [
    projects,
    isLoading,
    defaultCustomEmailSubject,
    defaultCustomEmailText,
    updateSendAssessmentModal,
  ])

  return React.useCallback(() => {
    setNeedData(true)

    const onSubmit = (formData) => {
      const loaderId = showLoader()

      let promise = Promise.resolve(formData.projectId)
      // In case of new, let's create new project first
      if (formData.projectId === NEW_OPTION_VALUE) {
        const project = {
          name: formData.projectName,
          assessment_template_ids: [formData.assessmentTemplateId],
          custom_email_subject: formData.customEmailSubject,
          custom_email_text: formData.customEmailText,
          invitation_reminder: formData.invitationReminder,
        }
        promise = createClientProject({ data: { project } }).then(
          (response) => response.id,
        )
      }

      return promise
        .then((projectId) => {
          const projectVendors = [
            {
              vendor_id: vendorId,
              date_range_attributes: {
                start_at: formData.launchDate,
                end_at: formData.dueDate,
              },
            },
          ]
          return addMultiProjectVendorsToClientProject({
            id: projectId,
            data: {
              project_vendors: projectVendors,
            },
          }).then(() => {
            // Take user to the project view
            window.location.href = getPageUrl('clientProject', {
              id: projectId,
            })
          })
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)
          NotificationManager.error()
          hideLoader(loaderId)
        })
    }

    openSendAssessmentModal({
      vendorName,
      projects,
      projectsLoading: isLoading,
      defaultCustomEmailSubject,
      defaultCustomEmailText,
      onSubmit,
    })
  }, [
    vendorId,
    vendorName,
    projects,
    isLoading,
    defaultCustomEmailSubject,
    defaultCustomEmailText,
    openSendAssessmentModal,
    showLoader,
    hideLoader,
  ])
}
