import styled from 'styled-components'
import Table from 'components-v2/molecules/Table'
import { colors, fonts, mixins } from 'styles'

export const classes = {
  SECTION_HEADING_ROW: 'row-section-heading',
  QUESTION_ROW: 'row-question',
  QUESTION_CELL: 'cell-question',
  SCORE_CELL: 'cell-score',
  QUESTION_COLUMN_HEADER: 'column-header-question',
  SCORE_COLUMN_HEADER: 'column-header-score',
}

export const TemplateLabel = styled.h6`
  color: ${colors.BODY_TEXT};
  margin-bottom: 0;
`

export const TemplateName = styled.h3`
  margin-top: 7px;
  margin-bottom: 17px;
`

export const StyledTable = styled(Table).attrs({ type: Table.types.ALT })`
  tr:nth-child(n + 8),
  tr:nth-child(n + 8) td,
  tfoot {
    ${mixins.avoidPageBreak}
  }

  .${classes.QUESTION_CELL} {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .${classes.SCORE_CELL} {
    padding: 0;
    font-family: ${fonts.SECONDARY};
    font-size: 15px;
    font-weight: bold;
  }

  .${classes.QUESTION_COLUMN_HEADER} {
    width: 40%;
  }

  .${classes.SCORE_COLUMN_HEADER} {
    background: #e1e2e6;
    width: ${(props) => 60 / props.scoreColumnsLength}%;
  }

  .${classes.SECTION_HEADING_ROW} .${classes.QUESTION_CELL} {
    font-weight: bold;
  }

  .${classes.QUESTION_ROW} {
    background: #f8f9fb;
  }
`
