import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import ProgressBar from 'components-v2/molecules/ProgressBar'
import Tooltip from 'components-v2/molecules/Tooltip'
import Widget from '../Widget'
import { getAssessmentGradeColor } from '../utils'
import { Item, Top, VendorName, VendorLabel, Status } from './styles'

const AssessmentScoreChartWidget = ({ assessments, canLink, ...rest }) => {
  const VendorNameComponent = canLink ? VendorName : VendorLabel
  return (
    <Widget {...rest}>
      {map(assessments, (assessment) => {
        const riskRatingDisplay =
          assessment.risk_rating == null
            ? '(N/A)'
            : `${assessment.risk_rating}%`
        const progressBar = (
          <Tooltip
            parent={
              <ProgressBar
                now={assessment.risk_rating}
                color={getAssessmentGradeColor(
                  assessment.grade,
                  assessment.grade_color,
                )}
              />
            }
          >
            {assessment.grade_label} {riskRatingDisplay}
          </Tooltip>
        )
        return (
          <Item key={assessment.id}>
            <Top>
              <VendorNameComponent
                href={assessment.path}
                title={assessment.vendor.name}
              >
                {assessment.vendor.name}
              </VendorNameComponent>
              <Status
                color={getAssessmentGradeColor(
                  assessment.grade,
                  assessment.grade_color,
                )}
              >{`${assessment.grade_label} ${riskRatingDisplay}`}</Status>
            </Top>
            {canLink ? (
              <a href={assessment.path}>{progressBar}</a>
            ) : (
              progressBar
            )}
          </Item>
        )
      })}
    </Widget>
  )
}

AssessmentScoreChartWidget.propTypes = {
  assessments: PropTypes.array.isRequired,
  canLink: PropTypes.bool,
}

export default React.memo(AssessmentScoreChartWidget)
