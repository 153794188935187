import styled from 'styled-components'
import Button from 'components-v2/atoms/Button'

export const Wrapper = styled.div`
  padding: 10px;
`

export const StyledButton = styled(Button)`
  flex: none;
  width: 100px;
  margin-left: 10px;
`

export const Title = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
`
