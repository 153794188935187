import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { Formik } from 'formik'
import * as yup from 'yup'
import Icon from 'components-v2/atoms/Icon'
import { FormFieldTypes } from 'components-v2/molecules/FormField'
import { Row, Col } from 'components-v2/organisms/Layout'
import FormikAutoSave from 'components-v2/utils/FormikAutoSave'
import FormikIsValidDetector from 'components-v2/utils/FormikIsValidDetector'
import { toDate } from 'utils/date'
import { useUsersFetcher } from 'apis'
import { LabelTypes, ProjectExtensionTypes } from 'constants/index'
import { StyledFormField as FormField } from '../styles'
import {
  AddButton,
  ExtensionRequestFieldWrapper,
  SmallFormField,
  AutoExtensionOptionsWrapper,
} from './styles'

const theme = {
  hGutter: 35,
}

const dateControlProps = {
  dateFormat: 'yyyy/MM/dd h:mm aa',
  fullWidth: true,
  showTimeSelect: true,
  todayButton: 'Today',
  minDateTime: new Date(),
}

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .label('Name')
    .required()
    .max(255, 'Maximum of 255 characters'),
  nickname: yup.string().nullable().max(255, 'Maximum of 255 characters'),
  description: yup.string().nullable().max(5000, 'Maximum of 5000 characters'),
  instructions: yup
    .string()
    .nullable()
    .max(10000, 'Maximum of 10,000 characters'),
  max_extension_days: yup.mixed().when('show_extension_request', {
    is: true,
    then: yup
      .number()
      .nullable()
      .typeError('must be a number')
      .required('is required')
      .positive('must be positive')
      .integer('must be an integer')
      .max(180, 'Maximum of 180 days')
      .test('is-valid', 'invalid', (value, { parent, createError }) => {
        if (
          value &&
          parent.days_per_extension &&
          parent.max_number_of_extensions
        ) {
          const min =
            parent.days_per_extension * (parent.max_number_of_extensions - 1)
          if (value < min) {
            return createError({
              message: `Based on extension parameters, maximum extension days should be ${min} days or greater`,
            })
          }
        }
        return true
      }),
  }),
  days_per_extension: yup.mixed().when('show_extension_request', {
    is: true,
    then: yup
      .number()
      .nullable()
      .typeError('must be a number')
      .required('is required')
      .positive('must be positive')
      .integer('must be an integer')
      .max(30, 'Maximum of 30 days')
      .test(
        'is-valid',
        'Cannot exceed the maximum number of extension days allowed',
        (value, { parent }) =>
          !value ||
          !parent?.max_extension_days ||
          value <= parent?.max_extension_days,
      ),
  }),
  max_number_of_extensions: yup.mixed().when('show_extension_request', {
    is: true,
    then: yup
      .number()
      .nullable()
      .typeError('must be a number')
      .required('is required')
      .positive('must be positive')
      .integer('must be an integer')
      .max(20, 'Maximum of 20 requests'),
  }),
})

const DetailsForm = ({
  project,
  editable,
  onValidStateChange,
  onSubmit,
  ...rest
}) => {
  const { data: users } = useUsersFetcher({ params: { assignable: true } })

  const initialValues = React.useMemo(
    () => ({
      name: project.name,
      nickname_enabled: project.nickname_enabled,
      nickname: project.nickname,
      description: project.description,
      contact_user_id: project.contact_user?.id,
      instructions: project.instructions,
      launch_at: toDate(project.launch_at),
      due_at: toDate(project.due_at),
      show_extension_request:
        project.extension_type === ProjectExtensionTypes.MANUAL ||
        project.extension_type === ProjectExtensionTypes.AUTO,
      max_extension_days:
        project.max_extension_minutes !== null &&
        project.max_extension_minutes !== undefined
          ? project.max_extension_minutes / (60 * 24)
          : 30,
      auto_extension_request:
        project.extension_type === ProjectExtensionTypes.AUTO,
      days_per_extension:
        project.minutes_per_extension !== null &&
        project.minutes_per_extension !== undefined
          ? project.minutes_per_extension / (60 * 24)
          : 7,
      max_number_of_extensions:
        project.max_number_of_extensions !== null &&
        project.max_number_of_extensions !== undefined
          ? project.max_number_of_extensions
          : 3,
    }),
    [project],
  )
  const [estimatedDatesVisible, setEstimatedDatesVisible] = React.useState(
    !!initialValues.launch_at || !!initialValues.due_at,
  )

  const handleSubmit = useCallback(
    (formData, actions) => {
      const {
        show_extension_request: showExtensionRequest,
        max_extension_days: maxExtensionDays,
        auto_extension_request: autoExtensionRequest,
        days_per_extension: daysPerExtension,
        max_number_of_extensions: maxNumberOfExtensions,
        ...restFormData
      } = formData
      const data = {
        ...restFormData,
      }
      if (!showExtensionRequest) {
        data.extension_type = ProjectExtensionTypes.NONE
      } else {
        data.extension_type = autoExtensionRequest
          ? ProjectExtensionTypes.AUTO
          : ProjectExtensionTypes.MANUAL
        data.max_extension_minutes = maxExtensionDays * 60 * 24
        data.minutes_per_extension = daysPerExtension * 60 * 24
        data.max_number_of_extensions = maxNumberOfExtensions
      }
      return onSubmit(data, actions)
    },
    [onSubmit],
  )

  return (
    <ThemeProvider theme={theme}>
      <Formik
        {...rest}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <>
            <FormikAutoSave />
            <FormikIsValidDetector onValidStateChange={onValidStateChange} />
            <FormField
              name="name"
              label="Project name"
              disabled={!editable || project.reassessment}
              helpMessage={`This will be visible to ${LabelTypes.VENDORS.toLowerCase()}.`}
            />
            <FormField
              type={FormFieldTypes.CHECKBOX}
              name="nickname_enabled"
              label="Use an internal nickname"
              helpMessage={`This will be visible to you instead of the project name. It will not be visible to ${LabelTypes.VENDORS.toLowerCase()}.`}
            />
            {values.nickname_enabled && (
              <FormField name="nickname" label="Internal nickname" />
            )}
            <FormField
              type={FormFieldTypes.TEXTAREA}
              name="description"
              label="Project description"
              disabled={!editable}
            />
            <Row>
              <Col md={6}>
                <FormField
                  name="contact_user_id"
                  type={FormFieldTypes.SELECT}
                  label="Project contact"
                  options={users?.map((user) => ({
                    label: user.name,
                    value: user.id,
                  }))}
                  controlProps={{
                    placeholderSelectable: true,
                  }}
                  disabled={!editable}
                />
              </Col>
            </Row>
            <FormField
              type={FormFieldTypes.TEXTAREA}
              name="instructions"
              label={`${LabelTypes.VENDOR} instructions`}
              disabled={!editable}
            />
            {estimatedDatesVisible ? (
              <Row>
                <Col md={6}>
                  <FormField
                    type={FormFieldTypes.DATE}
                    name="launch_at"
                    label="Estimated start date"
                    controlProps={dateControlProps}
                  />
                </Col>
                <Col md={6}>
                  <FormField
                    type={FormFieldTypes.DATE}
                    name="due_at"
                    label="Estimated completion date"
                    controlProps={dateControlProps}
                  />
                </Col>
              </Row>
            ) : (
              <AddButton onClick={() => setEstimatedDatesVisible(true)}>
                <Icon icon="fa fa-plus-circle" />
                Add estimated start and completion dates
              </AddButton>
            )}
            <ExtensionRequestFieldWrapper>
              <FormField
                type={FormFieldTypes.TOGGLE}
                name="show_extension_request"
                preserveHelpTextSpace={false}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <strong>Show Extension Request Option</strong>
            </ExtensionRequestFieldWrapper>
            {values.show_extension_request && (
              <AutoExtensionOptionsWrapper>
                <ExtensionRequestFieldWrapper>
                  <FormField
                    type={FormFieldTypes.CHECKBOX}
                    name="auto_extension_request"
                    preserveHelpTextSpace={false}
                  />
                  &nbsp;&nbsp;
                  <span>Automatically approve extension requests</span>
                </ExtensionRequestFieldWrapper>
                <div>
                  Show extension option for a maximum of
                  <SmallFormField name="max_extension_days" />
                  days after the assessment expiration date
                </div>
                <div>
                  Each extension duration:
                  <SmallFormField name="days_per_extension" />
                  days
                </div>
                <div>
                  Maximum number of extensions:
                  <SmallFormField name="max_number_of_extensions" />
                  time(s).
                </div>
              </AutoExtensionOptionsWrapper>
            )}
          </>
        )}
      </Formik>
    </ThemeProvider>
  )
}

DetailsForm.propTypes = {
  project: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  onValidStateChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default DetailsForm
