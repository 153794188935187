import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import MenuDropdown from 'components-v2/molecules/MenuDropdown'
import Pagination from 'components-v2/molecules/Pagination'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`

function BaseFooterPagination({
  pageSizes,
  hidePageSizes,
  hidePageListOnlyOnePage,
  table,
}) {
  const showPageList = hidePageListOnlyOnePage
    ? table.getPageCount() > 1
    : table.getPageCount() !== -1
  return (
    <Wrapper>
      <div>
        {!hidePageSizes && (
          <MenuDropdown
            placement="bottom-start"
            title={table.getState().pagination.pageSize?.toString()}
            onSelect={table.setPageSize}
          >
            {pageSizes.map((pageSize) => (
              <MenuDropdown.Item key={pageSize} eventKey={pageSize}>
                {pageSize}
              </MenuDropdown.Item>
            ))}
          </MenuDropdown>
        )}
      </div>
      <div>
        {showPageList && (
          <Pagination
            currentPage={
              table.getState().pagination.pageIndex + 1 > table.getPageCount()
                ? 0
                : table.getState().pagination.pageIndex + 1
            }
            totalPages={table.getPageCount()}
            hideFirstAndLastPageLinks
            onChange={(v) => table.setPageIndex(v - 1)}
          />
        )}
      </div>
    </Wrapper>
  )
}

BaseFooterPagination.propTypes = {
  pageSizes: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  hidePageSizes: PropTypes.bool,
  hidePageListOnlyOnePage: PropTypes.bool,
  table: PropTypes.object.isRequired,
}

export default BaseFooterPagination
