import styled from 'styled-components'

export const ContractRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const ContractAmt = styled.div`
  margin-right: 20px;
  flex: auto;
`

export const ContractPiece = styled.div`
  margin-right: 20px;
  flex: auto;
`

export const ContractValue = styled.span`
  margin-left: 10px;
`
