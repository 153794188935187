import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import { Combobox } from '@headlessui/react'
import { withReactQuery } from 'hocs'
import {
  Wrapper,
  SearchInput,
  ResultsContainer,
  Result,
  ResultHeading,
  ResultItem,
} from './styles'

const SearchBar = ({ url }) => {
  const [searchTerm, setSearchTerm] = React.useState('')
  const wrapperRef = React.useRef()
  const { data } = useQuery(
    ['search'],
    () => axios.get(url, { params: { q: searchTerm } }).then((res) => res.data),
    {
      enabled: searchTerm.length > 2,
    },
  )

  const filtered = React.useMemo(() => {
    if (!data) {
      return data
    }
    return Object.keys(data).reduce((acc, cur) => {
      if (data[cur].length > 0) {
        acc[cur] = data[cur]
      }
      return acc
    }, {})
  }, [data])

  const handleSelect = React.useCallback((e) => {
    if (e.length === 1) {
      window.location = e[0].path
    }
  }, [])

  return (
    <Wrapper ref={wrapperRef}>
      {/* Tab key press triggers selection of active option and then onChange callback. We want the active option to be selected on Enter press only. Setting "multiple" prop does the trick */}
      <Combobox multiple nullable onChange={handleSelect}>
        <SearchInput
          value={searchTerm}
          placeholder="Search"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {filtered && (
          <Combobox.Options as="div">
            <ResultsContainer className="dyn-lang">
              {isEmpty(filtered) ? (
                <span>Sorry, no matches.</span>
              ) : (
                Object.entries(filtered).map(([key, value]) => (
                  <Result key={key}>
                    <ResultHeading>{key.replace(/_/g, ' ')}</ResultHeading>
                    <ul className="list-unstyled wrap-second-line">
                      {Array.from(value).map((element) => (
                        <Combobox.Option key={element.path} value={element}>
                          {({ active }) => (
                            <ResultItem href={element.path} active={active}>
                              {element.name} {active}
                            </ResultItem>
                          )}
                        </Combobox.Option>
                      ))}
                    </ul>
                  </Result>
                ))
              )}
            </ResultsContainer>
          </Combobox.Options>
        )}
      </Combobox>
    </Wrapper>
  )
}

SearchBar.propTypes = {
  url: PropTypes.string.isRequired,
}

export default withReactQuery()(SearchBar)
