import styled from 'styled-components'

export const Wrapper = styled.div`
  @media (min-width: 786px) {
    display: flex;
  }
`

export const Left = styled.div`
  flex: auto;
  overflow: hidden;

  > * {
    margin-bottom: 25px;
  }
`

export const Right = styled.div`
  @media (min-width: 786px) {
    flex: none;
    width: 36%;
    margin-left: 25px;
  }

  > * {
    margin-bottom: 25px;
  }
`
