import { useCallback, useState } from 'react'
import { useGlobalLoader, useConfirm } from 'hooks'
import NotificationManager from 'lib/notifications'
import {
  useDeleteClientProject,
  useLaunchClientProjectEarly,
  useCloseClientProject,
  useRelaunchClientProject,
  useArchiveClientProject,
  useUnarchiveClientProject,
  useReviewClientProject,
} from 'apis'
import { getPageUrl } from 'utils/url'

export default function useProjectMutations({
  project,
  shouldPromptForCloseEarly,
}) {
  const [error, setError] = useState('')
  const [showLoader, hideLoader] = useGlobalLoader()
  const confirm = useConfirm()
  const { mutateAsync: deleteClientProject } = useDeleteClientProject()
  const { mutateAsync: launchClientProjectEarly } =
    useLaunchClientProjectEarly()
  const { mutateAsync: closeClientProject } = useCloseClientProject()
  const { mutateAsync: relaunchClientProject } = useRelaunchClientProject()
  const { mutateAsync: archiveClientProject } = useArchiveClientProject()
  const { mutateAsync: unarchiveClientProject } = useUnarchiveClientProject()
  const { mutateAsync: reviewClientProject } = useReviewClientProject()

  const deleteProject = useCallback(async () => {
    const confirmed = await confirm(
      {
        title: 'Deleting Project',
        body: `Are you sure you want to delete ${project.friendly_name}?`,
      },
      true,
    )
    if (!confirmed) {
      return
    }

    const loaderId = showLoader()
    try {
      await deleteClientProject({
        id: project.id,
      })
      NotificationManager.success('The project has been deleted successfully.')
      setTimeout(() => {
        window.location.href = getPageUrl('clientProjects')
      }, 1200)
    } catch {
      NotificationManager.error()
    }
    hideLoader(loaderId)
  }, [project, showLoader, hideLoader, confirm, deleteClientProject])

  const launchProjectEarly = useCallback(async () => {
    const confirmed = await confirm(
      {
        title: 'Launching Project Early',
        body: `Are you sure want to launch this project? This will launch any vendors with a launch date within the next 24 hours?`,
      },
      true,
    )
    if (!confirmed) {
      return
    }

    const loaderId = showLoader()
    try {
      await launchClientProjectEarly({
        id: project.id,
      })
      NotificationManager.success('The project has been launched successfully.')
      // TODO: Implement optimistic updates instead of page reloading
      setTimeout(() => {
        window.location.reload()
      }, 1200)
    } catch (err) {
      setError(err.response.data?.errors?.base?.join('/n'))
    }
    hideLoader(loaderId)
  }, [project, showLoader, hideLoader, confirm, launchClientProjectEarly])

  const handleDismissError = useCallback(() => {
    setError('')
  }, [])

  const closeProject = useCallback(async () => {
    if (shouldPromptForCloseEarly) {
      const confirmed = await confirm(
        {
          title: 'Closing Project',
          body: `Are you sure? Closing early will prohibit any additional submissions for this project.`,
        },
        true,
      )
      if (!confirmed) {
        return
      }
    }

    const loaderId = showLoader()
    try {
      await closeClientProject({
        id: project.id,
      })
      NotificationManager.success('The project has been closed successfully.')
      // TODO: Implement optimistic updates instead of page reloading
      setTimeout(() => {
        window.location.reload()
      }, 1200)
    } catch {
      NotificationManager.error()
    }
    hideLoader(loaderId)
  }, [
    project,
    showLoader,
    hideLoader,
    confirm,
    closeClientProject,
    shouldPromptForCloseEarly,
  ])

  const relaunchProject = useCallback(async () => {
    const loaderId = showLoader()
    try {
      await relaunchClientProject({
        id: project.id,
      })
      NotificationManager.success(
        'The project has been relaunched successfully.',
      )
      // TODO: Implement optimistic updates instead of page reloading
      setTimeout(() => {
        window.location.reload()
      }, 1200)
    } catch {
      NotificationManager.error()
    }
    hideLoader(loaderId)
  }, [project, showLoader, hideLoader, confirm, relaunchClientProject])

  const archiveProject = useCallback(async () => {
    const confirmed = await confirm(
      {
        title: 'Archiving Project',
        body: `Are you sure? Archiving the project will remove it from your viewable project list`,
      },
      true,
    )
    if (!confirmed) {
      return
    }

    const loaderId = showLoader()
    try {
      await archiveClientProject({
        id: project.id,
      })
      NotificationManager.success('The project has been archived successfully.')
      // TODO: Implement optimistic updates instead of page reloading
      setTimeout(() => {
        window.location.href = getPageUrl('clientProjects')
      }, 1200)
    } catch {
      NotificationManager.error()
    }
    hideLoader(loaderId)
  }, [project, showLoader, hideLoader, confirm, archiveClientProject])

  const unarchiveProject = useCallback(async () => {
    const confirmed = await confirm(
      {
        title: 'Unarchiving Project',
        body: `Are you sure? Unarchiving the project will return it to 'Closed' status`,
      },
      true,
    )
    if (!confirmed) {
      return
    }

    const loaderId = showLoader()
    try {
      await unarchiveClientProject({
        id: project.id,
      })
      NotificationManager.success(
        'The project has been unarchived successfully.',
      )
      // TODO: Implement optimistic updates instead of page reloading
      setTimeout(() => {
        window.location.href = getPageUrl('clientProjects')
      }, 1200)
    } catch {
      NotificationManager.error()
    }
    hideLoader(loaderId)
  }, [project, showLoader, hideLoader, confirm, unarchiveClientProject])

  const reviewProject = useCallback(async () => {
    const confirmed = await confirm(
      {
        title: 'Review Project',
        body: `Are you sure? Marking the project 'reviewed' indicates that grading is complete`,
      },
      true,
    )
    if (!confirmed) {
      return
    }

    const loaderId = showLoader()
    try {
      await reviewClientProject({
        id: project.id,
      })
      NotificationManager.success(
        'The project has been marked as reviewed successfully.',
      )
      // TODO: Implement optimistic updates instead of page reloading
      setTimeout(() => {
        window.location.reload()
      }, 1200)
    } catch {
      NotificationManager.error()
    }
    hideLoader(loaderId)
  }, [project, showLoader, hideLoader, confirm, reviewClientProject])

  return {
    deleteProject,
    launchProjectEarly,
    closeProject,
    relaunchProject,
    archiveProject,
    unarchiveProject,
    reviewProject,
    handleDismissError,
    error,
  }
}
