import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import Toggle from 'components-v2/atoms/Toggle'
import Table from 'components-v2/molecules/Table'
import Toolbar, { SearchInput } from 'components-v2/molecules/Table/Toolbar'
import SimpleDialog from 'components-v2/molecules/SimpleDialog'
import { LabelTypes } from 'constants/index'
import config from 'config'
import { useClientVendorsFetcher, useUpdateClientVendor } from 'apis'

const getTableColumns = (
  onToggleVendorAttribute,
  attributeToToggle,
  attributeDisplayName,
) => [
  {
    id: 'name',
    header: LabelTypes.VENDOR,
    width: '70%',
  },
  {
    id: attributeToToggle,
    header: attributeDisplayName,
    cell: (info) => (
      <Toggle
        checked={info.getValue()}
        onChange={(val) => onToggleVendorAttribute(info.row.original.id, val)}
      />
    ),
    width: '30%',
    style: {
      textAlign: 'right',
    },
    headerStyle: {
      textAlign: 'right',
    },
  },
]

const VendorsAttributeToggleModal = ({
  attributeDisplayName,
  attributeToToggle,
  onClose,
  ...rest
}) => {
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: config.collectionQueryPageLimit.vendorsToggleAttributeModal,
  })
  const [searchText, setSearchText] = useState('')
  const {
    data: vendors,
    totalSize,
    isFetching,
  } = useClientVendorsFetcher({
    params: {
      page: pagination.page,
      per_page: pagination.sizePerPage,
      search_query: searchText,
      lean: false,
    },
  })
  const { mutateAsync: updateVendor } = useUpdateClientVendor()

  const handleSearchChange = useCallback((value) => {
    setSearchText(value)
    setPagination((current) => ({ ...current, page: 1 }))
  }, [])

  const handleToggleVendorAttribute = useCallback(
    (id, value) => {
      updateVendor({
        id,
        data: {
          vendor: {
            [attributeToToggle]: value,
          },
        },
      })
    },
    [attributeToToggle, updateVendor],
  )

  const tableColumns = useMemo(
    () =>
      getTableColumns(
        handleToggleVendorAttribute,
        attributeToToggle,
        attributeDisplayName,
      ),
    [handleToggleVendorAttribute, attributeToToggle, attributeDisplayName],
  )

  return (
    <SimpleDialog
      {...rest}
      title={`Edit ${LabelTypes.VENDOR} ${attributeDisplayName}`}
      buttons={[{ onClick: onClose, children: 'Close' }]}
      onClose={onClose}
    >
      <Toolbar>
        <Toolbar.Group>
          <SearchInput value={searchText} onChange={handleSearchChange} />
        </Toolbar.Group>
      </Toolbar>
      <Table
        loading={isFetching}
        data={vendors || []}
        columns={tableColumns}
        totalSize={totalSize}
        pagination={pagination}
        enableSorting={false}
        onPaginationChange={setPagination}
        manualPagination
        noDataText={`No ${LabelTypes.VENDORS} found.`}
      />
    </SimpleDialog>
  )
}

VendorsAttributeToggleModal.propTypes = {
  attributeToToggle: PropTypes.string.isRequired,
  attributeDisplayName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default VendorsAttributeToggleModal
