import React from 'react'
import { useModal } from 'hooks'
import { useRequestVendorProjectsAutomations } from 'apis'
import { getPageUrl } from 'utils/url'
import NotificationManager from 'lib/notifications'
import RequestableProjectsModal from './RequestableProjectsModal'

export default (requestableProjects) => {
  const { mutateAsync: requestVendorProjectsAutomations } =
    useRequestVendorProjectsAutomations()
  const [openModal] = useModal(RequestableProjectsModal)

  return React.useCallback(() => {
    openModal({
      requestableProjects,
      title: 'Share an Assessment to your profile',
      onRequest: (id) => {
        requestVendorProjectsAutomations({ id })
          .then((response) => {
            window.location = getPageUrl('vendorProject', {
              id: response.project_id,
            })
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error)
            NotificationManager.error()
          })
      },
    })
  }, [openModal, requestableProjects, requestVendorProjectsAutomations])
}
