import { useCallback } from 'react'
import findIndex from 'lodash/findIndex'
import { useCurrent } from 'hooks'
import { isSectionElement, isNotSectionElement } from 'lib/assessment'
import { useMoveClientAssessmentTemplateElements } from 'apis'
import useDefaultAsyncActionHandler from './useDefaultAsyncActionHandler'

// Callback when reorder an element
export default function useReorderElement(assessmentTemplate) {
  const { mutateAsync: moveClientAssessmentTemplateElements } =
    useMoveClientAssessmentTemplateElements()
  const assessmentTemplateRef = useCurrent(assessmentTemplate)
  const asyncActionHandler = useDefaultAsyncActionHandler()

  return useCallback(
    (elementId, dropIndex) => {
      // There's a whole decision matrix worked out based on what's being
      // dragged (Section or Question), which direction (higher or lower
      // index than where it started), and what's around it wherever it lands
      // (target is what used to be in that spot and got shifted),
      // that all boils down to the following:
      // - Section
      // 	- target == Question: target.parent.append_sib
      // 	- else:
      // 		- higher ? target.append_sib : target.prepend_sib
      // - Question
      // 	- target = target - 1 if lower
      // 	- target == Section ? target.prepend_child : target.append_sib
      const elementIndex = findIndex(assessmentTemplateRef.current.elements, {
        id: elementId,
      })
      const draggedElement =
        assessmentTemplateRef.current.elements[elementIndex]
      let targetElement = assessmentTemplateRef.current.elements[dropIndex]
      const movingLower = dropIndex < elementIndex
      const payload = { element_ids: [draggedElement.id] }

      if (isSectionElement(draggedElement)) {
        if (isNotSectionElement(targetElement)) {
          payload.insert_after = targetElement.parent_id
        } else if (movingLower) {
          payload.insert_before = targetElement.id
        } else {
          payload.insert_after = targetElement.id
        }
      } else {
        targetElement = movingLower
          ? assessmentTemplateRef.current.elements[dropIndex - 1]
          : targetElement
        if (isSectionElement(targetElement)) {
          payload.first_child_of = targetElement.id
        } else {
          payload.insert_after = targetElement.id
        }
      }

      const promise = moveClientAssessmentTemplateElements({
        id: assessmentTemplateRef.current.id,
        data: payload,
      })
        // eslint-disable-next-line no-console
        .catch((error) => console.log('error >', error))
      asyncActionHandler(promise)
    },
    [asyncActionHandler],
  )
}
