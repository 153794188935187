export const OsanoScoreMax = 850

export const OsanoScoreRangeConfig = {
  800: {
    label: 'Exceptional',
    color: '#00458f',
    description: `Fewer than 5% of web sites rate as Exceptional. These sites have gone above and beyond in their efforts to create policies that are readable and fair.

Their policies usually indicate that they do not share your data with third parties unless required to do so by law or with your verifiable opt-in consent. Sites in this catgory collect only the minimum necessary data in order to provide you with service.

If a site is rated as Exceptional you can generally feel comfortable that your data is being handled professionally and is not being sold to third parties.`,
  },
  740: {
    label: 'Very Good',
    color: '#0d6db6',
    description: `Sites rated as Very Good are making proactive efforts to be transparent about their data practices.

They may share non-sensitive information with third parties who are necessary to the operation of their service, but usually outline this sharing in their policies.

If a site is rated as Very Good you can generally feel comfortable that they are good actors who are doing their best to handle your data well.`,
  },
  670: {
    label: 'Good',
    color: '#71207a',
    description: `Sites rated as Good are generally good citizens of the Internet who may engage in some data sharing but usually with your opt-out consent.

Their definition of consent may be less rigorous than more highly rated sites. While these sites have a generally good score, exercise caution before sharing sensitive information.`,
  },
  580: {
    label: 'Fair',
    color: '#af2283',
    description: `Sites rated as Fair have a lot of room for improvement.

These sites may be sharing your personal data without your consent, may be hiding onerous terms in their documents, and are likely to engage in data brokering.

While these sites are not as dangerous as the Very Poor category, you should still exercise caution before sharing ANY information.`,
  },
  300: {
    label: 'Very Poor',
    color: '#d62b80',
    description: `Sites rated as Very Poor may have no privacy policy, extremely outdated privacy policies, may be known to engage in non-consensual sharing of sensitive data with third parties, or are engaging in other data privacy practices that put you at risk.

There are many reasons that a site may be rated as Very Poor but this is an indicator that you should be cautious.`,
  },
}

export const OsanoContributingFactors = [
  {
    name: 'Cookie Policy, Choice',
    description:
      'This section looks at whether users of a website have meaningful choices when it comes to the cookies used on a website.  It also evaluates whether important information is provided to users before they make those choices about cookies.',
  },
  {
    name: 'Cookie Policy, Enforcement',
    description:
      'This section considers how an organization complies with its own cookie policy and what it tells users about its use of cookies.',
  },
  {
    name: 'Cookie Policy, Notice and Disclosure',
    description:
      'This section looks at whether an organization provides adequate notice to users about its use of cookies. It also considers if and how the website discloses its use of cookies to users.',
  },
  {
    name: 'Follow Up',
    description: `This section evaluates whether there is anything strikingly out-of-the-ordinary about the organization's privacy practices or policies.`,
  },
  {
    name: 'GDPR Statement, Accessibility',
    description: `This section examines how an organization tells users about how it is handing its obligations under the GDPR.  It looks at the availability of pertinent GDPR information from the user's perspective.`,
  },
  {
    name: 'GDPR Statement, Choice',
    description:
      'This section looks at how-- and if-- an organization gives users choices in terms of how their personal data is collected and used and what choices, if any, are available to users.',
  },
  {
    name: 'GDPR Statement, Enforcement',
    description:
      'This section looks at the measures an organization takes in order to demonstrate its compliance with the GDPR. It also evaluates how the company holds itself accountable for GDPR compliance.',
  },
  {
    name: 'GDPR Statement, Notification',
    description:
      'This section looks at how an organization notifies its users about its data processing activities. It also looks at whether the organization notifies users about various data processing activities it may engage in.',
  },
  {
    name: 'GDPR Statement, Identification',
    description:
      'This section evaluates how an organization represents its role and its obligations when it comes to GDPR compliance.  It looks at how the organization identifies itself for GDPR purposes and holds itself out to regulators.',
  },
  {
    name: 'GDPR Statement, Notice',
    description:
      'This section looks at whether the organization gives users adequate notice with regards to how their personal data is collected and used.  It also looks at how the organization gives notice to users about whether and how personal data is shared with other entities.',
  },
  {
    name: 'GDPR Statement, Rights and Obligations',
    description: `This section evaluates how an organization makes users aware of their rights regarding personal data under the GDPR.  It also looks at the company's process for allowing data subjects to exercise those rights.`,
  },
  {
    name: 'Privacy Policy, Accessibility',
    description: `This section evaluates how accessible an organization's privacy practices are to average users.  It looks at whether users can access the information related to privacy from an organization's website and whether that information can be readily understood by an average user.`,
  },
  {
    name: 'Privacy Policy, Choice',
    description: `This section looks at what choices are available to users of an organization's website when it comes to their personal information. It looks at whether users are able to opt-out of the organization's using their personal information for any reason.`,
  },
  {
    name: 'Privacy Policy, Enforcement, and Accountability',
    description:
      'This section evaluates how an organization holds itself accountable for its privacy practices.  It considers how the organization adheres to standard best practices with regards to general privacy practices.',
  },
  {
    name: 'Privacy Policy, Notice',
    description:
      'This section evaluates how an organization provides notice to its users regarding its data privacy practices. It looks at what the organization tells its users about what types of information are collected and how the organization uses that information.',
  },
  {
    name: 'Privacy Policy, Rights and Obligations',
    description: `This section looks at how the organization's privacy documents tell users about their rights regarding their personal information.  It also looks at the organization's obligations to individuals who exercise those rights and its duties under applicable laws.`,
  },
  {
    name: 'Privacy Policy, Security',
    description: `This section looks at whether an organization's privacy documents disclose the security measures the organization has implemented if any.  It looks at how the privacy policy and other documents explain the measures the organization takes to protect the personal information it collects, holds, and uses.`,
  },
  {
    name: 'Security Statement, Administrative Security',
    description:
      'This section analyzes the specific administrative security measures an organization has taken and controls it has implemented to protect any personal information it uses or holds.',
  },
  {
    name: 'Security Statement, Certification',
    description:
      'This section evaluates any compliance certifications that an organization says it holds.',
  },
  {
    name: 'Security Statement, Enforcement, and Accountability',
    description:
      'This section looks at the external and internal ways that an organization validates its security practices and holds itself accountable for adhering to those security practices.',
  },
  {
    name: 'Security Statement, Operational Security',
    description:
      'This section evaluates the specific operational security measures an organization has implemented protect any personal information it uses or holds.',
  },
  {
    name: 'Security Statement, Physical Security',
    description:
      'This section evaluates the specific physical security measures an organization has implemented to protect any personal information it uses or holds.',
  },
  {
    name: 'Security Statement, Product Security',
    description:
      'This section evaluates the security measures the organization has implemented specifically to any of its products.',
  },
]
