import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import DList from 'components-v2/molecules/DList'
import InfoCard from '../InfoCard'

const renderWithLineBreak = (content) => (
  <>
    {content}
    {content && <br />}
  </>
)

const ContactsCard = ({ globalOrg, mainContact }) => (
  <InfoCard title="Contacts">
    <DList>
      <dt>Principal assessment contact</dt>
      <dd>
        {renderWithLineBreak(globalOrg.primary_contact_name)}
        {renderWithLineBreak(globalOrg.primary_contact_email)}
        {renderWithLineBreak(globalOrg.primary_contact_phone)}
      </dd>
      {mainContact && (
        <>
          <dt>Primary contact</dt>
          <dd>
            {renderWithLineBreak(get(mainContact, 'name'))}
            {renderWithLineBreak(get(mainContact, 'email'))}
            {renderWithLineBreak(get(mainContact, 'phone'))}
          </dd>
        </>
      )}
    </DList>
  </InfoCard>
)

ContactsCard.propTypes = {
  globalOrg: PropTypes.object.isRequired,
  mainContact: PropTypes.object,
}

export default ContactsCard
