import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import Button from 'components-v2/atoms/Button'
import Dialog from 'components-v2/molecules/Dialog'

const SimpleDialog = ({ onClose, title, buttons, children, ...rest }) => (
  <Dialog onClose={onClose} {...rest}>
    <Dialog.Header onClose={onClose}>{title}</Dialog.Header>
    <Dialog.Content>{children}</Dialog.Content>
    {buttons?.length > 0 && (
      <Dialog.Footer>
        {map(buttons, (button, index) => (
          <Button key={index} {...button} />
        ))}
      </Dialog.Footer>
    )}
  </Dialog>
)

SimpleDialog.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.node,
  buttons: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
}

SimpleDialog.defaultProps = {
  title: '',
}

export default React.memo(SimpleDialog)
