/* eslint-disable react/no-danger */
import React from 'react'
import PropTypes from 'prop-types'
import PageHeader from 'components-v2/organisms/PageHeader'
import { getPageUrl } from 'utils/url'
import { toLocalTimeString } from 'utils/date'

const breadcrumbs = [
  { title: 'Announcements', href: getPageUrl('announcements') },
]

const Container = ({ announcement }) => (
  <>
    <PageHeader
      title={<span dangerouslySetInnerHTML={{ __html: announcement.title }} />}
      breadcrumbs={breadcrumbs}
    >
      {toLocalTimeString(announcement.start_at)}
    </PageHeader>
    <p dangerouslySetInnerHTML={{ __html: announcement.body }} />
  </>
)

Container.propTypes = {
  announcement: PropTypes.object.isRequired,
}

export default Container
