import styled from 'styled-components'
import Icon from 'components-v2/atoms/Icon'

export const SmallText = styled.div`
  font-size: 14px;
  font-weight: 420;
  color: #000;
  margin: 0 8px 16px;
`

export const ListItemWrapper = styled.div`
  width: 100%;
  padding: 16px 16px 16px 8px;
  display: flex;
  align-items: center;
  gap: 12px;
  border-top: 1px solid #e5e5e5;
  @media (max-width: 400px) {
    flex-direction: column;
    align-items: start;
  }
`

export const ListItemIcon = styled(Icon).attrs({
  icon: 'fa fa-file',
})`
  @media (max-width: 400px) {
    display: none;
  }
`

export const ListItemMain = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

export const ListItemTitle = styled.div`
  font-weight: 420;
  color: #000;
`
