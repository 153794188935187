import React from 'react'
import PropTypes from 'prop-types'
import find from 'lodash/find'
import findLast from 'lodash/findLast'
import findKey from 'lodash/findKey'
import difference from 'lodash/difference'
import map from 'lodash/map'
import reject from 'lodash/reject'
import forEach from 'lodash/forEach'
import truncate from 'lodash/truncate'
import Well from 'components-v2/atoms/Well'
import Radio from 'components-v2/atoms/Radio'
import Button from 'components-v2/atoms/Button'
import IconButton from 'components-v2/atoms/IconButton'
import { BaseField } from 'components-v2/molecules/FormField'
import SearchableSelect from 'components-v2/molecules/SearchableSelect'
import SimpleDialog from 'components-v2/molecules/SimpleDialog'
import ListGroup from 'components-v2/molecules/ListGroup'
import {
  AssessmentElementTypes,
  AssessmentTemplateRuleTypes,
} from 'constants/index'
import AlterAttributeRule from 'lib/assessment/alter_attribute_rule'
import { FormControlStatic, List } from './styles'

// Constants
const Modes = {
  CREATE: 'create',
  EDIT: 'edit',
}

const buildConditionsFromRules = (
  rules,
  targets,
  allPossibleChoices,
  possibleQuestions,
) => {
  const ruleConditions = { conjunction: 'and', conditions: [], value: false }
  // find rule with last target
  const rule = findLast(
    rules,
    (r) => difference(targets, r.targets).length === 0,
  )

  if (rule) {
    forEach(rule.choices, (choice) => {
      let choiceLabel = null
      const questionId = findKey(allPossibleChoices, (c) => {
        const res = find(c, ['value', choice])
        if (res) {
          choiceLabel = res.label
          return true
        }
        return false
      })
      if (questionId) {
        const question = find(possibleQuestions, ['value', questionId])
        if (question) {
          // build condition for each choice
          const condition = {
            question: {
              id: questionId,
              label: question.label,
            },
            choice: {
              id: choice,
              label: choiceLabel,
            },
          }
          ruleConditions.conditions.push(condition)
          if (rule.conjunction) {
            ruleConditions.conjunction = rule.conjunction
          }
          if (rule.value) {
            ruleConditions.value =
              rule.value.toString().toLowerCase() === 'true'
          }
        }
      }
    })
  }

  return ruleConditions
}

function AlterAttributeRuleModal(props) {
  const data = React.useMemo(() => {
    const possibleQuestions = []
    const allPossibleChoices = {}
    props.elements.forEach((el) => {
      if (
        el.type !== AssessmentElementTypes.TEXT_QUESTION &&
        el.type !== AssessmentElementTypes.SECTION_HEADING &&
        !props.selected_element_ids.includes(el.id)
      ) {
        possibleQuestions.push({
          label: `${el.key} - ${el.label}`,
          value: el.id,
        })
      }
      allPossibleChoices[el.id] = el.choices
        ? el.choices.map((c) => ({ label: c.text, value: c.id }))
        : []
    })

    let ruleConditions = { conditions: [], conjunction: 'and', value: false }
    if (props.mode === 'edit') {
      ruleConditions = buildConditionsFromRules(
        props.rules,
        props.selected_element_ids,
        allPossibleChoices,
        possibleQuestions,
      )
    }
    return {
      possibleQuestions,
      allPossibleChoices,
      ruleConditions,
    }
  }, [props.elements, props.selected_element_ids, props.mode, props.rules])
  const { possibleQuestions, allPossibleChoices, ruleConditions } = data

  const [conditions, setConditions] = React.useState(ruleConditions.conditions)
  const [conjunction, setConjunction] = React.useState(
    ruleConditions.conjunction,
  )
  const [attributeValue, setAttributeValue] = React.useState(
    ruleConditions.value,
  )
  const [selectedQuestionId, setSelectedQuestionId] = React.useState('')
  const [selectedChoiceId, setSelectedChoiceId] = React.useState('')
  const [saveEnabled, setSaveEnabled] = React.useState(false)

  const save = () => {
    const newRule = {
      type: AssessmentTemplateRuleTypes.ALTER_ATTRIBUTE,
      attribute: props.attribute,
      value: attributeValue.toString(),
      targets: props.selected_element_ids,
      choices: map(conditions, 'choice.id'),
      conjunction,
    }
    const rules = AlterAttributeRule.mergeRule(newRule, props.rules)
    props.onSubmit(rules)
  }

  const handleConjunctionChange = (value) => {
    setConjunction(value)
    setSaveEnabled(conditions.length > 0)
  }

  const handleAttributeValueChange = (value) => {
    setAttributeValue(value)
    setSaveEnabled(conditions.length > 0)
  }

  const addCondition = () => {
    const condition = {
      question: {
        id: selectedQuestionId,
        label: possibleQuestions.find((e) => e.value === selectedQuestionId)
          .label,
      },
      choice: {
        id: selectedChoiceId,
        label: allPossibleChoices[selectedQuestionId].find(
          (e) => e.value === selectedChoiceId,
        ).label,
      },
    }

    setConditions((current) => [...current, condition])
    setSelectedQuestionId('')
    setSelectedChoiceId('')
    setSaveEnabled(true)
  }

  const removeCondition = (choiceId) => {
    setConditions((current) =>
      reject(current, {
        choice: { id: choiceId },
      }),
    )
    setSaveEnabled(true)
  }

  const buttons = [
    {
      children: 'Close',
      onClick: props.onClose,
    },
    {
      children: 'Save Conditions',
      color: 'primary',
      disabled: !saveEnabled || !props.editable,
      onClick: save,
    },
  ]
  return (
    <SimpleDialog
      open={props.open}
      onClose={props.onClose}
      title={`${props.selected_element_ids.length} question(s) selected`}
      buttons={buttons}
    >
      <form>
        <BaseField controlId="skip">
          <FormControlStatic>The selected questions will be:</FormControlStatic>
          <Radio
            name="skip"
            label="enabled"
            inline
            disabled={!props.editable}
            onChange={() => handleAttributeValueChange(false)}
            checked={!attributeValue}
          />
          &nbsp;&nbsp;&nbsp;
          <Radio
            name="skip"
            label="disabled"
            inline
            disabled={!props.editable}
            onChange={() => handleAttributeValueChange(true)}
            checked={attributeValue}
          />
          <FormControlStatic>
            if the respondent chooses the answer(s) to the question(s) below:
          </FormControlStatic>
        </BaseField>
        <h5>Conditions:</h5>
        <ListGroup>
          {conditions.map((cond, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <ListGroup.Item key={i} title={cond.question.label}>
              {props.editable && (
                <IconButton
                  size="sm"
                  icon="fa fa-times"
                  className="pull-right top5 left10"
                  onClick={() => removeCondition(cond.choice.id)}
                />
              )}
              {truncate(cond.question.label, { length: 150 })}
              <List>
                <li title={cond.choice.label}>
                  {truncate(cond.choice.label, { length: 50 })}
                </li>
              </List>
            </ListGroup.Item>
          ))}
          {conditions.length === 0 && (
            <ListGroup.Item key={0}>None.</ListGroup.Item>
          )}
        </ListGroup>
        {props.editable && (
          <Well>
            <h5>Add Condition</h5>
            <BaseField controlId="question" label="Question">
              <SearchableSelect
                name="question"
                value={selectedQuestionId}
                onChange={setSelectedQuestionId}
                options={possibleQuestions}
              />
            </BaseField>
            {selectedQuestionId !== '' && (
              <BaseField
                controlId="choice"
                label={`Answer that will cause the questions to be ${
                  attributeValue ? 'disabled' : 'enabled'
                }`}
              >
                <SearchableSelect
                  name="answer"
                  value={selectedChoiceId}
                  onChange={setSelectedChoiceId}
                  options={allPossibleChoices[selectedQuestionId]}
                />
              </BaseField>
            )}

            {selectedChoiceId !== '' && (
              <Button size="sm" onClick={addCondition}>
                Add
              </Button>
            )}
          </Well>
        )}
        {conditions.length > 0 && (
          <BaseField>
            <Radio
              name="conjunction"
              label="Match Any"
              inline
              disabled={!props.editable}
              onChange={() => handleConjunctionChange('or')}
              checked={conjunction === 'or'}
            />
            &nbsp;&nbsp;&nbsp;
            <Radio
              name="conjunction"
              label="Match All"
              inline
              disabled={!props.editable}
              onChange={() => handleConjunctionChange('and')}
              checked={conjunction === 'and'}
            />
          </BaseField>
        )}
      </form>
    </SimpleDialog>
  )
}

AlterAttributeRuleModal.propTypes = {
  open: PropTypes.bool,
  selected_element_ids: PropTypes.array,
  attribute: PropTypes.string.isRequired,
  rules: PropTypes.array,
  elements: PropTypes.array,
  mode: PropTypes.string,
  editable: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

AlterAttributeRuleModal.defaultProps = {
  selected_element_ids: [],
  rules: [],
  elements: [],
  mode: Modes.CREATE,
  editable: true,
}

AlterAttributeRuleModal.modes = Modes

export default AlterAttributeRuleModal
