import styled from 'styled-components'
import Button from 'components-v2/atoms/Button'
import IconButton from 'components-v2/atoms/IconButton'
import FormField from 'components-v2/molecules/FormField'
import { mixins } from 'styles'

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
`

export const StyledFormField = styled(FormField)`
  flex: 1;
  margin-right: 8px;
`

export const DeleteButton = styled(IconButton).attrs({ icon: 'fa fa-close' })`
  margin-top: 7px;
`

export const AddButton = styled(Button).attrs({
  variant: 'text',
  color: 'primary',
})`
  ${mixins.smallHeader};
  letter-spacing: 0;

  ${(props) =>
    props.theme.formFieldHorizontalLayout &&
    `
    &&:first-child {
      margin-top: 7px;
    }
  `}
`

export const subFormFieldTheme = {
  formFieldHorizontalLayout: false,
}
