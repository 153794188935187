import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactUserAvatar from 'react-user-avatar'
import { colors, mixins } from 'styles'

const MAX_NAME_WORD_COUNT = 3
const shortenName = (name = '') => {
  let words = name.split(' ')
  if (words.length > MAX_NAME_WORD_COUNT) {
    words = words.slice(0, MAX_NAME_WORD_COUNT)
    return words.join(' ')
  }
  if (!name) {
    return '?' // UserAvatar needs something
  }
  return name
}

const StyledUserAvatar = styled(ReactUserAvatar)`
  display: inline-block;
  color: #fff;
  ${mixins.smallHeader}
  text-transform: none;
  letter-spacing: 0;

  .UserAvatar--inner {
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.UserAvatar--light {
    color: gray;
  }

  &.UserAvatar--light .UserAvatar--inner {
    border: 1px solid lightgray;
  }
`

const UserAvatar = ({ name, ...rest }) => (
  <StyledUserAvatar {...rest} name={shortenName(name)} />
)

UserAvatar.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
  src: PropTypes.string,
}

UserAvatar.defaultProps = {
  size: 32,
  color: colors.PRIMARY,
}

export default UserAvatar
