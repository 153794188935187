import ColumnMap from 'lib/column_map'

export const ActionMenuItemTypes = {
  IMPORT_VENDORS: 'import_vendors',
  IMPORT_CUSTOM_FIELDS: 'import_custom_fields',
  EXPORT_CSV: 'export_csv',
  EXPORT_CONTACTS_CSV: 'export_contacts_csv',
  EXPORT_XLS: 'export_xls',
}

export const RequestOpts = {
  false: {
    columnSet: 'vendors',
    prefsPath: 'vendors',
    inactiveLabel: 'Show Archived',
  },
  true: {
    columnSet: 'pre_vendors',
    prefsPath: 'pre_vendors',
    inactiveLabel: 'Show Declined',
  },
}

export const RequestStatuses = {
  requested: 'Requested',
  in_review: 'In Review',
  // could include this and update the controller to allow review of past approvals
  // approved: 'Approved',
  declined: 'Declined',
}

export const COL_NAMES = new ColumnMap({
  ID: 'id',
  NAME: 'name',
  STATUS: 'status',
  RISK_TIER: 'risk_tier',
  NEXT_REASSESSMENT_AT: 'next_reassessment_at',
  // neither provided nor used anymore,
  // but need to keep here to maintain column indexing:
  ASSESSMENTS_SUBMITTED: 'assessments_submitted_to_client',
  ISSUES_COUNT: 'issues_count',
  USER_COUNT: 'user_count',
  FILE_COUNT: 'file_count',
  CREATED_AT: 'created_at',
  REQUESTER_NAME: 'requester_name',
  PARENT_ORG: 'parent_org_id',
  LAST_ASSESSMENT: 'last_assessment',
  LAST_ASSESSMENT_AT: 'last_assessment.created_at',
  ASSESSMENT_COUNT: 'assessment_count',
  RISK_RECON_SCORE: 'risk_recon',
  OSANO_SCORE: 'osano',
  OFAC_MATCH: 'ofac',
})
