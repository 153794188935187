import React from 'react'
import PropTypes from 'prop-types'
import findIndex from 'lodash/findIndex'
import filter from 'lodash/filter'
import map from 'lodash/map'
import { StickyContainer, Sticky } from 'react-sticky'
import Button from 'components-v2/atoms/Button'
import {
  isNotSectionElement,
  getQuestionsInSection,
  getSectionsFromElements,
} from 'lib/assessment'
import { sizes } from 'styles'
import SectionHeadingRow from './SectionHeadingRow'
import StickyHeader from './StickyHeader'
import {
  StickyContent,
  PaginationWrapper,
  Table,
  Section,
  SectionSkippedNote,
} from './styles'

const RenderComponent = ({ renderFunc, ...rest }) => renderFunc(rest)

const AssessmentElementsViewWithSectionPicker = ({
  elements,
  sections,
  selectedSectionId,
  translatable,
  useCustomKeys,
  tableId,
  renderSectionHeadingRow,
  renderQuestionRow,
  renderStickyHeader,
  renderTable,
  onSectionChange,
}) => {
  const isFirstSection = React.useMemo(
    () =>
      sections && selectedSectionId && sections[0]?.id === selectedSectionId,
    [sections, selectedSectionId],
  )

  const isLastSection = React.useMemo(
    () =>
      sections &&
      selectedSectionId &&
      sections[sections.length - 1]?.id === selectedSectionId,
    [sections, selectedSectionId],
  )

  const handleGoPrevSection = React.useCallback(() => {
    const currentSectionIndex = findIndex(sections, { id: selectedSectionId })
    if (currentSectionIndex > 0) {
      onSectionChange(sections[currentSectionIndex - 1].id)
    }
  }, [sections, selectedSectionId, onSectionChange])

  const handleGoNextSection = React.useCallback(() => {
    const currentSectionIndex = findIndex(sections, { id: selectedSectionId })
    if (currentSectionIndex < sections.length - 1) {
      onSectionChange(sections[currentSectionIndex + 1].id)
    }
  }, [sections, selectedSectionId, onSectionChange])

  const groupedBySection = React.useMemo(() => {
    const sectionElements = getSectionsFromElements(elements)
    let res
    if (sectionElements.length === 0) {
      res = [
        {
          key: 1,
          questions: elements,
        },
      ]
    } else {
      res = sectionElements.map((e) => ({
        key: e.id,
        section: e,
        questions: getQuestionsInSection(elements, e.id),
      }))
      const sectionIds = sectionElements.map((e) => e.id)
      const questionsInNoSection = filter(
        elements,
        (e) =>
          isNotSectionElement(e) &&
          (!e.parent_id || !sectionIds.includes(e.parent_id)),
      )
      if (questionsInNoSection.length > 0) {
        res = [
          {
            key: 'no-section',
            questions: questionsInNoSection,
          },
          ...res,
        ]
      }
    }
    return res.map((e) => ({
      ...e,
      isEmpty: e.questions.length === 0,
      isSkipped: e.length !== 0 && e.questions.every((q) => q.skipped),
    }))
  }, [elements])

  return (
    <StickyContainer>
      <Sticky topOffset={-sizes.HEADER_HEIGHT} disableHardwareAcceleration>
        {({ isSticky, style }) => (
          <StickyContent isSticky={isSticky} style={style}>
            <RenderComponent
              renderFunc={renderStickyHeader}
              sections={sections}
              selectedSectionId={selectedSectionId}
              onSectionChange={onSectionChange}
            />
          </StickyContent>
        )}
      </Sticky>
      <RenderComponent renderFunc={renderTable} id={tableId}>
        {map(groupedBySection, (e) => (
          <Section
            key={e.key}
            data-empty-section={e.isEmpty}
            data-skipped-section={e.isSkipped}
          >
            {e.section && (
              <RenderComponent
                renderFunc={renderSectionHeadingRow}
                element={e.section}
                translatable={translatable}
                useCustomKeys={useCustomKeys}
              />
            )}
            {e.questions.map((q) => (
              <RenderComponent
                renderFunc={renderQuestionRow}
                key={q.key}
                element={q}
                translatable={translatable}
                useCustomKeys={useCustomKeys}
              />
            ))}
            {e.isSkipped && (
              <SectionSkippedNote>
                Section skipped based on previous answers
              </SectionSkippedNote>
            )}
          </Section>
        ))}
      </RenderComponent>
      {selectedSectionId && (
        <PaginationWrapper>
          <Button onClick={handleGoPrevSection} disabled={isFirstSection}>
            &lt; Previous Section
          </Button>
          <Button onClick={handleGoNextSection} disabled={isLastSection}>
            Next Section &gt;
          </Button>
        </PaginationWrapper>
      )}
    </StickyContainer>
  )
}

AssessmentElementsViewWithSectionPicker.propTypes = {
  elements: PropTypes.array.isRequired,
  sections: PropTypes.array.isRequired,
  selectedSectionId: PropTypes.string,
  translatable: PropTypes.bool,
  useCustomKeys: PropTypes.bool,
  tableId: PropTypes.string,
  renderSectionHeadingRow: PropTypes.func,
  renderQuestionRow: PropTypes.func.isRequired,
  renderStickyHeader: PropTypes.func,
  renderTable: PropTypes.func,
  onSectionChange: PropTypes.func.isRequired,
}

AssessmentElementsViewWithSectionPicker.defaultProps = {
  renderSectionHeadingRow: (props) => <SectionHeadingRow {...props} />,
  renderStickyHeader: (props) => <StickyHeader {...props} />,
  renderTable: (props) => <Table {...props} />,
}

export default AssessmentElementsViewWithSectionPicker
