// import images referenced outside js in rails views
import './transparent-tile.jpg'
import './user_icon.png'
import './ajax-loader.gif'
import './appstore-lrg.svg'
import './google_badge_web_generic.png'

function importAllImages(r) {
  r.keys().forEach(r)
}

// Import all files from the brand directory and subdirectories
importAllImages(require.context('./brand/', true, /\.(png|jpe?g|gif|svg)$/))
importAllImages(require.context('./error/', true, /\.(png|jpe?g|gif|svg)$/))
