import styled from 'styled-components'
import Textarea from 'components-v2/atoms/Textarea'
import FormattableEditor from 'components-v2/molecules/FormattableEditor'

export const StyledFormattableEditor = styled(FormattableEditor)`
  ${Textarea} {
    height: 380px;
  }
  .remirror-editor {
    height: 334px;
  }
`
