import React from 'react'
import PropTypes from 'prop-types'
import ProgressBar from 'components-v2/molecules/ProgressBar'
import { Note } from './styles'

const Progress = ({ completed, total, hideNote, ...rest }) => {
  const completeness = Math.floor((completed / total) * 100)
  return (
    <div {...rest}>
      <ProgressBar
        now={completeness}
        label={`${completeness.toString()}%`}
        height={22}
      />
      {!hideNote && (
        <Note>
          {completed} of {total} questions complete
        </Note>
      )}
    </div>
  )
}

Progress.propTypes = {
  completed: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  hideNote: PropTypes.bool,
}

export default React.memo(Progress)
