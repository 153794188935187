import React from 'react'
import PropTypes from 'prop-types'
import { GaugeChart2 } from 'components-v2/molecules/Charts'
import { getRiskReconRatingColor } from 'components/client/lib/globorg_integration_metrics'
import { ChartWrapper, MetricWrapper, Metric } from './styles'

const points = [0, 4, 5.5, 7, 8.5, 10].map((value) => ({
  pos: value / 10,
  label: value,
  color: getRiskReconRatingColor(value),
}))

const RiskReconOverallGradeChart = ({ grade, value, total, ...rest }) => {
  const color = getRiskReconRatingColor(value)
  return (
    <ChartWrapper {...rest}>
      <GaugeChart2
        value={value}
        max={total}
        markerColor={color}
        points={points}
        width="100%"
        height={138}
        outerRadius={110}
        innerRadius={104}
        cy={128}
      />
      <MetricWrapper>
        <Metric color={color} left={grade} right={value} />
      </MetricWrapper>
    </ChartWrapper>
  )
}

RiskReconOverallGradeChart.propTypes = {
  grade: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
}

export default RiskReconOverallGradeChart
