import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import keyBy from 'lodash/keyBy'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import { ThemeProvider } from 'styled-components'
import { Row } from 'components-v2/organisms/Layout'
import OverlayLoader from 'components-v2/organisms/OverlayLoader'
import { toDate } from 'utils/date'
import { AssessmentStatusTypes, AssessmentStatusOptions } from 'constants/index'
import AssessmentCard from './AssessmentCard'
import { theme, Content, ColHeader, CardList, StyledCol as Col } from './styles'

const AssessmentStatusOptionsObj = keyBy(AssessmentStatusOptions, 'value')
const statuses = [
  AssessmentStatusTypes.INVITED,
  AssessmentStatusTypes.IN_PROCESS,
  AssessmentStatusTypes.SUBMITTED,
  AssessmentStatusTypes.EXPIRED,
  AssessmentStatusTypes.REVIEWED,
]
const statusesWithConditionalVisibility = [
  AssessmentStatusTypes.INVITED,
  AssessmentStatusTypes.EXPIRED,
]

const CardsView = ({ assessments, isFetching, onRequestExtension }) => {
  const columns = useMemo(() => {
    const groupByStatus = groupBy(assessments, 'status')
    const filteredStatuses = statuses.filter(
      (status) =>
        !statusesWithConditionalVisibility.includes(status) ||
        groupByStatus[status]?.length > 0,
    )
    return filteredStatuses.map((status) => ({
      status,
      title: AssessmentStatusOptionsObj[status]?.label,
      color: AssessmentStatusOptionsObj[status]?.color,
      assessments: orderBy(
        groupByStatus[status],
        [(assessment) => toDate(assessment.due_at)],
        ['desc'],
      ),
    }))
  }, [assessments])
  return (
    <ThemeProvider theme={theme}>
      <OverlayLoader active={isFetching}>
        <Content>
          <Row>
            {columns.map((column) => (
              <Col key={column.status} $colCount={columns.length}>
                <ColHeader color={column.color}>
                  {column.title}{' '}
                  {column.assessments.length > 0
                    ? `(${column.assessments.length})`
                    : ''}
                </ColHeader>
                <CardList>
                  {column.assessments.map((e) => (
                    <AssessmentCard
                      key={e.id}
                      assessment={e}
                      onRequestExtension={() => onRequestExtension(e)}
                    />
                  ))}
                </CardList>
              </Col>
            ))}
          </Row>
          {assessments.length === 0 && !isFetching && (
            <p className="text-center">No assessments found.</p>
          )}
        </Content>
      </OverlayLoader>
    </ThemeProvider>
  )
}

CardsView.propTypes = {
  assessments: PropTypes.array,
  isFetching: PropTypes.bool,
  onRequestExtension: PropTypes.func.isRequired,
}

CardsView.defaultProps = {
  assessments: [],
}

export default CardsView
