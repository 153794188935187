import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { compose, hasLoader, hasError } from 'hocs'
import NotificationManager from 'lib/notifications'
import CommentList from './CommentList'
import CommentForm from './CommentForm'
import useCommentThread from './useCommentThread'

const ExtendedCommentList = compose(hasLoader, hasError())(CommentList)

const CommentThread = ({
  commentableType,
  commentableId,
  vendorId,
  canComment,
  isVendorContext,
  onCommentCreated,
  ...rest
}) => {
  const [comments, submitComment] = useCommentThread(
    commentableType,
    commentableId,
    vendorId,
    isVendorContext,
  )

  const getCommenter = React.useCallback(
    (comment) =>
      !isVendorContext && comment.organization.id !== vendorId
        ? `${comment.organization.name} (${get(comment, 'created_by.name')})`
        : comment.organization.name,
    [isVendorContext, vendorId],
  )

  const highlightGetter = React.useCallback(
    (comment) => !!isVendorContext === (comment.organization.id === vendorId),
    [isVendorContext, vendorId],
  )

  const handleSubmitComment = React.useCallback(
    (formData, { setSubmitting, resetForm }) => {
      submitComment(formData)
        .then(() => {
          if (onCommentCreated) {
            onCommentCreated()
          }
          setSubmitting(false)
          resetForm()
        })
        .catch((error) => {
          console.error(error)
          NotificationManager.error()
          setSubmitting(false)
        })
    },
    [submitComment, onCommentCreated],
  )

  return (
    <div {...rest}>
      <ExtendedCommentList
        comments={comments}
        getCommenter={getCommenter}
        highlightGetter={highlightGetter}
      />
      {canComment && <CommentForm onSubmit={handleSubmitComment} />}
    </div>
  )
}

CommentThread.propTypes = {
  commentableType: PropTypes.string.isRequired,
  commentableId: PropTypes.string.isRequired,
  vendorId: PropTypes.string.isRequired,
  isVendorContext: PropTypes.bool,
  canComment: PropTypes.bool,
  onCommentCreated: PropTypes.func,
}

CommentThread.defaultProps = {
  canComment: true,
}

export default CommentThread
