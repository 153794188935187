import styled from 'styled-components'
import Button from 'components-v2/atoms/Button'
import Accordion from 'components-v2/molecules/Accordion'
import { mixins } from 'styles'

export const Body = styled.div`
  display: flex;

  // Firefox Print Bugfix to prevent this flexbox being overlapped with elements after this
  @media print {
    display: block;
  }
`

export const Sidebar = styled.div`
  flex: none;
  width: 25%;
  margin-right: 22px;
  ${mixins.hidePrint}
`

export const Content = styled.div`
  flex: 1;
  min-width: 0;
`

export const HideFiltersButton = styled(Button).attrs({
  variant: 'text',
  fullWidth: true,
})`
  && {
    color: inherit;
    border-bottom: 1px solid #f1f3f7;
    padding: 12px;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    text-align: left;
  }
`

export const ShowFiltersButton = styled(Button)`
  margin-bottom: 20px;
`

export const AccordionItem = styled(Accordion.Item)`
  .accordion__button {
    font-weight: normal;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;

    i {
      color: inherit;
      margin: 0;
      &:before {
        content: '\f078' !important;
      }
    }

    &[aria-expanded='true'] > i:before {
      content: '\f077' !important;
    }
  }

  .accordion__panel {
    max-height: 180px;
    overflow-y: auto;
    overflow-x: hidden;
  }
`
