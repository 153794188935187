import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from 'components-v2/molecules/Tooltip'
import { Wrapper, StyledDragSourceColumn, LabelColumn } from './styles'
import { WeightColumn, CheckboxColumn, IconColumn } from '../styles'
import {
  EyeIcon,
  PencilIcon,
  TrashIcon,
  BalanceScaleIcon,
  CommentDotsIcon,
  PaperclipIcon,
  CogsIcon,
  FileDownloadIcon,
} from '../../styles'

const Thead = ({ editable, useCustomKeys, ...rest }) => (
  <Wrapper {...rest}>
    <StyledDragSourceColumn customKey={useCustomKeys}>
      Key
    </StyledDragSourceColumn>
    <LabelColumn>Question</LabelColumn>
    <WeightColumn>
      <Tooltip parent={BalanceScaleIcon}>Score Weight</Tooltip>
    </WeightColumn>
    <IconColumn>
      <Tooltip parent={FileDownloadIcon}>Manage Question Attachments</Tooltip>
    </IconColumn>
    <CheckboxColumn>
      <Tooltip parent={CommentDotsIcon}>Allow Comments</Tooltip>
    </CheckboxColumn>
    <CheckboxColumn>
      <Tooltip parent={PaperclipIcon}>Allow Attachments</Tooltip>
    </CheckboxColumn>
    <IconColumn>
      <Tooltip parent={CogsIcon}>Edit Question Automations</Tooltip>
    </IconColumn>
    <IconColumn>
      <Tooltip parent={EyeIcon}>Edit Question Skip Conditions</Tooltip>
    </IconColumn>
    {editable && (
      <>
        <IconColumn>
          <Tooltip parent={PencilIcon}>Edit Question</Tooltip>
        </IconColumn>
        <IconColumn>
          <Tooltip parent={TrashIcon}>Delete Question</Tooltip>
        </IconColumn>
      </>
    )}
  </Wrapper>
)

Thead.propTypes = {
  editable: PropTypes.bool,
  useCustomKeys: PropTypes.bool,
}

export default React.memo(Thead)
