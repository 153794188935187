import styled from 'styled-components'
import { media } from 'styles'

export const Wrapper = styled.div`
  ${media.desktop`
    display: flex;
  `}
`

export const Left = styled.div`
  ${media.desktop`
    flex: none;
    width: 36%;
    margin-right: 25px;
  `}

  > * {
    margin-bottom: 25px;
  }
`

export const Right = styled.div`
  flex: auto;
  min-width: 0;

  > * {
    margin-bottom: 25px;
  }
`
