import React from 'react'
import PropTypes from 'prop-types'
import { getPaginationRowModel } from '@tanstack/react-table'
import { toUncontrollable } from 'hocs'

// Enabled by default
export default function withPagination(WrappedComponent) {
  const WrapperComponent = ({
    state = {},
    totalSize,
    pagination,
    manualPagination,
    enablePagination,
    onPaginationChange,
    ...rest
  }) => {
    const paginationEx = React.useMemo(() => {
      if (pagination) {
        return {
          pageIndex: pagination.page - 1,
          pageSize: pagination.sizePerPage ?? 10, // default page size is 10
        }
      }
      return undefined
    }, [pagination])
    const stateEx =
      enablePagination && paginationEx
        ? {
            ...state,
            pagination: paginationEx,
          }
        : state
    const pageCount =
      enablePagination && manualPagination
        ? totalSize && pagination.sizePerPage
          ? Math.ceil(totalSize / pagination.sizePerPage)
          : -1
        : undefined
    const onPaginationChangeEx =
      enablePagination && onPaginationChange
        ? (updater) => {
            const newPagination = updater(paginationEx || {})
            return onPaginationChange({
              page: newPagination.pageIndex + 1,
              sizePerPage: newPagination.pageSize,
            })
          }
        : undefined

    return (
      <WrappedComponent
        {...rest}
        manualPagination={manualPagination}
        getPaginationRowModel={
          enablePagination && !manualPagination
            ? getPaginationRowModel()
            : undefined
        }
        enablePagination={enablePagination}
        pageCount={pageCount}
        state={stateEx}
        onPaginationChange={onPaginationChangeEx}
      />
    )
  }

  WrapperComponent.propTypes = {
    state: PropTypes.object,
    totalSize: PropTypes.number,
    pagination: PropTypes.shape({
      page: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
    }),
    manualPagination: PropTypes.bool,
    enablePagination: PropTypes.bool,
    onPaginationChange: PropTypes.func,
  }

  WrapperComponent.defaultProps = {
    enablePagination: true,
  }

  return toUncontrollable({ pagination: 'onPaginationChange' })(
    WrapperComponent,
  )
}
