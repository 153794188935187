import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import A from 'components-v2/atoms/A'
import Button from 'components-v2/atoms/Button'
import { useAuthorizations } from 'hooks'
import InfoCard from '../vendor_info_view/InfoCard'
import FieldGroupCard from '../vendor_info_view/FieldGroupCard'
import PrimaryContributorDisplay from '../vendor_info_view/FieldRenderers/PrimaryContributorDisplay'
import { VendorFieldGroupIdTypes } from '../vendor_info_view/constants'
import { Wrapper, Left, Right } from '../vendor_info_view/styles'
import { EmptyPlaceholder } from './styles'

const View = ({
  vendor,
  vendorFieldGroups,
  cards,
  onEdit,
  onSave,
  onCancel,
  onCreatePrimaryUser,
  ...rest
}) => {
  const [canManageVendor] = useAuthorizations('manage')

  if (
    !vendor.main_contact &&
    cards[VendorFieldGroupIdTypes.CONTRIBUTORS]?.editing
  ) {
    return (
      <FieldGroupCard
        editing
        cardId={VendorFieldGroupIdTypes.CONTRIBUTORS}
        data={vendorFieldGroups[VendorFieldGroupIdTypes.CONTRIBUTORS]}
        vendor={vendor}
        onSave={onSave}
        onCancel={onCancel}
      />
    )
  }

  if (!vendor.main_contact) {
    return (
      <InfoCard
        title={
          vendorFieldGroups[VendorFieldGroupIdTypes.PRIMARY_CONTRIBUTOR].label
        }
      >
        <EmptyPlaceholder>
          No primary user found.&nbsp;
          <A onClick={onCreatePrimaryUser} underline>
            Click here
          </A>{' '}
          to create a new user.
        </EmptyPlaceholder>
      </InfoCard>
    )
  }

  return (
    <Wrapper {...rest}>
      <Left>
        {get(cards, [
          VendorFieldGroupIdTypes.PRIMARY_CONTRIBUTOR,
          'editing',
        ]) ? (
          <FieldGroupCard
            {...cards[VendorFieldGroupIdTypes.PRIMARY_CONTRIBUTOR]}
            cardId={VendorFieldGroupIdTypes.PRIMARY_CONTRIBUTOR}
            data={
              vendorFieldGroups[VendorFieldGroupIdTypes.PRIMARY_CONTRIBUTOR]
            }
            vendor={vendor}
            onEdit={onEdit}
            onSave={onSave}
            onCancel={onCancel}
          />
        ) : (
          <InfoCard
            title={
              vendorFieldGroups[VendorFieldGroupIdTypes.PRIMARY_CONTRIBUTOR]
                .label
            }
          >
            <PrimaryContributorDisplay
              vendor={vendor}
              value={vendor.main_contact}
            />
            {vendor.class !== 'VendorRequest' && canManageVendor && (
              <Button
                style={{
                  marginTop: 20,
                }}
                onClick={() =>
                  onEdit(VendorFieldGroupIdTypes.PRIMARY_CONTRIBUTOR)
                }
              >
                Edit Primary Contributor
              </Button>
            )}
          </InfoCard>
        )}
      </Left>
      <Right>
        <FieldGroupCard
          {...cards[VendorFieldGroupIdTypes.CONTRIBUTORS]}
          cardId={VendorFieldGroupIdTypes.CONTRIBUTORS}
          data={vendorFieldGroups[VendorFieldGroupIdTypes.CONTRIBUTORS]}
          vendor={vendor}
          onEdit={onEdit}
          onSave={onSave}
          onCancel={onCancel}
        />
      </Right>
    </Wrapper>
  )
}

View.propTypes = {
  vendor: PropTypes.object.isRequired,
  vendorFieldGroups: PropTypes.object.isRequired,
  cards: PropTypes.any,
  onEdit: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCreatePrimaryUser: PropTypes.func.isRequired,
}

export default View
