import { useCallback } from 'react'
import { useUpdateCurrentOrganization } from 'apis'
import NotificationManager from 'lib/notifications'

export default (key, organization) => {
  const { mutateAsync: updateCurrentOrganization } =
    useUpdateCurrentOrganization()
  const value = organization[key]

  // Update server and then state
  const handleChange = useCallback(
    (newValue) => {
      updateCurrentOrganization({
        data: { organization: { [key]: newValue } },
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error)
        NotificationManager.error()
      })
    },
    [updateCurrentOrganization, key],
  )

  return [value, handleChange]
}
