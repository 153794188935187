import React, { useCallback } from 'react'
import map from 'lodash/map'
import { useGlobalLoader, useModal, useConfirm } from 'hooks'
import NotificationManager from 'lib/notifications'
import {
  useUpdateClientAssessment,
  useForceSubmitClientAssessment,
  useUndoSubmitClientAssessment,
  useContinueReviewClientAssessment,
  useCompleteReviewClientAssessment,
} from 'apis'
import Alert from 'components-v2/molecules/Alert'
import NoteModal from 'components/shared/NoteModal'
import ExpiredSubmitModal from './ExpiredSubmitModal'
import UnlockAssessmentModal from './UnlockAssessmentModal'
import useSetAssessment from './useSetAssessment'
import { NOTE_MAX_LENGTH } from './constants'

export default function useCRUDAssessment(assessment) {
  const [showLoader, hideLoader] = useGlobalLoader()
  const confirm = useConfirm()
  const [openNoteModal, closeNoteModal] = useModal(NoteModal)
  const [openExpiredSubmitModal, closeExpiredSubmitModal] =
    useModal(ExpiredSubmitModal)
  const [openUnlockAssessmentModal, closeUnlockAssessmentModal] = useModal(
    UnlockAssessmentModal,
  )
  const { mutateAsync: updateClientAssessment } = useUpdateClientAssessment()
  const { mutateAsync: forceSubmitClientAssessment } =
    useForceSubmitClientAssessment()
  const { mutateAsync: undoSubmitClientAssessment } =
    useUndoSubmitClientAssessment()
  const { mutateAsync: continueReviewClientAssessment } =
    useContinueReviewClientAssessment()
  const { mutateAsync: completeReviewClientAssessment } =
    useCompleteReviewClientAssessment()
  const { id: assessmentId, vendor_id: vendorId } = assessment
  const setAssessment = useSetAssessment(assessmentId)

  const assignAssessment = useCallback(
    async (formData) => {
      const payload = {
        client_assignee_id: formData.assigneeId,
        client_assignee_note: formData.note,
      }
      const loaderId = showLoader()
      try {
        const data = await updateClientAssessment({
          id: assessment.id,
          data: { assessment: payload },
        })
        setAssessment((current) => ({
          ...current,
          client_assignee: data.client_assignee,
          client_assignee_note: data.client_assignee_note,
        }))
        hideLoader(loaderId)
      } catch (error) {
        hideLoader(loaderId)
        NotificationManager.error()
        throw error
      }
    },
    [assessment, showLoader, hideLoader, updateClientAssessment, setAssessment],
  )

  const unlockAssessment = useCallback(() => {
    const onSubmit = (formData) => {
      const loaderId = showLoader()
      return undoSubmitClientAssessment({
        id: assessment.id,
        data: {
          assessment: {
            date_range_attributes: { end_at: formData.dueDate },
          },
        },
      })
        .then(() => {
          closeUnlockAssessmentModal()
          // TODO: Implement optimistic updates instead of page reloading
          window.location.href = `${window.location.href.replace(
            window.location.search,
            '',
          )}?reload_assessment_id=${assessment.id}&reload_vendor_id=${
            assessment.vendor_id
          }`
        })
        .catch(() => {
          NotificationManager.error()
        })
        .then(() => hideLoader(loaderId))
    }
    openUnlockAssessmentModal({ assessment, onSubmit })
  }, [
    assessment,
    openUnlockAssessmentModal,
    closeUnlockAssessmentModal,
    undoSubmitClientAssessment,
    showLoader,
    hideLoader,
  ])

  const continueReviewAssessment = useCallback(async () => {
    const loaderId = showLoader()
    try {
      await continueReviewClientAssessment({
        id: assessmentId,
      })
      // TODO: Implement optimistic updates instead of page reloading
      window.location.href = `${window.location.href.replace(
        window.location.search,
        '',
      )}?reload_assessment_id=${assessmentId}&reload_vendor_id=${vendorId}`
    } catch (err) {
      NotificationManager.error()
    }
    hideLoader(loaderId)
  }, [
    assessmentId,
    vendorId,
    continueReviewClientAssessment,
    showLoader,
    hideLoader,
  ])

  const completeAssessment = useCallback(
    async (grade) => {
      const tryAction = (allowOpenIssues = false) => {
        const loaderId = showLoader()
        return completeReviewClientAssessment({
          id: assessmentId,
          data: {
            assessment: {
              allow_open_issues: allowOpenIssues,
            },
          },
          options: {
            params: {
              grade,
            },
          },
        })
          .then(() => {
            // TODO: Implement optimistic updates instead of page reloading
            window.location.href = `${window.location.href.replace(
              window.location.search,
              '',
            )}?reload_assessment_id=${assessmentId}&reload_vendor_id=${vendorId}`
            hideLoader(loaderId)
          })
          .catch((err) => {
            hideLoader(loaderId)
            throw err
          })
      }

      // First try
      try {
        await tryAction()
        return
      } catch (error) {
        if (error.response.status !== 422) {
          NotificationManager.error()
          return
        }

        const confirmed = await confirm(
          {
            title: (
              <>
                <i className="fa fa-warning" /> Attention
              </>
            ),
            body: (
              <>
                <Alert>
                  <ul>
                    {map(error.response.data.errors, (val, key) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <li key={key}>{val}</li>
                    ))}
                  </ul>
                </Alert>
                <div className="strong control-label">
                  Do you want to proceed?
                </div>
              </>
            ),
            cancelText: 'No',
            confirmText: 'Yes',
            confirmColor: 'primary',
          },
          true,
        )
        if (!confirmed) {
          return
        }
      }

      // Second try
      try {
        await tryAction(true)
      } catch (error) {
        NotificationManager.error()
      }
    },
    [
      assessmentId,
      vendorId,
      completeReviewClientAssessment,
      showLoader,
      hideLoader,
      confirm,
    ],
  )

  const addOrEditAssessmentNotes = useCallback(() => {
    const note = assessment.notes?.[0]
    openNoteModal({
      title: `Notes on ${assessment.name_for_client}`,
      maxLength: NOTE_MAX_LENGTH,
      note,
      supportFormatting: true,
      onSubmit: ({ body, formatted }) => {
        const loaderId = showLoader()
        const payload = {
          // eslint-disable-next-line camelcase
          notes_attributes: [
            {
              id: note?.id || null,
              body,
              formatted,
            },
          ],
        }
        return updateClientAssessment({
          id: assessment.id,
          data: { assessment: payload },
        })
          .then((data) => {
            setAssessment((current) => ({
              ...current,
              notes: data.notes,
            }))
            closeNoteModal()
          })
          .catch(() => NotificationManager.error())
          .then(() => hideLoader(loaderId))
      },
    })
  }, [
    assessment,
    openNoteModal,
    closeNoteModal,
    showLoader,
    hideLoader,
    setAssessment,
  ])

  const submitExpiredAssessment = useCallback(() => {
    const onSubmit = async (data) => {
      const loaderId = showLoader()
      try {
        await forceSubmitClientAssessment({ id: assessment.id, data })
        closeExpiredSubmitModal()
        NotificationManager.success(
          'The assessment has been submitted successfully.',
        )
        // TODO: Implement optimistic updates instead of page reloading
        setTimeout(() => {
          window.location.reload()
        }, 1200)
      } catch (error) {
        NotificationManager.error()
        hideLoader(loaderId)
      }
    }
    openExpiredSubmitModal({
      assessment,
      onSubmit,
    })
  }, [
    assessment,
    openExpiredSubmitModal,
    closeExpiredSubmitModal,
    showLoader,
    hideLoader,
    forceSubmitClientAssessment,
  ])

  const setAssessmentAttachments = useCallback(
    (attachments) => {
      setAssessment((current) => ({
        ...current,
        attachments,
      }))
    },
    [setAssessment],
  )

  const setAssessmentInternalAttachments = useCallback(
    (internalAttachments) => {
      setAssessment((current) => ({
        ...current,
        internal_attachments: internalAttachments,
      }))
    },
    [setAssessment],
  )

  return {
    assignAssessment,
    unlockAssessment,
    continueReviewAssessment,
    completeAssessment,
    addOrEditAssessmentNotes,
    submitExpiredAssessment,
    setAssessmentAttachments,
    setAssessmentInternalAttachments,
  }
}
