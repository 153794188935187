import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import keyBy from 'lodash/keyBy'
import Icon from 'components-v2/atoms/Icon'
import AssessmentProgressBar from 'components/shared/AssessmentProgressBar'
import { getPageUrl } from 'utils/url'
import { toLocalTimeString } from 'utils/date'
import config from 'config'
import { AssessmentStatusTypes, AssessmentStatusOptions } from 'constants/index'
import {
  Link,
  Wrapper,
  Heading,
  Title,
  CloseDate,
  Body,
  Footer,
  Status,
  ProgressWrapper,
  ExtendButton,
} from './styles'

const AssessmentStatusOptionsObj = keyBy(AssessmentStatusOptions, 'value')

const AssessmentCard = ({ assessment, onRequestExtension, ...rest }) => {
  const color = AssessmentStatusOptionsObj[assessment.status]?.color
  const statusText = AssessmentStatusOptionsObj[assessment.status]?.label
  const url = `${getPageUrl('vendorProject', {
    id: assessment.project.id,
  })}#tab_${assessment.id}`
  const handleExtendButtonClick = useCallback(
    (e) => {
      e.preventDefault()
      onRequestExtension()
    },
    [onRequestExtension],
  )
  return (
    <Link href={url} {...rest}>
      <Wrapper color={color}>
        <Heading>
          <Title>{assessment.name}</Title>
          <CloseDate>
            Close Date:{' '}
            {toLocalTimeString(assessment.due_at, config.tableDateFormat)}
          </CloseDate>
        </Heading>
        <Body>{assessment.project.name}</Body>
        <Footer>
          {assessment.status === AssessmentStatusTypes.EXPIRED ? (
            <Status warning>
              <Icon icon="fa fa-exclamation-triangle" /> {statusText}
            </Status>
          ) : (
            <Status>{statusText}</Status>
          )}
          {assessment.status === AssessmentStatusTypes.IN_PROCESS && (
            <ProgressWrapper>
              <AssessmentProgressBar
                summary={assessment.progress}
                color={color}
              />
            </ProgressWrapper>
          )}
          {assessment.status === AssessmentStatusTypes.EXPIRED &&
            assessment.extension_request_allowed && (
              <ExtendButton onClick={handleExtendButtonClick}>
                Request Extension
              </ExtendButton>
            )}
          {assessment.status === AssessmentStatusTypes.EXPIRED &&
            assessment.extension_requested_at && (
              <ExtendButton disabled>Extension Requested</ExtendButton>
            )}
        </Footer>
      </Wrapper>
    </Link>
  )
}

AssessmentCard.propTypes = {
  assessment: PropTypes.object.isRequired,
  onRequestExtension: PropTypes.func.isRequired,
}

export default AssessmentCard
