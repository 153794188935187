import { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useGlobalLoader } from 'hooks'
import useApproveProjectVendorExtensionRequest from 'components/client/shared/useApproveProjectVendorExtensionRequest'
import { useClientAssessmentFetcher } from 'apis'

export default function useApproveExtensionRequest(assessment) {
  const queryClient = useQueryClient()
  const [showLoader, hideLoader] = useGlobalLoader()
  const approveProjectVendorExtensionRequest =
    useApproveProjectVendorExtensionRequest()

  return useCallback(() => {
    approveProjectVendorExtensionRequest({
      endAt: assessment.date_range.end_at,
      requestedAt: assessment.extension_requested_at,
      minutesRequested: assessment.extension_minutes_requested,
      projectVendorId: assessment.project_vendor_id,
      onSuccess: () => {
        const loaderId = showLoader()
        queryClient
          .invalidateQueries(
            useClientAssessmentFetcher.queryKey(),
            {
              predicate: (query) => query.queryKey[1].id === assessment.id,
            },
            {
              throwOnError: true,
            },
          )
          .catch(() => window.location.reload())
          .then(() => hideLoader(loaderId))
      },
    })
  }, [assessment, approveProjectVendorExtensionRequest, queryClient])
}
