import React from 'react'
import img from 'images/error/coffee.png'
import ErrorView from '../shared/ErrorView'

export default () => (
  <ErrorView
    imageUrl={img}
    errorCode="422"
    errorType="Unprocessable Entity"
    errorMessage="You didn't get a 415 or a 400, so most likely there is a problem within the contained instructions such as some semantically erroneous XML. Time to grab a coffee and get back to it!"
  />
)
