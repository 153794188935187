import Privva from 'Privva'
import keys from 'lodash/keys'
import forEach from 'lodash/forEach'

// Generate url based on template
// Example: /api/client/assessments/:id -> /api/client/assessments/1234
export const generateUrl = (
  template,
  params = {},
  query = {},
  extension = undefined,
) => {
  let url = template
  forEach(keys(params), (key) => {
    url = url.replace(`:${key}`, encodeURIComponent(params[key]))
  })
  if (extension) {
    url += `.${extension}`
  }
  return buildURL(url, query)
}

// Get api endpoint url
export const getEndpointUrl = (name, params, query, extension) => {
  const endpoint = Privva.Utils.endpoints[name]
  return generateUrl(endpoint, params, query, extension)
}

// Get page url
export const getPageUrl = (name, params, query, extension) => {
  const endpoint = Privva.Utils.pageUrls[name]
  return generateUrl(endpoint, params, query, extension)
}

// inlined based on axios buildURL.js as it is no longer exported
function encode(val) {
  return encodeURIComponent(val)
    .replace(/%3A/gi, ':')
    .replace(/%24/g, '$')
    .replace(/%2C/gi, ',')
    .replace(/%20/g, '+')
    .replace(/%5B/gi, '[')
    .replace(/%5D/gi, ']')
}

function buildURL(url, params, paramsSerializer) {
  if (!params) {
    return url
  }

  let serializedParams
  if (paramsSerializer) {
    serializedParams = paramsSerializer(params)
  } else if (
    typeof URLSearchParams !== 'undefined' &&
    params instanceof URLSearchParams
  ) {
    serializedParams = params.toString()
  } else {
    const parts = []

    Object.keys(params).forEach((originalKey) => {
      let val = params[originalKey]
      let key = originalKey
      if (val !== null && typeof val !== 'undefined') {
        if (Array.isArray(val)) {
          key = `${originalKey}[]`
        } else {
          val = [val]
        }

        val.forEach((v) => {
          let value = v
          if (value instanceof Date) {
            value = value.toISOString()
          } else if (value !== null && typeof value === 'object') {
            value = JSON.stringify(value)
          }
          parts.push(`${encode(key)}=${encode(value)}`)
        })
      }
    })

    serializedParams = parts.join('&')
  }

  let finalUrl = url
  if (serializedParams) {
    const hashmarkIndex = finalUrl.indexOf('#')
    if (hashmarkIndex !== -1) {
      finalUrl = finalUrl.slice(0, hashmarkIndex)
    }

    finalUrl += (finalUrl.indexOf('?') === -1 ? '?' : '&') + serializedParams
  }

  return finalUrl
}
