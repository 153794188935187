import React, { useState, useCallback, useRef, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import values from 'lodash/values'
import Input from 'components-v2/atoms/Input'
import AutoSizeTextarea from 'components-v2/atoms/AutoSizeTextarea'
import { FormWrapper, ButtonsWrapper, Button } from './styles'

export const dataTypes = {
  TEXT: 'text',
  TEXTAREA: 'textarea',
}

const Form = ({
  dataType,
  value: valueProp,
  placeholder,
  selectTextOnClick,
  required,
  inputProps,
  onSubmit,
  close,
}) => {
  const [value, setValue] = useState(valueProp)
  const inputRef = useRef()

  const handleChange = useCallback((e) => {
    setValue(e.target.value)
  }, [])

  const handleSubmit = useCallback(() => {
    close()
    onSubmit(value)
  }, [close, onSubmit, value])

  useLayoutEffect(() => {
    if (inputRef) {
      setTimeout(() => {
        if (selectTextOnClick) {
          inputRef.current.select()
        } else {
          inputRef.current.focus()
        }
      }, 100)
    }
  }, [selectTextOnClick])

  const props = {
    ...inputProps,
    value: value || '',
    placeholder,
    onChange: handleChange,
    inputRef,
  }
  return (
    <FormWrapper>
      {dataType === dataTypes.TEXTAREA && <AutoSizeTextarea {...props} />}
      {dataType === dataTypes.TEXT && <Input {...props} />}
      <ButtonsWrapper>
        <Button
          color="primary"
          size="small"
          disabled={required && !value}
          onClick={handleSubmit}
        >
          <i className="fa fa-check" />
        </Button>
        <Button size="small" onClick={close}>
          <i className="fa fa-times" />
        </Button>
      </ButtonsWrapper>
    </FormWrapper>
  )
}

Form.propTypes = {
  dataType: PropTypes.oneOf(values(dataTypes)),
  value: PropTypes.string,
  placeholder: PropTypes.string,
  selectTextOnClick: PropTypes.bool,
  required: PropTypes.bool,
  inputProps: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
}

Form.defaultProps = {
  dataType: dataTypes.TEXTAREA,
  value: '',
  placeholder: 'Enter text',
  selectTextOnClick: false,
  required: false,
  inputProps: {},
}

export default Form
