import {
  useClientVendorsSummaryFetcher,
  useClientVendorRequestsSummaryFetcher,
} from 'apis'

export default function useVendorsFetcher(params) {
  const vendorsSummaryResult = useClientVendorsSummaryFetcher(
    { params },
    { enabled: !params.requests },
  )
  const vendorRequestsSummaryResult = useClientVendorRequestsSummaryFetcher(
    { params },
    { enabled: params.requests },
  )
  return params.requests ? vendorRequestsSummaryResult : vendorsSummaryResult
}
