import styled from 'styled-components'
import Card from 'components-v2/organisms/Card'
import { colors } from 'styles'

export const Wrapper = styled(Card).attrs({ bordered: true })`
  display: flex;
  flex-direction: column;
`

export const Top = styled(Card.Segment)`
  flex-grow: 1;
  text-align: center;
  padding-bottom: 0;
`

export const Title = styled.h4`
  margin: 0 0 3px;
  color: ${colors.HEADING};
  font-size: 21px;
`

export const ChartWrapper = styled.div`
  position: relative;
`

export const MetricWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 20px;
  transform: translate(-50%, -50%);
  z-index: 1;
`

export const Bottom = styled(Card.ViewMoreSegment)`
  flex: none;
`
