import styled from 'styled-components'
import Button from 'components-v2/atoms/Button'
import Icon from 'components-v2/atoms/Icon'

export const EditIcon = styled(Icon).attrs({
  icon: 'fa fa-edit',
})`
  margin-right: 5px;
`

export const LinkButton = styled(Button).attrs({
  color: 'primary',
  variant: 'text',
})``
