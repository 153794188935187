import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { compose } from 'hocs'
import { toLocalTimeString } from 'utils/date'
import Input from 'components-v2/atoms/Input'
import withFullWidthProp from './withFullWidthProp'
import withMinDateTimeProp from './withMinDateTimeProp'
import withDurationsProp from './withDurationsProp'
import withTimezoneSelect from './withTimezoneSelect'
import withAccessibilityFix from './withAccessibilityFix'

const Wrapper = styled.div`
  .react-datepicker__view-calendar-icon input {
    padding: 7px 2em 7px 12px;
  }

  .react-datepicker__input-container .react-datepicker__calendar-icon {
    right: 0.5em;
    top: 50%;
    padding: 0;
    transform: translateY(-50%);
    color: var(--primary-color);
    pointer-events: none;
  }
`

// Customize CSS
const DatePicker = React.forwardRef(
  ({ value, placeholder, className, style, popperClassName, ...rest }, ref) => (
    <Wrapper className={className} style={style}>
      <ReactDatePicker
        {...rest}
        ref={ref}
        selected={value}
        placeholderText={placeholder}
        popperClassName={`${popperClassName || ''} datepicker-popper`}
      />
    </Wrapper>
  ),
)

DatePicker.propTypes = {
  value: PropTypes.instanceOf(Date),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  customInput: PropTypes.element,
  customInputRef: PropTypes.string,
  autoComplete: PropTypes.string,
  popperClassName: PropTypes.string,
}

const DatePickerEx = compose(
  withFullWidthProp,
  withTimezoneSelect,
  withDurationsProp,
  withMinDateTimeProp,
  withAccessibilityFix,
)(DatePicker)

DatePickerEx.defaultProps = {
  dateFormat: 'MM/dd/yyyy',
  customInput: <Input />,
  customInputRef: 'inputRef',
  autoComplete: 'off',
  renderDayContents: (day, date) => (
    <span data-test-label={toLocalTimeString(date, 'MM/dd/yyyy')}>{day}</span>
  ),
  showIcon: true,
  icon: 'fa fa-calendar',
}

DatePickerEx.displayName = 'DatePicker'

export default DatePickerEx
