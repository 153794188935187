import React from 'react'
import PropTypes from 'prop-types'
import find from 'lodash/find'
import map from 'lodash/map'
import {
  Wrapper,
  SectionSelectionTitle,
  JumpToText,
  SectionSelect,
} from './styles'

const StickyHeader = ({
  sections,
  selectedSectionId,
  onSectionChange,
  ...rest
}) => {
  const selectedSectionLabel = React.useMemo(() => {
    if (selectedSectionId) {
      const section = find(sections, { id: selectedSectionId })
      if (section) {
        return `${section.key} ${section.label}`
      }
    }
    return 'All Questions'
  }, [sections, selectedSectionId])
  return (
    <Wrapper {...rest}>
      <SectionSelectionTitle>{selectedSectionLabel}</SectionSelectionTitle>
      <JumpToText>Jump to: </JumpToText>
      <SectionSelect
        value={selectedSectionId || ''}
        onChange={(e) => onSectionChange(e.target.value)}
        onScroll={(e) => e.stopPropagation()}
      >
        {selectedSectionId ? (
          <option value="">All Questions</option>
        ) : (
          <option value="" disabled>
            Select a section
          </option>
        )}
        {map(sections, (section) => (
          <option key={section.id} value={section.id}>
            {section.key} {section.label}
          </option>
        ))}
      </SectionSelect>
    </Wrapper>
  )
}

StickyHeader.propTypes = {
  sections: PropTypes.array.isRequired,
  selectedSectionId: PropTypes.string,
  onSectionChange: PropTypes.func.isRequired,
}

export default StickyHeader
