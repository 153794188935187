import React from 'react'
import find from 'lodash/find'
import { AssessmentStatusTypes } from 'constants/index'
import Icon from 'components-v2/atoms/Icon'
import Button from 'components-v2/atoms/Button'
import AssessmentProgressBar from 'components/shared/AssessmentProgressBar'
import AssessmentStatusAndGrade from 'components/client/shared/AssessmentStatusAndGrade'
import { StatusWrapper } from '../styles'

export const formatHeader = (template, canLinkTemplate) =>
  canLinkTemplate ? <a href={template.url}>{template.name}</a> : template.name

export const formatCell =
  ({
    authManager,
    assessments,
    template,
    unlaunchedString,
    onApproveExtensionRequest,
  }) =>
  (info) => {
    if (!assessments) {
      return null
    }

    const canLink = authManager.userCan('show', 'Assessment')
    const canManage = authManager.userCan('manage', 'Assessment')
    const assessment = find(assessments, {
      assessment_template_id: template.id,
      vendor_id: info.row.original.vendor.id,
    })
    const scoredStates = [
      AssessmentStatusTypes.REVIEWED,
      AssessmentStatusTypes.SUBMITTED,
    ]

    if (
      assessment &&
      scoredStates.includes(assessment.status) &&
      assessment.risk_rating != null
    ) {
      return <AssessmentStatusAndGrade assessment={assessment} />
    }
    if (assessment) {
      return (
        <div>
          <StatusWrapper>
            {canLink ? (
              <a href={assessment.url}>{assessment.status.titleize()}</a>
            ) : (
              assessment.status.titleize()
            )}
            &nbsp;
            {assessment.status === AssessmentStatusTypes.EXPIRED &&
              assessment.extension_requested_at && (
                <Button
                  color="danger"
                  variant="outlined"
                  size="xs"
                  onClick={() => onApproveExtensionRequest(info.row.original)}
                  disabled={!canManage}
                >
                  Extension Requested
                  <Icon icon="fa fa-arrows-h" />
                </Button>
              )}
          </StatusWrapper>
          {assessment.status !== AssessmentStatusTypes.SUBMITTED && (
            <AssessmentProgressBar summary={assessment.progress} />
          )}
        </div>
      )
    }
    return unlaunchedString
  }
