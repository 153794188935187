import React from 'react'
import PropTypes from 'prop-types'
import keyBy from 'lodash/keyBy'
import minBy from 'lodash/minBy'
import { GloborgIntegrationOptions } from 'constants/index'
import { Wrapper, ImageLink, SourceImg, MetricIcon } from './styles'

const IntegrationMetric = ({
  signatureIntegrationMetric,
  formattedIntegrationMetrics,
  onViewIntegrationMetricsClick,
}) => {
  const types = Object.keys(signatureIntegrationMetric.integration_metrics)
  const GloborgIntegrationOptionsObj = keyBy(GloborgIntegrationOptions, 'value')

  return (
    <div>
      {types.map((metricType) => {
        if (
          formattedIntegrationMetrics[metricType] &&
          signatureIntegrationMetric.integration_metrics[metricType] &&
          signatureIntegrationMetric.integration_metrics[metricType].length > 0
        ) {
          const metricKeys =
            signatureIntegrationMetric.integration_metrics[metricType]
          const metrics = formattedIntegrationMetrics[metricType].filter(
            (m) => metricKeys.indexOf(m.code) >= 0,
          )
          const lowMetric = minBy(metrics, 'value')
          if (!lowMetric) {
            return null
          }

          return (
            <div key={metricType}>
              <Wrapper>
                <ImageLink
                  onClick={() =>
                    onViewIntegrationMetricsClick(metricType, metricKeys)
                  }
                >
                  {lowMetric.icon && (
                    <MetricIcon icon={lowMetric.icon} color={lowMetric.color} />
                  )}
                  <SourceImg
                    src={GloborgIntegrationOptionsObj[metricType].sourceLogo}
                    width={80}
                  />
                </ImageLink>
              </Wrapper>
            </div>
          )
        }
        return null
      })}
    </div>
  )
}

IntegrationMetric.propTypes = {
  signatureIntegrationMetric: PropTypes.object.isRequired,
  formattedIntegrationMetrics: PropTypes.object.isRequired,
  onViewIntegrationMetricsClick: PropTypes.func.isRequired,
}

export default IntegrationMetric
