import React from 'react'
import PropTypes from 'prop-types'
import reduce from 'lodash/reduce'
import keyBy from 'lodash/keyBy'
import { GoogleChart } from 'components-v2/molecules/Charts'
import { AssessmentStatusTypes, AssessmentStatusOptions } from 'constants/index'
import Widget from '../Widget'

const AssessmentStatusOptionsObj = keyBy(AssessmentStatusOptions, 'value')

const rowIds = [
  AssessmentStatusTypes.INVITED,
  AssessmentStatusTypes.IN_PROCESS,
  AssessmentStatusTypes.SUBMITTED,
  AssessmentStatusTypes.EXPIRED,
  AssessmentStatusTypes.REVIEWED,
]

// eslint-disable-next-line no-unused-vars
const getChartOptions = ([_, ...data]) => ({
  legend: { position: 'none' },
  chartArea: {
    left: '15%',
    top: '10%',
    width: '85%',
    height: '80%',
  },
  vAxis: {
    format: 'percent',
    minValue: 0,
    maxValue: Math.min(Math.max(...data.map((e) => e[1])) + 0.1, 1),
  },
  animation: {
    startup: true,
    duration: 1000,
    easing: 'out',
  },
})

const getChartEvents = (onStatusSelect) => [
  {
    eventName: 'select',
    callback: ({ chartWrapper }) => {
      onStatusSelect(rowIds[chartWrapper.getChart().getSelection()[0].row])
    },
  },
  {
    eventName: 'ready',
    callback: ({ chartWrapper, google }) => {
      const chart = chartWrapper.getChart()
      google.visualization.events.addListener(chart, 'onmouseover', () => {
        chart.container.style.cursor = 'pointer'
      })
      google.visualization.events.addListener(chart, 'onmouseout', () => {
        chart.container.style.cursor = 'default'
      })
    },
  },
]

const getChartData = (data) => {
  const all = getTotalCount(data)
  const chartData = rowIds.map((rowId) => [
    rowId.titleize(),
    data[rowId] / all || 0,
    `color: ${AssessmentStatusOptionsObj[rowId].color}`,
    `Count: ${data[rowId] || 0}`,
  ])
  chartData.unshift([
    'Status',
    'Percent',
    { role: 'style' },
    { type: 'string', role: 'tooltip' },
  ])
  return chartData
}

function getTotalCount(data) {
  return reduce(data, (sum, n) => sum + n, 0)
}

const AssessmentStatusChartWidget = ({
  data,
  onStatusSelect,
  canLink,
  ...rest
}) => {
  const chartData = React.useMemo(() => getChartData(data), [data])
  const chartEvents = React.useMemo(
    () => getChartEvents(onStatusSelect),
    [onStatusSelect],
  )
  const chartOptions = React.useMemo(
    () => getChartOptions(chartData),
    [chartData],
  )
  return (
    <Widget {...rest} useDefault={getTotalCount(data) === 0}>
      <GoogleChart
        width="100%"
        height={320}
        chartType="ColumnChart"
        data={chartData}
        options={chartOptions}
        chartEvents={canLink && chartEvents}
      />
    </Widget>
  )
}

AssessmentStatusChartWidget.propTypes = {
  data: PropTypes.object.isRequired,
  onStatusSelect: PropTypes.func.isRequired,
  canLink: PropTypes.bool,
}

export default React.memo(AssessmentStatusChartWidget)
