import { useCallback } from 'react'
import NoteModal from 'components/shared/NoteModal'
import { useModal, useGlobalLoader } from 'hooks'
import { useUpdateAssistAssessment } from 'apis'
import NotificationManager from 'lib/notifications'

export default function useUpdateAssessmentNote(assessments) {
  const [showLoader, hideLoader] = useGlobalLoader()
  const [openModal, closeModal] = useModal(NoteModal)
  const { mutateAsync: updateAssistAssessment } = useUpdateAssistAssessment()
  return useCallback(
    (assessmentId) => {
      const onSubmit = ({ body }) => {
        const loaderId = showLoader()
        return updateAssistAssessment({
          id: assessmentId,
          data: {
            assessment: {
              notes_attributes: [
                {
                  id: note?.id ?? null,
                  body,
                },
              ],
            },
          },
        })
          .then(() => closeModal())
          .catch(() => NotificationManager.error())
          .then(() => hideLoader(loaderId))
      }
      const note = assessments?.find((e) => e.id === assessmentId)?.notes?.[0]
      openModal({
        note,
        onSubmit,
      })
    },
    [
      assessments,
      updateAssistAssessment,
      openModal,
      closeModal,
      showLoader,
      hideLoader,
    ],
  )
}
