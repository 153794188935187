import React, { useState } from 'react'
import map from 'lodash/map'
import { ThemeProvider } from 'styled-components'
import Loader from 'components-v2/molecules/Loader'
import Error from 'components-v2/molecules/Error'
import Table from 'components-v2/molecules/Table'
import { Row, Col } from 'components-v2/organisms/Layout'
import { dateFormatterFactory } from 'lib/table'
import { useLibraryManageShareRequestsFetcher } from 'apis'
import PendingRequestsCard from './PendingRequestsCard'
import useReviewPendingRequests from './useReviewPendingRequests'
import { theme, Heading1, Heading2, ToggleIconButton } from './styles'

const dateFormatter = dateFormatterFactory()
const statusMap = {
  active: 'Approved',
  denied: 'Declined',
  expired: 'Expired',
}

const getColumns = (clientRef) => [
  {
    id: 'recipient_id',
    header: 'Client',
    accessorFn: (e) => clientRef[e.recipient_id],
  },
  {
    id: 'listing.name',
    header: 'File',
  },
  {
    id: 'created_at',
    header: 'Request Date',
    cell: dateFormatter,
  },
  {
    id: 'updated_at',
    header: 'Response Date',
    cell: dateFormatter,
  },
  {
    id: 'status',
    header: 'Status',
    accessorFn: (e) => statusMap[e.status],
  },
  // {
  //   id: 'note', // FIXME: What is property name for note?
  //   header: 'Note to client',
  // },
]

export default () => {
  const [pastRequestsOpen, setPastRequestsOpen] = useState(true)
  const { data, isFetching, isError } = useLibraryManageShareRequestsFetcher()
  const handleReview = useReviewPendingRequests(data?.client_ref)

  if (isFetching) {
    return <Loader />
  }

  if (isError) {
    return <Error />
  }

  return (
    <ThemeProvider theme={theme}>
      <Heading1>Pending requests</Heading1>
      <Row $equalHeight>
        {map(data.pending, (requests, clientId) => (
          <Col sm={6} md={3} key={clientId}>
            <PendingRequestsCard
              clientName={data.client_ref[clientId]}
              requestedAt={requests[0].created_at}
              count={requests.length}
              onReview={() => handleReview(clientId, requests)}
            />
          </Col>
        ))}
      </Row>
      <Heading2>
        View Past Requests
        <ToggleIconButton
          icon={pastRequestsOpen ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}
          onClick={() => setPastRequestsOpen((current) => !current)}
        />
      </Heading2>
      {pastRequestsOpen && (
        <Table
          data={data.past}
          columns={getColumns(data.client_ref)}
          enablePagination={false}
          type={Table.types.ALT}
        />
      )}
    </ThemeProvider>
  )
}
