import React from 'react'
import PropTypes from 'prop-types'
import Menu from 'components-v2/molecules/Menu'
import NavItem from '../NavItem'
import {
  StyledMenu,
  MenuItems,
  MenuItem,
  StyledUserAvatar,
  Caret,
} from './styles'

export default function AccountMenu({ currentUser, children }) {
  return (
    <StyledMenu placement="bottom-end" offset={-1}>
      <Menu.Button as={NavItem}>
        {currentUser.avatar_url ? (
          <StyledUserAvatar
            src={currentUser.avatar_url}
            name={currentUser.name}
            size={34}
          />
        ) : (
          <StyledUserAvatar
            name={currentUser.name}
            color="transparent"
            size={32}
            noSrc
          />
        )}
        <div>My Account</div>
        <Caret />
      </Menu.Button>
      <MenuItems>{children}</MenuItems>
    </StyledMenu>
  )
}

AccountMenu.Item = MenuItem
AccountMenu.Divider = Menu.Divider

AccountMenu.propTypes = {
  currentUser: PropTypes.shape({
    avatar_url: PropTypes.string,
    name: PropTypes.string.isRequired,
    organizations_count: PropTypes.number.isRequired,
  }),
  children: PropTypes.node,
}
