import React from 'react'
import PropTypes from 'prop-types'
import A from 'components-v2/atoms/A'
import Tooltip from 'components-v2/molecules/Tooltip'
import Table from 'components-v2/molecules/Table'
import Card from 'components-v2/organisms/Card'
import GradeLabel from 'components/shared/GradeLabel'
import { getPageUrl } from 'utils/url'
import { getRiskRatingLabel, getRiskRatingColor } from 'lib/assessment'
import { dateFormatterFactory } from 'lib/table'
import { AssessmentStatusTypes, SortOrderTypes } from 'constants/index'
import { useAuthorizations } from 'hooks'
import { Wrapper, AssessmentRiskRatingLabel } from './styles'

const dateFormatter = dateFormatterFactory()

const assessmentTableColumns = (canLink) => [
  {
    id: 'name',
    header: 'Assessment',
    cell: (info) =>
      canLink ? (
        <A href={info.row.original.url} underline>
          {info.getValue()}
        </A>
      ) : (
        info.getValue()
      ),
    enableSorting: false,
    width: '23%',
  },
  {
    id: 'project.name',
    header: 'Project',
    enableSorting: false,
    width: '23%',
  },
  {
    id: 'status',
    header: 'Status',
    cell: (info) => <span>{info.row.original.humanized_status}</span>,
    enableSorting: false,
    width: '10%',
  },
  {
    id: 'risk_rating',
    header: 'Rating',
    cell: (info) => {
      const riskRating =
        [
          AssessmentStatusTypes.REVIEWED,
          AssessmentStatusTypes.SUBMITTED,
        ].includes(info.row.original.status) && info.getValue()
      const ratingLabel = (
        <AssessmentRiskRatingLabel color={getRiskRatingColor(riskRating)}>
          {getRiskRatingLabel(
            riskRating,
            'N/A',
            info.row.original.status === AssessmentStatusTypes.SUBMITTED,
          )}
        </AssessmentRiskRatingLabel>
      )
      return info.row.original.status === AssessmentStatusTypes.SUBMITTED ? (
        <Tooltip parent={ratingLabel}>
          Score is subject to change as assessment is reviewed.
        </Tooltip>
      ) : (
        ratingLabel
      )
    },
    enableSorting: false,
    width: '10%',
  },
  {
    id: 'grade',
    header: 'Grade',
    cell: (info) => <GradeLabel grade={info.getValue()} />,
    enableSorting: false,
    width: '9%',
  },
  {
    id: 'sent_at',
    header: 'Date',
    cell: dateFormatter,
    width: '10%',
  },
]

const AssessmentTable = ({ assessmentsData, vendorId, ...rest }) => {
  const [canLink] = useAuthorizations(['show', 'Assessment'])
  return (
    <Wrapper {...rest}>
      <Card.Segment padding={false}>
        <Table
          loading={assessmentsData.isLoading}
          data={assessmentsData.data || []}
          columns={assessmentTableColumns(canLink)}
          defaultSorting={{
            sortField: 'sent_at',
            sortOrder: SortOrderTypes.DESC,
          }}
          enablePagination={false}
          noDataText="No assessments found."
          type={Table.types.ALT}
          bordered={false}
        />
      </Card.Segment>
      {assessmentsData.data &&
        assessmentsData.totalSize > assessmentsData.data.length && (
          <Card.ViewMoreSegment
            href={getPageUrl('clientVendorResultsSummary', { id: vendorId })}
          >
            View all {assessmentsData.totalSize} assessments
          </Card.ViewMoreSegment>
        )}
    </Wrapper>
  )
}

AssessmentTable.propTypes = {
  vendorId: PropTypes.string.isRequired,
  assessmentsData: PropTypes.object.isRequired,
}

export default AssessmentTable
