import React from 'react'
import PropTypes from 'prop-types'
import Privva from 'Privva'
import A from 'components-v2/atoms/A'
import Button from 'components-v2/atoms/Button'
import Table from 'components-v2/molecules/Table'
import Toolbar, { SearchInput } from 'components-v2/molecules/Table/Toolbar'
import ToolbarGroup from 'components-v2/molecules/ToolbarGroup'
import PageHeader from 'components-v2/organisms/PageHeader'
import { dateFormatterFactory } from 'lib/table'
import useAuthorizations from 'hooks/useAuthorizations'
import StatusLabel from './StatusLabel'
import { StyledSelect } from './styles'

const dateFormatter = dateFormatterFactory()
const templateTableColumns = [
  {
    id: 'id',
    header: 'id',
    hidden: true,
  },
  {
    id: 'name',
    header: 'Template',
    cell: (props) => <a href={props.row.original.url}>{props.getValue()}</a>,
    width: '55%',
  },
  {
    id: 'project_count',
    header: 'Projects',
    width: '15%',
  },
  {
    id: 'updated_at',
    header: 'Updated',
    cell: dateFormatter,
    width: '15%',
  },
  {
    id: 'status',
    header: 'Status',
    cell: (props) => <StatusLabel status={props.getValue()} />,
    width: '15%',
  },
]

const View = ({
  templates,
  totalSize,
  page,
  sizePerPage,
  sortField,
  sortOrder,
  searchText,
  statusFilter,
  statusFilterOptions,
  loading,
  onPaginationChange,
  onSortingChange,
  onSearchChange,
  onStatusFilterChange,
  onCreateClientAssessmentTemplate,
  onShowLibrary,
}) => {
  const [canCreate] = useAuthorizations('create')
  return (
    <div>
      <PageHeader title="Templates">
        {canCreate && (
          <ToolbarGroup>
            <Button
              onClick={onCreateClientAssessmentTemplate}
              data-test-id="blank-template"
            >
              <i className="fa fa-pencil-square-o fa-fw" />
              New Blank Template
            </Button>
            <Button
              color="primary"
              onClick={onShowLibrary}
              data-test-id="template-library"
            >
              <i className="fa fa-list-alt fa-fw" />
              Add From Library
            </Button>
          </ToolbarGroup>
        )}
      </PageHeader>
      <Toolbar>
        <SearchInput value={searchText} onChange={onSearchChange} />
        <StyledSelect
          placeholder="Select"
          value={statusFilter}
          onChange={onStatusFilterChange}
        >
          <option key="all" value="">
            All Statuses
          </option>
          {statusFilterOptions.map((s) => (
            <option key={s} value={s}>
              {s.titleize()}
            </option>
          ))}
        </StyledSelect>
      </Toolbar>
      <Table
        loading={loading}
        data={templates || []}
        columns={templateTableColumns}
        totalSize={totalSize}
        pagination={{ page, sizePerPage }}
        sorting={{ sortField, sortOrder }}
        onPaginationChange={onPaginationChange}
        onSortingChange={onSortingChange}
        manualPagination
        manualSorting
        noDataText={
          <div>
            No templates found.
            {!searchText && !statusFilter && (
              <>
                &nbsp;
                <A onClick={onCreateClientAssessmentTemplate} underline>
                  Create
                </A>{' '}
                a template now or{' '}
                <A onClick={onShowLibrary} underline>
                  add
                </A>{' '}
                one from the {Privva.Utils.brand.programName} standard template
                library.
              </>
            )}
          </div>
        }
        type={Table.types.ALT}
      />
    </div>
  )
}

View.propTypes = {
  templates: PropTypes.array,
  totalSize: PropTypes.number,
  page: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  sortField: PropTypes.string,
  sortOrder: PropTypes.bool,
  searchText: PropTypes.string.isRequired,
  statusFilter: PropTypes.string.isRequired,
  statusFilterOptions: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  onPaginationChange: PropTypes.func.isRequired,
  onSortingChange: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onStatusFilterChange: PropTypes.func.isRequired,
  onCreateClientAssessmentTemplate: PropTypes.func.isRequired,
  onShowLibrary: PropTypes.func.isRequired,
}

export default View
