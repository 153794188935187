import React from 'react'
import PropTypes from 'prop-types'
import BaseTable from './BaseTable'
import Alt from './Alt'
import Headless from './Headless'
import Mini from './Mini'
import Mini2 from './Mini2'

const types = {
  ALT: 'alt',
  DEFAULT: 'default',
  MINI: 'mini',
  MINI2: 'mini2',
  HEADLESS: 'headless',
}

const getComponent = (type) => {
  switch (type) {
    case types.ALT:
      return Alt
    case types.MINI:
      return Mini
    case types.MINI2:
      return Mini2
    case types.HEADLESS:
      return Headless
    default:
      return BaseTable
  }
}

const Table = ({ type, ...rest }) => {
  const Component = getComponent(type)
  return <Component {...rest} />
}

Table.propTypes = {
  type: PropTypes.oneOf([
    types.ALT,
    types.MINI,
    types.MINI2,
    types.HEADLESS,
    types.DEFAULT,
  ]),
}

Table.defaultProps = {
  type: types.DEFAULT,
}

Table.types = types

export default Table
