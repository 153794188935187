import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Tooltip from 'components-v2/molecules/Tooltip'

const WrappedText = styled.span`
  white-space: nowrap;
`

const GradeLabel = ({
  grade,
  iconOnly,
  iconLeft,
  passText,
  failText,
  noGradeText,
  ...rest
}) => {
  const icon = (() => {
    if (grade > 0) {
      return <i className="fa fa-check-circle text-very-good" />
    }
    if (grade < 0) {
      return <i className="fa fa-times-circle text-very-poor" />
    }
    return <i className="fa fa-minus-circle text-nothing" />
  })()
  const label = (() => {
    if (grade > 0) {
      return passText
    }
    if (grade < 0) {
      return failText
    }
    return noGradeText
  })()
  if (iconOnly) {
    return (
      <WrappedText {...rest}>
        <Tooltip parent={icon}>{label}</Tooltip>
      </WrappedText>
    )
  }
  if (iconLeft) {
    return (
      <WrappedText {...rest}>
        {icon}
        &nbsp;
        {label}
      </WrappedText>
    )
  }
  return (
    <WrappedText {...rest}>
      {label}
      &nbsp;
      {icon}
    </WrappedText>
  )
}

GradeLabel.propTypes = {
  grade: PropTypes.number,
  iconOnly: PropTypes.bool,
  iconLeft: PropTypes.bool,
  passText: PropTypes.string,
  failText: PropTypes.string,
  noGradeText: PropTypes.string,
}

GradeLabel.defaultProps = {
  iconOnly: false,
  iconLeft: true,
  passText: 'Pass',
  failText: 'Fail',
  noGradeText: 'No Grade',
}

export default GradeLabel
