import { useEffect, useRef } from 'react'
import { useModal, useGlobalLoader } from 'hooks'
import NotificationManager from 'lib/notifications'
import { useUpdateClientVendor } from 'apis'
import NextAssessmentReminderModal from './NextAssessmentReminderModal'

export default function useNextAssessmentReminder(assessment, enabled = false) {
  const remindedRef = useRef(false)
  const [showLoader, hideLoader] = useGlobalLoader()
  const [openModal, closeModal] = useModal(NextAssessmentReminderModal)
  const { mutateAsync: updateClientVendor } = useUpdateClientVendor()

  useEffect(() => {
    if (!enabled || remindedRef.current) {
      return
    }

    openModal({
      vendorName: assessment.vendor.name,
      onSubmit: async (formData) => {
        const loaderId = showLoader()
        try {
          await updateClientVendor({
            id: assessment.vendor.id,
            data: {
              vendor: { next_reassessment_at: formData.date },
            },
          })
          NotificationManager.success('Changes saved')
          closeModal()
        } catch {
          NotificationManager.error()
        }
        hideLoader(loaderId)
      },
    })
    remindedRef.current = true
  }, [
    enabled,
    openModal,
    closeModal,
    assessment,
    updateClientVendor,
    showLoader,
    hideLoader,
  ])
}
