import styled from 'styled-components'
import Capsule from 'components-v2/atoms/Capsule'

export const ChartWrapper = styled.div`
  position: relative;
  font-size: 12px;
`

export const MetricWrapper = styled.div`
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
`

export const Metric = styled(Capsule)`
  font-size: 28px;

  & > *:first-child {
    width: 60px;
  }

  & > *:last-child {
    width: 70px;
  }
`
