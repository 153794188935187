/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import some from 'lodash/some'
import find from 'lodash/find'
import mapValues from 'lodash/mapValues'
import unset from 'lodash/unset'
import map from 'lodash/map'
import filter from 'lodash/filter'
import { Formik } from 'formik'
import Dialog from 'components-v2/molecules/Dialog'
import { CommentDots } from '@styled-icons/fa-solid/CommentDots'
import { Paperclip } from '@styled-icons/fa-solid/Paperclip'
import { log } from 'utils/debug'
import Icon from 'components-v2/atoms/Icon'
import Button from 'components-v2/atoms/Button'
import FormField from 'components-v2/molecules/FormField'
import Tooltip from 'components-v2/molecules/Tooltip'
import {
  AssessmentElementTypes,
  AssessmentElementSubtypes,
  AssessmentElementSubtypeOptions,
} from 'constants/index'
import ChoicesEditorField from './ChoicesEditorField'
import { getSectionSelectOptions, getQuestionSelectOptions } from '../utils'
import { QuestionPlacementTypes } from '../constants'
import { BlockLabel, FieldRow, SmallWidthFormField } from './styles'

const getFormFieldValue = (element, key) => {
  if (key === 'choices') {
    return map(element[key], (o) => ({
      ...o,
      required: o.require_artifacts,
    }))
  }
  return element[key] === null ? undefined : element[key]
}

const getFormConfig = ({
  sectionRequired,
  sectionSelectOptions,
  elements,
  elementId,
  initialValues: initialValuesProp,
}) => {
  let initialValues = {
    placement: QuestionPlacementTypes.END_OF_SECTION,
    relative_question_id: undefined,
    parent_id:
      sectionSelectOptions.length > 0
        ? sectionSelectOptions[sectionSelectOptions.length - 1].value
        : undefined,
    label: undefined,
    attachments: [],
    note: undefined,
    type: AssessmentElementTypes.SINGLE_SELECT_QUESTION,
    subtype: '',
    choices: undefined,
    weight: 5,
    required: false,
    allow_comments: true,
    allow_attachments: true,
  }
  const validations = {
    placement: yup.string().required(),
    parent_id: yup.string().when('placement', {
      is: (val) =>
        val === QuestionPlacementTypes.END_OF_SECTION && sectionRequired,
      then: yup.string().required('Please select a section'),
    }),
    relative_question_id: yup.string().when('placement', {
      is: QuestionPlacementTypes.END_OF_SECTION,
      otherwise: yup.string().required('Please select a question'),
    }),
    label: yup.string().label('Label').required(),
    // attachment: yup.mixed(),
    note: yup.string().max(3000, '3000 character maximum length'),
    type: yup.string().label('Type').required(),
    choices: yup.array().when('type', {
      is: (val) =>
        val === AssessmentElementTypes.SINGLE_SELECT_QUESTION ||
        val === AssessmentElementTypes.MULTI_SELECT_QUESTION,
      then: yup
        .array()
        .test(
          'isNonEmptyChoices',
          'Choices are required',
          (value) =>
            Array.isArray(value) &&
            some(value, (e) => e && e.text && e.text.trim()),
        ),
    }),
    weight: yup
      .number()
      .label('Weight')
      .required()
      .min(0, 'Weight must be between 0 and 10')
      .max(10, 'Weight must be between 0 and 10'),
    allow_comments: yup
      .boolean()
      .test(
        'ensureRequiredArtifactsAllowed',
        'Please allow comments and/or attachments.',
        function callback(value) {
          return (
            value ||
            this.parent.allow_attachments ||
            !some(this.parent.choices, 'required')
          )
        },
      ),
    allow_attachments: yup
      .boolean()
      .test(
        'ensureRequiredArtifactsAllowed',
        'Please allow comments and/or attachments.',
        function callback(value) {
          return (
            value ||
            this.parent.allow_comments ||
            !some(this.parent.choices, 'required')
          )
        },
      ),
  }

  // In case of EDIT, override initialValues with the given element data and update validations
  if (elementId) {
    unset(initialValues, 'placement')
    unset(initialValues, 'relative_question_id')
    const element = find(elements, { id: elementId }, {})
    initialValues = mapValues(initialValues, (_, key) =>
      getFormFieldValue(element, key),
    )
    unset(validations, 'placement')
    unset(validations, 'relative_question_id')
    validations.parent_id = sectionRequired
      ? yup.string().required('Please select a section')
      : yup.string()
  } else {
    // In case of CREATE, respect initialValues prop
    initialValues = {
      ...initialValues,
      ...initialValuesProp,
    }
  }

  return {
    initialValues,
    validationSchema: yup.object().shape(validations),
  }
}

const placementOptions = [
  {
    label: 'End of section',
    value: QuestionPlacementTypes.END_OF_SECTION,
  },
  {
    label: 'Before question',
    value: QuestionPlacementTypes.BEFORE_QUESTION,
  },
  {
    label: 'After question',
    value: QuestionPlacementTypes.AFTER_QUESTION,
  },
]

const subtypeOptions = [
  AssessmentElementSubtypes.FLOAT,
  AssessmentElementSubtypes.MONEY,
  AssessmentElementSubtypes.DATE,
  AssessmentElementSubtypes.DATETIME,
  AssessmentElementSubtypes.YEAR,
  AssessmentElementSubtypes.PHONE,
  AssessmentElementSubtypes.STATE,
  AssessmentElementSubtypes.ZIPCODE,
  AssessmentElementSubtypes.EMAIL,
  AssessmentElementSubtypes.URI,
].map((subtype) =>
  AssessmentElementSubtypeOptions.find((e) => e.value === subtype),
)

const SubmitTypes = {
  DEFAULT: 'default',
  SAVE_AND_NEW: 'save_and_new',
}

const handleKeyPress = (event) => {
  if (
    event.which === 13 /* Enter */ &&
    event.target.tagName.toLowerCase() !== 'textarea'
  ) {
    event.preventDefault()
  }
}

const QuestionFormModal = ({
  initialValues,
  elementId,
  elements,
  questionClassesForSelect,
  sectionRequired,
  submitAndNewButtonVisible,
  onSubmit,
  onSubmitAndNew,
  onClose,
  ...rest
}) => {
  const submitTypeRef = React.useRef(SubmitTypes.DEFAULT)
  const isNew = !elementId
  const sectionSelectOptions = getSectionSelectOptions(elements)
  const relativeQuestionSelectOptions = getQuestionSelectOptions(elements)

  const formConfig = React.useMemo(
    () =>
      getFormConfig({
        sectionRequired,
        sectionSelectOptions,
        elements,
        elementId,
        initialValues,
      }),
    [sectionRequired, sectionSelectOptions, elements, elementId, initialValues],
  )

  const handleAttachmentChange = (attProps) => {
    log({ attProps })
  }

  const handleSubmit = React.useCallback(
    async (formData, options) => {
      const { choices, ...elementData } = formData
      // Trim text properties, set sort order
      let newChoices = map(
        choices,
        ({ text, required, ...choicesRest }, idx) => ({
          ...choicesRest,
          text: text ? text.trim() : null,
          sort_order: idx,
          // eslint-disable-next-line camelcase
          require_artifacts: required,
        }),
      )
      // Filter out empty choices
      newChoices = filter(newChoices, ({ text }) => text)
      // Rename choices property
      // eslint-disable-next-line dot-notation
      elementData['choices_attributes'] = newChoices
      if (submitTypeRef.current === SubmitTypes.SAVE_AND_NEW) {
        await onSubmitAndNew(elementData, options)
      } else {
        await onSubmit(elementData, options)
      }
    },
    [onSubmit, onSubmitAndNew],
  )

  return (
    <Dialog {...rest} onClose={onClose}>
      <Formik {...formConfig} onSubmit={handleSubmit}>
        {(formProps) => (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <form onSubmit={formProps.handleSubmit} onKeyPress={handleKeyPress}>
            <Dialog.Header onClose={onClose}>
              {isNew ? 'New Question' : 'Edit Question'}
            </Dialog.Header>
            <Dialog.Content>
              {isNew ? (
                <>
                  <BlockLabel>Question Placement</BlockLabel>
                  <FieldRow>
                    <SmallWidthFormField
                      name="placement"
                      type={FormField.types.SELECT}
                      options={placementOptions}
                    />
                    {formProps.values.placement ===
                    QuestionPlacementTypes.END_OF_SECTION ? (
                      <FormField
                        name="parent_id"
                        type={FormField.types.SEARCHABLE_SELECT}
                        options={sectionSelectOptions}
                        controlProps={{ autoFocus: true }}
                      />
                    ) : (
                      <FormField
                        name="relative_question_id"
                        type={FormField.types.SEARCHABLE_SELECT}
                        options={relativeQuestionSelectOptions}
                        controlProps={{ autoFocus: true }}
                      />
                    )}
                  </FieldRow>
                </>
              ) : (
                <FormField
                  name="parent_id"
                  label="Section"
                  type={FormField.types.SEARCHABLE_SELECT}
                  options={sectionSelectOptions}
                  controlProps={{ autoFocus: true }}
                />
              )}
              <FormField
                name="label"
                label="Question"
                type={FormField.types.TEXTAREA}
              />
              <FormField
                name="attachments"
                label="File Downloads"
                type={FormField.types.FILES_INPUT}
                onChange={handleAttachmentChange}
              />
              <FormField
                name="note"
                label="Note"
                type={FormField.types.TEXTAREA}
                helpMessage="(optional) This will be displayed as a tooltip for the question"
              />
              <FormField
                name="type"
                label="Type"
                type={FormField.types.SELECT}
                options={questionClassesForSelect}
              />
              {formProps.values.type ===
                AssessmentElementTypes.TEXT_QUESTION && (
                <FormField
                  name="subtype"
                  label="Text Validation"
                  type={FormField.types.SELECT}
                  options={subtypeOptions}
                  placeholder="None"
                  controlProps={{ placeholderSelectable: true }}
                />
              )}
              {(formProps.values.type ===
                AssessmentElementTypes.SINGLE_SELECT_QUESTION ||
                formProps.values.type ===
                  AssessmentElementTypes.MULTI_SELECT_QUESTION) && (
                <FormField
                  name="choices"
                  label="Choices"
                  component={ChoicesEditorField}
                  requiredColumnHeader={
                    <Tooltip
                      parent={
                        <span>
                          <Icon icon={CommentDots} /> or{' '}
                          <Icon icon={Paperclip} />
                        </span>
                      }
                    >
                      Require comment or attachment.
                    </Tooltip>
                  }
                  presets={
                    formProps.values.type ===
                    AssessmentElementTypes.SINGLE_SELECT_QUESTION
                      ? [
                          ['Yes', 'No'],
                          ['Yes', 'No', 'N/A'],
                        ]
                      : undefined
                  }
                />
              )}
              <FormField
                name="weight"
                label="Score Weight"
                helpMessage="The weight this question has on the rating score. Default is 5."
              />
              <FormField
                name="required"
                label="Require Answer"
                type={FormField.types.CHECKBOX}
              />
              <FormField
                name="allow_comments"
                label="Allow Comments"
                type={FormField.types.CHECKBOX}
              />
              <FormField
                name="allow_attachments"
                label="Allow Attachments"
                type={FormField.types.CHECKBOX}
              />
            </Dialog.Content>
            <Dialog.Footer>
              <Button onClick={onClose}>Cancel</Button>
              {submitAndNewButtonVisible && (
                <Button
                  color="primary"
                  disabled={formProps.isSubmitting}
                  onClick={() => {
                    submitTypeRef.current = SubmitTypes.SAVE_AND_NEW
                    formProps.handleSubmit()
                  }}
                >
                  Save & Add New
                </Button>
              )}
              <Button
                color="primary"
                disabled={formProps.isSubmitting}
                onClick={() => {
                  submitTypeRef.current = SubmitTypes.DEFAULT
                  formProps.handleSubmit()
                }}
              >
                Save
              </Button>
            </Dialog.Footer>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}

QuestionFormModal.propTypes = {
  initialValues: PropTypes.object,
  elementId: PropTypes.string,
  elements: PropTypes.array.isRequired,
  questionClassesForSelect: PropTypes.array.isRequired,
  sectionRequired: PropTypes.bool,
  submitAndNewButtonVisible: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onSubmitAndNew: PropTypes.func,
  onClose: PropTypes.func.isRequired,
}

QuestionFormModal.defaultProps = {
  initialValues: {},
  onSubmitAndNew: () => {},
}

export default QuestionFormModal
