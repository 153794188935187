import { useCallback, useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useModal, useGlobalLoader, useCurrent } from 'hooks'
import {
  useCreateAssistAssessment,
  useIndustriesFetcher,
  useOrganizationClientCustomersFetcher,
} from 'apis'
import NotificationManager from 'lib/notifications'
import objectToFormData from 'utils/object_to_formdata'
import CreateAssessmentFormModal from './CreateAssessmentFormModal'

export default function useCreateAssessment(organizationId) {
  const queryClient = useQueryClient()
  const [showLoader, hideLoader] = useGlobalLoader()
  const [openModal, closeModal, updateModal] = useModal(
    CreateAssessmentFormModal,
  )
  const { mutateAsync: createAssistAssessment } = useCreateAssistAssessment()
  const { data: industries = [] } = useIndustriesFetcher()
  const { data: clientCustomers = [] } = useOrganizationClientCustomersFetcher({
    organizationId,
  })
  const clientCustomersRef = useCurrent(clientCustomers)

  useEffect(() => {
    updateModal({
      industries,
      clientCustomers,
    })
  }, [updateModal, industries, clientCustomers])

  return useCallback(() => {
    const onSubmit = (formData) => {
      const loaderId = showLoader()

      const assessment = {
        date_received: formData.date_received,
        due_at: formData.due_at,
      }
      const existingCustomer = clientCustomersRef.current?.some(
        ({ id }) => id === formData.client_customer,
      )
      if (existingCustomer) {
        assessment.client_customer = {
          id: formData.client_customer,
        }
      } else {
        assessment.client_customer = {
          name: formData.client_customer,
          industry: formData.industry
            ? {
                id: formData.industry,
              }
            : undefined,
        }
      }
      if (formData.notes) {
        assessment.notes_attributes = [{ body: formData.notes }]
      }
      if (formData.attachment) {
        assessment.original_assessment_attributes = {
          file: formData.attachment,
        }
      } else {
        assessment.original_assessment_url = formData.existing_document_url
      }
      return createAssistAssessment({
        data: objectToFormData({ assessment }),
      })
        .then(() => closeModal())
        .catch(() => NotificationManager.error())
        .then(() => {
          hideLoader(loaderId)
          if (!existingCustomer) {
            queryClient.invalidateQueries(
              useOrganizationClientCustomersFetcher.queryKey(),
            )
          }
        })
    }
    openModal({
      clientCustomers,
      industries,
      onSubmit,
    })
  }, [
    queryClient,
    clientCustomers,
    industries,
    createAssistAssessment,
    openModal,
    closeModal,
    showLoader,
    hideLoader,
  ])
}
