// for form architecture using Formik + Yup
import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import isFunction from 'lodash/isFunction'
import Button from 'components-v2/atoms/Button'
import Drawer from 'components-v2/molecules/Drawer'

const handleKeyPress = (event) => {
  if (
    event.which === 13 /* Enter */ &&
    event.target.tagName.toLowerCase() === 'input'
  ) {
    event.preventDefault()
  }
}

const FormDrawer = ({
  title,
  submitButtonLabel,
  onClose,
  onSubmit,
  disableSubmit,
  children,
  formConfig,
  renderHeader,
  ...rest
}) => (
  <Drawer {...rest} onClose={onClose}>
    <Formik {...formConfig} onSubmit={onSubmit}>
      {(formProps) => (
        <form onSubmit={formProps.handleSubmit}>
          <div role="presentation" onKeyPress={handleKeyPress}>
            {renderHeader ? (
              isFunction(renderHeader) ? (
                renderHeader(formProps)
              ) : (
                renderHeader
              )
            ) : (
              <Drawer.Header title={title} onClose={onClose}>
                <Button
                  type="submit"
                  color="primary"
                  disabled={disableSubmit || formProps.isSubmitting}
                >
                  {submitButtonLabel}
                </Button>
              </Drawer.Header>
            )}
            <Drawer.Content>
              {isFunction(children) ? children(formProps) : children}
            </Drawer.Content>
          </div>
        </form>
      )}
    </Formik>
  </Drawer>
)

FormDrawer.propTypes = {
  title: PropTypes.string,
  submitButtonLabel: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disableSubmit: PropTypes.bool,
  children: PropTypes.any,
  formConfig: PropTypes.object,
  renderHeader: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
}

FormDrawer.defaultProps = {
  submitButtonLabel: 'Submit',
  disableSubmit: false,
  formConfig: {},
}

export default FormDrawer
