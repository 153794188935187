import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import { ThemeProvider } from 'styled-components'
import { Row, Col } from 'components-v2/organisms/Layout'
import AssessmentListingWidget from 'components/library/shared/AssessmentListingWidget'
import AddNewWidget from './AddNewWidget'
import { theme } from './styles'

const getStates = (assessmentListing) => {
  const states = []
  if (assessmentListing.visible) {
    states.push({ icon: 'fa fa-eye', label: 'Public' })
  } else {
    states.push({ icon: 'fa fa-eye-slash', label: 'Private' })
  }
  if (assessmentListing.shares_count > 0) {
    states.push({ icon: 'fa fa-users', label: 'Shared' })
  }
  return states
}

const View = ({
  assessmentListings,
  attachmentListings,
  onAddNew,
  onManageSharing,
}) => (
  <ThemeProvider theme={theme}>
    <Row $equalHeight>
      {map(assessmentListings, (assessmentListing) => (
        <Col md={3} key={assessmentListing.id}>
          <AssessmentListingWidget
            name={assessmentListing.name}
            description={assessmentListing.description}
            states={getStates(assessmentListing)}
            updatedAt={assessmentListing.updated_at}
            buttonProps={{
              children: 'Manage Sharing',
              onClick: () => onManageSharing(assessmentListing),
            }}
          />
        </Col>
      ))}
      {map(attachmentListings, (artifactListing) => (
        <Col md={3} key={artifactListing.id}>
          [ ### ATTACHMENT WIDGET GOES HERE? ### ]
        </Col>
      ))}
      <Col md={3}>
        <AddNewWidget onClick={onAddNew} />
      </Col>
    </Row>
  </ThemeProvider>
)

View.propTypes = {
  assessmentListings: PropTypes.array,
  attachmentListings: PropTypes.array,
  onAddNew: PropTypes.func.isRequired,
  onManageSharing: PropTypes.func.isRequired,
}

export default View
