import isNumber from 'lodash/isNumber'
import { colors } from 'styles'
import { RiskHealthScoreColors } from './constants'

export const formatRiskRating = (riskRating) =>
  isNumber(riskRating) ? Math.round(riskRating) : '––'

export const getRiskRatingColor = (riskRating) => {
  if (!isNumber(riskRating)) {
    return colors.GREY
  }
  if (riskRating >= 50) {
    return colors.GREEN
  }
  return colors.RED
}

export const getRiskHealthScoreColor = (score) => {
  const group = Math.max(Math.min(Math.ceil(score / 50), 6), 1)
  return RiskHealthScoreColors[group - 1]
}
