import React, { useCallback } from 'react'
import filter from 'lodash/filter'
import { useConfirm, useCurrent, useModal } from 'hooks'
import { wait } from 'utils/wait'
import {
  useMoveClientAssessmentTemplateElements,
  useCopyClientElementSection,
  useMultiCopyClientAssessmentTemplateElements,
} from 'apis'
import MoveOrCopyFormModal from './MoveOrCopyFormModal'
import useDefaultAsyncActionHandler from './useDefaultAsyncActionHandler'
import useUpdateCustomKeysAbility from './useUpdateCustomKeysAbility'
import {
  MoveOrCopyActionTypes,
  QuestionPlacementTypes,
  SectionPlacementTypes,
} from './constants'
import {
  getElementIdsWithSkipConditions,
  sortElements,
  filterElementsById,
  getKeyOfEntryPoint,
} from './utils'

// Callback when click "Bulk Move or Copy" button
export default function useMoveOrCopyElements({
  assessmentTemplate,
  isSectionSelectionMode,
  selectedQuestionIds,
  selectedSectionId,
  setIsSectionSelectionMode,
  setSelectedSectionId,
  setSelectedQuestionIds,
}) {
  const { mutateAsync: moveClientAssessmentTemplateElements } =
    useMoveClientAssessmentTemplateElements()
  const { mutateAsync: copyClientElementSection } =
    useCopyClientElementSection()
  const { mutateAsync: multiCopyClientAssessmentTemplateElements } =
    useMultiCopyClientAssessmentTemplateElements()
  const assessmentTemplateRef = useCurrent(assessmentTemplate)
  const [openMoveOrCopyFormModal, closeMoveOrCopyFormModal] =
    useModal(MoveOrCopyFormModal)
  const openConfirm = useConfirm()
  const handleAsyncAction = useDefaultAsyncActionHandler()
  const updateCustomKeysAbility = useUpdateCustomKeysAbility(
    assessmentTemplate.id,
  )

  const handleMoveOrCopySection = useCallback(
    (sectionId, data) => {
      const moveOrCopySection = () => {
        let promise = null

        if (data.action === MoveOrCopyActionTypes.MOVE) {
          const key =
            data.placement === SectionPlacementTypes.BEFORE
              ? 'insert_before'
              : 'insert_after'
          promise = moveClientAssessmentTemplateElements({
            id: assessmentTemplateRef.current.id,
            data: {
              element_ids: [sectionId],
              [key]: data.relative_section_id,
            },
          })
        } else {
          const key =
            data.placement === SectionPlacementTypes.BEFORE
              ? 'insert_before'
              : 'insert_after'
          promise = copyClientElementSection({
            id: sectionId,
            data: {
              [key]: data.relative_section_id,
            },
          })
        }

        return promise.then(() => {
          setIsSectionSelectionMode(false)
          setSelectedSectionId(null)
          setSelectedQuestionIds([])
          closeMoveOrCopyFormModal()
        })
      }

      const successMessage =
        data.action === MoveOrCopyActionTypes.MOVE
          ? 'The section has been successfully moved.'
          : 'The section has been successfully copied.'
      if (
        data.action === MoveOrCopyActionTypes.COPY &&
        assessmentTemplateRef.current.use_custom_keys
      ) {
        return openConfirm(
          {
            title: 'Copying Section',
            body: 'This action will disable custom keys. Are you sure you wish to copy this section?',
          },
          true,
        ).then((confirmed) => {
          if (!confirmed) {
            return null
          }
          const promise = updateCustomKeysAbility(false).then(() =>
            moveOrCopySection(),
          )
          return handleAsyncAction(promise, successMessage)
        })
      }
      return handleAsyncAction(moveOrCopySection(), successMessage)
    },
    [
      setIsSectionSelectionMode,
      setSelectedSectionId,
      setSelectedQuestionIds,
      openConfirm,
      handleAsyncAction,
      updateCustomKeysAbility,
      closeMoveOrCopyFormModal,
    ],
  )

  const handleMoveOrCopyQuestions = useCallback(
    async (data) => {
      if (data.action === MoveOrCopyActionTypes.MOVE) {
        // handle move case
        const {
          placement,
          relative_question_id: questionId,
          parent_id: parentId,
        } = data
        const relativeQuestionId =
          placement === QuestionPlacementTypes.END_OF_SECTION
            ? parentId
            : questionId

        const payload = { element_ids: data.element_ids }

        switch (placement) {
          case QuestionPlacementTypes.BEFORE_QUESTION:
            payload.insert_before = relativeQuestionId
            break
          case QuestionPlacementTypes.AFTER_QUESTION:
            payload.insert_after = relativeQuestionId
            break
          case QuestionPlacementTypes.TOP_OF_SECTION:
            payload.first_child_of = relativeQuestionId
            break
          default: {
            payload.parent_id = relativeQuestionId
          }
        }

        const promise = moveClientAssessmentTemplateElements({
          id: assessmentTemplateRef.current.id,
          data: payload,
        })
          .then(() => {
            setSelectedSectionId(null)
            setSelectedQuestionIds([])
            closeMoveOrCopyFormModal()
          })
          .then(() => true)
        await handleAsyncAction(
          promise,
          'The questions have been successfully moved.',
        )
      } else {
        // handle copy case

        // confirm disable custom keys
        if (assessmentTemplateRef.current.use_custom_keys) {
          const confirmed = await openConfirm(
            {
              title: 'Copying Questions',
              body: 'This action will disable custom keys. Are you sure you wish to copy questions?',
            },
            true,
          )
          if (!confirmed) {
            return
          }
          await wait(1000)
        }

        // check for and confirm need to manually update skip conditions
        const elementIdsWithSkipConditions = getElementIdsWithSkipConditions(
          assessmentTemplateRef.current.rules,
        )
        const selectedQuestionIdsWithSkipConditions = filter(
          data.element_ids,
          (r) => elementIdsWithSkipConditions.indexOf(r) !== -1,
        )
        if (selectedQuestionIdsWithSkipConditions.length > 0) {
          const sortedElements = sortElements(
            assessmentTemplateRef.current.elements,
          )
          const selectedQuestionsWithSkipConditions = filterElementsById(
            sortedElements,
            selectedQuestionIdsWithSkipConditions,
          )
          const newKeyOfFirstEntry = getKeyOfEntryPoint(
            assessmentTemplateRef.current.elements,
            data,
          )
          const title = 'Copying Questions'
          const body = (
            <div>
              <p>
                You need to recreate the Skip Condition for the following
                question(s):
              </p>
              <ul>
                {selectedQuestionsWithSkipConditions.map((el, index) => (
                  <li key={el.id}>
                    {newKeyOfFirstEntry.parentIndex + 1}.
                    {newKeyOfFirstEntry.childIndex + 1 + index} - {el.label}
                  </li>
                ))}
              </ul>
            </div>
          )

          const confirmed = await openConfirm({ title, body }, true)
          if (!confirmed) {
            return
          }
        }

        const payload = { element_ids: data.element_ids }

        switch (data.placement) {
          case QuestionPlacementTypes.BEFORE_QUESTION:
            payload.insert_before = data.relative_question_id
            break
          case QuestionPlacementTypes.AFTER_QUESTION:
            payload.insert_after = data.relative_question_id
            break
          default: {
            payload.parent_id = data.parent_id
          }
        }
        const promise = multiCopyClientAssessmentTemplateElements({
          id: assessmentTemplateRef.current.id,
          data: payload,
        }).then(() => {
          setSelectedSectionId(null)
          setSelectedQuestionIds([])
          closeMoveOrCopyFormModal()
        })
        await handleAsyncAction(
          promise,
          'The questions have been successfully copied.',
        )
      }
    },
    [
      setSelectedSectionId,
      setSelectedQuestionIds,
      openConfirm,
      handleAsyncAction,
      closeMoveOrCopyFormModal,
    ],
  )

  // Callback when click "Bulk Move or Copy" button
  return useCallback(() => {
    openMoveOrCopyFormModal({
      isSectionMode: isSectionSelectionMode,
      questionIds: selectedQuestionIds,
      sectionId: selectedSectionId,
      elements: sortElements(assessmentTemplateRef.current.elements),
      onSubmit: async (data) => {
        if (isSectionSelectionMode) {
          await handleMoveOrCopySection(selectedSectionId, data)
        } else {
          await handleMoveOrCopyQuestions(data)
        }
      },
    })
  }, [
    openMoveOrCopyFormModal,
    isSectionSelectionMode,
    selectedQuestionIds,
    selectedSectionId,
    handleMoveOrCopySection,
    handleMoveOrCopyQuestions,
  ])
}
