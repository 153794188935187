import styled from 'styled-components'
import Chart from 'react-google-charts'
import { colors, mixins } from 'styles'

export const StyledChart = styled(Chart)`
  ${mixins.avoidPageBreak}

  svg > g > rect:nth-child(1) {
    fill: url(#${(props) => props.options.chartId}hbcfx) !important;
    fill-opacity: 1 !important;
  }

  stop {
    &:nth-child(1) {
      stop-color: ${colors.GREEN};
    }

    &:nth-child(2) {
      stop-color: ${colors.GREEN};
    }

    &:nth-child(3) {
      stop-color: ${colors.ATLANTIS};
    }

    &:nth-child(4) {
      stop-color: ${colors.YELLOW};
    }

    &:nth-child(5) {
      stop-color: ${colors.ORANGE};
    }

    &:nth-child(6) {
      stop-color: ${colors.RED};
    }
  }
`
