import React from 'react'
import PropTypes from 'prop-types'
import LargeCard from '../LargeCard'
import OsanoScoreChart from '../../shared/OsanoScoreChart'
import { Inner, Label } from './styles'

const OsanoScoreCard = ({ score, rangeLabel, color, ...rest }) => (
  <LargeCard {...rest} title="Osano">
    <Inner>
      <OsanoScoreChart score={score} rangeLabel={rangeLabel} color={color} />
      <Label>Privacy score</Label>
    </Inner>
  </LargeCard>
)

OsanoScoreCard.propTypes = {
  score: PropTypes.number.isRequired,
  rangeLabel: PropTypes.string,
  color: PropTypes.string,
}

export default OsanoScoreCard
