import styled from 'styled-components'
import tileImage from 'assets/images/transparent-tile.jpg'

export const Thumbnail = styled.img`
  display: inline-block;
  margin-bottom: 8px;
  background-image: url(${tileImage});
  height: 50px;
`

export const Wrapper = styled.div``
