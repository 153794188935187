import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import SearchInput from 'components-v2/molecules/SearchInput'
import { Wrapper, Label } from './styles'
import PermittedAccessItem from './PermittedAccessItem'

const PermittedAccess = ({ shares, onAcceptRequest, onDeclineRequest }) => {
  const [searchValue, setSearchValue] = useState('')

  const listData = useMemo(
    () =>
      shares.filter((item) =>
        item.recipient_name
          .toLocaleLowerCase()
          .includes(searchValue.toLocaleLowerCase()),
      ),
    [shares, searchValue],
  )

  return (
    <Wrapper>
      <Label>Clients with Access</Label>
      <SearchInput
        value={searchValue}
        onChange={setSearchValue}
        placeholder="Search..."
      />
      <div>
        {listData.map((item) => (
          <PermittedAccessItem
            key={item.id}
            item={item}
            onAcceptRequest={onAcceptRequest}
            onDeclineRequest={onDeclineRequest}
          />
        ))}
      </div>
    </Wrapper>
  )
}

PermittedAccess.propTypes = {
  shares: PropTypes.array.isRequired,
  onAcceptRequest: PropTypes.func.isRequired,
  onDeclineRequest: PropTypes.func.isRequired,
}

export default PermittedAccess
