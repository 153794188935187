import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const ToolbarGroupInner = styled.div`
  margin: -3px ${(props) => -props.gap / 2}px;
  display: flex;
  align-items: ${(props) => props.alignItems};
  flex-wrap: wrap;
  ${(props) =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `}

  > * {
    margin: 3px ${(props) => props.gap / 2}px;

    &:empty {
      display: none;
    }
  }
`

const ToolbarGroup = ({
  gap,
  justifyContent,
  alignItems,
  children,
  ...rest
}) => (
  <div {...rest}>
    <ToolbarGroupInner
      gap={gap}
      justifyContent={justifyContent}
      alignItems={alignItems}
    >
      {React.Children.toArray(children).map((child, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index}>{child}</div>
      ))}
    </ToolbarGroupInner>
  </div>
)

ToolbarGroup.propTypes = {
  gap: PropTypes.number,
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
  children: PropTypes.node,
}

ToolbarGroup.defaultProps = {
  gap: 6,
  alignItems: 'center',
}

export default ToolbarGroup
