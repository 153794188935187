import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import Privva from 'Privva'
import Button from 'components-v2/atoms/Button'
import Icon from 'components-v2/atoms/Icon'
import ToolbarGroup from 'components-v2/molecules/ToolbarGroup'
import { getPageUrl } from 'utils/url'
import { useAuthorizations } from 'hooks'
import {
  ButtonContainer,
  CogIcon,
  Container,
  Description,
  DisconnectIcon,
  EnvelopeIcon,
  ErrorButtonsWrapper,
  ErrorContainer,
  ErrorDescription,
  ErrorTitle,
  ExternalLinkIcon,
  RatingCol,
  RatingRow,
  Rate,
  SubTitle,
  Title,
} from './styles'

const View = ({
  partnerInfo,
  detail,
  loading,
  error,
  disconnectButtonVisible,
  onDisconnect,
  onCreateIssue,
}) => {
  const [canManage, canManageClient, canCreateIssue] = useAuthorizations(
    'manage',
    ['manage', 'current_organization'],
    ['create', 'Issue'],
  )
  if (error) {
    return (
      <ErrorContainer>
        <ErrorTitle>Something&apos;s not working.</ErrorTitle>
        <ErrorDescription>
          Looks like something went wrong with our integration.
        </ErrorDescription>
        <ErrorButtonsWrapper>
          <Button color="primary" href={getPageUrl('organization')}>
            <CogIcon />
            Manage BitSight integration
          </Button>
          <Button
            color="primary"
            variant="outlined"
            href={Privva.Utils.brand.supportUrl}
            target="_blank"
          >
            <EnvelopeIcon />
            Contact the Support Team
          </Button>
        </ErrorButtonsWrapper>
      </ErrorContainer>
    )
  }

  return (
    <Container>
      {loading ? (
        <SubTitle>
          <i className="fa fa-large fa-spinner fa-spin" /> Fetching latest
          BitSight data...
        </SubTitle>
      ) : (
        <>
          <Title>This vendor is connected to {detail.name}</Title>
          <SubTitle>Updated: {get(detail, 'ratings[0].rating_date')}</SubTitle>
          <Description>Company Description: {detail.description}</Description>
          <RatingRow>
            <RatingCol>
              <Rate rounded color={get(detail, 'ratings[0].rating_color')}>
                {get(detail, 'ratings[0].rating')}
              </Rate>
              BITSIGHT
              <br />
              RATING
            </RatingCol>
            <RatingCol>
              <Rate
                color={get(detail, 'rating_details.data_breaches.grade_color')}
              >
                {get(detail, 'rating_details.data_breaches.grade')}
              </Rate>
              DATA
              <br />
              BREACHES
            </RatingCol>
            <RatingCol>
              <Rate
                color={get(
                  detail,
                  'rating_details.botnet_infections.grade_color',
                )}
              >
                {get(detail, 'rating_details.botnet_infections.grade')}
              </Rate>
              BOTNET
              <br />
              INFECTIONS
            </RatingCol>
            <RatingCol>
              <Rate
                color={get(
                  detail,
                  'rating_details.insecure_systems.grade_color',
                )}
              >
                {get(detail, 'rating_details.insecure_systems.grade')}
              </Rate>
              INSECURE
              <br />
              SYSTEMS
            </RatingCol>
          </RatingRow>
        </>
      )}
      <ButtonContainer>
        <ToolbarGroup justifyContent="center">
          {disconnectButtonVisible && canManage && (
            <Button onClick={onDisconnect}>
              <DisconnectIcon />
              Disconnect vendor
            </Button>
          )}
          {canCreateIssue && (
            <Button onClick={onCreateIssue}>
              <Icon icon="fa fa-plus" />
              New Issue
            </Button>
          )}
          {canManageClient && (
            <Button
              href={get(detail, 'display_url', partnerInfo.login_url)}
              color="primary"
              target="_blank"
            >
              <ExternalLinkIcon />
              View More Detail in BitSight
            </Button>
          )}
        </ToolbarGroup>
      </ButtonContainer>
    </Container>
  )
}

View.propTypes = {
  partnerInfo: PropTypes.object.isRequired,
  detail: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  disconnectButtonVisible: PropTypes.bool,
  onDisconnect: PropTypes.func.isRequired,
  onCreateIssue: PropTypes.func.isRequired,
}

export default View
