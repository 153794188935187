import styled, { css } from 'styled-components'
import Card from 'components-v2/organisms/Card'

export const Wrap = styled(Card)`
  ${(props) =>
    props.default &&
    css`
      display: flex;
      flex-direction: column;

      ${Top} {
        flex: none;
      }
    `}
`

export const Top = styled.div`
  margin-bottom: 30px;
`

export const Title = styled.h4`
  display: inline-block;
  margin: 0 15px 0 0;
`

export const DefaultContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  padding: 50px 20px;
  text-align: center;
`
