import { useCallback } from 'react'
import { useModal, useGlobalLoader } from 'hooks'
import NotificationManager from 'lib/notifications'
import {
  useUpdateClientOrganizationPartner,
  useCreateClientOrganizationPartner,
} from 'apis'
import { unflatten } from 'utils/object'
import EditPartnerModal from './EditPartnerModal'

export default () => {
  const [openModal, closeModal] = useModal(EditPartnerModal)
  const [showLoader, hideLoader] = useGlobalLoader()
  const { mutateAsync: updateOrganizationPartner } =
    useUpdateClientOrganizationPartner()
  const { mutateAsync: createOrganizationPartner } =
    useCreateClientOrganizationPartner()

  return useCallback(
    (partner) => {
      const onSubmit = async (data, { setErrors }) => {
        const loaderId = showLoader()
        try {
          await (partner.id
            ? updateOrganizationPartner({ id: partner.id, data })
            : createOrganizationPartner({
                data: { type: partner.type, ...data },
              }))
          closeModal()
        } catch (error) {
          console.error(error)
          if (error.response.status === 422) {
            setErrors(unflatten(error.response.data.errors))
          } else {
            NotificationManager.error()
          }
        }
        hideLoader(loaderId)
      }

      openModal({
        partner,
        onSubmit,
      })
    },
    [
      openModal,
      closeModal,
      showLoader,
      hideLoader,
      updateOrganizationPartner,
      createOrganizationPartner,
    ],
  )
}
