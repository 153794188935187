import styled from 'styled-components'
import { colors } from 'styles'

export const InputLabel = styled.span`
  color: ${colors.PRIMARY};
  cursor: pointer;
  font-weight: normal;
  margin-bottom: 0;
  text-decoration: underline;

  &:hover,
  &:active,
  &:focus {
    color: ${colors.PRIMARY_D05};
  }
`

export const Input = styled.input`
  display: none !important;
`
