import { useEffect } from 'react'
import map from 'lodash/map'
import isEqual from 'lodash/isEqual'
import { useUpdateClientAssessmentTemplate } from 'apis'
import AlterAttributeRule from 'lib/assessment/alter_attribute_rule'
import {
  AssessmentElementTypes,
  AssessmentTemplateRuleTypes,
  AssessmentTemplateRuleAttributeTypes,
} from 'constants/index'
import { getQuestionsFromElements } from './utils'
import useDefaultAsyncActionHandler from './useDefaultAsyncActionHandler'

export default function useInvalidSkipConditionRulesCleaner(
  assessmentTemplate,
) {
  const { mutateAsync: updateClientAssessmentTemplate } =
    useUpdateClientAssessmentTemplate()
  const handleAsyncAction = useDefaultAsyncActionHandler()

  // Remove invalid skip conditions rules which reference non-existing targets or choices
  useEffect(() => {
    const questions = getQuestionsFromElements(assessmentTemplate.elements)
    const validTargetIds = questions.map((e) => e.id)
    const validChoiceIds = questions.reduce((sum, question) => {
      const choiceIds =
        question.type !== AssessmentElementTypes.TEXT_QUESTION
          ? map(question.choices, 'id')
          : []
      return [...sum, ...choiceIds]
    }, [])
    const newRules = AlterAttributeRule.filterValidRules(
      assessmentTemplate.rules,
      AssessmentTemplateRuleTypes.ALTER_ATTRIBUTE,
      AssessmentTemplateRuleAttributeTypes.SKIP,
      validTargetIds,
      validChoiceIds,
    )
    if (!isEqual(newRules, assessmentTemplate.rules)) {
      const promise = updateClientAssessmentTemplate({
        id: assessmentTemplate.id,
        data: {
          assessment_template: {
            rules: newRules,
          },
        },
      })
      handleAsyncAction(
        promise,
        undefined,
        'There was a problem updating related skip condition rules',
      )
    }
  }, [
    assessmentTemplate.id,
    assessmentTemplate.elements,
    assessmentTemplate.rules,
  ])
}
