import React from 'react'
import PropTypes from 'prop-types'
import toPairs from 'lodash/toPairs'
import sortBy from 'lodash/sortBy'
import { PieChart } from 'components-v2/molecules/Charts'
import { getIssuePriorityLabel, getIssueStatusLabel } from 'lib/issue'
import colors from './colors'
import { Legend, LegendItem } from './styles'

const metricTypes = {
  PRIORITY: 'priority',
  STATUS: 'status',
}

const mapLabel = (label, metric) => {
  if (metric === metricTypes.PRIORITY) {
    return getIssuePriorityLabel(parseInt(label, 10))
  }
  if (metric === metricTypes.STATUS) {
    return getIssueStatusLabel(label, false)
  }
  return label === '' ? 'None' : label.titleize()
}

const getChartData = (raw, metric) =>
  sortBy(toPairs(raw), 0).map(([k, value]) => {
    const label = mapLabel(k, metric)
    return {
      name: label,
      value,
      color: colors[label],
    }
  })

const IssueSummaryPieChart = ({ metric, data, className, ...rest }) => {
  const payload = getChartData(data, metric)
  return (
    <div className={className}>
      <PieChart
        {...rest}
        data={payload}
        width="100%"
        height={250}
        innerRadius={38}
        outerRadius={77}
        startAngle={90}
        endAngle={450}
        label
        labelOnHover
      />
      <Legend>
        {payload.map((entry, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <LegendItem key={index} color={entry.color}>
            {entry.name} ({entry.value})
          </LegendItem>
        ))}
      </Legend>
    </div>
  )
}

IssueSummaryPieChart.propTypes = {
  metric: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
}

const MemoizedPieChart = React.memo(IssueSummaryPieChart)
MemoizedPieChart.metricTypes = metricTypes

export default MemoizedPieChart
