import React from 'react'
import PropTypes from 'prop-types'
import AutoLink from 'components-v2/atoms/AutoLink'
import SimpleDialog from 'components-v2/molecules/SimpleDialog'
import AssessmentElementAnswerForm from 'components/shared/AssessmentElementAnswerForm'

const AutoScoreSettingsModal = ({
  elements,
  elementId,
  autoScoreOptions,
  onAutoScoreChange,
  onClose,
  ...rest
}) => {
  const element = elements.find((e) => e.id === elementId)
  if (!element) {
    return null
  }
  const buttons = [{ onClick: onClose, children: 'Close' }]
  return (
    <SimpleDialog
      title="Autoscore Settings"
      buttons={buttons}
      onClose={onClose}
      {...rest}
    >
      <AutoLink text={element.label} />
      <AssessmentElementAnswerForm
        element={element}
        value={element.response?.answer}
        disabled
        showAutoScores
        autoScoreOptions={autoScoreOptions}
        onAutoScoreChange={onAutoScoreChange}
      />
    </SimpleDialog>
  )
}

AutoScoreSettingsModal.propTypes = {
  elements: PropTypes.array.isRequired,
  elementId: PropTypes.string.isRequired,
  autoScoreOptions: PropTypes.array.isRequired,
  onAutoScoreChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default AutoScoreSettingsModal
