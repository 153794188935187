import React from 'react'
import PropTypes from 'prop-types'
import Dialog from 'components-v2/molecules/Dialog'
import styled from 'styled-components'
import Button from 'components-v2/atoms/Button'

const TitleBlock = styled.div`
  margin-left: 50px;
  margin-right: 50px;
`
const TextBlock = styled.div`
  margin-left: 50px;
  margin-right: 50px;

  p {
    text-indent: 25px;
  }
`

const LargeTextDisplay = ({ title, text, className, onClose, ...rest }) => (
  <Dialog dialogClassName={className} onClose={onClose} {...rest}>
    <Dialog.Header onClose={onClose}>
      <TitleBlock>{title}</TitleBlock>
    </Dialog.Header>
    <Dialog.Content>
      <TextBlock>{text}</TextBlock>
    </Dialog.Content>
    <Dialog.Footer>
      <Button onClick={onClose}>Close</Button>
    </Dialog.Footer>
  </Dialog>
)

const LargeTextDisplayModal = styled(LargeTextDisplay)`
  width: 685px;
`

LargeTextDisplay.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  className: PropTypes.string,
  onClose: PropTypes.func,
}

export default LargeTextDisplayModal
