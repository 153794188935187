import React from 'react'
import PropTypes from 'prop-types'
import Loader from 'components-v2/molecules/Loader'
import { Row, Col } from 'components-v2/organisms/Layout'
import PieChart from 'components/shared/IssueSummaryPieChart'

const IssueSummary = ({ data, loading }) => {
  if (loading) {
    return <Loader />
  }

  return (
    <Row>
      <Col md={6}>
        <h5>Status</h5>
        <PieChart data={data.status} metric={PieChart.metricTypes.STATUS} />
      </Col>
      <Col md={6}>
        <h5>Priority</h5>
        <PieChart data={data.priority} metric={PieChart.metricTypes.PRIORITY} />
      </Col>
    </Row>
  )
}

IssueSummary.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
}

export default IssueSummary
