import styled, { css } from 'styled-components'
import { lighten, readableColor, getLuminance } from 'polished'
import { Paperclip } from '@styled-icons/fa-solid/Paperclip'
import Icon from 'components-v2/atoms/Icon'
import { mixins, colors } from 'styles'

const primaryLuminance = getLuminance(colors.PRIMARY)
const highlightedBgColor = lighten(
  primaryLuminance < 0.2 ? 0.3 : 0.12,
  colors.PRIMARY,
)
const highlightedBorderColor = lighten(
  primaryLuminance < 0.2 ? 0.1 : 0.02,
  colors.PRIMARY,
)
const highlightedTextColor = readableColor(highlightedBgColor)

export const Wrapper = styled.div``

export const Comment = styled.div`
  margin-bottom: 15px;
`

export const CommentHeader = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 5px;
`

export const CommentedBy = styled.div`
  ${mixins.smallHeader}
`

export const CommentedAt = styled.div`
  font-size: 14px;
  color: ${colors.MED_GREY};
`

export const CommentBodyArrow = styled.div`
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 11px;
  border-top-width: 0;
  border-bottom-color: #ccc;
  top: -11px;
  left: 20px;
  margin-left: -11px;

  &:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 10px;
    top: 1px;
    margin-left: -10px;
    content: ' ';
    border-top-width: 0;
    border-bottom-color: ${colors.LIGHT_GREY};
  }
`

export const CommentBody = styled.div`
  position: relative;
  white-space: pre-wrap;
  min-height: 2.4em;
  padding: 7px 12px 7px 12px;
  margin-top: 12px;
  background-color: ${colors.LIGHT_GREY};
  border: 1px solid #ccc;
  border-radius: 2px;

  ${({ $highlight }) =>
    $highlight &&
    css`
      background-color: ${highlightedBgColor};
      border-color: ${highlightedBorderColor};
      color: ${highlightedTextColor};

      ${CommentBodyArrow} {
        border-bottom-color: ${highlightedBorderColor};
        &:after {
          border-bottom-color: ${highlightedBgColor};
        }
      }
    `}
`

export const CommentAttachments = styled.div`
  margin-top: 3px;
`

export const CommentAttachment = styled.a`
  display: inline-block;
  margin: 3px 6px 3px 0;
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #333;

  &:hover,
  &:active,
  &:focus {
    color: #333;
    background-color: #e6e6e6;
    text-decoration: none;
  }
`

export const AttachmentIcon = styled(Icon).attrs({ icon: Paperclip })`
  margin-right: 5px;
`
