import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import Tooltip from 'components-v2/molecules/Tooltip'
import { toLocalTimeString } from 'utils/date'
import {
  Wrapper,
  Top,
  Category,
  Name,
  UpdatedAt,
  Description,
  StyledButton as Button,
  StyledIcon as Icon,
} from './styles'

const AssessmentListingWidget = ({
  name,
  description,
  states,
  updatedAt,
  buttonProps,
}) => (
  <Wrapper bordered>
    <Top>
      <Category>Assessment</Category>
      {map(states, (state, index) => (
        <Tooltip key={index} parent={<Icon icon={state.icon} />}>
          {state.label}
        </Tooltip>
      ))}
    </Top>
    <Name>{name}</Name>
    <UpdatedAt>
      Last updated {toLocalTimeString(updatedAt, 'MMM d, yy')}
    </UpdatedAt>
    <Description>{description}</Description>
    <Button {...buttonProps} fullWidth />
  </Wrapper>
)

AssessmentListingWidget.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  states: PropTypes.array,
  updatedAt: PropTypes.string.isRequired,
  buttonProps: PropTypes.object,
}

export default AssessmentListingWidget
