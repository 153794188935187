import React from 'react'
import PropTypes from 'prop-types'
import isObject from 'lodash/isObject'
import styled from 'styled-components'

const StyledSvg = styled.svg`
  width: 1em;
  height: 1em;
  font-size: inherit;
  text-align: center;
  ${(props) => props.onClick && 'cursor: pointer;'}
`

const Icon = ({ icon, className = '', ...rest }) => {
  if (isObject(icon)) {
    return <StyledSvg as={icon} className={className} {...rest} />
  }
  return (
    <StyledSvg
      as="i"
      aria-hidden="true"
      {...rest}
      className={`${className} ${icon}`}
    />
  )
}

Icon.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  className: PropTypes.string,
}

export default React.memo(Icon)
