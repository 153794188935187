import styled from 'styled-components'
import Metric from 'components-v2/molecules/Metric'
import { colors } from 'styles'

export const Wrapper = styled.div`
  display: flex;
`

export const StyledMetric = styled(Metric)`
  flex: none;
  width: 150px;
  color: ${colors.GREEN};
`
