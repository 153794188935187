import React, { useCallback } from 'react'
import {
  useClientIssueCategoriesFetcher,
  useCreateClientIssueCategory,
  useUpdateClientIssueCategory,
  useDeleteClientIssueCategory,
} from 'apis'
import EditableItemListTable from '../EditableItemListTable'

export default function IssueCategoryTable() {
  const { data, isLoading } = useClientIssueCategoriesFetcher()
  const { mutateAsync: createIssueCategory } = useCreateClientIssueCategory()
  const { mutateAsync: updateIssueCategory } = useUpdateClientIssueCategory()
  const { mutateAsync: deleteIssueCategory } = useDeleteClientIssueCategory()
  const handleCreate = useCallback(
    (name) => createIssueCategory({ data: { name } }),
    [createIssueCategory],
  )
  const handleUpdate = useCallback(
    (id, name) =>
      updateIssueCategory({ id, data: { issue_category: { id, name } } }),
    [updateIssueCategory],
  )
  const handleDelete = useCallback(
    (id) => deleteIssueCategory({ id }),
    [deleteIssueCategory],
  )
  return (
    <EditableItemListTable
      data={data}
      isLoading={isLoading}
      updateConfirmText="Changing this category will update it across issues."
      deleteConfirmText="Deleting this category will remove it across issues."
      onCreate={handleCreate}
      onUpdate={handleUpdate}
      onDelete={handleDelete}
    />
  )
}
