import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'components-v2/atoms/Icon'
import IconButton from 'components-v2/atoms/IconButton'
import Table from 'components-v2/molecules/Table'
import MenuDropdown from 'components-v2/molecules/MenuDropdown'
import Toolbar, { SearchInput } from 'components-v2/molecules/Table/Toolbar'
import { useAuthorizations } from 'hooks'
import GloborgOrgRiskProfile from './GloborgOrgRiskProfile'
import {
  Body,
  Sidebar,
  Watchlists,
  AllCompanies,
  Watchlist,
  WatchlistSize,
  Content,
  WatchlistSelectMobile,
  TitleBar,
  Title,
} from './styles'

const WatchlistsView = ({
  orgId,
  watchlists,
  globalOrgs,
  watchlistId,
  globalOrg,
  totalSize,
  tableState,
  searchText,
  loading,
  tableColumns,
  onPaginationChange,
  onSortingChange,
  onSearchChange,
  onWatchlistChange,
  onCreateWatchlistClick,
  onEditWatchlistClick,
  onDeleteWatchlistClick,
  onBackToWatchlists,
}) => {
  const watchListEls = React.useRef([])
  const [canManage] = useAuthorizations('manage', 'Watchlist')
  const title = !watchlistId
    ? 'All Companies'
    : watchlists.find((e) => e.id === watchlistId)?.name

  const handleKeyDown = React.useCallback(
    (e) => {
      const currentWatchlistIndex = watchlists.findIndex(
        (watchlist) => watchlist.id === watchlistId,
      )
      switch (e.keyCode) {
        case 38:
          e.preventDefault()
          if (currentWatchlistIndex > 0) {
            onWatchlistChange(watchlists?.[currentWatchlistIndex - 1]?.id)
            watchListEls.current[currentWatchlistIndex].focus()
          } else if (currentWatchlistIndex === 0) {
            onWatchlistChange(undefined)
            watchListEls.current[0].focus()
          } else {
            onWatchlistChange(watchlists?.[watchlists.length - 1]?.id)
            watchListEls.current[watchlists.length].focus()
          }
          break
        case 40:
          if (currentWatchlistIndex < watchlists.length - 1) {
            onWatchlistChange(watchlists?.[currentWatchlistIndex + 1]?.id)
            watchListEls.current[currentWatchlistIndex + 2].focus()
          } else {
            onWatchlistChange(undefined)
            watchListEls.current[0].focus()
          }
          e.preventDefault()
          break
        default:
      }
    },
    [watchlists, watchlistId, onWatchlistChange],
  )

  if (globalOrg) {
    return (
      <GloborgOrgRiskProfile
        clientId={orgId}
        org={globalOrg}
        backButtonLabel={title}
        onBack={onBackToWatchlists}
      />
    )
  }
  return (
    <Body>
      <Sidebar>
        <Watchlists>
          <AllCompanies
            $active={!watchlistId}
            onClick={() => onWatchlistChange()}
            tabIndex={!watchlistId ? 0 : -1}
            onKeyDown={handleKeyDown}
            ref={(element) => watchListEls.current.push(element)}
          >
            All Companies
          </AllCompanies>
          {watchlists.map(
            ({ id, name, globorg_orgs_count: globorgOrgsCount }) => (
              <Watchlist
                key={id}
                $active={id === watchlistId}
                onClick={() => onWatchlistChange(id)}
                tabIndex={id === watchlistId ? 0 : -1}
                onKeyDown={handleKeyDown}
                ref={(element) => watchListEls.current.push(element)}
              >
                {name}
                <WatchlistSize>{globorgOrgsCount}</WatchlistSize>
              </Watchlist>
            ),
          )}
        </Watchlists>
      </Sidebar>
      <Content>
        <WatchlistSelectMobile
          value=""
          onChange={(e) =>
            onWatchlistChange(
              e.target.value === '' ? undefined : e.target.value,
            )
          }
        >
          <option key="default" value="" disabled>
            Select a watchlist...
          </option>
          <option key="all" value="">
            All Companies
          </option>
          {watchlists.map(
            ({ id, name, globorg_orgs_count: globorgOrgsCount }) => (
              <option key={id} value={id}>
                {name} ({globorgOrgsCount})
              </option>
            ),
          )}
        </WatchlistSelectMobile>
        <TitleBar>
          <Title>{title}</Title>
          {canManage && watchlistId && (
            <>
              <IconButton icon="fa fa-pencil" onClick={onEditWatchlistClick} />
              <IconButton icon="fa fa-trash" onClick={onDeleteWatchlistClick} />
            </>
          )}
        </TitleBar>
        <Toolbar>
          <SearchInput value={searchText} onChange={onSearchChange} />
          {canManage && (
            <MenuDropdown title="Actions">
              <MenuDropdown.Item onClick={onCreateWatchlistClick}>
                <Icon icon="fa fa-plus" />
                Create a Watchlist
              </MenuDropdown.Item>
            </MenuDropdown>
          )}
        </Toolbar>
        <Table
          loading={loading}
          data={globalOrgs || []}
          columns={tableColumns}
          totalSize={totalSize}
          pagination={{
            page: tableState.page,
            sizePerPage: tableState.sizePerPage,
          }}
          sorting={{
            sortField: tableState.sortField,
            sortOrder: tableState.sortOrder,
          }}
          onPaginationChange={onPaginationChange}
          onSortingChange={onSortingChange}
          manualPagination
          manualSorting
          type={Table.types.ALT}
        />
      </Content>
    </Body>
  )
}

WatchlistsView.propTypes = {
  orgId: PropTypes.string.isRequired,
  watchlists: PropTypes.array.isRequired,
  globalOrgs: PropTypes.array.isRequired,
  watchlistId: PropTypes.string,
  globalOrg: PropTypes.object,
  totalSize: PropTypes.number,
  tableState: PropTypes.object.isRequired,
  searchText: PropTypes.string,
  loading: PropTypes.bool,
  tableColumns: PropTypes.array.isRequired,
  onPaginationChange: PropTypes.func.isRequired,
  onSortingChange: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onWatchlistChange: PropTypes.func.isRequired,
  onCreateWatchlistClick: PropTypes.func.isRequired,
  onEditWatchlistClick: PropTypes.func.isRequired,
  onDeleteWatchlistClick: PropTypes.func.isRequired,
  onBackToWatchlists: PropTypes.func.isRequired,
}

export default WatchlistsView
