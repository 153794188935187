import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import config from 'config'
import { useAssistAssessmentsFetcher } from 'apis'
import { withReactQuery } from 'hocs'
import useCreateAssessment from './useCreateAssessment'
import useUpdateAssessmentNote from './useUpdateAssessmentNote'
import useShowAnswerFiles from './useShowAnswerFiles'
import View from './View'

const AssessmentListViewContainer = ({
  mode,
  assistOrganization,
  assistOrganizationPath,
}) => {
  const [page, setPage] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(
    config.collectionQueryPageLimit.default,
  )
  const [sortField, setSortField] = useState()
  const [sortOrder, setSortOrder] = useState()
  const [searchText, setSearchText] = useState('')
  const {
    data: assessments,
    totalSize,
    isFetching,
  } = useAssistAssessmentsFetcher({
    params: {
      page,
      per_page: sizePerPage,
      search_query: searchText,
      ordered_by: sortField,
      direction: sortOrder,
    },
  })
  const createAssessment = useCreateAssessment(assistOrganization.id)
  const updateAssessmentNote = useUpdateAssessmentNote(assessments)
  const showAnswerFiles = useShowAnswerFiles()

  const handlePaginationChange = useCallback((newState) => {
    setPage(newState.page)
    setSizePerPage(newState.sizePerPage)
  }, [])

  const handleSortingChange = useCallback((newState) => {
    setSortField(newState.sortField)
    setSortOrder(newState.sortOrder)
  }, [])

  const handleSearchChange = useCallback((value) => {
    // Let's reset page to 1
    setPage(1)
    setSearchText(value)
  }, [])

  return (
    <View
      mode={mode}
      assistOrganizationPath={assistOrganizationPath}
      assistOrganizationName={assistOrganization.name}
      assessments={assessments}
      totalSize={totalSize}
      loading={isFetching}
      page={page}
      sizePerPage={sizePerPage}
      sortField={sortField}
      sortOrder={sortOrder}
      searchText={searchText}
      onPaginationChange={handlePaginationChange}
      onSortingChange={handleSortingChange}
      onSearchChange={handleSearchChange}
      onAnswerFilesBtnClick={showAnswerFiles}
      onNewClientAssessmentBtnClick={createAssessment}
      onAddOrEditNote={updateAssessmentNote}
    />
  )
}

AssessmentListViewContainer.propTypes = {
  mode: PropTypes.string,
  assistOrganization: PropTypes.object.isRequired,
  assistOrganizationPath: PropTypes.string,
}

export default withReactQuery()(AssessmentListViewContainer)
