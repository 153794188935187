import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import IconButton from 'components-v2/atoms/IconButton'
import Tooltip from 'components-v2/molecules/Tooltip'

const Wrapper = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  &:empty {
    display: none;
  }
`

const Label = styled.label`
  margin: 0;
  font-weight: inherit;
  display: inline;

  &:not(:last-child) {
    margin-right: 7px;
  }

  .has-error & {
    color: #a94442;
  }
`

const QuestionIconButton = styled(IconButton).attrs({
  icon: 'fa fa-question-circle',
})`
  font-size: 12px;
`

const ControlLabel = ({ htmlFor, helpMessage, children, ...rest }) => (
  <Wrapper {...rest}>
    {children && <Label htmlFor={htmlFor}>{children}</Label>}
    {helpMessage && (
      <Tooltip position="top" parent={QuestionIconButton}>
        {helpMessage}
      </Tooltip>
    )}
  </Wrapper>
)

ControlLabel.propTypes = {
  htmlFor: PropTypes.string,
  helpMessage: PropTypes.node,
  children: PropTypes.node,
}

ControlLabel.Wrapper = Wrapper

export default ControlLabel
