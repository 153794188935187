import styled from 'styled-components'
import FormField, { ControlLabel } from 'components-v2/molecules/FormField'
import { colors, fonts, mixins } from 'styles'

export const Heading = styled.div`
  ${mixins.smallHeader};
  color: ${colors.PRIMARY};
  margin-bottom: 30px;
`

export const Item = styled.div`
  position: relative;
`

export const Index = styled.div`
  position: absolute;
  top: 2px;
  left: -23px;
  color: ${colors.PRIMARY};
  font-family: ${fonts.SECONDARY};
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.55px;
`

export const RadioGroupFormField = styled(FormField).attrs({
  type: FormField.types.RADIO_GROUP,
  renderControlLabel: styled(ControlLabel)`
    font-weight: normal;
  `,
})`
  margin-bottom: 25px;
`
