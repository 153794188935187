import styled, { css } from 'styled-components'
import { Comment } from '@styled-icons/fa-solid/Comment'
import Icon from 'components-v2/atoms/Icon'
import Clickable from 'components-v2/atoms/Clickable'
import { colors, mixins } from 'styles'

export const ToggleButton = styled(Clickable).withConfig({
  shouldForwardProp: (prop) => !['isEmpty', 'multiLine'].includes(prop),
})`
  color: ${colors.BLUE};
  border-bottom: 1px dashed transparent;

  ${(props) =>
    props.isEmpty &&
    css`
      font-style: italic;
      color: ${colors.DANGER};
    `}
  ${(props) => props.multiLine && `white-space: pre-wrap;`}

  &:hover,
  &:active {
    border-bottom-color: currentColor;
    background: lightyellow;
  }

  &:disabled {
    font-style: normal;
    color: gray;
  }
`

export const Wrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isEmpty'].includes(prop),
})`
  display: flex;
  ${(props) => props.isEmpty && mixins.hidePrint}
`

export const CommentIcon = styled(Icon).attrs({ icon: Comment })`
  flex: none;
  margin-top: 3px;
`

export const PopoverPanelContent = styled.div`
  padding: 9px 14px;
`
