import { useCallback } from 'react'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'
import pickBy from 'lodash/pickBy'
import keyBy from 'lodash/keyBy'
import isArray from 'lodash/isArray'
import NotificationManager from 'lib/notifications'
import { useModal, useGlobalLoader } from 'hooks'
import objectToFormData from 'utils/object_to_formdata'
import rawRiskTierCalculatorConfig from 'components/elements/risk_tier_calculator/questions.json'
import { analyzeRiskTierAnswers } from 'components/elements/risk_tier_calculator/util'
import { LabelTypes } from 'constants/index'
import * as apis from 'apis'
import {
  useProposeClientVendor,
  usePostCommentToClientCommentThread,
} from 'apis'
import RequestVendorModal from './RequestVendorModal'
import { FieldNameTypes } from './RequestVendorModal/constants'
import {
  VendorOwnFieldNames,
  VendorFieldNameTypes,
} from '../vendor_info_view/constants'
import { parseFormValue } from '../vendor_info_view/utils'

const vendorLabel = LabelTypes.VENDOR.toLowerCase()
const defaultRiskTierCalculatorConfig =
  vendorLabel !== 'vendor'
    ? {
        ...rawRiskTierCalculatorConfig,
        questions: rawRiskTierCalculatorConfig.questions.map((e) => ({
          ...e,
          question: e.question.replace(/vendor/gi, vendorLabel),
        })),
      }
    : rawRiskTierCalculatorConfig

const getRiskTierCalculatorConfig = async (checkForCustom = false) => {
  // custom calculators disabled for the moment, so just
  // skipping the API check and returning the default
  if (!checkForCustom) {
    return defaultRiskTierCalculatorConfig
  }

  // Fetch custom riskTierCalculator config
  const getClientRiskTierCalculatorRes = await apis.getClientRiskTierCalculator(
    'current',
  )
  return getClientRiskTierCalculatorRes.data
    ? getClientRiskTierCalculatorRes.data
    : defaultRiskTierCalculatorConfig
}
const getCustomFieldDefinitions = apis.getClientCustomFieldDefinitions

export default function useRequestVendor() {
  const { mutateAsync: proposeClientVendor } = useProposeClientVendor()
  const { mutateAsync: postCommentToClientCommentThread } =
    usePostCommentToClientCommentThread()
  const [openRequestVendorModal, closeRequestVendorModal] =
    useModal(RequestVendorModal)
  const [showLoader, hideLoader] = useGlobalLoader()

  return useCallback(
    async ({ vendorRequestConfig, ...rest }) => {
      let riskTierCalculatorConfig
      let customFieldDefinitions

      const calcEnabled = vendorRequestConfig.riskCalculatorEnabled
      const addFieldGroups =
        Object.keys(vendorRequestConfig.fieldGroupsConfig).length > 0

      // handle any API calls if needed
      if (calcEnabled || addFieldGroups) {
        const loaderId = showLoader()
        try {
          riskTierCalculatorConfig = await getRiskTierCalculatorConfig()
          customFieldDefinitions = (await getCustomFieldDefinitions()).data
          hideLoader(loaderId)
        } catch (error) {
          hideLoader(loaderId)
          NotificationManager.error()
          return
        }
      }

      const handleSubmit = (formData) => {
        const loaderId = showLoader()

        const parsedFormData = mapValues(formData, (value, name) =>
          parseFormValue(name, value),
        )
        // Calculate risk tier based on the provided answers
        if (parsedFormData[VendorFieldNameTypes.VENDOR_RISK_TIER_ANSWERS]) {
          const [riskTierAnswers, riskTierScore] = analyzeRiskTierAnswers(
            parsedFormData[VendorFieldNameTypes.VENDOR_RISK_TIER_ANSWERS],
            riskTierCalculatorConfig,
          )
          parsedFormData[VendorFieldNameTypes.RISK_TIER] = riskTierScore
          parsedFormData[VendorFieldNameTypes.VENDOR_RISK_TIER_ANSWERS] =
            riskTierAnswers
          parsedFormData[VendorFieldNameTypes.RISK_TIER_CALCULATOR_ID] =
            riskTierCalculatorConfig.id
        }

        const vendorData = pickBy(parsedFormData, (_, key) =>
          VendorOwnFieldNames.includes(key),
        )
        const customFieldDefs = keyBy(
          customFieldDefinitions.field_definitions,
          'key',
        )
        const customFieldKeys = customFieldDefinitions.field_definitions.map(
          (e) => e.key,
        )
        vendorData.organization_detail_attributes = pickBy(
          parsedFormData,
          (_, key) =>
            !VendorOwnFieldNames.includes(key) &&
            !customFieldKeys.includes(key) &&
            key !== FieldNameTypes.REQUEST_COMMENT &&
            key !== FieldNameTypes.REQUEST_FILES,
        )
        vendorData.custom_field_data_attributes = mapValues(
          pickBy(parsedFormData, (_, key) => customFieldKeys.includes(key)),
          (val, key) => {
            if (customFieldDefs[key].type_opts.array && !isArray(val)) {
              return val ? [val] : []
            }
            return val
          },
        )

        return proposeClientVendor({ data: vendorData })
          .then((response) => {
            if (!parsedFormData[FieldNameTypes.REQUEST_COMMENT]) {
              return Promise.resolve()
            }
            const data = objectToFormData({
              comment_thread: {
                commentable_id: response.id,
                commentable_type: 'VendorRequest',
                comments_attributes: [
                  {
                    body: parsedFormData[FieldNameTypes.REQUEST_COMMENT],
                    attachments_attributes: map(
                      parsedFormData[FieldNameTypes.REQUEST_FILES],
                      (file) => ({ file }),
                    ),
                  },
                ],
              },
            })
            return postCommentToClientCommentThread({ data })
          })
          .then(() => {
            hideLoader(loaderId)
            NotificationManager.success(
              'Your request has successfully been submitted',
            )
            closeRequestVendorModal()
          })
          .catch(() => {
            hideLoader(loaderId)
            NotificationManager.error()
          })
      }

      openRequestVendorModal({
        ...rest,
        vendorRequestConfig,
        riskTierQuestions: calcEnabled
          ? riskTierCalculatorConfig.questions
          : undefined,
        customFieldDefinitions,
        onSubmit: handleSubmit,
      })
    },
    [openRequestVendorModal, closeRequestVendorModal, showLoader, hideLoader],
  )
}
