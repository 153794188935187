import React, { useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import styled from 'styled-components'
import Input from 'components-v2/atoms/Input'
import FormField from 'components-v2/molecules/FormField'
import { toDate, toLocalTimeString } from 'utils/date'
import {
  AssessmentElementSubtypes,
  AssessmentElementSubtypeOptions,
} from 'constants/index'

const Wrapper = styled.div`
  max-width: 300px;
`

const validationSchema = yup.object().shape({
  input: yup.date().nullable().typeError('Must be a valid date'),
})

const dateControlProps = {
  dateFormat: 'yyyy/MM/dd',
  fullWidth: true,
  todayButton: 'Today',
}
const dateTimeControlProps = {
  dateFormat: 'yyyy/MM/dd h:mm aa (OOOO)',
  fullWidth: true,
  showTimeSelect: true,
  showTimezoneSelect: true,
  todayButton: 'Today',
}

const Date = ({ type, value, disabled, placeholder, onChange }) => {
  const formikRef = useRef()
  const inputValue = value && value[0]
  const placeholderEx =
    placeholder ??
    AssessmentElementSubtypeOptions.find((e) => e.value === type).label
  const controlProps =
    type === AssessmentElementSubtypes.DATETIME
      ? dateTimeControlProps
      : dateControlProps

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue('input', toDate(inputValue))
    }
  }, [inputValue])

  const handleSubmit = useCallback(
    ({ input }) => {
      onChange([input])
    },
    [onChange],
  )

  if (disabled) {
    const formattedValue = toLocalTimeString(
      inputValue,
      controlProps.dateFormat,
      inputValue ?? '',
    )
    return (
      <Wrapper>
        <Input placeholder={placeholderEx} readOnly value={formattedValue} />
      </Wrapper>
    )
  }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{ input: toDate(inputValue) }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ submitForm }) => (
        <Form>
          <Wrapper>
            <FormField
              name="input"
              type={FormField.types.DATE}
              placeholder={placeholderEx}
              controlProps={controlProps}
              onChange={submitForm}
              preserveHelpTextSpace={false}
            />
          </Wrapper>
        </Form>
      )}
    </Formik>
  )
}

Date.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.array,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}

export default Date
