import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import filter from 'lodash/filter'
import Dialog from 'components-v2/molecules/Dialog'
import Progress from '../Progress'
import { SectionRow, Label, ProgressWrapper } from './styles'

const AssessmentStatusModal = ({
  assessmentName,
  progressDetails,
  onClose,
  ...rest
}) => (
  <Dialog {...rest} onClose={onClose} className="dyn-lang">
    <Dialog.Header onClose={onClose}>{assessmentName}</Dialog.Header>
    <Dialog.Content>
      {map(
        filter(progressDetails, (v) => v.total > 0),
        (item, index) => (
          <SectionRow key={index}>
            <Label>
              {item.label}{' '}
              <small>
                ({item.answered} of {item.total} complete)
              </small>
            </Label>
            <ProgressWrapper>
              <Progress completed={item.answered} total={item.total} hideNote />
            </ProgressWrapper>
          </SectionRow>
        ),
      )}
    </Dialog.Content>
  </Dialog>
)

AssessmentStatusModal.propTypes = {
  assessmentName: PropTypes.string.isRequired,
  progressDetails: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default AssessmentStatusModal
