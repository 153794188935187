import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const TextWrapper = styled.span`
  font-size: 11px;
  text-transform: uppercase;
`

const NavText = ({ text }) => <TextWrapper>{text}</TextWrapper>

NavText.propTypes = {
  text: PropTypes.string.isRequired,
}

export default NavText
