import { useCallback } from 'react'
import { useConfirm, useCurrent } from 'hooks'
import { useDeleteClientElement } from 'apis'
import useDefaultAsyncActionHandler from './useDefaultAsyncActionHandler'
import { getElementById, isSection } from './utils'

// Callback when click "Delete" button of an element
export default function useDeleteElement(assessmentTemplate) {
  const assessmentTemplateRef = useCurrent(assessmentTemplate)
  const openConfirm = useConfirm()
  const { mutateAsync: deleteClientElement } = useDeleteClientElement()
  const handleAsyncAction = useDefaultAsyncActionHandler()

  return useCallback(
    (elementId) => {
      const element = getElementById(
        assessmentTemplateRef.current.elements,
        elementId,
      )
      if (isSection(element)) {
        openConfirm({
          title: 'Deleting Section',
          body: 'Are you sure you wish to delete all questions in this section?',
          onConfirm: () => {
            const promise = deleteClientElement({ id: elementId })
            return handleAsyncAction(
              promise,
              'A section has been successfully deleted.',
            )
          },
        })
      } else {
        openConfirm({
          title: 'Deleting Question',
          body: 'Are you sure you wish to remove this question?',
          onConfirm: () => {
            const promise = deleteClientElement({ id: elementId })
            return handleAsyncAction(
              promise,
              'A question has been successfully deleted.',
            )
          },
        })
      }
    },
    [openConfirm, handleAsyncAction, deleteClientElement],
  )
}
