import styled, { css } from 'styled-components'
import get from 'lodash/get'
import A from 'components-v2/atoms/A'
import Card from 'components-v2/organisms/Card'
import { colors, mixins } from 'styles'
import { SecurityObjectiveStatusOptions } from '../../constants'

export const Heading = styled.h4`
  margin: 0;
`

export const Top = styled(Card.Segment)`
  padding-bottom: 0;
`

export const Bottom = Card.Segment

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const UpdatedAt = styled.span`
  font-size: 14px;
  color: ${colors.GREY};
`

export const SourceListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`

export const SourceColHeader = styled.div`
  flex: none;
  width: 120px;
  color: ${colors.GREY};
  ${mixins.smallHeader}
  letter-spacing: 0;
`

export const SourceItem = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  font-size: 15px;
  border-bottom: 1px solid ${colors.LIGHT_GREY};

  > div:nth-child(2) {
    width: 95px;
    flex: none;
    margin-left: 10px;
  }

  > div:nth-child(3) {
    width: 15px;
    flex: none;
    margin-left: 10px;
  }

  ${(props) =>
    props.disabled &&
    css`
      color: ${colors.GREY};
    `}

  &:last-child {
    border-bottom: none;
  }
`

export const ObjectiveLabel = styled.a`
  ${mixins.truncate}
  margin-right: auto;
  color: inherit;
`

export const ConnectLink = styled(A).attrs({ underline: true })`
  font-weight: 500;
`

export const Logo = styled.img`
  width: 100%;
  height: auto;
`

export const ObjectiveStatus = styled.span`
  font-weight: 500;
  color: ${(props) =>
    get(
      SecurityObjectiveStatusOptions,
      [props.status, 'color'],
      colors.DARK_GREY,
    )};
`
