import { useCallback } from 'react'
import { useModal, useGlobalLoader } from 'hooks'
import { useUpdateAssistAssessment } from 'apis'
import NotificationManager from 'lib/notifications'
import objectToFormData from 'utils/object_to_formdata'
import CompletedUploadModal from './CompletedUploadModal'

export default function useUploadCompletedAssessment(id, onActionSuccess) {
  const [showLoader, hideLoader] = useGlobalLoader()
  const [openModal, closeModal] = useModal(CompletedUploadModal)
  const { mutateAsync: updateAssistAssessment } = useUpdateAssistAssessment()

  return useCallback(() => {
    const onSubmit = (formData) => {
      const loaderId = showLoader()
      return updateAssistAssessment({
        id,
        data: objectToFormData({
          assessment: {
            complete_assessment_attributes: formData,
          },
        }),
      })
        .then((data) => {
          closeModal()
          onActionSuccess(data)
        })
        .catch(() => NotificationManager.error())
        .then(() => hideLoader(loaderId))
    }
    openModal({ onSubmit })
  }, [id, openModal, closeModal, showLoader, hideLoader, onActionSuccess])
}
