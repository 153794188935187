import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useClientVendorResultsSummaryFetcher } from 'apis'
import { compose, withAuthorize, withReactQuery } from 'hocs'
import { useQueryParams } from 'hooks'
import config from 'config'
import View from './View'

const VendorResultSummary = ({ vendor }) => {
  const [queryParams, setQueryParams] = useQueryParams({
    includeChildren: {
      type: Boolean,
    },
  })
  const { includeChildren } = queryParams
  const [archivedVisible, setArchivedVisible] = useState(false)
  const { data, isFetching } = useClientVendorResultsSummaryFetcher({
    vendorId: vendor.id,
    params: {
      page: 1,
      per_page: config.collectionQueryTotalLimit.vendorResultsSummary,
      include_archived: archivedVisible,
      include_children: includeChildren,
    },
  })

  const handleToggleArchived = useCallback((e) => {
    setArchivedVisible(e.target.checked)
  }, [])

  const handleToggleChildren = useCallback((e) => {
    setQueryParams({ includeChildren: e.target.checked })
  }, [])

  const hasChildren = vendor.child_orgs.length > 0
  return (
    <View
      vendor={vendor}
      projects={data}
      loading={isFetching}
      archivedVisible={archivedVisible}
      includeChildren={includeChildren}
      hasChildren={hasChildren}
      onToggleArchived={handleToggleArchived}
      onToggleChildren={handleToggleChildren}
    />
  )
}

VendorResultSummary.propTypes = {
  vendor: PropTypes.object.isRequired,
}

export default compose(withAuthorize(), withReactQuery())(VendorResultSummary)
