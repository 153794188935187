import React from 'react'
import PropTypes from 'prop-types'
import Table from 'components-v2/molecules/Table'
import { Wrapper, Header, Title, Link } from './styles'

const TableCard = ({ title, url, ...rest }) => (
  <Wrapper>
    <Header>
      <Title>{title}</Title>
      {url && <Link href={url}>View All</Link>}
    </Header>
    <Table
      enablePagination={false}
      enableSorting={false}
      type={Table.types.MINI}
      {...rest}
    />
  </Wrapper>
)

TableCard.propTypes = {
  ...Table.propsTypes,
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
}

export default React.memo(TableCard)
