/*
  Using the NumberFormat constructor below, most browsers will format "123456789" as "$123,456,789.00"
  and "1234" as "$1,234.00", which I suppose is fine for now.
  ES2020 recently added { notation: 'compact', compactDisplay: 'short' } to the spec,
  which will format the same number as "$123M" and "1234" as "$1.2K".
  Chrome supports this now; the others will presumably catch up shortly.
*/
export const formatCurrency = (val) => {
  if (
    [null, undefined, ''].includes(val) ||
    (typeof val === 'string' && val.trim() === '')
  ) {
    return null
  }
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    notation: 'compact',
    compactDisplay: 'short',
  }).format(val)
}
