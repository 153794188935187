import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import BaseSelect from '../../atoms/Select'

const Select = ({
  value,
  placeholder,
  options,
  disabled,
  placeholderSelectable,
  onChange,
  ...rest
}) => (
  <BaseSelect
    {...rest}
    value={value !== undefined && value !== null ? value : ''}
    disabled={disabled}
    onChange={(e) => onChange(e.currentTarget.value)}
  >
    <option key="default" value="" disabled={!placeholderSelectable}>
      {placeholder}
    </option>
    {map(options, ({ value: valueOpt, label, ...restOpt }) => (
      <option {...restOpt} key={valueOpt} value={valueOpt}>
        {label}
      </option>
    ))}
  </BaseSelect>
)

Select.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  placeholderSelectable: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
}

Select.defaultProps = {
  placeholder: 'Select',
  disabled: false,
  placeholderSelectable: false,
}

export default Select
