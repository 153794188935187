import styled from 'styled-components'
import { colors } from 'styles'

export const Inner = styled.div`
  position: relative;
  padding-left: 125px;
  min-height: 45px;
`

const NumberStyle = `
  position: absolute;
  left: 0;
  top: 0;
  width: 105px;
  text-align: right;
  font-size: 50px;
  font-weight: 300;
  line-height: .8em;
  vertical-align: top;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  sup {
    color: #666;
    font-size: 60%;
    margin-left: 5px;
  }
`

export const NumberLink = styled.a`
  color: ${(props) => (props.active ? colors.PRIMARY : '#333')};
  ${NumberStyle}
`

export const NumberLabel = styled.span`
  color: ${(props) => (props.active ? colors.PRIMARY : '#333')};
  ${NumberStyle}
`

export const Title = styled.span`
  display: block;
  font-weight: bold;
`
