import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components-v2/atoms/Button'
import Dialog from 'components-v2/molecules/Dialog'
import { Panel } from 'components-v2/molecules/Dialog/styles'
import IssueListView from 'components/client/issue_list_view'
import styled from 'styled-components'
import { media } from 'styles'

const StyledDialog = styled(Dialog)`
  ${media.nmobile`
    ${Panel} {
      width: 75%;
    }
  `}
`

const columnOrder = ['summary', 'created_at', 'due_at', 'priority', 'status']

const IssueListModal = ({
  assessment,
  onClose,
  onIssuesTransitioned,
  ...rest
}) => (
  <StyledDialog {...rest} onClose={onClose}>
    <Dialog.Header onClose={onClose}>All Issues</Dialog.Header>
    <Dialog.Content>
      <IssueListView
        assessmentId={assessment.id}
        vendorId={assessment.vendor.id}
        modalFormat
        columnOrder={columnOrder}
        onIssuesTransitioned={onIssuesTransitioned}
      />
    </Dialog.Content>
    <Dialog.Footer>
      <Button onClick={onClose}>Close</Button>
    </Dialog.Footer>
  </StyledDialog>
)

IssueListModal.propTypes = {
  assessment: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onIssuesTransitioned: PropTypes.func,
}

export default IssueListModal
