import React from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useUpdateCurrentUser, useCurrentUserFetcher } from 'apis'
import NotificationManager from 'lib/notifications'

export default function useUpdateUser() {
  const queryClient = useQueryClient()
  const { mutateAsync: updateCurrentUser } = useUpdateCurrentUser()

  return React.useCallback(
    async (serverData, clientData) => {
      try {
        // Update UI first
        queryClient.setQueriesData(
          useCurrentUserFetcher.queryKey(),
          (current) => ({ ...current, ...(clientData ?? serverData) }),
        )
        await updateCurrentUser({ data: { user: serverData } })
      } catch (error) {
        console.error(error)
        let msg =
          'There was an error saving your response. Please check your connection and try again later.'
        if (error.response && error.response.status === 422) {
          msg = `There was an error saving your response. ${
            error.response.data.error || 'Invalid'
          }`
        }
        NotificationManager.error(msg)
      }
    },
    [updateCurrentUser, queryClient],
  )
}
