import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import Button from 'components-v2/atoms/Button'
import Dialog from 'components-v2/molecules/Dialog'
import Weight from 'components-v2/molecules/Weight'
import Alert from 'components-v2/molecules/Alert'
import { AssessmentElementTypes } from 'constants/index'
import { WarningIcon } from '../styles'
import { Table, Tr, Td, LabelTd, WeightWrapper } from './styles'

const BulkEditWeightModal = ({
  elements,
  isDraft,
  hasSensitiveQuestions,
  onClose,
  onSave,
  ...rest
}) => {
  const [changedElements, setChangedElements] = React.useState({})

  const handleWeightChange = React.useCallback((id, val) => {
    setChangedElements((current) => ({
      ...current,
      [id]: {
        weight: val,
      },
    }))
  }, [])

  return (
    <Dialog {...rest} onClose={onClose} size="lg">
      <Dialog.Header onClose={onClose}>Edit Weights</Dialog.Header>
      <Dialog.Content>
        {(!isDraft || hasSensitiveQuestions) && (
          <>
            <h5>
              <WarningIcon /> Attention:
            </h5>
            {!isDraft && (
              <Alert color="danger">
                Changing question weights will cause all Projects that are
                launched or closed using this template to have their rating
                score recalculated.
              </Alert>
            )}
            {hasSensitiveQuestions && isDraft && (
              // maybe in the future we can provide some per-line indication of which ones this warning is talking about;
              // easy enough to do, just need to decide on the visual marker
              <Alert color="danger">
                Setting question weights to zero for any questions that
                don&apos;t support score-based automations will cause those be
                deleted from this template.
              </Alert>
            )}
          </>
        )}
        <h5>Questions</h5>
        <Table>
          {elements.map((e) => (
            <Tr
              key={e.id}
              isHeading={e.type === AssessmentElementTypes.SECTION_HEADING}
            >
              <Td noWrap>{e.key}</Td>
              <LabelTd>{e.label}</LabelTd>
              <Td>
                {e.type !== AssessmentElementTypes.SECTION_HEADING && (
                  <WeightWrapper isEdited={!!changedElements[e.id]}>
                    <Weight
                      value={
                        changedElements[e.id]
                          ? changedElements[e.id].weight
                          : e.weight
                      }
                      onChange={(val) => handleWeightChange(e.id, val)}
                    />
                  </WeightWrapper>
                )}
              </Td>
            </Tr>
          ))}
        </Table>
      </Dialog.Content>
      <Dialog.Footer>
        <Button onClick={onClose}>Close</Button>
        <Button
          color="primary"
          disabled={isEmpty(changedElements)}
          onClick={() => onSave(changedElements)}
        >
          {isDraft ? 'Save' : 'Save & Recalculate'}
        </Button>
      </Dialog.Footer>
    </Dialog>
  )
}

BulkEditWeightModal.propTypes = {
  elements: PropTypes.array.isRequired,
  isDraft: PropTypes.bool,
  hasSensitiveQuestions: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default React.memo(BulkEditWeightModal)
