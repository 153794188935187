import React from 'react'
import PropTypes from 'prop-types'
import FormField from 'components-v2/molecules/FormField'
import { Row, DeleteButton } from './styles'

const ContactFieldGroup = ({ name, onDelete }) => (
  <div>
    <Row>
      <FormField name={`${name}.name`} placeholder="Name" />
      <FormField name={`${name}.phone`} placeholder="Phone number" />
    </Row>
    <Row>
      <FormField name={`${name}.email`} placeholder="Email address" />
      <DeleteButton icon="fa fa-close" onClick={onDelete} />
    </Row>
  </div>
)

ContactFieldGroup.propTypes = {
  name: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default ContactFieldGroup
