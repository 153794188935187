import { useEffect } from 'react'
import { useSetElementResponse } from './useAssessmentElementsFetcher'
import useSetAssessment from './useSetAssessment'

export default function usePusher(channelId, assessmentId) {
  const setElementResponse = useSetElementResponse()
  const setAssessment = useSetAssessment(assessmentId)

  // Listen state changes via pusher
  useEffect(() => {
    const update = (data) => {
      if (data.review_scores || data.risk_rating != null) {
        setAssessment((current) => ({
          ...current,
          review_scores: data.review_scores,
          calculated_risk_score: data.risk_rating,
          risk_domain_ratings: data.risk_domain_ratings,
        }))
      }
    }
    const updateResponse = (data) => {
      const { response } = data
      if (response) {
        setElementResponse(response.id, response)
      }
    }
    const channel = window.pusher.subscribe(channelId)
    channel.bind('update', update)
    channel.bind('update_response', updateResponse)
    return () => {
      channel.unbind('update', update)
      channel.unbind('update_response', updateResponse)
    }
  }, [channelId, setAssessment, setElementResponse])
}
