import React, { useEffect, useRef, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/get'
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import OverlayLoader from 'components-v2/organisms/OverlayLoader'
import { mixins } from 'styles'
import { compose } from 'hocs'
import BaseFooterPagination from './BaseFooterPagination'
import { formatCell } from './styles'
import withRowSelection from './withRowSelection'
import withPagination from './withPagination'
import withSorting from './withSorting'
import withGlobalFilter from './withGlobalFilter'
import withSticky from './withSticky'

const NoContentWrapper = styled.div`
  height: ${({ height }) => height}px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const StyledOverlayLoader = styled(OverlayLoader)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const TableWrapper = styled.div`
  position: relative;

  table {
    margin: 0;
    ${formatCell(`
      padding: 10px;
    `)}
  }

  th.text-ellipsis,
  td.text-ellipsis {
    ${mixins.truncate}
    max-width: 0;
  }
`

// Add Loading and Empty UI
// Add Pagination and Sorting UI
// Add row-related props - rowClasses, onRowClick
// Add column style-related properties to columnDefs  - classes, headerClasses, style, headerStyle, width, minWidth, maxWidth
// Tweak columns prop using accessorFn and hidden properties
function Table({
  columns,
  className,
  wrapperRef,
  tfootContent,
  // Loading UI
  loading,
  // Empty UI
  noDataText,
  noDataWrapperHeight,
  // Pagination
  pageSizes,
  hidePageSizes,
  hidePageListOnlyOnePage,
  enablePagination,
  footerPaginationComponent: FooterPaginationComponent,
  // Row-related
  rowClasses,
  onRowClick,
  ...props
}) {
  const columnsEx = useMemo(
    () =>
      columns
        .filter((e) => !e.hidden)
        .map((e) => {
          if (e.accessorKey || e.accessorFn) {
            return e
          }
          return {
            ...e,
            accessorFn: (row) => get(row, e.id),
          }
        }),
    [columns],
  )
  const table = useReactTable({
    getRowId: (row) => row.id,
    columns: columnsEx,
    getCoreRowModel: getCoreRowModel(),
    ...props,
  })
  const overlayRef = useRef()
  const theadRef = useRef()

  useEffect(() => {
    if (!loading || !overlayRef.current || !theadRef.current) {
      return
    }

    overlayRef.current.style.top = window.getComputedStyle(
      theadRef.current,
    ).height
  }, [loading])

  const handleKeyDown = useCallback(
    (header) => (e) => {
      if (
        (e.currentTarget === e.target && e.key === 'Enter') ||
        e.keyCode === 32
      ) {
        e.preventDefault()
        header.column.getToggleSortingHandler()(e)
      }
    },
    [],
  )

  const handleKeyDownOnRow = useCallback(
    (row) => (e) => {
      if (e.key === 'Enter' || e.keyCode === 32) {
        e.preventDefault()
        onRowClick(row)
      }
    },
    [onRowClick],
  )

  return (
    <div className={className} ref={wrapperRef}>
      <TableWrapper className="table-responsive">
        <table className="table">
          <thead ref={theadRef}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={`${
                      header.column.columnDef.headerClasses || ''
                    } ${header.column.getCanSort() ? 'sortable' : ''}`}
                    onClick={header.column.getToggleSortingHandler()}
                    onKeyDown={handleKeyDown(header)}
                    style={{
                      width: header.column.columnDef.width,
                      minWidth: header.column.columnDef.minWidth,
                      maxWidth: header.column.columnDef.maxWidth,
                      ...header.column.columnDef.headerStyle,
                    }}
                    tabIndex={header.column.getCanSort() ? 0 : undefined}
                  >
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {header.column.getIsSorted() && (
                          <span
                            className={`caret ${
                              header.column.getIsSorted() === 'asc'
                                ? 'dropup'
                                : 'dropdown'
                            }`}
                          />
                        )}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                className={
                  rowClasses && typeof rowClasses === 'function'
                    ? rowClasses(row)
                    : rowClasses
                }
                onClick={() => onRowClick && onRowClick(row)}
                tabIndex={onRowClick ? 0 : undefined}
                onKeyDown={onRowClick ? handleKeyDownOnRow(row) : undefined}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className={cell.column.columnDef.classes}
                    style={{
                      width: cell.column.columnDef.width,
                      minWidth: cell.column.columnDef.minWidth,
                      maxWidth: cell.column.columnDef.maxWidth,
                      ...cell.column.columnDef.style,
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          {tfootContent && <tfoot>{tfootContent}</tfoot>}
        </table>
        {table.getRowModel().rows.length === 0 && (
          <NoContentWrapper height={noDataWrapperHeight}>
            {!loading && noDataText}
          </NoContentWrapper>
        )}
        {loading && <StyledOverlayLoader active ref={overlayRef} />}
      </TableWrapper>
      {enablePagination && (
        <FooterPaginationComponent
          pageSizes={pageSizes}
          hidePageSizes={hidePageSizes}
          hidePageListOnlyOnePage={hidePageListOnlyOnePage}
          table={table}
        />
      )}
    </div>
  )
}

Table.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.array.isRequired,
  state: PropTypes.object,
  pageSizes: PropTypes.arrayOf(PropTypes.number),
  hidePageSizes: PropTypes.bool,
  hidePageListOnlyOnePage: PropTypes.bool,
  footerPaginationComponent: PropTypes.elementType,
  enablePagination: PropTypes.bool,
  loading: PropTypes.bool,
  noDataText: PropTypes.node,
  noDataWrapperHeight: PropTypes.number,
  rowClasses: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  wrapperRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  tfootContent: PropTypes.node,
  onRowClick: PropTypes.func,
}

Table.defaultProps = {
  pageSizes: [10, 25, 30, 50],
  noDataText: 'No content',
  noDataWrapperHeight: 200,
  footerPaginationComponent: BaseFooterPagination,
}

export default compose(
  withRowSelection,
  withPagination,
  withSorting,
  withGlobalFilter,
  withSticky,
)(Table)
