import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import Table from 'components-v2/molecules/Table'
import Toolbar, { SearchInput } from 'components-v2/molecules/Table/Toolbar'
import PageHeader from 'components-v2/organisms/PageHeader'
import { dateFormatterFactory } from 'lib/table'
import {
  getIssueStatusLabel,
  getIssueStatusOptions,
  // sortIssues,
  issueDueDateFormatterFactory,
  issuePriorityFormatterFactory,
} from 'lib/issue'
import { STICKY_TOP_OFFSET, EXTRA_STICKY_GAP, StyledSelect } from './styles'

const dateFormatter = dateFormatterFactory()
const dueDateFormatter = issueDueDateFormatterFactory()
const priorityFormatter = issuePriorityFormatterFactory()
const issueTableColumns = [
  {
    id: 'id',
    header: 'id',
    hidden: true,
  },
  {
    id: 'summary',
    header: 'Issue',
    cell: (info) => <a href={info.row.original.path}>{info.getValue()}</a>,
    width: 350,
  },
  {
    id: 'priority',
    header: 'Priority',
    cell: priorityFormatter,
    width: 120,
  },
  {
    id: 'status',
    header: 'Status',
    cell: (info) => getIssueStatusLabel(info.getValue(), true),
    width: 110,
  },
  {
    id: 'created_at',
    header: 'Created',
    cell: dateFormatter,
    width: 120,
  },
  {
    id: 'due_at',
    header: 'Due',
    cell: dueDateFormatter,
    width: 100,
  },
  {
    id: 'df1',
    header: '',
    cell: (info) => <a href={info.row.original.path}>View</a>,
    enableSorting: false,
    classes: 'hidden-print',
    headerClasses: 'hidden-print',
    width: 80,
  },
]

const View = ({
  issues,
  totalSize,
  page,
  sizePerPage,
  sortField,
  sortOrder,
  searchText,
  statusFilter,
  loading,
  onPaginationChange,
  onSortingChange,
  onSearchChange,
  onStatusFilterChange,
}) => (
  <>
    <PageHeader title="Issues" />
    <Toolbar>
      <SearchInput value={searchText} onChange={onSearchChange} />
      <Toolbar.Group>
        <StyledSelect
          placeholder="All Issues"
          value={statusFilter}
          onChange={onStatusFilterChange}
        >
          <option key="all" value="">
            All Issues
          </option>
          {map(getIssueStatusOptions(true), (option, key) => (
            <option key={key} value={key}>
              {option.label}
            </option>
          ))}
        </StyledSelect>
      </Toolbar.Group>
    </Toolbar>
    <Table
      loading={loading}
      data={issues || []}
      // if we decide to apply default sorting:
      // data={(sortField == undefined && sortIssues(isVendor, issues)) || issues || []}
      columns={issueTableColumns}
      totalSize={totalSize}
      pagination={{ page, sizePerPage }}
      sorting={{ sortField, sortOrder }}
      onPaginationChange={onPaginationChange}
      onSortingChange={onSortingChange}
      manualPagination
      manualSorting
      type={Table.types.ALT}
      enableSticky
      topOffset={STICKY_TOP_OFFSET}
      aboveStickyWhiteSpace={EXTRA_STICKY_GAP}
      aboveStickyWhiteSpaceColor="#F5F5F5"
    />
  </>
)

View.propTypes = {
  issues: PropTypes.array,
  totalSize: PropTypes.number,
  page: PropTypes.number,
  sizePerPage: PropTypes.number,
  sortField: PropTypes.string,
  sortOrder: PropTypes.string,
  searchText: PropTypes.string,
  statusFilter: PropTypes.string,
  loading: PropTypes.bool,
  onPaginationChange: PropTypes.func.isRequired,
  onSortingChange: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onStatusFilterChange: PropTypes.func.isRequired,
}

export default View
