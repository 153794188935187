import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import includes from 'lodash/includes'
import map from 'lodash/map'
import omit from 'lodash/omit'
import { VendorStatusGroups } from 'constants/index'
import Address from './Address'
import InfoCard from './InfoCard'
import FieldGroupCard from './FieldGroupCard'
import ResultsSummaryCard from './ResultsSummaryCard'
import ReviewRequestCard from './ReviewRequestCard'
import RiskMeasuresCard from './RiskMeasuresCard'
import { VendorFieldGroupIdTypes } from './constants'
import {
  getVendorFieldValue,
  buildCustomFieldsCard,
  pickVendorFields,
} from './utils'
import { Wrapper, Left, Right } from './styles'

const View = ({
  vendor,
  vendorFieldGroups,
  resultsSummary,
  resultsSummaryLoading,
  resultsSummaryArchivedVisible,
  resultsSummaryIncludeChildren,
  cards,
  facetableSets,
  onEdit,
  onSave,
  onCancel,
  onToggleResultSummaryArchived,
  onToggleResultSummaryIncludeChildren,
  onApproveRequest,
  onDeclineRequest,
  ...rest
}) => {
  const updatable = vendor.class !== 'VendorRequest'
  const contactList = getVendorFieldValue(vendor, 'vendor_contacts')
  const rightFieldGroups = React.useMemo(() => {
    const groups = omit(vendorFieldGroups, [
      VendorFieldGroupIdTypes.GENERAL_INFO,
      VendorFieldGroupIdTypes.MAIN_ADDRESS,
      VendorFieldGroupIdTypes.PRIMARY_CONTRIBUTOR,
      VendorFieldGroupIdTypes.CONTRIBUTORS,
      VendorFieldGroupIdTypes.CONTACT_LIST,
    ])
    if (vendor.custom_field_definitions) {
      const custFieldsCards = buildCustomFieldsCard(
        vendor.custom_field_definitions,
        vendor.custom_field_groups,
      )
      return {
        ...custFieldsCards,
        ...groups,
      }
    }
    return groups
  }, [
    vendorFieldGroups,
    vendor.custom_field_definitions,
    vendor.custom_field_groups,
  ])
  const mainAddr = pickVendorFields(vendor, [
    'main_address_1',
    'main_address_2',
    'main_city',
    'main_state',
    'main_postal_code',
    'main_country_code',
  ])
  return (
    <Wrapper {...rest}>
      <Left>
        <FieldGroupCard
          {...cards[VendorFieldGroupIdTypes.GENERAL_INFO]}
          cardId={VendorFieldGroupIdTypes.GENERAL_INFO}
          data={vendorFieldGroups[VendorFieldGroupIdTypes.GENERAL_INFO]}
          vendor={vendor}
          onEdit={onEdit}
          onSave={onSave}
          onCancel={onCancel}
        />
        {contactList.length > 0 && (
          <FieldGroupCard
            {...cards[VendorFieldGroupIdTypes.CONTACT_LIST]}
            cardId={VendorFieldGroupIdTypes.CONTACT_LIST}
            data={vendorFieldGroups[VendorFieldGroupIdTypes.CONTACT_LIST]}
            vendor={vendor}
            onEdit={onEdit}
            onSave={onSave}
            onCancel={onCancel}
          />
        )}
        {get(cards, [VendorFieldGroupIdTypes.MAIN_ADDRESS, 'editing']) ? (
          <FieldGroupCard
            {...cards[VendorFieldGroupIdTypes.MAIN_ADDRESS]}
            cardId={VendorFieldGroupIdTypes.MAIN_ADDRESS}
            data={vendorFieldGroups[VendorFieldGroupIdTypes.MAIN_ADDRESS]}
            vendor={vendor}
            onEdit={onEdit}
            onSave={onSave}
            onCancel={onCancel}
          />
        ) : (
          <InfoCard
            title={
              vendorFieldGroups[VendorFieldGroupIdTypes.MAIN_ADDRESS].label
            }
            onEdit={() => onEdit(VendorFieldGroupIdTypes.MAIN_ADDRESS)}
            updatable={updatable}
          >
            <Address
              address1={mainAddr.main_address_1}
              address2={mainAddr.main_address_2}
              city={mainAddr.main_city}
              state={mainAddr.main_state}
              postal={mainAddr.main_postal_code}
              country={mainAddr.main_country_code}
            />
          </InfoCard>
        )}
      </Left>
      <Right>
        {facetableSets?.length > 0 && <RiskMeasuresCard data={facetableSets} />}
        {includes(VendorStatusGroups.operational, vendor.status) ? (
          <ResultsSummaryCard
            {...resultsSummary}
            loading={resultsSummaryLoading}
            archivedVisible={resultsSummaryArchivedVisible}
            includeChildren={resultsSummaryIncludeChildren}
            vendor={vendor}
            onToggleArchived={onToggleResultSummaryArchived}
            onToggleChildren={onToggleResultSummaryIncludeChildren}
          />
        ) : (
          <ReviewRequestCard
            organizationDetail={vendor.organization_detail}
            vendor={vendor}
            vendorRequest={vendor}
            onApproveRequest={onApproveRequest}
            onDeclineRequest={onDeclineRequest}
          />
        )}
        {map(rightFieldGroups, (group, id) => (
          <FieldGroupCard
            key={id}
            {...cards[id]}
            cardId={id}
            data={group}
            vendor={vendor}
            horizontal
            onEdit={onEdit}
            onSave={onSave}
            onCancel={onCancel}
          />
        ))}
      </Right>
    </Wrapper>
  )
}

View.propTypes = {
  vendor: PropTypes.object.isRequired,
  vendorFieldGroups: PropTypes.object.isRequired,
  resultsSummary: PropTypes.object.isRequired,
  resultsSummaryLoading: PropTypes.bool,
  resultsSummaryArchivedVisible: PropTypes.bool,
  resultsSummaryIncludeChildren: PropTypes.bool,
  cards: PropTypes.any,
  facetableSets: PropTypes.array,
  onEdit: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onToggleResultSummaryArchived: PropTypes.func.isRequired,
  onToggleResultSummaryIncludeChildren: PropTypes.func.isRequired,
  onApproveRequest: PropTypes.func,
  onDeclineRequest: PropTypes.func,
}

export default React.memo(View)
