import axios from 'axios'
import { getCSRFToken } from '../setupCSRFToken'

export default () => {
  axios.interceptors.request.use(
    (config) => {
      const isSameOriginRequest =
        config.url.startsWith('/') ||
        config.url.startsWith(window.location.origin)
      if (isSameOriginRequest) {
        /* eslint-disable-next-line no-param-reassign */
        config.headers['X-CSRF-Token'] = getCSRFToken()
      }
      return config
    },
    (error) => Promise.reject(error),
  )

  axios.interceptors.response.use(null, (error) => {
    if (error.response && error.response.status === 401) {
      // eslint-disable-next-line no-console
      console.log('axios 401 redirect')
      if (window.location.hash) {
        window.location.reload()
      } else {
        // eslint-disable-next-line no-self-assign
        window.location.href = window.location.href
      }
      // window.location.href = '/users/sign_in'
      // return
    }
    return Promise.reject(error)
  })
}
