import React from 'react'
import PropTypes from 'prop-types'
import FormField from 'components-v2/molecules/FormField'

const DateTimeFieldDetails = ({ fieldDef }) => {
  const primaryFields = (
    <React.Fragment key="primary_fields">
      <FormField
        name="render_opts.allow_past"
        label="Allow past values"
        helpMessage={`Should the user be able to select dates ${
          fieldDef.meta_type === 'datetime' ? 'and times ' : ''
        }in the past, or only in the future?`}
        type={FormField.types.TOGGLE}
        inline
      />
    </React.Fragment>
  )

  // placeholder
  const advancedFields = <React.Fragment key="advanced_fields" />

  return [primaryFields, advancedFields]
}
DateTimeFieldDetails.propTypes = {
  fieldDef: PropTypes.object.isRequired,
  // typeConfig: PropTypes.object,
  // formProps: PropTypes.object,
}

export default DateTimeFieldDetails
