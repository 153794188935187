import React from 'react'
import PropTypes from 'prop-types'
import filter from 'lodash/filter'
import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'
import merge from 'lodash/merge'
import { getClientIssueCategories } from 'apis'
import Button from 'components-v2/atoms/Button'
import AutomationItem from './AutomationItem'
import {
  Wrapper,
  Inner,
  Heading,
  Content,
  IconWrapper,
  CheckIcon,
} from './styles'

// since at least for now, issue stubs are the only thing needing manual review
const getValidStubs = (autoList) =>
  filter(
    autoList,
    (auto) =>
      auto.assessment_question_automation && auto.source_type === 'issue_stub',
  )

const getStaged = (autoList) => filter(autoList, (qa) => qa.status === 'staged')

const getSelected = (qaIndex) =>
  map(
    filter(qaIndex, ['transition', 'activate']),
    'assessment_question_automation.id',
  )

/**
 * Question automations come in as array of *all* automations for the assessment.
 *    source_types: 'issue_stub' or 'clause'
 *    statuses: 'staged', 'activated', 'skipped'
 *
 * Only prompt user for issue stubs that are staged.
 */
const AutomationReviewHeader = ({
  questionAutomations,
  onAutomationTransitionClick,
  onCancelClick,
}) => {
  const [qaIdx, setQaIdx] = React.useState({})
  const [selected, setSelected] = React.useState([])
  const updateStates = (newIdx, newSelections) => {
    setQaIdx(newIdx)
    setSelected(newSelections || getSelected(newIdx))
  }

  React.useEffect(() => {
    rebuildQaIndex(questionAutomations)
  }, [questionAutomations])

  const rebuildQaIndex = (qaList) => {
    const stubs = getValidStubs(qaList)
    const idx = merge(qaIdx, keyBy(stubs, 'assessment_question_automation.id'))
    const sel = []
    const newIdx = mapValues(idx, (stub) => {
      // take note of what should be pre-selected while we're here
      if (stub.status === 'staged')
        sel.push(stub.assessment_question_automation.id)

      let transition
      if (stub.transition) {
        transition = stub.transition === 'activate' ? 'skip' : stub.transition
      } else {
        transition = stub.status === 'staged' && 'activate'
      }
      return { ...stub, transition }
    })
    updateStates(newIdx, sel)
  }

  const [issueCategories, setIssueCategories] = React.useState([])
  React.useEffect(() => {
    getClientIssueCategories().then((response) =>
      setIssueCategories(response.data),
    )
  }, [])

  const toggleSelected = (id) => {
    const auto = qaIdx[id]
    auto.transition =
      get(auto, 'transition') === 'activate' ? 'skip' : 'activate'
    const newIdx = { ...qaIdx, [id]: auto }
    updateStates(newIdx)
  }

  const handleConfirm = () => {
    onAutomationTransitionClick(selected, 'activate')
  }

  const handleConfirmAndOpen = () => {
    onAutomationTransitionClick(selected, 'activate_open')
  }

  return (
    <Wrapper>
      <Inner>
        <IconWrapper>
          <CheckIcon />
        </IconWrapper>
        <Heading>Available automatic issues</Heading>
        <Content>
          <p>
            The following issues can automatically be created based on the
            answers given.
            <br />
            You&#39;ll be able to review the issues you want to create.
          </p>
          <>
            <p>
              We will <b>automatically create the issues below</b> based on
              answer automations in your template
            </p>
            {map(getStaged(questionAutomations), (auto) => (
              <AutomationItem
                key={auto.automation.id}
                autoItem={auto}
                selectedList={selected}
                issueCategories={issueCategories}
                onSelected={toggleSelected}
              />
            ))}
            <Button
              color="primary"
              disabled={selected.length === 0}
              onClick={handleConfirm}
            >
              Create
            </Button>
            &nbsp;
            <Button
              variant="outlined"
              disabled={selected.length === 0}
              onClick={handleConfirmAndOpen}
            >
              Create and Send
            </Button>
            &nbsp;
            <Button variant="outlined" onClick={onCancelClick}>
              Cancel
            </Button>
          </>
        </Content>
      </Inner>
    </Wrapper>
  )
}

AutomationReviewHeader.propTypes = {
  questionAutomations: PropTypes.array.isRequired,
  onAutomationTransitionClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
}

export default AutomationReviewHeader
