import React from 'react'
import { useModal } from 'hooks'
import SharingModal from './SharingModal'

export default (profileId) => {
  const [openModal] = useModal(SharingModal)

  return React.useCallback(
    (listing) => {
      openModal({
        profileId,
        listing,
      })
    },
    [profileId, openModal],
  )
}
