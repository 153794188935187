import React from 'react'
import { useModal } from 'hooks'
import { useGlobalOrgIntegrationMetricsRiskReconIssuesFetcher } from 'apis'
import RiskReconIssuesModal from './RiskReconIssuesModal'

export default function useShowRiskReconIssues(globalOrgId, clientId) {
  /* eslint-disable-next-line no-unused-vars */
  const [openRiskReconIssuesModal, _, updateRiskReconIssuesModal] =
    useModal(RiskReconIssuesModal)
  const [enabled, setEnabled] = React.useState(false)
  const fetcherData = useGlobalOrgIntegrationMetricsRiskReconIssuesFetcher(
    {
      globorgOrgId: globalOrgId,
      params: {
        client_id: clientId,
      },
    },
    { enabled },
  )
  // The above fetcher can be resolved as success with "error" data
  // For instance: fetcherData.data variable can hold the following value;
  // { data: null, error: “809: unexpected token at 'Unauthorized’” }
  // Let's repopulate real "successful" data and isError values
  const data =
    fetcherData.data && !fetcherData.data.error ? fetcherData.data : undefined
  const isError = fetcherData.isError || !!fetcherData.data?.error
  const { isLoading } = fetcherData

  React.useEffect(() => {
    updateRiskReconIssuesModal({ data, loading: isLoading, error: isError })
  }, [data, isLoading, isError, updateRiskReconIssuesModal])

  return React.useCallback(
    (filter, onCreateIssue) => {
      setEnabled(true)

      openRiskReconIssuesModal({
        data,
        loading: isLoading,
        error: isError,
        filter,
        onCreateIssue,
      })
    },
    [data, isLoading, isError, openRiskReconIssuesModal],
  )
}
