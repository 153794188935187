import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import FormDialog from 'components-v2/organisms/FormDialog'
import FormField from 'components-v2/molecules/FormField'

const getFormConfig = (name = '') => ({
  validationSchema: yup.object().shape({
    name: yup.string().required('This is required'),
  }),
  initialValues: {
    name,
  },
})

const WatchlistModal = ({ name, ...rest }) => {
  const formConfig = useMemo(() => getFormConfig(name), [name])
  return (
    <FormDialog {...rest} submitButtonLabel="Save" formConfig={formConfig}>
      <FormField
        name="name"
        label="List name"
        horizontal
        controlProps={{ autoFocus: true }}
      />
    </FormDialog>
  )
}

WatchlistModal.propTypes = {
  name: PropTypes.string,
}

export default WatchlistModal
