import { colors } from 'styles'

export default {
  // static color mappings for consistent colors across charts with missing series
  Closed: colors.GREEN,
  Created: colors.BLUE,
  Open: colors.RED,
  'Needs Review': colors.ORANGE,
  Archived: colors.DARK_GREY,

  Medium: colors.BLUE,
  High: colors.ORANGE,
  Critical: colors.RED,
  Low: colors.GREEN,

  Resolved: colors.GREEN,
  Failed: colors.RED,
  'Risk Accepted': colors.ORANGE,
  'In Remediation': colors.BLUE,
  Canceled: '#990099',
  None: '#999999',

  colors: [
    // "#3366CC",
    // "#DC3912",
    // "#FF9900",
    // "#109618",
    // "#990099",
    '#3B3EAC',
    '#0099C6',
    '#DD4477',
    '#66AA00',
    '#B82E2E',
    '#316395',
    '#994499',
    '#22AA99',
    '#AAAA11',
    '#6633CC',
    '#E67300',
    '#8B0707',
    '#329262',
    '#5574A6',
    '#3B3EAC',
  ],
}
