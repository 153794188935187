import styled from 'styled-components'
import { colors, fonts } from 'styles'
import Button from 'components-v2/atoms/Button'
import Dialog from 'components-v2/molecules/Dialog'

export const ModalDiv = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  font-weight: normal;
  text-align: center;
  color: #fff;
  font-family: ${fonts.PRIMARY};
  background-color: #2176db;
`

export const Callout = styled.div`
  padding: 0px 40px 10px;
  line-height: 28px;
  font-weight: normal;
  font-size: 24px;
`

export const Message = styled.div`
  padding: 10px 120px;
  font-weight: normal;
  font-size: 20px;
`

export const StyledModalHeader = styled(Dialog.Header)`
  margin: 0;
  font-size: 28px;
  font-weight: normal;
  color: #fff;
  background-color: #2176db;

  .close {
    color: ${colors.WHITE};
    opacity: 0.6;
    font-size: 28px;
  }
`

export const ModalTitle = styled.h2`
  font-size: 28px;
  font-weight: normal;
  color: #fff;
  text-align: center;
`

export const StyledButton = styled(Button)`
  width: 25%;
`
