import { useCallback } from 'react'
import { useGlobalLoader, useConfirm } from 'hooks'
import NotificationManager from 'lib/notifications'
import {
  useArchiveClientAssessmentTemplate,
  useMakeReadyClientAssessmentTemplate,
  useUnreadyClientAssessmentTemplate,
} from 'apis'
import { getTemplatesPageUrl } from './utils'

export default function useTemplateStateMutations(templateId) {
  const { mutateAsync: archiveClientAssessmentTemplate } =
    useArchiveClientAssessmentTemplate()
  const { mutateAsync: makeReadyClientAssessmentTemplate } =
    useMakeReadyClientAssessmentTemplate()
  const { mutateAsync: unreadyClientAssessmentTemplate } =
    useUnreadyClientAssessmentTemplate()
  const openConfirm = useConfirm()
  const [showLoader, hideLoader] = useGlobalLoader()

  const archive = useCallback(() => {
    openConfirm({
      title: 'Archiving Template',
      body: 'Are you sure you want to archive this template?',
      onConfirm: () => {
        const loaderId = showLoader({
          text: 'Archiving Template...',
        })
        archiveClientAssessmentTemplate({ id: templateId })
          .then(() => {
            window.location.href = getTemplatesPageUrl()
          })
          .catch(() => {
            NotificationManager.error()
            hideLoader(loaderId)
          })
      },
    })
  }, [templateId, openConfirm, showLoader, hideLoader])

  const makeReady = useCallback(() => {
    openConfirm({
      title: 'Make Template Ready',
      body: 'Would you like to mark this template as ready and available for usage? It will be locked for editing.',
      onConfirm: () => {
        const loaderId = showLoader({
          text: 'Preparing Template for use...',
        })
        return makeReadyClientAssessmentTemplate({ id: templateId })
          .then(() => {
            window.location.href = getTemplatesPageUrl()
          })
          .catch(() => {
            NotificationManager.error()
            hideLoader(loaderId)
          })
      },
    })
  }, [templateId, openConfirm, showLoader, hideLoader])

  const makeDraft = useCallback(() => {
    openConfirm({
      title: 'Continue Editing Template',
      body: 'Would you like to mark this template as draft and continue editing?',
      onConfirm: () => {
        const loaderId = showLoader({
          text: 'Setting template back to draft...',
        })
        return unreadyClientAssessmentTemplate({ id: templateId })
          .then(() => window.location.reload())
          .catch(() => {
            NotificationManager.error()
            hideLoader(loaderId)
          })
      },
    })
  }, [templateId, openConfirm, showLoader, hideLoader])

  return {
    archive,
    makeReady,
    makeDraft,
  }
}
