import React from 'react'
import * as yup from 'yup'
import FormField from 'components-v2/molecules/FormField'
import FormDialog from 'components-v2/organisms/FormDialog'

const formConfig = {
  initialValues: {
    label: undefined,
  },
  validationSchema: yup.object().shape({
    label: yup.string().label('Section Name').required(),
  }),
}

export default (props) => (
  <FormDialog
    {...props}
    submitButtonLabel="Save"
    title="New Section"
    formConfig={formConfig}
  >
    <FormField
      name="label"
      label="Section Name"
      type={FormField.types.TEXTAREA}
      controlProps={{ autoFocus: true }}
    />
  </FormDialog>
)
