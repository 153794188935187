import React from 'react'
import PropTypes from 'prop-types'
import Dialog from 'components-v2/molecules/Dialog'
import Button from 'components-v2/atoms/Button'
import Table from 'components-v2/molecules/Table'
import Tooltip from 'components-v2/molecules/Tooltip'
import { dateFormatterFactory } from 'lib/table'

const dateFormatter = dateFormatterFactory()
const tableColumns = [
  {
    id: 'name',
    header: 'File Name',
    cell: (props) => {
      const display = (
        <>
          <a href={props.row.original.url} target="_blank">
            {props.row.original.name}
          </a>
          {props.row.original.kind && (
            <small> ({props.row.original.kind})</small>
          )}
        </>
      )
      if (props.row.original.full_name !== props.row.original.name)
        return (
          <Tooltip parent={display}>{props.row.original.full_name}</Tooltip>
        )
      return <span>{display}</span>
    },
  },
  {
    id: 'created_at',
    header: 'Uploaded',
    cell: dateFormatter,
    width: '15%',
  },
]

const AttachmentsModal = ({
  title,
  attachments,
  loading,
  additionalFooterContent,
  onClose,
  ...rest
}) => (
  <Dialog {...rest} onClose={onClose}>
    <Dialog.Header onClose={onClose}>{title}</Dialog.Header>
    <Dialog.Content>
      <Table
        loading={loading}
        data={attachments || []}
        columns={tableColumns}
        noDataText="No attachments found."
        enableSorting={false}
        enablePagination={false}
        type={Table.types.ALT}
      />
    </Dialog.Content>
    <Dialog.Footer>
      {additionalFooterContent}
      <Button onClick={onClose}>Close</Button>
    </Dialog.Footer>
  </Dialog>
)

AttachmentsModal.propTypes = {
  title: PropTypes.string,
  attachments: PropTypes.array,
  loading: PropTypes.bool,
  additionalFooterContent: PropTypes.node,
  onClose: PropTypes.func.isRequired,
}

export default AttachmentsModal
