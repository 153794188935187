import React from 'react'
import styled from 'styled-components'
import keyBy from 'lodash/keyBy'
import Label from 'components-v2/atoms/Label'
import { RiskTierOptions } from 'constants/index'

const RiskTierOptObj = keyBy(RiskTierOptions, 'value')
const RiskTierLabel = styled(Label)`
  width: 55px;
`

export const vendorRiskTierFormatterFactory = () => (info) =>
  info.getValue() ? (
    <RiskTierLabel color={RiskTierOptObj[info.getValue()].color}>
      {RiskTierOptObj[info.getValue()].label}
    </RiskTierLabel>
  ) : (
    ''
  )
