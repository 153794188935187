import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import useCreateUpdateIssue from 'components/client/shared/useCreateUpdateIssue'
import { useDeleteClientOrganizationPartnerMapping } from 'apis'
import { withReactQuery } from 'hocs'
import View from './View'

const Container = ({ partnerInfo, vendorId }) => {
  const { createIssue } = useCreateUpdateIssue()
  const { mutateAsync: deleteClientOrganizationPartnerMapping } =
    useDeleteClientOrganizationPartnerMapping()
  const parternMapping = partnerInfo.partner_mappings[0]
  const apiUrl = `${partnerInfo.detail_api_path}?guid=${parternMapping.partner_vendor_id}`
  const { data, isLoading, isError } = useQuery(
    ['bigSightVendor', apiUrl],
    () => axios.get(apiUrl).then((res) => res.data),
  )

  const handleDisconnect = useCallback(() => {
    // eslint-disable-next-line
    if (confirm('Are you sure want to disconnect this vendor?')) {
      deleteClientOrganizationPartnerMapping({ id: parternMapping.id }).then(
        () => window.location.replace(window.location.pathname),
      )
    }
  }, [parternMapping, deleteClientOrganizationPartnerMapping])

  const handleCreateIssue = useCallback(() => {
    createIssue({ vendorId })
  }, [createIssue, vendorId])

  return (
    <View
      detail={data?.data}
      error={isError}
      loading={isLoading}
      partnerInfo={partnerInfo}
      disconnectButtonVisible={!!parternMapping}
      onDisconnect={handleDisconnect}
      onCreateIssue={handleCreateIssue}
    />
  )
}

Container.propTypes = {
  partnerInfo: PropTypes.object.isRequired,
  vendorId: PropTypes.string.isRequired,
}

export default withReactQuery()(Container)
