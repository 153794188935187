import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { compose, withAuthorize, withReactQuery } from 'hocs'
import { colors } from 'styles'
import ModalContainer from './ModalContainer'
import GlobalLoaderContainer from './GlobalLoaderContainer'

const GlobalStyle = createGlobalStyle`
  .Toastify__toast {
    font-family: inherit !important;
  }

  :root {
    --toastify-color-success: ${colors.GREEN};
    --toastify-color-error: ${colors.RED};
  }
`

const AfterFooter = () => (
  <>
    <ToastContainer theme="colored" limit={5} hideProgressBar />
    <GlobalStyle />
    <ModalContainer />
    <GlobalLoaderContainer />
  </>
)

export default compose(withAuthorize(), withReactQuery())(AfterFooter)
