import React from 'react'
import PropTypes from 'prop-types'
import Label from 'components-v2/atoms/Label'
import Tooltip from 'components-v2/molecules/Tooltip'
import { formatRiskRating, getRiskRatingColor } from '../utils'

const RiskRatingLabel = ({ value, ...rest }) => (
  <Tooltip
    parent={
      <Label {...rest} color={getRiskRatingColor(value)}>
        {formatRiskRating(value)}
      </Label>
    }
  >
    Risk rating
  </Tooltip>
)

RiskRatingLabel.propTypes = {
  value: PropTypes.number,
}

export default React.memo(RiskRatingLabel)
