import React from 'react'
import PropTypes from 'prop-types'
import reject from 'lodash/reject'
import some from 'lodash/some'
import { useClientProjectFetcher } from 'apis'
import useProjectVendorsProps from 'components/client/project_edit_view/useProjectVendorsProps'
import useCRUDAssessmentTemplate from './useCRUDAssessmentTemplate'
import View from './View'

const DraftView = ({
  project: projectProp,
  showGloborg,
  assessmentTemplates,
  assessmentTemplatesEditable,
}) => {
  const { data: project } = useClientProjectFetcher(
    {
      id: projectProp.id,
    },
    { initialData: projectProp, refetchOnMount: false },
  )
  const projectVendorsProps = useProjectVendorsProps(project, showGloborg)
  const { addAssessmentTemplate, deleteAssessmentTemplate } =
    useCRUDAssessmentTemplate(project)

  const addableAssessmentTemplates = React.useMemo(
    () =>
      reject(assessmentTemplates, ({ id }) =>
        some(project.assessment_templates, { id }),
      ),
    [assessmentTemplates, project.assessment_templates],
  )

  return (
    <View
      project={project}
      showGloborg={showGloborg}
      assessmentTemplatesEditable={assessmentTemplatesEditable}
      addableAssessmentTemplates={addableAssessmentTemplates}
      projectVendorsProps={projectVendorsProps}
      onAssessmentTemplateAdd={addAssessmentTemplate}
      onAssessmentTemplateDelete={deleteAssessmentTemplate}
    />
  )
}

DraftView.propTypes = {
  project: PropTypes.object.isRequired,
  showGloborg: PropTypes.bool,
  assessmentTemplates: PropTypes.array.isRequired,
  assessmentTemplatesEditable: PropTypes.bool,
}

export default DraftView
