import React from 'react'
import intersectionBy from 'lodash/intersectionBy'
import NotificationManager from 'lib/notifications'
import CreateVendorModal from 'components/client/shared/CreateVendorModal'
import { useConfirm, useGlobalLoader, useModal, useSimpleDialog } from 'hooks'
import * as apis from 'apis'
import {
  useCreateClientVendor,
  useApproveClientVendorRequest,
  useCreateClientOrganizationGloborgMapping,
} from 'apis'
import { LabelTypes, VendorStatusTypes } from 'constants/index'
import { getPageUrl } from 'utils/url'
import { unflatten } from 'utils/object'

const isArchived = (vendor) => vendor.status === VendorStatusTypes.ARCHIVED
const findBestMatchedVendor = (vendors) => {
  if (!vendors || vendors.length === 0) {
    return null
  }
  const activeVendor = vendors.find((e) => !isArchived(e))
  return activeVendor || vendors[0]
}
export const CreateVendorModeTypes = {
  CREATE: 'create',
  ACTIVATE: 'activate',
}

export default function useCreateVendor() {
  const { mutateAsync: createClientVendor } = useCreateClientVendor()
  const { mutateAsync: approveClientVendorRequest } =
    useApproveClientVendorRequest()
  const { mutateAsync: createClientOrganizationGloborgMapping } =
    useCreateClientOrganizationGloborgMapping()
  const openConfirm = useConfirm()
  const [openDialog, closeDialog] = useSimpleDialog()
  const [showLoader, hideLoader] = useGlobalLoader()
  const [openCreateVendorModal, closeCreateVendorModal] =
    useModal(CreateVendorModal)

  return React.useCallback(
    ({
      id,
      mode = CreateVendorModeTypes.CREATE,
      onCreated = () => {},
      onGloborgConnected = () => {},
      ...newVendorProps
    }) => {
      const linkToGlobalData = (vendor, domain) => {
        const loaderId = showLoader()
        createClientOrganizationGloborgMapping({
          data: {
            vendor_id: vendor.id,
            domain,
          },
        })
          .then((response) => {
            NotificationManager.success(
              'Public profile has been successfully connected.',
            )
            onGloborgConnected(vendor, domain, response)
            closeCreateVendorModal()
            hideLoader(loaderId)
          })
          .catch((error) => {
            NotificationManager.error()
            hideLoader(loaderId)
            throw error
          })
      }

      openCreateVendorModal({
        ...newVendorProps,
        onSubmit: (vendor, { setSubmitting, setErrors }) => {
          const createVendor = () => {
            const loaderId = showLoader()
            const promise =
              mode === CreateVendorModeTypes.CREATE
                ? createClientVendor({ data: { vendor } })
                : approveClientVendorRequest({ id, data: { vendor } })
            promise
              .then((response) => {
                NotificationManager.success(
                  `New ${LabelTypes.VENDOR.toLowerCase()} has been successfully created.`,
                )
                onCreated(response)
                closeCreateVendorModal()
              })
              .catch((error) => {
                if (error.response.status === 422) {
                  setErrors(unflatten(error.response.data.errors))
                } else {
                  NotificationManager.error()
                }
              })
              .finally(() => hideLoader(loaderId))
          }

          // We disable user interaction using loader, so we can just set submitting to false immediately
          setSubmitting(false)

          const { name, domain } = vendor
          const loaderId = showLoader()

          // Add checks for scenarios that might generate duplicate vendors
          const params = { name, domain, exclude_archived: false }
          apis
            .getClientSimilarVendors({ params })
            .then((response) => {
              hideLoader(loaderId)

              const {
                name_matches: nameMatches,
                domain_matches: domainMatches,
              } = response.data
              const common = intersectionBy(nameMatches, domainMatches, 'id')
              const exactNameMatches =
                nameMatches &&
                nameMatches.filter(
                  (m) =>
                    m.name.toLowerCase().trim() === name.toLowerCase().trim(),
                )

              if (exactNameMatches && exactNameMatches.length > 0) {
                // Scenario 1: Already have a vendor with exact same name
                const exactNameMatch = findBestMatchedVendor(exactNameMatches)
                const domainMatched = common.includes(
                  (e) => e.id === exactNameMatch.id,
                )
                const needLinkToGlobalData = !domainMatched && !!domain
                const buttons = [
                  {
                    children: `View ${LabelTypes.VENDOR.toLowerCase()}`,
                    onClick: () => {
                      window.location.href = getPageUrl('clientVendor', {
                        id: exactNameMatch.id,
                      })
                    },
                  },
                  {
                    color: 'primary',
                    children: needLinkToGlobalData
                      ? 'Yes, please link to global data'
                      : 'Yes',
                    onClick: () => {
                      if (needLinkToGlobalData) {
                        linkToGlobalData(exactNameMatch, domain)
                      }
                      closeDialog()
                    },
                  },
                ]
                if (isArchived(exactNameMatch)) {
                  buttons.unshift({
                    children: `No, add a new ${LabelTypes.VENDOR.toLowerCase()} anyway`,
                    onClick: () => {
                      createVendor()
                      closeDialog()
                    },
                  })
                }
                openDialog({
                  title: `We noticed that you already have ${
                    isArchived(exactNameMatch) ? 'an archived' : 'a'
                  } ${LabelTypes.VENDOR.toLowerCase()} named:`,
                  children: (
                    <div>
                      <h4>{exactNameMatch.name}</h4>
                      <h4>
                        Is this the same {LabelTypes.VENDOR.toLowerCase()}?
                      </h4>
                    </div>
                  ),
                  buttons,
                })
              } else if (common.length > 0) {
                // Scenario 2: Already have a vendor with similar name with link
                const matchedVendor = findBestMatchedVendor(common)
                openConfirm({
                  title: `We noticed that you already have ${
                    isArchived(matchedVendor) ? 'an archived' : 'a'
                  } ${LabelTypes.VENDOR.toLowerCase()} named:`,
                  body: <h4>{matchedVendor.name}</h4>,
                  cancelText: 'Nevermind',
                  confirmText: `Add a new ${LabelTypes.VENDOR.toLowerCase()} anyway`,
                  onConfirm: () => createVendor(),
                })
              } else if (nameMatches && nameMatches.length > 0) {
                // Scenario 3: Already have a vendor with similar name with no link
                const matchedVendor = findBestMatchedVendor(nameMatches)
                openDialog({
                  title: `We noticed that you already have ${
                    isArchived(matchedVendor) ? 'an archived' : 'a'
                  } ${LabelTypes.VENDOR.toLowerCase()} with a similar name.`,
                  children: (
                    <div>
                      <h4>
                        Is this the same {LabelTypes.VENDOR.toLowerCase()}?
                      </h4>
                      <h4>{matchedVendor.name}</h4>
                    </div>
                  ),
                  buttons: [
                    {
                      children: `No, add a new ${LabelTypes.VENDOR.toLowerCase()} anyway`,
                      onClick: () => {
                        createVendor()
                        closeDialog()
                      },
                    },
                    {
                      color: 'primary',
                      children: domain
                        ? 'Yes, please link to global data'
                        : 'Yes',
                      onClick: () => {
                        if (domain) {
                          linkToGlobalData(matchedVendor, domain)
                        }
                        closeDialog()
                      },
                    },
                  ],
                })
              } else if (domainMatches && domainMatches.length > 0) {
                // Scenario 4: Have another vendor named something else linked to the given domain
                const matchedVendor = findBestMatchedVendor(domainMatches)
                openConfirm({
                  title: `We noticed that you already have at least one ${
                    isArchived(matchedVendor) ? 'archived' : ''
                  } ${LabelTypes.VENDOR.toLowerCase()} linked to ${domain}.`,
                  body: (
                    <div>
                      If you create another vendor, any assessment data from
                      these two {LabelTypes.VENDORS.toLowerCase()} will be
                      separate.
                    </div>
                  ),
                  confirmText: `Add a new ${LabelTypes.VENDOR.toLowerCase()} anyway`,
                  cancelText: 'Nevermind',
                  onConfirm: () => createVendor(),
                })
              } else {
                createVendor()
              }
            })
            .catch(() => {
              hideLoader(loaderId)
              createVendor()
            })
        },
      })
    },
    [
      openConfirm,
      openDialog,
      closeDialog,
      showLoader,
      hideLoader,
      openCreateVendorModal,
      closeCreateVendorModal,
    ],
  )
}
