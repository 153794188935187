import { useCallback } from 'react'
import axios from 'axios'
import { useCurrent } from 'hooks'
import NotificationManager from 'lib/notifications'
import { trackError } from 'lib/error_tracker'
import { takeLatest } from 'utils/api'
import { updateVendorAssessmentElementResponse } from 'apis'
import { ChangeScopes } from './constants'
import { useSetElement } from './useAssessmentElementsFetcher'

const apiUpdateElementResponse = takeLatest(
  updateVendorAssessmentElementResponse,
  {
    groupIdentity: (args) => args[1],
  },
)

export default function useUpdateElementResponse(
  assessmentId,
  selectedSectionId,
  socketId,
  updateStatesFromElementResponse,
) {
  const setElement = useSetElement(assessmentId)
  const selectedSectionIdRef = useCurrent(selectedSectionId)

  return useCallback(
    (elementId, response) => {
      // Let's update UI first and call api
      setElement(elementId, (current) => ({
        ...current,
        response: {
          ...current.response,
          ...response,
        },
      }))

      // Save selectedSectionId state
      const isSectionScope = !!selectedSectionIdRef.current
      apiUpdateElementResponse(assessmentId, elementId, {
        response,
        socket_id: socketId,
        scope: isSectionScope ? ChangeScopes.SECTION : undefined,
      })
        .then(({ data }) =>
          updateStatesFromElementResponse(
            data,
            isSectionScope && data.section_id,
          ),
        )
        .catch((e) => {
          if (!axios.isCancel(e)) {
            NotificationManager.error(
              'There was an error saving your response. Please check your connection and try again later.',
            )
            trackError(e, e.response)
          }
        })
    },
    [assessmentId, socketId, setElement, updateStatesFromElementResponse],
  )
}
