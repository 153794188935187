import styled, { keyframes } from 'styled-components'
import { Spinner } from '@styled-icons/fa-solid/Spinner'
export const Loading = styled.div`
  display: flex;
  align-items: center;
`

export const Message = styled.h5`
  margin-left: 5px;
`

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
export const SpinningIcon = styled(Spinner)`
  animation: 2s linear ${spin} infinite;
`
