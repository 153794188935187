import merge from 'lodash/merge'
import forOwn from 'lodash/forOwn'
import { flatten } from 'q-flat'

// create a form via dom and submit
// Submit a hidden form.
// submit("/some-url", {
//   method: "POST",
//   body: { foo: 'bar' }
// });
export function submitForm(action, opts = {}) {
  const body = merge({}, flatten(opts.body), getCSRF())

  const { form, button } = buildForm(body)
  form.setAttribute('action', action || window.location.href)
  form.setAttribute('method', (opts.method || 'GET').toUpperCase())
  form.setAttribute(
    'enctype',
    opts.enctype || form.enctype || 'application/x-www-form-urlencoded',
  )
  form.setAttribute('target', opts.target || form.target || '_self')

  setTimeout(() => {
    button.click()
    document.body.removeChild(form)
  }, 0)
}

function buildForm(body) {
  // Form used to submit requests.
  const form = document.createElement('form')
  const button = document.createElement('input')
  button.type = 'submit'
  form.style.display = 'none'
  form.appendChild(button)
  forOwn(body, (value, key) => {
    if (value != null) {
      const input = form.appendChild(document.createElement('textarea'))
      input.name = key
      input.value = body[key]
    }
  })
  document.body.appendChild(form)
  return { form, button }
}

function getCSRF() {
  const csrfParamNode = document.querySelector('meta[name=csrf-param]')
  const csrfTokenNode = document.querySelector('meta[name=csrf-token]')
  if (csrfParamNode && csrfTokenNode) {
    return { [csrfParamNode.content]: csrfTokenNode.content }
  }
  return {}
}
