import React, { useMemo, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import reduce from 'lodash/reduce'
import { LabelTypes } from 'constants/index'
import TreeMenu from './TreeMenu'
import StepForm from './StepForm'
import { getStepsData } from './constants'
import { StyledDialog, DialogHeader, Body, Sidebar, Content } from './styles'

function RequestVendorModal({
  vendorRequestConfig,
  riskTierQuestions,
  customFieldDefinitions,
  disableSubmit,
  onSubmit,
  onClose,
  ...rest
}) {
  const [values, setValues] = useState({})
  const [currentStepKey, setCurrentStepKey] = useState('vendor_basics')
  const stepsData = useMemo(
    () =>
      getStepsData({
        vendorRequestConfig,
        riskTierQuestions,
        customFieldDefinitions,
      }),
    [vendorRequestConfig, riskTierQuestions, customFieldDefinitions],
  )

  const flattenStepsData = useMemo(() => {
    function process(data, parentKey) {
      return reduce(
        data,
        (result, { nodes, ...restR }, childKey) => {
          const key = parentKey ? `${parentKey}/${childKey}` : childKey
          let newResult = {
            ...result,
          }
          if (nodes) {
            newResult = {
              ...result,
              ...process(nodes, key),
            }
          } else {
            newResult[key] = restR
          }
          return newResult
        },
        {},
      )
    }
    return process(stepsData)
  }, [stepsData])

  const sortedStepKeys = useMemo(
    () => Object.keys(flattenStepsData),
    [flattenStepsData],
  )

  const stepData = flattenStepsData[currentStepKey]
  const isFirst = sortedStepKeys[0] === currentStepKey
  const isLast = sortedStepKeys[sortedStepKeys.length - 1] === currentStepKey

  const handleNext = useCallback(() => {
    setCurrentStepKey((newKey) => {
      const currentIndex = sortedStepKeys.indexOf(newKey)
      return sortedStepKeys[
        Math.min(currentIndex + 1, sortedStepKeys.length - 1)
      ]
    })
  }, [sortedStepKeys])

  const handleBack = useCallback(() => {
    setCurrentStepKey((newKey) => {
      const currentIndex = sortedStepKeys.indexOf(newKey)
      return sortedStepKeys[Math.max(currentIndex - 1, 0)]
    })
  }, [sortedStepKeys])

  const handleSubmit = useCallback(
    (formData, extra) => {
      const newValues = {
        ...values,
        ...formData,
      }
      setValues(newValues)

      if (isLast) {
        return onSubmit(newValues, extra)
      }
      handleNext()
      return Promise.resolve()
    },
    [values, isLast, handleNext],
  )

  return (
    <StyledDialog {...rest} onClose={onClose}>
      <DialogHeader onClose={onClose}>
        New {LabelTypes.VENDOR} Request
      </DialogHeader>
      <Body>
        <Sidebar>
          <TreeMenu data={stepsData} activeKey={currentStepKey} />
        </Sidebar>
        <Content>
          {stepData && (
            <StepForm
              key={currentStepKey}
              {...stepData}
              values={values}
              isFirst={isFirst}
              isLast={isLast}
              disableSubmit={disableSubmit}
              onBack={handleBack}
              onSkip={handleNext}
              onSubmit={handleSubmit}
            />
          )}
        </Content>
      </Body>
    </StyledDialog>
  )
}

RequestVendorModal.propTypes = {
  vendorRequestConfig: PropTypes.object.isRequired,
  customFieldDefinitions: PropTypes.shape({
    groups: PropTypes.object,
    field_definitions: PropTypes.array,
  }),
  riskTierQuestions: PropTypes.array,
  disableSubmit: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default RequestVendorModal
