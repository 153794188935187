import styled, { css } from 'styled-components'
import Icon from 'components-v2/atoms/Icon'
import { FileAlt } from '@styled-icons/fa-regular/FileAlt'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-transform: uppercase;
  color: #ccc;

  ${(props) =>
    props.color &&
    css`
      color: #fff;
      background: ${props.color};
    `}
`

export const NoteIcon = styled(Icon).attrs({ icon: FileAlt })``
