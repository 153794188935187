import React from 'react'
import sortBy from 'lodash/sortBy'
import { withReactQuery } from 'hocs'
import { useClientOrganizationPartnersFetcher } from 'apis'
import View from './View'
import useEditPartner from './useEditPartner'

const Container = () => {
  const { data: partners, isFetching } = useClientOrganizationPartnersFetcher()
  const [searchText, setSearchText] = React.useState('')
  const editPartner = useEditPartner()

  const sortedPartners = React.useMemo(
    () => sortBy(partners, 'type'),
    [partners],
  )

  return (
    <View
      loading={isFetching}
      partners={sortedPartners}
      searchText={searchText}
      onSearchChange={setSearchText}
      onEditPartner={editPartner}
    />
  )
}

export default withReactQuery()(Container)
