import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Transition } from '@headlessui/react'
import Modal from '../Modal'
import ToolbarGroup from '../ToolbarGroup'

import {
  Inner,
  Backdrop,
  Panel,
  DrawerTitle,
  DrawerContent,
  DrawerHeaderText,
  DrawerCloseButton,
} from './styles'

const DrawerHeader = ({ title, children, onClose, ...rest }) => (
  <DrawerTitle {...rest}>
    <DrawerHeaderText>{title}</DrawerHeaderText>
    <ToolbarGroup>
      {children}
      {onClose && <DrawerCloseButton onClick={onClose} />}
    </ToolbarGroup>
  </DrawerTitle>
)

DrawerHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.node,
}

const Drawer = ({ children, ...rest }) => (
  <Modal {...rest}>
    <Inner>
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-out duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Backdrop />
      </Transition.Child>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="ease-out duration-300"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <Panel>{children}</Panel>
      </Transition.Child>
    </Inner>
  </Modal>
)

Drawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
}

Drawer.Header = DrawerHeader

Drawer.Content = DrawerContent

export default Drawer
