import React from 'react'
import PropTypes from 'prop-types'
import Privva from 'Privva'
import Button from 'components-v2/atoms/Button'
import { LabelTypes } from 'constants/index'
import {
  Wrap,
  Inner,
  Heading,
  Description,
  Content,
  ButtonsWrapper,
} from './styles'

const WelcomeCard = ({ newVendorUrl, newProjectUrl, ...rest }) => (
  <Wrap {...rest}>
    <Inner>
      <Content>
        <Heading>Welcome to {Privva.Utils.brand.programName}.</Heading>
        <Description>
          Let’s get started with a new project or adding a{' '}
          {LabelTypes.VENDOR.toLowerCase()}.
        </Description>
      </Content>
      <ButtonsWrapper>
        <Button
          href={newVendorUrl}
        >{`Add a new ${LabelTypes.VENDOR.toLowerCase()}`}</Button>
        <Button color="primary" href={newProjectUrl}>
          Start new project
        </Button>
      </ButtonsWrapper>
    </Inner>
  </Wrap>
)

WelcomeCard.propTypes = {
  newVendorUrl: PropTypes.string.isRequired,
  newProjectUrl: PropTypes.string.isRequired,
}

export default WelcomeCard
