import React from 'react'
import truncate from 'lodash/truncate'
import Button from 'components-v2/atoms/Button'
import Tooltip from 'components-v2/molecules/Tooltip'
import { LabelTypes } from 'constants/index'

export const nameFormatterFactory = () => (info) => {
  const existingDocumentUrl =
    // eslint-disable-next-line camelcase
    info.row.original?.cva?.existing_document_url ||
    info.row.original?.existing_document_url
  if (!info.row.original && !existingDocumentUrl) return null

  const url =
    // eslint-disable-next-line camelcase
    info.row.original?.url ||
    info.row.original?.attachment?.url ||
    info.row.original?.cva?.existing_document_url
  if (!info.row.original.name) {
    // for document link only rows
    if (!url) return null
  }
  const name =
    info.row.original?.fullname ||
    info.row.original?.attachment?.fullname ||
    info.row.original?.attachment?.name ||
    existingDocumentUrl.substring(existingDocumentUrl.lastIndexOf('/') + 1) ||
    existingDocumentUrl
  const truncatedName = info.row.original.name || truncate(name, { length: 20 })
  if (truncatedName.length < name.length)
    return (
      <Tooltip
        parent={
          <a target="_blank" href={url}>
            {truncatedName}
          </a>
        }
      >
        {name}
      </Tooltip>
    )
  return (
    <a target="_blank" href={url}>
      {name}
    </a>
  )
}

export const sourceFormatterFactory = () => (info) => {
  const label = info.row.original.source_label.replace(
    'Vendor ',
    `${LabelTypes.VENDOR} `,
  )
  let path = info.row.original.source_path
  if (info.row.original.source_type === 'ClientVendorAttachment') {
    path += '?tab=files'
  }
  if (!path) {
    return label
  }
  return <a href={path}>{label}</a>
}

export const linkFormatterFactory = () => (info) => {
  const [path, label] = [info.row.original.path, info.row.original.label]
  return <a href={path}>{label}</a>
}

export const vendorSourceFormatterFactory = () => (info) => {
  const label =
    info.row.original?.cva?.vendor?.name || info.row.original?.vendor?.name
  const path =
    info.row.original?.cva?.vendor?.path || info.row.original?.vendor?.path
  if (!path) {
    return label
  }
  return <a href={path}>{label}</a>
}

export const nameSortFunc = (rowA, rowB) => {
  const nameA = rowA.attachment
    ? rowA.attachment.name
    : rowA.existing_document_url
  const nameB = rowB.attachment
    ? rowB.attachment.name
    : rowB.existing_document_url
  return nameA < nameB ? -1 : nameB < nameA ? 1 : 0
}

export const actionsFormatterFactory =
  (canUpdate, canDelete, onEdit, onDelete) => (info) => {
    if (!info.row.original.has_vendor || !(canUpdate || canDelete)) {
      return null
    }
    return (
      <div>
        {canUpdate && (
          <Button
            variant="text"
            color="primary"
            onClick={() => onEdit(info.row.original.cva.id)}
          >
            <i className="fa fa-edit" />
            Edit
          </Button>
        )}
        {canDelete && (
          <div>
            <Button
              variant="text"
              color="primary"
              onClick={() => onDelete(info.row.original.cva.id)}
            >
              <i className="fa fa-trash" />
              Delete
            </Button>
          </div>
        )}
      </div>
    )
  }

export const commentFormatterFactory = () => (info) => {
  const maxLength = 100
  if (info.getValue()?.length > maxLength)
    return (
      <Tooltip
        parent={<p>{truncate(info.getValue(), { length: maxLength })}</p>}
      >
        {info.getValue()}
      </Tooltip>
    )
  return <p>{info.getValue()}</p>
}
