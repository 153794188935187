export const ClientHumanizedStatusTypes = {
  IN_PROCESS: 'In Process',
}

export const MoveOrCopyActionTypes = {
  MOVE: 'move',
  COPY: 'copy',
}

export const QuestionPlacementTypes = {
  TOP_OF_SECTION: 'top_of_section',
  END_OF_SECTION: 'end_of_section',
  BEFORE_QUESTION: 'before_question',
  AFTER_QUESTION: 'after_question',
}

export const SectionPlacementTypes = {
  BEFORE: 'before',
  AFTER: 'after',
}
