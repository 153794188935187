import styled from 'styled-components'
import { Col } from 'components-v2/organisms/Layout'
import { colors, media } from 'styles'

export const Set = styled.div`
  margin-bottom: 40px;
`

export const SetTop = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 14px;
  border-bottom: 1px solid ${colors.MED_GREY};
  margin-bottom: 14px;
`

export const SetTitle = styled.h3`
  color: ${colors.HEADING};
  margin: 0;
`

export const SetStatus = styled.div`
  margin-left: 10px;
  font-size: 18px;
`

export const SetDate = styled.div`
  margin-bottom: 20px;
  font-size: 18px;
`

export const StyledCol = styled(Col).attrs({
  sm: 4,
  xs: 12,
})`
  ${media.desktop`
    width: 20%;
  `}
`
