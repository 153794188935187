import React from 'react'
import PropTypes from 'prop-types'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'
import ProgressBar from 'components-v2/molecules/ProgressBar'
import Card from 'components-v2/organisms/Card'
import { toFromNowString } from 'utils/date'
import StatusPieChart from '../../StatusPieChart'
import { RISK_HEALTH_SCORE_MAX } from '../constants'
import { getRiskHealthScoreColor } from '../../utils'
import {
  Top,
  Bottom,
  Heading,
  Header,
  UpdatedAt,
  ObjectiveList,
  ObjectiveItem,
  ObjectiveName,
} from './styles'

const parseRawStatusCounts = (data) => mapValues(keyBy(data, 'status'), 'value')

const SummaryCard = ({
  collection: { objectives, status_counts: statusCounts, score },
  updatedAt,
  ...rest
}) => (
  <Card {...rest}>
    <Top>
      <Header>
        <Heading>Current status</Heading>
        {updatedAt && (
          <UpdatedAt>
            Updated {toFromNowString(updatedAt, { addSuffix: true })}
          </UpdatedAt>
        )}
      </Header>
      <StatusPieChart
        countByStatus={parseRawStatusCounts(statusCounts)}
        riskRating={score}
      />
    </Top>
    <Bottom>
      <Heading>Objective</Heading>
      <ObjectiveList>
        {map(objectives, (objective) => (
          <ObjectiveItem key={objective.name}>
            <ObjectiveName
              disabled={!objective.enabled}
              // TODO centralize url
              href={`/cme/vendors/1/objectives/${objective.id}`}
            >
              {objective.name}
            </ObjectiveName>
            <ProgressBar
              now={objective.average_score}
              max={RISK_HEALTH_SCORE_MAX}
              color={getRiskHealthScoreColor(objective.average_score)}
            />
          </ObjectiveItem>
        ))}
      </ObjectiveList>
    </Bottom>
  </Card>
)

SummaryCard.propTypes = {
  collection: PropTypes.object.isRequired,
  updatedAt: PropTypes.any,
}

export default React.memo(SummaryCard)
