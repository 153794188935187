import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useClientVendorProjectsFetcher } from 'apis'
import View from './View'

const ProjectListForVendorViewContainer = ({ vendorId }) => {
  const [searchText, setSearchText] = useState('')

  const { data: projects, isFetching } = useClientVendorProjectsFetcher({
    vendorId,
  })

  return (
    <View
      projects={projects}
      loading={isFetching}
      searchText={searchText}
      onSearch={setSearchText}
    />
  )
}

ProjectListForVendorViewContainer.propTypes = {
  vendorId: PropTypes.string.isRequired,
}

export default ProjectListForVendorViewContainer
