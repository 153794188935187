import styled from 'styled-components'

export const Wrapper = styled.div`
  color: #333;
  display: flex;
  background: #eee;

  > * {
    padding: 8px;
    font-size: 120%;
    font-weight: bold;
  }

  body.grey-bg & {
    background: #d4d4d4;
  }
`

export const Key = styled.span`
  flex: none;
  width: ${(p) => (p.customKey ? 110 : 52)}px;
`

export const Label = styled.span`
  flex: 1;
`
