import styled from 'styled-components'
import { Col } from 'components-v2/organisms/Layout'
import { media } from 'styles'

export const theme = {
  hGutter: 30,
  vGutter: 24,
}

export const Content = styled.div`
  min-height: 400px;
`

export const ColHeader = styled.h5`
  border-bottom: 2px solid ${({ color }) => color};
  line-height: 1.7;
  margin-top: 0;
  margin-bottom: 20px;
`

export const CardList = styled.div`
  & > * {
    margin-bottom: 22px;
  }
`

export const StyledCol = styled(Col).attrs({ md: 3 })`
  ${({ $colCount }) => media.ntablet`
    width: ${100 / $colCount}%;
  `}
`
