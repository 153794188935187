import config from 'config'
import { SortOrderTypes } from 'constants/index'

// not sure if there may be additional action menu options at some point
export const ActionMenuItemTypes = {
  EXPORT_ALL_CSV: 'export_all_csv',
  EXPORT_CURRENT_VIEW_CSV: 'export_current_view_csv',
}

export const archiveOptions = [
  {
    value: 'projects',
    label: 'Projects',
  },
  {
    value: 'vendors',
    label: 'Vendors',
  },
]

export const queryParamsConfig = {
  page: {
    type: Number,
    defaultValue: 1,
  },
  sizePerPage: {
    queryKey: 'per_page',
    type: Number,
    defaultValue: config.collectionQueryPageLimit.default,
  },
  sortField: {
    queryKey: 'ordered_by',
    defaultValue: 'vendor.name',
  },
  sortOrder: {
    queryKey: 'direction',
    defaultValue: SortOrderTypes.ASC,
  },
  searchText: {
    queryKey: 'q',
    defaultValue: '',
  },
  statuses: {
    queryKey: 'status',
    defaultValue: [],
    isArray: true,
  },
  assignees: {
    queryKey: 'assignee',
    defaultValue: [],
    isArray: true,
  },
  vendorTags: {
    queryKey: 'vendor_tag',
    defaultValue: [],
    isArray: true,
  },
  vendorTagsAnyMatch: {
    queryKey: 'vendor_tags_any_match',
    type: Boolean,
    defaultValue: true,
  },
  archiveValues: {
    queryKey: 'include_archived',
    defaultValue: [],
    isArray: true,
  },
}
