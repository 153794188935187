import styled from 'styled-components'
import { fonts, colors } from 'styles'

const colorNameMap = {
  primary: colors.PRIMARY,
  success: colors.GREEN,
  info: colors.BLUE,
  warning: colors.YELLOW,
  danger: colors.RED,
  default: colors.MED_DARK_GREY,
}

export default styled.span`
  display: inline-block;
  background: ${({ color }) =>
    colorNameMap[color] ?? color ?? colors.MED_DARK_GREY};
  font-family: ${fonts.SECONDARY};
  font-size: 75%;
  font-weight: 700;
  padding: 0 0.5em;
  line-height: 2.1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
`
