import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Trend, CaretIcon } from './styles'

const Metric = ({ trend, trendColor, children, ...rest }) => (
  <Wrapper {...rest}>
    {children}
    {trend ? (
      <Trend color={trendColor}>
        <CaretIcon positive={trend > 0} />
        {Math.abs(trend)}
      </Trend>
    ) : null}
  </Wrapper>
)

Metric.propTypes = {
  trend: PropTypes.number,
  trendColor: PropTypes.string,
  children: PropTypes.node,
}

export default Metric
