import styled, { css, keyframes } from 'styled-components'
import { colors } from 'styles'

export const Wrapper = styled.div`
  overflow: hidden;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.05);
  height: ${(props) => props.$height}px;
`

const stripes = keyframes`
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
`

export const Single = styled.div`
  position: relative;
  float: left;
  width: 0%;
  min-width: 0;
  height: 100%;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: grey;
  text-indent: 1px;
  transition: width 2s;

  &:after {
    content: attr(data-label);
    display: block;
    color: white;
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    bottom: 0;
    overflow: hidden;
  }

  ${(props) => css`
    background-color: ${props.$color || colors.PRIMARY};
    width: ${props.$percentage}%;
    ${props.$striped &&
    `
      background-image: linear-gradient(45deg,hsla(0,0%,100%,.15) 25%,transparent 0,transparent 50%,hsla(0,0%,100%,.15) 0,hsla(0,0%,100%,.15) 75%,transparent 0,transparent);
      background-size: 40px 40px;
    `}
    ${props.$active &&
    css`
      animation: ${stripes} 2s linear infinite;
    `}
  `}
`
