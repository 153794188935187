import React from 'react'
import Chart from 'react-google-charts'
import { chartColors } from 'styles'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import Loader from 'components-v2/molecules/Loader'
import { ChartWrapper } from './styles'

const columnChartOptions = {
  chartArea: {
    left: 45,
    top: 10,
    width: '90%',
    height: '85%',
  },
  bar: { groupWidth: '94%' },
  legend: { position: 'none' },
  hAxis: { textStyle: { fontSize: 14 } },
  tooltip: { textStyle: { fontSize: 13, bold: true } },
}

const COLORS = chartColors.DEFAULT
const getChartData = (chartData) => {
  const data = [['Status', 'Average', { role: 'style' }]]
  ;['Time to Start', 'Time to Submit', 'Time to Review'].forEach((cat, i) => {
    data.push([cat.titleize(), chartData[`${cat}_avg`], COLORS[i]])
  })
  return data
}

const StatusChartWidget = ({ chartData }) => (
  <ChartWrapper>
    {!isEmpty(chartData) && (
      <Chart
        width="100%"
        height="340px"
        chartType="ColumnChart"
        loader={<Loader>Loading Chart...</Loader>}
        data={getChartData(chartData)}
        options={columnChartOptions}
      />
    )}
  </ChartWrapper>
)

StatusChartWidget.propTypes = {
  chartData: PropTypes.object,
}

export default StatusChartWidget
