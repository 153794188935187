import FilterOptionTypes from 'constants/filter_option_types'

export const ElementFilterOptions = {
  ANSWERED: {
    key: 'ElementFilter.ANSWERED',
    type: FilterOptionTypes.SINGLE,
    label: 'Answered',
  },
  UNANSWERED: {
    key: 'ElementFilter.UNANSWERED',
    type: FilterOptionTypes.SINGLE,
    label: 'Unanswered',
  },
  INCOMPLETE: {
    key: 'ElementFilter.INCOMPLETE',
    type: FilterOptionTypes.SINGLE,
    label: 'Incomplete',
  },
}

export const ChangeScopes = {
  SECTION: 'section',
  ASSESSMENT: 'assessment',
}

export const STICKY_HEADER_HEIGHT = 154
export const ASSESSMENT_TABLE_ID_PREFIX = 'table_'

export const highlightTimeout = {
  enter: 100,
  exit: 2500,
}
