// HeadlessUI Popover component with the following changes;
// 1. Floating UI + Branded styles
// 2. Focus Trap strategy inside Popover Panel
// Let's not change HeadlessUI Popover's composition logic to allow us to compose its subcomponents and make a new thing as we want later.
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Popover as BasePopover } from '@headlessui/react'
import { Float } from '@headlessui-float/react'
import PopoverButton from './PopoverButton'
import PopoverPanel from './PopoverPanel'

const Wrapper = styled(BasePopover)`
  display: inline-block;
`

const Popover = ({
  placement,
  offset,
  portal,
  arrow,
  shift,
  zIndex,
  children,
  ...rest
}) => (
  <Wrapper {...rest}>
    {(renderProps) => (
      <Float
        composable
        placement={placement}
        offset={offset}
        arrow={arrow}
        portal={portal}
        shift={shift}
        zIndex={zIndex}
      >
        {/* Hacky solution to prevent a weird error from Float component */}
        {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
        <></>
        {typeof children === 'function' ? children(renderProps) : children}
      </Float>
    )}
  </Wrapper>
)

Popover.propTypes = {
  placement: PropTypes.string,
  offset: PropTypes.number,
  portal: PropTypes.bool,
  arrow: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  shift: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  zIndex: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
}

Popover.defaultProps = {
  placement: 'bottom',
  offset: 12,
  arrow: 16,
  shift: 16,
  zIndex: 1032,
}

Popover.Button = PopoverButton
Popover.Panel = PopoverPanel
Popover.Overlay = styled(BasePopover.Overlay)`
  position: fixed;
  inset: 0;
`

export default Popover
