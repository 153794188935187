import styled from 'styled-components'

export const Description = styled.div`
  white-space: pre-wrap;
  margin-bottom: 22px;
`

export const StatusBreadcrumbItem = styled.li`
  color: white;
  padding: 6px 0px 6px 40px;
  position: relative;
  display: inline-block;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || 'hsla(0, 0%, 83%, 1)'};
  text-align: left;

  &:before {
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 30px solid white;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    margin-left: 1px;
    left: 100%;
    z-index: 1;
  }

  &:after {
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 30px solid
      ${({ backgroundColor }) => backgroundColor || 'hsla(0, 0%, 83%, 1)'};
    position: absolute;
    top: 50%;
    margin-top: -50px;
    left: 100%;
    z-index: 2;
  }

  &:first-child {
    padding-left: 15px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-of-type {
    padding-right: 15px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    &:before,
    &:after {
      content: none;
    }
  }
`

export const StatusBreadcrumb = styled.ul`
  padding: 0px;
  list-style: none;
  overflow: hidden;
  display: inline-block;
  font-size: 12px;
  margin-bottom: 40px;
`
