import styled from 'styled-components'
import IconButton from 'components-v2/atoms/IconButton'

export const TableWrapper = styled.div`
  margin-top: 28px;
`

export const EllipsisIconButton = styled(IconButton).attrs({
  icon: 'fa fa-ellipsis-h',
})``
