import React from 'react'
import PropTypes from 'prop-types'
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts'
import Loader from 'components-v2/molecules/Loader'
import { LabelTypes } from 'constants/index'
import { chartColors, colors } from 'styles'

const ComposedChartWidget = ({ chartData }) => (
  <ResponsiveContainer height={340} width="100%">
    <BarChart
      data={chartData}
      loader={<Loader>Loading Chart...</Loader>}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis
        label={{
          value: LabelTypes.VENDORS,
          angle: -90,
          position: 'insideLeft',
        }}
      />
      <Tooltip />
      <Legend />
      <Bar
        dataKey="None"
        stackId="bar"
        fill={chartColors.DEFAULT[5]}
        fillOpacity={0.6}
      />
      <Bar
        dataKey="Passed"
        stackId="bar"
        fill={colors.GREEN}
        fillOpacity={0.6}
      />
      <Bar dataKey="Failed" stackId="bar" fill={colors.RED} fillOpacity={0.6} />
    </BarChart>
  </ResponsiveContainer>
)

ComposedChartWidget.propTypes = {
  chartData: PropTypes.array,
}

export default ComposedChartWidget
