import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'components-v2/organisms/Layout'

const ErrorView = ({ imageUrl, heading, children }) => (
  <Row>
    <Col md={4}>
      <img src={imageUrl} alt="" />
    </Col>
    <Col md={8}>
      <h1>{heading}</h1>
      {children}
    </Col>
  </Row>
)

ErrorView.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  heading: PropTypes.node,
  children: PropTypes.node,
}

export default ErrorView
