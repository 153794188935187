import { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'
import mergeWith from 'lodash/mergeWith'
import { useGlobalLoader } from 'hooks'
import NotificationManager from 'lib/notifications'
import {
  useUpdateClientAssessmentQuestionAutomations,
  useClientQuestionAutomationsFetcher,
} from 'apis'

export default function useTransitionQuestionAutomations(
  currentOrganization,
  assessmentId,
) {
  const queryClient = useQueryClient()
  const [showLoader, hideLoader] = useGlobalLoader()
  const { mutateAsync: updateClientAssessmentQuestionAutomations } =
    useUpdateClientAssessmentQuestionAutomations()

  return useCallback(
    (autoIdList, transition) => {
      const loaderId = showLoader()
      updateClientAssessmentQuestionAutomations({
        data: {
          client_id: currentOrganization.id,
          ids: autoIdList,
          transition,
        },
      })
        .then((response) => {
          queryClient.setQueriesData(
            {
              predicate: (query) =>
                query.queryKey[0] ===
                  useClientQuestionAutomationsFetcher.queryKey()[0] &&
                query.queryKey[1].params.assessment_id === assessmentId,
            },
            (current) => {
              const updatedIdx = keyBy(response, 'question_automation_id')
              const mappedIdx = mapValues(updatedIdx, (v) => ({
                // eslint-disable-next-line camelcase
                assessment_question_automation: v,
                status: v.status,
              }))
              return mergeWith(
                { ...current },
                mappedIdx,
                (destValue, srcValue) => ({
                  ...destValue,
                  ...srcValue,
                }),
              )
            },
          )
          NotificationManager.success(
            `Successfully created ${autoIdList.length} issues.`,
          )
        })
        .catch(() => NotificationManager.error())
        .then(() => hideLoader(loaderId))
    },
    [
      updateClientAssessmentQuestionAutomations,
      showLoader,
      hideLoader,
      queryClient,
      currentOrganization,
      assessmentId,
    ],
  )
}
