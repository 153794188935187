import React from 'react'
import Privva from 'Privva'
import * as yup from 'yup'
import map from 'lodash/map'
import concat from 'lodash/concat'
import keyBy from 'lodash/keyBy'
import FormField from 'components-v2/molecules/FormField'
import SelectField from 'components-v2/molecules/FormField/SelectField'
import VendorRiskTierFormField from 'components/client/shared/VendorRiskTierFormField'
import { convertStringArrayToOptions } from 'utils/array'
import { LabelTypes } from 'constants/index'
import { contactSchema, contributorSchema } from 'schemas'
import AdditionalInfoDisplay from './FieldRenderers/AdditionalInfoDisplay'
import PublicProfileDisplay from './FieldRenderers/PublicProfileDisplay'
import ParentOrgDisplay from './FieldRenderers/ParentOrgDisplay'
import ChildOrgDisplay from './FieldRenderers/ChildOrgDisplay'
import ContractValueDisplay from './FieldRenderers/ContractValueDisplay'
import ContractValueFieldGroup from './FieldRenderers/ContractValueFieldGroup'
import ContactsFieldGroup from './FieldRenderers/ContactsFieldGroup'
import ContactsDisplay from './FieldRenderers/ContactsDisplay'
import CountryField from './FieldRenderers/CountryField'
import NameDisplay from './FieldRenderers/NameDisplay'
import ContributorsDisplay from './FieldRenderers/ContributorsDisplay'
import ContributorsFieldGroup from './FieldRenderers/ContributorsFieldGroup'

export const VendorFieldGroupIdTypes = {
  GENERAL_INFO: 'general_info',
  PRIMARY_CONTRIBUTOR: 'primary_contributor',
  CONTRIBUTORS: 'contributors',
  CONTACT_LIST: 'contact_list',
  MAIN_ADDRESS: 'main_address',
  ORGANIZATION_DETAILS: 'organization_details',
  DATA_SOURCES: 'data_sources',
  ASSESSMENT_CATEGORIES: 'assessment_categories',
  OTHER: 'other',
  CUSTOM_FIELD_DATA: 'custom_field_data',
}

export const VendorFieldNameTypes = {
  NAME: 'name',
  PARENT_ORG: 'parent_org_id',
  CHILD_ORGS: 'child_orgs',
  USERS: 'users',
  MAIN_CONTACT: 'main_contact',
  RISK_TIER: 'risk_tier',
  VENDOR_TAG_LIST: 'vendor_tag_list',
  RISK_TIER_CALCULATOR_ID: 'vendor_risk_tier_calculator_id',
  VENDOR_RISK_TIER_ANSWERS: 'vendor_risk_tier_answers',
  GLOBORG_MAPPING: 'globorg_mapping',
  VENDOR_CONTACTS: 'vendor_contacts',
}

const VendorFieldGroups = {
  [VendorFieldGroupIdTypes.GENERAL_INFO]: {
    label: 'General Info',
    fields: [
      {
        name: VendorFieldNameTypes.NAME,
        label: `${LabelTypes.VENDOR} Name`,
        placeholder: `${LabelTypes.VENDOR} name`,
        validation: yup.string().required(),
        valueRenderComponent: NameDisplay,
      },
      {
        name: VendorFieldNameTypes.PARENT_ORG,
        label: `Parent ${LabelTypes.VENDOR}`,
        type: FormField.types.SEARCHABLE_SELECT,
        controlProps: {
          isClearable: true,
        },
        valueRenderComponent: ParentOrgDisplay,
        visibilityGetter: ({ vendor, editing }) => {
          const isVendor = vendor.class !== 'VendorRequest'
          const hasNoChildren = vendor.child_orgs?.length === 0
          const hasParent = vendor.parent_org_id !== null
          if (editing) {
            return hasNoChildren
          }
          return isVendor && hasNoChildren && hasParent
        },
      },
      {
        name: VendorFieldNameTypes.CHILD_ORGS,
        label:
          LabelTypes.VENDOR === 'Vendor'
            ? 'Products'
            : `Child ${LabelTypes.VENDORS}`,
        valueRenderComponent: ChildOrgDisplay,
        visibilityGetter: ({ vendor, editing }) => {
          const isVendor = vendor.class !== 'VendorRequest'
          return isVendor && !editing && vendor.child_orgs?.length > 0
        },
      },
      {
        name: VendorFieldNameTypes.RISK_TIER,
        label: 'Risk Tier',
        component: VendorRiskTierFormField,
        formFieldValueFormatter: ({ value, vendor }) => ({
          calculatorId:
            vendor?.organization_detail?.[
              VendorFieldNameTypes.RISK_TIER_CALCULATOR_ID
            ],
          riskTier: value,
          riskTierAnswers:
            vendor?.organization_detail?.[
              VendorFieldNameTypes.VENDOR_RISK_TIER_ANSWERS
            ],
        }),
        visibilityGetter: ({ vendor }) => vendor.class !== 'VendorRequest',
      },
      {
        name: 'next_reassessment_at',
        label: 'Next Re-assessment Date',
        type: FormField.types.DATE,
        controlProps: {
          minDate: new Date(),
        },
        visibilityGetter: ({ vendor }) => vendor.class !== 'VendorRequest',
      },
      {
        name: VendorFieldNameTypes.VENDOR_TAG_LIST,
        label: 'Labels',
        type: FormField.types.CREATABLE_SELECT,
        controlProps: {
          isMulti: true,
          hideSelectedOptions: true,
          placeholder: 'Add...',
          noOptionsMessage: () => 'Add...',
        },
        visibilityGetter: ({ vendor }) => vendor.class !== 'VendorRequest',
      },
      {
        name: VendorFieldNameTypes.GLOBORG_MAPPING,
        label: 'Public profile',
        valueRenderComponent: PublicProfileDisplay,
        editable: false,
        visibilityGetter: ({ vendor }) => vendor.class !== 'VendorRequest',
      },
    ],
  },
  [VendorFieldGroupIdTypes.PRIMARY_CONTRIBUTOR]: {
    label: 'Primary Contributor',
    fields: [
      {
        name: VendorFieldNameTypes.MAIN_CONTACT,
        component: (props) => <SelectField {...props} />,
        validation: yup.string().required(),
        formFieldValueFormatter: ({ value }) => value?.id,
        visibilityGetter: ({ vendor }) => vendor.class !== 'VendorRequest',
      },
    ],
  },
  [VendorFieldGroupIdTypes.CONTRIBUTORS]: {
    label: 'Contributors',
    fields: [
      {
        name: VendorFieldNameTypes.USERS,
        valueRenderComponent: ContributorsDisplay,
        component: ContributorsFieldGroup,
        validation: yup.array().of(contributorSchema),
      },
    ],
  },
  [VendorFieldGroupIdTypes.ORGANIZATION_DETAILS]: {
    label: 'Organization Details',
    fields: [
      {
        name: 'vendor_contract_value',
        label: 'Contract Value',
        valueRenderComponent: ContractValueDisplay,
        component: ContractValueFieldGroup,
      },
      {
        name: 'vendor_solution_overview',
        label: 'Solution Overview',
        type: FormField.types.TEXTAREA,
      },
      {
        name: 'vendor_business_unit_manager',
        label: 'Business Unit Manager',
        type: FormField.types.CREATABLE_SELECT,
        controlProps: {
          isMulti: true,
          placeholder: 'Add...',
          noOptionsMessage: () => 'Add...',
        },
      },
      {
        name: 'vendor_intended_office_use',
        label: 'Intended Office Use / Division',
        validation: yup
          .string()
          .nullable()
          .max(5000, 'This is too long (maximum is 5000 characters)'),
      },
      {
        name: 'vendor_systems_accessed',
        label: `What systems will the ${LabelTypes.VENDOR.toLowerCase()} be accessing?`,
        type: FormField.types.CREATABLE_SELECT,
        controlProps: {
          isMulti: true,
          placeholder: 'Add...',
          noOptionsMessage: () => 'Add...',
        },
      },
      {
        name: 'vendor_systems_integrations',
        label: 'Does this solution integrate with other systems?',
        type: FormField.types.CREATABLE_SELECT,
        controlProps: {
          isMulti: true,
          placeholder: 'Add...',
          noOptionsMessage: () => 'Add...',
        },
      },
      {
        name: 'vendor_products_services',
        label: 'Product / Service',
        type: FormField.types.MULTI_CHECKBOX,
        options: convertStringArrayToOptions(
          Privva.Utils.vendor_details_options.vendorProductsServices,
        ),
      },
    ],
  },
  [VendorFieldGroupIdTypes.CONTACT_LIST]: {
    label: 'Business Contacts',
    fields: [
      {
        name: VendorFieldNameTypes.VENDOR_CONTACTS,
        component: ContactsFieldGroup,
        validation: yup.array().of(contactSchema),
        valueRenderComponent: ContactsDisplay,
      },
    ],
  },
  [VendorFieldGroupIdTypes.MAIN_ADDRESS]: {
    label: 'Main Address',
    fields: [
      {
        name: 'main_address_1',
        label: 'Address 1',
        validation: yup
          .string()
          .nullable()
          .max(255, 'This is too long (maximum is 255 characters)'),
      },
      {
        name: 'main_address_2',
        label: 'Address 2',
        validation: yup
          .string()
          .nullable()
          .max(255, 'This is too long (maximum is 255 characters)'),
      },
      {
        name: 'main_city',
        label: 'City',
        validation: yup
          .string()
          .nullable()
          .max(255, 'This is too long (maximum is 255 characters)'),
      },
      {
        name: 'main_state',
        label: 'State / Province / Region',
        validation: yup
          .string()
          .nullable()
          .max(255, 'This is too long (maximum is 255 characters)'),
      },
      {
        name: 'main_postal_code',
        label: 'ZIP / Postal Code',
        validation: yup
          .string()
          .nullable()
          .max(10, 'This is too long (maximum is 10 characters)'),
      },
      {
        name: 'main_country_code',
        label: 'Country',
        component: CountryField,
        validation: yup
          .string()
          .nullable()
          .max(2, 'This is too long (maximum is 2 characters)'),
      },
    ],
  },
  [VendorFieldGroupIdTypes.DATA_SOURCES]: {
    label: 'Data Sources',
    fields: [
      {
        name: 'vendor_data_sources_pii',
        label: 'Personally Identifiable Information',
        type: FormField.types.MULTI_CHECKBOX,
        options: convertStringArrayToOptions(
          Privva.Utils.vendor_details_options.vendorDataSourcesPii,
        ),
        other: {
          name: 'vendor_data_sources_pii_other',
          label: 'Other',
          validation: yup
            .string()
            .nullable()
            .max(5000, 'This is too long (maximum is 5000 characters)'),
        },
      },
      {
        name: 'vendor_data_sources_company_data',
        label: 'Company Data',
        type: FormField.types.MULTI_CHECKBOX,
        options: convertStringArrayToOptions(
          Privva.Utils.vendor_details_options.vendorDataSourcesCompanyData,
        ),
        other: {
          name: 'vendor_data_sources_company_data_other',
          label: 'Other',
          validation: yup
            .string()
            .nullable()
            .max(5000, 'This is too long (maximum is 5000 characters)'),
        },
      },
      {
        name: 'vendor_data_sources_record_count',
        label: 'Estimated Record Count',
        type: FormField.types.RADIO_GROUP,
        options: convertStringArrayToOptions(
          Privva.Utils.vendor_details_options.vendorDataSourcesRecordCount,
        ),
      },
    ],
  },
  [VendorFieldGroupIdTypes.ASSESSMENT_CATEGORIES]: {
    label: 'Assessment Categories',
    fields: [
      {
        name: 'vendor_type',
        label: 'Type',
        type: FormField.types.MULTI_CHECKBOX,
        options: convertStringArrayToOptions(
          Privva.Utils.vendor_details_options.vendorType,
        ),
      },
      {
        name: 'vendor_contract_type',
        label: 'Contract Type',
        type: FormField.types.MULTI_CHECKBOX,
        options: convertStringArrayToOptions(
          Privva.Utils.vendor_details_options.vendorContractType,
        ),
      },
    ],
  },
  [VendorFieldGroupIdTypes.OTHER]: {
    label: 'Other',
    fields: [
      {
        name: 'vendor_software_solution',
        label: 'If this solution is a software solution',
        type: FormField.types.MULTI_CHECKBOX,
        options: convertStringArrayToOptions(
          Privva.Utils.vendor_details_options.vendorSoftwareSolution,
        ),
      },
      {
        name: 'vendor_internal_id',
        label: 'Internal Identifier',
        validation: yup
          .string()
          .nullable()
          .max(5000, 'This is too long (maximum is 5000 characters)'),
      },
      {
        name: 'vendor_additional_info',
        label: 'Additional Info',
        type: FormField.types.TEXTAREA,
        controlProps: {
          rows: 4,
        },
        valueRenderComponent: AdditionalInfoDisplay,
      },
    ],
  },
}

export const VendorFields = keyBy(
  concat(...map(VendorFieldGroups, (group) => group.fields)),
  'name',
)

export const VendorOwnFieldNames = [
  VendorFieldNameTypes.NAME,
  VendorFieldNameTypes.PARENT_ORG,
  VendorFieldNameTypes.CHILD_ORGS,
  VendorFieldNameTypes.RISK_TIER,
  'status',
  'next_reassessment_at',
  VendorFieldNameTypes.USERS,
  VendorFieldNameTypes.MAIN_CONTACT,
  VendorFieldNameTypes.VENDOR_TAG_LIST,
  VendorFieldNameTypes.GLOBORG_MAPPING,
]

export default VendorFieldGroups
