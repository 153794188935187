import React from 'react'
import PropTypes from 'prop-types'
import PrintButton from 'components-v2/molecules/PrintButton'
import PageHeader from 'components-v2/organisms/PageHeader'
import RiskRatingSummary from 'components/shared/AssessmentRiskRatingSummary'
import { withReactQuery } from 'hocs'
import { getPageUrl } from 'utils/url'
import { ExtLogo, Subtitle, Title, HeaderWrapper } from './styles'

const breadcrumbs = [
  {
    href: getPageUrl('clientAssessments'),
    title: 'Assessments',
  },
  {
    active: true,
    title: 'Summary Report',
  },
]

const Container = ({ assessment, currentOrganization }) => (
  <div>
    <PageHeader
      title="Summary Report"
      breadcrumbs={breadcrumbs}
      className="hidden-print"
    >
      <PrintButton color="primary" />
    </PageHeader>
    <HeaderWrapper>
      <ExtLogo src={currentOrganization.external_logo?.url} />
      <Title>{assessment.vendor.name}</Title>
      <Subtitle>{assessment.name}</Subtitle>
    </HeaderWrapper>
    <RiskRatingSummary assessmentId={assessment.id} />
  </div>
)

Container.propTypes = {
  assessment: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object.isRequired,
}

export default withReactQuery()(Container)
