import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import { remark } from 'remark'
import strip from 'strip-markdown'
import A from 'components-v2/atoms/A'
import Tooltip from 'components-v2/molecules/Tooltip'
import AssessmentReviewScoresProgressBar from 'components/shared/AssessmentReviewScoresProgressBar'
import GradeLabel from 'components/shared/GradeLabel'
import Note from 'components/shared/Note'
import config from 'config'
import { toLocalTimeString } from 'utils/date'
import { getPageUrl } from 'utils/url'
import { AssessmentStatusTypes } from 'constants/index'
import { useAuthorizations } from 'hooks'
import {
  Wrapper,
  SummaryRow,
  Name,
  Date,
  Status,
  Score,
  ScoreBarWrapper,
  Grade,
  Notes,
  NoteWrapper,
  NoteUser,
  NoteUserAvatar,
  NoteUserName,
  ReadMoreLink,
  TextWrap,
} from './styles'

const NoteExcerptLength = 300

const NoteBody = ({ note }) => {
  const [expanded, setExpanded] = React.useState(false)
  const [excerpt, setExcerpt] = React.useState('')
  const body = note.body || ''

  React.useEffect(() => {
    remark()
      .use(strip)
      .process(body)
      .then((file) => setExcerpt(String(file).substr(0, NoteExcerptLength)))
  }, [body])

  if (expanded || body.length <= NoteExcerptLength) {
    return <Note note={note} />
  }

  if (!excerpt) {
    return null
  }

  return (
    <TextWrap>
      {excerpt}...{' '}
      <ReadMoreLink onClick={() => setExpanded(true)}>Read more</ReadMoreLink>
    </TextWrap>
  )
}
NoteBody.propTypes = {
  note: PropTypes.shape({
    body: PropTypes.string,
  }).isRequired,
}

function Assessment({ assessment, ...rest }) {
  const [canLinkTemplate, canLinkAssessment] = useAuthorizations(
    ['show', 'AssessmentTemplate'],
    ['show', 'Assessment'],
  )
  return (
    <Wrapper {...rest}>
      <SummaryRow>
        <Name>
          {canLinkTemplate ? (
            <A href={assessment.assessment_template_url} underline>
              {assessment.name}
            </A>
          ) : (
            assessment.name
          )}
        </Name>
        <Date>
          {toLocalTimeString(assessment.sent_at, config.tableDateFormat)}
        </Date>
        <Status>
          {canLinkAssessment ? (
            <A
              href={getPageUrl('clientAssessment', { id: assessment.id })}
              useGrey
              underline
            >
              {assessment.humanized_status}
            </A>
          ) : (
            assessment.humanized_status
          )}
        </Status>
        <Score>
          {[
            AssessmentStatusTypes.REVIEWED,
            AssessmentStatusTypes.SUBMITTED,
          ].includes(assessment.status) &&
            assessment.review_scores &&
            assessment.review_scores.length > 0 && (
              <>
                <ScoreBarWrapper>
                  <AssessmentReviewScoresProgressBar
                    reviewScores={assessment.review_scores}
                    height={12.75}
                  />
                </ScoreBarWrapper>
                {assessment.status === AssessmentStatusTypes.SUBMITTED ? (
                  <Tooltip
                    parent={
                      <span>
                        (
                        {assessment.risk_rating != null
                          ? `${assessment.risk_rating}%`
                          : 'N/A'}
                        *)
                      </span>
                    }
                  >
                    Score is subject to change as assessment is reviewed.
                  </Tooltip>
                ) : (
                  <span>
                    {assessment.risk_rating != null
                      ? `${assessment.risk_rating}%`
                      : 'N/A'}
                  </span>
                )}
              </>
            )}
        </Score>
        <Grade>
          {assessment.status === AssessmentStatusTypes.REVIEWED && (
            <GradeLabel grade={assessment.grade} />
          )}
        </Grade>
      </SummaryRow>
      {/* if we decide to hide notes from status only users, use
          this instead of the line below it.
          could also check 'show_vendor' on page's vendor instead */}
      {/* {canLinkAssessment && assessment.notes.length > 0 && ( */}
      {assessment.notes.length > 0 && (
        <Notes>
          {map(assessment.notes, (note) => (
            <NoteWrapper key={note.id}>
              <NoteUser>
                <NoteUserAvatar
                  src={note.user.avatar_url}
                  name={note.user.name}
                />
                <span>
                  <NoteUserName>{note.user.name}</NoteUserName> commented
                </span>
              </NoteUser>
              <NoteBody note={note} />
            </NoteWrapper>
          ))}
        </Notes>
      )}
    </Wrapper>
  )
}

Assessment.propTypes = {
  assessment: PropTypes.object.isRequired,
}

export default React.memo(Assessment)
