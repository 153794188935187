import React from 'react'
import isFunction from 'lodash/isFunction'

const branch = (test, ComponentOnPass, ComponentOnFail) => {
  const BranchComponent = React.forwardRef((props, ref) => {
    if (isFunction(test) ? test(props) : test) {
      return <ComponentOnPass {...props} ref={ref} />
    }
    if (ComponentOnFail) {
      return <ComponentOnFail {...props} ref={ref} />
    }
    return null
  })

  return BranchComponent
}

export default branch
