import React from 'react'
import img from 'images/error/hazard1.png'
import ErrorView from '../shared/ErrorView'

export default () => (
  <ErrorView
    imageUrl={img}
    errorCode={403}
    errorType="Forbidden"
    errorMessage="You don't have permission to access this page. If you believe you should have access, please contact your administrator."
  />
)
