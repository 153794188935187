import React from 'react'
import map from 'lodash/map'
import {
  useClientCommentThreadFetcher,
  useVendorCommentThreadFetcher,
  usePostCommentToClientCommentThread,
  usePostCommentToVendorCommentThread,
} from 'apis'
import objectToFormData from 'utils/object_to_formdata'

export default function useCommentThread(
  commentableType,
  commentableId,
  vendorId,
  isVendorContext,
) {
  const { mutateAsync: postCommentToClientCommentThread } =
    usePostCommentToClientCommentThread()
  const { mutateAsync: postCommentToVendorCommentThread } =
    usePostCommentToVendorCommentThread()
  const { data: clientCommentThread } = useClientCommentThreadFetcher(
    {
      params: {
        commentable_id: commentableId,
        commentable_type: commentableType,
      },
    },
    { enabled: !isVendorContext },
  )
  const { data: vendorCommentThread } = useVendorCommentThreadFetcher(
    {
      params: {
        commentable_id: commentableId,
        commentable_type: commentableType,
      },
    },
    { enabled: !!isVendorContext },
  )

  const commentThread = isVendorContext
    ? vendorCommentThread
    : clientCommentThread

  const submitComment = React.useCallback(
    (formData) => {
      const data = objectToFormData({
        comment_thread: {
          vendor_id: vendorId,
          commentable_id: commentableId,
          commentable_type: commentableType,
          comments_attributes: [
            {
              body: formData.body,
              attachments_attributes: map(formData.attachments, (f) => ({
                file: f,
              })),
            },
          ],
        },
      })
      const api = isVendorContext
        ? postCommentToVendorCommentThread
        : postCommentToClientCommentThread
      return api({ data })
    },
    [
      commentableType,
      commentableId,
      vendorId,
      isVendorContext,
      postCommentToClientCommentThread,
      postCommentToVendorCommentThread,
    ],
  )

  return [commentThread?.comments, submitComment]
}
