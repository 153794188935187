import React from 'react'
import PropTypes from 'prop-types'
import PageHeader from 'components-v2/organisms/PageHeader'
import AssessmentElementsViewWithSectionPicker from 'components/shared/AssessmentElementsViewWithSectionPicker'
import { getPageUrl } from 'utils/url'
import ScoreSheetHeader from './ScoreSheetHeader'
import QuestionRow from './QuestionRow'

const View = ({
  libraryProfile,
  assessment,
  elements,
  sections,
  selectedSectionId,
  onSectionChange,
}) => {
  const breadcrumbs = React.useMemo(
    () => [
      { title: 'Vendors', href: getPageUrl('clientVendors') },
      {
        title: libraryProfile.name,
        // FIXME: should go to client's vendor page
        // href: getPageUrl('proactOrg', {
        //   id: libraryProfile.id,
        // }),
      },
      { title: assessment.name, active: true },
    ],
    [libraryProfile, assessment],
  )

  return (
    <>
      <PageHeader title={assessment.name} breadcrumbs={breadcrumbs} />
      {assessment.include_header && (
        <ScoreSheetHeader assessment={assessment} className="margin-below" />
      )}
      <AssessmentElementsViewWithSectionPicker
        elements={elements}
        sections={sections}
        selectedSectionId={selectedSectionId}
        useCustomKeys={assessment.use_custom_keys}
        renderQuestionRow={(props) => (
          <QuestionRow
            {...props}
            isReviewed={assessment.reviewed}
            ratingLabelSet={assessment.rating_label_set}
          />
        )}
        onSectionChange={onSectionChange}
      />
    </>
  )
}

View.propTypes = {
  libraryProfile: PropTypes.object.isRequired,
  assessment: PropTypes.object.isRequired,
  elements: PropTypes.array.isRequired,
  sections: PropTypes.array.isRequired,
  selectedSectionId: PropTypes.string,
  onSectionChange: PropTypes.func.isRequired,
}

export default View
