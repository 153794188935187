import styled from 'styled-components'
import SearchInputBase from 'components-v2/molecules/SearchInput'
import ToolbarGroup from 'components-v2/molecules/ToolbarGroup'

const Toolbar = styled(ToolbarGroup)`
  margin-bottom: 20px;

  input[type='text'],
  select {
    font-weight: 500;
  }
`

Toolbar.defaultProps = {
  justifyContent: 'space-between',
}

Toolbar.Group = ToolbarGroup

export default Toolbar

export const SearchInput = styled(SearchInputBase)`
  width: 240px;

  &:not(:last-child) {
    margin-right: 5px;
  }
`

export { default as ColumnToggler } from './ColumnToggler'
