import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Records, UpdatedAt } from './styles'

const SpyCloudRecordsCard = ({ records, updatedAt, ...rest }) => (
  <Wrapper {...rest} title="Total Exposed Records">
    <Records records={records} />
    <UpdatedAt>Last updated {updatedAt}</UpdatedAt>
  </Wrapper>
)

SpyCloudRecordsCard.propTypes = {
  description: PropTypes.string.isRequired,
  records: PropTypes.number.isRequired,
  updatedAt: PropTypes.string.isRequired,
}

export default SpyCloudRecordsCard
