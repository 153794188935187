import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Title, Actions } from './styles'

const InfoCard = ({ title, actions, children, ...rest }) => (
  <Wrapper {...rest}>
    <Title>{title}</Title>
    {actions && <Actions>{actions}</Actions>}
    {children}
  </Wrapper>
)

InfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  actions: PropTypes.element,
  children: PropTypes.element,
}

export default React.memo(InfoCard)
