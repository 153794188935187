import React, { useState, useMemo, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import some from 'lodash/some'
import sortBy from 'lodash/sortBy'
import useTemplateExcelExport from 'components/client/shared/useTemplateExcelExport'
import { getEndpointUrl } from 'utils/url'
import { compose, withAuthorize, withReactQuery } from 'hocs'
import { useAuthorizations } from 'hooks'
import {
  useClientAssessmentFetcher,
  useClientQuestionAutomationsFetcher,
} from 'apis'
import { AssessmentStatusTypes } from 'constants/index'
import AssessmentView from './View'
import useAssessmentElementsFetcher, {
  useSetElementResponse,
} from './useAssessmentElementsFetcher'
import usePusher from './usePusher'
import useHashScrollOnInitialLoad from './useHashScrollOnInitialLoad'
import useExtendQueryParamOnMount from './useExtendQueryParamOnMount'
import useCRUDResponse from './useCRUDResponse'
import useCRUDIssue from './useCRUDIssue'
import useCRUDAssessment from './useCRUDAssessment'
import useApproveExtensionRequest from './useApproveExtensionRequest'
import useViewFiles from './useViewFiles'
import useViewIntegrationMetrics from './useViewIntegrationMetrics'
import useEditQuestionAutoScore from './useEditQuestionAutoScore'
import useViewContractClauses from './useViewContractClauses'
import useTransitionQuestionAutomations from './useTransitionQuestionAutomations'
import usePrintWithOptions from './usePrintWithOptions'
import useViewQuestionAttachments from './useViewQuestionAttachments'
import useImportResponses from './useImportResponses'
import useNextAssessmentReminder from './useNextAssessmentReminder'
import {
  parseArgosRiskData,
  parseRiskReconData,
  parseSpyCloudData,
} from '../lib/globorg_integration_metrics'
import { isCompleted } from './utils'

const Container = ({
  assessment: assessmentProp,
  elements: elementsProp,
  initialSectionId,
  confirmAutoScore,
  integrationMetrics,
  signatureIntegrationMetrics,
  currentOrganization,
  libraryProfile,
  reassessmentPrompt,
  userName,
}) => {
  const { data: assessment } = useClientAssessmentFetcher(
    {
      id: assessmentProp.id,
      params: { lean: true },
    },
    {
      initialData: assessmentProp,
      refetchOnMount: false,
    },
  )
  const [selectedSectionId, setSelectedSectionId] = useState(initialSectionId)
  const [elementFilter, setElementFilter] = useState({})
  const [translateResponsesOptions, setTranslateResponsesOptions] = useState({})
  const { data: elements } = useAssessmentElementsFetcher(
    {
      assessmentId: assessmentProp.id,
      sectionId: selectedSectionId,
      filter: elementFilter,
      translateResponsesOptions,
    },
    elementsProp,
  )
  const [canImport, canExport, canManage] = useAuthorizations(
    'import',
    'export',
    'manage',
  )
  const [displayAutomationReview, setDisplayAutomationReview] = useState(false)
  const { data: questionAutomationsIdx } = useClientQuestionAutomationsFetcher({
    params: {
      assessment_id: assessmentProp.id,
    },
  })
  useEffect(() => {
    setDisplayAutomationReview(
      canManage &&
        assessment.status === AssessmentStatusTypes.SUBMITTED &&
        some(questionAutomationsIdx, ['status', 'staged']),
    )
  }, [canManage, assessment.status, questionAutomationsIdx])
  const questionAutomations = useMemo(
    () =>
      sortBy(questionAutomationsIdx, [
        (a) => Number.parseFloat(a.element.natural_key),
        'source_type',
        (b) =>
          b.source_type === 'issue_stub'
            ? b.automation.automation_source.summary
            : b.automation.automation_source.name,
      ]),
    [questionAutomationsIdx],
  )
  const formattedIntegrationMetrics = useMemo(
    () => ({
      // eslint-disable-next-line camelcase
      argos_risk: parseArgosRiskData(get(integrationMetrics, 'argos_risk')),
      // eslint-disable-next-line camelcase
      risk_recon: parseRiskReconData(get(integrationMetrics, 'risk_recon')),
      // eslint-disable-next-line camelcase
      spy_cloud: parseSpyCloudData(get(integrationMetrics, 'spy_cloud')),
    }),
    [integrationMetrics],
  )
  usePusher(assessmentProp.channel, assessmentProp.id)
  useHashScrollOnInitialLoad(assessmentProp.id, initialSectionId)
  useExtendQueryParamOnMount(assessment)
  useNextAssessmentReminder(assessment, reassessmentPrompt)
  const setElementResponse = useSetElementResponse()
  const {
    updateResponseReviewScore,
    updateResponseReviewNote,
    markResponseReviewed,
  } = useCRUDResponse(assessmentProp.id, userName)
  const { createIssue, sendIssue, viewIssues } = useCRUDIssue(assessment)
  const {
    assignAssessment,
    unlockAssessment,
    continueReviewAssessment,
    completeAssessment,
    addOrEditAssessmentNotes,
    submitExpiredAssessment,
    setAssessmentAttachments,
    setAssessmentInternalAttachments,
  } = useCRUDAssessment(assessment)
  const printWithOptions = usePrintWithOptions()
  const exportToExcel = useTemplateExcelExport()
  const approveExtensionRequest = useApproveExtensionRequest(assessment)
  const viewFiles = useViewFiles(assessmentProp.id)
  const viewQuestionAttachments = useViewQuestionAttachments()
  const viewIntegrationMetrics = useViewIntegrationMetrics(
    formattedIntegrationMetrics,
  )
  const editQuestionAutoScore = useEditQuestionAutoScore(
    elements,
    confirmAutoScore,
    assessment.assessment_template.rating_label_set,
  )
  const viewContractClauses = useViewContractClauses(
    assessment,
    questionAutomations,
  )
  const transitionQuestionAutomations = useTransitionQuestionAutomations(
    currentOrganization,
    assessmentProp.id,
  )
  const importResponses = useImportResponses(assessment)

  const handleElementAttachmentsChange = useCallback(
    (responseId, attachments, response) => {
      setElementResponse(responseId, response)
    },
    [setElementResponse],
  )

  const handleTemplateExcelExportButtonClick = useCallback(() => {
    exportToExcel(assessment.assessment_template_id)
  }, [exportToExcel, assessment.assessment_template_id])

  const handleCloseAutomationReview = useCallback(() => {
    setDisplayAutomationReview(false)
  }, [])

  const assessmentEndpointUrl = getEndpointUrl('clientAssessment', {
    id: assessmentProp.id,
  })
  const completed = isCompleted(assessment.status)
  const hasClauses = some(
    questionAutomations,
    (auto) => auto.source_type === 'clause' && auto.status === 'activated',
  )
  const canImportResponses =
    canImport &&
    [AssessmentStatusTypes.INVITED, AssessmentStatusTypes.IN_PROCESS].includes(
      assessment.status,
    )
  const canExportResponses =
    canExport &&
    [AssessmentStatusTypes.SUBMITTED, AssessmentStatusTypes.REVIEWED].includes(
      assessment.status,
    )
  return (
    <AssessmentView
      assessment={assessment}
      elements={elements || []}
      selectedSectionId={selectedSectionId}
      elementFilter={elementFilter}
      questionAutomations={questionAutomations}
      integrationMetrics={integrationMetrics}
      formattedIntegrationMetrics={formattedIntegrationMetrics}
      signatureIntegrationMetrics={signatureIntegrationMetrics}
      displayAutomationReview={displayAutomationReview}
      assessmentEndpointUrl={assessmentEndpointUrl}
      currentOrganization={currentOrganization}
      completed={completed}
      hasClauses={hasClauses}
      libraryProfile={libraryProfile}
      canImportResponses={canImportResponses}
      canExportResponses={canExportResponses}
      onElementFilterChange={setElementFilter}
      onReviewScoreChange={updateResponseReviewScore}
      onReviewNoteChange={updateResponseReviewNote}
      onSectionChange={setSelectedSectionId}
      onMarkReviewed={markResponseReviewed}
      onAutoScoreEditButtonClick={editQuestionAutoScore}
      onCreateIssueClick={createIssue}
      onSendIssue={sendIssue}
      onAssign={assignAssessment}
      onUnlock={unlockAssessment}
      onContinueReview={continueReviewAssessment}
      onComplete={completeAssessment}
      onAddOrEditNotes={addOrEditAssessmentNotes}
      onCloseAutomationReview={handleCloseAutomationReview}
      onViewAttachmentsClick={viewFiles}
      onViewQuestionAttachmentsClick={viewQuestionAttachments}
      onTemplateExcelExportButtonClick={handleTemplateExcelExportButtonClick}
      onViewIssuesClick={viewIssues}
      onViewIntegrationMetricsClick={viewIntegrationMetrics}
      onAutomationTransitionClick={transitionQuestionAutomations}
      onTranslateResponsesChange={setTranslateResponsesOptions}
      onViewContractClausesClick={viewContractClauses}
      onPrintClick={printWithOptions}
      onAttachmentsChange={setAssessmentAttachments}
      onInternalAttachmentsChange={setAssessmentInternalAttachments}
      onElementAttachmentsChange={handleElementAttachmentsChange}
      onApproveExtensionRequest={approveExtensionRequest}
      onSubmitExpiredAssessment={submitExpiredAssessment}
      onImportResponsesButtonClick={importResponses}
    />
  )
}

Container.propTypes = {
  currentOrganization: PropTypes.object.isRequired,
  confirmAutoScore: PropTypes.bool,
  integrationMetrics: PropTypes.object,
  signatureIntegrationMetrics: PropTypes.array,
  assessment: PropTypes.object.isRequired,
  elements: PropTypes.array.isRequired,
  initialSectionId: PropTypes.string,
  libraryProfile: PropTypes.bool,
  reassessmentPrompt: PropTypes.bool,
  userName: PropTypes.string,
}

export default compose(withAuthorize(), withReactQuery())(Container)
