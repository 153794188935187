import React from 'react'
import PropTypes from 'prop-types'
import FileInput from 'components-v2/molecules/FileInput'
import BaseField from './BaseField'

const FileInputField = ({
  name,
  value,
  disabled,
  controlProps = {},
  onChange,
  ...rest
}) => (
  <BaseField {...rest}>
    <FileInput
      {...controlProps}
      name={name}
      value={value}
      disabled={disabled}
      onChange={onChange}
    />
  </BaseField>
)

FileInputField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.object,
  disabled: PropTypes.bool,
  controlProps: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

export default React.memo(FileInputField)
