import React from 'react'
import { useLibraryProfilesFetcher } from 'apis'
import { getPageUrl } from 'utils/url'
import View from './View'
import useAddVendor from './useAddVendor'

const Container = () => {
  const { data: libraryProfiles, isFetching } = useLibraryProfilesFetcher()
  const addVendor = useAddVendor()
  const existingVendorLink = (vendorId) => {
    const tab = 'library'
    const link = getPageUrl('clientVendor', { id: vendorId }, { tab })
    window.location.assign(link)
  }

  return (
    <View
      libraryProfiles={libraryProfiles}
      loading={isFetching}
      onAddVendor={addVendor}
      onLinkVendor={existingVendorLink}
    />
  )
}

export default Container
