import React from 'react'
import PropTypes from 'prop-types'
import { SyncLoader } from 'halogenium'
import { Wrapper, LoaderWrapper } from './styles'

const OverlayLoader = React.forwardRef(
  (
    { isGlobal, backgroundColor, active, color, text, children, ...rest },
    ref,
  ) => (
    <Wrapper {...rest} ref={ref}>
      <LoaderWrapper
        backgroundColor={backgroundColor}
        active={active}
        isGlobal={isGlobal}
      >
        <div>
          <SyncLoader color={color} />
          <div>{text}</div>
        </div>
      </LoaderWrapper>
      {children}
    </Wrapper>
  ),
)

OverlayLoader.propTypes = {
  isGlobal: PropTypes.bool,
  backgroundColor: PropTypes.string,
  active: PropTypes.bool,
  color: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.element,
}

OverlayLoader.defaultProps = {
  isGlobal: false,
  backgroundColor: 'rgba(0,0,0,.4)',
  color: '#FFF',
  active: false,
  text: 'Please wait...',
}

export default React.memo(OverlayLoader)
