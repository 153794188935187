import React from 'react'
import PropTypes from 'prop-types'
import sortBy from 'lodash/sortBy'
import sumBy from 'lodash/sumBy'
import values from 'lodash/values'
import isEqual from 'lodash/isEqual'
import { BalanceScale } from '@styled-icons/fa-solid/BalanceScale'
import Button from 'components-v2/atoms/Button'
import Icon from 'components-v2/atoms/Icon'
import DraggableRow from 'components-v2/molecules/DraggableRow'
import FormDialog from 'components-v2/organisms/FormDialog'
import Question from 'components/elements/risk_tier_calculator/Question'
import { useConfirm, useModal } from 'hooks'
import { withDragDropContext } from 'hocs'
import { moveArrayElementTo } from 'utils/array'
import EditTireThresholdsModal from '../EditTireThresholdsModal'
import QuestionModal from '../QuestionModal'

const recalculateOrder = (questions) =>
  questions.map((e, index) => ({
    ...e,
    order: index + 1,
  }))

const generateNewQuestionId = (questions) => {
  const keyCollection = questions.map((q) => q.id)
  const newKey = () => Math.random().toString(36).substring(2, 15)
  let tmpKey = newKey()
  // most likely entirely unnecessary, but can't hurt to be safe
  while (keyCollection.includes(tmpKey)) {
    tmpKey = newKey()
  }
  return tmpKey
}

const formConfig = {
  initialValues: {},
}

const EditCalculatorModal = ({
  calculatorConfig,
  onSubmit,
  onClose,
  ...rest
}) => {
  const [openEditTireThresholdsModal, closeEditTireThresholdsModal] = useModal(
    EditTireThresholdsModal,
  )
  const [openQuestionModal, closeQuestionModal] = useModal(QuestionModal)
  const confirm = useConfirm()
  const [questions, setQuestions] = React.useState(
    sortBy(calculatorConfig.questions, 'order'),
  )
  const [tempQuestions, setTempQuestions] = React.useState(questions)
  const [tiers, setTiers] = React.useState(calculatorConfig.tier_divisions)

  React.useEffect(() => {
    setTempQuestions(recalculateOrder(questions))
  }, [questions])

  const handleRowMove = React.useCallback(() => {
    setQuestions(recalculateOrder(tempQuestions))
  }, [tempQuestions])

  const handlerRowTemporaryMove = React.useCallback((dragIndex, hoverIndex) => {
    setTempQuestions((current) =>
      moveArrayElementTo(current, dragIndex, hoverIndex),
    )
  }, [])

  const handleRowCancelMove = React.useCallback(() => {
    setTempQuestions(questions)
  }, [questions])

  const handleRowDelete = React.useCallback(
    async (id) => {
      const confirmed = await confirm(
        {
          title: 'Remove question?',
          body: 'Remove this question from the calculator configuration?',
          confirmText: "Yes, I'm sure",
        },
        true,
      )
      if (confirmed) {
        setQuestions((current) => current.filter((q) => q.id !== id))
      }
    },
    [confirm],
  )

  const handleEditTireThresholds = React.useCallback(() => {
    const possiblePoints = sumBy(questions, (q) =>
      Math.max(...values(q.choices)),
    )
    openEditTireThresholdsModal({
      tiers,
      max: possiblePoints,
      onSave: (value) => {
        setTiers(value)
        closeEditTireThresholdsModal()
      },
    })
  }, [
    tiers,
    questions,
    openEditTireThresholdsModal,
    closeEditTireThresholdsModal,
  ])

  const handleAddQuestion = React.useCallback(() => {
    openQuestionModal({
      title: 'Add Question',
      onSubmit: (formData) => {
        setQuestions((current) => {
          const newQuestion = {
            id: generateNewQuestionId(current),
            ...formData,
          }
          return [...current, newQuestion]
        })
        closeQuestionModal()
      },
    })
  }, [openQuestionModal, closeQuestionModal])

  const handleEditQuestion = React.useCallback(
    (questionId) => {
      const question = questions.find((q) => q.id === questionId)
      if (!question) {
        return
      }
      openQuestionModal({
        title: 'Edit Question',
        question,
        onSubmit: (formData) => {
          setQuestions((current) => {
            const questionIndex = questions.findIndex(
              (q) => q.id === questionId,
            )
            if (questionIndex === -1) {
              return current
            }
            const newQuestions = [...current]
            newQuestions[questionIndex] = {
              ...questions[questionIndex],
              ...formData,
            }
            return newQuestions
          })
          closeQuestionModal()
        },
      })
    },
    [questions, openQuestionModal, closeQuestionModal],
  )

  const handleHide = React.useCallback(() => {
    if (
      isEqual(calculatorConfig.questions, questions) &&
      isEqual(calculatorConfig.tier_divisions, tiers)
    ) {
      onClose()
    } else {
      confirm({
        title: 'Discard Changes?',
        body: 'You have unsaved changes. Are you sure you want to discard them?',
        confirmText: "Yes, I'm sure",
        onConfirm: onClose,
      })
    }
  }, [calculatorConfig, tiers, questions, onClose, confirm])

  const handleSubmit = React.useCallback(
    (_, actions) =>
      onSubmit(
        {
          ...calculatorConfig,
          questions: recalculateOrder(questions),
          tier_divisions: tiers,
        },
        actions,
      ),
    [calculatorConfig, questions, tiers, onSubmit],
  )

  const footerLeftContent = (
    <>
      <Button onClick={handleEditTireThresholds} disabled={!questions.length}>
        <Icon icon={BalanceScale} />
        Edit Tier Thresholds
      </Button>
      <Button onClick={handleAddQuestion}>
        <Icon icon="fa fa-plus" />
        Add Question
      </Button>
    </>
  )

  return (
    <FormDialog
      {...rest}
      title={calculatorConfig.name}
      submitButtonLabel="Save"
      size="lg"
      formConfig={formConfig}
      footerLeftContent={footerLeftContent}
      onSubmit={handleSubmit}
      onClose={handleHide}
    >
      {tempQuestions.map((question, idx) => (
        <DraggableRow
          key={question.id}
          id={question.id}
          index={idx}
          onRowMove={handleRowMove}
          onRowTemporaryMove={handlerRowTemporaryMove}
          onRowCancelMove={handleRowCancelMove}
        >
          {({ opacity, ref }) => (
            <div ref={ref} style={{ opacity }}>
              <Question
                question={question}
                editMode
                onEditClick={handleEditQuestion}
                onDeleteClick={handleRowDelete}
              />
            </div>
          )}
        </DraggableRow>
      ))}
    </FormDialog>
  )
}

EditCalculatorModal.propTypes = {
  calculatorConfig: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default withDragDropContext(EditCalculatorModal)
