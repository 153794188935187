export const VendorStatusTypes = {
  REQUESTED: 'requested',
  DRAFT: 'in_review',
  DECLINED: 'declined',
  ACTIVE: 'active',
  ARCHIVED: 'archived',
}

export const VendorStatusTransitions = {
  UNARCHIVE: 'unarchive',
  ARCHIVE: 'archive',
}

export const VendorStatusGroups = {
  proposed: [
    VendorStatusTypes.REQUESTED,
    VendorStatusTypes.DRAFT,
    VendorStatusTypes.DECLINED,
  ],
  operational: [VendorStatusTypes.ACTIVE, VendorStatusTypes.ARCHIVED],
}

export default VendorStatusTypes
