import React from 'react'
import get from 'lodash/get'
import { getPageUrl } from 'utils/url'
import PropTypes from 'prop-types'

const ParentOrgDisplay = ({ vendor }) => {
  const parentPath = getPageUrl('clientVendor', {
    id: get(vendor, 'parent_org.id'),
  })
  return <a href={parentPath}>{get(vendor, 'parent_org.name')}</a>
}

ParentOrgDisplay.propTypes = {
  vendor: PropTypes.object.isRequired,
}

export default ParentOrgDisplay
