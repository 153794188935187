import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { createPageList } from 'components-v2/molecules/Pagination'
import { colors } from 'styles'
import BaseTable from './BaseTable'
import { formatCell } from './styles'

const Table = styled(BaseTable)`
  table {
    border-bottom: 1px solid #f5f5f5;

    thead {
      display: none;
    }

    ${formatCell(`
      vertical-align: middle;
      height: 60px;
      border-color: #F5F5F5;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    `)}
  }
`

const PaginationRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;

  .pagination {
    & > li {
      & > a,
      & > span {
        font-weight: 500;
        color: ${colors.BODY_TEXT} !important;
        border-color: #cfd1d4 !important;
      }

      &.disabled {
        opacity: 1;

        & > a,
        & > span {
          color: ${colors.GREY} !important;
          font-weight: normal;
        }
      }

      &.active {
        & > a,
        & > span {
          background-color: ${colors.LIGHT_GREY};
        }
      }
    }
  }
`

const PaginationTotal = styled.div`
  font-weight: 500;
`

const PageList = createPageList({
  previousPage: '< Previous',
  nextPage: 'Next >',
})
function FooterPagination({
  table,
  paginationTotalRenderer,
  totalSize,
  manualPagination,
}) {
  const from =
    table.getState().pagination.pageIndex *
      table.getState().pagination.pageSize +
    1
  const to = from - 1 + table.getRowModel().rows.length
  const size = manualPagination
    ? totalSize
    : table.getFilteredRowModel().rows.length
  return (
    <PaginationRow>
      <div>
        {table.getPageCount() > 1 && (
          <PageList
            currentPage={
              table.getState().pagination.pageIndex + 1 > table.getPageCount()
                ? 0
                : table.getState().pagination.pageIndex + 1
            }
            totalPages={table.getPageCount()}
            hideFirstAndLastPageLinks
            onChange={(v) => table.setPageIndex(v - 1)}
          />
        )}
      </div>
      <PaginationTotal>
        {paginationTotalRenderer(from, to, size)}
      </PaginationTotal>
    </PaginationRow>
  )
}
FooterPagination.propTypes = {
  table: PropTypes.object.isRequired,
  paginationTotalRenderer: PropTypes.func.isRequired,
  totalSize: PropTypes.number,
  manualPagination: PropTypes.bool,
}

const Headless = ({
  paginationTotalRenderer,
  totalSize,
  manualPagination,
  ...rest
}) => (
  <Table
    {...rest}
    totalSize={totalSize}
    manualPagination={manualPagination}
    // eslint-disable-next-line react/no-unstable-nested-components
    footerPaginationComponent={(props) => (
      <FooterPagination
        {...props}
        manualPagination={manualPagination}
        totalSize={totalSize}
        paginationTotalRenderer={paginationTotalRenderer}
      />
    )}
  />
)

Headless.propTypes = {
  paginationTotalRenderer: PropTypes.func,
  totalSize: PropTypes.number,
  manualPagination: PropTypes.bool,
}
Headless.defaultProps = {
  paginationTotalRenderer: (from, to, size) =>
    `Viewing ${from}-${to} of ${size}`,
}

export default Headless
