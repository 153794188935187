import React from 'react'
import PropTypes from 'prop-types'
import Toggle from 'components-v2/atoms/Toggle'
import BaseField from './BaseField'

const ToggleField = ({
  name,
  value,
  disabled,
  controlProps = {},
  onChange,
  ...rest
}) => (
  <BaseField {...rest}>
    <div>
      <Toggle
        {...controlProps}
        name={name}
        checked={value}
        disabled={disabled}
        onChange={onChange}
      />
    </div>
  </BaseField>
)

ToggleField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  controlProps: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

export default ToggleField
