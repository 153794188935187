import React from 'react'
import PropTypes from 'prop-types'
import { ShieldAlt } from '@styled-icons/fa-solid/ShieldAlt'
import Icon from 'components-v2/atoms/Icon'
import Button from 'components-v2/atoms/Button'
import PrintButton from 'components-v2/molecules/PrintButton'
import Tabs, { Tab } from 'components-v2/molecules/Tabs'
import ToolbarGroup from 'components-v2/molecules/ToolbarGroup'
import PageHeader from 'components-v2/organisms/PageHeader'
import VendorListView from 'components/client/vendor_list_view'
import AssessmentListView from 'components/client/assessment_list_view'
import AssessmentRiskDomainView from 'components/client/assessment_risk_domain_view'
import AttachmentListForVendorsView from 'components/client/attachment_list_for_vendors_view'
import LibraryProfileListView from 'components/library/profile_list_view'
import BitSightFeedView from 'components/client/bitsight_feed_view'
import WatchlistsView from 'components/client/watchlists_view'
import { LabelTypes } from 'constants/index'
import { useAuthorizations, useRoleAtLeast } from 'hooks'

const VendorsView = ({
  orgId,
  bitSight,
  userPrefs,
  orgRole,
  vendorRoles,
  watchlists,
  activeTab,
  vendorListViewRef,
  onTabSelect,
  onAddVendorClick,
  allowIntakeFeature,
  onRequestVendorClick,
  enableRiskDomains,
  showWatchlists,
  canAdmin,
  disabledIntegrations,
  vendorLibraryEnabled,
}) => {
  const [
    canCreateVendor,
    canViewPartner,
    canViewFiles,
    canViewRiskDomains,
    canViewWatchlist,
  ] = useAuthorizations(
    'create_vendor',
    'view_partner_data',
    ['index', 'ClientVendorAttachment'],
    ['index', 'RiskDomain'],
    ['index', 'Watchlist'],
  )
  const canViewRequests = useRoleAtLeast('user')

  const createVendorLabel = () =>
    `${
      LabelTypes.VENDOR.beginsWithVowel() ? 'an' : 'a'
    } ${LabelTypes.VENDOR.toLowerCase()}`

  const addVendorButton = () => (
    <Button color="primary" onClick={onAddVendorClick}>
      <i className="fa fa-plus-circle" />
      {`Add ${createVendorLabel()}`}
    </Button>
  )

  const requestVendorButton = (intakeEnabled) =>
    intakeEnabled && (
      <Button color="primary" onClick={onRequestVendorClick}>
        <i className="fa fa-plus-circle" />
        {`Request ${createVendorLabel()}`}
      </Button>
    )

  return (
    <>
      <PageHeader title={LabelTypes.VENDORS}>
        <ToolbarGroup>
          <PrintButton />
          {canCreateVendor
            ? addVendorButton()
            : requestVendorButton(allowIntakeFeature)}
        </ToolbarGroup>
      </PageHeader>
      <Tabs activeKey={activeTab} onChange={onTabSelect}>
        <Tab eventKey="list" title="List">
          <VendorListView
            ref={vendorListViewRef}
            orgId={orgId}
            canAdmin={canAdmin}
            requests={false}
            userPrefs={userPrefs}
            disabledIntegrations={disabledIntegrations}
          />
        </Tab>
        <Tab eventKey="assessments" title="Assessments">
          <AssessmentListView
            orgId={orgId}
            userPrefs={userPrefs}
            orgRole={orgRole}
            vendorRoles={vendorRoles}
            context="all"
            disabledIntegrations={disabledIntegrations}
          />
        </Tab>
        {canViewRiskDomains && enableRiskDomains && (
          <Tab eventKey="risk_domains" title="Risk Domain Scores">
            <AssessmentRiskDomainView />
          </Tab>
        )}
        {canViewFiles && (
          <Tab eventKey="files" title="Files">
            <AttachmentListForVendorsView />
          </Tab>
        )}
        {vendorLibraryEnabled && (
          <Tab eventKey="vendorLibrary" title="Vendor Library">
            <LibraryProfileListView />
          </Tab>
        )}
        {showWatchlists && canViewWatchlist && (
          <Tab eventKey="watchlist" title="Watchlist">
            <WatchlistsView orgId={orgId} watchlists={watchlists} />
          </Tab>
        )}
        {allowIntakeFeature && canViewRequests && (
          <Tab eventKey="requests" title="Requests">
            <VendorListView requests orgId={orgId} userPrefs={userPrefs} />
          </Tab>
        )}
        {bitSight && canViewPartner && (
          <Tab
            eventKey="bitSight"
            title={
              <span>
                <Icon icon={ShieldAlt} /> BitSight
              </span>
            }
          >
            <BitSightFeedView
              info={bitSight}
              orgId={orgId}
              userPrefs={userPrefs}
            />
          </Tab>
        )}
      </Tabs>
    </>
  )
}

VendorsView.propTypes = {
  bitSight: PropTypes.object,
  orgId: PropTypes.string,
  userPrefs: PropTypes.object,
  orgRole: PropTypes.string,
  watchlists: PropTypes.array.isRequired,
  vendorRoles: PropTypes.object,
  activeTab: PropTypes.string,
  vendorListViewRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  onTabSelect: PropTypes.func.isRequired,
  onAddVendorClick: PropTypes.func.isRequired,
  allowIntakeFeature: PropTypes.bool,
  onRequestVendorClick: PropTypes.func.isRequired,
  enableRiskDomains: PropTypes.bool,
  showWatchlists: PropTypes.bool,
  canAdmin: PropTypes.bool,
  disabledIntegrations: PropTypes.array.isRequired,
  vendorLibraryEnabled: PropTypes.bool,
}

export default VendorsView
