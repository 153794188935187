import PropTypes from 'prop-types'
import React from 'react'
import map from 'lodash/map'
import DList from 'components-v2/molecules/DList'
import InfoCard from '../InfoCard'
import TagList from '../TagList'

const TechnologyCard = ({ globalOrg }) => (
  <InfoCard title="Technology">
    <DList horizontal>
      <dt>Categories</dt>
      <dd>
        <TagList
          list={globalOrg.tech_categories}
          plural="categories"
          titleize
        />
      </dd>
      <dt>Products and services</dt>
      <dd>{map(globalOrg.tech, (tech) => tech.titleize()).join(', ')}</dd>
    </DList>
  </InfoCard>
)

TechnologyCard.propTypes = {
  globalOrg: PropTypes.object.isRequired,
}

export default TechnologyCard
