import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import keys from 'lodash/keys'
import filter from 'lodash/filter'
import map from 'lodash/map'
import keyBy from 'lodash/keyBy'
import {
  PieChart,
  RadarChart,
  GaugeChart2,
} from 'components-v2/molecules/Charts'
import Tooltip from 'components-v2/molecules/Tooltip'
import { useClientRiskDomainsFetcher } from 'apis'
import { AssessmentClientSubmitReasonOptions } from 'constants/index'
import { getRiskRatingColor, getRiskRatingLabel } from 'lib/assessment'
import { colors } from 'styles'
import Card from './Card'
import {
  Wrapper,
  Top,
  Title,
  SmallNoScore,
  RiskScoreSmallChartWrapper,
  SmallRiskScore,
  HeaderGradeLabel,
  Toggler,
  Content,
  Left,
  Right,
  RiskScoreChartWrapper,
  RiskScore,
  NoScoreWrapper,
  NoScore,
  NoScoreReason,
  ViewSummary,
  Legend,
  LegendItem,
  DomainLegend,
  DomainLegendItem,
  DomainLegendItemLabel,
  ContentGradeLabel,
  ScoreAndGradeWrapper,
  SmallHeading,
  StyledTabs as Tabs,
} from './styles'

const AssessmentClientSubmitReasonOptionsObj = keyBy(
  AssessmentClientSubmitReasonOptions,
  'value',
)
const riskScoreChartPoints = ['0%', '20%', '40%', '60%', '80%', '100%'].map(
  (label) => ({ label }),
)

const generatePieChart = (chartData) => (
  <>
    <PieChart
      data={chartData}
      width="100%"
      height={260}
      cy={140}
      innerRadius={30}
      outerRadius={70}
      startAngle={90}
      endAngle={450}
      label
      labelOnHover
    />
    <Legend>
      {chartData.map((entry, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <LegendItem key={index} color={entry.color}>
          {entry.name} ({entry.value})
        </LegendItem>
      ))}
    </Legend>
  </>
)

const generateRadarChart = (radarData) => (
  <>
    <RadarChart
      data={radarData}
      width="100%"
      height={220}
      xAxisDataKey="domain"
      dataKey="value"
      margin={{ top: 15, bottom: 15 }}
    />
    <DomainLegend>
      {radarData.map((entry) => (
        <DomainLegendItem key={entry.domainId}>
          <Tooltip parent={<span>{entry.domain}</span>}>
            {entry.domainSummary}
          </Tooltip>
          <DomainLegendItemLabel color={getRiskRatingColor(entry.valueLabel)}>
            {entry.valueLabel}
          </DomainLegendItemLabel>
        </DomainLegendItem>
      ))}
    </DomainLegend>
  </>
)

const AssessmentScoreSheetHeader = ({
  assessment,
  ratingLabel,
  children,
  onViewSummaryClick,
  ...rest
}) => {
  const { data: riskDomains } = useClientRiskDomainsFetcher()
  const [expanded, setExpanded] = React.useState(true)
  const riskScore = assessment.calculated_risk_score
  const pieChartData = React.useMemo(() => {
    const data = map(assessment.review_scores, (score) => ({
      name: score.label,
      value: score.count,
      color: score.color,
    }))
    return filter(data, (sector) => sector.value)
  }, [assessment.review_scores])
  const domainRatings = assessment.risk_domain_ratings
  const radarData = React.useMemo(
    () =>
      map(riskDomains, (domain) => ({
        domainId: domain.id,
        domain: domain.name,
        domainSummary: domain.summary,
        value: get(domainRatings, domain.id, null) || 0,
        valueLabel: get(domainRatings, domain.id, 'N/A'),
      })),
    [riskDomains, domainRatings],
  )

  return (
    <Wrapper {...rest}>
      <Top onClick={() => setExpanded(!expanded)}>
        {!expanded ? (
          <>
            {riskScore || riskScore === 0 ? (
              <RiskScoreSmallChartWrapper>
                <GaugeChart2
                  value={riskScore}
                  color={colors.BLUE}
                  max={100}
                  width="100%"
                  height={40}
                  outerRadius={30}
                  innerRadius={28}
                  cy={30}
                  points={riskScoreChartPoints}
                  hideLabels
                />
                <SmallRiskScore>{getRiskRatingLabel(riskScore)}</SmallRiskScore>
              </RiskScoreSmallChartWrapper>
            ) : (
              <SmallNoScore>N/A</SmallNoScore>
            )}
            <Title>{ratingLabel}</Title>
            {(assessment.grade || assessment.grade === 0) && (
              <HeaderGradeLabel grade={assessment.grade} iconLeft={false} />
            )}
            <Toggler>
              Expand Details&nbsp;&nbsp;
              <i className="fa fa-chevron-down" aria-hidden="true" />
            </Toggler>
          </>
        ) : (
          <>
            <Title>Assessment Overview</Title>
            <Toggler>
              <i className="fa fa-chevron-up" aria-hidden="true" />
            </Toggler>
          </>
        )}
      </Top>
      {expanded && (
        <Content>
          <Left>
            <Card title={ratingLabel}>
              <ScoreAndGradeWrapper>
                {riskScore || riskScore === 0 ? (
                  <RiskScoreChartWrapper>
                    <GaugeChart2
                      value={riskScore}
                      color={colors.BLUE}
                      max={100}
                      width="100%"
                      height={118}
                      outerRadius={90}
                      innerRadius={85}
                      cy={108}
                      points={riskScoreChartPoints}
                    />
                    <RiskScore>{getRiskRatingLabel(riskScore)}</RiskScore>
                  </RiskScoreChartWrapper>
                ) : (
                  <NoScoreWrapper>
                    <NoScore>N/A</NoScore>
                    {assessment.client_submitted && (
                      <NoScoreReason>
                        {
                          AssessmentClientSubmitReasonOptionsObj[
                            assessment.client_submit_reason
                          ]?.label
                        }
                      </NoScoreReason>
                    )}
                  </NoScoreWrapper>
                )}
                <div>
                  {(assessment.grade || assessment.grade === 0) && (
                    <ContentGradeLabel grade={assessment.grade} />
                  )}
                  <br />
                  {onViewSummaryClick && (
                    <ViewSummary onClick={onViewSummaryClick}>
                      View Summary
                    </ViewSummary>
                  )}
                </div>
              </ScoreAndGradeWrapper>
              {keys(domainRatings).length ? (
                <Tabs defaultActiveKey="radar">
                  <Tabs.Tab eventKey="pie" title="Breakdown">
                    {generatePieChart(pieChartData)}
                  </Tabs.Tab>
                  <Tabs.Tab eventKey="radar" title="Risk Domain">
                    {generateRadarChart(radarData)}
                  </Tabs.Tab>
                </Tabs>
              ) : (
                generatePieChart(pieChartData)
              )}
            </Card>
          </Left>
          <Right>{children}</Right>
        </Content>
      )}
    </Wrapper>
  )
}

AssessmentScoreSheetHeader.propTypes = {
  assessment: PropTypes.object.isRequired,
  ratingLabel: PropTypes.string.isRequired,
  children: PropTypes.node,
  onViewSummaryClick: PropTypes.func,
}

AssessmentScoreSheetHeader.Card = Card
AssessmentScoreSheetHeader.SmallHeading = SmallHeading

export default AssessmentScoreSheetHeader
