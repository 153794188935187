import React from 'react'
import PropTypes from 'prop-types'
import { RadioGroup } from '@headlessui/react'
import { Group, Card, Label, Count } from './styles'

const CardRadioGroup = ({ label, options, ...rest }) => (
  <RadioGroup {...rest}>
    <RadioGroup.Label className="sr-only">{label}</RadioGroup.Label>
    <Group>
      {options.map((e) => (
        <RadioGroup.Option key={e.value} value={e.value} as={React.Fragment}>
          {({ checked, ...restOptionProps }) => (
            <Card {...restOptionProps} checked={checked} color={e.color}>
              <Label checked={checked}>{e.label}</Label>
              <Count checked={checked} zero={!e.count}>
                {e.count}
              </Count>
            </Card>
          )}
        </RadioGroup.Option>
      ))}
    </Group>
  </RadioGroup>
)

CardRadioGroup.propTypes = {
  value: PropTypes.any,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func,
}

export default CardRadioGroup
