import React from 'react'
import PropTypes from 'prop-types'
import filter from 'lodash/filter'
import isInteger from 'lodash/isInteger'
import map from 'lodash/map'
import { toUncontrollable } from 'hocs'
import Icon from 'components-v2/atoms/Icon'
import DropzoneUploader from '../DropzoneUploader'
import { Wrapper, FileList, FileListItem } from './styles'

export const FilesInput = ({
  value,
  buttonContent,
  buttonColor,
  description,
  onChange,

  // input attributes
  accept,
  maxFiles,
  maxSizeBytes,
  disabled,
  ...rest
}) => {
  const handleRemove = React.useCallback(
    (file) => {
      onChange(filter(value, (e) => e !== file))
    },
    [value, onChange],
  )

  const handleChangeStatus = React.useCallback(
    ({ file, remove }, status) => {
      if (status === 'done') {
        onChange(value ? [...value, file] : [file])
        remove()
      }
    },
    [value, onChange],
  )

  return (
    <Wrapper {...rest}>
      <DropzoneUploader
        disabled={disabled}
        buttonContent={buttonContent}
        buttonColor={buttonColor}
        description={description}
        accept={accept}
        maxSizeBytes={maxSizeBytes}
        maxFiles={
          isInteger(maxFiles) && value ? maxFiles - value.length : maxFiles
        }
        onChangeStatus={handleChangeStatus}
      />
      {value && value.length > 0 && (
        <FileList {...rest}>
          {map(value, (file, index) => (
            <FileListItem key={index}>
              {file.name}{' '}
              <Icon
                icon="fa fa-times-circle"
                onClick={() => handleRemove(file)}
              />
            </FileListItem>
          ))}
        </FileList>
      )}
    </Wrapper>
  )
}

FilesInput.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.instanceOf(File),
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ]),
  ),
  buttonContent: PropTypes.node,
  buttonColor: PropTypes.string,
  description: PropTypes.string,
  accept: PropTypes.string,
  maxFiles: PropTypes.number,
  maxSizeBytes: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

FilesInput.defaultProps = {
  value: [],
}

export default toUncontrollable({ value: 'onChange' })(FilesInput)
