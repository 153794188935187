import styled from 'styled-components'
import { colors, mixins } from 'styles'

export const theme = {
  hGutter: 20,
  vGutter: 20,
}

export const SubTitle = styled.div`
  ${mixins.smallHeader}
  font-size: 14px;
  letter-spacing: 0;
  color: ${colors.MED_GREY};
  margin-bottom: 20px;
`
