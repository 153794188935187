import styled from 'styled-components'
import { colors } from 'styles'

export const Content = styled.div`
  padding-top: 30px;
`

export const Placeholder = styled.div`
  height: 63px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Item = styled.div`
  margin-bottom: 30px;
`

export const BullhornIcon = styled.i.attrs({
  className: 'fa fa-bullhorn fa-2x',
})`
  margin-right: 10px;
`

export const Date = styled.span`
  font-size: 87%;
  color: ${colors.MED_GREY};
`

export const PaginationWrapper = styled.div`
  margin-top: 30px;
  text-align: center;
`
