import styled from 'styled-components'
import Dialog from 'components-v2/molecules/Dialog'
import { mixins, colors } from 'styles'

export const DialogHeader = styled(Dialog.Header)`
  text-align: center;
`

export const PrintHeader = styled.div`
  ${mixins.showPrint};
  text-align: left;
  padding-bottom: 20px;
  border-bottom: 1px solid ${colors.LIGHT_GREY};
  margin: 0px 0px 0px 15px;
`

export const ExtLogo = styled.img`
  float: right;
  margin: 0px 15px 0px 15px;
  max-height: 100px;
  max-width: 250px;
`
