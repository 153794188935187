import styled from 'styled-components'
import Checkbox from 'components-v2/atoms/Checkbox'
import MultiSelectFilterDropdown from 'components-v2/molecules/MultiSelectFilterDropdown'

export const ScoreCheckbox = styled(Checkbox)`
  ${(props) => props.color && `color: ${props.color};`}
`

export const StyledMultiSelectFilterDropdown = styled(
  MultiSelectFilterDropdown,
)`
  &:not(:first-child) > button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`
