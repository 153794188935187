import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import reject from 'lodash/reject'
import some from 'lodash/some'
import map from 'lodash/map'
import without from 'lodash/without'
import get from 'lodash/get'
import values from 'lodash/values'
import assign from 'lodash/assign'
import isEmpty from 'lodash/isEmpty'
import { ProjectStatusTypes } from 'constants/index'
import { useClientProjectFetcher, useUpdateClientProject } from 'apis'
import { useModal, useGlobalLoader, useAutoToggleStates } from 'hooks'
import NotificationManager from 'lib/notifications'
import { isProjectEditable } from 'lib/project'
import { takeLatest } from 'utils/api'
import { compose, withAuthorize, withReactQuery } from 'hocs'
import { getContentOffsetTop, scrollTo } from './utils'
import { SectionIdTypes } from './consts'
import useCRUDEventSubscriber from './useCRUDEventSubscriber'
import useProjectVendorsProps from './useProjectVendorsProps'
import EmailPreviewModal from './EmailPreviewModal'
import View from './View'

const ASSESSMENT_TEMPLATES_MAX_LIMIT = 6
const AUTOSAVED_AUTOHIDE_TIMEOUT = 2000

const Container = (props) => {
  const { data: project } = useClientProjectFetcher(
    {
      id: props.project.id,
    },
    { initialData: props.project, refetchOnMount: false },
  )
  const { mutateAsync: updateClientProject } = useUpdateClientProject()

  // Cancel the previous requests and take the latest request before completion
  // NOTE: Since we update project and show state (i.e. auto-saved message) per section, let's group requests by sectionId
  const apiUpdateProjectBySection = React.useMemo(
    () =>
      takeLatest(
        (id, data, sectionId, options) =>
          updateClientProject({ id, data, ...options }),
        {
          groupIdentity: (args) => args[2],
        },
      ),
    [updateClientProject],
  )

  const [detailsFormValid, setDetailsFormValid] = React.useState(true)
  const [emailInvitationsFormValid, setEmailInvitationsFormValid] =
    React.useState(true)
  const [autoSavedVisibleCollection, activateAutoSaved] = useAutoToggleStates(
    AUTOSAVED_AUTOHIDE_TIMEOUT,
  )
  const unsavedFormChangesRef = React.useRef({})
  const assessmentTemplatesEditable =
    project.status === ProjectStatusTypes.DRAFT
  const editable = isProjectEditable(project.status)
  const saveEnabled = detailsFormValid && emailInvitationsFormValid
  const [openEmailPreviewModal] = useModal(EmailPreviewModal)
  const [showLoader, hideLoader] = useGlobalLoader()
  const handleEventSubscribersSave = React.useCallback(
    () => activateAutoSaved(SectionIdTypes.NOTIFICATIONS),
    [activateAutoSaved],
  )

  const handleProjectVendorsSave = React.useCallback(
    () => activateAutoSaved(SectionIdTypes.VENDORS),
    [activateAutoSaved],
  )
  const handleProjectVendorTablePageChange = React.useCallback(() => {
    // Scroll to vendors section if needed
    const vendorsSectionElement = document.getElementById(
      SectionIdTypes.VENDORS,
    )
    const rect = vendorsSectionElement.getBoundingClientRect()
    if (rect.top < getContentOffsetTop()) {
      scrollTo(SectionIdTypes.VENDORS)
    }
  }, [])
  const projectVendorsProps = useProjectVendorsProps(
    project,
    props.showGloborg,
    handleProjectVendorsSave,
    handleProjectVendorTablePageChange,
  )

  const {
    eventSubscribers,
    isFetchingEventSubscribers,
    createEventSubscribers,
    updateEventSubscriber,
    deleteEventSubscriber,
  } = useCRUDEventSubscriber(
    project,
    projectVendorsProps,
    handleEventSubscribersSave,
  )

  const addableAssessmentTemplates = React.useMemo(
    () =>
      reject(props.assessmentTemplates, ({ id }) =>
        some(project.assessment_templates, { id }),
      ),
    [props.assessmentTemplates, project.assessment_templates],
  )

  const updateProject = React.useCallback(
    (projectData, sectionId) =>
      apiUpdateProjectBySection(project.id, { project: projectData }, sectionId)
        .then(() => {
          // Show auto-saved message
          activateAutoSaved(sectionId)
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            // Log error
            if (get(error, 'response.status')) {
              console.error(
                `API error > status: ${error.response.status} >`,
                error.response.data,
              )
            } else {
              console.error(error)
            }
            // Create error notification
            if (get(error, 'response.data.errors')) {
              NotificationManager.error(error.response.data.errors)
            } else {
              NotificationManager.error(
                `There was an error updating the project. Please refresh the page and try again.`,
              )
            }
          }
          throw error
        }),
    [project.id, activateAutoSaved, apiUpdateProjectBySection],
  )

  const handleSaveClose = React.useCallback(() => {
    if (saveEnabled) {
      const unsavedFormChanges = assign(
        {},
        ...values(unsavedFormChangesRef.current),
      )
      if (!isEmpty(unsavedFormChanges)) {
        // Make sure to save the unsaved changes
        const loaderId = showLoader()
        updateClientProject({ id: project.id, data: unsavedFormChanges })
          .then(() => {
            window.location = project.url
          })
          .catch((error) => {
            console.error(error)
            NotificationManager.error()
            hideLoader(loaderId)
          })
      } else {
        window.location = project.url
      }
    }
  }, [
    saveEnabled,
    project.url,
    project.id,
    updateClientProject,
    showLoader,
    hideLoader,
  ])

  const handleAssessmentTemplateAdd = React.useCallback(
    (id) => {
      if (
        project.assessment_templates.length >= ASSESSMENT_TEMPLATES_MAX_LIMIT
      ) {
        NotificationManager.error(
          `Only ${ASSESSMENT_TEMPLATES_MAX_LIMIT} templates are allowed per project`,
        )
        return
      }
      const loaderId = showLoader()
      const assessment_template_ids = [
        ...map(project.assessment_templates, 'id'),
        id,
      ]
      updateProject({ assessment_template_ids }, SectionIdTypes.TEMPLATES)
        .catch(() => {})
        .then(() => hideLoader(loaderId))
    },
    [project.assessment_templates, updateProject, showLoader, hideLoader],
  )

  const handleAssessmentTemplateDelete = React.useCallback(
    (id) => {
      const loaderId = showLoader()
      const assessment_template_ids = without(
        map(project.assessment_templates, 'id'),
        id,
      )
      updateProject({ assessment_template_ids }, SectionIdTypes.TEMPLATES)
        .catch(() => {})
        .then(() => hideLoader(loaderId))
    },
    [project.assessment_templates, updateProject, showLoader, hideLoader],
  )

  const handleEmailInvitationPreview = React.useCallback(() => {
    const previewUrl = `${project.url}/invite_preview`
    openEmailPreviewModal({ previewUrl })
  }, [project.url, openEmailPreviewModal])

  const updateProjectOrStashUnsaved = React.useCallback(
    (model, sectionId) => {
      unsavedFormChangesRef.current[sectionId] = model
      updateProject(model, sectionId)
        .then(() => {
          if (unsavedFormChangesRef.current[sectionId] === model) {
            delete unsavedFormChangesRef.current[sectionId]
          }
        })
        .catch(() => {})
    },
    [updateProject],
  )

  const handleDetailsFormSubmit = React.useCallback(
    (model) => {
      updateProjectOrStashUnsaved(model, SectionIdTypes.PROJECT_DETAILS)
    },
    [updateProjectOrStashUnsaved],
  )

  const handleEmailInvitationsFormSubmit = React.useCallback(
    (model) => {
      updateProjectOrStashUnsaved(model, SectionIdTypes.EMAIL_INVITATIONS)
    },
    [updateProjectOrStashUnsaved],
  )

  const handleNotificationsFormSubmit = React.useCallback(
    (model) => {
      updateProjectOrStashUnsaved(model, SectionIdTypes.NOTIFICATIONS)
    },
    [updateProjectOrStashUnsaved],
  )

  return (
    <View
      project={project}
      editable={editable}
      saveEnabled={saveEnabled}
      assessmentTemplatesEditable={assessmentTemplatesEditable}
      addableAssessmentTemplates={addableAssessmentTemplates}
      autoSavedVisibleCollection={autoSavedVisibleCollection}
      projectVendorsProps={projectVendorsProps}
      eventSubscribers={eventSubscribers}
      isFetchingEventSubscribers={isFetchingEventSubscribers}
      onSaveClose={handleSaveClose}
      onEmailInvitationPreview={handleEmailInvitationPreview}
      onDetailsFormSubmit={handleDetailsFormSubmit}
      onDetailsFormValidStateChange={setDetailsFormValid}
      onEmailInvitationsFormSubmit={handleEmailInvitationsFormSubmit}
      onEmailInvitationsFormValidStateChange={setEmailInvitationsFormValid}
      onAssessmentTemplateAdd={handleAssessmentTemplateAdd}
      onAssessmentTemplateDelete={handleAssessmentTemplateDelete}
      onAddUsersToNotify={createEventSubscribers}
      onEditEventSubscriber={updateEventSubscriber}
      onRemoveEventSubscriber={deleteEventSubscriber}
      onNotificationsFormSubmit={handleNotificationsFormSubmit}
    />
  )
}

Container.propTypes = {
  project: PropTypes.object.isRequired,
  showGloborg: PropTypes.bool,
  assessmentTemplates: PropTypes.array.isRequired,
}

export default compose(withAuthorize(), withReactQuery())(Container)
