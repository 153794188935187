import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as yup from 'yup'
import filter from 'lodash/filter'
import Button from 'components-v2/atoms/Button'
import Input from 'components-v2/atoms/Input'
import FormField from 'components-v2/molecules/FormField'
import Dialog from 'components-v2/molecules/Dialog'
import { AssessmentElementTypes } from 'constants/index'
import { Table, Tr, NaturalKeyTd, CustomKeyTd, LabelTd, THead } from './styles'

const customKeyValidation = yup
  .string()
  .nullable()
  .required('This is required')
  .max(12, 'Key can be a maximum of 12 characters')
  // eslint-disable-next-line func-names
  .test('uniqueCustomKey', 'Key must be unique', function (value) {
    return !value || filter(this.parent, (v) => v === value).length === 1
  })

const validationSchema = yup.object().shape({
  customKeys: yup.array().of(customKeyValidation),
})

const getCustomKeysObj = (elements, customKeys) => {
  const data = {}
  elements.forEach((e, index) => {
    data[e.id] = customKeys[index]
  })
  return data
}

const CustomKeysModal = ({
  elements,
  enabled,
  editable,
  onClose,
  onSave,
  onSaveAndEnable,
  onDisable,
  ...rest
}) => {
  const onSubmit = React.useCallback(
    ({ customKeys }, actions) =>
      onSaveAndEnable(getCustomKeysObj(elements, customKeys), actions),
    [elements, onSaveAndEnable],
  )

  const initialValues = React.useMemo(
    () => ({
      customKeys: elements.map((e) => e.custom_key),
    }),
    [elements],
  )

  return (
    <Dialog {...rest} onClose={onClose} size="lg">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Dialog.Header onClose={onClose}>Enter Custom Keys</Dialog.Header>
            <Dialog.Content>
              <p>
                Custom keys will be displayed to those taking assessments. Keys
                can be a maximum of 12 characters and must be unique.
              </p>
              <Table>
                <THead>
                  <NaturalKeyTd />
                  <CustomKeyTd>Custom Key</CustomKeyTd>
                  <LabelTd />
                </THead>
                {elements.map((e, index) => (
                  <Tr
                    key={e.id}
                    isHeading={
                      e.type === AssessmentElementTypes.SECTION_HEADING
                    }
                  >
                    <NaturalKeyTd sm={1}>{e.natural_key}</NaturalKeyTd>
                    <CustomKeyTd>
                      {editable ? (
                        <FormField
                          name={`customKeys.${index}`}
                          controlProps={{ autoFocus: index === 0 }}
                          preserveHelpTextSpace={false}
                        />
                      ) : (
                        <Input value={e.custom_key || ''} readOnly />
                      )}
                    </CustomKeyTd>
                    <LabelTd>{e.label}</LabelTd>
                  </Tr>
                ))}
              </Table>
            </Dialog.Content>
            <Dialog.Footer>
              <Button onClick={onClose}>Close</Button>
              {editable && (
                <>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    onClick={() =>
                      onSave(getCustomKeysObj(elements, values.customKeys))
                    }
                  >
                    Save
                  </Button>
                  {enabled ? (
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      onClick={onDisable}
                    >
                      Disable Custom Keys
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      onClick={handleSubmit}
                    >
                      Save and Enable Custom Keys
                    </Button>
                  )}
                </>
              )}
            </Dialog.Footer>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}

CustomKeysModal.propTypes = {
  elements: PropTypes.array.isRequired,
  enabled: PropTypes.bool,
  editable: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSaveAndEnable: PropTypes.func.isRequired,
  onDisable: PropTypes.func.isRequired,
}

export default React.memo(CustomKeysModal)
