/* eslint-disable react/prop-types */
import React from 'react'
import { createUltimatePagination, ITEM_TYPES } from 'react-ultimate-pagination'
import Button from 'components-v2/atoms/Button'
import ButtonGroup from 'components-v2/molecules/ButtonGroup'

export function createPageList(labels) {
  const Page = ({ value, isActive, isDisabled, onClick }) => (
    <Button
      active={isActive}
      color={isActive ? 'primary' : undefined}
      disabled={isDisabled}
      onClick={onClick}
    >
      {value}
    </Button>
  )

  const Ellipsis = ({ isDisabled, onClick }) => (
    <Button disabled={isDisabled} onClick={onClick}>
      {labels?.ellipsis ?? '...'}
    </Button>
  )

  const FirstPageLink = ({ isActive, isDisabled, onClick }) => (
    <Button disabled={isActive || isDisabled} onClick={onClick}>
      {labels?.firstPage ?? <>&laquo;</>}
    </Button>
  )

  const PreviousPageLink = ({ isActive, isDisabled, onClick }) => (
    <Button disabled={isActive || isDisabled} onClick={onClick}>
      {labels?.previousPage ?? <>&lt;</>}
    </Button>
  )

  const NextPageLink = ({ isActive, isDisabled, onClick }) => (
    <Button disabled={isActive || isDisabled} onClick={onClick}>
      {labels?.nextPage ?? <>&gt;</>}
    </Button>
  )

  const LastPageLink = ({ isActive, isDisabled, onClick }) => (
    <Button disabled={isActive || isDisabled} onClick={onClick}>
      {labels?.lastPage ?? <>&raquo;</>}
    </Button>
  )

  const itemTypeToComponent = {
    [ITEM_TYPES.PAGE]: Page,
    [ITEM_TYPES.ELLIPSIS]: Ellipsis,
    [ITEM_TYPES.FIRST_PAGE_LINK]: FirstPageLink,
    [ITEM_TYPES.PREVIOUS_PAGE_LINK]: PreviousPageLink,
    [ITEM_TYPES.NEXT_PAGE_LINK]: NextPageLink,
    [ITEM_TYPES.LAST_PAGE_LINK]: LastPageLink,
  }

  return createUltimatePagination({
    itemTypeToComponent,
    WrapperComponent: ButtonGroup,
  })
}

export default createPageList()
