import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// TODO: Support variant (i.e. h1, h2, subtitle, body, etc) and other css properties as props

const Component = styled.p`
  ${({ whiteSpace }) => whiteSpace && `white-space: ${whiteSpace};`}
`

const Typography = ({ component, ...rest }) => (
  <Component {...rest} as={component} />
)

Typography.propTypes = {
  component: PropTypes.string,
}

export default Typography
