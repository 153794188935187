import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Container, Wrapper, Spacer, BgArrow, FgArrow } from './styles'

// Constants
const ARROW_FG_SIZE = 5
const ARROW_BG_SIZE = 6
const MARGIN = 12
const ARROW_OFFSET = 22

function executeFunctionIfExist(object, key) {
  if (Object.prototype.hasOwnProperty.call(object, key)) {
    object[key]()
  }
}

function getBestPosition(containerRect, parent, position) {
  const parentRect = parent.getBoundingClientRect()
  switch (position) {
    case 'top':
      return parentRect.top - containerRect.height - MARGIN < 0
        ? 'bottom'
        : position
    case 'bottom':
      return parentRect.top +
        parentRect.height +
        containerRect.height +
        MARGIN >
        window.innerHeight
        ? 'top'
        : position
    case 'left':
      return parentRect.left - containerRect.width - MARGIN < 0
        ? 'right'
        : position
    case 'right':
      return parentRect.left + parentRect.width + containerRect.width + MARGIN >
        window.innerWidth
        ? 'left'
        : position
    default:
      return position
  }
}

function getContainerStyle(containerRect, parent, position, arrow, align) {
  const parentRect = parent.getBoundingClientRect()
  const scrollY =
    window.scrollY !== undefined ? window.scrollY : window.pageYOffset
  const scrollX =
    window.scrollX !== undefined ? window.scrollX : window.pageXOffset
  const top = scrollY + parentRect.top
  const left = scrollX + parentRect.left
  let alignOffset = 0
  const style = {}

  if (align === 'left') {
    alignOffset = -parentRect.width / 2 + ARROW_OFFSET
  } else if (align === 'right') {
    alignOffset = parentRect.width / 2 - ARROW_OFFSET
  }

  const stylesFromPosition = {
    left: () => {
      style.top = top + parentRect.height / 2 - containerRect.height / 2
      style.left = left - containerRect.width - MARGIN
    },
    right: () => {
      style.top = top + parentRect.height / 2 - containerRect.height / 2
      style.left = left + parentRect.width + MARGIN
    },
    top: () => {
      style.left =
        left - containerRect.width / 2 + parentRect.width / 2 + alignOffset
      style.top = top - containerRect.height - MARGIN
    },
    bottom: () => {
      style.left =
        left - containerRect.width / 2 + parentRect.width / 2 + alignOffset
      style.top = top + parentRect.height + MARGIN
    },
  }

  const stylesFromArrow = {
    left: () => {
      style.left = left + parentRect.width / 2 - ARROW_OFFSET + alignOffset
    },
    right: () => {
      style.left =
        left -
        containerRect.width +
        parentRect.width / 2 +
        ARROW_OFFSET +
        alignOffset
    },
    top: () => {
      style.top = top + parentRect.height / 2 - ARROW_OFFSET
    },
    bottom: () => {
      style.top =
        top + parentRect.height / 2 - containerRect.height + ARROW_OFFSET
    },
  }

  executeFunctionIfExist(stylesFromPosition, position)
  executeFunctionIfExist(stylesFromArrow, arrow)

  return style
}

function getArrowStyle(containerRect, position, arrow, bgColor, borderColor) {
  const fgColorBorder = `${ARROW_FG_SIZE + 2}px solid ${bgColor}`
  const fgTransBorder = `${ARROW_FG_SIZE}px solid transparent`
  const bgColorBorder = `${ARROW_BG_SIZE + 2}px solid ${borderColor}`
  const bgTransBorder = `${ARROW_BG_SIZE}px solid transparent`

  const fgStyle = {}
  const bgStyle = {}

  if (position === 'left' || position === 'right') {
    fgStyle.top = '50%'
    fgStyle.borderTop = fgTransBorder
    fgStyle.borderBottom = fgTransBorder
    fgStyle.marginTop = -ARROW_FG_SIZE

    bgStyle.borderTop = bgTransBorder
    bgStyle.borderBottom = bgTransBorder
    bgStyle.top = '50%'
    bgStyle.marginTop = -ARROW_BG_SIZE

    if (position === 'left') {
      fgStyle.right = -(ARROW_FG_SIZE + 1)
      fgStyle.borderLeft = fgColorBorder
      bgStyle.right = -(ARROW_BG_SIZE + 1)
      bgStyle.borderLeft = bgColorBorder
    } else {
      fgStyle.left = -(ARROW_FG_SIZE + 1)
      fgStyle.borderRight = fgColorBorder
      bgStyle.left = -(ARROW_BG_SIZE + 1)
      bgStyle.borderRight = bgColorBorder
    }

    if (arrow === 'top') {
      fgStyle.top = ARROW_OFFSET
      bgStyle.top = ARROW_OFFSET
    }
    if (arrow === 'bottom') {
      fgStyle.top = null
      fgStyle.bottom = ARROW_OFFSET - ARROW_FG_SIZE
      bgStyle.top = null
      bgStyle.bottom = ARROW_OFFSET - ARROW_BG_SIZE
    }
  } else {
    fgStyle.left = containerRect.width / 2 - ARROW_FG_SIZE
    fgStyle.borderLeft = fgTransBorder
    fgStyle.borderRight = fgTransBorder
    fgStyle.marginLeft = 0
    bgStyle.left = fgStyle.left - 1
    bgStyle.borderLeft = bgTransBorder
    bgStyle.borderRight = bgTransBorder
    bgStyle.marginLeft = 0

    if (position === 'top') {
      fgStyle.bottom = -(ARROW_FG_SIZE + 1)
      fgStyle.borderTop = fgColorBorder
      bgStyle.bottom = -(ARROW_BG_SIZE + 1)
      bgStyle.borderTop = bgColorBorder
    } else {
      fgStyle.top = -(ARROW_FG_SIZE + 1)
      fgStyle.borderBottom = fgColorBorder
      bgStyle.top = -(ARROW_BG_SIZE + 1)
      bgStyle.borderBottom = bgColorBorder
    }

    if (arrow === 'right') {
      fgStyle.left = null
      fgStyle.right = ARROW_OFFSET + 1 - ARROW_FG_SIZE
      bgStyle.left = null
      bgStyle.right = ARROW_OFFSET + 1 - ARROW_BG_SIZE
    }
    if (arrow === 'left') {
      fgStyle.left = ARROW_OFFSET + 1 - ARROW_FG_SIZE
      bgStyle.left = ARROW_OFFSET + 1 - ARROW_BG_SIZE
    }
  }

  return { fgStyle, bgStyle }
}

function checkWindowPosition(
  containerStyle,
  arrowStyle,
  containerRect,
  position,
  parent,
) {
  const newContainerStyle = { ...containerStyle }
  const newArrowStyle = { ...arrowStyle }
  if (position === 'top' || position === 'bottom') {
    if (containerStyle.left < 0) {
      if (parent) {
        let bgStyleRight = arrowStyle.bgStyle.right
        // For arrow = center
        if (!bgStyleRight) {
          bgStyleRight = containerRect.width / 2 - ARROW_BG_SIZE
        }
        const newBgRight = bgStyleRight - containerStyle.left + MARGIN
        newArrowStyle.bsStyle = {
          ...arrowStyle.bgStyle,
          right: newBgRight,
          left: null,
        }
        newArrowStyle.fgStyle = {
          ...arrowStyle.fgStyle,
          right: newBgRight + 1,
          left: null,
        }
      }
    } else {
      const rightOffset =
        containerStyle.left + containerRect.width - window.innerWidth
      if (rightOffset > 0) {
        const originalLeft = containerStyle.left
        newContainerStyle.left =
          window.innerWidth - containerRect.width - MARGIN
        newArrowStyle.fgStyle.marginLeft += originalLeft - containerStyle.left
        newArrowStyle.bgStyle.marginLeft += originalLeft - containerStyle.left
      }
    }
  }

  return [newContainerStyle, newArrowStyle]
}

function getSpacerStyle(position) {
  const spacerSize = MARGIN + 3 // Adding little margin to spacer size does the trick to fix tooltip disappearing bug on hover in Safari
  const style = {}

  if (position === 'left' || position === 'right') {
    style.top = 0
    style.bottom = 0
    style.width = spacerSize

    if (position === 'left') {
      style.right = -spacerSize
    } else {
      style.left = -spacerSize
    }
  } else {
    style.left = 0
    style.right = 0
    style.height = spacerSize

    if (position === 'top') {
      style.bottom = -spacerSize
    } else {
      style.top = -spacerSize
    }
  }

  return style
}

const initialDummySize = {
  width: 1000,
  height: 0,
}

const Card = ({
  parent,
  position,
  arrow,
  align,
  bgColor,
  borderColor,
  children,
  ...rest
}) => {
  const [container, setContainer] = useState()
  const containerRect = container?.getBoundingClientRect() || initialDummySize
  const bestPosition = getBestPosition(containerRect, parent, position)
  const containerStyle = getContainerStyle(
    containerRect,
    parent,
    bestPosition,
    arrow,
    align,
  )
  const arrowStyle = getArrowStyle(
    containerRect,
    bestPosition,
    arrow,
    bgColor,
    borderColor,
  )
  const [finalContainerStyle, finalArrowStyle] = checkWindowPosition(
    containerStyle,
    arrowStyle,
    containerRect,
    bestPosition,
    parent,
  )
  const spacerStyle = getSpacerStyle(bestPosition)
  return (
    <Container style={finalContainerStyle} ref={(ref) => setContainer(ref)}>
      <Wrapper bgColor={bgColor} borderColor={borderColor} {...rest}>
        {arrow && (
          <div>
            <Spacer style={spacerStyle} />
            <FgArrow style={finalArrowStyle.fgStyle} />
            <BgArrow style={finalArrowStyle.bgStyle} />
          </div>
        )}
        {children}
      </Wrapper>
    </Container>
  )
}

Card.propTypes = {
  parent: PropTypes.object.isRequired,
  // ^ Tooltip trigger DOM Element
  position: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  arrow: PropTypes.oneOf([null, 'center', 'top', 'right', 'bottom', 'left']),
  align: PropTypes.oneOf([null, 'center', 'right', 'left']),
  bgColor: PropTypes.string,
  borderColor: PropTypes.string,
  children: PropTypes.any,
}

Card.defaultProps = {
  position: 'top',
  arrow: 'center',
  align: null,
  bgColor: '#FFF',
  borderColor: '#CFD1D4',
}

export default Card
