import {
  AssistAssessmentStatusTypes,
  AssistAssessmentStatusOptions,
} from 'constants/index'

export const modeTypes = {
  MANAGER: 'manager',
}

export const statuses = [
  AssistAssessmentStatusTypes.UPLOADED,
  AssistAssessmentStatusTypes.IN_PROCESS,
  AssistAssessmentStatusTypes.IN_REVIEW,
  AssistAssessmentStatusTypes.REVIEWED,
  AssistAssessmentStatusTypes.COMPLETED,
].map((value) => ({
  value,
  label: AssistAssessmentStatusOptions.find((e) => e.value === value)?.label,
}))
