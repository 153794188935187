import { useCallback } from 'react'
import { useUpdateVendorAssessment } from 'apis'
import NotificationManager from 'lib/notifications'

export default function useUpdateAssessmentComment(
  assessmentId,
  setAssessment,
) {
  const { mutateAsync: updateVendorAssessment } = useUpdateVendorAssessment()
  return useCallback(
    (comment) => {
      // Let's update UI first and call api
      setAssessment((current) => ({
        ...current,
        comment,
      }))

      updateVendorAssessment({
        id: assessmentId,
        data: {
          assessment: {
            comment,
          },
        },
      }).catch(() =>
        NotificationManager.error(
          'There was an error saving your response. Please check your connection and try again later.',
        ),
      )
    },
    [setAssessment, updateVendorAssessment],
  )
}
