import React from 'react'
import PropTypes from 'prop-types'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import {
  useClientRiskDomainsFetcher,
  useClientAssessmentsFetcher,
  useClientOrganizationPartnersFetcher,
} from 'apis'
import { scrollTo } from 'utils/dom'
import { SortOrderTypes, OrganizationPartnerTypes } from 'constants/index'
import { sizes } from 'styles'
import useGetMoreInformation from './useGetMoreInformation'
import View from './View'

const Container = ({
  clientId,
  vendorId,
  globalOrgIntegrationMetrics,
  onRiskReconIssuesCountClick,
}) => {
  const [includeArchivedAssessments, setIncludeArchivedAssessments] =
    React.useState(false)
  const { data: riskDomains } = useClientRiskDomainsFetcher()
  const { data: partners } = useClientOrganizationPartnersFetcher()
  const assessmentsData = useClientAssessmentsFetcher({
    params: {
      page: 1,
      per_page: 5,
      ordered_by: 'sent_at',
      direction: SortOrderTypes.DESC,
      vendor_id: vendorId,
      exclude_archived: !includeArchivedAssessments,
      with_additional_attributes: true,
    },
  })
  const { isLoading } = globalOrgIntegrationMetrics
  const getMoreInformation = useGetMoreInformation(clientId, vendorId)

  React.useEffect(() => {
    const { hash } = window.location
    if (!isLoading && hash) {
      setTimeout(() => {
        const domId = hash.replace('#', '')
        scrollTo(domId, sizes.HEADER_HEIGHT + 20)
      }, 100)
    }
  }, [isLoading])

  const handleToggleArchivedAssessments = React.useCallback((e) => {
    setIncludeArchivedAssessments(e.target.checked)
  }, [])

  const assessmentForRiskDomainScores = React.useMemo(
    () => find(assessmentsData.data, (e) => !isEmpty(e.risk_domain_ratings)),
    [assessmentsData.data],
  )

  const riskReconMoreInformationAvailable = partners?.find(
    (e) => e.type === OrganizationPartnerTypes.RISK_RECON,
  )?.has_credentials

  return (
    <View
      privvaRiskAssessmentVisible
      riskReconMoreInformationAvailable={riskReconMoreInformationAvailable}
      loading={isLoading}
      hasError={
        globalOrgIntegrationMetrics.isError || !globalOrgIntegrationMetrics.data
      }
      vendorId={vendorId}
      assessmentsData={assessmentsData}
      assessmentForRiskDomainScores={assessmentForRiskDomainScores}
      riskDomains={riskDomains}
      includeArchivedAssessments={includeArchivedAssessments}
      integrationMetrics={globalOrgIntegrationMetrics.data}
      onToggleArchivedAssessments={handleToggleArchivedAssessments}
      onRiskReconIssuesCountClick={onRiskReconIssuesCountClick}
      onGetMoreInformation={getMoreInformation}
    />
  )
}

Container.propTypes = {
  clientId: PropTypes.string.isRequired,
  vendorId: PropTypes.string.isRequired,
  globalOrgIntegrationMetrics: PropTypes.object.isRequired,
  onRiskReconIssuesCountClick: PropTypes.func.isRequired,
}

export default Container
