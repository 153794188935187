import React from 'react'
import PropTypes from 'prop-types'
import BaseErrorView from 'components-v2/templates/ErrorView'

const ErrorView = ({
  imageUrl,
  errorCode,
  errorType,
  errorMessage,
  children,
}) => (
  <BaseErrorView
    imageUrl={imageUrl}
    heading={
      <>
        {errorCode} <span className="text-uppercase">[{errorType}]</span>
      </>
    }
  >
    {errorMessage && <p>{errorMessage}</p>}
    {children}
    <p>
      In the meantime, please go back to&nbsp;
      <a href="/dashboard">where you were</a>&nbsp;or head straight to&nbsp;
      <a href="/dashboard">your dashboard.</a>
    </p>
  </BaseErrorView>
)

ErrorView.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  errorCode: PropTypes.string.isRequired,
  errorType: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default ErrorView
