import styled from 'styled-components'
import Button from 'components-v2/atoms/Button'
import Icon from 'components-v2/atoms/Icon'

export const Wrapper = styled.div``

export const TrashIcon = styled(Icon).attrs({
  icon: 'fa fa-trash',
})`
  margin-right: 5px;
`

export const LinkButton = styled(Button).attrs({
  color: 'primary',
  variant: 'text',
})``
