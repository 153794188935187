import PropTypes from 'prop-types'
import React from 'react'
// import GradeLabel from 'components/elements/grade_label'
import { toLocalTimeString } from 'utils/date'
import config from 'config'
import { vendorRiskTierFormatterFactory } from 'lib/vendor'
import { StyledTable as Table, NoWrapCell } from './styles'
import FacetSetTable from '../FacetSetTable'

// const getCellValue = (url, value) => (
//   <span>{value > 0 ? <a href={url}>{value}</a> : value}</span>
// )

const vendorRiskTierFormatter = vendorRiskTierFormatterFactory()

const VendorTable = ({ data, totals }) => (
  <Table responsive>
    <thead>
      <tr>
        <th>Name</th>
        <th>Risk Tier</th>
        <th>Tags</th>
        <th>Created</th>
      </tr>
    </thead>
    {data && (
      <tbody>
        {data.map((r) => (
          <>
            <tr key={r.name}>
              <td>
                <NoWrapCell>
                  <a href={r.path}>{r.name}</a>
                </NoWrapCell>
              </td>
              <td>
                {vendorRiskTierFormatter({ getValue: () => r.risk_tier })}
              </td>
              <td>{r.vendor_tag_list.join(', ')}</td>
              <td>{toLocalTimeString(r.created_at, config.tableDateFormat)}</td>
            </tr>
            <FacetSetTable
              facetableSets={r.facetable_sets}
              openFacetSets={r.open_facet_sets}
            />
          </>
        ))}
      </tbody>
    )}
    {totals && (
      <tfoot>
        <tr>
          <th>Total</th>
          <th>{data.length}</th>
        </tr>
      </tfoot>
    )}
  </Table>
)

VendorTable.propTypes = {
  data: PropTypes.array,
  totals: PropTypes.array,
}

export default VendorTable
