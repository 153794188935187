import styled from 'styled-components'
import { components } from 'react-select'

export const Option = styled(components.Option)`
  &:before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: ${(props) => props.data.color};
    margin-right: 7px;
  }
`

export const SingleValue = styled(components.SingleValue)`
  &:before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: ${(props) => props.data.color};
    margin-right: 7px;
  }
`

export const Row = styled.div`
  display: flex;
  align-items: center;

  > *:first-child {
    flex: auto;
    margin-right: 8px;
  }
`
