import styled, { css } from 'styled-components'
import { EyeSlash } from '@styled-icons/fa-solid/EyeSlash'
import { File } from '@styled-icons/fa-regular/File'
import { colors, mixins, media } from 'styles'
import Icon from 'components-v2/atoms/Icon'

export const UploadIcon = styled(Icon).attrs({ icon: 'fa fa-cloud-upload' })`
  font-size: 44px;
  color: #bcc6ce;
  margin-bottom: 14px;
`

export const KeyColumn = styled.div`
  flex: none;
  width: ${(p) => (p.customKey ? 110 : 52)}px;
`

export const QuestonColumn = styled.div`
  flex: 1;
  min-width: 0;
`

export const ActionColumn = styled.div`
  flex: none;
  width: 240px;

  ${media.mobile`
    padding-left: 60px !important;
    width: 100%;
  `}
`

export const QuestionLabel = styled.div`
  font-weight: bold;
  white-space: pre-wrap;
`

export const CommentWrapper = styled.div`
  overflow-wrap: break-word;
`

export const EyeSlashIcon = styled(EyeSlash)`
  width: 1em;
  height: 1em;
`

export const Actions = styled.div`
  > * {
    margin-bottom: 10px;
  }
`

export const AnswerFormWrapper = styled.div`
  margin: 8px 0;
`
export const DisabledInfo = styled.div`
  text-align: right;
  font-style: italic;
  ${mixins.showPrint}
`

export const Row = styled.div`
  display: flex;

  > * {
    padding: 8px;
  }

  ${(props) =>
    props.skipped &&
    css`
      ${AnswerFormWrapper},
      ${Actions} {
        ${mixins.hidePrint}
      }
    `}

  ${media.mobile`
    flex-wrap: wrap;
  `}
`

export const ModifiedBy = styled.p`
  font-size: 15px;
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
`

export const RequiredLabel = styled.span`
  font-weight: bold;
  color: ${colors.DANGER};
`

export const FileList = styled.div`
  margin-top: 8px;
`
export const FileRow = styled.div`
  display: flex;
`
export const FileItem = styled.a`
  vertical-align: top;
`
export const FileIcon = styled(File)`
  width: 0.9em;
  height: 0.9em;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 3px;
  vertical-align: top;
`
