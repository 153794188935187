import React from 'react'
import {
  FilterOptionTypes,
  SecurityObjectiveStatusTypes,
} from 'constants/index'

export const ViewTypes = {
  LIST: 'list',
  GRID: 'grid',
}

export const StatusFilterOptions = {
  ALL: {
    value: '',
    label: 'All statuses',
  },
  CONNECTED: {
    value: SecurityObjectiveStatusTypes.CONNECTED,
    label: 'All connected',
  },
}

export const ViewTypeFilterOptions = {
  LIST: {
    key: ViewTypes.LIST,
    type: FilterOptionTypes.SINGLE,
    label: [<i className="fa fa-th-list"></i>, 'List'],
  },
  GRID: {
    key: ViewTypes.GRID,
    type: FilterOptionTypes.SINGLE,
    label: [<i className="fa fa-th-large"></i>, 'Grid'],
  },
}
