import React from 'react'
import PropTypes from 'prop-types'
import Toggle from 'components-v2/atoms/Toggle'
import Tooltip from 'components-v2/molecules/Tooltip'
import { Row, Col } from 'components-v2/organisms/Layout'
import {
  useUserSubscriptionsFetcher,
  useCreateUserSubscription,
  useSubscribeUserSubscription,
  useUnsubscribeUserSubscription,
  useSubscribeUserSubscriptionEmail,
  useUnsubscribeUserSubscriptionEmail,
} from 'apis'
import NotificationManager from 'lib/notifications'
import { getPageUrl } from 'utils/url'
import useUpdateUser from '../useUpdateUser'

const boolFormatter = (val) => (val ? [false, true] : false)

const Notifications = ({ currentUser }) => {
  const userId = currentUser.id
  const onUpdate = useUpdateUser()
  const { mutateAsync: createUserSubscription } = useCreateUserSubscription()
  const { mutateAsync: subscribeUserSubscription } =
    useSubscribeUserSubscription()
  const { mutateAsync: unsubscribeUserSubscription } =
    useUnsubscribeUserSubscription()
  const { mutateAsync: subscribeUserSubscriptionEmail } =
    useSubscribeUserSubscriptionEmail()
  const { mutateAsync: unsubscribeUserSubscriptionEmail } =
    useUnsubscribeUserSubscriptionEmail()
  const { data: subscriptionsData, isLoading } = useUserSubscriptionsFetcher({
    userId,
  })
  const subscriptions = React.useMemo(() => {
    if (!subscriptionsData) {
      return undefined
    }
    // Merge the two arrays but track which ones are stored settings and which
    // ones are settings which are available to write to. If `applied` is false
    // it is an unset default value.
    return subscriptionsData.subscriptions
      .map((el) => ({ ...el, applied: true }))
      .concat(
        subscriptionsData.unconfigured_notification_keys.map((el) => ({
          ...el,
          applied: false,
        })),
      )
      .sort((a, b) => (a.key > b.key ? 1 : -1))
  }, [subscriptionsData])

  const toggleSubscription = React.useCallback(
    async (subscription, value) => {
      try {
        if (subscription.applied) {
          const mutation = value
            ? subscribeUserSubscription
            : unsubscribeUserSubscription
          await mutation({ userId, id: subscription.id })
        } else {
          await createUserSubscription({
            userId,
            data: {
              subscription: {
                key: subscription.key,
                subscribing: boolFormatter(value),
                subscribing_to_email: boolFormatter(
                  subscription.subscribing_to_email,
                ),
              },
            },
          })
        }
      } catch (error) {
        console.error(error)
        NotificationManager.error()
      }
    },
    [
      userId,
      subscribeUserSubscription,
      unsubscribeUserSubscription,
      createUserSubscription,
    ],
  )

  const toggleSubscriptionEmail = React.useCallback(
    async (subscription, value) => {
      try {
        if (subscription.applied) {
          const mutation = value
            ? subscribeUserSubscriptionEmail
            : unsubscribeUserSubscriptionEmail
          await mutation({ userId, id: subscription.id })
        } else {
          await createUserSubscription({
            userId,
            data: {
              subscription: {
                key: subscription.key,
                subscribing: boolFormatter(subscription.subscribing),
                subscribing_to_email: boolFormatter(value),
              },
            },
          })
        }
      } catch (error) {
        console.error(error)
        NotificationManager.error()
      }
    },
    [
      userId,
      subscribeUserSubscriptionEmail,
      unsubscribeUserSubscriptionEmail,
      createUserSubscription,
    ],
  )

  return (
    <div>
      <h3>Notification Settings</h3>
      <br />
      <Row>
        <Col xs={4}>
          <label>
            All email notifications{' '}
            <Tooltip>
              Global notification setting. This will disable notifications
              across all organizations.
            </Tooltip>
          </label>
        </Col>
        <Col xs={8}>
          <Toggle
            checked={currentUser.email_notifications}
            onChange={(val) => onUpdate({ email_notifications: val })}
          />
        </Col>
      </Row>
      <br />
      {isLoading && (
        <p>
          <i className="fa fa-large fa-spinner fa-spin" />{' '}
          <i>Loading notification settings</i>
        </p>
      )}
      {!isLoading && !subscriptions?.length && (
        <h5>No subscription settings available</h5>
      )}
      {!isLoading && subscriptions?.length && (
        <table className="table">
          <thead>
            <tr>
              <th>Topic</th>
              <th>Notifications</th>
              <th>Email Notifications</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {subscriptions.map((sub) => (
              <tr key={sub.key}>
                <td>{sub.label}</td>
                <td>
                  <Toggle
                    checked={sub.subscribing}
                    onChange={(val) => toggleSubscription(sub, val)}
                  />
                </td>
                <td>
                  <Toggle
                    checked={sub.subscribing_to_email && sub.subscribing}
                    onChange={(val) => toggleSubscriptionEmail(sub, val)}
                    disabled={!sub.subscribing}
                  />
                </td>
                <td>
                  <a
                    href={getPageUrl(
                      'userNotifications',
                      { id: userId },
                      {
                        filtered_by_key: sub.key,
                        redirect: encodeURIComponent(
                          window.location.pathname + window.location.hash,
                        ),
                      },
                    )}
                  >
                    View Notifications
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}

Notifications.propTypes = {
  currentUser: PropTypes.object.isRequired,
}

export default Notifications
