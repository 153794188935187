import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import Privva from 'Privva'
import { convertStringArrayToOptions } from 'utils/array'
import InputField from 'components-v2/molecules/FormField/InputField'
import FormField, { BaseField } from 'components-v2/molecules/FormField'
import { Row } from './styles'

function AmountFormField({ onChange, ...rest }) {
  const handleChange = useCallback(
    (val) => {
      // avoid NaN hosing things up on a subsequent update
      // const newVal = val.length > 0 ? parseInt(val) : null;
      let newVal = null
      if (val.length > 0) {
        newVal = val.replace(/ /g, '')
        const suffix = newVal.charAt(newVal.length - 1).toLowerCase()
        const k = suffix === 'k' ? 1000 : 1
        const m = suffix === 'm' ? 1000000 : 1
        newVal = newVal.replace(/[$,]/g, '')
        newVal = newVal.replace(/[km]$/gi, '')
        newVal = newVal * k * m
        newVal = parseInt(newVal, 10)
      }
      newVal = Number.isNaN(newVal) ? null : newVal
      onChange(newVal)
    },
    [onChange],
  )
  return <InputField {...rest} onChange={handleChange} />
}
AmountFormField.propTypes = {
  onChange: PropTypes.func.isRequired,
}

function ContractValueFieldGroup({ name, size, onChange, ...rest }) {
  return (
    <BaseField {...rest} size={size} preserveHelpTextSpace={false}>
      <Row>
        <FormField
          name={`${name}.amount`}
          label="Amount"
          placeholder="Amount"
          component={AmountFormField}
          size={size}
        />
        <FormField
          name={`${name}.term`}
          label="Term"
          type={FormField.types.SEARCHABLE_SELECT}
          options={convertStringArrayToOptions(
            Privva.Utils.vendor_details_options.vendorContractTerm,
          )}
          controlProps={{
            isClearable: true,
          }}
          size={size}
        />
        <FormField
          name={`${name}.length`}
          label="Length"
          type={FormField.types.SEARCHABLE_SELECT}
          options={convertStringArrayToOptions(
            Privva.Utils.vendor_details_options.vendorContractLength,
          )}
          controlProps={{
            isClearable: true,
          }}
          size={size}
        />
      </Row>
    </BaseField>
  )
}

ContractValueFieldGroup.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default ContractValueFieldGroup
