import styled from 'styled-components'
import Card from 'components-v2/organisms/Card'
import { colors, sizes } from 'styles'

export const Wrapper = styled.div`
  color: ${colors.BODY_TEXT};
  margin-top: 15px;

  @media (min-width: 992px) {
    display: flex;
  }
`

export const Left = styled.div`
  flex: 1;
  margin-bottom: ${sizes.GRID_HGUTTER}px;
`

export const Right = styled.div`
  @media (min-width: 992px) {
    flex: none;
    width: 400px;
    margin-left: 28px;
  }
`

export const Title = styled.h3`
  margin: 0 0 15px 0;
  color: ${colors.HEADING};
`

export const SubTitle = styled.h5`
  margin: 0 0 15px 0;
  text-transform: uppercase;
  color: ${colors.HEADING};
`

export const FrameworkLabel = styled.span`
  font-weight: 900;
  padding-right: 10px;
`

export const StatusDetailsSegment = styled(Card.Segment)`
  position: relative;
`

export const StatusDotWrapper = styled.div`
  position: absolute;
  right: 3px;
  top: 3px;
`

export const ToolLogo = styled.img`
  max-width: 100%;
  height: auto;
  max-height: 70px;
  margin: 10px 0;
`
