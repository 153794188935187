import styled from 'styled-components'
import { Col } from 'components-v2/organisms/Layout'
import { colors } from 'styles'

export const Wrapper = styled.div`
  color: ${colors.BODY_TEXT};
`

export const StyledCol = styled(Col)`
  margin-bottom: 20px;
`
