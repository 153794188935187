import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import Card from 'components-v2/organisms/Card'
import { Inner, NumberLink, NumberLabel, Title } from './styles'

const StatsWidget = ({ details, ...rest }) => (
  <Card {...rest} padding>
    {map(details, (detail, index) => {
      const detailText = detail.number ? detail.number : '--'
      const NumberComponent = detail.canLink ? NumberLink : NumberLabel
      return (
        <Card.Segment key={index}>
          <Inner>
            <NumberComponent active={detail.active} href={detail.link}>
              {detailText}
            </NumberComponent>
            <Title>{detail.title}</Title>
            {detail.canLink && <a href={detail.link}>View all</a>}
          </Inner>
        </Card.Segment>
      )
    })}
  </Card>
)

StatsWidget.propTypes = {
  details: PropTypes.array.isRequired,
}

export default React.memo(StatsWidget)
