import addHours from 'date-fns/addHours'
import Privva from 'Privva'

export default {
  data: {
    active_vendors: 100,
    average_score: {
      score: 252,
      label: 'Great!',
    },
    event_summary: {
      events: {
        critical: 5,
        unknown: 15,
        ok: 80,
      },
    },
    open_issues: {
      count: 12,
      trend: -2,
      history: [
        {
          date: '2019-06-10T00:00:00.000Z',
          count: 12,
        },
        {
          date: '2019-06-09T00:00:00.000Z',
          count: 8,
        },
        {
          date: '2019-06-08T00:00:00.000Z',
          count: 10,
        },
        {
          date: '2019-06-07T00:00:00.000Z',
          count: 9,
        },
        {
          date: '2019-06-06T00:00:00.000Z',
          count: 12,
        },
        {
          date: '2019-06-05T00:00:00.000Z',
          count: 9,
        },
        {
          date: '2019-06-04T00:00:00.000Z',
          count: 7,
        },
      ],
    },
    vendors_by_score_range: {
      updated_at: addHours(new Date(), -1).toISOString(),
      ranges: [
        {
          label: '0-50',
          value: 4,
        },
        {
          label: '51-100',
          value: 3,
        },
        {
          label: '101-150',
          value: 17,
        },
        {
          label: '151-200',
          value: 23,
        },
        {
          label: '201-250',
          value: 24,
        },
        {
          label: '251-300',
          value: 29,
        },
      ],
    },
    recent_changes: {
      vendor_list: [
        {
          name: 'Saturn Networks',
          score: 250,
          trend: -9,
        },
        {
          name: 'Checkmark Solutions',
          score: 145,
          trend: -7,
        },
        {
          name: 'Solid Systems',
          score: 80,
          trend: 5,
        },
        {
          name: Privva.Utils.brand.programName,
          score: 280,
          trend: 4,
        },
        {
          name: 'Theseus AI',
          score: 275,
          trend: 3,
        },
      ],
    },
    top_sources: {
      source_list: [
        {
          name: 'Lastpass',
          logo_url:
            'https://privva-resources-public.s3.amazonaws.com/ss/logos/symantec_logo.png',
          count: 20,
        },
        {
          name: Privva.Utils.brand.programName,
          logo_url:
            'https://privva-resources-public.s3.amazonaws.com/ss/logos/privva_logo.png',
          count: 15,
        },
        {
          name: 'Alert Logic',
          logo_url:
            'https://privva-resources-public.s3.amazonaws.com/ss/logos/alertlogic_logo.png',
          count: 12,
        },
        {
          name: 'Symantec',
          logo_url:
            'https://privva-resources-public.s3.amazonaws.com/ss/logos/symantec_logo.png',
          count: 8,
        },
        {
          name: 'Github',
          logo_url:
            'https://privva-resources-public.s3.amazonaws.com/ss/logos/github_logo.png',
          count: 7,
        },
      ],
    },
    category_summaries: [
      {
        category: 'Application Security',
        score: 66,
        status_counts: [
          {
            status: 'not_applicable',
            value: 44,
          },
          {
            status: 'active',
            value: 66,
          },
        ],
        objectives: [
          {
            id: 'c2a6bf81-4e08-4431-b717-313a61214854',
            name: 'Code Analysis',
            enabled: true,
            average_score: 250,
          },
          {
            id: '6beb5dbb-53f5-42ab-b30b-eddc82331bc7',
            name: 'Software Development Life Cycle',
            enabled: false,
            average_score: 0,
          },
          {
            id: '653fa12e-1396-4168-8ae4-218455891bed',
            name: 'Change Management',
            enabled: true,
            average_score: 232,
          },
        ],
      },
      {
        category: 'Network Security',
        score: 65,
        status_counts: [
          {
            status: 'not_applicable',
            value: 0,
          },
          {
            status: 'active',
            value: 4,
          },
          {
            status: 'not_reporting',
            value: 2,
          },
        ],
        objectives: [
          {
            id: '5dfd0c1e-7bd6-496c-a4cc-a4e9cdf023ac',
            name: 'Identity & Access Management',
            enabled: true,
            average_score: 135,
          },
          {
            id: 'c1dba479-d753-4480-8703-0decac105b9d',
            name: 'Vulnerability Scan',
            enabled: true,
            average_score: 245,
          },
          {
            id: '6cd13f2d-1c0e-487c-9006-660e81a14dc8',
            name: 'Intrusion Detection',
            enabled: true,
            average_score: 87,
          },
          {
            id: 'cb8fbd25-3a3f-4cbc-94c3-765dfb9ed323',
            name: 'Infrastructure Security',
            enabled: true,
            average_score: 275,
          },
          {
            id: 'b1a381c2-14fe-4f2a-921c-e1ed38d44717',
            name: 'IT Operations Management',
            enabled: true,
            average_score: 212,
          },
        ],
      },
      {
        category: 'Organization Security',
        score: 75,
        status_counts: [
          {
            status: 'not_applicable',
            value: 1,
          },
          {
            status: 'active',
            value: 7,
          },
          {
            status: 'not_reporting',
            value: 2,
          },
        ],
        objectives: [
          {
            id: 'e57573f0-84b0-0137-9ef3-644bf001fadb',
            name: 'Endpoint Protection',
            enabled: true,
            average_score: 175,
          },
          {
            id: 'ed084010-84b0-0137-9ef4-644bf001fadb',
            name: 'Vendor Risk Management',
            enabled: true,
            average_score: 205,
          },
          {
            id: 'c3baafdb-a396-4e3b-bffa-a8e85dac860c',
            name: 'Background Checks',
            enabled: true,
            average_score: 65,
          },
          {
            id: 'cf5db5cf-b63d-448c-af96-ea9b42638581',
            name: 'Mobile Device Management',
            enabled: true,
            average_score: 245,
          },
        ],
      },
    ],
  },
}
