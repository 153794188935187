import React from 'react'
import PropTypes from 'prop-types'
import ahoy from 'ahoy.js'
import Dialog from 'components-v2/molecules/Dialog'
import VideoPlayer from 'components/shared/VideoPlayer'
import {
  usePromotionsForPlacementFetcher,
  useUpdatePromotionClickCount,
  useInitiateCallToAction,
} from 'apis'
import {
  Callout,
  ModalDiv,
  Message,
  StyledModalHeader,
  StyledButton,
  ModalTitle,
} from './styles'

const PROMOTION = 'Promotion'
const PromotionActions = {
  PLAY: 'Play',
}

const PromotionalModal = ({
  parentOrgId,
  targetOrgId,
  placement,
  promotionType,
  onClose,
  ...rest
}) => {
  const path =
    window.location && window.location.pathname
      ? window.location.pathname
      : '<unknown>'

  const [tellMeMore, setTellMeMore] = React.useState(false)
  const { mutateAsync: updatePromotionClickCount } =
    useUpdatePromotionClickCount()
  const { mutateAsync: initiateCallToAction } = useInitiateCallToAction()
  const { data: promotion } = usePromotionsForPlacementFetcher(
    {
      params: {
        placement,
        promotion_type: promotionType,
        parent_org_id: parentOrgId,
      },
    },
    { enabled: !!(placement && promotionType && parentOrgId) },
  )

  const onCallToActionClick = () => {
    updatePromotionClickCount({ id: promotion.id })
    initiateCallToAction({ id: promotion.id })
    setTellMeMore(true)
  }

  const recordPlayEvent = () => {
    ahoy.track(PROMOTION, {
      _path: path,
      promotionAction: PromotionActions.PLAY,
      promotionId: promotion.id,
      placement,
      promotionType,
      parentOrganizationId: parentOrgId,
    })
  }

  if (!promotion) {
    return undefined
  }

  return (
    <Dialog {...rest} onClose={onClose}>
      <ModalDiv>
        <StyledModalHeader onClose={onClose}>
          <ModalTitle
            dangerouslySetInnerHTML={{
              __html: promotion.title,
            }}
          />
        </StyledModalHeader>
        <Dialog.Content>
          {promotion.config.video_text && (
            <Callout
              dangerouslySetInnerHTML={{
                __html: promotion.config.video_text,
              }}
            />
          )}
          {promotion.config.video_url && (
            <VideoPlayer
              url={promotion.config.video_url}
              onStart={recordPlayEvent}
            />
          )}
          <Callout
            dangerouslySetInnerHTML={{
              __html: promotion.config.call_to_action_text,
            }}
          />
          {!tellMeMore && (
            <Callout>
              <StyledButton
                onClick={onCallToActionClick}
                dangerouslySetInnerHTML={{
                  __html: promotion.config.call_to_action_button_text,
                }}
              />
            </Callout>
          )}
          {tellMeMore && (
            <Message
              dangerouslySetInnerHTML={{
                __html: promotion.config.call_to_action_response,
              }}
            />
          )}
        </Dialog.Content>
      </ModalDiv>
    </Dialog>
  )
}

PromotionalModal.propTypes = {
  parentOrgId: PropTypes.string.isRequired,
  targetOrgId: PropTypes.string.isRequired,
  placement: PropTypes.string.isRequired,
  promotionType: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default PromotionalModal
