import React, { useLayoutEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import mergeRefs from 'react-merge-refs'
import styled from 'styled-components'
import { styles } from 'components-v2/atoms/Input'
import Textarea from 'components-v2/atoms/Textarea'
import { mixins } from 'styles'

const TextWrapper = styled.div`
  ${styles}
  height: auto;
  min-height: 54px;
  ${mixins.showPrint}
`

const StyledTextarea = styled(Textarea)`
  ${mixins.hidePrint}
`

const BUFFER = 20

const AutoSizeTextarea = (props) => {
  const textareaRef = useRef()

  useLayoutEffect(() => {
    const adjust = (shrunk) => {
      const el = textareaRef.current
      if (!el.offsetWidth > 0 && !el.offsetHeight > 0) {
        // element is hidden, consumes no space in the document.
        return
      }

      if (el.scrollHeight > el.offsetHeight - 2) {
        // eslint-disable-next-line no-param-reassign
        el.style.height = `${el.scrollHeight + BUFFER}px`
      } else if (!shrunk) {
        // eslint-disable-next-line no-param-reassign
        el.style.height = ''
        adjust(true)
      }

      if (el.scrollWidth > el.offsetWidth) {
        // eslint-disable-next-line no-param-reassign
        el.style.width = `${el.scrollWidth + BUFFER}px`
      } else if (!shrunk) {
        // eslint-disable-next-line no-param-reassign
        el.style.width = ''
        adjust(true)
      }
    }
    adjust()
  }, [props.value])

  return (
    <div>
      <StyledTextarea
        {...props}
        ref={mergeRefs([textareaRef, props.inputRef])}
      />
      <TextWrapper className={props.className}>
        {props.value ? props.value : props.placeholder}
      </TextWrapper>
    </div>
  )
}

AutoSizeTextarea.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  className: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
}

export default AutoSizeTextarea
