import React, { useCallback } from 'react'
import map from 'lodash/map'
import { useGlobalLoader, useConfirm, useCurrent } from 'hooks'
import NotificationManager from 'lib/notifications'
import { useUpdateClientElement } from 'apis'
import { getElementById, checkNewElementDataForTrouble } from './utils'
import { ProblemList } from './styles'

// Callback when edit an element field
export default function useChangeElementField(assessmentTemplate) {
  const { mutateAsync: updateClientElement } = useUpdateClientElement()
  const assessmentTemplateRef = useCurrent(assessmentTemplate)
  const [showLoader, hideLoader] = useGlobalLoader()
  const openConfirm = useConfirm()

  return useCallback(
    (elementId, field, value) => {
      const element = getElementById(
        assessmentTemplateRef.current.elements,
        elementId,
      )
      const problems = checkNewElementDataForTrouble(
        { [field]: value },
        element,
      )

      const callUpdateElement = () => {
        const loaderId = showLoader({ withDelay: true })
        updateClientElement({
          id: elementId,
          data: { element: { [field]: value } },
        })
          .catch(() => NotificationManager.error())
          .then(() => hideLoader(loaderId))
      }

      if (problems.length > 0) {
        openConfirm({
          title: 'Are you sure?',
          body: (
            <>
              This change may result in invalid question automations, which will
              be removed if you proceed:
              <br />
              <ProblemList>
                {map(problems, (p, idx) => (
                  <li key={idx}>{p}</li>
                ))}
              </ProblemList>
            </>
          ),
          confirmText: "Yes, I'm sure",
          onConfirm: callUpdateElement,
        })
      } else {
        callUpdateElement()
      }
    },
    [showLoader, hideLoader, openConfirm],
  )
}
