import React from 'react'
import PropTypes from 'prop-types'
import includes from 'lodash/includes'
import Checkbox from 'components-v2/atoms/Checkbox'
import Icon from 'components-v2/atoms/Icon'
import Button from 'components-v2/atoms/Button'
import Table from 'components-v2/molecules/Table'
import Toolbar, { SearchInput } from 'components-v2/molecules/Table/Toolbar'
import CardRadioGroup from 'components-v2/molecules/CardRadioGroup'
import PageHeader from 'components-v2/organisms/PageHeader'
import { projectStatusFormatterFactory } from 'lib/project'
import { ProjectStatusTypes, LabelTypes } from 'constants/index'
import { useAuthorizations, useAuthManager } from 'hooks'
import { toLocalTimeString } from 'utils/date'
import config from 'config'
import Menu from 'components-v2/molecules/Menu'
import { getLaunchDate, getCloseDate, progressFormatterFactory } from './utils'
import { TableWrapper, EllipsisIconButton } from './styles'

const NotEditableProjectStatusTypes = [
  ProjectStatusTypes.REVIEWED,
  ProjectStatusTypes.CLOSED,
  ProjectStatusTypes.ARCHIVED,
]

const statusFormatter = projectStatusFormatterFactory()
const progressFormatter = progressFormatterFactory()
const getColumns = (onAddProjectVendor, authManager) => [
  {
    id: 'friendly_name',
    header: 'Project',
    cell: (props) =>
      authManager.userCan('show', props.row.original) ? (
        <a href={props.row.original.url}>{props.getValue()}</a>
      ) : (
        props.getValue()
      ),
    width: '35%',
  },
  {
    id: 'project_vendors',
    header: LabelTypes.VENDORS,
    cell: (info) => (info.getValue() ? info.getValue().length : ''),
    width: '10%',
  },
  {
    id: 'earliest_launch_at',
    header: 'First launch',
    cell: (info) =>
      toLocalTimeString(
        getLaunchDate(info.row.original),
        config.tableDateFormat,
      ),
    width: '15%',
  },
  {
    id: 'latest_due_at',
    header: 'Last close',
    cell: (info) =>
      toLocalTimeString(
        getCloseDate(info.row.original),
        config.tableDateFormat,
      ),
    width: '15%',
  },
  {
    id: 'status',
    header: 'Status',
    cell: statusFormatter,
    width: '15%',
  },
  {
    id: 'progress',
    header: 'Progress',
    cell: progressFormatter,
    enableSorting: false,
    width: '20%',
  },
  {
    id: 'dfAction',
    header: '',
    cell: (props) => {
      if (
        !authManager.userCan('update', props.row.original) ||
        includes(NotEditableProjectStatusTypes, props.row.original.status)
      ) {
        return null
      }
      return (
        <Menu placement="top-start" offset={8} portal>
          <Menu.Button as={EllipsisIconButton} />
          <Menu.Items size="small" arrow>
            <Menu.Item
              onClick={() => onAddProjectVendor(props.row.original.id)}
            >
              <Icon icon="fa fa-plus-circle" />
              Add vendor
            </Menu.Item>
          </Menu.Items>
        </Menu>
      )
    },
    enableSorting: false,
  },
]

const View = ({
  projects,
  canCreate,
  statusFilter,
  statusFiltersWithCount,
  archivedSelected,
  loading,
  page,
  sizePerPage,
  sortField,
  sortOrder,
  searchText,
  totalSize,
  onStatusFilterChange,
  onToggleArchived,
  onAddProjectVendor,
  onPaginationChange,
  onSortingChange,
  onSearchChange,
  onCreateProjectClick,
}) => {
  const [canSearchArchived] = useAuthorizations('search_archived')
  const authManager = useAuthManager()
  const columns = React.useMemo(
    () => getColumns(onAddProjectVendor, authManager),
    [onAddProjectVendor, authManager],
  )
  return (
    <>
      <PageHeader title="Projects">
        {canCreate && (
          <Button color="primary" onClick={onCreateProjectClick}>
            <i className="fa fa-plus" />
            New Project
          </Button>
        )}
      </PageHeader>
      <CardRadioGroup
        label="Project status"
        value={statusFilter}
        onChange={onStatusFilterChange}
        options={statusFiltersWithCount}
      />
      <TableWrapper>
        <Toolbar>
          <SearchInput value={searchText} onChange={onSearchChange} />
          {canSearchArchived && (
            <Checkbox
              checked={archivedSelected}
              onChange={onToggleArchived}
              label="Show Archived"
            />
          )}
        </Toolbar>
        <Table
          loading={loading}
          data={projects || []}
          columns={columns}
          totalSize={totalSize}
          pagination={{ page, sizePerPage }}
          sorting={{ sortField, sortOrder }}
          onPaginationChange={onPaginationChange}
          onSortingChange={onSortingChange}
          manualPagination
          manualSorting
          noDataText={`No ${LabelTypes.VENDORS.toLowerCase()} found.`}
          type={Table.types.ALT}
        />
      </TableWrapper>
    </>
  )
}

View.propTypes = {
  projects: PropTypes.array,
  canCreate: PropTypes.bool,
  statusFilter: PropTypes.string,
  statusFiltersWithCount: PropTypes.array.isRequired,
  archivedSelected: PropTypes.bool,
  loading: PropTypes.bool,
  page: PropTypes.number,
  sizePerPage: PropTypes.number,
  sortField: PropTypes.string,
  sortOrder: PropTypes.string,
  searchText: PropTypes.string,
  totalSize: PropTypes.number,
  onStatusFilterChange: PropTypes.func.isRequired,
  onToggleArchived: PropTypes.func.isRequired,
  onAddProjectVendor: PropTypes.func.isRequired,
  onPaginationChange: PropTypes.func.isRequired,
  onSortingChange: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onCreateProjectClick: PropTypes.func.isRequired,
}

export default View
