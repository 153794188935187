import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rgba } from 'polished'
import Icon from 'components-v2/atoms/Icon'
import { mixins, colors } from 'styles'
import Segment from './Segment'
import Clickable from '../../atoms/Clickable'

export const Wrapper = styled(Segment).attrs({ as: Clickable })`
  flex: none;
  display: block;
  padding-top: 0;
  padding-bottom: 0;
  height: 35px;
  background: ${rgba(colors.LIGHT_GREY, 0.5)};
  ${mixins.smallHeader}
  font-size: 11px;
  line-height: 35px;
  color: #777;
  cursor: pointer;
  ${mixins.hidePrint}

  &:hover,
  &:active,
  &:focus {
    color: ${colors.DARK_GREY};
    text-decoration: none;
  }
`

const ViewMoreSegment = ({ children, ...rest }) => (
  <Wrapper {...rest}>
    {children}&nbsp;&nbsp;
    <Icon icon="fa fa-long-arrow-right" />
  </Wrapper>
)

ViewMoreSegment.propTypes = {
  children: PropTypes.node,
}

export default ViewMoreSegment
