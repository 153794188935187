import React from 'react'
import PropTypes from 'prop-types'
import img from 'images/error/hazard1.png'
import ErrorView from '../shared/ErrorView'

const View = ({ requestId }) => (
  <ErrorView
    imageUrl={img}
    errorCode={401}
    errorType="Unauthorized"
    errorMessage="If you are seeing this error it is likely you are not properly logged into your account."
  >
    {requestId && <p>Unique Id: {requestId}</p>}
  </ErrorView>
)

View.propTypes = {
  requestId: PropTypes.string,
}

export default View
