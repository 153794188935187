import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import addMinutes from 'date-fns/addMinutes'
import FormField, { BaseField } from 'components-v2/molecules/FormField'
import FormDialog from 'components-v2/organisms/FormDialog'
import { toDate, toLocalTimeString } from 'utils/date'
import { Text } from './styles'

const validationSchema = yup.object().shape({
  dueDate: yup
    .date()
    .nullable()
    .required('Due date is required')
    .min(new Date(), 'Due date must be in the future'),
})

const dueDateControlProps = {
  dateFormat: 'MM/dd/yyyy hh:mm aa',
  fullWidth: true,
  showTimeSelect: true,
  todayButton: 'Today',
  minDateTime: new Date(),
  showDurationButtons: true,
}

const ApproveExtensionRequestModal = ({
  endAt,
  requestedAt,
  minutesRequested,
  ...rest
}) => {
  const formConfig = React.useMemo(() => {
    const requestedAtDate = toDate(requestedAt)
    const requestedEndAt = requestedAtDate
      ? addMinutes(requestedAtDate, minutesRequested)
      : undefined
    return {
      validationSchema,
      initialValues: {
        dueDate: requestedEndAt,
      },
    }
  }, [requestedAt, minutesRequested])
  return (
    <FormDialog
      {...rest}
      title="Extension Request"
      submitButtonLabel="Grant Extension"
      formConfig={formConfig}
    >
      <BaseField label="Original Due Date" horizontal>
        <Text>{toLocalTimeString(endAt, 'MM/dd/yy hh:mm aa')}</Text>
      </BaseField>
      <BaseField label="Extension Requested On" horizontal>
        <Text>{toLocalTimeString(requestedAt, 'MM/dd/yy')}</Text>
      </BaseField>
      <BaseField label="Days Requested" horizontal>
        <Text>{minutesRequested / (60 * 24)}</Text>
      </BaseField>
      <FormField
        name="dueDate"
        label="New Due Date"
        type={FormField.types.DATE}
        controlProps={dueDateControlProps}
        placeholder="Select a date"
        horizontal
      />
    </FormDialog>
  )
}

ApproveExtensionRequestModal.propTypes = {
  endAt: PropTypes.string.isRequired,
  requestedAt: PropTypes.string.isRequired,
  minutesRequested: PropTypes.number.isRequired,
}

export default ApproveExtensionRequestModal
