import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from 'components-v2/molecules/Tooltip'
import GradeLabel from 'components/shared/GradeLabel'
import { AssessmentStatusTypes } from 'constants/index'
import { useAuthorizations } from 'hooks'
import { getPageUrl } from 'utils/url'
import { getRiskRatingColor } from 'lib/assessment'
import { RiskRating, StatusWrapper } from './styles'

const AssessmentStatusAndGrade = ({ assessment }) => {
  const [canLink] = useAuthorizations(['show', 'Assessment'])
  let ratingLabel

  if (assessment.id) {
    ratingLabel = (
      <RiskRating color={getRiskRatingColor(assessment.risk_rating)}>
        {assessment.risk_rating}%
      </RiskRating>
    )
  }

  if (assessment.status === AssessmentStatusTypes.SUBMITTED) {
    ratingLabel = (
      <Tooltip parent={ratingLabel}>
        Score is subject to change as assessment is reviewed.
      </Tooltip>
    )
  }

  let statusLabel
  if (assessment.id) {
    statusLabel = (
      <span>
        {assessment.status.titleize()} ({ratingLabel}
        {assessment.status === AssessmentStatusTypes.SUBMITTED ? '*' : ''})
      </span>
    )
  }

  return (
    <StatusWrapper>
      {canLink ? (
        <a href={getPageUrl('clientAssessment', { id: assessment.id })}>
          {statusLabel}
        </a>
      ) : (
        statusLabel
      )}
      &nbsp;
      {assessment.id && <GradeLabel grade={assessment.grade} iconOnly />}
    </StatusWrapper>
  )
}

AssessmentStatusAndGrade.propTypes = {
  assessment: PropTypes.object.isRequired,
}

export default AssessmentStatusAndGrade
