import React, { useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import filter from 'lodash/filter'
import reduce from 'lodash/reduce'
import {
  AssessmentStatusTypes,
  SortOrderTypes,
  VendorStatusGroups,
} from 'constants/index'
import {
  useClientAssessmentsFetcher,
  useClientTagsFetcher,
  useClientVendorsFetcher,
  useClientVendorResultsSummaryFetcher,
} from 'apis'
import View from './View'
import { getVendorFieldGroups } from './utils'
import useSaveFieldGroupCard from './useSaveFieldGroupCard'
import useVendorRequestMutations from './useVendorRequestMutations'

const PROJECT_MAX_COUNT_TO_SHOW = 5
const ASSESSMENT_MAX_COUNT_TO_SHOW = 5

const Container = ({
  vendor,
  globorgEnabled,
  globalOrg,
  facetableSets,
  onClickGlobOrgName,
  onConnectGloborg,
  onDisconnectGloborg,
}) => {
  const vendorId = vendor.id
  const [cards, setCards] = useState({})
  const [resultsSummaryArchivedVisible, setResultsSummaryArchivedVisible] =
    useState(false)
  const [resultsSummaryIncludeChildren, setResultsSummaryIncludeChildren] =
    useState(vendor.child_orgs?.length > 0)
  const {
    data: vendorResultsSummaryData,
    totalSize: vendorResultsSummaryTotalSize,
    isFetching: isFetchingVendorResultsSummary,
  } = useClientVendorResultsSummaryFetcher(
    {
      vendorId,
      params: {
        page: 1,
        per_page: PROJECT_MAX_COUNT_TO_SHOW,
        // eslint-disable-next-line camelcase
        include_archived: resultsSummaryArchivedVisible,
        // eslint-disable-next-line camelcase
        include_children: resultsSummaryIncludeChildren,
      },
    },
    { enabled: VendorStatusGroups.operational.includes(vendor.status) },
  )
  const {
    data: reviewedAssessments,
    isFetching: isFetchingReviewedAssessments,
  } = useClientAssessmentsFetcher({
    params: {
      page: 1,
      per_page: ASSESSMENT_MAX_COUNT_TO_SHOW,
      ordered_by: 'reviewed_at',
      direction: SortOrderTypes.DESC,
      status: AssessmentStatusTypes.REVIEWED,
      vendor_id: vendorId,
    },
  })
  const resultsSummary = {
    projects: vendorResultsSummaryData,
    reviewedAssessments,
    hasMore: vendorResultsSummaryTotalSize > PROJECT_MAX_COUNT_TO_SHOW,
  }
  const resultsSummaryLoading =
    isFetchingVendorResultsSummary || isFetchingReviewedAssessments
  const { data: vendorTagsData } = useClientTagsFetcher()
  const clientTags = useMemo(
    () => vendorTagsData?.map((e) => e.name) || [],
    [vendorTagsData],
  )
  const { data: vendorsData } = useClientVendorsFetcher()
  const otherVendors = useMemo(
    () =>
      reduce(
        filter(vendorsData, (v) => v.id !== vendorId),
        (res, val) => {
          // make sure we don't offer a child as a parent option
          if (!val.parent_org_id) {
            res[val.id] = { id: val.id, name: val.name }
          }
          return res
        },
        {},
      ),
    [vendorsData, vendorId],
  )

  const vendorFieldGroups = useMemo(
    () =>
      getVendorFieldGroups({
        otherVendors,
        clientTags,
        globorgEnabled,
        globOrgName: globalOrg?.name,
        onConnectGlobOrg: onConnectGloborg,
        onDisconnectGlobOrg: onDisconnectGloborg,
        onClickGlobOrgName,
      }),
    [
      vendor,
      otherVendors,
      clientTags,
      globorgEnabled,
      globalOrg,
      onConnectGloborg,
      onDisconnectGloborg,
      onClickGlobOrgName,
    ],
  )

  const handleEdit = useCallback((cardId) => {
    setCards((current) => ({
      ...current,
      [cardId]: {
        editing: true,
      },
    }))
  }, [])

  const handleCancel = useCallback((cardId) => {
    setCards((current) => ({
      ...current,
      [cardId]: {
        editing: false,
      },
    }))
  }, [])

  const saveFieldGroupCard = useSaveFieldGroupCard(vendor, handleCancel)

  const { approveVendorRequest, declineVendorRequest } =
    useVendorRequestMutations(vendor, globorgEnabled)

  return (
    <View
      cards={cards}
      resultsSummary={resultsSummary}
      resultsSummaryLoading={resultsSummaryLoading}
      resultsSummaryArchivedVisible={resultsSummaryArchivedVisible}
      resultsSummaryIncludeChildren={resultsSummaryIncludeChildren}
      clientTags={clientTags}
      otherVendors={otherVendors}
      vendor={vendor}
      vendorFieldGroups={vendorFieldGroups}
      facetableSets={facetableSets}
      onEdit={handleEdit}
      onSave={saveFieldGroupCard}
      onCancel={handleCancel}
      onToggleResultSummaryArchived={setResultsSummaryArchivedVisible}
      onToggleResultSummaryIncludeChildren={setResultsSummaryIncludeChildren}
      onApproveRequest={approveVendorRequest}
      onDeclineRequest={declineVendorRequest}
    />
  )
}

Container.propTypes = {
  vendor: PropTypes.object.isRequired,
  globorgEnabled: PropTypes.bool,
  globalOrg: PropTypes.object,
  facetableSets: PropTypes.array,
  onConnectGloborg: PropTypes.func.isRequired,
  onDisconnectGloborg: PropTypes.func.isRequired,
  onClickGlobOrgName: PropTypes.func.isRequired,
}

export default Container
