import React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const defaultOptions = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
}

const withReactQuery =
  (options = defaultOptions) =>
  (WrappedComponent) => {
    const queryClient = new QueryClient(options)
    return React.forwardRef((props, ref) => (
      <QueryClientProvider client={queryClient}>
        <WrappedComponent ref={ref} {...props} />
      </QueryClientProvider>
    ))
  }

export default withReactQuery
