import styled, { css } from 'styled-components'
import Input from 'components-v2/atoms/Input'
import Textarea from 'components-v2/atoms/Textarea'
import Select from 'components-v2/atoms/Select'
import FormField, {
  BaseField,
  ControlLabel,
} from 'components-v2/molecules/FormField'
import { colors, mixins } from 'styles'
import InfoCard from '../InfoCard'

// NOTE: Consider to make this input "sm" style as global
export const StyledInfoCard = styled(InfoCard.type)`
  ${Input}, ${Textarea}, ${Select} {
    height: 30px;
    padding: 3px 9px 4px;
  }
`

export const ActionButtons = styled.div`
  margin-top: 12px;

  button:not(:last-child) {
    margin-right: 6px;
  }
`

export const StyledFormField = styled(FormField).attrs(({ horizontal }) => ({
  size: 'small',
  renderControlLabel: styled(ControlLabel)`
    &&& {
      ${!horizontal &&
      css`
        ${mixins.smallHeader}
        margin-bottom: 6px;
      `}
      ${horizontal &&
      `
        font-size: 16px;
      `}
    }
  `,
}))`
  &:not(:last-child) {
    ${({ $hasOther }) =>
      !$hasOther &&
      css`
        border-bottom: 1px solid ${colors.LIGHT_GREY};
        margin-bottom: 15px;
      `}
  }
`

export const OtherFormField = styled(StyledFormField).attrs({
  renderControlLabel: ControlLabel,
})`
  padding-left: 30%;
`

export const ValueRenderRow = styled(BaseField).attrs(({ horizontal }) => ({
  renderControlLabel: styled(ControlLabel)`
    &&& {
      ${!horizontal &&
      css`
        ${mixins.smallHeader}
        margin-bottom: 8px;
      `}
      ${horizontal &&
      `
        font-size: 16px;
      `}
    }
  `,
}))`
  padding-bottom: 0;
  &:not(:last-child) {
    padding-bottom: ${(props) => (props.horizontal ? 7 : 15)}px;
    border-bottom: 1px solid ${colors.LIGHT_GREY};
    margin-bottom: 15px;
  }
`

export const ValueWrapper = styled.div`
  ${(props) => props.horizontal && `padding: 6px 0;`}
  word-break: break-word;
`

export const GroupTitle = styled.h4`
  margin-top: 30px;
  margin-bottom: 15px;
`
