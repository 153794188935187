import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from 'components-v2/atoms/Icon'

const Textarea = styled.textarea`
  display: block;
  width: 100%;
  resize: none;
`

const ToolbarWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const StyledIcon = styled(Icon)`
  ${({ condensed }) =>
    condensed
      ? `
    margin: 2px;
    font-size: 20px;
    `
      : `
    font-size: 24px;
    margin: 3px;
  `}
`

const TextEditor = ({
  placeholder,
  value,
  autoFocus,
  disabled,
  toolbarCondensed,
  onChange,
  onToggleEditorMode,
}) => {
  const handleInputRef = useCallback(
    (input) => {
      if (!autoFocus || !input) {
        return
      }
      input.focus()
      input.setSelectionRange(input.value.length, input.value.length)
    },
    [autoFocus],
  )

  const handleTextareaChange = useCallback(
    (e) => {
      if (onChange) {
        onChange(e.target.value)
      }
    },
    [onChange],
  )

  return (
    <div>
      <ToolbarWrapper>
        <StyledIcon
          icon="fa fa-eye"
          condensed={toolbarCondensed}
          onClick={onToggleEditorMode}
        />
      </ToolbarWrapper>
      <div className="remirror-editor-wrapper">
        <Textarea
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          onChange={handleTextareaChange}
          className="ProseMirror remirror-editor"
          ref={handleInputRef}
        />
      </div>
    </div>
  )
}

TextEditor.propTypes = {
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  toolbarCondensed: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onToggleEditorMode: PropTypes.func.isRequired,
}

export default TextEditor
