import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components-v2/atoms/Button'
import ToolbarGroup from 'components-v2/molecules/ToolbarGroup'
import { Wrapper, Footer } from './styles'

const ConfirmPopoverContent = ({ onConfirm, onHide, ...rest }) => (
  <Wrapper {...rest}>
    Action is irreversible. Do you want to continue?
    <Footer>
      <ToolbarGroup>
        <Button onClick={onHide}>Cancel</Button>
        <Button color="primary" onClick={onConfirm}>
          Confirm
        </Button>
      </ToolbarGroup>
    </Footer>
  </Wrapper>
)

ConfirmPopoverContent.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
}

export default ConfirmPopoverContent
