import { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useModal, useGlobalLoader, useConfirm } from 'hooks'
import NotificationManager from 'lib/notifications'
import {
  useClientSourcedAttachmentsFetcher,
  useUpdateClientVendorAttachment,
  useDeleteClientVendorAttachment,
} from 'apis'
import objectToFormData from 'utils/object_to_formdata'
import VendorAttachmentModal from './VendorAttachmentModal'
import UploadBulkFilesModal from './UploadBulkFilesModal'

export default function useCRUDVendorAttachment(clientVendorId) {
  const queryClient = useQueryClient()
  const { mutateAsync: updateClientVendorAttachment } =
    useUpdateClientVendorAttachment()
  const { mutateAsync: deleteVendorAttachment } =
    useDeleteClientVendorAttachment()
  const [showLoader, hideLoader] = useGlobalLoader()
  const openConfirm = useConfirm()
  const [openVendorAttachmentModal, closeVendorAttachmentModal] = useModal(
    VendorAttachmentModal,
  )
  const [openUploadBulkFilesModal, closeUploadBulkFilesModal] =
    useModal(UploadBulkFilesModal)

  const onBulkCreate = useCallback(() => {
    openUploadBulkFilesModal({
      clientVendorId,
      onSuccess: () => {
        closeUploadBulkFilesModal()
        queryClient.invalidateQueries(
          useClientSourcedAttachmentsFetcher.queryKey(),
        )
      },
    })
  }, [
    openUploadBulkFilesModal,
    closeUploadBulkFilesModal,
    clientVendorId,
    queryClient,
  ])

  const onEdit = useCallback(
    (vendorAttachment) => {
      const onSubmit = ({ attachment, ...rest }) => {
        const loaderId = showLoader()

        const payload = objectToFormData({
          client_vendor_attachment: {
            ...rest,
            attachment_attributes:
              attachment && attachment !== vendorAttachment.attachment
                ? { file: attachment }
                : undefined,
          },
        })
        return updateClientVendorAttachment({
          id: vendorAttachment.id,
          data: payload,
        })
          .then(() => {
            NotificationManager.success('A file has been successfully updated.')
            closeVendorAttachmentModal()
          })
          .catch((error) => {
            console.error(error)
            NotificationManager.error()
          })
          .then(() => hideLoader(loaderId))
      }

      openVendorAttachmentModal({
        title: 'Update File',
        vendorAttachment,
        onSubmit,
      })
    },
    [
      openVendorAttachmentModal,
      updateClientVendorAttachment,
      closeVendorAttachmentModal,
      showLoader,
      hideLoader,
    ],
  )

  const onDelete = useCallback(
    (attachmentId) => {
      openConfirm({
        title: 'Deleting File',
        onConfirm: () => {
          const loaderId = showLoader()
          deleteVendorAttachment({ id: attachmentId })
            .then(() =>
              NotificationManager.success(
                'The file has been successfully deleted.',
              ),
            )
            .catch(() => NotificationManager.error())
            .then(() => hideLoader(loaderId))
        },
      })
    },
    [openConfirm, deleteVendorAttachment, showLoader, hideLoader],
  )

  return {
    createVendorAttachments: onBulkCreate,
    editVendorAttachment: onEdit,
    deleteVendorAttachment: onDelete,
  }
}
