import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { parseRiskReconData } from 'components/client/lib/globorg_integration_metrics'
import BaseOverviewCard from '../BaseOverviewCard'
import RiskReconOverallGradeChart from '../../RiskReconOverallGradeChart'

const SmallRiskReconOverallGradeChart = styled(RiskReconOverallGradeChart)`
  transform: scale(0.6);
  margin: -50px;
`

const RiskReconOverviewCard = ({ data, ...rest }) => {
  const parsed = React.useMemo(() => parseRiskReconData(data)[0], [data])
  return (
    <BaseOverviewCard
      {...rest}
      title={
        <span>
          RiskRecon
          <br />
          overall grade
        </span>
      }
      description={parsed.description}
    >
      <SmallRiskReconOverallGradeChart
        grade={parsed.grade}
        value={parsed.value}
        total={parsed.total}
      />
    </BaseOverviewCard>
  )
}

RiskReconOverviewCard.propTypes = {
  data: PropTypes.object.isRequired,
}

export default RiskReconOverviewCard
