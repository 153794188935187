import { useCallback } from 'react'
import NotificationManager from 'lib/notifications'
import { useGlobalLoader } from 'hooks'
import { useUpdateClientIssue } from 'apis'

export default function useAssignUser(issue) {
  const [showLoader, hideLoader] = useGlobalLoader()
  const { mutateAsync: updateClientIssue } = useUpdateClientIssue()

  return useCallback(
    async (formData) => {
      const payload = {
        client_assignee_id: formData.assigneeId,
        client_assignee_note: formData.note,
      }
      const loaderId = showLoader()
      try {
        await updateClientIssue({
          id: issue.id,
          data: { issue: payload },
        })
        hideLoader(loaderId)
      } catch (error) {
        hideLoader(loaderId)
        NotificationManager.error()
        throw error
      }
    },
    [issue, showLoader, hideLoader, updateClientIssue],
  )
}
