import React from 'react'
import PropTypes from 'prop-types'
import SelectField from 'components-v2/molecules/FormField/SelectField'
import DateField from 'components-v2/molecules/FormField/DateField'

const stateOptions = [
  {
    value: 'sent',
    label: 'Sent',
  },
  {
    value: 'submitted',
    label: 'Submitted',
  },
  {
    value: 'reviewed',
    label: 'Reviewed',
  },
]

const DateRangeFilter = ({
  value,
  onChange,
  minDate,
  maxDate,
  datePickerProps,
  ...rest
}) => (
  <div {...rest}>
    <DateField
      label="Start Date"
      value={value.startDate}
      onChange={(startDate) => onChange({ ...value, startDate })}
      controlProps={{
        ...datePickerProps,
        minDate,
        maxDate: value.endDate || maxDate,
        fullWidth: true,
        portalId: 'datepicker-portal',
      }}
    />
    <DateField
      label="End Date"
      value={value.endDate}
      onChange={(endDate) => onChange({ ...value, endDate })}
      controlProps={{
        ...datePickerProps,
        minDate: value.startDate || minDate,
        maxDate,
        fullWidth: true,
        portalId: 'datepicker-portal',
      }}
    />
    <SelectField
      label="State"
      value={value.state}
      options={stateOptions}
      onChange={(state) => onChange({ ...value, state })}
      controlProps={{ placeholderSelectable: true }}
    />
  </div>
)

DateRangeFilter.propTypes = {
  value: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    state: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  datePickerProps: PropTypes.object,
}

DateRangeFilter.defaultProps = {
  value: {},
}

export default DateRangeFilter

export const getDateRangerFilterSelectedCount = (value) =>
  (value?.startDate ? 1 : 0) + (value?.endDate ? 1 : 0) + (value?.state ? 1 : 0)
