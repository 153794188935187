import React from 'react'
import PropTypes from 'prop-types'
import { Transition } from 'react-transition-group'

export default function withTransition(WrappedComponent) {
  const WrapperComponent = ({
    timeout,
    as,
    className,
    style,
    children,
    ...rest
  }) => (
    <Transition {...rest} timeout={timeout}>
      {(state) => (
        <WrappedComponent
          timeout={timeout}
          state={state}
          className={className}
          style={style}
          as={as}
        >
          {children}
        </WrappedComponent>
      )}
    </Transition>
  )

  WrapperComponent.propTypes = {
    ...Transition.propTypes,
    timeout: PropTypes.number.isRequired,
    in: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.node,
  }

  WrapperComponent.defaultProps = {
    ...Transition.defaultProps,
    in: false,
  }

  return WrapperComponent
}
