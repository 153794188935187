import React from 'react'
import PropTypes from 'prop-types'
import { useQueryClient } from '@tanstack/react-query'
import Button from 'components-v2/atoms/Button'
import Icon from 'components-v2/atoms/Icon'
import ToolbarGroup from 'components-v2/molecules/ToolbarGroup'
import PageHeader from 'components-v2/organisms/PageHeader'
import IssueListView from 'components/client/issue_list_view'
import useCreateUpdateIssue from 'components/client/shared/useCreateUpdateIssue'
import { getPageUrl } from 'utils/url'
import { compose, withAuthorize, withReactQuery } from 'hocs'
import { useAuthorizations } from 'hooks'
import { useClientIssuesFetcher, useClientIssueCategoriesFetcher } from 'apis'

const Container = (props) => {
  const queryClient = useQueryClient()
  const { createIssue } = useCreateUpdateIssue()
  const [userCanCreate] = useAuthorizations('create')
  const handleCreateIssue = React.useCallback(() => {
    createIssue({
      onIssueCreated: () =>
        queryClient.invalidateQueries(useClientIssuesFetcher.queryKey()),
      onIssueCategoryCreated: () =>
        queryClient.invalidateQueries(
          useClientIssueCategoriesFetcher.queryKey(),
        ),
    })
  }, [createIssue, queryClient])

  return (
    <>
      <PageHeader title="Issues">
        <ToolbarGroup>
          <Button href={getPageUrl('clientIssuesSummary')}>
            <Icon icon="fa fa-pie-chart" />
            Summary
          </Button>
          {userCanCreate && (
            <Button color="primary" onClick={handleCreateIssue}>
              <Icon icon="fa fa-plus" />
              New Issue
            </Button>
          )}
        </ToolbarGroup>
      </PageHeader>
      <IssueListView {...props} columnTogglerVisible />
    </>
  )
}

Container.propTypes = {
  orgId: PropTypes.string.isRequired,
  userPrefs: PropTypes.object.isRequired,
  authManager: PropTypes.object.isRequired,
}

export default compose(withAuthorize(), withReactQuery())(Container)
