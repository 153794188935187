import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import findIndex from 'lodash/findIndex'
import filter from 'lodash/filter'
import union from 'lodash/union'
import difference from 'lodash/difference'
import startsWith from 'lodash/startsWith'
import { AssessmentElementTypes } from 'constants/index'
import withDragDropContext from 'hocs/withDragDropContext'
import { moveArrayElementTo } from 'utils/array'
import DraggableRow from 'components-v2/molecules/DraggableRow'
import SectionHeadingRow from './SectionHeadingRow'
import QuestionRow from './QuestionRow'
import { Table } from './styles'
export { default as Thead } from './Thead'

const TemplateEditTable = ({
  elements: elementsProp,
  elementIdsWithSkipConditions,
  isSectionSelectionMode,
  selectedSectionId,
  selectedQuestionIds,
  editable,
  useCustomKeys,
  autoScoreOptions,
  onElementFieldChange,
  onElementMove,
  onElementEdit,
  onElementDelete,
  onElementEditSkipConditions,
  onElementQuestionAutomationEdit,
  onElementAutoScoreChange,
  onSelectedQuestionIdsChange,
  onToggleSectionSelection,
  onViewQuestionAttachmentsClick,
  ...rest
}) => {
  const [elements, setElements] = React.useState(elementsProp)
  const isShiftKeyDownRef = React.useRef(false)
  const lastSelectedElementIdRef = React.useRef(null)

  React.useEffect(() => {
    setElements(elementsProp)
  }, [elementsProp])

  const handleKeyDown = React.useCallback((event) => {
    isShiftKeyDownRef.current = event.shiftKey
  }, [])

  const handleKeyUp = React.useCallback(() => {
    isShiftKeyDownRef.current = false
  }, [])

  const handleRowTemporaryMove = React.useCallback((dragIndex, hoverIndex) => {
    setElements((current) => moveArrayElementTo(current, dragIndex, hoverIndex))
  }, [])

  const handleRowCancelMove = () => {
    setElements(elementsProp)
  }

  const handleToggleQuestionSelection = (questionId, selected) => {
    let filteredQuestionIds
    let newSelectedQuestionIds
    let fromIndex = -1

    if (lastSelectedElementIdRef.current) {
      const lastSelectedElementIndex = findIndex(elementsProp, {
        id: lastSelectedElementIdRef.current,
      })
      if (
        elementsProp[lastSelectedElementIndex] !==
        AssessmentElementTypes.SECTION_HEADING
      ) {
        fromIndex = lastSelectedElementIndex
      }
    }

    // If shift key is down and fromIndex exists, let's bulk select/deselect
    if (isShiftKeyDownRef.current && fromIndex >= 0) {
      const toIndex = findIndex(elementsProp, { id: questionId })
      const filteredQuestions = filter(
        elementsProp,
        (e, index) =>
          index >= Math.min(fromIndex, toIndex) &&
          index <= Math.max(fromIndex, toIndex) &&
          e.type !== AssessmentElementTypes.SECTION_HEADING,
      )
      filteredQuestionIds = map(filteredQuestions, 'id')
    }
    // Otherwise, single toggle selection
    else {
      filteredQuestionIds = [questionId]
    }

    // Generate new selectedQuestionIds array
    if (selected) {
      newSelectedQuestionIds = union(selectedQuestionIds, filteredQuestionIds)
    } else {
      newSelectedQuestionIds = difference(
        selectedQuestionIds,
        filteredQuestionIds,
      )
    }

    // Fire onSelectedQuestionIdsChange callback
    onSelectedQuestionIdsChange(newSelectedQuestionIds)

    // Save the last selected elementId
    lastSelectedElementIdRef.current = questionId
  }

  const handleToggleSectionSelection = React.useCallback(
    (sectionId, selected) => {
      onToggleSectionSelection(sectionId, selected)

      // Save the last selected elementId
      lastSelectedElementIdRef.current = sectionId
    },
    [onToggleSectionSelection],
  )

  const renderRow = (element, dragSourceRef) => {
    const skipped = elementIdsWithSkipConditions.indexOf(element.id) !== -1

    if (element.type === AssessmentElementTypes.SECTION_HEADING) {
      const selected =
        isSectionSelectionMode && selectedSectionId === element.id
      const selectDisabled =
        isSectionSelectionMode && selectedSectionId !== element.id
      return (
        <SectionHeadingRow
          element={element}
          selected={selected}
          selectDisabled={selectDisabled}
          skipped={skipped}
          editable={editable}
          useCustomKeys={useCustomKeys}
          dragSourceRef={dragSourceRef}
          onEdit={onElementEdit}
          onDelete={onElementDelete}
          onEditSkipConditions={onElementEditSkipConditions}
          onToggleSelection={handleToggleSectionSelection}
        />
      )
    }
    const selected = selectedQuestionIds.indexOf(element.id) !== -1
    const selectDisabled = isSectionSelectionMode
    return (
      <QuestionRow
        element={element}
        selected={selected}
        selectDisabled={selectDisabled}
        skipped={skipped}
        editable={editable}
        useCustomKeys={useCustomKeys}
        autoScoreOptions={autoScoreOptions}
        dragSourceRef={dragSourceRef}
        onFieldChange={onElementFieldChange}
        onEdit={onElementEdit}
        onDelete={onElementDelete}
        onEditSkipConditions={onElementEditSkipConditions}
        onElementQuestionAutomationEdit={onElementQuestionAutomationEdit}
        onViewQuestionAttachmentsClick={onViewQuestionAttachmentsClick}
        onAutoScoreChange={onElementAutoScoreChange}
        onToggleSelection={handleToggleQuestionSelection}
      />
    )
  }

  return (
    <Table {...rest} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
      {map(elements, (element, index) => {
        if (editable) {
          return (
            <DraggableRow
              key={element.id}
              id={element.id}
              index={index}
              hasDragSource
              onRowMove={onElementMove}
              onRowTemporaryMove={handleRowTemporaryMove}
              onRowCancelMove={handleRowCancelMove}
              canDrop={() =>
                element.natural_key !== '1.0' &&
                !startsWith(element.natural_key, '0.')
              }
            >
              {({ opacity, ref, dragSourceRef }) => (
                <div ref={ref} style={{ opacity }}>
                  {renderRow(element, dragSourceRef)}
                </div>
              )}
            </DraggableRow>
          )
        }
        return (
          <React.Fragment key={element.id}>{renderRow(element)}</React.Fragment>
        )
      })}
    </Table>
  )
}

TemplateEditTable.propTypes = {
  elements: PropTypes.array.isRequired,
  elementIdsWithSkipConditions: PropTypes.array.isRequired,
  isSectionSelectionMode: PropTypes.bool,
  selectedSectionId: PropTypes.string,
  selectedQuestionIds: PropTypes.array.isRequired,
  editable: PropTypes.bool,
  useCustomKeys: PropTypes.bool,
  autoScoreOptions: PropTypes.array.isRequired,
  onElementFieldChange: PropTypes.func.isRequired,
  onElementMove: PropTypes.func.isRequired,
  onElementEdit: PropTypes.func.isRequired,
  onElementDelete: PropTypes.func.isRequired,
  onElementEditSkipConditions: PropTypes.func.isRequired,
  onElementAutoScoreChange: PropTypes.func.isRequired,
  onElementQuestionAutomationEdit: PropTypes.func.isRequired,
  onViewQuestionAttachmentsClick: PropTypes.func.isRequired,
  onSelectedQuestionIdsChange: PropTypes.func.isRequired,
  onToggleSectionSelection: PropTypes.func.isRequired,
}

export default withDragDropContext(TemplateEditTable)
