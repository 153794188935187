import styled from 'styled-components'
import Card from 'components-v2/organisms/Card'
import { colors } from 'styles'

export const Wrapper = styled(Card).attrs({ bordered: true })`
  min-height: 164px;
  display: flex;
  flex-direction: column;
`

export const Top = styled(Card.Segment)`
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;

  &:not(:last-child) {
    padding-bottom: 10px;
  }
`

export const Label = styled.h6`
  flex: none;
  height: 28px;
  margin: 0;
  line-height: 14px;
`

export const Content = styled.div`
  margin: auto 0;
`

export const UpdatedAt = styled.div`
  flex: none;
  font-size: 13px;
  line-height: 16px;
  height: 14px;
  color: ${colors.DARK_GREY};
  margin-top: 12px;
`

export const Bottom = styled(Card.ViewMoreSegment)`
  padding: 0;
  flex: none;
  font-size: 8px;
  line-height: 30px;
  height: 30px;
  text-align: center;
`
