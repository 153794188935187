import React from 'react'
import PropTypes from 'prop-types'
import isBefore from 'date-fns/isBefore'
import add from 'date-fns/add'
import A from 'components-v2/atoms/A'
import Button from 'components-v2/atoms/Button'
import Tabs from 'components-v2/molecules/Tabs'
import Table from 'components-v2/molecules/Table'
import { useQueryParams } from 'hooks'
import { dateFormatterFactory } from 'lib/table'
import { getPageUrl } from 'utils/url'
import { toDate } from 'utils/date'

const lastAccessDateFormatter = dateFormatterFactory({
  emptyText: 'Never',
})
const dueDateFormatter = dateFormatterFactory({
  showWarning: (_, date) => isBefore(date, new Date()),
})

const tokenTableColumns = [
  {
    id: 'name',
    header: 'Application Name',
  },
  {
    id: 'expires',
    header: 'Expires',
    accessorFn: (row) =>
      row.access_tokens?.[0]
        ? add(toDate(row.access_tokens[0].created_at), {
            seconds: row.access_tokens[0].expires_in,
          })
        : undefined,
    cell: dueDateFormatter,
  },
  {
    id: 'access_tokens[0].token',
    header: 'Token',
    cell: (info) => (
      <div>
        <textarea
          className="monospace small"
          id={`token-${info.row.original.id}`}
          cols="60"
          rows="6"
          readOnly
          value={info.getValue()}
        />
        &nbsp;
        <i
          className="fa fa-fw fa-clipboard text-primary"
          onClick={() => {
            document.getElementById(`token-${info.row.original.id}`).select()
            document.execCommand('copy')
          }}
        />
      </div>
    ),
  },
  {
    id: 'action',
    header: 'Delete',
    cell: (info) => (
      <Button
        color="danger"
        size="sm"
        data-confirm="Are you sure want to delete this token?"
        rel="nofollow"
        data-method="delete"
        href={getPageUrl('oauthApplicationPath', { id: info.row.original.id })}
      >
        Delete
      </Button>
    ),
  },
]

const appTableColumns = [
  {
    id: 'name',
    header: 'Application Name',
  },
  {
    id: 'redirect_uri',
    header: 'Redirect URI',
  },
  {
    id: 'authorized',
    header: 'Access',
    cell: (info) => {
      if (info.getValue()) {
        return (
          <A
            data-confirm="Are you sure?"
            rel="nofollow"
            data-method="delete"
            href={getPageUrl('oauthAuthorizedApplicationPath', {
              id: info.row.original.id,
            })}
          >
            Revoke
          </A>
        )
      }
      return (
        <A href={info.row.original.authorize_uri} target="_blank">
          Authorize
        </A>
      )
    },
  },
  {
    id: 'last_access',
    header: 'Last Access',
    cell: lastAccessDateFormatter,
  },
  {
    id: 'manage',
    header: 'Manage',
    cell: (info) => (
      <Button
        size="sm"
        href={getPageUrl('oauthApplicationPath', { id: info.row.original.id })}
      >
        Manage
      </Button>
    ),
  },
]

const ApiApplications = ({ oauthApplications }) => {
  const [{ appsTab }, setQueryParams] = useQueryParams()
  const handleTabSelect = React.useCallback(
    (e) => setQueryParams({ appsTab: e }),
    [],
  )
  return (
    <div>
      <h3>
        Registered API Applications{' '}
        <span className="small">
          ( <a href={getPageUrl('docs')}>API Docs</a> )
        </span>
      </h3>
      <Tabs activeKey={appsTab} onChange={handleTabSelect}>
        <Tabs.Tab eventKey="tokens" title="API Tokens">
          <Table
            data={oauthApplications.filter(
              (e) => e.custom_type === 'managed_token',
            )}
            columns={tokenTableColumns}
            enableSorting={false}
            enablePagination={false}
          />
          <Button
            color="primary"
            href={getPageUrl('newOauthApplicationPath', undefined, {
              custom_type: 'managed_token',
            })}
          >
            Create a new token
          </Button>
        </Tabs.Tab>
        <Tabs.Tab eventKey="oauth" title="OAuth Applications">
          <Table
            data={oauthApplications.filter((e) => !e.custom_type)}
            columns={appTableColumns}
            enableSorting={false}
            enablePagination={false}
          />
          <Button color="primary" href={getPageUrl('newOauthApplicationPath')}>
            Add a new OAuth application
          </Button>
        </Tabs.Tab>
      </Tabs>
    </div>
  )
}

ApiApplications.propTypes = {
  oauthApplications: PropTypes.array.isRequired,
}

export default ApiApplications
