// MenuDropdown component composing molecules/Menu and its subcomponents for an easier usage
// In addition to molecules/Menu component logic, this has two important features;
// 1. This doesn't render itself if the dropdown content is empty
// 2. This provides onSelect callback optionally. If you don't want to add onClick prop to every menu item,
//    you can add eventKey prop to menu item and receive "click" event through onSelect callback of the root component.
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from 'components-v2/atoms/Icon'
import Menu from 'components-v2/molecules/Menu'

export const Caret = styled.span`
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: middle;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
`

const MenuDropdown = ({
  title,
  icon,
  arrow,
  buttonProps = {},
  onSelect,
  children,
  ...rest
}) => {
  const notEmpty = React.Children.toArray(children).some((child) => child)

  // Do not render the whole component if dropdown content is empty
  if (!notEmpty) {
    return null
  }

  return (
    <Menu placement="bottom-end" offset={arrow ? 12 : 2} {...rest}>
      <Menu.Button {...buttonProps}>
        {icon && <Icon icon={icon} />}
        {title}
        <Caret />
      </Menu.Button>
      <Menu.Items arrow={arrow}>
        {React.Children.map(children, (child) => {
          if (!child) {
            return null
          }
          return React.cloneElement(child, {
            onClick:
              !child.props.onClick && onSelect && child.props.eventKey // onClick prop of child has higher priority
                ? () => onSelect(child.props.eventKey)
                : child.props.onClick,
          })
        })}
      </Menu.Items>
    </Menu>
  )
}

MenuDropdown.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  arrow: PropTypes.bool,
  buttonProps: PropTypes.shape({
    active: PropTypes.bool,
    size: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  onSelect: PropTypes.func,
  children: PropTypes.node,
}

MenuDropdown.Item = Menu.Item
MenuDropdown.Divider = Menu.Divider

export default MenuDropdown
