import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from '@remirror/react'
import { AllStyledComponent } from '@remirror/styles/styled-components'
import '@remirror/styles/all.css'
import { BaseWrapper, theme } from '../styles'
import TextEditor from './TextEditor'
import VisualEditor from './VisualEditor'

const Editor = ({
  placeholder,
  value,
  autoFocus,
  disabled,
  toolbarCondensed,
  onChange,
  ...rest
}) => {
  const [visualEditing, setVisualEditing] = useState(true)
  const toggleVisualEditing = useCallback(
    () => setVisualEditing((current) => !current),
    [],
  )
  const Component = visualEditing ? VisualEditor : TextEditor
  return (
    <BaseWrapper {...rest}>
      <ThemeProvider theme={theme}>
        <AllStyledComponent>
          <Component
            value={value}
            autoFocus={autoFocus}
            placeholder={placeholder}
            disabled={disabled}
            toolbarCondensed={toolbarCondensed}
            onChange={onChange}
            onToggleEditorMode={toggleVisualEditing}
          />
        </AllStyledComponent>
      </ThemeProvider>
    </BaseWrapper>
  )
}

Editor.propTypes = {
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  toolbarCondensed: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

export default Editor
