import React, { useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import toPairs from 'lodash/toPairs'
import Checkbox from 'components-v2/atoms/Checkbox'
import Button from 'components-v2/atoms/Button'
import Select from 'components-v2/atoms/Select'
import Loader from 'components-v2/molecules/Loader'
import { BaseField } from 'components-v2/molecules/FormField'
import SearchableSelect from 'components-v2/molecules/SearchableSelect'
import PrintButton from 'components-v2/molecules/PrintButton'
import PageHeader from 'components-v2/organisms/PageHeader'
import { Row, Col } from 'components-v2/organisms/Layout'
import PieChartWidget from 'components/shared/IssueSummaryPieChart'
import { LabelTypes } from 'constants/index'
import { withReactQuery } from 'hocs'
import { useQueryParams } from 'hooks'
import config from 'config'
import {
  useClientIssueSummaryFetcher,
  useClientVendorsFetcher,
  useClientIssueCategoriesFetcher,
} from 'apis'
import { Filter, ChartsCard, Title, SubTitle, Footer } from './styles'

const perPage = config.collectionQueryPageLimit.issueSummary

const Container = ({ showCategory }) => {
  const [{ dimension }, setQueryParams] = useQueryParams({
    dimension: { defaultValue: 'all' },
  })
  const [page, setPage] = useState(1)
  const [includeArchived, setIncludeArchived] = useState(false)
  const [filter, setFilter] = useState([])
  const {
    data: issueSummary,
    totalSize,
    isFetching,
  } = useClientIssueSummaryFetcher({
    params: {
      page,
      per_page: perPage,
      dimension,
      include_archived: includeArchived,
      filter: filter.join(','),
    },
  })
  const { data: vendors } = useClientVendorsFetcher()
  const vendorsList = useMemo(
    () =>
      vendors?.map((v) => ({
        value: v.id,
        label: v.name,
      })),
    [vendors],
  )
  const { data: issueCategories } = useClientIssueCategoriesFetcher()
  const categoryList = useMemo(
    () =>
      issueCategories?.map((v) => ({
        value: v.id,
        label: v.name,
      })),
    [issueCategories],
  )

  const handleDimensionChange = useCallback((e) => {
    setQueryParams({ dimension: e.target.value })
    setFilter([])
    setPage(1)
  }, [])

  const handleIncludeArchivedChange = useCallback((e) => {
    setIncludeArchived(e.target.checked)
    setPage(1)
  }, [])

  const handleFilterChange = useCallback((e) => {
    setFilter(e)
    setPage(1)
  }, [])

  const handlePrevious = useCallback(() => {
    setPage((current) => Math.max(current - 1, 1))
  }, [])

  const handleNext = useCallback(() => {
    setPage((current) => current + 1)
  }, [])

  return (
    <div>
      <PageHeader title="Issues Summary">
        <PrintButton color="primary" />
      </PageHeader>
      <Filter>
        <Row>
          <Col sm={6}>
            <BaseField label="Dimension:">
              <Select value={dimension} onChange={handleDimensionChange}>
                <option key="all" value="all">
                  All
                </option>
                {showCategory && (
                  <option key="issue_category" value="issue_category">
                    Issue Category
                  </option>
                )}
                <option key="vendor" value="vendor">
                  {LabelTypes.VENDOR}
                </option>
              </Select>
            </BaseField>
            <Checkbox
              label="Include Archived"
              checked={includeArchived}
              onChange={handleIncludeArchivedChange}
            />
          </Col>
          <Col sm={6}>
            {dimension === 'vendor' && (
              <BaseField label={`Filter ${LabelTypes.VENDORS}:`}>
                <SearchableSelect
                  name="filter-vendors"
                  placeholder={`Filter ${LabelTypes.VENDORS}`}
                  value={filter}
                  isMulti
                  options={vendorsList || []}
                  onChange={handleFilterChange}
                />
              </BaseField>
            )}
            {dimension === 'issue_category' && (
              <BaseField label="Filter Issue Categories">
                <SearchableSelect
                  name="filter-categories"
                  placeholder="Filter Issue Categories"
                  value={filter}
                  isMulti
                  options={categoryList || []}
                  onChange={handleFilterChange}
                />
              </BaseField>
            )}
          </Col>
        </Row>
      </Filter>
      {isFetching ? (
        <Loader />
      ) : (
        Object.entries(issueSummary).map(([k, v]) => (
          <ChartsCard key={k}>
            <Title>{k === '' ? 'None' : k}</Title>
            <Row>
              {toPairs(v).map(([metric, data], index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Col key={index} md={4}>
                  <SubTitle>{metric || 'None'}</SubTitle>
                  <PieChartWidget data={data} metric={metric} />
                </Col>
              ))}
            </Row>
          </ChartsCard>
        ))
      )}
      <Footer>
        {page > 1 && (
          <Button onClick={handlePrevious}>&larr; Previous Page</Button>
        )}
        <span />
        {totalSize > page * perPage && (
          <Button onClick={handleNext}>Next Page &rarr;</Button>
        )}
      </Footer>
    </div>
  )
}

Container.propTypes = {
  showCategory: PropTypes.bool.isRequired,
}

export default withReactQuery()(Container)
