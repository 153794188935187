import styled from 'styled-components'
import { transparentize } from 'polished'
import { ExternalLinkAlt } from '@styled-icons/fa-solid/ExternalLinkAlt'
import { Unlink } from '@styled-icons/fa-solid/Unlink'
import { Cog, Envelope } from '@styled-icons/fa-solid'
import Icon from 'components-v2/atoms/Icon'
import { media } from 'styles'

export const ExternalLinkIcon = styled(Icon).attrs({ icon: ExternalLinkAlt })``
export const DisconnectIcon = styled(Icon).attrs({ icon: Unlink })``
export const CogIcon = styled(Icon).attrs({ icon: Cog })``
export const EnvelopeIcon = styled(Icon).attrs({ icon: Envelope })``

export const ErrorContainer = styled.div`
  background: #f1f3f7;
  border-radius: 5px;
  padding: 60px 15px;
  text-align: center;
`

export const ErrorTitle = styled.h3`
  color: #000;
`

export const ErrorDescription = styled.p`
  color: #4f4f4f;
`

export const ErrorButtonsWrapper = styled.div`
  margin-top: 40px;
  > * {
    margin: 0 5px;
  }
`

export const Container = styled.div`
  background: #f1f3f7;
  border-radius: 5px;
  padding: 60px 15px;
  text-align: center;
`

export const Title = styled.h3`
  color: #000;
`

export const SubTitle = styled.h4`
  color: #000;
`

export const RatingRow = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.tablet`
    flex-wrap: wrap;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  `}

  > * {
    margin: 11px;
  }
`

export const RatingCol = styled.div`
  width: 160px;
  text-align: left;
  color: #000;
  font-weight: 600;
`

export const Description = styled.p`
  color: #4f4f4f;
`

export const ButtonContainer = styled.div`
  display: inline-block;
`

export const Rate = styled.span.attrs((p) => ({
  color: p.color || '#000',
}))`
  float: right;
  display: inline-block;
  height: 22px;
  line-height: 20px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.07em;
  text-align: center;
  border: 1px solid ${(props) => props.color};
  background: ${(props) => transparentize(0.8, props.color)};
  color: ${(props) => props.color};

  ${({ rounded }) =>
    rounded
      ? `
    padding: 0 7px;
    border-radius: 5px;
  `
      : `
    width: 22px;
    border-radius: 100%;
  `}
`
