import styled from 'styled-components'
import { opacify } from 'polished'
import Dialog from 'components-v2/molecules/Dialog'
import { Panel } from 'components-v2/molecules/Dialog/styles'
import { colors, media } from 'styles'

export const StyledDialog = styled(Dialog)`
  ${Panel} {
    border-radius: 10px;
    box-shadow: none;
    border: none;

    ${media.desktop`
      width: 770px;
    `}
  }
`

export const DialogHeader = styled(Dialog.Header)`
  padding: 22px 34px 12px;
  border-bottom: 3px solid #f5f5f5;

  h4 {
    font-size: 24px;
    color: ${colors.BLACK};
  }
`

export const Body = styled.div`
  height: 494px;
  display: flex;
  color: ${colors.BODY_TEXT};
`

export const Sidebar = styled.div`
  flex: none;
  width: 220px;
  background: ${opacify(-0.5, colors.LIGHT_GREY)};
  border-bottom-left-radius: 10px;
  padding-top: 32px;
  padding-left: 34px;
`

export const Content = styled.div`
  flex: 1;
  padding: 32px 34px 32px 0;
`
