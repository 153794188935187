import styled from 'styled-components'
import Transition from 'components-v2/molecules/Transition'
import Card from 'components-v2/organisms/Card'
import { colors, media } from 'styles'

export const Wrapper = styled(Card)`
  position: relative;
  padding: 20px 22px 33px;
`

export const Top = styled.div`
  display: flex;
  align-items: center;
  min-height: 39px;
  justify-content: space-between;
  margin-bottom: 17px;
`

export const Title = styled.h3`
  margin: 0;
  color: ${colors.HEADING};

  ${media.tablet`
    font-size: 18px;
  `}
`

export const AutoSavedMessage = styled(Transition.Fade)`
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: 500;
  color: ${colors.PRIMARY};
`
