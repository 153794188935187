import React from 'react'

/**
 * Custom hook that runs an effect only when dependencies change, but not on the initial mount.
 *
 * @param {Function} effect - The effect function to be executed after the initial mount and when dependencies update.
 * @param {Array} dependencies - An array of dependencies that the effect depends on. The effect will only run when one of these values changes.
 */

export default function useUpdateEffect(effect, dependencies) {
  const isInitialMount = React.useRef(true)

  React.useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      effect()
    }
  }, dependencies)
}
