import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import isBefore from 'date-fns/isBefore'
import A from 'components-v2/atoms/A'
import Button from 'components-v2/atoms/Button'
import Table from 'components-v2/molecules/Table'
import Toolbar, { SearchInput } from 'components-v2/molecules/Table/Toolbar'
import PageHeader from 'components-v2/organisms/PageHeader'
import { dateFormatterFactory } from 'lib/table'
import ClientActionButton from './ClientActionButton'
import AdminActionButton from './AdminActionButton'
import { modeTypes, statuses } from './constants'
import { OriginalAssessmentLink, StatusRatingSelect } from './styles'

const dateFormatter = dateFormatterFactory()
const dueDateFormatter = dateFormatterFactory({
  showWarning: (_, date) => isBefore(date, new Date()),
})
const getTableColumns = (mode, onAddOrEditNote) => [
  {
    id: 'client_customer.name',
    header: 'Client',
    width: '15%',
  },
  {
    id: 'date_received',
    header: 'Received',
    cell: dateFormatter,
    width: '10%',
  },
  {
    id: 'due_at',
    header: 'Due',
    cell: dueDateFormatter,
    width: '10%',
  },
  {
    id: 'status',
    header: 'Status',
    cell: (props) => (
      <StatusRatingSelect
        value={props.getValue()}
        markers={statuses}
        readOnly
      />
    ),
    width: 100,
  },
  {
    id: 'submitted_at',
    header: 'Submitted',
    cell: dateFormatter,
    width: '10%',
  },
  {
    id: 'notes',
    header: 'Notes',
    cell: (props) => (
      <A onClick={() => onAddOrEditNote(props.row.original.id)} underline>
        {props.getValue() && props.getValue().length > 0
          ? 'View Note'
          : 'Add Note'}
      </A>
    ),
    enableSorting: false,
    width: '10%',
  },
  {
    id: 'df1',
    isDummyField: true,
    header: 'Original Assessment',
    cell: (props) => {
      let url
      let text
      if (props.row.original.original_assessment) {
        url = get(props.row.original.original_assessment, 'url')
        text = get(
          props.row.original.original_assessment,
          'name',
          '(no file name)',
        )
      }
      if (!url) {
        url = props.row.original.original_assessment_url
        text = url
      }
      if (!url) {
        return '(none)'
      }
      return (
        <OriginalAssessmentLink href={url} target="_new">
          {text}
        </OriginalAssessmentLink>
      )
    },
    enableSorting: false,
    width: '10%',
  },
  {
    id: 'df2',
    isDummyField: true,
    header: '',
    cell: (props) => {
      if (mode === modeTypes.MANAGER) {
        return (
          <AdminActionButton
            id={props.row.original.id}
            status={props.row.original.status}
            completeAssessment={props.row.original.complete_assessment}
          />
        )
      }
      return (
        <ClientActionButton
          id={props.row.original.id}
          status={props.row.original.status}
          completeAssessment={props.row.original.complete_assessment}
        />
      )
    },
    enableSorting: false,
    width: 150,
  },
]

const View = ({
  mode,
  assessments,
  totalSize,
  page,
  sizePerPage,
  sortField,
  sortOrder,
  searchText,
  loading,
  assistOrganizationName,
  assistOrganizationPath,
  onPaginationChange,
  onSortingChange,
  onSearchChange,
  onAnswerFilesBtnClick,
  onNewClientAssessmentBtnClick,
  onAddOrEditNote,
}) => {
  const tableColumns = React.useMemo(
    () => getTableColumns(mode, onAddOrEditNote),
    [mode, onAddOrEditNote],
  )
  return (
    <div>
      <PageHeader
        title="Client Assessments"
        sub={
          mode === modeTypes.MANAGER && (
            <h4>
              {assistOrganizationName}{' '}
              <span className="small">
                <a href={assistOrganizationPath}>edit</a>
              </span>
            </h4>
          )
        }
      >
        <Button onClick={onAnswerFilesBtnClick}>
          <i className="fa fa-fw fa-files-o" />
          Answer Files
        </Button>
        &nbsp;
        <Button color="primary" onClick={onNewClientAssessmentBtnClick}>
          <i className="fa fa-fw fa-plus" />
          New Client Assessment
        </Button>
      </PageHeader>
      <Toolbar>
        <SearchInput value={searchText} onChange={onSearchChange} />
      </Toolbar>
      <Table
        loading={loading}
        data={assessments || []}
        columns={tableColumns}
        totalSize={totalSize}
        pagination={{ page, sizePerPage }}
        sorting={{ sortField, sortOrder }}
        onPaginationChange={onPaginationChange}
        onSortingChange={onSortingChange}
        manualPagination
        manualSorting
        noDataText="No assessments found."
        type={Table.types.ALT}
      />
    </div>
  )
}

View.propTypes = {
  mode: PropTypes.string,
  assessments: PropTypes.array,
  totalSize: PropTypes.number,
  page: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  sortField: PropTypes.string,
  sortOrder: PropTypes.string,
  searchText: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  assistOrganizationName: PropTypes.string,
  assistOrganizationPath: PropTypes.string,
  onPaginationChange: PropTypes.func.isRequired,
  onSortingChange: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onAnswerFilesBtnClick: PropTypes.func.isRequired,
  onNewClientAssessmentBtnClick: PropTypes.func.isRequired,
  onAddOrEditNote: PropTypes.func.isRequired,
}

export default View
