import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'components-v2/atoms/Icon'
import Tabs from 'components-v2/molecules/Tabs'
import PageHeader from 'components-v2/organisms/PageHeader'
import CardsView from './CardsView'
import ListView from './ListView'
import { Wrapper } from './styles'

const View = ({
  assessments,
  isFetching,
  activeTab,
  onTabSelect,
  onRequestExtension,
}) => (
  <Wrapper>
    <PageHeader title="Assessments" />
    <Tabs activeKey={activeTab} onChange={onTabSelect}>
      <Tabs.Tab
        eventKey="cards"
        title={
          <span>
            <Icon icon="fa fa-columns" /> Cards
          </span>
        }
      >
        <CardsView
          assessments={assessments}
          isFetching={isFetching}
          onRequestExtension={onRequestExtension}
        />
      </Tabs.Tab>
      <Tabs.Tab
        eventKey="list"
        title={
          <span>
            <Icon icon="fa fa-table" /> List
          </span>
        }
      >
        <ListView assessments={assessments} isFetching={isFetching} />
      </Tabs.Tab>
    </Tabs>
  </Wrapper>
)

View.propTypes = {
  assessments: PropTypes.array,
  isFetching: PropTypes.bool,
  activeTab: PropTypes.string,
  onTabSelect: PropTypes.func.isRequired,
  onRequestExtension: PropTypes.func.isRequired,
}

export default View
