import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import styled from 'styled-components'
import { RadioGroup } from '@headlessui/react'
import { colors } from 'styles'
import BaseField from './BaseField'
import Radio from '../../atoms/Radio'
import { Indicator } from '../../atoms/Radio/styles'

const List = styled(RadioGroup)`
  margin: 7px 0;
`

const StyledRadio = styled(Radio)`
  &:not(:first-child) {
    margin-top: 4px;
  }

  &:not(:last-child) {
    margin-bottom: 4px;
    margin-right: 10px;
  }

  ${({ $active }) =>
    $active &&
    `
      ${Indicator}:after {
        position: absolute;
        content: ' ';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: -1px;
        border-radius: inherit;
        box-shadow: 0 0 0 3px ${colors.PRIMARY};
        opacity: 0.2;
      }
    `}
`

const StyledRadioContainer = styled.div`
  outline: none;
`

const RadioGroupField = ({
  name,
  options,
  value,
  disabled,
  inline,
  onChange,
  ...rest
}) => (
  <BaseField {...rest}>
    <List value={value} onChange={onChange}>
      {map(options, (option) => (
        <RadioGroup.Option
          key={option.value}
          value={option.value}
          disabled={disabled}
          onChange={() => onChange(option.value)}
          as={Fragment}
        >
          {({ active, checked }) => (
            <StyledRadioContainer>
              <StyledRadio
                label={option.label}
                inline={inline}
                checked={checked}
                disabled={disabled}
                tabIndex={-1}
                $active={active}
              />
            </StyledRadioContainer>
          )}
        </RadioGroup.Option>
      ))}
    </List>
  </BaseField>
)

RadioGroupField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  options: PropTypes.array,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

RadioGroupField.defaultProps = {
  options: [],
}

export default RadioGroupField
