import styled from 'styled-components'
import Card from 'components-v2/organisms/Card'
import { media } from 'styles'

export const Wrap = styled(Card).attrs({ padding: true })`
  padding: 27px 32px;
`

export const Inner = styled.div`
  ${media.desktop`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`

export const Heading = styled.h3`
  font-size: 20px;
  margin: 0 0 5px;
`

export const Description = styled.div`
  font-size: 18px;
`

export const Content = styled.div`
  ${media.desktop`
    margin-right: 20px;
  `}

  ${media.tablet`
    margin-bottom: 20px;
  `}
`

export const ButtonsWrapper = styled.div`
  > *:not(:last-child) {
    margin-right: 10px;
  }
`
