import React, { useCallback, useState, useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import config from 'config'
import {
  useClientWatchlistsFetcher,
  useClientGlobalProfilesSummaryFetcher,
} from 'apis'
import { useAuthorizations } from 'hooks'
import View from './View'
import useCRUDWatchlist from './useCRUDWatchlist'
import useGloborgWatchlistsMutations from './useGloborgWatchlistsMutations'
import useCreateVendorFromGloborg from './useCreateVendorFromGloborg'
import { getTableColumns, orderedByMap } from './columns'

const initialTableState = {
  page: 1,
  sizePerPage: config.collectionQueryPageLimit.default,
}

const Container = ({ orgId, watchlists: initialWatchlists }) => {
  const [canManage] = useAuthorizations('manage', 'Watchlist')
  const { data: watchlists } = useClientWatchlistsFetcher(undefined, {
    initialData: {
      data: initialWatchlists,
    },
  })
  const [watchlistId, setWatchlistId] = useState(watchlists[0]?.id)
  const [tableState, setTableState] = useState(initialTableState)
  const [searchText, setSearchText] = useState('')
  const [globalOrg, setGlobalOrg] = useState()
  const {
    isFetching,
    data: globalOrgs = [],
    totalSize,
  } = useClientGlobalProfilesSummaryFetcher({
    params: {
      watchlist_id: watchlistId,
      per_page: tableState.sizePerPage,
      page: tableState.page,
      ordered_by: orderedByMap[tableState.sortField] || tableState.sortField,
      direction: tableState.sortOrder,
      search_query: searchText,
    },
  })
  const globalOrgsRef = useRef()
  globalOrgsRef.current = globalOrgs
  const { createWatchlist, updateWatchlist, deleteWatchlist } =
    useCRUDWatchlist()
  const {
    updateGloborgWatchlists,
    addGloborgToNewWatchlist,
    removeGloborgFromWatchlist,
  } = useGloborgWatchlistsMutations(globalOrgsRef, watchlistId)
  const createVendorFromGloborg = useCreateVendorFromGloborg(globalOrgsRef)

  const handlePaginationChange = useCallback((newState) => {
    setTableState((current) => ({ ...current, ...newState }))
  }, [])

  const handleSortingChange = useCallback((newState) => {
    setTableState((current) => ({ ...current, ...newState }))
  }, [])

  const handleSearchChange = useCallback((newSearchText) => {
    // Let's reset page to 1
    setTableState((state) => ({ ...state, page: 1 }))
    setSearchText(newSearchText)
  }, [])

  const handleWatchlistChange = useCallback((newWatchlistId) => {
    // Let's reset page to 1
    setTableState((state) => ({ ...state, page: 1 }))
    setWatchlistId(newWatchlistId)
  }, [])

  // If selected watchlist is not available anymore (i.e. by removing), reset to "All Companies"
  useEffect(() => {
    if (watchlistId && !watchlists.map((e) => e.id).includes(watchlistId)) {
      setWatchlistId()
    }
  }, [watchlistId, watchlists])

  const watchlistSelected = !!watchlistId
  const tableColumns = useMemo(
    () =>
      getTableColumns(
        watchlists,
        watchlistSelected,
        addGloborgToNewWatchlist,
        updateGloborgWatchlists,
        createVendorFromGloborg,
        removeGloborgFromWatchlist,
        setGlobalOrg,
        canManage,
      ),
    [
      watchlists,
      watchlistSelected,
      addGloborgToNewWatchlist,
      updateGloborgWatchlists,
      createVendorFromGloborg,
      removeGloborgFromWatchlist,
      canManage,
    ],
  )

  return (
    <View
      orgId={orgId}
      watchlists={watchlists}
      globalOrgs={globalOrgs}
      watchlistId={watchlistId}
      globalOrg={globalOrg}
      tableState={tableState}
      searchText={searchText}
      totalSize={totalSize}
      loading={isFetching}
      tableColumns={tableColumns}
      onPaginationChange={handlePaginationChange}
      onSortingChange={handleSortingChange}
      onSearchChange={handleSearchChange}
      onWatchlistChange={handleWatchlistChange}
      onCreateWatchlistClick={() => createWatchlist()}
      onEditWatchlistClick={() => updateWatchlist(watchlists, watchlistId)}
      onDeleteWatchlistClick={() => deleteWatchlist(watchlistId)}
      onBackToWatchlists={() => setGlobalOrg()}
    />
  )
}

Container.propTypes = {
  orgId: PropTypes.string.isRequired,
  watchlists: PropTypes.array.isRequired,
}

export default Container
