import React from 'react'
import PropTypes from 'prop-types'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import { AssessmentElementTypes } from 'constants/index'
import { getResponseScoreLabelByValue } from 'lib/assessment'
import Button from 'components-v2/atoms/Button'
import IconButton from 'components-v2/atoms/IconButton'
import ButtonGroup from 'components-v2/molecules/ButtonGroup'
import RadioGroupField from 'components-v2/molecules/FormField/RadioGroupField'
import MultiCheckboxField from 'components-v2/molecules/FormField/MultiCheckboxField'
import { ControlLabel } from 'components-v2/molecules/FormField'
import Tooltip from 'components-v2/molecules/Tooltip'
import AutomationSourceDisplay from './AutomationSourceDisplay'
import ExistingAutomationsTable from './ExistingAutomationsTable'

import {
  DetailPanelDivider,
  AutomationTitleRow,
  AutomationTitle,
  IconBlock,
  ScoreLimitWrapper,
  StyledResponseScoreSelect,
  DetailDisplayRow,
  DetailDisplayLabel,
  DetailDisplayBody,
  SourceDivider,
} from './styles'

const QuestionAutomationModalView = ({
  element,
  ratingLabelSet,
  automationIndex,
  currentAutomation,
  currentAutomationGroupID,
  currentAutomationGroup,
  currentStub,
  currentClause,
  scoreOperatorList,
  maxScore,
  minScore,
  mode,
  validTriggers,
  extras,
  automationSourceTypes,
  resetElement,
  setCurrentAutomationGroupID,
  setCurrentStub,
  setCurrentClause,
  setTriggerType,
  setRatingScore,
  onScoreOperatorButtonClick,
  setChoices,
  setAutomationSourceTypes,
  onDeleteAutomationGroup,
  onResetStub,
  onClearStub,
  onResetClause,
  onClearClause,
  onOpenSourceForm,
  onLargeTextViewClick,
}) => {
  const renderTriggerDisplay = (el, currAuto) => {
    const operatorLabels = {
      '<': 'less than',
      '<=': 'less than or equal to',
      '==': 'equal to',
      '=>': 'greater than or equal to',
      '>': 'greater than',
    }

    const choiceMap = keyBy(el.choices, 'id')
    return (
      <DetailDisplayRow>
        <DetailDisplayLabel>If: </DetailDisplayLabel>
        {currAuto.trigger_type === 'score' ? (
          <DetailDisplayBody>
            The reviewed score is {operatorLabels[currAuto.score_oper]} &quot;
            {getResponseScoreLabelByValue(currAuto.score, ratingLabelSet)}&quot;
          </DetailDisplayBody>
        ) : (
          <DetailDisplayBody>
            The selected answer is
            {currAuto.choices.length > 1 && ' one of'}:
            <ul>
              {map(currAuto.choices, (c) => (
                <li key={c}>{choiceMap[c].text}</li>
              ))}
            </ul>
          </DetailDisplayBody>
        )}
      </DetailDisplayRow>
    )
  }

  const renderTriggerTypeSelection = (
    el,
    currAuto,
    triggers,
    handleSetTriggerType,
  ) =>
    el.type !== AssessmentElementTypes.TEXT_QUESTION ? (
      <RadioGroupField
        label="Automation applies to the following condition(s):"
        value={currAuto.trigger_type}
        inline
        options={triggers}
        onChange={handleSetTriggerType}
      />
    ) : (
      <ControlLabel>Automation applies if assigned score is:</ControlLabel>
    )

  const renderTriggerDetails = (
    el,
    currAuto,
    handleSetRatingScore,
    max,
    min,
    operatorList,
    handleScoreOperatorButtonClick,
    handleSetChoices,
  ) => {
    if (currAuto && currAuto.trigger_type === 'score') {
      return (
        <>
          {el.type !== AssessmentElementTypes.TEXT_QUESTION && (
            <ControlLabel>if the assigned score is:</ControlLabel>
          )}
          <ScoreLimitWrapper>
            <ButtonGroup>
              <Button
                active={operatorList.includes('<')}
                disabled={currAuto.score === min}
                onClick={() =>
                  handleScoreOperatorButtonClick(
                    operatorList.includes('<')
                      ? operatorList.filter((e) => e !== '<')
                      : [...operatorList, '<'],
                  )
                }
              >
                Less than
              </Button>
              <Button
                active={operatorList.includes('=')}
                onClick={() =>
                  handleScoreOperatorButtonClick(
                    operatorList.includes('=')
                      ? operatorList.filter((e) => e !== '=')
                      : [...operatorList, '='],
                  )
                }
              >
                Equal
              </Button>
              <Button
                active={operatorList.includes('>')}
                disabled={currAuto.score === max}
                onClick={() =>
                  handleScoreOperatorButtonClick(
                    operatorList.includes('>')
                      ? operatorList.filter((e) => e !== '>')
                      : [...operatorList, '>'],
                  )
                }
              >
                More than
              </Button>
            </ButtonGroup>
            <StyledResponseScoreSelect
              value={currAuto.score}
              weight={5}
              ratingLabelSet={ratingLabelSet}
              onChange={handleSetRatingScore}
            />
          </ScoreLimitWrapper>
        </>
      )
    }
    if (currAuto && currAuto.trigger_type === 'choices') {
      return (
        <MultiCheckboxField
          label="if the respondent chooses any of the following answers:"
          value={currAuto.choices}
          options={map(el.choices, (c) => ({
            label: c.text,
            value: c.id,
          }))}
          onChange={handleSetChoices}
        />
      )
    }
    return null
  }

  const renderTriggerFields = (
    el,
    currAuto,
    triggers,
    handleSetTriggerType,
    handleSetRatingScore,
    max,
    min,
    operatorList,
    handleScoreOperatorButtonClick,
    handleSetChoices,
  ) => (
    <>
      {renderTriggerTypeSelection(el, currAuto, triggers, handleSetTriggerType)}
      {renderTriggerDetails(
        el,
        currAuto,
        handleSetRatingScore,
        max,
        min,
        operatorList,
        handleScoreOperatorButtonClick,
        handleSetChoices,
      )}
    </>
  )

  return (
    <>
      {element.question_automations.length > 0 && (
        <ExistingAutomationsTable
          element={element}
          ratingLabelSet={ratingLabelSet}
          automationIndex={automationIndex}
          currentAutomation={currentAutomation}
          currentAutomationGroupID={currentAutomationGroupID}
          setCurrentAutomationGroupID={setCurrentAutomationGroupID}
          onDeleteAutomationGroup={onDeleteAutomationGroup}
        />
      )}

      {mode !== 'list' && (
        <>
          {element.question_automations.length > 0 && <DetailPanelDivider />}
          <AutomationTitleRow>
            <AutomationTitle>
              <h6>
                {mode === 'add' ? 'Add automation' : 'Automation Details'}
              </h6>
            </AutomationTitle>
            {element.question_automations.length > 0 && (
              <IconBlock>
                <Tooltip
                  parent={
                    <IconButton icon="fa fa-close" onClick={resetElement} />
                  }
                >
                  Close details panel
                </Tooltip>
              </IconBlock>
            )}
          </AutomationTitleRow>
          {mode === 'display'
            ? renderTriggerDisplay(element, currentAutomation)
            : renderTriggerFields(
                element,
                currentAutomation,
                validTriggers,
                setTriggerType,
                setRatingScore,
                maxScore,
                minScore,
                scoreOperatorList,
                onScoreOperatorButtonClick,
                setChoices,
              )}

          {mode !== 'display' && <hr />}
          {(mode === 'add' || mode === 'edit') && (
            <MultiCheckboxField
              label="If conditions are met, automation will add:"
              value={automationSourceTypes}
              options={[
                { label: 'Issue', value: 'issue_stub' },
                { label: 'AutoReport Section', value: 'clause' },
              ]}
              onChange={setAutomationSourceTypes}
            />
          )}

          {/* tempted to just do two separate component tags, one for each type,
          but this structure retains flexibility if/when we add more automation types
          (emails, webhooks, slack message posts, who knows what else)
        */}
          {map(['issue_stub', 'clause'], (src) => {
            let source
            let sourceAuto
            let setSource
            let resetSource
            let clearSource
            // let currentSourceAutomation;
            if (src === 'clause') {
              source = currentClause
              sourceAuto = currentAutomationGroup.clause || currentAutomation
              setSource = setCurrentClause
              resetSource = onResetClause
              clearSource = onClearClause
            } else {
              source = currentStub
              sourceAuto =
                currentAutomationGroup.issue_stub || currentAutomation
              setSource = setCurrentStub
              resetSource = onResetStub
              clearSource = onClearStub
            }
            return (
              automationSourceTypes.includes(src) &&
              currentAutomation && (
                <React.Fragment key={`${src}-display-block`}>
                  {src === 'clause' && automationSourceTypes.length > 1 && (
                    <center>
                      <SourceDivider width="30%" />
                    </center>
                  )}
                  <AutomationSourceDisplay
                    sourceType={src}
                    element={element}
                    currentAutomation={sourceAuto}
                    currentSource={source}
                    mode={mode}
                    extras={extras}
                    automationSourceTypes={automationSourceTypes}
                    setCurrentSource={setSource}
                    onOpenSourceForm={onOpenSourceForm}
                    onLargeTextViewClick={onLargeTextViewClick}
                    onResetSource={resetSource}
                    onClearSource={clearSource}
                  />
                </React.Fragment>
              )
            )
          })}
        </>
      )}
    </>
  )
}

/*

  Rough pseudocode we're aiming for:

  if qas
    - mode: list
    - display table
      - item clicked -> mode: edit
    - display add button
  else
    - mode: add
  /if

  if mode == add
    - display trigger selection fields
    - display type selection (checkboxes)
    - for each type selected
      - use existing? (display select)
        - display data for selected
        - display [edit this stub] and [copy to new stub]
          - edit clicked -> sourceMode: edit, pre-filled
          - copy clicked -> sourceMode: add, pre-filled (no id)
      - or add new? -> sourceMode: add
        - display empty form fields
  else if mode == edit
    - sourceMode: display
    - display trigger selection fields
    - display type value
    - use existing? (display select, current source pre-selected)
      - display data for selected
      - display [edit this stub] and [copy to new stub]
        - edit clicked -> sourceMode: edit, pre-filled
        - copy clicked -> sourceMode: add, pre-filled (no id)
    - or add new? -> sourceMode: add
      - display empty form fields
  /if

  */

QuestionAutomationModalView.propTypes = {
  element: PropTypes.object.isRequired,
  ratingLabelSet: PropTypes.string,
  automationIndex: PropTypes.object.isRequired,
  currentAutomation: PropTypes.object,
  currentAutomationGroupID: PropTypes.string,
  currentAutomationGroup: PropTypes.object.isRequired,
  currentStub: PropTypes.object,
  currentClause: PropTypes.object,
  scoreOperatorList: PropTypes.arrayOf(PropTypes.string),
  maxScore: PropTypes.number.isRequired,
  minScore: PropTypes.number.isRequired,
  mode: PropTypes.string.isRequired,
  extras: PropTypes.object,
  automationSourceTypes: PropTypes.array,
  validTriggers: PropTypes.array.isRequired,
  resetElement: PropTypes.func.isRequired,
  setCurrentAutomationGroupID: PropTypes.func.isRequired,
  setCurrentStub: PropTypes.func.isRequired,
  setCurrentClause: PropTypes.func.isRequired,
  setTriggerType: PropTypes.func.isRequired,
  setRatingScore: PropTypes.func.isRequired,
  onScoreOperatorButtonClick: PropTypes.func.isRequired,
  setChoices: PropTypes.func.isRequired,
  setAutomationSourceTypes: PropTypes.func.isRequired,
  onDeleteAutomationGroup: PropTypes.func.isRequired,
  onResetStub: PropTypes.func.isRequired,
  onClearStub: PropTypes.func.isRequired,
  onResetClause: PropTypes.func.isRequired,
  onClearClause: PropTypes.func.isRequired,
  onOpenSourceForm: PropTypes.func.isRequired,
  onLargeTextViewClick: PropTypes.func.isRequired,
}

export default QuestionAutomationModalView
