import React from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash/omit'
import isNumber from 'lodash/isNumber'
import findLast from 'lodash/findLast'
import { ResponsiveContainer, PieChart, Pie } from 'recharts'
import { colors } from 'styles'

const RADIAN = Math.PI / 180

const getPosition = (angle, radius, cx, cy) => {
  const sin = Math.sin(-RADIAN * angle)
  const cos = Math.cos(RADIAN * angle)
  const x = (cx + radius * cos).toFixed(3)
  const y = (cy + radius * sin).toFixed(3)
  return [x, y]
}

/* eslint-disable react/prop-types */
const GaugeBar = React.memo(
  ({
    outerRadius,
    innerRadius,
    startAngle,
    endAngle,
    cx,
    cy,
    points,
    hideLabels,
  }) => {
    const id = React.useId()
    const gutterAngle = endAngle > startAngle ? 1 : -1
    const radius = (innerRadius + outerRadius) / 2

    return (
      <g
        fill="none"
        strokeWidth={outerRadius - innerRadius}
        transform={`translate(${cx},${cy})`}
      >
        {points.map((currentPoint, index) => {
          if (index === points.length - 1) {
            return null
          }
          const nextPoint = points[index + 1]
          const sAngle = startAngle + currentPoint.pos * (endAngle - startAngle)
          const eAngle =
            startAngle +
            nextPoint.pos * (endAngle - startAngle) -
            (index === points.length - 2 ? 0 : gutterAngle)
          const [sx, sy] = getPosition(sAngle, radius, 0, 0)
          const [ex, ey] = getPosition(eAngle, radius, 0, 0)
          return (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={index}>
              <defs>
                <linearGradient
                  id={`${id}_linearGradient${index}`}
                  gradientUnits="userSpaceOnUse"
                  x1={sx}
                  y1={sy}
                  x2={ex}
                  y2={ey}
                >
                  <stop offset="0%" stopColor={currentPoint.color} />
                  <stop offset="100%" stopColor={nextPoint.color} />
                </linearGradient>
              </defs>
              <path
                d={`M ${sx},${sy} A ${radius},${radius} 0 0,1 ${ex},${ey}`}
                stroke={`url(#${id}_linearGradient${index})`}
              />
            </React.Fragment>
          )
        })}
        {!hideLabels &&
          points.map((currentPoint, index) => {
            const sAngle =
              startAngle + currentPoint.pos * (endAngle - startAngle)
            const [ax, ay] = getPosition(sAngle, radius + 5, 0, 0)
            return (
              <text
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                x={ax}
                y={ay}
                textAnchor={ax > 0 ? 'start' : 'end'}
                fill={currentPoint.color}
              >
                {currentPoint.label}
              </text>
            )
          })}
      </g>
    )
  },
)
/* eslint-enable react/prop-types */

/* eslint-disable react/prop-types */
const Marker = React.memo(({ outerRadius, angle, cx, cy, color }) => {
  const [sx, sy] = getPosition(angle, outerRadius * 1.03, cx, cy)
  const [mx, my] = getPosition(angle - 3, outerRadius * 1.2, cx, cy)
  const [ex, ey] = getPosition(angle + 3, outerRadius * 1.2, cx, cy)
  return (
    <path
      d={`M${sx},${sy} ${mx},${my} ${ex},${ey} z`}
      stroke="none"
      fill={color}
    />
  )
})
/* eslint-enable react/prop-types */

const GaugeChart = ({
  value,
  min,
  max,
  width,
  height,
  points: pointsProp,
  color,
  markerColor: markerColorProp,
  hideLabels,
  className,
  ...rest
}) => {
  const points = React.useMemo(() => {
    const temp = isNumber(pointsProp) ? Array(pointsProp).fill({}) : pointsProp
    return temp.map((e, index) => ({
      pos: index / (temp.length - 1),
      label: min + ((max - min) * index) / (temp.length - 1),
      color,
      ...e,
    }))
  }, [pointsProp, color, max, min])

  const ActiveShape = React.useMemo(
    // eslint-disable-next-line react/no-unstable-nested-components
    () => (props) => {
      // eslint-disable-next-line react/prop-types
      const { startAngle, endAngle, innerRadius, outerRadius, cx, cy } = props
      const markerColor =
        markerColorProp ||
        findLast(
          points,
          (e) =>
            (endAngle - startAngle) / (rest.endAngle - startAngle) >= e.pos,
        )?.color
      return (
        <g>
          <Marker
            outerRadius={outerRadius}
            angle={endAngle}
            cx={cx}
            cy={cy}
            color={markerColor}
          />
          <GaugeBar
            outerRadius={outerRadius}
            innerRadius={innerRadius}
            startAngle={startAngle}
            endAngle={rest.endAngle}
            cx={cx}
            cy={cy}
            points={points}
            hideLabels={hideLabels}
          />
        </g>
      )
    },
    [points, rest.endAngle, min, max, markerColorProp, hideLabels],
  )

  return (
    <ResponsiveContainer width={width} height={height} className={className}>
      <PieChart>
        <Pie
          {...rest}
          dataKey="value"
          data={[{ value: value - min }, { value: max - value }]}
          fill="none"
          stroke="none"
          activeIndex={0}
          activeShape={ActiveShape}
        />
      </PieChart>
    </ResponsiveContainer>
  )
}

GaugeChart.propTypes = {
  ...omit(Pie.propTypes, 'dataKey'),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.number.isRequired,
  min: PropTypes.number,
  max: PropTypes.number.isRequired,
  startAngle: PropTypes.number,
  endAngle: PropTypes.number,
  points: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  color: PropTypes.string,
  markerColor: PropTypes.string,
  hideLabels: PropTypes.bool,
}

GaugeChart.defaultProps = {
  min: 0,
  startAngle: 180,
  endAngle: 0,
  points: 2,
  color: colors.PRIMARY,
  markerColor: undefined,
  hideLabels: false,
}

export default GaugeChart
