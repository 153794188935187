import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from 'components-v2/atoms/Icon'
import Popover from 'components-v2/molecules/Popover'
import { colors, fonts, mixins } from 'styles'

const PopoverButton = styled.button`
  ${mixins.clearButtonStyle}
`

const QuestionIcon = styled(Icon).attrs({
  icon: 'fa fa-question-circle',
})`
  font-size: 12px;
`

const FinePrintContent = styled.div`
  padding: 10px 14px;
  width: 490px;
  font-family: ${fonts.PRIMARY};
  font-size: 14px;
  font-weight: normal;
  color: ${colors.BODY_TEXT};
  text-transform: none;
  word-wrap: break-word;
`

const LegalTipPopover = ({ children }) => (
  <Popover>
    <Popover.Button as={PopoverButton}>
      <QuestionIcon />
    </Popover.Button>
    <Popover.Panel arrow>
      <FinePrintContent>{children}</FinePrintContent>
    </Popover.Panel>
  </Popover>
)

LegalTipPopover.propTypes = {
  children: PropTypes.any,
}

export default LegalTipPopover
