import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SwaggerUI from 'swagger-ui-react'
import 'swagger-ui-react/swagger-ui.css'
import * as mixins from 'styles/mixins'
import PrintButton from 'components-v2/molecules/PrintButton'

const Wrapper = styled.div`
  .swagger-ui pre {
    background: none;
    border: none;
  }
`

const NonPrintView = styled.div`
  ${mixins.hidePrint}
  .swagger-ui .wrapper {
    padding: 0;
  }
`

const PrintView = styled.div`
  ${mixins.showPrint}
  .swagger-ui {
    .highlight-code > .microlight {
      max-height: none;
    }

    .opblock-summary-control,
    .models-control,
    .model-box-control {
      display: flex !important;
    }

    .opblock-summary-control svg,
    .models-control svg,
    .model-toggle {
      display: none;
    }

    .operation-tag-content > span {
      display: block;
      page-break-after: always;
    }

    .opblock-tag-section,
    .models {
      ${mixins.avoidPageBreak}
    }

    // Center the main information content vertically on the first print page

    .information-container {
      margin-top: 50vh;
      transform: translateY(-50%);
    }
  }
`

const PrintButtonWrapper = styled.div`
  position: relative;
`

const StyledPrintButton = styled(PrintButton)`
  position: absolute;
  right: 0;
  top: 60px;
`

export default function Swagger({ apidocsPath = '/apidocs' }) {
  const printViewRef = React.useRef()
  const handleCompleteForPrintView = React.useCallback(() => {
    // Expand "Models" section
    const matches =
      printViewRef.current?.getElementsByClassName('model-box-control')
    for (let i = 0; i < matches.length; i++) {
      matches[i].click()
    }
  }, [])
  return (
    <Wrapper>
      <NonPrintView>
        <div className="container">
          <PrintButtonWrapper>
            <StyledPrintButton color="primary" />
          </PrintButtonWrapper>
          <SwaggerUI url={apidocsPath} />
        </div>
      </NonPrintView>

      <PrintView ref={printViewRef}>
        <SwaggerUI
          url={apidocsPath}
          docExpansion="full"
          defaultModelsExpandDepth={20}
          onComplete={handleCompleteForPrintView}
        />
      </PrintView>
    </Wrapper>
  )
}

Swagger.propTypes = {
  apidocsPath: PropTypes.string,
}
