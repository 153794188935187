import isEmpty from 'lodash/isEmpty'
import keyBy from 'lodash/keyBy'
import { LabelTypes } from 'constants/index'
import defaultConfig from './questions.json'
import * as api from './api'

export const calculateTier = (score, divs) => {
  if (score <= divs[0]) return 4
  if (score <= divs[1]) return 3
  if (score <= divs[2]) return 2
  return 1
}

export const calculateRiskTier = (riskTierAnswers, calculatorConfig) => {
  if (isEmpty(riskTierAnswers)) return null

  const { questions, tier_divisions: tierDivisions } = calculatorConfig
  const score = riskTierAnswers.reduce((total, answer) => {
    const question = questions.find((e) => e.id === answer.id)
    return total + (question ? question.choices[answer.value] : 0)
  }, 0)

  return calculateTier(score, tierDivisions)
}

const buildAnswerResponse = (riskTierAnswers, calculatorConfig) => {
  // check: don't need to use keyBy here if we can assume questions
  // array is already ordered
  const questions = keyBy(calculatorConfig.questions, (q) => q.order)
  const res = riskTierAnswers.map((o, i) => {
    const idx = i + 1
    return { id: questions[idx].id, value: o }
  })
  return res
}

export const analyzeRiskTierAnswers = (riskTierAnswers, calculatorConfig) => {
  const build = buildAnswerResponse(riskTierAnswers, calculatorConfig)
  const score = calculateRiskTier(riskTierAnswers, calculatorConfig)
  return [build, score]
}

const cleanQuestions = (config) => {
  if (LabelTypes.VENDOR.toLowerCase() !== 'vendor') {
    return {
      ...config,
      questions: config.questions.map((q) => ({
        ...q,
        question: q.question.replace(
          /vendor/gi,
          LabelTypes.VENDOR.toLowerCase(),
        ),
      })),
    }
  }
  return config
}

export const getCalcConfig = (calculatorId = 'default') => {
  if (calculatorId === 'default') {
    return { ...cleanQuestions(defaultConfig) }
  }

  // FIXME: untested, but we're not using custom calcs yet anyway:
  const resp = api.getRiskTierCalculator(calculatorId)
  const thisCalc = isEmpty(resp.data)
    ? cleanQuestions(defaultConfig)
    : resp.data
  return thisCalc
}
