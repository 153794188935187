import { colors } from 'styles'
import map from 'lodash/map'

const ProjectStatusTypes = {
  DRAFT: 'draft',
  RECEIVED: 'received',
  LAUNCHED: 'launched',
  IN_PROCESS: 'in_process',
  CLOSED: 'closed',
  SUBMITTED: 'submitted',
  REVIEWED: 'reviewed',
  ARCHIVED: 'archived',
}

export const ProjectStatusOptions = map(
  [
    {
      value: ProjectStatusTypes.DRAFT,
      color: colors.MED_DARK_GREY,
    },
    {
      value: ProjectStatusTypes.RECEIVED,
      color: colors.MED_DARK_GREY,
    },
    {
      value: ProjectStatusTypes.LAUNCHED,
      color: colors.YELLOW,
    },
    {
      value: ProjectStatusTypes.IN_PROCESS,
      color: colors.YELLOW,
    },
    {
      value: ProjectStatusTypes.CLOSED,
      color: colors.BLUE,
    },
    {
      value: ProjectStatusTypes.SUBMITTED,
      color: colors.BLUE,
    },
    {
      value: ProjectStatusTypes.REVIEWED,
      color: colors.GREEN,
    },
    {
      value: ProjectStatusTypes.ARCHIVED,
      color: colors.DANGER,
    },
  ],
  (o) => ({ ...o, label: o.value.titleize() }),
)

export default ProjectStatusTypes
