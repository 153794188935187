import styled from 'styled-components'

export const Title = styled.div`
  position: relative;
`

export const DotWrapper = styled.div`
  position: absolute;
  left: -30px;
  top: 13px;
`
