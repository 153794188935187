import React from 'react'
import PropTypes from 'prop-types'
import mapValues from 'lodash/mapValues'
import toArray from 'lodash/toArray'
import { GaugeChart2 } from 'components-v2/molecules/Charts'
import { OsanoScoreRangeConfig, OsanoScoreMax } from 'constants/index'
import { ChartWrapper, MetricWrapper, Metric } from './styles'

const OsanoScoreRangeSortedArr = toArray(
  mapValues(OsanoScoreRangeConfig, (value, key) => ({ ...value, start: key })),
).sort((a, b) => a.start - b.start)

const points = [
  ...OsanoScoreRangeSortedArr.map((e) => ({
    color: e.color,
    label: e.start,
  })),
  {
    color: OsanoScoreRangeSortedArr[OsanoScoreRangeSortedArr.length - 1].color,
    label: OsanoScoreMax,
  },
]

const MAX = 100

const OsanoScoreChart = ({ score, rangeLabel, color, ...rest }) => {
  // Osano score range interval is not consistent for each range
  // Let's convert into equal interval, so GaugeChart2 can draw it correctly
  const value = React.useMemo(
    () =>
      OsanoScoreRangeSortedArr.reduce((current, range, index) => {
        const { start } = range
        if (start > score) {
          return current
        }
        const end =
          index === OsanoScoreRangeSortedArr.length - 1
            ? OsanoScoreMax
            : OsanoScoreRangeSortedArr[index + 1].start
        if (end <= score) {
          return current + MAX / OsanoScoreRangeSortedArr.length
        }
        return (
          current +
          (((score - start) / (end - start)) * MAX) /
            OsanoScoreRangeSortedArr.length
        )
      }, 0),
    [score],
  )
  return (
    <ChartWrapper {...rest}>
      <GaugeChart2
        value={value}
        max={MAX}
        width="100%"
        height={138}
        outerRadius={110}
        innerRadius={104}
        cy={128}
        points={points}
      />
      <MetricWrapper>
        <Metric color={color} left={rangeLabel} right={score} />
      </MetricWrapper>
    </ChartWrapper>
  )
}

OsanoScoreChart.propTypes = {
  score: PropTypes.number.isRequired,
  rangeLabel: PropTypes.string,
  color: PropTypes.string,
}

export default OsanoScoreChart
