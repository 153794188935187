import styled from 'styled-components'
import Checkbox from 'components-v2/atoms/Checkbox'
import Toolbar from 'components-v2/molecules/Table/Toolbar'
import { Col } from 'components-v2/organisms/Layout'
import { fonts, media, mixins, sizes } from 'styles'

export const theme = {
  hGutter: 25,
  vGutter: 24,
}

export const Section = styled.div`
  ${mixins.avoidPageBreak}
  scroll-margin-top: ${sizes.HEADER_HEIGHT + 20}px;

  &:not(:first-child) {
    margin-top: 40px;
  }
`

export const SectionHeader = styled(Toolbar)`
  margin-bottom: 14px;
`

export const ShowArchivedCheckbox = styled(Checkbox)`
  font-size: 14px;
  ${mixins.hidePrint}
`

export const TwentyCol = styled(Col)`
  ${media.nmobile`
    float: left;
    width: 20%;
  `}
`

export const OsanoContributingFactor = styled.div`
  font-size: 11px;
  font-family: ${fonts.SECONDARY};
  color: black;
  line-height: 1.4;
  padding: 10px 0;
`

export const OsanoPrivacyImpact = styled.div`
  font-size: 11px;
  font-family: ${fonts.SECONDARY};
  line-height: 1.4;
  color: black;
  text-align: left;
  white-space: pre-wrap;
  padding: 0 10px;
`
