import styled from 'styled-components'
import Select from 'components-v2/atoms/Select'
import { Col } from 'components-v2/organisms/Layout'
import { colors, mixins, sizes } from 'styles'

export const Wrapper = styled.div`
  color: ${colors.BODY_TEXT};
`

export const Filters = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;

  > * {
    margin-left: 10px;
  }
`

export const FilterByLabel = styled.span`
  ${mixins.smallHeader}
`

export const FilterSeparator = styled.span`
  display: inline-block;
  width: 1px;
  height: 38px;
  background: ${colors.GREY};
`

export const StyledSelect = styled(Select)`
  font-weight: 500;
  border-color: ${colors.DARK_GREY};
  color: ${colors.DARK_GREY};
  background: transparent;

  &:hover {
    border-color: ${colors.DARK_GREY};
    color: ${colors.DARK_GREY};
  }
`

export const StyledCol = styled(Col)`
  > * {
    margin-bottom: ${sizes.GRID_HGUTTER}px;
  }
`

export const ColTitle = styled.h6`
  font-size: 14px;
  letter-spacing: 0;
  &&& {
    flex: none;
    margin-bottom: 11px;
  }
`
