import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import IconButton from 'components-v2/atoms/IconButton'

const Wrapper = styled.div`
  position: relative;
  border: 1px solid transparent;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 15px;

  ${({ $dismissable }) =>
    $dismissable &&
    `
    padding-right: 35px;
  `}

  ${({ color }) =>
    color === 'danger' &&
    `
    background-color: #f2dede;
    border-color: #ebccd1;
    color: #a94442;
  `}

  ${({ color }) =>
    color === 'warning' &&
    `
    background-color: #fcf8e3;
    border-color: #faebcc;
    color: #8a6d3b;
  `}

  ${({ color }) =>
    color === 'success' &&
    `
    background-color: #dff0d8;
    border-color: #d6e9c6;
    color: #3c763d;
  `}

  ${({ color }) =>
    color === 'info' &&
    `
    background-color: #d9edf7;
    border-color: #bce8f1;
    color: #31708f;
  `}
`

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 15px;
  top: 18px;

  & {
    color: inherit;
    opacity: 0.2;
  }

  &:hover,
  &:active,
  &:focus {
    color: #000;
    opacity: 0.5;
  }
`

const Alert = React.forwardRef(({ children, onDismiss, ...rest }, ref) => (
  <Wrapper role="alert" {...rest} $dismissable={!!onDismiss} ref={ref}>
    {onDismiss && <StyledIconButton icon="fa fa-times" onClick={onDismiss} />}
    {children}
  </Wrapper>
))

Alert.propTypes = {
  color: PropTypes.oneOf(['success', 'info', 'danger', 'warning']),
  children: PropTypes.node,
  onDismiss: PropTypes.func,
}

Alert.defaultProps = {
  color: 'info',
}

export default Alert
