import styled from 'styled-components'
import Button from 'components-v2/atoms/Button'
import Icon from 'components-v2/atoms/Icon'
import Card from 'components-v2/organisms/Card'
import { colors, mixins } from 'styles'

export const StyledIcon = styled(Icon)`
  font-size: 16px;
  color: ${colors.GREY};
  margin-left: 6px;
`

export const Wrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
`

export const Top = styled.div`
  display: flex;
  align-items: center;
  height: 16px;
`

export const Category = styled.div`
  ${mixins.smallHeader}
  letter-spacing: 0;
  color: ${colors.PRIMARY};
  margin-right: auto;
`

export const Name = styled.div`
  font-size: 21px;
  font-weight: bold;
  color: #404144;
  margin-top: 4px;
  margin-bottom: 7px;
`

export const UpdatedAt = styled.div`
  font-size: 13px;
  line-height: 19px;
  opacity: 0.75;
  margin-bottom: 15px;
`

export const Description = styled.div`
  line-height: 19px;
  margin-bottom: 24px;
`

export const StyledButton = styled(Button)`
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: auto;
`
