import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import * as yup from 'yup'
import FormField from 'components-v2/molecules/FormField'
import { LabelTypes } from 'constants/index'
import { useScopePermissionsFetcher } from 'apis'

const VendorFilterFormFields = ({ projectVendors, users }) => {
  const formik = useFormikContext()
  const { data: permittedVendors } = useScopePermissionsFetcher(
    {
      params: {
        user_ids: users.filter((e) => e.type === 'user').map((e) => e.value),
        groups: users.filter((e) => e.type === 'group').map((e) => e.value),
        class: 'vendors',
      },
    },
    { enabled: projectVendors?.length > 0 && users?.length > 0 },
  )
  const vendorsOptions = useMemo(() => {
    if (!projectVendors || !permittedVendors) {
      return []
    }
    return projectVendors
      .filter((pv) => {
        if (permittedVendors[0] === 'all') {
          return true
        }
        return permittedVendors.some((v) => v.id === pv.vendor.id)
      })
      .map((pv) => ({
        value: pv.id,
        label: pv.vendor.name,
      }))
  }, [projectVendors, permittedVendors])
  return (
    <>
      <FormField
        type={FormField.types.TOGGLE}
        name="vendorsFilter"
        label={`Specify ${LabelTypes.VENDORS}`}
        horizontal
        preserveHelpTextSpace={!formik.values.vendorsFilter}
        disabled={!permittedVendors}
      />
      {formik.values.vendorsFilter && (
        <FormField
          name="vendors"
          type={FormField.types.MULTI_CHECKBOX}
          options={vendorsOptions}
          horizontal
        />
      )}
    </>
  )
}

VendorFilterFormFields.propTypes = {
  projectVendors: PropTypes.array,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['user', 'group']),
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default VendorFilterFormFields

export const validationSchema = yup.array().when('vendorsFilter', {
  is: true,
  then: yup
    .array()
    .min(1, `Please select at least 1 ${LabelTypes.VENDOR.toLowerCase()}`),
})
