import React from 'react'
import PropTypes from 'prop-types'
import keys from 'lodash/keys'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'
import keyBy from 'lodash/keyBy'
import A from 'components-v2/atoms/A'
import Well from 'components-v2/atoms/Well'
import DList from 'components-v2/molecules/DList'
import Tooltip from 'components-v2/molecules/Tooltip'
import FormField from 'components-v2/molecules/FormField'
import FormDialog from 'components-v2/organisms/FormDialog'

import {
  NameLabel,
  SubLabel,
  IntroText,
  IntroDivider,
  NoticeText,
  QuestionCircleIcon,
  CollectionHeadRow,
  NameHead,
  ToggleHead,
  CollectionType,
  CollectionRow,
  CollectionName,
  ToggleSlot,
  ToggleField,
} from './styles'

const toggleDisplayRules = {
  clone_null: {
    value: false,
    disabled: true,
    tip: `This collection must be imported, as you don't currently have a suitable version already.`,
  },
  transfer_null: {
    value: true,
    disabled: false,
    tip: `It looks like you already have an existing, identical copy of this collection we can use.`,
  },
  transfer_compatible: {
    value: true,
    disabled: false,
    tip: `You already have a copy of this collection, and though you've made some changes it apppears to be compatible.`,
  },
  clone_transferable: {
    value: false,
    disabled: false,
    tip: `It looks like you have a copy of a previous version of this collection. Importing an updated copy is recommended, but you can use your previous verison if you'd prefer.`,
  },
  clone_yikes: {
    value: false,
    disabled: false,
    tip: (
      <>
        It looks like you have a copy of a previous version of this collection
        that you&apos;ve since made changes to. Importing an updated copy is{' '}
        <i>highly</i> recommended and won&apos;t affect any of your existing
        templates, but if you like you can try to use your existing version
        instead.
      </>
    ),
  },
}

const getToggleDisplayRule = (c) =>
  toggleDisplayRules[`${c.result}_${c.variant || 'null'}`]

const DuplicateAssessmentTemplateConfirmModal = ({
  assessmentTemplate,
  analysis,
  ...rest
}) => {
  const [showAll, setShowAll] = React.useState(false)
  const needReview = groupBy(analysis, 'needs_review')
  const sourceGroups = showAll
    ? groupBy(analysis, 'source_type')
    : groupBy(needReview.true, 'source_type')

  const toggleShowAll = React.useCallback(() => {
    setShowAll((current) => !current)
  }, [])

  const formConfig = React.useMemo(() => {
    const initialValues = {
      automation_source_directives: mapValues(
        keyBy(analysis, (c) => c.source.id),
        (c) => getToggleDisplayRule(c)?.value,
      ),
    }
    return { initialValues }
  }, [analysis])

  return (
    <FormDialog formConfig={formConfig} {...rest}>
      <Well>
        <NameLabel>{assessmentTemplate.name}</NameLabel>
        <SubLabel>{assessmentTemplate.summary}</SubLabel>
        <SubLabel>{assessmentTemplate.global_template_description}</SubLabel>
      </Well>
      <IntroText>
        This template&apos;s automations depend on one or more collections you
        may already have, but either modifications have been made to your copies
        or we have updated versions available. You can choose below to use your
        your existing collections or import fresh copies with the assessment
        template you&apos;ve selected. Rest assured, your choices here will have
        no effect on any existing templates or automations you may have.
      </IntroText>
      {keys(needReview).length === 2 && (
        <NoticeText>
          {showAll ? (
            <>
              All collections for this template are shown. Show only those{' '}
              <A onClick={toggleShowAll}>needing attention</A>.
            </>
          ) : (
            <>
              Only collections needing review are shown.{' '}
              <A onClick={toggleShowAll}>Show all</A>.
            </>
          )}
        </NoticeText>
      )}
      <IntroDivider />
      {map(sourceGroups, (group, type) => (
        <DList key={type}>
          <dt>
            <CollectionType>
              {type === 'IssueStubCollection'
                ? 'Issue Template Collections'
                : 'AutoReport Section Collections'}
            </CollectionType>
          </dt>
          <dd>
            <CollectionHeadRow>
              <NameHead>Name</NameHead>
              <ToggleHead>Use Existing?</ToggleHead>
            </CollectionHeadRow>
            {map(group, (c) => {
              const { disabled, tip } = getToggleDisplayRule(c) || {}
              return (
                <CollectionRow>
                  <CollectionName>{c.source.name}</CollectionName>
                  <ToggleSlot>
                    <ToggleField
                      name={`automation_source_directives[${c.source.id}]`}
                      type={FormField.types.TOGGLE}
                      disabled={disabled}
                      preserveHelpTextSpace={false}
                    />
                    {tip && (
                      <Tooltip parent={<QuestionCircleIcon />}>{tip}</Tooltip>
                    )}
                  </ToggleSlot>
                </CollectionRow>
              )
            })}
          </dd>
        </DList>
      ))}
    </FormDialog>
  )
}

DuplicateAssessmentTemplateConfirmModal.propTypes = {
  assessmentTemplate: PropTypes.object.isRequired,
  analysis: PropTypes.array.isRequired,
}

export default DuplicateAssessmentTemplateConfirmModal
