import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import Well from 'components-v2/atoms/Well'
import FormField, { BaseField } from 'components-v2/molecules/FormField'
import AutoSizeTextarea from 'components-v2/atoms/AutoSizeTextarea'
import FormDialog from 'components-v2/organisms/FormDialog'

export const ATTRIBUTES_LIST = ['name', 'body']

const validationSchema = yup.object().shape({
  name: yup.string().label('Name').required(),
  body: yup.string().label('Section text').required(),
})

const AutoSizeTextAreaField = (fieldProps) => {
  const { name, value, onChange, ...rest } = fieldProps
  return (
    <BaseField {...rest}>
      <AutoSizeTextarea
        name={name}
        value={value || ''}
        onChange={(e) => onChange(e.currentTarget.value)}
      />
    </BaseField>
  )
}

export const AutoReportFormModal = ({ source, onSave, ...rest }) => {
  const formConfig = React.useMemo(
    () => ({
      validationSchema,
      initialValues: {
        name: source.name,
        body: source.body,
      },
    }),
    [source],
  )

  return (
    <FormDialog
      {...rest}
      title="New AutoReport Section"
      onSubmit={onSave}
      submitButtonLabel="Apply"
      formConfig={formConfig}
    >
      <Well>
        <FormField name="name" label="Name" />
        <FormField
          name="body"
          label="Section Text"
          component={AutoSizeTextAreaField}
        />
      </Well>
    </FormDialog>
  )
}

AutoReportFormModal.propTypes = {
  source: PropTypes.object,
  onSave: PropTypes.func.isRequired,
}

export default AutoReportFormModal
