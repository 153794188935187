import { useGlobalLoader } from 'hooks'
import NotificationManager from 'lib/notifications'
import { useUpdateClientAssessmentTemplate } from 'apis'
import { useCallback } from 'react'

export default function useEditRatingLabelSet(assessmentTemplateId) {
  const { mutateAsync: updateClientAssessmentTemplate } =
    useUpdateClientAssessmentTemplate()
  const [showLoader, hideLoader] = useGlobalLoader()

  return useCallback(
    (newValue) => {
      const loaderId = showLoader()
      return updateClientAssessmentTemplate({
        id: assessmentTemplateId,
        data: {
          assessment_template: { rating_label_set: newValue },
        },
      })
        .catch((err) => {
          NotificationManager.error(err.response.data?.errors)
        })
        .then(() => hideLoader(loaderId))
    },
    [
      updateClientAssessmentTemplate,
      assessmentTemplateId,
      showLoader,
      hideLoader,
    ],
  )
}
