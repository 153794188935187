import React from 'react'
import PropTypes from 'prop-types'
import find from 'lodash/find'
import get from 'lodash/get'
import indexOf from 'lodash/indexOf'
import map from 'lodash/map'
import split from 'lodash/split'
import truncate from 'lodash/truncate'
import { issuePriorityFormatterFactory } from 'lib/issue'
import Checkbox from 'components-v2/atoms/Checkbox'

import {
  CheckWrapper,
  QuestionIcon,
  InfoTooltip,
  TooltipBlock,
  StubDetailRow,
  StubDetailLabel,
  StubDetailBody,
  FileList,
  FileItem,
  FileIcon,
} from './styles'

const formatText = (text) => {
  const parsed = split(text, /(?:\r\n|\r|\n)+/)
  return map(parsed, (p, idx) => <p key={`lgTxt-${idx}`}>{p}</p>)
}

const issuePriorityFormatter = issuePriorityFormatterFactory()
const LONG_TEXT_LENGTH = 750

const AutomationItem = ({
  autoItem,
  selectedList,
  issueCategories,
  onSelected,
}) => {
  const itemLabel = (item) =>
    `${item.element.key} - ${
      item.source_type === 'issue_stub' ? item.source.label : item.source.name
    }`

  const src = autoItem.source
  return (
    <CheckWrapper key={autoItem.assessment_question_automation.id}>
      <Checkbox
        label={itemLabel(autoItem)}
        checked={
          selectedList.indexOf(autoItem.assessment_question_automation.id) !==
          -1
        }
        onChange={() => {
          onSelected(autoItem.assessment_question_automation.id)
        }}
      />

      <InfoTooltip
        parent={<QuestionIcon />}
        dark={false}
        arrow="left"
        position="bottom"
        tooltipDelay={300}
      >
        <TooltipBlock>
          <StubDetailRow>
            <StubDetailLabel>Summary</StubDetailLabel>
            <StubDetailBody>{src.summary}</StubDetailBody>
          </StubDetailRow>
          <StubDetailRow>
            <StubDetailLabel>Description</StubDetailLabel>
            <StubDetailBody>
              {src.description && (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {src.description.length < 100
                    ? src.description
                    : formatText(
                        truncate(src.description, {
                          length: LONG_TEXT_LENGTH,
                          separator: ' ',
                        }),
                      )}
                </>
              )}
            </StubDetailBody>
          </StubDetailRow>
          <StubDetailRow>
            <StubDetailLabel>Attachments</StubDetailLabel>
            <StubDetailBody>
              <FileList>
                {map(src.attachments, (file) => (
                  <FileItem
                    href={file.url}
                    title={file.full_name}
                    key={file.id}
                    deleted={indexOf(src.fileDeletes, file.id) > -1}
                  >
                    <FileIcon />
                    {file.name}
                    <br />
                  </FileItem>
                ))}
              </FileList>
            </StubDetailBody>
          </StubDetailRow>
          <StubDetailRow>
            <StubDetailLabel>Priority</StubDetailLabel>
            <StubDetailBody>
              {issuePriorityFormatter({ getValue: () => src.priority })}
            </StubDetailBody>
          </StubDetailRow>
          <StubDetailRow>
            <StubDetailLabel>Days to Respond</StubDetailLabel>
            {/* <StubDetailBody>{currentAutomation.days_to_respond || src.default_delay}</StubDetailBody> */}
            <StubDetailBody>{src.default_delay}</StubDetailBody>
          </StubDetailRow>
          <StubDetailRow>
            <StubDetailLabel>Category</StubDetailLabel>
            <StubDetailBody>
              {src.issue_category_id &&
                (get(
                  find(issueCategories, ['id', src.issue_category_id]),
                  'name',
                ) ||
                  src.issue_category_id)}
            </StubDetailBody>
          </StubDetailRow>
        </TooltipBlock>
      </InfoTooltip>
    </CheckWrapper>
  )
}

AutomationItem.propTypes = {
  autoItem: PropTypes.object.isRequired,
  selectedList: PropTypes.array,
  issueCategories: PropTypes.array,
  onSelected: PropTypes.func.isRequired,
}

export default AutomationItem
