import axios from 'axios'

export const getCSRFToken = () => {
  const metaCsrf = document.querySelector('meta[name=csrf-token]')
  return metaCsrf !== null ? metaCsrf.content : ''
}

export default () => {
  axios.defaults.headers.common['X-CSRF-Token'] = getCSRFToken()
}
