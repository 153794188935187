import React, { useCallback } from 'react'
import isArray from 'lodash/isArray'
import keyBy from 'lodash/keyBy'
import keys from 'lodash/keys'
import mapValues from 'lodash/mapValues'
import pickBy from 'lodash/pickBy'
import isEmpty from 'lodash/isEmpty'
import has from 'lodash/has'
import partition from 'lodash/partition'
import differenceBy from 'lodash/differenceBy'
import NotificationManager from 'lib/notifications'
import { useGlobalLoader, useConfirm } from 'hooks'
import {
  useUpdateClientVendor,
  useUpdateClientVendorOrganizationDetail,
  useUpdateClientVendorCustomFieldData,
  useUpdateClientVendorTags,
} from 'apis'
import { unflatten } from 'utils/object'
import { format } from 'date-fns'
import { parseFormValue } from './utils'
import {
  VendorFieldNameTypes,
  VendorOwnFieldNames,
  VendorFieldGroupIdTypes,
} from './constants'

export default function useSaveFieldGroupCard(vendor, onCloseEditingMode) {
  const { mutateAsync: updateClientVendor } = useUpdateClientVendor()
  const { mutateAsync: updateClientVendorOrganizationDetail } =
    useUpdateClientVendorOrganizationDetail()
  const { mutateAsync: updateClientVendorCustomFieldData } =
    useUpdateClientVendorCustomFieldData()
  const { mutateAsync: updateClientVendorTags } = useUpdateClientVendorTags()
  const [showLoader, hideLoader] = useGlobalLoader()
  const openConfirm = useConfirm()

  const saveContributorsCard = useCallback(
    async (formData, { setErrors }) => {
      const { users } = formData
      // Let's add index (which represents position in form) per user, this will be used later when populate server-side error.
      const usersWithIndex = users.map((user, index) => ({ ...user, index }))
      const [newUsers, existingUsers] = partition(usersWithIndex, 'isNew')
      const [originalExistingUsers] = partition(vendor.users, 'id')
      const existingUsersToRemove = differenceBy(
        originalExistingUsers,
        existingUsers,
        'id',
      )

      if (existingUsersToRemove.length) {
        const confirmed = await openConfirm(
          {
            title:
              existingUsersToRemove.length === 1
                ? `Remove this user from ${vendor.name}?`
                : `Remove these users from ${vendor.name}?`,
            body: existingUsersToRemove.map((user) => (
              <p key={user.id}>
                <b>Name:</b> {user.name}
                <br />
                <b>Email:</b> {user.email}
              </p>
            )),
            confirmText: `Yes, I'm sure`,
            cancelText: 'Cancel',
          },
          true,
        )
        if (!confirmed) {
          return
        }
      }

      const usersData = [
        ...existingUsersToRemove.map(({ id }) => ({
          id,
          _destroy: true,
        })),
        ...existingUsers.map((user) => ({
          id: user.id,
          email: user.email,
          name: user.name,
          phone: user.phone,
          title: user.title,
          index: user.index,
        })),
        ...newUsers.map((user) => ({
          email: user.email,
          name: user.name,
          phone: user.phone,
          title: user.title,
          index: user.index,
        })),
      ]
      const usersDataWithoutIndex = usersData.map(({ index, ...user }) => user)

      if (usersDataWithoutIndex.length === 0) {
        onCloseEditingMode(VendorFieldGroupIdTypes.CONTRIBUTORS)
        return
      }

      const loaderId = showLoader()
      try {
        await updateClientVendor({
          id: vendor.id,
          data: {
            vendor: {
              users: usersDataWithoutIndex,
            },
          },
        })
        onCloseEditingMode(VendorFieldGroupIdTypes.CONTRIBUTORS)
      } catch (error) {
        if (error.response.status === 422) {
          const serverErrors = unflatten(error.response.data.errors)
          // There can be index differences between form fields and server error response, let's fix it
          const usersFormError = Array(usersWithIndex.length).fill(undefined)
          serverErrors.users?.forEach((userError, index) => {
            const formIndex = usersData[index]?.index
            if (formIndex !== undefined) {
              usersFormError[formIndex] = userError
            }
          })
          setErrors({
            ...serverErrors,
            users: usersFormError,
          })
        } else {
          NotificationManager.error()
        }
      }
      hideLoader(loaderId)
    },
    [vendor, onCloseEditingMode, openConfirm, showLoader, hideLoader],
  )

  return useCallback(
    (cardId, formData, bag) => {
      // Use custom logic in case of Contributors card
      if (cardId === VendorFieldGroupIdTypes.CONTRIBUTORS) {
        return saveContributorsCard(formData, bag)
      }

      const parsedFormData = mapValues(formData, (value, name) =>
        parseFormValue(name, value),
      )
      const vendorData = pickBy(
        parsedFormData,
        (_, key) => VendorOwnFieldNames.indexOf(key) !== -1,
      )
      const customFieldDefs = keyBy(vendor.custom_field_definitions, 'key')
      const customFieldKeys = keys(customFieldDefs)

      const organizationDetailsData = pickBy(
        parsedFormData,
        (_, key) =>
          VendorOwnFieldNames.indexOf(key) === -1 &&
          customFieldKeys.indexOf(key) === -1,
      )
      const customFieldData = mapValues(
        pickBy(parsedFormData, (_, key) => customFieldKeys.indexOf(key) !== -1),
        (val, key) => {
          if (customFieldDefs[key].type_opts.array && !isArray(val)) {
            return val ? [val] : []
          }
          const customFieldDef = customFieldDefs[key]
          if (customFieldDef.data_type === 'date') {
            return val ? format(val, 'yyyy-MM-dd') : val
          }
          return val
        },
      )

      // Update VendorFieldNameTypes.VENDOR_RISK_TIER_ANSWERS as well
      if (has(parsedFormData, VendorFieldNameTypes.RISK_TIER)) {
        vendorData[VendorFieldNameTypes.RISK_TIER] =
          parsedFormData[VendorFieldNameTypes.RISK_TIER].riskTier
        organizationDetailsData[VendorFieldNameTypes.RISK_TIER_CALCULATOR_ID] =
          parsedFormData[VendorFieldNameTypes.RISK_TIER].calculatorId
        organizationDetailsData[VendorFieldNameTypes.VENDOR_RISK_TIER_ANSWERS] =
          parsedFormData[VendorFieldNameTypes.RISK_TIER].riskTierAnswers
      }

      const loaderId = showLoader()
      const promises = [
        !isEmpty(vendorData)
          ? updateClientVendor({ id: vendor.id, data: vendorData })
          : null,
        !isEmpty(organizationDetailsData)
          ? updateClientVendorOrganizationDetail({
              vendorId: vendor.id,
              data: organizationDetailsData,
            })
          : null,
        !isEmpty(customFieldData)
          ? updateClientVendorCustomFieldData({
              vendorId: vendor.id,
              data: customFieldData,
            })
          : null,
        has(parsedFormData, VendorFieldNameTypes.VENDOR_TAG_LIST)
          ? updateClientVendorTags({
              vendorId: vendor.id,
              data: {
                vendor_tag: {
                  list: parsedFormData[VendorFieldNameTypes.VENDOR_TAG_LIST],
                },
              },
            })
          : null,
      ]
      return Promise.all(promises)
        .then(() => {
          onCloseEditingMode(cardId)
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            bag.setErrors(unflatten(error.response.data.errors))
          } else {
            NotificationManager.error()
          }
        })
        .finally(() => hideLoader(loaderId))
    },
    [vendor, onCloseEditingMode, showLoader, hideLoader],
  )
}
