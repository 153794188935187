import { useEffect, useRef, useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import isFunction from 'lodash/isFunction'
import {
  useVendorAssessmentSectionFetcher,
  useVendorAssessmentElementsInfiniteFetcher,
} from 'apis'
import { useAllPagesOfInfiniteQuery, useGlobalLoader, useCurrent } from 'hooks'
import NotificationManager from 'lib/notifications'
import config from 'config'
import { scrollToTable } from './utils'

export default function useAssessmentElementsFetcher(
  { assessmentId, sectionId },
  initialData,
) {
  const [showLoader, hideLoader] = useGlobalLoader()
  const previousSectionIdRef = useRef()
  const sectionIdRef = useCurrent(sectionId)
  const options = {
    keepPreviousData: true,
    refetchOnMount: false,
    onSuccess: () => {
      if (previousSectionIdRef.current !== sectionIdRef.current) {
        setTimeout(() => {
          scrollToTable(assessmentId)
        }, 500)
      }
      previousSectionIdRef.current = sectionIdRef.current
    },
    onError: () => {
      NotificationManager.error()
      previousSectionIdRef.current = sectionIdRef.current
    },
  }
  const sectionData = useVendorAssessmentSectionFetcher(
    {
      assessmentId,
      sectionId,
    },
    {
      ...options,
      initialData: {
        elements: initialData,
      },
      enabled: !!sectionId,
    },
  )
  const elementsData = useAllPagesOfInfiniteQuery(
    useVendorAssessmentElementsInfiniteFetcher(
      {
        assessmentId,
        params: {
          per_page: config.collectionQueryTotalLimit.assessmentElements,
        },
      },
      {
        ...options,
        initialData: {
          pages: [
            {
              results: initialData,
            },
          ],
        },
        enabled: !sectionId,
      },
    ),
  )
  const res = sectionId
    ? {
        ...sectionData,
        data: sectionData.data?.elements,
      }
    : elementsData
  const { isFetching } = res

  useEffect(() => {
    if (!isFetching) {
      return undefined
    }
    const loaderId = showLoader()
    return () => {
      hideLoader(loaderId)
    }
  }, [isFetching])

  return res
}

function useSetElements(assessmentId) {
  const queryClient = useQueryClient()
  return useCallback(
    (updater) => {
      queryClient.setQueriesData(
        {
          predicate: (query) =>
            query.queryKey[0] ===
              useVendorAssessmentSectionFetcher.queryKey()[0] &&
            query.queryKey[1].assessmentId === assessmentId,
        },
        (current) =>
          current
            ? {
                ...current,
                elements: updater(current.elements),
              }
            : undefined,
      )
      queryClient.setQueriesData(
        {
          predicate: (query) =>
            query.queryKey[0] ===
              useVendorAssessmentElementsInfiniteFetcher.queryKey()[0] &&
            query.queryKey[1].assessmentId === assessmentId,
        },
        (current) =>
          current
            ? {
                ...current,
                pages: current.pages.map((page) => ({
                  ...page,
                  results: updater(page.results),
                })),
              }
            : undefined,
      )
    },
    [queryClient],
  )
}

export function useSetElement(assessmentId) {
  const setElements = useSetElements(assessmentId)
  return useCallback(
    (elementId, updater) => {
      setElements((elements) =>
        elements?.map((element) => {
          if (element.id === elementId) {
            return isFunction(updater) ? updater(element) : updater
          }
          return element
        }),
      )
    },
    [setElements],
  )
}

export function useSetSkippedElements(assessmentId) {
  const setElements = useSetElements(assessmentId)
  return useCallback(
    (skippedElementIds, sectionId, byOther = false) => {
      setElements((elements) =>
        elements?.map((element) => {
          // If the given skippedElementIds is for section scope and the element is not in that section, let's skip
          if (sectionId && element.parent_id !== sectionId) {
            return element
          }

          // Otherwise, let's update skipped attribute
          const skipped = skippedElementIds.indexOf(element.id) !== -1
          if (skipped !== element.skipped) {
            return { ...element, skipped, tempVisible: skipped && byOther }
          }
          if (!byOther) {
            // Reset tempVisible when updating skipped state by my action
            return { ...element, tempVisible: false }
          }
          return element
        }),
      )
    },
    [setElements],
  )
}
