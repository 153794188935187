import axios from 'axios'
import isNumber from 'lodash/isNumber'
import uniqueId from 'lodash/uniqueId'

// Higher order function that cancel the previous requests and take the latest request before completion.
// f: wrapped function
// options:
//    groupIdentity: function to get a request group ID. Based on this ID, it will filter and cancel requests.
//    optionsParamIndex: index of the axios "options" parameter (passed to axios request funcs) in the parameter list.
//        default value is the last index of parameters expected by the wrapped function
//        This fallback logic is possibly not working if the function accepts parameters with a default value
export const takeLatest = (f, options) => {
  const defaultGroupId = uniqueId('groupId_')
  const groupIdentity = options.groupIdentity || (() => defaultGroupId)
  const optionsParamIndex = isNumber(options.optionsParamIndex)
    ? options.optionsParamIndex
    : f.length - 1
  const cancelTokenSources = {}

  return (...args) => {
    const groupID = groupIdentity(args)

    // Cancel the previous request
    if (typeof cancelTokenSources[groupID] !== typeof undefined) {
      cancelTokenSources[groupID].cancel(
        'Operation canceled due to new request.',
      )
    }

    // Save the new request for cancellation
    cancelTokenSources[groupID] = axios.CancelToken.source()

    // Pass cancelToken to the axios "options" param
    const newArgs = [...args]
    newArgs[optionsParamIndex] = {
      ...args[optionsParamIndex],
      cancelToken: cancelTokenSources[groupID].token,
    }
    return f(...newArgs)
  }
}
