import styled from 'styled-components'
import Select from 'components-v2/atoms/Select'
import { SearchInput } from 'components-v2/molecules/Table/Toolbar'
import { colors, media } from 'styles'

export const Container = styled.div`
  display: flex;
  margin-top: 30px;
`

export const StatusFilters = styled.div`
  flex: none;
  width: 160px;
  margin-right: 30px;

  ${media.tablet`
    display: none;
  `}
`

export const StatusFilter = styled.span`
  opacity: 0.6;
  color: ${colors.DARK_GREY};
  line-height: 1.7;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  ${(props) =>
    props.active &&
    `
    font-weight: 800;
    opacity: 1;
  `}
`

export const TableWrapper = styled.div`
  flex: 1;
  min-width: 0;
`

export const StatusSelectMobile = styled(Select)`
  margin-bottom: 20px;

  ${media.ntablet`
    display: none;
  `}
`

export const StyledSearchInput = styled(SearchInput)`
  margin-bottom: 20px;

  ${media.tablet`
    width: 100%;
  `}
`
