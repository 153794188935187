import React from 'react'
import Button from 'components-v2/atoms/Button'

const onClick = () => window.print()
const PrintButton = React.forwardRef((props, ref) => (
  <Button {...props} ref={ref} onClick={onClick} />
))

PrintButton.defaultProps = {
  children: [<i key="icon" className="fa fa-print" />, 'Print'],
}

export default PrintButton
