import styled from 'styled-components'
import BaseButton from 'components-v2/atoms/Button'

export const FormWrapper = styled.div`
  width: 370px;
  max-width: 100%;
`

export const ButtonsWrapper = styled.div`
  margin-top: 10px;
`

export const Button = styled(BaseButton)`
  min-width: 38px;
  &:not(:first-child) {
    margin-left: 6px;
  }
`
