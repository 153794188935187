// FilterDropdownTray component composing molecules/Popover and its subcomponents
import React from 'react'
import { findDOMNode } from 'react-dom'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import sum from 'lodash/sum'
import Popover from 'components-v2/molecules/Popover'
import PanelContent from './PanelContent'
import { getFilterSelectedCount } from './utils'
import { filterTypes } from './consts'
import { PopoverPanel, Inner, FilterIcon } from './styles'

const populateInitialVisibleFilters = (
  configList,
  initialVisibleFilters,
  value,
) => {
  if (!initialVisibleFilters) {
    return null
  }
  const filtersWithNonEmptyValue = configList
    ?.filter(({ name }) => getFilterSelectedCount(configList, name, value))
    ?.map(({ name }) => name)
  return [...new Set([...initialVisibleFilters, ...filtersWithNonEmptyValue])]
}

const FilterDropdownTray = ({
  configList,
  initialVisibleFilters,
  filtersEditable,
  value,
  onChange,
  boundingTarget,
  ...rest
}) => {
  const dropdownButtonRef = React.useRef()
  const [panelPosition, setPanelPosition] = React.useState({
    left: 0,
    right: 0,
    maxWidth: 0,
  })
  const [visibleFilters, setVisibleFilters] = React.useState(
    populateInitialVisibleFilters(configList, initialVisibleFilters, value),
  )

  React.useEffect(() => {
    const handler = () => {
      if (!dropdownButtonRef.current) {
        return
      }
      // Store the position of the toggle button relative to main content area in a state
      // eslint-disable-next-line react/no-find-dom-node
      const eleRect = findDOMNode(
        dropdownButtonRef.current,
      ).getBoundingClientRect()
      const targetRect = (
        boundingTarget ?? document.getElementById('main-content')
      ).getBoundingClientRect()
      const left = eleRect.left - targetRect.left
      const right = targetRect.right - eleRect.right
      const maxWidth = targetRect.left + targetRect.width
      setPanelPosition((current) => {
        if (
          current.left !== left ||
          current.right !== right ||
          current.maxWidth !== maxWidth
        ) {
          return { left, right, maxWidth }
        }
        return current
      })
    }

    handler()

    window.addEventListener('resize', handler)
    return () => {
      window.removeEventListener('resize', handler)
    }
  }, [boundingTarget])

  const allFilterSelectedCount = React.useMemo(
    () =>
      sum(
        map(configList, ({ name }) =>
          getFilterSelectedCount(configList, name, value),
        ),
      ),
    [value, configList],
  )

  return (
    <Popover offset={2} {...rest}>
      {({ close }) => (
        <Inner ref={dropdownButtonRef}>
          <Popover.Button>
            <FilterIcon />
            <span>
              Filters
              {allFilterSelectedCount ? `(${allFilterSelectedCount})` : ''}
            </span>
          </Popover.Button>
          <PopoverPanel position={panelPosition}>
            <PanelContent
              maxWidth={panelPosition.maxWidth}
              configList={configList}
              visibleFilters={visibleFilters}
              filtersEditable={filtersEditable}
              value={value}
              onChange={onChange}
              onClose={close}
              onChangeVisibleFilters={setVisibleFilters}
            />
          </PopoverPanel>
        </Inner>
      )}
    </Popover>
  )
}

FilterDropdownTray.propTypes = {
  configList: PropTypes.array.isRequired,
  initialVisibleFilters: PropTypes.array,
  filtersEditable: PropTypes.bool,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  boundingTarget: PropTypes.object,
}

FilterDropdownTray.defaultProps = {
  initialVisibleFilters: undefined,
  filtersEditable: false,
  value: undefined,
  boundingTarget: undefined,
}

FilterDropdownTray.filterTypes = filterTypes

export const FilterTypes = filterTypes

export default FilterDropdownTray
