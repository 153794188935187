import React from 'react'
import PropTypes from 'prop-types'
import SelectField from 'components-v2/molecules/FormField/SelectField'

const options = [
  { value: 3, label: '3 - High risk' },
  { value: 2, label: '2 - Medium risk' },
  { value: 1, label: '1 - Low risk' },
  { value: 0, label: '0 - No risk' },
]

const ChoiceSelectField = ({ value, onChange, ...rest }) => (
  <SelectField
    {...rest}
    options={options}
    value={value || value === 0 ? value.toString() : ''}
    onChange={(val) => onChange(Number.parseInt(val, 10))}
  />
)

ChoiceSelectField.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default ChoiceSelectField
