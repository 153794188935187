import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import FormField from 'components-v2/molecules/FormField'
import FormDialog from 'components-v2/organisms/FormDialog'
import { OrText } from './styles'

const formConfig = {
  initialValues: {
    client_customer: undefined,
    industry: undefined,
    date_received: undefined,
    due_at: undefined,
    attachment: undefined,
    existing_document_url: '',
    notes: '',
  },
  validationSchema: yup.object().shape(
    {
      client_customer: yup.string().required('This is required'),
      date_received: yup.date().nullable().required('This is required'),
      due_at: yup.date().nullable().required('This is required'),
      attachment: yup.mixed().when('existing_document_url', {
        is: (value) => !value || !value.trim(),
        then: yup
          .mixed()
          .nullable()
          .required('Please attach a file or set an existing document url'),
      }),
      existing_document_url: yup.string().when('attachment', {
        is: (value) => !value,
        then: yup
          .string()
          .trim()
          .required('Please attach a file or set an existing document url'),
      }),
    },
    [['attachment', 'existing_document_url']],
  ),
}

const clientCustomerControlProps = {
  placeholder: 'Select or Create New...',
  noOptionsMessage: () => 'No clients found',
  formatCreateLabel: (i) => `Create new client "${i}"`,
  isClearable: true,
}

const CreateAssessmentFormModal = ({
  clientCustomers,
  industries,
  ...rest
}) => {
  const clientCustomerOptions = useMemo(
    () =>
      clientCustomers?.map((e) => ({
        value: e.id,
        label: e.name,
      })),
    [clientCustomers],
  )
  const industryOptions = useMemo(
    () =>
      industries?.map((e) => ({
        value: e.id,
        label: e.name,
      })),
    [industries],
  )
  const handleCustomerSelect = useCallback(
    (customer, setFieldValue) => {
      const existingCustomer = clientCustomers?.find(
        ({ id }) => id === customer,
      )
      if (existingCustomer) {
        setFieldValue('industry', existingCustomer.industry?.id, false)
      }
    },
    [clientCustomers],
  )
  return (
    <FormDialog
      {...rest}
      title="Upload Client Assessment"
      submitButtonLabel="Save"
      formConfig={formConfig}
    >
      {({ values, setFieldValue }) => (
        <>
          <FormField
            name="client_customer"
            label="Client Name"
            type={FormField.types.CREATABLE_SELECT}
            options={clientCustomerOptions}
            controlProps={clientCustomerControlProps}
            onChange={(value) => handleCustomerSelect(value, setFieldValue)}
            horizontal
          />
          <FormField
            name="industry"
            label="Industry"
            type={FormField.types.SEARCHABLE_SELECT}
            options={industryOptions}
            controlProps={{ isClearable: true }}
            disabled={
              values.client_customer &&
              clientCustomers?.some(({ id }) => id === values.client_customer)
            }
            horizontal
          />
          <FormField
            name="date_received"
            label="Date Received"
            type={FormField.types.DATE}
            controlProps={{
              todayButton: 'Today',
            }}
            horizontal
          />
          <FormField
            name="due_at"
            label="Due Date"
            type={FormField.types.DATE}
            controlProps={{
              todayButton: 'Today',
              showDurationButtons: true,
            }}
            horizontal
          />
          <FormField
            name="attachment"
            label="File"
            type={FormField.types.FILE_INPUT}
            controlProps={{
              accept: '.pdf,.doc,.docx,.xls,.xlsx,.csv,.xlsm,.zip',
            }}
            disabled={!values.attachment && !!values.existing_document_url}
            horizontal
          />
          <OrText>or</OrText>
          <FormField
            name="existing_document_url"
            label="URL"
            disabled={!!values.attachment}
            horizontal
          />
          <FormField
            name="notes"
            label="Notes"
            type={FormField.types.TEXTAREA}
            controlProps={{
              rows: 3,
            }}
            horizontal
          />
        </>
      )}
    </FormDialog>
  )
}

CreateAssessmentFormModal.propTypes = {
  clientCustomers: PropTypes.array,
  industries: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
}

export default CreateAssessmentFormModal
