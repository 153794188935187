import React from 'react'
import Chart from 'react-google-charts'
import { ChartWrapper, Loader } from './styles'

const GoogleChart = (props) => (
  <ChartWrapper height={props.height}>
    <Chart {...props} />
  </ChartWrapper>
)

GoogleChart.propTypes = Chart.propTypes

GoogleChart.defaultProps = {
  loader: <Loader>Loading Chart</Loader>,
}

export default GoogleChart
