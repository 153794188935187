import React from 'react'
import FormField from 'components-v2/molecules/FormField'
import { ExportFormatTypes } from 'constants/index'

const options = [
  { label: 'CSV', value: ExportFormatTypes.CSV },
  { label: 'XLSX (Excel)', value: ExportFormatTypes.XLSX },
]

const SelectFormatFormField = (props) => (
  <FormField
    name="format"
    label="Format"
    type={FormField.types.RADIO_GROUP}
    options={options}
    horizontal
    {...props}
  />
)

export default SelectFormatFormField
