import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import map from 'lodash/map'
import Button from 'components-v2/atoms/Button'
import IconButton from 'components-v2/atoms/IconButton'
import UserEnteredTextRenderer from 'components-v2/molecules/UserEnteredTextRenderer'
import DList from 'components-v2/molecules/DList'
import ToolbarGroup from 'components-v2/molecules/ToolbarGroup'
import Uploader from 'components-v2/molecules/Uploader'
import MenuDropdown from 'components-v2/molecules/MenuDropdown'
import PageHeader from 'components-v2/organisms/PageHeader'
import Assignee from 'components/client/shared/Assignee'
import CommentThread from 'components/shared/CommentThread'
import IssueIssuable from 'components/shared/IssueIssuable'
import Note from 'components/shared/Note'
import config from 'config'
import {
  getIssuePriorityLabel,
  getIssueResolutionLabel,
  getIssueStatusLabel,
  issueDueDateFormatterFactory,
} from 'lib/issue'
import { useAuthorizations } from 'hooks'
import { toLocalTimeString } from 'utils/date'
import { IssueResolutionOptions, LabelTypes } from 'constants/index'
import InfoCard from './InfoCard'
import { Content, Left, NarrowDList, NoteMeta, Right } from './styles'

const dueAtFormatter = issueDueDateFormatterFactory()

const View = ({
  issue,
  extras,
  canClose,
  canSend,
  canArchive,
  canReopen,
  canComment,
  setIssue,
  onEditIssue,
  onDeleteIssue,
  onCloseIssue,
  onSendIssue,
  onArchiveIssue,
  onReopenIssue,
  onAssign,
  onCommentCreated,
  onEditNote,
}) => {
  const [
    userCanUpdate,
    userCanArchive,
    userCanClose,
    userCanDelete,
    userCanManage,
    canViewAttachments,
  ] = useAuthorizations('update', 'archive', 'close', 'delete', 'manage', [
    'show',
    'ClientVendorAttachment',
  ])
  const renderActions = (placement) => (
    <ToolbarGroup>
      {canClose && userCanClose && (
        <MenuDropdown
          title="Close Issue"
          placement={placement}
          buttonProps={{ color: canSend ? 'default' : 'primary' }}
          onSelect={onCloseIssue}
        >
          {map(IssueResolutionOptions, (option) => (
            <MenuDropdown.Item key={option.value} eventKey={option.value}>
              {option.label}
            </MenuDropdown.Item>
          ))}
        </MenuDropdown>
      )}
      {canSend && userCanManage && (
        <Button color="primary" onClick={onSendIssue}>
          Send Issue
        </Button>
      )}
      {canArchive && userCanArchive && (
        <Button onClick={onArchiveIssue}>Archive Issue</Button>
      )}
      {canReopen && userCanManage && (
        <Button color="primary" onClick={onReopenIssue}>
          Re-Open Issue
        </Button>
      )}
    </ToolbarGroup>
  )
  const note = issue.notes?.[0]
  return (
    <div>
      <PageHeader title="Issue">{renderActions('bottom-end')}</PageHeader>
      <Content>
        <Left>
          <InfoCard
            title="Issue Details"
            actions={
              <>
                {userCanUpdate && (
                  <IconButton icon="fa fa-pencil" onClick={onEditIssue} />
                )}
                {userCanDelete && (
                  <IconButton icon="fa fa-trash" onClick={onDeleteIssue} />
                )}
              </>
            }
          >
            <DList horizontal>
              <dt>Summary</dt>
              <dd>{issue.summary}</dd>
              <dt>Details</dt>
              <dd>
                <UserEnteredTextRenderer
                  text={issue.description}
                  formatted={issue.formatted}
                />
              </dd>
              {issue.issue_category && (
                <>
                  <dt>Category</dt>
                  <dd>{issue.issue_category.name}</dd>
                </>
              )}
              <dt>Created</dt>
              <dd>
                {toLocalTimeString(issue.created_at, config.tableDateFormat)}
              </dd>
              <dt>Viewed</dt>
              <dd>
                {toLocalTimeString(
                  issue.vendor_viewed_at,
                  config.tableDateFormat,
                )}
              </dd>
              <dt>Due</dt>
              <dd>
                {dueAtFormatter({
                  getValue: () => issue.due_at,
                  row: { original: issue },
                })}
              </dd>
              <dt>Priority</dt>
              <dd>{getIssuePriorityLabel(issue.priority)}</dd>
              <dt>Status</dt>
              <dd>{getIssueStatusLabel(issue.status)}</dd>
              {issue.closed_at && (
                <>
                  <dt>Close date</dt>
                  <dd>
                    {toLocalTimeString(issue.closed_at, config.tableDateFormat)}
                  </dd>
                </>
              )}
              <dt>Resolution</dt>
              <dd>{getIssueResolutionLabel(issue.resolution)}</dd>
              {issue.attachments.length > 0 && canViewAttachments && (
                <>
                  <dt>Attachments</dt>
                  <dd>
                    <Uploader
                      defaultFiles={issue.attachments}
                      displayLabel={false}
                      url=""
                      model=""
                      disabled
                    />
                  </dd>
                </>
              )}
            </DList>
          </InfoCard>
          <InfoCard
            title="Internal Notes"
            actions={
              userCanUpdate ? (
                <IconButton icon="fa fa-pencil" onClick={onEditNote} />
              ) : undefined
            }
          >
            {note && (
              <>
                <Note note={note} />
                <NoteMeta>
                  <div>
                    <b>Last Updated:</b> {toLocalTimeString(note.updated_at)}
                  </div>
                  <div>
                    <b>Updated By:</b> {note.user?.name}
                  </div>
                </NoteMeta>
              </>
            )}
          </InfoCard>
          <InfoCard title="Comments">
            <CommentThread
              commentableType="Issue"
              commentableId={issue.id}
              vendorId={issue.vendor.id}
              canComment={canComment && userCanUpdate}
              onCommentCreated={onCommentCreated}
            />
          </InfoCard>
        </Left>
        <Right>
          <InfoCard title="Assignee">
            <NarrowDList>
              <dt>Assigned to</dt>
              <dd>
                <Assignee
                  assigneeId={issue.client_assignee?.id}
                  assigneeName={issue.client_assignee?.name}
                  note={issue.client_assignee_note}
                  editable={userCanManage}
                  onSave={onAssign}
                />
              </dd>
              {issue.client_assignee_note && (
                <>
                  <dt>Note</dt>
                  <dd>{issue.client_assignee_note}</dd>
                </>
              )}
            </NarrowDList>
          </InfoCard>
          <InfoCard title="Issue Source">
            <NarrowDList>
              <dt>{LabelTypes.VENDOR}</dt>
              <dd>
                <a href={get(issue, 'vendor.path')}>
                  {get(issue, 'vendor.name')}
                </a>
              </dd>
              <IssueIssuable
                issue={issue}
                extras={extras}
                setIssue={setIssue}
              />
            </NarrowDList>
          </InfoCard>
        </Right>
      </Content>
      {renderActions('bottom-start')}
    </div>
  )
}

View.propTypes = {
  issue: PropTypes.object.isRequired,
  extras: PropTypes.object,
  canClose: PropTypes.bool,
  canArchive: PropTypes.bool,
  canReopen: PropTypes.bool,
  canSend: PropTypes.bool,
  canComment: PropTypes.bool,
  setIssue: PropTypes.func.isRequired,
  onEditIssue: PropTypes.func.isRequired,
  onDeleteIssue: PropTypes.func.isRequired,
  onCloseIssue: PropTypes.func.isRequired,
  onSendIssue: PropTypes.func.isRequired,
  onArchiveIssue: PropTypes.func.isRequired,
  onReopenIssue: PropTypes.func.isRequired,
  onAssign: PropTypes.func.isRequired,
  onCommentCreated: PropTypes.func.isRequired,
  onEditNote: PropTypes.func.isRequired,
}

export default View
