import styled from 'styled-components'
import A from 'components-v2/atoms/A'
import { mixins } from 'styles'

export const Wrapper = styled.div`
  margin-top: 15px;
`

export const Details = styled.div`
  margin-top: 15px;
`

export const ToggleButton = styled(A)`
  ${mixins.smallHeader};
  letter-spacing: 0;
`
