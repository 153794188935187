import styled from 'styled-components'
import { transparentize } from 'polished'
import Card from 'components-v2/organisms/Card'
import { colors, media } from 'styles'

export const Wrap = styled(Card).attrs({ padding: true, bordered: true })`
  border-color: ${colors.PRIMARY};
  background-color: ${transparentize(0.9, colors.PRIMARY)};
  margin-bottom: 25px;
`

export const Inner = styled.div`
  ${media.desktop`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`

export const Heading = styled.h4`
  color: ${colors.PRIMARY};
  margin-top: 0;
`

export const Description = styled.div`
  color: ${colors.HEADING};
`

export const Content = styled.div`
  ${media.desktop`
    margin-right: 20px;
  `}

  ${media.tablet`
    margin-bottom: 20px;
  `}
`

export const ButtonsWrapper = styled.div`
  > *:not(:last-child) {
    margin-right: 8px;
  }

  > button {
    margin-bottom: 8px;
    margin-top: 8px;
  }
`

export const Logo = styled.img`
  max-width: 100%;
  height: auto;
  max-height: 70px;
  margin: 0 15px 0 0;
  display: inline;
`
export const Company = styled.div`
  margin-top: 10px;
`
export const CompanyLabel = styled.span`
  font-weight: bold;
  margin-right: 10px;
`
