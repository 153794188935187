import { useCallback } from 'react'
import { useGlobalLoader } from 'hooks'
import NotificationManager from 'lib/notifications'

export default function useDefaultAsyncActionHandler() {
  const [showLoader, hideLoader] = useGlobalLoader()

  return useCallback(
    (promise, successMessage, errorMessage) => {
      const loaderId = showLoader()
      return promise
        .then((response) => {
          hideLoader(loaderId)
          if (successMessage) {
            NotificationManager.success(successMessage)
          }
          return response
        })
        .catch((err) => {
          hideLoader(loaderId)
          NotificationManager.error(errorMessage)
          throw err
        })
    },
    [showLoader, hideLoader],
  )
}
