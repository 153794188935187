import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components-v2/atoms/Button'
import DList from 'components-v2/molecules/DList'
import SimpleDialog from 'components-v2/molecules/SimpleDialog'
import { toLocalTimeString } from 'utils/date'
import { detectTranslationLangauge } from 'lib/language'

const MoreInfoModal = ({
  assessment,
  translated,
  onClose,
  onTranslateClick,
  ...rest
}) => {
  const buttons = React.useMemo(
    () => [{ children: 'Close', onClick: onClose }],
    [onClose],
  )
  const translateButtonVisible =
    assessment.available_response_translations?.includes(
      detectTranslationLangauge(),
    )
  return (
    <SimpleDialog
      {...rest}
      title="More Info"
      buttons={buttons}
      onClose={onClose}
    >
      <DList horizontal>
        <dt>Due by</dt>
        <dd>{toLocalTimeString(assessment.due_at)}</dd>
        <dt>Recent action</dt>
        <dd>
          <div>Sent: {toLocalTimeString(assessment.sent_at)}</div>
          {assessment.in_process_at && (
            <div>Started: {toLocalTimeString(assessment.in_process_at)}</div>
          )}
          {assessment?.submit_by?.name && !assessment.client_submitted && (
            <>
              <div>
                Submitted by: {assessment?.submit_by?.name} from{' '}
                {assessment.submit_by_ip}
              </div>
              {assessment.submit_at && (
                <div>
                  Submitted time: {toLocalTimeString(assessment.submit_at)}
                </div>
              )}
            </>
          )}
          {assessment.client_submitted && (
            <>
              <div>
                Submitted by: <span className="strong">Client Import</span> from{' '}
                {assessment.submit_by_ip}
              </div>
              <div>
                Client Submit Reason:{' '}
                {assessment.client_submit_reason?.titleize()}
              </div>
              {assessment.submit_at && (
                <div>
                  Submitted time: {toLocalTimeString(assessment.submit_at)}
                </div>
              )}
            </>
          )}
          {assessment.expired_at && (
            <div>Expired: {toLocalTimeString(assessment.expired_at)}</div>
          )}
          {assessment?.reviewed_by?.name && (
            <>
              <div>
                Reviewed by: {assessment?.reviewed_by?.name}{' '}
                {assessment.reviewed_by_ip &&
                  `from ${assessment.reviewed_by_ip}`}
              </div>
              {assessment.reviewed_at && (
                <div>
                  Reviewed time: {toLocalTimeString(assessment.reviewed_at)}
                </div>
              )}
            </>
          )}
        </dd>
        {assessment.submission_language_name && (
          <>
            <dt>Response Language</dt>
            <dd>
              {assessment.submission_language_name}&nbsp;&nbsp;&nbsp;&nbsp;
              {translateButtonVisible && (
                <Button size="xsmall" onClick={onTranslateClick}>
                  <i className="fa fa-exchange" />
                  {translated ? 'Untranslate' : 'Translate'}
                </Button>
              )}
            </dd>
          </>
        )}
      </DList>
    </SimpleDialog>
  )
}

MoreInfoModal.propTypes = {
  assessment: PropTypes.object.isRequired,
  translateButtonVisible: PropTypes.bool,
  translated: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onTranslateClick: PropTypes.func.isRequired,
}

export default MoreInfoModal
