import { colors } from 'styles'
import { EmailOutline } from '@styled-icons/evaicons-outline/EmailOutline'
import { MinusCircle } from '@styled-icons/boxicons-regular/MinusCircle'
import { CheckmarkCircle } from '@styled-icons/ionicons-solid/CheckmarkCircle'
import { ExclamationCircle } from '@styled-icons/bootstrap/ExclamationCircle'

const AssessmentStatusTypes = {
  INVITED: 'invited',
  IN_PROCESS: 'in_process',
  SUBMITTED: 'submitted',
  REVIEWED: 'reviewed',
  EXPIRED: 'expired',
  CANCELED: 'canceled',
}

export const AssessmentStatusOptions = [
  {
    value: AssessmentStatusTypes.INVITED,
    color: colors.MED_DARK_GREY,
    icon: EmailOutline,
  },
  {
    value: AssessmentStatusTypes.IN_PROCESS,
    color: colors.YELLOW,
    icon: MinusCircle,
  },
  {
    value: AssessmentStatusTypes.SUBMITTED,
    color: colors.BLUE,
    icon: CheckmarkCircle,
  },
  {
    value: AssessmentStatusTypes.EXPIRED,
    color: colors.RED,
    icon: ExclamationCircle,
  },
  {
    value: AssessmentStatusTypes.REVIEWED,
    color: colors.GREEN,
    icon: CheckmarkCircle,
  },
].map((o) => ({ ...o, label: o.value.titleize() }))

export default AssessmentStatusTypes
