import React from 'react'
import PropTypes from 'prop-types'
import { isValidElementType, isElement } from 'react-is'
import styled from 'styled-components'
import IconButton from 'components-v2/atoms/IconButton'
import { colors } from 'styles'

export const QuestionIconButton = styled(IconButton).attrs({
  icon: 'fa fa-question-circle',
})`
  font-size: 14px;
  color: ${colors.MED_GREY};
`

// Let's support DOM Selector, DOM Element, React Element, and React Element Type for parent prop
export default (WrappedComponent) => {
  const WrapperComponent = ({ parent, parentWrapperStyle, ...rest }) => {
    const [parentRef, setParentRef] = React.useState(null)

    const isReactElementType =
      typeof parent !== 'string' && isValidElementType(parent)
    const isReactElement = isElement(parent)

    // If parent prop is a React Element Type or React Element
    if (isReactElementType || isReactElement) {
      const ParentComponent = parent
      return [
        <span key="parent" ref={setParentRef} style={parentWrapperStyle}>
          {isReactElementType ? (
            <ParentComponent active={rest.active} />
          ) : (
            parent
          )}
        </span>,
        parentRef ? (
          <WrappedComponent key="tooltip" {...rest} parent={parentRef} />
        ) : null,
      ]
    }
    // Otherwise, parent prop is DOM Selector or DOM Element
    const parentDOMEl =
      typeof parent === 'string' ? document.querySelector(parent) : parent
    return parentDOMEl ? (
      <WrappedComponent {...rest} parent={parentDOMEl} />
    ) : null
  }

  WrapperComponent.propTypes = {
    parent: PropTypes.oneOfType([
      PropTypes.string, // DOM Selector
      PropTypes.object, // DOM Element
      PropTypes.element, // React Element
      PropTypes.elementType, // React Element Type
    ]),
    parentWrapperStyle: PropTypes.object,
  }

  WrapperComponent.defaultProps = {
    // eslint-disable-next-line react/default-props-match-prop-types
    parent: <QuestionIconButton />,
  }

  return WrapperComponent
}
