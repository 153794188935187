/* eslint-disable no-extend-native */
// avoid conflict with other native methods
if (!String.prototype.titleize) {
  String.prototype.titleize = function titleize() {
    // clear underscores
    const value = this.valueOf().replace(/_/g, ' ')
    let words = value.split(' ')
    words = words.map(
      (word) => word.charAt(0).toUpperCase() + word.substring(1),
    )
    return words.join(' ')
  }
}
if (!String.prototype.beginsWithVowel) {
  String.prototype.beginsWithVowel = function beginsWithVowel() {
    return (
      ['a', 'e', 'i', 'o', 'u'].indexOf(this.valueOf()[0].toLowerCase()) !== -1
    )
  }
}
/* eslint-enable no-extend-native */

export const pluralize = (count, noun, suffix = 's') =>
  `${count || count === 0 ? count : ''} ${noun}${count !== 1 ? suffix : ''}`
