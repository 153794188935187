import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTableColumnsPref, useQueryParams } from 'hooks'
import { submitForm } from 'utils/submit_form'
import { getPageUrl } from 'utils/url'
import { localTimeZoneOffsetInHours } from 'utils/date'
import {
  AssessmentTemplateStatusTypes,
  ExportFormatTypes,
} from 'constants/index'
import {
  useClientAssessmentTemplatesFetcher,
  useUsersFetcher,
  useClientTagsFetcher,
  useClientAssessmentsFetcher,
} from 'apis'
import useShowErrorModal from './useShowErrorModal'
import View from './View'

// Utils
import {
  exportToCSV,
  validateAnalysisSelection,
  getFilterConfigList,
} from './utils'

// Constants
import { ActionMenuItemTypes, COL_NAMES, queryParamsConfig } from './constants'

const Container = ({
  orgId,
  userPrefs,
  disabledIntegrations,
  orgRole,
  context,
}) => {
  const [columnToggles, setColumnToggles] = useTableColumnsPref(
    userPrefs,
    'table_columns.assessments',
    COL_NAMES,
    orgId,
  )
  const [selected, setSelected] = useState({})
  const [queryParams, setQueryParams] = useQueryParams(queryParamsConfig)
  const { page, sizePerPage, sortField, sortOrder, searchText, archiveValues } =
    queryParams
  const { data: users = [] } = useUsersFetcher()
  const { data: vendorTags = [] } = useClientTagsFetcher()
  const { data: templates = [] } = useClientAssessmentTemplatesFetcher({
    params: {
      page: 1,
      per_page: 500,
      status: [
        AssessmentTemplateStatusTypes.READY,
        AssessmentTemplateStatusTypes.IN_PROCESS,
      ].join(','),
    },
  })
  const {
    data: assessments = [],
    totalSize,
    isFetching,
  } = useClientAssessmentsFetcher({
    params: {
      page,
      per_page: sizePerPage,
      search_query: searchText,
      ordered_by: sortField,
      direction: sortOrder,
      status: queryParams.statuses.join(','),
      with_vendor_tags: queryParams.vendorTags.join(','),
      any: queryParams.vendorTagsAnyMatch,
      client_assignee: queryParams.assignees.join(','),
      unassigned: queryParams.unassigned,
      risk_tier_list: queryParams.riskTiers,
      with_templates: queryParams.assessmentTemplates,
      date_type: queryParams.dateType,
      start_date: queryParams.startDate,
      end_date: queryParams.endDate,
      exclude_archived_vendors: !archiveValues?.includes('vendors'),
      exclude_archived: !archiveValues?.includes('projects'),
      tz: localTimeZoneOffsetInHours,
    },
  })
  const showErrorModal = useShowErrorModal()

  const filter = {
    statuses: { selected: queryParams.statuses },
    vendorTags: {
      selected: queryParams.vendorTags,
      anyMatch: queryParams.vendorTagsAnyMatch,
    },
    assignees: {
      selected: queryParams.assignees,
      other: queryParams.unassigned,
    },
    riskTiers: { selected: queryParams.riskTiers },
    assessmentTemplates: { selected: queryParams.assessmentTemplates },
    dateRange: {
      startDate: queryParams.startDate,
      endDate: queryParams.endDate,
      state: queryParams.dateType,
    },
  }
  const filterConfigList = useMemo(
    () => getFilterConfigList(users, templates, vendorTags),
    [users, templates, vendorTags],
  )

  const handleSearchChange = useCallback((newValue) => {
    setQueryParams({
      page: 1,
      searchText: newValue,
    })
    setSelected({})
  }, [])

  const handleArchiveChange = useCallback((newValue) => {
    setQueryParams({
      page: 1,
      archiveValues: newValue.selected,
    })
    setSelected({})
  }, [])

  const handleFilterChange = useCallback((newValue) => {
    setQueryParams({
      page: 1,
      statuses: newValue.statuses?.selected,
      vendorTags: newValue.vendorTags?.selected,
      vendorTagsAnyMatch: newValue.vendorTags?.anyMatch,
      assignees: newValue.assignees?.selected,
      unassigned: newValue.assignees?.other,
      riskTiers: newValue.riskTiers?.selected,
      assessmentTemplates: newValue.assessmentTemplates?.selected,
      startDate: newValue.dateRange?.startDate,
      endDate: newValue.dateRange?.endDate,
      dateType: newValue.dateRange?.state,
    })
    setSelected({})
  }, [])

  const handleMenuItemSelect = useCallback(
    (key) => {
      const selectedIds = Object.keys(selected)
      switch (key) {
        case ActionMenuItemTypes.DOWNLOAD_SCORES:
          window.location.assign(
            getPageUrl(
              'clientAssessmentsGradeExport',
              undefined,
              { ids: selectedIds.join() },
              ExportFormatTypes.XLSX,
            ),
          )
          break
        case ActionMenuItemTypes.RESULTS_REPORT:
          submitForm(getPageUrl('clientAssessmentsResultsReport'), {
            method: 'POST',
            body: { ids: selectedIds.join() },
          })
          break
        case ActionMenuItemTypes.VENDOR_ANALYSIS_REPORT: {
          const { error, message } = validateAnalysisSelection(
            assessments,
            selectedIds,
          )
          if (error) {
            showErrorModal([message])
          } else {
            submitForm(getPageUrl('clientAssessmentsVendorAnalysisReport'), {
              method: 'POST',
              body: { ids: selectedIds.join() },
            })
          }
          break
        }
        case ActionMenuItemTypes.VENDOR_ANSWER_COMPARISON_REPORT: {
          const { error, message } = validateAnalysisSelection(
            assessments,
            selectedIds,
          )
          if (error) {
            showErrorModal([message])
          } else {
            submitForm(
              getPageUrl('clientAssessmentsVendorAnswerComparisonReport'),
              {
                method: 'POST',
                body: { ids: selectedIds.join() },
              },
            )
          }
          break
        }
        case ActionMenuItemTypes.EXPORT_CURRENT_VIEW: {
          exportToCSV(queryParams, columnToggles)
          break
        }
        case ActionMenuItemTypes.STATUS_REPORT:
          window.location.assign(getPageUrl('clientAssessmentsStatusReport'))
          break
        case ActionMenuItemTypes.CYCLE_TIME_REPORT:
          window.location.assign(getPageUrl('clientAssessmentCycleTimeReport'))
          break
        default:
      }
    },
    [assessments, selected, queryParams, columnToggles, showErrorModal],
  )

  return (
    <View
      assessments={assessments}
      totalSize={totalSize}
      loading={isFetching}
      page={page}
      sizePerPage={sizePerPage}
      sortField={sortField}
      sortOrder={sortOrder}
      selected={selected}
      searchText={searchText}
      filter={filter}
      filterConfigList={filterConfigList}
      archiveValues={archiveValues}
      orgRole={orgRole}
      context={context}
      disabledIntegrations={disabledIntegrations}
      columnToggles={columnToggles}
      onSearchChange={handleSearchChange}
      onMenuItemSelect={handleMenuItemSelect}
      onArchiveChange={handleArchiveChange}
      onFilterChange={handleFilterChange}
      onColumnTogglesChange={setColumnToggles}
      onRowSelectionChange={setSelected}
      onPaginationChange={setQueryParams}
      onSortingChange={setQueryParams}
    />
  )
}

Container.propTypes = {
  orgId: PropTypes.string.isRequired,
  userPrefs: PropTypes.object,
  orgRole: PropTypes.string,
  context: PropTypes.string,
  disabledIntegrations: PropTypes.array.isRequired,
}

export default Container
