import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Input = styled.input`
  cursor: pointer;
`

function IndeterminateCheckbox({ indeterminate, ...rest }) {
  const ref = React.useRef(null)

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate])

  return <Input type="checkbox" ref={ref} {...rest} />
}

IndeterminateCheckbox.propTypes = {
  indeterminate: PropTypes.bool,
}

const rowSelectionColumn = {
  id: 'rowSelection',
  header: ({ table }) => (
    <IndeterminateCheckbox
      checked={table.getIsAllRowsSelected()}
      indeterminate={table.getIsSomeRowsSelected()}
      onChange={table.getToggleAllRowsSelectedHandler()}
    />
  ),
  cell: ({ row }) => (
    <IndeterminateCheckbox
      checked={row.getIsSelected()}
      indeterminate={row.getIsSomeSelected()}
      onChange={row.getToggleSelectedHandler()}
    />
  ),
  enableSorting: false,
  width: 40,
}

// Disabled by default
export default function withRowSelection(WrappedComponent) {
  const WrapperComponent = ({
    state = {},
    columns,
    rowSelection,
    enableRowSelection,
    onRowSelectionChange,
    ...rest
  }) => {
    const newState =
      enableRowSelection && rowSelection ? { ...state, rowSelection } : state
    const columnsEx = React.useMemo(() => {
      if (!enableRowSelection) {
        return columns
      }
      return [rowSelectionColumn, ...columns]
    }, [columns, enableRowSelection])
    const onRowSelectionChangeEx =
      enableRowSelection && onRowSelectionChange
        ? (updater) => onRowSelectionChange(updater(rowSelection))
        : undefined

    return (
      <WrappedComponent
        {...rest}
        columns={columnsEx}
        state={newState}
        enableRowSelection={enableRowSelection}
        onRowSelectionChange={onRowSelectionChangeEx}
      />
    )
  }

  WrapperComponent.propTypes = {
    state: PropTypes.object,
    columns: PropTypes.array.isRequired,
    rowSelection: PropTypes.object,
    enableRowSelection: PropTypes.bool,
    onRowSelectionChange: PropTypes.func,
  }

  WrapperComponent.defaultProps = {
    enableRowSelection: false,
  }

  return WrapperComponent
}
