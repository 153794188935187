import styled from 'styled-components'
import Icon from 'components-v2/atoms/Icon'
import Card from 'components-v2/organisms/Card'

export const MetricCard = styled(Card).attrs({ bordered: true, padding: true })`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;
`

export const Label = styled.h6`
  height: 28px;
  margin-top: 0;
  line-height: 14px;
`

export const Metric = styled.div`
  font-size: 50px;
  line-height: 37px;
  ${(p) => p.color && `color: ${p.color};`}
`

export const MetricIcon = styled(Icon)`
  color: ${({ color }) => color};
  margin-top: 5px;
  margin-bottom: -10px;
`
