import styled, { css } from 'styled-components'
import { colors, fonts } from 'styles'

export const Input = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`

export const Track = styled.div`
  width: 53px;
  height: 25px;
  border-radius: 3px;
  background-color: ${colors.GREY};
  transition: all 0.2s ease;
`

export const Thumb = styled.div`
  position: absolute;
  top: 4px;
  left: 4px;
  width: 17px;
  height: 17px;
  border-radius: 2px;
  background-color: ${colors.WHITE};
  transition: all 0.25s ease;
`

export const TrackOn = styled.div`
  position: absolute;
  color: ${colors.WHITE};
  font-family: ${fonts.SECONDARY};
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.25s ease;
`

export const TrackOff = styled.div`
  position: absolute;
  color: ${colors.WHITE};
  font-family: ${fonts.SECONDARY};
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  top: 50%;
  right: 6px;
  transform: translateY(-50%);
  opacity: 1;
  transition: opacity 0.25s ease;
`

export const Wrapper = styled.div`
  touch-action: pan-x;
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;

  ${(props) =>
    props.disabled &&
    `
    cursor: not-allowed;
    opacity: 0.5;
    transition: opacity 0.25s;
  `}

  ${(props) =>
    !props.disabled &&
    css`
      &:hover ${Track} {
        background-color: ${colors.MED_GREY};
      }

      &:active ${Thumb} {
        box-shadow: 0px 0px 5px 5px #0099e0;
      }
    `}

  ${(props) =>
    props.checked &&
    css`
      ${Track} {
        background-color: ${colors.PRIMARY};
      }

      ${Thumb} {
        left: auto;
        right: 4px;
      }

      ${TrackOn} {
        opacity: 1;
      }

      ${TrackOff} {
        opacity: 0;
      }
    `}

  ${(props) =>
    props.checked &&
    !props.disabled &&
    css`
      &:hover ${Track} {
        background-color: ${colors.PRIMARY_D05};
      }
    `}

  ${(props) =>
    props.focus &&
    css`
      ${Thumb} {
        &::before {
          left: 2px;
          right: 2px;
          top: 2px;
          bottom: 2px;
          position absolute;
          content: '';
          border: 2px solid ${colors.PRIMARY_D05};
        }
      }
    `}
`
