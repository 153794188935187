import styled from 'styled-components'
import Radio from 'components-v2/atoms/Radio'

export const Wrapper = styled.div``

export const MatchWrapper = styled.div`
  white-space: nowrap;
  margin-bottom: 7px;
`

export const StyledRadio = styled(Radio)`
  margin-left: 5px;
`

export const OtherWrapper = styled.div`
  margin-bottom: 4px;
  padding-bottom: 4px;
  border-bottom: 1px solid #ccc;
`
