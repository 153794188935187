import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const BaseListGroupItem = styled.div`
  background-color: #fff;
  border: 1px solid #ddd;
  display: block;
  margin-bottom: -1px;
  padding: 10px 15px;
  position: relative;

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-bottom: 0;
  }

  ${({ color }) =>
    color === 'success' &&
    `
    background-color: #dff0d8;
    color: #3c763d;
  `}

  ${({ color }) =>
    color === 'info' &&
    `
    background-color: #d9edf7;
    color: #31708f;
  `}

  ${({ color }) =>
    color === 'warning' &&
    `
    background-color: #fcf8e3;
    color: #8a6d3b;
  `}

  ${({ color }) =>
    color === 'danger' &&
    `
    background-color: #f2dede;
    color: #a94442;
  `}
`

export const ListGroupItem = ({ href, onClick, ...rest }) => {
  if (href) {
    return <BaseListGroupItem as="a" href={href} onClick={onClick} {...rest} />
  }
  if (onClick) {
    return <BaseListGroupItem as="button" onClick={onClick} {...rest} />
  }
  return <BaseListGroupItem {...rest} />
}

ListGroupItem.propTypes = {
  color: PropTypes.oneOf(['success', 'info', 'danger', 'warning']),
  href: PropTypes.string,
  onClick: PropTypes.func,
}

const ListGroup = styled.div`
  margin-bottom: 20px;

  a${BaseListGroupItem}, button${BaseListGroupItem} {
    color: #555;

    &:hover,
    &:focus {
      background-color: #f5f5f5;
      color: #555;
      text-decoration: none;
    }
  }
`

ListGroup.Item = ListGroupItem

export default ListGroup
