import styled from 'styled-components'
import Card from 'components-v2/organisms/Card'
import { colors, mixins } from 'styles'

export const Wrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
`

export const Heading = styled.div`
  ${mixins.smallHeader}
  letter-spacing: 0;
  color: ${colors.YELLOW};
`

export const Name = styled.div`
  font-size: 24px;
  line-height: 34px;
  color: #000;
  margin-top: 5px;
  margin-bottom: 8px;
`

export const RequestedAt = styled.div`
  font-size: 12px;
  line-height: 17px;
  color: ${colors.MED_GREY};
  margin-bottom: 22px;
`
