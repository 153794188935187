import React from 'react'

export default function useAutoToggleState(timeout) {
  const [value, setValue] = React.useState(false)
  const timeoutRef = React.useRef()

  const activate = React.useCallback(() => {
    setValue(true)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      setValue(false)
    }, timeout)
  }, [timeout])

  return [value, activate]
}
