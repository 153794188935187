import React from 'react'
import PropTypes from 'prop-types'
import Tabs from 'components-v2/molecules/Tabs'
import PageHeader from 'components-v2/organisms/PageHeader'
import { useQueryParams } from 'hooks'
import { withReactQuery } from 'hocs'
import { useCurrentUserFetcher } from 'apis'
import GeneralSettings from './GeneralSettings'
import Security from './Security'
import ApiApplications from './ApiApplications'
import Notifications from './Notifications'

const Container = ({
  currentUser: currentUserProp,
  showApiAppsTab,
  oauthApplications,
}) => {
  const { data: currentUser } = useCurrentUserFetcher(undefined, {
    initialData: currentUserProp,
  })
  const [{ tab }, setQueryParams] = useQueryParams()
  const handleTabSelect = React.useCallback(
    (e) => setQueryParams({ tab: e }, true),
    [],
  )
  return (
    <div>
      <PageHeader title="Edit User Settings" />
      <Tabs activeKey={tab} onChange={handleTabSelect}>
        <Tabs.Tab eventKey="general" title="General Settings">
          <GeneralSettings currentUser={currentUser} />
        </Tabs.Tab>
        <Tabs.Tab eventKey="security" title="Security">
          <Security currentUser={currentUser} />
        </Tabs.Tab>
        {showApiAppsTab && (
          <Tabs.Tab eventKey="applications" title="API Applications">
            <ApiApplications oauthApplications={oauthApplications} />
          </Tabs.Tab>
        )}
        <Tabs.Tab eventKey="notifications" title="Notifications">
          <Notifications currentUser={currentUser} />
        </Tabs.Tab>
      </Tabs>
    </div>
  )
}

Container.propTypes = {
  currentUser: PropTypes.object.isRequired,
  showApiAppsTab: PropTypes.bool,
  oauthApplications: PropTypes.array.isRequired,
}

export default withReactQuery()(Container)
