import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import isNaN from 'lodash/isNaN'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import { formatCurrency } from 'utils/currency'
import {
  ContractRow,
  ContractAmt,
  ContractPiece,
  ContractValue,
} from './styles'

const ContractValueDisplay = ({ value }) => {
  const amt = get(value, 'amount')
  const term = get(value, 'term')
  const len = get(value, 'length')

  const isValidAmt = (num) => {
    if (isNull(num) || isUndefined(num) || isNaN(num)) {
      return false
    }
    return true
  }

  return (
    <ContractRow>
      {isValidAmt(amt) && (
        <ContractAmt>
          <b>Amount:</b>
          <ContractValue>{formatCurrency(amt)}</ContractValue>
        </ContractAmt>
      )}
      {term && (
        <ContractPiece>
          <b>Term:</b>
          <ContractValue>{term}</ContractValue>
        </ContractPiece>
      )}
      {len && (
        <ContractPiece>
          <b>Length:</b>
          <ContractValue>{len}</ContractValue>
        </ContractPiece>
      )}
    </ContractRow>
  )
}

ContractValueDisplay.propTypes = {
  value: PropTypes.object,
}

export default ContractValueDisplay
