import React, { useMemo, useCallback } from 'react'
import { useQueryParams } from 'hooks'
import {
  useUsersFetcher,
  useClientTagsFetcher,
  useClientAssessmentsFetcher,
  useClientRiskDomainsFetcher,
} from 'apis'
import config from 'config'
import View from './View'
import { exportViewToCSV, getFilterConfigList } from './utils'
import { ActionMenuItemTypes, queryParamsConfig } from './constants'

export default function Container() {
  const [queryParams, setQueryParams] = useQueryParams(queryParamsConfig)
  const { page, sizePerPage, sortField, sortOrder, searchText, archiveValues } =
    queryParams
  const { data: users = [] } = useUsersFetcher()
  const { data: vendorTags = [] } = useClientTagsFetcher()
  const { data: riskDomains = [] } = useClientRiskDomainsFetcher()
  const {
    data: assessments = [],
    totalSize,
    isFetching,
  } = useClientAssessmentsFetcher({
    params: {
      page,
      per_page: sizePerPage,
      search_query: searchText,
      ordered_by: sortField,
      direction: sortOrder,
      status: queryParams.statuses.join(','),
      with_vendor_tags: queryParams.vendorTags.join(','),
      any: queryParams.vendorTagsAnyMatch,
      client_assignee: queryParams.assignees.join(','),
      exclude_archived_vendors: !archiveValues?.includes('vendors'),
      exclude_archived: !archiveValues?.includes('projects'),
      with_additional_attributes: true,
      with_risk_domain_data: true,
    },
  })

  const filter = {
    statuses: { selected: queryParams.statuses },
    vendorTags: {
      selected: queryParams.vendorTags,
      anyMatch: queryParams.vendorTagsAnyMatch,
    },
    assignees: { selected: queryParams.assignees },
  }
  const filterConfigList = useMemo(
    () => getFilterConfigList(vendorTags, users),
    [users, vendorTags],
  )

  const handleSearchChange = useCallback((newValue) => {
    setQueryParams({
      page: 1,
      searchText: newValue,
    })
  }, [])

  const handleArchiveChange = useCallback((newValue) => {
    setQueryParams({
      page: 1,
      archiveValues: newValue.selected,
    })
  }, [])

  const handleFilterChange = useCallback((newValue) => {
    setQueryParams({
      page: 1,
      statuses: newValue.statuses?.selected,
      vendorTags: newValue.vendorTags?.selected,
      vendorTagsAnyMatch: newValue.vendorTags?.anyMatch,
      assignees: newValue.assignees?.selected,
    })
  }, [])

  const handleMenuItemSelect = useCallback(
    (key) => {
      switch (key) {
        case ActionMenuItemTypes.EXPORT_CURRENT_VIEW_CSV: {
          exportViewToCSV(queryParams)
          break
        }
        case ActionMenuItemTypes.EXPORT_ALL_CSV:
          exportViewToCSV({
            sizePerPage: config.collectionQueryTotalLimit.assessmentRiskDomains,
            archiveValues: queryParams.archiveValues,
          })
          break
        default:
      }
    },
    [queryParams],
  )

  return (
    <View
      assessments={assessments}
      totalSize={totalSize}
      loading={isFetching}
      riskDomains={riskDomains}
      page={page}
      sizePerPage={sizePerPage}
      sortField={sortField}
      sortOrder={sortOrder}
      searchText={searchText}
      archiveValues={archiveValues}
      filter={filter}
      filterConfigList={filterConfigList}
      onPaginationChange={setQueryParams}
      onSortingChange={setQueryParams}
      onSearchChange={handleSearchChange}
      onMenuItemSelect={handleMenuItemSelect}
      onArchiveChange={handleArchiveChange}
      onFilterChange={handleFilterChange}
    />
  )
}
