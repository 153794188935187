import styled from 'styled-components'
import Icon from 'components-v2/atoms/Icon'
import IconButton from 'components-v2/atoms/IconButton'
import { FileCloud } from '@styled-icons/remix-line/FileCloud'
import { mixins } from 'styles'

export const Wrapper = styled.div`
  margin-top: 20px;
  padding-left: 24px;
  position: relative;
`

export const Inner = styled.div`
  font-size: 14px;
  padding-right: 30px;
  padding-bottom: 20px;

  ${Wrapper}:not(:last-child) & {
    border-bottom: 1px solid #e5e5e5;
  }
`

export const FileIcon = styled(Icon).attrs({ icon: 'fa fa-file-text-o' })`
  position: absolute;
  left: 0;
  top: 2px;
`

export const CloudFileIcon = styled(FileCloud)`
  width: 1em;
  height: 1em;
  position: absolute;
  left: 0;
  top: 6px;
`

export const DeleteIconButton = styled(IconButton).attrs({
  icon: 'fa fa-close',
})`
  position: absolute;
  right: 0;
  top: ${(props) => (props.withFile ? 2 : 6)}px;
`

export const Text = styled.div`
  font-weight: bold;
  ${mixins.truncate}
`
