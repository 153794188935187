import React from 'react'
import PropTypes from 'prop-types'
import GradeLabel from 'components/shared/GradeLabel'
import {
  getSectionsFromElements,
  getRiskRatingLabel,
  getRiskRatingColor,
} from 'lib/assessment'
import { RatingLabelSetTypes } from 'constants/index'
import get from 'lodash/get'
import { CriteriaTable, ScoreTable } from './styles'

const BySections = ({ assessment, ...rest }) => {
  const {
    elements,
    risk_rating: overallRiskRating,
    id: assessmentId,
    section_risk_ratings: sectionRiskRatings,
    grade,
  } = assessment
  const ratingLabelSet = get(
    assessment,
    'assessment_template.rating_label_set',
    RatingLabelSetTypes.STANDARD,
  )
  const isStandardRatingLabelSet =
    ratingLabelSet === RatingLabelSetTypes.STANDARD
  const sections = getSectionsFromElements(elements)
  return (
    <div {...rest}>
      <CriteriaTable>
        <div style={{ color: getRiskRatingColor(0) }}>
          0-20%
          <br />
          {isStandardRatingLabelSet && 'Very Poor'}
        </div>
        <div style={{ color: getRiskRatingColor(21) }}>
          21%-40%
          <br />
          {isStandardRatingLabelSet && 'Poor'}
        </div>
        <div style={{ color: getRiskRatingColor(41) }}>
          41%-60%
          <br />
          {isStandardRatingLabelSet && 'Acceptable'}
        </div>
        <div style={{ color: getRiskRatingColor(61) }}>
          61%-80%
          <br />
          {isStandardRatingLabelSet && 'Good'}
        </div>
        <div style={{ color: getRiskRatingColor(81) }}>
          81%-100%
          <br />
          {isStandardRatingLabelSet && 'Very Good'}
        </div>
      </CriteriaTable>
      <ScoreTable id={`risk-rating-modal-assessment-${assessmentId}`}>
        <tbody>
          <tr>
            <td className="strong">Section</td>
            <td className="strong"></td>
            <td className="text-right strong">
              {get(
                assessment,
                'assessment_template.rating_label',
                'Risk Rating',
              )}
            </td>
          </tr>
          {sections.map((section) => {
            const riskRating = sectionRiskRatings[section.id]
            return (
              <tr
                key={section.id}
                id={section.id}
                className="section-risk-rating-label"
              >
                <td>{section.key}</td>
                <td>{section.label}</td>
                <td
                  className="text-right"
                  style={{ color: getRiskRatingColor(riskRating) }}
                >
                  {getRiskRatingLabel(riskRating)}
                </td>
              </tr>
            )
          })}
          <tr
            key={assessmentId}
            id={assessmentId}
            className="section-risk-rating-label"
          >
            <td></td>
            <td className="strong">
              Overall{' '}
              {get(
                assessment,
                'assessment_template.rating_label',
                'Risk Rating',
              )}
            </td>
            <td
              className="text-right strong"
              style={{ color: getRiskRatingColor(overallRiskRating) }}
            >
              {getRiskRatingLabel(overallRiskRating)}
              <br />
              <div className="top7" style={{ color: 'black' }}>
                <GradeLabel grade={grade} iconLeft={false} />
              </div>
            </td>
          </tr>
        </tbody>
      </ScoreTable>
    </div>
  )
}

BySections.propTypes = {
  assessment: PropTypes.object.isRequired,
}

export default React.memo(BySections)
