import React from 'react'
import styled from 'styled-components'
import Icon from 'components-v2/atoms/Icon'
import PropTypes from 'prop-types'

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1em;
  height: 1em;
  background: currentColor;
  border-radius: 100%;

  > * {
    font-size: 0.45em;
    color: #fff;
  }
`

const CircleIcon = ({ icon, ...rest }) => (
  <Wrapper {...rest}>
    <Icon icon={icon} />
  </Wrapper>
)

CircleIcon.propTypes = {
  // icon: Icon.propTypes.icon, // breaks in Rails 6.1 ?
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
}

export default CircleIcon
