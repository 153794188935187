import React, { useState, useCallback, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Transition } from '@headlessui/react'
import FocusLock from 'react-focus-lock'
import { RemoveScroll } from 'react-remove-scroll'
import { Wrapper } from './styles'

const Modal = ({ open, onClose, children, ...rest }) => {
  const [active, setActive] = useState(false)
  const handleActivation = useCallback(() => setActive(true), [])
  const handleDeactivation = useCallback(() => setActive(false), [])

  useEffect(() => {
    const onKeyDown = (event) => {
      if (!event.defaultPrevented) {
        if (
          (event.code === 'Escape' ||
            event.key === 'Escape' ||
            event.keyCode === 27) &&
          onClose
        ) {
          onClose()
        }
      }
    }

    if (active) {
      document.addEventListener('keydown', onKeyDown)

      return () => {
        document.removeEventListener('keydown', onKeyDown)
      }
    }

    return undefined
  }, [active, onClose])

  return (
    <Transition show={open} as={Fragment}>
      <FocusLock
        returnFocus
        onActivation={handleActivation}
        onDeactivation={handleDeactivation}
      >
        <RemoveScroll>
          <Wrapper {...rest}>{children}</Wrapper>
        </RemoveScroll>
      </FocusLock>
    </Transition>
  )
}

Modal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
}

export default Modal
