import React from 'react'
import PropTypes from 'prop-types'
import { useLibraryShareFetcher } from 'apis'
import { compose, withAuthorize, withReactQuery } from 'hocs'
import View from './View'

const ShareContainer = ({ shareId }) => {
  const {
    data: share,
    isLoading,
    isError,
  } = useLibraryShareFetcher({ id: shareId })

  return <View share={share} loading={isLoading} hasError={isError} />
}

ShareContainer.propTypes = {
  shareId: PropTypes.string.isRequired,
}

export default compose(withAuthorize(), withReactQuery())(ShareContainer)
