import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import groupBy from 'lodash/groupBy'
import size from 'lodash/size'
import pick from 'lodash/pick'
import { ThemeProvider } from 'styled-components'
import Icon from 'components-v2/atoms/Icon'
import Tabs from 'components-v2/molecules/Tabs'
import { Row, Col } from 'components-v2/organisms/Layout'
import PageHeader from 'components-v2/organisms/PageHeader'
import Widget from './Widget'
import { CategoryTypes, Categories } from './consts'
import {
  Wrapper,
  theme,
  CategoryWrapper,
  CategoryHead,
  CategoryIconWrapper,
  CategoryName,
  CategorySize,
} from './styles'

const ALL = 'all'

const View = ({ reports, ...rest }) => {
  const [categoryFilter, setCategoryFilter] = React.useState('all')
  const reportsByCategory = React.useMemo(
    () => groupBy(reports, 'category'),
    [reports],
  )
  const filteredCategories = React.useMemo(
    () =>
      categoryFilter === ALL ? Categories : pick(Categories, [categoryFilter]),
    [categoryFilter],
  )

  return (
    <ThemeProvider theme={theme}>
      <Wrapper {...rest}>
        <PageHeader title="Reporting center" />
        <Tabs activeKey={categoryFilter} onChange={setCategoryFilter}>
          <Tabs.Tab eventKey={ALL} title="All Reports" />
          <Tabs.Tab eventKey={CategoryTypes.SUMMARY} title="Summaries" />
          <Tabs.Tab
            eventKey={CategoryTypes.ORGANIZATION_COMPARISONS}
            title="Organization Comparisons"
          />
          <Tabs.Tab
            eventKey={CategoryTypes.REMEDIATION_MANAGEMENT}
            title="Remediation Management"
          />
          <Tabs.Tab
            eventKey={CategoryTypes.PROGRESS_TRACKING}
            title="Progress Tracking"
          />
          <Tabs.Tab
            eventKey={CategoryTypes.DATA_EXPORTS}
            title="Data Exports"
          />
        </Tabs>
        {map(filteredCategories, (category, key) => (
          <CategoryWrapper key={key}>
            <CategoryHead>
              <CategoryIconWrapper>
                <Icon icon={category.icon} />
              </CategoryIconWrapper>
              <CategoryName>{category.label}</CategoryName>
              <CategorySize>({size(reportsByCategory[key])})</CategorySize>
            </CategoryHead>
            <Row>
              {map(reportsByCategory[key], (report, index) => (
                <Col md={3} key={index}>
                  <Widget
                    {...report}
                    categoryIcon={category.icon}
                    color={category.color}
                  />
                </Col>
              ))}
            </Row>
          </CategoryWrapper>
        ))}
      </Wrapper>
    </ThemeProvider>
  )
}

View.propTypes = {
  reports: PropTypes.array.isRequired,
}

export default View
