import { useCallback } from 'react'
import { useModal } from 'hooks'
import AssessmentFilesModal from './AssessmentFilesModal'

export default function useViewFiles(assessmentId) {
  const [openModal] = useModal(AssessmentFilesModal)
  return useCallback(() => {
    openModal({ assessmentId })
  }, [assessmentId, openModal])
}
