import React from 'react'
import PropTypes from 'prop-types'
import ResendInviteModal from '../../../ResendInviteModal'
import {
  Wrapper,
  Status,
  Bottom,
  CheckCircleIcon,
  ResendInviteWrapper,
} from './styles'

const Contributor = ({ contributor, vendorId, canManage }) => (
  <Wrapper>
    <div>
      <b>{contributor.name}</b>&nbsp;&nbsp;{contributor.title}
      <br />
      {contributor.email}&nbsp;&nbsp;{contributor.phone}
    </div>
    <Bottom>
      <Status>
        {contributor.status}&nbsp;
        {contributor.invited && <CheckCircleIcon />}
      </Status>
      {canManage && contributor.shareable_invitation_url && (
        <ResendInviteWrapper>
          <ResendInviteModal user={contributor} vendorId={vendorId} />
        </ResendInviteWrapper>
      )}
    </Bottom>
  </Wrapper>
)

Contributor.propTypes = {
  contributor: PropTypes.object.isRequired,
  vendorId: PropTypes.string.isRequired,
  canManage: PropTypes.bool,
}

export default Contributor
