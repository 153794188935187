import React from 'react'
import PropTypes from 'prop-types'
import DList from 'components-v2/molecules/DList'
import InfoCard from '../InfoCard'

const CompletedAssessmentsCard = ({ globalOrg, ...rest }) => (
  <InfoCard padding={false} title="Completed Assessments" {...rest}>
    <DList horizontal>
      {globalOrg.completed_assessments.map((assessment, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={i}>
          <dt>{assessment}</dt>
          <dd>
            <i className="fa fa-check-circle fa-lg text-very-good" />
          </dd>
        </React.Fragment>
      ))}
    </DList>
  </InfoCard>
)

CompletedAssessmentsCard.propTypes = {
  globalOrg: PropTypes.object.isRequired,
}

export default CompletedAssessmentsCard
