import styled from 'styled-components'
import { colors } from 'styles'

export const Wrapper = styled.div`
  max-width: 200px;
  padding: 3px 8px;
  color: ${colors.BODY_TEXT};
  background: ${(p) => p.bgColor};
  border: 1px solid ${(p) => p.borderColor};
  border-radius: 4px;
  font-size: 13px;
  line-height: 1.4;
  text-align: center;
  word-wrap: break-word;
`

export const Container = styled.div`
  position: absolute;
  z-index: 9999;
`

export const Spacer = styled.div`
  position: absolute;
`

export const BgArrow = styled.div`
  position: absolute;
`

export const FgArrow = styled.div`
  position: absolute;
  z-index: 1;
`
