import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { smallHeader } from 'styles/mixins'
import { colors } from 'styles'
import BaseTable from './BaseTable'
import { formatCell } from './styles'

const Table = styled(BaseTable)`
  font-size: 15px;

  table {
    ${formatCell(`
      border-top: 1px solid ${colors.LIGHT_GREY};
      padding: 5px;
      height: 50px;
      vertical-align: middle;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    `)}

    > thead > tr > th {
      ${smallHeader}
      letter-spacing: 0;
      line-height: 1.25em;
      color: ${colors.BODY_TEXT};
      height: auto;
      border-bottom: none;
    }

    > tbody > tr:first-child > td {
      border-top: none;
    }
  }

  ${(props) =>
    props.$hover &&
    css`
      table > tbody > tr:hover {
        background: #fafafa;
      }
    `}
`

const Mini = ({ hover, ...rest }) => <Table {...rest} $hover={hover} />

Mini.propTypes = {
  hover: PropTypes.bool,
}
Mini.defaultProps = {
  hover: false,
}

export default Mini
