import { useCallback } from 'react'
import { useGlobalLoader, useConfirm } from 'hooks'
import NotificationManager from 'lib/notifications'
import { useDeleteAssistAnswerSet } from 'apis'

export default function useDeleteAnswerSet() {
  const { mutateAsync: deleteAssistAnswerSet } = useDeleteAssistAnswerSet()
  const openConfirm = useConfirm()
  const [showLoader, hideLoader] = useGlobalLoader()

  return useCallback(
    (id) => {
      openConfirm({
        title: 'Deleting Answer Set',
        body: 'Are you sure you wish to remove the answer set?',
        onConfirm: () => {
          const loaderId = showLoader()
          return deleteAssistAnswerSet({ id })
            .then(() =>
              NotificationManager.success(
                'The answer set has been successfully deleted',
              ),
            )
            .catch(() => NotificationManager.error())
            .then(() => hideLoader(loaderId))
        },
      })
    },
    [openConfirm, deleteAssistAnswerSet, showLoader, hideLoader],
  )
}
