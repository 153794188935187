// Let's support timezone select
// TODO: Need to implement timezone conversion of date/time max/min props (for now, there is no use case)
import React from 'react'
import PropTypes from 'prop-types'
import mergeRefs from 'react-merge-refs'
import styled, { createGlobalStyle } from 'styled-components'
import format from 'date-fns/format'
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz'
import { Clock } from '@styled-icons/bootstrap/Clock'
import Icon from 'components-v2/atoms/Icon'
import { branch } from 'hocs'
import { localTimeZone, timeZones } from 'utils/date'
import { truncate } from 'styles/mixins'

const GlobalStyle = createGlobalStyle`
  .datepicker-popper-with-timezone-select {
    .react-datepicker__time {
      padding-bottom: 43px;
    }
    ${(p) =>
      !p.showTodayButton &&
      `
        .react-datepicker__today-button {
          display: none;
        }
      `}
  }
`

const getShortTimeZoneDisplay = (timeZone) =>
  timeZone
    ? `${timeZone.alternativeName.replace('Time', '').trim()} (GTM${
        timeZone.currentTimeFormat.split(' ')[0]
      })`
    : null

const getLongTimeZoneDisplay = (timeZone) =>
  timeZone
    ? `(GTM${timeZone.currentTimeFormat.split(' ')[0]}) - ${
        timeZone.alternativeName
      }`
    : null

const getTimeZoneDisplay = (timeZone) => {
  const [offset, ...rest] = timeZone.currentTimeFormat.split(' ')
  return `(GTM${offset}) - ${rest.join(' ')}`
}

const LocalTimeZoneDisplay = styled.div`
  position: absolute;
  right: -95px;
  bottom: 0;
  width: 95px;
  background: #f0f0f0;
  border: 1px solid #aeaeae;
  border-radius: 0 0 0.3rem 0.3rem;
  padding: 4px 4px 2px 4px;
`

const LocalTimeZoneDisplayTop = styled.div`
  display: flex;
  align-items: center;
`

const LocalTimeZoneDisplayBottom = styled.div`
  font-size: 0.85em;
  margin-top: 2px;
  ${truncate}
`

const ClockIcon = styled(Icon).attrs({ icon: Clock })`
  font-size: 1.3em;
  margin-right: 4px;
`

const TimeZoneSelect = styled.div`
  position: absolute;
  right: -394px;
  top: 0;
  bottom: 0;
  width: 300px;
  background: #ffffff;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

const TimeZoneSelectTop = styled.div`
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  padding: 2px 8px;
  flex: none;
`

const TimeZoneSelectTitle = styled.div`
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
`

const TimeZoneList = styled.ul`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  list-style: none;
  padding: 0;
  margin: 0;
`

const TimeZoneItem = styled.li`
  height: 30px;
  padding: 5px 8px;
  white-space: nowrap;
  ${({ selected }) =>
    selected
      ? `
        background-color: #216ba5;
        color: white;
        font-weight: bold;
      `
      : `
        &:hover {
          cursor: pointer;
          background-color: #f0f0f0;
        }
      `}
`

export default (WrappedComponent) => {
  const WrapperComponent = React.forwardRef(
    (
      {
        value,
        todayButton,
        showTimezoneSelect,
        children,
        onChange,
        customInput: customInputProp,
        popperClassName,
        ...rest
      },
      ref,
    ) => {
      const localRef = React.useRef()
      const [timeZone, setTimeZone] = React.useState(localTimeZone)
      const valueInActiveTimezone = React.useMemo(
        () =>
          value && timeZone ? utcToZonedTime(value, timeZone.name) : value,
        [value, timeZone],
      )
      const handleSelectTimeZone = React.useCallback(
        (newTimeZone) => {
          setTimeZone(newTimeZone)
          localRef.current.setOpen(false)
          if (valueInActiveTimezone) {
            onChange(zonedTimeToUtc(valueInActiveTimezone, newTimeZone.name))
          }
        },
        [valueInActiveTimezone, onChange],
      )
      const handleChange = React.useCallback(
        (newValue, event) => {
          // Let's do time conversion only if this is called by selecting any controls in popper
          if (event?.type !== 'change' && newValue) {
            onChange(zonedTimeToUtc(newValue, timeZone.name))
          } else {
            onChange(newValue, event)
          }
        },
        [onChange, timeZone],
      )
      const CustomInput = React.useMemo(() => {
        // eslint-disable-next-line react/no-unstable-nested-components
        const Component = React.forwardRef(
          // eslint-disable-next-line react/prop-types
          ({ originalValue, ...props }, inputRef) => {
            const customInput = customInputProp || <input type="text" />
            const inputValue =
              typeof localRef.current?.state?.inputValue === 'string'
                ? localRef.current.state.inputValue
                : safeDateFormat(originalValue, {
                    dateFormat: rest.dateFormat,
                    locale: rest.locale,
                  })

            return React.cloneElement(customInput, {
              ...props,
              value: inputValue,
              ref: inputRef,
            })
          },
        )
        Component.propTypes = {
          // eslint-disable-next-line react/no-unused-prop-types
          originalValue: PropTypes.instanceOf(Date),
        }
        return Component
      }, [customInputProp, rest.dateFormat, rest.locale])
      return (
        <WrappedComponent
          {...rest}
          value={valueInActiveTimezone}
          onChange={handleChange}
          todayButton={todayButton || 'Today'}
          ref={mergeRefs([localRef, ref])}
          shouldCloseOnSelect={false}
          customInput={<CustomInput originalValue={value} />}
          popperClassName={`${
            popperClassName || ''
          } datepicker-popper-with-timezone-select`}
        >
          <GlobalStyle showTodayButton={!!todayButton} />
          {children}
          <LocalTimeZoneDisplay>
            <LocalTimeZoneDisplayTop>
              <ClockIcon />
              Time Zone
            </LocalTimeZoneDisplayTop>
            <LocalTimeZoneDisplayBottom>
              {getShortTimeZoneDisplay(timeZone)}
            </LocalTimeZoneDisplayBottom>
          </LocalTimeZoneDisplay>
          <TimeZoneSelect>
            <TimeZoneSelectTop>
              <TimeZoneSelectTitle>Time Zone</TimeZoneSelectTitle>
              <div>{getLongTimeZoneDisplay(timeZone)}</div>
            </TimeZoneSelectTop>
            <TimeZoneList>
              {timeZones.map((tz) => (
                <TimeZoneItem
                  key={tz.name}
                  selected={tz === timeZone}
                  onClick={() => handleSelectTimeZone(tz)}
                >
                  {getTimeZoneDisplay(tz)}
                </TimeZoneItem>
              ))}
            </TimeZoneList>
          </TimeZoneSelect>
        </WrappedComponent>
      )
    },
  )

  WrapperComponent.propTypes = {
    value: PropTypes.instanceOf(Date),
    todayButton: PropTypes.string,
    showTimeSelect: PropTypes.bool,
    showTimezoneSelect: PropTypes.bool,
    onChange: PropTypes.func,
    children: PropTypes.element,
    customInput: PropTypes.element,
    popperClassName: PropTypes.string,
  }

  return branch(
    (props) => props.showTimeSelect && props.showTimezoneSelect,
    WrapperComponent,
    WrappedComponent,
  )
}

// Copied from https://github.com/Hacker0x01/react-datepicker/blob/v3.1.3/src/date_utils.js
function getDefaultLocale() {
  const scope = typeof window !== 'undefined' ? window : global

  // eslint-disable-next-line no-underscore-dangle
  return scope.__localeId__
}

function getLocaleObject(localeSpec) {
  if (typeof localeSpec === 'string') {
    // Treat it as a locale name registered by registerLocale
    const scope = typeof window !== 'undefined' ? window : global
    // eslint-disable-next-line no-underscore-dangle
    return scope.__localeData__ ? scope.__localeData__[localeSpec] : null
  }
  // Treat it as a raw date-fns locale object
  return localeSpec
}

function formatDate(date, formatStr, locale) {
  if (locale === 'en') {
    return format(date, formatStr, { awareOfUnicodeTokens: true })
  }
  let localeObj = getLocaleObject(locale)
  if (locale && !localeObj) {
    console.warn(
      `A locale object was not found for the provided string ["${locale}"].`,
    )
  }
  if (
    !localeObj &&
    !!getDefaultLocale() &&
    !!getLocaleObject(getDefaultLocale())
  ) {
    localeObj = getLocaleObject(getDefaultLocale())
  }
  return format(date, formatStr, {
    locale: localeObj || null,
    awareOfUnicodeTokens: true,
  })
}

function safeDateFormat(date, { dateFormat, locale }) {
  return (
    (date &&
      formatDate(
        date,
        Array.isArray(dateFormat) ? dateFormat[0] : dateFormat,
        locale,
      )) ||
    ''
  )
}
