import forEach from 'lodash/forEach'
import {
  getResponseScoreLabelByValue,
  getResponseScoreOptionsWithLabel,
} from 'lib/assessment'
import { AssessmentElementTypes } from 'constants/index'

export function getOffsetAndChartOptions(template) {
  const scoreOptions = getResponseScoreOptionsWithLabel(
    template.rating_label_set,
  )
  const offset = scoreOptions.length === 5 ? 3 : 4
  const vAxisTickts = [
    { v: 0, f: '' },
    ...scoreOptions.map((opt) => ({
      v: opt.value + offset,
      f: `${
        getResponseScoreLabelByValue(opt.value, template.rating_label_set) || ''
      }`,
    })),
  ]
  const options = {
    hAxis: {
      ticks: [],
      maxTextLines: 1000,
      minTextSpacing: 40,
      textStyle: {
        color: '#777777',
      },
    },
    vAxis: {
      title: '',
      minValue: 0,
      maxValue: offset,
      ticks: vAxisTickts,
    },
    // jewel tone
    // from here http://www.savvydata.com/blog/wp-content/uploads/2010/03/snap-ChartFun_Palettes.png
    // colors: [
    //     '#CD363C', '#7D4EA9', '#39B8E8', '#9DCF52', '#9BD150',
    //     '#966130', '#E67398', '#FBBD48', '#838BA4', '#95B2A9',
    //     '#BD6768', '#8E75A7', '#67B4CF', '#A2C173', '#886F59',
    //     '#D28468', '#D087A2', '#DDB670', '#7789A4', '#74A08A',
    //     '#B87E7E', '#9784AB', '#3153A8', '#268757',
    // ],
    chartArea: { left: 135, top: 30, width: '100%', height: '45%' },
    legend: { position: 'bottom', maxLines: 5 },
    height: 475,
  }
  return { offset, options }
}

export function getSummaryReportRowsAndChartDataArray(
  assessments,
  templateId,
  offset,
) {
  if (!assessments) {
    return {
      chartDataArray: [],
      summaryReportRows: [],
    }
  }
  const questions = {}
  const vendors = []
  const summaryReportRows = []
  assessments.forEach((assessment) => {
    // this line is not really needed
    if (assessment.assessment_template_id === templateId) {
      vendors.push(assessment.vendor)
      summaryReportRows.push(assessment)
      assessment.elements.forEach((element) => {
        if (!questions[element.id]) {
          questions[element.id] = []
        }
        if (element.type !== AssessmentElementTypes.SECTION_HEADING) {
          let hasResponse = false
          assessment.responses.forEach((response) => {
            if (element.id === response.element_id) {
              questions[element.id].push({
                label: `${element.key} ${element.label}`,
                review_score: response.review_score,
              })
              hasResponse = true
            }
          })
          if (!hasResponse) {
            questions[element.id].push({
              label: `${element.key} ${element.label}`,
              review_score: null,
            })
          }
          hasResponse = false
        } else {
          questions[element.id] = { type: element.type }
        }
      })
    }
  })

  const rows = []
  forEach(questions, (question) => {
    let row = []
    if (question.type !== AssessmentElementTypes.SECTION_HEADING) {
      question.forEach((item, idx) => {
        if (row.length === 0) {
          row.push(item.label)
        }
        row.push(item.review_score === null ? 0 : item.review_score + offset)
        row.push(vendors[idx].name)
      })
    } else {
      row = question
    }
    rows.push(row)
  })

  const allGraphsData = []
  let graphData = []
  const maxItemsPerChart = 4

  const firstRow = [{ label: 'Question', type: 'string' }]
  forEach(vendors, (vendor) => {
    firstRow.push({ label: vendor.name, type: 'number' })
    firstRow.push({ label: vendor.name, role: 'tooltip' })
  })

  graphData.push(firstRow)
  rows.forEach((row, i) => {
    if (rows.length === i + 1) {
      graphData.push(row)
      allGraphsData.push(graphData)
      return
    }
    if (row.type === AssessmentElementTypes.SECTION_HEADING) {
      if (i !== 0) {
        if (graphData.length > 0) {
          allGraphsData.push(graphData)
        }
        graphData = []
        graphData.push(firstRow)
      }
      allGraphsData.push(row)
    } else if (graphData.length < maxItemsPerChart) {
      graphData.push(row)
    } else {
      graphData.push(row)
      allGraphsData.push(graphData)
      graphData = []
      if (
        rows.length >= i + 1 &&
        rows[i + 1].type !== AssessmentElementTypes.SECTION_HEADING
      ) {
        graphData.push(firstRow)
      }
    }
  })

  const fakeRow = ['']
  for (let i = 0; i < vendors.length; i++) {
    fakeRow.push(0)
    fakeRow.push('')
  }

  const chartDataArray = allGraphsData.map((item) => {
    if (Array.isArray(item)) {
      for (let i = 0; item.length < maxItemsPerChart; i++) {
        item.push(fakeRow)
      }
    }
    return item
  })
  return {
    chartDataArray,
    summaryReportRows,
  }
}
