import React from 'react'
import PropTypes from 'prop-types'

// eslint-disable-next-line no-unused-vars
const ProjectContactUserForm = ({ project }) => (
  <p>ProjectContactUserForm Spinup</p>
)

ProjectContactUserForm.propTypes = {
  project: PropTypes.object.isRequired,
}

export default ProjectContactUserForm
