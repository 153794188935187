import React from 'react'
import PropTypes from 'prop-types'
import filter from 'lodash/filter'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import values from 'lodash/values'
import get from 'lodash/get'
import { PieChart } from 'components-v2/molecules/Charts'
import {
  SecurityToolStatusTypes,
  SecurityObjectiveStatusTypes,
} from 'constants/index'
import { colors } from 'styles'
import { SecurityToolStatusOptions } from '../constants'
import { formatRiskRating, getRiskRatingColor } from '../utils'
import { Wrapper, HoleContent, RiskRating } from './styles'

const StatusPieChart = ({ countByStatus, riskRating, ...rest }) => {
  const chartData = React.useMemo(() => {
    const countByStatusEx = {
      ...countByStatus,
      // Let's treat 'No Coverage' as 'Not reporting' on the graph
      [SecurityToolStatusTypes.NOT_REPORTING]:
        get(countByStatus, SecurityToolStatusTypes.NOT_REPORTING, 0) +
        get(countByStatus, SecurityObjectiveStatusTypes.NO_COVERAGE, 0),
      [SecurityObjectiveStatusTypes.NO_COVERAGE]: 0,
    }
    const data = map(SecurityToolStatusTypes, (type) => ({
      value: countByStatusEx[type],
      name: SecurityToolStatusOptions[type].label,
      color: SecurityToolStatusOptions[type].color,
    }))
    const statusTypeArr = values(SecurityToolStatusTypes)
    const res = reduce(
      countByStatus,
      (result, count, status) =>
        statusTypeArr.indexOf(status) === -1 ? result + count : result,
      0,
    )
    data.push({ name: 'N/A', value: res, color: colors.DARK_GREY })
    return filter(data, (sector) => sector.value)
  }, [countByStatus])
  return (
    <Wrapper {...rest}>
      <PieChart
        data={chartData}
        width="100%"
        height={260}
        innerRadius={38}
        outerRadius={77}
        startAngle={90}
        endAngle={450}
        label
        labelOnHover
      />
      <HoleContent>
        <RiskRating color={getRiskRatingColor(riskRating)}>
          {formatRiskRating(riskRating)}
        </RiskRating>
        Risk Rating
      </HoleContent>
    </Wrapper>
  )
}

StatusPieChart.propTypes = {
  countByStatus: PropTypes.object.isRequired,
  riskRating: PropTypes.number,
}

export default React.memo(StatusPieChart)
