import styled from 'styled-components'
import Icon from 'components-v2/atoms/Icon'
import { colors } from 'styles'

export const PreviewsWrapper = styled.div`
  .dzu-previewContainer {
    z-index: initial;
    &:last-child {
      border-bottom: none;
    }
  }
`

export const Dropzone = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 184px;
  min-height: 0;
  padding: 20px 20px 30px;
  background-color: ${colors.LIGHT_GREY};
  color: ${colors.BODY_TEXT};
  overflow: initial;
  border-radius: 0;
  outline: none;
  transition: all 0.15s linear;
  border: none;
`

export const UploadIcon = styled(Icon).attrs({ icon: 'fa fa-cloud-upload' })`
  font-size: 44px;
  color: #bcc6ce;
  margin-bottom: 14px;
`

export const ContentWrapper = styled.label`
  outline: none;
  border: 2px dashed ${colors.GREY};
  position: absolute;
  inset: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ active }) =>
    active &&
    `
    background-color: #DEEBFF;
    border-color: #2484FF;
  `}

  ${({ reject }) =>
    reject &&
    `
    background-color: #DAA;
    border-color: ${colors.DANGER};
  `}

  ${({ disabled }) =>
    disabled &&
    `
    opacity: .5;
    pointer-events: none;
  `}

  &:focus {
    border-color: #2196f3;
  }
`
