import React from 'react'
import PropTypes from 'prop-types'
import AutoLink from 'components-v2/atoms/AutoLink'
import Tooltip from 'components-v2/molecules/Tooltip'
import YoutubeVideoModal from 'components/shared/YoutubeVideoModal'
import { useModal } from 'hooks'

const youtubeRegExp =
  // TODO: Eslint says there are few useless escapes in the following reg. Double-check if those can be removed
  // eslint-disable-next-line no-useless-escape
  /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/

const AssessmentElementNoteTooltip = ({ element, ...rest }) => {
  const [openYoutubeVideoModal] = useModal(YoutubeVideoModal)

  if (!element.note) {
    return null
  }

  const match = element.note.match(youtubeRegExp)
  if (match && match[2].length === 11) {
    const videoId = match[2]
    const thumbUrl = `https://img.youtube.com/vi/${videoId}/default.jpg`
    return (
      <Tooltip {...rest} closeOnTooltipClick>
        <img
          alt="youtube video thumb"
          src={thumbUrl}
          width="120"
          height="90"
          onClick={() => openYoutubeVideoModal({ videoId, title: element.key })}
        />
      </Tooltip>
    )
  }
  return (
    <Tooltip {...rest}>
      <AutoLink text={element.note} />
    </Tooltip>
  )
}

AssessmentElementNoteTooltip.propTypes = {
  element: PropTypes.object.isRequired,
}

export default AssessmentElementNoteTooltip
