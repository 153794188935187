import styled from 'styled-components'
import IconButton from 'components-v2/atoms/IconButton'
import { MoreVert } from '@styled-icons/material/MoreVert'
import { colors, media } from 'styles'

export const Wrapper = styled.div`
  position: relative;
  background: #fff;
  border-radius: 4px;
  padding: 13px 24px 18px;

  ${media.mobile`
    @media print {
      page-break-inside: avoid;
    }
  `}
`

export const Title = styled.h3`
  margin: 0 0 20px;
  font-size: 21px;
`

export const Content = styled.div`
  color: ${colors.BODY_TEXT};
`

export const Separator = styled.div`
  &:not(:last-child) {
    margin: 13px -24px;
    border-top: 1px solid #eeeeee;
  }
`

export const MoreIconButton = styled(IconButton).attrs({ icon: MoreVert })`
  position: absolute;
  top: 15px;
  right: 6px;
  color: #cdd0d4;
  font-size: 30px;
`
