import filter from 'lodash/filter'
import reject from 'lodash/reject'
import map from 'lodash/map'
import find from 'lodash/find'
import sortBy from 'lodash/sortBy'
import isEmpty from 'lodash/isEmpty'
import { isSectionElement } from 'lib/assessment'
import * as dom from 'utils/dom'
import { sizes } from 'styles'
import {
  ElementFilterOptions,
  STICKY_HEADER_HEIGHT,
  ASSESSMENT_TABLE_ID_PREFIX,
} from './constants'

const isAnsweredQuestion = (question) =>
  question.response &&
  !isEmpty(question.response.answer) &&
  question.response.answer[0]
const isIncompleteQuestion = (question) =>
  (question.required && !question.response) ||
  (question.response && question.response.has_reqs_unmet)

// Filter elements using filter options and text
// Filter out skipped elements as well
export const filterElements = (
  elements,
  elementFilter,
  filterText,
  elementIdInFocus,
) => {
  const filteredElements = filter(elements, (element) => {
    if (isSectionElement(element)) {
      return true
    }

    if (element.id === elementIdInFocus) {
      return true
    }

    // Filter using filter options
    if (elementFilter) {
      if (elementFilter[ElementFilterOptions.ANSWERED.key]) {
        if (!isAnsweredQuestion(element)) {
          return false
        }
      } else if (elementFilter[ElementFilterOptions.UNANSWERED.key]) {
        if (element.skipped || isAnsweredQuestion(element)) {
          return false
        }
      } else if (elementFilter[ElementFilterOptions.INCOMPLETE.key]) {
        if (!isIncompleteQuestion(element)) {
          return false
        }
      }
    }

    // Filter using text filter
    if (filterText) {
      if (
        element.label.toLowerCase().indexOf(filterText.toLowerCase()) === -1
      ) {
        return false
      }
    }

    // Filter out skipped questions
    if (element.skipped && !element.tempVisible) {
      return false
    }

    return true
  })

  // Filter out empty sections
  return reject(
    filteredElements,
    (element, index) =>
      isSectionElement(element) &&
      (index === filteredElements.length - 1 ||
        isSectionElement(filteredElements[index + 1])),
  )
}

export const getProgressDetails = (progress, sections) => {
  const details = map(progress, (value, key) => {
    const section = find(sections, { id: key })
    if (section) {
      return {
        answered: value.answered,
        incomplete: value.incomplete,
        total: value.questions,
        order: section.sort_order,
        label: `${section.key} ${section.label}`,
      }
    }
    return {
      answered: value.answered,
      incomplete: value.incomplete,
      total: value.questions,
      label: 'Unknown',
    }
  })
  return sortBy(details, ['order'])
}

export const scrollTo = (domId) =>
  dom.scrollTo(domId, sizes.HEADER_HEIGHT + STICKY_HEADER_HEIGHT)

export const scrollToTable = (assessmentId) => {
  scrollTo(ASSESSMENT_TABLE_ID_PREFIX + assessmentId)
}
