import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import AutoLink from 'components-v2/atoms/AutoLink'
import Editable from 'components-v2/molecules/Editable'
import Uploader from 'components-v2/molecules/Uploader'
import AssessmentElementNoteTooltip from 'components/shared/AssessmentElementNoteTooltip'
import AssessmentElementAnswerForm from 'components/shared/AssessmentElementAnswerForm'
import IssueLink from 'components/shared/IssueLink'
import { EntityTypes } from 'constants/index'
import { getEndpointUrl } from 'utils/url'
import {
  ActionColumn,
  Actions,
  AnswerFormWrapper,
  CommentWrapper,
  DisabledInfo,
  EyeSlashIcon,
  KeyColumn,
  ModifiedBy,
  QuestionLabel,
  QuestonColumn,
  Row,
  RequiredLabel,
  FileList,
  FileRow,
  FileItem,
  FileIcon,
} from './styles'

const ResponseRow = ({
  element,
  assessmentId,
  socketId,
  disabled,
  incompleteAttempted,
  currentUser,
  translatable,
  useCustomKeys,
  onAnswerChange,
  onCommentSubmit,
  onAttachmentsChange,
  onRowClick,
  ...rest
}) => {
  const handleAnswerChange = useCallback(
    (answer) => onAnswerChange(element.id, answer),
    [onAnswerChange, element.id],
  )

  const handleCommentSubmit = useCallback(
    (value) => onCommentSubmit(element.id, value),
    [onCommentSubmit, element.id],
  )

  const handleAttachmentsChange = useCallback(
    (attachments, respData) => onAttachmentsChange(element.id, respData),
    [onAttachmentsChange, element.id],
  )

  const handleRowClick = useCallback(() => {
    onRowClick(element.id)
  }, [onRowClick, element.id])

  const showModifiedBy = useMemo(() => {
    if (
      !element?.response?.responder?.id ||
      !element?.response?.responder?.name
    ) {
      return false
    }
    if (!currentUser) return true
    return currentUser !== element.response.responder.id
  }, [element, currentUser])

  const requiredLabelText = useMemo(() => {
    const com = element.allow_comments
    const att = element.allow_attachments
    if (com && !att) {
      return 'Comment required'
    }
    if (att && !com) {
      return 'Attachment required'
    }
    if (com && att) {
      return 'Comment or attachment required'
    }
    // shouldn't ever happen
    return ''
  }, [element])

  const { response } = element
  const skippedOrDisabled = disabled || element.skipped
  const attachmentUploadUrl = getEndpointUrl(
    'vendorAssessmentElementResponse',
    { assessmentId, elementId: element.id },
  )
  const artifactRequired =
    !skippedOrDisabled && response?.reqs_needed?.some((x) => x === 'artifact')
  return (
    <Row
      {...rest}
      skipped={element.skipped}
      id={element.id}
      onClick={handleRowClick}
    >
      <KeyColumn customKey={useCustomKeys}>
        {element.key}
        {element.required && incompleteAttempted && ' *'}
      </KeyColumn>
      <QuestonColumn>
        <QuestionLabel>
          <span className={!translatable ? 'no-lang' : undefined}>
            <AutoLink text={element.label} />
          </span>
          &nbsp;
          <AssessmentElementNoteTooltip element={element} />
        </QuestionLabel>
        <FileList>
          {element.attachments?.map((file) => (
            <FileRow key={file.id}>
              <FileItem
                href={file.download_url}
                title={file.full_name}
                target="_blank"
              >
                <FileIcon />
                {file.name}
              </FileItem>
            </FileRow>
          ))}
        </FileList>
        <AnswerFormWrapper>
          <AssessmentElementAnswerForm
            element={element}
            value={response?.answer}
            disabled={skippedOrDisabled}
            onChange={handleAnswerChange}
            translatable={translatable}
          />
        </AnswerFormWrapper>
        {element.allow_comments && (
          <CommentWrapper>
            <Editable
              mode="popup"
              emptyValueText="Add Comment"
              value={response?.comment}
              title={response?.comment ? 'Edit Comment' : 'Add Comment'}
              disabled={skippedOrDisabled}
              includeIcon={!skippedOrDisabled}
              onSubmit={handleCommentSubmit}
              translatable={translatable}
            />
          </CommentWrapper>
        )}
      </QuestonColumn>
      <ActionColumn>
        <Actions>
          {artifactRequired && (
            <RequiredLabel>* {requiredLabelText}</RequiredLabel>
          )}
          {element.allow_attachments && !element.skipped && (
            <Uploader
              model="response"
              files={response?.attachments}
              url={attachmentUploadUrl}
              socketId={socketId}
              disabled={disabled}
              onChange={handleAttachmentsChange}
              translatable={translatable}
              description="Attach any additional files related to this response below."
            />
          )}
          <IssueLink
            issues={response?.issues}
            entity_type={EntityTypes.VENDOR}
          />
          {response && showModifiedBy && (
            <ModifiedBy disabled={disabled}>
              Last modified by <i>{response.responder.name}</i>
            </ModifiedBy>
          )}
        </Actions>
        {element.skipped && (
          <DisabledInfo>
            <EyeSlashIcon />
            &nbsp;Disabled
          </DisabledInfo>
        )}
      </ActionColumn>
    </Row>
  )
}

ResponseRow.propTypes = {
  element: PropTypes.object.isRequired,
  assessmentId: PropTypes.string.isRequired,
  socketId: PropTypes.string,
  disabled: PropTypes.bool,
  incompleteAttempted: PropTypes.bool,
  currentUser: PropTypes.string,
  useCustomKeys: PropTypes.bool,
  onAnswerChange: PropTypes.func.isRequired,
  onCommentSubmit: PropTypes.func.isRequired,
  onAttachmentsChange: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  translatable: PropTypes.bool,
}

export default ResponseRow
