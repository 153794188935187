import React from 'react'
import PropTypes from 'prop-types'
import { PieChart } from 'components-v2/molecules/Charts'
import { colors } from 'styles'
import LargeCard from '../LargeCard'
import Metric from '../Metric'
import { ChartWrapper, ChartContent, UpdatedAt, Tiny } from './styles'

const IntegrationScoreCard = ({
  value,
  grade,
  color,
  total,
  updatedAt,
  children,
  companyName,
  companyAddress,
  ...rest
}) => {
  const chartData = React.useMemo(
    () => [
      { name: '1', value, color },
      { name: '2', value: total - value, color: 'transparent' },
    ],
    [value, total, color],
  )
  return (
    <LargeCard {...rest}>
      <ChartWrapper>
        <PieChart
          data={chartData}
          width="100%"
          height={180}
          innerRadius={66}
          outerRadius={90}
          startAngle={90}
          endAngle={-270}
          backgroundColor={colors.LIGHT_GREY}
        />
        <ChartContent>
          <Metric color={color}>{grade || value}</Metric>
          <UpdatedAt>Last updated {updatedAt}</UpdatedAt>
        </ChartContent>
      </ChartWrapper>
      {children}
      <Tiny>
        {companyName}
        <br />
        {companyAddress}
      </Tiny>
    </LargeCard>
  )
}

IntegrationScoreCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  grade: PropTypes.string,
  total: PropTypes.number.isRequired,
  color: PropTypes.string,
  children: PropTypes.node,
  updatedAt: PropTypes.string.isRequired,
  companyName: PropTypes.string,
  companyAddress: PropTypes.string,
}

export default IntegrationScoreCard
