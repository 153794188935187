import React from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash/omit'
import range from 'lodash/range'
import tinygradient from 'tinygradient'
import { ResponsiveContainer, PieChart, Pie, Sector } from 'recharts'
import { colors } from 'styles'

const GaugeChart = ({
  value,
  min,
  max,
  width,
  height,
  stepCount,
  color,
  colors: colorsProp,
  endAngle,
  className,
  ...rest
}) => {
  const id = React.useId()
  const ActiveShape = React.useMemo(() => {
    let gradientColors = colorsProp || [color]
    if (stepCount > 1) {
      gradientColors =
        gradientColors.length > 1
          ? tinygradient(gradientColors)
              .rgb(stepCount)
              .map((e) => e.toRgbString())
          : new Array(stepCount).fill(gradientColors[0])
    }
    // eslint-disable-next-line react/no-unstable-nested-components
    return (props) => {
      // eslint-disable-next-line react/prop-types
      const { startAngle, innerRadius, outerRadius, cx, cy } = props
      const stepAngle = (endAngle - startAngle) / (stepCount * 4 - 3)
      const gutterAngle = stepAngle * 3
      return (
        <g>
          <mask id={`${id}_mask`}>
            <Sector {...props} fill="white" />
          </mask>
          <g>
            {range(stepCount).map((i) => (
              <Sector
                key={i}
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                cornerRadius={Math.abs(stepAngle) / 2}
                startAngle={startAngle + i * (stepAngle + gutterAngle)}
                endAngle={
                  startAngle + i * (stepAngle + gutterAngle) + stepAngle
                }
                fill="#DDD"
              />
            ))}
          </g>
          <g mask={`url(#${id}_mask)`}>
            {range(stepCount).map((i) => (
              <Sector
                key={i}
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                cornerRadius={Math.abs(stepAngle) / 2}
                startAngle={startAngle + i * (stepAngle + gutterAngle)}
                endAngle={
                  startAngle + i * (stepAngle + gutterAngle) + stepAngle
                }
                fill={gradientColors[i]}
              />
            ))}
          </g>
        </g>
      )
    }
  }, [stepCount, color, colorsProp, endAngle])
  return (
    <ResponsiveContainer width={width} height={height} className={className}>
      <PieChart>
        <Pie
          {...rest}
          dataKey="value"
          data={[{ value: value - min }, { value: max - value }]}
          endAngle={endAngle}
          fill="none"
          stroke="none"
          activeIndex={0}
          activeShape={ActiveShape}
        />
      </PieChart>
    </ResponsiveContainer>
  )
}

GaugeChart.propTypes = {
  ...omit(Pie.propTypes, 'dataKey'),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.number.isRequired,
  min: PropTypes.number,
  max: PropTypes.number.isRequired,
  stepCount: PropTypes.number,
  startAngle: PropTypes.number,
  endAngle: PropTypes.number,
  color: PropTypes.string,
  colors: PropTypes.array,
}

GaugeChart.defaultProps = {
  min: 0,
  stepCount: 1,
  startAngle: 200,
  endAngle: -20,
  color: colors.PRIMARY,
}

export default GaugeChart
