import React from 'react'
import ReactPlayer from 'react-player/youtube'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { PlayerWrapper } from './styles'

const Player = styled(ReactPlayer)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
}))

const VideoPlayer = ({ url, width, height, onStart }) => (
  <PlayerWrapper>
    <Player
      url={url}
      width={width || '100%'}
      height={height || '100%'}
      onStart={onStart}
    />
  </PlayerWrapper>
)

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  onStart: PropTypes.func,
}

export default VideoPlayer
