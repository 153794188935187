import React, { useState, useCallback } from 'react'
import { compose, withAuthorize, withReactQuery } from 'hocs'
import {
  useClientAssessmentMonthlyMetricsFetcher,
  useUsersInfiniteFetcher,
  useClientTagsFetcher,
  useClientAssessmentTemplatesInfiniteFetcher,
} from 'apis'
import {
  getMonthlyMetricsQueryParams,
  getMonthlyMetricsExportUrl,
  getAssessmentsForMetricPageUrl,
} from './utils'
import View from './View'

const Container = () => {
  const [status, setStatus] = useState()
  const [riskTier, setRiskTier] = useState()
  const [archivedValues, setArchivedValues] = useState()
  const [assessmentTemplate, setAssessmentTemplate] = useState()
  const [assignee, setAssignee] = useState()
  const [vendorTag, setVendorTag] = useState()
  const [dateRange, setDateRange] = useState()

  const clientTagsFetcherResult = useClientTagsFetcher()
  const assessmentTemplatesFetcherResult =
    useClientAssessmentTemplatesInfiniteFetcher({
      params: {
        per_page: 10,
      },
    })
  const usersFetcherResult = useUsersInfiniteFetcher({
    params: {
      per_page: 10,
    },
  })
  const filters = {
    assessmentTemplate,
    status,
    riskTier,
    assignee,
    vendorTag,
    dateRange,
    archivedValues,
  }
  const { data: chartData } = useClientAssessmentMonthlyMetricsFetcher({
    params: getMonthlyMetricsQueryParams(filters),
  })

  const handleExport = useCallback(
    (format) => {
      window.location.assign(getMonthlyMetricsExportUrl(filters, format))
    },
    [filters],
  )

  const getAssessmentsPageUrl = useCallback(
    (customFilters) => getAssessmentsForMetricPageUrl(filters, customFilters),
    [filters],
  )

  return (
    <View
      status={status}
      riskTier={riskTier}
      archivedValues={archivedValues}
      assessmentTemplate={assessmentTemplate}
      assignee={assignee}
      vendorTag={vendorTag}
      dateRange={dateRange}
      clientTagsFetcherResult={clientTagsFetcherResult}
      assessmentTemplatesFetcherResult={assessmentTemplatesFetcherResult}
      usersFetcherResult={usersFetcherResult}
      chartData={chartData}
      onStatusChange={setStatus}
      onArchivedValuesChange={setArchivedValues}
      onAssessmentTemplateChange={setAssessmentTemplate}
      onRiskTierChange={setRiskTier}
      onAssigneeChange={setAssignee}
      onVendorTagChange={setVendorTag}
      onDateRangeChange={setDateRange}
      onExport={handleExport}
      getAssessmentsPageUrl={getAssessmentsPageUrl}
    />
  )
}

export default compose(withAuthorize(), withReactQuery())(Container)
