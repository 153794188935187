import React from 'react'
import map from 'lodash/map'
import NotificationManager from 'lib/notifications'
import { useModal, useGlobalLoader } from 'hooks'
import { useUpdateLibraryManageSharesByClient } from 'apis'
import ReviewPendingRequestsModal from './ReviewPendingRequestsModal'

export default (clientIdNameMap) => {
  const [openModal, closeModal] = useModal(ReviewPendingRequestsModal)
  const [showLoader, hideLoader] = useGlobalLoader()
  const { mutateAsync: update } = useUpdateLibraryManageSharesByClient()

  return React.useCallback(
    (clientId, requests) => {
      const onSubmit = (choices, note) => {
        const loaderId = showLoader()
        return update({
          data: {
            client: {
              id: clientId,
              shares: map(choices, (choice, shareId) => ({
                id: shareId,
                action: choice ? 'approve' : 'deny',
              })),
              note,
            },
          },
        })
          .then(() => {
            NotificationManager.success(
              'Vendors have been successfully imported.',
            )
            closeModal()
          })
          .catch((error) => {
            console.error(error)
            NotificationManager.error()
          })
          .then(() => hideLoader(loaderId))
      }
      openModal({ clientName: clientIdNameMap[clientId], requests, onSubmit })
    },
    [clientIdNameMap, openModal, closeModal, showLoader, hideLoader, update],
  )
}
