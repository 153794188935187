import React from 'react'
import map from 'lodash/map'
import get from 'lodash/get'
import AddProjectVendorsModal from 'components/client/shared/AddProjectVendorsModal'
import NotificationManager from 'lib/notifications'
import { useModal, useGlobalLoader, useStateRef } from 'hooks'
import { LabelTypes } from 'constants/index'
import * as apis from 'apis'
import { useClientTagsFetcher } from 'apis'

export default function useAddProjectVendors() {
  const [
    openAddProjectVendorsModal,
    closeAddProjectVendorsModal,
    updateAddProjectVendorsModal,
  ] = useModal(AddProjectVendorsModal)
  // Fetching all the vendorNames' vendor_tag_list at once is very expensive.
  // As a workaround, let's memorize getClientVendorNames requests by tag
  // and generate & append vendor_tag_list property per vendorName obj at front-end
  const [vendorNamesByTag, setVendorNamesByTag] = React.useState({})
  const [showLoader, hideLoader] = useGlobalLoader()
  const vendorNamesByTagRef = useStateRef(vendorNamesByTag)

  const [needClientTags, setNeedClientTags] = React.useState(false)
  const { data: clientTags, isLoading: clientTagsLoading } =
    useClientTagsFetcher({}, { enabled: needClientTags })

  React.useEffect(() => {
    updateAddProjectVendorsModal({ vendorNamesByTag })
  }, [vendorNamesByTag, updateAddProjectVendorsModal])

  React.useEffect(() => {
    updateAddProjectVendorsModal({
      clientTags,
      clientTagsLoading,
    })
  }, [clientTags, clientTagsLoading, updateAddProjectVendorsModal])

  const fetchVendorNamesByTag = React.useCallback((tag) => {
    // Use cached data if possible
    if (vendorNamesByTagRef.current[tag]) {
      return Promise.resolve(vendorNamesByTagRef.current[tag])
    }
    return apis
      .getClientVendorNames({
        params: { with_vendor_tags: tag },
      })
      .then(({ data }) => {
        setVendorNamesByTag((current) => ({
          ...current,
          [tag]: data,
        }))
        return data
      })
  }, [])

  return React.useCallback(
    ({
      projectId,
      showGloborg,
      showSuccessNotification = true,
      onProjectVendorsAdded = () => {},
    }) => {
      setNeedClientTags(true)

      openAddProjectVendorsModal({
        projectId,
        vendorNamesByTag,
        clientTags,
        clientTagsLoading,
        showGloborg,
        fetchVendorNamesByTag,
        onSubmit: ({ vendorIds, dueDate, launchDate }) => {
          if (!vendorIds || vendorIds.length === 0) {
            closeAddProjectVendorsModal()
            return Promise.resolve()
          }

          const loaderId = showLoader()
          const projectVendorsData = map(vendorIds, (id) => ({
            vendor_id: id,
            date_range_attributes: {
              start_at: launchDate,
              end_at: dueDate,
            },
          }))
          return apis
            .addMultiProjectVendorsToClientProject(projectId, {
              project_vendors: projectVendorsData,
            })
            .then((response) => {
              closeAddProjectVendorsModal()
              if (showSuccessNotification) {
                const message =
                  vendorIds.length === 1
                    ? `A ${LabelTypes.VENDOR.toLowerCase()} has been successfully added to the project.`
                    : `${
                        vendorIds.length
                      } ${LabelTypes.VENDORS.toLowerCase()} have been successfully added to the project.`
                NotificationManager.success(message)
              }
              onProjectVendorsAdded(response.data)
            })
            .catch((error) => {
              // eslint-disable-next-line no-console
              console.log(error)
              if (error.response && error.response.status === 422) {
                const errors = get(error, 'response.data.errors', {})
                NotificationManager.error(
                  `Error adding ${LabelTypes.VENDORS.toLowerCase()}. ${Object.values(
                    errors,
                  ).join(', ')}`,
                )
              } else {
                NotificationManager.error()
              }
            })
            .then(() => hideLoader(loaderId))
        },
      })
    },
    [
      vendorNamesByTag,
      openAddProjectVendorsModal,
      closeAddProjectVendorsModal,
      fetchVendorNamesByTag,
      clientTags,
      clientTagsLoading,
      showLoader,
      hideLoader,
    ],
  )
}
