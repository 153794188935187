import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { useTableColumnsPref } from 'hooks'
import Privva from 'Privva'
import { getPageUrl } from 'utils/url'
import { COL_NAMES } from './constants'
import View from './View'

const integrationManagementPath = getPageUrl('organization', undefined, {
  tab: 'partners',
})
const supportPath = Privva.Utils.brand.supportUrl

const BitSightFeedViewContainer = ({ info, userPrefs, orgId }) => {
  const [feed, setFeed] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(false)
  const [searchText, setSearchText] = React.useState('')
  const [columnToggles, setColumnToggles] = useTableColumnsPref(
    userPrefs,
    'table_columns.bitsight',
    COL_NAMES,
    orgId,
  )

  React.useEffect(() => {
    axios.get(info.feed_api_path).then((response) => {
      const feedData = response.data
      if (feedData.data) {
        setFeed(feedData.data)
        setLoading(false)
      } else {
        setLoading(false)
        setError(true)
      }
    })
  }, [info.feed_api_path])

  return (
    <View
      orgId={orgId}
      feed={feed}
      loading={loading}
      error={error}
      searchText={searchText}
      integrationManagementPath={integrationManagementPath}
      supportPath={supportPath}
      partnerInfo={info}
      columnToggles={columnToggles}
      onColumnTogglesChange={setColumnToggles}
      onSearch={setSearchText}
    />
  )
}

BitSightFeedViewContainer.propTypes = {
  info: PropTypes.object.isRequired,
  orgId: PropTypes.string.isRequired,
  userPrefs: PropTypes.object,
}

export default BitSightFeedViewContainer
