import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Menu } from '@headlessui/react'
import Button from 'components-v2/atoms/Button'
import { colors, mixins } from 'styles'

const MenuItemButton = styled(Button)`
  ${mixins.clearButtonStyle}
  display: block;
  width: 100%;
  border-radius: 0;
  padding: 6px 15px;
  line-height: 1em;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  text-align: left;
  box-shadow: none;

  ${(props) => props.active && `background-color: #e6e6e6;`}
  ${(props) => props.danger && `color: ${colors.DANGER} !important;`}
`

const MenuItem = ({ disabled, ...rest }) => (
  <Menu.Item disabled={disabled}>
    {(props) => <MenuItemButton {...rest} {...props} />}
  </Menu.Item>
)

MenuItem.propTypes = {
  disabled: PropTypes.bool,
}

export default MenuItem
