import styled from 'styled-components'
import FormField, { ControlLabel } from 'components-v2/molecules/FormField'

export const StyledFormField = styled(FormField).attrs({
  renderControlLabel: styled(ControlLabel)`
    && {
      width: initial;
      padding-left: 10px;
      padding-right: 0;
    }
  `,
})`
  flex-direction: row-reverse;
  width: 170px;
  margin: 0 auto;
`
