import React from 'react'
import PropTypes from 'prop-types'
import A from 'components-v2/atoms/A'
import Icon from 'components-v2/atoms/Icon'
import { SingleUploader } from 'components-v2/molecules/Uploader'
import { getPageUrl } from 'utils/url'
import { Wrapper, Thumbnail } from './styles'

const url = getPageUrl('organization')

const CustomLayout = ({ file, dropzoneUploader, onRemove, ...rest }) => (
  <Wrapper {...rest}>
    {!!file && <Thumbnail src={file.url} />}
    {dropzoneUploader}
    {!!file && <A onClick={onRemove}>Remove</A>}
  </Wrapper>
)

CustomLayout.propTypes = {
  file: PropTypes.object,
  dropzoneUploader: PropTypes.element,
  onRemove: PropTypes.func.isRequired,
}

const SingleImageUploader = ({ organizationId, ...rest }) => (
  <SingleUploader
    {...rest}
    url={url}
    accept="image/*"
    additionalPayload={{ id: organizationId }}
    renderLayout={CustomLayout}
  />
)

SingleImageUploader.propTypes = {
  organizationId: PropTypes.string.isRequired,
  buttonContent: PropTypes.node,
  model: PropTypes.string,
}

SingleImageUploader.defaultProps = {
  buttonContent: [
    <Icon key="icon" icon="fa fa-cloud-upload" />,
    'Upload Image',
  ],
  model: 'organization',
}

export default SingleImageUploader
