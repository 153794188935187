import styled from 'styled-components'
import { colors } from 'styles'

export const Inner = styled.div`
  padding: 30px 0 20px 0;
`

export const UpdatedAt = styled.div`
  font-size: 13px;
  line-height: 16px;
  color: ${colors.DARK_GREY};
  margin-top: 10px;
`

export const IssueCountsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 15px -24px 0;
  font-size: 15px;
`

export const IssueCountCol = styled.div`
  margin: 0 4px;
  display: flex;
  align-items: center;
`

export const IssueCountColLabel = styled.div`
  ${({ color }) => `color: ${color};`}
  margin-right: 8px;
`
