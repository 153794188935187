import React from 'react'
import * as yup from 'yup'
import FormField from 'components-v2/molecules/FormField'
import FormDrawer from 'components-v2/organisms/FormDrawer'

const validationSchema = yup.object().shape({
  name: yup.string().label('Name').required(),
  email: yup.string().label('Email').required().email(),
})

const formConfig = {
  validationSchema,
  initialValues: {
    name: undefined,
    email: undefined,
  },
}

const InviteVendorUserModal = (props) => (
  <FormDrawer {...props} title="Send Invitation" formConfig={formConfig}>
    <FormField name="name" label="Name" placeholder="Jane Doe" />
    <FormField name="email" label="Email" placeholder="jdoe@email.com" />
  </FormDrawer>
)

export default InviteVendorUserModal
