import React from 'react'
import PropTypes from 'prop-types'
import { useAuthorizations } from 'hooks'
import Contributor from './Contributor'
import { List } from './styles'

const ContributorsDisplay = ({ value, vendor }) => {
  const [canManage] = useAuthorizations('manage')

  return (
    <List>
      {value?.map((e) => (
        <Contributor
          key={e.id}
          contributor={e}
          vendorId={vendor.id}
          canManage={canManage}
        />
      ))}
    </List>
  )
}

ContributorsDisplay.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object),
  vendor: PropTypes.object.isRequired,
}

export default ContributorsDisplay
