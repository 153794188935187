import React from 'react'
import PropTypes from 'prop-types'
import Dialog from 'components-v2/molecules/Dialog'
import { IframeWrapper, Iframe } from './styles'

const YoutubeVideoModal = ({ videoId, title, onClose, ...rest }) => (
  <Dialog {...rest} onClose={onClose}>
    <Dialog.Header onClose={onClose}>{title}</Dialog.Header>
    <Dialog.Content>
      <IframeWrapper>
        <Iframe
          frameBorder="0"
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1&enablejsapi=1`}
          allowFullScreen
        />
      </IframeWrapper>
    </Dialog.Content>
  </Dialog>
)

YoutubeVideoModal.propTypes = {
  videoId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default YoutubeVideoModal
