import { useEffect } from 'react'

export default function useAllPagesOfInfiniteQuery({
  data,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  isLoading,
  isFetching,
  isError,
  isSuccess,
}) {
  useEffect(() => {
    if (!isError && !isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [hasNextPage, fetchNextPage, isFetchingNextPage, isError])
  return {
    data,
    isLoading: isLoading || (!isError && hasNextPage),
    isFetching: isFetching || (!isError && hasNextPage),
    isError,
    isSuccess: isSuccess && !hasNextPage,
  }
}
