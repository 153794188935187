import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@tanstack/react-query'
import Table from 'components-v2/molecules/Table'
import PrintButton from 'components-v2/molecules/PrintButton'
import PageHeader from 'components-v2/organisms/PageHeader'
import { Col } from 'components-v2/organisms/Layout'
import { withReactQuery } from 'hocs'
import * as apis from 'apis'
import { LabelTypes } from 'constants/index'
import {
  getOffsetAndChartOptions,
  getSummaryReportRowsAndChartDataArray,
} from './utils'
import {
  AnalysisHeader,
  AnalysisSubHeader,
  TableWrapper,
  SummaryReportHeaderRow,
  VendorColumnSectionHeader,
  VendorColumnSectionHeaderPrint,
  StyledChart,
  PrintFooter,
  LoadingContent,
} from './styles'

const columns = [
  {
    id: 'vendor.name',
    header: LabelTypes.VENDOR,
  },
  {
    id: 'vendor.risk_tier',
    header: 'Tier',
  },
  {
    id: 'risk_rating',
    header: 'Risk Score',
  },
]

const Container = ({ assessmentIds, template, clientName }) => {
  const { data: assessments, isFetching } = useQuery(
    ['clientAssessmentVendorAnalysisReport', assessmentIds],
    () =>
      Promise.all(
        assessmentIds.map((assessmentId) =>
          apis
            .getClientAssessment(assessmentId)
            .then((response) => response.data),
        ),
      ),
    options,
  )

  const { offset, options } = useMemo(
    () => getOffsetAndChartOptions(template),
    [template],
  )

  const { chartDataArray, summaryReportRows } = useMemo(
    () =>
      getSummaryReportRowsAndChartDataArray(assessments, template.id, offset),
    [assessments, template, offset],
  )

  let sectionHeader = ''
  return (
    <>
      <PageHeader
        title={`${LabelTypes.VENDOR} Analysis Report`}
        className="hidden-print"
      >
        <PrintButton color="primary" />
      </PageHeader>
      <AnalysisHeader>
        <h1>{LabelTypes.VENDOR} Security Assessment Results</h1>
      </AnalysisHeader>
      <AnalysisSubHeader>
        <h1>Results Summary</h1>
      </AnalysisSubHeader>
      {isFetching ? (
        <LoadingContent>
          <i className="fa fa-spinner fa-spin spin-big" /> &nbsp; Loading
          report...
        </LoadingContent>
      ) : (
        <div>
          <SummaryReportHeaderRow>
            <Col md={6}>
              The results report reflects an overall summary comparison of
              answer scores by each {LabelTypes.VENDOR.toLowerCase()}. The risk
              rating indicates the {LabelTypes.VENDOR.toLowerCase()}’s security
              posture.
            </Col>
            <Col md={6}>
              <TableWrapper>
                <Table
                  data={summaryReportRows}
                  columns={columns}
                  enableSorting={false}
                  enablePagination={false}
                />
              </TableWrapper>
            </Col>
          </SummaryReportHeaderRow>
          {chartDataArray.map((data, index) => {
            if (Array.isArray(data)) {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index}>
                  <VendorColumnSectionHeaderPrint>
                    {sectionHeader}
                  </VendorColumnSectionHeaderPrint>
                  <StyledChart
                    chartType="ColumnChart"
                    data={data}
                    options={options}
                  />
                </div>
              )
            }
            sectionHeader = data.label
            return (
              // eslint-disable-next-line react/no-array-index-key
              <VendorColumnSectionHeader key={index}>
                {sectionHeader}
              </VendorColumnSectionHeader>
            )
          })}
          <PrintFooter>Confidential {clientName}</PrintFooter>
        </div>
      )}
    </>
  )
}

Container.propTypes = {
  assessmentIds: PropTypes.array.isRequired,
  template: PropTypes.object.isRequired,
  clientName: PropTypes.string.isRequired,
}

export default withReactQuery()(Container)
