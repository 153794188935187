import React from 'react'
import styled from 'styled-components'

export const Table = styled.div`
  display: table;
  border-bottom: 1px solid #ddd;
  margin: 0 -15px;
`

export const Tr = styled(({ isHeading, ...rest }) => <div {...rest} />)`
  display: table-row;
  height: 47px;
  ${(props) => props.isHeading && 'background: #EEE;'}
`

export const Td = styled.div`
  display: table-cell;
  vertical-align: middle;
  padding: 0 15px;
  border-top: 1px solid #ddd;
  ${(props) => props.noWrap && 'white-space: nowrap;'}
`

export const LabelTd = styled(Td)`
  width: 100%;
  word-break: break-word;
`

export const WeightWrapper = styled.div`
  width: 85px;
  font-size: 85%;
  ${(props) => props.isEdited && 'font-weight: bold;'}
`
