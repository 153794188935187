/* Clickable element (anchor or button) with Anchor style */
import styled from 'styled-components'
import { colors } from 'styles'
import Clickable from '../Clickable'

export default styled(Clickable).withConfig({
  shouldForwardProp: (prop) => !['useGray', 'underline', 'size'].includes(prop),
})`
  color: ${({ useGray }) =>
    useGray ? colors.MED_DARK_GREY : 'var(--primary-color)'};
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
  ${({ size }) => size === 'sm' && 'font-size: 14px;'}

  &:hover,
  &:focus {
    color: ${({ useGray }) =>
      useGray ? colors.DARK_GREY : 'var(--primary-color-d15)'};
    text-decoration: underline;
  }
`
