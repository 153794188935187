import React from 'react'
import PropTypes from 'prop-types'
import has from 'lodash/has'
import isEmpty from 'lodash/isEmpty'
import keyBy from 'lodash/keyBy'
import * as yup from 'yup'
import { Formik } from 'formik'
import Button from 'components-v2/atoms/Button'
import Dialog from 'components-v2/molecules/Dialog'
import Question from './Question'
import { getCalcConfig, calculateRiskTier } from './util'

const handleKeyPress = (event) => {
  if (
    event.which === 13 /* Enter */ &&
    event.target.tagName.toLowerCase() === 'input'
  ) {
    event.preventDefault()
  }
}

const buildFormConfig = (calcConfig, answers) => {
  const validSchema = {}
  const initVals = {}
  const ans = keyBy(answers, 'id')
  calcConfig.questions.forEach((q) => {
    const tmpTag = `q_${q.id}`
    validSchema[tmpTag] = yup.string().required('This field is required')
    initVals[tmpTag] = has(ans, q.id) ? ans[q.id].value : undefined
  })
  const formConfig = {
    validationSchema: yup.object().shape(validSchema),
    initialValues: initVals,
  }

  return formConfig
}

const RiskTierCalculatorModal = ({
  answers,
  calculatorId: calculatorIdProp,
  previewMode,
  onSave,
  onClose,
  ...rest
}) => {
  // forcing this to default; customs are disabled for the moment
  const calculatorId = 'default'

  const [calcConfig, setCalcConfig] = React.useState({})
  const [formConfig, setFormConfig] = React.useState({})

  React.useEffect(() => {
    const thisCalc = getCalcConfig(calculatorId)
    const thisForm = buildFormConfig(thisCalc, answers)
    setCalcConfig(thisCalc)
    setFormConfig(thisForm)
  }, [])

  const handleSubmit = (formData) => {
    const riskTierAnswers = calcConfig.questions.map((q) => {
      const value = formData[`q_${q.id}`]
      return { id: q.id, value }
    })
    const riskTier = calculateRiskTier(riskTierAnswers, calcConfig)
    onSave({ calculatorId: calcConfig.id, riskTier, riskTierAnswers })
  }

  return (
    <Dialog {...rest} size="large" onClose={onClose}>
      {!isEmpty(calcConfig) && (
        <Formik {...formConfig} onSubmit={handleSubmit}>
          {(formProps) => (
            <form onSubmit={formProps.handleSubmit}>
              <div role="presentation" onKeyPress={handleKeyPress}>
                <Dialog.Header onClose={onClose}>
                  {calcConfig.name}
                </Dialog.Header>
                <Dialog.Content>
                  {calcConfig.questions.map((q) => (
                    <Question
                      question={q}
                      key={q.id}
                      previewMode={previewMode}
                    />
                  ))}
                </Dialog.Content>
                <Dialog.Footer>
                  <Button onClick={onClose}>Cancel</Button>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={previewMode || formProps.isSubmitting}
                  >
                    Submit
                  </Button>
                </Dialog.Footer>
              </div>
            </form>
          )}
        </Formik>
      )}
    </Dialog>
  )
}

RiskTierCalculatorModal.propTypes = {
  answers: PropTypes.array,
  calculatorId: PropTypes.string,
  previewMode: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default RiskTierCalculatorModal
