import styled from 'styled-components'
import Card from 'components-v2/organisms/Card'
import { colors } from 'styles'

export const RuleLabel = styled.div`
  color: ${(props) => (props.exclude ? colors.RED : colors.BODY_TEXT)};
  font-family: monospace;
`

export const Wrapper = styled(Card)`
  font-size: 15px;
`

export const Header = styled.div`
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 5px;
`

export const Domain = styled.span`
  font-weight: bold;
`

export const NoDataIndication = styled.div`
  align-items: center;
  justify-content: center;
  height: 100px;
`
