import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import isArray from 'lodash/isArray'

const Wrapper = styled.span`
  display: block;
  margin: 0 0 5px;
  font-size: 14px;
  line-height: 20px;
  color: #a94442;
`

const ErrorMessage = ({ error, ...rest }) => {
  if (!error) {
    return null
  }
  const errors = isArray(error) ? error : [error]
  return (
    <Wrapper {...rest}>
      {errors.length > 1
        ? // eslint-disable-next-line react/no-array-index-key
          error.map((e, index) => <div key={index}>- {e}</div>)
        : errors[0]}
    </Wrapper>
  )
}

ErrorMessage.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}

export default ErrorMessage
