import { useCallback } from 'react'
import { isRoleEnough } from 'utils/roles'
import { useAuthManager } from 'lib/authorizationContext'

export function useRoleAtLeast(baseline) {
  const authManager = useAuthManager()
  const orgRole = authManager.userRole()
  return isRoleEnough(orgRole, baseline)
}

export function useRoleCallback() {
  const authManager = useAuthManager()
  const orgRole = authManager.userRole()

  return useCallback((baseline) => isRoleEnough(orgRole, baseline), [orgRole])
}
