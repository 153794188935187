import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { smallHeader } from 'styles/mixins'
import { colors } from 'styles'
import BaseTable from './BaseTable'
import { formatCell } from './styles'

const Table = styled(BaseTable)`
  .table-responsive {
    border: 1px solid ${colors.LIGHT_GREY};
    border-radius: 3px;
    overflow: hidden;
    overflow-x: auto;
  }

  table {
    ${formatCell(`
      border-top: none;
      padding: 6px 15px 6px 0;
      vertical-align: middle;

      &:first-child {
        padding-left: 15px;
      }
    `)}

    > thead {
      background: ${colors.LIGHT_GREY};

      > tr > th {
        ${smallHeader}
        letter-spacing: 0;
        line-height: 1.25em;
        color: ${colors.BODY_TEXT};
        height: auto;
        border-bottom: none;
      }
    }

    > tbody > tr:first-child > td {
      padding-top: 12px;
    }

    > tbody > tr:last-child > td {
      padding-bottom: 12px;
    }
  }

  ${(props) =>
    props.hover &&
    css`
      table > tbody > tr:hover {
        background: #fafafa;
      }
    `}
`

const Mini2 = ({ hover, ...rest }) => <Table {...rest} $hover={hover} />

Mini2.propTypes = {
  hover: PropTypes.bool,
}
Mini2.defaultProps = {
  hover: false,
}

export default Mini2
