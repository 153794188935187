import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import IconButton from 'components-v2/atoms/IconButton'
import FormField from 'components-v2/molecules/FormField'
import { Row, Col } from 'components-v2/organisms/Layout'
import { useFormField } from 'hooks'
import { theme, Wrapper, DeleteButtonWrapper } from './styles'

const ContributorFieldGroup = ({ name, size, onDelete }) => {
  const { value: contributor } = useFormField(name)
  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <Row $equalHeight>
          <Col sm={6}>
            <FormField
              name={`${name}.name`}
              placeholder="Name"
              size={size}
              horizontal={false}
              preserveHelpTextSpace={false}
              disabled={contributor.registered}
            />
          </Col>
          <Col sm={6}>
            <FormField
              name={`${name}.title`}
              placeholder="Title"
              size={size}
              horizontal={false}
              preserveHelpTextSpace={false}
            />
          </Col>
          <Col sm={6}>
            <FormField
              name={`${name}.email`}
              placeholder="Email"
              size={size}
              horizontal={false}
              preserveHelpTextSpace={false}
              disabled={!contributor.isNew}
            />
          </Col>
          <Col sm={6}>
            <FormField
              name={`${name}.phone`}
              placeholder="Phone"
              size={size}
              horizontal={false}
              preserveHelpTextSpace={false}
              disabled={contributor.registered}
            />
          </Col>
        </Row>
        <DeleteButtonWrapper>
          <IconButton icon="fa fa-close" onClick={onDelete} />
        </DeleteButtonWrapper>
      </Wrapper>
    </ThemeProvider>
  )
}

ContributorFieldGroup.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
}

export default ContributorFieldGroup
