import styled from 'styled-components'
import Select from 'components-v2/atoms/Select'
import A from 'components-v2/atoms/A'
import IconButton from 'components-v2/atoms/IconButton'
import { colors, mixins } from 'styles'

export const DetailsWrapper = styled.div`
  margin-top: 25px;
`

export const SelectedVendorsTable = styled.div`
  position: relative;
  margin-top: 25px;
  border: 2px solid ${colors.LIGHT_GREY};
  border-radius: 3px;
`

export const SelectedVendorsHead = styled.div`
  ${mixins.smallHeader}
  letter-spacing: 0;
  padding: 6px 28px 6px 12px;
  background: ${colors.LIGHT_GREY};
  display: flex;
`

export const SelectedVendorsTh = styled.div`
  flex: 1;
  padding-right: 12px;
`

export const SelectedVendorTr = styled.div`
  padding: 0 12px;
  height: 58px;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 2px solid ${colors.LIGHT_GREY};
  }
`

export const SelectedVendorTd = styled.div`
  flex: 1;
  padding-right: 12px;
`

export const SelectedArchivedVendorTd = styled.div`
  flex: 1;
  padding-right: 12px;
  font-style: italic;
  color: ${colors.MED_GREY};
`

export const DeleteIconButton = styled(IconButton).attrs({
  icon: 'fa fa-close',
})`
  flex: none;
`

export const RoleSelect = styled(Select)`
  width: 110px;
`

export const ClearButton = styled(A)`
  position: absolute;
  top: -25px;
  right: 10px;
  font-size: 12px;
`
