import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import FormField, { ControlLabel } from 'components-v2/molecules/FormField'
import { OptionGroup, OptionLabel } from './styles'

export const stringRules = (config) =>
  yup.object({
    value: yup.object({
      min: yup.number().label('Minimum').positive().integer().min(0),
      max: yup.number().label('Maximum').positive().integer().max(config.max),
    }),
  })

const StringFieldDetails = ({ fieldDef, typeConfig }) => {
  const primaryFields = (
    <React.Fragment key="primary_fields">
      <FormField
        name="render_opts.multi"
        label="Text entry field"
        helpMessage="Should the user see a single text entry line or a multi-row text box?"
        type={FormField.types.RADIO_GROUP}
        options={[
          { label: 'Single line', value: false },
          { label: 'Multiple lines', value: true },
        ]}
        horizontal
        inline
      />
    </React.Fragment>
  )

  const advancedFields = (
    <React.Fragment key="advanced_fields">
      <ControlLabel>Number of characters allowed</ControlLabel>
      <OptionGroup>
        <FormField
          name="render_opts.validations.value.min"
          label="Minimum"
          controlProps={{ style: { width: 100 } }}
          renderControlLabel={OptionLabel}
          horizontal
          inline
        />
        <FormField
          name="render_opts.validations.value.max"
          label="Maximum"
          placeholder={
            fieldDef.render_opts.multi
              ? typeConfig.longDefault
              : typeConfig.shortDefault
          }
          controlProps={{ style: { width: 100 } }}
          renderControlLabel={OptionLabel}
          horizontal
          inline
        />
      </OptionGroup>
    </React.Fragment>
  )

  return [primaryFields, advancedFields]
}

StringFieldDetails.propTypes = {
  fieldDef: PropTypes.object.isRequired,
  typeConfig: PropTypes.object.isRequired,
  // formProps: PropTypes.object,
}

export default StringFieldDetails
