import { useCallback } from 'react'
import { useModal } from 'hooks'
import ContractClausesModal from './ContractClausesModal'

export default function useViewContractClauses(
  assessment,
  questionAutomations,
) {
  const [openModal] = useModal(ContractClausesModal)

  return useCallback(() => {
    const clauseList = questionAutomations
      .filter((e) => e.source_type === 'clause' && e.status === 'activated')
      .map((e) => e.source)
    openModal({
      assessment,
      clauseList,
      onDownloadClick: () => {
        const exportUrl = `${
          window.location.href.split('?')[0]
        }/contract_clause_export.docx`
        window.location.assign(exportUrl)
      },
    })
  }, [openModal, assessment, questionAutomations])
}
