import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { HeatBubbleChart } from 'components-v2/molecules/Charts'
import Table from 'components-v2/molecules/Table'
import Tabs from 'components-v2/molecules/Tabs'
import AssessmentReviewScoresProgressBar from 'components/shared/AssessmentReviewScoresProgressBar'
import GradeLabel from 'components/shared/GradeLabel'
import { getRiskRatingColor } from 'lib/assessment'
import { hashToA } from 'utils/ruby-utils'
import { getPageUrl } from 'utils/url'
import { useAuthManager } from 'hooks'
import { LabelTypes } from 'constants/index'

const StyledTabs = styled(Tabs).attrs({
  RenderPanel: styled.div`
    display: block !important;
    height: 0;
    overflow: hidden;

    ${({ selected }) =>
      selected &&
      `
      height: auto;
    `}

    @media print {
      height: auto;
      page-break-after: always;
    }
  `,
})``

const SubRow = styled.div`
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: nowrap;
`

const getColumns = (assessments, authManager) => [
  {
    id: 'name',
    header: 'Templates',
    cell: (info) => {
      if (authManager.userCan('show', info.row.original)) {
        return (
          <a
            href={getPageUrl('clientAssessmentTemplate', {
              id: info.row.original.id,
            })}
          >
            <b>{info.getValue()}</b>
          </a>
        )
      }
      return <b>{info.getValue()}</b>
    },
  },
  {
    id: 'vendor',
    header: LabelTypes.VENDOR,
    cell: (info) => {
      const filteredAssessments = assessments.filter(
        (e) => e.assessment_template_id === info.row.original.id,
      )
      return (
        <div>
          {filteredAssessments.map((a) => (
            <SubRow key={a.id}>
              <a href={getPageUrl('clientVendor', { id: a.vendor.id })}>
                <b>{a.vendor.name}</b>
              </a>
            </SubRow>
          ))}
        </div>
      )
    },
  },
  {
    id: 'status',
    header: 'Status',
    cell: (info) => {
      const filteredAssessments = assessments.filter(
        (e) => e.assessment_template_id === info.row.original.id,
      )
      return (
        <div>
          {filteredAssessments.map((a) => (
            <SubRow key={a.id}>
              {!authManager.userCan('show', a) ? (
                a.humanized_status
              ) : (
                <a href={getPageUrl('clientAssessment', { id: a.id })}>
                  {a.humanized_status}
                </a>
              )}
            </SubRow>
          ))}
        </div>
      )
    },
  },
  {
    id: 'results',
    header: 'Results',
    cell: (info) => {
      const filteredAssessments = assessments.filter(
        (e) => e.assessment_template_id === info.row.original.id,
      )
      return (
        <div>
          {filteredAssessments.map((a) => (
            <SubRow key={a.id}>
              {a.reviewed && (
                <AssessmentReviewScoresProgressBar
                  reviewScores={a.review_scores}
                  height="17"
                />
              )}
            </SubRow>
          ))}
        </div>
      )
    },
    width: '33%',
    minWidth: 200,
  },
  {
    id: 'rating',
    header: 'Rating',
    cell: (info) => {
      const filteredAssessments = assessments.filter(
        (e) => e.assessment_template_id === info.row.original.id,
      )
      return (
        <div>
          {filteredAssessments.map((a) => (
            <SubRow key={a.id}>
              {a.reviewed && a.risk_rating && (
                <b style={{ color: getRiskRatingColor(a.risk_rating) }}>
                  {a.risk_rating}%
                </b>
              )}
            </SubRow>
          ))}
        </div>
      )
    },
  },
  {
    id: 'grade',
    header: 'Grade',
    cell: (info) => {
      const filteredAssessments = assessments.filter(
        (e) => e.assessment_template_id === info.row.original.id,
      )
      return (
        <div>
          {filteredAssessments.map((a) => (
            <SubRow key={a.id}>
              {a.reviewed && <GradeLabel iconOnly={false} grade={a.grade} />}
            </SubRow>
          ))}
        </div>
      )
    },
  },
]

export default function ResultsReport({
  assessmentTemplates,
  assessments,
  riskRatings,
}) {
  const authManager = useAuthManager()
  return (
    <div>
      <StyledTabs>
        {assessmentTemplates.map((template) => (
          <StyledTabs.Tab
            key={template.id}
            title={template.name}
            unmount={false}
          >
            <HeatBubbleChart
              width={960}
              height={480}
              data={hashToA(riskRatings[template.name])}
              hLabel={LabelTypes.VENDOR}
              vLabel={`${template.rating_label}`}
              options={{ title: `${template.name} ${template.rating_label}s` }}
            />
          </StyledTabs.Tab>
        ))}
      </StyledTabs>
      <Table
        data={assessmentTemplates}
        columns={getColumns(assessments, authManager)}
        enableSorting={false}
        enablePagination={false}
      />
    </div>
  )
}

ResultsReport.propTypes = {
  assessmentTemplates: PropTypes.arrayOf(PropTypes.object).isRequired,
  assessments: PropTypes.arrayOf(PropTypes.object).isRequired,
  riskRatings: PropTypes.object.isRequired,
}
