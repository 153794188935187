import React from 'react'
import * as yup from 'yup'
import A from 'components-v2/atoms/A'
import Button from 'components-v2/atoms/Button'
import FormField from 'components-v2/molecules/FormField'
import FormDialog from 'components-v2/organisms/FormDialog'
import { getPageUrl } from 'utils/url'
import { LabelTypes } from 'constants/index'
import { FooterLinkWrapper } from './styles'

const formConfig = {
  initialValues: {
    objective: 'Code analysis',
    vendor: undefined,
    apiKey: undefined,
    apiSecret: undefined,
    username: undefined,
    password: undefined,
  },
  validationSchema: yup.object().shape({
    objective: yup.string().label('Objective').required(),
    vendor: yup.string().label('Security vendor').required(),
    apiKey: yup.string().label('API Key').required(),
    apiSecret: yup.string().label('API Secret').required(),
    username: yup.string().label('Username').required(),
    password: yup.string().label('Password').required(),
  }),
}

const handleSave = (formData) => {
  // TODO: Wire up with API
  // eslint-disable-next-line no-console
  console.log(formData)
}

const ConnectVendorModal = (props) => (
  <FormDialog
    {...props}
    title={`Connect a ${LabelTypes.VENDOR.toLowerCase()}`}
    formConfig={formConfig}
    submitButtonLabel="Connect"
    cancelButtonLabel="Cancel"
    footerLeftContent={
      <>
        <FooterLinkWrapper>
          <A href={getPageUrl('docs')} target="blank" underline>
            Or attest via API / SDK
          </A>
        </FooterLinkWrapper>
        <Button>N/A</Button>
        <Button>No Coverage</Button>
      </>
    }
    onSubmit={handleSave}
  >
    <FormField
      name="objective"
      label="Objective"
      type={FormField.types.SELECT}
      options={[
        {
          label: 'Code analysis',
          value: 'Code analysis',
        },
      ]}
      horizontal
    />
    <FormField
      name="vendor"
      label="Security vendor"
      type={FormField.types.SELECT}
      options={[]}
      horizontal
    />
    <FormField
      name="apiKey"
      label="API Key"
      placeholder="16-digit code"
      horizontal
    />
    <FormField
      name="apiSecret"
      label="API Secret"
      placeholder="API secret"
      horizontal
    />
    <FormField
      name="username"
      label="Username"
      placeholder="Vendor username"
      horizontal
    />
    <FormField
      name="password"
      label="Password"
      placeholder="Vendor password"
      horizontal
    />
  </FormDialog>
)

export default ConnectVendorModal
