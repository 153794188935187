import React from 'react'
import PropTypes from 'prop-types'
import { ClipLoader } from 'halogenium'
import { Wrapper } from './styles'

const Loader = React.forwardRef(({ size, message, color, ...rest }, ref) => (
  <Wrapper ref={ref} {...rest}>
    <ClipLoader size={size} color={color} />
    {message && <h3>{message}</h3>}
  </Wrapper>
))

Loader.propTypes = {
  message: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Loader.defaultProps = {
  color: 'black',
}

export default Loader
