import React from 'react'
import Privva from 'Privva'
import styled from 'styled-components'
import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import filter from 'lodash/filter'
import find from 'lodash/find'
import findLast from 'lodash/findLast'
import reduce from 'lodash/reduce'
import isNumber from 'lodash/isNumber'
import sortBy from 'lodash/sortBy'
import identity from 'lodash/identity'
import Label from 'components-v2/atoms/Label'
import Capsule from 'components-v2/atoms/Capsule'
import Icon from 'components-v2/atoms/Icon'
import { useAuthorizations } from 'hooks'
import { getPageUrl } from 'utils/url'
import {
  AssessmentElementTypes,
  AssessmentStatusOptions,
  AssessmentGradeOptions,
  ResponseScoreOptions,
  RatingLabelSetTypes,
  RatingLabelSetOptions,
} from 'constants/index'
import PropTypes from 'prop-types'

const AssessmentStatusOptionsObj = keyBy(AssessmentStatusOptions, 'value')
const defaultRatingLabelSet = RatingLabelSetTypes.STANDARD
const RatingLabelSetOptionsObj = keyBy(RatingLabelSetOptions, 'value')
const sortedRiskRatingColorOptions = sortBy(
  map(Privva.Utils.risk_rating_colors, (color, value) => ({
    value: parseInt(value, 10),
    color,
  })),
  ['value'],
)

export const isSectionElement = (element) =>
  element.type === AssessmentElementTypes.SECTION_HEADING

export const isNotSectionElement = (element) =>
  element.type !== AssessmentElementTypes.SECTION_HEADING

export const isQuestionScorable = (question) => question.weight !== '0.0'

export const getElementById = (elements, elementId) =>
  find(elements, { id: elementId })

export const filterElementsById = (elements, elementIds) =>
  filter(elements, (e) => elementIds.indexOf(e.id) !== -1)

export const getSectionsFromElements = (elements) =>
  filter(elements, isSectionElement)

export const getQuestionsFromElements = (elements) =>
  filter(elements, isNotSectionElement)

export const getQuestionsInSection = (elements, sectionId) =>
  filter(elements, { parent_id: sectionId })

export const getProgressSummary = (progress) =>
  reduce(
    progress,
    (result, value) => ({
      answeredQuestions: result.answeredQuestions + value.answered,
      incompleteQuestions: result.incompleteQuestions + value.incomplete,
      totalQuestions: result.totalQuestions + value.questions,
    }),
    { answeredQuestions: 0, incompleteQuestions: 0, totalQuestions: 0 },
  )

export const getRiskRatingLabel = (
  riskRating,
  placeholder = '--',
  showAsterisk = false,
) =>
  isNumber(riskRating)
    ? `${riskRating}%${showAsterisk ? '*' : ''}`
    : placeholder

export const getRiskRatingColor = (riskRating, placeholderColor) => {
  if (isNumber(riskRating)) {
    const option = findLast(
      sortedRiskRatingColorOptions,
      (e) => riskRating >= e.value,
    )
    if (option) {
      return option.color
    }
  }
  return placeholderColor
}

export const getRatingLabelSetOption = (ratingLabelSet) =>
  RatingLabelSetOptionsObj[ratingLabelSet] ||
  RatingLabelSetOptionsObj[defaultRatingLabelSet]

export const getResponseScoreLabelByValue = (value, ratingLabelSet) =>
  getRatingLabelSetOption(ratingLabelSet).score_labels[value]

export const getResponseScoreOptionsWithLabel = (
  ratingLabelSet,
  labelFormatter = identity,
) => {
  const ratingLabelOptions =
    getRatingLabelSetOption(ratingLabelSet).score_labels
  const keys = Object.keys(ratingLabelOptions).map((k) => parseInt(k, 10))
  const validOptions = filter(
    ResponseScoreOptions,
    (o) => keys.indexOf(o.value) >= 0,
  )

  return validOptions.map((e) => ({
    ...e,
    label: labelFormatter(
      getResponseScoreLabelByValue(e.value, ratingLabelSet),
    ),
  }))
}

const AssessmentStatusAndScoreCapsule = styled(Capsule)`
  & > *:first-child {
    font-size: 11px;
    width: 94px;

    span {
      flex: 1;
      text-align: center;
    }
  }

  & > *:last-child {
    width: 47px;
  }
`

const LinkWithNoStyle = styled.a`
  text-decoration: none !important;
  color: inherit !important;
`

const AssessmentStatusAndScore = ({ assessment }) => {
  const [canLink] = useAuthorizations(['show', 'Assessment'])
  if (!assessment) {
    return null
  }
  const statusOption = AssessmentStatusOptions.find(
    (e) => e.value === assessment.status,
  )
  const gradeOption = AssessmentGradeOptions.find(
    (e) => e.value === assessment.grade,
  )
  if (!statusOption || !gradeOption) {
    return null
  }
  const content = (
    <AssessmentStatusAndScoreCapsule
      left={
        <>
          <Icon icon={gradeOption?.icon} />
          <span>{statusOption?.label}</span>
        </>
      }
      right={
        assessment.risk_rating !== null ? `${assessment.risk_rating}%` : 'N/A'
      }
      color={statusOption?.color}
    />
  )
  if (canLink) {
    return (
      <LinkWithNoStyle
        href={getPageUrl('clientAssessment', { id: assessment.id })}
      >
        {content}
      </LinkWithNoStyle>
    )
  }
  return content
}
AssessmentStatusAndScore.propTypes = {
  assessment: PropTypes.object,
}

export const assessmentStatusAndScoreFormatterFactory = () => (info) =>
  <AssessmentStatusAndScore assessment={info.getValue()} />

const StatusLabel = styled(Label)`
  width: 80px;
`

export const assessmentStatusFormatterFactory = () => (info) =>
  info.getValue() ? (
    <StatusLabel
      color={get(AssessmentStatusOptionsObj, [info.getValue(), 'color'])}
    >
      {get(AssessmentStatusOptionsObj, [info.getValue(), 'label'])}
    </StatusLabel>
  ) : (
    ''
  )
