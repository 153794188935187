import styled from 'styled-components'
import Label from 'components-v2/atoms/Label'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

export const StyledLabel = styled(Label)`
  margin-left: 30px;
  flex: none;
`
