const AssistAssessmentStatusTypes = {
  UPLOADED: 'uploaded',
  IN_PROCESS: 'in_process',
  IN_REVIEW: 'in_review',
  REVIEWED: 'reviewed',
  COMPLETED: 'completed',
}

export const AssistAssessmentStatusOptions = [
  {
    value: AssistAssessmentStatusTypes.UPLOADED,
    label: 'Uploaded',
  },
  {
    value: AssistAssessmentStatusTypes.IN_PROCESS,
    label: 'In Process',
  },
  {
    value: AssistAssessmentStatusTypes.IN_REVIEW,
    label: 'Ready for Review',
  },
  {
    value: AssistAssessmentStatusTypes.REVIEWED,
    label: 'Review Completed',
  },
  {
    value: AssistAssessmentStatusTypes.COMPLETED,
    label: 'Completed',
  },
]

export default AssistAssessmentStatusTypes
