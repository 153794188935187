import React from 'react'
import PropTypes from 'prop-types'
import { colors } from 'styles'
import { VendorStatusTypes } from 'constants/index'
import { Wrapper, StyledLabel as Label } from './styles'

const getColor = (status) => {
  switch (status) {
    case VendorStatusTypes.ACTIVE:
      return colors.GREEN
    case VendorStatusTypes.ARCHIVED:
      return colors.DANGER
    default:
  }
  return undefined
}

const NameDisplay = ({ value, vendor }) => (
  <Wrapper>
    <span>{value}</span>
    <Label color={getColor(vendor.status)}>{vendor.status.titleize()}</Label>
  </Wrapper>
)

NameDisplay.propTypes = {
  value: PropTypes.string,
  vendor: PropTypes.shape({
    status: PropTypes.string.isRequired,
  }).isRequired,
}

export default NameDisplay
