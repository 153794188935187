import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import CircleMarker from 'components-v2/atoms/CircleMarker'
import { SecurityObjectiveStatusOptions } from '../../constants'

const ObjectiveStatusDot = ({ status, ...rest }) => (
  <CircleMarker
    {...rest}
    color={get(SecurityObjectiveStatusOptions, [status, 'color'])}
  />
)

ObjectiveStatusDot.propTypes = {
  status: PropTypes.string,
}

export default ObjectiveStatusDot
