import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { Row } from 'components-v2/organisms/Layout'
import GradeLabel from 'components/shared/GradeLabel'
import { toLocalTimeString } from 'utils/date'
import FacetableItem from './FacetableItem'
import {
  Set,
  SetTop,
  SetTitle,
  SetStatus,
  SetDate,
  StyledCol as Col,
} from './styles'

const theme = {
  hGutter: 20,
  vGutter: 20,
}

const convertSetStatusToGrade = (status) => {
  if (status === 'passing') {
    return 1
  }
  if (status === 'failing') {
    return -1
  }
  return 0
}

const VendorRiskMeasuresView = ({ data }) => (
  <ThemeProvider theme={theme}>
    {data.map((set) => (
      <Set key={set.id} id={`set-${set.id}`}>
        <SetTop>
          <SetTitle>{set.facet_set_name}</SetTitle>
          <SetStatus>
            <GradeLabel
              grade={convertSetStatusToGrade(set.status)}
              noGradeText="Pending"
            />
          </SetStatus>
        </SetTop>
        {set.created_at && (
          <SetDate>
            Updated: {toLocalTimeString(set.created_at, 'MM/dd/yy')}
          </SetDate>
        )}
        <Row $equalHeight break="mobile">
          {set.facetable_items.map((item) => (
            <Col key={item.id}>
              <FacetableItem data={item} />
            </Col>
          ))}
          {set.open_facet_set_items.map((item) => (
            <Col key={item.id}>
              <FacetableItem data={item} />
            </Col>
          ))}
        </Row>
      </Set>
    ))}
  </ThemeProvider>
)

VendorRiskMeasuresView.propTypes = {
  data: PropTypes.array.isRequired,
}

export default VendorRiskMeasuresView
