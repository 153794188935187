import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import merge from 'lodash/merge'
import pick from 'lodash/pick'
import without from 'lodash/without'
import { useModal } from 'hooks'
import { formatText } from '../utils'
import View from './View'
import IssueStubFormModal from './IssueStubFormModal'

export const ATTRIBUTES_LIST = [
  'label',
  'summary',
  'description',
  'attachments',
  'fileUploads',
  'fileDeletes',
  'priority',
  'issue_category_id',
  'default_delay',
]

const IssueStub = ({
  currentAutomation,
  currentSource,
  mode,
  extras,
  setCurrentSource,
  onOpenSourceForm,
  onLargeTextViewClick,
  onResetSource,
  onClearSource,
}) => {
  const [openFormModal, closeFormModal] = useModal(IssueStubFormModal)

  // STATE HANDLING
  const [stub, setStub] = React.useState(currentSource)
  React.useEffect(() => {
    setStub(currentSource)
  }, [currentSource])

  const [issueCategories, setIssueCategories] = React.useState([])
  React.useEffect(() => {
    setIssueCategories(extras.issueCategories)
  }, [extras.issueCategories])

  const [stubList, setStubList] = React.useState({})
  React.useEffect(() => {
    setStubList(keyBy(extras.issueStubs, 'id'))
  }, [extras.issueStubs])

  const [sourceMode, setSourceMode] = React.useState()
  React.useEffect(() => {
    setSourceMode(mode === 'display' ? 'display' : 'select')
  }, [mode])

  // misc functions

  const getIssueStubSelectOptions = () =>
    map(stubList, (stubItem) => ({ label: stubItem.label, value: stubItem.id }))

  const applyStub = (stubId) => {
    const stubItem = stubList[stubId] || {}
    setCurrentSource(stubItem)
  }

  const handleFormModalUpdate = (formData, srcMode) => {
    let newStub
    // even if srcMode is 'edit', if there's no stub.id
    // then we're still working on a new stub
    if (['add', 'copy'].includes(srcMode) || !stub.id) {
      newStub = formData
    } else if (srcMode === 'edit') {
      newStub = merge(stub, formData)
    }
    setCurrentSource(newStub)
    setSourceMode(srcMode)
  }

  const triggerSourceForm = (sourceParam, srcMode) => {
    const source = sourceParam
    // eslint-disable-next-line camelcase
    source.default_delay = isEmpty(source.default_delay)
      ? 30
      : source.default_delay
    onOpenSourceForm({
      openFormModal,
      closeFormModal,
      label: 'issue template',
      updateCallback: handleFormModalUpdate,
      submitSourceMode: srcMode,
      source,
      issueCategories,
    })
  }

  const handleSourceCreate = () => {
    triggerSourceForm({}, 'add')
  }

  const handleSourceEdit = () => {
    triggerSourceForm(stub, 'edit')
  }

  const handleSourceCopy = () => {
    const source = pick(stub, without(ATTRIBUTES_LIST, 'attachments'))
    source.label += ' (copy)'
    triggerSourceForm(source, 'copy')
  }

  const openBodyDisplayModal = () => {
    onLargeTextViewClick(
      stub.summary,
      stub.description.length < 150
        ? stub.description
        : formatText(stub.description),
    )
  }

  const handleResetSource = () => {
    onResetSource()
    setSourceMode('select')
  }

  // render
  return (
    <View
      sourceMode={sourceMode}
      currentAutomation={currentAutomation}
      stub={stub}
      stubList={stubList}
      issueCategories={issueCategories}
      getIssueStubSelectOptions={getIssueStubSelectOptions}
      handleSourceCreate={handleSourceCreate}
      handleSourceEdit={handleSourceEdit}
      handleSourceCopy={handleSourceCopy}
      handleSourceReset={handleResetSource}
      handleSourceClear={onClearSource}
      applyStub={applyStub}
      openBodyDisplayModal={openBodyDisplayModal}
    />
  )
}

IssueStub.propTypes = {
  currentAutomation: PropTypes.object.isRequired,
  currentSource: PropTypes.object,
  mode: PropTypes.string.isRequired,
  extras: PropTypes.object,
  setCurrentSource: PropTypes.func.isRequired,
  onOpenSourceForm: PropTypes.func.isRequired,
  onLargeTextViewClick: PropTypes.func.isRequired,
  onResetSource: PropTypes.func.isRequired,
  onClearSource: PropTypes.func.isRequired,
}

export default IssueStub
