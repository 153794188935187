import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import FormField from 'components-v2/molecules/FormField'
import CredentialsField from '../CredentialsField'

const validationSchema = yup.object().shape({
  credentials: yup
    .string()
    .nullable()
    .test(
      'is-valid',
      'Please enter API Key to enable this partner integration',
      function callback(value) {
        return (
          !this.parent.enabled ||
          value ||
          (this.parent.credentials_ending_in && value === null)
        )
      },
    ),
})

const toFormValues = (partner) => ({
  credentials: null,
  credentials_ending_in: partner.credentials_ending_in,
  enabled: partner.enabled,
})

const fromFormValues = (formValues) => {
  const obj = {
    enabled: formValues.enabled,
  }
  // Only if changed, send to server
  if (formValues.credentials !== null) {
    obj.credentials = formValues.credentials
  }
  return obj
}

const Default = ({ data }) => (
  <FormField
    component={CredentialsField}
    name="credentials"
    label="API Key"
    ending={data.credentials_ending_in}
  />
)

Default.propTypes = {
  data: PropTypes.object.isRequired,
}

Default.validationSchema = validationSchema
Default.toFormValues = toFormValues
Default.fromFormValues = fromFormValues

export default Default
