import React from 'react'
import PropTypes from 'prop-types'
import { useQueryParams } from 'hooks'
import { withReactQuery } from 'hocs'
import View from './View'

const Container = ({ libraryProfile, perms, requestableProjects }) => {
  const [{ tab }, setQueryParams] = useQueryParams()
  const handleTabSelect = React.useCallback(
    (e) => setQueryParams({ tab: e }, true),
    [setQueryParams],
  )

  return (
    <View
      libraryProfile={libraryProfile}
      perms={perms}
      requestableProjects={requestableProjects}
      activeTab={tab}
      onTabSelect={handleTabSelect}
    />
  )
}

Container.propTypes = {
  libraryProfile: PropTypes.object.isRequired,
  perms: PropTypes.object.isRequired,
  requestableProjects: PropTypes.array,
}

export default withReactQuery()(Container)
