import React from 'react'
import AuthManager, { AuthorizationContext } from 'lib/authorizationContext'

const withAuthorize = () => (AuthorizedParentComponent) =>
  React.forwardRef((compProps, ref) => {
    const { userPolicy } = compProps
    const authManager = React.useMemo(
      () => new AuthManager(userPolicy ?? {}),
      [userPolicy],
    )
    return (
      <AuthorizationContext.Provider value={authManager}>
        <AuthorizedParentComponent
          authManager={authManager}
          ref={ref}
          {...compProps}
        />
      </AuthorizationContext.Provider>
    )
  })

export default withAuthorize
