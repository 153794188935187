import React from 'react'
import differenceBy from 'lodash/differenceBy'
import get from 'lodash/get'
import Privva from 'Privva'
import AutoLink from 'components-v2/atoms/AutoLink'
import Tooltip from 'components-v2/molecules/Tooltip'
import { colors } from 'styles'
import { dateFormatterFactory } from 'lib/table'
import { vendorRiskTierFormatterFactory } from 'lib/vendor'
import {
  riskReconScoreFormatterFactory,
  osanoScoreFormatterFactory,
  ofacFormatterFactory,
} from 'components/client/lib/globorg_integration_table'
import { assessmentStatusAndScoreFormatterFactory } from 'lib/assessment'
import { LabelTypes } from 'constants/index'
import { RequestOpts, COL_NAMES } from './constants'
import { Circle, TierLabel } from './styles'

const dateFormatter = dateFormatterFactory()
const riskTierFormatter = vendorRiskTierFormatterFactory()
const riskReconScoreFormatter = riskReconScoreFormatterFactory()
const osanoScoreFormatter = osanoScoreFormatterFactory()
const ofacFormatter = ofacFormatterFactory()
const assessmentStatusAndScoreFormatter =
  assessmentStatusAndScoreFormatterFactory()
const ofacTooltip = `Information provided here is on a best effort to match vendors by names, addresses, websites etc. Due to a relatively broad data set and generic aliases, false hits may be generated. ${Privva.Utils.brand.programName} recommends all results should be confirmed with The Office of Foreign Assets Control ("OFAC") -https://home.treasury.gov/policy-issues/financial-sanctions/sanctions-list-search-tool`

const preVendorStatusOpts = {
  Requested: {
    sort: 0,
    color: colors.GREEN,
  },
  Draft: {
    sort: 1,
    color: colors.BODY_TEXT,
  },
  Declined: {
    sort: 2,
    color: colors.GREY,
  },
}

const statusRow = (status, text) => {
  const localText = text || status
  return status === 'Declined' ? (
    <span style={{ color: preVendorStatusOpts[status].color }}>
      {localText}
    </span>
  ) : (
    localText
  )
}

const statusLabel = (status) =>
  status === 'Requested' ? (
    <TierLabel color={preVendorStatusOpts[status].color}>New</TierLabel>
  ) : (
    ''
  )

export const preVendorTableColumns = [
  {
    id: COL_NAMES.NAME,
    header: LabelTypes.VENDOR,
    cell: (info) => (
      <>
        {statusRow(info.row.original.status, info.getValue())}{' '}
        {statusLabel(info.row.original.status)}
      </>
    ),
    width: '30%',
  },
  // {
  //   id: COL_NAMES.RISK_TIER,
  //   header: 'Risk Tier',
  //   cell: riskTierFormatter,
  //   width: '10%',
  // },
  {
    id: COL_NAMES.STATUS,
    header: 'Status',
    cell: (info) => statusRow(info.row.original.status),
    width: '20%',
  },
  {
    id: COL_NAMES.REQUESTER_NAME,
    header: 'Requested by',
    cell: (info) => statusRow(info.row.original.status, info.getValue() || ' '),
  },
  {
    id: COL_NAMES.USER_COUNT,
    header: 'Contacts',
    cell: (info) => (info.getValue() ? <Circle>{info.getValue()}</Circle> : ''),
    enableSorting: false,
    width: '10%',
  },
  {
    id: COL_NAMES.FILE_COUNT,
    header: 'Attachments',
    cell: (info) => (info.getValue() ? <Circle>{info.getValue()}</Circle> : ''),
    enableSorting: false,
    width: '10%',
  },
  {
    id: COL_NAMES.CREATED_AT,
    header: 'Requested on',
    cell: dateFormatter,
    width: '10%',
  },
]

export const vendorTableColumns = [
  {
    id: COL_NAMES.NAME,
    header: LabelTypes.VENDOR,
    width: '25%',
  },
  {
    id: COL_NAMES.PARENT_ORG,
    header: `Parent ${LabelTypes.VENDOR}`,
    cell: (info) =>
      info.getValue() ? get(info.row.original, 'parent_org.name') : '',
    width: '19%',
    hidden: true,
  },
  {
    id: COL_NAMES.RISK_TIER,
    header: 'Risk Tier',
    cell: riskTierFormatter,
    width: '10%',
  },
  {
    id: COL_NAMES.LAST_ASSESSMENT,
    header: 'Last Assessment',
    cell: assessmentStatusAndScoreFormatter,
    enableSorting: false,
    width: '15%',
  },
  {
    id: COL_NAMES.LAST_ASSESSMENT_AT,
    header: 'Assessed',
    cell: dateFormatter,
    enableSorting: false,
    width: '15%',
  },
  {
    id: COL_NAMES.ASSESSMENT_COUNT,
    header: 'Assessments',
    cell: (info) => (info ? <Circle>{info.getValue()}</Circle> : ''),
    enableSorting: false,
    width: '10%',
  },
  {
    id: COL_NAMES.RISK_RECON_SCORE,
    header: 'RiskRecon Score',
    accessorFn: (row) => row.metrics?.risk_recon,
    cell: riskReconScoreFormatter,
    enableSorting: false,
    width: '10%',
  },
  {
    id: COL_NAMES.OSANO_SCORE,
    header: 'Osano Score',
    accessorFn: (row) => row.metrics?.osano,
    cell: osanoScoreFormatter,
    enableSorting: false,
    width: '10%',
  },
  {
    id: COL_NAMES.OFAC_MATCH,
    header: (
      <span>
        OFAC&nbsp;
        <Tooltip>
          <AutoLink text={ofacTooltip} />
        </Tooltip>
      </span>
    ),
    accessorFn: (row) => row.metrics?.ofac,
    cell: ofacFormatter,
    enableSorting: false,
    minWidth: 160,
  },
  {
    id: COL_NAMES.ISSUES_COUNT,
    header: 'Open Issues',
    cell: (info) =>
      info.getValue() ? (
        <Circle color={colors.RED}>{info.getValue()}</Circle>
      ) : (
        ''
      ),
    enableSorting: false,
    width: '10%',
  },
  {
    id: COL_NAMES.NEXT_REASSESSMENT_AT,
    header: 'Next Assessment',
    cell: dateFormatter,
    width: '15%',
  },
  {
    id: COL_NAMES.USER_COUNT,
    header: 'Users',
    cell: (info) => (info.getValue() ? <Circle>{info.getValue()}</Circle> : ''),
    enableSorting: false,
    width: '10%',
    hidden: true,
  },
  {
    id: COL_NAMES.CREATED_AT,
    header: 'Created',
    cell: dateFormatter,
    width: '10%',
    hidden: true,
  },
]

const columnSet = {
  pre_vendors: preVendorTableColumns,
  vendors: vendorTableColumns,
}

export function getVendorTableColumns(requests, canShowFull) {
  const screenedCols = [
    { id: 'risk_recon' },
    { id: 'osano' },
    { id: 'ofac' },
    // { id: 'issues_count' },
  ]
  const allCols = columnSet[RequestOpts[requests].columnSet]
  const filtered = canShowFull
    ? allCols
    : differenceBy(allCols, screenedCols, 'id')
  return filtered
}
