import React from 'react'
import * as yup from 'yup'
import Icon from 'components-v2/atoms/Icon'
import FormField from 'components-v2/molecules/FormField'
import FormDialog from 'components-v2/organisms/FormDialog'
import { getPageUrl } from 'utils/url'
import { ExportFormatTypes } from 'constants/index'
import ErrorMessage from './ErrorMessage'

const formConfig = {
  validationSchema: yup.object().shape({
    file: yup.mixed().required('A file is required'),
  }),
  initialValues: {
    file: undefined,
  },
}

const fileControlProps = {
  accept: '.csv',
  buttonContent: [<Icon key="icon" icon="fa fa-cloud-upload" />, 'Upload file'],
}

const sampleFileUrl = getPageUrl(
  'clientVendorsBulkImportSample',
  undefined,
  undefined,
  ExportFormatTypes.CSV,
)

const ImportVendorsModal = (props) => (
  <FormDialog
    {...props}
    title="Import Vendors"
    submitButtonLabel="Import"
    formConfig={formConfig}
  >
    <FormField
      name="file"
      label="Select file"
      type={FormField.types.FILE_INPUT}
      controlProps={fileControlProps}
      renderErrorMessage={ErrorMessage}
      horizontal
    />
    <p>
      To import vendors, upload a csv file in the correct format. The csv
      template can be downloaded here:{' '}
      <a href={sampleFileUrl} target="_blank">
        vendor import template
      </a>
    </p>
  </FormDialog>
)

export default ImportVendorsModal
