import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import filter from 'lodash/filter'
import FilterButtonGroup from 'components-v2/molecules/FilterButtonGroup'
import { Row } from 'components-v2/organisms/Layout'
import PageHeader from 'components-v2/organisms/PageHeader'
import SummaryCard from './SummaryCard'
import ObjectiveCard from './ObjectiveCard'
import RiskRatingLabel from '../RiskRatingLabel'
import {
  getLastActiveTime,
  getToolStatus,
  getCategoryRiskRating,
  getCategoryUpdatedAt,
} from './utils'
import {
  ViewTypes,
  ViewTypeFilterOptions,
  StatusFilterOptions,
} from './constants'
import {
  Wrapper,
  Filters,
  FilterByLabel,
  FilterSeparator,
  StyledSelect,
  ColTitle,
  StyledCol as Col,
} from './styles'

const parseObjectives = (objectivesByCategory) =>
  map(objectivesByCategory, (objectives, category) => {
    const newObjectives = objectives.map(({ source, ...rest }) => {
      if (source) {
        const lastActiveTime = getLastActiveTime(source.metrics)
        const status = getToolStatus(source)
        return {
          ...rest,
          tool: {
            ...source,
            lastActiveTime,
            status,
          },
        }
      }
      return rest
    })
    const riskRating = getCategoryRiskRating(newObjectives)
    const updatedAt = getCategoryUpdatedAt(newObjectives)
    return { category, objectives: newObjectives, riskRating, updatedAt }
  })

const filterObjectives = (collections, statusFilter) =>
  collections.map((collection) => {
    const filteredObjectives = statusFilter
      ? filter(collection.objectives, { status: statusFilter })
      : collection.objectives
    return { ...collection, filteredObjectives }
  })

const SecuritySenseDashboard = ({
  title,
  objectivesByCategory,
  loading,
  onConnect,
  ...rest
}) => {
  const [viewTypeFilter, setViewTypeFilter] = React.useState({
    [ViewTypes.LIST]: true,
  })
  const [statusFilter, setStatusFilter] = React.useState(null)

  const viewType = Object.keys(viewTypeFilter)[0]
  const objectives = React.useMemo(
    () => filterObjectives(parseObjectives(objectivesByCategory), statusFilter),
    [objectivesByCategory, statusFilter],
  )
  return (
    <Wrapper {...rest}>
      <PageHeader title={title}>
        <Filters>
          {viewType === ViewTypes.GRID && (
            <>
              <FilterByLabel>Filter by:</FilterByLabel>
              <div>
                <StyledSelect
                  value={statusFilter || ''}
                  onChange={(e) => setStatusFilter(e.currentTarget.value)}
                >
                  {map(StatusFilterOptions, (option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </StyledSelect>
              </div>
              <FilterSeparator />
            </>
          )}
          <FilterButtonGroup
            selected={viewTypeFilter}
            filterOptions={ViewTypeFilterOptions}
            selectAllVisible={false}
            selectedButtonColor="primary"
            defaultButtonVariant="outlined"
            onChange={setViewTypeFilter}
          />
        </Filters>
      </PageHeader>
      {!loading && viewType === ViewTypes.GRID && (
        <Row>
          {objectives.map((collection) => (
            <Col key={collection.category} md={4}>
              <ColTitle>
                {collection.category}{' '}
                <RiskRatingLabel value={collection.riskRating} />
              </ColTitle>
              {collection.filteredObjectives.map((objective) => (
                <ObjectiveCard
                  key={objective.id}
                  objective={objective}
                  onConnect={onConnect}
                />
              ))}
            </Col>
          ))}
        </Row>
      )}
      {!loading && viewType === ViewTypes.LIST && (
        <Row $equalHeight>
          {objectives.map((collection) => (
            <Col key={collection.category} md={4}>
              <ColTitle>
                {collection.category}{' '}
                <RiskRatingLabel value={collection.riskRating} />
              </ColTitle>
              <SummaryCard collection={collection} onConnect={onConnect} />
            </Col>
          ))}
        </Row>
      )}
    </Wrapper>
  )
}

SecuritySenseDashboard.propTypes = {
  title: PropTypes.string.isRequired,
  objectivesByCategory: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  onConnect: PropTypes.func,
}

export default SecuritySenseDashboard
