import PropTypes from 'prop-types'
import styled from 'styled-components'

// Let's support fullWidth prop
export default (WrappedComponent) => {
  const WrapperComponent = styled(WrappedComponent)`
    ${(p) =>
      p.fullWidth &&
      `
      display: block;
      .react-datepicker-wrapper {
        display: block;
      }
    `}
  `

  WrapperComponent.propTypes = {
    fullWidth: PropTypes.bool,
  }

  return WrapperComponent
}
