import styled from 'styled-components'

export default styled.div`
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  min-height: 20px;
  padding: 19px;

  ${({ size }) =>
    ['sm', 'small'].includes(size) &&
    `
    border-radius: 3px;
    padding: 9px;
  `}

  ${({ size }) =>
    ['lg', 'large'].includes(size) &&
    `
    border-radius: 6px;
    padding: 24px;
  `}
`
