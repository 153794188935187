export default {
  // Breakpoint
  TABLET_MAX_WIDTH: 991,
  MOBILE_MAX_WIDTH: 767,

  // Grid
  GRID_HGUTTER: 22, // Partially used in new design

  // Extra
  HEADER_HEIGHT: 55,
}
