import React from 'react'
import PropTypes from 'prop-types'
import merge from 'lodash/merge'
import map from 'lodash/map'
import { Sticky, StickyContainer } from 'react-sticky'
import { LabelTypes } from 'constants/index'
import { useAuthorizations } from 'hooks'
import { sizes } from 'styles'
import { getResponseScoreOptionsWithLabel } from 'lib/assessment'
import Button from 'components-v2/atoms/Button'
import Checkbox from 'components-v2/atoms/Checkbox'
import ButtonGroup from 'components-v2/molecules/ButtonGroup'
import Editable from 'components-v2/molecules/Editable'
import Tooltip from 'components-v2/molecules/Tooltip'
import ToolbarGroup from 'components-v2/molecules/ToolbarGroup'
import MenuDropdown from 'components-v2/molecules/MenuDropdown'
import PageHeader from './PageHeader'
import TemplateEditTable, { Thead } from './TemplateEditTable'
import StatusLabel from './StatusLabel'
import RatingLabelSetLink from './RatingLabelSetLink'
import { getQuestionsFromElements } from './utils'
import {
  BalanceScaleIcon,
  CopyIcon,
  EyeIcon,
  GlobalLabel,
  KeyIcon,
  OutdentIcon,
  PencilIcon,
  QuestionIcon,
  StickyContent,
  TableInfo,
  TemplateAttributeList,
  Title,
  TopInfoSection,
  TrashIcon,
  Wrapper,
} from './styles'

const AssessmentTemplateView = ({
  elements,
  assessmentTemplate,
  isNew,
  archivable,
  readyable,
  unreadyable,
  isSectionSelectionMode,
  selectedSectionId,
  selectedQuestionIds,
  elementIdsWithSkipConditions,
  actionButtonOptions,
  apiClientAssessmentTemplatePath,
  onEditRatingLabelSet,
  onElementFieldChange,
  onElementMove,
  onElementEdit,
  onElementDelete,
  onElementEditSkipConditions,
  onElementQuestionAutomationEdit,
  onViewQuestionAttachmentsClick,
  onElementAutoScoreChange,
  onUseCustomKeysButtonClick,
  onEditWeightsButtonClick,
  onSelectedQuestionIdsChange,
  onToggleSectionSelection,
  onAddSectionButtonClick,
  onAddQuestionButtonClick,
  onBulkEditButtonClick,
  onBulkMoveOrCopyButtonClick,
  onBulkDeleteButtonClick,
  onBulkAddSkipConditionsButtonClick,
  onBulkRemoveSkipConditionsButtonClick,
  onArchiveButtonClick,
  onReadyButtonClick,
  onUnreadyButtonClick,
  onExcelExportButtonClick,
  onDuplicateButtonClick,
  onAssessmentAttributeChange,
}) => {
  const [userCanEdit] = useAuthorizations('update')
  const editable = !assessmentTemplate.readonly && userCanEdit
  const questionCount = React.useMemo(
    () => getQuestionsFromElements(elements).length,
    [elements],
  )
  const actionButtons = merge(
    {
      bulkEdit: {
        onClick: onBulkEditButtonClick,
        icon: PencilIcon,
        label: 'Bulk Edit',
      },
      moveOrCopy: {
        onClick: onBulkMoveOrCopyButtonClick,
        icon: CopyIcon,
        label: 'Move or Copy',
      },
      addOrReplaceSkipConditions: {
        onClick: onBulkAddSkipConditionsButtonClick,
        icon: EyeIcon,
        label: 'Add/Replace Question Skip Conditions',
      },
      removeSkipConditions: {
        onClick: onBulkRemoveSkipConditionsButtonClick,
        icon: EyeIcon,
        label: 'Remove Question Skip Conditions',
      },
      delete: {
        onClick: onBulkDeleteButtonClick,
        icon: TrashIcon,
        label: 'Delete',
      },
    },
    actionButtonOptions,
  )
  const autoScoreOptions = React.useMemo(
    () =>
      getResponseScoreOptionsWithLabel(
        assessmentTemplate.rating_label_set,
        (label) => `Auto Score: ${label}`,
      ),
    [assessmentTemplate.rating_label_set],
  )
  return (
    <Wrapper>
      <PageHeader
        pageTitle={assessmentTemplate.name}
        sub={
          assessmentTemplate.is_global ? (
            <GlobalLabel>Global Template</GlobalLabel>
          ) : undefined
        }
        editable={editable}
        isNew={isNew}
        archivable={archivable}
        readyable={readyable}
        unreadyable={unreadyable}
        apiPath={apiClientAssessmentTemplatePath}
        onPageTitleChanged={(value) =>
          onAssessmentAttributeChange('name', value)
        }
        onDuplicateButtonClick={onDuplicateButtonClick}
        onArchiveButtonClick={onArchiveButtonClick}
        onReadyButtonClick={onReadyButtonClick}
        onUnreadyButtonClick={onUnreadyButtonClick}
        onExcelExportButtonClick={onExcelExportButtonClick}
      />
      <TopInfoSection>
        <dt>
          Summary <Tooltip position="right">Short internal overview</Tooltip>
        </dt>
        <dd>
          <Editable
            value={assessmentTemplate.summary}
            dataType="textarea"
            mode="popup"
            emptytext="Summary"
            placeholder="Summary"
            title="Edit Summary"
            disabled={!editable}
            inputProps={{ maxLength: '255' }}
            onSubmit={(value) => onAssessmentAttributeChange('summary', value)}
          />
        </dd>
        <dt>
          Description{' '}
          <Tooltip position="right">
            External facing details or instructions
          </Tooltip>
        </dt>
        <dd>
          <Editable
            value={assessmentTemplate.description}
            dataType="textarea"
            mode="popup"
            emptytext="Description"
            placeholder="Description"
            title="Edit Description"
            disabled={!editable}
            inputProps={{ maxLength: '5000' }}
            onSubmit={(value) =>
              onAssessmentAttributeChange('description', value)
            }
          />
        </dd>
        {assessmentTemplate.language !== 'en' && (
          <>
            <dt>Language</dt>
            <dd>{assessmentTemplate.language_name}</dd>
          </>
        )}
        <dt>
          <StatusLabel status={assessmentTemplate.status} />
        </dt>
        <dd>
          <TemplateAttributeList>
            <div>
              <Checkbox
                checked={assessmentTemplate.allow_comments}
                label="Comments Allowed"
                disabled={!editable}
                onChange={(e) =>
                  onAssessmentAttributeChange(
                    'allow_comments',
                    e.target.checked,
                  )
                }
                inline
              />
            </div>
            <div>
              <Checkbox
                checked={assessmentTemplate.allow_attachments}
                label="Attachments Allowed"
                disabled={!editable}
                onChange={(e) =>
                  onAssessmentAttributeChange(
                    'allow_attachments',
                    e.target.checked,
                  )
                }
                inline
              />
            </div>
            {!editable && assessmentTemplate.industry_assessment_id && (
              <div>
                {/* basic placeholder, probably want to revisit UI/UX */}
                <Checkbox
                  checked={assessmentTemplate.allow_vendor_imports}
                  label={`${LabelTypes.VENDOR} Response Imports Allowed`}
                  disabled={editable}
                  onChange={(e) =>
                    onAssessmentAttributeChange(
                      'allow_vendor_imports',
                      e.target.checked,
                    )
                  }
                  inline
                />
              </div>
            )}
          </TemplateAttributeList>
        </dd>
        <dt>Rating Labels</dt>
        <dd>
          <RatingLabelSetLink
            disabled={!editable}
            value={assessmentTemplate.rating_label_set}
            onSave={onEditRatingLabelSet}
          />
        </dd>
      </TopInfoSection>
      <StickyContainer>
        <Sticky topOffset={-sizes.HEADER_HEIGHT} disableHardwareAcceleration>
          {({ isSticky, style }) => (
            <StickyContent isSticky={isSticky} style={style}>
              <ToolbarGroup justifyContent="space-between">
                {editable ? <Title>Edit Template</Title> : <span />}
                <ToolbarGroup>
                  {userCanEdit && (
                    <Button onClick={onUseCustomKeysButtonClick}>
                      <KeyIcon active={assessmentTemplate.use_custom_keys} />
                      Use Custom Keys
                    </Button>
                  )}
                  <ButtonGroup>
                    {userCanEdit && (
                      <Button onClick={onEditWeightsButtonClick}>
                        <BalanceScaleIcon />
                        Edit Weights
                      </Button>
                    )}
                    {editable && (
                      <>
                        <Button onClick={onAddSectionButtonClick}>
                          <OutdentIcon />
                          Add Section
                        </Button>
                        <Button onClick={onAddQuestionButtonClick}>
                          <QuestionIcon />
                          Add Question
                        </Button>
                        <MenuDropdown title="Actions">
                          {map(actionButtons, (button, key) => {
                            const Icon = button.icon
                            return (
                              <MenuDropdown.Item
                                key={key}
                                disabled={button.disabled}
                                onClick={button.onClick}
                              >
                                <Icon />
                                {button.label}
                              </MenuDropdown.Item>
                            )
                          })}
                        </MenuDropdown>
                      </>
                    )}
                  </ButtonGroup>
                </ToolbarGroup>
              </ToolbarGroup>
              <Thead
                editable={editable}
                useCustomKeys={assessmentTemplate.use_custom_keys}
              />
            </StickyContent>
          )}
        </Sticky>
        <TemplateEditTable
          elements={elements}
          elementIdsWithSkipConditions={elementIdsWithSkipConditions}
          isSectionSelectionMode={isSectionSelectionMode}
          selectedSectionId={selectedSectionId}
          selectedQuestionIds={selectedQuestionIds}
          editable={editable}
          useCustomKeys={assessmentTemplate.use_custom_keys}
          autoScoreOptions={autoScoreOptions}
          onElementFieldChange={onElementFieldChange}
          onElementMove={onElementMove}
          onElementEdit={onElementEdit}
          onElementDelete={onElementDelete}
          onElementEditSkipConditions={onElementEditSkipConditions}
          onElementQuestionAutomationEdit={onElementQuestionAutomationEdit}
          onViewQuestionAttachmentsClick={onViewQuestionAttachmentsClick}
          onElementAutoScoreChange={onElementAutoScoreChange}
          onSelectedQuestionIdsChange={onSelectedQuestionIdsChange}
          onToggleSectionSelection={onToggleSectionSelection}
        />
        <TableInfo>
          Showing 1 to {questionCount} of {questionCount} questions
        </TableInfo>
      </StickyContainer>
    </Wrapper>
  )
}

AssessmentTemplateView.propTypes = {
  elements: PropTypes.array.isRequired,
  assessmentTemplate: PropTypes.object.isRequired,
  isNew: PropTypes.bool,
  archivable: PropTypes.bool,
  readyable: PropTypes.bool,
  unreadyable: PropTypes.bool,
  isSectionSelectionMode: PropTypes.bool,
  selectedSectionId: PropTypes.string,
  selectedQuestionIds: PropTypes.array.isRequired,
  elementIdsWithSkipConditions: PropTypes.array.isRequired,
  actionButtonOptions: PropTypes.object.isRequired,
  apiClientAssessmentTemplatePath: PropTypes.string.isRequired,
  onEditRatingLabelSet: PropTypes.func.isRequired,
  onElementFieldChange: PropTypes.func.isRequired,
  onElementMove: PropTypes.func.isRequired,
  onElementEdit: PropTypes.func.isRequired,
  onElementDelete: PropTypes.func.isRequired,
  onElementEditSkipConditions: PropTypes.func.isRequired,
  onElementQuestionAutomationEdit: PropTypes.func.isRequired,
  onViewQuestionAttachmentsClick: PropTypes.func.isRequired,
  onElementAutoScoreChange: PropTypes.func.isRequired,
  onSelectedQuestionIdsChange: PropTypes.func.isRequired,
  onUseCustomKeysButtonClick: PropTypes.func.isRequired,
  onEditWeightsButtonClick: PropTypes.func.isRequired,
  onToggleSectionSelection: PropTypes.func.isRequired,
  onAddSectionButtonClick: PropTypes.func.isRequired,
  onAddQuestionButtonClick: PropTypes.func.isRequired,
  onBulkEditButtonClick: PropTypes.func.isRequired,
  onBulkMoveOrCopyButtonClick: PropTypes.func.isRequired,
  onBulkDeleteButtonClick: PropTypes.func.isRequired,
  onBulkAddSkipConditionsButtonClick: PropTypes.func.isRequired,
  onBulkRemoveSkipConditionsButtonClick: PropTypes.func.isRequired,
  onArchiveButtonClick: PropTypes.func.isRequired,
  onReadyButtonClick: PropTypes.func.isRequired,
  onUnreadyButtonClick: PropTypes.func.isRequired,
  onExcelExportButtonClick: PropTypes.func.isRequired,
  onDuplicateButtonClick: PropTypes.func.isRequired,
  onAssessmentAttributeChange: PropTypes.func.isRequired,
}

export default AssessmentTemplateView
