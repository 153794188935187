import React from 'react'
import PropTypes from 'prop-types'
import Dialog from 'components-v2/molecules/Dialog'
import styled from 'styled-components'
import A from 'components-v2/atoms/A'
import Button from 'components-v2/atoms/Button'
import Alert from 'components-v2/molecules/Alert'
import Tooltip from 'components-v2/molecules/Tooltip'
import { Row, Col } from 'components-v2/organisms/Layout'
import { useReinviteClientVendorUser } from 'apis'

export const CenteredRow = styled(Row)`
  text-align: center;
`

export const P = styled.p`
  margin-top: 15px;
`

const ResendInviteModal = ({ user, vendorId }) => {
  const { mutateAsync: reinviteClientVendorUser } =
    useReinviteClientVendorUser()
  const invitable = user.shareable_invitation_url && !user.registered
  const sendText = !user.registered && !user.invited ? 'Send' : 'Resend'
  const [showModal, setShowModal] = React.useState(false)
  const [copySuccess, setCopySuccess] = React.useState(false)
  const [sendSuccess, setSendSuccess] = React.useState(false)
  const [error, setError] = React.useState(false)
  const textAreaRef = React.useRef()

  const open = React.useCallback(() => {
    setShowModal(true)
  }, [])

  const close = React.useCallback(() => {
    setShowModal(false)
    setError(false)
    setCopySuccess(false)
  }, [])

  const resendEmail = React.useCallback(() => {
    reinviteClientVendorUser({ vendorId, userId: user.id })
      .then(() => setSendSuccess(true))
      .catch((err) => {
        if (err.response.status === 422) {
          setError(err.response.data.error)
        } else {
          setError('Error resending invite.')
        }
      })
  }, [vendorId, user.id])

  const copyToClipboard = React.useCallback(() => {
    textAreaRef.current.select()
    document.execCommand('copy')
    setCopySuccess(true)
    setTimeout(() => setCopySuccess(false), 2000)
  }, [])

  const dismissError = React.useCallback(() => {
    setError(false)
  }, [])

  return (
    <>
      <Dialog open={showModal} onClose={close}>
        <Dialog.Header onClose={close}>{sendText} Invite</Dialog.Header>
        <Dialog.Content>
          <CenteredRow>
            <Col md={5}>
              <Button
                color="primary"
                disabled={sendSuccess}
                onClick={resendEmail}
              >
                <i className="fa fa-fw fa-envelope" />
                {sendText} via Email
              </Button>
              <P>{`${sendText} the invite to the user's email address. `}</P>
            </Col>
            <Col md={2}>or</Col>
            <Col md={5}>
              <Tooltip
                active={copySuccess}
                position="bottom"
                parent={
                  <Button color="primary" onClick={copyToClipboard}>
                    <i className="fa fa-fw fa-clipboard" />
                    Copy URL
                  </Button>
                }
              >
                Copied to clipboard!
              </Tooltip>
              <P>Copy the URL to your clipboard and send on your own.</P>
              {showModal && (
                <textarea
                  ref={textAreaRef}
                  style={{ position: 'fixed', top: '-1000px' }}
                  defaultValue={user.shareable_invitation_url}
                />
              )}
            </Col>
          </CenteredRow>
          <CenteredRow>
            <Col md={12}>
              {error && (
                <Alert color="danger" onDismiss={dismissError}>
                  {error}
                </Alert>
              )}
              {sendSuccess && (
                <Alert color="success">
                  The email invitation has been sent to the user.
                </Alert>
              )}
            </Col>
          </CenteredRow>
        </Dialog.Content>
        <Dialog.Footer>
          <Button onClick={close}>Close</Button>
        </Dialog.Footer>
      </Dialog>
      {invitable && !sendSuccess && (
        <A useGrey onClick={open} underline>
          {sendText} Invite
        </A>
      )}
      {sendSuccess && <span>Sent!</span>}
    </>
  )
}

ResendInviteModal.propTypes = {
  vendorId: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
}

export default ResendInviteModal
