import { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import {
  useClientAssessmentsFetcher,
  useClientProjectProjectVendorsFetcher,
} from 'apis'
import useApproveProjectVendorExtensionRequest from 'components/client/shared/useApproveProjectVendorExtensionRequest'

export default () => {
  const queryClient = useQueryClient()
  const approveProjectVendorExtensionRequest =
    useApproveProjectVendorExtensionRequest()
  return useCallback(
    (projectVendor) => {
      approveProjectVendorExtensionRequest({
        endAt: projectVendor.date_range.end_at,
        requestedAt: projectVendor.extension_requested_at,
        minutesRequested: projectVendor.extension_minutes_requested,
        projectVendorId: projectVendor.id,
        onSuccess: () => {
          queryClient.invalidateQueries(
            useClientProjectProjectVendorsFetcher.queryKey(),
          )
          queryClient.invalidateQueries(useClientAssessmentsFetcher.queryKey())
        },
      })
    },
    [approveProjectVendorExtensionRequest],
  )
}
