/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import filter from 'lodash/filter'
import intersection from 'lodash/intersection'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import keys from 'lodash/keys'
import maxBy from 'lodash/maxBy'
import minBy from 'lodash/minBy'
import pick from 'lodash/pick'
import reduce from 'lodash/reduce'
import toNumber from 'lodash/toNumber'
import without from 'lodash/without'
import xor from 'lodash/xor'
import SimpleDialog from 'components-v2/molecules/SimpleDialog'
import {
  ResponseScoreOptions,
  AssessmentElementTypes,
  AssessmentTemplateStatusTypes,
} from 'constants/index'
import { useAuthorizations, useModal, useConfirm } from 'hooks'
import {
  useClientIssueCategoriesFetcher,
  useCreateClientIssueCategory,
  useClientIssueStubsFetcher,
  useClientClientSourceContractsFetcher,
  useUpdateClientIssueStub,
} from 'apis'
import objectToFormData from 'utils/object_to_formdata'
import View from './View'
import { ATTRIBUTES_LIST as StubAttributes } from './AutomationSourceDisplay/IssueStub'
import { ATTRIBUTES_LIST as ClauseAttributes } from './AutomationSourceDisplay/AutoReport'
import LargeTextDisplayModal from './AutomationSourceDisplay/LargeTextDisplayModal'

const maxScore = maxBy(ResponseScoreOptions, 'value').value
const minScore = minBy(ResponseScoreOptions, 'value').value

const QuestionAutomationModal = ({
  element,
  templateId,
  templateStatus,
  ratingLabelSet,
  handleAsyncAction,
  createClientQuestionAutomation,
  updateClientQuestionAutomation,
  deleteClientQuestionAutomation,
  onClose,
  ...rest
}) => {
  const { mutateAsync: createClientIssueCategory } =
    useCreateClientIssueCategory()
  const { mutateAsync: updateClientIssueStub } = useUpdateClientIssueStub()
  const [userCanEdit] = useAuthorizations('update')
  const modeLabels = {
    add: 'Save',
    display: 'Edit',
    edit: 'Save Automation Changes',
    list: 'Add new automation',
  }

  const newAutomation = () => ({
    element_id: currentElement.id,
    trigger_type:
      currentElement.type === AssessmentElementTypes.TEXT_QUESTION
        ? 'score'
        : 'choices',
    score: 0,
    choices: [],
    attachment_check: false,
    comment_check: false,
  })

  const indexAutomationList = (autoList) => {
    const tmp = reduce(
      autoList,
      (res, val) => {
        const type = val.automation_source_type
        const groupKey = val.group_key
        const group = res[groupKey] || { count: 0 }
        group[type] = val
        group.count += 1
        group.primary = group.issue_stub || group.clause
        group.primary_type =
          group.primary && group.primary.automation_source_type
        res[groupKey] = group
        return res
      },
      {},
    )
    // log.debug('QA list indexed:', tmp);
    return tmp
  }

  const extractCleanStub = (stubAuto) => {
    const sourceStub = stubAuto && stubAuto.automation_source
    return sourceStub
      ? pick(sourceStub, ['id', 'client_id', ...StubAttributes])
      : {}
  }
  const extractCleanClause = (clauseAuto) => {
    const sourceClause = clauseAuto && clauseAuto.automation_source
    return sourceClause
      ? pick(sourceClause, [
          'id',
          'client_source_contract_id',
          ...ClauseAttributes,
        ])
      : {}
  }
  const extractSourcesFromGroup = (autoGroup) =>
    autoGroup
      ? [
          extractCleanStub(autoGroup.issue_stub),
          extractCleanClause(autoGroup.clause),
        ]
      : []
  const detectMode = (currElement, currGroupID) => {
    if (currElement.question_automations.length === 0) return 'add'
    if (currGroupID) return 'display'
    return 'list'
  }

  // STATE
  const [currentElement, setCurrentElement] = React.useState(element)
  const [automationIndex, setAutomationIndex] = React.useState({})
  const [currentGroupID, setCurrentGroupID] = React.useState()
  const [currentGroup, setCurrentGroup] = React.useState({})
  const [currentStub, setCurrentStub] = React.useState({})
  const [currentClause, setCurrentClause] = React.useState({})
  const [automationSourceTypes, setAutomationSourceTypes] = React.useState([])
  const [primaryAutomation, setPrimaryAutomation] = React.useState(
    newAutomation(),
  )
  const [scoreOperatorList, setScoreOperatorList] = React.useState(['<'])
  const [mode, setMode] = React.useState(
    detectMode(currentElement, currentGroupID),
  )
  const { data: issueCategories = [] } = useClientIssueCategoriesFetcher()
  const { data: issueStubs = [] } = useClientIssueStubsFetcher()
  const { data: clientSourceContracts = [] } =
    useClientClientSourceContractsFetcher()

  // STATE MANAGEMENT ROUTINES

  const setAllForGroupID = (groupID) => {
    const autoGroup = automationIndex[groupID]
    const [stub, clause] = extractSourcesFromGroup(autoGroup)
    setCurrentStub(stub)
    setCurrentClause(clause)
    setAutomationSourceTypes(
      intersection(['issue_stub', 'clause'], keys(autoGroup)),
    )

    const { primary } = autoGroup
    const operList =
      primary && primary.score_oper ? primary.score_oper.split('') : ['<']
    setPrimaryAutomation(primary || newAutomation())
    setScoreOperatorList(operList)

    setCurrentGroup(autoGroup)
    setMode('display')
  }

  const resetAll = () => {
    const newMode = detectMode(currentElement)
    setMode(newMode)
    setCurrentGroup({})
    setCurrentStub({})
    setCurrentClause({})
    setAutomationSourceTypes([])
    setPrimaryAutomation(newAutomation())
    setScoreOperatorList(['<'])
  }

  // HOOKS

  const [openLargeTextModal] = useModal(LargeTextDisplayModal)
  const openConfirm = useConfirm()

  // [currentGroupID]: update current displayed automations if groupID is set or changed
  React.useEffect(() => {
    // logcurrentGroupId })
    if (currentGroupID) setAllForGroupID(currentGroupID)
    else resetAll()
  }, [currentGroupID])

  // [currentElement]: update various dependent states if element changes
  React.useEffect(() => {
    // log({ currentElement })
    const autoIdx = indexAutomationList(currentElement.question_automations)
    setAutomationIndex(autoIdx)
    setCurrentGroupID(undefined)
    resetAll()
  }, [currentElement])

  // FORM DISPLAY/INTERACTION HANDLERS

  const handleLargeTextView = (title, text) => {
    openLargeTextModal({ title, text })
  }

  const handleOpenSourceForm = ({
    openFormModal,
    closeFormModal,
    label,
    updateCallback,
    submitSourceMode,
    source,
    ...sourceFormRest
  }) => {
    const openSourceForm = () =>
      openFormModal({
        ...sourceFormRest,
        source,
        onSave: (data) => {
          updateCallback(data, submitSourceMode)
          closeFormModal()
        },
      })

    if (submitSourceMode === 'edit' && source.id) {
      const warningText = `Editing this ${label} will affect all assessments and assessment templates that are configured to use it. If you're not sure, consider creating a duplicate instead.`
      openConfirm({
        title: 'Are you sure?',
        body: warningText,
        onConfirm: () => openSourceForm(),
      })
    } else openSourceForm()
  }

  const setTriggerType = (trigger_type) => {
    setPrimaryAutomation({ ...primaryAutomation, trigger_type })
  }

  const setRatingScore = (score) => {
    setPrimaryAutomation({ ...primaryAutomation, score })
    if (score === minScore && includes(scoreOperatorList, '<')) {
      setScoreOperatorList(['='])
    } else if (score === maxScore && includes(scoreOperatorList, '>')) {
      setScoreOperatorList(['='])
    }
  }

  const handleScoreOperatorButtonClick = (value) => {
    let operList = scoreOperatorList
    const diff = xor(operList, value)
    if (value.length === 0) {
      // don't allow to uncheck all buttons
      return
    }
    if (diff[0] === '>') {
      operList = without(value, '<')
    } else if (diff[0] === '<') {
      operList = without(value, '>')
    } else {
      operList = value
    }
    setScoreOperatorList(operList)
  }

  const setTriggerExceptions = (exceptions) => {
    const ex = {
      attachment_check: false,
      comment_check: false,
    }
    exceptions.forEach((x) => {
      ex[x] = true
    })
    setPrimaryAutomation({ ...primaryAutomation, ...ex })
  }

  const setChoices = (choices) => {
    setPrimaryAutomation({ ...primaryAutomation, choices })
  }

  const resetStub = () => {
    setCurrentStub(extractCleanStub(currentGroup.issue_stub))
  }
  const clearStub = () => {
    setCurrentStub({})
    setAutomationSourceTypes(without(automationSourceTypes, 'issue_stub'))
  }
  const resetClause = () => {
    setCurrentClause(extractCleanClause(currentGroup.clause))
  }
  const clearClause = () => {
    setCurrentClause({})
    setAutomationSourceTypes(without(automationSourceTypes, 'clause'))
  }

  const checkToClose = () => {
    if (currentElement.question_automations.length === 0) onClose()
  }

  const resetAutomation = () => {
    checkToClose()
    // whatever changed state things are in, basically flush it out
    // and re-set the display for the current group
    if (mode === 'edit') setAllForGroupID(currentGroupID)
    else resetAll()
  }

  const resetElement = () => {
    checkToClose()
    setCurrentGroupID(undefined)
    resetAll()
  }

  const handleSave = () => {
    const promises = []
    let issueStub = {}

    if (automationSourceTypes.includes('issue_stub') && !isEmpty(currentStub)) {
      issueStub = { ...currentStub }
      let newAttachments = currentStub.attachments
      if (currentStub.fileDeletes)
        newAttachments = filter(
          currentStub.attachments,
          (file) => !currentStub.fileDeletes.includes(file.id),
        )
      if (currentStub.fileUploads)
        newAttachments = newAttachments.concat(currentStub.fileUploads)

      if (newAttachments) {
        // have any objects been removed?
        const deadThings = issueStub.attachments?.filter(
          (obj) => !newAttachments?.some((e) => e.id === obj.id),
        )
        if (deadThings?.length) {
          promises.push(
            updateClientIssueStub({
              id: currentStub.id,
              data: {
                issue_stub: {
                  attachments_attributes: deadThings.map((obj) => ({
                    id: obj.id,
                    _destroy: 1,
                  })),
                },
              },
            }),
          )
        }

        // have any objects actually been added?
        const newThings = newAttachments
          .filter((obj) => !obj.id)
          .map((obj) => ({ file: obj }))
        if (newThings.length) {
          issueStub.attachments_attributes = newThings
        }
      }
      delete issueStub.attachments
      delete issueStub.fileUploads
      delete issueStub.fileDeletes
    }

    // this should enforce the correct order of whatever we have:
    const operList = intersection(['<', '>', '='], scoreOperatorList)
    const ops = operList.join('')
    const data = {
      question_automation: {
        ...primaryAutomation,
        automation_source_type: automationSourceTypes,
        score_oper: ops === '=' ? '==' : ops,
      },
      issue_stub: issueStub,
      clause:
        automationSourceTypes.includes('clause') && currentClause
          ? currentClause
          : {},
    }

    const promise = Promise.all(promises)
      .then(() => {
        const issueCategoryId = data.issue_stub?.issue_category_id
        if (
          issueCategoryId &&
          !issueCategories.some((e) => e.id === issueCategoryId)
        ) {
          return createClientIssueCategory({
            data: { name: issueCategoryId },
          }).then((newCategory) => {
            data.issue_stub.issue_category_id = newCategory.id
          })
        }
        return null
      })
      .then(() => {
        const { id } = data.question_automation
        return id
          ? updateClientQuestionAutomation({
              id,
              data: objectToFormData({
                assessment_template_id: templateId,
                ...data,
              }),
            })
          : createClientQuestionAutomation({
              data: objectToFormData({
                assessment_template_id: templateId,
                ...data,
              }),
            })
      })
      .then((response) => {
        setCurrentElement(response)
      })
    handleAsyncAction(
      promise,
      'The question automation has successfully been created',
    )
  }

  const handleDelete = (id, includeDelete = false) => {
    const thing = includeDelete ? 'automation group' : 'automation'
    const warningText = (
      <>
        Are you sure you wish to delete this {thing}?
        {templateStatus === AssessmentTemplateStatusTypes.IN_PROCESS && (
          <>
            <br />
            This {thing} will be removed from <i>all</i> assessments currently
            using this template.
          </>
        )}
      </>
    )
    openConfirm({
      title: 'Deleting Automation',
      body: warningText,
      onConfirm: () => {
        const promise = deleteClientQuestionAutomation({
          id,
          options: {
            data: {
              assessment_template_id: templateId,
              delete_group: includeDelete,
            },
          },
        }).then((data) => {
          setCurrentElement(data)
        })
        handleAsyncAction(
          promise,
          'The automation has successfully been removed',
        )
      },
    })
  }

  const validTriggers = () => {
    const availableTriggers = [
      {
        label: 'Selected Answer(s)',
        value: 'choices',
        condition: currentElement.type !== AssessmentElementTypes.TEXT_QUESTION,
      },
      {
        label: 'Assigned Score',
        value: 'score',
        // || currentElement.type === 'SingleSelectQuestion'
        condition: toNumber(currentElement.weight) !== 0,
      },
      {
        label: 'Answer Not Given',
        value: 'blank',
        condition: false, // disabled for now
      },
    ]
    return filter(availableTriggers, 'condition')
  }

  // log.debug("\n" +
  //   "=============================\n" +
  //   "Rendering overall modal view:\n" +
  //   "\tmode:", mode, "\n" +
  //   "\telement:", currentElement, "\n" +
  //   "\tsorted automation index", automationIndex, "\n" +
  //   "\tcurrent automation count", currentElement.question_automations.length, "\n" +
  //   "\tcurrent group ID:", currentGroupID, "\n" +
  //   "\tprimary automation, if any?", primaryAutomation, "\n" +
  //   "\tcurrent stub:", currentStub, "\n" +
  //   "\tcurrent clause:", currentClause, "\n" +
  //   "\n"
  // );
  const enableSave =
    (primaryAutomation.trigger_type === 'score' ||
      (primaryAutomation.trigger_type === 'choices' &&
        primaryAutomation.choices.length > 0)) &&
    ((automationSourceTypes.includes('issue_stub') &&
      !isEqual(currentStub, {})) ||
      (automationSourceTypes.includes('clause') && !isEqual(currentClause, {})))

  const buttons = [
    {
      onClick: onClose,
      children: 'Close',
    },
  ]

  if (userCanEdit && mode === 'list') {
    buttons.push({
      color: 'primary',
      onClick: () => setMode('add'),
      children: modeLabels[mode],
    })
  }

  if (userCanEdit && mode === 'display') {
    buttons.push({
      color: 'primary',
      onClick: () => setMode('edit'),
      children: modeLabels[mode],
    })
  }

  if (userCanEdit && (mode === 'add' || mode === 'edit')) {
    buttons.push({
      onClick: () => resetAutomation(),
      children: 'Cancel',
    })
    buttons.push({
      color: 'primary',
      disabled: !enableSave,
      onClick: handleSave,
      children: modeLabels[mode],
    })
  }

  return (
    <SimpleDialog
      title="Answer Automations"
      buttons={buttons}
      onClose={onClose}
      {...rest}
    >
      <View
        element={currentElement}
        ratingLabelSet={ratingLabelSet}
        automationIndex={automationIndex}
        currentAutomationGroupID={currentGroupID}
        currentAutomationGroup={currentGroup}
        currentAutomation={primaryAutomation}
        currentStub={currentStub}
        currentClause={currentClause}
        scoreOperatorList={scoreOperatorList}
        maxScore={maxScore}
        minScore={minScore}
        mode={mode}
        validTriggers={validTriggers()}
        extras={{
          // eslint-disable-next-line camelcase
          issue_stub: { issueCategories, issueStubs },
          clause: { clientSourceContracts },
        }}
        automationSourceTypes={automationSourceTypes}
        resetElement={resetElement}
        setCurrentAutomationGroupID={setCurrentGroupID}
        setCurrentStub={setCurrentStub}
        setCurrentClause={setCurrentClause}
        setTriggerType={setTriggerType}
        setTriggerExceptions={setTriggerExceptions}
        setRatingScore={setRatingScore}
        onScoreOperatorButtonClick={handleScoreOperatorButtonClick}
        setChoices={setChoices}
        setAutomationSourceTypes={setAutomationSourceTypes}
        onDeleteAutomationGroup={handleDelete}
        onResetStub={resetStub}
        onClearStub={clearStub}
        onResetClause={resetClause}
        onClearClause={clearClause}
        onOpenSourceForm={handleOpenSourceForm}
        onLargeTextViewClick={handleLargeTextView}
        setMode={setMode}
        onClose={onClose}
      />
    </SimpleDialog>
  )
}

QuestionAutomationModal.propTypes = {
  element: PropTypes.object.isRequired,
  templateId: PropTypes.string.isRequired,
  templateStatus: PropTypes.string.isRequired,
  ratingLabelSet: PropTypes.string,
  handleAsyncAction: PropTypes.func.isRequired,
  createClientQuestionAutomation: PropTypes.func.isRequired,
  updateClientQuestionAutomation: PropTypes.func.isRequired,
  deleteClientQuestionAutomation: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default React.memo(QuestionAutomationModal)
