import React from 'react'
import PropTypes from 'prop-types'
import A from 'components-v2/atoms/A'
import GradeLabel from 'components/shared/GradeLabel'
import { toLocalTimeString } from 'utils/date'
import { Wrapper, Top, Title, Content, Item, ItemName, Bottom } from './styles'

const convertSetStatusToGrade = (status) => {
  if (status === 'passing') {
    return 1
  }
  if (status === 'failing') {
    return -1
  }
  return 0
}

const FacetableSet = ({ data }) => (
  <Wrapper>
    <Top>
      <Title>{data.facet_set_name}</Title>
      <GradeLabel
        grade={convertSetStatusToGrade(data.status)}
        noGradeText="Pending"
      />
    </Top>
    <Content>
      {data.facetable_items.map((item) => (
        <Item key={item.id}>
          <ItemName>{item.facet_name}</ItemName>
          <GradeLabel grade={item.grade} noGradeText="Pending" />
        </Item>
      ))}
      {data.open_facet_set_items.map((item) => (
        <Item key={item.id}>
          <ItemName>{item.facet_name}</ItemName>
          <GradeLabel grade={item.grade} noGradeText="Pending" />
        </Item>
      ))}
    </Content>
    <Bottom>
      <span>Created: {toLocalTimeString(data.created_at, 'MM/dd/yy')}</span>
      <A href={`?tab=riskMeasures#set-${data.id}`} underline>
        View Details
      </A>
    </Bottom>
  </Wrapper>
)

FacetableSet.propTypes = {
  data: PropTypes.object.isRequired,
}

export default FacetableSet
