import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components-v2/atoms/Button'
import Alert from 'components-v2/molecules/Alert'
import Dialog from 'components-v2/molecules/Dialog'

const ErrorModal = ({ errors, onClose, ...rest }) => (
  <Dialog onClose={onClose} {...rest}>
    <Dialog.Header>Error</Dialog.Header>
    <Dialog.Content>
      <Alert color="danger">
        <ul>
          {errors.map((error, id) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={id}>{error}</li>
          ))}
        </ul>
      </Alert>
    </Dialog.Content>
    <Dialog.Footer>
      <Button onClick={onClose}>Close</Button>
    </Dialog.Footer>
  </Dialog>
)

ErrorModal.propTypes = {
  errors: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ErrorModal
