import { useCallback } from 'react'
import { useRequestShareToLibraryListing } from 'apis'
import { useGlobalLoader } from 'hooks'
import NotificationManager from 'lib/notifications'

export default function useRequestAccess() {
  const { mutateAsync: requestShare } = useRequestShareToLibraryListing()
  const [showLoader, hideLoader] = useGlobalLoader()

  return useCallback(
    async (id) => {
      const loaderId = showLoader()
      try {
        await requestShare({ id })
        NotificationManager.success(
          'Your request has successfully been submitted',
        )
      } catch {
        NotificationManager.error()
      }
      hideLoader(loaderId)
    },
    [requestShare, showLoader, hideLoader],
  )
}
