import styled from 'styled-components'
import { colors } from 'styles'

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`

export const Title = styled.h4`
  margin: 0;
`

export const UpdatedAt = styled.span`
  font-size: 14px;
  color: ${colors.GREY};
`
