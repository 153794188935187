export const queryParamsConfig = {
  sortField: {
    queryKey: 'ordered_by',
  },
  sortOrder: {
    queryKey: 'direction',
  },
  status: {
    defaultValue: '',
  },
}
