import React from 'react'
import last from 'lodash/last'
import GlobalLoaderManager from 'lib/globalLoader'
import OverlayLoader from 'components-v2/organisms/OverlayLoader'

export default () => {
  const [globalLoaders, setGlobalLoaders] = React.useState({})

  React.useEffect(() => {
    GlobalLoaderManager.addChangeListener(setGlobalLoaders)
    return () => {
      GlobalLoaderManager.removeChangeListener(setGlobalLoaders)
    }
  }, [])

  const activeLoader = last(globalLoaders)
  const loaderProps = activeLoader
    ? { ...activeLoader.options, isGlobal: true, active: true }
    : { isGlobal: true }
  return <OverlayLoader {...loaderProps} />
}
