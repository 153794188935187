const OrganizationPartnerTypes = {
  BITSIGHT: 'BitSight',
  RISK_RECON: 'RiskRecon',
  AWS_SES: 'AwsSes',
}

export const OrganizationPartnerOptions = [
  {
    value: OrganizationPartnerTypes.BITSIGHT,
    label: 'BitSight',
  },
  {
    value: OrganizationPartnerTypes.RISK_RECON,
    label: 'RiskRecon',
  },
  {
    value: OrganizationPartnerTypes.AWS_SES,
    label: 'AWS Simple Email Service',
  },
]

export default OrganizationPartnerTypes
