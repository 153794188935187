import { useCallback } from 'react'
import { useGlobalLoader, useModal } from 'hooks'
import NotificationManager from 'lib/notifications'
import objectToFormData from 'utils/object_to_formdata'
import { useCreateAssistAnswerSet } from 'apis'
import AnswerSetUploadModal from './AnswerSetUploadModal'

export default function useUploadAnswerSet(onSuccess) {
  const [showLoader, hideLoader] = useGlobalLoader()
  const [openModal, closeModal] = useModal(AnswerSetUploadModal)
  const { mutateAsync: createAssistAnswerSet } = useCreateAssistAnswerSet()

  return useCallback(() => {
    const onSubmit = (formData, { setErrors }) => {
      const loaderId = showLoader()
      createAssistAnswerSet({ data: objectToFormData(formData) })
        .then((data) => {
          closeModal()

          if (onSuccess) {
            onSuccess(data)
          }
        })
        .catch((error) => {
          if (error.response.data?.message) {
            setErrors({
              sheet: error.response.data.message,
            })
          } else {
            NotificationManager.error()
          }
        })
        .then(() => hideLoader(loaderId))
    }
    openModal({
      onSubmit,
    })
  }, [
    onSuccess,
    openModal,
    closeModal,
    createAssistAnswerSet,
    showLoader,
    hideLoader,
  ])
}
