// for form architecture using Formik + Yup
import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import isFunction from 'lodash/isFunction'
import Button from 'components-v2/atoms/Button'
import Dialog from 'components-v2/molecules/Dialog'
import { log } from 'utils/debug'
import { Footer, FooterSpacer } from './styles'

const handleKeyPress = (event) => {
  if (
    event.which === 13 /* Enter */ &&
    event.target.tagName.toLowerCase() === 'input'
  ) {
    event.preventDefault()
  }
}

const handleDisableCheck = (disableSubmit, values) =>
  typeof disableSubmit === 'boolean' ? disableSubmit : disableSubmit(values)

const FormDialog = ({
  title,
  submitButtonLabel,
  cancelButtonLabel,
  onClose,
  onCancel,
  onSubmit,
  disableSubmit,
  children,
  footerLeftContent,
  formConfig,
  debug,
  bodyContent,
  submitColor,
  ...rest
}) => (
  <Dialog {...rest} onClose={onClose}>
    <Formik {...formConfig} onSubmit={onSubmit}>
      {(formProps) => {
        if (debug) {
          const {
            isSubmitting,
            isValidating,
            isValid,
            dirty,
            values,
            errors,
            touched,
          } = formProps
          log({
            'Formik debug': {
              isSubmitting,
              isValidating,
              isValid,
              dirty,
              values,
              errors,
              touched,
            },
          })
        }
        return (
          <form onSubmit={formProps.handleSubmit}>
            <div role="presentation" onKeyPress={handleKeyPress}>
              <Dialog.Header onClose={onClose}>{title}</Dialog.Header>
              <Dialog.Content>
                <p>{bodyContent}</p>
                {isFunction(children) ? children(formProps) : children}
              </Dialog.Content>
              <Footer>
                {footerLeftContent}
                <FooterSpacer />
                <Button onClick={onCancel || onClose}>
                  {cancelButtonLabel}
                </Button>
                <Button
                  type="submit"
                  color={`${submitColor || 'primary'}`}
                  disabled={
                    formProps.isSubmitting ||
                    handleDisableCheck(disableSubmit, formProps.values)
                  }
                >
                  {submitButtonLabel}
                </Button>
              </Footer>
            </div>
          </form>
        )
      }}
    </Formik>
  </Dialog>
)

FormDialog.propTypes = {
  title: PropTypes.string.isRequired,
  submitButtonLabel: PropTypes.any,
  cancelButtonLabel: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  disableSubmit: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  children: PropTypes.any,
  footerLeftContent: PropTypes.any,
  formConfig: PropTypes.object,
  debug: PropTypes.bool,
  bodyContent: PropTypes.string,
  submitColor: PropTypes.string,
}

FormDialog.defaultProps = {
  submitButtonLabel: 'Submit',
  cancelButtonLabel: 'Cancel',
  disableSubmit: false,
  formConfig: {},
  debug: false,
}

export default React.memo(FormDialog)
