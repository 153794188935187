import styled from 'styled-components'
import graphics from './graphics.svg'

export const Inner = styled.div`
  position: relative;
  padding-left: 125px;
  min-height: 45px;
`

export const Graphics = styled.img.attrs({ src: graphics })`
  position: absolute;
  left: 0;
  top: 0;
  width: 95px;
`

export const Title = styled.span`
  display: block;
  font-weight: bold;
`

export const ViewAllLink = styled.a`
  color: inherit;

  &:hover,
  &:active,
  &:focus {
    color: inherit;
  }
`
