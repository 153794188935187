import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components-v2/atoms/Button'
import Dialog from 'components-v2/molecules/Dialog'
import { StyledDialog, DialogContent, Iframe } from './styles'

const EmailPreviewModal = ({ previewUrl, onClose, ...rest }) => (
  <StyledDialog {...rest} onClose={onClose}>
    <Dialog.Header onClose={onClose}>Email Preview</Dialog.Header>
    <DialogContent>
      <Iframe src={previewUrl} />
    </DialogContent>
    <Dialog.Footer>
      <Button color="primary" onClick={onClose}>
        Close
      </Button>
    </Dialog.Footer>
  </StyledDialog>
)

EmailPreviewModal.propTypes = {
  previewUrl: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default EmailPreviewModal
