import styled, { css } from 'styled-components'
import Button from 'components-v2/atoms/Button'
import Tabs from 'components-v2/molecules/Tabs'
import Table from 'components-v2/molecules/Table'
import { formatCell } from 'components-v2/molecules/Table/styles'
import { colors, fonts, mixins } from 'styles'

export const StyledTabs = styled(Tabs).attrs({
  RenderTabItem: styled.button`
    ${mixins.clearButtonStyle}
    ${mixins.smallHeader}
    color: ${colors.BODY_TEXT};
    border-bottom: 4px solid transparent;
    margin-right: 24px;
    padding: 10px 0;

    &:hover,
    &:focus {
      color: ${colors.PRIMARY};
    }

    ${({ selected }) =>
      selected &&
      `
      color: ${colors.PRIMARY};
      border-bottom-color: ${colors.PRIMARY};
    `}
  `,
  RenderPanels: 'div',
})``

export const StyledTable = styled(Table)`
  color: ${colors.BODY_TEXT};

  table {
    ${formatCell(`
      padding-top: 20px;
      padding-bottom: 20px;
    `)}
  }
`

export const CreateButton = styled(Button)`
  visibility: hidden;
`

export const IssueWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: -14px;
  padding: 14px;

  ${({ hoverable }) =>
    hoverable &&
    css`
      &:hover {
        box-shadow: 0 4px 18px 0 rgba(80, 80, 80, 0.15);

        ${CreateButton} {
          visibility: visible;
        }
      }
    `}
`

export const IssueContentLeft = styled.div`
  flex: 1;
  margin-right: 20px;
`

export const Title = styled.span`
  font-family: ${fonts.SECONDARY};
  font-size: 14px;
  font-weight: bold;
  color: #404144;
`

export const Severity = styled.span`
  font-family: ${fonts.SECONDARY};
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${({ danger }) => (danger ? colors.RED : `#ee832c`)};
  margin-left: 7px;
`

export const Description = styled.div`
  margin-top: 10px;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  opacity: 0.9;
`
