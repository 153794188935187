import { useCallback } from 'react'
import { useModal } from 'hooks'
import { useImportClientAssessmentResponse } from 'apis'
import { LabelTypes } from 'constants/index'
import ImportAssessmentResponsesModal from 'components/shared/ImportAssessmentResponsesModal'

export default function useImportResponses(assessment) {
  const [openModal] = useModal(ImportAssessmentResponsesModal)
  const { mutateAsync: importClientAssessmentResponse } =
    useImportClientAssessmentResponse()

  return useCallback(() => {
    openModal({
      assessmentStatus: assessment.status,
      instruction: `To import responses for this assessment, upload the file containing the responses in the correct format. The assessment's XLSX template can be downloaded using the Export button and filled out offline.`,
      note: `Note: This will replace any in progress answers from the ${LabelTypes.VENDOR.toLowerCase()}.`,
      onSubmit: (data) =>
        importClientAssessmentResponse({
          assessmentId: assessment.id,
          data,
        }),
    })
  }, [openModal, assessment, importClientAssessmentResponse])
}
