import React, {
  useState,
  useMemo,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react'
import PropTypes from 'prop-types'
import View from './View'
import { getVendorFieldGroups } from '../vendor_info_view/utils'
import { VendorFieldGroupIdTypes } from '../vendor_info_view/constants'
import useSaveFieldGroupCard from '../vendor_info_view/useSaveFieldGroupCard'

const Container = forwardRef(({ vendor }, ref) => {
  const [cards, setCards] = useState({})
  const vendorFieldGroups = useMemo(
    () => getVendorFieldGroups({ vendorUsers: vendor.users }),
    [vendor],
  )

  const handleEdit = useCallback((cardId) => {
    setCards((current) => ({
      ...current,
      [cardId]: {
        editing: true,
      },
    }))
  }, [])

  const handleCancel = useCallback((cardId) => {
    setCards((current) => ({
      ...current,
      [cardId]: {
        editing: false,
      },
    }))
  }, [])

  const handleCreatePrimaryUser = useCallback(() => {
    handleEdit(VendorFieldGroupIdTypes.CONTRIBUTORS)
  }, [handleEdit])

  const saveFieldGroupCard = useSaveFieldGroupCard(vendor, handleCancel)

  useImperativeHandle(
    ref,
    () => ({
      editContributors: () => handleEdit(VendorFieldGroupIdTypes.CONTRIBUTORS),
    }),
    [handleEdit],
  )

  return (
    <View
      cards={cards}
      vendor={vendor}
      vendorFieldGroups={vendorFieldGroups}
      onEdit={handleEdit}
      onSave={saveFieldGroupCard}
      onCancel={handleCancel}
      onCreatePrimaryUser={handleCreatePrimaryUser}
    />
  )
})

Container.propTypes = {
  vendor: PropTypes.object.isRequired,
}

export default Container
