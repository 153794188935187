import styled from 'styled-components'
import ControlLabel from 'components-v2/molecules/FormField/ControlLabel'

export const AdvancedOptionsBlock = styled.details`
  border: 1px solid #ccc;
  padding: 10px;

  > summary {
    font-weight: bold;
    outline: 0;
    cursor: pointer;

    > * {
      display: inline;
    }
  }

  &[open] summary {
    margin-bottom: 10px;
  }
`
export const OptionGroup = styled.div`
  margin-left: 20px;
`
export const OptionLabel = styled(ControlLabel)`
  font-style: italic;
  font-weight: normal;
`
