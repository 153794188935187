import React from 'react'
import each from 'lodash/each'
import every from 'lodash/every'
import fill from 'lodash/fill'
import filter from 'lodash/filter'
import partition from 'lodash/partition'
import some from 'lodash/some'
import sortBy from 'lodash/sortBy'
import startCase from 'lodash/startCase'
import { AssessmentStatusTypes } from 'constants/index'
import { toLocalTimeString } from 'utils/date'
import Tooltip from 'components-v2/molecules/Tooltip'
import { ReminderSpot, WarningSpot, StatusWrapper } from '../styles'

export const formatHeader = () => (
  <span>
    Reminders Sent{' '}
    <Tooltip>
      Automatic email reminders will be sent 2, 5, and 7 business days after the
      launch date if an invitation has not been accepted. If not submitted,
      reminders will also be sent 3 days and 1 day prior to the completion date.
    </Tooltip>
  </span>
)

export const formatCell =
  ({ assessments }) =>
  (info) => {
    const vendorAssessments = filter(assessments, {
      vendor_id: info.row.original.vendor.id,
    })
    const disabled = info.row.original.reminders_disabled_at ? 'disabled' : ''

    const reminderGroups = partition(
      info.row.original.reminder_delivery_schedule,
      (r) => r.name.startsWith('reminder'),
    )
    const warningsSent = some(reminderGroups[1], (r) => r.actual != null)
    return vendorAssessments.length > 0 ? (
      <StatusWrapper>
        {formatReminders(
          info.row.original,
          vendorAssessments,
          reminderGroups[0],
          disabled,
          warningsSent,
        )}
        {formatWarnings(
          info.row.original,
          vendorAssessments,
          reminderGroups[1],
          disabled,
        )}
      </StatusWrapper>
    ) : null
  }

const formatWarnings = (row, assessments, warnings, disabled) => {
  const stillWorkingStates = [
    AssessmentStatusTypes.INVITED,
    AssessmentStatusTypes.IN_PROCESS,
  ]
  // if we don't have any assessments left to warn about, we're done here
  if (!some(assessments, (asmt) => stillWorkingStates.includes(asmt.status))) {
    return null
  }

  const sortedWarnings = sortBy(warnings, 'delay')
  const groupResult = renderReminderGroup(row, sortedWarnings, disabled)
  return (
    <>
      <span className="left5 right5">/</span>
      {groupResult}
    </>
  )
}

const formatReminders = (
  row,
  assessments,
  reminders,
  disabled,
  warningsSent,
) => {
  if (!every(assessments, { status: AssessmentStatusTypes.INVITED })) {
    return <ReminderSpot className="fa fa-check-circle left5" />
  }

  const sortedReminders = sortBy(reminders, 'delay')
  return renderReminderGroup(row, sortedReminders, disabled, warningsSent)
}

const renderReminderGroup = (row, reminders, disabled, warningsSent) => {
  let beforeSent = some(reminders, (r) => r.actual != null)
  const out = []
  each(reminders, (r, idx, coll) => {
    if (!r.eligible || (r.actual == null && (beforeSent || warningsSent))) {
      // reminders were enabled late and this one got skipped over
      out.push(renderSkipped(r, disabled))
    } else if (r.actual != null) {
      beforeSent = false
      out.push(renderSent(r, disabled))
    } else if (disabled) {
      const howMany = coll.length - idx
      const date = row.reminders_disabled_at
      out.push(renderDisabled(r, howMany, disabled, date))
      return false
    } else {
      out.push(renderScheduled(r))
    }
    return true
  })
  return out
}

const renderSkipped = (reminder, disabled) => {
  const iconClass = 'fa-ban'
  const statusStr = 'was skipped'
  const date = undefined
  return renderReminder(reminder, iconClass, statusStr, date, disabled)
}

const renderSent = (reminder, disabled) => {
  const iconClass = 'fa-circle'
  const statusStr = 'sent'
  const date = reminder.actual
  return renderReminder(reminder, iconClass, statusStr, date, disabled)
}

const renderScheduled = (reminder) => {
  const iconClass = 'fa-circle-o'
  const statusStr = 'scheduled for'
  const date = reminder.target
  return renderReminder(reminder, iconClass, statusStr, date)
}

const renderReminder = (reminder, iconClass, statusStr, date, disabled) => {
  const Icon = reminder.name.startsWith('reminder') ? ReminderSpot : WarningSpot
  return (
    <Tooltip
      parent={
        <Icon
          key={reminder.name}
          className={`fa ${iconClass} ${disabled} left5`}
        />
      }
    >
      {startCase(reminder.name)} {statusStr}
      {!!date && (
        <>
          :<br />
          {toLocalTimeString(date)}
        </>
      )}
    </Tooltip>
  )
}

const renderDisabled = (reminder, num, disabled, disabledAt) => {
  const Icon = reminder.name.startsWith('reminder') ? ReminderSpot : WarningSpot
  // group together however many there are so we can put a single tooltip over the bunch
  return (
    <Tooltip
      parent={
        <>
          {fill(Array(num), <Icon className={`fa fa-ban ${disabled} left5`} />)}
        </>
      }
    >
      Reminders disabled:
      <br />
      {toLocalTimeString(disabledAt)}
    </Tooltip>
  )
}
