import styled from 'styled-components'
import { colors, mixins } from 'styles'
import Icon from 'components-v2/atoms/Icon'
import A from 'components-v2/atoms/A'

export const OrgNameLink = styled(A)`
  color: ${colors.PRIMARY};

  &:hover,
  &:active,
  &:focus {
    color: ${colors.PRIMARY};
    text-decoration: none;
  }
`

export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;

  // Firefox Print Bugfix to prevent this flexbox being overlapped with elements after this
  @media print {
    display: block;
  }
`

export const CircleIcon = styled(Icon)`
  border-radius: 100%;
  background: ${({ background }) => background || colors.PRIMARY};
  color: ${colors.WHITE};
  font-size: 22px;
  padding: 7px;
  box-sizing: content-box;
`

export const AdditionalLinkList = styled.div`
  flex: none;
  margin-left: 30px;
  display: flex;
  align-items: center;
  align-self: flex-start;
  ${mixins.hidePrint}
`

export const AdditionalLink = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 20px;
  }

  ${CircleIcon} {
    margin-right: 10px;
  }
`

export const NoteWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  min-width: 0;
  padding-top: 7px;

  ${CircleIcon} {
    margin-top: -7px;
    flex: none;
  }

  button {
    margin-top: 3px;
    flex: none;
  }

  // Firefox Print Bugfix to prevent this flexbox being overlapped with elements after this
  @media print {
    display: block;
  }
`

export const NoteLabel = styled.span`
  font-weight: bold;
  margin: 0 10px;
  flex: none;
`

export const NoteBody = styled.div`
  min-width: 0;
  margin-left: 10px;

  &:not(:last-child) {
    margin-right: 10px;
  }
`

export const HeaderSplit = styled.hr`
  margin-top: 5px;
  margin-bottom: 5px;
`
