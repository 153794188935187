import styled from 'styled-components'
import { colors } from 'styles'

export const Wrapper = styled.span`
  display: inline-flex;
  align-items: stretch;
  border: 1px solid ${({ $color }) => $color};
  border-radius: 100px;
  overflow: hidden;
  font-weight: 500;
  text-transform: uppercase;
`

export const Left = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${colors.WHITE};
  background: ${({ $color }) => $color};
  white-space: nowrap;
  padding: 0 6px 0 10px;
`

export const Right = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 0 10px 0 6px;
`
