import React from 'react'
import PropTypes from 'prop-types'
import A from 'components-v2/atoms/A'
import Button from 'components-v2/atoms/Button'
import Table from 'components-v2/molecules/Table'
import { dateFormatterFactory } from 'lib/table'
import { LabelTypes } from 'constants/index'

const dateFormatter = dateFormatterFactory()
const getTableColumns = (canAdd, onAddVendor, onLinkVendor) =>
  [
    {
      id: 'name',
      header: LabelTypes.VENDOR,
      width: '20%',
    },
    {
      id: 'website_url',
      header: 'Website URL',
      width: '20%',
    },
    {
      id: 'created_at',
      header: 'Date Created',
      cell: dateFormatter,
      width: '15%',
    },
    {
      id: 'updated_at',
      header: 'Date Updated',
      cell: dateFormatter,
      width: '15%',
    },
    {
      id: 'listing_count',
      header: 'Listings Available',
      width: '15%',
    },
    {
      id: 'df1',
      header: 'Action',
      cell: (info) =>
        info.row.original.mapped_vendor_id ? (
          <A
            useGray
            onClick={() => onLinkVendor(info.row.original.mapped_vendor_id)}
          >
            View Added {LabelTypes.VENDOR}
          </A>
        ) : (
          <Button
            color="primary"
            disabled={!canAdd}
            onClick={() => onAddVendor(info.row.id)}
          >
            <i className="fa fa-plus-circle" />
            <span>Add {LabelTypes.VENDOR}</span>
          </Button>
        ),
      sortingFn: (rowA, rowB) => {
        const valueA = rowA.original.mapped_vendor_id ? 1 : 0
        const valueB = rowB.original.mapped_vendor_id ? 1 : 0
        return valueA < valueB ? -1 : valueB < valueA ? 1 : 0
      },
      width: '15%',
    },
  ].filter((e) => e)

const View = ({ libraryProfiles, loading, onAddVendor, onLinkVendor }) => {
  // for now:
  const canAdd = true
  const columns = React.useMemo(
    () => getTableColumns(canAdd, onAddVendor, onLinkVendor),
    [canAdd, onAddVendor, onLinkVendor],
  )

  return (
    <Table
      loading={loading}
      data={libraryProfiles || []}
      columns={columns}
      enablePagination={false}
      noDataText="No library vendors found."
      type={Table.types.ALT}
    />
  )
}

View.propTypes = {
  libraryProfiles: PropTypes.array,
  loading: PropTypes.bool,
  onAddVendor: PropTypes.func,
  onLinkVendor: PropTypes.func,
}

export default View
