import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import filter from 'lodash/filter'
import FormField from 'components-v2/molecules/FormField'
import FormDialog from 'components-v2/organisms/FormDialog'
import { FieldRow, SmallWidthFormField } from './styles'
import { SectionPlacementTypes, MoveOrCopyActionTypes } from '../constants'
import { getSectionSelectOptions } from '../utils'

const validationSchema = yup.object().shape({
  action: yup.string().required(),
  placement: yup.string().required(),
  relative_section_id: yup.string().required('Please select a section'),
})

const placementOptions = [
  {
    label: 'Before',
    value: SectionPlacementTypes.BEFORE,
  },
  {
    label: 'After',
    value: SectionPlacementTypes.AFTER,
  },
]

const SectionFormModal = ({ elements, sectionId, ...rest }) => {
  const sectionSelectOptions = getSectionSelectOptions(elements)
  const sectionSelectOptionsForMoveAction = filter(
    getSectionSelectOptions(elements),
    (opt) => opt.value !== sectionId,
  )
  const canMoveAction = sectionSelectOptionsForMoveAction.length > 0

  const formConfig = React.useMemo(
    () => ({
      validationSchema,
      initialValues: {
        action: canMoveAction
          ? MoveOrCopyActionTypes.MOVE
          : MoveOrCopyActionTypes.COPY,
        placement: SectionPlacementTypes.AFTER,
        relative_section_id: canMoveAction
          ? sectionSelectOptionsForMoveAction[
              sectionSelectOptionsForMoveAction.length - 1
            ].value
          : sectionId,
      },
    }),
    [canMoveAction, sectionSelectOptionsForMoveAction, sectionId],
  )

  const actionOptions = React.useMemo(
    () => [
      {
        label: 'Move to',
        value: MoveOrCopyActionTypes.MOVE,
        disabled: !canMoveAction,
      },
      {
        label: 'Copy to',
        value: MoveOrCopyActionTypes.COPY,
      },
    ],
    [canMoveAction],
  )

  return (
    <FormDialog {...rest} title="Move or Copy Section" formConfig={formConfig}>
      {({ values }) => (
        <FieldRow>
          <SmallWidthFormField
            name="action"
            type={FormField.types.SELECT}
            options={actionOptions}
          />
          <SmallWidthFormField
            name="placement"
            type={FormField.types.SELECT}
            options={placementOptions}
          />
          <FormField
            name="relative_section_id"
            type={FormField.types.SEARCHABLE_SELECT}
            options={
              values.action === MoveOrCopyActionTypes.COPY
                ? sectionSelectOptions
                : sectionSelectOptionsForMoveAction
            }
          />
        </FieldRow>
      )}
    </FormDialog>
  )
}

SectionFormModal.propTypes = {
  elements: PropTypes.array.isRequired,
  sectionId: PropTypes.string.isRequired,
}

export default SectionFormModal
