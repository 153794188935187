import styled from 'styled-components'
import Select from 'components-v2/atoms/Select'
import { colors, mixins } from 'styles'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #eee;
  color: #333;
  padding: 8px;

  body.grey-bg & {
    background: #d4d4d4;
  }
`

export const SectionSelectionTitle = styled.span`
  margin: 0 auto 0 0;
  font-size: 120%;
  font-weight: bold;
  ${mixins.truncate};
`

export const JumpToText = styled.span`
  ${mixins.smallHeader};
  color: ${colors.BODY_TEXT};
  margin-left: 10px;
  ${mixins.hidePrint}
`

export const SectionSelect = styled(Select)`
  width: 240px;
  flex: none;
  margin-left: 10px;
  ${mixins.hidePrint}
`
