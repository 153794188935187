import styled from 'styled-components'
import Card from 'components-v2/organisms/Card'
import { mixins } from 'styles'

export const Filter = styled.div`
  margin-bottom: 20px;
  ${mixins.hidePrint}
`

export const ChartsCard = styled(Card).attrs({ padding: true })`
  margin-bottom: 30px;
  ${mixins.avoidPageBreak}
`

export const Title = styled.h4`
  margin: 0 0 20px 0;
`

export const SubTitle = styled.h5`
  text-transform: capitalize;
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`
