import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import keys from 'lodash/keys'
import Button from 'components-v2/atoms/Button'
import Table from 'components-v2/molecules/Table'
import Toolbar, { SearchInput } from 'components-v2/molecules/Table/Toolbar'
import Tooltip from 'components-v2/molecules/Tooltip'
import UserAvatar from 'components-v2/molecules/UserAvatar'
import IconButton from 'components-v2/atoms/IconButton'
import { UserOrgRoles } from 'utils/roles'
import { pluralize } from 'utils/string'
import { useAuthorizations } from 'hooks'
import { LabelTypes } from 'constants/index'
import { UserCell, TopSection, DisabledText } from './styles'

const getUserTableColumns = (
  isClient,
  ssoActive,
  canManageGroups,
  onClickAccessRoleExplainer,
) => {
  const columns = [
    {
      id: 'name',
      header: 'User',
      cell: (info) => (
        <UserCell>
          <UserAvatar
            name={info.getValue()}
            src={info.row.original.avatar_url}
          />
          <span>{info.getValue()}</span>
        </UserCell>
      ),
      minWidth: 200,
    },
    {
      id: 'email',
      header: 'Email',
      minWidth: 200,
    },
  ]

  if (isClient) {
    columns.push({
      id: 'org_role',
      header: (
        <span>
          Access Role&nbsp;&nbsp;
          <Tooltip
            parent={
              <IconButton
                icon="fa fa-lock"
                onClick={onClickAccessRoleExplainer}
              />
            }
          >
            Click the lock icon to view role descriptions
          </Tooltip>
        </span>
      ),
      cell: (info) => UserOrgRoles[info.row.original.org_role].label,
      enableSorting: false,
      minWidth: 150,
    })

    if (canManageGroups) {
      columns.push({
        id: 'groups',
        header: 'Groups',
        cell: (info) => info.getValue().length,
        enableSorting: false,
      })
    }
  }

  if (isClient) {
    columns.push({
      id: 'otp_required_for_login',
      header: () => (
        <>
          Two-factor{' '}
          <Tooltip>
            Two-Factor authentication can be enabled by the user in their
            settings
          </Tooltip>
        </>
      ),
      cell: (info) => {
        if (get(info.row.original, 'sso_active')) {
          return <DisabledText>N/A</DisabledText>
        }
        return info.getValue() ? (
          <span>Enabled</span>
        ) : (
          <DisabledText>Disabled</DisabledText>
        )
      },
      enableSorting: false,
      minWidth: 150,
    })

    if (ssoActive) {
      columns.push({
        id: 'sso_active',
        header: 'SSO',
        cell: (info) =>
          info.getValue() ? (
            <span>Enabled</span>
          ) : (
            <DisabledText>Disabled</DisabledText>
          ),
        enableSorting: false,
      })
    }

    columns.push({
      id: 'api_enabled',
      header: () => (
        <>
          API Permission{' '}
          <Tooltip>
            The REST API permissions can be enabled by the Admin user
          </Tooltip>
        </>
      ),
      cell: (info) =>
        info.getValue() ? (
          <span>Enabled</span>
        ) : (
          <DisabledText>Disabled</DisabledText>
        ),
      enableSorting: false,
      minWidth: 170,
    })
  }

  columns.push({
    id: 'email_notifications',
    header: 'Notifications',
    cell: (info) => (info.getValue() ? 'On' : 'Off'),
    enableSorting: false,
  })

  if (isClient) {
    columns.push({
      id: 'vendor_roles',
      header: `${LabelTypes.VENDOR} Permission`,
      cell: (info) => {
        const vendorIds = keys(info.getValue())
        return info.getValue().all
          ? `All ${LabelTypes.VENDORS.toLowerCase()}`
          : vendorIds.length === 1
          ? `1 ${LabelTypes.VENDOR.toLowerCase()}`
          : `${vendorIds.length} ${LabelTypes.VENDORS.toLowerCase()}`
      },
      enableSorting: false,
      minWidth: 190,
    })
  }

  return columns
}

const View = ({
  users,
  searchText,
  totalSize,
  pagination,
  sorting,
  loading,
  isClient,
  ssoActive,
  onClickInviteBtn,
  onClickAccessRoleExplainer,
  onPaginationChange,
  onSortingChange,
  onSearchChange,
  onEditUser,
}) => {
  const [canInvite, canManageGroups] = useAuthorizations(
    ['create', 'User'],
    ['manage_user_groups', 'User'], // bringing in for pending UX update
  )
  const tableColumns = React.useMemo(
    () =>
      getUserTableColumns(
        isClient,
        ssoActive,
        canManageGroups,
        onClickAccessRoleExplainer,
      ),
    [isClient, ssoActive, canManageGroups, onClickAccessRoleExplainer],
  )
  return (
    <div>
      <TopSection>
        <h3>Users</h3>
        {totalSize !== undefined && totalSize !== null && (
          <span>
            {searchText
              ? `${pluralize(totalSize, 'user')} found.`
              : `There are ${pluralize(
                  totalSize,
                  'user',
                )} in this organization.`}
          </span>
        )}
      </TopSection>
      <Toolbar>
        <SearchInput
          value={searchText}
          placeholder="Search by Name or Email"
          onChange={onSearchChange}
        />
        {isClient ? (
          canInvite && (
            <Button color="primary" onClick={onClickInviteBtn}>
              <i className="fa fa-envelope-o" />
              Invite a user to your organization
            </Button>
          )
        ) : (
          <Button color="primary" onClick={onClickInviteBtn}>
            <i className="fa fa-envelope-o" />
            Invite a user to your organization
          </Button>
        )}
      </Toolbar>
      <Table
        loading={loading}
        data={users || []}
        columns={tableColumns}
        totalSize={totalSize}
        pagination={pagination}
        sorting={sorting}
        onPaginationChange={onPaginationChange}
        onSortingChange={onSortingChange}
        onRowClick={(row) => onEditUser(row.original)}
        manualPagination
        manualSorting
        noDataText="No users found."
        type={Table.types.ALT}
      />
    </div>
  )
}

View.propTypes = {
  users: PropTypes.array,
  searchText: PropTypes.string,
  totalSize: PropTypes.number,
  pagination: PropTypes.object.isRequired,
  sorting: PropTypes.object,
  loading: PropTypes.bool,
  isClient: PropTypes.bool,
  ssoActive: PropTypes.bool,
  onClickInviteBtn: PropTypes.func.isRequired,
  onClickAccessRoleExplainer: PropTypes.func.isRequired,
  onPaginationChange: PropTypes.func.isRequired,
  onSortingChange: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onEditUser: PropTypes.func.isRequired,
}

export default View
