import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ListGroup from 'components-v2/molecules/ListGroup'
import Card from 'components-v2/organisms/Card'
import { mixins } from 'styles'

const Wrapper = styled(Card)`
  border: 1px solid #ddd;
  margin-bottom: 22px;

  > *:not(:last-child) {
    border-bottom-color: #ddd;
  }

  ${ListGroup} {
    margin-bottom: 0;

    > * {
      border-radius: 0;
      border-width: 0;

      &:not(:first-child) {
        border-top-width: 1px;
      }
    }
  }
`

const Title = styled.div`
  ${mixins.smallHeader}
  font-size: 14px;
  padding: 10px 15px;
  background-color: #f5f5f5;
`

const CardWithTitle = ({ title, children, ...rest }) => (
  <Wrapper {...rest}>
    <Card.Segment padding={false}>
      <Title>{title}</Title>
    </Card.Segment>
    <Card.Segment padding={false}>{children}</Card.Segment>
  </Wrapper>
)

CardWithTitle.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default CardWithTitle
