import React from 'react'
import * as yup from 'yup'
import Icon from 'components-v2/atoms/Icon'
import FormField from 'components-v2/molecules/FormField'
import FormDialog from 'components-v2/organisms/FormDialog'

const formConfig = {
  initialValues: {
    sheet: undefined,
    completed_at: undefined,
  },
  validationSchema: yup.object().shape({
    sheet: yup.mixed().nullable().required('Please attach a spreadsheet'),
    completed_at: yup.date().nullable().required('This is required'),
  }),
}

const AnswerSetUploadModal = (props) => (
  <FormDialog
    {...props}
    title="Upload Answer Set"
    submitButtonLabel="Save"
    formConfig={formConfig}
  >
    <FormField
      name="completed_at"
      label="Date Completed"
      type={FormField.types.DATE}
      controlProps={{
        todayButton: 'Today',
      }}
      horizontal
    />
    <FormField
      name="sheet"
      label="File"
      type={FormField.types.FILE_INPUT}
      controlProps={{
        buttonContent: [
          <Icon key="icon" icon="fa fa-table" />,
          'Attach Spreadsheet',
        ],
      }}
      horizontal
    />
  </FormDialog>
)

export default AnswerSetUploadModal
