import styled from 'styled-components'
import DList from 'components-v2/molecules/DList'
import CheckboxField from 'components-v2/molecules/FormField/CheckboxField'
import { media } from 'styles'

export const Table = styled.table`
  width: 100%;

  tr {
    border-top: 1px solid #ddd;
  }

  td {
    padding: 8px;
    line-height: 1.428571429;
    vertical-align: top;
  }

  td:nth-child(1) {
    width: 350px;
  }

  ${media.mobile`
    tbody, tr, td {
      display: block;
      width: 100% !important;
    }
  `}
`

export const PreviewWrapper = styled.div`
  float: right;
`

export const GroupList = styled(DList)`
  margin-top: 5px;

  dt {
    font-size: 11px;
  }
`
export const GroupLine = styled.div`
  display: flex;
  justify-content: space-between;
`
export const CheckboxWrapper = styled.span`
  flex: 1;
  text-align: left;
`
export const CheckboxCell = styled(CheckboxField)`
  display: inline-block;
  padding-bottom: 2px;
`

export const EditIconWrapper = styled.span`
  align-self: flex-end;
`
