import React from 'react'
import PropTypes from 'prop-types'
import Markdown from 'components-v2/molecules/Markdown'
import { Unformatted } from './styles'

const UserEnteredTextRenderer = ({ text, formatted }) => {
  if (formatted) {
    return <Markdown content={text} />
  }

  return <Unformatted>{text}</Unformatted>
}

UserEnteredTextRenderer.propTypes = {
  text: PropTypes.string,
  formatted: PropTypes.bool,
}

export default UserEnteredTextRenderer
