import { useCallback } from 'react'
import { useModal } from 'hooks'
import AnswerFileModal from './AnswerFileModal'

export default function useShowAnswerFiles() {
  const [openModal] = useModal(AnswerFileModal)

  return useCallback(() => {
    openModal()
  }, [openModal])
}
