import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withReactQuery } from 'hocs'
import { useAssistQuestionSetFetcher } from 'apis'
import { ModeTypes } from './constants'
import useEditQuestionSetSettings from '../shared/useEditQuestionSetSettings'
import View from './View'

function QuestionSetView({
  question_set: questionSetProp,
  answers_count: answersCount,
}) {
  const { data: questionSet } = useAssistQuestionSetFetcher(
    { id: questionSetProp.id },
    { initialData: questionSetProp, refetchOnMount: false },
  )
  const [selectedQuestionId, setSelectedQuestionId] = useState(
    questionSet.questions[0]?.id,
  )
  const selectedQuestion = questionSet.questions.find(
    (e) => e.id === selectedQuestionId,
  )
  const [mode, setMode] = useState(ModeTypes.MAP)
  const editQuestionSetSettings = useEditQuestionSetSettings(questionSet.id)

  const handleQuestionClick = React.useCallback((id, newMode) => {
    setSelectedQuestionId(id)
    if (newMode) {
      setMode(newMode)
    }
  }, [])

  return (
    <View
      questionSet={questionSet}
      answersCount={answersCount}
      mode={mode}
      selectedQuestion={selectedQuestion}
      onModeChange={setMode}
      onQuestionSelect={handleQuestionClick}
      onSettingsButtonClick={editQuestionSetSettings}
    />
  )
}

QuestionSetView.propTypes = {
  question_set: PropTypes.object.isRequired,
  answers_count: PropTypes.number.isRequired,
}

export default withReactQuery()(QuestionSetView)
