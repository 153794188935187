import React from 'react'
import PropTypes from 'prop-types'
import { compose, hasLoader, hasError } from 'hocs'
// import { log, setLog } from 'utils/debug'
import AssessmentListingContainer from './AssessmentListingContainer'
// import AttachmentListingContainer from './AttachmentListingContainer'

const getComponent = (type) => {
  switch (type) {
    case 'Assessment':
      return AssessmentListingContainer
    // case 'Attachment':
    //   return AttachmentListingContainer
    default:
      return null
  }
}

const View = ({ share }) => {
  // in theory a share supports sharing other things besides a listing
  // but that doesn't exist yet, so for the moment we're shortcutting a bit
  const listingType = share?.listing?.listing_item_type
  const Component = getComponent(listingType)
  // setLog({ share })
  // log({ spot: 'share_view/view', listingType, Component })

  if (!Component) {
    return null
  }

  return (
    <Component libraryProfile={share.library_profile} data={share.listing} />
  )
}

View.propTypes = {
  share: PropTypes.object,
}

export default compose(hasLoader, hasError())(View)
