import styled from 'styled-components'
import { colors } from 'styles'

export const theme = {
  hGutter: 25,
}

export const Wrapper = styled.div`
  color: ${colors.BODY_TEXT};
`

export const CategoryWrapper = styled.div`
  margin-bottom: 20px;
`

export const CategoryHead = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  &:after {
    content: '';
    display: block;
    margin-left: 15px;
    height: 1px;
    flex: 1;
    background: #d7d7d7;
  }
`

export const CategoryName = styled.h3`
  font-size: 21px;
  color: ${colors.HEADING};
  margin: 0;
`

export const CategoryIconWrapper = styled.div`
  width: 31px;
  height: 31px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${colors.LIGHT_GREY};
  border-radius: 100%;
  margin-right: 9px;
  font-size: 14px;

  > * {
    opacity: 0.75;
  }
`

export const CategorySize = styled.span`
  color: ${colors.GREY};
  font-size: 20px;
  margin-top: 2px;
  margin-left: 9px;
`
