import React from 'react'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import FormField, { BaseField } from 'components-v2/molecules/FormField'
import { ClickableMessageBox } from './styles'

const CustomFormField = ({ multipleValueProperties, name, label, ...rest }) => {
  // Grab values and setFieldValue from context
  const { values, setFieldValue } = useFormikContext()
  const hasMultipleValues = multipleValueProperties.includes(name)

  // If this is a multiple-value and non-editable field
  if (hasMultipleValues && !values[name].enabled) {
    return (
      <BaseField label={label}>
        <ClickableMessageBox
          onClick={() => setFieldValue(`${name}.enabled`, true)}
        >
          <p>Multiple Values</p>
          {name === multipleValueProperties[0] && (
            <span className="small">
              The selected items contain different values for this input. To
              edit and set all items for this input to the same value, click or
              tap here, otherwise they will retain their individual values.
            </span>
          )}
        </ClickableMessageBox>
      </BaseField>
    )
  }

  return (
    <div>
      <FormField {...rest} name={`${name}.value`} label={label} />
      {hasMultipleValues && (
        <ClickableMessageBox
          onClick={() => setFieldValue(`${name}.enabled`, false)}
        >
          Undo changes
        </ClickableMessageBox>
      )}
    </div>
  )
}

CustomFormField.propTypes = {
  multipleValueProperties: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
}

export default CustomFormField
