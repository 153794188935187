import React from 'react'
import PropTypes from 'prop-types'
import { MultiLineTextWrapper } from './styles'

const MultiLineTextDisplay = ({ value }) => (
  <MultiLineTextWrapper>{value}</MultiLineTextWrapper>
)

MultiLineTextDisplay.propTypes = {
  value: PropTypes.string,
}

export default MultiLineTextDisplay
