// Detect if Weglot is present and if it was used to translate page
export function detectTranslationLangauge() {
  let lang = null

  if (
    typeof window.Weglot === 'object' &&
    Object.prototype.hasOwnProperty.call(window.Weglot, 'getCurrentLang')
  ) {
    lang = window.Weglot.getCurrentLang()
  }
  return lang
}
