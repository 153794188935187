// 3rd party
import React from 'react'
import styled from 'styled-components'
import { FileArchive } from '@styled-icons/fa-solid/FileArchive'

// system libs
import { useCheckClientAssessmentTemplateAttachmentPresence } from 'apis'
import { ExportFormatTypes } from 'constants/index'
import { useSimpleDialog, useGlobalLoader } from 'hooks'
import NotificationManager from 'lib/notifications'
import { getPageUrl } from 'utils/url'

// generalized components
import Icon from 'components-v2/atoms/Icon'
import A from 'components-v2/atoms/A'

// define styles
const Row = styled.div`
  margin: 5px;
`
const TableIcon = styled(Icon).attrs({ icon: 'fa fa-table' })`
  margin-right: 5px;
`
const ZipIcon = styled(Icon).attrs({ icon: FileArchive })`
  margin-right: 5px;
  vertical-align: baseline;
`

// utils
const getExcelExportUrl = (id) =>
  getPageUrl('clientAssessmentTemplateExcelExport', {
    id,
    format: ExportFormatTypes.XLSX,
  })

const getAttachmentsZipUrl = (id) =>
  getPageUrl('clientAssessmentTemplateAttachmentsZip', {
    id,
    format: ExportFormatTypes.ZIP,
  })

// actual hook
export default function useTemplateExcelExport() {
  const { mutateAsync: checkClientAssessmentTemplateAttachmentPresence } =
    useCheckClientAssessmentTemplateAttachmentPresence()
  const [openDialog, closeDialog] = useSimpleDialog()
  const [showLoader, hideLoader] = useGlobalLoader()

  return React.useCallback((templateId) => {
    const loaderId = showLoader()
    const excelExportUrl = getExcelExportUrl(templateId)
    const attachmentZipUrl = getAttachmentsZipUrl(templateId)
    checkClientAssessmentTemplateAttachmentPresence({ id: templateId })
      .then((hasFiles) => {
        if (!hasFiles) {
          window.location.href = excelExportUrl
        } else {
          openDialog({
            title: 'Export Template',
            children: (
              <>
                <Row>
                  Some questions in this template include supporting attachments
                </Row>
                <Row>
                  <A href={excelExportUrl}>
                    <TableIcon icon="fa fa-table" /> Download Template (XLSX)
                  </A>
                </Row>
                <Row>
                  <A href={attachmentZipUrl}>
                    <ZipIcon icon={FileArchive} /> Download Question Attachments
                    (ZIP)
                  </A>
                </Row>
              </>
            ),
            buttons: [
              {
                onClick: closeDialog,
                children: 'Close',
              },
            ],
          })
        }
        hideLoader(loaderId)
      })
      .catch(() => {
        NotificationManager.error()
        hideLoader(loaderId)
      })
  }, [])
}
