import { useCallback, useState } from 'react'
import get from 'lodash/get'
import isAfter from 'date-fns/isAfter'
import { useQueryClient } from '@tanstack/react-query'
import NotificationManager from 'lib/notifications'
import { useCurrent, useGlobalLoader, useConfirm, useModal } from 'hooks'
import DueDateFormDialog from 'components/client/shared/DueDateFormDialog'
import useAddProjectVendors from 'components/client/shared/useAddProjectVendors'
import {
  useClientProjectProjectVendorsFetcher,
  useUpdateClientProjectVendor,
  useLaunchClientProjectVendor,
  useRelaunchClientProjectVendor,
  useCancelClientProjectVendor,
  useDeleteClientProjectVendor,
} from 'apis'
import { toDate } from 'utils/date'
import { ProjectStatusTypes, LabelTypes } from 'constants/index'
import config from 'config'

const sizePerPage = config.collectionQueryPageLimit.projectVendorsTable

export default function useProjectVendors(
  project,
  showGloborg,
  onSave = () => {},
  onTablePageChange = () => {},
) {
  const queryClient = useQueryClient()
  const { mutateAsync: updateClientProjectVendor } =
    useUpdateClientProjectVendor()
  const { mutateAsync: launchClientProjectVendor } =
    useLaunchClientProjectVendor()
  const { mutateAsync: relaunchClientProjectVendor } =
    useRelaunchClientProjectVendor()
  const { mutateAsync: cancelClientProjectVendor } =
    useCancelClientProjectVendor()
  const { mutateAsync: deleteClientProjectVendor } =
    useDeleteClientProjectVendor()
  const confirm = useConfirm()
  const [showLoader, hideLoader] = useGlobalLoader()
  const addProjectVendors = useAddProjectVendors()
  const onSaveRef = useCurrent(onSave)
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState('')
  const [canceledExpanded, setCanceledExpanded] = useState(false)
  const [canceledPage, setCanceledPage] = useState(1)
  const [openDueDateFormDialog, closeDueDateFormDialog] =
    useModal(DueDateFormDialog)

  const {
    data: projectVendors,
    totalSize,
    isFetching,
    isError,
    refetch,
  } = useClientProjectProjectVendorsFetcher({
    projectId: project.id,
    params: {
      per_page: sizePerPage,
      page,
      ordered_by: 'vendor.name',
      direction: 'asc',
      search_query: searchText,
    },
  })
  const projectVendorsRef = useCurrent(projectVendors)

  const {
    data: canceledProjectVendors,
    totalSize: canceledTotalSize,
    isFetching: canceledLoading,
    isError: canceledError,
    refetch: refetchCanceled,
  } = useClientProjectProjectVendorsFetcher(
    {
      projectId: project.id,
      params: {
        per_page: sizePerPage,
        page: canceledPage,
        ordered_by: 'vendor.name',
        direction: 'asc',
        only_canceled: true,
      },
    },
    { enabled: canceledExpanded },
  )
  const canceledProjectVendorsRef = useCurrent(canceledProjectVendors)

  const setProjectVendorsData = useCallback(
    (value) => {
      queryClient.setQueriesData(
        {
          predicate: (query) =>
            query.queryKey[0] ===
              useClientProjectProjectVendorsFetcher.queryKey()[0] &&
            query.queryKey[1].projectId === project.id &&
            !query.queryKey[1].params?.only_canceled,
        },
        value,
      )
    },
    [queryClient, project.id],
  )

  const setProjectVendorState = useCallback(
    (projectVendor) => {
      setProjectVendorsData((current) => ({
        ...current,
        data: current.data.map((e) =>
          e.id === projectVendor.id ? projectVendor : e,
        ),
      }))
    },
    [setProjectVendorsData],
  )

  const onPaginationChange = useCallback(
    (state) => {
      setPage(state.page)
      onTablePageChange(state.page)
    },
    [onTablePageChange],
  )

  const onSearchChange = useCallback((newSearchText) => {
    // Let's reset page to 1
    setPage(1)
    setSearchText(newSearchText)
  }, [])

  const onAdd = useCallback(() => {
    addProjectVendors({
      projectId: project.id,
      showGloborg,
      showSuccessNotification: false,
      onProjectVendorsAdded: (response) => {
        setProjectVendorsData((current) => ({
          ...current,
          data: [...response, ...current.data].slice(0, sizePerPage),
          totalSize: current.totalSize + response.length,
        }))
        onSaveRef.current('Added', response)
      },
    })
  }, [
    project.id,
    showGloborg,
    addProjectVendors,
    setProjectVendorsData,
    onSaveRef,
  ])

  const handleAPIError = useCallback((err, defaultErrorMessage) => {
    // Log error
    if (get(err, 'response.status')) {
      console.error(
        `API error > status: ${err.response.status} >`,
        err.response.data,
      )
    } else {
      console.error(err)
    }
    // Create error notification
    if (get(err, 'response.data.errors')) {
      NotificationManager.error(err.response.data.errors)
    } else {
      NotificationManager.error(defaultErrorMessage)
    }
  }, [])

  const onLaunch = useCallback(
    (id) => {
      confirm({
        title: `Launch ${LabelTypes.VENDOR}`,
        body: `Are you sure you would like to launch this ${LabelTypes.VENDOR.toLowerCase()} now?`,
        onConfirm: () =>
          launchClientProjectVendor({ id })
            .then((data) => {
              setProjectVendorState(data)
              onSaveRef.current('Launched', data)
            })
            .catch((err) =>
              handleAPIError(
                err,
                `The ${LabelTypes.VENDOR.toLowerCase()} could not be launched. Please refresh the page and try again.`,
              ),
            ),
      })
    },
    [
      confirm,
      launchClientProjectVendor,
      setProjectVendorState,
      handleAPIError,
      onSaveRef,
    ],
  )

  const onRelaunch = useCallback(
    (id) => {
      const canceledProjectVendor = canceledProjectVendorsRef.current.find(
        (e) => e.id === id,
      )
      if (!canceledProjectVendor) {
        return
      }

      const launchDate = canceledProjectVendor.date_range.start_at
      if (
        !isAfter(toDate(canceledProjectVendor.date_range.end_at), new Date())
      ) {
        // Allow user to set new due date
        openDueDateFormDialog({
          onSubmit: ({ dueDate }) => {
            const loaderId = showLoader()
            const data = {
              project_vendor: {
                date_range_attributes: {
                  start_at: launchDate,
                  end_at: dueDate,
                },
              },
            }
            return updateClientProjectVendor({ id, data })
              .then(() => relaunchClientProjectVendor({ id }))
              .then((response) => {
                refetch()
                refetchCanceled()
                closeDueDateFormDialog()
                onSaveRef.current('Relaunched', response)
              })
              .catch((err) =>
                handleAPIError(
                  err,
                  `The ${LabelTypes.VENDOR.toLowerCase()} could not be relaunched. Please refresh the page and try again.`,
                ),
              )
              .then(() => hideLoader(loaderId))
          },
        })
        return
      }

      const loaderId = showLoader()
      relaunchClientProjectVendor({ id })
        .then((data) => {
          refetch()
          refetchCanceled()
          onSaveRef.current('Relaunched', data)
        })
        .catch((err) =>
          handleAPIError(
            err,
            `The ${LabelTypes.VENDOR.toLowerCase()} could not be relaunched. Please refresh the page and try again.`,
          ),
        )
        .then(() => hideLoader(loaderId))
    },
    [
      handleAPIError,
      showLoader,
      hideLoader,
      openDueDateFormDialog,
      closeDueDateFormDialog,
      updateClientProjectVendor,
      relaunchClientProjectVendor,
      refetch,
      refetchCanceled,
      canceledProjectVendorsRef,
      onSaveRef,
    ],
  )

  const onDatesChange = useCallback(
    (id, payload) => {
      const loaderId = showLoader()
      const data = {
        project_vendor: {
          date_range_attributes: payload,
        },
      }
      updateClientProjectVendor({ id, data })
        .then((response) => {
          setProjectVendorState(response)
          onSaveRef.current('DatesChanged', response)
        })
        .catch((err) =>
          handleAPIError(
            err,
            `The ${LabelTypes.VENDOR.toLowerCase()} could not be updated. Please refresh the page and try again.`,
          ),
        )
        .then(() => hideLoader(loaderId))
    },
    [
      updateClientProjectVendor,
      setProjectVendorState,
      handleAPIError,
      showLoader,
      hideLoader,
      onSaveRef,
    ],
  )

  const onCancel = useCallback(
    (id) => {
      const projectVendor = projectVendorsRef.current.find((e) => e.id === id)
      if (!projectVendor) {
        return
      }

      confirm({
        title: `Cancel ${LabelTypes.VENDOR}`,
        body: `Are you sure you want to cancel all assessments for ${projectVendor.vendor.name} for this project?`,
        onConfirm: () =>
          cancelClientProjectVendor({ id })
            .then((data) => {
              refetch()
              refetchCanceled()
              setCanceledExpanded(true)
              onSaveRef.current('Canceled', data)
            })
            .catch((err) =>
              handleAPIError(
                err,
                `The ${LabelTypes.VENDOR.toLowerCase()} could not be canceled. Please refresh the page and try again.`,
              ),
            ),
      })
    },
    [
      confirm,
      handleAPIError,
      refetch,
      refetchCanceled,
      cancelClientProjectVendor,
      setCanceledExpanded,
      projectVendorsRef,
      onSaveRef,
    ],
  )

  const onDelete = useCallback(
    (id) => {
      const loaderId = showLoader()
      deleteClientProjectVendor({ id })
        .then(() => {
          refetch()
          onSaveRef.current('Deleted', id)
        })
        .catch((err) =>
          handleAPIError(
            err,
            `The ${LabelTypes.VENDOR.toLowerCase()} could not be removed. Please refresh the page and try again.`,
          ),
        )
        .then(() => hideLoader(loaderId))
    },
    [
      deleteClientProjectVendor,
      handleAPIError,
      showLoader,
      hideLoader,
      refetch,
      onSaveRef,
    ],
  )

  const onCanceledExpand = useCallback(() => setCanceledExpanded(true), [])

  const onCanceledPaginationChange = useCallback((state) => {
    setCanceledPage(state.page)
  }, [])

  return {
    projectVendors,
    totalSize,
    loading: isFetching,
    error: isError,
    page,
    sizePerPage,
    searchText,
    canceledExpanded,
    canceledProjectVendors,
    canceledTotalSize,
    canceledLoading,
    canceledError,
    canceledPage,
    launchDisabled: project.status !== ProjectStatusTypes.LAUNCHED,
    onPaginationChange,
    onCanceledPaginationChange,
    onSearchChange,
    onAdd,
    onLaunch,
    onRelaunch,
    onDatesChange,
    onCancel,
    onDelete,
    onCanceledExpand,
  }
}
