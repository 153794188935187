import { AssessmentStatusTypes } from 'constants/index'
import { colors } from 'styles'

export const FilterTypes = {
  ALL: 'all',
  NOT_LAUNCHED: 'not_launched',
  INVITED: AssessmentStatusTypes.INVITED,
  IN_PROCESS: AssessmentStatusTypes.IN_PROCESS,
  SUBMITTED: AssessmentStatusTypes.SUBMITTED,
  REVIEWED: AssessmentStatusTypes.REVIEWED,
  EXPIRED: AssessmentStatusTypes.EXPIRED,
}

export const ActionMenuItemTypes = {
  RESEND_INVITE: 'resend_invite',
  DISABLE_REMINDERS: 'disable_reminders',
  EXTEND: 'extend',
}

export const FilterOptions = {
  [FilterTypes.ALL]: {
    label: 'Total vendors',
    color: '#404144',
  },
  [FilterTypes.NOT_LAUNCHED]: {
    label: 'Not launched',
    color: colors.DARK_GREY,
  },
  [FilterTypes.INVITED]: {
    label: 'Invited',
    color: colors.YELLOW,
  },
  [FilterTypes.IN_PROCESS]: {
    label: 'In process',
    color: colors.BLUE,
  },
  [FilterTypes.SUBMITTED]: {
    label: 'Submitted',
    color: colors.ATLANTIS,
  },
  [FilterTypes.REVIEWED]: {
    label: 'Reviewed',
    color: colors.GREEN,
  },
  [FilterTypes.EXPIRED]: {
    label: 'Expired',
    color: colors.RED,
  },
}
