import React from 'react'
import PropTypes from 'prop-types'
import Table from 'components-v2/molecules/Table'
import Toolbar, { SearchInput } from 'components-v2/molecules/Table/Toolbar'
import { pluralize } from 'utils/string'
import { getPartnerName } from './utils'
import { TopSection } from './styles'

const getTableColumns = () => [
  {
    id: 'type',
    header: 'Name',
    cell: (info) => getPartnerName(info.getValue()),
  },
  {
    id: 'credentials_ending_in',
    header: 'API Key',
    cell: (info) => (info.getValue() ? `*****${info.getValue()}` : ''),
  },
  {
    id: 'df',
    header: 'Custom Login URL',
    cell: (info) =>
      info.row.original.is_custom_login_url ? info.row.original.login_url : '',
  },
  {
    id: 'enabled',
    header: 'Enabled/Disabled',
    cell: (info) => (info.getValue() ? 'On' : 'Off'),
  },
]

const View = ({
  loading,
  partners,
  searchText,
  onSearchChange,
  onEditPartner,
}) => {
  const tableColumns = React.useMemo(() => getTableColumns(), [])
  return (
    <>
      <TopSection>
        {partners !== null && (
          <span>
            {`There are ${pluralize(
              partners.length,
              'partner',
            )} in this organization.`}
          </span>
        )}
      </TopSection>
      <Toolbar>
        <SearchInput
          value={searchText}
          placeholder="Search by..."
          onChange={onSearchChange}
        />
      </Toolbar>
      <Table
        loading={loading}
        data={partners || []}
        columns={tableColumns}
        globalFilter={searchText}
        noDataText="No partners found."
        type={Table.types.ALT}
        enablePagination={false}
        rowClasses={(row) => !row.original.enabled && 'disabled'}
        onRowClick={(row) => onEditPartner(row.original)}
      />
    </>
  )
}

View.propTypes = {
  loading: PropTypes.bool,
  partners: PropTypes.array,
  searchText: PropTypes.string,
  onSearchChange: PropTypes.func.isRequired,
  onEditPartner: PropTypes.func.isRequired,
}

export default View
