import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import split from 'lodash/split'
import Dialog from 'components-v2/molecules/Dialog'
import A from 'components-v2/atoms/A'
import Button from 'components-v2/atoms/Button'
import Accordion from 'components-v2/molecules/Accordion'
import DList from 'components-v2/molecules/DList'
import styled from 'styled-components'
const ExpandButtonRow = styled.div`
  text-align: right;
`
const TextBlock = styled.dd`
  p {
    text-indent: 25px;
  }
`

const formatText = (text) => {
  const parsed = split(text, /(?:\r\n|\r|\n)+/)
  return map(parsed, (p, idx) => <p key={`lgTxt-${idx}`}>{p}</p>)
}

const ContractClausesModal = ({
  assessment,
  clauseList,
  onDownloadClick,
  onClose,
  ...rest
}) => {
  /* This is a *complete* hack to get around react-accessible-accordion's restriction
     for not allowing direct control of accordion item states. They allow you to define
     a 'preExpanded' list of items to expand on mount, at which point you no longer have
     control.  So to toggle we umount, (un)define the list, and re-mount. ¯\_(ツ)_/¯
     Causes a brief visual blip, but it works.
   */
  const [doExpand, setDoExpand] = React.useState(false)
  const [displayList, setDisplayList] = React.useState(true)
  const [preExpanded, setPreExpanded] = React.useState([])
  const toggleAll = () => {
    setDoExpand(!doExpand)
  }
  React.useEffect(() => {
    setDisplayList(false)
  }, [doExpand])
  React.useEffect(() => {
    if (displayList === false) {
      setPreExpanded(preExpanded.length > 0 ? [] : map(clauseList, 'id'))
    }
  }, [displayList])
  React.useEffect(() => {
    setDisplayList(true)
  }, [preExpanded])

  const showClose = !!onDownloadClick
  return (
    <Dialog onClose={onClose} {...rest}>
      <Dialog.Header closeButton={showClose} onClose={onClose}>
        <center>
          <h2>AutoReport</h2>
          <h3>{assessment.vendor.name}</h3>
          <h3>{assessment.assessment_template.name}</h3>
        </center>
      </Dialog.Header>
      <Dialog.Content>
        <ExpandButtonRow>
          <A onClick={toggleAll}>{doExpand ? 'Collapse' : 'Expand'} All</A>
        </ExpandButtonRow>
        {displayList && (
          <Accordion
            allowMultipleExpanded
            allowZeroExpanded
            preExpanded={preExpanded}
          >
            {map(clauseList, (clause) => (
              <Accordion.Item
                uuid={clause.id}
                key={clause.name}
                title={clause.name}
              >
                <DList>
                  <dt />
                  <TextBlock>{formatText(clause.body)}</TextBlock>
                </DList>
              </Accordion.Item>
            ))}
          </Accordion>
        )}
      </Dialog.Content>
      <Dialog.Footer>
        {onDownloadClick && (
          <>
            <Button color="primary" onClick={onDownloadClick}>
              Download (DOCX)
            </Button>
            <Button onClick={onClose}>Close</Button>
          </>
        )}
      </Dialog.Footer>
    </Dialog>
  )
}

ContractClausesModal.propTypes = {
  assessment: PropTypes.object.isRequired,
  clauseList: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDownloadClick: PropTypes.func,
  onClose: PropTypes.func.isRequired,
}

export default ContractClausesModal
