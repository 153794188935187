import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from 'components-v2/molecules/Tooltip'
import { Wrapper, Title } from './styles'

const LargeCard = ({ title, description, children, ...rest }) => (
  <Wrapper {...rest}>
    <Title>
      {description ? (
        <Tooltip parent={<span>{title}</span>}>{description}</Tooltip>
      ) : (
        title
      )}
    </Title>
    {children}
  </Wrapper>
)

LargeCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node,
}

export default LargeCard
