import React from 'react'
import PrintButton from 'components-v2/molecules/PrintButton'
import PageHeader from 'components-v2/organisms/PageHeader'
import ResultsReport from 'components/client/shared/ResultsReport'
import { withAuthorize } from 'hocs'
import { getPageUrl } from 'utils/url'

const breadcrumbs = [
  {
    href: getPageUrl('clientAssessments'),
    title: 'Assessments',
  },
  {
    active: true,
    title: 'Results Report',
  },
]

const AssessmentsResultsReport = (props) => (
  <div>
    <PageHeader title="Assessment Results Report" breadcrumbs={breadcrumbs}>
      <PrintButton color="primary" />
    </PageHeader>
    <ResultsReport {...props} />
  </div>
)

export default withAuthorize()(AssessmentsResultsReport)
