import { colors } from 'styles'

export const RiskTierTypes = {
  TIER1: 1,
  TIER2: 2,
  TIER3: 3,
  TIER4: 4,
}

export const RiskTierOptions = [
  { value: RiskTierTypes.TIER1, label: 'Tier 1', color: colors.RED },
  { value: RiskTierTypes.TIER2, label: 'Tier 2', color: '#FC7F27' },
  { value: RiskTierTypes.TIER3, label: 'Tier 3', color: colors.YELLOW },
  { value: RiskTierTypes.TIER4, label: 'Tier 4', color: colors.GREEN },
]

export default RiskTierTypes
