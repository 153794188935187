import React from 'react'
import img from 'images/error/robot.png'
import ErrorView from '../shared/ErrorView'

export default () => (
  <ErrorView
    imageUrl={img}
    errorCode={404}
    errorType="File Not Found"
    errorMessage="The page you are looking for is not here. There's a slim possibility our robot ate it, sorry. Excuse us while we try to get it back."
  />
)
