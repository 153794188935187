import styled from 'styled-components'
import Button from 'components-v2/atoms/Button'
import { mixins, colors } from 'styles'

export const Wrapper = styled.div`
  font-size: 15px;
  padding: 15px 12px 11px;
`

export const Heading = styled.div`
  ${mixins.smallHeader}
  color: ${colors.HEADING};
  margin-bottom: 12px;
`

export const CreateButton = styled(Button).attrs({
  variant: 'text',
  color: 'primary',
})`
  font-size: 15px;
  padding: 0;
  border: none;
  margin-top: 12px;

  svg {
    font-size: 18px;
  }
`
