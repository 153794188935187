import React, { useState, useCallback } from 'react'
import Privva from 'Privva'
import { getProgressSummary } from 'lib/assessment'
import { useGlobalLoader, useConfirm, useModal } from 'hooks'
import NotificationManager from 'lib/notifications'
import { trackError } from 'lib/error_tracker'
import { detectTranslationLangauge } from 'lib/language'
import { useValidateVendorAssessment, useUpdateVendorAssessment } from 'apis'
import PromotionalModal from 'components/shared/PromotionalModal'
import {
  useSetElement,
  useSetSkippedElements,
} from './useAssessmentElementsFetcher'

export default function useSubmitAssessment(
  assessment,
  setAssessment,
  setElementFilter,
  setSelectedSectionId,
) {
  const [showPromotionalModal] = useModal(PromotionalModal)
  const [showLoader, hideLoader] = useGlobalLoader()
  const openConfirm = useConfirm()
  const { mutateAsync: validateVendorAssessment } =
    useValidateVendorAssessment()
  const { mutateAsync: updateVendorAssessment } = useUpdateVendorAssessment()
  const setElement = useSetElement(assessment.id)
  const setSkippedElements = useSetSkippedElements(assessment.id)
  const [incompleteAttempted, setIncompleteAttempted] = useState(false)

  const submitAssessment = useCallback(async () => {
    //  show loader
    //  request validate
    //  good: show confirm
    //    confirm: send submit
    //  bad:  show modal
    //  hide loader
    const loaderId = showLoader()
    try {
      await validateVendorAssessment({ id: assessment.id })
      const { answeredQuestions, totalQuestions } = getProgressSummary(
        assessment.progress,
      )
      const confirmMessage =
        answeredQuestions === totalQuestions
          ? 'Are you sure you are ready to submit this assessment? You will not be able to make any changes once submitted.'
          : 'You did not complete all the questions. Are you sure you want to submit this assessment? You will not be able to make changes once submitted.'
      openConfirm({
        title: 'Submitting',
        body: confirmMessage,
        onConfirm: () =>
          updateVendorAssessment({
            id: assessment.id,
            data: {
              assessment: {
                transition: 'submit',
                submission_language: detectTranslationLangauge(),
              },
            },
          })
            .then((data) => {
              // Update state using the api response
              // (why are we only updated a few fields instead of the whole thing?)
              setAssessment((current) => ({
                ...current,
                disabled: data.disabled,
                status: data.status,
                humanized_status: data.humanized_status,
              }))
              NotificationManager.success(
                'This assessment has been successfully submitted.',
              )
            })
            .catch((e) => {
              NotificationManager.error()
              trackError(e, e.response)
            })
            .then(() => {
              showPromotionalModal({
                parentOrgId: assessment.client.id,
                targetOrgId: assessment.vendor.id,
                placement:
                  Privva.Utils.promotionPlacements.VENDOR_ASSESSMENT_SUBMIT,
                promotionType: Privva.Utils.promotionTypes.MODAL_VIDEO,
              })
            }),
      })
    } catch (error) {
      if (error.response.status === 422) {
        try {
          const {
            progress,
            skipped_element_ids: skippedElementIds,
            created_responses: createdResponses, // object
          } = error.response.data
          setAssessment((current) => ({ ...current, progress }))
          setSkippedElements(skippedElementIds)
          if (createdResponses) {
            Object.values(createdResponses).forEach((response) => {
              setElement(response.element_id, (current) => ({
                ...current,
                response,
              }))
            })
          }

          const incompleteSections = assessment.sections
            .map((section) => ({
              ...section,
              incomplete: progress?.[section.id]?.incomplete ?? 0,
            }))
            .filter((s) => s.incomplete)
          openConfirm({
            title: 'Incomplete Requirements Remain',
            body: (
              <div>
                <p>
                  The following sections contain incomplete questions which must
                  be resolved before you can submit this assessment:
                </p>
                <ul>
                  {incompleteSections.map((section) => (
                    <li key={section.id}>
                      <strong>
                        Section {section.key} {section.label}:
                      </strong>
                      &nbsp;
                      {section.incomplete === 1
                        ? '1 incomplete question'
                        : `${section.incomplete} incomplete questions`}
                    </li>
                  ))}
                </ul>
                <p>
                  Click &quot;Show&quot; to go to the first section with
                  incomplete requirements.
                </p>
              </div>
            ),
            confirmText: 'Show',
            onConfirm: () => {
              setElementFilter({ 'ElementFilter.INCOMPLETE': true })
              setIncompleteAttempted(true)
              // Can this be false?
              if (incompleteSections.length > 0) {
                setSelectedSectionId(incompleteSections[0].id)
              }
            },
          })
        } catch (err) {
          console.error(err)
          NotificationManager.error()
        }
      } else {
        NotificationManager.error()
        trackError(error, error.response)
      }
    }
    hideLoader(loaderId)
  }, [
    assessment,
    setAssessment,
    setElementFilter,
    setSelectedSectionId,
    setElement,
    setSkippedElements,
    showLoader,
    hideLoader,
    openConfirm,
    showPromotionalModal,
    validateVendorAssessment,
    updateVendorAssessment,
  ])

  return {
    incompleteAttempted,
    submitAssessment,
  }
}
