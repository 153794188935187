import React from 'react'
import PropTypes from 'prop-types'
import { getPageUrl } from 'utils/url'
import NotificationBadge from 'components-v2/molecules/NotificationBadge'
import { MobileNavItem, NotificationCount } from './styles'

export default function NotificationPopover({
  userId,
  unopenedNotificationCount,
}) {
  return (
    <>
      <NotificationBadge
        unopenedNotificationCount={unopenedNotificationCount}
      />
      <MobileNavItem href={getPageUrl('userNotifications', { id: userId })}>
        Notifications&nbsp;&nbsp;
        <NotificationCount $hasUnopened={!!unopenedNotificationCount}>
          {unopenedNotificationCount}
        </NotificationCount>
      </MobileNavItem>
    </>
  )
}

NotificationPopover.propTypes = {
  userId: PropTypes.string.isRequired,
  unopenedNotificationCount: PropTypes.number,
}
