import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from 'components-v2/molecules/Tooltip'
import { Wrapper, NoteIcon } from './styles'

const ScoreCell = ({ color, label, note, ...rest }) => (
  <Wrapper {...rest} color={color}>
    <span>{label}</span>
    {note && (
      <Tooltip parent={NoteIcon} parentWrapperStyle={{ marginLeft: 5 }}>
        Note: {note}
      </Tooltip>
    )}
  </Wrapper>
)

ScoreCell.propTypes = {
  color: PropTypes.string,
  label: PropTypes.any,
  note: PropTypes.string,
}

export default ScoreCell
