import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  ComponentItem,
  EditorComponent,
  Remirror,
  Toolbar,
} from '@remirror/react'
import styled from 'styled-components'
import { Markdown as MarkdownIcon } from '@styled-icons/fa-brands/Markdown'
import Icon from 'components-v2/atoms/Icon'
import { useCurrent } from 'hooks'
import useMarkdownRemirror from '../useMarkdownRemirror'

const ToolbarWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'condensed',
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ condensed }) =>
    condensed &&
    `
    .remirror-button {
      font-size: 0.8em;
      padding: 0.175em 0.3em;
    }
  `}
`

const StyledIcon = styled(Icon).withConfig({
  shouldForwardProp: (prop) => prop !== 'condensed',
})`
  font-size: 30px;
  ${({ condensed }) =>
    condensed &&
    `
    height: 24px;
  `}
`

const VisualEditor = ({
  placeholder,
  value: valueProp,
  autoFocus,
  disabled,
  toolbarCondensed,
  onChange,
  onToggleEditorMode,
}) => {
  const [value, setValue] = useState(valueProp)
  const valueRef = useCurrent(value)
  const { manager, getContext } = useMarkdownRemirror(placeholder)
  const getContextRef = useCurrent(getContext)

  useEffect(() => {
    if (valueRef.current !== valueProp) {
      getContextRef.current().setContent(valueProp || '')
      setValue(valueProp)
    }
  }, [valueProp])

  const handleRemirrorChange = useCallback(
    ({ helpers, state }) => {
      const markdown = helpers.getMarkdown(state)
      setValue(markdown)
      onChange(markdown)
    },
    [onChange],
  )

  const handleToolbarClick = useCallback((e) => {
    // Prevent form submission on button click inside Toolbar
    e.stopPropagation()
    e.preventDefault()
  }, [])

  return (
    <Remirror
      manager={manager}
      autoFocus={autoFocus}
      disabled={disabled}
      initialContent={valueProp || ''}
      onChange={handleRemirrorChange}
    >
      <ToolbarWrapper condensed={toolbarCondensed} onClick={handleToolbarClick}>
        <Toolbar items={toolbarItems} refocusEditor label="Top Toolbar" />
        <StyledIcon
          icon={MarkdownIcon}
          condensed={toolbarCondensed}
          onClick={onToggleEditorMode}
        />
      </ToolbarWrapper>
      <EditorComponent />
    </Remirror>
  )
}

VisualEditor.propTypes = {
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  toolbarCondensed: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onToggleEditorMode: PropTypes.func.isRequired,
}

export default VisualEditor

const toolbarItems = [
  {
    type: ComponentItem.ToolbarGroup,
    label: 'Simple Formatting',
    items: [
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: 'toggleBold',
        display: 'icon',
      },
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: 'toggleItalic',
        display: 'icon',
      },
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: 'toggleStrike',
        display: 'icon',
      },
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: 'toggleCode',
        display: 'icon',
      },
    ],
    separator: 'end',
  },
  {
    type: ComponentItem.ToolbarGroup,
    label: 'Heading Formatting',
    items: [
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: 'toggleHeading',
        display: 'icon',
        attrs: { level: 1 },
      },
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: 'toggleHeading',
        display: 'icon',
        attrs: { level: 2 },
      },
      {
        type: ComponentItem.ToolbarMenu,

        items: [
          {
            type: ComponentItem.MenuGroup,
            role: 'radio',
            items: [
              {
                type: ComponentItem.MenuCommandPane,
                commandName: 'toggleHeading',
                attrs: { level: 3 },
              },
              {
                type: ComponentItem.MenuCommandPane,
                commandName: 'toggleHeading',
                attrs: { level: 4 },
              },
              {
                type: ComponentItem.MenuCommandPane,
                commandName: 'toggleHeading',
                attrs: { level: 5 },
              },
              {
                type: ComponentItem.MenuCommandPane,
                commandName: 'toggleHeading',
                attrs: { level: 6 },
              },
            ],
          },
        ],
      },
    ],
    separator: 'end',
  },
  {
    type: ComponentItem.ToolbarGroup,
    label: 'Lists',
    items: [
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: 'toggleBulletList',
        display: 'icon',
      },
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: 'toggleOrderedList',
        display: 'icon',
      },
    ],
    separator: 'end',
  },
  {
    type: ComponentItem.ToolbarGroup,
    label: 'Simple Formatting',
    items: [
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: 'toggleBlockquote',
        display: 'icon',
      },
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: 'toggleCodeBlock',
        display: 'icon',
      },
    ],
    separator: 'end',
  },
  {
    type: ComponentItem.ToolbarGroup,
    label: 'History',
    items: [
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: 'undo',
        display: 'icon',
      },
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: 'redo',
        display: 'icon',
      },
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: 'toggleColumns',
        display: 'icon',
        attrs: { count: 2 },
      },
    ],
    separator: 'none',
  },
]
