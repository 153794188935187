import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import BaseField from './BaseField'
import MultiCheckbox from '../MultiCheckbox'

const StyledMultiCheckbox = styled(MultiCheckbox)`
  margin: 7px 0;
`

const MultiCheckboxField = ({
  name,
  options,
  value,
  disabled,
  selectAllVisible,
  onChange,
  ...rest
}) => (
  <BaseField {...rest}>
    <StyledMultiCheckbox
      value={value}
      options={options}
      disabled={disabled}
      selectAllVisible={selectAllVisible}
      onChange={onChange}
    />
  </BaseField>
)

MultiCheckboxField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.array,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  selectAllVisible: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

MultiCheckboxField.defaultProps = {
  options: [],
  value: [],
}

export default MultiCheckboxField
