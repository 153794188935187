import React from 'react'
import styled from 'styled-components'
import ReactNumericInput from 'react-numeric-input'
import { styles } from 'components-v2/atoms/Input'

const StyledReactNumericInput = styled(ReactNumericInput)`
  ${styles}
`

const Weight = (props) => (
  <StyledReactNumericInput
    {...props}
    precision={null}
    onChange={(v) => handleChange(v, props)}
    onBlur={(e) => handleBlur(e, props)}
  />
)

const handleChange = (v, props) => {
  // Let's call onChange props only if value is not empty
  if (v || v === 0) {
    // We disabled the precision prop because it generates unnecessary zero decimals
    // Instead, let's round upto the given precision here
    let parsed = Number(
      `${Math.round(`${v}e${props.precision}`)}e-${props.precision}`,
    )

    // Validate min and max conditions
    parsed = Math.max(parsed, props.min)
    parsed = Math.min(parsed, props.max)

    // Finally, call onChange props
    props.onChange(parsed)
  }
}

const handleBlur = (e, props) => {
  // If input is empty on blur, let's fallback to zero
  if (e.target.value === '') {
    props.onChange(0)
  }
}

// Please refer https://www.npmjs.com/package/react-numeric-input for more information about props and default values
Weight.propTypes = ReactNumericInput.propTypes

Weight.defaultProps = {
  ...ReactNumericInput.defaultProps,
  min: 0,
  max: 10,
  step: 1,
  precision: 4,
  size: 5,
  mobile: true,
}

export default React.memo(Weight)
