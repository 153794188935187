/* eslint-disable react/no-danger */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Transition } from '@headlessui/react'
import Alert from 'components-v2/molecules/Alert'
import NotificationManager from 'lib/notifications'
import { withReactQuery } from 'hocs'
import { useDismissNowAnnouncements } from 'apis'
import { colors } from 'styles'

const Wrapper = styled.div`
  transition: 500ms max-height ease;
  overflow: hidden;

  &.leave-from {
    max-height: 100px;
  }

  &.leave-to {
    max-height: 0;
  }
`

const StyledAlert = styled(Alert)`
  color: ${colors.BODY_TEXT};
  background-color: ${colors.WHITE};
  border: none;
  border-bottom: 1px solid #eeeeee;
  border-radius: 0;
  margin: 0;
  text-align: center;
  font-size: 20px;
`

const Announcement = ({ banner }) => {
  const [visible, setVisible] = React.useState(true)
  const { mutateAsync: dismissNowAnnouncements } = useDismissNowAnnouncements()
  const handleDismiss = React.useCallback(async () => {
    try {
      await dismissNowAnnouncements()
      setVisible(false)
    } catch {
      NotificationManager.error()
    }
  }, [dismissNowAnnouncements])
  return (
    <Transition
      show={visible}
      as={Wrapper}
      leaveFrom="leave-from"
      leaveTo="leave-to"
    >
      <StyledAlert onDismiss={handleDismiss}>
        <i className="fa fa-bullhorn" />
        &nbsp;
        <span
          dangerouslySetInnerHTML={{
            __html: banner,
          }}
        />
      </StyledAlert>
    </Transition>
  )
}

Announcement.propTypes = {
  banner: PropTypes.string.isRequired,
}

export default withReactQuery()(Announcement)
