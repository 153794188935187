import styled from 'styled-components'
import Label from 'components-v2/atoms/Label'
import Card from 'components-v2/organisms/Card'

export const Wrapper = styled(Card).attrs({ bordered: true })`
  overflow: hidden;
  overflow-x: auto;

  table > thead {
    background: #d4d4d4;
  }
`

export const AssessmentRiskRatingLabel = styled(Label)`
  width: 50px;
`
