import styled, { css } from 'styled-components'

export const errorStyles = css`
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

  &:focus {
    border-color: #983d3b;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c16361;
  }
`

export const smallStyles = css`
  height: 33px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 3px;
`

export const styles = css`
  display: block;
  width: 100%;
  height: 38px;
  padding: 7px 12px;
  font-size: 16px;
  line-height: 1.428571429;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
  }

  &[disabled],
  &[readonly] {
    background-color: #eeeeee;
    opacity: 1;
  }

  ${({ error }) => error && errorStyles}

  ${({ size }) => ['sm', 'small'].includes(size) && smallStyles}
`

export default styled.input`
  ${styles}
`
