import React from 'react'
import PropTypes from 'prop-types'
import PrintButton from 'components-v2/molecules/PrintButton'
import ToolbarGroup from 'components-v2/molecules/ToolbarGroup'
import MultiSelectFilter from 'components-v2/molecules/MultiSelectFilter'
import DateRangeFilter from 'components-v2/molecules/DateRangeFilter'
import MenuDropdown from 'components-v2/molecules/MenuDropdown'
import Card from 'components-v2/organisms/Card'
import PageHeader from 'components-v2/organisms/PageHeader'
import InfiniteScroll from 'components-v2/organisms/InfiniteScroll'
import ContentWithFilterSidebar from 'components-v2/organisms/ContentWithFilterSidebar'
import { useAuthorizations } from 'hooks'
import {
  getMultiSelectFilterSelectedCount,
  formatMultiSelectFilterValue,
} from 'lib/multiSelectFilter'
import { toLocalTimeString } from 'utils/date'
import {
  ExportFormatTypes,
  LabelTypes,
  RiskTierOptions,
  // AssessmentStatusOptions,
} from 'constants/index'
import ComposedChartWidget from './ComposedChartWidget'
import ChartTableWidget from './ChartTableWidget'
import { getMultiSelectFilterLabelWithSelectedCount } from './utils'
import { TableTitle, PrintViewInfo } from './styles'

const archiveOptions = [
  { value: 'vendors', label: `Include Archived ${LabelTypes.VENDORS}` },
]

const datePickerProps = {
  dateFormat: 'MM/yyyy',
  showMonthYearPicker: true,
  portalId: 'datepicker-portal',
}

const View = ({
  riskTier,
  archivedValues,
  assessmentTemplate,
  assignee,
  vendorTag,
  assessRange,
  createRange,
  clientTagsFetcherResult,
  assessmentTemplatesFetcherResult,
  usersFetcherResult,
  detail,
  summary,
  onArchivedValuesChange,
  onAssessmentTemplateChange,
  onRiskTierChange,
  onAssigneeChange,
  onVendorTagChange,
  onAssessRangeChange,
  onCreateRangeChange,
  onExport,
}) => {
  const [canExport] = useAuthorizations('export')
  const multiSelectFilters = [
    {
      uuid: 'archived',
      label: `Archived ${LabelTypes.VENDORS}`,
      value: archivedValues,
      options: archiveOptions,
      onChange: onArchivedValuesChange,
      selectAllVisible: false,
    },
    {
      uuid: 'vendorLabels',
      label: `${LabelTypes.VENDOR} Labels`,
      value: vendorTag,
      options: clientTagsFetcherResult.data || [],
      getOptionValue: (e) => e.id,
      getOptionLabel: (e) => e.name,
      onChange: onVendorTagChange,
      selectAllVisible: true,
      matchVisible: true,
    },
    {
      uuid: 'vendorRiskTiers',
      label: `${LabelTypes.VENDOR} Risk Tiers`,
      value: riskTier,
      options: [...RiskTierOptions, { value: 'none', label: 'None' }],
      onChange: onRiskTierChange,
      selectAllVisible: true,
    },

    {
      uuid: 'assignee',
      label: 'Assessment Assignees',
      value: assignee,
      options: usersFetcherResult.data || [],
      getOptionValue: (e) => e.id,
      getOptionLabel: (e) => e.name,
      onChange: onAssigneeChange,
      otherVisible: true,
      otherLabel: 'Unassigned',
      collectionQueryResult: usersFetcherResult,
    },
    {
      uuid: 'assessmentTemplates',
      label: 'Assessment Templates',
      value: assessmentTemplate,
      options: assessmentTemplatesFetcherResult.data || [],
      getOptionValue: (e) => e.id,
      getOptionLabel: (e) => e.name,
      onChange: onAssessmentTemplateChange,
      collectionQueryResult: assessmentTemplatesFetcherResult,
    },
  ]
  return (
    <>
      <PageHeader title={`${LabelTypes.VENDOR} Assessments Status`}>
        <ToolbarGroup>
          <PrintButton color={canExport ? 'default' : 'primary'} />
          {canExport && (
            <MenuDropdown
              title="Export"
              icon="fa fa-table"
              buttonProps={{ color: 'primary' }}
              onSelect={onExport}
            >
              <MenuDropdown.Item eventKey={ExportFormatTypes.CSV}>
                <i className="fa fa-table" />
                Export (CSV)
              </MenuDropdown.Item>
              <MenuDropdown.Item eventKey={ExportFormatTypes.XLSX}>
                <i className="fa fa-table" />
                Export (XLS)
              </MenuDropdown.Item>
            </MenuDropdown>
          )}
        </ToolbarGroup>
      </PageHeader>
      <ContentWithFilterSidebar
        filters={
          <>
            {multiSelectFilters.map(
              ({ uuid, label, value, collectionQueryResult, ...rest }) => (
                <ContentWithFilterSidebar.AccordionItem
                  key={uuid}
                  uuid={uuid}
                  title={getMultiSelectFilterLabelWithSelectedCount(
                    label,
                    value,
                  )}
                >
                  {collectionQueryResult ? (
                    <InfiniteScroll
                      loadMore={collectionQueryResult.fetchNextPage}
                      hasMore={collectionQueryResult.hasNextPage}
                      isLoading={collectionQueryResult.isFetchingNextPage}
                      threshold={30}
                      useWindow={false}
                    >
                      <MultiSelectFilter {...rest} value={value} />
                    </InfiniteScroll>
                  ) : (
                    <MultiSelectFilter {...rest} value={value} />
                  )}
                </ContentWithFilterSidebar.AccordionItem>
              ),
            )}
            <ContentWithFilterSidebar.AccordionItem
              key="createRange"
              uuid="createRange"
              title={`${LabelTypes.VENDOR} Created Between`}
            >
              <DateRangeFilter
                value={createRange}
                onChange={onCreateRangeChange}
                minDate={new Date('2017-01-01')}
                maxDate={
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 2,
                    0,
                  )
                }
                startLabel="Start Month"
                endLabel="End Month"
                datePickerProps={datePickerProps}
              />
            </ContentWithFilterSidebar.AccordionItem>
            <ContentWithFilterSidebar.AccordionItem
              key="assessRange"
              uuid="assessRange"
              title="Assessments Sent Between"
            >
              <DateRangeFilter
                value={assessRange}
                onChange={onAssessRangeChange}
                minDate={new Date('2017-01-01')}
                maxDate={
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 2,
                    0,
                  )
                }
                startLabel="Start Month"
                endLabel="End Month"
                datePickerProps={datePickerProps}
              />
            </ContentWithFilterSidebar.AccordionItem>
          </>
        }
      >
        <Card padding>
          <PrintViewInfo>
            <b>Date Created:</b> {toLocalTimeString(new Date(), 'MM/dd/yyyy')}
            <br />
            {/* <b>Total Vendors:</b> 11
            <br />
            <b>Average Risk Rating:</b> 52
            <br /> */}
            {multiSelectFilters.map(({ uuid, value, label, ...rest }) => {
              if (!getMultiSelectFilterSelectedCount(value)) {
                return null
              }
              return (
                <React.Fragment key={uuid}>
                  <b>{label}:</b>&nbsp;
                  {formatMultiSelectFilterValue({ value, ...rest })}
                  <br />
                </React.Fragment>
              )
            })}
            {(createRange?.startDate || createRange?.endDate) && (
              <>
                <b>{`${LabelTypes.VENDOR} Created Range:`}</b>&nbsp;
                {createRange?.startDate &&
                  toLocalTimeString(createRange.startDate, 'MM/yyyy')}{' '}
                ~
                {createRange?.endDate &&
                  toLocalTimeString(createRange.endDate, 'MM/yyyy')}
              </>
            )}
            {(assessRange?.startDate || assessRange?.endDate) && (
              <>
                <b>Assessment Sent Range:</b>&nbsp;
                {assessRange?.startDate &&
                  toLocalTimeString(assessRange.startDate, 'MM/yyyy')}{' '}
                ~
                {assessRange?.endDate &&
                  toLocalTimeString(assessRange.endDate, 'MM/yyyy')}
              </>
            )}
          </PrintViewInfo>
          <ComposedChartWidget chartData={summary} />
          <TableTitle>Details</TableTitle>
          <ChartTableWidget data={detail} totals={summary} />
        </Card>
      </ContentWithFilterSidebar>
    </>
  )
}

View.propTypes = {
  riskTier: PropTypes.object,
  archivedValues: PropTypes.object,
  assessmentTemplate: PropTypes.object,
  assignee: PropTypes.object,
  vendorTag: PropTypes.object,
  assessRange: PropTypes.object,
  createRange: PropTypes.object,
  clientTagsFetcherResult: PropTypes.object.isRequired,
  assessmentTemplatesFetcherResult: PropTypes.object.isRequired,
  usersFetcherResult: PropTypes.object.isRequired,
  summary: PropTypes.array,
  detail: PropTypes.array,
  onArchivedValuesChange: PropTypes.func.isRequired,
  onAssessmentTemplateChange: PropTypes.func.isRequired,
  onRiskTierChange: PropTypes.func.isRequired,
  onAssigneeChange: PropTypes.func.isRequired,
  onVendorTagChange: PropTypes.func.isRequired,
  onAssessRangeChange: PropTypes.func.isRequired,
  onCreateRangeChange: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
}

export default View
