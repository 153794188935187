import PropTypes from 'prop-types'
import React from 'react'
import DList from 'components-v2/molecules/DList'
import InfoCard from '../InfoCard'
import TagList from '../TagList'

const DetailsCard = ({ globalOrg }) => (
  <InfoCard title="Details">
    <DList horizontal>
      <dt>Legal name</dt>
      <dd>{globalOrg.legal_name}</dd>
      <dt>Founded</dt>
      <dd>{globalOrg.founded_year}</dd>
      <dt>Employee range</dt>
      <dd>{globalOrg.employees_range}</dd>
      <dt>EIN</dt>
      <dd>{globalOrg.us_ein}</dd>
      <dt>Type</dt>
      <dd>{globalOrg.company_type}</dd>
      <dt>SIC</dt>
      <dd>{globalOrg.sic_code}</dd>
      <dt>NAIC code</dt>
      <dd>{globalOrg.naics_code}</dd>
      <dt>Industry classification</dt>
      <dd></dd>
      <dt>Tags</dt>
      <dd>
        <TagList list={globalOrg.tags} plural="tags" />
      </dd>
    </DList>
  </InfoCard>
)

DetailsCard.propTypes = {
  globalOrg: PropTypes.object.isRequired,
}

export default DetailsCard
