import React from 'react'
import PropTypes from 'prop-types'
import { toFromNowString } from 'utils/date'
import Card from 'components-v2/organisms/Card'
import { Header, Title, UpdatedAt } from './styles'

const ContentCardWithTimeMeta = ({ title, updatedAt, children, ...rest }) => (
  <Card {...rest} padding>
    <Header>
      <Title>{title}</Title>
      {updatedAt && (
        <UpdatedAt>
          Updated {toFromNowString(updatedAt, { addSuffix: true })}
        </UpdatedAt>
      )}
    </Header>
    {children}
  </Card>
)

ContentCardWithTimeMeta.propTypes = {
  title: PropTypes.string.isRequired,
  updatedAt: PropTypes.any,
  children: PropTypes.node,
}

export default ContentCardWithTimeMeta
