import styled from 'styled-components'

export const Row = styled.div`
  margin: 6px;
  padding: 5px;
`

export const NoteRow = styled(Row)`
  text-align: right;
`

export const TierRow = styled.div`
  display: flex;
  margin-top: 2px;
  ${({ color }) => color && `color: ${color};`}
`

export const TierHeadRow = styled(TierRow)`
  font-weight: bold;
`

export const LeftCol = styled.div`
  flex: 1;
  margin-left: 30%;
`

export const MidCol = styled.div`
  flex: 1;
`

export const RightCol = styled.div`
  flex: 1;
  margin-right: 30%;
  text-align: right;
`

export const LeftColHead = styled(LeftCol)`
  text-align: center;
`
