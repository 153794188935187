import { useCallback } from 'react'
import { useGlobalLoader } from 'hooks'
import NotificationManager from 'lib/notifications'
import { useUpdateClientProject } from 'apis'

const ASSESSMENT_TEMPLATES_MAX_LIMIT = 6

export default function useCRUDAssessmentTemplate(project) {
  const { mutateAsync: updateClientProject } = useUpdateClientProject()
  const [showLoader, hideLoader] = useGlobalLoader()

  const addAssessmentTemplate = useCallback(
    (id) => {
      if (
        project.assessment_templates.length >= ASSESSMENT_TEMPLATES_MAX_LIMIT
      ) {
        NotificationManager.error(
          `Only ${ASSESSMENT_TEMPLATES_MAX_LIMIT} templates are allowed per project`,
        )
        return
      }
      const loaderId = showLoader()
      const assessment_template_ids = [
        ...project.assessment_templates.map((e) => e.id),
        id,
      ]
      updateClientProject({
        id: project.id,
        data: {
          project: { assessment_template_ids },
        },
      })
        .catch((error) => {
          // Log error
          if (error?.response?.status) {
            console.error(
              `API error > status: ${error.response.status} >`,
              error.response.data,
            )
          } else {
            console.error(error)
          }
          // Create error notification
          if (error?.response?.data?.errors) {
            NotificationManager.error(error.response.data.errors)
          } else {
            NotificationManager.error(
              `There was an error updating the project. Please refresh the page and try again.`,
            )
          }
        })
        .then(() => hideLoader(loaderId))
    },
    [project.id, project.assessment_templates, showLoader, hideLoader],
  )

  const deleteAssessmentTemplate = useCallback(
    (id) => {
      const loaderId = showLoader()
      const assessment_template_ids = project.assessment_templates
        .map((e) => e.id)
        .filter((e) => e !== id)
      updateClientProject({
        id: project.id,
        data: {
          project: { assessment_template_ids },
        },
      })
        .catch((error) => {
          console.error(error)
          NotificationManager.error()
        })
        .then(() => hideLoader(loaderId))
    },
    [project.id, project.assessment_templates, showLoader, hideLoader],
  )

  return {
    addAssessmentTemplate,
    deleteAssessmentTemplate,
  }
}
