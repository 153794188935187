import React from 'react'
import PropTypes from 'prop-types'
import Toggle from 'components-v2/atoms/Toggle'
import {
  Wrapper,
  Label,
  Content,
  Description,
  Radio,
  RadioOption,
} from './styles'
import AssessmentApprovalOption from './AssessmentApprovalOption'

const AssessmentVisibility = ({
  visible,
  onChange,
  approvalRequired,
  onSelect,
}) => (
  <Wrapper>
    <Label>Assessment Visibililty</Label>
    <Content>
      <Description>
        Toggle this assessment’s visibility on your profile. Invisible files may
        still be shared with clients, they just won’t be available on request or
        viewable to the public.
      </Description>
      <div>
        <Toggle checked={visible} onChange={onChange} />
      </div>
    </Content>
    {visible && (
      <Radio value={approvalRequired} onChange={onSelect}>
        <RadioOption value>
          {({ checked }) => (
            <AssessmentApprovalOption
              icon="fa fa-lock"
              title="Available Upon Request"
              content="Manually approve or deny client’s requests to view this file."
              checked={checked}
            />
          )}
        </RadioOption>
        <RadioOption value={false}>
          {({ checked }) => (
            <AssessmentApprovalOption
              icon="fa fa-globe"
              title="Browsable"
              content="Anyone using the vendor library will have access to browse this assessment."
              checked={checked}
            />
          )}
        </RadioOption>
      </Radio>
    )}
  </Wrapper>
)

AssessmentVisibility.propTypes = {
  visible: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  approvalRequired: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default AssessmentVisibility
