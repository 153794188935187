import styled from 'styled-components'
import RatingSelect from 'components-v2/molecules/RatingSelect'
import IconButton from 'components-v2/atoms/IconButton'
import { mixins } from 'styles'

export const OriginalAssessmentLink = styled.a`
  ${mixins.truncate}
  max-width: 250px;
  display: inline-block;
`

export const StatusRatingSelect = styled(RatingSelect).attrs({
  RenderMarker: styled(RatingSelect.Marker)`
    background: ${(props) => (props.active ? 'royalblue' : '#EEE')};
    border: 1px solid rgba(0, 0, 0, 0.2);
  `,
  RenderLabel: styled(RatingSelect.Label)`
    font-size: 8pt;
    font-weight: 700;
  `,
  markerHeight: 15,
})``

export const EllipsisIconButton = styled(IconButton).attrs({
  icon: 'fa fa-ellipsis-h',
})``
