import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Left, Right } from './styles'

const Capsule = ({ left, right, color, ...rest }) => (
  <Wrapper $color={color} {...rest}>
    <Left $color={color}>{left}</Left>
    <Right>{right}</Right>
  </Wrapper>
)

Capsule.propTypes = {
  left: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.number,
    PropTypes.string,
  ]),
  right: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.number,
    PropTypes.string,
  ]),
  color: PropTypes.string,
}

export default Capsule
