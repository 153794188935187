import config from 'config'
import ColumnMap from 'lib/column_map'

export const ActionMenuItemTypes = {
  EXPORT_CURRENT_VIEW: 'export_current_view',
  STATUS_REPORT: 'status_report',
  DOWNLOAD_SCORES: 'download_scores',
  RESULTS_REPORT: 'results_report',
  VENDOR_ANALYSIS_REPORT: 'vendor_analysis_report',
  CYCLE_TIME_REPORT: 'cycle_time_report',
  VENDOR_ANSWER_COMPARISON_REPORT: 'vendor_answer_comparison_report',
}

// NOTE: Do NOT change the order below; it's unrelated
// to display ordering, but user preference indexing
// relies on it staying frozen. Any new columns can
// simply be added to the end.
export const COL_NAMES = new ColumnMap({
  ID: 'id',
  VENDOR_NAME: 'vendor.name',
  RISK_RATING: 'risk_rating',
  GRADE: 'grade',
  ASSESSMENT_TEMPLATE_NAME: 'assessment_template.name',
  PROJECT_NAME: 'project.friendly_name',
  STATUS: 'status',
  SENT_AT: 'sent_at',
  SUBMIT_AT: 'submit_at',
  REVIEWED_AT: 'reviewed_at',
  EXPIRED_AT: 'expired_at',
  DUE_AT: 'due_at',
  VENDOR_NEXT_REASSESSMENT_AT: 'vendor.next_reassessment_at',
  PROGRESS: 'progress',
  CLIENT_ASSIGNEE_NAME: 'client_assignee.name',
  PARENT_ORG: 'vendor.parent_org_id',
  VENDOR_RISK_TIER: 'vendor.risk_tier',
  RISK_RECON_SCORE: 'risk_recon',
  OSANO_SCORE: 'osano',
  ARGOS_SCORE: 'argos_risk',
})

export const archiveOptions = [
  {
    value: 'projects',
    label: 'Projects',
  },
  {
    value: 'vendors',
    label: 'Vendors',
  },
]

export const initialVisibleFilters = [
  'statuses',
  'assignees',
  'riskTiers',
  'vendorTags',
]

export const queryParamsConfig = {
  page: {
    type: Number,
    defaultValue: 1,
  },
  sizePerPage: {
    queryKey: 'per_page',
    type: Number,
    defaultValue: config.collectionQueryPageLimit.default,
  },
  sortField: {
    queryKey: 'ordered_by',
  },
  sortOrder: {
    queryKey: 'direction',
  },
  searchText: {
    queryKey: 'q',
    defaultValue: '',
  },
  statuses: {
    queryKey: 'status',
    defaultValue: [],
    isArray: true,
  },
  assignees: {
    queryKey: 'assignee',
    defaultValue: [],
    isArray: true,
  },
  unassigned: {
    type: Boolean,
    defaultValue: false,
  },
  assessmentTemplates: {
    queryKey: 'assessmentTemplate',
    defaultValue: [],
    isArray: true,
  },
  vendorTags: {
    queryKey: 'vendorTag',
    defaultValue: [],
    isArray: true,
  },
  vendorTagsAnyMatch: {
    queryKey: 'vendorTagsAnyMatch',
    type: Boolean,
    defaultValue: true,
  },
  riskTiers: {
    queryKey: 'riskTier',
    type: (e) => (Number.isNaN(parseInt(e, 10)) ? e : parseInt(e, 10)),
    defaultValue: [],
    isArray: true,
  },
  archiveValues: {
    queryKey: 'include_archived',
    defaultValue: [],
    isArray: true,
  },
  dateType: {
    queryKey: 'date_type',
  },
  startDate: {
    queryKey: 'start_date',
    type: Date,
    dateFormat: 'yyyy-MM-dd',
  },
  endDate: {
    queryKey: 'end_date',
    type: Date,
    dateFormat: 'yyyy-MM-dd',
  },
}
