import React, { useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import Dialog from 'components-v2/molecules/Dialog'
import Button from 'components-v2/atoms/Button'
import RiskRatingSummary from 'components/shared/AssessmentRiskRatingSummary'
import { printComponent } from 'utils/print'
import { ExtLogo, DialogHeader, PrintHeader } from './styles'

const RiskRatingSummaryModal = ({
  assessment,
  currentOrganization,
  onClose,
  ...rest
}) => {
  const modalBodyRef = useRef()

  const handlePrint = useCallback(() => {
    printComponent(modalBodyRef.current)
  }, [])

  return (
    <Dialog {...rest} onClose={onClose} size="large">
      <DialogHeader onClose={onClose}>
        Summary Report
        <h5>{get(assessment, 'vendor.name')}</h5>
        <h5>{get(assessment, 'name')}</h5>
      </DialogHeader>
      <Dialog.Content ref={modalBodyRef}>
        <PrintHeader>
          <ExtLogo src={currentOrganization.external_logo?.url} />
          <h3>Summary Report</h3>
          <h5>{assessment.vendor.name}</h5>
          <h5>{assessment.name}</h5>
        </PrintHeader>
        <RiskRatingSummary assessmentId={assessment.id} />
      </Dialog.Content>
      <Dialog.Footer>
        <Button color="primary" onClick={handlePrint}>
          <i className="fa fa-print" /> Print
        </Button>
        <Button onClick={onClose}>Close</Button>
      </Dialog.Footer>
    </Dialog>
  )
}

RiskRatingSummaryModal.propTypes = {
  assessment: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  currentOrganization: PropTypes.object.isRequired,
}

export default RiskRatingSummaryModal
