import styled from 'styled-components'

export const TabPaneTitle = styled.h3`
  margin-top: 0px;
`

export const SmallWidthContent = styled.div`
  width: 750px;
  max-width: 100%;
`
