import styled from 'styled-components'
import { mixins } from 'styles'

export const Heading = styled.div`
  ${mixins.smallHeader}
  margin-bottom: 5px;
`

export const Footer = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`
