import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import filter from 'lodash/filter'
import get from 'lodash/get'
import without from 'lodash/without'
import map from 'lodash/map'
import { SecurityObjectiveStatusTypes } from 'constants/index'
import SecuritySenseDashboard from '../SecuritySenseDashboard'
import { SecurityObjectiveCategories } from '../constants'

const demoData = {
  'Change Management': SecurityObjectiveStatusTypes.NO_COVERAGE,
  'IT Operations Management': SecurityObjectiveStatusTypes.REQUESTED,
  Background: SecurityObjectiveStatusTypes.REQUESTED,
  'Mobile Management': SecurityObjectiveStatusTypes.REQUESTED,
}

function getAllObjectivesByCategory(sources) {
  const connectedObjectives = sources.map((source) => {
    const category = get(source, 'metrics[0].category.name')
    const objective = get(source, 'metrics[0].objective.name')
    return {
      id: source.id,
      name: objective,
      category,
      status: SecurityObjectiveStatusTypes.CONNECTED,
      source,
    }
  })
  const result = {}
  SecurityObjectiveCategories.forEach(
    ({ name, objectives: objectiveNames }) => {
      const objectives = filter(connectedObjectives, { category: name })
      const unconnectedObjectives = without(
        objectiveNames,
        ...map(objectives, 'name'),
      )
      unconnectedObjectives.forEach((objective) => {
        objectives.push({
          id: objective,
          name: objective,
          category: name,
          status:
            demoData[objective] || SecurityObjectiveStatusTypes.NOT_APPLICABLE,
        })
      })
      result[name] = objectives
    },
  )
  return result
}

const Container = ({ partner }) => {
  const [objectivesByCategory, setObjectivesByCategory] = React.useState()
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(false)

  React.useEffect(() => {
    axios.get(partner.feed_api_path).then((response) => {
      const feedData = response.data
      if (feedData.sources) {
        setObjectivesByCategory(getAllObjectivesByCategory(feedData.sources))
        setLoading(false)
      } else {
        setLoading(false)
        setError(true)
      }
    })
  }, [])

  return (
    <SecuritySenseDashboard
      title="Vendor: Saturn Networks"
      objectivesByCategory={objectivesByCategory}
      loading={loading}
      error={error}
    />
  )
}

Container.propTypes = {
  partner: PropTypes.object.isRequired,
}

export default Container
