import PropTypes from 'prop-types'
import React from 'react'
import round from 'lodash/round'
import isEmpty from 'lodash/isEmpty'
import Table from 'components-v2/molecules/Table'
import { TableWrapper } from './styles'

const columns = [
  {
    id: 'status',
    header: 'Status',
  },
  {
    id: 'averageDays',
    header: 'Average Days',
  },
  {
    id: 'maxDays',
    header: 'Max Days',
  },
]

const StatusTableWidget = ({ data }) => {
  const rows = React.useMemo(() => {
    if (isEmpty(data)) {
      return null
    }
    const groups = ['Time to Start', 'Time to Submit', 'Time to Review'].map(
      (cat) => [cat.titleize(), data[`${cat}_avg`], data[`${cat}_max`]],
    )
    return groups.map((r) => ({
      status: r[0],
      averageDays: round(r[1]),
      maxDays: round(r[2]),
    }))
  }, [data])
  return (
    <TableWrapper>
      <Table
        data={rows ?? []}
        columns={columns}
        enableSorting={false}
        enablePagination={false}
      />
    </TableWrapper>
  )
}

StatusTableWidget.propTypes = {
  data: PropTypes.object,
}

export default StatusTableWidget
