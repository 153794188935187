import React from 'react'
import get from 'lodash/get'
import map from 'lodash/map'
import Tooltip from 'components-v2/molecules/Tooltip'
import GradeLabel from 'components/shared/GradeLabel'
import AssessmentProgressBar from 'components/shared/AssessmentProgressBar'
import { dateFormatterFactory } from 'lib/table'
import { getRiskRatingLabel, getRiskRatingColor } from 'lib/assessment'
import { AssessmentStatusTypes, LabelTypes } from 'constants/index'
import { vendorRiskTierFormatterFactory } from 'lib/vendor'
import {
  parseOsanoData,
  parseArgosRiskData,
  getRiskReconRatingColor,
} from 'components/client/lib/globorg_integration_metrics'
import {
  StatusLabel,
  RiskRatingLabel,
  SubmittedFile,
  ArgosRiskScoreCapsule,
  RisReconScoreCapsule,
  OsanoScoreCapsule,
} from './styles'
import { COL_NAMES } from './constants'

const vendorRiskTierFormatter = vendorRiskTierFormatterFactory()
const dateFormatter = dateFormatterFactory()
const allColumns = [
  {
    id: COL_NAMES.VENDOR_NAME,
    header: LabelTypes.VENDOR,
    minWidth: '19%',
  },
  {
    id: COL_NAMES.VENDOR_RISK_TIER,
    header: `${LabelTypes.VENDOR} Risk Tier`,
    cell: vendorRiskTierFormatter,
    hidden: true,
    width: '9%',
  },
  {
    id: COL_NAMES.PARENT_ORG,
    header: `Parent ${LabelTypes.VENDOR}`,
    cell: (props) => get(props.row.original, 'vendor.parent_org.name', ''),
    width: '19%',
  },
  {
    id: COL_NAMES.RISK_RATING,
    header: 'Rating',
    cell: (props) => {
      const riskRating =
        [
          AssessmentStatusTypes.REVIEWED,
          AssessmentStatusTypes.SUBMITTED,
        ].includes(props.row.original.status) && props.row.original.risk_rating
      const ratingLabel = (
        <RiskRatingLabel color={getRiskRatingColor(riskRating)}>
          {getRiskRatingLabel(
            riskRating,
            'N/A',
            props.row.original.status === AssessmentStatusTypes.SUBMITTED,
          )}
        </RiskRatingLabel>
      )
      return props.row.original.status === AssessmentStatusTypes.SUBMITTED ? (
        <Tooltip parent={ratingLabel}>
          Score is subject to change as assessment is reviewed.
        </Tooltip>
      ) : (
        ratingLabel
      )
    },
    width: '9%',
  },
  {
    id: COL_NAMES.GRADE,
    header: 'Grade',
    cell: (props) => <GradeLabel grade={props.getValue()} />,
    width: '15%',
  },
  {
    id: COL_NAMES.RISK_RECON_SCORE,
    header: (
      <>
        RiskRecon Score&nbsp;
        <Tooltip>Scores range from 0-10</Tooltip>
      </>
    ),
    cell: (props) => {
      const riskRecon = props.row.original.metrics?.risk_recon
      if (!riskRecon) {
        return null
      }
      return (
        <RisReconScoreCapsule
          color={getRiskReconRatingColor(riskRecon.rating_numeric)}
          left={riskRecon.rating_v2}
          right={riskRecon.rating_numeric}
        />
      )
    },
    width: '10%',
  },
  {
    id: COL_NAMES.OSANO_SCORE,
    header: (
      <>
        Osano Score&nbsp;
        <Tooltip>Scores range from 350-800</Tooltip>
      </>
    ),
    cell: (props) => {
      const osano = props.row.original.metrics?.osano
      if (!osano) {
        return null
      }
      const parsed = parseOsanoData(osano)
      return (
        <OsanoScoreCapsule
          color={parsed.color}
          left={parsed.rangeLabel}
          right={parsed.value}
        />
      )
    },
    width: '10%',
  },
  {
    id: COL_NAMES.ARGOS_SCORE,
    header: (
      <>
        Argos Score&nbsp;
        <Tooltip>Scores range from 0-100</Tooltip>
      </>
    ),
    cell: (props) => {
      const argos = props.row.original.metrics?.argos_risk
      if (!argos) {
        return null
      }
      const parsed = parseArgosRiskData(argos)
      return (
        <ArgosRiskScoreCapsule
          color={parsed[0].color}
          left={null}
          right={parsed[0].value}
        />
      )
    },
    width: '10%',
  },
  {
    id: COL_NAMES.ASSESSMENT_TEMPLATE_NAME,
    header: 'Template',
    width: '23%',
  },
  {
    id: COL_NAMES.PROJECT_NAME,
    header: 'Project',
  },
  {
    id: COL_NAMES.STATUS,
    header: 'Status',
    cell: (props) => (
      <StatusLabel status={props.getValue()}>
        {props.row.original.humanized_status}
      </StatusLabel>
    ),
    width: '11%',
  },
  {
    id: COL_NAMES.SENT_AT,
    header: 'Sent',
    cell: dateFormatter,
    width: '10%',
  },
  {
    id: COL_NAMES.SUBMIT_AT,
    header: 'Submitted',
    cell: dateFormatter,
    width: '10%',
  },
  {
    id: COL_NAMES.REVIEWED_AT,
    header: 'Reviewed',
    cell: dateFormatter,
    width: '10%',
  },
  {
    id: COL_NAMES.EXPIRED_AT,
    header: 'Expired',
    cell: dateFormatter,
    width: '10%',
  },
  {
    id: COL_NAMES.DUE_AT,
    header: 'Due',
    cell: dateFormatter,
    width: '10%',
  },
  {
    id: COL_NAMES.VENDOR_NEXT_REASSESSMENT_AT,
    header: 'Next Assessment',
    cell: dateFormatter,
    width: '11%',
  },
  {
    id: COL_NAMES.PROGRESS,
    header: 'Progress',
    enableSorting: false,
    cell: (props) => <AssessmentProgressBar summary={props.getValue()} />,
  },
  {
    id: COL_NAMES.CLIENT_ASSIGNEE_NAME,
    header: 'Assignee',
    cell: (props) => props.getValue() || <i>unassigned</i>,
  },
]

export function getAssessmentTableColumns(authManager, orgRole, context) {
  if (context === 'risk_domains') {
    return domainColumns
  }

  let visibleCols = []
  let formatters = {}

  switch (orgRole) {
    case 'status_only':
      visibleCols = [
        'vendor.name',
        'assessment_template.name',
        'status',
        'risk_rating',
        'progress',
        'grade',
      ]
      break
    case 'limited':
      visibleCols = [
        'vendor.name',
        'assessment_template.name',
        'status',
        'risk_rating',
        'progress',
        'grade',
      ]
      break
    default:
      visibleCols = [
        'vendor.name',
        'risk_rating',
        'grade',
        'assessment_template.name',
        'status',
        'sent_at',
        'submit_at',
        'vendor.next_reassessment_at',
      ]
      formatters = {
        'vendor.name': (props) => (
          <a href={get(props.row.original, 'vendor.url')}>{props.getValue()}</a>
        ),
        'vendor.parent_org_id': (props) =>
          authManager.userCan('show', props.row.original) ? (
            <a href={get(props.row.original, 'vendor.parent_org.path')}>
              {get(props.row.original, 'vendor.parent_org.name')}
            </a>
          ) : (
            get(props.row.original, 'vendor.parent_org.name')
          ),
        status: (props) =>
          authManager.userCan('show', props.row.original) ? (
            <a href={props.row.original.url}>
              <StatusLabel status={props.getValue()}>
                {props.row.original.humanized_status}
                {props.row.original.client_submitted ? (
                  <Tooltip parent={<SubmittedFile />}>
                    Imported by: {get(props.row.original, 'submit_by.name')}
                  </Tooltip>
                ) : (
                  ''
                )}
              </StatusLabel>
            </a>
          ) : (
            <StatusLabel status={props.getValue()}>
              {props.row.original.humanized_status}
            </StatusLabel>
          ),
      }
  }

  return map(allColumns, (column) => {
    const updated = { ...column }
    updated.hidden = visibleCols.indexOf(column.id) === -1
    if (formatters[column.id]) {
      updated.cell = formatters[column.id]
    }
    return updated
  })
}

export const domainColumns = [
  {
    id: COL_NAMES.VENDOR_NAME,
    header: LabelTypes.VENDOR,
    width: '19%',
  },
  {
    id: COL_NAMES.VENDOR_NAME,
    header: 'Rating',
    cell: (props) => {
      const riskRating =
        [
          AssessmentStatusTypes.REVIEWED,
          AssessmentStatusTypes.SUBMITTED,
        ].includes(props.row.original.status) && props.row.original.risk_rating
      return (
        <RiskRatingLabel color={getRiskRatingColor(riskRating)}>
          {getRiskRatingLabel(
            riskRating,
            'N/A',
            props.row.original.status === AssessmentStatusTypes.SUBMITTED,
          )}
        </RiskRatingLabel>
      )
    },
    width: '9%',
  },
]
