import React from 'react'
import PropTypes from 'prop-types'
import findIndex from 'lodash/findIndex'
import NotificationManager from 'lib/notifications'
import { useGlobalLoader } from 'hooks'
import { compose, withAuthorize, withReactQuery } from 'hocs'
import { useUpdateClientIssue } from 'apis'
import { IssueTransitionTypes } from 'constants/index'
import { getAssessmentsInStatusPageUrl } from './utils'
import View from './View'

const Dashboard = ({ data, preferences }) => {
  const { mutateAsync: updateClientIssue } = useUpdateClientIssue()
  const [upcomingIssues, setUpcomingIssues] = React.useState(
    data.upcoming_issues,
  )
  const [showLoader, hideLoader] = useGlobalLoader()

  const handleSendIssue = React.useCallback(
    (id) => {
      const loaderId = showLoader()
      updateClientIssue({
        id,
        data: {
          issue: { transition: IssueTransitionTypes.OPEN },
        },
      })
        .then((response) => {
          // Update state using the api response
          setUpcomingIssues((current) => {
            const index = findIndex(current, { id })
            const newUpcomingIssues = [...current]
            newUpcomingIssues[index] = response
            return newUpcomingIssues
          })
        })
        .catch(() => NotificationManager.error())
        .then(() => hideLoader(loaderId))
    },
    [showLoader, hideLoader],
  )

  const handleAssessmentStatusSelect = React.useCallback((status) => {
    window.location.href = getAssessmentsInStatusPageUrl(status)
  }, [])

  return (
    <View
      upcomingIssues={upcomingIssues}
      overview={data.overview}
      assessmentGrades={data.assessment_grades}
      issueMatrix={data.issue_matrix}
      activeAssessmentStatusCounts={
        data.overview.active_assessment_status_counts
      }
      recentProjects={data.recent_projects}
      recentAssessmentScores={data.recent_assessment_scores}
      onSendIssue={handleSendIssue}
      onAssessmentStatusSelect={handleAssessmentStatusSelect}
      preferences={preferences}
    />
  )
}

Dashboard.propTypes = {
  data: PropTypes.object.isRequired,
  preferences: PropTypes.object.isRequired,
}

export default compose(withAuthorize(), withReactQuery())(Dashboard)
