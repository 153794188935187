import styled from 'styled-components'
import { mixins } from 'styles'

export const TableTitle = styled.h3`
  margin-top: 50px;
  margin-bottom: 10px;
  font-weight: normal;
`

export const PrintViewInfo = styled.div`
  ${mixins.showPrint}
  margin-bottom: 20px;
`
