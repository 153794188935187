import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import filter from 'lodash/filter'
import some from 'lodash/some'
import map from 'lodash/map'
import intersection from 'lodash/intersection'
import { compose, withAuthorize, withReactQuery } from 'hocs'
import { useCurrent } from 'hooks'
import { useClientAssessmentTemplateFetcher } from 'apis'
import View from './View'
import useTemplateExcelExport from '../shared/useTemplateExcelExport'
import useDuplicateAssessmentTemplate from '../shared/useDuplicateAssessmentTemplate'
import useCreateSection from './useCreateSection'
import useCreateQuestion from './useCreateQuestion'
import useEditElement from './useEditElement'
import useEditQuestions from './useEditQuestions'
import useDeleteElement from './useDeleteElement'
import useDeleteElements from './useDeleteElements'
import useEditRatingLabelSet from './useEditRatingLabelSet'
import useEditQuestionAutomations from './useEditQuestionAutomations'
import useViewQuestionAttachments from './useViewQuestionAttachments'
import useChangeElementField from './useChangeElementField'
import useReorderElement from './useReorderElement'
import useChangeElementAutoScore from './useChangeElementAutoScore'
import useEditCustomKeys from './useEditCustomKeys'
import useEditWeights from './useEditWeights'
import useChangeTemplateAttribute from './useChangeTemplateAttribute'
import useTemplateStateMutations from './useTemplateStateMutations'
import useMoveOrCopyElements from './useMoveOrCopyElements'
import useInvalidSkipConditionRulesCleaner from './useInvalidSkipConditionRulesCleaner'
import useCRUDSkipConditions from './useCRUDSkipConditions'

// Utilities
import {
  getElementById,
  getClientAssessmentTemplateEndpointUrl,
  sortElements,
  isNotSection,
  getQuestionsFromElements,
  getElementIdsWithSkipConditions,
} from './utils'

const Container = ({
  assessmentTemplate: assessmentTemplateProp,
  isNew,
  archivable,
  readyable,
  unreadyable,
  templateId,
  questionClassesForSelect,
  confirmAutoScore,
  clientAssessmentsPageForTemplate,
}) => {
  const { data: assessmentTemplate } = useClientAssessmentTemplateFetcher(
    {
      id: assessmentTemplateProp.id,
    },
    { initialData: assessmentTemplateProp, refetchOnMount: false },
  )
  const assessmentTemplateRef = useCurrent(assessmentTemplate)
  const [isSectionSelectionMode, setIsSectionSelectionMode] = useState(false)
  const [selectedSectionId, setSelectedSectionId] = useState(null)
  const [selectedQuestionIds, setSelectedQuestionIds] = useState([])

  const createSection = useCreateSection(assessmentTemplate)
  const createQuestion = useCreateQuestion({
    initialElements: assessmentTemplateProp.elements,
    assessmentTemplate,
    questionClassesForSelect,
  })
  const editRatingLabelSet = useEditRatingLabelSet(assessmentTemplate.id)
  const duplicateAssessmentTemplate = useDuplicateAssessmentTemplate()
  const exportToExcel = useTemplateExcelExport()
  const viewQuestionAttachments = useViewQuestionAttachments(assessmentTemplate)
  const editQuestionAutomations = useEditQuestionAutomations(assessmentTemplate)
  const editElement = useEditElement({
    initialElements: assessmentTemplateProp.elements,
    assessmentTemplate,
    questionClassesForSelect,
  })
  const deleteElement = useDeleteElement(assessmentTemplate)
  const deleteElements = useDeleteElements({
    assessmentTemplate,
    isSectionSelectionMode,
    selectedSectionId,
    selectedQuestionIds,
    setSelectedQuestionIds,
    setSelectedSectionId,
  })
  const changeElementField = useChangeElementField(assessmentTemplate)
  const reorderElement = useReorderElement(assessmentTemplate)
  const changeElementAutoScore = useChangeElementAutoScore({
    assessmentTemplate,
    confirmAutoScore,
  })
  const editCustomKeys = useEditCustomKeys(assessmentTemplate)
  const editWeights = useEditWeights(assessmentTemplate)
  const editQuestions = useEditQuestions({
    assessmentTemplate,
    selectedQuestionIds,
    setSelectedQuestionIds,
    setSelectedSectionId,
  })
  const changeTemplateAttribute = useChangeTemplateAttribute(assessmentTemplate)
  const { archive, makeReady, makeDraft } =
    useTemplateStateMutations(templateId)
  const moveOrCopyElements = useMoveOrCopyElements({
    assessmentTemplate,
    isSectionSelectionMode,
    selectedQuestionIds,
    selectedSectionId,
    setIsSectionSelectionMode,
    setSelectedSectionId,
    setSelectedQuestionIds,
  })
  const {
    bulkAddSkipConditions,
    editSkipConditions,
    bulkDeleteSkipConditions,
  } = useCRUDSkipConditions({
    assessmentTemplate,
    selectedQuestionIds,
    setSelectedQuestionIds,
    setSelectedSectionId,
  })

  useInvalidSkipConditionRulesCleaner(assessmentTemplate)

  useEffect(() => {
    const questionIds = map(
      getQuestionsFromElements(assessmentTemplate.elements),
      'id',
    )
    setSelectedQuestionIds((current) => intersection(current, questionIds))
    setSelectedSectionId((current) =>
      current && getElementById(assessmentTemplate.elements, current)
        ? current
        : null,
    )
  }, [assessmentTemplate.elements])

  useEffect(() => {
    setIsSectionSelectionMode((current) => current && !!selectedSectionId)
  }, [selectedSectionId])

  // Callback when a section checkbox is toggled
  const handleToggleSectionSelection = useCallback((sectionId, toggled) => {
    if (toggled) {
      const questionsInSection = filter(
        assessmentTemplateRef.current.elements,
        (element) => isNotSection(element) && element.parent_id === sectionId,
      )
      const questionIdsInSection = map(questionsInSection, 'id')
      setIsSectionSelectionMode(true)
      setSelectedSectionId(sectionId)
      setSelectedQuestionIds(questionIdsInSection)
    } else {
      setIsSectionSelectionMode(false)
      setSelectedSectionId(null)
      setSelectedQuestionIds([])
    }
  }, [])

  const handleExcelExportButtonClick = useCallback(() => {
    exportToExcel(templateId)
  }, [exportToExcel, templateId])

  const handleDuplicateButtonClick = useCallback(() => {
    const automationsCheck = some(
      assessmentTemplateRef.current.elements,
      (e) => e.question_automations && e.question_automations.length > 0,
    )

    duplicateAssessmentTemplate({
      assessmentTemplate,
      hasAutomations: automationsCheck,
    })
  }, [assessmentTemplate, duplicateAssessmentTemplate])

  const sortedElements = useMemo(
    () => sortElements(assessmentTemplate.elements),
    [assessmentTemplate.elements],
  )
  const elementIdsWithSkipConditions = useMemo(
    () => getElementIdsWithSkipConditions(assessmentTemplate.rules),
    [assessmentTemplate.rules],
  )
  const actionButtonOptions = useMemo(() => {
    const questionSelected = selectedQuestionIds.length > 0
    const questionWithSkipConditionsSelected = some(
      selectedQuestionIds,
      (r) => elementIdsWithSkipConditions.indexOf(r) !== -1,
    )
    return {
      bulkEdit: {
        disabled: isSectionSelectionMode || !questionSelected,
      },
      moveOrCopy: {
        disabled: isSectionSelectionMode
          ? !selectedSectionId
          : !questionSelected,
      },
      addOrReplaceSkipConditions: {
        disabled: isSectionSelectionMode || !questionSelected,
      },
      removeSkipConditions: {
        disabled: isSectionSelectionMode || !questionWithSkipConditionsSelected,
      },
      delete: {
        disabled:
          (isSectionSelectionMode && !selectedSectionId) ||
          (!isSectionSelectionMode && !questionSelected),
      },
    }
  }, [
    isSectionSelectionMode,
    selectedSectionId,
    selectedQuestionIds,
    elementIdsWithSkipConditions,
  ])
  const apiClientAssessmentTemplatePath =
    getClientAssessmentTemplateEndpointUrl(assessmentTemplate.id)
  return (
    <View
      assessmentTemplate={assessmentTemplate}
      elements={sortedElements}
      isSectionSelectionMode={isSectionSelectionMode}
      selectedSectionId={selectedSectionId}
      selectedQuestionIds={selectedQuestionIds}
      isNew={isNew}
      archivable={archivable}
      readyable={readyable}
      unreadyable={unreadyable}
      questionClassesForSelect={questionClassesForSelect}
      clientAssessmentsPageForTemplate={clientAssessmentsPageForTemplate}
      elementIdsWithSkipConditions={elementIdsWithSkipConditions}
      actionButtonOptions={actionButtonOptions}
      apiClientAssessmentTemplatePath={apiClientAssessmentTemplatePath}
      onEditRatingLabelSet={editRatingLabelSet}
      onElementFieldChange={changeElementField}
      onElementMove={reorderElement}
      onElementEdit={editElement}
      onElementDelete={deleteElement}
      onElementEditSkipConditions={editSkipConditions}
      onElementAutoScoreChange={changeElementAutoScore}
      onElementQuestionAutomationEdit={editQuestionAutomations}
      onViewQuestionAttachmentsClick={viewQuestionAttachments}
      onUseCustomKeysButtonClick={editCustomKeys}
      onEditWeightsButtonClick={editWeights}
      onSelectedQuestionIdsChange={setSelectedQuestionIds}
      onToggleSectionSelection={handleToggleSectionSelection}
      onAddSectionButtonClick={createSection}
      onAddQuestionButtonClick={createQuestion}
      onBulkEditButtonClick={editQuestions}
      onBulkMoveOrCopyButtonClick={moveOrCopyElements}
      onBulkDeleteButtonClick={deleteElements}
      onBulkAddSkipConditionsButtonClick={bulkAddSkipConditions}
      onBulkRemoveSkipConditionsButtonClick={bulkDeleteSkipConditions}
      onArchiveButtonClick={archive}
      onReadyButtonClick={makeReady}
      onUnreadyButtonClick={makeDraft}
      onExcelExportButtonClick={handleExcelExportButtonClick}
      onDuplicateButtonClick={handleDuplicateButtonClick}
      onAssessmentAttributeChange={changeTemplateAttribute}
    />
  )
}

Container.propTypes = {
  assessmentTemplate: PropTypes.object.isRequired,
  isNew: PropTypes.bool,
  archivable: PropTypes.bool,
  readyable: PropTypes.bool,
  unreadyable: PropTypes.bool,
  templateId: PropTypes.string.isRequired,
  questionClassesForSelect: PropTypes.array.isRequired,
  confirmAutoScore: PropTypes.bool.isRequired,
  clientAssessmentsPageForTemplate: PropTypes.string.isRequired,
}

export default compose(withAuthorize(), withReactQuery())(Container)
