import styled from 'styled-components'

export const RatingSelectWrapper = styled.div`
  ${(props) => props.disabled && 'opacity: .6;'}
`

export const Markers = styled.div`
  display: flex;
  height: ${({ height }) => height}px;

  > * {
    flex: 1;

    &:not(:last-child) {
      margin-right: 2px;
    }
  }
`

export const Marker = styled.div`
  width: 100%;
  height: 100%;
  background: ${(props) => (props.active ? props.color : '#EEE')};
  ${(props) => props.hoverable && 'cursor: pointer;'}
`

export const Label = styled.div`
  text-align: center;
  color: #000;
  padding-top: 0.5em;
`
