import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import Privva from 'Privva'
import Button from 'components-v2/atoms/Button'
import Checkbox from 'components-v2/atoms/Checkbox'
import ToolbarGroup from 'components-v2/molecules/ToolbarGroup'
import { toLocalTimeString } from 'utils/date'
import { useCurrent } from 'hooks'
import {
  Wrapper,
  Inner,
  Heading,
  Content,
  IconWrapper,
  CheckIcon,
  CloseIcon,
  CheckWrapper,
  StyledProgressBar as ProgressBar,
} from './styles'

const AutoAnswer = ({
  vendorList,
  result,
  error,
  onConfirm,
  onClose,
  ...rest
}) => {
  const errorRef = useCurrent(error)
  const resultRef = useCurrent(result)
  const [confirming, setConfirming] = useState(false)
  const [inProgress, setInProgress] = useState(false)
  const inProgressRef = useCurrent(inProgress)
  const [progress, setProgress] = useState(0)
  const progressRef = useCurrent(progress)
  const [selected, setSelected] = useState(() => map(vendorList, 'id'))

  const toggleCheckbox = useCallback((id) => {
    setSelected((current) => {
      if (current.includes(id)) {
        return current.filter((item) => item !== id)
      }
      return [...current, id]
    })
  }, [])

  const handleConfirm = useCallback(() => {
    const checkProgress = () => {
      if (!inProgressRef.current || errorRef.current) {
        return
      }

      if (resultRef.current) {
        setInProgress(false)
        return
      }

      if (progressRef.current > 75) {
        setProgress((current) => current + 1)
        setTimeout(checkProgress, 500)
      } else {
        setProgress((current) => current + 6)
        setTimeout(checkProgress, 300)
      }
    }

    setInProgress(true)
    setTimeout(() => {
      onConfirm(selected)
      setTimeout(checkProgress, 1000)
    }, 0)
  }, [onConfirm, selected])

  if (error) {
    return (
      <Wrapper {...rest} error>
        <Inner>
          <IconWrapper>
            <CloseIcon />
          </IconWrapper>
          <Content>
            <Heading>There was a problem.</Heading>
            <p>
              Please try reloading the page.
              <br />
              If the problem persists please contact support at{' '}
              <a href={`mailto:${Privva.Utils.brand.supportEmail}`}>
                {Privva.Utils.brand.supportEmail}
              </a>
              .
            </p>
          </Content>
        </Inner>
      </Wrapper>
    )
  }

  if (result) {
    const { answered, bypassed } = result
    return (
      <Wrapper {...rest}>
        <Inner>
          <IconWrapper>
            <CheckIcon />
          </IconWrapper>
          <Content>
            <Heading>Import complete</Heading>
            <ul>
              <li>{answered} answers copied</li>
              {bypassed && (
                <li>
                  {bypassed.length} answers did not meet updated validation
                  requirements and were skipped
                </li>
              )}
            </ul>
            <Button color="primary" onClick={onClose}>
              Close
            </Button>
          </Content>
        </Inner>
      </Wrapper>
    )
  }

  return (
    <Wrapper {...rest}>
      <Inner>
        <IconWrapper>
          <CheckIcon />
        </IconWrapper>
        <Content>
          <Heading>Use your previous answers to save time.</Heading>
          <p>
            We’ve noticed you’ve answered some of these questions before.
            <br />
            You’ll be able to review your answers before you submit.
          </p>
          {!confirming ? (
            <ToolbarGroup>
              <Button color="primary" onClick={() => setConfirming(true)}>
                Autofill Assessment
              </Button>
              <Button variant="outlined" onClick={onClose}>
                Ignore Previous Answers
              </Button>
            </ToolbarGroup>
          ) : (
            <>
              <p>
                We will <b>automatically fill in the same responses</b> based on
                assessments from the following accounts:
              </p>
              {map(vendorList, (vendor) => {
                const { id, name, submit_at: submitAt } = vendor
                return (
                  <CheckWrapper key={id}>
                    <Checkbox
                      label={
                        <>
                          {name}{' '}
                          <i>
                            {submitAt &&
                              toLocalTimeString(submitAt, 'MM/dd/yy')}
                          </i>
                        </>
                      }
                      checked={selected.includes(id)}
                      onChange={() => toggleCheckbox(id)}
                    />
                  </CheckWrapper>
                )
              })}
              <ToolbarGroup>
                <Button
                  color="primary"
                  disabled={selected.length === 0 || inProgress}
                  onClick={handleConfirm}
                >
                  Confirm
                </Button>
                <Button
                  variant="outlined"
                  disabled={inProgress}
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </ToolbarGroup>
            </>
          )}
        </Content>
      </Inner>
      {inProgress && <ProgressBar now={Math.min(progress, 99)} />}
    </Wrapper>
  )
}

AutoAnswer.propTypes = {
  vendorList: PropTypes.array.isRequired,
  result: PropTypes.object,
  error: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default AutoAnswer
