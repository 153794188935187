import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import filter from 'lodash/filter'
import Well from 'components-v2/atoms/Well'
import FormField from 'components-v2/molecules/FormField'
import FormDialog from 'components-v2/organisms/FormDialog'
import { FieldRow, SmallWidthFormField, SelectedElementList } from './styles'
import { QuestionPlacementTypes, MoveOrCopyActionTypes } from '../constants'
import {
  getSectionSelectOptions,
  getQuestionSelectOptions,
  filterElementsById,
} from '../utils'

const validationSchema = yup.object().shape({
  action: yup.string().required(),
  placement: yup.string().required(),
  parent_id: yup.string().when('placement', {
    is: QuestionPlacementTypes.END_OF_SECTION,
    then: yup.string().required('Please select a section'),
  }),
  relative_question_id: yup.string().when('placement', {
    is: QuestionPlacementTypes.END_OF_SECTION,
    otherwise: yup.string().required('Please select a question'),
  }),
})

const actionOptions = [
  {
    label: 'Move to',
    value: MoveOrCopyActionTypes.MOVE,
  },
  {
    label: 'Copy to',
    value: MoveOrCopyActionTypes.COPY,
  },
]

const placementOptions = [
  {
    label: 'End of section',
    value: QuestionPlacementTypes.END_OF_SECTION,
  },
  {
    label: 'Before question',
    value: QuestionPlacementTypes.BEFORE_QUESTION,
  },
  {
    label: 'After question',
    value: QuestionPlacementTypes.AFTER_QUESTION,
  },
]

const QuestionsFormModal = ({ elements, questionIds, ...rest }) => {
  const selectedQuestions = filterElementsById(elements, questionIds)
  const sectionSelectOptions = getSectionSelectOptions(elements)
  const questionSelectOptions = filter(
    getQuestionSelectOptions(elements),
    (opt) => questionIds.indexOf(opt.value) === -1,
  )

  const formConfig = React.useMemo(
    () => ({
      validationSchema,
      initialValues: {
        action: MoveOrCopyActionTypes.MOVE,
        placement: QuestionPlacementTypes.END_OF_SECTION,
        parent_id:
          sectionSelectOptions.length > 0
            ? sectionSelectOptions[sectionSelectOptions.length - 1].value
            : undefined,
        relative_question_id: undefined,
        // make sure the element ids that get sent are properly ordered,
        // no matter what order the user clicked them in.
        // better ways to accomplish this with a much wider scope,
        // but for now this should do it.
        element_ids: selectedQuestions.map((el) => el.id),
      },
    }),
    [sectionSelectOptions, selectedQuestions],
  )

  return (
    <FormDialog
      {...rest}
      title="Move or Copy Questions"
      formConfig={formConfig}
    >
      {({ values }) => (
        <>
          <FieldRow>
            <SmallWidthFormField
              name="action"
              type={FormField.types.SELECT}
              options={actionOptions}
            />
            <SmallWidthFormField
              name="placement"
              type={FormField.types.SELECT}
              options={placementOptions}
            />
            {values.placement === QuestionPlacementTypes.END_OF_SECTION ? (
              <FormField
                name="parent_id"
                type={FormField.types.SEARCHABLE_SELECT}
                options={sectionSelectOptions}
              />
            ) : (
              <FormField
                name="relative_question_id"
                type={FormField.types.SEARCHABLE_SELECT}
                options={questionSelectOptions}
              />
            )}
          </FieldRow>
          <Well>
            {selectedQuestions.length === 1
              ? `1 Question Selected`
              : `${selectedQuestions.length} Questions Selected`}
            <SelectedElementList>
              {selectedQuestions.map((el) => (
                <li key={el.id}>
                  {el.key} - {el.label}
                </li>
              ))}
            </SelectedElementList>
          </Well>
        </>
      )}
    </FormDialog>
  )
}

QuestionsFormModal.propTypes = {
  elements: PropTypes.array.isRequired,
  questionIds: PropTypes.array.isRequired,
}

export default QuestionsFormModal
