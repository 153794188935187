import styled from 'styled-components'
import { mixins } from 'styles'

export const Item = styled.div`
  margin-bottom: 25px;
  &:last-child {
    margin-bottom: 0;
  }
`

export const Top = styled.div`
  display: flex;
  margin-bottom: 10px;
`

const VendorStyle = `
  margin: 0 10px 0 0;
  font-size: 15px;
  font-weight: 600;
  color: #333;
  ${mixins.truncate}
`

export const VendorName = styled.a`
  ${VendorStyle}
`

export const VendorLabel = styled.span`
  ${VendorStyle}
`

export const Status = styled.span`
  flex: none;
  color: ${(props) => props.color || '#999'};
`
