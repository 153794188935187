import styled from 'styled-components'
import { QuestionCircle } from '@styled-icons/fa-solid/QuestionCircle'
import A from 'components-v2/atoms/A'
import Well from 'components-v2/atoms/Well'
import Icon from 'components-v2/atoms/Icon'
import IconButton from 'components-v2/atoms/IconButton'
import { colors } from 'styles'

export const IconLink = styled(A).attrs({ underline: true })`
  margin-left: 10px;
`
export const EllipsisIconButton = styled(IconButton).attrs({
  icon: 'fa fa-ellipsis-h',
})``
export const GroupTitleRow = styled.div`
  display: flex;
`
export const GroupTitle = styled.h4`
  margin-top: 15px;
  margin-bottom: 5px;
`
export const TitleBarSpinner = styled.span`
  margin-top: 13px;
  margin-left: 10px;
`
export const GroupIconSet = styled.div`
  margin-right: 2px;
  margin-top: 12px;
  flex: auto;
  text-align: right;
`
export const ArrowUpIcon = styled(Icon).attrs({
  icon: 'fa fa-arrow-up',
})``
export const ArrowDownIcon = styled(Icon).attrs({
  icon: 'fa fa-arrow-down',
})``
export const FieldTableBlock = styled(Well)`
  padding-top: 10px;
  padding-bottom: 10px;
`
export const FieldTable = styled.table.attrs({ className: 'table' })`
  margin-bottom: 0px;
`

export const DragHandle = styled.div`
  display: inline;
  ${({ draggable }) => draggable && `cursor: move;`}
`
export const DragIcon = styled(Icon)`
  margin-bottom: 4px;
`
export const QuestionCircleIcon = styled(Icon).attrs({ icon: QuestionCircle })`
  /* color: ${colors.PRIMARY}; */
  width: 0.7em;
  height: 0.7em;
  margin-bottom: 3px;
  margin-left: 4px;
`
