// for form architecture using Formik + Yup
import { useField } from 'formik'

export default function useFormField(params) {
  const [field, meta, helpers] = useField(params)
  const error = meta.touched && meta.error
  return {
    ...field,
    onChange: helpers.setValue,
    showError: !!error,
    errorMessage: error || undefined,
    meta,
    helpers,
  }
}
