import React from 'react'
import PropTypes from 'prop-types'
import QuestionsFormModal from './QuestionsFormModal'
import SectionFormModal from './SectionFormModal'

const MoveOrCopyFormModal = ({
  isSectionMode,
  questionIds,
  sectionId,
  ...rest
}) => {
  if (isSectionMode) {
    return <SectionFormModal {...rest} sectionId={sectionId} />
  }
  return <QuestionsFormModal {...rest} questionIds={questionIds} />
}

MoveOrCopyFormModal.propTypes = {
  isSectionMode: PropTypes.bool,
  questionIds: PropTypes.array.isRequired,
  sectionId: PropTypes.string,
}

export default MoveOrCopyFormModal
