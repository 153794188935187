/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import filter from 'lodash/filter'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import omit from 'lodash/omit'
import replace from 'lodash/replace'
import snakeCase from 'lodash/snakeCase'
import { LabelTypes } from 'constants/index'
import {
  useSimpleFormDialog,
  useConfirm,
  useModal,
  useGlobalLoader,
} from 'hooks'
import { moveArrayElementTo } from 'utils/array'
import { sortGroups, defaultGroupKey } from 'lib/custom_fields'
import NotificationManager from 'lib/notifications'
import {
  useClientCustomFieldDefinitionsFetcher,
  useCreateClientCustomFieldGroup,
  useSortClientCustomFieldGroups,
  useUpdateClientCustomFieldGroup,
  useDeleteClientCustomFieldGroup,
  useCreateClientCustomFieldDefinition,
  useSortClientCustomFieldDefinitions,
  useUpdateClientCustomFieldDefinition,
  useDeleteClientCustomFieldDefinition,
} from 'apis'
import View from './View'
import FieldDetailsModal from './FieldDetailsModal'

const typeConfigs = {
  string: {
    shortDefault: 255,
    longDefault: 5000,
    max: 10000,
  },
}

const cleanKey = (dirty) =>
  // quick and dirty replacement for previous lodash/chain approach
  snakeCase(
    replace(dirty, /^[^a-zA-Z]*/, ''), // make sure the first char is a letter
  )

const CustomFieldDefinitionsTable = ({ organization, targetType }) => {
  const { mutateAsync: createClientCustomFieldGroup } =
    useCreateClientCustomFieldGroup()
  const { mutateAsync: sortClientCustomFieldGroups } =
    useSortClientCustomFieldGroups()
  const { mutateAsync: updateClientCustomFieldGroup } =
    useUpdateClientCustomFieldGroup()
  const { mutateAsync: deleteClientCustomFieldGroup } =
    useDeleteClientCustomFieldGroup()
  const { mutateAsync: createClientCustomFieldDefinition } =
    useCreateClientCustomFieldDefinition()
  const { mutateAsync: sortClientCustomFieldDefinitions } =
    useSortClientCustomFieldDefinitions()
  const { mutateAsync: updateClientCustomFieldDefinition } =
    useUpdateClientCustomFieldDefinition()
  const { mutateAsync: deleteClientCustomFieldDefinition } =
    useDeleteClientCustomFieldDefinition()
  const openConfirm = useConfirm()
  const [showLoader, hideLoader] = useGlobalLoader()
  const [openModal, closeModal] = useModal(FieldDetailsModal)
  const [openFormDialog, closeFormDialog, addFormField] =
    useSimpleFormDialog('Add Group')
  const { data: customFieldDefinitions, isLoading } =
    useClientCustomFieldDefinitionsFetcher()
  const fieldGroups = customFieldDefinitions?.groups?.[targetType]
  const defaultGroup = React.useMemo(
    () => (fieldGroups ? defaultGroupKey(fieldGroups) : 'default'),
    [fieldGroups],
  )
  const sortedGroups = React.useMemo(
    () => sortGroups(fieldGroups),
    [fieldGroups],
  )
  const fieldDefs = React.useMemo(() => {
    if (!customFieldDefinitions) {
      return {}
    }
    return keyBy(
      customFieldDefinitions.field_definitions.filter(
        (e) => e.fieldable_type === targetType,
      ),
      'key',
    )
  }, [customFieldDefinitions, targetType])

  const handleCreate = (formData) => {
    const data = formData
    if (data.name.length === 0) return

    const meta_types = {
      select: 'string',
      email: 'string',
      url: 'string',
      money: 'float',
    }

    const key = cleanKey(data.name)
    const newFieldDef = {
      name: data.name,
      key,
      client_id: organization.id,
      data_type: meta_types[data.data_type] || data.data_type,
      meta_type: data.data_type,
      fieldable_type: targetType,
      type_opts: { array: data.data_type === 'select' },
      render_opts: {
        group: data.group,
        sort: filter(fieldDefs, ['render_opts.group', defaultGroup]).length,
      },
    }
    if (['date', 'datetime'].includes(data.data_type)) {
      newFieldDef.render_opts.allow_past = true
    }
    const loaderId = showLoader()
    createClientCustomFieldDefinition({
      data: { custom_field_definition: newFieldDef },
    })
      .then(() => NotificationManager.success('Custom field definition added'))
      .catch(() => NotificationManager.error())
      .finally(() => hideLoader(loaderId))
  }

  const handleEdit = (key) => {
    openModal({
      fieldDef: fieldDefs[key],
      fieldGroups,
      typeConfigs,
      onSave: (data) => {
        handleUpdate(key, data)
        closeModal()
      },
    })
  }

  const handleNameChange = (key, newName) => {
    handleUpdate(key, { name: newName })
  }

  const handleUpdate = (key, updated) => {
    const loaderId = showLoader()
    updateClientCustomFieldDefinition({
      id: fieldDefs[key].id,
      data: {
        custom_field_definition: omit(
          {
            ...fieldDefs[key],
            ...updated,
            client_id: organization.id,
          },
          'class',
        ),
      },
    })
      .then(() => NotificationManager.success('Changes saved'))
      .catch(() => NotificationManager.error())
      .finally(() => hideLoader(loaderId))
  }

  const handleDelete = (key) => {
    openConfirm({
      title: 'Delete Custom Field?',
      body: `Are you sure you want to delete this custom field definition? If any of your ${LabelTypes.VENDORS.toLowerCase()}
        have a value stored in this field, you'll no longer be able to view it.`,
      onConfirm: () => {
        const loaderId = showLoader()
        deleteClientCustomFieldDefinition({ id: fieldDefs[key].id })
          .then(() => NotificationManager.success('Custom field removed'))
          .catch(() => NotificationManager.error())
          .finally(() => hideLoader(loaderId))
      },
    })
  }

  const handleCreateGroup = () => {
    addFormField({
      name: 'groupName',
      label: 'New Group Name',
      placeholder: 'Enter name',
      validType: 'string',
    })
    openFormDialog({
      onSubmit: (formData) => {
        const loaderId = showLoader()
        const newKey = Math.random().toString(36).substring(2, 15)
        createClientCustomFieldGroup({
          data: {
            client_id: organization.id,
            class: targetType,
            key: newKey,
            name: formData.groupName,
          },
        })
          .then(() => {
            NotificationManager.success('Changes saved')
            closeFormDialog()
          })
          .catch(() => NotificationManager.error())
          .finally(() => hideLoader(loaderId))
      },
    })
  }

  const handleSortGroup = (oldIdx, newIdx) => {
    const loaderId = showLoader()
    const rows = map(
      moveArrayElementTo(sortedGroups, oldIdx, newIdx),
      (row, idx) => ({ ...row, sort: idx }),
    )
    sortClientCustomFieldGroups({
      data: {
        client_id: organization.id,
        class: 'Organization',
        rows,
      },
    })
      .then(() => NotificationManager.success('Changes saved'))
      .catch(() => NotificationManager.error())
      .finally(() => hideLoader(loaderId))
  }

  const handleRenameGroup = (gKey, name) => {
    updateGroup(gKey, { name })
  }

  const handleHideGroup = (gKey) => {
    updateGroup(gKey, { hidden: true })
  }

  const handleUnhideGroup = (gKey) => {
    updateGroup(gKey, { hidden: false })
  }

  const updateGroup = (gKey, updated) => {
    const loaderId = showLoader()
    updateClientCustomFieldGroup({
      key: gKey,
      data: {
        client_id: organization.id,
        class: targetType,
        key: gKey,
        group: {
          ...fieldGroups[gKey],
          ...updated,
        },
      },
    })
      .then(() => NotificationManager.success('Changes saved'))
      .catch(() => NotificationManager.error())
      .finally(() => hideLoader(loaderId))
  }

  const handleDeleteGroup = (gKey) => {
    openConfirm({
      title: 'Remove group?',
      body: 'Remove this group from your custom field configuration? Any remaining fields will simply be moved to the default group.',
      confirmText: "Yes, I'm sure",
      onConfirm: () => {
        const loaderId = showLoader()
        deleteClientCustomFieldGroup({
          options: {
            data: {
              client_id: organization.id,
              class: targetType,
              key: gKey,
            },
          },
        })
          .then(() => NotificationManager.success('Changes saved'))
          .catch(() => NotificationManager.error())
          .finally(() => hideLoader(loaderId))
      },
    })
  }

  const handleMoveRow = (updateList) => {
    const data = {
      client_id: organization.id,
      rows: updateList,
    }
    const loaderId = showLoader()
    sortClientCustomFieldDefinitions({ data })
      .then(() => NotificationManager.success('Reorder update complete'))
      .catch(() => NotificationManager.error())
      .finally(() => hideLoader(loaderId))
  }

  return (
    <View
      fieldDefs={fieldDefs}
      defaultGroup={defaultGroup}
      sortedGroups={sortedGroups}
      loaded={!isLoading}
      onCreate={handleCreate}
      onEditClick={handleEdit}
      onNameChange={handleNameChange}
      onDeleteClick={handleDelete}
      onCreateGroup={handleCreateGroup}
      onRenameGroup={handleRenameGroup}
      onSortGroup={handleSortGroup}
      onHideGroup={handleHideGroup}
      onUnhideGroup={handleUnhideGroup}
      onDeleteGroup={handleDeleteGroup}
      onRowMove={handleMoveRow}
    />
  )
}

CustomFieldDefinitionsTable.propTypes = {
  organization: PropTypes.object.isRequired,
  targetType: PropTypes.string.isRequired,
}

export default CustomFieldDefinitionsTable
