import styled from 'styled-components'
import { ResponsiveContainer } from 'recharts'

export const Container = styled(ResponsiveContainer)`
  font-size: 13px;

  // https://github.com/recharts/recharts/issues/1114#issuecomment-407949274
  .recharts-wrapper,
  .recharts-surface {
    @media print {
      width: calc(100%) !important;
    }
  }
`
