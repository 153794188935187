import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import isAfter from 'date-fns/isAfter'
import isSameDay from 'date-fns/isSameDay'
import Icon from 'components-v2/atoms/Icon'
import IconButton from 'components-v2/atoms/IconButton'
import Button from 'components-v2/atoms/Button'
import Tooltip from 'components-v2/molecules/Tooltip'
import Menu from 'components-v2/molecules/Menu'
import { usePrevious, useAuthorizations } from 'hooks'
import { toDate } from 'utils/date'
import {
  Wrapper,
  DateRangeWrapper,
  StyledDateField as DateField,
  DateRangeButtons,
  ActionsWrapper,
  HourglassIcon,
  LockIcon,
  EllipsisIconButton,
} from './styles'

const parseDate = (str) => toDate(str) || undefined
const dateControlProps = {
  dateFormat: 'yyyy/MM/dd h:mm aa',
  fullWidth: true,
  showTimeSelect: true,
  todayButton: 'Today',
  minDateTime: new Date(),
  portalId: 'datepicker-portal',
}
const dueDateControlProps = {
  ...dateControlProps,
  showDurationButtons: true,
}

const ProjectVendorActions = ({
  projectVendor,
  editable,
  launchDisabled,
  onDelete,
  onCancel,
  onDatesChange,
  onLaunch,
  ...rest
}) => {
  const [launchDate, setLaunchDate] = React.useState(
    parseDate(get(projectVendor, 'date_range.start_at')),
  )
  const [dueDate, setDueDate] = React.useState(
    parseDate(get(projectVendor, 'date_range.end_at')),
  )
  const [datesEditing, setDatesEditing] = React.useState(false)
  const [datesExtending, setDatesExtending] = React.useState(false)
  const updatedAt = get(projectVendor, 'date_range.updated_at')
  const updatedAtChanged = usePrevious(updatedAt) !== updatedAt
  const [userCanManage] = useAuthorizations(['manage', projectVendor])

  React.useEffect(() => {
    if (updatedAtChanged) {
      setLaunchDate(parseDate(get(projectVendor, 'date_range.start_at')))
      setDueDate(parseDate(get(projectVendor, 'date_range.end_at')))
      setDatesEditing(false)
      setDatesExtending(false)
    }
  })

  // TODO: Get rid of the following logic from this component
  const launchable = React.useMemo(() => {
    const startAt = toDate(get(projectVendor, 'date_range.start_at'))
    const endAt = toDate(get(projectVendor, 'date_range.end_at'))
    if (!startAt || !endAt) {
      return false
    }
    return (
      !launchDisabled &&
      !projectVendor.launched &&
      isAfter(endAt, startAt) &&
      isSameDay(startAt, new Date())
    )
  }, [projectVendor, launchDisabled])

  const launchDateDisabled = React.useMemo(
    () => projectVendor.launched || !userCanManage,
    [projectVendor.launched],
  )

  const dueDateInvalid = React.useMemo(
    () =>
      toDate(launchDate) &&
      toDate(dueDate) &&
      !isAfter(toDate(dueDate), toDate(launchDate)),
    [launchDate, dueDate],
  )

  const launchDateRequired = !launchDate && !!dueDate
  const dueDateRequired = !!launchDate && !dueDate

  const dueDateDisabled = React.useMemo(
    () => (projectVendor.launched && !datesExtending) || !userCanManage,
    [projectVendor.launched, datesExtending],
  )

  const handleLaunchDatechange = React.useCallback(
    (value) => {
      if (!datesEditing) {
        setDatesEditing(true)
      }
      setLaunchDate(value)
    },
    [datesEditing],
  )

  const handleDueDatechange = React.useCallback(
    (value) => {
      if (!datesEditing) {
        setDatesEditing(true)
      }
      setDueDate(value)
    },
    [datesEditing],
  )

  const handleExtendClick = React.useCallback(() => {
    setDatesExtending(true)
  }, [])

  const handleDatesEditCancel = React.useCallback(() => {
    setDatesEditing(false)
    setDatesExtending(false)
    setLaunchDate(parseDate(get(projectVendor, 'date_range.start_at')))
    setDueDate(parseDate(get(projectVendor, 'date_range.end_at')))
  }, [projectVendor])

  const handleDatesEditConfirm = React.useCallback(() => {
    onDatesChange(projectVendor.id, { start_at: launchDate, end_at: dueDate })
  }, [projectVendor.id, launchDate, dueDate, onDatesChange])

  const menuItems = [
    projectVendor.extendable && (
      <Menu.Item
        key="extend"
        onClick={handleExtendClick}
        disabled={datesExtending}
      >
        <Icon icon="fa fa-arrows-h" />
        Extend
      </Menu.Item>
    ),
    launchable && (
      <Menu.Item key="launch" onClick={() => onLaunch(projectVendor.id)}>
        <Icon icon="fa fa-rocket" />
        Launch now
      </Menu.Item>
    ),
    projectVendor.cancelable && (
      <Menu.Item key="cancel" danger onClick={() => onCancel(projectVendor.id)}>
        <Icon icon="fa fa-times-circle" />
        Cancel
      </Menu.Item>
    ),
  ].filter((e) => e)

  return (
    <Wrapper {...rest}>
      <DateRangeWrapper>
        <DateField
          value={launchDate}
          label="Launch Date"
          placeholder="Select a date"
          controlProps={dateControlProps}
          disabled={launchDateDisabled}
          showError={datesEditing && launchDateRequired}
          errorMessage={
            datesEditing && launchDateRequired ? 'Launch date is required' : ''
          }
          onChange={handleLaunchDatechange}
          preserveHelpTextSpace={false}
          data-test-id={`pv-${projectVendor.id}-launch-date-field`}
        />
        <DateField
          value={dueDate}
          label="Due Date"
          placeholder="Select a date"
          controlProps={dueDateControlProps}
          disabled={dueDateDisabled}
          showError={datesEditing && (dueDateInvalid || dueDateRequired)}
          errorMessage={
            datesEditing
              ? // eslint-disable-next-line no-nested-ternary
                dueDateRequired
                ? 'Due date is required'
                : dueDateInvalid
                ? 'Due date must be after launch date'
                : undefined
              : ''
          }
          onChange={handleDueDatechange}
          preserveHelpTextSpace={false}
          data-test-id={`pv-${projectVendor.id}-due-date-field`}
        />
        {datesEditing && (
          <DateRangeButtons>
            <Button
              color="primary"
              size="sm"
              disabled={launchDateRequired || dueDateRequired || dueDateInvalid}
              onClick={handleDatesEditConfirm}
            >
              <Icon icon="fa fa-check" />
            </Button>
            &nbsp;
            <Button size="sm" onClick={handleDatesEditCancel}>
              <Icon icon="fa fa-times" />
            </Button>
          </DateRangeButtons>
        )}
      </DateRangeWrapper>
      {userCanManage && (
        <ActionsWrapper>
          {menuItems.length > 0 && (
            <Menu placement="right" offset={16} portal>
              <Menu.Button as={EllipsisIconButton} />
              <Menu.Items size="small" arrow>
                {menuItems}
              </Menu.Items>
            </Menu>
          )}
          {projectVendor.awaiting && (
            <Tooltip parent={HourglassIcon}>
              Assessments are invited or in progress
            </Tooltip>
          )}
          {!projectVendor.awaiting && projectVendor.launched && (
            <Tooltip parent={LockIcon}>
              Assessments have been submitted or have expired.
            </Tooltip>
          )}
          {!projectVendor.launched && (
            <IconButton
              icon="fa fa-trash-o"
              onClick={() => onDelete(projectVendor.id)}
            />
          )}
        </ActionsWrapper>
      )}
    </Wrapper>
  )
}

ProjectVendorActions.propTypes = {
  projectVendor: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  launchDisabled: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDatesChange: PropTypes.func.isRequired,
  onLaunch: PropTypes.func.isRequired,
}

export default ProjectVendorActions
