import React from 'react'
import PropTypes from 'prop-types'
import Card from 'components-v2/organisms/Card'
import { MoreButton, Title } from './styles'

const InfoCard = ({
  title,
  viewMoreText,
  onClickViewMore,
  bottomButtonText,
  onClickBottomButton,
  children,
  ...rest
}) => (
  <Card bordered {...rest}>
    <Card.Segment>
      <Title>{title}</Title>
      {children}
    </Card.Segment>
    {viewMoreText && onClickViewMore && (
      <Card.ViewMoreSegment onClick={onClickViewMore}>
        {viewMoreText}
      </Card.ViewMoreSegment>
    )}
    {bottomButtonText && onClickBottomButton && (
      <MoreButton onClick={onClickBottomButton}>
        {bottomButtonText}{' '}
        <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
      </MoreButton>
    )}
  </Card>
)

InfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  viewMoreText: PropTypes.string,
  onClickViewMore: PropTypes.func,
  bottomButtonText: PropTypes.string,
  onClickBottomButton: PropTypes.func,
  children: PropTypes.element,
}

export default React.memo(InfoCard)
