import React from 'react'
import PropTypes from 'prop-types'
// import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import keys from 'lodash/keys'
import map from 'lodash/map'
import some from 'lodash/some'
import { Formik } from 'formik'
import * as yup from 'yup'
import Button from 'components-v2/atoms/Button'
import FormField from 'components-v2/molecules/FormField'
import DraggableRow from 'components-v2/molecules/DraggableRow'
import withDragDropContext from 'hocs/withDragDropContext'
import { moveArrayElementTo } from 'utils/array'
// import { log, setLog } from 'utils/debug'
import { organizeFieldDefs } from 'lib/custom_fields'
import GroupTitleBar from './GroupTitleBar'
import FieldRow from './FieldRow'

import { FieldTableBlock, FieldTable } from './styles'

const dataTypeOptions = {
  string: 'Text',
  integer: 'Number (Whole)', // not a fan of the labeling here
  float: 'Number',
  money: 'Money',
  boolean: 'Yes/No',
  select: 'Selection',
  email: 'Email',
  url: 'Url',
  date: 'Date',
  datetime: 'Date (with Time)',
}

const View = ({
  fieldDefs,
  defaultGroup,
  sortedGroups,
  loaded,
  onCreate,
  onEditClick,
  onNameChange,
  onDeleteClick,
  onCreateGroup,
  onSortGroup,
  onRenameGroup,
  onHideGroup,
  onUnhideGroup,
  onDeleteGroup,
  onRowMove,
}) => {
  const [defsList, setDefsList] = React.useState({})
  const [draggedItemId, setDraggedItemId] = React.useState(null)
  const [dirtyGroups, setDirtyGroups] = React.useState()
  const [rowStates, setRowStates] = React.useState({})

  React.useEffect(() => {
    const newDefsList = organizeFieldDefs(fieldDefs, defaultGroup, sortedGroups)
    setDefsList(newDefsList)
  }, [fieldDefs, sortedGroups])

  React.useEffect(() => {
    setDirtyGroups([])
  }, [])

  const formConfig = {
    validationSchema: yup.object().shape({
      name: yup
        .string()
        .trim()
        .max(100, 'Maximum of 100 characters')
        .notOneOf(map(fieldDefs, 'name'), 'Field names must be unique'),
      data_type: yup.string().required().oneOf(keys(dataTypeOptions)),
    }),
    initialValues: {
      name: '',
      data_type: 'string',
    },
  }

  const handleSubmit = (vals, group, { resetForm }) => {
    const newVals = vals
    newVals.group = group
    onCreate(newVals)
    resetForm()
  }

  const handleRowMove = React.useCallback(
    (/* groupKey, itemId, newIdx */) => {
      const dirty = dirtyGroups
      if (dirty.length === 0) {
        handleCancelRowMove()
        return
      }

      let updateList = defsList[dirty[0]].map((field, idx) => ({
        id: field.id,
        // eslint-disable-next-line camelcase
        render_opts: { group: dirty[0], sort: idx },
      }))
      if (dirty[0] !== dirty[1]) {
        updateList = updateList.concat(
          defsList[dirty[1]].map((field, idx) => ({
            id: field.id,
            // eslint-disable-next-line camelcase
            render_opts: { group: dirty[1], sort: idx },
          })),
        )
      }
      onRowMove(updateList)
      setDraggedItemId(null)
      setDirtyGroups([])
    },
    [fieldDefs, defsList, dirtyGroups],
  )

  const handleTempRowMove = (targetGroupKey, itemId, oldIdx, newIdx) => {
    const field = fieldDefs[draggedItemId]
    const srcGroupKey = field.render_opts.group
    if (srcGroupKey === targetGroupKey) {
      // move within your group
      const oldArr = defsList[targetGroupKey]
      const newArr = moveArrayElementTo(oldArr, oldIdx, newIdx)
      setDefsList({ ...defsList, [targetGroupKey]: newArr })

      // ...not yet:
      // } else {
      //   // move between groups
      //   const srcGroupArr = cloneDeep(defsList[srcGroupKey])
      //   srcGroupArr.splice(oldIdx, 1)
      //   const targetGroupArr = cloneDeep(defsList[targetGroupKey])
      //   field.render_opts.group = targetGroupKey
      //   targetGroupArr.splice(newIdx, 0, field)
      //   setDefsList({
      //     ...defsList,
      //     [srcGroupKey]: srcGroupArr,
      //     [targetGroupKey]: targetGroupArr,
      //   })
    }
    setDirtyGroups([dirtyGroups[0] || srcGroupKey, targetGroupKey])
  }

  const handleCancelRowMove = (/* itemId, oldIdx */) => {
    const newDefsList = organizeFieldDefs(fieldDefs, defaultGroup, sortedGroups)
    setDefsList(newDefsList)
    setDraggedItemId(null)
    setDirtyGroups([])
  }

  const handleRowDragChange = (key, isDragging) => {
    if (isDragging) setDraggedItemId(key)
    setRowStates((rs) => ({ ...rs, [key]: isDragging }))
  }

  const anyRowDragging = some(rowStates, (v) => v)
  return (
    <>
      {map(sortedGroups, (group, groupIdx) => (
        <React.Fragment key={`${group.key}_table`}>
          <Formik
            key={`${group.key}_form`}
            {...formConfig}
            onSubmit={(bits, extra) => handleSubmit(bits, group.key, extra)}
          >
            {(formProps) => (
              <form onSubmit={formProps.handleSubmit}>
                <GroupTitleBar
                  group={group}
                  groupIdx={groupIdx}
                  groupCount={sortedGroups.length}
                  loaded={loaded}
                  onRename={onRenameGroup}
                  onSort={onSortGroup}
                  onHide={onHideGroup}
                  onUnhide={onUnhideGroup}
                  onDelete={onDeleteGroup}
                />
                <FieldTableBlock>
                  <FieldTable>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th width="150">Type</th>
                        <th width="90"> </th>
                      </tr>
                    </thead>
                    <tbody>
                      {map(get(defsList, group.key), (fieldDef, idx) => (
                        <DraggableRow
                          key={`${fieldDef.key}_drag`}
                          id={fieldDef.key}
                          index={idx}
                          itemType="Row"
                          hasDragSource
                          canDrop={(item) =>
                            group.key === fieldDefs[item.id].render_opts.group
                          }
                          onRowMove={(itemId, newIdx) =>
                            handleRowMove(group.key, itemId, newIdx)
                          }
                          onRowTemporaryMove={(oldIdx, newIdx) =>
                            handleTempRowMove(
                              group.key,
                              fieldDef.key,
                              oldIdx,
                              newIdx,
                            )
                          }
                          onRowCancelMove={handleCancelRowMove}
                        >
                          {(dragProps) => (
                            <FieldRow
                              fieldDef={fieldDef}
                              dataType={
                                dataTypeOptions[
                                  fieldDef.meta_type || fieldDef.data_type
                                ]
                              }
                              enableTooltips={!anyRowDragging}
                              dragProps={dragProps}
                              onDragChange={handleRowDragChange}
                              onNameChange={onNameChange}
                              onEditClick={onEditClick}
                              onDeleteClick={onDeleteClick}
                            />
                          )}
                        </DraggableRow>
                      ))}

                      {/* "Add new field" row */}
                      <tr>
                        <td>
                          <FormField
                            name="name"
                            placeholder="New field name"
                            preserveHelpTextSpace={false}
                          />
                        </td>
                        <td>
                          <FormField
                            type={FormField.types.SELECT}
                            name="data_type"
                            options={map(dataTypeOptions, (v, k) => ({
                              value: k,
                              label: v,
                            }))}
                            preserveHelpTextSpace={false}
                          />
                        </td>
                        <td align="right">
                          <Button
                            type="submit"
                            disabled={!!formProps.errors.name}
                          >
                            <i className="fa fa-plus" />
                            Add
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </FieldTable>
                </FieldTableBlock>
              </form>
            )}
          </Formik>
        </React.Fragment>
      ))}
      {sortedGroups.length <= 20 && (
        <div>
          <Button onClick={onCreateGroup} className="top10">
            <i className="fa fa-plus" />
            Add Group
          </Button>
        </div>
      )}
    </>
  )
}

View.propTypes = {
  fieldDefs: PropTypes.object.isRequired,
  defaultGroup: PropTypes.string.isRequired,
  sortedGroups: PropTypes.arrayOf(PropTypes.object),
  loaded: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onNameChange: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onCreateGroup: PropTypes.func.isRequired,
  onSortGroup: PropTypes.func.isRequired,
  onRenameGroup: PropTypes.func.isRequired,
  onHideGroup: PropTypes.func,
  onUnhideGroup: PropTypes.func,
  onDeleteGroup: PropTypes.func.isRequired,
  onRowMove: PropTypes.func.isRequired,
}

export default withDragDropContext(View)
