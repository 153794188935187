import { useCallback } from 'react'
import { useModal, useGlobalLoader } from 'hooks'
import NotificationManager from 'lib/notifications'
import { useUpdateCurrentOrganization } from 'apis'
import BCCEmailFormModal from './BCCEmailFormModal'

export default function useUpdateBccEmail(organization) {
  const [openModal, closeModal] = useModal(BCCEmailFormModal)
  const [showLoader, hideLoader] = useGlobalLoader()
  const { mutateAsync: updateCurrentOrganization } =
    useUpdateCurrentOrganization()
  const { bcc_email: bccEmail, id: organizationId } = organization

  return useCallback(() => {
    const onSubmit = async (formData) => {
      const loaderId = showLoader()
      try {
        await updateCurrentOrganization({
          data: {
            id: organizationId,
            organization: formData,
          },
        })
        closeModal()
      } catch (error) {
        if (error.response.status === 422) {
          // Example response
          // { message: ["Bcc email only allows valid emails"] }
          NotificationManager.error(error.response?.data?.message)
        } else {
          NotificationManager.error()
        }
      }
      hideLoader(loaderId)
    }
    openModal({
      bcc_email: bccEmail,
      onSubmit,
    })
  }, [
    openModal,
    organizationId,
    bccEmail,
    updateCurrentOrganization,
    showLoader,
    hideLoader,
  ])
}
