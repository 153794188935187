import styled from 'styled-components'
import { colors } from 'styles'

export const ChartWrapper = styled.div`
  position: relative;
  margin: 30px 0 20px;
`

export const ChartContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  padding-top: 10px;
  transform: translate(-50%, -50%);
  z-index: 1;
`

export const UpdatedAt = styled.div`
  flex: none;
  font-size: 13px;
  line-height: 16px;
  color: ${colors.DARK_GREY};
  margin-top: 10px;
`

export const Tiny = styled.p`
  font-size: 10px;
  font-weight: bold;
  line-height: 12px;
  color: ${colors.DARK_GREY};
  margin-top: 10px;
  text-align: left;
`
