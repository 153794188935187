import { useCallback } from 'react'
import { useConvertLibraryProfile } from 'apis'
import { getPageUrl } from 'utils/url'
import { useGlobalLoader } from 'hooks'
import NotificationManager from 'lib/notifications'

export default function useAddVendor() {
  const { mutateAsync: convertLibraryProfile } = useConvertLibraryProfile()
  const [showLoader, hideLoader] = useGlobalLoader()

  return useCallback(
    async (id) => {
      const loaderId = showLoader()
      try {
        const response = await convertLibraryProfile({ id })
        window.location = getPageUrl('clientVendor', {
          id: response.id,
        })
      } catch {
        NotificationManager.error()
      }
      hideLoader(loaderId)
    },
    [convertLibraryProfile, showLoader, hideLoader],
  )
}
