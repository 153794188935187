import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from 'components-v2/atoms/Icon'

const Cell = styled.span`
  color: white;
  background-color: ${(props) => props.backgroundColor};
  text-align: center;
  border-radius: 10000px;
  padding: 0px 15px;
  white-space: nowrap;
`

export default function OfacStatusCell({ label, color, icon }) {
  return (
    <Cell backgroundColor={color}>
      <Icon icon={icon} />
      &nbsp;
      {label}
    </Cell>
  )
}

OfacStatusCell.propTypes = {
  color: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
}
