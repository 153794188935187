import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import keyBy from 'lodash/keyBy'
import Button from 'components-v2/atoms/Button'
import Select from 'components-v2/atoms/Select'
import ToolbarGroup from 'components-v2/molecules/ToolbarGroup'
import { RatingLabelSetOptions } from 'constants/index'
import {
  PreviewRow,
  PreviewLeftCol,
  RatingLabel,
  RiskScore,
  PreviewRightCol,
  StyledResponseScoreSelect,
  Footer,
} from './styles'

const RatingLabelSetsObj = keyBy(RatingLabelSetOptions, 'value')

const EditRatingLabelForm = ({ value, onSave, onClose, ...rest }) => {
  const [current, setCurrent] = React.useState(value)
  const ratingLabelSetOption = RatingLabelSetsObj[current]
  const labelSize = Object.keys(ratingLabelSetOption?.score_labels).length || 5

  const handleSave = useCallback(
    async (newValue) => {
      try {
        await onSave(newValue)
        // Automatically close on success
        onClose()
        // eslint-disable-next-line no-empty
      } catch {}
    },
    [onSave, onClose],
  )

  return (
    <div {...rest}>
      <Select
        value={current || ''}
        onChange={(event) => setCurrent(event.target.value)}
      >
        <option key="default" value="" disabled>
          Select
        </option>
        {RatingLabelSetOptions.map((e) => (
          <option key={e.value} value={e.value}>
            {e.label}
          </option>
        ))}
      </Select>
      {ratingLabelSetOption && (
        <PreviewRow>
          <PreviewLeftCol>
            <RatingLabel>{ratingLabelSetOption.rating_label}</RatingLabel>
            <RiskScore>XX%</RiskScore>
          </PreviewLeftCol>
          <PreviewRightCol>
            {Array(labelSize)
              .fill(null)
              .map((_, i) => (
                <StyledResponseScoreSelect
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  value={i + (3 - labelSize)}
                  ratingLabelSet={current}
                />
              ))}
          </PreviewRightCol>
        </PreviewRow>
      )}
      <Footer>
        <ToolbarGroup>
          <Button color="primary" onClick={() => handleSave(current)}>
            Save
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ToolbarGroup>
      </Footer>
    </div>
  )
}

EditRatingLabelForm.propTypes = {
  value: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default EditRatingLabelForm
