import { useSimpleFormDialog, useGlobalLoader } from 'hooks'
import React from 'react'
import { useDisableTwoFactorAuth } from 'apis'
import { getPageUrl } from 'utils/url'

const useDisableTwoFactorAuthDialog = () => {
  const [openFormDialog, closeFormDialog] = useSimpleFormDialog(
    'Two-Factor Authentication',
  )
  const [showLoader, hideLoader] = useGlobalLoader()
  const { mutateAsync: disable } = useDisableTwoFactorAuth()

  return React.useCallback(() => {
    const fields = [
      {
        name: 'pin_code',
        label: '',
        placeholder: '',
        validType: 'string',
        required: true,
      },
    ]
    openFormDialog({
      fields,
      onSubmit: (formData, { setFieldError, setSubmitting }) => {
        const loaderId = showLoader()
        disable({ data: { pin_code: formData.pin_code } })
          .then(() => {
            window.location.href = getPageUrl('user', undefined, {
              tab: 'security',
            })
            closeFormDialog()
          })
          .catch((error) => {
            setFieldError('pin_code', error.response.data.errors.pin_code)
            setSubmitting(false)
          })
          .finally(() => {
            hideLoader(loaderId)
          })
      },
      submitColor: 'danger',
      bodyContent:
        'Are you sure you want to disable two-factor authentication? This will clear all two-factor authentication registrations and delete any backup codes.',
    })
  }, [openFormDialog, closeFormDialog, disable, hideLoader, showLoader]) // this array should be outside the function
}

export default useDisableTwoFactorAuthDialog
