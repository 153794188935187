/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import truncate from 'lodash/truncate'
import A from 'components-v2/atoms/A'
import Tooltip from 'components-v2/molecules/Tooltip'
import SearchableSelectField from 'components-v2/molecules/FormField/SearchableSelectField'
import { ControlLabel } from 'components-v2/molecules/FormField'
import { formatText, LONG_TEXT_LENGTH } from '../utils'

import {
  BlockWell,
  DetailDisplayRow,
  DetailDisplayLabel,
  DetailDisplayBody,
  SelectionLabelRow,
  DirtySource,
  DetailBlock,
  DetailTitleRow,
  DetailItemLabel,
  DetailItemBody,
  SourceIconBar,
  EditIcon,
  CopyIcon,
  OpenExternalIcon,
  ExpandDownIcon,
  CompressIcon,
  TrashIcon,
  UndoIcon,
} from '../../styles'

const View = ({
  mode,
  sourceMode,
  currentAutomation,
  currentSource,
  contractId,
  clause,
  contractList,
  clauseList,
  getSourceContractOptions,
  getClauseListOptions,
  handleSourceCreate,
  handleContractSelect,
  handleSourceEdit,
  handleSourceCopy,
  handleSourceReset,
  handleSourceClear,
  applyClause,
  openBodyDisplayModal,
}) => {
  const [lgTextDisplayMode, setLgTextDisplayMode] = React.useState('truncate')

  const handleTextBlockClick = (method) => setLgTextDisplayMode(method)

  return (
    (mode !== 'display' || !isEmpty(currentSource)) && (
      <>
        {sourceMode !== 'select' ? (
          <DetailDisplayRow>
            {contractList.length === 0 ? (
              <>New AutoReport Section:</>
            ) : (
              <>
                {sourceMode === 'display' ? (
                  <>
                    <DetailDisplayLabel>Then: </DetailDisplayLabel>
                    <DetailDisplayBody>
                      Include the following AutoReport section:
                    </DetailDisplayBody>
                  </>
                ) : (
                  <>
                    Include the following
                    <DirtySource>
                      &nbsp;{sourceMode === 'edit' ? 'updated' : 'new'}&nbsp;
                    </DirtySource>
                    AutoReport section:
                  </>
                )}
              </>
            )}
          </DetailDisplayRow>
        ) : (
          <>
            {contractList.length === 0 ? (
              <ControlLabel>
                <A onClick={() => handleSourceCreate(true)}>
                  Create a new AutoReport Section
                </A>
              </ControlLabel>
            ) : (
              <SelectionLabelRow>
                <ControlLabel>
                  Select existing AutoReport section to apply, or{' '}
                  <A onClick={() => handleSourceCreate(true)}>
                    create a new one
                  </A>
                </ControlLabel>
              </SelectionLabelRow>
            )}
          </>
        )}

        {sourceMode === 'select' && contractList.length > 0 && (
          <>
            {contractList.length > 1 && (
              <SearchableSelectField
                label="AutoReport Collection"
                value={contractId}
                horizontal
                isClearable
                options={getSourceContractOptions()}
                onChange={handleContractSelect}
              />
            )}
            {!isEmpty(clauseList) && (
              <SearchableSelectField
                label="AutoReport Section"
                value={
                  clause.client_source_contract_id === contractId
                    ? clause.id
                    : ''
                }
                horizontal
                isClearable
                options={getClauseListOptions()}
                onChange={applyClause}
              />
            )}
          </>
        )}

        {['display', 'select', 'add', 'copy', 'edit'].includes(sourceMode) && (
          <>
            {!isEmpty(clause) && (
              <BlockWell>
                <DetailBlock>
                  <DetailTitleRow>
                    <DetailItemLabel>{clause.name}</DetailItemLabel>
                    <SourceIconBar>
                      {sourceMode !== 'display' && (
                        <>
                          <Tooltip
                            parent={<EditIcon onClick={handleSourceEdit} />}
                          >
                            Edit this AutoReport section
                          </Tooltip>
                          {sourceMode !== 'add' && sourceMode !== 'copy' && (
                            <Tooltip
                              parent={<CopyIcon onClick={handleSourceCopy} />}
                            >
                              Create a duplicate of this AutoReport section
                            </Tooltip>
                          )}
                        </>
                      )}
                      <Tooltip
                        parent={
                          <OpenExternalIcon onClick={openBodyDisplayModal} />
                        }
                      >
                        View full AutoReport section in a new window
                      </Tooltip>
                      {sourceMode !== 'display' && (
                        <>
                          {['add', 'edit', 'copy'].includes(sourceMode) ||
                          clause.id !==
                            get(currentAutomation, 'automation_source.id') ? (
                            <Tooltip
                              parent={<UndoIcon onClick={handleSourceReset} />}
                            >
                              Revert any changes to this AutoReport section
                            </Tooltip>
                          ) : (
                            <Tooltip
                              parent={<TrashIcon onClick={handleSourceClear} />}
                            >
                              Remove this AutoReport section from the automation
                            </Tooltip>
                          )}
                        </>
                      )}
                    </SourceIconBar>
                  </DetailTitleRow>
                  <DetailItemBody>
                    {lgTextDisplayMode === 'full'
                      ? formatText(
                          clause.body,
                          <>
                            {clause.body.length > 1000 && (
                              <Tooltip
                                position="bottom"
                                parent={
                                  <CompressIcon
                                    onClick={() =>
                                      handleTextBlockClick('truncate')
                                    }
                                  />
                                }
                              >
                                Compress
                              </Tooltip>
                            )}
                          </>,
                        )
                      : // the indent looks kinda silly if we only have one or two lines
                        clause.body &&
                        (clause.body.length < 100
                          ? clause.body
                          : formatText(
                              truncate(clause.body, {
                                length: LONG_TEXT_LENGTH,
                                separator: ' ',
                              }),
                              <>
                                {clause.body.length > LONG_TEXT_LENGTH - 50 && (
                                  <>
                                    <Tooltip
                                      position="bottom"
                                      parent={
                                        <ExpandDownIcon
                                          onClick={() =>
                                            handleTextBlockClick('full')
                                          }
                                        />
                                      }
                                    >
                                      Expand
                                    </Tooltip>
                                  </>
                                )}
                              </>,
                            ))}
                  </DetailItemBody>
                </DetailBlock>
              </BlockWell>
            )}
          </>
        )}
      </>
    )
  )
}

View.propTypes = {
  mode: PropTypes.string,
  sourceMode: PropTypes.string,
  currentAutomation: PropTypes.object,
  currentSource: PropTypes.object,
  contractId: PropTypes.string,
  clause: PropTypes.object,
  contractList: PropTypes.array,
  clauseList: PropTypes.object,
  handleSourceCreate: PropTypes.func.isRequired,
  getSourceContractOptions: PropTypes.func.isRequired,
  handleContractSelect: PropTypes.func.isRequired,
  getClauseListOptions: PropTypes.func.isRequired,
  applyClause: PropTypes.func.isRequired,
  handleSourceEdit: PropTypes.func.isRequired,
  handleSourceCopy: PropTypes.func.isRequired,
  handleSourceReset: PropTypes.func.isRequired,
  handleSourceClear: PropTypes.func.isRequired,
  openBodyDisplayModal: PropTypes.func.isRequired,
}

export default View
