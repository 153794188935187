import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import Menu from 'components-v2/molecules/Menu'
import { useUpdateAssistAssessment } from 'apis'
import NotificationManager from 'lib/notifications'
import {
  AssistAssessmentStatusTypes,
  AssistAssessmentTransitionTypes,
} from 'constants/index'
import useUploadCompletedAssessment from './useUploadCompletedAssessment'
import { EllipsisIconButton } from './styles'

function ClientActionButton({ id, status, completeAssessment }) {
  const { mutateAsync: updateAssistAssessment } = useUpdateAssistAssessment()
  const onUploadSuccess = useCallback(() => {
    if (status === AssistAssessmentStatusTypes.IN_REVIEW) {
      updateAssistAssessment({
        id,
        data: {
          assessment: {
            transition: AssistAssessmentTransitionTypes.COMPLETE_REVIEW,
          },
        },
      }).catch((error) => {
        NotificationManager.error()
        console.error(error)
      })
    }
  }, [id, status])
  const uploadCompletedAssessment = useUploadCompletedAssessment(
    id,
    onUploadSuccess,
  )

  const items = [
    status === AssistAssessmentStatusTypes.IN_REVIEW && {
      children: 'Upload Reviewed',
      onClick: uploadCompletedAssessment,
    },
    (status === AssistAssessmentStatusTypes.IN_REVIEW ||
      status === AssistAssessmentStatusTypes.COMPLETED) && {
      children: 'Download',
      href: completeAssessment.url,
      target: '_blank',
    },
  ].filter((e) => e)

  if (items.length === 0) {
    return null
  }
  return (
    <Menu placement="top-end" offset={8} portal>
      <Menu.Button as={EllipsisIconButton} />
      <Menu.Items size="small" arrow>
        {items.map((e, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Menu.Item key={index} {...e} />
        ))}
      </Menu.Items>
    </Menu>
  )
}

ClientActionButton.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  completeAssessment: PropTypes.object,
}

export default ClientActionButton
