import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from 'components-v2/atoms/Checkbox'
import Table from 'components-v2/molecules/Table'
import Toolbar, {
  SearchInput,
  ColumnToggler,
} from 'components-v2/molecules/Table/Toolbar'
import FilterDropdownTray from 'components-v2/molecules/FilterDropdownTray'
import MenuDropdown from 'components-v2/molecules/MenuDropdown'
import { useAuthorizations } from 'hooks'
import { applyColumnToggles } from 'lib/table'
import { LabelTypes } from 'constants/index'
// Constants
import { ActionMenuItemTypes, RequestOpts } from './constants'
import { getVendorTableColumns } from './columns'

const View = ({
  vendors,
  totalSize,
  page,
  sizePerPage,
  sortField,
  sortOrder,
  searchText,
  includeArchived,
  filter,
  filterConfigList,
  loading,
  requests,
  canBulkImport,
  onPaginationChange,
  onSortingChange,
  onSearchChange,
  onMenuItemSelect,
  onToggleArchived,
  onFilterChange,
  columnToggles,
  disabledIntegrations,
  onColumnTogglesChange,
}) => {
  const [canExport, canViewArchived, canShowFull] = useAuthorizations(
    'export',
    'view_archived',
    'show_vendor',
  )
  const columns = React.useMemo(
    () => getVendorTableColumns(requests, canShowFull),
    [requests, canShowFull],
  )

  const finalColumns = React.useMemo(
    () => applyColumnToggles(columns, columnToggles),
    [columns, columnToggles],
  )

  const { inactiveLabel } = RequestOpts[requests]
  const actionLabel = requests
    ? `${LabelTypes.VENDOR} Request`
    : LabelTypes.VENDOR
  return (
    <div>
      <Toolbar>
        <Toolbar.Group>
          <SearchInput value={searchText} onChange={onSearchChange} />
          {canViewArchived && (
            <Checkbox
              checked={includeArchived}
              onChange={onToggleArchived}
              label={inactiveLabel}
              disabled={filter.status?.selected?.length > 0}
            />
          )}
        </Toolbar.Group>
        <Toolbar.Group>
          <ColumnToggler
            columns={columns}
            selected={columnToggles}
            onToggle={onColumnTogglesChange}
            maskedColumns={disabledIntegrations}
          />
          <FilterDropdownTray
            value={filter}
            configList={filterConfigList}
            onChange={onFilterChange}
          />
          <MenuDropdown title="Actions" onSelect={onMenuItemSelect}>
            {canBulkImport && (
              <MenuDropdown.Item eventKey={ActionMenuItemTypes.IMPORT_VENDORS}>
                <i className="fa fa-table" />
                {`Import ${LabelTypes.VENDORS} (CSV)`}
              </MenuDropdown.Item>
            )}
            {canBulkImport && (
              <MenuDropdown.Item
                eventKey={ActionMenuItemTypes.IMPORT_CUSTOM_FIELDS}
              >
                <i className="fa fa-table" />
                Import Custom Fields (CSV)
              </MenuDropdown.Item>
            )}
            {canExport && (
              <MenuDropdown.Item eventKey={ActionMenuItemTypes.EXPORT_CSV}>
                <i className="fa fa-table" />
                {`Export ${actionLabel} Details (CSV)`}
              </MenuDropdown.Item>
            )}
            {canExport && (
              <MenuDropdown.Item eventKey={ActionMenuItemTypes.EXPORT_XLS}>
                <i className="fa fa-table" />
                {`Export ${actionLabel} Details (XLS)`}
              </MenuDropdown.Item>
            )}
            {canExport && !requests && (
              <MenuDropdown.Item
                eventKey={ActionMenuItemTypes.EXPORT_CONTACTS_CSV}
              >
                <i className="fa fa-table" />
                {`Export ${LabelTypes.VENDOR} Contacts (CSV)`}
              </MenuDropdown.Item>
            )}
          </MenuDropdown>
        </Toolbar.Group>
      </Toolbar>
      <Table
        loading={loading}
        data={vendors || []}
        columns={finalColumns}
        totalSize={totalSize}
        pagination={{ page, sizePerPage }}
        sorting={{ sortField, sortOrder }}
        onPaginationChange={onPaginationChange}
        onSortingChange={onSortingChange}
        manualPagination
        manualSorting
        noDataText="No Vendors found."
        type={Table.types.ALT}
        onRowClick={(row) => {
          window.location.href = row.original.path
        }}
      />
    </div>
  )
}

View.propTypes = {
  vendors: PropTypes.array,
  totalSize: PropTypes.number,
  page: PropTypes.number,
  sizePerPage: PropTypes.number,
  sortField: PropTypes.string,
  sortOrder: PropTypes.string,
  searchText: PropTypes.string,
  includeArchived: PropTypes.bool,
  filter: PropTypes.object,
  filterConfigList: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  requests: PropTypes.bool,
  columnToggles: PropTypes.array,
  disabledIntegrations: PropTypes.array,
  canBulkImport: PropTypes.bool,
  onPaginationChange: PropTypes.func.isRequired,
  onSortingChange: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onMenuItemSelect: PropTypes.func.isRequired,
  onToggleArchived: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onColumnTogglesChange: PropTypes.func.isRequired,
}

export default View
