import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { compose, withAuthorize, withReactQuery } from 'hocs'
import useRequestVendor from 'components/client/shared/useRequestVendor'
import View from './View'

function AssessmentsView({ vendorRequestConfig, ...rest }) {
  const requestVendor = useRequestVendor()
  const handleRequestVendorClick = useCallback(() => {
    requestVendor({ vendorRequestConfig })
  }, [vendorRequestConfig, requestVendor])
  return (
    <View
      {...rest}
      requestEnabled={vendorRequestConfig.enabled}
      onRequestVendorClick={handleRequestVendorClick}
    />
  )
}

AssessmentsView.propTypes = {
  orgId: PropTypes.string.isRequired,
  orgRole: PropTypes.string.isRequired,
  vendorRoles: PropTypes.object.isRequired,
  userPrefs: PropTypes.object.isRequired,
  vendorRequestConfig: PropTypes.object.isRequired,
}

export default compose(withAuthorize(), withReactQuery())(AssessmentsView)
