import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Counter } from './styles'

const CharacterCounter = ({
  length,
  maxLength,
  visibleOnFocusOnly,
  children,
  ...rest
}) => (
  <Wrapper {...rest}>
    {children}
    <Counter visibleOnFocusOnly={visibleOnFocusOnly}>
      {!!length && !!maxLength && `${length}/${maxLength}`}
    </Counter>
  </Wrapper>
)

CharacterCounter.propTypes = {
  length: PropTypes.number,
  maxLength: PropTypes.number,
  visibleOnFocusOnly: PropTypes.bool,
  children: PropTypes.any,
}

CharacterCounter.defaultProps = {
  visibleOnFocusOnly: true,
}

export default CharacterCounter
