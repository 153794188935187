import PropTypes from 'prop-types'
import React from 'react'
import DList from 'components-v2/molecules/DList'
import InfoCard from '../InfoCard'
import TagList from '../TagList'

const GeneralCard = ({ globalOrg }) => (
  <InfoCard title="General contact info">
    <DList>
      <dt>Address</dt>
      <dd>
        {globalOrg.main_street_name} {globalOrg.main_street_number}
        <br />
        {globalOrg.main_city}, {globalOrg.main_state_code}{' '}
        {globalOrg.main_postal_code}
        <br />
        {globalOrg.main_country}
      </dd>
      <dt>Phone number</dt>
      <dd>{globalOrg.main_phone}</dd>
      <dt>Email address(es)</dt>
      <dd>
        <TagList list={globalOrg.site_emails} plural="email addresses" />
      </dd>
    </DList>
  </InfoCard>
)

GeneralCard.propTypes = {
  globalOrg: PropTypes.object.isRequired,
}

export default GeneralCard
