import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { Row, Col } from 'components-v2/organisms/Layout'
import InfoCard from '../InfoCard'
import FacetableSet from './FacetableSet'

const theme = {
  hGutter: 20,
  vGutter: 20,
}

const RiskMeasuresCard = ({ data }) => (
  <InfoCard title="Risk Measures">
    <ThemeProvider theme={theme}>
      <Row $equalHeight break="mobile">
        {data.map((set) => (
          <Col
            key={set.id}
            lg={data.length === 1 ? 12 : data.length === 2 ? 6 : 4}
            md={data.length === 1 ? 12 : 6}
            sm={data.length === 1 ? 12 : data.length === 2 ? 6 : 4}
            xs={12}
          >
            <FacetableSet data={set} />
          </Col>
        ))}
      </Row>
    </ThemeProvider>
  </InfoCard>
)

RiskMeasuresCard.propTypes = {
  data: PropTypes.array.isRequired,
}

export default RiskMeasuresCard
