import React, { useState, useCallback } from 'react'
import { AssessmentTemplateStatusTypes } from 'constants/index'
import { compose, withAuthorize, withReactQuery } from 'hocs'
import config from 'config'
import { useClientAssessmentTemplatesFetcher } from 'apis'
import useAddAssessmentTemplateFromLibrary from './useAddAssessmentTemplateFromLibrary'
import useCreateAssessmentTemplate from './useCreateAssessmentTemplate'
import View from './View'

const statusFilterOptions = [
  AssessmentTemplateStatusTypes.IN_PROCESS,
  AssessmentTemplateStatusTypes.READY,
  AssessmentTemplateStatusTypes.DRAFT,
  AssessmentTemplateStatusTypes.ARCHIVED,
]

const Container = () => {
  const [page, setPage] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(
    config.collectionQueryPageLimit.default,
  )
  const [sortField, setSortField] = useState()
  const [sortOrder, setSortOrder] = useState()
  const [searchText, setSearchText] = useState('')
  const [statusFilter, setStatusFilter] = useState('')
  const {
    data: templates,
    totalSize,
    isFetching,
  } = useClientAssessmentTemplatesFetcher({
    params: {
      page,
      per_page: sizePerPage,
      ordered_by: sortField,
      direction: sortOrder,
      search_query: searchText,
      status: statusFilter,
      with_assessment_counts: true,
    },
  })

  const addAssessmentTemplateFromLibrary = useAddAssessmentTemplateFromLibrary()
  const createAssessmentTemplate = useCreateAssessmentTemplate()

  const handlePaginationChange = useCallback((newState) => {
    setPage(newState.page)
    setSizePerPage(newState.sizePerPage)
  }, [])

  const handleSortingChange = useCallback((newState) => {
    setSortField(newState.sortField)
    setSortOrder(newState.sortOrder)
  }, [])

  const handleSearchChange = useCallback((newValue) => {
    // Let's reset page to 1
    setPage(1)
    setSearchText(newValue)
  }, [])

  const handleStatusFilterChange = useCallback((event) => {
    // Let's reset page to 1
    setPage(1)
    setStatusFilter(event.target.value)
  }, [])

  return (
    <View
      templates={templates}
      totalSize={totalSize}
      loading={isFetching}
      page={page}
      sizePerPage={sizePerPage}
      sortField={sortField}
      sortOrder={sortOrder}
      searchText={searchText}
      statusFilter={statusFilter}
      statusFilterOptions={statusFilterOptions}
      onPaginationChange={handlePaginationChange}
      onSortingChange={handleSortingChange}
      onSearchChange={handleSearchChange}
      onStatusFilterChange={handleStatusFilterChange}
      onCreateClientAssessmentTemplate={createAssessmentTemplate}
      onShowLibrary={addAssessmentTemplateFromLibrary}
    />
  )
}

export default compose(withAuthorize(), withReactQuery())(Container)
