import React from 'react'
import PropTypes from 'prop-types'
import Well from 'components-v2/atoms/Well'
import Button from 'components-v2/atoms/Button'
import IconButton from 'components-v2/atoms/IconButton'
import FileInput from 'components-v2/molecules/FileInput'
import Table from 'components-v2/molecules/Table'
import Dialog from 'components-v2/molecules/Dialog'
import { BaseField } from 'components-v2/molecules/FormField'
import InputField from 'components-v2/molecules/FormField/InputField'
import DateField from 'components-v2/molecules/FormField/DateField'
import { Row, Col } from 'components-v2/organisms/Layout'
import AttributeEditLink from 'components/elements/attribute_edit_link'
import { dateFormatterFactory } from 'lib/table'
import NotificationManager from 'lib/notifications'
import objectToFormData from 'utils/object_to_formdata'
import { useGlobalLoader } from 'hooks'
import config from 'config'
import {
  useAssistAnswerFilesFetcher,
  useCreateAssistAnswerFile,
  useDeleteAssistAnswerFile,
} from 'apis'

const dateFormatter = dateFormatterFactory()

const getColumns = (deleteFile) => [
  {
    id: 'attachments',
    header: 'File',
    cell: (props) =>
      props.getValue()?.map(
        (attachment) => (
          <a key={attachment.id} target="_blank" href={attachment.url}>
            <i className="fa fa-file-text-o" />
            &nbsp;{attachment.name}
          </a>
        ),
        this,
      ),
  },
  {
    id: 'created_at',
    header: 'Uploaded On',
    cell: dateFormatter,
  },
  {
    id: 'completed_at',
    header: 'Completed On',
    cell: dateFormatter,
  },
  {
    id: 'comment',
    header: 'Comment',
    cell: (props) => (
      <AttributeEditLink
        name="comment"
        title="Please enter comment"
        value={props.getValue()}
        model="answer_file"
        attribute="comment"
        url={props.row.original.api_path}
        emptyValueText="Add Comment"
      />
    ),
  },
  {
    id: 'df',
    isDummyField: true,
    header: '',
    cell: (props) => (
      <IconButton
        onClick={() => deleteFile(props.row.original.id)}
        title="Delete"
        icon="fa fa-trash"
      />
    ),
  },
]

export default function AnswerFileModal(props) {
  const { mutateAsync: createAssistAnswerFile } = useCreateAssistAnswerFile()
  const { mutateAsync: deleteAssistAnswerFile } = useDeleteAssistAnswerFile()
  const [showLoader, hideLoader] = useGlobalLoader()
  const [completedAt, setCompletedAt] = React.useState(null)
  const [comment, setComment] = React.useState('')
  const [file, setFile] = React.useState(null)
  const { data: answerFiles, isLoading } = useAssistAnswerFilesFetcher({
    params: {
      page: 1,
      per_page: config.collectionQueryTotalLimit.answerFiles,
    },
  })

  const saveUpload = () => {
    if (file) {
      const loaderId = showLoader()
      const data = {
        answer_file: {
          attachments_attributes: [{ file }],
          completed_at: completedAt,
          comment,
        },
      }

      createAssistAnswerFile({ data: objectToFormData(data) })
        .then(() => {
          setFile(null)
          setComment('')
          setCompletedAt(null)
        })
        .catch((error) => {
          NotificationManager.error()
          console.error(error)
        })
        .then(() => {
          hideLoader(loaderId)
        })
    }
  }

  const deleteFile = (id) => {
    // eslint-disable-next-line no-restricted-globals
    if (!confirm('Are you sure you want to delete this file?')) return

    const loaderId = showLoader()
    deleteAssistAnswerFile({ id })
      .catch((error) => {
        NotificationManager.error()
        console.error(error)
      })
      .then(() => {
        hideLoader(loaderId)
      })
  }

  const columns = getColumns(deleteFile)

  return (
    <div>
      <Dialog size="lg" open={props.open} onClose={props.onClose}>
        <Dialog.Header onClose={props.onClose}>Answer Files</Dialog.Header>
        <Dialog.Content>
          <h5>Upload New Answer File</h5>
          <Well size="small">
            <Row>
              <Col xs={8}>
                <InputField
                  controlId="formInlineName"
                  label="Comment:"
                  type="text"
                  value={comment}
                  onChange={setComment}
                />
              </Col>
              <Col xs={4}>
                <DateField
                  controlId="formInlineEmail"
                  label="Completed On:"
                  controlProps={{ todayButton: 'Today' }}
                  value={completedAt}
                  onChange={setCompletedAt}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={10}>
                <BaseField controlId="formInlineFile">
                  <FileInput
                    value={file}
                    accept=".pdf,.doc,.docx,.xls,.xlsx,.csv,.xlsm,.zip,.rtf,.jpg,.jpeg,.png,.gif,.ppt,.pptx"
                    onChange={setFile}
                  />
                </BaseField>
              </Col>
              <Col xs={2}>
                <Button
                  color="primary"
                  className="pull-right"
                  disabled={!file}
                  onClick={saveUpload}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Well>
          <Table
            loading={isLoading}
            data={answerFiles ?? []}
            columns={columns}
            enableSorting={false}
            enablePagination={false}
            noDataText="No files have been uploaded."
          />
        </Dialog.Content>
        <Dialog.Footer>
          <Button onClick={props.onClose}>Close</Button>
        </Dialog.Footer>
      </Dialog>
    </div>
  )
}

AnswerFileModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}
