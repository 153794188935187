import React from 'react'
import { toast } from 'react-toastify'
import castArray from 'lodash/castArray'
import isArray from 'lodash/isArray'
import isPlainObject from 'lodash/isPlainObject'
import map from 'lodash/map'
import size from 'lodash/size'

export const createNotificationManager = (config = {}) => {
  const {
    timeout = 5000,
    errorMessage = 'There was an error processing your request.',
  } = config

  const parseNonPrimitiveMessage = (message) => {
    if (
      !React.isValidElement(message) &&
      (isArray(message) || isPlainObject(message))
    ) {
      // If message is coming back from the app api layer
      // (e.g. via error.response.data?.errors), the structure is an object
      // where each value in theory should be an array of strings, even if
      // there's only one.
      return (
        <>
          {map(message, (e, key) => {
            const msgList = castArray(e)
            return (
              <>
                {map(msgList, (msg, idx) => (
                  <div key={`${key}.${idx}`}>
                    {(size(msgList) > 1 || size(message) > 1) && '*'} {msg}
                  </div>
                ))}
              </>
            )
          })}
        </>
      )
    }
    return message
  }

  const error = (message = errorMessage) => {
    toast.error(parseNonPrimitiveMessage(message), {
      autoClose: timeout,
    })
  }

  const success = (message) => {
    toast.success(parseNonPrimitiveMessage(message), {
      autoClose: timeout,
    })
  }

  return { success, error }
}

export default createNotificationManager()
