// Opera 8.0+
// can't confirm at the moment -jfouse
/*
export const isOpera =
  // eslint-disable-next-line no-undef
  (!!window.opr && !!opr.addons) ||
  !!window.opera ||
  navigator.userAgent.indexOf(' OPR/') >= 0
*/

// Firefox 1.0+
// ...still seems valid as of Firefox 87.x -jfouse
export const isFirefox = typeof InstallTrigger !== 'undefined'

// Safari 3.0+ "[object HTMLElementConstructor]"
// ...still seems valid as of Safari 14.x -jfouse
export const isSafari =
  /constructor/i.test(window.HTMLElement) ||
  // eslint-disable-next-line func-names
  (function (p) {
    return p.toString() === '[object SafariRemoteNotification]'
  })(
    !window.safari ||
      // eslint-disable-next-line no-undef
      (typeof safari !== 'undefined' && safari.pushNotification),
  )

// Internet Explorer 6-11
// confirmed -jfouse
export const isIE = /* @cc_on!@ */ false || !!document.documentMode

// Edge 20+
// ...nope, not valid at least in 89.x anyway -jfouse
// export const isEdge = !isIE && !!window.StyleMedia

// Chrome 1 - 71
// not valid anymore, current version: 89.x -jfouse
/*
export const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)
*/

// Blink engine detection
// can't confirm at the moment -jfouse
// export const isBlink = (isChrome || isOpera) && !!window.CSS
