// MultiSelectFilterDropdown component composing molecules/Popover and its subcomponents
import React from 'react'
import PropTypes from 'prop-types'
import pick from 'lodash/pick'
import omit from 'lodash/omit'
import Popover from 'components-v2/molecules/Popover'
import MultiSelectFilter from '../MultiSelectFilter'
import { StyledMultiSelectFilter, Caret } from './styles'

const filterPropKeys = [
  'value',
  'defaultValue',
  'options',
  'selectAllVisible',
  'matchVisible',
  'otherVisible',
  'otherLabel',
  'onChange',
  'RenderCheckbox',
]

const MultiSelectFilterDropdown = ({ title, buttonProps = {}, ...rest }) => {
  const filterProps = pick(rest, filterPropKeys)
  const parentProps = omit(rest, filterPropKeys)
  return (
    <Popover placement="bottom-start" offset={2} {...parentProps}>
      <Popover.Button {...buttonProps}>
        {title}
        <Caret />
      </Popover.Button>
      <Popover.Panel>
        <StyledMultiSelectFilter {...filterProps} />
      </Popover.Panel>
    </Popover>
  )
}

MultiSelectFilterDropdown.propTypes = {
  ...MultiSelectFilter.propTypes,
  title: PropTypes.string.isRequired,
  buttonProps: PropTypes.shape({
    active: PropTypes.bool,
    size: PropTypes.string,
    color: PropTypes.string,
  }),
}

export default MultiSelectFilterDropdown
