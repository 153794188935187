import React from 'react'
import PropTypes from 'prop-types'
import Well from 'components-v2/atoms/Well'
import FormField from 'components-v2/molecules/FormField'
import FormDialog from 'components-v2/organisms/FormDialog'
import { NameLabel, SubLabel, IntroText, IntroDivider } from './styles'

const IncludeAutomationsOptions = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
]

const DuplicateAssessmentTemplateModal = ({
  assessmentTemplate,
  hasAutomations,
  ...rest
}) => {
  const formConfig = React.useMemo(() => {
    const initialValues = hasAutomations ? { includeAutomations: 'true' } : {}
    return { initialValues }
  }, [hasAutomations])
  return (
    <FormDialog formConfig={formConfig} {...rest}>
      <Well>
        <NameLabel>{assessmentTemplate.name}</NameLabel>
        <SubLabel>{assessmentTemplate.summary}</SubLabel>
        <SubLabel>{assessmentTemplate.global_template_description}</SubLabel>
      </Well>
      {hasAutomations ? (
        <>
          <IntroText>
            This template includes optional question automations to provide
            advanced features like automatic issue creation and built-in
            reports.
            <IntroDivider />
          </IntroText>
          <div>Would you like to include these automations with your copy?</div>
          <FormField
            name="includeAutomations"
            type={FormField.types.RADIO_GROUP}
            options={IncludeAutomationsOptions}
            preserveHelpTextSpace={false}
          />
          <div>Template duplication may take a moment. Please be patient.</div>
        </>
      ) : (
        <>
          Are you sure you want to duplicate this template? If so, please be
          patient as it may take a moment.
        </>
      )}
    </FormDialog>
  )
}

DuplicateAssessmentTemplateModal.propTypes = {
  assessmentTemplate: PropTypes.object.isRequired,
  hasAutomations: PropTypes.bool,
}

export default DuplicateAssessmentTemplateModal
