import React from 'react'
import PropTypes from 'prop-types'
import { compose, withAuthorize, withReactQuery } from 'hocs'
import { useQueryParams } from 'hooks'
import { useCurrentOrganizationFetcher } from 'apis'
import View from './View'

const OrganizationManageView = ({
  organization: organizationProp,
  ...rest
}) => {
  const { data: organization } = useCurrentOrganizationFetcher(undefined, {
    initialData: organizationProp,
    refetchOnMount: false,
  })
  const [{ tab }, setQueryParams] = useQueryParams()
  const handleTabSelect = React.useCallback(
    (e) => setQueryParams({ tab: e }, true),
    [],
  )
  return (
    <View
      {...rest}
      organization={organization}
      activeTab={tab}
      onTabSelect={handleTabSelect}
    />
  )
}

OrganizationManageView.propTypes = {
  organization: PropTypes.object.isRequired,
  isClient: PropTypes.bool.isRequired,
  ssoEnabled: PropTypes.bool.isRequired,
  ssoActive: PropTypes.bool.isRequired,
  allowVendorRequests: PropTypes.bool.isRequired,
  showMfaEnforcement: PropTypes.bool.isRequired,
  showMultilang: PropTypes.bool,
  currentUserId: PropTypes.string.isRequired,
  currentOrgRole: PropTypes.string.isRequired,
}

export default compose(
  withAuthorize(),
  withReactQuery(),
)(OrganizationManageView)
