import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components-v2/atoms/Button'
import Icon from 'components-v2/atoms/Icon'
import ToolbarGroup from 'components-v2/molecules/ToolbarGroup'
import Editable from 'components-v2/molecules/Editable'
import PageHeader from 'components-v2/organisms/PageHeader'
import { getPageUrl } from 'utils/url'
import useAuthorizations from 'hooks/useAuthorizations'

const templatesPageUrl = getPageUrl('clientAssessmentTemplates')
const assessmentsPageUrl = (q) =>
  getPageUrl('clientVendors', undefined, { tab: 'assessments', q })
const getBreadcrumbs = (pageTitle) => [
  { title: 'Templates', href: templatesPageUrl },
  { title: pageTitle, href: assessmentsPageUrl(pageTitle) },
]

const Header = ({
  pageTitle,
  editable,
  isNew,
  archivable,
  readyable,
  unreadyable,
  onPageTitleChanged,
  onDuplicateButtonClick,
  onArchiveButtonClick,
  onReadyButtonClick,
  onUnreadyButtonClick,
  onExcelExportButtonClick,
  sub,
}) => {
  const [canCreate, canManage, canExport] = useAuthorizations(
    'create',
    'manage',
    'export',
  )
  const title = (
    <Editable
      value={pageTitle}
      disabled={!editable}
      selectTextOnClick={isNew}
      inputProps={{ bsSize: 'lg' }}
      emptyValueText="Unnamed Template"
      placeholder="Title here"
      onSubmit={onPageTitleChanged}
      data-test-id="template-title-editable"
    />
  )
  return (
    <PageHeader title={title} sub={sub} breadcrumbs={getBreadcrumbs(pageTitle)}>
      <ToolbarGroup>
        {canCreate && (
          <Button onClick={onDuplicateButtonClick} rel="nofollow">
            <Icon icon="fa fa-clone" />
            Duplicate
          </Button>
        )}
        {canManage && archivable && (
          <Button onClick={onArchiveButtonClick} rel="nofollow">
            <Icon icon="fa fa-trash" />
            Archive
          </Button>
        )}
        {canManage && unreadyable && (
          <Button onClick={onUnreadyButtonClick} rel="nofollow">
            <Icon icon="fa fa-pencil" />
            Back To Draft
          </Button>
        )}
        {canExport && (
          <Button
            onClick={onExcelExportButtonClick}
            rel="nofollow"
            color={readyable ? 'default' : 'primary'}
          >
            <Icon icon="fa fa-table" />
            Export Template (XLSX)
          </Button>
        )}
        {canManage && readyable && (
          <Button onClick={onReadyButtonClick} color="primary" rel="nofollow">
            <Icon icon="fa fa-check" />
            Make Ready
          </Button>
        )}
      </ToolbarGroup>
    </PageHeader>
  )
}

Header.propTypes = {
  pageTitle: PropTypes.string,
  editable: PropTypes.bool,
  isNew: PropTypes.bool,
  archivable: PropTypes.bool,
  readyable: PropTypes.bool,
  unreadyable: PropTypes.bool,
  onPageTitleChanged: PropTypes.func.isRequired,
  onDuplicateButtonClick: PropTypes.func.isRequired,
  onArchiveButtonClick: PropTypes.func.isRequired,
  onReadyButtonClick: PropTypes.func.isRequired,
  onUnreadyButtonClick: PropTypes.func.isRequired,
  onExcelExportButtonClick: PropTypes.func.isRequired,
  sub: PropTypes.node,
}

export default React.memo(Header)
