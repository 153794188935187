import { useCallback } from 'react'
import NotificationManager from 'lib/notifications'
import { useUpdateCurrentOrganization } from 'apis'

export default function useUpdateOrganization(organization) {
  const { mutateAsync: updateCurrentOrganization } =
    useUpdateCurrentOrganization()
  const { id: organizationId } = organization

  return useCallback(
    async (data) => {
      try {
        await updateCurrentOrganization({
          data: {
            id: organizationId,
            organization: data,
          },
        })
      } catch (error) {
        if (error.response.status === 422) {
          // Example response
          // { message: ["Bcc email only allows valid emails"] }
          NotificationManager.error(error.response?.data?.message)
        } else {
          NotificationManager.error()
        }
      }
    },
    [organizationId, updateCurrentOrganization],
  )
}
