import React from 'react'
import PropTypes from 'prop-types'
import PhoneNumberInput from 'components-v2/molecules/PhoneNumberInput'
import BaseField from './BaseField'

const PhoneNumberField = ({
  name,
  value,
  placeholder,
  disabled,
  controlProps = {},
  onChange,
  ...rest
}) => (
  <BaseField {...rest}>
    <PhoneNumberInput
      {...controlProps}
      name={name}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChange}
    />
  </BaseField>
)

PhoneNumberField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  controlProps: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

export default PhoneNumberField
