import React from 'react'
import PropTypes from 'prop-types'
import FormField from 'components-v2/molecules/FormField'

const SelectAssessmentTemplateFormField = ({
  assessmentTemplates,
  ...rest
}) => {
  const options = React.useMemo(
    () =>
      assessmentTemplates.map((template) => ({
        value: template.id,
        label: template.name,
      })),
    [assessmentTemplates],
  )
  return (
    <FormField
      name="assessmentTemplate"
      label="Assessment"
      type={FormField.types.SELECT}
      options={options}
      horizontal
      {...rest}
    />
  )
}

SelectAssessmentTemplateFormField.propTypes = {
  assessmentTemplates: PropTypes.array.isRequired,
}

export default SelectAssessmentTemplateFormField
