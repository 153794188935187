import styled from 'styled-components'
import Icon from 'components-v2/atoms/Icon'

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: flex-end;
  margin-right: 10px;
`

export const MetricIcon = styled(Icon)`
  color: ${({ color }) => color};
  margin-right: 5px;
  vertical-align: bottom;
`

export const SourceImg = styled.img`
  width: ${(p) => p.width}px;
  height: auto;
  opacity: 0.9;
`

export const ImageLink = styled.div`
  cursor: pointer;
`
