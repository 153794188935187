import styled from 'styled-components'
import { mixins } from 'styles'
import Button from 'components-v2/atoms/Button'

export const CanceledVendorsHeading = styled.div`
  ${mixins.smallHeader}
  letter-spacing: 0;
  margin: 20px 0;
`

export const Name = styled.span`
  font-weight: 500;
`

export const AddButton = styled(Button).attrs({
  color: 'primary',
  variant: 'text',
})`
  ${mixins.smallHeader};
  letter-spacing: 0;
  margin: 20px 0;
`
