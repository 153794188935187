import { getEndpointUrl } from 'utils/url'
import { localTimeZoneOffsetInHours } from 'utils/date'
import { ExportFormatTypes } from 'constants/index'
import config from 'config'

export function exportClientAssessmentsToCSV(params) {
  const newParams = {
    page: 1,
    per_page: config.collectionQueryTotalLimit.assessments,
    tz: localTimeZoneOffsetInHours,
    ...params,
  }
  const url = getEndpointUrl(
    'clientAssessments',
    undefined,
    newParams,
    ExportFormatTypes.CSV,
  )
  window.location.assign(url)
}

export function exportClientIssuesToCSV({
  searchQuery,
  orderedBy,
  direction,
  statuses,
  categories,
  resolutions,
  clientAssignees,
  unassigned,
  vendorTags,
  vendorTagsAnyMatch,
  vendorId,
  assessmentId,
  priorities,
  riskTiers,
} = {}) {
  const params = {
    page: 1,
    per_page: config.collectionQueryTotalLimit.issues,
    search_query: searchQuery,
    ordered_by: orderedBy,
    direction,
    status: statuses ? statuses.join(',') : undefined,
    category_list: categories,
    resolution: resolutions ? resolutions.join(',') : undefined,
    client_assignee_list: clientAssignees,
    unassigned,
    priority_list: priorities,
    risk_tier_list: riskTiers,
    with_vendor_tags: vendorTags ? vendorTags.join(',') : undefined,
    any: vendorTagsAnyMatch,
    vendor_id: vendorId,
    assessment_id: assessmentId,
    tz: localTimeZoneOffsetInHours,
  }
  const url = getEndpointUrl(
    'clientIssues',
    undefined,
    params,
    ExportFormatTypes.CSV,
  )
  window.location.assign(url)
}
