import $ from 'jquery'
$(() =>
  $(document).ajaxError((event, jqxhr) => {
    if (jqxhr.status === 401) {
      // eslint-disable-next-line no-self-assign
      window.location.href = window.location.href
    }
  }),
)

// Make enter or space key working on focusible Divs with "button" role
document.addEventListener('keydown', (e) => {
  if (
    (e.code === 'Space' || e.code === 'Enter') &&
    document.activeElement &&
    document.activeElement.getAttribute('role') === 'button'
  ) {
    document.activeElement.click()
  }
})
