import styled from 'styled-components'
import Label from 'components-v2/atoms/Label'
import { colors } from 'styles'

export const Circle = styled.span`
  display: inline-block;
  min-width: 22px;
  height: 22px;
  line-height: 22px;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  border-radius: 11px;
  background: ${(props) => props.color || colors.PRIMARY};
  color: #fff;
  padding: 0 7px;
`

export const TierLabel = styled(Label)`
  min-width: 55px;
`
