import { findDOMNode } from 'react-dom'
import { isIE } from 'utils/browser'

// FIXME: Do not use findDOMNode. Update this function to accept domNode instead of component
export function printComponent(contentEl, copyStyles = true) {
  if (contentEl === undefined) {
    console.error(
      `Refs are not available for stateless components. For "react-to-print" to work only Class based components can be printed`,
    ) // tslint:disable-line max-line-length no-console
    return
  }

  const printWindow = document.createElement('iframe')
  printWindow.style.position = 'absolute'
  printWindow.style.top = '-1000px'
  printWindow.style.left = '-1000px'
  printWindow.id = 'printWindow'

  // eslint-disable-next-line react/no-find-dom-node
  const contentNodes = findDOMNode(contentEl)
  const linkNodes = document.querySelectorAll("link[rel='stylesheet']")

  const linkTotal = linkNodes.length || 0
  const linksLoaded = []
  const linksErrored = []

  const startPrint = (target) => {
    setTimeout(() => {
      target.contentWindow.focus()
      target.contentWindow.print()
      if (document.getElementById('printWindow')) {
        document.body.removeChild(document.getElementById('printWindow'))
      }
    }, 500)
  }

  const markLoaded = (linkNode, loaded) => {
    if (loaded) {
      linksLoaded.push(linkNode)
    } else {
      console.error(
        `"react-to-print" was unable to load a link. It may be invalid. "react-to-print" will continue attempting to print the page. The link the errored was:`,
        linkNode,
      ) // tslint:disable-line max-line-length no-console
      linksErrored.push(linkNode)
    }

    // We may have errors, but attempt to print anyways - maybe they are trivial and the
    // user will be ok ignoring them
    if (linksLoaded.length + linksErrored.length === linkTotal) {
      startPrint(printWindow)
    }
  }

  printWindow.onload = () => {
    /* IE11 support */
    if (isIE) {
      printWindow.onload = null
    }

    const domDoc =
      printWindow.contentDocument || printWindow.contentWindow.document
    const srcCanvasEls = contentNodes.querySelectorAll('canvas')

    domDoc.open()
    domDoc.write(contentNodes.outerHTML)
    domDoc.close()

    /* remove date/time from top */
    const pageStyle =
      '@page { size: auto;  margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; } }'

    const styleEl = domDoc.createElement('style')
    styleEl.appendChild(domDoc.createTextNode(pageStyle))
    domDoc.head.appendChild(styleEl)

    const canvasEls = domDoc.querySelectorAll('canvas')
    // eslint-disable-next-line no-plusplus
    for (let i = 0, canvasElsLen = canvasEls.length; i < canvasElsLen; ++i) {
      const node = canvasEls[i]
      node.getContext('2d').drawImage(srcCanvasEls[i], 0, 0)
    }

    if (copyStyles !== false) {
      const headEls = document.querySelectorAll("style, link[rel='stylesheet']")

      // eslint-disable-next-line no-plusplus
      for (let i = 0, headElsLen = headEls.length; i < headElsLen; ++i) {
        const node = headEls[i]
        if (node.tagName === 'STYLE') {
          const newHeadEl = domDoc.createElement(node.tagName)
          const { sheet } = node

          if (sheet) {
            let styleCSS = ''
            // NOTE: for-of is not supported by IE
            // eslint-disable-next-line no-plusplus
            for (let j = 0, cssLen = sheet.cssRules.length; j < cssLen; ++j) {
              if (typeof sheet.cssRules[j].cssText === 'string') {
                styleCSS += `${sheet.cssRules[j].cssText}\r\n`
              }
            }
            newHeadEl.setAttribute('id', `react-to-print-${i}`)
            newHeadEl.appendChild(domDoc.createTextNode(styleCSS))
            domDoc.head.appendChild(newHeadEl)
          }
        } else {
          // Many browsers will do all sorts of weird things if they encounter an
          // empty `href` tag (which is invalid HTML). Some will attempt to load the
          // current page. Some will attempt to load the page"s parent directory.
          // These problems can cause `react-to-print` to stop  without any error
          // being thrown. To avoid such problems we simply do not attempt to load
          // these links.
          // eslint-disable-next-line no-lonely-if
          if (node.hasAttribute('href') && !!node.getAttribute('href')) {
            const newHeadEl = domDoc.createElement(node.tagName)

            // node.attributes has NamedNodeMap type that is not an Array and can be
            // iterated only via direct [i] access
            for (
              let j = 0, attrLen = node.attributes.length;
              j < attrLen;
              // eslint-disable-next-line no-plusplus
              ++j
            ) {
              const attr = node.attributes[j]
              newHeadEl.setAttribute(attr.nodeName, attr.nodeValue)
            }

            newHeadEl.onload = markLoaded.bind(null, newHeadEl, true)
            newHeadEl.onerror = markLoaded.bind(null, newHeadEl, false)
            domDoc.head.appendChild(newHeadEl)
          } else {
            console.warn(
              `"react-to-print" encountered a <link> tag with an empty "href" attribute. In addition to being invalid HTML, this can cause problems in many browsers, and so the <link> was not loaded. The <link> is:`,
              node,
            ) // tslint:disable-line max-line-length no-console
            // `true` because we"ve already shown a warning for this
            markLoaded(node, true)
          }
        }
      }
    }

    if (linkTotal === 0 || copyStyles === false) {
      startPrint(printWindow)
    }
  }

  if (document.getElementById('printWindow')) {
    document.body.removeChild(document.getElementById('printWindow'))
  }
  document.body.appendChild(printWindow)
}
