import React from 'react'
import PropTypes from 'prop-types'
import img from 'images/error/solar-sun.png'
import ErrorView from '../shared/ErrorView'

const View = ({ requestId }) => (
  <ErrorView
    imageUrl={img}
    errorCode={500}
    errorType="Internal Server Error"
    errorMessage="Perhaps a solar flare has caused an interference. Our engineers have been notified and are looking into it."
  >
    {requestId && <p>Unique Id: {requestId}</p>}
  </ErrorView>
)

View.propTypes = {
  requestId: PropTypes.string,
}

export default View
