import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import {
  Wrapper,
  Content,
  Title,
  Sub,
  Right,
  Top,
  Breadcrumb,
  BreadcrumbItem,
} from './styles'

const PageHeader = React.forwardRef(
  ({ title, sub, breadcrumbs, children, portal, fixedWidth, ...rest }, ref) => {
    const content = (
      <Wrapper {...rest} ref={ref}>
        <div className={fixedWidth ? 'container' : ''}>
          {breadcrumbs && (
            <Top>
              {breadcrumbs && (
                <Breadcrumb>
                  {breadcrumbs.map(
                    ({ title: titleOpt, href, ...res }, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <BreadcrumbItem key={index} {...res}>
                        {href ? (
                          <a href={href}>{titleOpt}</a>
                        ) : (
                          <span>{titleOpt}</span>
                        )}
                      </BreadcrumbItem>
                    ),
                  )}
                </Breadcrumb>
              )}
            </Top>
          )}
          <Content>
            <div>
              <Title>{title}</Title>
              {sub && <Sub>{sub}</Sub>}
            </div>
            {children && <Right>{children}</Right>}
          </Content>
        </div>
      </Wrapper>
    )
    if (portal) {
      const headerContainerEl = document.querySelector('header')
      return ReactDOM.createPortal(content, headerContainerEl)
    }
    return content
  },
)

PageHeader.propTypes = {
  title: PropTypes.any.isRequired,
  sub: PropTypes.any,
  breadcrumbs: PropTypes.array,
  portal: PropTypes.bool,
  fixedWidth: PropTypes.bool,
  children: PropTypes.node,
}

PageHeader.defaultProps = {
  portal: true,
  fixedWidth: true,
}

export default PageHeader
