export default {
  data: {
    name: 'Endpoint Protection',
    definition:
      "Endpoint Protection reporting at least nightly from your tool's API",
    category: {
      name: 'Organization Security',
      average_score: 75,
    },

    sources: [
      {
        name: 'Symantec',
        logo_url:
          'https://privva-resources-public.s3.amazonaws.com/ss/logos/symantec_logo.png',
        metrics: [],
      },
    ],
    frameworkMappings: [
      {
        framework: 'SIG',
        identifier: 'M.1',
        parentIdentifier: '',
        description:
          'Are End User Devices (Desktops, Laptops, Tablets, Smartphones) used for transmitting, processing or storing Scoped Data?',
      },
      {
        framework: 'SIG',
        identifier: 'M.1.1',
        parentIdentifier: 'M.1',
        description:
          'Are end user device security configuration standards documented?',
      },
      {
        framework: 'SIG',
        identifier: 'M.1.1.1',
        parentIdentifier: 'M.1.1',
        description:
          'Are end user device security configuration standards reviewed and/or updated at least annually to account for any changes in environment, available security features and/or best practices?',
      },
      {
        framework: 'SIG',
        identifier: 'M.1.1.2',
        parentIdentifier: 'M.1.1',
        description:
          'Are end user device security reviews performed to validate compliance with documented standards?',
      },
      {
        framework: 'SIG',
        identifier: 'M.1.10',
        parentIdentifier: 'M.1',
        description:
          'Are all end user device Operating System and application logs configured to provide sufficient detail to support incident investigation, including successful and failed login attempts and changes to sensitive configuration settings and files?',
      },
      {
        framework: 'SIG',
        identifier: 'M.1.11',
        parentIdentifier: 'M.1',
        description:
          'Are all end user device events relevant to supporting incident investigation retained for a minimum of one year?',
      },
      {
        framework: 'SIG',
        identifier: 'D.6.6',
        parentIdentifier: '',
        description:
          'Does Scoped Data sent or received electronically include protection against malicious code by network virus inspection or virus scan at the endpoint?',
      },
      {
        framework: 'ISO 27002',
        identifier: '13.2.1',
        parentIdentifier: '',
        description:
          'Information Transfer Policies and Procedures - Formal transfer policies, procedures and controls shall be in place to protect the transfer of information through the use of all types of communication facilities.',
      },
      {
        framework: 'ISO 27002',
        identifier: '11.2.8',
        parentIdentifier: '',
        description:
          'Unattended User Equipment - Users shall ensure that unattended equipment has appropriate protection.',
      },
      {
        framework: 'ISO 27002',
        identifier: '11.2.9',
        parentIdentifier: '',
        description:
          'Clear Desk and Clear Screen Policy - A clear desk policy for papers and removable storage media and a clear screen policy for information processing facilities shall be adopted.',
      },
      {
        framework: 'FFIEC CAT Tool',
        identifier: 'D3.CC.R.Int.4',
        parentIdentifier: '',
        description:
          '(Domain 3 / Cybersecurity Controls / Remediation / Intermediate) - Generally accepted and appropriate forensic procedures, including chain of custody, are used to gather and present evidence to support potential legal action.',
      },
      {
        framework: 'PCI 3.2',
        identifier: '1.1.6',
        parentIdentifier: '',
        description:
          'Documentation of business justification and approval for use of all services, protocols, and ports allowed, including documentation of security features implemented for those protocols considered to be insecure.',
      },
      {
        framework: 'PCI 3.2',
        identifier: '10.5.1',
        parentIdentifier: '',
        description:
          'Limit viewing of audit trails to those with a job-related need. Only individuals who have a job-related need can view audit trail files.',
      },
      {
        framework: 'NIST SP 800-53 (Rev. 4)',
        identifier: 'AC-11.a',
        parentIdentifier: '',
        description:
          'Access Control - The information system prevents further access to the system by initiating a session lock after [Assignment: organization-defined time period] of inactivity or upon receiving a request from a user.',
      },
      {
        framework: 'NIST SP 800-53 (Rev. 4)',
        identifier: 'AC-12',
        parentIdentifier: '',
        description:
          'Access Control - The information system automatically terminates a user session after [Assignment: organization-defined conditions or trigger events requiring session disconnect].',
      },
      {
        framework: 'NIST Cybersecurity Framework',
        identifier: 'PR.DS-2',
        parentIdentifier: '',
        description:
          'Information and records (data) are managed consistent with the organization’s risk strategy to protect the confidentiality, integrity, and availability of information. Data-in-transit is protected.',
      },
      {
        framework: 'NIST Cybersecurity Framework',
        identifier: 'DE.CM-4',
        parentIdentifier: '',
        description:
          'The information system and assets are monitored to identify cybersecurity events and verify the effectiveness of proactive measures. Malicious code is detected.',
      },
      {
        framework: 'HIPAA Simplification',
        identifier: '§164.310(2)(iii)',
        parentIdentifier: '',
        description:
          'Accountability (Addressable). Maintain a record of the movements of hardware and electronic media and any person responsible therefore.',
      },
      {
        framework: 'HIPAA Simplification',
        identifier: '§164.312(b)(2)',
        parentIdentifier: '',
        description:
          'Standard: Audit controls. Implement hardware, software, and/or procedural mechanisms that record and examine activity in information systems that contain or use electronic protected health information.   Implementation specification: Mechanism to authenticate electronic protected health information (Addressable). Implement electronic mechanisms to corroborate that electronic protected health information has not been altered or destroyed in an unauthorized manner. ',
      },
    ],
  },
}
