import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import tinycolor from 'tinycolor2'
import A from 'components-v2/atoms/A'
import ColorInput from 'components-v2/molecules/ColorInput'
import { getLightness } from 'utils/color'
import { useAutoToggleState } from 'hooks'
import { Message } from './styles'

const ColorPicker = ({ value, maxLightness, onChange }) => {
  const [noticeVisible, activateNotice] = useAutoToggleState(3000)

  const handleReset = useCallback(() => {
    onChange()
  }, [onChange])

  const handleChange = useCallback(
    (val) => {
      if (val && maxLightness !== 1) {
        const lightness = getLightness(val)
        if (lightness > maxLightness) {
          const newColor = tinycolor(val)
            .darken(((lightness - maxLightness) / lightness) * 100)
            .toString()
          activateNotice()
          onChange(newColor)
          return
        }
      }
      onChange(val)
    },
    [onChange, activateNotice],
  )
  return (
    <div>
      <ColorInput
        value={value || ''}
        placeholder="Hex code, including #"
        onChange={handleChange}
      />
      {!!value && (
        <div>
          <A onClick={handleReset} underline>
            Reset
          </A>
          &nbsp;&nbsp;
          <Message in={noticeVisible} timeout={500}>
            Color too light
          </Message>
        </div>
      )}
    </div>
  )
}

ColorPicker.propTypes = {
  value: PropTypes.string,
  maxLightness: PropTypes.number,
  onChange: PropTypes.func.isRequired,
}

ColorPicker.defaultProps = {
  maxLightness: 1,
}

export default ColorPicker
