import styled from 'styled-components'
import Popover from 'components-v2/molecules/Popover'
import { media } from 'styles'
import NavItem from '../NavItem'

export const NotificationCount = styled.span`
  color: #fff;
  background-color: #909090;
  border-radius: 20px;
  font-size: 12px;
  padding: 4px 8px;

  ${({ $hasUnopened }) => $hasUnopened && `background-color: #777777;`}
`
export const NotificationContainer = styled.div`
  position: relative;
  width: 25px;
  height: 30px;
  display: inline-block;
`
export const NotificationIcon = styled.i`
  left: ${({ $unopenedNotificationCount }) =>
    $unopenedNotificationCount > 999
      ? '-10px;'
      : $unopenedNotificationCount > 99
      ? '-5px;'
      : '0px;'};
`
export const IconButtonBadge = styled.span`
  --notification-badge: white;
  --unopened-notification-count: #777;
  display: inline-flex;
  position: absolute;
  top: -5px;
  right: -15px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--unopened-notification-count); /* Change text color as needed */
  font-size: 1rem; /* Adjust font size as needed */
  padding: 3px; /* Adjust padding to control size */
  aspect-ratio: 1 / 1;
  min-width: 20px; /* Minimum size to accommodate single-digit numbers */
  min-height: 20px;
  font-size: 14px;
  box-sizing: border-box;
  white-space: nowrap;
  [data-theme='dark'] & {
    --notification-badge: #777;
    --unopened-notification-count: white;
  }

  ${({ $unopenedNotificationCount }) =>
    $unopenedNotificationCount > 0 &&
    `background-color: var(--notification-badge);`}
  ${({ $unopenedNotificationCount }) =>
    $unopenedNotificationCount > 99 &&
    `
    right: -20px;
    font-size: 12px; 
    padding: 2px 4px;
    aspect-ratio: auto;
    min-height: 5px;
    border-radius: 10px;
    background-color: var(--notification-badge);
    `}
  ${({ $unopenedNotificationCount }) =>
    $unopenedNotificationCount > 999 &&
    `
    padding: 2px 2px 2px 5px;
  `}
`
export const Container = styled.div`
  width: 330px;
  height: 490px;
  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  flex: none;
  border-bottom: 1px solid #e5e5e5;
  padding: 6px 10px;
  font-size: 14px;
`

export const Content = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledPopover = styled(Popover)`
  ${media.mobile`
    display: none !important;
  `}
`

export const MobileNavItem = styled(NavItem)`
  ${media.nmobile`
    display: none !important;
  `}
`
