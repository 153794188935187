import React from 'react'
import sortBy from 'lodash/sortBy'
import truncate from 'lodash/truncate'
import cloneDeep from 'lodash/cloneDeep'
import reduce from 'lodash/reduce'
import get from 'lodash/get'
import { getRiskRatingLabel, getRiskRatingColor } from 'lib/assessment'
import { AssessmentStatusTypes, LabelTypes } from 'constants/index'
import Tooltip from 'components-v2/molecules/Tooltip'
import {
  RiskRatingLabel,
  DomainScoreLabel,
  VendorName,
  AssessmentName,
} from './styles'

const splitLongName = (name) => {
  // if we take the "white-space: nowrap" out of the cell style,
  // it wraps practically *everything* even if it's not needed
  // and spreads things out too much vertically.
  // Setting the break threshold at 20 instead of 24 just so we
  // don't break on like the last two or three characters.
  const parts = name.match(/.{1,20}(\s|$)/g)
  const max = parts.length - 1
  return reduce(
    parts,
    (res, val, idx) => {
      res.push(val)
      if (idx < max) {
        res.push(<br />)
      }
      return res
    },
    [],
  )
}

const baseColumns = [
  {
    id: 'id',
    header: 'id',
    enableSorting: false,
    hidden: true,
  },
  {
    id: 'vendor.name',
    header: `${LabelTypes.VENDOR} / Assessment`,
    cell: (props) => (
      <>
        <VendorName>
          <a href={props.row.original.vendor.url}>
            {props.getValue().length > 24
              ? splitLongName(props.getValue())
              : props.getValue()}
          </a>
        </VendorName>
        <br />
        <AssessmentName>
          <a href={props.row.original.url}>
            {truncate(props.row.original.name)}
          </a>
        </AssessmentName>
      </>
    ),
    width: '19%',
    headerStyle: { fontSize: '.6em' },
  },
  {
    id: 'risk_rating',
    header: 'Risk Rating',
    cell: (props) => {
      const riskRating =
        [
          AssessmentStatusTypes.REVIEWED,
          AssessmentStatusTypes.SUBMITTED,
        ].includes(props.row.original.status) && props.row.original.risk_rating
      const label = (
        <RiskRatingLabel color={getRiskRatingColor(riskRating)}>
          {getRiskRatingLabel(
            riskRating,
            'N/A',
            props.row.original.status === AssessmentStatusTypes.SUBMITTED,
          )}
        </RiskRatingLabel>
      )
      return props.row.original.status === AssessmentStatusTypes.SUBMITTED ? (
        <Tooltip parent={label}>
          Score is subject to change as assessment is reviewed.
        </Tooltip>
      ) : (
        label
      )
    },
    width: '15%',
    headerStyle: { fontSize: '.6em', textAlign: 'center' },
    style: { textAlign: 'center', padding: '6px', verticalAlign: 'bottom' },
  },
]

export const domainColumns = (riskDomains, canLinkTemplate) => {
  const cols = cloneDeep(baseColumns)

  sortBy(riskDomains, [(o) => o.name]).forEach((domain) => {
    let text = domain.name
    if (text === 'Risk Management') text = 'Risk Mgmt'
    if (text === 'Information Management') text = 'Info Mgmt'
    if (text === 'Security Operations') text = 'Security Ops'

    cols[1].cell = (props) => (
      <>
        <VendorName>
          <a href={props.row.original.vendor.url}>
            {props.getValue().length > 24
              ? splitLongName(props.getValue())
              : props.getValue()}
          </a>
        </VendorName>
        <br />
        <AssessmentName>
          {canLinkTemplate ? (
            <a href={props.row.original.url}>
              {truncate(props.row.original.name)}
            </a>
          ) : (
            truncate(props.row.original.name)
          )}
        </AssessmentName>
      </>
    )

    cols.push({
      id: domain.name,
      header: <Tooltip parent={<span>{text}</span>}>{domain.summary}</Tooltip>,
      cell: (props) => {
        const val = get(props.row.original.risk_domain_ratings, domain.id, '--')
        return (
          <center>
            <DomainScoreLabel color={getRiskRatingColor(val)}>
              {val}
            </DomainScoreLabel>
          </center>
        )
      },
      enableSorting: false,
      width: '7%',
      headerStyle: { fontSize: '.6em', textAlign: 'center' },
      style: { textAlign: 'center', padding: '6px', verticalAlign: 'bottom' },
    })
  })
  return cols
}
