import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import A from 'components-v2/atoms/A'
import Tooltip from 'components-v2/molecules/Tooltip'
import { toLocalTimeString } from 'utils/date'
import LargeCard from '../LargeCard'
import { parseRiskReconData } from '../../lib/globorg_integration_metrics'
import RiskReconOverallGradeChart from '../../shared/RiskReconOverallGradeChart'
import {
  Inner,
  UpdatedAt,
  IssueCountsRow,
  IssueCountCol,
  IssueCountColLabel,
} from './styles'

const dateFormatter = (str) => toLocalTimeString(str, 'M/d/yy')

const RiskReconOverallScoreCard = ({ data, onIssuesCountClick, ...rest }) => {
  const riskReconData = React.useMemo(() => {
    const [primary, ...metrics] = parseRiskReconData(data)
    const pMetrics = metrics.filter((e) => e.type === 'issue_count')
    const updatedAt = dateFormatter(data.date)
    const premiumAuthorized = data.premium_authorized
    return { primary, pMetrics, updatedAt, premiumAuthorized }
  }, [data])
  return (
    <LargeCard {...rest} title="RiskRecon Overall Grade">
      <Inner>
        <RiskReconOverallGradeChart
          grade={riskReconData.primary.grade}
          value={riskReconData.primary.value}
          total={riskReconData.primary.total}
        />
        <UpdatedAt>
          Last updated
          <br />
          {riskReconData.updatedAt}
        </UpdatedAt>
        {riskReconData.premiumAuthorized && (
          <IssueCountsRow>
            {riskReconData.pMetrics.map((p) => (
              <IssueCountCol key={p.category}>
                <IssueCountColLabel color={p.color}>
                  {p.category} <Tooltip>{p.description}</Tooltip>
                </IssueCountColLabel>
                <A onClick={() => onIssuesCountClick(p.priority)} underline>
                  {get(p, 'value', 'N/A')}
                </A>
              </IssueCountCol>
            ))}
          </IssueCountsRow>
        )}
      </Inner>
    </LargeCard>
  )
}

RiskReconOverallScoreCard.propTypes = {
  data: PropTypes.object.isRequired,
  onIssuesCountClick: PropTypes.func.isRequired,
}

export default RiskReconOverallScoreCard
