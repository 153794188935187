import styled from 'styled-components'
import Icon from 'components-v2/atoms/Icon'
import { QuestionCircle } from '@styled-icons/fa-solid/QuestionCircle'
import FormField from 'components-v2/molecules/FormField'
import { colors } from 'styles'

export const NameLabel = styled.div`
  font-weight: bold;
`
export const SubLabel = styled.div`
  font-size: 15px;
  margin-top: 10px;
`
export const IntroText = styled.div`
  // font-size: 1.2em;
  margin-bottom: 15px;
`
export const IntroDivider = styled.hr`
  text-align: center;
  width: 30%;
  margin-top: 5px;
`
export const NoticeText = styled.div`
  font-size: 0.7em;
  font-style: italic;
`
export const QuestionCircleIcon = styled(Icon).attrs({ icon: QuestionCircle })`
  margin-left: 5px;
  vertical-align: middle;
  width: 0.7em;
  color: ${colors.PRIMARY};
`
export const CollectionHeadRow = styled.div`
  display: flex;
  font-style: italic;
  border-bottom: 1px solid black;
  margin-bottom: 10px;
`
export const NameHead = styled.div``
export const ToggleHead = styled.div`
  text-align: right;
  flex: auto;
  margin-right: 20px;
`
export const CollectionType = styled.div``
export const CollectionRow = styled.div`
  display: flex;
  margin-bottom: 15px;
`
export const CollectionName = styled.div`
  margin-left: 20px;
`
export const ToggleSlot = styled.div`
  text-align: right;
  flex: auto;
  margin-right: 20px;
`
export const ToggleField = styled(FormField)`
  display: inline-flex;
`
