import React from 'react'
import { useModal, useConfirm, useCurrent } from 'hooks'
import { AssessmentTemplateStatusTypes } from 'constants/index'
import QuestionAttachmentsModal from './QuestionAttachmentsModal'
import { setElementToElements } from './utils'
import useSetAssessmentTemplate from './useSetAssessmentTemplate'

export default function useViewQuestionAttachments(assessmentTemplate) {
  const setAssessmentTemplate = useSetAssessmentTemplate(assessmentTemplate.id)
  const assessmentTemplateRef = useCurrent(assessmentTemplate)
  const [openModal, , updateModal] = useModal(QuestionAttachmentsModal)
  const confirm = useConfirm()

  return React.useCallback(
    (element) => {
      const templateIsDraft =
        assessmentTemplateRef.current.status ===
        AssessmentTemplateStatusTypes.DRAFT

      const confirmReplace = () => {
        if (!templateIsDraft) {
          return confirm(
            {
              title: 'Replace Attachment',
              body: 'This will remove the current attachment file and replace it with your upload. Are you sure?',
              confirmText: "Yes, I'm sure",
              onConfirm: () => updateModal({ loading: true }),
            },
            true,
          )
        }
        return Promise.resolve(true)
      }

      const confirmDelete = () => {
        if (!templateIsDraft) {
          return confirm(
            {
              title: 'Delete Attachment',
              body: 'This file will no longer be available for download. Are you sure you want to remove it?',
              confirmText: "Yes, I'm sure",
              onConfirm: () => updateModal({ loading: true }),
            },
            true,
          )
        }
        return Promise.resolve(true)
      }

      const handleUpdate = (updatedFiles) => {
        updateModal({
          attachments: updatedFiles,
          loading: false,
        })
        setAssessmentTemplate((current) => ({
          ...current,
          elements: setElementToElements(
            { ...element, attachments: updatedFiles },
            current.elements,
          ),
        }))
      }

      openModal({
        questionId: element.id,
        attachments: element.attachments,
        canAdd: templateIsDraft,
        canReplace: true,
        canDelete: templateIsDraft, // for now?
        confirmReplace,
        confirmDelete,
        onAttachmentsChange: handleUpdate,
      })
    },
    [openModal, updateModal, confirm, setAssessmentTemplate],
  )
}
