import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import get from 'lodash/get'
import filter from 'lodash/filter'
import { PieChart } from 'components-v2/molecules/Charts'
import { useAuthorizations } from 'hooks'
import PrivvaAssessmentMetric from '../PrivvaAssessmentMetric'
import {
  Wrapper,
  Top,
  Title,
  ChartWrapper,
  MetricWrapper,
  Bottom,
} from './styles'

const AssessmentRiskRatingCard = ({ assessment, ...rest }) => {
  const [canLink] = useAuthorizations(['show', 'Assessment'])
  const chartData = React.useMemo(() => {
    const data = map(assessment.review_scores, (score) => ({
      name: score.label,
      value: score.count,
      color: score.color,
    }))
    return filter(data, (sector) => sector.value)
  }, [assessment.review_scores])
  return (
    <Wrapper {...rest}>
      <Top>
        <Title>
          {get(assessment, 'assessment_template.rating_label', 'Risk Rating')}
        </Title>
        <div>
          Based on <a href={assessment.url}>{assessment.name}</a>
        </div>
        <ChartWrapper>
          <MetricWrapper>
            <PrivvaAssessmentMetric assessment={assessment} />
          </MetricWrapper>
          <PieChart
            data={chartData}
            width="100%"
            height={300}
            cy={160}
            innerRadius={66}
            outerRadius={90}
            startAngle={90}
            endAngle={450}
            label
            labelOnHover
          />
        </ChartWrapper>
      </Top>
      {canLink && (
        <Bottom href={assessment.url}>View Assessment Details</Bottom>
      )}
    </Wrapper>
  )
}

AssessmentRiskRatingCard.propTypes = {
  assessment: PropTypes.object.isRequired,
}

export default AssessmentRiskRatingCard
