import styled from 'styled-components'
import Card from 'components-v2/organisms/Card'
import { mixins } from 'styles'

export const Filter = styled.div`
  margin-bottom: 20px;
  ${mixins.hidePrint}
`

export const StyledCard = styled(Card).attrs({ padding: true })`
  margin-bottom: 30px;
  ${mixins.avoidPageBreak}
`
