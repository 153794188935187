import React from 'react'
import PropTypes from 'prop-types'
import { StyledProgressBar as ProgressBar, Indicator } from './styles'

const CircleProgressBar = ({ children, ...rest }) => (
  <ProgressBar
    {...rest}
    pointerStrokeColor={rest.pointerStrokeColor || rest.strokeColor}
  >
    <Indicator>{children}</Indicator>
  </ProgressBar>
)

// Please refer https://www.npmjs.com/package/react-customizable-progressbar for more information about props and default values
CircleProgressBar.propTypes = {
  radius: PropTypes.number,
  progress: PropTypes.number.isRequired,
  steps: PropTypes.number,
  cut: PropTypes.number,
  rotate: PropTypes.number,
  strokeWidth: PropTypes.number,
  strokeColor: PropTypes.string,
  strokeLinecap: PropTypes.string,
  transition: PropTypes.string,
  trackStrokeWidth: PropTypes.number,
  trackStrokeColor: PropTypes.string,
  trackStrokeLinecap: PropTypes.string,
  trackTransition: PropTypes.string,
  pointerRadius: PropTypes.number,
  pointerStrokeWidth: PropTypes.number,
  pointerStrokeColor: PropTypes.string,
  pointerFillColor: PropTypes.string,
  initialAnimation: PropTypes.bool,
  initialAnimationDelay: PropTypes.number,
  inverse: PropTypes.bool,
  counterClockwise: PropTypes.bool,
  children: PropTypes.any,
}

CircleProgressBar.defaultProps = {
  strokeWidth: 6,
  trackStrokeWidth: 6,
  trackStrokeColor: '#EEEEEE',
  pointerRadius: 6,
  pointerStrokeWidth: 5,
}

export default CircleProgressBar
