import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDebouncedCallback } from 'hooks'
import { Wrapper, StyledInput, SearchIcon, ClearIcon } from './styles'

const SearchInput = ({
  value: valueProp,
  onChange,
  debounceWait,
  clearable,
  className,
  style,
  ...rest
}) => {
  const [value, setValue] = useState(valueProp)
  const onChangeDebounced = useDebouncedCallback(onChange, debounceWait)

  useEffect(() => {
    setValue(valueProp)
  }, [valueProp])

  const change = useCallback(
    (newValue) => {
      setValue(newValue)
      onChangeDebounced(newValue)
    },
    [onChangeDebounced],
  )

  const handleInputChange = useCallback((e) => change(e.target.value), [change])

  const handleClear = useCallback(() => change(''), [change])

  return (
    <Wrapper className={className} style={style}>
      <SearchIcon />
      <StyledInput {...rest} value={value || ''} onChange={handleInputChange} />
      {clearable && <ClearIcon disabled={!value} onClick={handleClear} />}
    </Wrapper>
  )
}

SearchInput.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  debounceWait: PropTypes.number,
  clearable: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

SearchInput.defaultProps = {
  placeholder: 'Search...',
  maxLength: 255,
  debounceWait: 500,
  clearable: true,
}

export default SearchInput
