import { useCallback } from 'react'
import assignIn from 'lodash/assignIn'
import { useModal, useConfirm, useCurrent } from 'hooks'
import { useCreateClientAssessmentTemplateElement } from 'apis'
import { AssessmentElementTypes } from 'constants/index'
import NewSectionFormModal from './NewSectionFormModal'
import useDefaultAsyncActionHandler from './useDefaultAsyncActionHandler'
import useUpdateCustomKeysAbility from './useUpdateCustomKeysAbility'

// Callback when click "Add Section" button
export default function useCreateSection(assessmentTemplate) {
  const assessmentTemplateRef = useCurrent(assessmentTemplate)
  const { mutateAsync: createClientAssessmentTemplateElement } =
    useCreateClientAssessmentTemplateElement()
  const openConfirm = useConfirm()
  const [openNewSectionFormModal, closeNewSectionFormModal] =
    useModal(NewSectionFormModal)
  const handleAsyncAction = useDefaultAsyncActionHandler()
  const updateCustomKeysAbility = useUpdateCustomKeysAbility(
    assessmentTemplate.id,
  )

  return useCallback(() => {
    openNewSectionFormModal({
      onSubmit: (formData) => {
        const addSection = () =>
          createClientAssessmentTemplateElement({
            id: assessmentTemplateRef.current.id,
            data: assignIn(
              {
                type: AssessmentElementTypes.SECTION_HEADING,
                weight: 5,
                allow_comments: true,
                allow_attachments: true,
              },
              formData,
            ),
          }).then(() => {
            closeNewSectionFormModal()
          })
        if (assessmentTemplateRef.current.use_custom_keys) {
          return openConfirm(
            {
              title: 'Adding New Section',
              body: 'This action will disable custom keys. Are you sure you wish to add new section?',
            },
            true,
          ).then((confirmed) => {
            if (!confirmed) {
              return null
            }
            const promise = updateCustomKeysAbility(false).then(() =>
              addSection(),
            )
            return handleAsyncAction(
              promise,
              'New section has been successfully added.',
            )
          })
        }
        return handleAsyncAction(
          addSection(),
          'New section has been successfully added.',
        )
      },
    })
  }, [
    openConfirm,
    openNewSectionFormModal,
    closeNewSectionFormModal,
    updateCustomKeysAbility,
    handleAsyncAction,
    createClientAssessmentTemplateElement,
  ])
}
