import React from 'react'
import PropTypes from 'prop-types'
import PrintButton from 'components-v2/molecules/PrintButton'
import PageHeader from 'components-v2/organisms/PageHeader'
import ResultsReport from 'components/client/shared/ResultsReport'
import { withAuthorize } from 'hocs'
import { getPageUrl } from 'utils/url'

const getBreadcrumbs = (project) => [
  {
    href: getPageUrl('clientProjects'),
    title: 'Projects',
  },
  {
    href: getPageUrl('clientProject', { id: project.id }),
    title: project.friendly_name,
  },
  {
    active: true,
    title: 'Results Report',
  },
]

const ProjectResultsReport = ({ project, ...rest }) => (
  <div>
    <PageHeader
      title={`${project.friendly_name} Results Report`}
      breadcrumbs={getBreadcrumbs(project)}
    >
      <PrintButton color="primary" />
    </PageHeader>
    <ResultsReport {...rest} />
  </div>
)

ProjectResultsReport.propTypes = {
  project: PropTypes.object.isRequired,
}

export default withAuthorize()(ProjectResultsReport)
