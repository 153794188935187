import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactInfiniteScroll from 'react-infinite-scroller'
import Loader from 'components-v2/molecules/Loader'

const StyledLoader = styled(Loader).attrs({ size: 20 })`
  padding: 15px 0;
`

const doNothing = () => {}
const InfiniteScroll = ({ isLoading, loadMore, ...rest }) => (
  <ReactInfiniteScroll {...rest} loadMore={!isLoading ? loadMore : doNothing} />
)

InfiniteScroll.propTypes = {
  isLoading: PropTypes.bool,
  loadMore: PropTypes.func.isRequired,
  loader: PropTypes.element,
}

InfiniteScroll.defaultProps = {
  loader: <StyledLoader key={0} />,
}

export default InfiniteScroll
