import styled from 'styled-components'
import Icon from 'components-v2/atoms/Icon'
import Card from 'components-v2/organisms/Card'
import { fonts } from 'styles'

export const Wrapper = styled(Card).attrs({ bordered: true, padding: true })`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

export const Title = styled.h6`
  margin: 0 0 20px;
`

export const Status = styled.div`
  margin: 0 0 20px;
  display: inline-flex;
  align-items: center;
  ${({ $color }) => `background: ${$color};`}
  color: #fff;
  border-radius: 100px;
  padding: 8px 12px;
  font-family: ${fonts.SECONDARY};
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-transform: uppercase;
`

export const StatusIcon = styled(Icon)`
  font-size: 16px;
  margin-right: 8px;
`
