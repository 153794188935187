import React from 'react'
import PropTypes from 'prop-types'
import IconButton from 'components-v2/atoms/IconButton'
import Popover from 'components-v2/molecules/Popover'
import { PencilAlt } from '@styled-icons/fa-solid'
import EditSectionPopoverForm from './EditSectionPopoverForm'
import { PanelContent, PopoverPanel } from './styles'

const EditSectionPopover = ({ element, onSave, ...rest }) => (
  <Popover shift={false} {...rest}>
    <Popover.Button as={IconButton} icon={PencilAlt} active />
    <PopoverPanel title="Section Name" arrow>
      {({ close }) => (
        <PanelContent>
          <EditSectionPopoverForm
            element={element}
            onSave={onSave}
            onClose={close}
          />
        </PanelContent>
      )}
    </PopoverPanel>
  </Popover>
)

EditSectionPopover.propTypes = {
  element: PropTypes.object,
  onSave: PropTypes.func.isRequired,
}

export default React.memo(EditSectionPopover)
