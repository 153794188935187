import styled from 'styled-components'
import Select from 'components-v2/atoms/Select'
import { sizes } from 'styles'

export const EXTRA_STICKY_GAP = 20
export const STICKY_TOP_OFFSET = sizes.HEADER_HEIGHT + EXTRA_STICKY_GAP

export const StyledSelect = styled(Select)`
  max-width: 150px;
`
