import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components-v2/atoms/Button'
import Dialog from 'components-v2/molecules/Dialog'
import Table from 'components-v2/molecules/Table'
import Toolbar, { SearchInput } from 'components-v2/molecules/Table/Toolbar'
import { LabelTypes } from 'constants/index'
import { vendorRiskTierFormatterFactory } from 'lib/vendor'

const riskTierFormatter = vendorRiskTierFormatterFactory()

const tableColumns = [
  {
    id: 'name',
    header: LabelTypes.VENDOR,
  },
  {
    id: 'parent_org.name',
    header: `Parent ${LabelTypes.VENDOR}`,
  },
  {
    id: 'risk_tier',
    header: 'Risk Tier',
    cell: riskTierFormatter,
  },
]

const SelectVendorsModal = ({ vendors, onClose, onSelect, ...rest }) => {
  const [searchText, setSearchText] = React.useState('')
  const [selected, setSelected] = React.useState({})
  return (
    <Dialog size="lg" {...rest} onClose={onClose}>
      <Dialog.Header onClose={onClose}>{LabelTypes.VENDORS}</Dialog.Header>
      <Dialog.Content>
        <Toolbar>
          <SearchInput value={searchText} onChange={setSearchText} />
        </Toolbar>
        <Table
          data={vendors ?? []}
          columns={tableColumns}
          noDataText={`No ${LabelTypes.VENDORS.toLowerCase()} found.`}
          type={Table.types.ALT}
          globalFilter={searchText}
          enableRowSelection
          rowSelection={selected}
          onRowSelectionChange={setSelected}
        />
      </Dialog.Content>
      <Dialog.Footer>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          color="primary"
          disabled={Object.keys(selected).length === 0}
          onClick={() => onSelect(Object.keys(selected))}
        >
          {`Add ${LabelTypes.VENDORS}`}
        </Button>
      </Dialog.Footer>
    </Dialog>
  )
}

SelectVendorsModal.propTypes = {
  vendors: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default SelectVendorsModal
