/* eslint-disable react/no-danger */
import React from 'react'
import Well from 'components-v2/atoms/Well'
import A from 'components-v2/atoms/A'
import Loader from 'components-v2/molecules/Loader'
import Pagination from 'components-v2/molecules/Pagination'
import PageHeader from 'components-v2/organisms/PageHeader'
import { useAnnouncementsFetcher } from 'apis'
import { withReactQuery } from 'hocs'
import { useQueryParams } from 'hooks'
import { toLocalTimeString } from 'utils/date'
import { getPageUrl } from 'utils/url'
import config from 'config'
import {
  Content,
  Placeholder,
  Item,
  BullhornIcon,
  Date,
  PaginationWrapper,
} from './styles'

const View = () => {
  const [queryParams, setQueryParams] = useQueryParams({
    page: {
      type: Number,
      defaultValue: 1,
    },
  })
  const { page } = queryParams
  const {
    data: announcements = [],
    totalSize,
    isFetching,
  } = useAnnouncementsFetcher({
    params: {
      page,
    },
  })

  const totalPages = totalSize
    ? Math.ceil(totalSize / config.collectionQueryPageLimit.default)
    : 0

  return (
    <>
      <PageHeader title="Announcements" />
      <Content>
        {isFetching && (
          <Placeholder>
            <Loader />
          </Placeholder>
        )}
        {!isFetching && announcements.length === 0 && (
          <Well>No Recent Announcements</Well>
        )}
        {announcements.map((e) => (
          <Item key={e.id}>
            <p>
              <BullhornIcon />
              <span
                dangerouslySetInnerHTML={{
                  __html: e.banner,
                }}
              />
            </p>
            <div>
              <Date>{toLocalTimeString(e.start_at)}</Date>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <A href={getPageUrl('announcement', { id: e.id })}>View</A>
            </div>
          </Item>
        ))}
        {totalPages > 1 && (
          <PaginationWrapper>
            <Pagination
              currentPage={page}
              totalPages={totalPages}
              hideFirstAndLastPageLinks
              onChange={(v) => setQueryParams({ page: v })}
            />
          </PaginationWrapper>
        )}
      </Content>
    </>
  )
}

export default withReactQuery()(View)
