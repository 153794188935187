import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import reduce from 'lodash/reduce'
import Button from 'components-v2/atoms/Button'
import FormField from 'components-v2/molecules/FormField'
import { toLocalTimeString } from 'utils/date'
import {
  getVendorFieldValue,
  getFormFieldValue,
  getFormFieldValidation,
  getFormFieldProps,
} from '../utils'
import {
  StyledInfoCard,
  ActionButtons,
  StyledFormField,
  OtherFormField,
  ValueRenderRow,
  ValueWrapper,
} from './styles'

const renderFieldSingleValue = (field, vendor) => {
  const value = getVendorFieldValue(vendor, field.name)
  if (field.valueRenderComponent) {
    const Component = field.valueRenderComponent
    return <Component value={value} field={field} vendor={vendor} />
  }
  if (field.type === FormField.types.DATE) {
    return toLocalTimeString(value, 'MM/dd/yyyy')
  }
  if (Array.isArray(value)) {
    return value.join(', ')
  }
  return value
}

const FieldGroupCard = ({
  cardId,
  horizontal,
  vendor,
  data,
  editing,
  onEdit,
  onSave,
  onCancel,
  ...rest
}) => {
  const updatable = vendor.class !== 'VendorRequest'
  const initialValues = React.useMemo(
    () =>
      reduce(
        data.fields,
        (result, field) => {
          const newResult = {
            ...result,
          }
          newResult[field.name] = getFormFieldValue(field, vendor)
          if (field.other) {
            newResult[field.other.name] = getFormFieldValue(field.other, vendor)
          }
          return newResult
        },
        {},
      ),
    [data.fields, vendor],
  )

  const validationSchema = React.useMemo(() => {
    const validations = reduce(
      data.fields,
      (result, field) => {
        const newResult = {
          ...result,
        }
        newResult[field.name] = getFormFieldValidation(field)
        if (field.other) {
          newResult[field.other.name] = getFormFieldValidation(field.other)
        }
        return newResult
      },
      {},
    )
    return yup.object().shape(validations)
  }, [data.fields])

  const renderValue = (field) => {
    const valueHtml = renderFieldSingleValue(field, vendor)
    const otherHtml = field.other && renderFieldSingleValue(field.other, vendor)
    return (
      <ValueRenderRow
        key={field.name}
        label={field.label}
        helpMessage={field.helpMessage}
        horizontal={horizontal}
      >
        <ValueWrapper horizontal={horizontal}>
          {valueHtml && <div>{valueHtml}</div>}
          {otherHtml && (
            <div>
              {field.other.label}: {otherHtml}
            </div>
          )}
        </ValueWrapper>
      </ValueRenderRow>
    )
  }

  const visibleFields = React.useMemo(
    () =>
      data.fields.filter(
        (field) =>
          !field.visibilityGetter ||
          field.visibilityGetter({ field, vendor, editing }),
      ),
    [data.fields, vendor, editing],
  )

  if (data.fields.length === 0) {
    return null
  }

  return (
    <StyledInfoCard
      {...rest}
      title={data.label}
      editing={editing}
      onEdit={() => onEdit(cardId)}
      updatable={updatable}
    >
      {editing ? (
        <Formik
          onSubmit={(formData, bag) => onSave(cardId, formData, bag)}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          <Form>
            <div>
              {visibleFields.map((field) => {
                if (field.editable === false) {
                  return renderValue(field)
                }

                return (
                  <React.Fragment key={field.name}>
                    <StyledFormField
                      {...getFormFieldProps(field)}
                      horizontal={horizontal}
                      $hasOther={!!field.other}
                    />
                    {field.other && (
                      <OtherFormField {...getFormFieldProps(field.other)} />
                    )}
                  </React.Fragment>
                )
              })}
            </div>
            <ActionButtons>
              <Button size="small" onClick={() => onCancel(cardId)}>
                Cancel
              </Button>
              <Button type="submit" color="primary" size="small">
                Save
              </Button>
            </ActionButtons>
          </Form>
        </Formik>
      ) : (
        <div>{visibleFields.map(renderValue)}</div>
      )}
    </StyledInfoCard>
  )
}

FieldGroupCard.propTypes = {
  cardId: PropTypes.string.isRequired,
  horizontal: PropTypes.bool,
  data: PropTypes.object.isRequired,
  vendor: PropTypes.object.isRequired,
  editing: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default React.memo(FieldGroupCard)
