import React, { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import get from 'lodash/get'
import SearchGlobOrgModal from 'components/client/shared/SearchGlobOrgModal'
import NotificationManager from 'lib/notifications'
import { useGlobalLoader, useModal } from 'hooks'
import {
  useClientVendorFetcher,
  useCreateClientOrganizationGloborgMapping,
  useConfirmClientOrganizationGloborgMapping,
  useDeleteClientOrganizationGloborgMapping,
} from 'apis'
import { LabelTypes } from 'constants/index'

export default function useCRUDGloborgMapping(vendor) {
  const queryClient = useQueryClient()
  const { mutateAsync: createClientOrganizationGloborgMapping } =
    useCreateClientOrganizationGloborgMapping()
  const { mutateAsync: confirmClientOrganizationGloborgMapping } =
    useConfirmClientOrganizationGloborgMapping()
  const { mutateAsync: deleteClientOrganizationGloborgMapping } =
    useDeleteClientOrganizationGloborgMapping()
  const [showLoader, hideLoader] = useGlobalLoader()
  const [openModal, closeModal] = useModal(SearchGlobOrgModal)
  const vendorId = vendor.id
  const mappingId = get(vendor, 'globorg_mapping.id')

  const setVendor = useCallback(
    (data) => {
      queryClient.setQueriesData(
        {
          predicate: (query) =>
            query.queryKey[0] === useClientVendorFetcher.queryKey()[0] &&
            query.queryKey[1].id === vendorId,
        },
        data,
      )
    },
    [queryClient, vendorId],
  )

  const connectGloborg = useCallback(() => {
    openModal({
      title: `Connect a ${LabelTypes.VENDOR.toLowerCase()}`,
      submitButtonLabel: [
        <i key="icon" className="fa fa-link" aria-hidden="true"></i>,
        <span key="text">Connect</span>,
      ],
      onSubmit: (data) => {
        const loaderId = showLoader()
        createClientOrganizationGloborgMapping({
          data: {
            vendor_id: vendorId,
            domain: data.domain,
          },
        })
          .then((response) => {
            setVendor((current) => ({
              ...current,
              globorg_mapping: response,
            }))
            NotificationManager.success(
              'Public profile has been successfully connected.',
            )
            closeModal('SearchGlobOrgModal')
          })
          .catch(() => NotificationManager.error())
          .then(() => hideLoader(loaderId))
      },
    })
  }, [vendorId, openModal, closeModal, showLoader, hideLoader, setVendor])

  const confirmGloborg = useCallback(() => {
    const loaderId = showLoader()
    confirmClientOrganizationGloborgMapping({ id: mappingId })
      .then(() => {
        setVendor((current) => ({
          ...current,
          globorg_mapping: {
            ...current.globorg_mapping,
            type: 'explicit',
          },
        }))
      })
      .catch(() => NotificationManager.error())
      .then(() => hideLoader(loaderId))
  }, [mappingId, showLoader, hideLoader, setVendor])

  const disconnectGloborg = useCallback(() => {
    const loaderId = showLoader()
    deleteClientOrganizationGloborgMapping({ id: mappingId })
      .then(() => {
        setVendor((current) => ({
          ...current,
          globorg_mapping: null,
        }))
        NotificationManager.success(
          'Public profile has been successfully disconnected.',
        )
      })
      .catch(() => NotificationManager.error())
      .then(() => hideLoader(loaderId))
  }, [mappingId, showLoader, hideLoader, setVendor])

  return {
    connectGloborg,
    confirmGloborg,
    disconnectGloborg,
  }
}
