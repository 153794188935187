import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import { Menu, MenuItem, MenuItemLabel, SubMenu } from './styles'

function renderMenuItems(items, activeKey) {
  const activeKeySplits = activeKey ? activeKey.split(/\/(.+)/) : []
  return map(items, (item, key) => (
    <MenuItem key={key}>
      <MenuItemLabel active={key === activeKeySplits[0]}>
        {item.title}
      </MenuItemLabel>
      {!item.hideSubMenu && item.nodes && key === activeKeySplits[0] && (
        <SubMenu>{renderMenuItems(item.nodes, activeKeySplits[1])}</SubMenu>
      )}
    </MenuItem>
  ))
}

function TreeMenu({ data, activeKey, ...rest }) {
  return <Menu {...rest}>{renderMenuItems(data, activeKey)}</Menu>
}

TreeMenu.propTypes = {
  data: PropTypes.object.isRequired,
  activeKey: PropTypes.string,
}

export default TreeMenu
