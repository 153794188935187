import React from 'react'
import PropTypes from 'prop-types'
import { GoogleMapLink } from './styles'

const renderWithLineBreak = (content) => (
  <>
    {content}
    {content && <br />}
  </>
)

export function getGoogleMapUrl({
  address1,
  address2,
  city,
  state,
  postal,
  country,
}) {
  const searchStr = `${[address1, address2, city, state, postal, country].join(
    ' ',
  )}`
  return `https://google.com/maps/search/${encodeURIComponent(searchStr)}`
}

const Address = (props) => {
  const { address1, address2, city, state, postal, country } = props
  const usefulData = address1 || address2 || city

  return (
    <>
      <div>
        {renderWithLineBreak(address1)}
        {renderWithLineBreak(address2)}
        {city && <>{city}, </>}
        {state}
        {postal && <> {postal}</>}
        {/* replace with display name? */}
        {usefulData && country && <> {country}</>}
      </div>

      {/* only render link if we have any useful specific values */}
      {usefulData && (
        <GoogleMapLink
          href={getGoogleMapUrl({
            address1,
            address2,
            city,
            state,
            postal,
            country,
          })}
          target="_blank"
          underline
        >
          View on Google Maps
        </GoogleMapLink>
      )}
    </>
  )
}

Address.propTypes = {
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  postal: PropTypes.string,
  country: PropTypes.string,
}

export default Address
