import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'components-v2/atoms/Icon'
import {
  ApprovalOptionWrapper,
  ApprovalOptionMain,
  ApprovalOptionTitle,
  ApprovalOptionContent,
  ApprovalOptionMarker,
} from './styles'

const AssessmentApprovalOption = ({ icon, title, content, checked }) => (
  <ApprovalOptionWrapper className={checked ? 'checked' : ''}>
    <ApprovalOptionMain>
      <ApprovalOptionTitle>
        <Icon icon={icon} />
        <span>{title}</span>
      </ApprovalOptionTitle>
      <ApprovalOptionContent>{content}</ApprovalOptionContent>
    </ApprovalOptionMain>
    <ApprovalOptionMarker checked={checked} />
  </ApprovalOptionWrapper>
)

AssessmentApprovalOption.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
}

export default AssessmentApprovalOption
