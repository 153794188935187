import React from 'react'
import PropTypes from 'prop-types'
import some from 'lodash/some'
import map from 'lodash/map'
import SimpleDialog from 'components-v2/molecules/SimpleDialog'
import DropzoneUploader from './DropzoneUploader'
import { P } from './styles'

const AttachFilesModal = ({
  initialFiles,
  accept,
  maxSizeBytes,
  maxFiles,
  description,
  submitButtonLabel,
  onClose,
  onSubmit,
  ...rest
}) => {
  const [filesWithMeta, setFilesWithMeta] = React.useState([])

  const buttons = React.useMemo(
    () => [
      {
        onClick: onClose,
        children: 'Nevermind',
      },
      {
        color: 'primary',
        disabled:
          filesWithMeta.length === 0 ||
          some(filesWithMeta, (e) => e.meta.status !== 'done'),
        onClick: () => onSubmit(map(filesWithMeta, 'file')),
        children: submitButtonLabel,
      },
    ],
    [filesWithMeta, onClose, submitButtonLabel],
  )

  const handleChangeStatus = React.useCallback(
    (fileWithMeta, status, filesWithMetaP) => {
      // At next tick, "removed" files will be removed from filesWithMetaP array
      setTimeout(() => {
        // filesWithMetaP is a mutable array, let's regenerate immutable array
        setFilesWithMeta(
          map(filesWithMetaP, ({ file, meta }) => ({ file, meta })),
        )
      })
    },
    [],
  )

  return (
    <SimpleDialog
      {...rest}
      title="Attach file(s)"
      buttons={buttons}
      onClose={onClose}
    >
      {description && <P>{description}</P>}
      <DropzoneUploader
        initialFiles={initialFiles}
        accept={accept}
        maxSizeBytes={maxSizeBytes}
        maxFiles={maxFiles}
        onChangeStatus={handleChangeStatus}
      />
    </SimpleDialog>
  )
}

AttachFilesModal.propTypes = {
  initialFiles: PropTypes.array,
  accept: PropTypes.string,
  maxFiles: PropTypes.number,
  maxSizeBytes: PropTypes.number,
  description: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

AttachFilesModal.defaultProps = {
  submitButtonLabel: 'Upload file(s)',
}

export default AttachFilesModal
