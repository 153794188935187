import React, { useRef, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { toUncontrollable } from 'hocs'
import { Wrapper, Input, Track, TrackOn, TrackOff, Thumb } from './styles'

const Toggle = ({
  checked,
  className,
  disabled,
  onChange,
  onFocus,
  onBlur,
  ...inputProps
}) => {
  const [hasFocus, setHasFocus] = useState(false)
  const inputRef = useRef()

  const handleClick = useCallback(() => {
    if (disabled) {
      return
    }
    if (inputRef.current) {
      inputRef.current.focus()
    }
    if (onChange) {
      onChange(!checked)
    }
  }, [checked, disabled, onChange])

  const handleFocus = useCallback(
    (event) => {
      if (onFocus) {
        onFocus(event)
      }
      setHasFocus(true)
    },
    [onFocus],
  )

  const handleBlur = useCallback(
    (event) => {
      if (onBlur) {
        onBlur(event)
      }
      setHasFocus(false)
    },
    [onBlur],
  )

  return (
    <Wrapper
      className={className}
      checked={checked}
      disabled={disabled}
      focus={hasFocus}
      onClick={handleClick}
    >
      <Track>
        <TrackOn>on</TrackOn>
        <TrackOff>off</TrackOff>
      </Track>
      <Thumb />
      <Input
        {...inputProps}
        ref={inputRef}
        onFocus={handleFocus}
        onBlur={handleBlur}
        type="checkbox"
      />
    </Wrapper>
  )
}

Toggle.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
}

export default toUncontrollable({ checked: 'onChange' })(Toggle)
