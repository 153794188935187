/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import Dialog from 'components-v2/molecules/Dialog'
import Icon from 'components-v2/atoms/Icon'
import Button from 'components-v2/atoms/Button'
import Checkbox from 'components-v2/atoms/Checkbox'
import FileInput from 'components-v2/molecules/FileInput'
import Tooltip from 'components-v2/molecules/Tooltip'
import Popover from 'components-v2/molecules/Popover'
import NotificationManager from 'lib/notifications'
import { useGlobalLoader } from 'hooks'
import objectToFormData from 'utils/object_to_formdata'
import { getPageUrl } from 'utils/url'
import { useBulkImportClientVendorCustomFields } from 'apis'
import { ExportFormatTypes, LabelTypes } from 'constants/index'
import ConfirmPopoverContent from './ConfirmPopoverContent'
import {
  Body,
  Footer,
  FooterOptionSet,
  FooterOption,
  FooterSpacer,
  Error,
  Warning,
  NoErrorFound,
} from './styles'

const sampleFileUrl = getPageUrl(
  'clientVendorsCustomImportTemplate',
  undefined,
  undefined,
  ExportFormatTypes.CSV,
)

export default function ImportCustomFieldsModal({ orgId, onClose, ...rest }) {
  const [showLoader, hideLoader] = useGlobalLoader()
  const { mutateAsync: bulkImportCustomFields } =
    useBulkImportClientVendorCustomFields()
  const [file, setFile] = useState()
  const [overwrite, setOverwrite] = useState(false)
  const [clear, setClear] = useState(false)
  const [isValidating, setIsValidating] = useState(false)
  const [validationResult, setValidationResult] = useState()
  const [isImporting, setIsImporting] = useState(false)

  const handleValidate = useCallback(async () => {
    setIsValidating(true)
    const loaderId = showLoader()
    try {
      const data = await bulkImportCustomFields({
        data: objectToFormData({
          import_file: file,
          client_id: orgId,
          step: 'validate',
        }),
      })
      setValidationResult(data.result)
    } catch (error) {
      if (error.response?.status === 422) {
        setValidationResult(error.response.data.result)
      } else {
        NotificationManager.error()
      }
    }
    hideLoader(loaderId)
    setIsValidating(false)
  }, [file, orgId, bulkImportCustomFields, showLoader, hideLoader])

  const handleBack = useCallback(() => {
    setValidationResult()
  }, [])

  const handleImport = useCallback(async () => {
    setIsImporting(true)
    const loaderId = showLoader()
    try {
      const data = await bulkImportCustomFields({
        data: objectToFormData({
          import_file: file,
          client_id: orgId,
          overwrite,
          clear,
          step: 'import',
        }),
      })
      NotificationManager.success(
        `You've updated ${data.result.stats.matched} ${LabelTypes.VENDORS}.`,
      )
      onClose()
    } catch (error) {
      NotificationManager.error()
      setIsImporting(false)
    }
    hideLoader(loaderId)
  }, [
    orgId,
    file,
    overwrite,
    clear,
    bulkImportCustomFields,
    showLoader,
    hideLoader,
    onClose,
  ])

  const isValidationStep = !validationResult
  const hasValidationError = validationResult?.status === 'error'
  return (
    <Dialog {...rest} onClose={onClose}>
      <Dialog.Header onClose={onClose}>Import Custom Fields</Dialog.Header>
      {isValidationStep ? (
        <>
          <Body>
            <p>
              To import custom fields for existing vendor records, upload a csv
              file in the correct format. The csv template can be downloaded
              here:{' '}
              <a href={sampleFileUrl} target="_blank">
                custom fields import template
              </a>
              .
            </p>
            <FileInput
              value={file}
              accept=".csv"
              buttonContent={[
                <Icon key="icon" icon="fa fa-cloud-upload" />,
                'Upload file',
              ]}
              onChange={setFile}
            />
          </Body>
          <Footer>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              type="submit"
              color="primary"
              disabled={!file || isValidating}
              onClick={handleValidate}
            >
              Validate
            </Button>
          </Footer>
        </>
      ) : (
        <>
          <Body>
            {validationResult.errors.total > 0 && (
              <Error>
                <p>
                  <b>
                    {validationResult.errors.total} error(s) detected. File must
                    be error free to import. Please fix these validation errors
                    to proceed:
                  </b>
                </p>
                <ul>
                  {validationResult.errors.file.map((e) => (
                    <li key={e}>{e}</li>
                  ))}
                  {validationResult.errors.data.map((e) => (
                    <li key={e}>
                      row: {e.row}, column: {e.column} - {e.message}
                    </li>
                  ))}
                </ul>
              </Error>
            )}
            {validationResult.warnings.total > 0 && (
              <Warning>
                <p>
                  <b>{validationResult.warnings.total} warning(s):</b>
                </p>
                <ul>
                  {validationResult.warnings.file.map((e) => (
                    <li key={e}>{e}</li>
                  ))}
                  {validationResult.warnings.data.map((e) => (
                    <li key={e}>
                      row: {e.row}, column: {e.column} - {e.message}
                    </li>
                  ))}
                </ul>
              </Warning>
            )}
            {validationResult.status === 'success' &&
              validationResult.errors.total === 0 &&
              validationResult.warnings.total === 0 && (
                <NoErrorFound>No validation errors found!</NoErrorFound>
              )}
          </Body>
          <Footer>
            <FooterOptionSet>
              <FooterOption>
                <Checkbox
                  label="Overwrite existing values"
                  value={overwrite}
                  disabled={hasValidationError}
                  onChange={(e) => setOverwrite(e.target.checked)}
                />
                <Tooltip>
                  When enabled, any existing values will be overwritten by
                  values provided in the import file; otherwise, existing values
                  will be maintained.
                </Tooltip>
              </FooterOption>
              <FooterOption>
                <Checkbox
                  label="Clear with blanks"
                  value={clear}
                  disabled={!overwrite}
                  onChange={(e) => setClear(e.target.checked)}
                />
                <Tooltip>
                  When enabled, existing values will be deleted if the
                  corresponding value in the import file is blank.
                </Tooltip>
              </FooterOption>
            </FooterOptionSet>
            <FooterSpacer />
            <Button onClick={handleBack}>Back</Button>
            <Popover placement="top">
              <Popover.Button
                color="primary"
                disabled={hasValidationError || isImporting}
              >
                Import
              </Popover.Button>
              <Popover.Panel arrow>
                {({ close }) => (
                  <ConfirmPopoverContent
                    onConfirm={() => {
                      close()
                      handleImport()
                    }}
                    onHide={close}
                  />
                )}
              </Popover.Panel>
            </Popover>
          </Footer>
        </>
      )}
    </Dialog>
  )
}

ImportCustomFieldsModal.propTypes = {
  orgId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}
