import { useCallback } from 'react'
import { useGlobalLoader } from 'hooks'
import NotificationManager from 'lib/notifications'
import {
  useAddAnswerSetToAssistQuestionSetAnswerSets,
  useDeleteAnswerSetFromAssistQuestionSetAnswerSets,
} from 'apis'

export default function useToggleAnswerSet(questionSetId) {
  const { mutateAsync: addAnswerSetToAssistQuestionSetAnswerSets } =
    useAddAnswerSetToAssistQuestionSetAnswerSets()
  const { mutateAsync: deleteAnswerSetFromAssistQuestionSetAnswerSets } =
    useDeleteAnswerSetFromAssistQuestionSetAnswerSets()
  const [showLoader, hideLoader] = useGlobalLoader()

  return useCallback(
    (id, checked) => {
      const loaderId = showLoader()
      const api = checked
        ? addAnswerSetToAssistQuestionSetAnswerSets
        : deleteAnswerSetFromAssistQuestionSetAnswerSets
      return api({
        id: questionSetId,
        data: { question_set_answer_set: { assist_answer_set_id: id } },
      })
        .catch(() => NotificationManager.error())
        .then(() => hideLoader(loaderId))
    },
    [
      questionSetId,
      addAnswerSetToAssistQuestionSetAnswerSets,
      deleteAnswerSetFromAssistQuestionSetAnswerSets,
      showLoader,
      hideLoader,
    ],
  )
}
