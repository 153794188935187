import React, { useState, useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { compose, withAuthorize, withReactQuery } from 'hocs'
import { useAutoToggleStates } from 'hooks'
import { getEndpointUrl } from 'utils/url'
import { getProgressSummary } from 'lib/assessment'
import { useVendorAssessmentAutoAnswerableFetcher } from 'apis'
import AssessmentView from './View'
import useAssessmentElementsFetcher from './useAssessmentElementsFetcher'
import usePusher from './usePusher'
import useHashScrollOnInitialLoad from './useHashScrollOnInitialLoad'
import useUpdateElementResponse from './useUpdateElementResponse'
import useImportResponses from './useImportResponses'
import useRequestExtension from './useRequestExtension'
import useUpdateStatesFromElementResponse from './useUpdateStatesFromElementResponse'
import useUpdateAssessmentComment from './useUpdateAssessmentComment'
import useViewAssessmentStatus from './useViewAssessmentStatus'
import useAutoAnswer from './useAutoAnswer'
import useSubmitAssessment from './useSubmitAssessment'
import { filterElements } from './utils'
import { highlightTimeout } from './constants'

export const VendorAssessmentView = ({
  assessment: assessmentProp,
  elements: elementsProp,
  initialSectionId,
  currentOrganization,
  organizationPath,
  currentUser,
  hideHeader,
}) => {
  const [assessment, setAssessment] = useState(assessmentProp)
  const [selectedSectionId, setSelectedSectionId] = useState(initialSectionId)
  const [elementFilter, setElementFilter] = useState({})
  const [filterText, setFilterText] = useState('')
  const [autoSavedAt, setAutoSavedAt] = useState()
  const [elementIdInFocus, setElementIdInFocus] = useState(null)
  const [highlightedRows, activateHighlight] = useAutoToggleStates(
    highlightTimeout.enter,
  )
  const { data: elements } = useAssessmentElementsFetcher(
    {
      assessmentId: assessmentProp.id,
      sectionId: selectedSectionId,
    },
    elementsProp,
  )
  const { data: autoAnswerableData } = useVendorAssessmentAutoAnswerableFetcher(
    {
      id: assessmentProp.id,
    },
    {
      enabled: assessmentProp.auto_answerable,
    },
  )
  const autoAnswerableVendors = autoAnswerableData?.vendor_list

  useHashScrollOnInitialLoad(assessmentProp.id, initialSectionId)
  const updateStatesFromElementResponse = useUpdateStatesFromElementResponse(
    assessmentProp.id,
    setAssessment,
    setAutoSavedAt,
  )
  const socketId = usePusher(
    assessment.channel,
    assessmentProp.id,
    selectedSectionId,
    setAssessment,
    updateStatesFromElementResponse,
    activateHighlight,
  )
  const updateElementResponse = useUpdateElementResponse(
    assessmentProp.id,
    selectedSectionId,
    socketId,
    updateStatesFromElementResponse,
  )
  const importResponses = useImportResponses(assessment)
  const requestExtension = useRequestExtension(assessment, setAssessment)
  const updateAssessmentComment = useUpdateAssessmentComment(
    assessmentProp.id,
    setAssessment,
  )
  const viewAssessmentStatus = useViewAssessmentStatus(assessment)
  const {
    dismissed: autoAnswerDismissed,
    data: autoAnswerResult,
    isError: autoAnswerError,
    confirm: onAutoAnswerConfirm,
    close: onAutoAnswerClose,
  } = useAutoAnswer(assessmentProp.id, setAssessment)
  const { incompleteAttempted, submitAssessment } = useSubmitAssessment(
    assessment,
    setAssessment,
    setElementFilter,
    setSelectedSectionId,
  )

  const handleElementAnswerChange = useCallback(
    (elementId, answer) => updateElementResponse(elementId, { answer }),
    [updateElementResponse],
  )

  const handleElementCommentSubmit = useCallback(
    (elementId, comment) => updateElementResponse(elementId, { comment }),
    [updateElementResponse],
  )

  const handleElementAttachmentsChange = useCallback(
    (elementId, responseData) => updateStatesFromElementResponse(responseData),
    [updateStatesFromElementResponse],
  )

  const handleAssessmentAttachmentsChange = useCallback((attachments) => {
    setAssessment((current) => ({
      ...current,
      attachments,
    }))
  }, [])

  useEffect(() => {
    setElementIdInFocus(null)
  }, [elementFilter, filterText, selectedSectionId])

  const filteredElements = useMemo(
    () => filterElements(elements, elementFilter, filterText, elementIdInFocus),
    [elements, elementFilter, filterText, elementIdInFocus],
  )
  const { answeredQuestions, incompleteQuestions, totalQuestions } = useMemo(
    () => getProgressSummary(assessment.progress),
    [assessment.progress],
  )
  const assessmentEndpointUrl = getEndpointUrl('vendorAssessment', {
    id: assessment.id,
  })

  // TODO: So many of prop.
  return (
    <AssessmentView
      assessment={assessment}
      selectedSectionId={selectedSectionId}
      elementFilter={elementFilter}
      filterText={filterText}
      incompleteAttempted={incompleteAttempted}
      autoSavedAt={autoSavedAt}
      autoAnswerableVendors={autoAnswerableVendors}
      autoAnswerDismissed={autoAnswerDismissed}
      autoAnswerResult={autoAnswerResult}
      autoAnswerError={autoAnswerError}
      assessmentEndpointUrl={assessmentEndpointUrl}
      socketId={socketId}
      hideHeader={hideHeader}
      answeredQuestions={answeredQuestions}
      incompleteQuestions={incompleteQuestions}
      totalQuestions={totalQuestions}
      filteredElements={filteredElements}
      highlightedRows={highlightedRows}
      currentOrganization={currentOrganization}
      organizationPath={organizationPath}
      currentUser={currentUser}
      onElementAnswerChange={handleElementAnswerChange}
      onElementCommentSubmit={handleElementCommentSubmit}
      onElementAttachmentsChange={handleElementAttachmentsChange}
      onElementRowClick={setElementIdInFocus}
      onElementFilterChange={setElementFilter}
      onFilterTextChange={(e) => setFilterText(e.target.value)}
      onAssessmentCommentSubmit={updateAssessmentComment}
      onAssessmentAttachmentsChange={handleAssessmentAttachmentsChange}
      onRequestExtension={requestExtension}
      onAssessmentSubmit={submitAssessment}
      onViewAssessmentStatusButtonClick={viewAssessmentStatus}
      onSectionChange={setSelectedSectionId}
      onAutoAnswerConfirm={onAutoAnswerConfirm}
      onAutoAnswerClose={onAutoAnswerClose}
      onImportResponsesButtonClick={importResponses}
    />
  )
}

VendorAssessmentView.propTypes = {
  assessment: PropTypes.object.isRequired,
  elements: PropTypes.array,
  initialSectionId: PropTypes.string,
  currentOrganization: PropTypes.object.isRequired,
  organizationPath: PropTypes.string.isRequired,
  currentUser: PropTypes.string,
  hideHeader: PropTypes.bool,
}

export default compose(withAuthorize(), withReactQuery())(VendorAssessmentView)
