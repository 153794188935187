import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import { useUpdateEffect, useDebouncedCallback } from 'hooks'

const AutoSave = ({ debounceMs }) => {
  const formik = useFormikContext()
  const debouncedSubmit = useDebouncedCallback(
    (submitForm) => submitForm(),
    debounceMs,
  )

  useUpdateEffect(() => {
    debouncedSubmit(formik.submitForm)
  }, [debouncedSubmit, formik.submitForm, formik.values])

  return null
}

AutoSave.propTypes = {
  debounceMs: PropTypes.number,
}

AutoSave.defaultProps = {
  debounceMs: 500,
}

export default AutoSave
