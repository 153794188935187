import PropTypes from 'prop-types'
import React from 'react'
import GradeLabel from 'components/shared/GradeLabel'
import { NoWrapCell } from './styles'

const FacetSetTable = ({ facetableSets, openFacetSets }) => (
  <ol>
    {facetableSets.map((r) => (
      <li key={r.id}>
        {r.facet_set_name} {r.status}
        <ol>
          {r.facetable_items.map((f) => (
            <li>
              <NoWrapCell>
                <GradeLabel grade={f.grade} /> &nbsp;
                {f.facet_name}
              </NoWrapCell>
            </li>
          ))}
          {r.open_facet_set_items.map((f) => (
            <li>
              <NoWrapCell>
                None &nbsp;
                {f.facet_name}
              </NoWrapCell>
            </li>
          ))}
        </ol>
      </li>
    ))}
    {openFacetSets.map((r) => (
      <li key={r.id}>{r.name} None</li>
    ))}
  </ol>
)

FacetSetTable.propTypes = {
  facetableSets: PropTypes.array,
  openFacetSets: PropTypes.array,
}

export default FacetSetTable
