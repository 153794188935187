import { useMemo } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'

// Let's add the following logics to useInfiniteQuery
// 1. nextPageParam population logic
// 2. instead of returning data in pages, return single array containing the data from all pages
export default (queryKey, queryFn, options) => {
  const { data, ...rest } = useInfiniteQuery(
    queryKey,
    ({ pageParam = 1 }) =>
      queryFn({ pageParam }).then((res) => ({
        results: res.data,
        page: pageParam,
        perPage: res.headers['per-page'],
        total: res.headers.total,
      })),
    {
      ...options,
      getNextPageParam: (lastPage) =>
        lastPage.page * lastPage.perPage < lastPage.total
          ? lastPage.page + 1
          : undefined,
    },
  )
  const collatedData = useMemo(
    () => data?.pages?.flatMap(({ results }) => results),
    [data?.pages],
  )
  return {
    data: collatedData,
    ...rest,
  }
}
