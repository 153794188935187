import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import config from 'config'
import { ExportFormatTypes } from 'constants/index'
import { useCurrent, useQueryParams } from 'hooks'
import { useClientSourcedAttachmentsFetcher } from 'apis'
import { ActionMenuItemTypes, filterConfigList } from './constants'
import { getFileQueryParams, getExportUrl } from './utils'
import View from './View'
import useCRUDVendorAttachment from './useCRUDVendorAttachment'

const Container = ({ vendorId, clientVendorId }) => {
  const {
    createVendorAttachments,
    editVendorAttachment,
    deleteVendorAttachment,
  } = useCRUDVendorAttachment(clientVendorId)

  const [
    { page, sizePerPage, sortField, sortOrder, searchText },
    setQueryParams,
  ] = useQueryParams({
    page: {
      type: Number,
      defaultValue: 1,
    },
    sizePerPage: {
      queryKey: 'per_page',
      type: Number,
      defaultValue: config.collectionQueryPageLimit.default,
    },
    sortField: {
      queryKey: 'ordered_by',
    },
    sortOrder: {
      queryKey: 'direction',
    },
    searchText: {
      queryKey: 'q',
      defaultValue: '',
    },
  })
  const [filter, setFilter] = useState({
    sourceTypes: { selected: ['vendor'], anyMatch: true },
    expiration: { selected: [] },
  })

  const tableState = {
    page,
    sizePerPage,
    sortField,
    sortOrder,
    searchText,
    filter,
  }

  const fileQueryParams = getFileQueryParams(tableState, vendorId)
  const fileQueryParamsRef = useCurrent(fileQueryParams)
  const {
    data: attachments,
    totalSize,
    isFetching,
  } = useClientSourcedAttachmentsFetcher({
    params: fileQueryParams,
  })

  const handleSearchChange = useCallback(
    (newValue) => {
      setQueryParams({
        page: 1,
        searchText: newValue,
      })
    },
    [setQueryParams],
  )

  const handleFilterChange = useCallback(
    (newValue) => {
      setQueryParams({ page: 1 })
      setFilter(newValue)
    },
    [setQueryParams],
  )

  const handleMenuItemSelect = useCallback(
    (key) => {
      switch (key) {
        case ActionMenuItemTypes.EXPORT_CSV:
          window.location.assign(
            getExportUrl(ExportFormatTypes.CSV, fileQueryParamsRef.current),
          )
          return
        case ActionMenuItemTypes.EXPORT_XLS:
          window.location.assign(
            getExportUrl(ExportFormatTypes.XLSX, fileQueryParamsRef.current),
          )
          break
        default:
      }
    },
    [fileQueryParamsRef],
  )

  const handleEdit = useCallback(
    (cvaId) => {
      const row = attachments?.find((e) => e?.cva?.id === cvaId)
      if (!row) {
        return
      }

      const { cva, ...rest } = row
      const vendorAttachment = {
        ...cva,
        attachment: row.source_id ? rest : undefined,
      }
      editVendorAttachment(vendorAttachment)
    },
    [editVendorAttachment, attachments],
  )

  return (
    <View
      {...tableState}
      attachments={attachments}
      totalSize={totalSize}
      loading={isFetching}
      filterConfigList={filterConfigList}
      vendorColumnVisible={!vendorId}
      newFileButtonVisible={!!clientVendorId}
      onPaginationChange={setQueryParams}
      onSortingChange={setQueryParams}
      onSearchChange={handleSearchChange}
      onMenuItemSelect={handleMenuItemSelect}
      onFilterChange={handleFilterChange}
      onBulkCreate={createVendorAttachments}
      onEdit={handleEdit}
      onDelete={deleteVendorAttachment}
    />
  )
}

Container.propTypes = {
  vendorId: PropTypes.string,
  clientVendorId: PropTypes.string,
}

export default Container
