import styled from 'styled-components'
import Card from 'components-v2/organisms/Card'
import { colors } from 'styles'

export const Wrapper = styled(Card)`
  text-align: center;
`

export const Title = styled.h4`
  margin: 20px 0 0;
`

export const ScoreLabel = styled.div`
  font-size: 28px;
  color: ${colors.GREEN};
  margin-bottom: 20px;
`

export const ChartWrapper = styled.div`
  position: relative;
`

export const Score = styled.h4`
  position: absolute;
  font-size: 54px;
  line-height: 1em;
  color: ${(props) => props.color};
  text-align: center;
  margin: 0;
  top: 80px;
  left: 0;
  right: 0;
`

export const UpdatedAt = styled.div`
  margin-top: 15px;
  font-size: 14px;
  color: ${colors.GREY};
`
