import styled from 'styled-components'
import Icon from 'components-v2/atoms/Icon'
import IconButton from 'components-v2/atoms/IconButton'
import DateField from 'components-v2/molecules/FormField/DateField'
import ControlLabel from 'components-v2/molecules/FormField/ControlLabel'
import { colors } from 'styles'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 15px;
  }
`

export const HourglassIcon = styled(Icon).attrs({
  icon: 'fa fa-hourglass-end',
})`
  color: ${colors.GREY};
`

export const LockIcon = styled(Icon).attrs({ icon: 'fa fa-lock' })`
  color: ${colors.GREY};
`

export const EllipsisIconButton = styled(IconButton).attrs({
  icon: 'fa fa-ellipsis-h',
})``

export const DateRangeWrapper = styled.div`
  width: 305px;

  &:not(:last-child) {
    margin-right: 30px;
  }
`

export const StyledDateField = styled(DateField).attrs({
  horizontal: true,
  size: 'sm',
})`
  padding-left: 90px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  ${ControlLabel.Wrapper} {
    width: 90px;
    padding-right: 15px;
  }
`

export const DateRangeButtons = styled.div`
  text-align: right;
`
