import * as yup from 'yup'

export default yup.object().shape({
  name: yup.string().label('Name').required(),
  email: yup.string().label('Email').required().email(),
  phone: yup
    .string()
    .label('Phone')
    .nullable()
    .max(75, 'Phone is too long (maximum is 75 characters)'),
  title: yup
    .string()
    .label('Title')
    .nullable()
    .max(255, 'Title is too long (maximum is 255 characters)'),
})
