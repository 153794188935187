import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import ProgressBar from 'components-v2/molecules/ProgressBar'
import { getProgressSummary } from 'lib/assessment'

const AssessmentProgressBar = ({ summary, color }) => {
  const progressSummary = useMemo(() => getProgressSummary(summary), [summary])
  const now =
    (progressSummary.answeredQuestions / progressSummary.totalQuestions) * 100
  return (
    <ProgressBar
      now={now}
      label={`${Math.round(now)}%`}
      height={22}
      color={color}
    />
  )
}

AssessmentProgressBar.propTypes = {
  summary: PropTypes.object.isRequired,
  color: PropTypes.string,
}

AssessmentProgressBar.defaultProps = {
  color: undefined,
}

export default AssessmentProgressBar
