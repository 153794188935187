import styled from 'styled-components'
import ResponseScoreSelect from 'components/shared/ResponseScoreSelect'
import { getRiskRatingColor } from 'lib/assessment'
import { fonts, mixins } from 'styles'

export const PreviewRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`

export const PreviewLeftCol = styled.div`
  width: 190px;
  flex: none;
`

export const RatingLabel = styled.div`
  font-family: ${fonts.SECONDARY};
  font-size: 22px;
  font-weight: bold;
  line-height: 1em;
  margin-bottom: 10px;
`

export const RiskScore = styled.div`
  color: ${getRiskRatingColor(80)};
  font-weight: 800;
  line-height: 1em;
  font-size: 54px;
  letter-spacing: 0.05em;
`

export const PreviewRightCol = styled.div`
  min-width: 0;
`

const StyledMarkers = styled(ResponseScoreSelect.Markers)`
  flex: none;
  width: 104px;
  margin-right: 15px;
`

const StyledLabel = styled(ResponseScoreSelect.Label)`
  text-align: left;
  font-size: 0.95em;
  padding-top: 0;
  ${mixins.truncate}
`

export const StyledResponseScoreSelect = styled(ResponseScoreSelect).attrs({
  RenderMarkers: StyledMarkers,
  RenderLabel: StyledLabel,
  readOnly: true,
  weight: 1,
})`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`

export const Footer = styled.div`
  margin-top: 20px;
`
