import styled from 'styled-components'
import ButtonGroup from 'components-v2/molecules/ButtonGroup'

export const Title = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 10px;
`

export const List = styled.ul`
  margin-top: 10px;
  padding-left: 0;
  list-style: none;
  overflow-y: auto;
  max-height: 1000px;
  min-height: 500px;
  background-color: #eee;
`

export const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
  padding: 10px;

  ${({ $active }) =>
    $active &&
    `
    font-weight: bold;
    background-color: #fff;
    margin-right: 0px;
    padding-right: 20px;
  `}
`

export const StyledButtonGroup = styled(ButtonGroup)`
  flex: none;
  margin-left: 20px;
`
