import { getPageUrl } from 'utils/url'
import { AssessmentGradeTypes } from 'constants/index'
import { colors } from 'styles'

// NOTE: Change grade color for "Pass" only. This is specific to this page.
export const getAssessmentGradeColor = (grade, defaultColor) =>
  grade === AssessmentGradeTypes.PASS ? colors.BLUE : defaultColor

export const getAssessmentsPageUrl = () =>
  getPageUrl('clientVendors', undefined, { tab: 'assessments' })

export const getAssessmentsInStatusPageUrl = (status, archived) =>
  getPageUrl('clientVendors', undefined, {
    tab: 'assessments',
    status,
    include_archived: archived ? ['vendors', 'projects'] : undefined,
  })

export const getIssuesInStatusPageUrl = (status) =>
  getPageUrl('clientIssues', undefined, { status })
