import React from 'react'
import PropTypes from 'prop-types'
import Privva from 'Privva'
import { Transition } from '@headlessui/react'
import Toggle from './Toggle'
import {
  Logo,
  ShieldStarIcon,
  Wrapper,
  Inner,
  LogoLink,
  Top,
  Collapse,
} from './styles'

const BaseNavBar = ({
  logoLink,
  logoTitle,
  siteLogo,
  defaultSiteLogo,
  isSystemClient,
  children,
  isLoggedIn,
}) => {
  const [mobileCollapsed, setMobileCollapsed] = React.useState(true)
  return (
    <Wrapper
      isLoggedIn={isLoggedIn}
      id="top-nav"
      data-theme={Privva.Utils.theme.theme}
    >
      <Inner className="container">
        <Top>
          {siteLogo && (
            <LogoLink href={logoLink} title={logoTitle}>
              <Logo
                src={siteLogo}
                id="navbar-logo"
                data-src={defaultSiteLogo}
              />
            </LogoLink>
          )}
          {isSystemClient && <ShieldStarIcon />}
          {children && (
            <Toggle onClick={() => setMobileCollapsed((current) => !current)} />
          )}
        </Top>

        {children && (
          <Transition
            show={!mobileCollapsed}
            unmount={false}
            as={Collapse}
            enter="ease-out duration-300"
            enterFrom="translate-y-full"
            enterTo="translate-y-0"
            leave="ease-out duration-300"
            leaveFrom="translate-y-0"
            leaveTo="translate-y-full"
          >
            {children}
          </Transition>
        )}
      </Inner>
    </Wrapper>
  )
}

BaseNavBar.propTypes = {
  logoLink: PropTypes.string,
  logoTitle: PropTypes.string,
  siteLogo: PropTypes.string,
  defaultSiteLogo: PropTypes.string,
  isSystemClient: PropTypes.bool,
  children: PropTypes.node,
  isLoggedIn: PropTypes.bool,
}

export default BaseNavBar
