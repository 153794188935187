import React from 'react'
import PropTypes from 'prop-types'
import Linkify from 'linkify-react'

/**
 * Sample Input: Go to http://www.rubyonrails.org and say hello to david@loudthinking.com"
 * Output: Go to <a href=\"http://www.rubyonrails.org\">http://www.rubyonrails.org</a> and say hello to <a href=\"mailto:david@loudthinking.com\">david@loudthinking.com</a>
 */
const AutoLink = ({ text, ...rest }) => (
  <Linkify
    options={{
      target: '_blank',
      rel: 'noopener noreferrer',
    }}
    {...rest}
  >
    {text}
  </Linkify>
)

AutoLink.propTypes = {
  text: PropTypes.string,
}

export default AutoLink
