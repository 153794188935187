import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import styled from 'styled-components'
import IconButton from 'components-v2/atoms/IconButton'
import FormField from 'components-v2/molecules/FormField'
import Popover from 'components-v2/molecules/Popover'
import ListGroup from 'components-v2/molecules/ListGroup'
import FormDialog from 'components-v2/organisms/FormDialog'
import { toLocalTimeString } from 'utils/date'
import { mixins } from 'styles'

const StyledListGroup = styled(ListGroup)`
  margin: 0;
  padding: 16px;
`

const ListGroupItemHeading = styled.h4`
  margin: 0 0 5px;
  ${mixins.smallHeader}
`

const Heading = styled.div`
  margin-bottom: 11px;
`

const validationSchema = yup.object().shape({
  scorerType: yup.string().required('Please select a type'),
})

const QuestionSetSettingsModal = ({
  questionSet,
  scorerTypes,
  scorerModels,
  ...rest
}) => {
  const formConfig = React.useMemo(() => {
    const initialValues = {
      scorerType: questionSet.scorer_type,
      scorerModel: questionSet.scorer_model,
      status: questionSet.status || '',
    }
    return { initialValues, validationSchema }
  }, [questionSet])

  const scorerTypeOptions = useMemo(
    () =>
      scorerTypes?.map((e) => ({
        value: e.type,
        label: e.name,
      })),
    [scorerTypes],
  )

  const scorerModelOptions = useMemo(
    () =>
      scorerModels?.map((e) => ({
        value: e,
        label: e,
      })),
    [scorerModels],
  )

  return (
    <FormDialog
      {...rest}
      title="Question Set Settings"
      submitButtonLabel="Save"
      formConfig={formConfig}
    >
      <Heading>
        <span className="h5">{questionSet.name}</span>&nbsp;
        <Popover>
          <Popover.Button
            as={IconButton}
            icon="fa fa-info-circle"
            title="Additional Info"
          />
          <Popover.Panel title="Additional Info" arrow>
            <StyledListGroup>
              <ListGroup.Item>
                <ListGroupItemHeading>Created At:</ListGroupItemHeading>
                {toLocalTimeString(questionSet.created_at, 'MM/dd/yyyy h:mm a')}
              </ListGroup.Item>
              {questionSet.scored_job_id && (
                <ListGroup.Item>
                  <ListGroupItemHeading>Job Id:</ListGroupItemHeading>
                  {questionSet.scored_job_id}
                </ListGroup.Item>
              )}
              {questionSet.job_started_at && (
                <ListGroup.Item>
                  <ListGroupItemHeading>Job Started At:</ListGroupItemHeading>
                  {toLocalTimeString(
                    questionSet.job_started_at,
                    'MM/dd/yyyy h:mm a',
                  )}
                </ListGroup.Item>
              )}
              {questionSet.scored_at && (
                <ListGroup.Item>
                  <ListGroupItemHeading>Scored At:</ListGroupItemHeading>
                  {toLocalTimeString(
                    questionSet.scored_at,
                    'MM/dd/yyyy h:mm a',
                  )}
                </ListGroup.Item>
              )}
              {questionSet.failed_at && (
                <ListGroup.Item>
                  <ListGroupItemHeading>Failed At:</ListGroupItemHeading>
                  {toLocalTimeString(
                    questionSet.failed_at,
                    'MM/dd/yyyy h:mm a',
                  )}
                </ListGroup.Item>
              )}
            </StyledListGroup>
          </Popover.Panel>
        </Popover>
      </Heading>
      <FormField
        name="scorerType"
        label="Scorer"
        type={FormField.types.SEARCHABLE_SELECT}
        disabled={!!questionSet.scored_at}
        options={scorerTypeOptions}
        controlProps={{
          isClearable: false,
          isLoading: !scorerTypeOptions,
        }}
        horizontal
      />
      <FormField
        name="scorerModel"
        label="Scorer Model"
        type={FormField.types.SEARCHABLE_SELECT}
        disabled={!!questionSet.scored_at}
        options={scorerModelOptions}
        controlProps={{
          isClearable: true,
          isLoading: !scorerModelOptions,
        }}
        horizontal
      />
      <FormField name="status" label="Status" disabled horizontal />
    </FormDialog>
  )
}

QuestionSetSettingsModal.propTypes = {
  questionSet: PropTypes.object.isRequired,
  scorerTypes: PropTypes.array,
  scorerModels: PropTypes.array,
}

export default QuestionSetSettingsModal
