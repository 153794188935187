import styled from 'styled-components'

export const ChildVendorNameList = styled.div`
  margin-left: 10px;
`

export const ChildVendorName = styled.div`
  padding-bottom: 0px;
  border-bottom: unset;
  margin-bottom: 5px;
`
