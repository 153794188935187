import React from 'react'
import PropTypes from 'prop-types'
import {
  ScoreCheckbox,
  StyledMultiSelectFilterDropdown as MultiSelectFilterDropdown,
} from './styles'

const ColorfulCheckboxSet = ({
  selected,
  label,
  options,
  selectedButtonColor,
  selectedButtonVariant,
  defaultButtonColor,
  defaultButtonVariant,
  buttonSize,
  RenderButton,
  onSelect,
  ...rest
}) => (
  <MultiSelectFilterDropdown
    {...rest}
    title={selected ? `${label}: (${selected?.selected?.length})` : `${label}:`}
    placement="bottom-end"
    buttonProps={{
      active: !!selected,
      size: buttonSize,
      color: selected ? selectedButtonColor : defaultButtonColor,
      variant: selected ? selectedButtonVariant : defaultButtonVariant,
      hidePrint: false,
    }}
    value={selected}
    options={options}
    onChange={onSelect}
    RenderCheckbox={ScoreCheckbox}
  />
)

ColorfulCheckboxSet.propTypes = {
  selected: PropTypes.object,
  label: PropTypes.string.isRequired,
  selectedButtonColor: PropTypes.string,
  selectedButtonVariant: PropTypes.string,
  defaultButtonColor: PropTypes.string,
  defaultButtonVariant: PropTypes.string,
  buttonSize: PropTypes.string,
  options: PropTypes.array.isRequired,
  RenderButton: PropTypes.elementType,
  onSelect: PropTypes.func.isRequired,
}

export default ColorfulCheckboxSet
