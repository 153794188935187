// HeadlessUI Menu component with the following changes;
// 1. Floating UI + Branded styles
// Let's not change HeadlessUI Menu's composition logic to allow us to compose its subcomponents and make a new thing as we want later.
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Menu as BaseMenu } from '@headlessui/react'
import { Float } from '@headlessui-float/react'
import MenuButton from './MenuButton'
import MenuItems from './MenuItems'
import MenuItem from './MenuItem'

const Wrapper = styled(BaseMenu).attrs({ forwardedAs: 'div' })`
  display: inline-block;
`

const Menu = ({
  placement,
  offset,
  portal,
  arrow,
  shift,
  zIndex,
  children,
  ...rest
}) => (
  <Wrapper {...rest}>
    {(renderProps) => (
      <Float
        composable
        placement={placement}
        offset={offset}
        arrow={arrow}
        portal={portal}
        shift={shift}
        zIndex={zIndex}
      >
        {/* Hacky solution to prevent a weird error from Float component */}
        {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
        <></>
        {typeof children === 'function' ? children(renderProps) : children}
      </Float>
    )}
  </Wrapper>
)

Menu.propTypes = {
  placement: PropTypes.string,
  offset: PropTypes.number,
  portal: PropTypes.bool,
  arrow: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  shift: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  zIndex: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
}

Menu.defaultProps = {
  placement: 'bottom',
  offset: 12,
  arrow: 16,
  shift: 16,
  zIndex: 1000,
}

Menu.Button = MenuButton
Menu.Items = MenuItems
Menu.Item = MenuItem
Menu.Divider = styled.div`
  height: 1px;
  margin: 10px 0;
  overflow: hidden;
  background-color: #e5e5e5;
`

export default Menu
