import styled from 'styled-components'
import Button from 'components-v2/atoms/Button'
import FormField from 'components-v2/molecules/FormField'
import { mixins } from 'styles'

export const AddButton = styled(Button).attrs({
  color: 'primary',
  variant: 'text',
})`
  ${mixins.smallHeader};
  letter-spacing: 0;
  margin-bottom: 25px;
`

export const ExtensionRequestFieldWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const SmallFormField = styled(FormField).attrs({
  preserveHelpTextSpace: false,
})`
  display: inline-block;
  margin: 0 10px;
  vertical-align: top;

  input {
    width: 90px;
    height: 30px;
    text-align: center;
  }
`

export const AutoExtensionOptionsWrapper = styled.div`
  padding-left: 70px;
  margin-top: 15px;
  line-height: 30px;

  > *:not(:last-child) {
    margin-bottom: 5px;
  }

  > *:not(:first-child) {
    margin-left: 24px;
  }
`
