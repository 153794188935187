import styled from 'styled-components'
import Card from 'components-v2/organisms/Card'

export const StyledContentCard = styled(Card)`
  display: flex;
  flex-direction: column;
`

export const Top = styled(Card.Segment)`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
`

export const Title = styled.h4`
  margin: 0 0 10px 0;
  flex: none;
`

export const ChartWrapper = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  flex: none;
`

export const Bottom = styled(Card.Segment)`
  flex: none;
  text-align: center;
`

export const Score = styled.span`
  display: inline-block;
  font-size: 60px;
  font-weight: 300;
  line-height: 1em;

  sup {
    color: #666;
    font-size: 60%;
    margin-left: 5px;
  }
`

export const BottomRight = styled.div`
  display: inline-block;
  margin-left: 15px;
  padding-top: 10px;
  text-align: left;
  vertical-align: top;
`

export const ScoreLabel = styled.span`
  display: block;
  font-weight: bold;
`
