import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import NotificationManager from 'lib/notifications'
import { useUpdateClientResponse } from 'apis'
import ResponseSource from './ResponseSource'

const IssuableTypes = {
  RESPONSE: 'response',
}

const IssueIssuable = ({ issue, setIssue, ...rest }) => {
  const responseId = get(issue, 'issuable.response.id')
  const { mutateAsync: updateClientResponse } = useUpdateClientResponse()

  const handleResponseScoreChange = React.useCallback(
    (score) => {
      const setScore = (newScore) =>
        setIssue((currentIssue) => ({
          ...currentIssue,
          issuable: {
            ...currentIssue.issuable,
            response: {
              ...currentIssue.issuable.response,
              review_score: newScore,
            },
          },
        }))

      // Let's update UI first and call api
      setScore(score)

      updateClientResponse({
        id: responseId,
        data: { response: { review_score: score } },
      })
        .then((data) => {
          // Update response state
          setScore(data.review_score)
        })
        .catch(() => NotificationManager.error())
    },
    [responseId],
  )

  if (get(issue, 'issuable.type') === IssuableTypes.RESPONSE) {
    return (
      <ResponseSource
        {...rest}
        response={get(issue, 'issuable.response', {})}
        onScoreChange={handleResponseScoreChange}
      />
    )
  }

  return null
}

IssueIssuable.propTypes = {
  issue: PropTypes.object.isRequired,
  extra: PropTypes.object,
  isVendorContext: PropTypes.bool,
  setIssue: PropTypes.func,
}

IssueIssuable.defaultProps = {
  setIssue: () => {},
}

export default IssueIssuable
