import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import isBefore from 'date-fns/isBefore'
import addMinutes from 'date-fns/addMinutes'
import Alert from 'components-v2/molecules/Alert'
import FormField from 'components-v2/molecules/FormField'
import FormDialog from 'components-v2/organisms/FormDialog'
import { toDate } from 'utils/date'
import { ErrorList } from './styles'

const dateControlProps = {
  dateFormat: 'MM/dd/yyyy h:mm aa',
  fullWidth: true,
  showTimeSelect: true,
  todayButton: 'Today',
  minDateTime: new Date(),
  showDurationButtons: true,
}

const UnlockAssessmentModal = ({ assessment, ...rest }) => {
  const dueDateRequired = useMemo(() => {
    const due = toDate(assessment.due_at)
    return !!due && isBefore(due, addMinutes(new Date(), 1))
  }, [assessment])
  const formConfig = useMemo(
    () => ({
      initialValues: {
        dueDate: assessment.extension_date_requested
          ? toDate(assessment.extension_date_requested)
          : '',
      },
      validationSchema: dueDateRequired
        ? yup.object().shape({
            dueDate: yup
              .date()
              .nullable()
              .label('Due date')
              .required('Due date is required')
              .min(new Date(), 'Due date must be in the future'),
          })
        : undefined,
    }),
    [assessment],
  )
  return (
    <FormDialog
      {...rest}
      title={
        <>
          <i className="fa fa-warning" /> Attention
        </>
      }
      submitButtonLabel="Unlock"
      formConfig={formConfig}
    >
      <Alert color="danger">
        <ErrorList>
          This will allow the vendor to change their submitted answers for this
          assessment and re-submit.
        </ErrorList>
      </Alert>
      {dueDateRequired && (
        <FormField
          name="dueDate"
          label="Please select a new Due Date to proceed"
          type={FormField.types.DATE}
          controlProps={dateControlProps}
        />
      )}
    </FormDialog>
  )
}

UnlockAssessmentModal.propTypes = {
  assessment: PropTypes.object.isRequired,
}

export default UnlockAssessmentModal
