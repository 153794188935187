import styled from 'styled-components'
import Dialog from 'components-v2/molecules/Dialog'
import { colors } from 'styles'

export const Body = styled(Dialog.Content)`
  min-height: 300px;
`

export const Footer = styled(Dialog.Footer)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: left;
`

export const FooterOptionSet = styled.div`
  display: flex;
  gap: 20px;
`

export const FooterOption = styled.div`
  display: flex;
  gap: 5px;
`

export const FooterSpacer = styled.div`
  flex-grow: 1;
  min-width: 5px;
`

export const Error = styled.div`
  color: ${colors.DANGER};
`

export const Warning = styled.div`
  color: ${colors.ORANGE};
`

export const NoErrorFound = styled.div`
  color: ${colors.BLUE};
  font-weight: bold;
`
