import React from 'react'
import PropTypes from 'prop-types'
import filter from 'lodash/filter'
import map from 'lodash/map'
import sum from 'lodash/sum'
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from 'recharts'
import { colors } from 'styles'
import { Wrapper, StyledMetric } from './styles'

const SecuredDevices = ({ devices, ...rest }) => {
  const chartData = React.useMemo(() => {
    const data = [
      { name: 'Secure', value: devices.secure, color: colors.GREEN },
      { name: 'N/A', value: devices.unknown, color: colors.DARK_GREY },
      { name: 'Insecure', value: devices.insecure, color: colors.RED },
    ].map((e) => ({
      ...e,
      original: e.value,
    }))
    const filtered = filter(data, (sector) => sector.value)
    const total = sum(map(filtered, 'value'))
    // UI hack: Add some angles to the first and last cells
    filtered[0].value += (5 / 100) * total
    filtered[filtered.length - 1].value += (5 / 100) * total
    return filtered
  }, [devices])
  return (
    <Wrapper {...rest}>
      <StyledMetric>
        {devices.insecure}/{devices.secure}
      </StyledMetric>
      <ResponsiveContainer width="100%" height={50}>
        <PieChart>
          <Pie
            dataKey="value"
            data={chartData}
            innerRadius={70}
            outerRadius={90}
            startAngle={150}
            endAngle={30}
            paddingAngle={2}
            cy={90}
          >
            {chartData.map((entry) => (
              <Cell key={entry.name} fill={entry.color} />
            ))}
          </Pie>
          <Tooltip formatter={(value, name, { payload }) => payload.original} />
        </PieChart>
      </ResponsiveContainer>
    </Wrapper>
  )
}

SecuredDevices.propTypes = {
  devices: PropTypes.object.isRequired,
}

export default React.memo(SecuredDevices)
