import React from 'react'
import { useModal } from 'hooks'
import { useAddClientGlobalAssessmentTemplateFromLibrary } from 'apis'
import SelectGlobalAssessmentTemplateModal from './SelectGlobalAssessmentTemplateModal'
import useDuplicateAssessmentTemplate from '../shared/useDuplicateAssessmentTemplate'

export default function useAddAssessmentTemplateFromLibrary() {
  const { mutateAsync: addClientGlobalAssessmentTemplateFromLibrary } =
    useAddClientGlobalAssessmentTemplateFromLibrary()
  const [openModal, closeModal] = useModal(SelectGlobalAssessmentTemplateModal)
  const duplicateAssessmentTemplate = useDuplicateAssessmentTemplate()

  return React.useCallback(() => {
    openModal({
      onSelect: (assessmentTemplate) => {
        closeModal()
        duplicateAssessmentTemplate({
          title: 'Add Template',
          assessmentTemplate,
          hasAutomations: assessmentTemplate.has_automations,
          api: addClientGlobalAssessmentTemplateFromLibrary,
        })
      },
    })
  }, [openModal, closeModal, duplicateAssessmentTemplate])
}
