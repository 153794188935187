import React from 'react'
import PropTypes from 'prop-types'
import { InfoCircle } from '@styled-icons/fa-solid/InfoCircle'
import { BookOpen } from '@styled-icons/fa-solid/BookOpen'
import { HandHoldingWater } from '@styled-icons/fa-solid/HandHoldingWater'
import { PersonFill } from '@styled-icons/bootstrap/PersonFill'
import Icon from 'components-v2/atoms/Icon'
import Tabs from 'components-v2/molecules/Tabs'
import PageHeader from 'components-v2/organisms/PageHeader'
import LegalTipPopover from 'components/shared/LegalTipPopover'
import General from './General'
import Clients from './Clients'
import LibraryContent from './LibraryContent'
import Requests from './Requests'

const View = ({
  libraryProfile,
  requestableProjects,
  activeTab,
  onTabSelect,
}) => (
  <>
    <PageHeader title={libraryProfile.name} />
    <Tabs activeKey={activeTab} onChange={onTabSelect}>
      <Tabs.Tab
        eventKey="profile"
        title={
          <span>
            <Icon icon={InfoCircle} /> General
          </span>
        }
      >
        <General libraryProfile={libraryProfile} />
      </Tabs.Tab>
      <Tabs.Tab
        eventKey="clients"
        title={
          <span>
            <Icon icon={PersonFill} /> Clients
          </span>
        }
      >
        <Clients />
      </Tabs.Tab>
      <Tabs.Tab
        eventKey="listings"
        title={
          <span>
            <Icon icon={BookOpen} /> Library Content{' '}
            <LegalTipPopover>
              Vendor agrees that all answers provided in the Standardized
              Information Gathering Questionnaire (“SIG Questionnaire”) are
              current and accurate at the time of response and will remain valid
              for twelve (12) months from the date of completion (the “Validated
              Response Period”). Vendor shall be solely responsible for updating
              the SIG Questionnaire, and the responses contained therein, in the
              event of any changes within the Validated Response Period.
            </LegalTipPopover>
          </span>
        }
      >
        <LibraryContent
          profileId={libraryProfile.id}
          requestableProjects={requestableProjects}
        />
      </Tabs.Tab>
      <Tabs.Tab
        eventKey="requests"
        title={
          <span>
            <Icon icon={HandHoldingWater} /> Requests
          </span>
        }
      >
        <Requests />
      </Tabs.Tab>
    </Tabs>
  </>
)

View.propTypes = {
  libraryProfile: PropTypes.object.isRequired,
  requestableProjects: PropTypes.array.isRequired,
  activeTab: PropTypes.string,
  onTabSelect: PropTypes.func.isRequired,
}

export default View
