import React from 'react'
import PropTypes from 'prop-types'
import DateRangeFilter from 'components-v2/molecules/DateRangeFilter'
import InputField from 'components-v2/molecules/FormField/InputField'
import MultiSelectFilter from 'components-v2/molecules/MultiSelectFilter'
import PrintButton from 'components-v2/molecules/PrintButton'
import Table from 'components-v2/molecules/Table'
import Card from 'components-v2/organisms/Card'
import PageHeader from 'components-v2/organisms/PageHeader'
import ContentWithFilterSidebar from 'components-v2/organisms/ContentWithFilterSidebar'
import {
  parseOfacData,
  OFAC_MATCH_LABEL,
  OFAC_NO_MATCH_LABEL,
  OFAC_NO_INFO_LABEL,
} from 'components/client/lib/globorg_integration_metrics'
import { vendorRiskTierFormatterFactory } from 'lib/vendor'
import {
  getMultiSelectFilterSelectedCount,
  formatMultiSelectFilterValue,
} from 'lib/multiSelectFilter'
import { toLocalTimeString } from 'utils/date'
import { RiskTierOptions } from 'constants/index'
import { colors } from 'styles'
import OfacStatusCell from './OfacStatusCell'
import { PrintViewInfo } from './styles'

const datePickerProps = {
  portalId: 'datepicker-portal',
}

const archiveOptions = [{ value: 'include', label: 'Include Archived' }]

const ofacStatus = [
  { value: 'match', label: OFAC_MATCH_LABEL, color: colors.RED },
  { value: 'no_match', label: OFAC_NO_MATCH_LABEL, color: colors.GREEN },
  { value: 'unknown', label: OFAC_NO_INFO_LABEL, color: colors.MED_GREY },
]

const vendorRiskTierFormatter = vendorRiskTierFormatterFactory()

const tableColumns = [
  { id: 'name', header: 'Name' },
  { id: 'risk_tier', header: 'Risk Tier', cell: vendorRiskTierFormatter },
  { id: 'metrics', header: 'Status', cell: renderMatchStatus },
  { id: 'reason', header: 'Reason', accessorFn: renderReason },
]

function renderMatchStatus(props) {
  const parsedResult = parseOfacData(props.getValue()?.ofac)
  return <OfacStatusCell {...parsedResult} />
}

function renderReason(row) {
  const parsedResult = parseOfacData(row?.metrics?.ofac)
  if (parsedResult.value === 'no_match') return '-'
  if (parsedResult.value === 'match')
    return row?.metrics?.ofac?.reasons?.join(', ')
  if (row?.metrics?.globorg_org_id) return 'Public Profile missing information'
  return 'Not linked to Public Profile'
}

export default function OfacStatusView({
  ofacSummary,
  page,
  sizePerPage,
  vendorNameFilter,
  ofacStatusFilterValues,
  includeArchived,
  riskTiers,
  dateRange,
  onPaginationChange,
  onVendorNameFilterChange,
  onOfacStatusFilterValueChange,
  onIncludeArchivedChange,
  onRiskTierChange,
  onDateRangeChange,
}) {
  const multiSelectFilters = [
    {
      uuid: 'archived',
      label: 'Archived',
      value: { selected: includeArchived ? ['include'] : [] },
      options: archiveOptions,
      onChange: ({ selected }) =>
        onIncludeArchivedChange(selected.includes('include')),
      selectAllVisible: false,
    },
    {
      uuid: 'riskTiers',
      label: 'Risk Tiers',
      value: riskTiers,
      options: [...RiskTierOptions, { value: 'none', label: 'None' }],
      onChange: onRiskTierChange,
    },
    {
      uuid: 'ofacStatus',
      label: 'OFAC Status',
      value: ofacStatusFilterValues,
      options: ofacStatus,
      onChange: onOfacStatusFilterValueChange,
      selectAllVisible: false,
    },
  ]
  return (
    <>
      <PageHeader title="OFAC Summary">
        <PrintButton color="primary" />
      </PageHeader>
      <ContentWithFilterSidebar
        filters={
          <>
            <ContentWithFilterSidebar.AccordionItem title="Name Filter">
              <InputField
                type="text"
                value={vendorNameFilter}
                onChange={onVendorNameFilterChange}
                preserveHelpTextSpace={false}
              />
            </ContentWithFilterSidebar.AccordionItem>
            {multiSelectFilters.map(({ uuid, label, value, ...rest }) => (
              <ContentWithFilterSidebar.AccordionItem
                key={uuid}
                uuid={uuid}
                title={label}
              >
                <MultiSelectFilter {...rest} value={value} />
              </ContentWithFilterSidebar.AccordionItem>
            ))}
            <ContentWithFilterSidebar.AccordionItem title="Date Range">
              <DateRangeFilter
                value={dateRange}
                onChange={onDateRangeChange}
                datePickerProps={datePickerProps}
              />
            </ContentWithFilterSidebar.AccordionItem>
          </>
        }
      >
        <Card padding>
          <PrintViewInfo>
            <b>Date Created:</b> {toLocalTimeString(new Date(), 'MM/dd/yyyy')}
            <br />
            {vendorNameFilter && (
              <>
                <b>Name:</b> {vendorNameFilter}
                <br />
              </>
            )}
            {multiSelectFilters.map(({ uuid, value, label, ...rest }) => {
              if (!getMultiSelectFilterSelectedCount(value)) {
                return null
              }
              return (
                <React.Fragment key={uuid}>
                  <b>{label}:</b>&nbsp;
                  {formatMultiSelectFilterValue({ value, ...rest })}
                  <br />
                </React.Fragment>
              )
            })}
            {(dateRange?.startDate || dateRange?.endDate) && (
              <>
                <b>Date Range</b>&nbsp;
                {dateRange?.startDate &&
                  toLocalTimeString(dateRange.startDate, 'MM/dd/yyyy')}{' '}
                ~
                {dateRange?.endDate &&
                  toLocalTimeString(dateRange.endDate, 'MM/dd/yyyy')}
                <br />
              </>
            )}
          </PrintViewInfo>
          <Table
            loading={ofacSummary.isLoading}
            columns={tableColumns}
            data={ofacSummary.data || []}
            totalSize={ofacSummary.totalSize}
            manualPagination
            pagination={{ page, sizePerPage }}
            onPaginationChange={onPaginationChange}
            enableSorting={false}
          />
        </Card>
      </ContentWithFilterSidebar>
    </>
  )
}

OfacStatusView.propTypes = {
  ofacSummary: PropTypes.object,
  page: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  vendorNameFilter: PropTypes.string.isRequired,
  includeArchived: PropTypes.bool.isRequired,
  ofacStatusFilterValues: PropTypes.array.isRequired,
  riskTiers: PropTypes.object.isRequired,
  dateRange: PropTypes.object.isRequired,
  onPaginationChange: PropTypes.func.isRequired,
  onVendorNameFilterChange: PropTypes.func.isRequired,
  onOfacStatusFilterValueChange: PropTypes.func.isRequired,
  onIncludeArchivedChange: PropTypes.func.isRequired,
  onRiskTierChange: PropTypes.func.isRequired,
  onDateRangeChange: PropTypes.func.isRequired,
}
