const AssessmentGradeTypes = {
  PASS: 1,
  FAIL: -1,
  NO_GRADE: 0,
}

export const AssessmentGradeOptions = [
  {
    value: AssessmentGradeTypes.PASS,
    label: 'Pass',
    icon: 'fa fa-check',
  },
  {
    value: AssessmentGradeTypes.FAIL,
    label: 'Fail',
    icon: 'fa fa-times',
  },
  {
    value: AssessmentGradeTypes.NO_GRADE,
    label: 'No Grade',
    icon: 'fa fa-minus',
  },
]

export default AssessmentGradeTypes
