import React, { useEffect, useRef, useCallback } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { useCurrent } from 'hooks'
import Card from './Card'

const portalNodes = {}

const getPortalNode = (group) => {
  // If portal does not exist, let's create
  if (!portalNodes[group]) {
    const domNode = document.createElement('div')
    document.body.appendChild(domNode)
    portalNodes[group] = domNode
  }
  return portalNodes[group]
}

const removePortalNode = (group) => {
  const portalNode = portalNodes[group]
  if (portalNode) {
    document.body.removeChild(portalNode)
    delete portalNodes[group]
  }
}

const ControlledTooltip = ({
  enabled,
  active,
  group,
  parent,
  trigger,
  onToggle,
  closeOnParentClick,
  closeOnTooltipClick,
  ...rest
}) => {
  const portalHoverRef = useRef(false)
  const parentHoverRef = useRef(false)
  const groupRef = useCurrent(group)
  const parentRef = useCurrent(parent)
  const onToggleRef = useCurrent(onToggle)
  const enabledRef = useCurrent(enabled)
  const activeRef = useCurrent(active)
  const closeOnParentClickRef = useCurrent(closeOnParentClick)
  const closeOnTooltipClickRef = useCurrent(closeOnTooltipClick)
  const childElement = parent?.firstElementChild

  // Listen events of the parent DOM element
  useEffect(() => {
    const handleParentMouseEnter = () => {
      parentHoverRef.current = true
      // only toggle active flag to true if we're currently enabled
      onToggleRef.current(enabledRef.current && true)
    }

    const handleParentMouseLeave = () => {
      parentHoverRef.current = false
      if (!portalHoverRef.current) {
        onToggleRef.current(false)
      }
    }

    const handleParentTouchStart = () => {
      parentHoverRef.current = true
      if (!activeRef.current) {
        onToggleRef.current(true)
      } else if (closeOnParentClickRef.current) {
        onToggleRef.current(false)
      }
    }

    if (trigger === 'click') {
      // basically treating a click just like a touch/tap
      parent.addEventListener('click', handleParentTouchStart)
    } else {
      parent.addEventListener('mouseenter', handleParentMouseEnter)
      parent.addEventListener('mouseleave', handleParentMouseLeave)
      parent.addEventListener('mousedown', handleParentTouchStart)

      if (childElement?.tagName === 'BUTTON') {
        childElement.addEventListener('focus', handleParentMouseEnter)
        childElement.addEventListener('blur', handleParentMouseLeave)
      } else {
        parent.addEventListener('focus', handleParentTouchStart)
      }
    }
    parent.addEventListener('touchstart', handleParentTouchStart, {
      passive: true,
    })

    return () => {
      if (trigger === 'click') {
        parent.removeEventListener('click', handleParentTouchStart)
      } else {
        parent.removeEventListener('mouseenter', handleParentMouseEnter)
        parent.removeEventListener('mouseleave', handleParentMouseLeave)
        parent.removeEventListener('mousedown', handleParentTouchStart)

        if (childElement?.tagName === 'BUTTON') {
          childElement.removeEventListener('focus', handleParentTouchStart)
          childElement.removeEventListener('blur', handleParentMouseLeave)
        } else {
          parent.removeEventListener('focus', handleParentTouchStart)
          parent.removeEventListener('blur', handleParentMouseLeave)
        }
      }
      parent.removeEventListener('touchstart', handleParentTouchStart, {
        passive: true,
      })
    }
  }, [parent, trigger, childElement])

  // Listen event touching outside of the parent DOM and tooltip portal node
  useEffect(() => {
    const handleOutsideTouchStart = (e) => {
      const portalNode = getPortalNode(groupRef.current)
      let currentNode = e.target
      while (currentNode.parentNode) {
        if (
          currentNode === parentRef.current ||
          currentNode === portalNode ||
          currentNode === portalNode.domNode
        ) {
          return
        }
        currentNode = currentNode.parentNode
      }
      if (currentNode !== document) {
        return
      }
      portalHoverRef.current = false
      parentHoverRef.current = false
      onToggleRef.current(false)
    }

    if (!active) {
      return undefined
    }

    document.addEventListener('touchstart', handleOutsideTouchStart, {
      passive: true,
    })
    if (trigger === 'click') {
      document.addEventListener('click', handleOutsideTouchStart)
    }
    return () => {
      document.removeEventListener('touchstart', handleOutsideTouchStart, {
        passive: true,
      })
      if (trigger === 'click') {
        document.removeEventListener('click', handleOutsideTouchStart)
      }
    }
  }, [active])

  // Make sure to remove portal node when unmounting
  useEffect(
    () => () => {
      removePortalNode(group)
    },
    [group],
  )

  const handlePortalClick = useCallback(() => {
    if (closeOnTooltipClickRef.current) {
      onToggleRef.current(false)
    }
  }, [])

  const handlePortalMouseEnter = useCallback(() => {
    portalHoverRef.current = true
    onToggleRef.current(true)
  }, [])

  const handlePortalMouseLeave = useCallback(() => {
    portalHoverRef.current = false
    if (!parentHoverRef.current) {
      onToggleRef.current(false)
    }
  }, [])

  const handlePortalTouchStart = useCallback(() => {
    portalHoverRef.current = true
    if (closeOnTooltipClickRef.current) {
      onToggleRef.current(false)
    }
  }, [])

  if (!enabled || !active) {
    return null
  }

  const portalNode = getPortalNode(group)
  return ReactDOM.createPortal(
    <Card
      {...rest}
      parent={parent}
      onClick={handlePortalClick}
      onMouseEnter={handlePortalMouseEnter}
      onMouseLeave={handlePortalMouseLeave}
      onTouchStart={handlePortalTouchStart}
    />,
    portalNode,
  )
}

ControlledTooltip.propTypes = {
  ...Card.propTypes,
  enabled: PropTypes.bool,
  active: PropTypes.bool,
  group: PropTypes.string,
  trigger: PropTypes.oneOf(['hover', 'click']),
  onToggle: PropTypes.func,
  closeOnParentClick: PropTypes.bool,
  closeOnTooltipClick: PropTypes.bool,
}

ControlledTooltip.defaultProps = {
  enabled: true,
  active: false,
  group: 'main',
  trigger: 'hover',
  onToggle: () => {},
  closeOnParentClick: true,
  closeOnTooltipClick: false,
}

export default ControlledTooltip
