import tinycolor from 'tinycolor2'

export const getLightness = (input) => {
  const color = tinycolor(input)

  if (!color.isValid()) {
    return null
  }

  const rgb = color.toRgb()
  const rgbArray = [rgb.r, rgb.g, rgb.b]

  // Get the highest and lowest out of red, green and blue
  const highest = Math.max(...rgbArray)
  const lowest = Math.min(...rgbArray)

  // Return the average divided by 255
  return (highest + lowest) / 2 / 255
}
