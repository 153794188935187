import styled from 'styled-components'
import Well from 'components-v2/atoms/Well'
import Button from 'components-v2/atoms/Button'
import Textarea from 'components-v2/atoms/Textarea'

export const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin: 0 -4px 16px -4px;

  & > * {
    margin-left: 4px;
    margin-right: 4px;
  }
`

export const ActionWrapper = styled.div`
  > * {
    margin-right: 10px;
  }
`

export const RoundedButton = styled(Button)`
  border-radius: 100px;
`

export const ContentWrapper = styled(Well)`
  margin-bottom: 0;
`

export const StyledTextarea = styled(Textarea)`
  overflow-x: hidden;
  flex: 1;
`

export const CheckboxCol = styled.div`
  width: 70px;
  display: flex;
  justify-content: center;
`

export const HeadRow = styled(RowWrapper)`
  justify-content: flex-end;
`
