import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from 'components-v2/molecules/Tooltip'
import { AssessmentStatusTypes } from 'constants/index'
import { getRiskRatingLabel, getRiskRatingColor } from 'lib/assessment'
import Metric from '../Metric'
import { Grade, Status } from './styles'

const PrivvaAssessmentMetric = ({ assessment }) => {
  if (
    [AssessmentStatusTypes.SUBMITTED, AssessmentStatusTypes.REVIEWED].includes(
      assessment.status,
    )
  ) {
    const ratingLabel = (
      <Metric color={getRiskRatingColor(assessment.risk_rating)}>
        {getRiskRatingLabel(
          assessment.risk_rating,
          'N/A',
          assessment.status === AssessmentStatusTypes.SUBMITTED,
        )}
      </Metric>
    )
    return (
      <div>
        {assessment.status === AssessmentStatusTypes.SUBMITTED ? (
          <Tooltip parent={ratingLabel}>
            Score is subject to change as assessment is reviewed.
          </Tooltip>
        ) : (
          ratingLabel
        )}
        <Grade grade={assessment.grade} />
      </div>
    )
  }
  return <Status>{assessment.humanized_status}</Status>
}

PrivvaAssessmentMetric.propTypes = {
  assessment: PropTypes.object.isRequired,
}

export default PrivvaAssessmentMetric
