import styled from 'styled-components'
import Input from 'components-v2/atoms/Input'
import Popover from 'components-v2/molecules/Popover'
import { Card } from 'components-v2/molecules/Popover/PopoverPanel'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  border-collapse: collapse;
`

export const StyledInput = styled(Input)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`

export const ColorIndicator = styled.div`
  display: inline-block;
  width: 16px;
  &:before {
    content: '';
    width: 16px;
    height: 16px;
    background: ${({ color }) => color};
    position: absolute;
    top: 50%;
    margin-left: -8px;
    margin-top: -8px;
  }
`

export const StyledPopoverPanel = styled(Popover.Panel)`
  & > div {
    border: none;
    box-shadow: 0 1px 2px rgb(0 0 0 / 3%), 0 2px 4px rgb(0 0 0 / 3%),
      0 4px 8px rgb(0 0 0 / 3%), 0 8px 16px rgb(0 0 0 / 3%),
      0 16px 32px rgb(0 0 0 / 3%), 0 32px 64px rgb(0 0 0 / 3%);
    border-radius: 9px;
  }

  ${Card} {
    border-radius: 9px;
  }
`

export const PopoverButton = styled.button`
  position: relative;
  width: 38px;
  height: 38px;
  padding: 0;
  border: 1px solid #ccc;
  border-left: 0;
  border-radius: 0 4px 4px 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  background-color: #e6e6e6 !important;

  &:hover,
  &:active,
  &:focus {
    background-color: #e6e6e6 !important;
    border-color: #ccc;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  }

  ${({ active }) =>
    active &&
    `
    background-color: #e6e6e6 !important;
    border-color: #ccc;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  `}
`
