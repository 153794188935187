import styled from 'styled-components'

export const Table = styled.div`
  & > *:not(:first-child) {
    border-top: 1px solid #ddd;
  }
`

export const Row = styled.div`
  display: flex;
  position: relative;

  > * {
    padding: 8px;
  }
`

export const DragSourceColumn = styled.div`
  flex: none;
  width: ${(p) => (p.customKey ? 140 : 82)}px;
  display: flex;
  align-items: flex-start;
  ${({ draggable }) => draggable && `cursor: move;`}
`

export const Key = styled.div`
  margin-left: 20px;
  white-space: nowrap;
`

export const QuestionColumn = styled.div`
  flex: 1;
  white-space: pre-wrap;
`

export const WeightColumn = styled.div`
  flex: none;
  width: 100px;
  text-align: center;
`

export const CheckboxColumn = styled.div`
  flex: none;
  width: 36px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 11px;
`

export const IconColumn = styled.div`
  flex: none;
  width: 30px;
  text-align: center;
`

export const IconButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
`
