import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import { Times } from '@styled-icons/fa-solid/Times'
import { List, Item, FileIcon, RemoveButton } from './styles'

const FileList = ({ files, disabled, onRemove, ...rest }) => (
  <List {...rest}>
    {map(files, (file) => (
      <Item key={file.id}>
        <a href={file.url} target="_blank">
          <FileIcon />
          <span className="no-lang">{file.name}</span>
        </a>
        {!disabled && (
          <RemoveButton icon={Times} onClick={() => onRemove(file.id)} />
        )}
      </Item>
    ))}
  </List>
)

FileList.propTypes = {
  files: PropTypes.array,
  disabled: PropTypes.bool,
  onRemove: PropTypes.func,
}

export default FileList
