import styled, { css, keyframes } from 'styled-components'
import memoize from 'lodash/memoize'
import Button from 'components-v2/atoms/Button'
import Card from 'components-v2/organisms/Card'
import { colors, fonts, mixins } from 'styles'

export const Header = styled(Card.Segment)`
  position: relative;
  display: flex;
  align-items: center;
`

export const Title = styled.h4`
  flex: 1;
  margin: 0 7px 0 0;
  ${mixins.truncate}
`

export const Body = styled(Card.Segment)`
  padding-top: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Timeline = styled.div`
  display: flex;
  flex: 1;
  max-width: 210px;
  margin: 0 -2px;
  ${mixins.clearfix}
`

const fadeInItem = keyframes`
  0% { display: block; opacity: 0; }
  30% { display: block; opacity: .5; }
  60% { display: block; opacity: .8; }
  100% { display: block; opacity: 1; }
`

export const TItem = styled.div`
  flex: 1;
  width: 14%;
  padding: 0 2px;
  opacity: 0;
  animation: ${fadeInItem} 1s ${(props) => props.index * 0.15 + 0.1}s ease-out
    forwards;
`

export const Day = styled.div`
  margin-bottom: 5px;
  color: ${(props) => (props.today ? colors.DARK_GREY : colors.GREY)};
  font-size: 8px;
  font-weight: bold;
  font-family: ${fonts.SECONDARY};
  text-transform: uppercase;
  text-align: center;
`

const getMetricColor = memoize((metric) => {
  if (metric === 1) {
    return colors.GREEN
  }
  if (metric === 0) {
    return colors.RED
  }
  return colors.YELLOW
})

export const Bar = styled.div`
  background: ${(props) => getMetricColor(props.value)};
  height: 36px;
  border-radius: 2px;
`

export const Logo = styled.img`
  margin-top: 10px;
  margin-left: 20px;
  width: 90px;
  height: auto;
`

export const ConnectButton = styled(Button)`
  background-color: ${colors.GREY};
  color: #fff;
  ${mixins.smallHeader}
  letter-spacing: 0.05em;
  margin-left: 10px;
  height: 36px;
  align-self: flex-end;

  &:hover,
  &:focus,
  &:active {
    color: #fff;
    background-color: ${colors.DARK_GREY} !important;
  }
`

export const Wrapper = styled(Card)`
  ${(props) =>
    props.disabled &&
    css`
      border: 2px dotted ${colors.GREY};
      background: transparent;
      box-shadow: none;

      ${Header} {
        border-bottom-color: #e6eaef;
        margin-left: -2px;
        margin-right: -2px;
        margin-top: -2px;
      }

      ${Title} {
        color: ${colors.GREY};
      }

      ${Body} {
        margin-left: -2px;
        margin-right: -2px;
        margin-bottom: -2px;
      }

      ${Day} {
        color: ${colors.GREY};
      }

      ${Bar} {
        background-color: ${colors.GREY};
        opacity: 0.2;
      }

      ${TItem} {
        opacity: 1;
        animation: none;
      }
    `}
`

export const ObjectiveStatus = styled.span`
  margin-top: 16px;
  width: 90px;
  color: ${colors.GREY};
  font-size: 15px;
  font-weight: 500;
  text-align: center;
`
