const LibraryShareStatusTypes = {
  DRAFT: 'draft',
  REQUESTED: 'requested',
  INVITED: 'invited',
  ACTIVE: 'active',
  DENIED: 'denied',
  EXPIRED: 'expired',
}

export default LibraryShareStatusTypes
