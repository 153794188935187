import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import Menu from 'components-v2/molecules/Menu'
import NotificationManager from 'lib/notifications'
import { useUpdateAssistAssessment } from 'apis'
import {
  AssistAssessmentStatusTypes,
  AssistAssessmentTransitionTypes,
} from 'constants/index'
import useUploadCompletedAssessment from './useUploadCompletedAssessment'
import { EllipsisIconButton } from './styles'

const AdminActionButton = ({ id, status, completeAssessment }) => {
  const { mutateAsync: updateAssistAssessment } = useUpdateAssistAssessment()

  const transit = useCallback(
    (transition) => {
      updateAssistAssessment({
        id,
        data: {
          assessment: { transition },
        },
      }).catch((error) => {
        NotificationManager.error()
        console.error(error)
      })
    },
    [id],
  )

  const onUploadSuccess = useCallback(
    (data) => {
      if (data.status === AssistAssessmentStatusTypes.IN_PROCESS) {
        transit(AssistAssessmentTransitionTypes.START_REVIEW)
      }
      if (data.status === AssistAssessmentStatusTypes.REVIEWED) {
        transit(AssistAssessmentTransitionTypes.COMPLETE)
      }
    },
    [transit],
  )
  const uploadCompletedAssessment = useUploadCompletedAssessment(
    id,
    onUploadSuccess,
  )

  const items = [
    status === AssistAssessmentStatusTypes.UPLOADED && {
      children: 'Accept',
      onClick: () => transit(AssistAssessmentTransitionTypes.ACCEPT),
    },
    status === AssistAssessmentStatusTypes.IN_PROCESS && {
      children: 'Upload Assessment for review',
      onClick: uploadCompletedAssessment,
    },
    [
      AssistAssessmentStatusTypes.IN_REVIEW,
      AssistAssessmentStatusTypes.REVIEWED,
      AssistAssessmentStatusTypes.COMPLETED,
    ].includes(status) && {
      children: 'In Process',
      onClick: () =>
        transit(AssistAssessmentTransitionTypes.CONTINUE_PROCESSING),
    },
    status === AssistAssessmentStatusTypes.IN_REVIEW && {
      children: 'Complete Review',
      onClick: () => transit(AssistAssessmentTransitionTypes.COMPLETE_REVIEW),
    },
    [
      AssistAssessmentStatusTypes.IN_REVIEW,
      AssistAssessmentStatusTypes.REVIEWED,
      AssistAssessmentStatusTypes.COMPLETED,
    ].includes(status) && {
      children: 'Upload Completed Assessment',
      onClick: uploadCompletedAssessment,
    },
    [
      AssistAssessmentStatusTypes.IN_REVIEW,
      AssistAssessmentStatusTypes.REVIEWED,
      AssistAssessmentStatusTypes.COMPLETED,
    ].includes(status) &&
      completeAssessment?.url && {
        children: 'Download',
        href: completeAssessment.url,
        target: '_blank',
      },
  ].filter((e) => e)

  if (items.length === 0) {
    return null
  }

  return (
    <Menu placement="top-end" offset={8} portal>
      <Menu.Button as={EllipsisIconButton} />
      <Menu.Items size="small" arrow>
        {items.map((e, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Menu.Item key={index} {...e} />
        ))}
      </Menu.Items>
    </Menu>
  )
}

AdminActionButton.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  completeAssessment: PropTypes.object,
}

export default AdminActionButton
