import styled from 'styled-components'

export const Table = styled.table`
  td {
    vertical-align: middle !important;
  }

  td:nth-child(1) {
    width: 60%;
  }

  td:nth-child(2),
  td:nth-child(3) {
    width: 20%;
  }

  td:nth-child(2):last-child {
    width: 40%;
  }
`

export const InlineFlex = styled.div`
  display: inline-flex;
  align-items: center;
`
