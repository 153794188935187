/**
 * Add createIssue, createResponseIssue, and updateIssue APIs to an wrapped component
 */

import React from 'react'
import addDays from 'date-fns/addDays'
import some from 'lodash/some'
import map from 'lodash/map'
import get from 'lodash/get'
import defaults from 'lodash/defaults'
import IssueModal from 'components/client/shared/IssueModal'
import NotificationManager from 'lib/notifications'
import { useModal, useGlobalLoader, useCurrent } from 'hooks'
import {
  useClientVendorNamesFetcher,
  useClientIssueCategoriesFetcher,
  useCreateClientIssue,
  useCreateClientIssueCategory,
  useCreateClientResponseIssue,
  useUpdateClientIssue,
} from 'apis'
import { toDate } from 'utils/date'
import objectToFormData from 'utils/object_to_formdata'
import { IssuePriorityTypes, IssueStatusTypes } from 'constants/index'

const getDefaultValues = () => ({
  priority: IssuePriorityTypes.INFORMATIONAL,
  due_at: addDays(new Date(), 7),
})

const convertFormDataToIssueAPIPayload = ({ description, files, ...rest }) => {
  const payload = {
    ...rest,
  }
  if (description) {
    payload.description = description.body
    payload.formatted = description.formatted
  }
  if (files) {
    payload.attachments_attributes = map(files, (file) => ({ file }))
  }
  return payload
}

export default function useCreateUpdateIssue() {
  const { mutateAsync: createClientIssue } = useCreateClientIssue()
  const { mutateAsync: createClientIssueCategory } =
    useCreateClientIssueCategory()
  const { mutateAsync: createClientResponseIssue } =
    useCreateClientResponseIssue()
  const { mutateAsync: updateClientIssue } = useUpdateClientIssue()
  const [openIssueModal, closeIssueModal, updateIssueModal] =
    useModal(IssueModal)
  const [showLoader, hideLoader] = useGlobalLoader()

  const [needVendorNames, setNeedVendorNames] = React.useState(false)
  const { data: vendorNames, isLoading: vendorNamesLoading } =
    useClientVendorNamesFetcher({}, { enabled: needVendorNames })
  const [needIssueCategories, setNeedIssueCategories] = React.useState(false)
  const { data: issueCategories, isLoading: issueCategoriesLoading } =
    useClientIssueCategoriesFetcher({}, { enabled: needIssueCategories })
  const issueCategoriesRef = useCurrent(issueCategories)

  React.useEffect(() => {
    updateIssueModal({
      vendorNames,
      vendorNamesLoading,
      issueCategories,
      issueCategoriesLoading,
    })
  }, [vendorNames, vendorNamesLoading, issueCategories, issueCategoriesLoading])

  const createIssueCategoryOrNoop = React.useCallback(
    (formData, onIssueCategoryCreated) => {
      // If new category is created via form UI, let's save it to back-end
      const issueCategoryId = formData.issue_category_id
      if (
        issueCategoryId &&
        !some(issueCategoriesRef.current, { id: issueCategoryId })
      ) {
        return createClientIssueCategory({
          data: { name: issueCategoryId },
        }).then((newCategory) => {
          onIssueCategoryCreated(newCategory)
          // Return formData with new issue_category_id value
          return {
            ...formData,
            issue_category_id: newCategory.id,
          }
        })
      }

      return Promise.resolve(formData)
    },
    [],
  )

  const createIssue = React.useCallback(
    ({
      initialValues,
      vendorId,
      disabledFields = [],
      onIssueCreated = () => {},
      onIssueCategoryCreated = () => {},
    }) => {
      setNeedVendorNames(true)
      setNeedIssueCategories(true)

      const onSubmit = (formData, { setSubmitting }) => {
        const loaderId = showLoader()

        // Save issue to back-end
        createIssueCategoryOrNoop(formData, onIssueCategoryCreated)
          .then((data) => {
            const issue = {
              ...convertFormDataToIssueAPIPayload(data),
              issuable_type: 'Organization',
              issuable_id: data.vendor_id,
            }
            return createClientIssue({ data: objectToFormData({ issue }) })
          })
          .then((data) => {
            onIssueCreated(data)
            NotificationManager.success(
              'An issue has been successfully created.',
            )
            closeIssueModal()
          })
          .catch((error) => {
            console.error(error)
            NotificationManager.error()
            setSubmitting(false)
          })
          .finally(() => hideLoader(loaderId))
      }

      openIssueModal({
        title: 'Create Issue',
        vendorNames,
        vendorNamesLoading,
        issueCategories,
        issueCategoriesLoading,
        initialFormData: defaults(
          {},
          { vendor_id: vendorId },
          initialValues,
          getDefaultValues(),
        ),
        disabledFields: vendorId
          ? [...disabledFields, 'vendor_id']
          : disabledFields,
        hiddenFields: ['resolution'],
        onSubmit,
      })
    },
    [
      openIssueModal,
      closeIssueModal,
      showLoader,
      hideLoader,
      createIssueCategoryOrNoop,
      vendorNames,
      vendorNamesLoading,
      issueCategories,
      issueCategoriesLoading,
    ],
  )

  const createResponseIssue = React.useCallback(
    ({
      initialValues,
      vendorId,
      responseId,
      disabledFields = [],
      onIssueCreated = () => {},
      onIssueCategoryCreated = () => {},
    }) => {
      setNeedIssueCategories(true)

      const onSubmit = (formData, { setSubmitting }) => {
        const loaderId = showLoader()

        // Save issue to back-end
        createIssueCategoryOrNoop(formData, onIssueCategoryCreated)
          .then((data) => {
            const issue = {
              ...convertFormDataToIssueAPIPayload(data),
              vendor_id: vendorId,
            }
            return createClientResponseIssue({
              responseId,
              data: objectToFormData({ issue }),
            })
          })
          .then((data) => {
            onIssueCreated(data)
            NotificationManager.success(
              'An issue has been successfully created.',
            )
            closeIssueModal()
          })
          .catch((error) => {
            console.error(error)
            NotificationManager.error()
            setSubmitting(false)
          })
          .finally(() => hideLoader(loaderId))
      }

      openIssueModal({
        title: 'Create Issue',
        issueCategories,
        issueCategoriesLoading,
        initialFormData: defaults({}, initialValues, getDefaultValues()),
        disabledFields,
        hiddenFields: ['resolution', 'vendor_id'],
        onSubmit,
      })
    },
    [
      openIssueModal,
      closeIssueModal,
      showLoader,
      hideLoader,
      createIssueCategoryOrNoop,
      issueCategories,
      issueCategoriesLoading,
    ],
  )

  const updateIssue = React.useCallback(
    ({
      issue,
      disabledFields,
      onIssueUpdated = () => {},
      onIssueCategoryCreated = () => {},
    }) => {
      setNeedIssueCategories(true)

      const onSubmit = (formData, { setSubmitting }) => {
        const loaderId = showLoader()

        // Save issue to back-end
        createIssueCategoryOrNoop(formData, onIssueCategoryCreated)
          .then((data) =>
            updateClientIssue({
              id: issue.id,
              data: {
                issue: convertFormDataToIssueAPIPayload(data),
              },
            }),
          )
          .then((data) => {
            onIssueUpdated(data)
            NotificationManager.success(
              'An issue has been successfully updated.',
            )
            closeIssueModal()
          })
          .catch((error) => {
            console.error(error)
            NotificationManager.error()
            setSubmitting(false)
          })
          .finally(() => hideLoader(loaderId))
      }

      const hiddenFields = [
        IssueStatusTypes.CLOSED,
        IssueStatusTypes.ARCHIVED,
      ].includes(issue.status)
        ? ['vendor_id', 'files']
        : ['vendor_id', 'files', 'resolution']

      const initialFormData = {
        ...issue,
        description: {
          body: issue.description,
          formatted: issue.formatted,
        },
        due_at: toDate(issue.due_at),
        issue_category_id: get(issue, 'issue_category.id'),
      }

      openIssueModal({
        title: 'Edit Issue',
        issueCategories,
        issueCategoriesLoading,
        initialFormData,
        disabledFields,
        hiddenFields,
        onSubmit,
      })
    },
    [
      openIssueModal,
      closeIssueModal,
      showLoader,
      hideLoader,
      createIssueCategoryOrNoop,
      issueCategories,
      issueCategoriesLoading,
    ],
  )

  return { createIssue, createResponseIssue, updateIssue }
}
