import { useCallback } from 'react'
import mapValues from 'lodash/mapValues'
import pickBy from 'lodash/pickBy'
import some from 'lodash/some'
import { useModal, useConfirm, useCurrent } from 'hooks'
import { useMultiUpdateClientAssessmentTemplateElements } from 'apis'
import CustomKeysModal from './CustomKeysModal'
import useDefaultAsyncActionHandler from './useDefaultAsyncActionHandler'
import useUpdateCustomKeysAbility from './useUpdateCustomKeysAbility'
import { sortElements } from './utils'

// Callback when click "Use Custom Keys" button
export default function useEditCustomKeys(assessmentTemplate) {
  const { mutateAsync: multiUpdateClientAssessmentTemplateElements } =
    useMultiUpdateClientAssessmentTemplateElements()
  const assessmentTemplateRef = useCurrent(assessmentTemplate)
  const [openCustomKeysModal, closeCustomKeysModal] = useModal(CustomKeysModal)
  const openConfirm = useConfirm()
  const handleAsyncAction = useDefaultAsyncActionHandler()
  const updateCustomKeysAbility = useUpdateCustomKeysAbility(
    assessmentTemplate.id,
  )

  return useCallback(() => {
    const saveCustomKeys = (formData) => {
      const filtered = pickBy(
        formData,
        (customKey, id) =>
          !some(assessmentTemplateRef.current.elements, {
            id,
            custom_key: customKey,
          }),
      )
      return multiUpdateClientAssessmentTemplateElements({
        id: assessmentTemplateRef.current.id,
        data: {
          elements: mapValues(filtered, (v) => ({ custom_key: v })),
        },
      })
    }

    const customhandleAsyncAction = (promise, message) => {
      const newPromise = promise.then(() => closeCustomKeysModal())
      return handleAsyncAction(newPromise, message)
    }

    const enabled = assessmentTemplateRef.current.use_custom_keys
    openCustomKeysModal({
      elements: sortElements(assessmentTemplateRef.current.elements),
      enabled,
      editable: !assessmentTemplateRef.current.readonly,
      onSave: async (formData) => {
        try {
          if (enabled) {
            const confirmed = await openConfirm(
              {
                title: 'Updating Custom Keys',
                body: 'Custom keys are enabled. Updating custom keys will disable them. Are you sure you wish to update custom keys?',
              },
              true,
            )

            if (!confirmed) {
              return
            }

            const promise = updateCustomKeysAbility(false).then(() =>
              saveCustomKeys(formData),
            )
            await customhandleAsyncAction(
              promise,
              'Custom keys have been successfully set and disabled.',
            )
          } else {
            await customhandleAsyncAction(
              saveCustomKeys(formData),
              'Custom keys have been successfully set.',
            )
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log(err)
        }
      },
      onSaveAndEnable: async (formData) => {
        const promise = saveCustomKeys(formData).then(() =>
          updateCustomKeysAbility(true),
        )
        await customhandleAsyncAction(
          promise,
          'Custom keys have been successfully set and enabled.',
        )
      },
      onDisable: () => {
        customhandleAsyncAction(
          updateCustomKeysAbility(false),
          'Custom keys have been successfully disabled.',
        ).catch(() => {})
      },
    })
  }, [
    openConfirm,
    handleAsyncAction,
    updateCustomKeysAbility,
    openCustomKeysModal,
    closeCustomKeysModal,
  ])
}
