import React from 'react'
import get from 'lodash/get'
import has from 'lodash/has'
import isEmpty from 'lodash/isEmpty'

export const AuthorizationContext = React.createContext()

export const useAuthManager = () => React.useContext(AuthorizationContext)

export default class AuthManager {
  constructor(policy) {
    this.userPolicy = policy
  }

  userRole() {
    return this.userPolicy.orgRole
  }

  userCan(action, subject) {
    if (this.userPolicy === undefined) {
      throw new Error(
        'AuthManager and dependent hooks cannot be used unless the top-level component has been wrapped with either the authorize or withAuthorize HOC',
      )
    } else if (isEmpty(this.userPolicy)) {
      // if a policy is defined and applied the default is typically false,
      // but if we're given an empty policy we have to assume the server
      // controller isn't yet set up to pass one to us; default to manager
      // role, which means default true
      return true
    }

    let { policy } = this.userPolicy.main
    let subjectPolicy
    if (subject) {
      const klass = subject.class || subject
      const key = has(this.userPolicy, [klass, subject.id])
        ? subject.id
        : get(this.userPolicy, [klass, 'default_key'])
      subjectPolicy = get(this.userPolicy, [klass, key, 'policy'])
    }

    if (subjectPolicy) {
      policy = subjectPolicy
    }

    return has(policy, action)
      ? get(policy, [action], false)
      : get(policy, ['__default__'], false)
  }
}
