import styled from 'styled-components'
import FormField from 'components-v2/molecules/FormField'

export const SelectedElementList = styled.ul`
  margin: 10px 0 0 0;
  list-style: none;

  li {
    margin: 5px 0;
  }
`

export const FieldRow = styled.div`
  display: flex;
  align-items: flex-start;

  > * {
    flex: 1;
  }
`

export const SmallWidthFormField = styled(FormField)`
  flex: none;
  width: 130px;
  margin-right: 15px;
`
