import React from 'react'
import PropTypes from 'prop-types'
import { colors } from 'styles'
import { Label, Indicator, Input } from './styles'

const Checkbox = ({
  label,
  color,
  disabled,
  inline,
  className,
  style,
  ...rest
}) => (
  <Label
    color={color}
    disabled={disabled}
    inline={inline}
    className={className}
    style={style}
    hasLabel={!!label}
  >
    <Input {...rest} type="checkbox" disabled={disabled} />
    <Indicator />
    {label}
  </Label>
)

Checkbox.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]),
  color: PropTypes.string,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
}

Checkbox.defaultProps = {
  color: colors.PRIMARY,
}

export default React.memo(Checkbox)
