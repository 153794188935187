import styled from 'styled-components'

export const CenterAlignedFlex = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 6px;
`

export const Gap = styled.div`
  flex: 1;
`

export const SmallText = styled.div`
  padding: 3px 20px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #777777;
  white-space: nowrap;
`
