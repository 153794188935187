import styled from 'styled-components'
import { ExclamationTriangle } from '@styled-icons/fa-solid/ExclamationTriangle'
import { Check } from '@styled-icons/fa-solid/Check'
import Icon from 'components-v2/atoms/Icon'
import { colors, fonts } from 'styles'

export const TooltipTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.GREY};
  padding-bottom: 5px;
  margin-bottom: 10px;
`

export const TooltipTitle = styled.h5`
  font-family: ${fonts.SECONDARY};
  font-size: 15px;
  color: ${colors.HEADING};
  margin: 0;
`

export const WarningIcon = styled(Icon).attrs({ icon: ExclamationTriangle })`
  color: #333;
`

export const CheckIcon = styled(Icon).attrs({ icon: Check })`
  color: #333;
`

export const PanelContent = styled.div`
  padding: 10px 14px 20px;
  width: 490px;
`
