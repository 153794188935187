import styled, { css } from 'styled-components'
import { colors } from 'styles'

export const padding = css`
  padding: 22px 24px 20px;
`

const Segment = styled.div`
  ${(props) => props.padding && padding}
  border-bottom: 1px solid ${colors.LIGHT_GREY};
  &:last-child {
    border-bottom: none;
  }
`

Segment.defaultProps = {
  padding: true,
}

export default Segment
