import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Input, { errorStyles, smallStyles } from 'components-v2/atoms/Input'
import Textarea from 'components-v2/atoms/Textarea'
import Select from 'components-v2/atoms/Select'
import ControlLabel from './ControlLabel'
import ErrorMessage from './ErrorMessage'

const Wrapper = styled.div`
  margin-bottom: 0;
  padding-bottom: ${({ $showError, $preserveHelpTextSpace }) =>
    !$preserveHelpTextSpace || $showError ? 0 : 25}px;

  ${(props) =>
    (props.horizontal || props.theme.formFieldHorizontalLayout) &&
    css`
      display: flex;

      & > ${ControlLabel.Wrapper} {
        display: block;
        width: ${props.theme.formFieldHorizontalLabelWidth || '30%'};
        flex: none;
        padding-right: 20px;
        margin: 6px 0;
      }
    `}

  ${({ size }) =>
    ['sm', 'small'].includes(size) &&
    `
    font-size: 14px;
    ${Input}, ${Textarea}, ${Select} {
      ${smallStyles}
    }
  `}

  ${({ $showError }) =>
    $showError &&
    `
    ${Input}, ${Textarea}, ${Select} {
      ${errorStyles}
    }
  `}
`

const Right = styled.div`
  flex: 1;
  min-width: 0;
  align-self: center;
`

const BaseField = ({
  label,
  controlId,
  helpMessage,
  showError,
  errorMessage,
  children,
  renderControlLabel: RenderControlLabel,
  renderErrorMessage: RenderErrorMessage,
  preserveHelpTextSpace,
  ...rest
}) => (
  <Wrapper
    {...rest}
    controlId={controlId}
    $showError={showError}
    $preserveHelpTextSpace={preserveHelpTextSpace}
  >
    <RenderControlLabel helpMessage={helpMessage} htmlFor={controlId}>
      {label}
    </RenderControlLabel>
    <Right>
      {children}
      <RenderErrorMessage error={errorMessage} />
    </Right>
  </Wrapper>
)

BaseField.propTypes = {
  label: PropTypes.node,
  controlId: PropTypes.string,
  helpMessage: PropTypes.node,
  showError: PropTypes.bool,
  errorMessage: PropTypes.any,
  preserveHelpTextSpace: PropTypes.bool,
  renderControlLabel: PropTypes.elementType,
  renderErrorMessage: PropTypes.elementType,
  children: PropTypes.node,
}

BaseField.defaultProps = {
  renderControlLabel: ControlLabel,
  renderErrorMessage: ErrorMessage,
  preserveHelpTextSpace: true,
}

export default BaseField
