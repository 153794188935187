import React from 'react'
import PropTypes from 'prop-types'
import difference from 'lodash/difference'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import pickBy from 'lodash/pickBy'
import sortBy from 'lodash/sortBy'
import { useClientCustomFieldDefinitionsFetcher } from 'apis'
import useRequestVendor from 'components/client/shared/useRequestVendor'
import VendorFieldGroups from 'components/client/vendor_info_view/constants'
import { useSimpleFormDialog } from 'hooks'
import View from './View'
import { updateOrgSettings } from './utils'
import { additionalInfoGroups } from './constants'
import { buildGroupsTreeOrdered } from '../../../../lib/custom_fields'

const buildFieldGroupOptions = (groupDefs) =>
  sortBy(
    map(groupDefs, (v, k) => ({
      label: v.name,
      value: k,
      sort: v.sort,
    })),
    'sort',
  )

const VendorRequestSettings = ({ organization }) => {
  const requestVendor = useRequestVendor()
  const [enableRequests, setEnableRequests] = React.useState(true)
  const [introNote, setIntroNote] = React.useState(null)
  const [customGroupOptions, setCustomGroupOptions] = React.useState([])
  const [standardGroupOptions, setStandardGroupOptions] = React.useState([])
  const [fieldGroupsConfig, setFieldGroupsConfig] = React.useState({})
  const [enableCalc, setEnableCalc] = React.useState(true)
  const [enableUploads, setEnableUploads] = React.useState(true)
  const [openGroupModal, closeGroupModal, addGroupFields] =
    useSimpleFormDialog('Select Fields')

  const { data: customFieldDefinitions } =
    useClientCustomFieldDefinitionsFetcher()
  const customFieldsTree = React.useMemo(() => {
    if (!customFieldDefinitions) {
      return {}
    }
    const revisedDefs = map(
      customFieldDefinitions.field_definitions,
      (def) => ({
        value: def.key,
        label: def.name,
        sort: def.render_opts.sort,
        render_opts: def.render_opts,
      }),
    )
    return buildGroupsTreeOrdered(
      revisedDefs,
      customFieldDefinitions.groups.Organization,
    )
  }, [customFieldDefinitions])

  React.useEffect(() => {
    setEnableRequests(organization.enable_vendor_requests)
    setIntroNote(organization.vendor_request_intro_note)
    setEnableCalc(organization.vendor_request_risk_calculator_enabled)
    setEnableUploads(organization.vendor_request_allow_upload)
    setCustomGroupOptions(
      buildFieldGroupOptions(organization.custom_field_groups.Organization),
    )
    setStandardGroupOptions(buildFieldGroupOptions(additionalInfoGroups))
    setFieldGroupsConfig(organization.vendor_request_field_groups_config)
  }, [organization])

  const openPreviewModal = (e) => {
    e.preventDefault()
    const config = {
      introNote: !isEmpty(introNote) ? introNote : null,
      riskCalculatorEnabled: enableCalc,
      uploadEnabled: enableUploads,
      fieldGroupsConfig,
    }
    requestVendor({
      vendorRequestConfig: config,
      disableSubmit: true,
    })
  }

  const handleEnableRequestsChecked = (val) => {
    setEnableRequests(val)
    applySettingsUpdate({ enable_vendor_requests: val })
  }

  const handleNoteChange = (val) => {
    setIntroNote(val)
    applySettingsUpdate({ vendor_request_intro_note: val })
  }

  const handleEnableCalculator = (val) => {
    setEnableCalc(val)
    applySettingsUpdate({ vendor_request_risk_calculator_enabled: val })
  }

  const handleAllowUpload = (val) => {
    setEnableUploads(val)
    applySettingsUpdate({ vendor_request_allow_upload: val })
  }

  const handleGroupChange = (groupName, newVal) => {
    let newFieldsConfig = { ...fieldGroupsConfig, [groupName]: newVal }
    // don't need to fill up config with tracking false values
    if (!newVal) newFieldsConfig = pickBy(newFieldsConfig, (val) => val)
    setFieldGroupsConfig(newFieldsConfig)
    applySettingsUpdate({ vendor_request_field_groups_config: newFieldsConfig })
  }

  const handleEditGroup = (group) => {
    const options =
      customFieldsTree[group.value] ||
      VendorFieldGroups[group.value].fields.map((f) => ({
        value: f.name,
        label: f.label,
      }))
    const allOptionValues = options.map((o) => o.value)
    const value =
      fieldGroupsConfig[group.value] === true
        ? allOptionValues
        : fieldGroupsConfig[group.value]
    addGroupFields({
      name: group.value,
      label: group.label,
      type: 'multi_checkbox',
      options,
      validType: 'array',
      initial: value,
    })
    openGroupModal({
      onSubmit: (formData) => {
        let newVal = formData[group.value]
        if (
          newVal.length === allOptionValues.length &&
          difference(allOptionValues, newVal).length === 0
        ) {
          // if they're all checked, just mark the group as true
          newVal = true
        } else if (newVal.length === 0) {
          // but if nothing's checked, just turn it off
          newVal = false
        }
        handleGroupChange(group.value, newVal)
        closeGroupModal()
      },
    })
  }

  const applySettingsUpdate = (settings) =>
    updateOrgSettings(organization.id, settings)

  return (
    <View
      organization={organization}
      introNote={introNote}
      enableRequests={enableRequests}
      openPreviewModal={openPreviewModal}
      customFieldGroupOptions={customGroupOptions}
      customFieldsTree={customFieldsTree}
      standardGroupOptions={standardGroupOptions}
      fieldGroupsConfig={fieldGroupsConfig}
      enableCalc={enableCalc}
      enableUploads={enableUploads}
      onEnableRequestsChecked={handleEnableRequestsChecked}
      onNoteChange={handleNoteChange}
      onGroupChange={handleGroupChange}
      onEditGroup={handleEditGroup}
      onEnableCalculator={handleEnableCalculator}
      onEnableUpload={handleAllowUpload}
    />
  )
}

VendorRequestSettings.propTypes = {
  organization: PropTypes.object.isRequired,
}

export default VendorRequestSettings
