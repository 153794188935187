import styled from 'styled-components'
import Select from 'components-v2/atoms/Select'
import FormField, { ControlLabel } from 'components-v2/molecules/FormField'
import SearchableSelect from 'components-v2/molecules/SearchableSelect'
import PageHeader from 'components-v2/organisms/PageHeader'
import { colors, mixins, media, sizes } from 'styles'
import CircleIcon from './CircleIcon'

const SidebarWidth = 240
const SpaceBetweenSections = 35
export const ContentOffsetTopDesktop = 170
export const ContentOffsetTopTablet = 172
export const ScrollSpyOffset = -ContentOffsetTopDesktop + SpaceBetweenSections

export const StickyContent = styled.div`
  ${({ isSticky }) =>
    isSticky &&
    `
    top: ${sizes.HEADER_HEIGHT}px !important;
    z-index: 100;
  `}
`

export const StickyContentInner = styled.div`
  margin: 0 -30px;
  padding: 0 30px;
  background: #f5f5f5;

  ${media.tablet`
    padding-bottom: 15px;
  `}
`

export const StyledPageHeader = styled(PageHeader)`
  ${media.desktop`
    h1 {
      margin-left: ${SidebarWidth}px;
    }
  `}

  ${media.tablet`
    padding-top: 19px;
    padding-bottom: 11px;

    h1 {
      font-size: 25px;
    }
  `}
`

export const Wrapper = styled.div`
  position: relative;
  color: ${colors.BODY_TEXT};

  ${media.desktop`
    margin-top: 15px;
  `}
`

export const SectionSelect = styled(Select)`
  ${media.desktop`
    display: none;
  `}
`

export const Sidebar = styled.div`
  position: absolute;
  top: 0px;
  left: 0;
  width: ${SidebarWidth}px !important;

  ${({ isSticky }) =>
    isSticky &&
    `
    top: 175px !important;
  `}

  ${media.tablet`
    display: none;
  `}
`

export const SectionNav = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const SectionNavItemIcon = styled(CircleIcon)`
  font-size: 25px;
  margin-right: 16px;
  opacity: 0.5;
`

export const SectionNavItem = styled.div`
  padding: 12px;
  display: inline-flex;
  width: 90%;
  font-size: 18px;
  font-weight: bold;
  color: ${colors.GREY};
  cursor: pointer;

  &:hover,
  &.is-current {
    color: ${colors.PRIMARY};

    ${SectionNavItemIcon} {
      opacity: 1;
    }
  }
`

export const Content = styled.div`
  > * {
    margin-bottom: ${SpaceBetweenSections}px;
  }

  ${media.desktop`
    margin-left: ${SidebarWidth}px;
  `}

  ${media.mobile`
    margin-left: -22px;
    margin-right: -22px;
  `}
`

export const TemplateSelect = styled(SearchableSelect)`
  width: 270px;
  flex: none;
`

export const FormWrapper = styled.div`
  padding-top: 22px;
  border-top: 1px solid #f5f5f5;
`

export const StyledFormField = styled(FormField)`
  font-weight: 500;

  ${ControlLabel.Wrapper} {
    ${mixins.smallHeader}
    letter-spacing: 0;
  }
`
