import React from 'react'
import PropTypes from 'prop-types'
import { Wrap, Top, Title, DefaultContentWrapper } from './styles'

const Widget = ({
  title,
  moreText,
  url,
  useDefault,
  defaultContent,
  children,
  ...rest
}) => (
  <Wrap {...rest} padding default={useDefault}>
    <Top>
      {title && <Title>{title}</Title>}
      {url && <a href={url}>{moreText}</a>}
    </Top>
    {useDefault ? (
      <DefaultContentWrapper>{defaultContent}</DefaultContentWrapper>
    ) : (
      children
    )}
  </Wrap>
)

Widget.propTypes = {
  title: PropTypes.string,
  moreText: PropTypes.string,
  url: PropTypes.string,
  useDefault: PropTypes.bool,
  defaultContent: PropTypes.node,
  children: PropTypes.node,
}

export default React.memo(Widget)
