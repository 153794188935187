/* eslint-disable react/no-danger */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as yup from 'yup'
import Button from 'components-v2/atoms/Button'
import FormField from 'components-v2/molecules/FormField'
import PageHeader from 'components-v2/organisms/PageHeader'
import { Row, Col } from 'components-v2/organisms/Layout'
import { withReactQuery } from 'hocs'
import { useSelectAssistOrganization } from 'apis'

const Description = styled.p`
  margin-bottom: 40px;
`

const validationSchema = yup.object().shape({
  organization_id: yup.string().nullable().required('This is required'),
})

function View({
  organizations,
  selectedId,
  destination,
  pageTitle,
  description,
}) {
  const { mutateAsync: selectAssistOrganization } =
    useSelectAssistOrganization()
  const handleSubmit = React.useCallback(
    async (formData) => {
      try {
        await selectAssistOrganization({ data: formData })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error)
      }
      // move to destination even if got api failure?
      window.location.href = destination
    },
    [destination, selectAssistOrganization],
  )

  const initialValues = {
    organization_id: selectedId,
  }

  return (
    <>
      <PageHeader title={pageTitle} />
      {description && (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      )}
      <Row>
        <Col md={7}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formProps) => (
              <form onSubmit={formProps.handleSubmit}>
                <FormField
                  name="organization_id"
                  label="Select a client"
                  type={FormField.types.SELECT}
                  options={organizations.map((e) => ({
                    label: e.name,
                    value: e.id,
                  }))}
                  horizontal
                />
                <Button
                  color="primary"
                  type="submit"
                  disabled={formProps.isSubmitting}
                >
                  Next &gt;&gt;
                </Button>
              </form>
            )}
          </Formik>
        </Col>
      </Row>
    </>
  )
}

View.propTypes = {
  organizations: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedId: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  description: PropTypes.string,
}

export default withReactQuery()(View)
