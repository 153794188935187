import React from 'react'
import PropTypes from 'prop-types'
import filter from 'lodash/filter'
import { Sector } from 'recharts'
import { PieChart } from 'components-v2/molecules/Charts'
import { colors } from 'styles'
import ContentCardWithTimeMeta from '../ContentCardWithTimeMeta'
import { Content, StyledMetric, ChartWrapper } from './styles'

const renderLabel = ({
  cx,
  cy,
  midAngle,
  outerRadius,
  startAngle,
  endAngle,
  fill,
  percent,
}) => {
  const RADIAN = Math.PI / 180
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius + 10) * cos
  const sy = cy + (outerRadius + 10) * sin
  const mx = cx + (outerRadius + 17) * cos
  const my = cy + (outerRadius + 17) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 7
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 7}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 4}
        y={ey}
        dy={5}
        textAnchor={textAnchor}
        fill={colors.BODY_TEXT}
      >
        {`${Math.round(percent * 100)}%`}
      </text>
    </g>
  )
}

const CriticalEventsCard = ({ data: { events }, ...rest }) => {
  const chartData = React.useMemo(() => {
    const data = [
      { name: 'Critical', value: events.critical, color: colors.RED },
      { name: 'N/A', value: events.unknown, color: colors.DARK_GREY },
      { name: 'Ok', value: events.ok, color: colors.GREEN },
    ]
    return filter(data, (sector) => sector.value)
  }, [events])
  return (
    <ContentCardWithTimeMeta {...rest} title="Critical Events">
      <Content>
        <StyledMetric>
          {events.critical}
          <sup>%</sup>
        </StyledMetric>
        <ChartWrapper>
          <PieChart
            data={chartData}
            width="100%"
            height={100}
            innerRadius={60}
            outerRadius={75}
            startAngle={0}
            endAngle={180}
            cy="100%"
            label={renderLabel}
            labelOnHover
          />
        </ChartWrapper>
      </Content>
    </ContentCardWithTimeMeta>
  )
}

CriticalEventsCard.propTypes = {
  data: PropTypes.object.isRequired,
}

export default React.memo(CriticalEventsCard)
