import styled from 'styled-components'
import Card from 'components-v2/organisms/Card'
import { colors } from 'styles'

export const Wrapper = styled(Card).attrs({ bordered: true, padding: true })`
  text-align: center;
`

export const Title = styled.h4`
  margin: 0;
  color: ${colors.HEADING};
  font-size: 21px;
`
