import React from 'react'
import PropTypes from 'prop-types'
import { FieldArray } from 'formik'
import Icon from 'components-v2/atoms/Icon'
import { BaseField } from 'components-v2/molecules/FormField'
import { useFormField } from 'hooks'
import ContactFieldGroup from './ContactFieldGroup'
import { AddButton } from './styles'

const emptyContact = {
  name: undefined,
  phone: undefined,
  email: undefined,
}

function ContactsFieldGroup({ name, horizontal, renderControlLabel }) {
  const field = useFormField(name)
  const contactsCount = field.value?.length || 0

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <>
          {contactsCount > 0 && (
            <BaseField
              label="Primary contact"
              horizontal={horizontal}
              renderControlLabel={renderControlLabel}
              preserveHelpTextSpace={false}
            >
              <ContactFieldGroup
                name={`${name}.0`}
                onDelete={() => arrayHelpers.remove(0)}
              />
            </BaseField>
          )}
          {contactsCount > 1 && (
            <BaseField
              label="Additional contacts"
              horizontal={horizontal}
              renderControlLabel={renderControlLabel}
              preserveHelpTextSpace={false}
            >
              {field.value?.map((_, index) => {
                if (index === 0) {
                  return null
                }
                return (
                  <ContactFieldGroup
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    name={`${name}.${index}`}
                    onDelete={() => arrayHelpers.remove(index)}
                  />
                )
              })}
            </BaseField>
          )}
          <AddButton
            key={contactsCount}
            onClick={() => arrayHelpers.push(emptyContact)}
          >
            <Icon icon="fa fa-plus-circle" />
            {contactsCount > 0 ? 'Add another contact' : 'Add a new contact'}
          </AddButton>
        </>
      )}
    />
  )
}

ContactsFieldGroup.propTypes = {
  name: PropTypes.string.isRequired,
  horizontal: PropTypes.bool,
  renderControlLabel: PropTypes.any,
}

export default ContactsFieldGroup
