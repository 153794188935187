import styled from 'styled-components'
import { FileText } from '@styled-icons/feather/FileText'
import Icon from 'components-v2/atoms/Icon'
import IconButton from 'components-v2/atoms/IconButton'

export const List = styled.div`
  list-style: none;
  padding: 0;
  &:not(:first-child) {
    margin-top: 5px;
  }
`

export const Item = styled.div`
  position: relative;
  padding-left: 2em;
  white-space: nowrap;
`

export const FileIcon = styled(Icon).attrs({ icon: FileText })`
  position: absolute;
  left: 0.5em;
  top: 0.25em;
`

export const RemoveButton = styled(IconButton).attrs({ color: 'primary' })`
  margin-left: 6px;
  font-size: 14px;
`
