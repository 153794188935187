import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components-v2/atoms/Button'
import Icon from 'components-v2/atoms/Icon'
import A from 'components-v2/atoms/A'
import Table from 'components-v2/molecules/Table'
import Toolbar, {
  SearchInput,
  ColumnToggler,
} from 'components-v2/molecules/Table/Toolbar'
import FilterDropdownTray from 'components-v2/molecules/FilterDropdownTray'
import MenuDropdown from 'components-v2/molecules/MenuDropdown'
import { useAuthorizations } from 'hooks'
import { dateFormatterFactory, applyColumnToggles } from 'lib/table'
import {
  getIssueStatusLabel,
  getIssueResolutionLabel,
  // sortIssues,
  issueDueDateFormatterFactory,
  issuePriorityFormatterFactory,
} from 'lib/issue'
import { vendorRiskTierFormatterFactory } from 'lib/vendor'
import { IssueStatusTypes, LabelTypes } from 'constants/index'
import { STICKY_TOP_OFFSET, EXTRA_STICKY_GAP } from './styles'

// Constants
import { ActionMenuItemTypes, ColumnDataFieldTypes } from './constants'

const dateFormatter = dateFormatterFactory()
const dueDateFormatter = issueDueDateFormatterFactory()
const priorityFormatter = issuePriorityFormatterFactory()
const vendorRiskTierFormatter = vendorRiskTierFormatterFactory()

const initialVisibleFilters = [
  'statuses',
  'categories',
  'vendorTags',
  'clientAssignees',
  'resolutions',
]

const getTableColumns = (vendorColumnHidden, onSendIssue, canManage) => [
  {
    id: ColumnDataFieldTypes.VENDOR_NAME,
    header: LabelTypes.VENDOR,
    hidden: vendorColumnHidden,
    togglable: !vendorColumnHidden,
    width: 200,
  },
  {
    id: ColumnDataFieldTypes.SUMMARY,
    header: 'Issue',
    cell: (info) => <a href={info.row.original.path}>{info.getValue()}</a>,
    width: 350,
  },
  {
    id: ColumnDataFieldTypes.PRIORITY,
    header: 'Priority',
    cell: priorityFormatter,
    width: 120,
  },
  {
    id: ColumnDataFieldTypes.STATUS,
    header: 'Status',
    cell: (info) => {
      const status = getIssueStatusLabel(info.getValue(), false)
      if (info.getValue() === IssueStatusTypes.CREATED) {
        return (
          <div>
            <div>{status}</div>
            {canManage && (
              <A onClick={() => onSendIssue(info.row.original.id)}>Send</A>
            )}
          </div>
        )
      }

      if (
        [IssueStatusTypes.CLOSED, IssueStatusTypes.ARCHIVED].includes(
          info.getValue(),
        ) &&
        info.row.original.resolution
      ) {
        return (
          <div>
            <div>{status}</div>
            <div className="small">
              {getIssueResolutionLabel(info.row.original.resolution)}
            </div>
          </div>
        )
      }

      return status
    },
    width: 110,
  },
  {
    id: ColumnDataFieldTypes.CREATED_AT,
    header: 'Created',
    cell: dateFormatter,
    width: 120,
  },
  {
    id: ColumnDataFieldTypes.DUE_AT,
    header: 'Due',
    cell: dueDateFormatter,
    width: 100,
  },
  {
    id: ColumnDataFieldTypes.VENDOR_RISK_TIER,
    header: `${LabelTypes.VENDOR} Risk Tier`,
    cell: vendorRiskTierFormatter,
    hidden: true,
    togglable: !vendorColumnHidden,
    width: 100,
  },
  {
    id: 'df1',
    togglable: false,
    header: '',
    cell: (info) => <a href={info.row.original.path}>View</a>,
    enableSorting: false,
    classes: 'hidden-print',
    headerClasses: 'hidden-print',
    width: 80,
  },
]

const View = ({
  vendorColumnHidden,
  modalFormat,
  newButtonVisible,
  columnTogglerVisible,
  issues,
  selected,
  totalSize,
  page,
  sizePerPage,
  sortField,
  sortOrder,
  searchText,
  columnToggles,
  filter,
  filterConfigList,
  archiveMenuItemDisabled,
  archiveMenuItemHidden,
  sendMenuItemDisabled,
  resendMenuItemDisabled,
  deleteMenuItemDisabled,
  loading,
  columnOrder,
  onPaginationChange,
  onSortingChange,
  onSearchChange,
  onColumnTogglesChange,
  onFilterChange,
  onMenuItemSelect,
  onRowSelectionChange,
  onSendIssue,
  onCreateIssue,
}) => {
  const [containerDom, setContainerDom] = React.useState()
  const [canCreate, canManage, canExport, canDelete] = useAuthorizations(
    ['create', 'Issue'],
    ['manage', 'Issue'],
    ['export', 'Issue'],
    ['delete', 'Issue'],
  )

  const tableColumns = React.useMemo(
    () => getTableColumns(vendorColumnHidden, onSendIssue, canManage),
    [vendorColumnHidden, onSendIssue, canManage],
  )

  const orderedColumns = React.useMemo(() => {
    if (!columnOrder) {
      return tableColumns
    }

    return columnOrder.reduce((res, colName) => {
      const column = tableColumns.find((e) => e.id === colName)
      if (column) {
        res.push(column)
      }
      return res
    }, [])
  }, [tableColumns, columnOrder])

  const finalColumns = React.useMemo(() => {
    if (!columnTogglerVisible) {
      return orderedColumns
    }
    return applyColumnToggles(orderedColumns, columnToggles)
  }, [columnTogglerVisible, orderedColumns, columnToggles])

  return (
    <div ref={(dom) => setContainerDom(dom)}>
      <Toolbar>
        <SearchInput value={searchText} onChange={onSearchChange} />
        <Toolbar.Group>
          {columnTogglerVisible && (
            <ColumnToggler
              columns={orderedColumns}
              selected={columnToggles}
              onToggle={onColumnTogglesChange}
            />
          )}
          <FilterDropdownTray
            value={filter}
            configList={filterConfigList}
            initialVisibleFilters={initialVisibleFilters}
            onChange={onFilterChange}
            filtersEditable
            boundingTarget={containerDom}
          />
          <MenuDropdown title="Actions" onSelect={onMenuItemSelect}>
            {canExport && (
              <MenuDropdown.Item
                eventKey={ActionMenuItemTypes.EXPORT_CURRENT_VIEW}
              >
                <i className="fa fa-table" />
                Export Current View
              </MenuDropdown.Item>
            )}
            {!archiveMenuItemHidden && (
              <MenuDropdown.Item
                eventKey={ActionMenuItemTypes.ARCHIVE_SELECTED}
                disabled={archiveMenuItemDisabled}
              >
                <i className="fa fa-archive" />
                Archive Selected
              </MenuDropdown.Item>
            )}
            {canManage && (
              <MenuDropdown.Item
                eventKey={ActionMenuItemTypes.SEND_SELECTED}
                disabled={sendMenuItemDisabled}
              >
                <i className="fa fa-envelope-o" />
                Send Selected
              </MenuDropdown.Item>
            )}
            {canManage && (
              <MenuDropdown.Item
                eventKey={ActionMenuItemTypes.RESEND_SELECTED}
                disabled={resendMenuItemDisabled}
              >
                <i className="fa fa-share-square-o" />
                Resend Selected
              </MenuDropdown.Item>
            )}
            {canDelete && (
              <MenuDropdown.Item
                eventKey={ActionMenuItemTypes.DELETE_SELECTED}
                disabled={deleteMenuItemDisabled}
              >
                <i className="fa fa-trash-o" />
                Delete Selected
              </MenuDropdown.Item>
            )}
          </MenuDropdown>
          {newButtonVisible && canCreate && (
            <Button color="primary" onClick={onCreateIssue}>
              <Icon icon="fa fa-plus" />
              New Issue
            </Button>
          )}
        </Toolbar.Group>
      </Toolbar>
      <Table
        loading={loading}
        data={issues || []}
        columns={finalColumns}
        rowSelection={selected}
        totalSize={totalSize}
        pagination={{ page, sizePerPage }}
        sorting={{ sortField, sortOrder }}
        onRowSelectionChange={onRowSelectionChange}
        onPaginationChange={onPaginationChange}
        onSortingChange={onSortingChange}
        manualPagination
        manualSorting
        enableRowSelection={!archiveMenuItemHidden || canManage || canDelete}
        type={Table.types.ALT}
        // sticky table header doesn't work right in a modal at the moment...
        enableSticky={!modalFormat}
        topOffset={STICKY_TOP_OFFSET}
        aboveStickyWhiteSpace={EXTRA_STICKY_GAP}
        aboveStickyWhiteSpaceColor="#F5F5F5"
      />
    </div>
  )
}

View.propTypes = {
  vendorColumnHidden: PropTypes.bool,
  modalFormat: PropTypes.bool,
  newButtonVisible: PropTypes.bool,
  issues: PropTypes.array,
  selected: PropTypes.object.isRequired,
  totalSize: PropTypes.number,
  page: PropTypes.number,
  sizePerPage: PropTypes.number,
  sortField: PropTypes.string,
  sortOrder: PropTypes.string,
  searchText: PropTypes.string,
  columnToggles: PropTypes.array,
  filter: PropTypes.object,
  filterConfigList: PropTypes.array.isRequired,
  archiveMenuItemDisabled: PropTypes.bool,
  archiveMenuItemHidden: PropTypes.bool,
  sendMenuItemDisabled: PropTypes.bool,
  resendMenuItemDisabled: PropTypes.bool,
  deleteMenuItemDisabled: PropTypes.bool,
  loading: PropTypes.bool,
  columnTogglerVisible: PropTypes.bool,
  columnOrder: PropTypes.array,
  onPaginationChange: PropTypes.func.isRequired,
  onSortingChange: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onColumnTogglesChange: PropTypes.func,
  onFilterChange: PropTypes.func.isRequired,
  onMenuItemSelect: PropTypes.func.isRequired,
  onRowSelectionChange: PropTypes.func.isRequired,
  onSendIssue: PropTypes.func.isRequired,
  onCreateIssue: PropTypes.func.isRequired,
}

View.defaultProps = {
  modalFormat: false,
}

export default View
