import { LabelTypes } from 'constants/index'

export const ActionMenuItemTypes = {
  EXPORT_CSV: 'export_csv',
  EXPORT_XLS: 'export_xls',
}

const SourceTypeOptions = [
  { value: 'vendor', label: `My ${LabelTypes.VENDOR} Files` },
  { value: 'assessment', label: 'Assessments' },
  { value: 'comment', label: 'Issue Comments' },
  { value: 'response', label: 'Assessment Responses' },
]

export const filterConfigList = [
  {
    name: 'sourceTypes',
    title: 'File Types',
    options: SourceTypeOptions,
    selectAllVisible: true,
    matchVisible: false,
  },
  {
    name: 'expiration',
    title: 'Expiration',
    options: [
      { value: 'allpast', label: 'All Expired' },
      { value: 'past30', label: 'Expired in last 30 days' },
      { value: 'next30', label: 'Next 30 Days' },
      { value: 'next60', label: 'Next 60 Days' },
    ],
  },
]
