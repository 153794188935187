import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import { toLocalTimeString } from 'utils/date'
import Default from './Default'

const validationSchema = yup.object().shape({
  credentials: yup
    .string()
    .nullable()
    .test('is-valid', 'This is a required field', function callback(value) {
      return value || (this.parent.credentials_ending_in && value === null)
    }),
})

const RiskReconFields = ({ data }) => (
  <>
    <Default data={data} />
    {data.expiration_datetime && (
      <div>
        Expiration: {toLocalTimeString(data.expiration_datetime, 'MM/dd/yyyy')}
      </div>
    )}
  </>
)

RiskReconFields.propTypes = {
  data: PropTypes.object.isRequired,
}

RiskReconFields.validationSchema = validationSchema
RiskReconFields.toFormValues = Default.toFormValues
RiskReconFields.fromFormValues = Default.fromFormValues

export default RiskReconFields
