import ArgosRiskLogo from 'assets/images/argosrisk_logo.png'
import RiskReconLogo from 'assets/images/riskrecon_logo.png'
import SpyCloudLogo from 'assets/images/spycloud_logo.png'
import OsanoLogo from 'assets/images/osano_logo.png'

export const GloborgIntegrationTypes = {
  SPY_CLOUD: 'spy_cloud',
  ARGOS_RISK: 'argos_risk',
  RISK_RECON: 'risk_recon',
  OSANO: 'osano',
  OFAC: 'ofac',
}

export const GloborgIntegrationOptions = [
  {
    value: GloborgIntegrationTypes.RISK_RECON,
    name: 'RiskRecon',
    sourceLogo: RiskReconLogo,
    sourceUrl: 'https://www.riskrecon.com',
  },
  {
    value: GloborgIntegrationTypes.OSANO,
    name: 'Osano',
    sourceLogo: OsanoLogo,
    sourceUrl:
      'https://www.osano.com/?utm_source=partner&utm_medium=4474893579&utm_campaign=data',
  },
  {
    value: GloborgIntegrationTypes.SPY_CLOUD,
    name: 'SpyCloud',
    sourceLogo: SpyCloudLogo,
    sourceUrl: 'https://spycloud.com/privva',
  },
  {
    value: GloborgIntegrationTypes.ARGOS_RISK,
    name: 'Argos Risk',
    sourceLogo: ArgosRiskLogo,
    sourceUrl: 'https://www.argosrisk.com',
  },
  {
    value: GloborgIntegrationTypes.OFAC,
    name: 'OFAC',
  },
]

export default GloborgIntegrationTypes
