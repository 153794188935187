import React from 'react'
import PropTypes from 'prop-types'
import MultiLineTextDisplay from '../MultiLineTextDisplay'
import { Muted } from './styles'

const AdditionalInfoDisplay = ({ value }) => {
  if (value) {
    return <MultiLineTextDisplay value={value} />
  }
  return <Muted>No additional information at this time.</Muted>
}

AdditionalInfoDisplay.propTypes = {
  value: PropTypes.string,
}

export default AdditionalInfoDisplay
