import React from 'react'
import PropTypes from 'prop-types'
import { BarChart } from 'components-v2/molecules/Charts'
import ContentCardWithTimeMeta from '../ContentCardWithTimeMeta'
import { RiskHealthScoreColors } from '../../constants'

const yAxisTickFormatter = (value) => `${value}%`
const barColorGetter = (_, index) => RiskHealthScoreColors[index]

const VendorsBySourceRangeCard = ({ data: { ranges }, ...rest }) => (
  <ContentCardWithTimeMeta {...rest} title="Vendors by Risk Health">
    <BarChart
      width="100%"
      height={255}
      data={ranges}
      xAxisLabel="Risk Health Score"
      yAxisLabel="Percent of vendors"
      yAxisTickFormatter={yAxisTickFormatter}
      barColorGetter={barColorGetter}
    />
  </ContentCardWithTimeMeta>
)

VendorsBySourceRangeCard.propTypes = {
  data: PropTypes.object.isRequired,
}

export default React.memo(VendorsBySourceRangeCard)
