import { useCallback } from 'react'
import { useGlobalLoader, useConfirm, useCurrent } from 'hooks'
import NotificationManager from 'lib/notifications'
import { useUpdateClientChoice } from 'apis'

export default function useEditQuestionAutoScore(elements, confirmAutoScore) {
  const { mutateAsync: updateClientChoice } = useUpdateClientChoice()
  const elementsRef = useCurrent(elements)
  const [showLoader, hideLoader] = useGlobalLoader()
  const openConfirm = useConfirm()

  return useCallback(
    async (elementId, choiceId, score, onEdited) => {
      if (confirmAutoScore) {
        const confirmed = await openConfirm(
          {
            title: 'Confirm Autoscore Change',
            body: 'This question has already been scored on existing assessments. Making this change will update the scores in all of these assessments. Do you want to proceed?',
          },
          true,
        )
        if (!confirmed) {
          return
        }
      }

      const element = elementsRef.current?.find((e) => e.id === elementId)
      if (!element) {
        return
      }

      const loaderId = showLoader({ withDelay: true })
      const choice = element?.choices?.find((e) => e.id === choiceId)
      const data = {
        choice: {
          // If user clicked the current score box, let's unset the score to null
          auto_score: choice?.auto_score === score ? null : score,
        },
      }
      try {
        const response = await updateClientChoice({
          id: choiceId,
          data,
        })
        if (onEdited) onEdited(response)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error >', error)
        NotificationManager.error()
      }
      hideLoader(loaderId)
    },
    [openConfirm, confirmAutoScore, showLoader, hideLoader],
  )
}
