import React from 'react'
import PropTypes from 'prop-types'
import { useLibraryListingsFetcher } from 'apis'
import View from './View'
import useRequestAccess from './useRequestAccess'

const Container = ({ profileId }) => {
  const { data: listings } = useLibraryListingsFetcher({
    params: { profile_id: profileId },
  })
  const requestAccess = useRequestAccess()
  return <View listings={listings} onRequestAccess={requestAccess} />
}

Container.propTypes = {
  profileId: PropTypes.string.isRequired,
}

export default Container
