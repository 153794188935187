import pick from 'lodash/pick'
import { getPageUrl } from 'utils/url'
import { toLocalTimeString } from 'utils/date'

const addDays = (num) => {
  const date = new Date()
  date.setDate(date.getDate() + num)
  return date
}

export const getFileQueryParams = (tableState, vendorId) => {
  const params = {
    page: tableState.page,
    per_page: tableState.sizePerPage,
    search_query: tableState.searchText,
    ordered_by: tableState.sortField,
    direction: tableState.sortOrder,
    source_type: tableState.filter.sourceTypes?.selected?.length
      ? tableState.filter.sourceTypes.selected
      : ['none'],
    exp_start: null,
    exp_end: null,
    vendor_id: vendorId,
  }
  if (tableState.filter.expiration?.selected?.length) {
    const exp = tableState.filter.expiration?.selected

    let expStart = Date.now()
    if (exp.includes('allpast')) expStart = null
    else if (exp.includes('past30')) expStart = addDays(-30)
    params.exp_start = toLocalTimeString(expStart)

    let expEnd = Date.now()
    if (exp.includes('next60')) expEnd = addDays(60)
    else if (exp.includes('next30')) expEnd = addDays(30)
    params.exp_end = toLocalTimeString(expEnd)
  }
  return params
}

export const getExportUrl = (format, queryParams) =>
  getPageUrl(
    'clientVendorAttachmentsExport',
    undefined,
    pick(queryParams, [
      'search_query',
      'ordered_by',
      'direction',
      'source_type',
      'exp_start',
      'exp_end',
      'vendor_id',
    ]),
    format,
  )
