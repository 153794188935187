import React from 'react'
import { Wrapper, Title } from './styles'

const WhatIsOFACText =
  'The Office of Foreign Assets Control("OFAC") of the US Department of the Treasury administers and enforces economic and trade sanctions based on US foreign policy and national security goals against targeted foreign countries and regimes, terrorists, international narcotics traffickers, those engaged in activities related to the proliferation of weapons of mass destruction, and other threats to the national security, foreign policy or economy of the United States.'

const OFACInfoCard = () => (
  <Wrapper>
    <Title>What is OFAC?</Title>
    {WhatIsOFACText}
  </Wrapper>
)

export default OFACInfoCard
