import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import isInteger from 'lodash/isInteger'
import { toUncontrollable } from 'hocs'
import { useAuthorizations } from 'hooks'
import objectToFormData from 'utils/object_to_formdata'
import DropzoneUploader from '../../DropzoneUploader'
import Layout from './Layout'

const Uploader = ({
  files,
  disabled,
  canRemove,
  displayLabel,
  url,
  model,
  formParam,
  socketId,
  buttonContent,
  buttonColor,
  description,
  accept,
  maxFiles,
  maxSizeBytes,
  onChange,
  setTrigger,
  renderLayout: RenderLayout,
  ...rest
}) => {
  // TODO: Consider to get rid of this business logic
  const [canManage] = useAuthorizations('manage')
  const isDisabled = disabled || !canManage

  const getUploadParams = React.useCallback(
    ({ file }) => {
      const params = {
        [model]: {
          [`${formParam}_attributes`]: [{ file }],
        },
      }
      if (socketId) params.socket_id = socketId
      const body = objectToFormData(params)
      return { url, body, method: 'PATCH' }
    },
    [url, model, formParam, socketId],
  )

  const handleChangeStatus = React.useCallback(
    ({ xhr, remove }, status) => {
      if (status === 'done') {
        const response = JSON.parse(xhr.response)
        onChange(response[formParam], response)
        remove()
      }
    },
    [formParam, onChange],
  )

  const handleFileRemove = React.useCallback(
    (id) => {
      const data = {
        [model]: {
          [`${formParam}_attributes`]: {
            0: {
              id,
              _destroy: 1,
            },
          },
        },
      }
      if (socketId) data.socket_id = socketId
      axios.patch(url, data).then((response) => {
        onChange(response.data[formParam], response.data)
      })
    },
    [url, model, formParam, socketId, onChange],
  )

  const dropzoneUploader = (
    <DropzoneUploader
      getUploadParams={getUploadParams}
      buttonContent={buttonContent}
      buttonColor={buttonColor}
      description={description}
      accept={accept}
      maxFiles={isInteger(maxFiles) ? maxFiles - files.length : maxFiles}
      maxSizeBytes={maxSizeBytes}
      onChangeStatus={handleChangeStatus}
      setTrigger={setTrigger}
    />
  )

  return (
    <RenderLayout
      {...rest}
      dropzoneUploader={dropzoneUploader}
      files={files}
      disabled={isDisabled}
      displayLabel={displayLabel}
      canRemove={canRemove}
      onRemove={handleFileRemove}
    />
  )
}

Uploader.propTypes = {
  url: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  files: PropTypes.array,
  disabled: PropTypes.bool,
  canRemove: PropTypes.bool,
  displayLabel: PropTypes.bool,
  formParam: PropTypes.string,
  socketId: PropTypes.string,
  buttonContent: PropTypes.node,
  buttonColor: PropTypes.string,
  description: PropTypes.string,
  accept: PropTypes.string,
  maxFiles: PropTypes.number,
  maxSizeBytes: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  setTrigger: PropTypes.func,
  renderLayout: PropTypes.any,
}

Uploader.defaultProps = {
  files: [],
  disabled: false,
  canRemove: true,
  displayLabel: true,
  formParam: 'attachments',
  renderLayout: Layout,
}

export default toUncontrollable({ files: 'onChange' })(Uploader)
