import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import { ShowAllButton } from './styles'

const LIMIT = 4

const TagList = ({ list = [], plural, titleize }) => {
  const [expanded, setExpanded] = React.useState(false)
  const showAll = expanded || list.length <= LIMIT
  const items = showAll ? list : list.slice(0, LIMIT)
  return (
    <div>
      {map(items, (item) => (
        <span key={item}>
          {titleize ? item.titleize() : item}
          <br />
        </span>
      ))}
      {!showAll && (
        <ShowAllButton
          onClick={() => setExpanded(true)}
        >{`View all (${list.length}) ${plural}`}</ShowAllButton>
      )}
    </div>
  )
}

TagList.propTypes = {
  list: PropTypes.array,
  plural: PropTypes.string,
  titleize: PropTypes.bool,
}

export default TagList
