import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components-v2/atoms/Button'
import SimpleDialog from 'components-v2/molecules/SimpleDialog'
import Table from 'components-v2/molecules/Table'
import Toolbar, { SearchInput } from 'components-v2/molecules/Table/Toolbar'
import { useClientGlobalAssessmentTemplatesFetcher } from 'apis'
import config from 'config'
import { NameLabel, SubLabel } from './styles'

const getTemplateTableColumns = (onSelect) => [
  {
    id: 'name',
    header: 'Template',
    cell: (props) => (
      <div>
        <NameLabel>{props.getValue()}</NameLabel>
        <SubLabel>{props.row.original.summary}</SubLabel>
        <SubLabel>{props.row.original.global_template_description}</SubLabel>
      </div>
    ),
    width: '84%',
  },
  {
    id: 'df1',
    header: '',
    cell: (props) => (
      <Button
        color="primary"
        size="small"
        onClick={() => onSelect(props.row.original)}
      >
        <i className="fa fa-plus fa-fw" />
        Add
      </Button>
    ),
    enableSorting: false,
    classes: 'hidden-print',
    headerClasses: 'hidden-print',
    width: '16%',
  },
]

const sizePerPageList = [5, 10, 15, 20]

const SelectGlobalAssessmentTemplateModal = ({
  onClose,
  onSelect,
  ...rest
}) => {
  const [searchText, setSearchText] = React.useState('')
  const [page, setPage] = React.useState(1)
  const [sizePerPage, setSizePerPage] = React.useState(
    config.collectionQueryPageLimit.selectGlobalAssessmentTemplateModal,
  )
  const [sortField, setSortField] = React.useState()
  const [sortOrder, setSortOrder] = React.useState()
  const tableColumns = React.useMemo(
    () => getTemplateTableColumns(onSelect),
    [onSelect],
  )
  const buttons = React.useMemo(
    () => [{ onClick: onClose, children: 'Cancel' }],
    [onClose],
  )

  // Fetch assessment templates
  const {
    data: templates = [],
    totalSize,
    isFetching,
  } = useClientGlobalAssessmentTemplatesFetcher({
    params: {
      page,
      per_page: sizePerPage,
      search_query: searchText,
      ordered_by: sortField,
      direction: sortOrder,
    },
  })

  const handleSearchChange = React.useCallback((text) => {
    setSearchText(text)
    setPage(1)
  }, [])

  const handlePaginationChange = React.useCallback((newState) => {
    setPage(newState.page)
    setSizePerPage(newState.sizePerPage)
  }, [])

  const handleSortingChange = React.useCallback((newState) => {
    setSortField(newState.sortField)
    setSortOrder(newState.sortOrder)
  }, [])

  return (
    <SimpleDialog
      title="Select A Template"
      buttons={buttons}
      onClose={onClose}
      {...rest}
    >
      <Toolbar>
        <SearchInput value={searchText} onChange={handleSearchChange} />
      </Toolbar>
      <Table
        loading={isFetching}
        data={templates || []}
        columns={tableColumns}
        totalSize={totalSize}
        pagination={{ page, sizePerPage }}
        sorting={{ sortField, sortOrder }}
        pageSizes={sizePerPageList}
        onPaginationChange={handlePaginationChange}
        onSortingChange={handleSortingChange}
        manualPagination
        manualSorting
        noDataText="No templates found."
        type={Table.types.ALT}
      />
    </SimpleDialog>
  )
}

SelectGlobalAssessmentTemplateModal.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default SelectGlobalAssessmentTemplateModal
