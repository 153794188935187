import styled from 'styled-components'
import Tooltip from 'components-v2/molecules/Tooltip'

export const Title = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 10px;
`

export const Toolbar = styled.div`
  display: flex;
  margin-bottom: 10px;
`

export const SearchInputWrapper = styled.div`
  flex: 1;
  margin-right: 6px;
`

export const AdvancedSearchTooltip = styled(Tooltip).attrs({ dark: false })`
  text-align: left;
  max-width: 400px;
`
