import React from 'react'
import * as yup from 'yup'
import FormDialog from 'components-v2/organisms/FormDialog'
import { useClientVendorsFetcher } from 'apis'
import { getPageUrl } from 'utils/url'
import { LabelTypes } from 'constants/index'
import config from 'config'
import SelectVendorFormField from '../fields/SelectVendorFormField'

const formConfig = {
  validationSchema: yup.object().shape({
    vendor: yup.string().label(LabelTypes.VENDOR).required(),
  }),
  initialValues: {
    vendor: undefined,
  },
}

const RiskProfileModal = (props) => {
  // Fetch vendors that have submitted assessments.
  const { data: vendors = [] } = useClientVendorsFetcher({
    params: {
      page: 1,
      per_page: config.collectionQueryTotalLimit.vendors,
      is_linked_to_globorg: true,
    },
  })

  const handleSubmit = React.useCallback((formData) => {
    window.location.assign(
      getPageUrl(
        'clientVendor',
        { id: formData.vendor },
        { tab: 'riskProfile' },
      ),
    )
  }, [])

  return (
    <FormDialog
      {...props}
      title="Risk Profile Report"
      submitButtonLabel="View Report"
      formConfig={formConfig}
      onSubmit={handleSubmit}
    >
      <SelectVendorFormField
        vendors={vendors}
        placeholder={
          vendors.length === 0
            ? `No ${LabelTypes.VENDORS.toLowerCase()} linked to Profiles found`
            : 'Select'
        }
      />
    </FormDialog>
  )
}

export default RiskProfileModal
