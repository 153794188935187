import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import Radio from 'components-v2/atoms/Radio'
import PrintButton from 'components-v2/molecules/PrintButton'
import ToolbarGroup from 'components-v2/molecules/ToolbarGroup'
import { BaseField, ControlLabel } from 'components-v2/molecules/FormField'
import SelectField from 'components-v2/molecules/FormField/SelectField'
import SearchableSelectField from 'components-v2/molecules/FormField/SearchableSelectField'
import MenuDropdown from 'components-v2/molecules/MenuDropdown'
import SearchableSelect from 'components-v2/molecules/SearchableSelect'
import PageHeader from 'components-v2/organisms/PageHeader'
import { Row, Col } from 'components-v2/organisms/Layout'
import { compose, withAuthorize, withReactQuery } from 'hocs'
import { toLocalTimeString, localTimeZoneOffsetInHours } from 'utils/date'
import {
  useClientTagsFetcher,
  useUsersFetcher,
  useClientAssessmentCycleTimeReportFetcher,
} from 'apis'
import { getEndpointUrl } from 'utils/url'
import { ExportFormatTypes, LabelTypes, RiskTierOptions } from 'constants/index'
import StatusChartWidget from './StatusChartWidget'
import StatusTableWidget from './StatusTableWidget'
import { Filter, StyledCard } from './styles'

const dimensionOptions = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'riskTier',
    label: `${LabelTypes.VENDOR} Risk Tier`,
  },
  {
    value: 'assignee',
    label: 'Assignee',
  },
  {
    value: 'vendorTag',
    label: `${LabelTypes.VENDOR} Label`,
  },
]

const getParams = (dimension, filter, anyMatch) => {
  if (dimension === 'riskTier') {
    return {
      risk_tier_list: filter,
    }
  }
  if (dimension === 'assignee') {
    return {
      client_assignee: filter,
    }
  }
  if (dimension === 'vendorTag') {
    return {
      with_vendor_tags: filter.join(','),
      any: anyMatch,
    }
  }
  return {}
}

const CycleTimeReport = ({ authManager }) => {
  const [dimension, setDimension] = useState('all')
  const [filter, setFilter] = useState([])
  const [anyMatch, setAnyMatch] = useState(true)
  const params = getParams(dimension, filter, anyMatch)
  const { data: clientTags } = useClientTagsFetcher()
  const vendorTagOptions = useMemo(
    () =>
      clientTags?.map((tag) => ({
        value: tag.id,
        label: tag.name,
      })),
    [clientTags],
  )
  const { data: users } = useUsersFetcher()
  const assigneeFilterOptions = useMemo(
    () =>
      users?.map((user) => ({
        value: user.id,
        label: user.name,
      })),
    [users],
  )
  const { data } = useClientAssessmentCycleTimeReportFetcher({ params })

  const handleExport = useCallback(
    (format) => {
      const url = getEndpointUrl(
        'clientAssessmentCycleTimeReport',
        undefined,
        {
          tz: localTimeZoneOffsetInHours,
          ...params,
        },
        format,
      )
      window.location.assign(url)
    },
    [params],
  )

  const handleDimensionChange = useCallback((value) => {
    setDimension(value)
    setFilter([])
  }, [])

  const canExport = authManager.userCan('export')
  const dateStr = toLocalTimeString(new Date(), 'MM/dd/yyyy')

  return (
    <>
      <PageHeader title="Assessment Cycle Time">
        <ToolbarGroup>
          <PrintButton color={canExport ? 'default' : 'primary'} />
          {canExport && (
            <MenuDropdown
              title="Export"
              icon="fa fa-table"
              buttonProps={{ color: 'primary' }}
              onSelect={handleExport}
            >
              <MenuDropdown.Item eventKey={ExportFormatTypes.CSV}>
                <i className="fa fa-table" />
                Export (CSV)
              </MenuDropdown.Item>
              <MenuDropdown.Item eventKey={ExportFormatTypes.XLSX}>
                <i className="fa fa-table" />
                Export (XLS)
              </MenuDropdown.Item>
            </MenuDropdown>
          )}
        </ToolbarGroup>
      </PageHeader>
      <Filter>
        <Row>
          <Col sm={6}>
            <SelectField
              label="Dimension:"
              value={dimension}
              options={dimensionOptions}
              onChange={handleDimensionChange}
            />
          </Col>
          <Col sm={6}>
            {dimension === 'riskTier' && (
              <SearchableSelectField
                label={`Filter ${LabelTypes.VENDORS} Risk Tiers:`}
                value={filter}
                options={RiskTierOptions}
                controlProps={{
                  placeholder: `Filter ${LabelTypes.VENDORS} Risk Tiers`,
                  isMulti: true,
                }}
                onChange={setFilter}
              />
            )}
            {dimension === 'assignee' && (
              <SearchableSelectField
                label="Filter Assignees:"
                value={filter}
                options={assigneeFilterOptions}
                controlProps={{
                  placeholder: 'Filter Assignees:',
                  isMulti: true,
                }}
                onChange={setFilter}
              />
            )}
            {dimension === 'vendorTag' && (
              <BaseField>
                <ControlLabel>
                  Match&nbsp;&nbsp;
                  <Radio
                    inline
                    checked={!anyMatch}
                    onChange={() => setAnyMatch(false)}
                    label="ALL"
                  />
                  &nbsp;&nbsp;
                  <Radio
                    inline
                    checked={anyMatch}
                    onChange={() => setAnyMatch(true)}
                    label="ANY"
                  />
                  &nbsp;of the following {LabelTypes.VENDORS} Labels:
                </ControlLabel>
                <SearchableSelect
                  placeholder={`Filter ${LabelTypes.VENDORS} Labels`}
                  value={filter}
                  isMulti
                  options={vendorTagOptions}
                  onChange={setFilter}
                />
              </BaseField>
            )}
          </Col>
        </Row>
      </Filter>
      <StyledCard>
        <Row>
          <Col sm={8} smOffset={2}>
            <p className="text-center">Date: {dateStr}</p>
            <StatusChartWidget chartData={data} />
            <StatusTableWidget data={data} />
          </Col>
        </Row>
      </StyledCard>
    </>
  )
}

CycleTimeReport.propTypes = {
  authManager: PropTypes.object.isRequired,
}

export default compose(withAuthorize(), withReactQuery())(CycleTimeReport)
