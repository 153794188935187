import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { darken, rgba } from 'polished'
import Clickable from 'components-v2/atoms/Clickable'
import { colors, mixins } from 'styles'

const active = (...args) => css`
  &:active {
    ${css(...args)};
  }

  ${(props) => props.active && css(...args)}
`

const containedColorVariant = (value, colorCode) => css`
  ${({ variant, color }) =>
    variant === 'contained' &&
    color === value &&
    css`
      background-color: ${colorCode};
      border-color: ${darken(0.05, colorCode)};

      &,
      &:hover,
      &:focus {
        color: #fff;
      }

      &:hover,
      &:focus {
        background-color: ${darken(0.07, colorCode)};
        border-color: ${darken(0.1, colorCode)};
      }

      ${active`
        background-color: ${darken(0.07, colorCode)};
        border-color: ${darken(0.1, colorCode)};

        &:hover, &:focus {
          background-color: ${darken(0.1, colorCode)};
          border-color: ${darken(0.15, colorCode)};
        }
      `}
    `}
`

const outlinedColorVariant = (value, colorCode) => css`
  ${({ variant, color }) =>
    variant === 'outlined' &&
    color === value &&
    css`
      border-color: currentColor;

      &,
      &:hover,
      &:focus {
        color: ${colorCode};
      }

      &:hover,
      &:focus {
        background-color: ${rgba(colorCode, 0.2)};
      }

      ${active`
        background-color: ${rgba(colorCode, 0.2)};

        &:hover, &:focus {
          background-color: ${rgba(colorCode, 0.3)};
        }
      `}
    `}
`

const textColorVariant = (value, colorCode) => css`
  ${({ variant, color }) =>
    variant === 'text' &&
    color === value &&
    css`
      color: ${colorCode};

      &:hover,
      &:focus {
        color: ${darken(0.1, colorCode)};
      }

      ${active`
        color: ${darken(0.1, colorCode)};

        &:hover, &:focus {
          color: ${darken(0.15, colorCode)};
        }
      `}
    `}
`

const colorVariant = (value, colorCode) => css`
  ${containedColorVariant(value, colorCode)}
  ${outlinedColorVariant(value, colorCode)}
  ${textColorVariant(value, colorCode)}
`

/**
 * @typedef {Object} ButtonProps
 * @property {'primary' | 'success' | 'info' | 'danger' | 'default'} [color]
 * @property {'xs' | 'xsmall' | 'sm' | 'small' | 'md' | 'medium' | 'lg' | 'large'} [size]
 * @property {'contained' | 'outlined' | 'text'} [variant]
 * @property {boolean} [active]
 * @property {boolean} [fullWidth]
 * @property {boolean} [hidePrint]
 * @property {React.ReactNode} [children]
 * @property {React.MouseEventHandler<HTMLDivElement>} [onClick]
 */

/**
 * @type {import('styled-components').StyledComponent<typeof Clickable, any, ButtonProps>}
 */

export const Wrapper = styled(Clickable).withConfig({
  shouldForwardProp: (prop) =>
    typeof prop === 'string' &&
    !['color', 'size', 'variant', 'active', 'fullWidth', 'hidePrint'].includes(
      prop,
    ),
})`
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  border: 1px solid transparent;
  user-select: none;

  svg,
  .fa {
    font-size: 0.85em;
  }

  > *:not(:last-child) {
    margin-right: 8px;
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:focus-visible {
    outline: 3px solid ${rgba(colors.PRIMARY, 0.2)};
    outline-offset: 0;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.65;
  }

  ${active`
    outline: 0;
    ${({ variant }) =>
      (variant === 'outlined' || variant === 'contained') &&
      `
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    `}
  `}

  ${(p) =>
    p.fullWidth &&
    `
    display: block;
    width: 100%;
  `}

  ${(p) => p.hidePrint && mixins.hidePrint}

  ${({ size }) =>
    (size === 'xs' || size === 'xsmall') &&
    `
    padding: 1px 5px;
    font-size: 14px;
    line-height: 1.5;
    border-radius: 3px;
  `}

  ${({ size }) =>
    (size === 'sm' || size === 'small') &&
    `
    padding: 5px 10px;
    font-size: 14px;
    line-height: 1.5;
    border-radius: 3px;
  `}

  ${({ size }) =>
    (size === 'md' || size === 'medium') &&
    `
    padding: 7px 12px;
    font-size: 16px;
    line-height: 1.428571429;
    border-radius: 4px;
  `}

  ${({ size }) =>
    (size === 'lg' || size === 'large') &&
    `
    padding: 10px 16px;
    font-size: 20px;
    line-height: 1.3333333;
    border-radius: 6px;
  `}

  ${({ color, variant }) =>
    variant === 'contained' &&
    color === 'default' &&
    css`
      background-color: #fff;
      border-color: #ccc;

      &,
      &:hover,
      &:focus {
        color: #333;
      }

      &:hover,
      &:focus {
        background-color: #e6e6e6;
        border-color: #b4b4b4;
      }

      ${active`
        background-color: #e6e6e6;
        border-color: #b4b4b4;

        &:hover, &:focus {
          background-color: #d4d4d4;
          border-color: #999999;
        }
      `}
    `}

  ${({ color, variant }) =>
    variant === 'text' &&
    color === 'default' &&
    css`
      color: ${colors.MED_DARK_GREY};

      &:hover,
      &:focus {
        color: ${colors.DARK_GREY};
      }

      ${active`
        color: ${colors.DARK_GREY};
      `}
    `}

  ${colorVariant('primary', colors.PRIMARY)}
  ${colorVariant('success', colors.GREEN)}
  ${colorVariant('info', colors.BLUE)}
  ${colorVariant('danger', colors.DANGER)}
  ${outlinedColorVariant('default', '#333')}
`
/**
 * @type {React.ForwardRefExoticComponent<ButtonProps & React.RefAttributes<any>>}
 */

const Button = React.forwardRef(({ children, ...rest }, ref) => (
  <Wrapper {...rest} ref={ref}>
    {React.Children.toArray(children).map((child, index) => {
      if (typeof child === 'string') {
        // eslint-disable-next-line react/no-array-index-key
        return <span key={index}>{child}</span>
      }
      return child
    })}
  </Wrapper>
))

Button.propTypes = {
  color: PropTypes.oneOf(['primary', 'success', 'info', 'danger', 'default']),
  size: PropTypes.oneOf([
    'xs',
    'xsmall',
    'sm',
    'small',
    'md',
    'medium',
    'lg',
    'large',
  ]),
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  active: PropTypes.bool,
  fullWidth: PropTypes.bool,
  hidePrint: PropTypes.bool,
  children: PropTypes.node,
}

Button.defaultProps = {
  color: 'default',
  size: 'md',
  variant: 'contained',
  hidePrint: true,
}

export default Button
