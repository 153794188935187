import Privva from 'Privva'
import styled from 'styled-components'
import { ShieldStar } from '@styled-icons/remix-line/ShieldStar'
import Icon from 'components-v2/atoms/Icon'
import { media, mixins } from 'styles'

export const Logo = styled.img`
  max-height: 30px;
`

export const ShieldStarIcon = styled(Icon).attrs({ icon: ShieldStar })`
  color: white;
  font-size: 22px;
`

export const Wrapper = styled.nav`
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  --left-offset: 100px;
  left: var(--left-offset);
  height: 55px;
  z-index: 1030;
  font-size: 16px;
  font-weight: 500;
  --border-color: #e7e7e7;
  --bg-color: ${Privva.Utils.theme.bannerColor};
  background-color: var(--bg-color);
  border-bottom: 1px solid var(--border-color);

  &[data-theme='dark'] {
    --border-color: #ccc;
  }
  ${media.mobile`
    left: 0;
  `}

  ${({ isLoggedIn }) => !isLoggedIn && `--left-offset: 0;`}

  ${mixins.hidePrint}
`

export const Inner = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 0;
  padding-right: 0;

  ${media.mobile`
    display: block;
  `}
`

export const LogoLink = styled.a`
  padding: 7px 15px;
`

export const Top = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  ${media.mobile`
    position: relative;
    flex: 1;
    background-color: var(--bg-color);
    z-index: 1;
  `}
`

export const Collapse = styled.div`
  ${media.nmobile`
    display: flex !important;
    align-items: center;
    height: 100%;
    flex: 1;
  `}

  ${media.mobile`
    position: absolute;
    top: 55px;
    left: 0;
    right: 0;
    background-color: var(--bg-color);
    border-bottom: 1px solid var(--border-color);
    box-shadow: inset 0 1px 0 hsla(0,0%,100%,.1);
    overflow-y: auto;
    max-height: 340px;
    padding: 7px 0;

    &.ease-out {
      overflow: hidden;
      transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    }

    &.duration-300 {
      transition-duration: 300ms;
    }

    &.translate-y-full {
      transform: translateY(-100%);
    }

    &.translate-y-0 {
      transform: translateY(0);
    }
  `}
`
