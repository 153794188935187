import styled from 'styled-components'
import { colors } from 'styles'
import Card from 'components-v2/organisms/Card'

export const Box = styled(Card)`
  margin-bottom: 20px;
`

export const Header = styled.div`
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 15px;
`

export const Info = styled.samp`
  margin-left: 1rem;
  margin-bottom: 1rem;
  display: block;
  font-size: 12px;
`

export const InfoGroup = styled.div`
  color: ${colors.MED_GREY};
`
export const LoadingMessage = styled.h5`
  margin-top: 5px;
`

export const InfoLabel = styled.div`
  font-weight: bold;
  font-size: 13px;
  margin-bottom: 5px;
`
