/* eslint-disable camelcase */
import React, { useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import merge from 'lodash/merge'
import uniqueId from 'lodash/uniqueId'
import * as yup from 'yup'
import Well from 'components-v2/atoms/Well'
import Button from 'components-v2/atoms/Button'
import Icon from 'components-v2/atoms/Icon'
import FormModal from 'components-v2/organisms/FormDialog'
import FormField from 'components-v2/molecules/FormField'
import InputField from 'components-v2/molecules/FormField/InputField'
import { useModal } from 'hooks'
import FieldPreviewModal from '../FieldPreviewModal'
import { AdvancedOptionsBlock } from './styles'

import StringFieldDetails, { stringRules } from './StringFieldDetails'
import NumberFieldDetails, {
  integerRules,
  floatRules,
} from './NumberFieldDetails'
import BooleanFieldDetails from './BooleanFieldDetails'
import SelectFieldDetails from './SelectFieldDetails'
import DateTimeFieldDetails from './DateTimeFieldDetails'
// import UrlFieldDetails from './UrlFieldDetails'
// import ContactFieldDetails from './ContactFieldDetails'

const PlaceholderComponent = () => []
const placeholderRules = () => yup.object({})

const typeDetailComponents = {
  string: { component: StringFieldDetails, rules: stringRules },
  integer: { component: NumberFieldDetails, rules: integerRules },
  float: { component: NumberFieldDetails, rules: floatRules },
  money: { component: NumberFieldDetails, rules: floatRules },
  boolean: { component: BooleanFieldDetails, rules: placeholderRules },
  select: { component: SelectFieldDetails, rules: placeholderRules },
  email: { component: PlaceholderComponent, rules: placeholderRules },
  url: { component: PlaceholderComponent, rules: placeholderRules },
  date: { component: DateTimeFieldDetails, rules: placeholderRules },
  datetime: { component: DateTimeFieldDetails, rules: placeholderRules },
  // time: DateTimeFieldDetails,
  // contact: ContactFieldDetails,
}

const toFormValues = (fieldDef) => {
  let formData = fieldDef
  if (fieldDef.render_opts?.choices) {
    formData = {
      ...fieldDef,
      render_opts: {
        ...fieldDef.render_opts,
        choices: fieldDef.render_opts.choices.map((choice) => ({
          id: uniqueId('temp_'),
          text: choice,
        })),
      },
    }
  }

  return merge(
    {
      description: undefined,
      data_type: 'string',
      type_opts: {
        default: undefined,
        allow_null: true,
        allow_blank: true,
        array: false,
        scale: undefined,
        limit: undefined,
        precision: undefined,
      },
      render_opts: {
        multi: false,
        choices: [],
        group: 'default',
        sort: 0,
        allow_past: true,
        use_toggle: false,
        validations: {
          value: {
            max: undefined,
            min: undefined,
          },
        },
      },
    },
    formData,
  )
}

const fromFormValues = (data) => {
  if (data.render_opts?.choices) {
    return {
      ...data,
      render_opts: {
        ...data.render_opts,
        choices: data.render_opts.choices.map((choice) => choice.text),
      },
    }
  }
  return data
}

const FieldDetailsModal = ({
  fieldDef,
  fieldGroups,
  typeConfigs,
  onSave,
  ...rest
}) => {
  const formikRef = useRef()
  const [openPreviewModal] = useModal(FieldPreviewModal)

  const handlePreview = useCallback(() => {
    if (formikRef.current) {
      openPreviewModal({ fieldDef: fromFormValues(formikRef.current.values) })
    }
  }, [openPreviewModal])

  const type = fieldDef.meta_type || fieldDef.data_type
  const formConfig = useMemo(
    () => ({
      initialValues: toFormValues(fieldDef),
      validationSchema: yup.object().shape({
        description: yup.string().max(255).nullable(),
        data_type: yup.string(),
        type_opts: yup.object({
          default: yup.mixed(),
          allow_null: yup.boolean(),
          allow_blank: yup.boolean(),
          array: yup.boolean(),
          scale: yup.number(),
          limit: yup.number(),
          precision: yup.number(),
        }),
        render_opts: yup.object({
          multi: yup.boolean(),
          choices: yup.array(), // .of(yup.string().max(50)),
          group: yup.string().nullable(),
          allow_past: yup.boolean(),
          validations: yup
            .object({})
            .concat(typeDetailComponents[type].rules(typeConfigs[type])),
        }),
      }),
      innerRef: formikRef,
    }),
    [fieldDef, type, typeConfigs],
  )

  const handleSubmit = useCallback(
    (data) => onSave(fromFormValues(data)),
    [onSave],
  )

  return (
    <FormModal
      {...rest}
      title={fieldDef.name}
      formConfig={formConfig}
      onSubmit={handleSubmit}
      footerLeftContent={
        <Button onClick={handlePreview}>
          <Icon icon="fa fa-search" />
          Preview
        </Button>
      }
    >
      {(formProps) => {
        const [primaryFields, advancedFields] = typeDetailComponents[
          type
        ].component({
          fieldDef,
          typeConfig: typeConfigs[type],
          formProps,
        })

        return (
          <Well>
            {/* Tooltip description */}
            <FormField
              name="description"
              label="Description"
              type={FormField.types.TEXTAREA}
              placeholder="Enter description for tooltip"
            />
            <InputField label="API Name" value={fieldDef.key} disabled />

            {primaryFields}

            <AdvancedOptionsBlock>
              <summary>Advanced Options</summary>

              {advancedFields}

              {/* Group selection */}
              <FormField
                name="render_opts.group"
                label="Group"
                type={FormField.types.SEARCHABLE_SELECT}
                controlProps={{
                  isClearable: true,
                }}
                options={sortBy(
                  map(fieldGroups, (fg, key) => ({
                    label: fg.name,
                    value: key,
                    sort: fg.sort,
                  })),
                  'sort',
                )}
                horizontal
                inline
              />

              {/* Required on intake form? (not there yet) */}
              {/* <FormField
                name="type_opts.allow_blank"
                label="Required"
                type={FormField.types.RADIO_GROUP}
                options={[
                  { label: 'Yes', value: false },
                  { label: 'No', value: true },
                ]}
                horizontal
                inline
              /> */}
            </AdvancedOptionsBlock>
          </Well>
        )
      }}
    </FormModal>
  )
}

FieldDetailsModal.propTypes = {
  fieldDef: PropTypes.object.isRequired,
  fieldGroups: PropTypes.object,
  typeConfigs: PropTypes.object,
  onSave: PropTypes.func,
}

export default FieldDetailsModal
