import { FilterOptionTypes } from 'constants/index'

export const ElementFilterOptions = {
  NEEDS_REVIEW: {
    key: 'ElementFilter.NEEDS_REVIEW',
    type: FilterOptionTypes.SINGLE,
    label: 'Needs Review',
  },
  REQUIRED: {
    key: 'ElementFilter.REQUIRED',
    type: FilterOptionTypes.SINGLE,
    label: 'Required',
  },
  ATTACHMENTS: {
    key: 'ElementFilter.ATTACHMENTS',
    type: FilterOptionTypes.SINGLE,
    label: 'With Attachments',
  },
  UNANSWERED: {
    key: 'ElementFilter.UNANSWERED',
    type: FilterOptionTypes.SINGLE,
    label: 'Not Answered',
  },
  UNSCORED: {
    key: 'ElementFilter.UNSCORED',
    type: FilterOptionTypes.SINGLE,
    label: 'Unscored',
  },
  SCORED: {
    key: 'ElementFilter.SCORED',
    type: FilterOptionTypes.COLORFUL_CHECKBOX_SET,
    label: 'Scored',
  },
}

export const NOTE_MAX_LENGTH = 10000
export const STICKY_HEADER_HEIGHT = 54
export const ASSESSMENT_TABLE_ID_PREFIX = 'table_'
