import styled from 'styled-components'
import { darken, transparentize } from 'polished'
import { colors, fonts } from 'styles'

export const BaseWrapper = styled.div`
  .remirror-theme .ProseMirror {
    > h1,
    > h2,
    > h3 {
      &:not(:first-child) {
        margin-top: 22px;
      }
    }
    > h1,
    > h2,
    > h3,
    > p {
      &:not(:last-child) {
        margin-bottom: 11px;
      }
    }
  }
`

export const Wrapper = styled(BaseWrapper)`
  .remirror-editor-wrapper {
    padding: 0;
  }
  .remirror-theme .ProseMirror {
    padding: 0;
    box-shadow: none !important;
    overflow: initial;
    min-height: initial;
  }
`

export const theme = {
  color: {
    primary: colors.PRIMARY,
    active: {
      primary: darken(0.15, colors.PRIMARY),
    },
    hover: {
      primary: darken(0.075, colors.PRIMARY),
    },
    outline: transparentize(0.6, colors.PRIMARY),
  },
  fontFamily: {
    default: fonts.PRIMARY,
    heading: fonts.SECONDARY,
  },
  lineHeight: {
    heading: 1.1,
    default: 1.428571429,
  },
}
