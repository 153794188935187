import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import omit from 'lodash/omit'
import A from 'components-v2/atoms/A'
import Button from 'components-v2/atoms/Button'
import PrintButton from 'components-v2/molecules/PrintButton'
import FilterButtonGroup from 'components-v2/molecules/FilterButtonGroup'
import Toolbar from 'components-v2/molecules/Table/Toolbar'
import PageHeader from 'components-v2/organisms/PageHeader'
import Transition from 'components-v2/molecules/Transition'
import AssessmentElementsViewWithSectionPicker from 'components/shared/AssessmentElementsViewWithSectionPicker'
import { toLocalTimeString } from 'utils/date'
import { getPageUrl } from 'utils/url'
import { AssessmentStatusTypes } from 'constants/index'
import AutoAnswer from './AutoAnswer'
import ResponseRow from './ResponseRow'
import OverviewHeader from './OverviewHeader'
import {
  ImportButtonContainer,
  Description,
  AutosavedNote,
  FilterFormInput,
  StyledToolbar,
  ToolbarSubmitButtonWrapper,
  StyledProgress,
  SectionSelectionWrapper,
  SectionSelect,
  SectionProgressWrapper,
  ResponseTable,
} from './styles'

// Constants
import {
  ElementFilterOptions,
  ASSESSMENT_TABLE_ID_PREFIX,
  highlightTimeout,
} from './constants'

const getFilterOptions = (includeIncomplete) => {
  if (!includeIncomplete) {
    return omit(ElementFilterOptions, 'INCOMPLETE')
  }
  return ElementFilterOptions
}

const AssessmentView = ({
  assessment,
  assessmentEndpointUrl,
  socketId,
  hideHeader,
  totalQuestions,
  incompleteQuestions,
  answeredQuestions,
  selectedSectionId,
  filteredElements,
  currentOrganization,
  organizationPath,
  elementFilter,
  filterText,
  incompleteAttempted,
  autoSavedAt,
  autoAnswerableVendors,
  autoAnswerDismissed,
  autoAnswerResult,
  autoAnswerError,
  highlightedRows,
  currentUser,
  onElementAnswerChange,
  onElementCommentSubmit,
  onElementAttachmentsChange,
  onElementRowClick,
  onElementFilterChange,
  onFilterTextChange,
  onAssessmentCommentSubmit,
  onAssessmentAttachmentsChange,
  onRequestExtension,
  onAssessmentSubmit,
  onViewAssessmentStatusButtonClick,
  onSectionChange,
  onAutoAnswerConfirm,
  onAutoAnswerClose,
  onImportResponsesButtonClick,
}) => {
  const reviewedOrSubmitted =
    assessment.status === AssessmentStatusTypes.SUBMITTED ||
    assessment.status === AssessmentStatusTypes.REVIEWED
  const canAutoAnswer =
    assessment.auto_answerable &&
    autoAnswerableVendors?.length > 0 &&
    !answeredQuestions
  const breadcrumbs = assessment.project
    ? [
        {
          href: getPageUrl('vendorProject', { id: assessment.project.id }),
          title: assessment.project.name,
        },
        {
          active: true,
          title: assessment.name_for_vendor,
        },
      ]
    : undefined
  return (
    <div>
      {!hideHeader && (
        <PageHeader
          title={assessment.name_for_vendor}
          breadcrumbs={breadcrumbs}
        >
          <PrintButton color="primary" />
        </PageHeader>
      )}
      {assessment.assessment_template.allow_vendor_imports &&
        !assessment.disabled && (
          <ImportButtonContainer>
            <Button onClick={onImportResponsesButtonClick}>
              <i className="fa fa-table" />
              Import Responses
            </Button>
          </ImportButtonContainer>
        )}
      {!autoAnswerDismissed &&
        (canAutoAnswer || autoAnswerResult || autoAnswerError) && (
          <AutoAnswer
            vendorList={autoAnswerableVendors}
            result={autoAnswerResult}
            error={autoAnswerError}
            onConfirm={onAutoAnswerConfirm}
            onClose={onAutoAnswerClose}
          />
        )}
      <Description>{assessment.assessment_template.description}</Description>
      <OverviewHeader
        assessment={assessment}
        assessmentEndpointUrl={assessmentEndpointUrl}
        newContributorButtonVisible={
          currentOrganization.id === assessment.vendor_id
        }
        addNewContributorUrl={organizationPath}
        onAssessmentCommentSubmit={onAssessmentCommentSubmit}
        onAssessmentAttachmentsChange={onAssessmentAttachmentsChange}
        onRequestExtension={onRequestExtension}
      />
      <AssessmentElementsViewWithSectionPicker
        elements={filteredElements}
        sections={assessment.sections}
        selectedSectionId={selectedSectionId}
        translatable={assessment.translatable}
        useCustomKeys={get(assessment, 'assessment_template.use_custom_keys')}
        tableId={ASSESSMENT_TABLE_ID_PREFIX + assessment.id}
        renderQuestionRow={(props) => (
          <Transition.Highlight
            // eslint-disable-next-line react/prop-types
            in={highlightedRows[props.element.id]}
            timeout={highlightTimeout}
          >
            <ResponseRow
              {...props}
              assessmentId={assessment.id}
              socketId={socketId}
              disabled={assessment.disabled || reviewedOrSubmitted}
              incompleteAttempted={incompleteAttempted}
              currentUser={currentUser}
              onAnswerChange={onElementAnswerChange}
              onCommentSubmit={onElementCommentSubmit}
              onAttachmentsChange={onElementAttachmentsChange}
              onRowClick={onElementRowClick}
            />
          </Transition.Highlight>
        )}
        renderStickyHeader={() => (
          <>
            <StyledToolbar className="hidden-print">
              <Toolbar.Group>
                <FilterButtonGroup
                  selected={elementFilter}
                  filterOptions={getFilterOptions(incompleteAttempted)}
                  onChange={onElementFilterChange}
                />
                <FilterFormInput
                  type="text"
                  value={filterText}
                  placeholder="Filter"
                  onChange={onFilterTextChange}
                />
              </Toolbar.Group>
              <Toolbar.Group gap={15} alignItems="flex-start">
                {autoSavedAt && (
                  <AutosavedNote>
                    Autosaved: {toLocalTimeString(autoSavedAt, 'h:mm aaa')}
                  </AutosavedNote>
                )}
                <Toolbar.Group gap={15} alignItems="flex-start">
                  <A onClick={onViewAssessmentStatusButtonClick}>
                    View Details
                  </A>
                  <StyledProgress
                    completed={answeredQuestions}
                    incomplete={incompleteQuestions}
                    total={totalQuestions}
                  />
                </Toolbar.Group>
                <ToolbarSubmitButtonWrapper>
                  {reviewedOrSubmitted ? (
                    <span>Submitted!</span>
                  ) : (
                    <Button
                      color="primary"
                      disabled={assessment.disabled || answeredQuestions === 0}
                      onClick={onAssessmentSubmit}
                    >
                      Submit Assessment
                    </Button>
                  )}
                </ToolbarSubmitButtonWrapper>
              </Toolbar.Group>
            </StyledToolbar>
            <SectionSelectionWrapper>
              <SectionSelect
                value={selectedSectionId || ''}
                onChange={(e) => onSectionChange(e.target.value)}
                onScroll={(e) => e.stopPropagation()}
              >
                <option value="">All Questions</option>
                {assessment.sections.map((section) => (
                  <option
                    className={assessment.translatable ? '' : 'no-lang'}
                    key={section.id}
                    value={section.id}
                  >
                    {section.key} {section.label}
                  </option>
                ))}
              </SectionSelect>
              {selectedSectionId && (
                <SectionProgressWrapper>
                  {assessment.progress[selectedSectionId] && (
                    <span>
                      {assessment.progress[selectedSectionId].answered} of{' '}
                      {assessment.progress[selectedSectionId].questions}{' '}
                      questions complete
                    </span>
                  )}
                </SectionProgressWrapper>
              )}
            </SectionSelectionWrapper>
          </>
        )}
        renderTable={(props) => (
          <ResponseTable
            {...props}
            submitted={assessment.status === AssessmentStatusTypes.SUBMITTED}
          />
        )}
        onSectionChange={onSectionChange}
      />
    </div>
  )
}

AssessmentView.propTypes = {
  assessment: PropTypes.object.isRequired,
  assessmentEndpointUrl: PropTypes.string.isRequired,
  socketId: PropTypes.string,
  hideHeader: PropTypes.bool,
  filteredElements: PropTypes.array.isRequired,
  totalQuestions: PropTypes.number.isRequired,
  answeredQuestions: PropTypes.number.isRequired,
  incompleteQuestions: PropTypes.number.isRequired,
  selectedSectionId: PropTypes.string,
  currentOrganization: PropTypes.object.isRequired,
  organizationPath: PropTypes.string.isRequired,
  elementFilter: PropTypes.object.isRequired,
  filterText: PropTypes.string.isRequired,
  incompleteAttempted: PropTypes.bool.isRequired,
  autoSavedAt: PropTypes.instanceOf(Date),
  autoAnswerableVendors: PropTypes.array,
  autoAnswerDismissed: PropTypes.bool,
  autoAnswerResult: PropTypes.object,
  autoAnswerError: PropTypes.bool,
  highlightedRows: PropTypes.object.isRequired,
  currentUser: PropTypes.string.isRequired,
  onElementAnswerChange: PropTypes.func.isRequired,
  onElementCommentSubmit: PropTypes.func.isRequired,
  onElementAttachmentsChange: PropTypes.func.isRequired,
  onElementRowClick: PropTypes.func.isRequired,
  onElementFilterChange: PropTypes.func.isRequired,
  onFilterTextChange: PropTypes.func.isRequired,
  onAssessmentCommentSubmit: PropTypes.func.isRequired,
  onAssessmentAttachmentsChange: PropTypes.func.isRequired,
  onRequestExtension: PropTypes.func.isRequired,
  onAssessmentSubmit: PropTypes.func.isRequired,
  onViewAssessmentStatusButtonClick: PropTypes.func.isRequired,
  onSectionChange: PropTypes.func.isRequired,
  onAutoAnswerConfirm: PropTypes.func.isRequired,
  onAutoAnswerClose: PropTypes.func.isRequired,
  onImportResponsesButtonClick: PropTypes.func.isRequired,
}

export default AssessmentView
