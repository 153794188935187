import styled from 'styled-components'
import IconButton from 'components-v2/atoms/IconButton'
import { Wrapper as ButtonWrapper } from 'components-v2/atoms/Button'

export const Inner = styled.div`
  position: relative;
  display: flex;
  align-items: start;
  justify-content: center;
  min-height: 100%;

  > .ease-out {
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  > .duration-300 {
    transition-duration: 300ms;
  }

  > .translate-y-full {
    transform: translateY(-100%);
  }

  > .translate-y-0 {
    transform: translateY(0);
  }

  > .opacity-0 {
    opacity: 0;
  }

  > .opacity-100 {
    opacity: 1;
  }
`

export const Backdrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
`

export const Panel = styled.div`
  position: relative;
  width: auto;
  max-width: 95%;
  margin: 10px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);

  @media (min-width: 768px) {
    width: 600px;
    ${({ size }) => ['sm', 'small'].includes(size) && `width: 300px;`}
    ${({ size }) => ['md', 'medium'].includes(size) && `width: 600px;`}
    margin: 30px auto;
  }

  ${({ size }) =>
    ['lg', 'large'].includes(size) &&
    `
    @media (min-width: 992px) {
      width: 900px;
    }
  `}
`

export const DialogTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
`

export const DialogHeaderText = styled.h4`
  margin: 0;
  font-size: 18px;
  line-height: 1.4;
`

export const DialogCloseButton = styled(IconButton).attrs({
  icon: 'fa fa-times',
})`
  font-size: 17px;
`

export const DialogContent = styled.div.attrs({
  'data-autofocus-inside': true, // This is very important to set initial focus inside dialog content, not to the X close icon button
})`
  padding: 15px;
`

export const DialogFooter = styled.div`
  text-align: right;
  padding: 15px;
  border-top: 1px solid #e5e5e5;

  ${ButtonWrapper} + ${ButtonWrapper} {
    margin-left: 5px;
  }
`
