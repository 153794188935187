import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components-v2/atoms/Button'
import { Row } from 'components-v2/organisms/Layout'
import PageHeader from 'components-v2/organisms/PageHeader'
import { getPageUrl } from 'utils/url'
import CriticalEventsCard from './CriticalEventsCard'
import OpenIssuesCard from './OpenIssuesCard'
import RiskHealthCard from './RiskHealthCard'
import VendorsBySourceRangeCard from './VendorsBySourceRangeCard'
import TableCard from './TableCard'
import RiskRatingLabel from '../RiskRatingLabel'
import SummaryCard from './SummaryCard'
import { recentChangesTableColumns, securityToolsTableColumns } from './tables'
import {
  Wrapper,
  ManageLink,
  PlusCircleIcon,
  StyledCol as Col,
  ColTitle,
} from './styles'

const View = ({ data }) => {
  const updatedAt = new Date()
  return (
    <Wrapper>
      <PageHeader
        title="SecuritySense Dashboard"
        sub={
          <>
            Currently monitoring {data.active_vendors} vendors.{' '}
            <ManageLink href="#">Manage</ManageLink>
          </>
        }
      >
        <Button color="primary">
          <PlusCircleIcon />
          Add a vendor
        </Button>
      </PageHeader>
      <Row $equalHeight>
        <Col md={4}>
          <RiskHealthCard updatedAt={updatedAt} data={data.average_score} />
        </Col>
        <Col md={8}>
          <VendorsBySourceRangeCard
            updatedAt={data.vendors_by_score_range.updated_at}
            data={data.vendors_by_score_range}
          />
        </Col>
      </Row>
      <Row $equalHeight>
        <Col md={4}>
          <CriticalEventsCard updatedAt={updatedAt} data={data.event_summary} />
          <OpenIssuesCard updatedAt={updatedAt} data={data.open_issues} />
        </Col>
        <Col md={4}>
          <TableCard
            title="Recent Changes"
            url={getPageUrl('clientVendors')}
            getRowId={(row) => row.name}
            data={data.recent_changes.vendor_list}
            columns={recentChangesTableColumns}
          />
        </Col>
        <Col md={4}>
          <TableCard
            title="Top Security Tools"
            url="#"
            getRowId={(row) => row.name}
            data={data.top_sources.source_list}
            columns={securityToolsTableColumns}
          />
        </Col>
      </Row>
      <h3>Risk Health Summary</h3>
      <Row $equalHeight>
        {data.category_summaries.map((collection) => (
          <Col key={collection.category} md={4}>
            <ColTitle>
              {collection.category} <RiskRatingLabel value={collection.score} />
            </ColTitle>
            <SummaryCard collection={collection} updatedAt={updatedAt} />
          </Col>
        ))}
      </Row>
    </Wrapper>
  )
}

View.propTypes = {
  data: PropTypes.object.isRequired,
}

export default View
