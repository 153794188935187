/* eslint-disable react/no-danger */
import React from 'react'
import * as yup from 'yup'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import Button from 'components-v2/atoms/Button'
import Well from 'components-v2/atoms/Well'
import PrintButton from 'components-v2/molecules/PrintButton'
import Alert from 'components-v2/molecules/Alert'
import FormField from 'components-v2/molecules/FormField'
import { Row, Col } from 'components-v2/organisms/Layout'
import PageHeader from 'components-v2/organisms/PageHeader'
import googleBadgeImg from 'images/google_badge_web_generic.png'
import appstoreImg from 'images/appstore-lrg.svg'
import { withReactQuery } from 'hocs'
import { getPageUrl } from 'utils/url'
import { toLocalTimeString } from 'utils/date'
import useRegisterTwoFactorAuth from '../shared/useRegisterTwoFactorAuth'
import { KeyCode, FieldWrapper, Codes } from './styles'
import useDisableTwoFactorAuth from '../shared/useDisableTwoFactorAuth'

const initialValues = {
  pin_code: '',
}

const validationSchema = yup.object().shape({
  pin_code: yup.string().nullable().required('This is required'),
})

const View = ({ qrCode, accountString, otpSecret, otpRequiredForLogin }) => {
  const { codes, registered, registerTwoFactorAuth } =
    useRegisterTwoFactorAuth()

  const disableTwoFactorAuthDialog = useDisableTwoFactorAuth()

  if (registered) {
    return (
      <div>
        <PageHeader title="Two-Factor Authentication">
          <PrintButton color="primary" />
        </PageHeader>
        <Alert color="success">
          Congratulations! You have enabled Two-factor Authentication!
        </Alert>
        <p>
          Should you ever lose your phone, each of these recovery codes can be
          used one time each in place of a two factor code to regain access to
          your account. Please save them in a safe place, or you&nbsp;
          <b>will</b>&nbsp; lose access to your account.
        </p>
        <Codes>
          <ul>
            {codes.map((code) => (
              <li key={code}>{code}</li>
            ))}
          </ul>
        </Codes>
        <p>Codes generated on: {toLocalTimeString(new Date())}</p>
        <Button
          color="primary"
          href={getPageUrl('user')}
          className="hidden-print"
        >
          Proceed
        </Button>
      </div>
    )
  }

  return (
    <div>
      <PageHeader title="Two-Factor Authentication" />
      <h4>Register Two-Factor Authentication App</h4>
      <br />
      {otpRequiredForLogin ? (
        <>
          <p>
            You&apos;ve already enabled two-factor authentication using mobile
            authenticator applications. In order to register a different device,
            you must first disable two-factor authentication.
          </p>
          <Button color="danger" onClick={disableTwoFactorAuthDialog}>
            Disable two-factor authentication
          </Button>
        </>
      ) : (
        <>
          <h5>STEP 1: DOWNLOAD</h5>
          <p>
            Use an app on your mobile device to enable two-factor authentication
            (2FA).
          </p>
          <p>
            You&apos;ll need a two-factor app, like Google Authenticator, on
            your martphone to proceed.
          </p>
          <p>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
            >
              <img width="140" alt="authenticator app" src={googleBadgeImg} />
            </a>
            &nbsp;
            <a
              target="_blank"
              href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8"
            >
              <img width="140" alt="authenticator app" src={appstoreImg} />
            </a>
            <h5 className="top30">STEP 2: SCAN</h5>
            <p>
              After you have downloaded the Google Authenticator application
              from App Store or Google Play Store, scan this code:
            </p>
            <Row>
              <Col sm={3} id="qrcode-2fa-svg">
                <div dangerouslySetInnerHTML={{ __html: qrCode }} />
              </Col>
              <Col sm={9}>
                <Well>
                  <p>Can&apos;t scan the code?</p>
                  <p>
                    To add the entry manually, provide the following details to
                    the application on your phone.
                  </p>
                  <p>Account: {accountString}</p>
                  <p>
                    Key:{' '}
                    <KeyCode>
                      {otpSecret.split('').map((e, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <span key={index}>{e}</span>
                      ))}
                    </KeyCode>
                  </p>
                  <p>Time based: Yes</p>
                </Well>
              </Col>
            </Row>
            <h5 className="top30">STEP 3: VERIFY</h5>
            <p>
              To enable two-factor authentication, enter the 6-digit token from
              your two-factor app and click register:
            </p>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={registerTwoFactorAuth}
            >
              {(formProps) => (
                <form onSubmit={formProps.handleSubmit}>
                  <FieldWrapper>
                    <FormField name="pin_code" label="Pin code" />
                  </FieldWrapper>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={formProps.isSubmitting}
                  >
                    Register with two-factor app
                  </Button>
                </form>
              )}
            </Formik>
          </p>
        </>
      )}
    </div>
  )
}

View.propTypes = {
  qrCode: PropTypes.string,
  accountString: PropTypes.string,
  otpSecret: PropTypes.string,
  otpRequiredForLogin: PropTypes.bool,
}

export default withReactQuery()(View)
