import styled from 'styled-components'
import { Check } from '@styled-icons/fa-solid/Check'
import { Close } from '@styled-icons/material/Close'
import Icon from 'components-v2/atoms/Icon'
import Tooltip from 'components-v2/molecules/Tooltip'
import Card from 'components-v2/organisms/Card'
import { colors } from 'styles'

// TODO: shamelessly copied from AutoAnswer header box just to put something in place,
//       so could stand a bit of cleanup once actual UI direction is decided
export const Wrapper = styled(Card).attrs({ padding: true, bordered: true })`
  position: relative;
  padding: 25px;
  color: ${colors.PRIMARY};
  border-color: currentColor;
  background: transparent;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: inherit;
    background: currentColor;
    opacity: 0.1;
    z-index: -1;
  }

  ${(props) =>
    props.error &&
    `
    color: #DC1B20;
  `}
`

export const Inner = styled.div`
  position: relative;
  padding-left: 105px;
  padding-top: 5px;
  min-height: 80px;
`

export const Heading = styled.h3`
  color: inherit;
  margin: 0 0 10px 0;
`

export const Content = styled.div`
  color: ${colors.HEADING};
`

export const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 2em;
  height: 2em;
  border: 1px solid currentColor;
  border-radius: 100%;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CheckIcon = styled(Icon).attrs({ icon: Check })``
export const CloseIcon = styled(Icon).attrs({ icon: Close })``

export const CheckWrapper = styled.div`
  margin: 10px 30px;
  display: flex;
`

export const QuestionIcon = styled.i.attrs({
  className: 'fa fa-question-circle hidden-print',
})`
  margin-left: 5px;
  color: ${colors.GREY};
  font-size: 0.9em;
`

export const InfoTooltip = styled(Tooltip)`
  max-width: 500px;
  text-align: left;
`

export const TooltipBlock = styled.div`
  margin-left: 10px;
  margin-bottom: 0px;
  padding-bottom: 5px;
  padding-top: 5px;
`

export const FileIcon = styled.i.attrs({ className: 'fa fa-file-text-o' })`
  width: 0.9em;
  height: 0.9em;
  margin-left: 5px;
  margin-right: 5px;
`

export const StubDetailRow = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
`

export const StubDetailLabel = styled.span`
  font-weight: bold;
  width: 150px;
`

export const StubDetailBody = styled.div`
  width: 325px;

  /* p {
    text-indent: 25px;
  } */
`

export const FileList = styled.div`
  margin-top: 7px;
  margin-bottom: 7px;
`

export const FileRow = styled.div`
  display: flex;
`

export const FileItem = styled.a`
  ${(props) => props.deleted && `text-decoration: line-through;`}
`
