import { EventEmitter } from 'events'
import reject from 'lodash/reject'

const CHANGE = 'change'
const DELAY = 1000

class GlobalLoaderManager extends EventEmitter {
  constructor() {
    super()
    this.loaders = []
  }

  showLoader(props = {}) {
    const { withDelay, ...options } = props
    const id = setTimeout(
      () => {
        this.setLoaders([...this.loaders, { id, options }])
      },
      withDelay ? DELAY : 0,
    )
    return id
  }

  hideLoader(id) {
    clearTimeout(id)
    this.setLoaders(reject(this.loaders, { id }))
  }

  setLoaders(loaders) {
    this.loaders = loaders
    this.emit(CHANGE, this.loaders)
  }

  addChangeListener(callback) {
    this.addListener(CHANGE, callback)
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE, callback)
  }
}

export default new GlobalLoaderManager()
