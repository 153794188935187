import styled from 'styled-components'
import A from 'components-v2/atoms/A'
import Button from 'components-v2/atoms/Button'
import Checkbox from 'components-v2/atoms/Checkbox'
import Card from 'components-v2/organisms/Card'
import { colors, mixins } from 'styles'

export const CHART_HEIGHT = 290

export const Wrapper = styled(Card)`
  font-size: 15px;
`

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`

export const Title = styled.h3`
  color: ${colors.HEADING};
  margin: 0;
`

export const Toolbox = styled.div`
  display: flex;
  align-items: center;
`

export const PrintLink = styled(A).attrs({ useGrey: true, underline: true })`
  font-size: 14px;
  margin-right: 10px;
  vertical-align: middle;
`

export const PrintIcon = styled.i.attrs({ className: 'fa fa-print' })`
  margin-right: 5px;
`

export const OptionCheckbox = styled(Checkbox)`
  margin-right: 10px;
`

export const IconButton = styled(Button).attrs((p) => ({
  size: 'xsmall',
  color: p.active ? 'primary' : 'default',
}))`
  width: 28px;
  height: 28px;
  margin-left: 7px;
`

export const ViewAllLinkContainer = styled(Card.Segment)`
  padding-top: 6px;
  padding-bottom: 7px;
  background: rgba(241, 243, 247, 0.5);
`

export const ViewAllLink = styled(A).attrs({ useGrey: true })`
  ${mixins.smallHeader}
  font-size: 11px;
  text-decoration: none;
`

export const Placeholder = styled.div`
  height: ${CHART_HEIGHT + 40}px;
  padding-top: ${(CHART_HEIGHT + 40) / 2}px;
  text-align: center;
`

export const ChartWrapper = styled.div`
  background: #f8f8f8;
  padding: 25px 25px 15px 15px;

  .recharts-tooltip-wrapper {
    pointer-events: initial !important;
    transform: none !important;
    top: 30%;
  }
`

export const TooltipWrapper = styled.div`
  padding: 10px;
  background: #fff;
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;
  margin-left: ${(props) => props.coordinate.x}px;
`
