import findIndex from 'lodash/findIndex'
import filter from 'lodash/filter'
import map from 'lodash/map'
import find from 'lodash/find'
import reduce from 'lodash/reduce'
import union from 'lodash/union'
import some from 'lodash/some'
import isEqual from 'lodash/isEqual'
import toNumber from 'lodash/toNumber'
import { getPageUrl, getEndpointUrl } from 'utils/url'
import {
  isSectionElement,
  isNotSectionElement,
  getSectionsFromElements,
  getQuestionsFromElements,
  getElementById,
} from 'lib/assessment'
import {
  AssessmentTemplateRuleTypes,
  AssessmentTemplateRuleAttributeTypes,
  // ExportFormatTypes,
} from 'constants/index'
import { QuestionPlacementTypes } from './constants'

export const isSection = isSectionElement
export const isNotSection = isNotSectionElement
export {
  getElementById,
  filterElementsById,
  getSectionsFromElements,
  getQuestionsFromElements,
} from 'lib/assessment'

export const getTemplatesPageUrl = () => getPageUrl('clientAssessmentTemplates')

export const getVendorPageUrl = (id) => getPageUrl('clientVendor', { id })

export const getClientAssessmentTemplateEndpointUrl = (id) =>
  getEndpointUrl('clientAssessmentTemplate', { id })

export const getSectionSelectOptions = (elements) => {
  const headings = getSectionsFromElements(elements)
  return map(headings, (el) => ({
    label: `${el.key} - ${el.label}`,
    value: el.id,
  }))
}

export const getQuestionSelectOptions = (elements) => {
  const questions = getQuestionsFromElements(elements)
  return map(questions, (el) => ({
    label: `${el.key} - ${el.label}`,
    value: el.id,
  }))
}

// Get new constructed elements data with the updated element
export const setElementToElements = (element, elements) => {
  if (elements.some((e) => e.id === element.id)) {
    return elements.map((e) => {
      if (e.id === element.id) {
        return {
          ...element,
          key: element.key || e.key,
          natural_key: element.natural_key || e.natural_key,
        }
      }
      return e
    })
  }
  return elements
}

export const sortElements = (elements) =>
  // this should be faster and a lot less work
  elements.sort((a, b) => {
    const aBits = a.natural_key.split('.').map((v) => parseInt(v, 10))
    const bBits = b.natural_key.split('.').map((v) => parseInt(v, 10))
    return aBits[0] === bBits[0] ? aBits[1] - bBits[1] : aBits[0] - bBits[0]
  })

// Re-populate parent_id and sort_order properties of the elements based on array index
export const repopulateElementSortOrders = (elements) => {
  let parentSortOrder = 0
  let childSortOrder = 0
  let parentId = null
  return map(elements, (element) => {
    // If meet new SECTION_HEADING element, reset childSortOrder to zero
    if (isSection(element)) {
      childSortOrder = 0
      parentId = element.id
      parentSortOrder += 1
      return {
        ...element,
        sort_order: parentSortOrder,
      }
    }
    childSortOrder += 1
    return {
      ...element,
      parent_id: parentId,
      sort_order: childSortOrder,
    }
  })
}

// Generate key (for instance 2.3) where new element will be added
export const getKeyOfEntryPoint = (elements, options) => {
  const sortedElements = sortElements(elements)
  const sortedSections = getSectionsFromElements(sortedElements)
  switch (options.placement) {
    case QuestionPlacementTypes.BEFORE_QUESTION: {
      const relativeQuestion = getElementById(
        sortedElements,
        options.relative_question_id,
      )
      const parentIndex = findIndex(sortedSections, {
        id: relativeQuestion.parent_id,
      })
      return {
        parentIndex,
        childIndex: relativeQuestion.sort_order,
      }
    }
    case QuestionPlacementTypes.AFTER_QUESTION: {
      const relativeQuestion = getElementById(
        sortedElements,
        options.relative_question_id,
      )
      const parentIndex = findIndex(sortedSections, {
        id: relativeQuestion.parent_id,
      })
      return {
        parentIndex,
        childIndex: relativeQuestion.sort_order + 1,
      }
    }
    default: {
      const siblings = filter(sortedElements, {
        parent_id: options.parent_id,
      })
      const parentIndex = findIndex(sortedSections, {
        id: options.parent_id,
      })
      return {
        parentIndex,
        childIndex:
          siblings && siblings.length > 0
            ? siblings[siblings.length - 1].sort_order + 1
            : 0,
      }
    }
  }
}

export const decorateQuestions = (questions) =>
  map(questions, (question) => {
    const { choices, ...rest } = question
    if (choices) {
      rest.choices_attributes = choices
    }
    return rest
  })

export const hasRootQuestions = (elements) =>
  !!find(elements, (element) => isNotSection(element) && !element.parent_id)

// Get ids of elements which have skip conditions
export const getElementIdsWithSkipConditions = (rules) =>
  reduce(
    rules,
    (elementIds, rule) => {
      if (
        rule.type === AssessmentTemplateRuleTypes.ALTER_ATTRIBUTE &&
        rule.attribute === AssessmentTemplateRuleAttributeTypes.SKIP
      ) {
        return union(elementIds, rule.targets)
      }
      return elementIds
    },
    [],
  )

export const screenChoiceTempIds = (choices) =>
  map(choices, ({ id, ...rest }) => ({
    ...rest,
    id: id.startsWith('temp_') ? undefined : id,
  }))

export const checkNewElementDataForTrouble = (formData, element) => {
  let problems = []
  if (element.question_automations.length === 0) {
    return []
  }

  // Problem: changing type to text question if choice-based
  // automations already exist
  const typeChange =
    formData.type === 'TextQuestion' &&
    some(element.question_automations, ['trigger_type', 'choices'])
  if (typeChange) {
    problems = problems.concat(
      'Text questions cannot support question automations based on choice selection',
    )
  }

  // Problem: making changes to available choices if choice-based
  // automations already exist
  const oldChoices = map(element.choices, 'id').sort()
  const newChoices = map(formData.choices_attributes, 'id').sort()
  const choiceChange =
    some(element.question_automations, ['trigger_type', 'choices']) &&
    oldChoices.length > 0 &&
    formData.choices_attributes &&
    !isEqual(oldChoices, newChoices)
  if (choiceChange) {
    problems = problems.concat(
      'Changes in available choices may affect question automations that rely on them',
    )
  }

  // Problem: setting weight to zero if score-based automations exist
  const zeroWeightChange =
    toNumber(formData.weight) === 0 &&
    some(element.question_automations, ['trigger_type', 'score'])
  if (zeroWeightChange) {
    problems = problems.concat(
      'Questions with zero score weight cannot have question automations based on assigned score',
    )
  }

  // return (typeChange || choiceChange || textWeightChange || multiWeightChange);
  return problems
}
