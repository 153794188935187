import styled from 'styled-components'
import Icon from 'components-v2/atoms/Icon'
import { EyeSlash } from '@styled-icons/fa-solid/EyeSlash'
import { Cog } from '@styled-icons/fa-solid/Cog'
import { Comment } from '@styled-icons/fa-solid/Comment'
import { Plus } from '@styled-icons/fa-solid/Plus'
import { FileDownload } from '@styled-icons/fa-solid/FileDownload'
import { colors, mixins } from 'styles'

export const Row = styled.div`
  display: flex;
  page-break-inside: avoid;

  &[data-skipped='true'] {
    opacity: 0.75;
  }

  body:not(.include-skipped-questions-on-print) &[data-skipped='true'] {
    ${mixins.hidePrint}
  }

  > * {
    padding: 8px;
  }
`

export const KeyColumn = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'customKey',
})`
  flex: none;
  width: ${(p) => (p.customKey ? 110 : 52)}px;
`

export const QuestonColumn = styled.div`
  flex: 1;
  min-width: 0;
  overflow-wrap: break-word;
`

export const ReviewScoreColumn = styled.div`
  flex: none;
  width: 120px;

  body.hide-question-ratings-on-print & {
    ${mixins.hidePrint}
  }
`

export const ActionColumn = styled.div`
  flex: none;
  width: 240px;

  body.hide-comments-on-print & {
    ${mixins.hidePrint}
  }
`

export const QuestionLabel = styled.div`
  font-weight: bold;
  white-space: pre-wrap;
`

export const AnswerFormWrapper = styled.div`
  margin: 8px 0;
`

export const CommentWrapper = styled.div`
  white-space: pre-wrap;
  padding-left: 22px;
  position: relative;

  > svg {
    position: absolute;
    left: 0;
    top: 3px;
  }
`

export const ActionWrapper = styled.div`
  padding-bottom: 8px;
  padding-left: 6px;
  overflow-wrap: break-word;
`

export const ReviewScoreWrapper = styled.div`
  > * {
    margin-right: 5px;
  }
`

export const Autoscored = styled.span`
  ${mixins.hidePrint}
`

export const NeedsReviewLabel = styled.span`
  position: relative;
  display: inline-block;
  width: 90px;
  height: 18px;
  font-size: 75%;
  font-weight: bold;
  line-height: 18px;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: top;
  border-radius: 0.25em;
  background-color: #e4b93f;
  ${mixins.hidePrint}

  &:before {
    content: 'Needs Review';
    position: absolute;
    left: 0;
    right: 0;
  }
`

export const MarkReviewedButton = styled.a`
  position: relative;
  display: inline-block;
  width: 90px;
  height: 18px;
  font-size: 75%;
  font-weight: bold;
  line-height: 18px;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: top;
  border-radius: 0.25em;
  cursor: pointer;
  background-color: #e4b93f;
  ${mixins.hidePrint}

  &:before {
    content: 'Needs Review';
    position: absolute;
    left: 0;
    right: 0;
  }

  &:hover {
    color: #fff;
    &:before {
      content: 'Mark Reviewed';
    }
  }
`

export const CogIcon = styled(Icon)
  .attrs({ icon: Cog })
  .withConfig({
    shouldForwardProp: (prop) => prop !== 'isReviewed',
  })`
  color: ${(props) => (props.isReviewed ? '#888' : '#333')};
  ${mixins.hidePrint}
`
export const CommentIcon = styled(Icon)
  .attrs({ icon: Comment })
  .withConfig({
    shouldForwardProp: (prop) => prop !== 'isReviewed',
  })`
  color: ${(props) => (props.isReviewed ? '#888' : '#333')};
`
export const EyeSlashIcon = styled(Icon).attrs({ icon: EyeSlash })``
export const PlusIcon = styled(Icon).attrs({ icon: Plus })`
  color: #333;
`
export const FileDownloadIcon = styled(Icon)
  .attrs({ icon: FileDownload })
  .withConfig({
    shouldForwardProp: (prop) => prop !== 'hasAttachments',
  })`
  ${(props) => props.hasAttachments && `color: ${colors.PRIMARY};`}
  margin-bottom: 5px;
  ${mixins.hidePrint}
`

export const CreateIssueWrapper = styled.span`
  ${mixins.hidePrint}
`
