import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { EditorComponent, Remirror, ThemeProvider } from '@remirror/react'
import { AllStyledComponent } from '@remirror/styles/styled-components'
import '@remirror/styles/all.css'
import { useCurrent } from 'hooks'
import useMarkdownRemirror from './useMarkdownRemirror'
import { Wrapper, theme } from './styles'

const Markdown = ({ content, ...rest }) => {
  const { manager, getContext } = useMarkdownRemirror()
  const getContextRef = useCurrent(getContext)

  useEffect(() => {
    getContextRef.current().setContent(content || '')
  }, [content])

  return (
    <Wrapper {...rest}>
      <ThemeProvider theme={theme}>
        <AllStyledComponent>
          <Remirror
            editable={false}
            manager={manager}
            initialContent={content || ''}
          >
            <EditorComponent />
          </Remirror>
        </AllStyledComponent>
      </ThemeProvider>
    </Wrapper>
  )
}

Markdown.propTypes = {
  content: PropTypes.string,
}

export default Markdown

export { default as MarkdownEditor } from './Editor'
