import React from 'react'
import PropTypes from 'prop-types'
import { useAuthorizations } from 'hooks'
import Contributor from '../ContributorsDisplay/Contributor'

const PrimaryContributorDisplay = ({ value, vendor }) => {
  const [canManage] = useAuthorizations('manage')

  if (!value) {
    return null
  }

  return (
    <Contributor
      contributor={value}
      vendorId={vendor.id}
      canManage={canManage}
    />
  )
}

PrimaryContributorDisplay.propTypes = {
  value: PropTypes.object,
  vendor: PropTypes.object.isRequired,
}

export default PrimaryContributorDisplay
