import { useCallback } from 'react'
import { useGlobalLoader, useConfirm } from 'hooks'
import NotificationManager from 'lib/notifications'
import { useDeleteAssistQuestionSet } from 'apis'

export default function useDeleteQuestionSet() {
  const { mutateAsync: deleteAssistQuestionSet } = useDeleteAssistQuestionSet()
  const openConfirm = useConfirm()
  const [showLoader, hideLoader] = useGlobalLoader()

  return useCallback(
    (id) => {
      openConfirm({
        title: 'Deleting Question Set',
        body: 'Are you sure you wish to remove the question set?',
        onConfirm: () => {
          const loaderId = showLoader()
          return deleteAssistQuestionSet({ id })
            .then(() =>
              NotificationManager.success(
                'The question set has been successfully deleted',
              ),
            )
            .catch(() => NotificationManager.error())
            .then(() => hideLoader(loaderId))
        },
      })
    },
    [openConfirm, deleteAssistQuestionSet, showLoader, hideLoader],
  )
}
