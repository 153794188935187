import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import { useQueryClient } from '@tanstack/react-query'
import FormField from 'components-v2/molecules/FormField'
import FormDialog from 'components-v2/organisms/FormDialog'
import { LabelTypes } from 'constants/index'
import {
  useClientVendorNamesFetcher,
  useClientProjectProjectVendorsFetcher,
} from 'apis'
import VendorsSelectFormField from './VendorsSelectFormField'

const vendorsMaxLimit = 500
const dateControlProps = {
  dateFormat: 'yyyy/MM/dd h:mm aa',
  fullWidth: true,
  showTimeSelect: true,
  todayButton: 'Today',
  minDateTime: new Date(),
}
const dueDateControlProps = {
  ...dateControlProps,
  showDurationButtons: true,
}

const initialValues = {
  vendorIds: [],
  launchDate: undefined,
  dueDate: undefined,
}

const AddProjectVendorsModal = ({
  projectId,
  vendorNamesByTag,
  clientTags,
  clientTagsLoading,
  showGloborg,
  fetchVendorNamesByTag,
  ...rest
}) => {
  const queryClient = useQueryClient()
  const { data: vendorNames = [], isLoading: vendorNamesLoading } =
    useClientVendorNamesFetcher({ params: { not_in_project_id: projectId } })
  const { totalSize: projectVendorsCount } =
    useClientProjectProjectVendorsFetcher({
      projectId,
      params: { per_page: 0 },
    })

  const handleVendorCreated = React.useCallback(
    (vendor) => {
      queryClient.setQueriesData(
        {
          predicate: (query) =>
            query.queryKey[0] === useClientVendorNamesFetcher.queryKey()[0] &&
            query.queryKey[1]?.params?.not_in_project_id === projectId,
        },
        (current) => [...current, vendor],
      )
    },
    [queryClient, projectId],
  )

  const formConfig = React.useMemo(
    () => ({
      initialValues,
      validationSchema: yup.object().shape(
        {
          vendorIds: yup
            .array()
            .min(1, `Please add at least 1 ${LabelTypes.VENDOR.toLowerCase()}`)
            .max(
              vendorsMaxLimit - projectVendorsCount,
              `Projects cannot contain more than ${vendorsMaxLimit} ${LabelTypes.VENDORS.toLowerCase()}`,
            ),
          launchDate: yup
            .date()
            .nullable()
            .when('dueDate', {
              is: (val) => !!val,
              then: yup.date().nullable().required('Launch date is required'),
            }),
          dueDate: yup
            .date()
            .nullable()
            .when('launchDate', {
              is: (val) => !!val,
              then: yup
                .date()
                .nullable()
                .required('Due date is required')
                .min(
                  yup.ref('launchDate'),
                  'Due date must be after launch date',
                ),
            }),
        },
        [['launchDate', 'dueDate']],
      ),
    }),
    [projectVendorsCount],
  )

  return (
    <FormDialog
      {...rest}
      title={`Add ${LabelTypes.VENDORS} to Project`}
      submitButtonLabel="Save"
      formConfig={formConfig}
    >
      <FormField
        name="vendorIds"
        component={VendorsSelectFormField}
        vendorNames={vendorNames}
        vendorNamesLoading={vendorNamesLoading}
        vendorNamesByTag={vendorNamesByTag}
        clientTags={clientTags}
        clientTagsLoading={clientTagsLoading}
        showGloborg={showGloborg}
        onVendorCreated={handleVendorCreated}
        fetchVendorNamesByTag={fetchVendorNamesByTag}
      />
      <FormField
        name="launchDate"
        label="Launch Date"
        type={FormField.types.DATE}
        controlProps={dateControlProps}
        placeholder="Select a date"
        horizontal
        data-test-id="add-pvs-modal-launch-date-field"
      />
      <FormField
        name="dueDate"
        label="Due Date"
        type={FormField.types.DATE}
        controlProps={dueDateControlProps}
        placeholder="Select a date"
        horizontal
        data-test-id="add-pvs-modal-due-date-field"
      />
    </FormDialog>
  )
}

AddProjectVendorsModal.propTypes = {
  projectId: PropTypes.string.isRequired,
  vendorNamesByTag: PropTypes.object,
  clientTags: PropTypes.array,
  clientTagsLoading: PropTypes.bool,
  showGloborg: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  fetchVendorNamesByTag: PropTypes.func.isRequired,
}

AddProjectVendorsModal.defaultProps = {
  vendorNamesByTag: {},
  clientTags: [],
}

export default AddProjectVendorsModal
