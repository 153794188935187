import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import Typography from 'components-v2/atoms/Typography'
import PrintButton from 'components-v2/molecules/PrintButton'
import DList from 'components-v2/molecules/DList'
import PageHeader from 'components-v2/organisms/PageHeader'
import { VendorAssessmentView } from 'components/vendor/assessment_view'
import { compose, withAuthorize, withReactQuery } from 'hocs'
import { StyledTabs as Tabs } from './styles'

const TAB_PREFIX = 'tab_'

const getActiveTabKeyFromHash = () => {
  if (window.location.hash && window.location.hash.match(`#${TAB_PREFIX}`)) {
    return window.location.hash.replace(`#${TAB_PREFIX}`, '')
  }
  return null
}

const handleTabSelect = (eventKey) => {
  window.location.hash = `#${TAB_PREFIX}${eventKey}`
}

const View = ({
  project,
  clientName,
  assessments,
  currentOrganization,
  organizationPath,
  currentUser,
}) => {
  const activeKey =
    getActiveTabKeyFromHash() || assessments[0].serialized_assessment.id

  return (
    <div>
      <PageHeader title={project.name}>
        <PrintButton color="primary" />
      </PageHeader>
      <DList horizontal>
        <dt>Client</dt>
        <dd>
          <span className="no-lang">{clientName}</span>
        </dd>
        {project.description && (
          <>
            <dt>Description</dt>
            <dd>
              <Typography whiteSpace="pre-wrap" component="span">
                {project.description}
              </Typography>
            </dd>
          </>
        )}
        {project.instructions && (
          <>
            <dt>Instructions</dt>
            <dd>
              <Typography whiteSpace="pre-wrap" component="span">
                {project.instructions}
              </Typography>
            </dd>
          </>
        )}
      </DList>
      <Tabs defaultActiveKey={activeKey} onChange={handleTabSelect}>
        {map(assessments, (assessment) => (
          <Tabs.Tab
            key={assessment.serialized_assessment.id}
            eventKey={assessment.serialized_assessment.id}
            title={assessment.serialized_assessment.assessment_template.name}
            unmount={false}
          >
            <VendorAssessmentView
              assessment={assessment.serialized_assessment}
              elements={assessment.serialized_elements}
              initialSectionId={assessment.initial_section_id}
              currentOrganization={currentOrganization}
              organizationPath={organizationPath}
              currentUser={currentUser}
              hideHeader
            />
          </Tabs.Tab>
        ))}
      </Tabs>
    </div>
  )
}

View.propTypes = {
  project: PropTypes.object.isRequired,
  clientName: PropTypes.string.isRequired,
  assessments: PropTypes.array.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  organizationPath: PropTypes.string.isRequired,
  currentUser: PropTypes.string,
}

export default compose(withAuthorize(), withReactQuery())(View)
