import React from 'react'
import img from 'images/error/wrench-gear.png'
import ErrorView from '../shared/ErrorView'

export default () => (
  <ErrorView
    imageUrl={img}
    errorCode={405}
    errorType="Method Not Allowed"
    errorMessage="Sometimes a thing should work and it doesn't, perhaps you tried to submit a form or something else. When that happens please email us a note with the details of where you were and what you tried to do, and we'll do our best to address it in a timely manner."
  />
)
