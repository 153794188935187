import { getPageUrl } from 'utils/url'

export const getBitSightRatingColor = (rating) => {
  if (rating < 640) {
    return '#b24053'
  }
  if (rating < 740) {
    return '#e8a951'
  }
  return '#2c4d7f'
}

export const getVendorPageUrl = (vendorId, tab) =>
  getPageUrl('clientVendor', { id: vendorId }, { tab })
