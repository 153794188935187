/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import PropTypes from 'prop-types'
import find from 'lodash/find'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import indexOf from 'lodash/indexOf'
import map from 'lodash/map'
import truncate from 'lodash/truncate'
import { issuePriorityFormatterFactory } from 'lib/issue'
import A from 'components-v2/atoms/A'
import SearchableSelectField from 'components-v2/molecules/FormField/SearchableSelectField'
import { ControlLabel } from 'components-v2/molecules/FormField'
import Tooltip from 'components-v2/molecules/Tooltip'
import { formatText, LONG_TEXT_LENGTH } from '../utils'
import {
  BlockWell,
  DetailDisplayRow,
  DetailDisplayLabel,
  DetailDisplayBody,
  SelectionLabelRow,
  DirtySource,
  DetailBlock,
  DetailTitleRow,
  StubDetailRow,
  StubDetailLabel,
  StubLabelBody,
  StubDetailBody,
  SourceIconBar,
  EditIcon,
  CopyIcon,
  OpenExternalIcon,
  ExpandDownIcon,
  CompressIcon,
  TrashIcon,
  UndoIcon,
  FileList,
  FileItem,
  FileIcon,
  NewFileIcon,
} from '../../styles'

const issuePriorityFormatter = issuePriorityFormatterFactory()

const IssueStubDisplayBody = ({
  sourceMode,
  currentAutomation,
  stub,
  stubList,
  issueCategories,
  getIssueStubSelectOptions,
  handleSourceCreate,
  handleSourceEdit,
  handleSourceCopy,
  handleSourceReset,
  handleSourceClear,
  applyStub,
  openBodyDisplayModal,
}) => {
  const [lgTextDisplayMode, setLgTextDisplayMode] = React.useState('truncate')

  const handleTextBlockClick = (method) => setLgTextDisplayMode(method)

  return (
    <>
      {sourceMode !== 'select' ? (
        <DetailDisplayRow>
          {isEmpty(stubList) ? (
            <>New issue template:</>
          ) : (
            <>
              {sourceMode === 'display' ? (
                <>
                  <DetailDisplayLabel>Then: </DetailDisplayLabel>
                  <DetailDisplayBody>
                    Create an issue from the following template:
                  </DetailDisplayBody>
                </>
              ) : (
                <>
                  Create an issue from the following
                  <DirtySource>
                    &nbsp;{sourceMode === 'edit' ? 'updated' : 'new'}&nbsp;
                  </DirtySource>
                  issue template:
                </>
              )}
            </>
          )}
        </DetailDisplayRow>
      ) : (
        <>
          {isEmpty(stubList) ? (
            <ControlLabel>
              <A onClick={() => handleSourceCreate()}>
                Create a new Issue Template
              </A>
            </ControlLabel>
          ) : (
            <SelectionLabelRow>
              <ControlLabel>
                Select existing issue template to apply, or{' '}
                <A onClick={() => handleSourceCreate()}>create a new one</A>
              </ControlLabel>
            </SelectionLabelRow>
          )}
        </>
      )}

      {sourceMode === 'select' && !isEmpty(stubList) && (
        <>
          <SearchableSelectField
            label="Issue Template"
            value={stub.id}
            horizontal
            isClearable
            options={getIssueStubSelectOptions()}
            onChange={applyStub}
          />
        </>
      )}

      {/* {['display', 'select', 'add', 'copy', 'edit'].includes(sourceMode) && ( */}
      {sourceMode && (
        <>
          {!isEmpty(stub) && (
            <BlockWell>
              <DetailBlock>
                <DetailTitleRow>
                  <StubDetailLabel>Issue Template Name</StubDetailLabel>
                  <StubLabelBody>{stub.label}</StubLabelBody>
                  <SourceIconBar>
                    {sourceMode !== 'display' && (
                      <>
                        <Tooltip
                          parent={<EditIcon onClick={handleSourceEdit} />}
                        >
                          Edit this issue template
                        </Tooltip>
                        {sourceMode !== 'add' && sourceMode !== 'copy' && (
                          <Tooltip
                            parent={<CopyIcon onClick={handleSourceCopy} />}
                          >
                            Create a duplicate of this issue template
                          </Tooltip>
                        )}
                      </>
                    )}
                    <Tooltip
                      parent={
                        <OpenExternalIcon onClick={openBodyDisplayModal} />
                      }
                    >
                      View issue template in a new window
                    </Tooltip>
                    {sourceMode !== 'display' && (
                      <>
                        {['add', 'edit', 'copy'].includes(sourceMode) ||
                        stub.id !==
                          get(currentAutomation, 'automation_source.id') ? (
                          <Tooltip
                            parent={<UndoIcon onClick={handleSourceReset} />}
                          >
                            Revert any changes to this issue template
                          </Tooltip>
                        ) : (
                          <Tooltip
                            parent={<TrashIcon onClick={handleSourceClear} />}
                          >
                            Remove this issue template from the automation
                          </Tooltip>
                        )}
                      </>
                    )}
                  </SourceIconBar>
                </DetailTitleRow>
                <StubDetailRow>
                  <StubDetailLabel>Summary</StubDetailLabel>
                  <StubDetailBody>{stub.summary}</StubDetailBody>
                </StubDetailRow>
                <StubDetailRow>
                  <StubDetailLabel>Description</StubDetailLabel>
                  <StubDetailBody>
                    {lgTextDisplayMode === 'full'
                      ? formatText(
                          stub.description,
                          <>
                            {stub.description.length > 1000 && (
                              <CompressIcon
                                onClick={() => handleTextBlockClick('truncate')}
                              />
                            )}
                          </>,
                        )
                      : // the indent looks kinda silly if we only have one or two lines
                        stub.description &&
                        (stub.description.length < 100
                          ? stub.description
                          : formatText(
                              truncate(stub.description, {
                                length: LONG_TEXT_LENGTH,
                                separator: ' ',
                              }),
                              <>
                                {stub.description.length >
                                  LONG_TEXT_LENGTH - 50 && (
                                  <>
                                    <ExpandDownIcon
                                      onClick={() =>
                                        handleTextBlockClick('full')
                                      }
                                    />
                                  </>
                                )}
                              </>,
                            ))}
                  </StubDetailBody>
                </StubDetailRow>
                <StubDetailRow>
                  <StubDetailLabel>Attachments</StubDetailLabel>
                  <StubDetailBody>
                    <FileList>
                      {map(stub.attachments, (file) => (
                        <FileItem
                          href={file.url}
                          title={file.full_name}
                          target="_blank"
                          key={file.id}
                          deleted={indexOf(stub.fileDeletes, file.id) > -1}
                        >
                          <FileIcon />
                          {file.name}
                          <br />
                        </FileItem>
                      ))}
                      {map(stub.fileUploads, (file) => (
                        <FileItem key={file.name}>
                          <NewFileIcon />
                          {file.name}
                          <br />
                        </FileItem>
                      ))}
                    </FileList>
                  </StubDetailBody>
                </StubDetailRow>
                <StubDetailRow>
                  <StubDetailLabel>Priority</StubDetailLabel>
                  <StubDetailBody>
                    {issuePriorityFormatter({ getValue: () => stub.priority })}
                  </StubDetailBody>
                </StubDetailRow>
                <StubDetailRow>
                  <StubDetailLabel>Days to Respond</StubDetailLabel>
                  {/* <StubDetailBody>{currentAutomation.days_to_respond || stub.default_delay}</StubDetailBody> */}
                  <StubDetailBody>{stub.default_delay}</StubDetailBody>
                </StubDetailRow>
                <StubDetailRow>
                  <StubDetailLabel>Category</StubDetailLabel>
                  <StubDetailBody>
                    {stub.issue_category_id &&
                      (get(
                        find(issueCategories, ['id', stub.issue_category_id]),
                        'name',
                      ) ||
                        stub.issue_category_id)}
                  </StubDetailBody>
                </StubDetailRow>
              </DetailBlock>
            </BlockWell>
          )}
        </>
      )}
    </>
  )
}

IssueStubDisplayBody.propTypes = {
  sourceMode: PropTypes.string,
  currentAutomation: PropTypes.object,
  stub: PropTypes.object,
  stubList: PropTypes.object,
  issueCategories: PropTypes.array,
  getIssueStubSelectOptions: PropTypes.func.isRequired,
  handleSourceCreate: PropTypes.func.isRequired,
  handleSourceEdit: PropTypes.func.isRequired,
  handleSourceCopy: PropTypes.func.isRequired,
  handleSourceReset: PropTypes.func.isRequired,
  handleSourceClear: PropTypes.func.isRequired,
  applyStub: PropTypes.func.isRequired,
  openBodyDisplayModal: PropTypes.func.isRequired,
}

export default IssueStubDisplayBody
