import React from 'react'
import PropTypes from 'prop-types'
import { getPageUrl } from 'utils/url'
import { Wrap, Segment, Top, Title, MoreLink, CTA } from './styles'

const Widget = React.memo(
  ({
    title,
    moreText,
    moreUrl,
    ctaText,
    ctaUrl,
    ctaPosition,
    children,
    ...rest
  }) => (
    <Wrap {...rest} padding bordered>
      {(title || moreUrl) && (
        <Top>
          {title && <Title>{title}</Title>}
          {moreUrl && <MoreLink href={moreUrl}>{moreText}</MoreLink>}
        </Top>
      )}
      <div>{children}</div>
      <CTA href={ctaUrl} position={ctaPosition}>
        {ctaText}
        <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
      </CTA>
    </Wrap>
  ),
)

Widget.propTypes = {
  title: PropTypes.string,
  moreText: PropTypes.string,
  moreUrl: PropTypes.string,
  ctaUrl: PropTypes.string,
  ctaText: PropTypes.string,
  ctaPosition: PropTypes.string,
  children: PropTypes.node,
}

Widget.defaultProps = {
  ctaUrl: getPageUrl('clientProjects'),
  ctaText: 'Get started with a new project.',
  ctaPosition: 'bottom',
}

Widget.Segment = Segment

export default Widget
