import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import map from 'lodash/map'
import Button from 'components-v2/atoms/Button'
import Label from 'components-v2/atoms/Label'
import DList from 'components-v2/molecules/DList'
import Editable from 'components-v2/molecules/Editable'
import Uploader from 'components-v2/molecules/Uploader'
import IconButton from 'components-v2/atoms/IconButton'
import { toLocalTimeString } from 'utils/date'
import { AssessmentStatusTypes, AssessmentStatusOptions } from 'constants/index'
import { Wrapper, Top, Title, Toggler, Content, Card } from './styles'

const expiredStatusColor = AssessmentStatusOptions.find(
  (e) => e.value === AssessmentStatusTypes.EXPIRED,
).color

const renderContributors = (contributors) => {
  const partials = map(contributors, (c) => (
    <React.Fragment key={c.id}>
      <span className="no-lang">{c.name || c.email}</span>
      {c.status ? ` (${c.status})` : ''}
    </React.Fragment>
  ))
  return partials.reduce((prev, curr) => [prev, ', ', curr])
}

/**
 * @param assessment
 */
function getProjectContactInfo(assessment) {
  const pContact = get(assessment, 'project_contact_name')
  if (pContact) return pContact

  const contactName = get(assessment, 'client.main_contact.name')
  if (contactName) return contactName

  return null
}

const OverviewHeader = ({
  assessment,
  assessmentEndpointUrl,
  newContributorButtonVisible,
  addNewContributorUrl,
  onAssessmentCommentSubmit,
  onAssessmentAttachmentsChange,
  onRequestExtension,
  ...rest
}) => {
  const [expanded, setExpanded] = React.useState(true)
  const contactInfo = getProjectContactInfo(assessment)

  return (
    <Wrapper {...rest}>
      <Top onClick={() => setExpanded(!expanded)}>
        <Title>Assessment Overview</Title>
        <Toggler>
          <IconButton
            icon={expanded ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}
          />
        </Toggler>
      </Top>
      {expanded && (
        <Content>
          <Card>
            <DList horizontal>
              {get(assessment, 'project.name') && (
                <>
                  <dt>Project</dt>
                  <dd>{assessment.project.name}</dd>
                </>
              )}

              <dt>Client</dt>
              <dd className="no-lang">
                {get(assessment, 'assessment_template.client.name')}
              </dd>

              <dt>Due</dt>
              <dd>{toLocalTimeString(assessment.due_at)}</dd>

              <dt>Begin</dt>
              <dd>{toLocalTimeString(assessment.sent_at)}</dd>

              <dt>Contact</dt>
              <dd className="no-lang">{contactInfo}</dd>

              <dt>Status</dt>
              <dd>
                {assessment.status === AssessmentStatusTypes.EXPIRED ? (
                  <>
                    <Label color={expiredStatusColor}>
                      {get(assessment, 'humanized_status')}
                    </Label>
                    &nbsp;
                    {assessment.extension_request_allowed && (
                      <Button
                        size="xs"
                        color="danger"
                        variant="outlined"
                        onClick={onRequestExtension}
                      >
                        Request Extension
                      </Button>
                    )}
                    {assessment.extension_requested_at && (
                      <Button
                        size="xs"
                        color="danger"
                        variant="outlined"
                        disabled
                      >
                        Extension Requested
                      </Button>
                    )}
                  </>
                ) : (
                  get(assessment, 'humanized_status')
                )}
              </dd>

              {(assessment.submitted || assessment.reviewed) && (
                <>
                  <dt>Submitted by</dt>
                  <dd>
                    <span className="no-lang">
                      {get(assessment, 'submit_by.name')}
                    </span>
                    {assessment.submit_at && (
                      <span> on {toLocalTimeString(assessment.submit_at)}</span>
                    )}
                  </dd>
                </>
              )}

              <dt>Contributors</dt>
              <dd>
                {renderContributors(assessment.vendor.users)}{' '}
                {newContributorButtonVisible && (
                  <a href={addNewContributorUrl} className="hidden-print">
                    + Add
                  </a>
                )}
              </dd>

              {get(assessment, 'assessment_template.allow_comments') && (
                <>
                  <dt>Comment</dt>
                  <dd>
                    <Editable
                      mode="popup"
                      emptyValueText="Add Comment"
                      title={
                        assessment.comment ? 'Edit Comment' : 'Add Comment'
                      }
                      value={assessment.comment}
                      disabled={assessment.disabled}
                      onSubmit={onAssessmentCommentSubmit}
                      translatable={assessment.translatable}
                    />
                  </dd>
                </>
              )}

              {get(assessment, 'assessment_template.allow_attachments') && (
                <>
                  <dt>Files</dt>
                  <dd>
                    <Uploader
                      model="assessment"
                      files={assessment.attachments}
                      url={assessmentEndpointUrl}
                      disabled={assessment.disabled}
                      displayLabel={false}
                      description="Attach any additional files related to this assessment below."
                      onChange={onAssessmentAttachmentsChange}
                    />
                  </dd>
                </>
              )}

              {/* {get(assessment, 'assessment_template.allow_vendor_uploads') && (
                <>Upload component goes here, maybe?</>
              )} */}
            </DList>
          </Card>
        </Content>
      )}
    </Wrapper>
  )
}

OverviewHeader.propTypes = {
  assessment: PropTypes.object.isRequired,
  assessmentEndpointUrl: PropTypes.string.isRequired,
  newContributorButtonVisible: PropTypes.bool,
  addNewContributorUrl: PropTypes.string.isRequired,
  onAssessmentCommentSubmit: PropTypes.func.isRequired,
  onAssessmentAttachmentsChange: PropTypes.func.isRequired,
  onRequestExtension: PropTypes.func.isRequired,
}

export default React.memo(OverviewHeader)
