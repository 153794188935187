import styled from 'styled-components'
import { colors, mixins } from 'styles'

export const Wrapper = styled.div`
  border: 1px solid ${colors.PRIMARY};
  border-radius: 7px;
  overflow: hidden;
`

export const Title = styled.h5`
  ${mixins.truncate}
  text-align: center;
  color: ${({ scored }) => (scored ? colors.WHITE : colors.HEADING)};
  background: ${({ scored }) => (scored ? colors.PRIMARY : '#e1e1e1')};
  border-bottom: 1px solid ${colors.PRIMARY};
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  padding: 6px;
  margin: 0;
`

export const Content = styled.div`
  padding: 7px 17px;
`

export const GradeWrapper = styled.div`
  padding: 12px;
  text-align: center;
  margin-bottom: 7px;
`
