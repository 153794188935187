import { useCallback } from 'react'
import {
  useSetElement,
  useSetSkippedElements,
} from './useAssessmentElementsFetcher'

export default function useUpdateStatesFromElementResponse(
  assessmentId,
  setAssessment,
  setAutoSavedAt,
) {
  const setElement = useSetElement(assessmentId)
  const setSkippedElements = useSetSkippedElements(assessmentId)

  return useCallback(
    (data, sectionId, byOther = false) => {
      const {
        progress,
        skipped_element_ids: skippedElementIds,
        ...response
      } = data
      // Update response, progress and skipped_element_ids state
      setElement(data.element_id, (current) => ({ ...current, response }))
      setAssessment((current) => ({ ...current, progress }))
      setSkippedElements(skippedElementIds, sectionId, byOther)

      // Update autoSavedAt to now
      setAutoSavedAt(new Date())
    },
    [setAssessment, setAutoSavedAt, setElement, setSkippedElements],
  )
}
