import React from 'react'
import PropTypes from 'prop-types'
import { PieChart as RechartsPieChart, Pie, Sector, Cell } from 'recharts'
import { Text } from '@visx/text'
import { Wrapper } from './styles'

const RADIAN = Math.PI / 180

const renderCustomizedLabel = ({
  cx,
  cy,
  startAngle,
  endAngle,
  midAngle,
  outerRadius,
  fill,
  payload,
  percent,
}) => {
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius + 10) * cos
  const sy = cy + (outerRadius + 10) * sin
  const mx = cx + (outerRadius + 30) * cos
  const my = cy + (outerRadius + 30) * sin
  const ex = cos < 0 ? 0 : cx * 2
  const ey = my
  const textAnchor = cos < 0 ? 'start' : 'end'
  const textWidth = Math.abs(ex - mx)

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle + 1}
        endAngle={endAngle - 1}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <Text
        x={ex}
        y={ey}
        dy={-6}
        textAnchor={textAnchor}
        verticalAnchor="end"
        fill="#505050"
        width={textWidth}
      >
        {payload.name}
      </Text>
      <Text
        x={ex}
        y={ey}
        dy={6}
        textAnchor={textAnchor}
        verticalAnchor="start"
        fill="#69717C"
      >
        {`${Math.round(percent * 100)}%`}
      </Text>
    </g>
  )
}

const renderCustomizedActiveShape = (label) => (props) =>
  (
    <g>
      <Sector {...props} />
      {label(props)}
    </g>
  )

const backgroundPieProps = {
  data: [{ value: 1 }],
  dataKey: 'value',
  labelLine: false,
  label: false,
  isAnimationActive: false,
}

function PieChart({
  data,
  width,
  height,
  label,
  labelOnHover,
  backgroundColor,
  className,
  ...rest
}) {
  const [activeIndex, setActiveIndex] = React.useState(0)
  const customLabel = label === true ? renderCustomizedLabel : label

  return (
    <Wrapper width={width} height={height} className={className}>
      <RechartsPieChart>
        {backgroundColor && (
          <Pie {...rest} {...backgroundPieProps} fill={backgroundColor} />
        )}
        {label && labelOnHover ? (
          <Pie
            {...rest}
            data={data}
            labelLine={false}
            label={false}
            activeShape={renderCustomizedActiveShape(customLabel)}
            activeIndex={activeIndex}
            onMouseEnter={(_, index) => setActiveIndex(index)}
          >
            {data.map((entry) => (
              <Cell key={entry.name} fill={entry.color} />
            ))}
          </Pie>
        ) : (
          <Pie {...rest} data={data} labelLine={false} label={customLabel}>
            {data.map((entry) => (
              <Cell key={entry.name} fill={entry.color} />
            ))}
          </Pie>
        )}
      </RechartsPieChart>
    </Wrapper>
  )
}

PieChart.propTypes = {
  ...Pie.propTypes,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      color: PropTypes.string,
    }),
  ).isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelOnHover: PropTypes.bool,
  backgroundColor: PropTypes.string,
}

PieChart.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  dataKey: 'value',
  // eslint-disable-next-line react/default-props-match-prop-types
  cx: '50%',
  // eslint-disable-next-line react/default-props-match-prop-types
  cy: '50%',
}

export default PieChart
