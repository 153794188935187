import React from 'react'
import PropTypes from 'prop-types'
import { parseOfacData } from '../../lib/globorg_integration_metrics'
import { Wrapper, Title, Status, StatusIcon } from './styles'

const OFACSanctionStatusCard = ({ data }) => {
  const ofacData = React.useMemo(() => parseOfacData(data), [data])
  return (
    <Wrapper>
      <Title>Sanction Status</Title>
      <Status $color={ofacData.color}>
        <StatusIcon icon={ofacData.icon} />
        {ofacData.label}
      </Status>
      <div>{ofacData.description}</div>
    </Wrapper>
  )
}

OFACSanctionStatusCard.propTypes = {
  data: PropTypes.object,
}

export default OFACSanctionStatusCard
