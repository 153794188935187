const AssessmentElementSubtypes = {
  TEXT: 'text',
  // Numeric
  INTEGER: 'integer',
  FLOAT: 'float',
  MONEY: 'money',
  // Date/Time
  DATE: 'date',
  TIME: 'time',
  DATETIME: 'datetime',
  YEAR: 'year',
  // Physical Contact
  PHONE: 'phone',
  STATE: 'state',
  ZIPCODE: 'zipcode',
  // Inet
  EMAIL: 'email',
  DOMAIN: 'domain',
  URI: 'uri',
  IPADDR: 'ipaddr',
}

export const AssessmentElementSubtypeOptions = [
  {
    value: AssessmentElementSubtypes.TEXT,
    label: 'Text',
  },
  {
    value: AssessmentElementSubtypes.INTEGER,
    label: 'Number (integer)',
  },
  {
    value: AssessmentElementSubtypes.FLOAT,
    label: 'Number (decimal)',
  },
  {
    value: AssessmentElementSubtypes.MONEY,
    label: 'Currency',
  },
  {
    value: AssessmentElementSubtypes.DATE,
    label: 'Date',
  },
  {
    value: AssessmentElementSubtypes.TIME,
    label: 'Time',
  },
  {
    value: AssessmentElementSubtypes.DATETIME,
    label: 'Datetime',
  },
  {
    value: AssessmentElementSubtypes.YEAR,
    label: 'Year',
  },
  {
    value: AssessmentElementSubtypes.PHONE,
    label: 'Phone Number',
  },
  {
    value: AssessmentElementSubtypes.STATE,
    label: 'State',
  },
  {
    value: AssessmentElementSubtypes.ZIPCODE,
    label: 'Zipcode',
  },
  {
    value: AssessmentElementSubtypes.EMAIL,
    label: 'Email',
  },
  {
    value: AssessmentElementSubtypes.DOMAIN,
    label: 'Domain',
  },
  {
    value: AssessmentElementSubtypes.URI,
    label: 'URL',
  },
  {
    value: AssessmentElementSubtypes.IPADDR,
    label: 'IP Address',
  },
]

export default AssessmentElementSubtypes
