import React from 'react'
import PropTypes from 'prop-types'
import A from 'components-v2/atoms/A'

const UrlDisplay = ({ value }) => (
  <A href={value} target="_blank" underline>
    {value}
  </A>
)

UrlDisplay.propTypes = {
  value: PropTypes.string,
}

export default UrlDisplay
