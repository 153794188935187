import styled from 'styled-components'
import MultiSelectFilter from '../MultiSelectFilter'

export const StyledMultiSelectFilter = styled(MultiSelectFilter)`
  padding: 10px;
  white-space: nowrap;
`

export const Caret = styled.span`
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: middle;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
`
