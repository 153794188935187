import React, { useMemo, Fragment } from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import omit from 'lodash/omit'
import merge from 'lodash/merge'
import { Formik } from 'formik'
import * as yup from 'yup'
import Button from 'components-v2/atoms/Button'
import Note from 'components/shared/Note'
import {
  Form,
  Scrollable,
  Title,
  NoteBody,
  Footer,
  BackButton,
  SkipButton,
  StyledFormField as FormField,
  OtherFormField,
} from './styles'

function omitConfigProperties(obj) {
  return omit(obj, ['initialValue', 'validation'])
}

function handleKeyPress(event) {
  if (
    event.which === 13 /* Enter */ &&
    event.target.tagName.toLowerCase() !== 'textarea'
  ) {
    event.preventDefault()
  }
}

function StepForm({
  values,
  title,
  note,
  fields,
  horizontal,
  canSkip,
  isFirst,
  isLast,
  disableSubmit,
  onBack,
  onSkip,
  ...rest
}) {
  const initialValues = useMemo(
    () =>
      merge(
        reduce(
          fields,
          (result, field) => {
            const newResult = {
              ...result,
              [field.name]: field.initialValue,
            }
            if (field.other) {
              newResult[field.other.name] = field.initialValue
            }
            return newResult
          },
          {},
        ),
        values,
      ),
    [fields, values],
  )

  const validationSchema = useMemo(() => {
    const getFieldValidation = (field) =>
      field.validation ? field.validation.label(field.label) : undefined
    const validations = reduce(
      fields,
      (result, field) => {
        const newResult = {
          ...result,
          [field.name]: getFieldValidation(field),
        }
        if (field.other) {
          newResult[field.other.name] = getFieldValidation(field.other)
        }
        return newResult
      },
      {},
    )
    return yup.object().shape(validations)
  }, [fields])

  return (
    <Formik
      {...rest}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {(props) => (
        // eslint-disable-next-line react/prop-types
        <Form onSubmit={props.handleSubmit} onKeyPress={handleKeyPress}>
          <Scrollable>
            <Title>{title}</Title>
            {note && (
              <NoteBody>
                <Note note={note} />
              </NoteBody>
            )}
            {map(fields, ({ name, other, ...restF }) => (
              <Fragment key={name}>
                <FormField
                  {...omitConfigProperties(restF)}
                  name={name}
                  horizontal={horizontal}
                  preserveHelpTextSpace={!other}
                />
                {other && <OtherFormField {...omitConfigProperties(other)} />}
              </Fragment>
            ))}
          </Scrollable>
          <Footer>
            {!isFirst && (
              <BackButton onClick={onBack}>&lt;&nbsp;&nbsp;Back</BackButton>
            )}
            {canSkip && (
              <SkipButton onClick={onSkip}>Skip this section</SkipButton>
            )}
            {isLast && (
              <Button
                type="submit"
                color="primary"
                // eslint-disable-next-line react/prop-types
                disabled={props.isSubmitting || disableSubmit}
              >
                Submit Request
              </Button>
            )}
            {!isLast && (
              <Button type="submit" color="primary">
                Next&nbsp;&nbsp;&gt;
              </Button>
            )}
          </Footer>
        </Form>
      )}
    </Formik>
  )
}

StepForm.propTypes = {
  values: PropTypes.object,
  title: PropTypes.string.isRequired,
  note: PropTypes.string,
  fields: PropTypes.array.isRequired,
  horizontal: PropTypes.bool,
  canSkip: PropTypes.bool,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  disableSubmit: PropTypes.bool,
  onBack: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired,
}

export default StepForm
