import React from 'react'
import PropTypes from 'prop-types'
import { toLocaleString } from 'utils/number'
import { Wrapper } from './styles'

const SpyCloudMetric = ({ records, ...rest }) => (
  <Wrapper {...rest}>{toLocaleString(records)}</Wrapper>
)

SpyCloudMetric.propTypes = {
  records: PropTypes.number.isRequired,
}

export default SpyCloudMetric
