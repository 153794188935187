import { css } from 'styled-components'

export const formatCell = (style) => css`
  & > thead,
  & > tbody,
  & > tfoot {
    & > tr {
      & > th,
      & > td {
        ${style}
      }
    }
  }
`
