import React from 'react'
import Table from 'components-v2/molecules/Table'
import Tooltip from 'components-v2/molecules/Tooltip'
import config from 'config'
import { Header, Wrapper, RuleLabel, Domain } from './styles'

export const emailDomainsTableColumns = [
  //   {
  //   id: '',
  //   header: 'Tool',
  //   // cell: (info) => <ToolLogo src={info.row.original['logo_url']} title={info.row.original.name} />,
  // }, {
  //   id: 'count',
  //   header: 'Used by',
  //   // cell: info => <A href="#">{info.getValue()} vendors</A>,
  //   width: 80,
  // },
  {
    id: 'id',
    header: 'id',
    hidden: true,
  },
  {
    id: 'exclude',
    header: '',
    cell: (info) => (
      <div>{info.row.original.exclude ? 'Exclude' : 'Match'}</div>
    ),
  },
  {
    id: 'rule',
    header: '',
    cell: (info) => {
      const rule = info.row.original.is_domain ? (
        <Domain>*@{info.row.original.rule}</Domain>
      ) : (
        info.row.original.rule
      )
      return <RuleLabel exclude={info.row.original.exclude}>{rule}</RuleLabel>
    },
  },
]

const EmailDomainsCard = ({ data, ...rest }) => (
  <Wrapper padding bordered>
    <Header>
      Email Domain Rules{' '}
      <Tooltip>
        The email addresses or email domains that will authenticate using Single
        Sign On.
      </Tooltip>
    </Header>
    <Table
      data={data}
      columns={emailDomainsTableColumns}
      defaultPagination={{
        page: 1,
        sizePerPage: config.collectionQueryPageLimit.emailDomainsCard,
      }}
      hidePageSizes
      hidePageListOnlyOnePage
      enableSorting={false}
      noDataText="No Emails/Domains configured. Please contact support for setup."
      noDataWrapperHeight={100}
      type={Table.types.MINI}
      {...rest}
    />
  </Wrapper>
)

EmailDomainsCard.propTypes = {
  ...Table.propsTypes,
}

export default React.memo(EmailDomainsCard)
