import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components-v2/atoms/Button'
import FormField from 'components-v2/molecules/FormField'
import Drawer from 'components-v2/molecules/Drawer'
import ToolbarGroup from 'components-v2/molecules/ToolbarGroup'
import FormDrawer from 'components-v2/organisms/FormDrawer'
import { OrganizationPartnerTypes } from 'constants/index'
import { getPartnerName } from '../utils'
import BitSightFields from './BitSightFields'
import RiskReconFields from './RiskReconFields'
import AwsSesFields from './AwsSesFields'
import Default from './Default'

const getComponent = ({ type }) => {
  if (type === OrganizationPartnerTypes.BITSIGHT) {
    return BitSightFields
  }
  if (type === OrganizationPartnerTypes.RISK_RECON) {
    return RiskReconFields
  }
  if (type === OrganizationPartnerTypes.AWS_SES) {
    return AwsSesFields
  }
  return Default
}

const EditPartnerModal = ({ partner, onSubmit, onClose, ...rest }) => {
  const Component = getComponent(partner)
  const handleSubmit = React.useCallback(
    (formValues, bag) => {
      const values = Component.fromFormValues
        ? Component.fromFormValues(formValues)
        : formValues
      return onSubmit(values, bag)
    },
    [Component, onSubmit],
  )
  return (
    <FormDrawer
      {...rest}
      formConfig={{
        initialValues: Component.toFormValues
          ? Component.toFormValues(partner)
          : partner,
        validationSchema: Component.validationSchema,
      }}
      onClose={onClose}
      onSubmit={handleSubmit}
      renderHeader={(formProps) => (
        <Drawer.Header title={getPartnerName(partner.type)} onClose={onClose}>
          <ToolbarGroup gap={16}>
            <FormField
              name="enabled"
              type={FormField.types.TOGGLE}
              preserveHelpTextSpace={false}
            />
            <Button
              type="submit"
              color="primary"
              disabled={formProps.isSubmitting}
            >
              Save
            </Button>
          </ToolbarGroup>
        </Drawer.Header>
      )}
    >
      <Component data={partner} />
    </FormDrawer>
  )
}

EditPartnerModal.propTypes = {
  partner: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default EditPartnerModal
