import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { darken } from 'polished'
import {
  RadarChart as RChart,
  Radar,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Tooltip,
} from 'recharts'
import { colors } from 'styles'
import { Container } from './styles'

const RadarChart = ({
  width,
  height,
  data,
  name,
  dataKey,
  xAxisDataKey,
  tooltipProps,
  strokeColor,
  fillColor,
  fillOpacity,
  dataKeys,
  className,
  ...rest
}) => {
  const customTick = ({ payload, x, y, textAnchor, stroke, radius }) => {
    let newY = y
    if (payload.coordinate === -90) {
      // give whatever's on the very bottom a little breathing room
      newY += 5
    } else if (payload.coordinate > -135 && payload.coordinate < -45) {
      // decided whatever's on the bottom-sides could use a hair more space as well
      newY += 2
    }

    return (
      <g className="recharts-layer recharts-polar-angle-axis-tick">
        <text
          radius={radius}
          stroke={stroke}
          x={x}
          y={newY}
          className="recharts-text recharts-polar-angle-axis-tick-value"
          textAnchor={textAnchor}
        >
          <tspan x={x} dy="0em">
            {payload.value}
          </tspan>
        </text>
      </g>
    )
  }

  const dataKeysEx = dataKeys.length
    ? dataKeys
    : [
        {
          name,
          dataKey,
          strokeColor,
          fillColor,
          fillOpacity,
        },
      ]

  return (
    <Container width={width} height={height} className={className}>
      <RChart
        {...rest}
        outerRadius="80%"
        width={height * 0.75}
        height={height}
        data={data}
      >
        <PolarGrid />
        <PolarAngleAxis dataKey={xAxisDataKey} tick={customTick} />
        <PolarRadiusAxis />
        {tooltipProps && <Tooltip {...tooltipProps} />}
        {dataKeysEx.map((d) => {
          const nm = get(d, 'name', name)
          const dk = get(d, 'dataKey', dataKey)
          const sc = get(d, 'strokeColor', strokeColor)
          const fc = get(d, 'fillColor', fillColor)
          const fo = get(d, 'fillOpacity', fillOpacity)
          return (
            <Radar
              key={dk}
              name={nm}
              dataKey={dk}
              stroke={sc}
              fill={fc}
              fillOpacity={fo}
            />
          )
        })}
      </RChart>
    </Container>
  )
}

RadarChart.propTypes = {
  ...Radar.propTypes,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tooltipProps: PropTypes.object,
  data: PropTypes.array.isRequired,
  xAxisDataKey: PropTypes.string,
  // either these five (which have defaults below)...
  name: PropTypes.string,
  dataKey: PropTypes.string,
  strokeColor: PropTypes.string,
  fillColor: PropTypes.string,
  fillOpacity: PropTypes.number,
  // or for multiple overlayed graphs, this as an array
  // of hashes, each containing the above five keys
  dataKeys: PropTypes.array,
}

// example structure:
// data = [
//   { subject: 'Math', A: 94, B: 78 },
//   { subject: 'English', A: 86, B: 95 },
//   { subject: 'Science', A: 75, B: 89 }
// ];
//  xAxisDataKey = 'subject';
//  dataKeys = [
//    { name: 'Mark', dataKey: 'A' },  // will use default colors
//    { name: 'Sally', dataKey: 'B', strokeColor: '#123456', fillColor: '#654321' }
// ]

RadarChart.defaultProps = {
  name: 'Chart 1',
  xAxisDataKey: 'label',
  dataKey: 'value',
  // should probably pull from somewhere more standard...
  strokeColor: darken(0.1, colors.BLUE),
  fillColor: colors.BLUE,
  fillOpacity: 0.6,
  dataKeys: [],
}

export default RadarChart
