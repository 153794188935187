import styled from 'styled-components'
import PendingLabel from 'components-v2/atoms/Label'
import UserAvatar from 'components-v2/molecules/UserAvatar'
import { colors } from 'styles'

export const Wrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const Label = styled.p`
  margin: 0;
  padding-left: 12px;
  font-size: 14px;
  font-weight: 420;
  color: #000;
  line-height: 1.5;
`

export const ListItemWrapper = styled.div`
  width: 100%;
  padding: 16px 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid #ccc;
  @media (max-width: 400px) {
    flex-direction: column;
    align-items: start;
  }
`
export const ListItemUserAvatar = styled(UserAvatar).attrs({
  color: 'default',
})`
  color: inherit !important;
  border: 1px solid ${colors.MED_GREY};
  border-radius: 100%;
  @media (max-width: 400px) {
    display: none;
  }
`

export const ListItemMain = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

export const ListItemTitle = styled.div`
  display: flex;
  font-weight: 420;
  color: #000;
  gap: 8px;
`

export const ListItemPendingLabel = styled(PendingLabel).attrs({
  color: `${colors.YELLOW}`,
})`
  height: 20px;
  line-height: 20px;
`

export const ListItemEmail = styled.p`
  margin: 0;
  font-size: 12px;
  color: #000;
`

export const ListItemDate = styled.span`
  font-size: 12px;
  color: ${colors.MED_GREY};
`
