import { useCallback } from 'react'
import some from 'lodash/some'
import { useModal, useCurrent } from 'hooks'
import { useMultiUpdateClientAssessmentTemplateElements } from 'apis'
import { AssessmentTemplateStatusTypes } from 'constants/index'
import BulkEditWeightModal from './BulkEditWeightModal'
import useDefaultAsyncActionHandler from './useDefaultAsyncActionHandler'
import { sortElements } from './utils'

// Callback when click "Edit Weights" button
export default function useEditWeights(assessmentTemplate) {
  const { mutateAsync: multiUpdateClientAssessmentTemplateElements } =
    useMultiUpdateClientAssessmentTemplateElements()
  const assessmentTemplateRef = useCurrent(assessmentTemplate)
  const [openBulkEditWeightModal, closeBulkEditWeightModal] =
    useModal(BulkEditWeightModal)
  const handleAsyncAction = useDefaultAsyncActionHandler()

  return useCallback(() => {
    openBulkEditWeightModal({
      elements: sortElements(assessmentTemplateRef.current.elements),
      isDraft:
        assessmentTemplateRef.current.status ===
        AssessmentTemplateStatusTypes.DRAFT,
      hasSensitiveQuestions: some(
        assessmentTemplateRef.current.elements,
        (elem) =>
          (elem.type === 'TextQuestion' ||
            elem.type === 'MultiSelectQuestion') &&
          some(elem.question_automations, ['trigger_type', 'score']),
      ),
      onSave: (data) => {
        const promise = multiUpdateClientAssessmentTemplateElements({
          id: assessmentTemplateRef.current.id,
          data: { elements: data },
        }).then(() => closeBulkEditWeightModal())
        handleAsyncAction(promise, 'Weights have been successfully updated.')
      },
    })
  }, [openBulkEditWeightModal, closeBulkEditWeightModal, handleAsyncAction])
}
