/* Clickable element (anchor or button) with zero-style */
import React from 'react'
import styled from 'styled-components'
import { mixins } from 'styles'

const Button = styled.button`
  ${mixins.clearButtonStyle}
  appearance: none;
  margin: 0;
  text-align: left;
`

function ClickableInner(props, ref) {
  if ('href' in props) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a {...props} ref={ref} />
  }
  return <Button type="button" {...props} ref={ref} />
}

export default React.forwardRef(ClickableInner)
