import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Privva from 'Privva'
import Button from 'components-v2/atoms/Button'
import Checkbox from 'components-v2/atoms/Checkbox'
import Accordion from 'components-v2/molecules/Accordion'
import Dialog from 'components-v2/molecules/Dialog'
import FileInputField from 'components-v2/molecules/FormField/FileInputField'
import ListGroup from 'components-v2/molecules/ListGroup'
import { AssessmentStatusTypes } from 'constants/index'
import { Loading, Message } from './styles'

export default function ImportAssessmentResponsesModal({
  assessmentStatus,
  instruction,
  note,
  onClose,
  onSubmit,
  ...rest
}) {
  const [file, setFile] = useState(null)
  const [errors, setErrors] = useState([])
  const [warnings, setWarnings] = useState([])
  const [status, setStatus] = useState('none')
  const [changeStatusToSubmit, setChangeStatusToSubmit] = useState(true)

  const upload = (validateOnly = false, fileOverride = null) => {
    if (validateOnly) setStatus('loading')
    else setStatus('importing')

    const data = new FormData()
    if (fileOverride) setFile(fileOverride)
    data.append('responses_file', fileOverride || file)
    data.append('submit', !!changeStatusToSubmit)
    data.append('validate_only', validateOnly)
    onSubmit(data)
      .then(() => {
        if (validateOnly) {
          setStatus('ok')
        } else {
          setStatus('success')
          setTimeout(() => {
            window.location.reload()
          }, 1200)
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err)
        setWarnings(err.response.data.warnings || [])
        setErrors(err.response.data.errors || [])
        setStatus('error')
        if (err.response.status !== 422) setStatus('servererror')
      })
  }

  const fileChanged = (newFile) => {
    setFile(newFile)
    setStatus('loaded')
  }

  return (
    <Dialog {...rest} onClose={onClose}>
      <Dialog.Header onClose={onClose}>Import Responses</Dialog.Header>

      <Dialog.Content>
        <FileInputField
          horizontal
          label="Select file"
          value={file}
          controlProps={{
            accept: '.xlsx,.xlsm,.json',
          }}
          onChange={fileChanged}
        />
        {status === 'none' && <p>{instruction}</p>}
        {status === 'servererror' && (
          <p>
            An error occurred while importing. Please contact
            {Privva.Utils.brand.supportEmail} for further assistance.
          </p>
        )}
        {status === 'error' && (
          <>
            <p className="text-danger strong">
              Validation errors were detected with the file:
            </p>
            <Accordion
              allowMultipleExpanded
              allowZeroExpanded
              preExpanded={['expandErrors', 'expandWarnings']}
            >
              {!!errors && errors.length > 0 && (
                <Accordion.Item
                  uuid={errors.length < 6 ? 'expandErrors' : 'errors'}
                  title={
                    <span className="text-danger">{`${errors.length} Errors`}</span>
                  }
                >
                  <ListGroup>
                    {errors.map((e) => (
                      <ListGroup.Item color="danger">{e}</ListGroup.Item>
                    ))}
                  </ListGroup>
                </Accordion.Item>
              )}
              {!!warnings && warnings.length > 0 && (
                <Accordion.Item
                  uuid={warnings.length < 6 ? 'expandWarnings' : 'warnings'}
                  title={
                    <span className="text-warning">{`${warnings.length} Warnings`}</span>
                  }
                >
                  <ListGroup>
                    {warnings.map((e) => (
                      <ListGroup.Item color="warning">{e}</ListGroup.Item>
                    ))}
                  </ListGroup>
                </Accordion.Item>
              )}
            </Accordion>
          </>
        )}
        {status === 'ok' && <h5>File verified!</h5>}
        {status === 'loading' && (
          <Loading>
            <i className="fa fa-2x fa-spinner fa-spin " />
            <Message> Verifying...</Message>
          </Loading>
        )}
        {status === 'importing' && (
          <Loading>
            <i className="fa fa-2x fa-spinner fa-spin " />
            <Message> Importing...</Message>
          </Loading>
        )}
        {status === 'success' && (
          <Loading>
            <i className="fa fa-2x fa-spinner fa-spin " />
            <Message> Responses imported, reloading...</Message>
          </Loading>
        )}
        {['none', 'ok', 'loaded'].includes(status) && (
          <p className="text-danger strong">{note}</p>
        )}
      </Dialog.Content>

      <Dialog.Footer>
        {/* <a style={{ margin: '.4rem' }} onClick={()=>upload(true)}>Validate</a> */}
        {false && assessmentStatus === AssessmentStatusTypes.IN_PROCESS && (
          <Checkbox
            inline
            disabled={['loading', 'error', 'servererror', 'none'].includes(
              status,
            )}
            checked={changeStatusToSubmit}
            style={{ marginRight: '.5rem' }}
            label="Set as submitted?"
            onChange={(e) => setChangeStatusToSubmit(e.target.checked)}
          />
        )}
        <Button onClick={onClose}>Close</Button>
        <Button
          onClick={() => upload()}
          disabled={[
            'loading',
            'importing',
            'error',
            'servererror',
            'none',
          ].includes(status)}
          color="primary"
        >
          Import
        </Button>
      </Dialog.Footer>
    </Dialog>
  )
}

ImportAssessmentResponsesModal.propTypes = {
  assessmentStatus: PropTypes.string.isRequired,
  instruction: PropTypes.any,
  note: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}
