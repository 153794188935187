import Privva from 'Privva'
import map from 'lodash/map'

const RatingLabelSetTypes = {
  STANDARD: 'standard',
}

export const RatingLabelSetOptions = map(
  Privva.Utils.RiskLabelSets,
  ({ name, ...rest }, key) => ({ value: key, label: name, ...rest }),
)

export default RatingLabelSetTypes
