import React from 'react'
import PropTypes from 'prop-types'
import Input from 'components-v2/atoms/Input'
import BaseField from 'components-v2/molecules/FormField/BaseField'

const CredentialsField = ({
  name,
  controlId,
  value,
  placeholder,
  disabled,
  ending,
  controlProps = {},
  onChange,
  ...rest
}) => {
  const [isFocused, setIsFocused] = React.useState(false)
  const handleOnFocus = React.useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleBlur = React.useCallback(() => {
    setIsFocused(false)
  }, [])

  return (
    <BaseField controlId={controlId} {...rest}>
      <Input
        {...controlProps}
        id={controlId}
        name={name}
        type="text"
        value={
          value !== undefined && value !== null
            ? value
            : isFocused || !ending
            ? ''
            : `*****${ending}`
        }
        placeholder={placeholder}
        disabled={disabled}
        onChange={(e) => onChange(e.currentTarget.value)}
        onFocus={handleOnFocus}
        onBlur={handleBlur}
      />
    </BaseField>
  )
}

CredentialsField.propTypes = {
  name: PropTypes.string,
  controlId: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  ending: PropTypes.string,
  controlProps: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

export default CredentialsField
