import { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import NotificationManager from 'lib/notifications'
import {
  useUpdateClientResponse,
  useClientQuestionAutomationsFetcher,
} from 'apis'
import { useSetElementResponse } from './useAssessmentElementsFetcher'

export default function useCRUDResponse(assessmentId, userName) {
  const queryClient = useQueryClient()
  const setElementResponse = useSetElementResponse()
  const { mutateAsync: updateResponse } = useUpdateClientResponse({
    onMutate: ({ id, data }) => {
      setElementResponse(id, (current) => ({
        ...current,
        ...data.response,
      }))
    },
    onSuccess: (data) => {
      setElementResponse(data.id, data)
      queryClient.invalidateQueries(
        useClientQuestionAutomationsFetcher.queryKey(),
        {
          predicate: (query) =>
            query.queryKey[1].params.assessment_id === assessmentId,
        },
      )
    },
    onError: () => {
      NotificationManager.error(
        'There was an error saving your response. Please check your connection and try again later.',
      )
    },
  })

  const updateResponseReviewScore = useCallback(
    (responseId, score) => {
      updateResponse({
        id: responseId,
        data: {
          response: {
            // eslint-disable-next-line camelcase
            review_score: score,
            reviewer: { name: userName },
          },
        },
      })
    },
    [updateResponse, userName],
  )

  const updateResponseReviewNote = useCallback(
    (responseId, note) => {
      updateResponse({
        id: responseId,
        data: {
          response: {
            review_note: note,
          },
        },
      })
    },
    [updateResponse],
  )

  const markResponseReviewed = useCallback(
    (responseId) => {
      updateResponse({
        id: responseId,
        data: {
          response: {
            reviewer: null,
          },
        },
      })
    },
    [updateResponse],
  )

  return {
    updateResponseReviewScore,
    updateResponseReviewNote,
    markResponseReviewed,
  }
}
