import { useCallback, useEffect, useMemo } from 'react'
import compact from 'lodash/compact'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'
import uniq from 'lodash/uniq'
import NotificationManager from 'lib/notifications'
import { useModal, useGlobalLoader, useCurrent } from 'hooks'
import {
  useClientUserGroupsFetcher,
  useClientProjectEventSubscriptionsFetcher,
  useAddClientProjectEventSubscriptions,
  useUpdateClientProjectEventSubscribers,
  useRemoveClientProjectEventSubscribers,
} from 'apis'
import AddUsersToNotifyModal from './AddUsersToNotifyModal'
import EditNotificationsModal from './EditNotificationsModal'

export default function useCRUDEventSubscriber(
  project,
  projectVendorsProps,
  onSave,
) {
  const [
    openAddUsersToNotifyModal,
    closeAddUsersToNotifyModal,
    updateAddUsersToNotifyModal,
  ] = useModal(AddUsersToNotifyModal)
  const [openEditNotificationsModal, closeEditNotificationsModal] = useModal(
    EditNotificationsModal,
  )
  const [showLoader, hideLoader] = useGlobalLoader()
  const onSaveRef = useCurrent(onSave)
  const { data: userGroups } = useClientUserGroupsFetcher({
    params: { with_users: true },
  })
  const { data: eventSubscriptions, isFetching: isFetchingEventSubscribers } =
    useClientProjectEventSubscriptionsFetcher({ projectId: project.id })
  const { mutateAsync: addClientProjectEventSubscriptions } =
    useAddClientProjectEventSubscriptions()
  const { mutateAsync: updateClientProjectEventSubscribers } =
    useUpdateClientProjectEventSubscribers()
  const { mutateAsync: removeClientProjectEventSubscribers } =
    useRemoveClientProjectEventSubscribers()
  const { id: projectId, assessment_templates: assessmentTemplates } = project

  useEffect(() => {
    updateAddUsersToNotifyModal({
      userGroups,
    })
  }, [userGroups, updateAddUsersToNotifyModal])

  // let's keep this an ID-indexed object for faster
  // lookup/reference, just pad it a bit w/ template & vendor info
  const eventSubscribers = useMemo(() => {
    if (!eventSubscriptions) {
      return {}
    }
    return mapValues(eventSubscriptions.subscribers, (sub) => {
      const templates = compact(
        uniq(map(sub.subscriptions, 'key_hash.assessment_template_id')),
      ).filter((e) => e !== '*')
      const vendors = compact(
        uniq(map(sub.subscriptions, 'key_hash.project_vendor_id')),
      )
      return { templates, vendors, ...sub }
    })
  }, [eventSubscriptions])

  const createEventSubscribers = useCallback(() => {
    const onSubmit = async (formData) => {
      const loaderId = showLoader()
      try {
        await addClientProjectEventSubscriptions({
          projectId,
          data: {
            event_subscription: {
              user_ids: formData.users
                .filter((e) => e.type === 'user')
                .map((e) => e.value),
              groups: formData.users
                .filter((e) => e.type === 'group')
                .map((e) => e.value),
              limited_to:
                formData.templatesFilter || formData.vendorsFilter
                  ? {
                      assessment_template_ids: formData.templates,
                      project_vendor_ids: formData.vendors,
                    }
                  : undefined,
            },
          },
        })
        closeAddUsersToNotifyModal()
        onSaveRef.current()
      } catch (error) {
        NotificationManager.error()
      }
      hideLoader(loaderId)
    }

    openAddUsersToNotifyModal({
      assessmentTemplates,
      projectVendorsProps,
      eventSubscribers,
      userGroups,
      onSubmit,
    })
  }, [
    projectId,
    eventSubscribers,
    userGroups,
    assessmentTemplates,
    projectVendorsProps,
    openAddUsersToNotifyModal,
    closeAddUsersToNotifyModal,
    onSaveRef,
    showLoader,
    hideLoader,
    addClientProjectEventSubscriptions,
  ])

  const updateEventSubscriber = useCallback(
    (userId) => {
      const onSubmit = async (formData) => {
        const loaderId = showLoader()
        try {
          const limits = {}
          if (formData.templatesFilter)
            limits.assessment_template_ids = formData.templates
          if (formData.vendorsFilter)
            limits.project_vendor_ids = formData.vendors
          await updateClientProjectEventSubscribers({
            projectId,
            data: {
              event_subscription: {
                user_ids: [userId],
                limited_to: limits,
              },
            },
          })
          closeEditNotificationsModal()
          onSaveRef.current()
        } catch (error) {
          NotificationManager.error()
        }
        hideLoader(loaderId)
      }

      const eventSubscriber = eventSubscribers[userId]
      if (!eventSubscriber) {
        return
      }
      openEditNotificationsModal({
        eventSubscriber,
        assessmentTemplates,
        projectVendorsProps,
        onSubmit,
      })
    },
    [
      projectId,
      assessmentTemplates,
      projectVendorsProps,
      eventSubscribers,
      openEditNotificationsModal,
      closeEditNotificationsModal,
      onSaveRef,
      showLoader,
      hideLoader,
      updateClientProjectEventSubscribers,
    ],
  )

  const deleteEventSubscriber = useCallback(
    async (userId) => {
      const loaderId = showLoader()
      try {
        await removeClientProjectEventSubscribers({
          projectId,
          data: {
            event_subscription: {
              user_ids: [userId],
            },
          },
        })
        onSaveRef.current()
      } catch (error) {
        NotificationManager.error()
      }
      hideLoader(loaderId)
    },
    [
      projectId,
      onSaveRef,
      showLoader,
      hideLoader,
      removeClientProjectEventSubscribers,
    ],
  )

  return {
    eventSubscribers,
    isFetchingEventSubscribers,
    createEventSubscribers,
    updateEventSubscriber,
    deleteEventSubscriber,
  }
}
