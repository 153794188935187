import React from 'react'
import PropTypes from 'prop-types'
import { RadioGroup } from '@headlessui/react'
import findIndex from 'lodash/findIndex'
import Tooltip from 'components-v2/molecules/Tooltip'
import { RatingSelectWrapper, Markers, Marker, Label } from './styles'

const RatingSelect = ({
  value,
  markers,
  readOnly,
  disabled,
  labelVisible,
  tooltipVisible,
  resetable,
  markerHeight,
  onChange,
  RenderMarkers,
  RenderMarker,
  RenderLabel,
  ...rest
}) => {
  const [hoverValue, setHoverValue] = React.useState(value)
  const hoverable = !readOnly && !disabled
  const focusedValue = hoverable && hoverValue !== null ? hoverValue : value
  const focusedMarkerIndex = findIndex(markers, { value: focusedValue })

  React.useEffect(() => {
    setHoverValue(value)
  }, [value])

  const handleMarkerHover = React.useCallback(
    (marker) => {
      if (hoverable) {
        setHoverValue(marker.value)
      }
    },
    [hoverable],
  )

  const handleMarkerClick = React.useCallback(
    (marker) => {
      if (hoverable) {
        const newValue =
          resetable && value === marker.value ? null : marker.value
        onChange(newValue)
        setHoverValue(newValue)
      }
    },
    [value, hoverable, resetable, onChange],
  )

  const handleMarkersLeave = React.useCallback(() => {
    setHoverValue(value)
  }, [value])

  const handleKeyPressOnMarker = React.useCallback(
    (e) => {
      if ((e.keyCode === 32 || e.key === 'Enter') && hoverable) {
        e.preventDefault()
        const index = parseInt(e.target.dataset.index, 10)
        if (markers[index]) {
          const newValue =
            resetable && value === markers[index].value
              ? null
              : markers[index].value
          onChange(newValue)
          setHoverValue(newValue)
        }
      }
    },
    [markers, hoverable, resetable, onChange, value],
  )

  return (
    <RatingSelectWrapper {...rest} disabled={disabled}>
      <RadioGroup
        as={RenderMarkers}
        height={markerHeight}
        onMouseLeave={handleMarkersLeave}
        onBlur={handleMarkersLeave}
        value={hoverValue}
        disabled={disabled || readOnly}
        onChange={setHoverValue}
        onKeyDown={(event) => handleKeyPressOnMarker(event)}
      >
        {markers.map((e, index) => {
          const marker = (
            <RenderMarker
              color={e.color}
              active={index <= focusedMarkerIndex}
              hoverable={hoverable}
            />
          )
          return (
            <RadioGroup.Option
              key={e.value}
              value={e.value}
              data-index={index}
              onMouseEnter={() => handleMarkerHover(e)}
              onClick={() => handleMarkerClick(e)}
            >
              {e.label && tooltipVisible ? (
                <Tooltip key={e.value} parent={marker}>
                  {e.label}
                </Tooltip>
              ) : (
                marker
              )}
            </RadioGroup.Option>
          )
        })}
      </RadioGroup>
      {labelVisible && focusedMarkerIndex !== -1 && (
        <RenderLabel>{markers[focusedMarkerIndex].label}</RenderLabel>
      )}
    </RatingSelectWrapper>
  )
}

RatingSelect.propTypes = {
  value: (props, propName, componentName) => {
    const value = props[propName]
    if (value !== undefined && value !== null) {
      if (typeof value !== 'number' && typeof value !== 'string') {
        return new Error(
          `Invalid prop \`${propName}\` of type \`${typeof value}\` supplied to \`${componentName}\`, expected \`number\` or \`string\`.`,
        )
      }
      if (findIndex(props.markers, { value }) === -1) {
        return new Error(`${propName} in ${componentName} is out of scope`)
      }
    }

    // assume all ok
    return null
  },
  // ^ current rating value

  markers: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
      label: PropTypes.string.isRequired,
      color: PropTypes.string,
    }),
  ).isRequired,
  // ^ array of marker option

  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  labelVisible: PropTypes.bool,
  tooltipVisible: PropTypes.bool,
  resetable: PropTypes.bool, // reset to null if click the current score
  markerHeight: PropTypes.number,
  onChange: PropTypes.func,
  RenderMarkers: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  RenderMarker: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  RenderLabel: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
}

RatingSelect.defaultProps = {
  value: 0,
  labelVisible: true,
  tooltipVisible: true,
  markerHeight: 30,
  onChange: () => {},
  RenderMarkers: Markers,
  RenderMarker: Marker,
  RenderLabel: Label,
}

RatingSelect.Markers = Markers
RatingSelect.Marker = Marker
RatingSelect.Label = Label

export default RatingSelect
