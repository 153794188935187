import React from 'react'
import PropTypes from 'prop-types'
import { Metric, Bottom, Dot, Label } from './styles'

const IntegrationMetric = ({ color, label, value, grade }) => (
  <div>
    <Metric color={color}>{grade || value}</Metric>
    <Bottom>
      {color && <Dot color={color} />}
      {label && <Label>{label}</Label>}
    </Bottom>
  </div>
)

IntegrationMetric.propTypes = {
  value: PropTypes.any.isRequired,
  grade: PropTypes.any,
  color: PropTypes.string,
  label: PropTypes.string,
}

export default IntegrationMetric
