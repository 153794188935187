import React from 'react'
import Privva from 'Privva'
import styled from 'styled-components'
import isBefore from 'date-fns/isBefore'
import get from 'lodash/get'
import mapValues from 'lodash/mapValues'
import sortBy from 'lodash/sortBy'
import keyBy from 'lodash/keyBy'
import Label from 'components-v2/atoms/Label'
import {
  IssueStatusTypes,
  IssuePriorityOptions,
  IssueResolutionOptions,
} from 'constants/index'
import { dateFormatterFactory } from 'lib/table'

const IssuePriorityOptionsObj = keyBy(IssuePriorityOptions, 'value')
const IssueResolutionOptionsObj = keyBy(IssueResolutionOptions, 'value')

export const getIssuePriorityLabel = (value) =>
  get(IssuePriorityOptionsObj, [value, 'label'], '')

export const getIssueResolutionLabel = (value) =>
  get(IssueResolutionOptionsObj, [value, 'label'], '')

export const getIssueStatusLabel = (value, isVendor) => {
  if (value === IssueStatusTypes.RESPONDED) {
    return isVendor ? 'Response Sent' : 'Needs Review'
  }
  return get(Privva, ['Utils', 'issue_states', value], '')
}

export const getIssueStatusOptions = (isVendor) => {
  const states = { ...Privva.Utils.issue_states }
  if (isVendor) {
    delete states[IssueStatusTypes.CREATED]
    delete states[IssueStatusTypes.ARCHIVED]
  }
  return mapValues(states, (_, value) => ({
    label: getIssueStatusLabel(value, isVendor),
  }))
}

export const issueDueDateFormatterFactory = (options) =>
  dateFormatterFactory({
    ...options,
    showWarning: (_, date, row) =>
      isBefore(date, new Date()) &&
      ![IssueStatusTypes.CLOSED, IssueStatusTypes.ARCHIVED].includes(
        row.status,
      ),
  })

const PriorityLabel = styled(Label)`
  min-width: 111px;
`

export const issuePriorityFormatterFactory = () => (info) =>
  Number.isInteger(info.getValue()) ? (
    <PriorityLabel
      color={get(IssuePriorityOptionsObj, [info.getValue(), 'color'], '')}
    >
      {getIssuePriorityLabel(info.getValue())}
    </PriorityLabel>
  ) : (
    ''
  )

const clientStatusSort = {
  [IssueStatusTypes.CREATED]: 0,
  [IssueStatusTypes.RESPONDED]: 1,
  [IssueStatusTypes.OPENED]: 2,
  [IssueStatusTypes.CLOSED]: 3,
  [IssueStatusTypes.ARCHIVED]: 4,
}
const vendorStatusSort = {
  [IssueStatusTypes.CREATED]: 0,
  [IssueStatusTypes.OPENED]: 1,
  [IssueStatusTypes.RESPONDED]: 2,
  [IssueStatusTypes.CLOSED]: 3,
  [IssueStatusTypes.ARCHIVED]: 4,
}
const closedSort = {
  resolved: 0,
  failed: 1,
  'risk accepted': 2,
  'in remediation': 3,
  cancelled: 4,
}

export const sortIssues = (isVendor, issues) =>
  sortBy(issues, [
    (i) =>
      isVendor &&
      i.due_at &&
      ![IssueStatusTypes.CLOSED, IssueStatusTypes.ARCHIVED].includes(i.status)
        ? i.due_at
        : null, // null sorts last
    (i) => (isVendor ? vendorStatusSort[i.status] : clientStatusSort[i.status]),
    (i) =>
      i.status === IssueStatusTypes.CLOSED ? closedSort[i.resolution] : -1,
    (i) => i.priority * -1,
  ])
