import { EventEmitter } from 'events'
import uniqueId from 'lodash/uniqueId'

const CHANGE = 'change'

/**
 * Class that manages the lifecycle of modals, allowing opening, updating, and closing modals.
 * It extends the `EventEmitter` to handle modal state changes and notify listeners.
 */

class ModalManager extends EventEmitter {
  constructor() {
    super()
    /**
     * Object to store currently active modals, keyed by unique modal IDs.
     * @type {Object}
     */
    this.modals = {}
  }

  /**
   * Generates a unique key for a modal instance.
   * @returns {string} A unique key prefixed with "modal_".
   */
  generateModalKey() {
    return uniqueId('modal_')
  }

  /**
   * Opens a modal by adding it to the `modals` state.
   * @param {string} key - The unique key identifying the modal.
   * @param {React.ComponentType} component - The modal component to render.
   * @param {Object} [props={}] - The props to pass to the modal component.
   */
  openModal(key, component, props = {}) {
    this.setModals({
      ...this.modals,
      [key]: {
        component,
        props,
      },
    })
  }

  /**
   * Updates the properties of an already opened modal.
   * @param {string} key - The unique key identifying the modal.
   * @param {Object} propsChange - The new properties to apply to the modal.
   */
  updateModal(key, propsChange) {
    if (this.modals[key]) {
      this.setModals({
        ...this.modals,
        [key]: {
          ...this.modals[key],
          props: {
            ...this.modals[key].props,
            ...propsChange,
          },
        },
      })
    }
  }

  /**
   * Closes and removes a modal from the `modals` state.
   * @param {string} key - The unique key identifying the modal to close.
   */
  closeModal(key) {
    if (!this.modals[key]) {
      return
    }

    const modals = { ...this.modals }
    delete modals[key]
    this.setModals(modals)
  }

  /**
   * Returns a set of functions to open, close, and update the modal component.
   * @param {React.ComponentType} modalComponent - The modal component to manage.
   * @returns {Array<Function>} Array containing the `open`, `close`, and `update` functions.
   */
  getModalFunctions(modalComponent) {
    const key = this.generateModalKey()
    const open = (props) => this.openModal(key, modalComponent, props)
    const update = (props) => this.updateModal(key, props)
    const close = () => this.closeModal(key)
    return [open, close, update]
  }

  /**
   * Updates the modal state and emits the change event to listeners.
   * @param {Object} modals - The updated modals object.
   * @fires ModalManager#change
   */
  setModals(modals) {
    this.modals = modals
    this.emit(CHANGE, modals)
  }

  /**
   * Adds a listener to respond to modal state changes.
   * @param {Function} callback - The callback to invoke when the modals state changes.
   */
  addChangeListener(callback) {
    this.addListener(CHANGE, callback)
  }

  /**
   * Removes a previously added change listener.
   * @param {Function} callback - The callback to remove.
   */
  removeChangeListener(callback) {
    this.removeListener(CHANGE, callback)
  }
}

export default new ModalManager()
