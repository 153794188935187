import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import { toLocalTimeString } from 'utils/date'
import {
  Wrapper,
  Comment,
  CommentHeader,
  CommentedBy,
  CommentedAt,
  CommentBody,
  CommentBodyArrow,
  CommentAttachments,
  CommentAttachment,
  AttachmentIcon,
} from './styles'

const CommentList = ({ comments, getCommenter, highlightGetter, ...rest }) => (
  <Wrapper {...rest}>
    {map(comments, (comment) => (
      <Comment key={comment.id}>
        <CommentHeader>
          <CommentedBy>{getCommenter(comment)}</CommentedBy>
          <CommentedAt>
            {toLocalTimeString(comment.created_at, 'MM/dd/yyyy')}
          </CommentedAt>
        </CommentHeader>
        <CommentBody $highlight={highlightGetter(comment)}>
          <CommentBodyArrow />
          {comment.body}
        </CommentBody>
        {comment.attachments && comment.attachments.length > 0 && (
          <CommentAttachments>
            {map(comment.attachments, (file) => (
              <CommentAttachment key={file.id} href={file.url} target="_blank">
                <AttachmentIcon />
                {file.name}
              </CommentAttachment>
            ))}
          </CommentAttachments>
        )}
      </Comment>
    ))}
  </Wrapper>
)

CommentList.propTypes = {
  comments: PropTypes.array,
  getCommenter: PropTypes.func,
  highlightGetter: PropTypes.func,
}

CommentList.defaultProps = {
  comments: [],
  getCommenter: (comment) => comment.organization.name,
  highlightGetter: () => false,
}

export default CommentList
