import React from 'react'
import keyBy from 'lodash/keyBy'
import { useModal } from 'hooks'
import PrintOptionsModal from './PrintOptionsModal'

const printOptions = [
  {
    label: 'Hide Question Ratings',
    value: 'hide_question_ratings',
    bodyClass: 'hide-question-ratings-on-print',
  },
  {
    label: 'Hide Comments',
    value: 'hide_comments',
    bodyClass: 'hide-comments-on-print',
  },
  {
    label: 'Include Skipped Questions',
    value: 'include_skipped_questions',
    bodyClass: 'include-skipped-questions-on-print',
  },
  {
    label: 'Include Monitoring Data',
    value: 'include_monitoring_data',
    bodyClass: 'include-monitoring-data-on-print',
  },
]
const printOptionsObj = keyBy(printOptions, 'value')

export default function usePrintWithOptions() {
  const [openModal, closeModal] = useModal(PrintOptionsModal)

  return React.useCallback(() => {
    openModal({
      printOptions,
      onSubmit: ({ options = [] }) => {
        closeModal()
        setTimeout(() => {
          options.forEach((value) => {
            document.body.classList.add(printOptionsObj[value].bodyClass)
          })
          window.print()
          options.forEach((value) => {
            document.body.classList.remove(printOptionsObj[value].bodyClass)
          })
        }, 500)
      },
    })
  }, [openModal, closeModal])
}
