import React from 'react'
import PropTypes from 'prop-types'
import Privva from 'Privva'
import A from 'components-v2/atoms/A'
import IconButton from 'components-v2/atoms/IconButton'
import Toggle from 'components-v2/atoms/Toggle'
import Tooltip from 'components-v2/molecules/Tooltip'
import { LabelTypes } from 'constants/index'
import { useAuthorizations, useModal } from 'hooks'
import VendorsAttributeToggleModal from './VendorsAttributeToggleModal'
import useUpdateBccEmail from './useUpdateBccEmail'
import useUpdateReplyToEmail from './useUpdateReplyToEmail'
import useUpdateOrganization from './useUpdateOrganization'
import { Table, InlineFlex } from './styles'

const OptionsView = ({ showMfaEnforcement, showMultilang, organization }) => {
  const [canAdmin] = useAuthorizations('admin')
  const updateBccEmail = useUpdateBccEmail(organization)
  const updateReplyToEmail = useUpdateReplyToEmail(organization)
  const updateOrganization = useUpdateOrganization(organization)
  const [openVendorsAttributeToggleModal] = useModal(
    VendorsAttributeToggleModal,
  )
  const mergeEmailAndName = (name, email) =>
    name ? `${name} <${email}>` : email
  return (
    <>
      {showMfaEnforcement && (
        <>
          <h3>Two-Factor Authentication</h3>
          <Table className="table">
            <tbody>
              <tr>
                <td>
                  Require for your organization&apos;s users&nbsp;
                  <Tooltip>
                    Two factor enforcement can only be applied if the
                    organization is not using Single Sign On.
                  </Tooltip>
                </td>
                <td>
                  <Toggle
                    checked={organization.mfa_required}
                    onChange={(value) =>
                      updateOrganization({ mfa_required: value })
                    }
                  />
                </td>
                <td></td>
              </tr>
              <tr>
                <td>
                  Require for new {LabelTypes.VENDORS.toLowerCase()} invited to
                  complete assessments&nbsp;
                  <Tooltip>
                    All {LabelTypes.VENDORS.toLowerCase()} created going forward
                    will require their users to have two factor authentication
                    enabled. Two factor enforcement can only be applied if the
                    organization is not using Single Sign On.
                  </Tooltip>
                </td>
                <td>
                  <Toggle
                    checked={organization.vendor_default_mfa_required}
                    onChange={(value) =>
                      updateOrganization({
                        vendor_default_mfa_required: value,
                      })
                    }
                  />
                </td>
                <td>
                  <A
                    underline
                    onClick={() =>
                      openVendorsAttributeToggleModal({
                        attributeToToggle: 'mfa_required',
                        attributeDisplayName: 'Two Factor Required',
                      })
                    }
                  >
                    {`Manage ${LabelTypes.VENDORS}`}
                  </A>
                </td>
              </tr>
            </tbody>
          </Table>
        </>
      )}
      {showMultilang && (
        <>
          <h3>Multi-language Translation</h3>
          <Table className="table">
            <tbody>
              <tr>
                <td>
                  Enable {LabelTypes.VENDORS.toLowerCase()} to switch languages
                </td>
                <td>
                  <A
                    underline
                    onClick={() =>
                      openVendorsAttributeToggleModal({
                        attributeToToggle: 'multilang',
                        attributeDisplayName: 'Multi-language Translations',
                      })
                    }
                  >
                    {`Manage ${LabelTypes.VENDORS}`}
                  </A>
                </td>
              </tr>
            </tbody>
          </Table>
        </>
      )}
      {canAdmin && (
        <>
          <h3>Email Archive</h3>
          <Table className="table">
            <tbody>
              <tr>
                <td>
                  BCC all {Privva.Utils.brand.programName} email communications
                </td>
                <td>
                  <InlineFlex>
                    <A underline onClick={updateBccEmail}>
                      {organization.bcc_email || 'Enter Email Address'}
                    </A>
                    {organization.bcc_email && (
                      <IconButton
                        icon="fa fa-times"
                        onClick={() => updateOrganization({ bcc_email: '' })}
                      />
                    )}
                  </InlineFlex>
                </td>
              </tr>
            </tbody>
          </Table>
          <h3>Email Reply-To Address</h3>
          <Table className="table">
            <tbody>
              <tr>
                <td>
                  Reply-To address for All {Privva.Utils.brand.programName}{' '}
                  email communications
                </td>
                <td>
                  <InlineFlex>
                    <A underline onClick={updateReplyToEmail}>
                      {organization.default_reply_email
                        ? mergeEmailAndName(
                            organization.default_reply_name,
                            organization.default_reply_email,
                          )
                        : 'Enter Email Address'}
                    </A>
                    {organization.default_reply_email && (
                      <IconButton
                        icon="fa fa-times"
                        onClick={() =>
                          updateOrganization({ default_reply_email: '' })
                        }
                      />
                    )}
                  </InlineFlex>
                </td>
              </tr>
            </tbody>
          </Table>
        </>
      )}
    </>
  )
}

OptionsView.propTypes = {
  organization: PropTypes.object.isRequired,
  showMfaEnforcement: PropTypes.bool,
  showMultilang: PropTypes.bool,
}

OptionsView.defaultProps = {
  showMfaEnforcement: false,
  showMultilang: false,
}

export default OptionsView
