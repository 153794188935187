import styled from 'styled-components'

export const Legend = styled.div`
  margin-top: 15px;
`

export const LegendItem = styled.div`
  font-size: 15px;
  margin-bottom: 2px;

  &:before {
    content: url('data:image/svg+xml;base64,${(props) =>
      window.btoa(
        `<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='${props.color}' /></svg>`,
      )}');
    margin-right: 10px;
    display: inline-block;
  }
`
