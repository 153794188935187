import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import { validateYupSchema, yupToFormErrors } from 'formik'
import find from 'lodash/find'
import filter from 'lodash/filter'
import keys from 'lodash/keys'
import some from 'lodash/some'
import mapValues from 'lodash/mapValues'
import FormField from 'components-v2/molecules/FormField'
import FormDialog from 'components-v2/organisms/FormDialog'
import CustomFormField from './CustomFormField'

const defaultInitialValues = {
  weight: { value: 5 },
  required: { value: false },
  allow_comments: { value: true },
  allow_attachments: { value: true },
}

const validationSchema = yup.object().shape({
  weight: yup.object().shape({
    value: yup
      .number()
      .label('Weight')
      .min(0, 'Weight must be between 0 and 10')
      .max(10, 'Weight must be between 0 and 10')
      .when('weight.enabled', {
        is: true,
        then: yup.number().required(),
      }),
  }),
  allow_comments: yup.object().shape({
    value: yup
      .boolean()
      .test(
        'ensureRequiredArtifactsAllowed',
        'Please allow comments and/or attachments.',
        function test(value) {
          return (
            value ||
            (this.options.context.allow_attachments.enabled &&
              this.options.context.allow_attachments.value)
          )
        },
      ),
  }),
  allow_attachments: yup.object().shape({
    value: yup
      .boolean()
      .test(
        'ensureRequiredArtifactsAllowed',
        'Please allow comments and/or attachments.',
        function test(value) {
          return (
            (this.options.context.allow_comments.enabled &&
              this.options.context.allow_comments.value) ||
            value
          )
        },
      ),
  }),
})

const validate = (value) => {
  try {
    validateYupSchema(value, validationSchema, true, value)
  } catch (err) {
    return yupToFormErrors(err) // for rendering validation errors
  }

  return {}
}

// Check if the given array elements have same value for a specific property
const hasSamePropertyValue = (arr, key) => {
  if (!arr || arr.length === 0) {
    return null
  }
  const firstValue = arr[0][key]
  return !some(arr, (e) => e[key] !== firstValue)
}

const BulkEditQuestionFormModal = ({
  elementIds,
  elements,
  onSubmit,
  ...rest
}) => {
  const multipleValueProperties = React.useMemo(() => {
    const selectedElements = filter(elements, (e) => elementIds.includes(e.id))
    return keys(defaultInitialValues).filter(
      (key) => !hasSamePropertyValue(selectedElements, key),
    )
  }, [elements, elementIds])

  const formConfig = React.useMemo(() => {
    const element = find(elements, (e) => elementIds.includes(e.id))
    const initialValues = mapValues(defaultInitialValues, (e, key) => {
      if (!multipleValueProperties.includes(key)) {
        return {
          value: element[key] === null ? undefined : element[key],
          enabled: true,
        }
      }
      return e
    })

    return {
      initialValues,
      validate,
    }
  }, [multipleValueProperties, elements, elementIds])

  const handleSubmit = React.useCallback(
    (formData, actions) => {
      const payload = {}
      Object.entries(formData).forEach(([key, value]) => {
        if (value.enabled) {
          payload[key] = value.value
        }
      })
      onSubmit(payload, actions)
    },
    [onSubmit],
  )

  return (
    <FormDialog
      {...rest}
      title="Edit Multiple Questions"
      formConfig={formConfig}
      onSubmit={handleSubmit}
    >
      <p>Editing multiple items.</p>
      <CustomFormField
        name="weight"
        label="Score Weight"
        helpMessage="The weight this question has on the rating score. Default is 5."
        multipleValueProperties={multipleValueProperties}
      />
      <CustomFormField
        name="required"
        label="Require Answer"
        type={FormField.types.CHECKBOX}
        multipleValueProperties={multipleValueProperties}
      />
      <CustomFormField
        name="allow_comments"
        label="Allow Comments"
        type={FormField.types.CHECKBOX}
        multipleValueProperties={multipleValueProperties}
      />
      <CustomFormField
        name="allow_attachments"
        label="Allow Attachments"
        type={FormField.types.CHECKBOX}
        multipleValueProperties={multipleValueProperties}
      />
    </FormDialog>
  )
}

BulkEditQuestionFormModal.propTypes = {
  elementIds: PropTypes.array.isRequired,
  elements: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default BulkEditQuestionFormModal
