import styled from 'styled-components'
import Card from 'components-v2/organisms/Card'
import Button from 'components-v2/atoms/Button'

const borderColor = '#E4E5E6'

export const Segment = styled(Card.Segment)`
  border-color: ${borderColor};
`

export const Top = styled.div`
  margin-bottom: 30px;
`

export const Title = styled.h4`
  display: inline-block;
  margin: 0 15px 0 0;
`

export const MoreLink = styled.a`
  color: inherit;

  &:hover,
  &:active,
  &:focus {
    color: inherit;
  }
`

export const CTA = styled(Button).attrs({
  color: 'primary',
  size: 'large',
})`
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) => props.position === 'bottom' && `bottom: 0;`}
  ${(props) => props.position === 'top' && `top: 0;`}
  left: 0;
  right: 0;
  border-radius: 0;
  z-index: 1;
  visibility: hidden;
`

export const Wrap = styled(Card)`
  position: relative;
  background: rgba(207, 209, 212, 0.25);
  border-color: ${borderColor};
  color: rgba(80, 80, 80, 0.5);
  overflow: hidden;

  &:hover ${CTA} {
    visibility: visible;
  }
`
