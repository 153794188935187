import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { CountryDropdown } from 'react-country-region-selector'
import { styles, smallStyles } from 'components-v2/atoms/Input'
import BaseField from 'components-v2/molecules/FormField/BaseField'

const StyledCountryDropdown = styled(CountryDropdown).attrs({
  valueType: 'short',
  priorityOptions: ['CA', 'US', 'GB'],
})`
  ${styles}
  ${smallStyles}
`

const CountryField = ({
  name,
  controlId,
  value,
  disabled,
  controlProps = {},
  onChange,
  ...rest
}) => (
  <BaseField controlId={controlId} {...rest}>
    <StyledCountryDropdown
      {...controlProps}
      id={controlId}
      name={name}
      value={value}
      disabled={disabled}
      onChange={onChange}
    />
  </BaseField>
)

CountryField.propTypes = {
  name: PropTypes.string,
  controlId: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  controlProps: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

export default CountryField
