import styled, { css } from 'styled-components'
import { darken } from 'polished'
import Button from 'components-v2/atoms/Button'
import Card from 'components-v2/organisms/Card'
import { colors } from 'styles'

export const Link = styled.a`
  display: block;
  color: inherit;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus,
  &:visited {
    color: inherit;
    text-decoration: none;
  }
`

export const Heading = styled(Card.Segment)`
  padding: 10px 15px;
  color: ${colors.WHITE};
  border-color: transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
`

export const Wrapper = styled(Card).attrs({ bordered: true })`
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);

  &:hover {
    border-color: #c7c7c7;
  }

  ${({ color }) =>
    color &&
    css`
      ${Heading} {
        background-color: ${color};
      }

      &:hover ${Heading} {
        background-color: ${darken(0.1, color)};
      }
    `}
`

export const Title = styled.h4`
  margin: 0;
`

export const CloseDate = styled.div`
  margin-top: 3px;
  opacity: 0.85;
  font-size: 12px;
`

export const Body = styled(Card.Segment)`
  padding: 15px;
  border-bottom: 1px solid #ddd;
`

export const Footer = styled(Card.Segment)`
  min-height: 42px;
  padding: 5px 15px;
  background-color: #f5f5f5;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const Status = styled.div`
  flex: none;
  min-width: 38%;
  padding-right: 10px;
  opacity: 0.5;
  white-space: nowrap;

  ${({ warning }) =>
    warning &&
    `
    color: ${colors.DANGER};
    opacity: 1;
  `}
`

export const ProgressWrapper = styled.div`
  flex: 1;
`

export const ExtendButton = styled(Button).attrs({
  size: 'sm',
  color: 'danger',
  variant: 'outlined',
})`
  flex: none;
  margin: 10px 0;
`
