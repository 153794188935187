import styled from 'styled-components'
import { mixins } from 'styles'

const cellColors = [
  '#bdc5cd',
  '#bdc5cd',
  '#54b443',
  '#9ac250',
  '#317dd4',
  '#f3c55b',
  '#e28647',
  '#cf3c2c',
  '#cf3c2c',
]
const TITLE_HEIGHT = 15
const XLABEL_HEIGHT = 25
const YLABEL_WIDTH = 35

export const Wrapper = styled.div`
  position: relative;
  padding-left: ${TITLE_HEIGHT}px;
  padding-bottom: ${TITLE_HEIGHT}px;
`

export const Row = styled.div`
  display: flex;
`

const BaseCell = styled.div`
  flex: 1 1 0%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const YLabel = styled(BaseCell)`
  flex: 0 0 ${YLABEL_WIDTH}px;
  font-size: 10px;
  text-align: right;
  padding-right: 5px;
`

export const XLabel = styled(BaseCell)`
  font-size: 10px;
  height: ${XLABEL_HEIGHT}px;
`

export const Cell = styled(BaseCell)`
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  height: 55px;
  font-weight: 800;
  background: ${({ x, y }) => cellColors[x - y + 4]};
  color: ${({ muted }) => (muted ? 'rgba(255, 255, 255, .3)' : '#FFF')};
`

export const CellValue = styled.div`
  font-size: 14px;
`

export const CellLabel = styled.div`
  font-size: 7px;
  text-transform: uppercase;
`

export const XTitle = styled.div`
  position: absolute;
  bottom: 0;
  left: ${TITLE_HEIGHT + YLABEL_WIDTH}px;
  right: 0;
  ${mixins.smallHeader}
  font-size: 10px;
  text-align: center;
`

export const YTitle = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: ${TITLE_HEIGHT + XLABEL_HEIGHT}px;
  ${mixins.smallHeader}
  font-size: 10px;
  text-align: center;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
`
