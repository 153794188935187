import { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import DueDateFormDialog from 'components/client/shared/DueDateFormDialog'
import { useGlobalLoader, useModal } from 'hooks'
import NotificationManager from 'lib/notifications'
import {
  useClientAssessmentsFetcher,
  useUpdateMultiProjectVendorsForClientProject,
  useClientProjectTotalsFetcher,
} from 'apis'

export default (projectId) => {
  const queryClient = useQueryClient()
  const [openModal, closeModal] = useModal(DueDateFormDialog)
  const [showLoader, hideLoader] = useGlobalLoader()
  const { mutateAsync: updateMultiProjectVendors } =
    useUpdateMultiProjectVendorsForClientProject()

  const canBulkExtend = useCallback(
    (project, projectVendors) =>
      project.status === 'launched' &&
      projectVendors.length > 0 &&
      !projectVendors.find((e) => !e.extendable),
    [],
  )

  const bulkExtend = useCallback(
    (projectVendors, onSuccess) => {
      const onSubmit = ({ dueDate }) => {
        const loaderId = showLoader()
        const data = {
          project_vendors: projectVendors.map((e) => ({
            id: e.id,
            date_range_attributes: {
              start_at: e.date_range.start_at,
              end_at: dueDate,
            },
          })),
        }
        return updateMultiProjectVendors({ projectId, data })
          .then((response) => {
            closeModal()
            queryClient.invalidateQueries(
              useClientAssessmentsFetcher.queryKey(),
            )
            queryClient.invalidateQueries(
              useClientProjectTotalsFetcher.queryKey({ id: projectId }),
            )
            NotificationManager.success(
              'The changes have been applied successfully.',
            )
            onSuccess(response)
          })
          .catch(() => NotificationManager.error())
          .then(() => hideLoader(loaderId))
      }
      openModal({ onSubmit })
    },
    [
      projectId,
      updateMultiProjectVendors,
      openModal,
      closeModal,
      showLoader,
      hideLoader,
      queryClient,
    ],
  )

  return {
    canBulkExtend,
    bulkExtend,
  }
}
