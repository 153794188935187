import React from 'react'
import PropTypes from 'prop-types'
import { parseArgosRiskData } from 'components/client/lib/globorg_integration_metrics'
import BaseOverviewCard from '../BaseOverviewCard'
import IntegrationMetric from '../IntegrationMetric'

const ArgosRiskOverviewCard = ({ data, ...rest }) => {
  const parsed = React.useMemo(() => parseArgosRiskData(data)[0], [data])
  return (
    <BaseOverviewCard
      {...rest}
      title={
        <span>
          Argos
          <br />
          risk score
        </span>
      }
      description={parsed.description}
      tooltipMaxWidth={440}
    >
      <IntegrationMetric value={parsed.value} color={parsed.color} />
    </BaseOverviewCard>
  )
}

ArgosRiskOverviewCard.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ArgosRiskOverviewCard
