import React from 'react'

export default function useAutoToggleStates(timeout) {
  const [value, setValue] = React.useState({})
  const timeoutsRef = React.useRef({})

  const activate = React.useCallback(
    (key) => {
      setValue((currentValue) => ({
        ...currentValue,
        [key]: true,
      }))
      if (timeoutsRef.current[key]) {
        clearTimeout(timeoutsRef.current[key])
      }
      timeoutsRef.current[key] = setTimeout(() => {
        setValue((currentValue) => ({
          ...currentValue,
          [key]: false,
        }))
      }, timeout)
    },
    [timeout],
  )

  return [value, activate]
}
